import { Injectable } from "@angular/core";
import {
  differenceInHours,
  isToday,
  isValid,
  parseISO,
  subDays,
} from "date-fns";
import { ConfigService } from "../_services/config.service";

@Injectable()
export class DateFormatInteractor {
  constructor(private configService: ConfigService) {}
  getCurrentMonth() {
    let month = new Date().getMonth();
    if (month < 9) {
      return 0 + "" + (month + 1);
    } else return month + 1;
  }

  getcurrentDate() {
    let date = new Date().getDate();
    if (date < 10) {
      return 0 + "" + date;
    } else return date;
  }

  currentDateNew() {
    var tDate =
      new Date().getFullYear() +
      "-" +
      this.getCurrentMonth() +
      "-" +
      this.getcurrentDate();
    return tDate;
  }
  currentDate() {
    var tDate =
      this.getcurrentDate() +
      "/" +
      this.getCurrentMonth() +
      "/" +
      new Date().getFullYear();
    return tDate;
  }

  formatDate(newDate) {
    var month_names_short = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    let monthIndex = new Date(newDate).getMonth();
    let dateFormatted =
      this.getInputDate(newDate) +
      " " +
      month_names_short[monthIndex] +
      " " +
      new Date(newDate).getFullYear();
    return dateFormatted;
  }
  getDay(newDate) {
    var day_names_short = [
      "result-sun",
      "result-mon",
      "result-tue",
      "result-wed",
      "result-thu",
      "result-fri",
      "result-sat",
    ];
    return day_names_short[new Date(newDate).getDay()];
  }

  private inputDate: Date;

  getInputMonth(fulldate) {
    let month = new Date(fulldate).getMonth();
    if (month < 9) {
      return 0 + "" + (month + 1);
    } else return month + 1;
  }

  getInputDate(fulldate) {
    let date = new Date(fulldate).getDate();
    if (date < 10) {
      return 0 + "" + date;
    } else return date;
  }

  inputDateFormat(date) {
    var tDate =
      this.getInputDate(date) +
      "/" +
      this.getInputMonth(date) +
      "/" +
      new Date(date).getFullYear();
    return tDate;
  }

  dynamicDate() {
    let config = this.configService.dynamicHeader;
    let todayI = Math.ceil(config / 2) - 1;
    let start = 0 - todayI;
    let end = 3 + todayI;
    let dateArr = [];
    let dayArr = [];
    let result = {};
    for (var i = start; i <= end; i++) {
      let date = this.formatDate(
        new Date(new Date().setDate(new Date().getDate() + i))
      );
      let day = this.getDay(
        new Date(new Date().setDate(new Date().getDate() + i))
      );

      // result.push({ date, day })
      dateArr.push(date);
      dayArr.push(day);
    }
    (result["date"] = dateArr), (result["day"] = dayArr);

    return result;
  }

  dynamicDateSearch(startDate?: string, endDate?: string) {
    const today = new Date();
    const minDate = subDays(today, 2);
    let start = -2;
    let selected = 2;

    if (startDate === "" || startDate === undefined) {
      startDate = this.currentDateNew();
    }

    if (endDate === "" || endDate === undefined) {
      endDate = startDate;
    }

    if (!isToday(parseISO(startDate))) {
      const startDateFromMinDate = differenceInHours(
        parseISO(startDate).setHours(0),
        minDate.setHours(0)
      );

      if (startDateFromMinDate === 23) {
        start = -1;
        selected = 1;
      }

      if (startDateFromMinDate === 0) {
        start = 0;
        selected = 0;
      }
    }

    let end = 2;
    let dateArr = [];
    let dayArr = [];
    let result = {};

    for (var i = start; i <= end; i++) {
      let date = this.formatDate(
        new Date(parseISO(startDate).setDate(new Date(startDate).getDate() + i))
      );
      let day = this.getDay(
        new Date(parseISO(startDate).setDate(new Date(startDate).getDate() + i))
      );
      // result.push({ date, day })
      console.log("day-date");
      console.log(dateArr);
      dateArr.push(date);
      dayArr.push(day);
    }

    (result["date"] = dateArr),
      (result["day"] = dayArr),
      (result["selected"] = selected);

    return result;
  }

  getCode(code) {
    let carrierCode = "carrierCode-" + code.toLowerCase();
    return carrierCode;
  }

  /**
   *
   * @param invalidDateString
   * date string formatted as "13/10/2022"
   */
  convertToValidDate(invalidDateString: string): string {
    // console.log("## convertToValidDate:", invalidDateString);
    const fDate = invalidDateString.split("/");
    const validDate = `${fDate[2]}-${fDate[1]}-${fDate[0]}`;

    if (!isValid(new Date(validDate)))
      console.warn("## (convertToValidDate) Invalid Date input");

    // console.log("## converted date", validDate);
    return isValid(new Date(validDate)) ? validDate : invalidDateString;
  }
}
