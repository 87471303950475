import { Pipe, PipeTransform } from '@angular/core';
import { NewLocaleService } from '../_services/locale_new.service';
import { Router, NavigationStart, NavigationEnd, NavigationError, NavigationCancel, RoutesRecognized } from '@angular/router';
import { filter } from 'rxjs/operators';
import { LocalJson }  from '../_locale/locale-en-GB'

@Pipe({
  name: 'locale',
  pure: false
})
export class LocalePipe implements PipeTransform {

  locale: Object;

  //TODO newObj ==> GIVE SOME GENERIC NAME
  newObj: Object = {}

  constructor(private newLocaleService: NewLocaleService,
  private localJson: LocalJson) {

    this.newObj = localJson.localJson()
    
    newLocaleService.localeInfo$.subscribe(
      (value) => {
        this.newObj = value;
      }
    )
  }

  transform(key: any, ...params: Array<any>): any {

    let [obj, objkey] = key.split('-');
    let object = this.newObj[obj];
    let text = object ? object[objkey] : '';
    if (params && params.length) {

      params.forEach((val, i) => {
        text = text.split('{x}').join(val)
      })

      return text;
    }




    return text || '-'

    // return this.localeService.fetchLocaleValue((localeCode ? localeCode : 'en-GB'), key, params);
  }

}
