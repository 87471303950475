import { NgModule } from '@angular/core';
import { Routes, RouterModule, ActivatedRoute } from '@angular/router';
import { FlightSearchComponent } from '../aa-flight-search/aa-flight-search.component';
import { APP_BASE_HREF } from '@angular/common';
import { UnderMaintenanceComponent } from "../aa-under-maintenance/aa-under-maintenance.component";

const routes: Routes = [
  {
    path: "flightstatus/:lang1/:lang2",
    component: FlightSearchComponent,
    // component: UnderMaintenanceComponent,
  },
  {
    path: "flightstatus",
    component: FlightSearchComponent,
    // component: UnderMaintenanceComponent,
  },
  {
    path: "",
    redirectTo: "/flightstatus",
    pathMatch: "full",
  },
  {
    path: "**",
    redirectTo: "/flightstatus",
    pathMatch: "full",
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
  providers: [{ provide: APP_BASE_HREF, useValue: '/' }]
})
export class FlightStatusModule { }
