import {
  Component,
  OnInit,
  ViewChild,
  ElementRef,
  OnChanges,
  AfterViewInit,
  SimpleChanges,
} from "@angular/core";
import { FormControl } from "@angular/forms";

import { from, of, Observable, Subscription } from "rxjs";
import { startWith, map, switchMap } from "rxjs/operators";
import { ControllerService } from "../_services/controller.service";
import { LookupService } from "../_services/lookup.service";
import { ɵc as UserdataService } from "aa-universal-header-footer";

import { AutoComplete } from "../shared/aa-auto-complete/aa-auto-complete.component";

import { environment } from "../../environments/environment";
// import FeedbackEmoji from "@airasia/phoenix-widgets/widgets/FeedbackEmoji";
import { format } from "date-fns";
import { Utilities } from "../_utilities/utilities";
import { MatDatepicker } from "@angular/material";
import * as moment from "moment";
import * as _ from "lodash";
import { WindowReferenceService } from "../_services/window-reference.service";
@Component({
  selector: "aa-route-search",
  templateUrl: "./aa-route-search.component.html",
  styleUrls: ["./aa-route-search.component.scss"],
})
export class AaRouteSearchComponent
  implements OnInit, OnChanges, AfterViewInit
{
  originCtrl: FormControl;
  destCtrl: FormControl;
  origiStations$: Observable<any[]>;
  selectedOrigin: string = "";
  selectedDest: string = "";
  public startDate: string = "";
  public endDate: string = "";
  public originLabel: string = "";
  public destLabel: string = "";
  public minDate: string = "";
  public maxDate: string = "";
  destinationStations$: Observable<any[]>;
  allStations: any;
  public loadingStatus: any = {};
  @ViewChild("destInput", { static: false }) destRef: ElementRef;
  @ViewChild("destination", { static: false }) destPanle: any;
  isValidOrigin: boolean = false;
  isValidDest: boolean = false;
  isSeachClicked: boolean = false;
  isOriginFocus: boolean = false;
  isDestFocus: boolean = false;
  allSub: Subscription[] = [];
  panelWidth: string = "0px";
  scrollCounter: number = 0;
  scrollHeight: any = 0;
  itemcounter: any = 0;
  defaultpost: any;
  container: any;
  isMobileSize: boolean = false;
  private widgetData: any = {};
  private hasViewLoaded = false;

  @ViewChild("origin", { static: false }) originautocomplete: AutoComplete;
  @ViewChild("destination", { static: false }) destautocomplete: AutoComplete;

  @ViewChild("originInput", { static: false }) originRef: ElementRef;
  // @ViewChild("destInput", { static: false }) destInput: ElementRef;

  @ViewChild("PickerFromInput", { static: true })
  datePickerFromRef: ElementRef;
  // @ViewChild("PickerToInput", { static: true }) datePickerToRef: ElementRef;
  @ViewChild("pickerFrom", { static: true }) pickerFrom: MatDatepicker<any>;
  // @ViewChild("pickerTo", { static: true }) pickerTo: MatDatepicker<any>;

  constructor(
    private controllerService: ControllerService,
    private lookupService: LookupService,
    private userdataService: UserdataService,
    private _windowRef: WindowReferenceService
  ) {
    this.originCtrl = new FormControl();
    this.destCtrl = new FormControl();

    this.origiStations$ = this.controllerService.originStationsList$.pipe(
      switchMap((stations: any[]) =>
        this.originCtrl.valueChanges.pipe(
          startWith(""),
          map((state) =>
            state ? this.filterStations(stations, state) : stations.slice()
          )
        )
      )
    );

    if (!environment.production) {
      this.minDate = format(
        new Date(new Date().getFullYear(), new Date().getMonth() - 3, 1),
        "yyyy-MM-dd"
      );
    } else {
      this.minDate = format(
        new Date(new Date().setDate(new Date().getDate() - 2)),
        "yyyy-MM-dd"
      );
    }

    this.maxDate = format(
      new Date(new Date().setDate(new Date().getDate() + 14)),
      "yyyy-MM-dd"
    );

    this.destinationStations$ = of([]);
  }

  setFocus(target, value, post) {
    // if(this[target])
    this[target] = value;
    if (!value) {
      this.resetKeyPressValue(0, 0, 0, post);
    }
    if (this.defaultpost != post) {
      this.resetKeyPressValue(0, 0, 0, post);
    }
  }

  search() {
    this.isSeachClicked = true;
    if (!this.isValidOrigin || !this.isValidDest) return;
    if (!this.originCtrl.value || !this.destCtrl.value) return;
    this.isSeachClicked = false;

    this._windowRef.pushEventsToGTMOnFindRoutes(
      "Search by route",
      this.selectedOrigin,
      this.selectedDest
    );
    this.controllerService.searchFlights({
      searchByRoute: "true",
      data: {
        origin: this.selectedOrigin,
        dest: this.selectedDest,
        startDate: this.startDate,
      },
    });
  }

  setOriginStation(option) {
    // console.log("___ ", option);
    let station = option.data;
    this.isValidOrigin = true;
    this.selectedOrigin = station["StationCode"];
    // console.log('matches...',matches[1])
    // console.log(this.originCtrl,'this.....')
    // this.validatation = {origin:!this.selectedOrigin,dest:!this.selectedDest}
    // console.log(this.selectedOrigin,'Origin')
    this.destinationStations$ = this.lookupService
      .getDestinationDetails(this.selectedOrigin)
      .pipe(
        switchMap((destList) =>
          this.controllerService.originStationsList$.pipe(
            map((stationList) => {
              this.destCtrl.setValue("");
              return this.extractDestinationsFromStations(
                stationList,
                destList,
                "StationCode"
              );
            })
          )
        )
      )
      .pipe(
        switchMap((destList: any[]) =>
          this.destCtrl.valueChanges.pipe(
            startWith(""),
            map((state) =>
              state ? this.filterStations(destList, state) : destList.slice()
            ),
            map((state) => {
              // this.destRef.nativeElement.focus();
              return state;
            })
          )
        )
      );
  }

  setDestinationStation(option) {
    let station = option.data;
    this.selectedDest = station["StationCode"];
    this.isValidDest = true;
  }

  setStartDate(value) {
    if (_.isDate(value)) {
      this.startDate = format(value, "yyyy-MM-dd");
    } else {
      this.startDate = format(moment().toDate(), "yyyy-MM-dd");
    }
    this.datePickerFromRef.nativeElement.value = moment(
      this.startDate,
      "YYYY-MM-DD"
    ).format("DD/MM/YYYY");
    // this.setFocus("startDateFocus", false);
  }

  setEndDate(value) {
    this.endDate = format(value, "yyyy-MM-dd");
    // this.setFocus("endDateFocus", false);
  }

  stationDisplay(station) {
    return station.StationName + " (" + station.StationCode + ")";
  }

  filterStations(source, name: string) {
    return source.filter(
      (stationData) =>
        ~(stationData.StationName + " (" + stationData.StationCode + ")")
          .toLowerCase()
          .indexOf(name.toLowerCase())
    );
  }

  extractDestinationsFromStations(
    allStations: any[],
    destinationStations: any,
    key: string
  ): any[] {
    if (
      !allStations ||
      allStations.length === 0 ||
      !destinationStations ||
      !key
    ) {
      return;
    }

    let obj;
    let extractedDestinations: any[] = [];

    // let directFlights = destinationStations[0];
    let directFlights = Object.assign(
      {},
      ...Object.values(destinationStations)
    );

    for (let destination in directFlights) {
      if (!destination) {
        return;
      }
      // console.log(destination);

      obj = allStations.find((allStation) => allStation[key] === destination);
      if (obj) {
        extractedDestinations.push(obj);
      }
    }
    // }

    return extractedDestinations;
  }

  ngOnInit() {
    this.hasViewLoaded = true;
    this.isMobileSize = Utilities.isMobileSize();
    this.controllerService.clearSearchFlights();

    const initialJourneyDate = moment().format("DD/MM/YYYY");
    this.datePickerFromRef.nativeElement.value = initialJourneyDate;

    let temp = document.getElementsByClassName(
      "auto-container"
    ) as HTMLCollectionOf<HTMLElement>;
    if (temp && temp[0]) this.panelWidth = temp[0].offsetWidth + "px";
    // console.log('width',this.panelWidth)
    // this.controllerService.originStationsList$.subscribe((stations) =>{
    //   this.allStations = stations;
    // })
    let routeSub = this.userdataService.$localeChange.subscribe(() => {
      // console.log(this.selectedDest,this.selectedOrigin,'Yooooo,...')
      this.controllerService
        .getCityName(this.selectedOrigin)
        .subscribe((val) => {
          this.originCtrl.setValue(val + " (" + this.selectedOrigin + ")");
          this.controllerService
            .getCityName(this.selectedDest)
            .subscribe((val) => {
              this.destCtrl.setValue(val + " (" + this.selectedDest + ")");
              this.destRef.nativeElement.blur();
              this.destPanle.last.closePanel &&
                this.destPanle.last.closePanel();
            });
        });
    });
    this.allSub.push(routeSub);

    let loadingSub = this.controllerService
      .getLoadingStatus()
      .subscribe((value) => {
        // console.log(value)
        this.loadingStatus = value;
      });

    this.allSub.push(loadingSub);

    let clearSearchSub = this.controllerService.clearSearch$.subscribe(
      (_userInput) => {
        this.clearData();
      }
    );

    this.allSub.push(clearSearchSub);
  }

  clearData() {
    // this.datePickerFromRef.nativeElement.value = "";
    // this.datePickerToRef.nativeElement.value = "";
    this.startDate = "";
    this.endDate = "";
    this.pickerFrom.select(undefined);
    // this.pickerTo.select(undefined);
  }

  ngOnDestroy() {
    for (let sub of this.allSub) {
      if (sub) sub.unsubscribe();
    }
  }

  onkeypress(event: KeyboardEvent, post) {
    if (post !== this.defaultpost) {
      this.resetKeyPressValue(0, 0, 0, post);
    }

    let itemheight;
    this.container = document.getElementsByClassName("dropdown-container");

    let itemlength = document.getElementsByClassName("items")[0]
      ? document.getElementsByClassName("items")[0].children.length
      : 0;

    let lastitem = document.getElementsByClassName("items")[0]
      ? document.getElementsByClassName("items")[0].children[itemlength - 1]
      : null;

    if (event.code === "ArrowDown") {
      if (this.itemcounter >= 6 && this.scrollCounter >= 6) {
        itemheight = this.getActiveDescendentHeight(post);
        this.scrollHeight =
          parseFloat(this.scrollHeight) + parseFloat(itemheight);
      }
      if (this.scrollCounter >= 6) {
        this.container[parseInt(post)].scrollTop = parseFloat(
          this.scrollHeight
        );
      } else {
        this.scrollCounter = this.scrollCounter + 1;
      }
      this.itemcounter += 1;
      if (this.itemcounter > itemlength) {
        this.scrollCounter = 1;
        this.itemcounter = 1;
        this.scrollHeight = 0;
        this.container[parseInt(post)].scrollTop = 0;
      }
      //console.log('down',this.scrollCounter, this.scrollHeight, this.itemcounter, itemlength);
    } else if (event.code === "ArrowUp") {
      if (this.scrollCounter > 0) {
        this.scrollCounter -= 1;
      }
      if (this.scrollCounter <= 0) {
        itemheight = this.getActiveDescendentHeight(post);
        this.scrollHeight =
          parseFloat(this.scrollHeight) - parseFloat(itemheight);
        this.container[parseInt(post)].scrollTop = parseFloat(
          this.scrollHeight
        );
        this.scrollCounter += 1;
      }
      this.itemcounter -= 1;
      if (this.itemcounter == 0) {
        let lastitemid = lastitem.id;
        let lastitemscrolltop = document.getElementById(lastitemid).offsetTop;
        this.scrollHeight = lastitemscrolltop - itemheight * 5;
        this.container[parseInt(post)].scrollTop = lastitemscrolltop;
        this.scrollCounter = 6;
        this.itemcounter = itemlength;
      }
      //console.log('up',this.scrollCounter, this.scrollHeight, this.itemcounter, itemlength);
    } else {
      this.resetKeyPressValue(0, 0, 0, post);
    }
  }

  resetKeyPressValue(sc, sh, ic, post) {
    this.scrollCounter = sc;
    this.scrollHeight = sh;
    this.itemcounter = ic;
    this.defaultpost = post;
    if (this.container) {
      this.container[parseInt(post)].scrollTop = 0;
    }

    if (post === "0") {
      this.originautocomplete._keyManager.setActiveItem(undefined);
      this.originautocomplete._keyManager.updateActiveItemIndex(-1);
    } else if (post === "1") {
      this.destautocomplete._keyManager.setActiveItem(undefined);
      this.destautocomplete._keyManager.updateActiveItemIndex(-1);
    }
  }

  getActiveDescendentHeight(post) {
    let activeDescendantId;
    let descHeight;
    if (post === "0") {
      activeDescendantId = document.getElementById("origin-autocomplete")
        .attributes[12].value;
    } else if (post === "1") {
      activeDescendantId =
        document.getElementById("dest-autocomplete").attributes[12].value;
    }
    descHeight = document
      .getElementById(activeDescendantId)
      .getBoundingClientRect().height;
    return descHeight;
  }

  switchOrigDest() {
    const selectedOrig = this.selectedOrigin;
    const selectedDest = this.selectedDest;
    const selectedOrigLabel = this.originRef.nativeElement.value;
    const selectedDestLabel = this.destRef.nativeElement.value;

    this.selectedOrigin = selectedDest;
    this.selectedDest = selectedOrig;

    this.originCtrl.setValue(selectedDestLabel);
    this.destCtrl.setValue(selectedOrigLabel);

    this.destRef.nativeElement.blur();
    this.isValidDest = true;
  }

  buildFlightSearchProps() {
    this.widgetData = {
      theme: "AIRASIA_CLASSIC",
      geoid: "MY",
      environment: ["SIT", "PROD"].includes(environment.uhfEnv)
        ? "production"
        : "staging",
      data: {
        ENDPOINT_CONFIG: [
          {
            data: [
              {
                endpointID: "origin",
                endpointUrl: "{static_url}/station/v2/{locale}/file.json",
                id: "origin",
              },
              {
                endpointID: "destination",
                endpointUrl: "{static_url}/v2/destination/{iata}/file.json",
                id: "destination",
              },
              {
                endpointID: "oneway",
                endpointUrl: "",
                id: "datepicker",
              },
              {
                endpointID: "return",
                endpointUrl: "",
                id: "datepicker",
              },
              {
                endpointID: "dateFormat",
                endpointUrl: "{gcs_url}/dataformat/date/{locale}/file.json",
                id: "dateFormat",
              },
              {
                endpointID: "multiCurrency",
                endpointUrl: "{kong_url}/multicurrency/{currency}",
                id: "multiCurrency",
              },
              {
                endpointID: "nearestAirport",
                endpointUrl:
                  "https://t.airasia.com/nearestairport/airport/user",
                id: "nearestAirport",
              },
              {
                endpointID: "featureFlag",
                endpointUrl:
                  "{static_url}/cosmos/featureflags/hercules/{feature_flag_env}/file.json",
                id: "featureFlag",
              },
              {
                endpointID: "searchDeepLink",
                endpointUrl: "{kong_url}/deeplink/v1/encryptdeeplink",
                id: "searchDeepLink",
              },
              {
                endpointID: "dataVisor",
                endpointUrl: "{kong_url}/bizsecrisk",
                id: "dataVisor",
              },
              {
                endpointID: "baseCurrency",
                endpointUrl: "{kong_url}/basecurrency/{IATA}",
                id: "baseCurrency",
              },
            ],
          },
        ],
        aaiDisclaimer: [
          {
            data: [
              {
                cnDisclaimerLine1:
                  "Just a moment. We’re redirecting you to airasia.co.in in {3} seconds.",
                cnDisclaimerLine2: " ",
                cnDisclaimerURL: "airasia.co.in",
                countDownTimer: "5",
                newTab: "TRUE",
                productID: "flights",
                redirectCompanyName: "AirAsiaIndia",
                redirectCompanyURL: "airasia.co.in",
                redirectionUrl: "",
              },
            ],
          },
        ],
        badges: [
          {
            data: [
              {
                icon: "locationIcon",
                label: "City",
                type: "city",
              },
              {
                icon: "airplaneIcon",
                label: "Airport",
                type: "airport",
              },
              {
                icon: "hoteIcon",
                label: "Hotel",
                type: "hotel",
              },
              {
                icon: "golfIcon",
                label: "Landmark",
                type: "landmark",
              },
            ],
          },
        ],
        chinaDisclaimer: [
          {
            data: [
              {
                cnDisclaimerLine1:
                  "请稍候，您将于 {3} 秒后由 {0} 网站跳转至中国境外网站 {1}。",
                cnDisclaimerLine2:
                  "您的交易是直接与中国境外网站 {1} 的运营主体 {2} 构成合同关系，与亚航商务服务(广州)有限公司无关。",
                cnDisclaimerURL: "airasia.cn",
                countDownTimer: "5",
                newTab: "TRUE",
                productID: "flights",
                redirectCompanyName: "AirAsiaCom Travel Sdn Bhd",
                redirectCompanyURL: "airasia.com",
                redirectionUrl:
                  "https://china.airasiago.com/lp/dailydeals?langid=2052&utm_source=airasia_web&utm_medium=product_tile&utm_campaign=SAH&utm_content=zh-CN",
              },
            ],
          },
        ],
        components: [
          {
            data0: [
              {
                iconName: "",
                id: "heroBanner",
                label1: "",
                label2: "",
                placeholderText: "",
                redirectUrl: "www.airasia.com",
                showInDesktop: "FALSE",
                showInMobile: "TRUE",
                type: "heroBanner",
              },
              {
                iconName: "",
                id: "trip_type",
                label1: "",
                label2: "",
                placeholderText: "",
                redirectUrl: "",
                showInDesktop: "TRUE",
                showInMobile: "TRUE",
                type: "trip_type",
              },
              {
                iconName: "",
                id: "passenger_counter",
                label1: "",
                label2: "",
                placeholderText: "",
                redirectUrl: "",
                showInDesktop: "TRUE",
                showInMobile: "FALSE",
                type: "passenger_counter",
              },
              {
                iconName: "",
                id: "promoCode",
                label1: "",
                label2: "",
                placeholderText: "Promo code",
                redirectUrl: "",
                showInDesktop: "TRUE",
                showInMobile: "FALSE",
                type: "promoCode",
              },
              {
                iconName: "",
                id: "big_points",
                label1: "",
                label2: "",
                placeholderText: "",
                redirectUrl: "",
                showInDesktop: "TRUE",
                showInMobile: "FALSE",
                type: "checkbox",
              },
              {
                iconName: "",
                id: "snapCheckbox",
                label1: "SNAP (Flights + Hotel)",
                label2: "Check out the savings you get when adding a hotel",
                placeholderText: "",
                redirectUrl: "",
                showInDesktop: "TRUE",
                showInMobile: "FALSE",
                type: "snapCheckbox",
              },
            ],
          },
          {
            data1: [
              {
                iconName: "locationIcon",
                id: "originDestination",
                label1: "Flying from",
                label2: "Going to",
                placeholderText: 'Try "{0}"',
                redirectUrl: "",
                showInDesktop: "TRUE",
                showInMobile: "TRUE",
                type: "placepicker",
              },
              {
                iconName: "calendarIcon",
                id: "datepicker",
                label1: "Depart",
                label2: "Return",
                placeholderText: "",
                redirectUrl: "",
                showInDesktop: "TRUE",
                showInMobile: "TRUE",
                type: "datepicker",
              },
              {
                iconName: "",
                id: "passenger_counter",
                label1: "",
                label2: "",
                placeholderText: "",
                redirectUrl: "",
                showInDesktop: "FALSE",
                showInMobile: "TRUE",
                type: "passenger_counter",
              },
              {
                iconName: "",
                id: "promoCode",
                label1: "",
                label2: "",
                placeholderText: "Enter Promocode",
                redirectUrl: "",
                showInDesktop: "FALSE",
                showInMobile: "TRUE",
                type: "promoCode",
              },
              {
                iconName: "",
                id: "big_points",
                label1: "Redeem BIG Points",
                label2: "",
                placeholderText: "",
                redirectUrl: "",
                showInDesktop: "FALSE",
                showInMobile: "TRUE",
                type: "checkbox",
              },
              {
                iconName: "",
                id: "search_button",
                label1: "Search",
                label2: "",
                placeholderText: "",
                redirectUrl: "",
                showInDesktop: "TRUE",
                showInMobile: "TRUE",
                type: "button",
              },
            ],
          },
        ],
        gtmConfig: [
          {
            data: [
              {
                event: "trackEventNoEcommerce",
                eventActionClick: "click",
                eventActionView: "view",
                eventCategory: "",
                eventLabelCardClick: "",
                eventLabelHeadlineClick: "",
              },
            ],
          },
        ],
        passengerConfig: [
          {
            data: [
              {
                max: "9",
                min: "1",
              },
            ],
          },
          {
            adult: [
              {
                max: "",
                min: "1",
              },
            ],
          },
          {
            child: [
              {
                max: "",
                min: "0",
              },
            ],
          },
          {
            infant: [
              {
                max: "adult",
                min: "0",
              },
            ],
          },
        ],
        placepickerConfig: [
          {
            cookie: [
              {
                recentSearchCookie: "flightRecentSearch",
              },
            ],
          },
          {
            recommendedStations: [
              {
                stationName: "Singapore",
              },
              {
                stationName: "Kuala Lumpur",
              },
              {
                stationName: "India",
              },
              {
                stationName: "Europe",
              },
            ],
          },
        ],
        translations: [
          {
            data: [
              {
                adultAgeLimit: "18 years and above",
                adultPlural: "Adults",
                adultSingle: "Adult",
                bigLoyaltyToggleLabel: "Redeem BIG Points",
                calendarResetButton: "Reset",
                calendarSubmitButton: "Confirm",
                childAgeLimit: "2 to 17 years",
                childPlural: "Children",
                childSingle: "Child",
                guestPlural: "{{count}} Guests",
                guestSingle: "{{count}} Guest",
                heroBannerUrl:
                  "https://a.staticaa.com/images/web-apps/component/flight-search/Generic-Flight-Mobile-Web-Banner.png",
                infantAgeLimit: "Below 2 years",
                infantPlural: "Infants",
                infantSingle: "Infant",
                loadOptions: "Options are loading",
                lowestFareLabel: "Lowest all-in fares in {0} for 1 adult",
                nearestAirportLabel: "Nearest airport",
                noFareLabel: "Unable to display the lowest fares",
                noResultLabel: "There is no result for {0}",
                passengerModalHeaderLabel: "Select guests and rooms",
                paxSelectorLabel: "Guests and travel class",
                recentSearchLabel: "Recent searches",
                roomAddButton: "Add more rooms",
                roomPlural: "Rooms",
                roomRemoveButton: "Remove",
                roomSingle: "Room",
                roomSubmitButton: "Done",
                roomTitle: "Room {0}",
                snapCheckboxLabel:
                  "Check out the savings you get when adding a hotel",
                snapCheckboxTitle: "SNAP (Flights + Hotel)",
                tripTypeMobileHeaderLabel: "Journey Type",
              },
            ],
          },
        ],
        tripTypeConfig: [
          {
            data: [
              {
                code: "R",
                label: "Return",
                selectionType: "select",
                url: "",
              },
              {
                code: "O",
                label: "One way",
                selectionType: "select",
                url: "",
              },
              {
                code: "M",
                label: "Multi-city",
                selectionType: "redirect",
                url: "https://flybeyond.airasia.com/en/landing/multicity",
              },
            ],
          },
        ],
      },
      autoViewportOnLoad: true,
      enableHotjar: true,
      showBorder: true,
      locale: "en-gb",
    };
    // this.renderComponent();
  }

  ngOnChanges(changes: SimpleChanges) {
    // this.renderComponent();
  }

  ngAfterViewInit() {
    // this.renderComponent();
  }

  // private renderComponent() {
  //   try {
  //     ReactDOM.render(
  //       React.createElement(FeedbackEmoji, {
  //         // title: "feedback",
  //         buttonTitle: "Feedback",
  //         config: {
  //           userID: "73y89u892u83u98298",
  //           userEmail: "newskies555@yahoo.com",
  //           product: "homepage",
  //           spacesKey: "",
  //           spacesToken: "",
  //           cultureCode: "en-gb",
  //           additionalInfo: {
  //             userId: "test",
  //             username: "bot",
  //           },
  //           environment: "STAGING",
  //           theme: "AIRASIA_CLASSIC",
  //         },
  //       }),
  //       document.getElementById("feedback-phoenix")
  //     );
  //   } catch (renderError) {
  //     console.log("RENDER Error", renderError);
  //     // do nothing
  //   }
  // }
}
