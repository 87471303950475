import {
  AfterContentInit,
  Component,
  ContentChildren,
  ElementRef,
  Input,
  QueryList,
  TemplateRef,
  ViewChild,
  ViewEncapsulation,
  ChangeDetectorRef,
  ChangeDetectionStrategy,
  EventEmitter,
  Output,
  InjectionToken,
  Inject,
} from '@angular/core';

import {ActiveDescendantKeyManager} from '@angular/cdk/a11y';

import {OptionComponent} from './option/option.component'

let _uniqueAutocompleteIdCounter = 0;


@Component({
  selector: "aa-auto-complete",
  templateUrl: "./aa-auto-complete.component.html",
  styleUrls: ["./aa-auto-complete.component.scss"],
})
export class AutoComplete {
  /** Manages active item in option list based on key events. */
  _keyManager: ActiveDescendantKeyManager<OptionComponent>;

  get isOpen(): boolean {
    return this._isOpen;
  }
  _isOpen: boolean = false;

  /** Element for the panel containing the autocomplete options. */
  @ViewChild("panel", { static: false }) panel: ElementRef;
  @ViewChild("content", { static: false }) content: ElementRef;
  @ContentChildren(OptionComponent, { descendants: true })
  options: QueryList<OptionComponent>;
  /** Event that is emitted whenever an option from the list is selected. */
  @Output() optionSelected: EventEmitter<OptionComponent> =
    new EventEmitter<OptionComponent>();

  /** Event that is emitted when the autocomplete panel is opened. */
  @Output() readonly opened: EventEmitter<void> = new EventEmitter<void>();

  /** Event that is emitted when the autocomplete panel is closed. */
  @Output() readonly closed: EventEmitter<void> = new EventEmitter<void>();

  /** Unique ID to be used by autocomplete trigger's "aria-owns" property. */
  id: string = `aa-autocomplete-${_uniqueAutocompleteIdCounter++}`;

  /** Function that maps an option's control value to its display value in the trigger. */
  @Input() displayWith: ((value: any) => string) | null = null;

  constructor() {}

  ngAfterContentInit() {
    this._keyManager = new ActiveDescendantKeyManager<OptionComponent>(
      this.options
    ).withWrap();
    // Set the initial visibility state.
    this._setVisibility(false);
  }

  /**
   * Sets the panel scrollTop. This allows us to manually scroll to display options
   * above or below the fold, as they are not actually being focused when active.
   */
  // _setScrollTop(scrollTop: number): void {
  //   if (this.panel) {
  //     this.panel.nativeElement.scrollTop = scrollTop;
  //   }
  // }
  //
  // /** Returns the panel's scrollTop. */
  // _getScrollTop(): number {
  //   return this.panel ? this.panel.nativeElement.scrollTop : 0;
  // }

  /** Panel should hide itself when the option list is empty. */
  _setVisibility(value: boolean) {
    this._isOpen = value;
    //console.log('this ..',this._isOpen)
    // this.showPanel = !!this.options.length;
    // this._classList['mat-autocomplete-visible'] = this.showPanel;
    // this._classList['mat-autocomplete-hidden'] = !this.showPanel;
    // this._changeDetectorRef.markForCheck();
  }

  /** Emits the `select` event. */
  _emitSelectEvent(option: OptionComponent): void {
    // const event = new MatAutocompleteSelectedEvent(this, option);
    // console.log("hey.....", option);
    this.optionSelected.emit(option);
  }
}
