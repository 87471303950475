import * as moment from "moment";
import { TERMINAL_CHANGE } from "./constant-variables";

export type AIRPORT_TYPE = {
  Country: string;
  CityName: string;
  IATACode: string;
  AirportName: string;
  DepartureTerminal: string;
  ArrivalTerminal: string;
  NewDepartureTerminal?: string;
};

let updatedTerminal = "Terminal 2";
let newTerminal = "Terminal 1";
let oldTerminal = "Terminal 2";
let targetDate = moment(TERMINAL_CHANGE.CAN.DATE, TERMINAL_CHANGE.CAN.FORMAT);
// targetDate = moment("Jun 15, 2023");
console.log(targetDate);
const currentDate = moment(new Date());

updatedTerminal = currentDate.isAfter(targetDate) ? newTerminal : oldTerminal;

export const AIRPORT_DETAILS: AIRPORT_TYPE[] = [
  {
    Country: "Australia",
    CityName: "Melbourne",
    IATACode: "MEL",
    AirportName: "Melbourne Tullamarine Airport",
    DepartureTerminal: "International Terminal 2",
    ArrivalTerminal: "International Terminal 2",
  },
  {
    Country: "Australia",
    CityName: "Gold Coast",
    IATACode: "OOL",
    AirportName: "Gold Coast Airport",
    DepartureTerminal: "Main Terminal",
    ArrivalTerminal: "Main Terminal",
  },
  {
    Country: "Australia",
    CityName: "Perth",
    IATACode: "PER",
    AirportName: "Perth International Airport",
    DepartureTerminal: "Terminal 1",
    ArrivalTerminal: "Terminal 1",
  },
  {
    Country: "Australia",
    CityName: "Sydney",
    IATACode: "SYD",
    AirportName: "Sydney International Airport",
    DepartureTerminal: "Terminal 1",
    ArrivalTerminal: "Terminal 1",
  },
  {
    Country: "Bangladesh",
    CityName: "Dhaka",
    IATACode: "DAC",
    AirportName: "Hazrat Shahjalal International Airport",
    DepartureTerminal: "International Terminal",
    ArrivalTerminal: "International Terminal",
  },
  {
    Country: "Brunei",
    CityName: "Bandar Seri Begawan",
    IATACode: "BWN",
    AirportName: "Brunei International Airport",
    DepartureTerminal: "Main Terminal",
    ArrivalTerminal: "Main Terminal",
  },
  {
    Country: "Cambodia",
    CityName: "Phnom Penh",
    IATACode: "PNH",
    AirportName: "Phnom Penh International Airport",
    DepartureTerminal: "Main Terminal",
    ArrivalTerminal: "Main Terminal",
  },
  {
    Country: "Cambodia",
    CityName: "Siem Reap",
    IATACode: "REP",
    AirportName: "Siem Reap International Airport",
    DepartureTerminal: "Terminal 1",
    ArrivalTerminal: "Terminal 1",
  },
  {
    Country: "Cambodia",
    CityName: "Sihanouk",
    IATACode: "KOS",
    AirportName: "Sihanouk International Airport",
    DepartureTerminal: "Main Terminal",
    ArrivalTerminal: "Main Terminal",
  },
  {
    Country: "China",
    CityName: "Beijing",
    IATACode: "PKX",
    AirportName: "Beijing Daxing International Airport ",
    DepartureTerminal: "Main Terminal",
    ArrivalTerminal: "Main Terminal",
  },
  {
    Country: "China",
    CityName: "Chang Sha",
    IATACode: "CSX",
    AirportName: "Changsha Huanghua International Airport",
    DepartureTerminal: "Terminal 2",
    ArrivalTerminal: "Terminal 2",
  },
  {
    Country: "China",
    CityName: "Cheng Du",
    IATACode: "TFU",
    AirportName: "Chengdu TianFu International Airport",
    DepartureTerminal: "Terminal 1",
    ArrivalTerminal: "Terminal 1",
  },
  {
    Country: "China",
    CityName: "Chongqing",
    IATACode: "CKG",
    AirportName: "Chongqing Jiangbei International Airport",
    DepartureTerminal: "Terminal 3",
    ArrivalTerminal: "Terminal 3",
  },
  {
    Country: "China",
    CityName: "Guangzhou",
    IATACode: "CAN",
    AirportName: "Guangzhou Baiyun International Airport",
    DepartureTerminal: updatedTerminal,
    ArrivalTerminal: updatedTerminal,
    NewDepartureTerminal: newTerminal,
  },
  {
    Country: "China",
    CityName: "Guilin",
    IATACode: "KWL",
    AirportName: "Guilin Liangjiang International Airport",
    DepartureTerminal: "Terminal 2",
    ArrivalTerminal: "Terminal 2",
  },
  {
    Country: "China",
    CityName: "Hangzhou",
    IATACode: "HGH",
    AirportName: "Hangzhou Xiaoshan International Airport",
    DepartureTerminal: "Terminal 4",
    ArrivalTerminal: "Terminal 4",
  },
  {
    Country: "China",
    CityName: "Kunming",
    IATACode: "KMG",
    AirportName: "Kunming Changshui International Airport",
    DepartureTerminal: "Main Terminal",
    ArrivalTerminal: "Main Terminal",
  },
  {
    Country: "China",
    CityName: "Nan Chang",
    IATACode: "KHN",
    AirportName: "Chang Bei International Airport",
    DepartureTerminal: "Terminal 1",
    ArrivalTerminal: "Terminal 1",
  },
  {
    Country: "China",
    CityName: "Nanjing",
    IATACode: "NKG",
    AirportName: "Nanjing Lukou International Airport",
    DepartureTerminal: "Terminal 2",
    ArrivalTerminal: "Terminal 2",
  },
  {
    Country: "China",
    CityName: "Nanning",
    IATACode: "NNG",
    AirportName: "Nanning Wuxu International Airport",
    DepartureTerminal: "Terminal 2",
    ArrivalTerminal: "Terminal 2",
  },
  {
    Country: "China",
    CityName: "Ningbo",
    IATACode: "NGB",
    AirportName: "Ningbo Lishe International Airport",
    DepartureTerminal: "Terminal 2",
    ArrivalTerminal: "Terminal 2",
  },
  {
    Country: "China",
    CityName: "Quanzhou",
    IATACode: "JJN",
    AirportName: "Quanzhou Jinjiang International Airport",
    DepartureTerminal: "International Terminal",
    ArrivalTerminal: "International Terminal",
  },
  {
    Country: "China",
    CityName: "Shanghai",
    IATACode: "PVG",
    AirportName: "Shanghai Pudong International Airport",
    DepartureTerminal: "Terminal 2",
    ArrivalTerminal: "Terminal 2",
  },
  {
    Country: "China",
    CityName: "Shantou",
    IATACode: "SWA",
    AirportName: "Jieyang Chaoshan International Airport",
    DepartureTerminal: "Main Terminal",
    ArrivalTerminal: "Main Terminal",
  },
  {
    Country: "China",
    CityName: "Shenzhen",
    IATACode: "SZX",
    AirportName: "Shenzhen Bao'an International Airport",
    DepartureTerminal: "Terminal 3",
    ArrivalTerminal: "Terminal 3",
  },
  {
    Country: "China",
    CityName: "Wuhan",
    IATACode: "WUH",
    AirportName: "Wuhan Tianhe International Airport",
    DepartureTerminal: "Terminal 3",
    ArrivalTerminal: "Terminal 3",
  },
  {
    Country: "China",
    CityName: "Xi'an",
    IATACode: "XIY",
    AirportName: "Xi'an Xianyang International Airport",
    DepartureTerminal: "Terminal 3",
    ArrivalTerminal: "Terminal 3",
  },
  {
    Country: "Hong Kong",
    CityName: "Hong Kong",
    IATACode: "HKG",
    AirportName: "Hong Kong International Airport",
    DepartureTerminal: "Terminal 1",
    ArrivalTerminal: "Terminal 1",
  },
  {
    Country: "India",
    CityName: "Ahmedabad",
    IATACode: "AMD",
    AirportName: "Sardar Vallabhbhai Patel International Airport",
    DepartureTerminal: "Terminal 2",
    ArrivalTerminal: "Terminal 2",
  },
  {
    Country: "India",
    CityName: "Bengaluru",
    IATACode: "BLR",
    AirportName: "Kempegowda International Airport",
    DepartureTerminal: "Terminal 2",
    ArrivalTerminal: "Terminal 2",
  },
  {
    Country: "India",
    CityName: "Chennai",
    IATACode: "MAA",
    AirportName: "Chennai International Airport",
    DepartureTerminal: "Anna International Terminal - T2",
    ArrivalTerminal: "Anna International Terminal - T2",
  },
  {
    Country: "India",
    CityName: "Cochin",
    IATACode: "COK",
    AirportName: "Cochin International Airport",
    DepartureTerminal: "Terminal 3",
    ArrivalTerminal: "Terminal 3",
  },
  {
    Country: "India",
    CityName: "Gaya",
    IATACode: "GAY",
    AirportName: "Gaya International Airport",
    DepartureTerminal: "Main Terminal",
    ArrivalTerminal: "Main Terminal",
  },
  {
    Country: "India",
    CityName: "Hyderabad",
    IATACode: "HYD",
    AirportName: "Rajiv Gandhi International Airport",
    DepartureTerminal: "RGIA Passenger Terminal Building",
    ArrivalTerminal: "RGIA Passenger Terminal Building",
  },
  {
    Country: "India",
    CityName: "Jaipur",
    IATACode: "JAI",
    AirportName: "Jaipur International Airport",
    DepartureTerminal: "Terminal 2",
    ArrivalTerminal: "Terminal 2",
  },
  {
    Country: "India",
    CityName: "Kolkata",
    IATACode: "CCU",
    AirportName: "Netaji Subhash Chandra Bose International Airport",
    DepartureTerminal: "Terminal 2",
    ArrivalTerminal: "Terminal 2",
  },
  {
    Country: "India",
    CityName: "Lucknow",
    IATACode: "LKO",
    AirportName: "CCS International Airport, Lucknow",
    DepartureTerminal: "Terminal 1",
    ArrivalTerminal: "Terminal 1",
  },
  {
    Country: "India",
    CityName: "New Delhi",
    IATACode: "DEL",
    AirportName: "Delhi International Airport",
    DepartureTerminal: "Terminal 3",
    ArrivalTerminal: "Terminal 3",
  },
  {
    Country: "India",
    CityName: "Tiruchirappalli (Trichy)",
    IATACode: "TRZ",
    AirportName: "Tiruchirappalli International Airport",
    DepartureTerminal: "New integrated Terminal Building",
    ArrivalTerminal: "New integrated Terminal Building",
  },
  {
    Country: "Indonesia",
    CityName: "Balikpapan",
    IATACode: "BPN",
    AirportName: "Sultan Aji Muhammad Sulaiman Sepinggan International Airport",
    DepartureTerminal:
      "sultan aji muhammad sulaiman sepinggan international airport",
    ArrivalTerminal:
      "sultan aji muhammad sulaiman sepinggan international airport",
  },
  {
    Country: "Indonesia",
    CityName: "Banda Aceh",
    IATACode: "BTJ",
    AirportName: "Sultan Iskandar Muda International Airport",
    DepartureTerminal: "Main Terminal",
    ArrivalTerminal: "Main Terminal",
  },
  {
    Country: "Indonesia",
    CityName: "Bandung",
    IATACode: "BDO",
    AirportName: "Husein Sastranegara International Airport",
    DepartureTerminal: "Main Terminal",
    ArrivalTerminal: "Main Terminal",
  },
  {
    Country: "Indonesia",
    CityName: "Denpasar",
    IATACode: "DPS",
    AirportName: "Denpasar Ngurah Rai International Airport (DPS)",
    DepartureTerminal: "Main Terminal",
    ArrivalTerminal: "Main Terminal",
  },
  {
    Country: "Indonesia",
    CityName: "Jakarta",
    IATACode: "CGK",
    AirportName: "Soekarno-hatta International Airport",
    DepartureTerminal:
      "Terminal 1A - Domestic\n\nTerminal 2F - International\n",
    ArrivalTerminal: "Terminal 1A - Domestic\n\nTerminal 2F - International\n",
  },
  {
    Country: "Indonesia",
    CityName: "Kertajati",
    IATACode: "KJT",
    AirportName: "Kertajati International Airport",
    DepartureTerminal: "Main Terminal",
    ArrivalTerminal: "Main Terminal",
  },
  {
    Country: "Indonesia",
    CityName: "Kualanamu",
    IATACode: "KNO",
    AirportName: "Kualanamu International Airport",
    DepartureTerminal: "Main Terminal",
    ArrivalTerminal: "Main Terminal",
  },
  {
    Country: "Indonesia",
    CityName: "Lombok",
    IATACode: "LOP",
    AirportName: "Zainuddin Abdul Madjid International Airport",
    DepartureTerminal: "Main Terminal",
    ArrivalTerminal: "Main Terminal",
  },
  {
    Country: "Indonesia",
    CityName: "Padang",
    IATACode: "PDG",
    AirportName: "Minangkabau International Airport",
    DepartureTerminal: "Main Terminal",
    ArrivalTerminal: "Main Terminal",
  },
  {
    Country: "Indonesia",
    CityName: "Palembang",
    IATACode: "PLM",
    AirportName: "Sultan Mahmud Badaruddin Ii International Airport",
    DepartureTerminal: "Main Terminal",
    ArrivalTerminal: "Main Terminal",
  },
  {
    Country: "Indonesia",
    CityName: "Pekanbaru",
    IATACode: "PKU",
    AirportName: "Sultan Syarif Kasim Ii International Airport",
    DepartureTerminal: "Main Terminal",
    ArrivalTerminal: "Main Terminal",
  },
  {
    Country: "Indonesia",
    CityName: "Pontianak",
    IATACode: "PNK",
    AirportName: "Supadio International Airport",
    DepartureTerminal: "Main Terminal",
    ArrivalTerminal: "Main Terminal",
  },
  {
    Country: "Indonesia",
    CityName: "Semarang",
    IATACode: "SRG",
    AirportName: "Jenderal Ahmad Yani International Airport",
    DepartureTerminal: "Main Terminal",
    ArrivalTerminal: "Main Terminal",
  },
  {
    Country: "Indonesia",
    CityName: "Sidoarjo",
    IATACode: "SUB",
    AirportName: "Juanda International Airport",
    DepartureTerminal: "Terminal 1 - Domestic\n\nTerminal 2 - International",
    ArrivalTerminal: "Terminal 1 - Domestic\n\nTerminal 2 - International",
  },
  {
    Country: "Indonesia",
    CityName: "Silangit",
    IATACode: "DTB",
    AirportName: "Silangit International Airport",
    DepartureTerminal: "Main Terminal",
    ArrivalTerminal: "Main Terminal",
  },
  {
    Country: "Indonesia",
    CityName: "Surakarta",
    IATACode: "SOC",
    AirportName: "Adi Soemarmo International Airport",
    DepartureTerminal: "Main Terminal",
    ArrivalTerminal: "Main Terminal",
  },
  {
    Country: "Indonesia",
    CityName: "Ujung Pandang",
    IATACode: "UPG",
    AirportName: "Sultan Hasanuddin International Airport",
    DepartureTerminal: "Main Terminal",
    ArrivalTerminal: "Main Terminal",
  },
  {
    Country: "Indonesia",
    CityName: "Yogyakarta",
    IATACode: "YIA",
    AirportName: "Yogyakarta International Airport",
    DepartureTerminal: "Main Terminal",
    ArrivalTerminal: "Main Terminal",
  },
  {
    Country: "Japan",
    CityName: "Chitose",
    IATACode: "CTS",
    AirportName: "New Chitose Airport",
    DepartureTerminal: "International Terminal",
    ArrivalTerminal: "International Terminal",
  },
  {
    Country: "Japan",
    CityName: "Fukuoka",
    IATACode: "FUK",
    AirportName: "Fukuoka Airport",
    DepartureTerminal: "International Terminal",
    ArrivalTerminal: "International Terminal",
  },
  {
    Country: "Japan",
    CityName: "Nagoya",
    IATACode: "NGO",
    AirportName: "Chubu Centrair International Airport",
    DepartureTerminal: "Terminal 1 & 2",
    ArrivalTerminal: "Terminal 1 & 2",
  },
  {
    Country: "Japan",
    CityName: "Narita",
    IATACode: "NRT",
    AirportName: "Narita International Airport",
    DepartureTerminal:
      "Philippines AirAsia (Z2) - Terminal 3\n\nThai AirAsia X (XJ) - Terminal 2",
    ArrivalTerminal:
      "Philippines AirAsia (Z2) - Terminal 3\n\nThai AirAsia X (XJ) - Terminal 2",
  },
  {
    Country: "Japan",
    CityName: "Okinawa",
    IATACode: "OKA",
    AirportName: "Naha Airport",
    DepartureTerminal: "Terminal 1",
    ArrivalTerminal: "Terminal 1",
  },
  {
    Country: "Japan",
    CityName: "Osaka",
    IATACode: "KIX",
    AirportName: "Kansai International Airport",
    DepartureTerminal: "Terminal 1",
    ArrivalTerminal: "Terminal 1",
  },
  {
    Country: "Japan",
    CityName: "Tokyo",
    IATACode: "HND",
    AirportName: "Tokyo International Airport",
    DepartureTerminal: "International Terminal 3",
    ArrivalTerminal: "International Terminal 3",
  },
  {
    Country: "Laos",
    CityName: "Luang Prabang",
    IATACode: "LPQ",
    AirportName: "Luang Prabang International Airport",
    DepartureTerminal: "Multiple Terminals",
    ArrivalTerminal: "Multiple Terminals",
  },
  {
    Country: "Laos",
    CityName: "Vientiane",
    IATACode: "VTE",
    AirportName: "Wattay International Airport",
    DepartureTerminal: "Main Terminal",
    ArrivalTerminal: "Main Terminal",
  },
  {
    Country: "Macau",
    CityName: "Macau",
    IATACode: "MFM",
    AirportName: "Macau International Airport",
    DepartureTerminal: "Main Terminal",
    ArrivalTerminal: "Main Terminal",
  },
  {
    Country: "Malaysia",
    CityName: "Alor Setar",
    IATACode: "AOR",
    AirportName: "Sultan Abdul Halim Airport",
    DepartureTerminal: "Main Terminal",
    ArrivalTerminal: "Main Terminal",
  },
  {
    Country: "Malaysia",
    CityName: "Batu Berendam",
    IATACode: "MKZ",
    AirportName: "Malacca International Airport",
    DepartureTerminal: "Main Terminal",
    ArrivalTerminal: "Main Terminal",
  },
  {
    Country: "Malaysia",
    CityName: "Bintulu",
    IATACode: "BTU",
    AirportName: "Bintulu Airport",
    DepartureTerminal: "Main Terminal",
    ArrivalTerminal: "Main Terminal",
  },
  {
    Country: "Malaysia",
    CityName: "Ipoh",
    IATACode: "IPH",
    AirportName: "Ipoh Airport",
    DepartureTerminal: "Terminal 1",
    ArrivalTerminal: "Terminal 1",
  },
  {
    Country: "Malaysia",
    CityName: "Johor",
    IATACode: "JHB",
    AirportName: "Senai International Airport",
    DepartureTerminal: "Main Terminal",
    ArrivalTerminal: "Main Terminal",
  },
  {
    Country: "Malaysia",
    CityName: "Kota Bharu",
    IATACode: "KBR",
    AirportName: "Sultan Ismail Petra Airport",
    DepartureTerminal: "Main Terminal",
    ArrivalTerminal: "Main Terminal",
  },
  {
    Country: "Malaysia",
    CityName: "Kota Kinabalu",
    IATACode: "BKI",
    AirportName: "Kota Kinabalu International Airport",
    DepartureTerminal: "Terminal 1",
    ArrivalTerminal: "Terminal 1",
  },
  {
    Country: "Malaysia",
    CityName: "Kuala Lumpur",
    IATACode: "KUL",
    AirportName: "Kuala Lumpur International Airport 2 (KLIA2)",
    DepartureTerminal: "Terminal 2",
    ArrivalTerminal: "Terminal 2",
  },
  {
    Country: "Malaysia",
    CityName: "Kuala Terengganu",
    IATACode: "TGG",
    AirportName: "Sultan Mahmud Airport",
    DepartureTerminal: "Main Terminal",
    ArrivalTerminal: "Main Terminal",
  },
  {
    Country: "Malaysia",
    CityName: "Kuantan",
    IATACode: "KUA",
    AirportName: "Sultan Ahmad Shah Airport",
    DepartureTerminal: "Main Terminal",
    ArrivalTerminal: "Main Terminal",
  },
  {
    Country: "Malaysia",
    CityName: "Kuching",
    IATACode: "KCH",
    AirportName: "Kuching International Airport",
    DepartureTerminal: "Main Terminal",
    ArrivalTerminal: "Main Terminal",
  },
  {
    Country: "Malaysia",
    CityName: "Labuan",
    IATACode: "LBU",
    AirportName: "Labuan Airport",
    DepartureTerminal: "Terminal 1",
    ArrivalTerminal: "Terminal 1",
  },
  {
    Country: "Malaysia",
    CityName: "Langkawi",
    IATACode: "LGK",
    AirportName: "Langkawi International Airport",
    DepartureTerminal: "Main Terminal",
    ArrivalTerminal: "Main Terminal",
  },
  {
    Country: "Malaysia",
    CityName: "Miri",
    IATACode: "MYY",
    AirportName: "Miri Airport",
    DepartureTerminal: "Main Terminal",
    ArrivalTerminal: "Main Terminal",
  },
  {
    Country: "Malaysia",
    CityName: "Mukah",
    IATACode: "MKM",
    AirportName: "Mukah Airport",
    DepartureTerminal: "Main Terminal",
    ArrivalTerminal: "Main Terminal",
  },
  {
    Country: "Malaysia",
    CityName: "Penang",
    IATACode: "PEN",
    AirportName: "Penang International Airport",
    DepartureTerminal: "Main Terminal",
    ArrivalTerminal: "Main Terminal",
  },
  {
    Country: "Malaysia",
    CityName: "Sandakan",
    IATACode: "SDK",
    AirportName: "Sandakan Airport",
    DepartureTerminal: "Main Terminal",
    ArrivalTerminal: "Main Terminal",
  },
  {
    Country: "Malaysia",
    CityName: "Sibu",
    IATACode: "SBW",
    AirportName: "Sibu Airport",
    DepartureTerminal: "Main Terminal",
    ArrivalTerminal: "Main Terminal",
  },
  {
    Country: "Malaysia",
    CityName: "Tawau",
    IATACode: "TWU",
    AirportName: "Tawau Airport",
    DepartureTerminal: "Main Terminal",
    ArrivalTerminal: "Main Terminal",
  },
  {
    Country: "Maldives",
    CityName: "Male",
    IATACode: "MLE",
    AirportName: "Velana International Airport",
    DepartureTerminal: "International Terminal",
    ArrivalTerminal: "International Terminal",
  },
  {
    Country: "Myanmar",
    CityName: "Mandalay",
    IATACode: "MDL",
    AirportName: "Mandalay International Airport",
    DepartureTerminal: "Main Terminal",
    ArrivalTerminal: "Main Terminal",
  },
  {
    Country: "Myanmar",
    CityName: "Yangon",
    IATACode: "RGN",
    AirportName: "Yangon International Airport",
    DepartureTerminal: "Terminal 1",
    ArrivalTerminal: "Terminal 1",
  },
  {
    Country: "Nepal",
    CityName: "Kathmandu",
    IATACode: "KTM",
    AirportName: "Tribhuvan International Airport",
    DepartureTerminal: "Terminal 1",
    ArrivalTerminal: "Terminal 1",
  },
  {
    Country: "New Zealand",
    CityName: "Auckland",
    IATACode: "AKL",
    AirportName: "Auckland International Airport",
    DepartureTerminal: "International Terminal",
    ArrivalTerminal: "International Terminal",
  },
  {
    Country: "Philippines",
    CityName: "Bacolod",
    IATACode: "BCD",
    AirportName: "Bcd-silay Airport",
    DepartureTerminal: "Silay Airport",
    ArrivalTerminal: "Silay Airport",
  },
  {
    Country: "Philippines",
    CityName: "Caticlan",
    IATACode: "MPH",
    AirportName: "Godofredo P. Ramos Airport",
    DepartureTerminal: "Caticlan Airport",
    ArrivalTerminal: "Caticlan Airport",
  },
  {
    Country: "Philippines",
    CityName: "Cebu",
    IATACode: "CEB",
    AirportName: "Mactan-cebu International Airport",
    DepartureTerminal: "Domestic -Terminal 1\n\nInternational - Terminal 2",
    ArrivalTerminal: "Domestic -Terminal 1\n\nInternational - Terminal 2",
  },
  {
    Country: "Philippines",
    CityName: "Clark",
    IATACode: "CRK",
    AirportName: "Clark International Airport",
    DepartureTerminal: "Clark International Airport",
    ArrivalTerminal: "Clark International Airport",
  },
  {
    Country: "Philippines",
    CityName: "Davao",
    IATACode: "DVO",
    AirportName: "Francisco Bangoy International Airport",
    DepartureTerminal: "Francisco Bangoy Davao International Airport",
    ArrivalTerminal: "Francisco Bangoy Davao International Airport",
  },
  {
    Country: "Philippines",
    CityName: "Dumaguete",
    IATACode: "DGT",
    AirportName: "Dumaguete Airport,sibulan Negros Oriental",
    DepartureTerminal: "Dumaguete Airport,sibulan Negros Oriental",
    ArrivalTerminal: "Dumaguete Airport,sibulan Negros Oriental",
  },
  {
    Country: "Philippines",
    CityName: "General Santos",
    IATACode: "GES",
    AirportName: "General Santos International Airport",
    DepartureTerminal: "General Santos International Airport",
    ArrivalTerminal: "General Santos International Airport",
  },
  {
    Country: "Philippines",
    CityName: "Iloilo",
    IATACode: "ILO",
    AirportName: "Iloilo International Airport",
    DepartureTerminal: "Iia",
    ArrivalTerminal: "Iia",
  },
  {
    Country: "Philippines",
    CityName: "Kalibo",
    IATACode: "KLO",
    AirportName: "Kalibo International Airport",
    DepartureTerminal: "Kalibo International Airport",
    ArrivalTerminal: "Kalibo International Airport",
  },
  {
    Country: "Philippines",
    CityName: "Laguindingan Municipality",
    IATACode: "CGY",
    AirportName: "Laguindingan Airport",
    DepartureTerminal: "Laguindingan Airport",
    ArrivalTerminal: "Laguindingan Airport",
  },
  {
    Country: "Philippines",
    CityName: "Manila",
    IATACode: "MNL",
    AirportName: "Ninoy Aquino International Airport",
    DepartureTerminal: "Terminal 3 - International Terminal 2 - Domestic",
    ArrivalTerminal: "Terminal 3 - International Terminal 2 - Domestic",
  },
  {
    Country: "Philippines",
    CityName: "Puerto Princesa",
    IATACode: "PPS",
    AirportName: "Puerto Princesa International Airport",
    DepartureTerminal: "Puerto Princesa International Airport",
    ArrivalTerminal: "Puerto Princesa International Airport",
  },
  {
    Country: "Philippines",
    CityName: "Roxas",
    IATACode: "RXS",
    AirportName: "Roxas, Capiz Airport",
    DepartureTerminal: "Roxas, Capiz Airport",
    ArrivalTerminal: "Roxas, Capiz Airport",
  },
  {
    Country: "Philippines",
    CityName: "Tacloban City",
    IATACode: "TAC",
    AirportName: "Daniel Z. Romualdez Airport",
    DepartureTerminal: "Daniel Z. Romualdez Airport",
    ArrivalTerminal: "Daniel Z. Romualdez Airport",
  },
  {
    Country: "Philippines",
    CityName: "Tagbilaran",
    IATACode: "TAG",
    AirportName: "Bohol–panglao International Airport",
    DepartureTerminal: "Bohol–panglao International Airport",
    ArrivalTerminal: "Bohol–panglao International Airport",
  },
  {
    Country: "Philippines",
    CityName: "Zamboanga",
    IATACode: "ZAM",
    AirportName: "Zamboanga International Airport",
    DepartureTerminal: "Zamboanga International Airport",
    ArrivalTerminal: "Zamboanga International Airport",
  },
  {
    Country: "Saudi Arabia",
    CityName: "Jeddah",
    IATACode: "JED",
    AirportName: "King Abdulaziz International Airport",
    DepartureTerminal: "Hajj Terminal",
    ArrivalTerminal: "Hajj Terminal",
  },
  {
    Country: "Singapore",
    CityName: "Singapore",
    IATACode: "SIN",
    AirportName: "Singapore Changi Airport",
    DepartureTerminal: "Terminal 4",
    ArrivalTerminal: "Terminal 4",
  },
  {
    Country: "South Korea",
    CityName: "Busan",
    IATACode: "PUS",
    AirportName: "Gimhae International Airport",
    DepartureTerminal: "International Terminal",
    ArrivalTerminal: "International Terminal",
  },
  {
    Country: "South Korea",
    CityName: "Incheon",
    IATACode: "ICN",
    AirportName: "Incheon International Airport",
    DepartureTerminal: "Terminal 1",
    ArrivalTerminal: "Terminal 1",
  },
  {
    Country: "South Korea",
    CityName: "Jeju",
    IATACode: "CJU",
    AirportName: "Jeju International Airport",
    DepartureTerminal: "Main Terminal",
    ArrivalTerminal: "Main Terminal",
  },
  {
    Country: "Sri Lanka",
    CityName: "Colombo",
    IATACode: "CMB",
    AirportName: "Bandaranaike International Airport",
    DepartureTerminal: "International Terminal",
    ArrivalTerminal: "International Terminal",
  },
  {
    Country: "Taiwan",
    CityName: "Kaohsiung",
    IATACode: "KHH",
    AirportName: "Kaohsiung International Airport",
    DepartureTerminal: "International Terminal",
    ArrivalTerminal: "International Terminal",
  },
  {
    Country: "Taiwan",
    CityName: "Taipei",
    IATACode: "TPE",
    AirportName: "Taiwan Taoyuan International Airport",
    DepartureTerminal: "Terminal 1",
    ArrivalTerminal: "Terminal 1",
  },
  {
    Country: "Thailand",
    CityName: "Bangkok - Don Mueang",
    IATACode: "DMK",
    AirportName: "Don Mueang International Airport",
    DepartureTerminal: "Multiple Terminals",
    ArrivalTerminal: "Multiple Terminals",
  },
  {
    Country: "Thailand",
    CityName: "Bangkok - Suvarnnabhumi",
    IATACode: "BKK",
    AirportName: "Suvarnnabhumi Inernational Airport",
    DepartureTerminal: "Main Terminal",
    ArrivalTerminal: "Main Terminal",
  },
  {
    Country: "Thailand",
    CityName: "Buriram",
    IATACode: "BFV",
    AirportName: "Buriram Airport",
    DepartureTerminal: "Main Terminal",
    ArrivalTerminal: "Main Terminal",
  },
  {
    Country: "Thailand",
    CityName: "Chiang Mai",
    IATACode: "CNX",
    AirportName: "Chiang Mai International Airport",
    DepartureTerminal: "Multiple Terminals",
    ArrivalTerminal: "Multiple Terminals",
  },
  {
    Country: "Thailand",
    CityName: "Chiang Rai",
    IATACode: "CEI",
    AirportName: "Mae Fah Luang - Chiang Rai International Airport",
    DepartureTerminal: "Main Terminal",
    ArrivalTerminal: "Main Terminal",
  },
  {
    Country: "Thailand",
    CityName: "Chumphon",
    IATACode: "CJM",
    AirportName: "Chumphon Airport",
    DepartureTerminal: "Main Terminal",
    ArrivalTerminal: "Main Terminal",
  },
  {
    Country: "Thailand",
    CityName: "Hua Hin",
    IATACode: "HHQ",
    AirportName: "Hua Hin Airport",
    DepartureTerminal: "Main Terminal",
    ArrivalTerminal: "Main Terminal",
  },
  {
    Country: "Thailand",
    CityName: "Khon Kaen",
    IATACode: "KKC",
    AirportName: "Khon Kaen Airport",
    DepartureTerminal: "Main Terminal",
    ArrivalTerminal: "Main Terminal",
  },
  {
    Country: "Thailand",
    CityName: "Krabi",
    IATACode: "KBV",
    AirportName: "Krabi Airport",
    DepartureTerminal: "Main Terminal",
    ArrivalTerminal: "Main Terminal",
  },
  {
    Country: "Thailand",
    CityName: "Loei",
    IATACode: "LOE",
    AirportName: "Loei Airport",
    DepartureTerminal: "Main Terminal",
    ArrivalTerminal: "Main Terminal",
  },
  {
    Country: "Thailand",
    CityName: "Nakhon Si Thammarat",
    IATACode: "NST",
    AirportName: "Nakhon Si Thammarat International Airport",
    DepartureTerminal: "Main Terminal",
    ArrivalTerminal: "Main Terminal",
  },
  {
    Country: "Thailand",
    CityName: "Nakhonphanom",
    IATACode: "KOP",
    AirportName: "Nakhon Phanom Airport",
    DepartureTerminal: "Main Terminal",
    ArrivalTerminal: "Main Terminal",
  },
  {
    Country: "Thailand",
    CityName: "Nan",
    IATACode: "NNT",
    AirportName: "Nan Nakhon Airport",
    DepartureTerminal: "Main Terminal",
    ArrivalTerminal: "Main Terminal",
  },
  {
    Country: "Thailand",
    CityName: "Narathiwat",
    IATACode: "NAW",
    AirportName: "Narathiwat Airport",
    DepartureTerminal: "Main Terminal",
    ArrivalTerminal: "Main Terminal",
  },
  {
    Country: "Thailand",
    CityName: "Phitsanulok",
    IATACode: "PHS",
    AirportName: "Phitsanulok Airport",
    DepartureTerminal: "Main Terminal",
    ArrivalTerminal: "Main Terminal",
  },
  {
    Country: "Thailand",
    CityName: "Phuket",
    IATACode: "HKT",
    AirportName: "Phuket International Airport",
    DepartureTerminal: "Multiple Terminals",
    ArrivalTerminal: "Multiple Terminals",
  },
  {
    Country: "Thailand",
    CityName: "Ranong",
    IATACode: "UNN",
    AirportName: "Ranong Airport",
    DepartureTerminal: "Main Terminal",
    ArrivalTerminal: "Main Terminal",
  },
  {
    Country: "Thailand",
    CityName: "Roi Et",
    IATACode: "ROI",
    AirportName: "Roi Et Airport",
    DepartureTerminal: "Main Terminal",
    ArrivalTerminal: "Main Terminal",
  },
  {
    Country: "Thailand",
    CityName: "Sakon Nakhon",
    IATACode: "SNO",
    AirportName: "Sakon Nakhon Airport",
    DepartureTerminal: "Main Terminal",
    ArrivalTerminal: "Main Terminal",
  },
  {
    Country: "Thailand",
    CityName: "Songkhla",
    IATACode: "HDY",
    AirportName: "Hat Yai International Airport",
    DepartureTerminal: "Terminal 2",
    ArrivalTerminal: "Terminal 2",
  },
  {
    Country: "Thailand",
    CityName: "Surat Thani",
    IATACode: "URT",
    AirportName: "Surat Thani International Airport",
    DepartureTerminal: "Main Terminal",
    ArrivalTerminal: "Main Terminal",
  },
  {
    Country: "Thailand",
    CityName: "Trang",
    IATACode: "TST",
    AirportName: "Trang Airport",
    DepartureTerminal: "Main Terminal",
    ArrivalTerminal: "Main Terminal",
  },
  {
    Country: "Thailand",
    CityName: "U-Tapao",
    IATACode: "UTP",
    AirportName: "Rayong–pattaya International Airport",
    DepartureTerminal: "Main Terminal",
    ArrivalTerminal: "Main Terminal",
  },
  {
    Country: "Thailand",
    CityName: "Ubon Ratchathani",
    IATACode: "UBP",
    AirportName: "Ubon Ratchathani International Airport",
    DepartureTerminal: "Main Terminal",
    ArrivalTerminal: "Main Terminal",
  },
  {
    Country: "Thailand",
    CityName: "Udon Thani",
    IATACode: "UTH",
    AirportName: "Udon Thani Airport",
    DepartureTerminal: "Terminal - B",
    ArrivalTerminal: "Terminal - B",
  },
  {
    Country: "United States of America",
    CityName: "Honolulu",
    IATACode: "HNL",
    AirportName: "Daniel K. Inouye International Airport",
    DepartureTerminal: "Terminal 2",
    ArrivalTerminal: "Terminal 2",
  },
  {
    Country: "Vietnam",
    CityName: "Can Tho",
    IATACode: "VCA",
    AirportName: "Can Tho International Airport",
    DepartureTerminal: "Main Terminal",
    ArrivalTerminal: "Main Terminal",
  },
  {
    Country: "Vietnam",
    CityName: "Da Lat",
    IATACode: "DLI",
    AirportName: "Lien Khuong Airport",
    DepartureTerminal: "Main Terminal",
    ArrivalTerminal: "Main Terminal",
  },
  {
    Country: "Vietnam",
    CityName: "Da Nang",
    IATACode: "DAD",
    AirportName: "Da Nang International Airport",
    DepartureTerminal: "Terminal 2",
    ArrivalTerminal: "Terminal 2",
  },
  {
    Country: "Vietnam",
    CityName: "Hanoi",
    IATACode: "HAN",
    AirportName: "Noi Bai International Airport",
    DepartureTerminal: "Terminal 2",
    ArrivalTerminal: "Terminal 2",
  },
  {
    Country: "Vietnam",
    CityName: "Hochiminh",
    IATACode: "SGN",
    AirportName: "Tan Son Nhat International Airport",
    DepartureTerminal: "Terminal 2",
    ArrivalTerminal: "Terminal 2",
  },
  {
    Country: "Vietnam",
    CityName: "Nha Trang",
    IATACode: "CXR",
    AirportName: "Cam Ranh International Airport",
    DepartureTerminal: "International Terminal",
    ArrivalTerminal: "International Terminal",
  },
  {
    Country: "Vietnam",
    CityName: "Phu Quoc",
    IATACode: "PQC",
    AirportName: "Phu Quoc International Airport",
    DepartureTerminal: "Main Terminal",
    ArrivalTerminal: "Main Terminal",
  },
];
