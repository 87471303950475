import { Inject, Injectable } from '@angular/core';
import { BehaviorSubject, ReplaySubject, of, throwError } from 'rxjs';
// import {  } from 'rxjs';
// import { Router } from '@angular/router';
import { filter, map, switchMap, catchError, mergeMap } from 'rxjs/operators'
import { Router, NavigationStart, NavigationEnd, NavigationError, NavigationCancel, RoutesRecognized } from '@angular/router';
import { ɵc as UserdataService, ɵb as UhfLocaleSerice } from 'aa-universal-header-footer';
import { LookupService } from './lookup.service';
import { ControllerService } from './controller.service';
import { BaseService } from './_base.service';
import { ConfigService } from './config.service';
import { Meta, Title } from '@angular/platform-browser';
import { DOCUMENT } from '@angular/common';
// import {  } from 'rxjs/operators';

declare function require(url: string);

@Injectable()
export class NewLocaleService {
  public defaultLocale = 'en-GB';

  isGetFromBucket: boolean = true;

  // localeArray: Object = [];

  private localeInfo = new ReplaySubject<any>();
  public localeInfo$ = this.localeInfo.asObservable();
  private canonicalTag: HTMLLinkElement;

  constructor(private router: Router,
    private userdataService: UserdataService,
    private lookupService: LookupService,
    private controllerService: ControllerService,
    private baseService: BaseService,
    private configService: ConfigService,
    private uhfLocaleService: UhfLocaleSerice,
    private titleService: Title,
    private metaService: Meta,
    @Inject(DOCUMENT) private doc,
  ) {

    this.getLocaleData().subscribe(
      data => {
        this.localeInfo.next(data)
      }
    )


  }

  // checkLocale(locale: any) {
  //   let localeArray = ['zh-CN', 'zh-HK', 'zh-TW', 'zh-HK', 'ja-JP', 'vi-VN', 'ko-KR', 'en-GB', 'th-TH', 'id-ID']
  //   if (locale == null || locale == undefined) {
  //     return "en-GB";
  //   }
  //   for (var i = 0; i <= localeArray.length; i++) {
  //     if (locale == localeArray[i]) {
  //       return locale
  //     }
  //   }
  //   return "en-GB";
  // }

  getLocaleString() {
    let results = this.findCurrentLocale();
    return results || this.defaultLocale
  }

  // TODO: Refactor
  getLocaleData() {

    // let urlData = this.router
    let urlChunk = this.router.routerState.snapshot.root.children[0]
      ? this.router.routerState.snapshot.root.children[0].url
      : [];

    let urlData = (urlChunk[1] && urlChunk[2]) ? `${urlChunk[1]}-` + `${urlChunk[2]}` : '';

    return this.userdataService.$localeChange.pipe(
      switchMap((locale) => {
        // console.log('Received locale ', locale)

        if (urlData && urlData.toLowerCase() != locale) {
          /* Valid URL - Complete override */
          console.log("## locale_new.service: has urlData");
          // console.log('/* Valid URL - Complete override */')
          locale = urlData.toLowerCase();
          this.userdataService.updateLocaleSubject(locale);
        }
        if (!urlData && this.getCookie("uhfLocale")) {
          /* Invalid URL + cookie */
          console.log("## locale_new.service: locale cookie ");
          // console.log('/* Invalid URL + cookie */')
          locale = this.getCookie("uhfLocale");
        }
        if (!urlData && !this.getCookie("uhfLocale")) {
          /* Invalid URL + NO Cookie */
          // console.log('/* Invalid URL + NO Cookie */');
          console.log(
            "## locale_new.service: this.uhfLocaleService.setLanguageDefault() "
          );
          this.uhfLocaleService.setLanguageDefault();

          // this.setCookie('uhfLocale', 'th-th', 30);
          // locale = 'th-th';
          // this.userdataService.updateLocaleSubject(locale);

          // setTimeout(() => {
          //   this.uhfLocaleService.setLanguageDefault();
          // }, 3000);
        }

        urlData = "";

        let changedLocale = locale.split("-")[0] + "-" + locale.split("-")[1];
        let results = of({});

        if (this.isGetFromBucket) {
          results = this.baseService.get(
            this.configService.getLocaleBucketUrl(changedLocale),
            "locale"
          );
          console.log(
            "## locale_new.service: IS GET FROM BUCKET ",
            changedLocale
          );
        }

        //  console.log('number of times...',locale)
        this.lookupService
          .getOriginStationDetailsPerCulture(changedLocale.toLowerCase())
          .subscribe((stations) => {
            this.controllerService.setOriginStations(stations);
          });

        this.redirectLocale(locale);
        return results;
      })
    );
  }


  findCurrentLocale() {
    if (localStorage)
      return localStorage.getItem('locale');
    // else
    // catchError((err) => {

    //   return observableThrowError(err);

    // })

  }




  setCurrentLocale(locale: string) {
    if (localStorage) {
      localStorage.setItem('locale', locale);
      return true;
    }
    return false;
  }

  redirectLocale(localeString) {
    if (!localeString)
      return;
    let { router } = this;
    let [lang1, lang2] = localeString.split('-');

    let urlChunk = router.routerState.snapshot.root.children[0]
      ? router.routerState.snapshot.root.children[0].url
      : [];
    let thisRoute = urlChunk.length
      ? urlChunk[0].path
      : '';

    this.localeInfo$.subscribe(
      (value) => {
          const metaTag = value.metaTag;
          this.titleService.setTitle(metaTag.pageTitle);
          this.removeTags();
          this.addTags(metaTag);
      }
    )

    // If invalid URL
    if (!urlChunk[1] && !urlChunk[2]) {
      this.router.navigate([`/${thisRoute}/${lang1}/${lang2}`]);
    }

    // If valid URL already
    if (localeString.toLowerCase() != `${urlChunk[1]}-${urlChunk[2]}`.toLowerCase()) {
      this.router.navigate([`/${thisRoute}/${lang1}/${lang2}`]);
    }

  }

  getCookie(name) {
    var nameEQ = name + "=";
    var ca = document.cookie.split(';');
    for (var i = 0; i < ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0) == ' ') c = c.substring(1, c.length);
      if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
    }
    return;
  }

  setCookie(name, value, days?: any) {
    var expires = "";
    if (days) {
      var date = new Date();
      date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
      expires = "; expires=" + date.toUTCString();
    }
    document.cookie = name + "=" + (value || "") + expires + "; path=/";
  }

  private addTags(metaTag){
      this.metaService.addTags([
          { name: 'title', content: metaTag.pageTitle },
          { name: 'keywords', content: metaTag.keywords },
          { name: 'description', content: metaTag.description },

          { property: 'og:url', content: metaTag.url },
          { property: 'og:title', content: metaTag.pageTitle },
          { property: 'og:description', content: metaTag.description },
        
          { name: 'twitter:title', content: metaTag.pageTitle },
          { name: 'twitter:description', content: metaTag.description },
      ]);
    
      if (this.canonicalTag === undefined) {
        this.canonicalTag = this.doc.createElement('link');
        this.canonicalTag.setAttribute('rel', 'canonical');
        this.doc.head.appendChild(this.canonicalTag);
      }
      this.canonicalTag.setAttribute('href', metaTag.url);
  }

  private removeTags(){
      this.metaService.removeTag("name='title'");
      this.metaService.removeTag("name='keywords'");
      this.metaService.removeTag("name='description'");

      this.metaService.removeTag("property='og:url'");
      this.metaService.removeTag("property='og:title'");
      this.metaService.removeTag("property='og:description'");

      this.metaService.removeTag("name='twitter:title'");
      this.metaService.removeTag("name='twitter:description'");
  }
}



// "cn": "zh-cn",
// "hk": "zh-hk",
// "tw": "zh-tw",
// "mo": "zh-hk",
// "jp": "ja-jp",
// "vn": "vi-vn",
// "kr": "ko-kr",
// "other": "en-gb",
// "th": "th-th",
// "my": "en-gb",
// "id": "id-id"