import { Injectable } from '@angular/core';
import { BaseService } from './_base.service';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class DateService {

  constructor(
    private base: BaseService
  ) { }

 
  getDateMask(locale): any {
    return this.base.get(environment.dateFormatUrl + `${locale}` + '/file.json', '')
  }

  
}

