
import { throwError as observableThrowError, Subject, from, of } from "rxjs";
import { Injectable } from "@angular/core";
// import { Response, Headers, RequestOptions } from '@angular/http';
import { map, catchError, concatMap } from "rxjs/operators";
import { environment } from "../../environments/environment";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import CryptoJS from 'crypto-js';
import isThisHour from "date-fns/isThisHour/index.js";

@Injectable()
export class BaseService {
  constructor(private httpClient: HttpClient) {}

  public loadingStatus = {
    origin: false,
    destination: false,
    routeSearch: false,
    numberSearch: false,
    pnrSearch: false,
  };

  private loading = new Subject<any>();
  public loading$ = this.loading.asObservable();
  public errorMessage: string = "";

  get(url: string, loadingFor: string): any {
    this.setLoadingStatus(loadingFor, true);

    let res = this.getHttpHeader(loadingFor)
      .pipe(
        concatMap((data) => {
          return this.httpClient.get(url, { headers: data });
        })
      )
      .pipe(
        map((response) => {
          this.setLoadingStatus(loadingFor, false);
          this.setLoadingStatus(loadingFor + "Error", false);
          // console.log("---RESPONSE--- ", response);
          return response;
        }),

        catchError((err) => {
          console.log(err, "caught error!");
          this.setLoadingStatus(loadingFor, false);
          this.setLoadingStatus(loadingFor + "Error", true);
          return observableThrowError(err);
        })
      );

    // console.log(res, "base service")
    return res;
  }

  private setLoadingStatus(loadingFor: string, value: boolean) {
    this.loadingStatus[loadingFor] = value;
    this.loading.next(this.loadingStatus);
  }

  public getHttpHeader(loadingFor?: string) {
    let headersValue = {};

    // only require auth header for route, flight number and pnr search
    if (
      loadingFor == "routeSearch" ||
      loadingFor == "numberSearch" ||
      loadingFor == "pnrSearch"
    ) {
      return this.getAuthToken().pipe(
        map((authResponse: any) => {
          headersValue["Authorization"] = "Bearer" + " " + authResponse;
          const header = {
            headers: new HttpHeaders().set(
              "Authorization",
              `Bearer ${authResponse}`
            ),
          }
          return this.getHeader(header);
        })
      );
    }

    return of(headersValue);
  }

  getHeader(options?: any) {
    let tempOptions = options;
    if (!tempOptions) {
      tempOptions = {};
    }
    let headers: any;
    if (tempOptions.headers) {
      headers = tempOptions.headers;
    } else {
      headers = new HttpHeaders();
    }
    headers = headers.append("Content-Security-Policy", "frame-ancestors'none'");
    headers = headers.append("Strict-Transport-Security", "max-age=31536000; includeSubdomains");
    headers = headers.append("X-Content-Type-Options", "nosniff");
    headers = headers.append("X-Frame-Options", "DENY");
    headers = headers.append("Content-Type", "application/json");
    return headers;
  }

  private getAuthInputUser() : string {
    return 'ZnNmcm9udGVuZA==';
  }
  private getAuthInputSec() : string {
    return 'ZnNmcm9udGVuZDEyMw==';
  }

  public getAuthToken() {
    const credentials = atob(this.getAuthInputUser()) + '|' + atob(this.getAuthInputSec()) + '|' + new Date().toISOString();
    const encryptedCredentials = this.aesEncryption(credentials);

    return this.httpClient
      .post(
        `${environment.authUrl}/V2/security/Signin?SecretPhrase=${encryptedCredentials}`,
        {

        },
        {
          headers: this.getHeader(),
        }
      )
      .pipe(
        map((response: string) => {
          return response;
        }),
        catchError((err) => {
          console.log(err, "caught error!");
          return observableThrowError(err);
        })
      );
  }

  // public extractData(res: HttpResponse<any>) {
  //   var headers = res.headers;
  //   try {
  //     res || {};
  //   } catch{
  //     res;
  //   }
  //   return res;
  // }

  // Refer to https://stackoverflow.com/q/67039733 it has the exact dotnet equivalent as ours
  private aesEncryption(value: string) {
    const encryptionKey = 'Flight Status';

    // "SXZhbiBNZWR2ZWRldg==" is a base64 encoded string of "Ivan Medvedev"
    // Ivan Medvedev is the string equivalent of the bytes[] from FlightStatusAPI
    // FlightStatusAPI -> new byte[] { 0x49, 0x76, 0x61, 0x6e, 0x20, 0x4d, 0x65, 0x64, 0x76, 0x65, 0x64, 0x65, 0x76 }
    // const salt = CryptoJS.enc.Base64.parse('SXZhbiBNZWR2ZWRldg==');
    const salt = CryptoJS.enc.Utf8.parse("Ivan Medvedev");
    const secret = CryptoJS.enc.Utf8.parse(encryptionKey);
    const keyAndIV = CryptoJS.PBKDF2(secret, salt, {
      keySize: 256/32 + 128/32,
      iterations: 1000,
      hasher: CryptoJS.algo.SHA1
    })

    const aesKeyAndIV =  CryptoJS.enc.Hex.stringify(keyAndIV);
    const aesKey = CryptoJS.enc.Hex.parse(aesKeyAndIV.substring(0, 64));
    const aesIV = CryptoJS.enc.Hex.parse(aesKeyAndIV.substring(64, aesKeyAndIV.length));
    const valueWordArray = CryptoJS.enc.Utf16LE.parse(value);

    const aes = CryptoJS.AES.encrypt(valueWordArray, aesKey, {iv: aesIV});

    return aes.toString();
  }
}
