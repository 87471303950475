import { Component, ViewEncapsulation, OnInit, HostListener, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
// import { Localization, LocaleService, TranslationService } from 'angular-l10n';
// import { UserSessionCookies } from './_utilities';
// import { EmitterService, ConfigService } from './_services';
import { ɵc as UserDataService } from "aa-universal-header-footer";
import { environment } from "../environments/environment";
import { BaseService } from "./_services";
import * as _ from "lodash";
import { DEEPLINK_PARAM_KEY, DEEPLINK_PARAM_VALUE, DEEPLINK_TYPE } from './_utilities/constant-variables';

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class AppComponent implements OnInit {
  deeplink = "false";
  showAppHeaderFooter = true;

  constructor(
    private base: BaseService,
    private userDataService: UserDataService,
    private route: ActivatedRoute
  ) {
  }

  ngOnInit() {
    this.userDataService.setEnvironment(environment.uhfEnv);

    this.deepLinkQueryParam();
    // this.userDataService.ssoLoginChange$.subscribe(data => console.log('SSO---- ', data))
  }

  deepLinkQueryParam(): void {
    this.route.queryParams.subscribe(params => {
      const pnr = params[DEEPLINK_PARAM_KEY.PNR];
      const flightNo = params[DEEPLINK_PARAM_KEY.FLIGHT];
      const mobileDevice = params[DEEPLINK_PARAM_KEY.ANDROID_IOS];

      if(!_.isEmpty(pnr)) {
        this.deeplink = DEEPLINK_TYPE.SEARCH_BY_PNR;
      } else if(!_.isEmpty(flightNo)) {
        this.deeplink = DEEPLINK_TYPE.SEARCH_BY_FLIGHT;
      } else {
        this.deeplink = DEEPLINK_TYPE.NORMAL_FLOW;
      }
      if(mobileDevice === DEEPLINK_PARAM_VALUE.isAndroid || mobileDevice === DEEPLINK_PARAM_VALUE.isIos) {
        this.showAppHeaderFooter = false;
      }
      sessionStorage.setItem(DEEPLINK_TYPE.SESSION_KEY, this.deeplink);
    });
  }
}
    // private cultureCode: string;
    // errorPage: boolean = false;
    // private currentPage: string = '';

    // allSub: Subscription[] = [];

    // constructor(
    //     private titleService: Title,
    //     // private configSvc: ConfigService
    // ) {
    //     // super(locale, translation);
    // }



    // ngOnInit() {
    //     // this.cultureCode = UserSessionCookies.getCultureCode();
    //     // this.currentPage = this.navigationSvc.getCurrentPage();
    // }

    // ngOnDestroy() {
    //     // for (let sub of this.allSub) {
    //     //     sub.unsubscribe();
    //     // }
    // }
