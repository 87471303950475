import { Component, OnInit,Input } from '@angular/core';

@Component({
  selector: 'aa-tab',
  templateUrl: './aa-tab.component.html',
  styleUrls: ['./aa-tab.component.scss']
})
export class TabComponent {

  @Input() title:string;
  @Input() active:boolean = false;
  @Input() disabled :boolean = false;
  public _isFocused:boolean = false;

  getLabel():string{
    return this.title;
  }
}
