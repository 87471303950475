import { Pipe, PipeTransform } from "@angular/core";
import * as moment from "moment";

@Pipe({
  name: "TimePipe",
})
export class CustomTimePipe implements PipeTransform {
  transform(value: number): string {
    return moment(value, "h:mm A").format("HH:mm");
 }
}
