import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnInit,
  ViewChild,
} from "@angular/core";
import { LookupService } from "../_services/lookup.service";
import { ControllerService } from "../_services/controller.service";
import { LocalJson } from "../_locale/locale-en-GB";
import { format } from "date-fns";
import { Subscription } from "rxjs";
import { Utilities } from "../_utilities/utilities";
import { environment } from "../../environments/environment";
import { MatDatepicker } from "@angular/material";
import {
  DEEPLINK_PARAM_KEY,
  DEEPLINK_TYPE,
  REQ_DATE_FORMAT,
} from "../_utilities/constant-variables";
import { ActivatedRoute } from "@angular/router";
import * as moment from "moment";
import * as _ from "lodash";
import { WindowReferenceService } from "../_services/window-reference.service";

@Component({
  selector: "aa-flight-number-search",
  templateUrl: "./aa-flight-number.component.html",
  styleUrls: ["./aa-flight-number.component.scss"],
})
export class AaFlightNumberComponent implements OnInit {
  public carrierCodes: string[] = [];
  public selectedCode: string = "";
  public flightNumber: string = "";
  public startDate: string = "";
  public endDate: string = "";
  public minDate: string = "";
  public maxDate: string = "";
  public isError: boolean = false;
  public flightNumFocus: boolean = false;
  public aircodeFocus: boolean = false;

  public startDateFocus: boolean = false;
  public endDateFocus: boolean = false;
  isMobileSize: boolean = false;

  public carrierCodeExtract: string = "";
  public carrierFull: string = "";
  public iterator: number = 0;
  public len: number = 0;
  public errorValue: boolean = true;
  public fn: string = "";
  allSub: Subscription[] = [];
  deeplinkSearchByFlight: string = "";

  constructor(
    private lookupService: LookupService,
    private controller: ControllerService,
    private localJson: LocalJson,
    private route: ActivatedRoute,
    private _windowRef: WindowReferenceService
  ) {
    if (!environment.production) {
      this.minDate = format(
        new Date(new Date().setDate(new Date().getDate() - 300)),
        "yyyy-MM-dd"
      );
    } else {
      this.minDate = format(
        new Date(new Date().setDate(new Date().getDate() - 2)),
        "yyyy-MM-dd"
      );
    }
    this.maxDate = format(
      new Date(new Date().setDate(new Date().getDate() + 14)),
      "yyyy-MM-dd"
    );

    this.deeplinkSearchByFlight = sessionStorage.getItem(
      DEEPLINK_TYPE.SESSION_KEY
    );
  }

  @ViewChild("Number", { static: true }) flightNumRef: ElementRef;
  @ViewChild("PickerFromInput", { static: true }) startDateRef: ElementRef;
  // @ViewChild("PickerToInput", { static: true }) endDateRef: ElementRef;
  @ViewChild("pickerFrom", { static: true }) pickerFrom: MatDatepicker<any>;
  // @ViewChild("pickerTo", { static: true }) pickerTo: MatDatepicker<any>;

  clearData() {
    this.flightNumRef.nativeElement.value = "";
    this.startDateRef.nativeElement.value = "";
    // this.endDateRef.nativeElement.value = "";

    this.flightNumber = "";
    this.startDate = "";
    this.endDate = "";

    this.pickerFrom.select(undefined);
    // this.pickerTo.select(undefined);

    this.setFocus("flightNumFocus", false);
    this.setFocus("startDateFocus", false);
    this.setFocus("endDateFocus", false);

    this.isError = false;
  }

  ngOnInit() {
    this.isMobileSize = Utilities.isMobileSize();
    this.carrierCodes = this.lookupService.getCarrierCodes();
    this.selectedCode = this.carrierCodes[0];
    const initialJourneyDate = moment().format("DD/MM/YYYY");
    this.startDateRef.nativeElement.value = initialJourneyDate;
    // this.controller.search$.subscribe(console.log)

    // let clearSearchSub = this.controller.clearSearch$.subscribe(
    //   (_userInput) => {
    //     this.clearData();
    //   }
    // );

    // this.allSub.push(clearSearchSub);

    // deeplink
    if (this.deeplinkSearchByFlight === DEEPLINK_TYPE.SEARCH_BY_FLIGHT) {
      this.route.queryParams.subscribe((params: Array<string>) => {
        const flightNumber = params[DEEPLINK_PARAM_KEY.FLIGHT];

        const journeyDate = this.validateJourneyDate(params);
        this.flightNumRef.nativeElement.value = flightNumber;
        if (_.isEmpty(journeyDate)) {
          this.startDateRef.nativeElement.value = initialJourneyDate;
        } else {
          this.startDateRef.nativeElement.value = moment(
            journeyDate,
            "YYYY-MM-DD"
          ).format("DD/MM/YYYY");
        }

        this.flightNumber = flightNumber;
        this.startDate = journeyDate;
        this.endDate = "";
        this.setFocus("flightNumFocus", false);
        this.search();
      });
    }
  }

  validateJourneyDate(params: Array<string>): string {
    const today = new Date();
    if (_.isEmpty(params[DEEPLINK_PARAM_KEY.FLIGHT_START_DATE])) return null;
    if (
      moment(params[DEEPLINK_PARAM_KEY.FLIGHT_START_DATE]).format() ===
      "Invalid Date"
    )
      return null;
    if (
      moment(
        moment(
          params[DEEPLINK_PARAM_KEY.FLIGHT_START_DATE],
          "MM-DD-YYYY"
        ).toDate()
      ).isAfter(moment().subtract(3, "days"))
    ) {
      return params[DEEPLINK_PARAM_KEY.FLIGHT_START_DATE]
        ? moment(
            params[DEEPLINK_PARAM_KEY.FLIGHT_START_DATE],
            "MM-DD-YYYY"
          ).format(REQ_DATE_FORMAT)
        : null;
    }
    return null;
  }

  ngOnDestroy() {
    for (let sub of this.allSub) {
      if (sub) sub.unsubscribe();
    }
  }

  setFocus(target, value) {
    // if(this[target])
    this[target] = value;
  }

  errorValueSetter() {
    this.errorValue = false;
  }

  search() {
    this.errorValue = true;
    //   console.log(this.selectedCode,this.flightNumber)
    if (!this.selectedCode || !this.flightNumber) {
      this.isError = true;
      return;
    }

    //console.log(this.localJson.localJson().carrierCode)
    const keys = Object.keys(this.localJson.localJson().carrierCode);
    //console.log(keys[1]);

    this.flightNumber = this.flightNumber.trim().toUpperCase();

    this.len = this.flightNumber.length;

    this.carrierCodeExtract = "";
    this.isError = false;

    //  console.log(this.flightNumber+"FNNN")
    this.carrierCodeExtract += this.flightNumber[0] + this.flightNumber[1];
    this.carrierCodeExtract = this.carrierCodeExtract.toLowerCase();

    //   console.log(this.flightNumber[0]+" ttt "+this.flightNumber[1])

    this.len = this.carrierCodes.length;

    //  console.log(this.carrierCodeExtract+" ttt ")

    this.selectedCode = "0";
    for (let i = 0; i < this.len; ++i) {
      if (this.carrierCodeExtract === keys[i]) {
        this.selectedCode = this.carrierCodeExtract;
        break;
      }
    }

    //  console.log(this.selectedCode+" fff ");

    this.len = this.flightNumber.length;
    this.carrierCodeExtract = "";
    for (let i = 2; i < this.len; ++i) {
      if (
        (this.flightNumber[i] >= "a" && this.flightNumber[i] <= "z") ||
        (this.flightNumber[i] >= "A" && this.flightNumber[i] <= "Z") ||
        (parseInt(this.flightNumber[i]) >= 0 &&
          parseInt(this.flightNumber[i]) <= 9)
      ) {
        this.carrierCodeExtract += this.flightNumber[i];
      }
    }

    this.carrierFull = this.carrierCodeExtract + this.selectedCode;
    //   console.log(this.selectedCode.toUpperCase()+" hhh ");

    // console.log(
    //   "## carrier ",
    //   this.carrierFull + " " + this.carrierFull.length,
    //   this.selectedCode
    // );

    if (this.selectedCode === "0" || this.carrierFull.length < 3) {
      this.isError = true;
      return;
    }

    //  console.log(this.isError+ " error ")
    //  console.log(this.selectedCode + " code ")
    //  console.log(this.carrierCodeExtract + " Number ")

    this.fn = this.carrierCodeExtract;

    this.carrierCodeExtract = "";
    this._windowRef.pushEventsToGTMOnFindFlights(
      "Search by flight number",
      this.flightNumber,
      moment(_.isEmpty(this.startDate) ? new Date() : this.startDate).format(
        "YYYY-MM-DD"
      )
    );
    this.controller.searchFlights({
      searchByFlightNumber: "true",
      data: {
        selectedCode: this.selectedCode.toUpperCase(),
        flightNumber: this.flightNumber,
        startDate: this.startDate,
        // flightNumber: this.fn
      },
    });
  }

  setFlightNumber(value) {
    this.flightNumber = value;
    this.setFocus("flightNumFocus", false);
  }

  setStartDate(value) {
    console.log(moment(value).format("YYYY-MM-DD"));

    this.startDate = format(value, "yyyy-MM-dd");
    this.setFocus("startDateFocus", false);
  }

  setEndDate(value) {
    this.endDate = format(value, "yyyy-MM-dd");
    this.setFocus("endDateFocus", false);
  }

  alphaNumberOnly(e) {
    const regex = new RegExp("^[a-zA-Z0-9 ]+$");
    const str = String.fromCharCode(!e.charCode ? e.which : e.charCode);
    if (regex.test(str)) {
      return true;
    }

    e.preventDefault();
    return false;
  }
}
