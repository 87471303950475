import { Injectable } from '@angular/core';
import { OnInit, Component } from '@angular/core';
import { format } from "date-fns";
import { ControllerService } from "../_services/controller.service";
import { DateFormatInteractor } from "./dateFormatInteractor";

@Injectable()
export class FlightStatusInteractor {
  spiloverlable: any = false;
  constructor(
    private controllerService: ControllerService,
    private dateFormatInteractor: DateFormatInteractor
  ) {
    this.controllerService.search$.subscribe((userInput) => {
      this.spiloverlable = false;
    });
  }

  flightStatusResult(data: any) {
    let formattedData = [];
    data.map((item) => {
      if (
        item.flightStatus != "RR" &&
        item.flightStatus != "FR" &&
        !item.flightNumber.endsWith("A") &&
        !item.flightNumber.endsWith("D") &&
        !item.flightNumber.endsWith("P")
      ) {
        let obj = {};
        obj["depart"] = item.flightDep;
        obj["arrival"] = item.flightArr;
        obj["estiDepartTime"] = item.flight_dep_estimated_time;
        obj["estiArrivalTime"] = item.flight_arr_estimated_time;
        obj["scheduledDepartTime"] = item.flight_dep_scheduled_time;
        obj["scheduledArrivalTime"] = item.flight_arr_scheduled_time;
        obj["actualDepartTime"] = item.flight_dep_actual_time;
        obj["actualArrivalTime"] = item.flight_arr_actual_time;
        obj["status"] = item.flightStatus;
        obj["flightName"] = item.flightNumber;
        obj["carrierName"] = this.dateFormatInteractor.getCode(
          item.flightCarrier
        );
        obj["lastUpdatedCache"] = item.lastUpdatedCache;
        obj["arrPlusDay"] = item.arr_delays_plus_day;
        obj["depPlusDay"] = item.dep_delays_plus_day;
        obj["journeyPlusDay"] = item.journey_crossover_plus_day;
        obj["journeyPlusDay"] = item.journey_crossover_plus_day;
        obj["flightTDOWN_UTC"] = item.flightTDOWN_UTC;
        obj["flightTKOFF_UTC"] = item.flightTKOFF_UTC;
        obj["flight_arr_actual_UTC"] = item.flight_arr_actual_UTC;
        obj["flight_dep_actual_UTC"] = item.flight_dep_actual_UTC;
        obj["flight_arr_estimated_UTC"] = item.flight_arr_estimated_UTC;
        obj["flight_dep_estimated_UTC"] = item.flight_dep_estimated_UTC;
        obj["flight_arr_scheduled_UTC"] = item.flight_arr_scheduled_UTC;
        obj["flight_dep_scheduled_UTC"] = item.flight_dep_scheduled_UTC;
        obj["flightDate"] = format(new Date(item.flightDate), "LLL d, yyyy");
        obj = { ...obj, ...item };
        if (
          item.dep_delays_plus_day ||
          item.arr_delays_plus_day ||
          item.journey_crossover_plus_day
        ) {
          this.spiloverlable = true;
        }
        formattedData.push(obj);
      }
    });
    return formattedData;
  }
}





