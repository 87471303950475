<!-- <div class="tabs-container">
  <header class="tab-header">
    <ul>
      <li class="tab-selected">Search by destination</li>
      <li>Search by flight number</li>
    </ul>
  </header>
  <aa-route-search>
  </aa-route-search>
</div> -->
<aa-tabs>
  <aa-tab [title]='"search-searchFlightNumber"| locale' [active]="isSearchFlightNumber">
    <aa-flight-number-search>
    </aa-flight-number-search>
  </aa-tab>
  <aa-tab [title]='"search-searchRoute"| locale' role="tabpanel" [active]="isSearchRoute">
    <aa-route-search>
    </aa-route-search>
  </aa-tab>
  <!-- Search by Booking Number -->
  <!-- <aa-tab [title]='"Search by booking number"' role="tabpanel"> -->
  <!-- <aa-tab [title]='"search-searchBookingNumber" | locale' role="tabpanel" [active]="isSearchBookingNumber">
    <app-aa-pnr-search></app-aa-pnr-search>
  </aa-tab>
   <aa-tab [title]='"Airport and Terminals"' role="tabpanel">
  </aa-tab>-->
</aa-tabs>
