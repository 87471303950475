import {
  Component,
  OnInit,
  ContentChildren,
  QueryList,
  ViewChildren,
  HostListener,
  ElementRef,
  ViewChild,
} from "@angular/core";
import { TabComponent as Tab } from "../aa-tab/aa-tab.component";
import { ControllerService } from "../../_services/controller.service";
import { ListKeyManager, FocusMonitor } from "@angular/cdk/a11y";
import { environment } from "../../../environments/environment";
import { fromEvent } from "rxjs";
import { WindowReferenceService } from "../../_services/window-reference.service";

@Component({
  selector: "aa-tabs",
  templateUrl: "./aa-tabs.component.html",
  styleUrls: ["./aa-tabs.component.scss"],
})
export class TabsComponent implements OnInit {
  // private tabs:any[] = ['Search by route','Search by flight number'];
  @ContentChildren(Tab) tabs: QueryList<Tab>;
  keyManager: any;

  @ViewChildren("tabtitles") tabtitles: QueryList<any> = null;
  @ViewChild("tabhead", { static: false }) tabhead: ElementRef;

  constructor(
    private focusMonitor: FocusMonitor,
    private controllerService: ControllerService,
    private elementRef: ElementRef,
    private _windowRef: WindowReferenceService
  ) {}
  ngOnInit() {}

  ngAfterContentInit() {
    let activeTabs = this.tabs.filter((tab) => tab.active);

    if (activeTabs.length === 0) {
      this.selectTab(this.tabs.first);
    }
  }

  ngAfterViewInit() {
    this.keyManager = new ListKeyManager(this.tabtitles);
    this.keyManager.withHorizontalOrientation("ltr");
    this.keyManager.withWrap();
    this.keyManager.change.asObservable().subscribe((ind) => {
      this.setTabFocus(this.tabs.toArray()[ind]);
    });

    this.keyManager.tabOut.asObservable().subscribe(() => {
      this.tabs.toArray().forEach((tab) => (tab._isFocused = false));
    });
    // this.keyManager.change

    this.focusMonitor
      .monitor(this.tabhead.nativeElement, true)
      .subscribe((value) => {
        if (value !== "keyboard") {
          this.tabs.toArray().forEach((tab) => (tab._isFocused = false));
        }
      });

    fromEvent(this.tabhead.nativeElement, "keyup").subscribe(
      this._handleEvent.bind(this)
    );
  }

  _handleEvent(event: any) {
    if (event.code == "Enter" || event.code == "Space") {
      this.selectTab(
        this.tabs["_results"].filter(
          ({ title }) =>
            title == this.keyManager.activeItem.nativeElement.textContent
        )[0]
      );
      this.focusMonitor.focusVia(
        this.keyManager.activeItem.nativeElement,
        "keyboard"
      );
    }
    if (event.code !== "Tab") {
      this.keyManager.onKeydown(event);
      this.focusMonitor.focusVia(
        this.keyManager.activeItem.nativeElement,
        "keyboard"
      );
    } else {
      // 'artificially' updates the active element in case the user uses Tab instead of arrows
      // this.keyManager.activeItem.nativeElement.background = 'red'

      this.keyManager.onKeydown(event);
      this.keyManager.setNextItemActive();
    }
  }

  setTabFocus(tab: Tab) {
    // console.log('tab',tab)
    this.tabs.toArray().forEach((tab) => (tab._isFocused = false));

    tab._isFocused = true;
  }

  selectTab(tab: Tab) {
    if (tab.title === "Airport and Terminals") {
      (window as any).open(`${environment.airportTerminalPageUrl}`, "_blank");
      return;
    }
    this._windowRef.pushEventsToGTM(tab.title);

    // deactivate all tabs
    this.tabs.toArray().forEach((tab) => {
      tab.active = false;
    });

    // * clear search results here
    this.controllerService.clearSearchFlights();

    // activate the tab the user has clicked on.
    tab.active = true;
  }
}
