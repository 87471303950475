export const MOBILE_WIDTH = 625;
export const TABLET_WIDTH = 992;
export const MEAL_MAX_COL_L = 3;
export const MEAL_MAX_COL_S = 2;

export const SUCCESSCODE = "00000";

export const SEARCH_DATE_FORMAT = "MM/DD/YYYY";
export const REQ_DATE_FORMAT = "YYYY-MM-DD";

export const FLIGHT_STATUS = {
  FLIGHT_RETURN: "Flight Return",
  RAMP_RETURN: "Ramp Return",
  DIVERTED: "Diverted",
  LANDED: "Landed",
  IN_FLIGHT: "In flight",
  DEPART: "Departed",
  ON_TIME: "On Time",
  DEPARTED_ON_TIME: "Departed On Time",
  NOT_YET_DEPARTED: "Not yet departed",
  EARLY_DEPARTURE: "Advanced",
  DELAYED: "Delayed",
  CANCELLED: "Cancelled",
};

export const DEEPLINK_TYPE = {
  SEARCH_BY_PNR: "searchByPNR",
  SEARCH_BY_FLIGHT: "searchByFlight",
  SESSION_KEY: "deeplink",
  NORMAL_FLOW: "normal",
};

export const DEEPLINK_PARAM_KEY = {
  PNR: "pnr",
  FLIGHT: "flight",
  FLIGHT_START_DATE: "journeyDate",
  ANDROID_IOS: "aa_app_platform",
};

export const DEEPLINK_PARAM_VALUE = {
  isAndroid: "android",
  isIos: "ios",
};

export const TERMINAL_CHANGE = {
  CAN: {
    DATE: "25/01/2024 09:00:00",
    FORMAT: "DD/MM/YYYY HH:mm:ss",
  },
};
