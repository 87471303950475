import { Injectable } from '@angular/core';
import { forkJoin, Observable, of } from "rxjs";
import { StatusResult } from "../interface/flight-status-interface";
import { BaseService } from "./_base.service";
import { DateFormatInteractor } from "../interactor/dateFormatInteractor";
import { environment } from "../../environments/environment";
import { concatMap, catchError } from "rxjs/operators";
// if (environment.production) {
//    console.log("prod env")
// }

@Injectable()
export class FlightStatusResultService {
  constructor(
    private base: BaseService,
    private dateFormatInteractor: DateFormatInteractor
  ) {}
  // private todayDate: string = this.dateFormatInteractor.currentDate();
  private todayDate: string = this.dateFormatInteractor.currentDateNew();
  public stateSearch: object = {};
  private date: string = " ";
  public testData: string = environment.testData;
  public searchByFlightNumber: boolean = false;

  getFlightStatus(userInput): Observable<StatusResult[]> {
    if(userInput.searchByFlightNumber === "true") {
      this.searchByFlightNumber = true;
    } else {
      this.searchByFlightNumber = false;
    }
    if (userInput.searchByDate) {
      this.date = userInput.date;
      userInput = this.stateSearch;
      userInput["flightDate"] = this.date;
    }
    if (userInput.searchByRoute) {
      this.stateSearch = userInput;

      const selectedDate = userInput.flightDate
        ? this.dateFormatInteractor.convertToValidDate(userInput.flightDate)
        : this.todayDate;

      let OriginDestination_URL =
        environment.searchUrlNew +
        "/GetByRoute?" +
        `Departure=${userInput.data.origin}&Arrival=${userInput.data.dest}&` +
        `TodayDate=${selectedDate}`;
      // environment.searchUrl +
      // "flightstatus/status/od/v3/" +
      // `${orgDesParam.flightDep}/${orgDesParam.flightArr}/${orgDesParam.flightDate}`;

      if (
        userInput.data.startDate &&
        userInput.data.endDate &&
        !userInput.flightDate
      ) {
        OriginDestination_URL =
          environment.searchUrlNew +
          "/GetByRouteRange?" +
          `Departure=${userInput.data.origin}&Arrival=${userInput.data.dest}&` +
          `StartDate=${userInput.data.startDate}&EndDate=${userInput.data.endDate}`;
      }

      if (
        userInput.data.startDate &&
        !userInput.data.endDate &&
        !userInput.flightDate
      ) {
        OriginDestination_URL =
         environment.searchUrlNew +
        "/GetByRoute?" +
        `Departure=${userInput.data.origin}&Arrival=${userInput.data.dest}&` +
        `TodayDate=${userInput.data.startDate}`;
          // environment.searchUrlNew +
          // "/GetByRouteRange?" +
          // `Departure=${userInput.data.origin}&Arrival=${userInput.data.dest}&` +
          // `StartDate=${userInput.data.startDate}&EndDate=${userInput.data.startDate}`;
      }

      if (
        !userInput.data.startDate &&
        userInput.data.endDate &&
        !userInput.flightDate
      ) {
        OriginDestination_URL =
          environment.searchUrlNew +
          "/GetByRouteRange?" +
          `Departure=${userInput.data.origin}&Arrival=${userInput.data.dest}&` +
          `StartDate=${userInput.data.endDate}&EndDate=${userInput.data.endDate}`;
      }

      const routeSearchRes = this.base.get(
        OriginDestination_URL,
        "routeSearch"
      );

      return routeSearchRes;
    } else if (userInput.searchByFlightNumber) {
      console.log("seaching by flight number", userInput);
      this.stateSearch = userInput;

      const selectedDate = userInput.flightDate
        ? this.dateFormatInteractor.convertToValidDate(userInput.flightDate)
        : this.todayDate;
      // let FlightNumber_URL =
      //   environment.searchUrl +
      //   "flightstatus/status/fd/v3/" +
      //   `${flightNoParam.flightCode}/${flightNoParam.flightNumber}/${flightNoParam.flightDate}`;

      let FlightNumber_URL = `${environment.searchUrlNew}/GetByFlightNumber?FlightNumber=${userInput.data.flightNumber}&TodayDate=${selectedDate}`;

      if (
        userInput.data.startDate &&
        userInput.data.endDate &&
        !userInput.flightDate
      ) {
        FlightNumber_URL =
          `${environment.searchUrlNew}/GetByFlightNumberRange?FlightNumber=${userInput.data.flightNumber}` +
          `&StartDate=${userInput.data.startDate}` +
          `&EndDate=${userInput.data.endDate}`;
      }

      if (
        userInput.data.startDate &&
        !userInput.data.endDate &&
        !userInput.flightDate
      ) {
        FlightNumber_URL = `${environment.searchUrlNew}/GetByFlightNumber?FlightNumber=${userInput.data.flightNumber}&TodayDate=${userInput.data.startDate}`;

          // `${environment.searchUrlNew}/GetByFlightNumberRange?FlightNumber=${userInput.data.flightNumber}` +
          // `&StartDate=${userInput.data.startDate}` +
          // `&EndDate=${userInput.data.startDate}`;
      }

      if (
        !userInput.data.startDate &&
        userInput.data.endDate &&
        !userInput.flightDate
      ) {
        FlightNumber_URL =
          `${environment.searchUrlNew}/GetByFlightNumberRange?FlightNumber=${userInput.data.flightNumber}` +
          `&StartDate=${userInput.data.endDate}` +
          `&EndDate=${userInput.data.endDate}`;
      }

      return this.base.get(FlightNumber_URL, "numberSearch");
    } else if (userInput.searchByPNR) {
      // console.log("flight status result: searchByPNR", userInput);
      this.stateSearch = userInput;
      let PnrNumber_URL = `${environment.searchUrlNew}/GetByPNR?PNR=${userInput.data.pnrNumber}`;

      return this.base.get(PnrNumber_URL, "pnrSearch");
    }
  }

  getAirportTerminal(data: StatusResult[], userInput: any): Observable<any> {
    const obsArr = [];
    for (let record of data) {
      const [carrier, flightNum] = record.flightNumber.split(" ");
      const [flightDate, _flightTime] = record.flightDate.split(" ");

      const clearCarrier = carrier.replace(".", "").toLowerCase();
      const cleanFlightNum = flightNum.replace(".", "").toLowerCase();

      let url = `${environment.airportTerminalUrl}/${flightDate}/${clearCarrier}/${cleanFlightNum}/file.json`;

      obsArr.push(
        this.base
          .get(url, "terminalSearch")
          .pipe(
            concatMap((terminalDetails: any) =>
              of({ ...record, ...terminalDetails[0] })
            ),
            catchError((error:any) =>
              of({ ... record })
            )
          )
      );
    }
    return forkJoin(obsArr);
  }
}


