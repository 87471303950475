export class MockData {
  getMockdata() {
    return [
      {
        recordLocator: null,
        flightDate: "2024-05-03",
        lastUpdatedCache: "2024-05-03 06:51:18",
        utcFlightDepart: "2024-05-03 07:20:00",
        utcFlightArrival: "2024-05-03 08:30:00",
        flightArr: "LGK",
        flightCarrier: "AK",
        flightDep: "KUL",
        flightNumber: "AK 6310 ",
        checkinTable: "S,T",
        boardGate: "K12",
        baggageID: "2",
        prev_carrierCode: "AK",
        prev_flightnumber: "390  ",
        prev_legcode: "  ",
        prev_departurestation: "KNO",
        prev_arrivalstation: "KUL",
        prev_std: "2024-05-03T04:15:00",
        prev_etd: null,
        prev_atd: "2024-05-03T04:08:00",
        prev_sta: "2024-05-03T05:25:00",
        prev_eta: "2024-05-03T05:15:00",
        prev_ata: "2024-05-03T05:06:00",
        rego: "9M-AQF",
        flight_map_flag: "True",
        flightStatus: "On Time",
        flightTDOWN_date: null,
        flightTDOWN_time: null,
        flightTDOWN_full: null,
        flightTDOWN_UTC: null,
        flightTKOFF_date: null,
        flightTKOFF_time: null,
        flightTKOFF_full: null,
        flightTKOFF_UTC: null,
        flight_arr_actual_date: null,
        flight_arr_actual_time: null,
        flight_arr_actual_full: null,
        flight_arr_actual_UTC: null,
        flight_dep_actual_date: null,
        flight_dep_actual_time: null,
        flight_dep_actual_full: null,
        flight_dep_actual_UTC: null,
        flight_arr_estimated_date: "2024-05-03",
        flight_arr_estimated_time: "4:25 PM",
        flight_arr_estimated_full: "2024-05-03 16:25:00",
        flight_arr_estimated_UTC: "2024-05-03 08:25:00",
        flight_dep_estimated_date: null,
        flight_dep_estimated_time: null,
        flight_dep_estimated_full: null,
        flight_dep_estimated_UTC: null,
        flight_arr_scheduled_date: "2024-05-03",
        flight_arr_scheduled_time: "4:30 PM",
        flight_arr_scheduled_full: "2024-05-03 16:30:00",
        flight_arr_scheduled_UTC: "2024-05-03 08:30:00",
        flight_dep_scheduled_date: "2024-05-03",
        flight_dep_scheduled_time: "3:20 PM",
        flight_dep_scheduled_full: "2024-05-03 15:20:00",
        flight_dep_scheduled_UTC: "2024-05-03 07:20:00",
      },
    ];
  }
}
