import {DOWN_ARROW, ENTER, ESCAPE, TAB, UP_ARROW} from '@angular/cdk/keycodes';
import {filter, take, switchMap, delay, tap, map,distinctUntilChanged} from 'rxjs/operators';
import {
  ChangeDetectorRef,
  Directive,
  ElementRef,
  forwardRef,
  Host,
  Inject,
  InjectionToken,
  Input,
  NgZone,
  OnDestroy,
  Optional,
  ViewContainerRef,
} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
import {Subscription, Subject,  merge ,  of ,  fromEvent } from 'rxjs';
import { Observable, defer } from 'rxjs';

import {OptionComponent,OptionSelectionChange} from './option/option.component'

// import 'rxjs/add/observable/merge';


import {AutoComplete} from './aa-auto-complete.component'

/** The height of each autocomplete option. */
export const AUTOCOMPLETE_OPTION_HEIGHT = 48;

/** The total height of the autocomplete panel. */
export const AUTOCOMPLETE_PANEL_HEIGHT = 256;

export const AUTOCOMPLETE_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => TriggerDirective),
  multi: true
};
const closingSubcribe :any = null;
const noop = () => {}

import {DOCUMENT} from '@angular/common';




@Directive({
  selector: "input[aa-autocomplete]",
  exportAs: "autocompleteTrigger",
  providers: [AUTOCOMPLETE_VALUE_ACCESSOR],
  host: {
    autocomplete: "off",
    "[attr.role]": '"combobox"',
    "[attr.aria-autocomplete]": '"list"',
    "[attr.aria-activedescendant]": "activeOption?.id",
    "[attr.aria-expanded]": "panelOpen.toString()",
    "[attr.aria-owns]": "autoComplete?.id",
    "(focusin)": "_handleFocus()",
    "(input)": "_handleInput($event)",
    "(blur)": "_onTouched()",
    "(keydown)": "_handleKeydown($event)",
  },
})
export class TriggerDirective implements ControlValueAccessor {
  /** The subscription for closing actions (some are bound to document). */
  private _closingActionsSubscription: Subscription;

  /** Subscription to viewport size changes. */
  private _viewportSubscription = Subscription.EMPTY;

  /** Stream of keyboard events that can close the panel. */
  private readonly _closeKeyEventStream = new Subject<void>();

  /** `View -> model callback called when value changes` */
  _onChange: (value: any) => void = () => {};

  /** `View -> model callback called when autocomplete has been touched` */
  _onTouched = () => {
    // console.log('blurrr....')
    this.autocomplete._setVisibility(false);
  };

  _previousValue = "";

  @Input("aa-autocomplete") autocomplete: AutoComplete;

  constructor(
    private _element: ElementRef,
    private _zone: NgZone,
    @Optional() @Inject(DOCUMENT) private _document: any
  ) {
    // _element.nativeElement.style.backgroundColor = 'yellow';
    // //console.log('Hellloooooo.... anybody there')
  }

  get panelOpen(): boolean {
    return this.autocomplete._isOpen;
  }

  openPanel(): void {
    // this.autoComplete._isOpen = true;
    this.autocomplete._setVisibility(true);
    this.autocomplete.opened.emit();
    // if(auto)
    this._closingActionsSubscription = this._subscribeToClosingActions();
  }

  closePanel(): void {
    //console.log('trying to close')
    this.autocomplete._setVisibility(false);
    this.autocomplete.closed.emit();
    if (this._closingActionsSubscription)
      this._closingActionsSubscription.unsubscribe();
  }

  _subscribeToClosingActions(): Subscription {
    // //console.log('HI..')

    const firstStable = this._zone.onStable.asObservable().pipe(take(1));
    const optionChanges = this.autocomplete.options.changes;
    // this.autocomplete.options.changes.subscribe('changed')
    return (
      merge(firstStable, optionChanges)
        .pipe(
          // create a new stream of panelClosingActions, replacing any previous streams
          // that were created, and flatten it so our stream only emits closing events...
          switchMap(() => {
            // this._resetActiveItem();
            // this.autocomplete._setVisibility(true);
            return this.panelClosingActions;
          }),
          // when the first closing event occurs...
          take(1)
        )
        // set the value, close the panel, and complete.
        .subscribe((event) => {
          this._setValueAndClose(event);
          // console.log('++')
        })
    );
  }

  private get _outsideClickStream(): Observable<any> {
    if (!this._document) {
      return of(null);
    }

    return merge(
      fromEvent(this._document, "click")
      // fromEvent(this._document, 'touchend')
    ).pipe(
      filter((event: MouseEvent | TouchEvent) => {
        const clickTarget = event.target as HTMLElement;
        // const formField = this._formField ?
        //     this._formField._elementRef.nativeElement : null;

        // //console.log('click target',clickTarget)

        return (
          this.autocomplete.isOpen &&
          clickTarget !== this._element.nativeElement
        );

        // return this._overlayAttached &&
        //         clickTarget !== this._element.nativeElement &&
        //         (!formField || !formField.contains(clickTarget)) &&
        //         (!!this._overlayRef && !this._overlayRef.overlayElement.contains(clickTarget));
      })
    );
  }

  get panelClosingActions(): Observable<OptionSelectionChange | null> {
    return merge(
      this.optionSelections,
      this.autocomplete._keyManager.tabOut.pipe(
        filter(() => this.autocomplete._isOpen)
      ),
      this._closeKeyEventStream,
      this._outsideClickStream
    ).pipe(
      // Normalize the output so we return a consistent type.
      map((event) => (event instanceof OptionSelectionChange ? event : null))
    );
  }

  readonly optionSelections: Observable<any> = defer(() => {
    if (this.autocomplete && this.autocomplete.options) {
      return merge(
        ...this.autocomplete.options.map((option) => {
          // console.log('option',option);
          return option.onSelectionChange;
        })
      );
    }

    // If there are any subscribers before `ngAfterViewInit`, the `autocomplete` will be undefined.
    // Return a stream that we'll replace with the real one once everything is in place.
    return this._zone.onStable.asObservable().pipe(
      take(1),
      switchMap(() => this.optionSelections)
    );
  });

  get activeOption(): OptionComponent | null {
    if (this.autocomplete && this.autocomplete._keyManager) {
      return this.autocomplete._keyManager.activeItem;
    }
    return null;
  }

  // Implemented as part of ControlValueAccessor.
  writeValue(value: any): void {
    Promise.resolve(null).then(() => this._setTriggerValue(value));
  }

  // Implemented as part of ControlValueAccessor.
  registerOnChange(fn: (value: any) => {}): void {
    this._onChange = fn;
  }

  // Implemented as part of ControlValueAccessor.
  registerOnTouched(fn: () => {}) {
    this._onTouched = fn;
  }

  // Implemented as part of ControlValueAccessor.
  setDisabledState(isDisabled: boolean) {
    this._element.nativeElement.disabled = isDisabled;
  }

  _handleKeydown(event: KeyboardEvent): void {
    const keyCode = event.keyCode;

    // Prevent the default action on all escape key presses. This is here primarily to bring IE
    // in line with other browsers. By default, pressing escape on IE will cause it to revert
    // the input value to the one that it had on focus, however it won't dispatch any events
    // which means that the model value will be out of sync with the view.
    if (keyCode === ESCAPE) {
      event.preventDefault();
    }

    // Close when pressing ESCAPE or ALT + UP_ARROW, based on the a11y guidelines.
    // See: https://www.w3.org/TR/wai-aria-practices-1.1/#textbox-keyboard-interaction
    if (
      this.panelOpen &&
      (keyCode === ESCAPE || (keyCode === UP_ARROW && event.altKey))
    ) {
      // this._resetActiveItem();
      this._closeKeyEventStream.next();
      event.stopPropagation();
    } else if (this.activeOption && keyCode === ENTER && this.panelOpen) {
      this.activeOption._selectViaInteraction();
      // this._resetActiveItem();
      event.preventDefault();
    } else {
      const prevActiveItem = this.autocomplete._keyManager.activeItem;
      const isArrowKey = keyCode === UP_ARROW || keyCode === DOWN_ARROW;

      if (this.panelOpen || keyCode === TAB) {
        this.autocomplete._keyManager.onKeydown(event);
      } else if (isArrowKey && this._canOpen()) {
        this.openPanel();
      }

      if (
        isArrowKey ||
        this.autocomplete._keyManager.activeItem !== prevActiveItem
      ) {
        // this._scrollToOption();
      }
    }
  }

  _handleInput(event: KeyboardEvent): void {
    let target = event.target as HTMLInputElement;
    let value: any = target.value;

    // Based on `NumberValueAccessor` from forms.
    if (target.type === "number") {
      value = value == "" ? null : parseFloat(value);
    }

    // If the input has a placeholder, IE will fire the `input` event on page load,
    // focus and blur, in addition to when the user actually changed the value. To
    // filter out all of the extra events, we save the value on focus and between
    // `input` events, and we check whether it changed.
    // See: https://connect.microsoft.com/IE/feedback/details/885747/
    if (
      this._previousValue !== value &&
      document.activeElement === event.target
    ) {
      this._previousValue = value;
      this._onChange(value);
      this.openPanel();
    }
  }

  _handleFocus(): void {
    if (this._canOpen()) {
      this._previousValue = this._element.nativeElement.value;
      this.autocomplete._setVisibility(true);
      // this._attachOverlay();
      // this._floatLabel(true);
      this._closingActionsSubscription = this._subscribeToClosingActions();
    }
  }

  //  private _scrollToOption(): void {
  //   // const index = this.autocomplete._keyManager.activeItemIndex || 0;
  //   // const labelCount = _countGroupLabelsBeforeOption(index,
  //   //     this.autocomplete.options, this.autocomplete.optionGroups);
  //   //
  //   // const newScrollPosition = _getOptionScrollPosition(
  //   //   index + labelCount,
  //   //   AUTOCOMPLETE_OPTION_HEIGHT,
  //   //   this.autocomplete._getScrollTop(),
  //   //   AUTOCOMPLETE_PANEL_HEIGHT
  //   // );
  //   //
  //   // this.autocomplete._setScrollTop(newScrollPosition);
  // }

  private _setTriggerValue(value: any): void {
    // //console.log('value',value)
    const toDisplay =
      this.autocomplete && this.autocomplete.displayWith
        ? this.autocomplete.displayWith(value)
        : value;

    // Simply falling back to an empty string if the display value is falsy does not work properly.
    // The display value can also be the number zero and shouldn't fall back to an empty string.
    const inputValue = toDisplay != null ? toDisplay : "";

    // If it's used within a `MatFormField`, we should set it through the property so it can go
    // through change detection.

    this._element.nativeElement.value = inputValue;
  }
  private _setValueAndClose(event: OptionSelectionChange | null): void {
    if (event && event.option && event.isUserInput) {
      // console.log('event',event)
      this._clearPreviousSelectedOption(event.option);
      this._setTriggerValue(event.option.value);
      this._onChange(event.option.value);
      this._element.nativeElement.focus();
      this.autocomplete._emitSelectEvent(event.option);
    }

    this.closePanel();
  }

  private _clearPreviousSelectedOption(skip: OptionComponent) {
    this.autocomplete.options.forEach((option) => {
      if (option != skip && option.selected) {
        option.deselect();
      }
    });
  }

  private _canOpen() {
    return true;
  }
}
