<div>
  <div class="tab-header">
    <ul #tabhead role="tablist" aria-label="Press left and right arrow keys to navigate between the tabs">
      <li [attr.tabindex]="tab.active ? 0 : -1" #tabtitles [attr.aria-selected]="tab.active" *ngFor='let tab of tabs' (click)="selectTab(tab)"
        [class.selectedTab]="tab.active" role="tab" [class.focusedTab]="tab._isFocused">{{tab.title}}</li>
    </ul>
  </div>
  <section>
    <ng-content></ng-content>
  </section>
</div>