<div class="app-search-wrapper">
  <div *ngIf="isDayHeader" class="day-header-container" id="date-list">
    <div class="cover" [ngClass]="isDateBarFixed ? 'fixedBar':'notFixedBar'"
    (scroll) = "onScroll()">
      <!-- <button class="left" (click)="leftScroll()">
        <i class="material-icons icon md-36">
            keyboard_arrow_left
        </i>
      </button> -->
      <div class="scroll-images">
        <div
          class="child day-tab"
          *ngFor="let data of datearr; let i = index"
          #tabhead
          tabindex="0"
          role="button"
          [class.day-tab-selected]="selectedTab == i"
          (click)="dateEvent(i, $event)"
          attr.aria-label="Search flight for {{dayarr[i] | locale }} and {{datearr[i]}} by hitting on enter">
          <span>{{ dayarr[i] | locale }}</span>
          <span>{{ datearr[i] }}</span>
        </div>
      </div>
      <!-- <button class="right" (click)="rightScroll()">
        <i class="material-icons icon md-36">
          keyboard_arrow_right
        </i>
      </button> -->
    </div>
  </div>
  <div *ngIf="errorCard">
    <app-error-message
      *ngIf="!loadingStatus.routeSearch && !loadingStatus.numberSearch && !loadingStatus.pnrSearch && !loadingTerminals"
      [errorMessage]="errorMessage"
    ></app-error-message>
  </div>
  <div *ngIf="!emptyResult" class="flight-status-result" id="flightResults">
    <!-- <app-flight-result
      *ngIf="!loadingStatus.routeSearch && !loadingStatus.numberSearch && !loadingStatus.pnrSearch && !loadingTerminals"
      [details]="details"
    ></app-flight-result> -->
    <app-new-flight-result
      *ngIf="!loadingStatus.routeSearch && !loadingStatus.numberSearch && !loadingStatus.pnrSearch && !loadingTerminals"
      [details]="details">
    </app-new-flight-result>
  </div>

  <!-- <div class="empty-result" *ngIf="emptyResult">
    <p>No flights found</p>
  </div> -->

  <app-error-message
      *ngIf="emptyResult"
      [errorMessage]="errorMessage"
    ></app-error-message>
</div>
