import {
  AfterContentInit,
  Component,
  HostListener,
  Input,
  OnInit,
  ViewChild,
  ViewEncapsulation,
} from "@angular/core";
import {
  ConfigService,
  ControllerService,
  FlightStatusResultService,
} from "../_services";
import { FlightStatusInteractor } from "../interactor/flight-status-result-interactor";
import { DateFormatInteractor } from "../interactor/dateFormatInteractor";
import { Observable, Subscription } from "rxjs";
import { map } from "rxjs/operators";
import { format } from "date-fns";
import { AIRPORT_TYPE } from "../_utilities/airport-terminals";
import { Utilities } from "../_utilities/utilities";
import {
  FLIGHT_STATUS,
  TERMINAL_CHANGE,
} from "../_utilities/constant-variables";
import { environment } from "../../environments/environment";
import * as moment from "moment";
import * as _ from "lodash";
import {} from "google.maps";
import { AIRPORT_LAT_LONG } from "../_utilities/airport-latlong";
import { fromEvent } from "rxjs";
import { WindowReferenceService } from "../_services/window-reference.service";

export interface Coordinates {
  x: any;
  y: any;
}
export interface InBoundFlight {
  prev_flight: string;
  prev_legcode: string;
  prev_departure: string;
  prev_arrival: string;
  prev_std: string;
  prev_etd: string;
  prev_atd: string;
  prev_sta: string;
  prev_eta: string;
  prev_arrival_formatted: string;
  prev_arrival_timein: string;
  prev_departure_formatted: string;
  prev_departure_timein: string;
  prev_ata: string;
  prev_status: string;
  flight_map_flag: boolean;
}

export class Popup extends google.maps.OverlayView {
  position;
  containerDiv;
  constructor(position, content) {
    super();
    this.position = position;
    content.classList.add("popup-bubble");
    // This zero-height div is positioned at the bottom of the bubble.
    const bubbleAnchor = document.createElement("div");

    bubbleAnchor.classList.add("popup-bubble-anchor");
    bubbleAnchor.appendChild(content);
    // This zero-height div is positioned at the bottom of the tip.
    this.containerDiv = document.createElement("div");
    this.containerDiv.classList.add("popup-container");
    this.containerDiv.appendChild(bubbleAnchor);
    // Optionally stop clicks, etc., from bubbling up to the map.
    Popup.preventMapHitsAndGesturesFrom(this.containerDiv);
  }
  ngOnInit(): void {
    throw new Error("Method not implemented.");
  }
  /** Called when the popup is added to the map. */
  onAdd() {
    this.getPanes().floatPane.appendChild(this.containerDiv);
  }
  /** Called when the popup is removed from the map. */
  onRemove() {
    if (this.containerDiv.parentElement) {
      this.containerDiv.parentElement.removeChild(this.containerDiv);
    }
  }
  /** Called each frame when the popup needs to draw itself. */
  draw() {
    const divPosition = this.getProjection().fromLatLngToDivPixel(
      this.position
    );
    // Hide the popup when it is far out of view.
    const display =
      Math.abs(divPosition.x) < 4000 && Math.abs(divPosition.y) < 4000
        ? "block"
        : "none";

    if (display === "block") {
      this.containerDiv.style.left = divPosition.x + "px";
      this.containerDiv.style.top = divPosition.y + "px";
    }

    if (this.containerDiv.style.display !== display) {
      this.containerDiv.style.display = display;
    }
  }
}

@Component({
  selector: "app-new-flight-result",
  templateUrl: "./new-flight-result.component.html",
  styleUrls: ["./new-flight-result.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class NewFlightResultComponent implements OnInit, AfterContentInit {
  stations$: Observable<any[]>;
  usersearch: any;
  @ViewChild("flightMapCanvas", { static: false }) mapElement: any;
  map: google.maps.Map;
  animLoop: any;
  animIndex = 0;
  animInboundIndex = 0;
  planePath: any;
  trailPath: any;
  inBoundTrailPath: any;
  planeSymbol: any;
  inBoundPlanePath: any;
  places: any;
  progressPercentage: number = 0;
  inBoundProgressPercentage: number = 0;
  hideMap: boolean = true;
  airport_latlong = AIRPORT_LAT_LONG;
  valid_airport_latlong = [];
  curvature = 0.5;
  pos1: google.maps.LatLng;
  pos2: google.maps.LatLng;
  currentFlightDeparture: google.maps.LatLng;
  currentFlightArrival: google.maps.LatLng;
  inBoundDeparture: google.maps.LatLng;
  inBoundArrival: google.maps.LatLng;

  markerP1: google.maps.Marker;
  markerP2: google.maps.Marker;
  inBoundMarkerP1: google.maps.Marker;
  inBoundMarkerP2: google.maps.Marker;
  currentMarkerP1: google.maps.Marker;
  currentMarkerP2: google.maps.Marker;
  curveMarker: google.maps.Marker;
  bounds: google.maps.LatLngBounds;

  isInBoundFlight: boolean = true;

  currentFlightDepartureBubble: any;
  currentFlightArrivalBubble: any;
  inBoundDepartureBubble: any;

  isSearchByFlightNumber: boolean = false;
  isMapInitialized: boolean = false;
  isMapLoaded: boolean = false;

  outofSyncIssue: boolean = false;
  globalOutofSyncIssue: boolean = false; //for both tab in FE => search by number and search by route
  outofSyncIssueSearchByRoute: Array<boolean> = [];
  inBoundFlightsData = {} as InBoundFlight;

  bottomSheet = false;

  defaultTouch = { x: 0, y: 0, time: 0 };

  isMobileScreen = false;
  loadedCount = 0;
  eventSubscription: Subscription;

  constructor(
    private configService: ConfigService,
    private controllerService: ControllerService,
    private flightStatusInteractor: FlightStatusInteractor,
    private dateFormatInteractor: DateFormatInteractor,
    private flightStatusResult: FlightStatusResultService,
    private _windowRef: WindowReferenceService
  ) {
    this.stations$ = this.controllerService.originStationsList$.pipe(
      map((station) => station.slice())
    );
    this.airport_latlong.filter((v: AIRPORT_LAT_LONG) => {
      if (v.lat != "") {
        this.valid_airport_latlong.push(v);
      }
    });
    this.isSearchByFlightNumber =
      this.flightStatusResult.stateSearch["searchByFlightNumber"];
  }
  show = 10;
  @Input() details: any[] = [];
  allSub: Subscription[] = [];
  departTimeDisplayed: any = "";
  arriveTimeDisplayed: any = "";
  greaterthanten = true;
  showplus = this.flightStatusInteractor.spiloverlable;
  FLIGHT_STATUS = FLIGHT_STATUS;
  tomorrow = moment().add(1, "days").endOf("day");

  onWindowScroll(): void {
    const resultContainer = document.getElementById("new-flight-result");
    const containerPosStarts = window.scrollY + 200;
    if (!this.hideMap && containerPosStarts < resultContainer.offsetTop) {
      this.bottomSheet = false;
    }
  }

  get formattedLastUpdatedCache() {
    return moment(this.details[0].lastUpdatedCache).format("DD MMM YYYY HH:mm");
  }

  ngOnInit() {
    this.setStaticPath();

    this.eventSubscription = fromEvent(window, "scroll").subscribe((e) => {
      this.onWindowScroll();
    });

    // process displayed depart and arrive time
    this.stations$.subscribe((terminals) => {
      this.details = this.details.map((detail, flightIndex) => {
        const departTimeDisplayed = (() => {
          switch (true) {
            case detail.flightTKOFF != null && detail.flightTKOFF != "":
              return detail.flightTKOFF;

            case detail.flight_dep_actual_time != null &&
              detail.flight_dep_actual_time != "":
              return detail.flight_dep_actual_time;

            case detail.flight_dep_estimated_time != null &&
              detail.flight_dep_estimated_time != "":
              return detail.flight_dep_estimated_time;

            case detail.flight_dep_scheduled_time != null &&
              detail.flight_dep_scheduled_time != "":
              return detail.flight_dep_scheduled_time;
          }
        })();

        //STD
        const scheduledDepartTimeDisplayed = (() => {
          switch (true) {
            case detail.flight_dep_scheduled_time != null &&
              detail.flight_dep_scheduled_time != "":
              return detail.flight_dep_scheduled_time;
          }
        })();

        const actualDepartTimeDisplayed = (() => {
          switch (true) {
            case detail.flight_dep_actual_time != null &&
              detail.flight_dep_actual_time != "":
              return detail.flight_dep_actual_time;
          }
        })();

        const actualUTCDepartTimeDisplayed = (() => {
          switch (true) {
            case detail.flight_dep_actual_UTC != null &&
              detail.flight_dep_actual_UTC != "":
              return detail.flight_dep_actual_UTC;
          }
        })();

        const scheduledUTCDepartTimeDisplayed = (() => {
          switch (true) {
            case detail.flight_dep_scheduled_UTC != null &&
              detail.flight_dep_scheduled_UTC != "":
              return detail.flight_dep_scheduled_UTC;
          }
        })();

        const actualUTCArrivalTimeDisplayed = (() => {
          switch (true) {
            case detail.flight_arr_actual_UTC != null &&
              detail.flight_arr_actual_UTC != "":
              return detail.flight_arr_actual_UTC;
          }
        })();

        const estimatedArrivalTimeDisplayed = (() => {
          switch (true) {
            case detail.flight_arr_estimated_time != null &&
              detail.flight_arr_estimated_time != "":
              return detail.flight_arr_estimated_time;
          }
        })();

        const estimatedUTCDepartureTimeDisplayed = (() => {
          switch (true) {
            case detail.flight_dep_estimated_UTC != null &&
              detail.flight_dep_estimated_UTC != "":
              // console.log(moment.utc(detail.flight_dep_estimated_UTC).local().format())
              return moment
                .utc(detail.flight_dep_estimated_UTC)
                .local()
                .format("HH:mm");
          }
          return null;
        })();

        const scheduledUTCArrivalTimeDisplayed = (() => {
          switch (true) {
            case detail.flight_arr_scheduled_UTC != null &&
              detail.flight_arr_scheduled_UTC != "":
              return detail.flight_arr_scheduled_UTC;
          }
        })();

        const arriveTimeDisplayed = (() => {
          switch (true) {
            case detail.flightTDOWN != null && detail.flightTDOWN != "":
              return detail.flightTDOWN;

            case detail.flight_arr_actual_time != null &&
              detail.flight_arr_actual_time != "":
              return detail.flight_arr_actual_time;

            case detail.flight_arr_estimated_time != null &&
              detail.flight_arr_estimated_time != "":
              return detail.flight_arr_estimated_time;

            case detail.flight_arr_scheduled_time != null &&
              detail.flight_arr_scheduled_time != "":
              return detail.flight_arr_scheduled_time;
          }
        })();
        //STA
        const scheduledArriveTimeDisplayed = (() => {
          switch (true) {
            case detail.flight_arr_scheduled_time != null &&
              detail.flight_arr_scheduled_time != "":
              return detail.flight_arr_scheduled_time;
          }
        })();

        const arriveDateDisplayed = (() => {
          let displayed = "";
          switch (true) {
            case detail.flightTDOWN_date != null &&
              detail.flightTDOWN_date != "":
              displayed = detail.flightTDOWN_date;
              break;
            case detail.flight_arr_actual_date != null &&
              detail.flight_arr_actual_date != "":
              displayed = detail.flight_arr_actual_date;
              break;
            case detail.flight_arr_estimated_date != null &&
              detail.flight_arr_estimated_date != "":
              displayed = detail.flight_arr_estimated_date;
              break;
            case detail.flight_arr_scheduled_date != null &&
              detail.flight_arr_scheduled_date != "":
              displayed = detail.flight_arr_scheduled_date;
              break;
          }
          return displayed;
        })();

        const departDateDisplayed = (() => {
          let displayed = "";
          switch (true) {
            case detail.flightTKOFF_date != null &&
              detail.flightTKOFF_date != "":
              displayed = detail.flightTKOFF_date;
              break;
            case detail.flight_dep_actual_date != null &&
              detail.flight_dep_actual_date != "":
              displayed = detail.flight_dep_actual_date;
              break;
            case detail.flight_dep_estimated_date != null &&
              detail.flight_dep_estimated_date != "":
              displayed = detail.flight_dep_estimated_date;
              break;
            case detail.flight_dep_scheduled_date != null &&
              detail.flight_dep_scheduled_date != "":
              displayed = detail.flight_dep_scheduled_date;
              break;
          }

          return displayed;
        })();

        const deptTerminalDetails: AIRPORT_TYPE | undefined = detail.depart
          ? Utilities.getAirportDetails(detail.depart)
          : Utilities.getAirportDetails(detail.DepartureStation);

        const arriveTerminalDetails: AIRPORT_TYPE | undefined = detail.arrival
          ? Utilities.getAirportDetails(detail.arrival)
          : Utilities.getAirportDetails(detail.ArrivalStation);

        const departureTerminalDisplayed = (() => {
          /// REMOVE THIS IN NEXT RELEASE if we pulling terminal data from BQ level
          if (
            moment(detail.flight_arr_scheduled_full).isAfter(
              moment(TERMINAL_CHANGE.CAN.DATE, TERMINAL_CHANGE.CAN.FORMAT)
            ) &&
            detail.depart === "CAN"
          ) {
            return deptTerminalDetails
              ? deptTerminalDetails.NewDepartureTerminal
              : "";
          } else {
            return deptTerminalDetails
              ? deptTerminalDetails.DepartureTerminal
              : "";
          }
          /// REMOVE THIS IN NEXT RELEASE if we pulling terminal data from BQ level

          // return deptTerminalDetails
          //   ? deptTerminalDetails.DepartureTerminal
          //   : "";
        })();

        const arrivalTerminalDisplayed = (() => {
          /// REMOVE THIS IN NEXT RELEASE if we pulling terminal data from BQ level
          if (
            moment(detail.flight_arr_scheduled_full).isAfter(
              moment(TERMINAL_CHANGE.CAN.DATE, TERMINAL_CHANGE.CAN.FORMAT)
            ) &&
            detail.arrival === "CAN"
          ) {
            return arriveTerminalDetails
              ? arriveTerminalDetails.NewDepartureTerminal
              : "";
          } else {
            return arriveTerminalDetails
              ? arriveTerminalDetails.ArrivalTerminal
              : "";
          }
          /// REMOVE THIS IN NEXT RELEASE if we pulling terminal data from BQ level

          return arriveTerminalDetails
            ? arriveTerminalDetails.ArrivalTerminal
            : "";
        })();

        const flightProgress = (() => {
          if (
            detail.status != FLIGHT_STATUS.DEPART &&
            detail.status != FLIGHT_STATUS.DEPARTED_ON_TIME
          ) {
            return null;
          }
          const startTime = moment.utc(scheduledUTCDepartTimeDisplayed);
          const endTime = moment.utc(scheduledUTCArrivalTimeDisplayed);
          const totalJourneyInminutes = this.timeDiffInMinutes(
            startTime,
            endTime
          );
          console.log(totalJourneyInminutes);
          const currentTime = moment(new Date(), "YYYY-MM-DD HH:mm:ss").utc();
          const actualDeparture = moment.utc(actualUTCDepartTimeDisplayed);
          const currentJourneyProgressInminutes = this.timeDiffInMinutes(
            actualDeparture,
            currentTime
          );
          let progress = Math.floor(
            (currentJourneyProgressInminutes / totalJourneyInminutes) * 100
          );
          //if status is not updated after landing - manually set to 100%
          if (progress > 100) {
            progress = 100;
          } else if (progress < 0) {
            //impossible scenario
            progress = 0;
          } else if (
            isNaN(progress) ||
            progress === null ||
            progress === undefined
          ) {
            //if any of utc values are missing or null
            progress = 50;
            // return `50%`;
          }
          document.documentElement.style.setProperty(
            "--progress-bar",
            `${progress}%`
          );
          this.progressPercentage = progress;
          return `${progress}%`;
        })();

        const isEarlyDepart = (() => {
          const startTime = moment(scheduledDepartTimeDisplayed, "HH:mm a");
          const endTime = moment(actualDepartTimeDisplayed, "HH:mm a");
          const duration = moment.duration(endTime.diff(startTime));
          const hours = Math.floor(duration.asHours());
          const minutes = duration.asMinutes() % 60;
          if (hours < 0 || minutes < 0) {
            // if early depart
            return true;
          } else {
            return false;
          }
          // return hours ? `${hours}h ${minutes}m` : `${minutes}m`;
        })();

        const getDelayInMinutes = (() => {
          const startTime = moment(detail.flight_dep_scheduled_UTC);
          const endTime = moment(
            detail.flight_dep_estimated_UTC || detail.flight_dep_actual_UTC
          );
          const duration = moment.duration(endTime.diff(startTime));
          const hours = Math.floor(duration.asHours());
          const minutes = duration.asMinutes() % 60;
          return hours ? `${hours}h ${minutes}m` : `${minutes}m`;
        })();

        const isSpillOverArrivalScheduled = (() => {
          return this.isDateDiff(
            detail.flight_dep_scheduled_date,
            detail.flight_arr_scheduled_date
          );
        })();

        const isSpillOverArrivalActualEstimated = (() => {
          return this.isDateDiff(
            detail.flight_dep_scheduled_date,
            arriveDateDisplayed
          );
        })();

        const isSpillOverDepart = (() => {
          return this.isDateDiff(
            detail.flight_dep_scheduled_date,
            departDateDisplayed
          );
        })();

        const baggageCarousel = (() => {
          return detail.baggageID;
        })();

        const boardingGate = (() => {
          return detail.boardGate;
        })();

        const checkinCounter = (() => {
          let checkinTableStr;
          let checkinTableArr;
          let result = "";

          if (
            !_.isEmpty(detail.checkinTable) &&
            !detail.checkinTable.includes("-")
          ) {
            return detail.checkinTable; //U,W,X,Y,Z
          } else if (_.isEmpty(detail.checkinTable)) {
            return result; // no checkin
          } else {
            checkinTableStr = detail.checkinTable;
            checkinTableArr = checkinTableStr.match(/[^,]+,[^,]+/g);
            if (checkinTableArr === null) {
              result = checkinTableStr;
            } else {
              //splits into two
              result = checkinTableArr.join(", ");
            }
          }
          return result;

          // return detail.checkinTable
          // ? detail.checkinTable.split(",").join(", ")
          // : "";
        })();

        const isDiverted = (() => {
          return (
            this.isSearchByFlightNumber &&
            detail.status === FLIGHT_STATUS.DIVERTED
          );
        })();

        const flightsFlyAtSameRouteCount = (() => {
          return this.getAllDepartedFlights().length;
        })();

        const inBoundFlights = (() => {
          this.inBoundFlightsData = {
            prev_flight: `${detail.prev_carrierCode}${detail.prev_flightnumber}`,
            prev_legcode: detail.prev_legcode,
            prev_departure: detail.prev_departurestation,
            prev_arrival: detail.prev_arrivalstation,
            prev_std: detail.prev_std,
            prev_etd: detail.prev_etd,
            prev_atd: detail.prev_atd,
            prev_sta: detail.prev_sta,
            prev_eta: detail.prev_eta,
            prev_ata: detail.prev_ata,
            prev_arrival_formatted: !_.isEmpty(detail.prev_ata)
              ? moment.utc(detail.prev_ata).local().format("HH:mm")
              : !_.isEmpty(detail.prev_eta)
              ? moment.utc(detail.prev_eta).local().format("HH:mm")
              : !_.isEmpty(detail.prev_sta)
              ? moment.utc(detail.prev_sta).local().format("HH:mm")
              : null,
            prev_arrival_timein: !_.isEmpty(detail.prev_ata)
              ? "ATA"
              : !_.isEmpty(detail.prev_eta)
              ? "ETA"
              : !_.isEmpty(detail.prev_sta)
              ? "STA"
              : null,
            prev_departure_formatted: !_.isEmpty(detail.prev_atd)
              ? moment.utc(detail.prev_atd).local().format("HH:mm")
              : !_.isEmpty(detail.prev_etd)
              ? moment.utc(detail.prev_etd).local().format("HH:mm")
              : !_.isEmpty(detail.prev_std)
              ? moment.utc(detail.prev_std).local().format("HH:mm")
              : null,
            prev_departure_timein: !_.isEmpty(detail.prev_atd)
              ? "ATD"
              : !_.isEmpty(detail.prev_etd)
              ? "ETD"
              : !_.isEmpty(detail.prev_std)
              ? "STD"
              : null,
            prev_status: FLIGHT_STATUS.DEPART,
            flight_map_flag: detail.flight_map_flag === "False" ? false : true,
          };
          return this.inBoundFlightsData;
        })();

        const inBoundFlightSTA_UTC = (() => {
          switch (true) {
            case detail.prev_sta != null && detail.prev_sta != "":
              return detail.prev_sta;
          }
        })();

        const inBoundFlightSTD_UTC = (() => {
          switch (true) {
            case detail.prev_std != null && detail.prev_std != "":
              return detail.prev_std;
          }
        })();

        const isMapShouldShow = (() => {
          let isMapShouldShow = false;
          const current_flight_std_utc = moment.utc(
            detail.flight_dep_scheduled_UTC
          );
          const current_flight_sta_utc = moment.utc(
            detail.flight_arr_scheduled_UTC
          );
          const currentTime = moment(new Date(), "YYYY-MM-DD HH:mm:ss").utc();
          const priorDeparture2hrs = current_flight_std_utc.subtract(2, "hour");
          isMapShouldShow = currentTime.isBetween(
            priorDeparture2hrs,
            current_flight_sta_utc
          );
          console.log(priorDeparture2hrs.format());
          console.log(currentTime.format());
          console.log(isMapShouldShow);
          return isMapShouldShow;
        })();

        const isBKIOnly = (() => {
          let isBKIOnlyShow = false;
          const depart = detail.depart;
          const arrival = detail.arrival;
          isBKIOnlyShow = arrival === "BKI" || depart === "BKI";
          console.log(isBKIOnlyShow);
          return isBKIOnlyShow;
        })();

        const inBoundFlightProgress = (() => {
          console.log(inBoundFlights.prev_status);
          if (inBoundFlights.prev_status != FLIGHT_STATUS.DEPART) {
            return null;
          }
          const startTime = moment.utc(inBoundFlightSTD_UTC);
          const endTime = moment.utc(inBoundFlightSTA_UTC);
          const totalJourneyInminutes = this.timeDiffInMinutes(
            startTime,
            endTime
          );
          const currentTime = moment(new Date(), "YYYY-MM-DD HH:mm:ss").utc();
          const actualDeparture = moment.utc(inBoundFlights.prev_atd);
          const currentJourneyProgressInminutes = this.timeDiffInMinutes(
            actualDeparture,
            currentTime
          );
          let progress = Math.floor(
            (currentJourneyProgressInminutes / totalJourneyInminutes) * 100
          );
          //if status is not updated after landing - manually set to 100%
          if (progress > 100) {
            progress = 100;
          } else if (progress < 0) {
            //impossible scenario
            progress = 0;
          } else if (
            isNaN(progress) ||
            progress === null ||
            progress === undefined
          ) {
            //if any of utc values are missing or null
            progress = 50;
          }
          this.inBoundProgressPercentage = progress;
          if (progress > 100) {
            this.inBoundFlightsData.prev_status = "Landed";
          }
          console.log(this.inBoundFlightsData);
          return `${progress}%`;
        })();

        const isDBoutOfSync = (() => {
          const flightSTD = moment(detail.flight_dep_scheduled_date);
          let currentDate = moment();
          let past2days = moment()
            .add(-3, "days")
            .startOf("day")
            .format("YYYY-MM-DD");
          let future2days = moment()
            .add(3, "days")
            .endOf("day")
            .format("YYYY-MM-DD");

          const lastSyncedInHours = Math.ceil(
            this.isDateDiffUTC(
              detail.flight_dep_scheduled_date,
              detail.lastUpdatedCache
            )[1]
          );
          if (flightSTD.isBetween(past2days, future2days)) {
            console.log(
              "is between T-2 & T+2 :::" +
                flightSTD.isBetween(past2days, future2days)
            );
            console.log(
              "X > -48 && X < 48 ::::" +
                (lastSyncedInHours > -48 && lastSyncedInHours < 48)
            );
            console.log(lastSyncedInHours);
            // if(this.isSearchByFlightNumber) {
            //   this.outofSyncIssue = !(lastSyncedInHours > -48 && lastSyncedInHours < 48);
            // } else {
            //   this.outofSyncIssueSearchByRoute[flightIndex] = !(lastSyncedInHours > -48 && lastSyncedInHours < 48);
            // }
            this.outofSyncIssueSearchByRoute[flightIndex] = !(
              lastSyncedInHours > -48 && lastSyncedInHours < 48
            );
          }
          console.log("sync flight:" + detail.flightName);
          console.log(
            "%csync: " + this.outofSyncIssueSearchByRoute[flightIndex],
            "background-color: yellow"
          );
          // return this.outofSyncIssue;
        })();

        const isDBoutOfSyncFlag = (() => {
          const dbSynconRoute = _.uniq(this.outofSyncIssueSearchByRoute);
          // console.log(this.outofSyncIssueSearchByRoute.some((f) => f == false))
          console.log(this.outofSyncIssueSearchByRoute);
          console.log(dbSynconRoute.length);
          console.log(dbSynconRoute.join(""));
          this.globalOutofSyncIssue = false;
          if (dbSynconRoute.length === 1 && dbSynconRoute.join("") === "true") {
            // return true;
            this.globalOutofSyncIssue = true;
          }
          // return false;
          console.log("globalOutofSyncIssue:" + this.globalOutofSyncIssue);
        })();

        return {
          ...detail,
          departTimeDisplayed,
          arriveTimeDisplayed,
          scheduledDepartTimeDisplayed,
          scheduledArriveTimeDisplayed,
          departDateDisplayed,
          arriveDateDisplayed,
          departureTerminalDisplayed,
          arrivalTerminalDisplayed,
          isEarlyDepart,
          flightProgress,
          getDelayInMinutes,
          isSpillOverArrivalScheduled,
          isSpillOverArrivalActualEstimated,
          isSpillOverDepart,
          baggageCarousel,
          boardingGate,
          checkinCounter,
          isDiverted,
          flightsFlyAtSameRouteCount,
          inBoundFlights,
          inBoundFlightProgress,
          scheduledUTCArrivalTimeDisplayed,
          estimatedUTCDepartureTimeDisplayed,
          isDBoutOfSync,
          isDBoutOfSyncFlag,
          estimatedArrivalTimeDisplayed,
          isMapShouldShow,
          isBKIOnly,
          deptStationDisplay:
            (deptTerminalDetails && deptTerminalDetails.AirportName) || "",
          arriveStationDisplay:
            (arriveTerminalDetails && arriveTerminalDetails.AirportName) || "",
        };
      });
    });

    // check screen width
    const screenWidth = screen.width;
    this.isMobileScreen = screenWidth < 767;
  }

  ngAfterContentInit(): void {
    //load map
    this.loadFlightMap();
  }

  setStaticPath(): void {
    const imageStorage = environment.imageStorage;
    console.log(imageStorage);
    document.documentElement.style.setProperty(
      "--bg-green",
      `url(${imageStorage}/green-small-flight.png)`
    );
    document.documentElement.style.setProperty(
      "--bg-yellow",
      `url(${imageStorage}/yellow-small-flight.png)`
    );
    document.documentElement.style.setProperty(
      "--bg-red",
      `url(${imageStorage}/red-small-flight.png)`
    );
    document.documentElement.style.setProperty(
      "--bg-inboundFlight",
      `url(${imageStorage}/inbound-flight.png)`
    );
  }

  inBoundState(state: string) {
    const flightNumber = this.details[0].inBoundFlights.prev_flight.replace(
      /\s/g,
      ""
    );
    switch (state) {
      case "first-rotation":
        return (
          this.details[0].inBoundFlights.flight_map_flag &&
          _.isEmpty(flightNumber)
        );
      case "inbound-landed":
        return (
          this.details[0].inBoundFlights.flight_map_flag &&
          !_.isEmpty(this.details[0].inBoundFlights.prev_ata) &&
          !_.isEmpty(flightNumber)
        );
      case "inbound-on-air":
        return (
          this.details[0].inBoundFlights.flight_map_flag &&
          _.isEmpty(this.details[0].inBoundFlights.prev_ata) &&
          !_.isEmpty(flightNumber)
        );
    }
  }

  timeDiffInMinutes(startTime: any, endTime: any): number {
    const blockDuration = moment.duration(endTime.diff(startTime));
    const hours = Math.floor(blockDuration.asHours());
    console.log(`${hours}h ${Math.floor(blockDuration.asMinutes() % 60)}m`);
    return hours
      ? hours * 60 + Math.floor(blockDuration.asMinutes() % 60)
      : Math.floor(blockDuration.asMinutes() % 60);
  }

  convertUTCtoLocal(utcTime): moment.Moment {
    const stillUtc = moment.utc(utcTime).toDate();
    const local = moment(stillUtc).local();
    return local.endOf("day");
  }

  isDateDiff(departureDate, arrivalDate): number {
    let now = moment(arrivalDate);
    let end = moment(departureDate);
    let duration = moment.duration(now.diff(end));
    let days = duration.asDays();
    return days;
  }

  isDateDiffUTC(startDate, endDate): Array<number> {
    let start = moment(startDate).utc();
    let end = moment(endDate).utc();
    let duration = moment.duration(end.diff(start));
    let days = duration.asDays();
    let hours = duration.asHours();
    return [days, hours];
  }

  ngOnDestroy() {
    for (let sub of this.allSub) {
      if (sub) sub.unsubscribe();
    }
  }

  getAllDepartedFlights(): Array<any> {
    let departedFlightsList = [];
    this.details.map((detail) => {
      if (
        detail.status === FLIGHT_STATUS.DEPART ||
        detail.status === FLIGHT_STATUS.DEPARTED_ON_TIME
      ) {
        departedFlightsList.push(detail);
      }
    });
    return departedFlightsList;
  }

  openMobileSheet(): void {
    this.bottomSheet = !this.bottomSheet;
  }

  loadFlightMap(): void {
    this.setCurrentFlightArrDepart();

    const inBoundFlight = this.details[0].inBoundFlights;
    this.isInBoundFlight = false;

    if (!_.isEmpty(inBoundFlight)) {
      console.log(inBoundFlight);
      this.isInBoundFlight =
        inBoundFlight.flight_map_flag &&
        inBoundFlight.prev_departure &&
        !_.isEmpty(inBoundFlight.prev_departure.trim());
      // this.isInBoundFlight = true;
    }
    console.log(this.isInBoundFlight);
    if (this.isInBoundFlight) {
      const inBoundDeparture = this.getLatLong(inBoundFlight.prev_departure);
      const inBoundArrival = this.getLatLong(inBoundFlight.prev_arrival);
      this.inBoundDeparture = new google.maps.LatLng(
        inBoundDeparture[0],
        inBoundDeparture[1]
      );
      this.inBoundArrival = new google.maps.LatLng(
        inBoundArrival[0],
        inBoundArrival[1]
      );
    }

    this.bounds = new google.maps.LatLngBounds();
    if (!this.isMapInitialized) {
      this.isMapInitialized = true;

      this.bounds.extend(this.currentFlightDeparture);
      this.bounds.extend(this.currentFlightArrival);

      setTimeout(() => {
        if (!_.isEmpty(this.mapElement)) {
          this.map = new google.maps.Map(this.mapElement.nativeElement, {
            center: this.bounds.getCenter(),
            zoom: 12,
            disableDefaultUI: true,
            disableDoubleClickZoom: true,
            streetViewControl: false,
            styles: [
              {
                featureType: "all",
                elementType: "all",
                stylers: [{ visibility: "off" }],
              },
              {
                featureType: "all",
                elementType: "labels",
                stylers: [{ visibility: "off" }, { saturation: "-100" }],
              },
              {
                featureType: "all",
                elementType: "labels.text.fill",
                stylers: [
                  { saturation: 36 },
                  { color: "#000000" },
                  { lightness: 40 },
                  { visibility: "off" },
                ],
              },
              {
                featureType: "all",
                elementType: "labels.text.stroke",
                stylers: [
                  { visibility: "off" },
                  { color: "#000000" },
                  { lightness: 16 },
                ],
              },
              {
                featureType: "all",
                elementType: "labels.icon",
                stylers: [{ visibility: "off" }],
              },
              {
                featureType: "administrative",
                elementType: "geometry.fill",
                stylers: [{ color: "#000000" }, { lightness: 20 }],
              },
              {
                featureType: "administrative",
                elementType: "geometry.stroke",
                stylers: [
                  { color: "#000000" },
                  { lightness: 17 },
                  { weight: 1.2 },
                ],
              },
              {
                featureType: "landscape",
                elementType: "geometry",
                stylers: [{ color: "#000000" }, { lightness: 20 }],
              },
              {
                featureType: "landscape",
                elementType: "geometry.fill",
                stylers: [{ color: "#e5e3df" }],
              },
              {
                featureType: "landscape",
                elementType: "geometry.stroke",
                stylers: [{ color: "#e5e3df" }],
              },
              {
                featureType: "landscape.natural",
                elementType: "geometry.fill",
                stylers: [{ color: "#e5e3df" }],
              },
              {
                featureType: "poi",
                elementType: "geometry",
                stylers: [{ lightness: 21 }],
              },
              {
                featureType: "poi",
                elementType: "geometry.fill",
                stylers: [{ color: "#e5e3df" }],
              },
              {
                featureType: "poi",
                elementType: "geometry.stroke",
                stylers: [{ color: "#e5e3df" }],
              },
              {
                featureType: "road",
                elementType: "geometry",
                stylers: [{ visibility: "off" }, { color: "#7f8d89" }],
              },
              {
                featureType: "road",
                elementType: "geometry.fill",
                stylers: [{ color: "#7f8d89" }],
              },
              {
                featureType: "road.highway",
                elementType: "geometry.fill",
                stylers: [{ color: "#7f8d89" }, { lightness: 17 }],
              },
              {
                featureType: "road.highway",
                elementType: "geometry.stroke",
                stylers: [
                  { color: "#7f8d89" },
                  { lightness: 29 },
                  { weight: 0.2 },
                ],
              },
              {
                featureType: "road.arterial",
                elementType: "geometry",
                stylers: [{ color: "#000000" }, { lightness: 18 }],
              },
              {
                featureType: "road.arterial",
                elementType: "geometry.fill",
                stylers: [{ color: "#7f8d89" }],
              },
              {
                featureType: "road.arterial",
                elementType: "geometry.stroke",
                stylers: [{ color: "#7f8d89" }],
              },
              {
                featureType: "road.local",
                elementType: "geometry",
                stylers: [{ color: "#000000" }, { lightness: 16 }],
              },
              {
                featureType: "road.local",
                elementType: "geometry.fill",
                stylers: [{ color: "#7f8d89" }],
              },
              {
                featureType: "road.local",
                elementType: "geometry.stroke",
                stylers: [{ color: "#7f8d89" }],
              },
              {
                featureType: "transit",
                elementType: "geometry",
                stylers: [{ color: "#000000" }, { lightness: 19 }],
              },
              {
                featureType: "water",
                elementType: "all",
                stylers: [{ color: "#2b3638" }, { visibility: "on" }],
              },
              {
                featureType: "water",
                elementType: "geometry",
                stylers: [{ color: "#2b3638" }, { lightness: 17 }],
              },
              {
                featureType: "water",
                elementType: "geometry.fill",
                stylers: [{ color: "#9fd3d2" }],
              },
              {
                featureType: "water",
                elementType: "geometry.stroke",
                stylers: [{ color: "#9fd3d2" }],
              },
              {
                featureType: "water",
                elementType: "labels",
                stylers: [{ visibility: "off" }],
              },
              {
                featureType: "water",
                elementType: "labels.text",
                stylers: [{ visibility: "off" }],
              },
              {
                featureType: "water",
                elementType: "labels.text.fill",
                stylers: [{ visibility: "off" }],
              },
              {
                featureType: "water",
                elementType: "labels.text.stroke",
                stylers: [{ visibility: "off" }],
              },
              {
                featureType: "water",
                elementType: "labels.icon",
                stylers: [{ visibility: "off" }],
              },
            ],
          });
        }
      }, 1000);
    }

    //plane symbol
    this.planeSymbol = {
      path: "M22.1,15.1c0,0-1.4-1.3-3-3l0-1.9c0-0.2-0.2-0.4-0.4-0.4l-0.5,0c-0.2,0-0.4,0.2-0.4,0.4l0,0.7c-0.5-0.5-1.1-1.1-1.6-1.6l0-1.5c0-0.2-0.2-0.4-0.4-0.4l-0.4,0c-0.2,0-0.4,0.2-0.4,0.4l0,0.3c-1-0.9-1.8-1.7-2-1.9c-0.3-0.2-0.5-0.3-0.6-0.4l-0.3-3.8c0-0.2-0.3-0.9-1.1-0.9c-0.8,0-1.1,0.8-1.1,0.9L9.7,6.1C9.5,6.2,9.4,6.3,9.2,6.4c-0.3,0.2-1,0.9-2,1.9l0-0.3c0-0.2-0.2-0.4-0.4-0.4l-0.4,0C6.2,7.5,6,7.7,6,7.9l0,1.5c-0.5,0.5-1.1,1-1.6,1.6l0-0.7c0-0.2-0.2-0.4-0.4-0.4l-0.5,0c-0.2,0-0.4,0.2-0.4,0.4l0,1.9c-1.7,1.6-3,3-3,3c0,0.1,0,1.2,0,1.2s0.2,0.4,0.5,0.4s4.6-4.4,7.8-4.7c0.7,0,1.1-0.1,1.4,0l0.3,5.8l-2.5,2.2c0,0-0.2,1.1,0,1.1c0.2,0.1,0.6,0,0.7-0.2c0.1-0.2,0.6-0.2,1.4-0.4c0.2,0,0.4-0.1,0.5-0.2c0.1,0.2,0.2,0.4,0.7,0.4c0.5,0,0.6-0.2,0.7-0.4c0.1,0.1,0.3,0.1,0.5,0.2c0.8,0.2,1.3,0.2,1.4,0.4c0.1,0.2,0.6,0.3,0.7,0.2c0.2-0.1,0-1.1,0-1.1l-2.5-2.2l0.3-5.7c0.3-0.3,0.7-0.1,1.6-0.1c3.3,0.3,7.6,4.7,7.8,4.7c0.3,0,0.5-0.4,0.5-0.4S22,15.3,22.1,15.1z",
      fillColor: "#c82828",
      fillOpacity: 1.5,
      scale: 1.2,
      anchor: new google.maps.Point(11, 11),
      strokeColor: "#000000",
      strokeWeight: 1,
    };
  }

  setCurrentFlightArrDepart(): void {
    //get LatLong for current flight
    const currentFlightDeparture = this.getLatLong(this.details[0].depart);
    const currentFlightArrival = this.getLatLong(this.details[0].arrival);
    this.currentFlightDeparture = new google.maps.LatLng(
      currentFlightDeparture[0],
      currentFlightDeparture[1]
    );
    this.currentFlightArrival = new google.maps.LatLng(
      currentFlightArrival[0],
      currentFlightArrival[1]
    );
  }

  setBoundary(): void {
    this.bounds.extend(this.currentFlightDeparture);
    this.bounds.extend(this.currentFlightArrival);
    if (this.isInBoundFlight) {
      this.bounds.extend(this.inBoundDeparture);
      this.bounds.extend(this.inBoundArrival);
    }

    // this.isMapInitialized ? this.map.setZoom(6) : null;
    // let zoom = this.map.getZoom(),
    //     scale = 1 / (Math.pow(2, -zoom));
    // console.log()
    // this.map.setOptions({ zoom: scale, center: this.bounds.getCenter() });
    if (!this.isMapLoaded) {
      this.map.fitBounds(this.bounds);
    }
    // if(!this.isMapLoaded) {
    //   this.map.setOptions({ minZoom: 6, maxZoom: 12, center: this.bounds.getCenter() });
    // }
  }

  setCurrentFlightMarkers(): void {
    this.currentMarkerP1 = new google.maps.Marker({
      position: this.currentFlightDeparture,
      draggable: true,
      map: this.map,
    });
    this.currentMarkerP2 = new google.maps.Marker({
      position: this.currentFlightArrival,
      draggable: true,
      map: this.map,
    });

    let lineLength = google.maps.geometry.spherical.computeDistanceBetween(
      this.currentMarkerP1.getPosition(),
      this.currentMarkerP2.getPosition()
    );
    let lineHeading = google.maps.geometry.spherical.computeHeading(
      this.currentMarkerP1.getPosition(),
      this.currentMarkerP2.getPosition()
    );
    let markerA = new google.maps.Marker({
      position: google.maps.geometry.spherical.computeOffset(
        this.currentMarkerP1.getPosition(),
        lineLength / 3,
        lineHeading - 60
      ),
      map: this.map,
      icon: {
        url: "https://maps.gstatic.com/intl/en_us/mapfiles/markers2/measle_blue.png",
        size: new google.maps.Size(1, 1),
        anchor: new google.maps.Point(3.5, 3.5),
      },
    });
    let markerB = new google.maps.Marker({
      position: google.maps.geometry.spherical.computeOffset(
        this.currentMarkerP2.getPosition(),
        lineLength / 3,
        -lineHeading + 120
      ),
      icon: {
        url: "https://maps.gstatic.com/intl/en_us/mapfiles/markers2/measle_blue.png",
        size: new google.maps.Size(1, 1),
        anchor: new google.maps.Point(3.5, 3.5),
      },
      map: this.map,
    });

    this.gmapsCubicBezier(
      this.currentMarkerP1.getPosition(),
      markerA.getPosition(),
      markerB.getPosition(),
      this.currentMarkerP2.getPosition(),
      0.01
    );
  }

  setLocationPopup(): void {
    // set bubble popup info of current flight
    this.currentFlightDepartureBubble = new Popup(
      this.currentFlightDeparture,
      document.getElementById("from") as HTMLElement
    );
    this.currentFlightDepartureBubble.setMap(this.map);

    this.currentFlightArrivalBubble = new Popup(
      this.currentFlightArrival,
      document.getElementById("to") as HTMLElement
    );
    this.currentFlightArrivalBubble.setMap(this.map);

    if (this.isInBoundFlight) {
      this.inBoundDepartureBubble = new Popup(
        this.inBoundDeparture,
        document.getElementById("inboundFrom") as HTMLElement
      );
      this.inBoundDepartureBubble.setMap(this.map);
    }
  }

  setInBoundFlightMarkers(): void {
    this.inBoundMarkerP1 = new google.maps.Marker({
      position: this.inBoundDeparture,
      draggable: true,
      map: this.map,
    });
    this.inBoundMarkerP2 = new google.maps.Marker({
      position: this.inBoundArrival,
      draggable: true,
      map: this.map,
    });

    let lineLength = google.maps.geometry.spherical.computeDistanceBetween(
      this.inBoundMarkerP1.getPosition(),
      this.inBoundMarkerP2.getPosition()
    );
    let lineHeading = google.maps.geometry.spherical.computeHeading(
      this.inBoundMarkerP1.getPosition(),
      this.inBoundMarkerP2.getPosition()
    );
    let markerC = new google.maps.Marker({
      position: google.maps.geometry.spherical.computeOffset(
        this.inBoundMarkerP1.getPosition(),
        lineLength / 3,
        lineHeading - 60
      ),
      map: this.map,
      icon: {
        url: "https://maps.gstatic.com/intl/en_us/mapfiles/markers2/measle_blue.png",
        size: new google.maps.Size(7, 7),
        anchor: new google.maps.Point(3.5, 3.5),
      },
    });
    let markerD = new google.maps.Marker({
      position: google.maps.geometry.spherical.computeOffset(
        this.inBoundMarkerP2.getPosition(),
        lineLength / 3,
        -lineHeading + 120
      ),
      icon: {
        url: "https://maps.gstatic.com/intl/en_us/mapfiles/markers2/measle_blue.png",
        size: new google.maps.Size(7, 7),
        anchor: new google.maps.Point(3.5, 3.5),
      },
      map: this.map,
    });

    // this.inBoundGmapsCubicBezier(this.inBoundMarkerP1.getPosition(), markerC.getPosition(), markerD.getPosition(), this.inBoundMarkerP2.getPosition(), 0.01);
  }

  toggleMap() {
    if (this.hideMap && this.isSearchByFlightNumber) {
      this.hideMap = false;
      this.bottomSheet = false;
      this._windowRef.pushEventsToGTMOnWhereIsMyAircraft();

      this.setBoundary();

      // if(this.isInBoundFlight) {
      //  this.setInBoundFlightMarkers();
      // }

      this.loadedCount += 1;

      if (this.isMapInitialized && this.loadedCount === 1) {
        this.setLocationPopup();
      }

      if (this.isInBoundFlight && this.loadedCount === 1) {
        this.inBoundTrailPath = null;
        this.inBoundPlanePath = null;
        this.inBoundTrailPath = new google.maps.Polyline({
          path: [this.inBoundDeparture, this.inBoundArrival],
          strokeColor: "#c82828",
          strokeOpacity: 1.0,
          strokeWeight: 5,
          map: this.map,
          geodesic: true,
          zIndex: 13,
        });

        this.inBoundPlanePath = new google.maps.Polyline({
          path: [this.inBoundDeparture, this.inBoundArrival],
          strokeColor: "#000000",
          strokeOpacity: 0.3,
          strokeWeight: 5,
          icons: [
            {
              icon: this.planeSymbol,
              offset: "0%",
            },
          ],
          zIndex: 15,
          map: this.map,
          geodesic: true,
        });

        this.drawInBoundPath(this.inBoundDeparture, this.inBoundArrival);
      }

      this.setCurrentFlightMarkers();
      this.isMapLoaded = true;
    } else {
      this.hideMap = true;
      this.loadFlightMap();
    }
  }

  blinkFlightIcon(polyLine): void {
    let count = 0;
    let icons = polyLine.get("icons");
    setInterval(() => {
      count += 1;

      if (count % 2 === 0) {
        icons = [
          {
            icon: this.planeSymbol,
            offset: this.inBoundProgressPercentage,
          },
        ];
      } else {
        icons[0].icon = null;
      }
      polyLine.set("icons", icons);
    }, 1000);
  }

  gmapsCubicBezier(latlong1, latlong2, latlong3, latlong4, resolution): any {
    let lat1 = latlong1.lat();
    let long1 = latlong1.lng();
    let lat2 = latlong2.lat();
    let long2 = latlong2.lng();
    let lat3 = latlong3.lat();
    let long3 = latlong3.lng();
    let lat4 = latlong4.lat();
    let long4 = latlong4.lng();

    let points = [];

    for (let it = 0; it <= 1; it += resolution) {
      points.push(
        this.getBezier(
          {
            x: lat1,
            y: long1,
          },
          {
            x: lat2,
            y: long2,
          },
          {
            x: lat3,
            y: long3,
          },
          {
            x: lat4,
            y: long4,
          },
          it
        )
      );
    }
    let path = [];
    for (let i = 0; i < points.length - 1; i++) {
      path.push(new google.maps.LatLng(points[i].x, points[i].y));
      path.push(
        new google.maps.LatLng(points[i + 1].x, points[i + 1].y, false)
      );
    }

    const lineSymbol = {
      path: "M 0,-2 0,0.5",
      strokeOpacity: 1,
      strokeWeight: 2,
      scale: 4,
    };

    // next current flight anim feature
    // this.trailPath = new google.maps.Polyline({
    //   path: path,
    //   geodesic: true,
    //   strokeOpacity: 0,
    //   strokeColor: 'grey',
    //   icons:this.isInBoundFlight ?  [{
    //     icon: {
    //       path: 'M 0,-1 0,1',
    //       strokeOpacity: 1,
    //       scale: 4
    //     },
    //     offset: '0',
    //     repeat: '20px'
    //   }] : [],
    //   map: this.map,
    // });

    // this.planePath = new google.maps.Polyline({
    //   path: path.reverse(),
    //   strokeOpacity: 1.0,
    //   strokeWeight: 5,
    //   icons:  [{
    //     icon: this.isInBoundFlight ? {} : this.planeSymbol,
    //     offset: '0%'
    //   }],
    //   map: this.map,
    //   geodesic: true
    // });

    this.trailPath = new google.maps.Polyline({
      path: path,
      geodesic: true,
      strokeOpacity: 0,
      icons: this.isInBoundFlight
        ? [
            {
              icon: {
                path: "M 0,-1 0,1",
                strokeColor: "#494646",
                strokeOpacity: 1,
                scale: 4,
              },
              offset: "0",
              repeat: "20px",
            },
          ]
        : [],
      map: this.map,
    });

    // this.trailPath = new google.maps.Polyline({
    // path: path.reverse(),
    // strokeOpacity: 0.4,
    // strokeWeight: 5,
    // strokeColor: '#ff0000',
    // icons:this.isInBoundFlight ?  [{
    //   icon: {
    //     path: 'M 0,-1 0,1',
    //     strokeOpacity: 0.5,
    //     scale: 4
    //   },
    //   offset: '0',
    //   repeat: '20px'
    // }] : [],
    // map: this.map,
    // geodesic: true
    // });

    // this.drawPath(path[0], path[0]);
  }

  B1(t: number) {
    return t * t * t;
  }
  B2(t: number) {
    return 3 * t * t * (1 - t);
  }
  B3(t: number) {
    return 3 * t * (1 - t) * (1 - t);
  }
  B4(t: number) {
    return (1 - t) * (1 - t) * (1 - t);
  }
  getBezier(
    C1: Coordinates,
    C2: Coordinates,
    C3: Coordinates,
    C4: Coordinates,
    percent: number
  ) {
    let pos = { x: 0, y: 0 };
    pos.x =
      C1.x * this.B1(percent) +
      C2.x * this.B2(percent) +
      C3.x * this.B3(percent) +
      C4.x * this.B4(percent);
    pos.y =
      C1.y * this.B1(percent) +
      C2.y * this.B2(percent) +
      C3.y * this.B3(percent) +
      C4.y * this.B4(percent);
    return pos;
  }

  // getDistance(p1,p2) {
  //   var R = 6378137; // Earth’s mean radius in meter
  //   var dLat = this.rad(p2.lat() - p1.lat());
  //   var dLong = this.rad(p2.lng() - p1.lng());
  //   var a = Math.sin(dLat / 2) * Math.sin(dLat / 2) +
  //     Math.cos(this.rad(p1.lat())) * Math.cos(this.rad(p2.lat())) *
  //     Math.sin(dLong / 2) * Math.sin(dLong / 2);
  //   var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  //   var d = R * c;
  //   return d; // returns the distance in meter
  // }

  // rad(x: number) {
  //   return x * Math.PI / 180;
  // };

  getLatLong(stationCode): Array<number> {
    let station: AIRPORT_LAT_LONG;
    console.log(station);
    station = this.valid_airport_latlong.find(
      (v: AIRPORT_LAT_LONG) => v.stationcode === stationCode
    );
    return [this.formatLatLong(station.lat), this.formatLatLong(station.long)];
  }

  formatLatLong(latlong: string): number {
    let direction = latlong.slice(latlong.length - 1, latlong.length) as string;
    let checksum = direction.toLocaleUpperCase() == "S" ? "-" : "+";
    let coordinates = latlong.slice(0, latlong.length - 1) as string;
    let degrees = +coordinates.slice(-1 * latlong.length, -4);
    let min = +coordinates.slice(-4, -2) / 60;
    let sec = +coordinates.slice(-2) / 3600;
    return checksum == "-"
      ? -(degrees + min + sec).toFixed(5)
      : +(degrees + min + sec).toFixed(5);
  }

  drawPath(startPoint, endPoint) {
    const percentage = this.progressPercentage;
    this.animIndex = percentage;
    if (this.details[0].status === "Landed") {
      this.animIndex = 100;
    }
    // Draw trail
    var nextPoint = google.maps.geometry.spherical.interpolate(
      startPoint,
      endPoint,
      this.animIndex / 100
    );
    this.trailPath.setPath([startPoint, nextPoint]);

    // Move the plane
    this.planePath.icons[0].offset = Math.min(this.animIndex, 100) + "%";
    this.planePath.setPath(this.planePath.getPath());
  }

  drawInBoundPath(startPoint, endPoint) {
    const percentage = this.inBoundProgressPercentage;
    this.animInboundIndex = percentage;
    // Draw trail
    var nextPoint = google.maps.geometry.spherical.interpolate(
      startPoint,
      endPoint,
      this.animInboundIndex / 100
    );
    this.inBoundTrailPath.setPath([startPoint, nextPoint]);

    let count = 0;
    let icons = this.inBoundPlanePath.get("icons");

    // Move the plane
    this.inBoundPlanePath.icons[0].offset =
      Math.min(
        this.animInboundIndex !== 100
          ? this.animInboundIndex + this.map.getZoom() / (1.5 * 5)
          : this.animInboundIndex,
        100
      ) + "%";

    setInterval(() => {
      count += 1;

      if (count % 2 === 0) {
        this.planeSymbol.scale = 1.2;
        icons = [
          {
            icon: this.planeSymbol,
            offset:
              Math.min(
                this.animInboundIndex !== 100
                  ? this.animInboundIndex + this.map.getZoom() / (1.5 * 5)
                  : this.animInboundIndex,
                100
              ) + "%",
          },
        ];
      } else {
        this.planeSymbol.scale = 0;
        icons[0].icon = this.planeSymbol;
      }
      this.inBoundPlanePath.set("icons", icons);
    }, 1000);
    this.inBoundPlanePath.setPath(this.inBoundPlanePath.getPath());
  }
}
