<div class="loading-fare-container">
    <div class="row">
      <div class="col" [ngClass]="'s12 m12 l12'">
        <div class="fare-content">
          <div class="fare-loader">
            <div class="fare-barLoader"></div>
          </div>
          <div class="loading-fare-header"></div>

          <div class="loading-fare-header"></div>
          <div class="loading-fare-header"></div><div class="loading-fare-header"></div>
          <div class="small-loader"></div>
        </div>
      </div>
    </div>
  </div>
