import { BrowserModule, Meta } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import {ReactiveFormsModule,FormsModule} from '@angular/forms';
import {CdkTableModule} from '@angular/cdk/table';
import { AppComponent } from './app.component';
import { FlightSearchComponent } from './aa-flight-search/aa-flight-search.component';
import { SearchTabsComponent } from './aa-search-tabs/aa-search-tabs.component';
import { AaRouteSearchComponent } from './aa-route-search/aa-route-search.component';
import { FlightStatusModule } from '../app/_modules/app-routing.module';
import { HeaderModule } from 'aa-universal-header-footer';
import { FooterModule } from 'aa-universal-header-footer';
import { MatFormFieldModule } from "@angular/material/form-field";

import {
  LookupService,
  ConfigService,
  BaseService,
  ControllerService,
  FlightStatusResultService,
  NewLocaleService
} from './_services'
import { HttpClientModule, HTTP_INTERCEPTORS} from '@angular/common/http';
// import { HttpClient } from '@angular/common/http';
import { TabsComponent } from './shared/aa-tabs/aa-tabs.component';
import { TabComponent } from './shared/aa-tab/aa-tab.component';
import { AaFlightNumberComponent } from './aa-flight-number/aa-flight-number.component';
import { AutoComplete } from './shared/aa-auto-complete/aa-auto-complete.component';
import {A11yModule} from '@angular/cdk/a11y';
import { SlickModule } from 'ngx-slick';


import { DayHeaderComponent } from './day-header/day-header.component';
import { FlightResultComponent } from './flight-result/flight-result.component';
import { UnderMaintenanceComponent } from "./aa-under-maintenance/aa-under-maintenance.component";
import { NotificationComponent } from "./notification/notification.component";
import { FlightStatusInteractor } from "./interactor/flight-status-result-interactor";
import { DateFormatInteractor } from "./interactor/dateFormatInteractor";
import { ErrorMessageComponent } from "./error-message/error-message.component";
import { AaResultsSkeletonComponent } from "./aa-results-skeleton/aa-results-skeleton.component";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { LocalePipe } from "./_pipes/locale.pipe";

import {
  MatIconModule,
  MatButtonModule,
  MatButtonToggleModule,
  MatListModule,
  MatSidenavModule,
  MatSliderModule,
  MatSlideToggleModule,
  MatSnackBarModule,
  MatSortModule,
  MatStepperModule,
  MatTableModule,
  MatTabsModule,
  MatToolbarModule,
  MatTooltipModule,
  MatDialogModule,
  MatAutocompleteModule,
  MatInputModule,
  MAT_DIALOG_DATA,
  MatDialogRef,
} from "@angular/material";
import {
  MatDatepickerModule,
  // MatDatepickerToggle
} from "@angular/material/datepicker";
import {
  MatNativeDateModule,
  MAT_DATE_LOCALE,
  // MAT_DATE_FORMATS,
  NativeDateAdapter,
  DateAdapter,
} from "@angular/material/core";
// import { NewAutoComponent } from './shared/new-auto/new-auto.component';
// import { TestComponent } from './test/test.component';
import { TriggerDirective } from "./shared/aa-auto-complete/auto-complete-trigger.directive";
import { OptionComponent } from "./shared/aa-auto-complete/option/option.component";
import { MockData } from "./mock";
import { LocalJson } from "./_locale/locale-en-GB";
import { AaPnrSearchComponent } from "./aa-pnr-search/aa-pnr-search.component";
import { NewFlightResultComponent } from './new-flight-result/new-flight-result.component';
import { CustomTimePipe } from './_pipes/time.pipe';

@NgModule({
  exports: [
    CdkTableModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatIconModule,
    MatListModule,
    MatSidenavModule,
    MatToolbarModule,
    MatAutocompleteModule,
    MatInputModule,
    MatFormFieldModule
  ],
})
export class DemoMaterialModule {}

@NgModule({
  declarations: [
    AppComponent,
    FlightSearchComponent,
    NotificationComponent,
    SearchTabsComponent,
    AaRouteSearchComponent,
    TabsComponent,
    TabComponent,
    AaFlightNumberComponent,
    AaPnrSearchComponent,
    AutoComplete,
    OptionComponent,
    DayHeaderComponent,
    FlightResultComponent,
    ErrorMessageComponent,
    LocalePipe,
    CustomTimePipe,
    TriggerDirective,
    AaResultsSkeletonComponent,
    UnderMaintenanceComponent,
    NewFlightResultComponent
  ],
  imports: [
    BrowserModule,
    ReactiveFormsModule,
    FormsModule,
    HttpClientModule,
    A11yModule,
    BrowserAnimationsModule,
    DemoMaterialModule,
    FlightStatusModule,
    MatDialogModule,
    HeaderModule,
    FooterModule,
    SlickModule.forRoot(),
    MatDatepickerModule,
    MatNativeDateModule,
  ],
  providers: [
    Meta,
    BaseService,
    ConfigService,
    LookupService,
    ControllerService,
    FlightStatusResultService,
    FlightStatusInteractor,
    DateFormatInteractor,
    NewLocaleService,
    MockData,
    LocalJson,
    { provide: MAT_DIALOG_DATA, useValue: {} },
    { provide: MAT_DATE_LOCALE, useValue: "en-GB" },
    { provide: MatDialogRef, useValue: {} },
  ],
  bootstrap: [AppComponent],
  entryComponents: [],
})
export class AppModule {}
