import { MOBILE_WIDTH, TABLET_WIDTH } from "./constant-variables";
import { AIRPORT_DETAILS } from "./airport-terminals";

declare let $: any;
export class Utilities {
  static TruncateString(completeString: string, from: number, to: number) {
    if (!!completeString && completeString.length >= to) {
      return completeString.substring(from, to);
    } else {
      return "";
    }
  }

  static Formatter(format, args) {
    return format.replace(/{(\d+)}/g, function (match, number) {
      return typeof args[number] !== "undefined" ? args[number] : match;
    });
  }

  static isMobileDevice(): boolean {
    return /iphone|ipad|ipod|android|blackberry|mini|windows\sce|palm/i.test(
      navigator.userAgent.toLowerCase()
    );
  }

  static isMobileDeviceOnly(): boolean {
    return /(?=.*(iphone|ipod|android|blackberry|mini|windows\sce|palm|mobile))(?=.*mobile)(^((?!(?=.*ipad)).)*$)/i.test(
      navigator.userAgent.toLowerCase()
    );
  }

  static isTabletDeviceOnly(): boolean {
    return /(?=.*ipad)|(?=.*(android|tablet))(^((?!(?=.*mobile)).)*$)/i.test(
      navigator.userAgent.toLowerCase()
    );
  }

  static isMobileSize(): boolean {
    return window.innerWidth <= MOBILE_WIDTH ? true : false;
  }

  static isTabletSize(): boolean {
    return window.innerWidth <= TABLET_WIDTH && window.innerWidth > MOBILE_WIDTH
      ? true
      : false;
  }

  static CalculateTotalDisplay(totalCost: number, totalPaid: number) {
    let balanceDue = totalCost - totalPaid;

    if (balanceDue < 0) {
      return 0;
    }

    return balanceDue;
  }

  static MergeObj(obj, defaults = null) {
    var length = arguments.length;
    if (defaults) obj = Object(obj);
    if (length < 2 || obj == null) return obj;
    for (var index = 1; index < length; index++) {
      var source = arguments[index],
        keys = Object.keys(source),
        l = keys.length;
      for (var i = 0; i < l; i++) {
        var key = keys[i];
        if (!defaults || obj[key] === void 0) obj[key] = source[key];
      }
    }
    return obj;
  }

  //copied from user story 14542
  static insertParams(url: string, args: string[]) {
    if (!url) {
      return;
    }
    let splittedUrl = url.split("?");
    if (splittedUrl.length < 1) {
      return;
    }
    let appendUrl = splittedUrl[0] + "?";
    if (splittedUrl.length > 1) {
      let paramList = splittedUrl[1].split("&");

      paramList.forEach((param, index) => {
        if (index !== 0) {
          appendUrl += "&";
        }
        appendUrl += param.replace(`\{${index}\}`, args[index]);
      });
    }
    return appendUrl;
  }

  /**
   * Transforms lowercase region code to uppercase
   * Example: 'en-gb' --> 'en-GB'
   */
  static getUpperCaseRegionCultureCode(cultureCode: string) {
    if (!cultureCode) {
      return;
    }

    let code = cultureCode.split("-");

    if (code.length !== 2) {
      return;
    }

    return `${code[0]}-${code[1].toUpperCase()}`;
  }

  /**
   * @returns common string value between 2 arrays
   */
  static intersectStringArray(array1: string[], array2: string[]): string[] {
    return array1.filter((a1) => array2.some((a2) => a2 === a1));
  }

  static sortAscending(records: Array<any>, sortByProperty: string) {
    if (!records) {
      return;
    }

    if (!sortByProperty) {
      return;
    }

    return records.sort(function (a, b) {
      if (!sortByProperty || sortByProperty.length < 1) {
        return 0;
      }

      let propertySort: string = sortByProperty;
      let propertyList: string[] = propertySort.split("|");

      propertyList.forEach((property) => {
        if (!a || !a[property] || !b || !b[property]) {
          return;
        }
        a = a[property];
        b = b[property];
      });

      if (a < b) {
        return -1;
      } else if (a > b) {
        return 1;
      } else {
        return 0;
      }
    });
  }

  static countSubstrOccurence(main_str: string, sub_str: string): number {
    if (!main_str || !sub_str) {
      return 0;
    }

    main_str += "";
    sub_str += "";

    if (sub_str.length <= 0) {
      return main_str.length + 1;
    }

    let subStr = sub_str.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
    return (main_str.match(new RegExp(subStr, "gi")) || []).length;
  }

  static generateRandomIdNum() {
    let newNumber = Math.floor(Math.random() * 100000);

    return newNumber;
  }

  // Accept only alpha numerics, not special characters
  static alphaNumberOnly(e: any) {
    const regex = new RegExp("^[a-zA-Z0-9 ]+$");
    const str = String.fromCharCode(!e.charCode ? e.which : e.charCode);
    if (regex.test(str)) {
      return true;
    }

    e.preventDefault();
    return false;
  }

  /**
   *
   * @param stationCode
   * @returns
   */
  static getAirportDetails(stationCode: string = "") {
    let airportDetail = AIRPORT_DETAILS.find(
      (airport) => airport.IATACode.toLowerCase() === stationCode.toLowerCase()
    );

    if (airportDetail) {
      if (
        airportDetail.AirportName.toLowerCase() ===
        airportDetail.ArrivalTerminal.toLowerCase()
      )
        airportDetail.ArrivalTerminal = airportDetail.ArrivalTerminal;
      if (
        airportDetail.AirportName.toLowerCase() ===
        airportDetail.DepartureTerminal.toLowerCase()
      )
        airportDetail.DepartureTerminal = airportDetail.DepartureTerminal;
    }

    return airportDetail;
  }
}
