import { Injectable } from '@angular/core';
import { ReplaySubject ,  Subject ,  from }    from 'rxjs';
import { filter,map, switchMap } from 'rxjs/operators';
import {BaseService} from './_base.service'

@Injectable()
export class ControllerService {
  public originStations = new ReplaySubject<any[]>(1);
  originStationsList$ = this.originStations.asObservable();

  public search = new Subject<any>();
  search$ = this.search.asObservable();

  public searchByDate = new Subject<any>();
  searchByDate$ = this.searchByDate.asObservable();

  public clearSearch = new Subject<any>();
  clearSearch$ = this.clearSearch.asObservable();

  constructor(public base: BaseService) {
    // this.gcsUrl = configSvc.getGcsUrl()
  }

  getSearchDate(request) {
    this.searchByDate.next(request);
  }

  setOriginStations(list) {
    var stations = [];
    list.map((item) => {
      if (item.StationType == "A") {
        stations.push(item);
      }
    });
    this.originStations.next(stations);
  }

  public getLoadingStatus() {
    return this.base.loading$;
  }
  getCityName(code) {
    return this.originStationsList$.pipe(
      switchMap((stations) =>
        from(stations).pipe(
          filter(({ StationCode }) => code === StationCode),
          map(({ StationName }) => StationName)
        )
      )
    );
  }
  searchFlights(request) {
    this.search.next(request);
  }
  clearSearchFlights() {
    this.clearSearch.next();
  }
}
