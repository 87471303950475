import { Component, OnInit } from "@angular/core";
import { ControllerService } from "../_services/controller.service";
import { DEEPLINK_TYPE } from "../_utilities/constant-variables";

@Component({
  selector: "aa-search-tabs",
  templateUrl: "./aa-search-tabs.component.html",
  styleUrls: ["./aa-search-tabs.component.scss"],
})
export class SearchTabsComponent implements OnInit {
  isSearchFlightNumber: boolean = false;
  isSearchRoute: boolean = false;
  isSearchBookingNumber: boolean = false;
  constructor(private controllerService: ControllerService) {}
  ngOnInit() {
    const deeplinkSearchByPNR = sessionStorage.getItem(DEEPLINK_TYPE.SESSION_KEY);
    if (deeplinkSearchByPNR === DEEPLINK_TYPE.SEARCH_BY_PNR) {
      this.isSearchFlightNumber = false;
      this.isSearchRoute = false;
      this.isSearchBookingNumber = true;
    }
    else if (deeplinkSearchByPNR === DEEPLINK_TYPE.SEARCH_BY_FLIGHT) {
      this.isSearchFlightNumber = true;
      this.isSearchRoute = false;
      this.isSearchBookingNumber = false;
    }
  }
}
