<div class="search-container">
  <!-- Flight Number  -->
  <div
    class="field-auto-width input-field flightNumber"
    [class.tooltip]="!isError"
    [class.isError]="isError"
    [class.active]="flightNumFocus"
  >
    <input
      type="text"
      [style.width]="'100%'"
      #Number
      (blur)="setFlightNumber(Number.value)"
      aria-label="number"
      name="flightNumber"
      id="flightNumber"
      autocomplete="off"
      (focus)="setFocus('flightNumFocus', true)"
      maxlength="6"
      (input)="alphaNumberOnly($event)" (keypress)="alphaNumberOnly($event)"
    />
    <div class="icon-container" [class.tooltipError]="isError">
      <i class="material-icons icon md-36" [class.isErrorIcon]="isError">
        {{ isError ? "error" : " " }}
      </i>
      <div *ngIf="isError && errorValue">
        <span class="tooltiptextError">
          <p class="p-cls-Error">
            {{ "errorMessage-invalidFlightNumber" | locale }}
          </p>
        </span>
      </div>
    </div>
    <div *ngIf="!isError">
      <span class="tooltiptext">
        <p class="p-cls">{{ "search-flightNumberTooltip" | locale }}</p>
      </span>
    </div>
    <label
      [attr.for]="'flightNumber'"
      [class]="flightNumFocus || flightNumber ? 'active' : ''"
      >{{ "search-flightNumber" | locale }}</label
    >
  </div>

  <!-- Date  -->
  <!-- <div class="datepickers-container"> -->
    <div class="field-auto-width auto-container">
      <div class="fromDateBox">
        <label>{{ "search-departureDate" | locale }}</label>
        <input
          #PickerFromInput
          class="matDateInput"
          [min]="minDate"
          [max]="maxDate"
          matInput
          (dateInput)="setStartDate($event.value)"
          [matDatepicker]="pickerFrom"
          [placeholder]='"search-departureDate" | locale'
          readonly
          (click)="pickerFrom.open()"
        />
        <mat-datepicker-toggle
          matSuffix
          [for]="pickerFrom"
          [disabled]="false"
        ></mat-datepicker-toggle>
        <mat-datepicker
          #pickerFrom
          [disabled]="false"
          [touchUi]="isMobileSize"
        ></mat-datepicker>
      </div>
    </div>

    <!-- <div class="auto-container">
      <div class="toDateBox">
        <input
          #PickerToInput
          class="matDateInput"
          [min]="startDate || minDate"
          matInput
          (dateInput)="setEndDate($event.value)"
          [matDatepicker]="pickerTo"
          [placeholder]='"search-endDate" | locale'
          disabled
        />
        <mat-datepicker-toggle
          matSuffix
          [for]="pickerTo"
          [disabled]="false"
        ></mat-datepicker-toggle>
        <mat-datepicker
          #pickerTo
          [disabled]="false"
          [touchUi]="isMobileSize"
        ></mat-datepicker>
      </div>
    </div> -->
  <!-- </div> -->

  <!-- Find Button -->
  <button class="field-auto-width findButton" (click)="search()">{{ "search-findFlights" | locale }}</button>
</div>
