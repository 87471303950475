<!-- <div class="abc"> -->
<div
  class="wrapper"
  [attr.data-locale]="currentLocale"
  *ngIf="!isUnderMaintenance"
>
  <div class="search-container">
    <app-notification></app-notification>
    <h1>{{ "search-flightstatus" | locale }}</h1>
    <p class="small-text">
      Search by flight number or route for flight status information.
    </p>
    <p class="small-text">
      For airport and terminal information, please search in at
      <a href="{{ env?.airportTerminalPageUrl }}" target="_blank"
        >Airports and Terminals</a
      >
    </p>
    <div class="tabs-container">
      <aa-search-tabs></aa-search-tabs>
    </div>
    <!-- <div *ngIf = "loadingStatus.originError">
          <app-error-message [errorMessage] = "'fs_text_error_no_origins'"></app-error-message>
      </div> -->
    <div class="results-container">
      <app-day-header></app-day-header>
      <ng-container *ngIf="loadingStatus.routeSearch">
        <aa-results-skeleton
          *ngFor="let x of loadersArray"
        ></aa-results-skeleton>
      </ng-container>
      <ng-container *ngIf="loadingStatus.numberSearch">
        <aa-results-skeleton></aa-results-skeleton>
      </ng-container>
      <ng-container *ngIf="loadingStatus.pnrSearch">
        <aa-results-skeleton></aa-results-skeleton>
      </ng-container>
    </div>
  </div>

  <div id="prime" class="search-container" style="padding: 0.5vw"></div>
  <!-- <footer class="footer"> -->
  <!-- </footer> -->
  <ng-container *ngIf="showAppHeaderFooter">
    <aa-footer [isSeparatorRequired]="true"></aa-footer>
  </ng-container>
</div>
<!-- </div> -->

<!-- <div class="search-container">
    <h1>Flight Status</h1>
    <div class="tabs-container">
      <aa-search-tabs></aa-search-tabs>

    </div>
    <div class="results-container">
      <app-day-header></app-day-header>
      <ng-container *ngIf="loadingStatus.routeSearch">
        <aa-results-skeleton *ngFor='let x of loadersArray'></aa-results-skeleton>
      </ng-container>
      <ng-container *ngIf="loadingStatus.numberSearch">
        <aa-results-skeleton></aa-results-skeleton>
      </ng-container>
    </div>
  </div>

  <div *ngIf="loadingStatus.routeSearch != true">
    <div class="footerBox">
      <aa-footer [isSticky]="numberOfFlight <= 1" [isSeparatorRequired]="true" [widthMode]="'less'"></aa-footer>
    </div>
  </div> -->
<div
  class="wrapper"
  [attr.data-locale]="currentLocale"
  *ngIf="isUnderMaintenance"
>
  <aa-under-maintenance></aa-under-maintenance>
  <ng-container *ngIf="showAppHeaderFooter">
    <aa-footer [isSeparatorRequired]="true"></aa-footer>
  </ng-container>
</div>
