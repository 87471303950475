import { Injectable } from "@angular/core";
import { ConfigService } from "./config.service";
import { BaseService } from "./_base.service";

@Injectable()
export class LookupService {
  gcsUrl: string;

  constructor(private configSvc: ConfigService, private base: BaseService) {
    this.gcsUrl = configSvc.getGcsUrl();
  }
  // public getCultureCode(): string {
  //   // if (UserSessionCookies) {
  //   //   return !UserSessionCookies.getCultureCode() ? 'en-gb' : UserSessionCookies.getCultureCode();
  //   // }
  //   return 'en-gb'; //default value
  // }
  public getOriginStationDetailsPerCulture(cultureCode: string): any {
    // let cultureCode = this.getCultureCode()
    let url = this.gcsUrl + `/station/${cultureCode.toLowerCase()}/file.json`;
    return this.base.get(url, "origin");
  }

  public getDestinationDetails(stationCode: string): any {
    if (!stationCode) return;
    // let url = this.gcsUrl + `/destinationv2/${stationCode.toLowerCase()}/file.json`
    let url =
      this.gcsUrl + `/destination/${stationCode.toLowerCase()}/file.json`;
    return this.base.get(url, "destination");
  }

  public getCarrierCodes(): string[] {
    let codes = [
      "AK",
      "D7",
      "FD",
      "I5",
      "DJ",
      "PQ",
      "QZ",
      "XJ",
      "XT",
      "Z2",
      "KT",
    ];
    return codes;
  }
}
