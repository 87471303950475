
export type AIRPORT_LAT_LONG = {
  stationcode: string;
  stationname: string;
  lat: string;
  long: string;
};

export const AIRPORT_LAT_LONG: AIRPORT_LAT_LONG[] =[
  {
    "stationcode": "JOG",
    "stationname": "Yogyakarta",
    "lat": "074717S",
    "long": "1102554E"
  },
  {
    "stationcode": "SOC",
    "stationname": "Solo",
    "lat": "073057S",
    "long": "1104524E"
  },
  {
    "stationcode": "YIA",
    "stationname": "Yogyakarta - Kulon Progo",
    "lat": "075436S",
    "long": "1100412E"
  },
  {
    "stationcode": "BDO",
    "stationname": "Bandung",
    "lat": "065402S",
    "long": "1073435E"
  },
  {
    "stationcode": "BTJ",
    "stationname": "Banda Aceh",
    "lat": "053125N",
    "long": "0952513E"
  },
  {
    "stationcode": "PLM",
    "stationname": "Palembang",
    "lat": "025354S",
    "long": "1044200E"
  },
  {
    "stationcode": "CGK",
    "stationname": "Jakarta",
    "lat": "060732S",
    "long": "1063921E"
  },
  {
    "stationcode": "SUB",
    "stationname": "Surabaya",
    "lat": "071305S",
    "long": "1124315E"
  },
  {
    "stationcode": "MES",
    "stationname": "Medan",
    "lat": "033329N",
    "long": "0984018E"
  },
  {
    "stationcode": "PDG",
    "stationname": "Padang",
    "lat": "005230S",
    "long": "1002107E"
  },
  {
    "stationcode": "BTH",
    "stationname": "Batam",
    "lat": "010716N",
    "long": "1040708E"
  },
  {
    "stationcode": "DTB",
    "stationname": "Silangit Airport",
    "lat": "021535N",
    "long": "0985915E"
  },
  {
    "stationcode": "PKU",
    "stationname": "Pekanbaru",
    "lat": "002739N",
    "long": "1012640E"
  },
  {
    "stationcode": "BPN",
    "stationname": "Balikpapan",
    "lat": "011606S",
    "long": "1165340E"
  },
  {
    "stationcode": "MDC",
    "stationname": "Manado",
    "lat": "013257N",
    "long": "1245535E"
  },
  {
    "stationcode": "DPS",
    "stationname": "Bali",
    "lat": "084455S",
    "long": "1150954E"
  },
  {
    "stationcode": "UPG",
    "stationname": "Makassar",
    "lat": "050400S",
    "long": "1193300E"
  },
  {
    "stationcode": "DWC",
    "stationname": "Al Maktoum International",
    "lat": "245346N",
    "long": "0550936E"
  },
  {
    "stationcode": "DXB",
    "stationname": "Dubai Airport",
    "lat": "251510N",
    "long": "0552152E"
  },
  {
    "stationcode": "SHJ",
    "stationname": "Sharjah Airport",
    "lat": "251943N",
    "long": "0553102E"
  },
  {
    "stationcode": "AUH",
    "stationname": "Abu Dhabi",
    "lat": "242559N",
    "long": "0543904E"
  },
  {
    "stationcode": "SJJ",
    "stationname": "Butmir Airport",
    "lat": "434929N",
    "long": "0181953E"
  },
  {
    "stationcode": "DAC",
    "stationname": "Dhaka",
    "lat": "235036N",
    "long": "0902352E"
  },
  {
    "stationcode": "BWN",
    "stationname": "Brunei",
    "lat": "045639N",
    "long": "1145542E"
  },
  {
    "stationcode": "SZX",
    "stationname": "Shenzhen T3",
    "lat": "223845N",
    "long": "1134908E"
  },
  {
    "stationcode": "HRB",
    "stationname": "Harbin Airport",
    "lat": "453724N",
    "long": "1261501E"
  },
  {
    "stationcode": "MXZ",
    "stationname": "Mei Xian Airport",
    "lat": "241551N",
    "long": "1160554E"
  },
  {
    "stationcode": "CGO",
    "stationname": "Zhengzhou Airport",
    "lat": "343110N",
    "long": "1135028E"
  },
  {
    "stationcode": "HET",
    "stationname": "Hohhot Airport",
    "lat": "405105N",
    "long": "1114927E"
  },
  {
    "stationcode": "CAN",
    "stationname": "Guangzhou T2",
    "lat": "232333N",
    "long": "1131756E"
  },
  {
    "stationcode": "DSN",
    "stationname": "Dongsheng Airport",
    "lat": "392932N",
    "long": "1095147E"
  },
  {
    "stationcode": "CZX",
    "stationname": "Changzhou Airport",
    "lat": "315510N",
    "long": "1194642E"
  },
  {
    "stationcode": "TFU",
    "stationname": "CHENGDU TIANFU T1",
    "lat": "301859N",
    "long": "1042634E"
  },
  {
    "stationcode": "KWE",
    "stationname": "Guiyang (Charter)",
    "lat": "263219N",
    "long": "1064803E"
  },
  {
    "stationcode": "XMN",
    "stationname": "Xiamen Airport",
    "lat": "243239N",
    "long": "1180740E"
  },
  {
    "stationcode": "CSX",
    "stationname": "Changsha T2",
    "lat": "281137N",
    "long": "1131304E"
  },
  {
    "stationcode": "DLC",
    "stationname": "Dalian Airport",
    "lat": "385756N",
    "long": "1213219E"
  },
  {
    "stationcode": "KHN",
    "stationname": "Nanchang T1",
    "lat": "285150N",
    "long": "1155408E"
  },
  {
    "stationcode": "KWL",
    "stationname": "Guilin T2",
    "lat": "251305N",
    "long": "1100221E"
  },
  {
    "stationcode": "CKG",
    "stationname": "Chongqing T3",
    "lat": "294310N",
    "long": "1063831E"
  },
  {
    "stationcode": "URC",
    "stationname": "Urumqi Airport",
    "lat": "435400N",
    "long": "0872900E"
  },
  {
    "stationcode": "KMG",
    "stationname": "Kunming",
    "lat": "250631N",
    "long": "1025606E"
  },
  {
    "stationcode": "XIY",
    "stationname": "Xi'an T3",
    "lat": "342652N",
    "long": "1084514E"
  },
  {
    "stationcode": "NNG",
    "stationname": "Nanning T2",
    "lat": "223500N",
    "long": "1080800E"
  },
  {
    "stationcode": "TSN",
    "stationname": "Tianjin",
    "lat": "390728N",
    "long": "1172046E"
  },
  {
    "stationcode": "CGQ",
    "stationname": "Changchun Airport",
    "lat": "435300N",
    "long": "1251900E"
  },
  {
    "stationcode": "PKX",
    "stationname": "Beijing Daxing International Airport",
    "lat": "393036N",
    "long": "1162440E"
  },
  {
    "stationcode": "WNZ",
    "stationname": "Wenzhou (Charter)",
    "lat": "275447N",
    "long": "1205108E"
  },
  {
    "stationcode": "WUX",
    "stationname": "Wuxi Airport",
    "lat": "312941N",
    "long": "1202546E"
  },
  {
    "stationcode": "JJN",
    "stationname": "Quanzhou",
    "lat": "244744N",
    "long": "1183517E"
  },
  {
    "stationcode": "CTU",
    "stationname": "Chengdu",
    "lat": "304200N",
    "long": "1035700E"
  },
  {
    "stationcode": "YIH",
    "stationname": "Yichang Airport",
    "lat": "303325N",
    "long": "1112847E"
  },
  {
    "stationcode": "LHW",
    "stationname": "Lanzhou Airport",
    "lat": "363100N",
    "long": "1033700E"
  },
  {
    "stationcode": "HFE",
    "stationname": "Hefei (Charter)",
    "lat": "315928N",
    "long": "1165921E"
  },
  {
    "stationcode": "SWA",
    "stationname": "Jieyang Chaoshan Airport",
    "lat": "232539N",
    "long": "1164545E"
  },
  {
    "stationcode": "TAO",
    "stationname": "Qingdao (Charter)",
    "lat": "362144N",
    "long": "1200515E"
  },
  {
    "stationcode": "HGH",
    "stationname": "Hangzhou T4",
    "lat": "301358N",
    "long": "1202609E"
  },
  {
    "stationcode": "FOC",
    "stationname": "Fuzhou Airport",
    "lat": "255606N",
    "long": "1193948E"
  },
  {
    "stationcode": "TXN",
    "stationname": "Tunxi Airport",
    "lat": "294358N",
    "long": "1181526E"
  },
  {
    "stationcode": "PEK",
    "stationname": "Beijing T2",
    "lat": "400448N",
    "long": "1163504E"
  },
  {
    "stationcode": "UYN",
    "stationname": "Yulin Airport",
    "lat": "381612N",
    "long": "1094350E"
  },
  {
    "stationcode": "SYX",
    "stationname": "Sanya",
    "lat": "181800N",
    "long": "1092400E"
  },
  {
    "stationcode": "SHA",
    "stationname": "Shanghai",
    "lat": "311152N",
    "long": "1212011E"
  },
  {
    "stationcode": "TYN",
    "stationname": "Taiyuan Airport",
    "lat": "374500N",
    "long": "1123700E"
  },
  {
    "stationcode": "XNN",
    "stationname": "Xining Airport",
    "lat": "363143N",
    "long": "1020225E"
  },
  {
    "stationcode": "SHE",
    "stationname": "Shenyang Airport",
    "lat": "413825N",
    "long": "1232946E"
  },
  {
    "stationcode": "PVG",
    "stationname": "Shanghai T2",
    "lat": "310836N",
    "long": "1214819E"
  },
  {
    "stationcode": "HAK",
    "stationname": "Haikou",
    "lat": "195605N",
    "long": "1102732E"
  },
  {
    "stationcode": "TNA",
    "stationname": "Jinan Airport",
    "lat": "365125N",
    "long": "1171257E"
  },
  {
    "stationcode": "NKG",
    "stationname": "Nanjing T2",
    "lat": "314401N",
    "long": "1185218E"
  },
  {
    "stationcode": "SJW",
    "stationname": "Daguocun Airport",
    "lat": "381650N",
    "long": "1144150E"
  },
  {
    "stationcode": "NGB",
    "stationname": "Ningbo Airport T2",
    "lat": "294936N",
    "long": "1212743E"
  },
  {
    "stationcode": "WUH",
    "stationname": "Wuhan T3",
    "lat": "304702N",
    "long": "1141229E"
  },
  {
    "stationcode": "XCH",
    "stationname": "Christmas Island Airport",
    "lat": "102704S",
    "long": "1054118E"
  },
  {
    "stationcode": "ALG",
    "stationname": "Houari Boumediene Airport",
    "lat": "364139N",
    "long": "0031253E"
  },
  {
    "stationcode": "CAI",
    "stationname": "Cairo International Airport",
    "lat": "300719N",
    "long": "0312420E"
  },
  {
    "stationcode": "TLS",
    "stationname": "Blagnac Airport",
    "lat": "433749N",
    "long": "0012154E"
  },
  {
    "stationcode": "ORY",
    "stationname": "Paris",
    "lat": "484324N",
    "long": "0022246E"
  },
  {
    "stationcode": "STN",
    "stationname": "London",
    "lat": "515306N",
    "long": "0001406E"
  },
  {
    "stationcode": "LGW",
    "stationname": "London",
    "lat": "510853N",
    "long": "0001125W"
  },
  {
    "stationcode": "TBS",
    "stationname": "Tbilisi International Airport",
    "lat": "414000N",
    "long": "0445700E"
  },
  {
    "stationcode": "H1B",
    "stationname": "Shenzhen - Shenzhen Bao An International Airport",
    "lat": "223812N",
    "long": "1134852E"
  },
  {
    "stationcode": "HKG",
    "stationname": "Hong Kong T1",
    "lat": "221900N",
    "long": "1135500E"
  },
  {
    "stationcode": "H1A",
    "stationname": "Shenzhen - Shenzhen Bay Port",
    "lat": "223020N",
    "long": "1135641E"
  },
  {
    "stationcode": "TLV",
    "stationname": "Ben Gurion International Airport",
    "lat": "320026N",
    "long": "0345250E"
  },
  {
    "stationcode": "IXA",
    "stationname": "Agartala",
    "lat": "235324N",
    "long": "0911432E"
  },
  {
    "stationcode": "JAI",
    "stationname": "Jaipur T2",
    "lat": "264918N",
    "long": "0754822E"
  },
  {
    "stationcode": "BBI",
    "stationname": "Bhubaneswar",
    "lat": "201440N",
    "long": "0854904E"
  },
  {
    "stationcode": "IXR",
    "stationname": "Ranchi",
    "lat": "231851N",
    "long": "0851918E"
  },
  {
    "stationcode": "TRZ",
    "stationname": "Tiruchirapalli",
    "lat": "104555N",
    "long": "0784235E"
  },
  {
    "stationcode": "DED",
    "stationname": "Dehra Dun Airport",
    "lat": "301123N",
    "long": "0781049E"
  },
  {
    "stationcode": "VNS",
    "stationname": "Varanasi",
    "lat": "252707N",
    "long": "0825147E"
  },
  {
    "stationcode": "CCU",
    "stationname": "Kolkata T2",
    "lat": "223917N",
    "long": "0882648E"
  },
  {
    "stationcode": "IXJ",
    "stationname": "Jammu",
    "lat": "324121N",
    "long": "0745015E"
  },
  {
    "stationcode": "DEL",
    "stationname": "New Delhi T3",
    "lat": "283359N",
    "long": "0770611E"
  },
  {
    "stationcode": "AMD",
    "stationname": "Ahmedabad T2",
    "lat": "230438N",
    "long": "0723805E"
  },
  {
    "stationcode": "PNQ",
    "stationname": "Pune",
    "lat": "183456N",
    "long": "0735511E"
  },
  {
    "stationcode": "UDR",
    "stationname": "Dabok Airport",
    "lat": "243654N",
    "long": "0735346E"
  },
  {
    "stationcode": "IDR",
    "stationname": "Indore Airport",
    "lat": "224318N",
    "long": "0754804E"
  },
  {
    "stationcode": "LKO",
    "stationname": "Lucknow (Chaudhary Charan Singh International Airport) T1",
    "lat": "264543N",
    "long": "0805300E"
  },
  {
    "stationcode": "IXZ",
    "stationname": "Port Blair Airport",
    "lat": "113828N",
    "long": "0924347E"
  },
  {
    "stationcode": "JSA",
    "stationname": "Jaisalmer Airport (CIVIL ENCLAVE under Indian Airforce)",
    "lat": "265319N",
    "long": "0705154E"
  },
  {
    "stationcode": "STV",
    "stationname": "Surat",
    "lat": "210651N",
    "long": "0724430E"
  },
  {
    "stationcode": "HYD",
    "stationname": "Hyderabad",
    "lat": "171352N",
    "long": "0782553E"
  },
  {
    "stationcode": "PAT",
    "stationname": "Patna",
    "lat": "253529N",
    "long": "0850517E"
  },
  {
    "stationcode": "NAG",
    "stationname": "Nagpur",
    "lat": "210528N",
    "long": "0790259E"
  },
  {
    "stationcode": "BLR",
    "stationname": "Bengaluru T2",
    "lat": "125644N",
    "long": "0773948E"
  },
  {
    "stationcode": "VTZ",
    "stationname": "Visakhapatnam",
    "lat": "174316N",
    "long": "0831328E"
  },
  {
    "stationcode": "MAA",
    "stationname": "Chennai T4",
    "lat": "125936N",
    "long": "0801037E"
  },
  {
    "stationcode": "IMF",
    "stationname": "Imphal",
    "lat": "244536N",
    "long": "0935348E"
  },
  {
    "stationcode": "BOM",
    "stationname": "Mumbai (T2)",
    "lat": "190519N",
    "long": "0725205E"
  },
  {
    "stationcode": "GAU",
    "stationname": "Guwahati",
    "lat": "260622N",
    "long": "0913509E"
  },
  {
    "stationcode": "GOI",
    "stationname": "Goa",
    "lat": "152300N",
    "long": "0735000E"
  },
  {
    "stationcode": "ATQ",
    "stationname": "Amritsar",
    "lat": "314225N",
    "long": "0744750E"
  },
  {
    "stationcode": "IXB",
    "stationname": "Bagdogra",
    "lat": "264052N",
    "long": "0881943E"
  },
  {
    "stationcode": "GAY",
    "stationname": "Gaya Airport",
    "lat": "244440N",
    "long": "0845704E"
  },
  {
    "stationcode": "COK",
    "stationname": "Kochi T3",
    "lat": "100907N",
    "long": "0762407E"
  },
  {
    "stationcode": "TRV",
    "stationname": "Trivandrum T2",
    "lat": "082856N",
    "long": "0765512E"
  },
  {
    "stationcode": "IXC",
    "stationname": "Chandigarh",
    "lat": "304024N",
    "long": "0764719E"
  },
  {
    "stationcode": "SXR",
    "stationname": "Srinagar",
    "lat": "335917N",
    "long": "0744631E"
  },
  {
    "stationcode": "IXS",
    "stationname": "Silchar",
    "lat": "245444N",
    "long": "0925853E"
  },
  {
    "stationcode": "RPR",
    "stationname": "Raipur",
    "lat": "211057N",
    "long": "0814423E"
  },
  {
    "stationcode": "BGW",
    "stationname": "Baghdad International Airport",
    "lat": "331545N",
    "long": "0441404E"
  },
  {
    "stationcode": "IKA",
    "stationname": "Tehran",
    "lat": "352458N",
    "long": "0510908E"
  },
  {
    "stationcode": "FCO",
    "stationname": "Fiumicino Airport",
    "lat": "414801N",
    "long": "0121420E"
  },
  {
    "stationcode": "AMM",
    "stationname": "Queen Alia International Airport",
    "lat": "314300N",
    "long": "0360000E"
  },
  {
    "stationcode": "NRT",
    "stationname": "Tokyo Narita",
    "lat": "354553N",
    "long": "1402311E"
  },
  {
    "stationcode": "FUK",
    "stationname": "Fukuoka",
    "lat": "333509N",
    "long": "1302702E"
  },
  {
    "stationcode": "NGO",
    "stationname": "Nagoya Chubu",
    "lat": "345119N",
    "long": "1364830E"
  },
  {
    "stationcode": "FKS",
    "stationname": "Fukushima Airport",
    "lat": "371336N",
    "long": "1402558E"
  },
  {
    "stationcode": "CTS",
    "stationname": "Sapporo Shin-Chitose",
    "lat": "424631N",
    "long": "1414132E"
  },
  {
    "stationcode": "JJJ",
    "stationname": "Transit",
    "lat": "294225N",
    "long": "1160007E"
  },
  {
    "stationcode": "HND",
    "stationname": "Tokyo Haneda T3",
    "lat": "353308N",
    "long": "1394647E"
  },
  {
    "stationcode": "SDJ",
    "stationname": "Sendai",
    "lat": "380808N",
    "long": "1405526E"
  },
  {
    "stationcode": "OKA",
    "stationname": "Okinawa (Naha) T1",
    "lat": "261145N",
    "long": "1273845E"
  },
  {
    "stationcode": "KIX",
    "stationname": "Osaka Kansai T1",
    "lat": "342538N",
    "long": "1351439E"
  },
  {
    "stationcode": "PNH",
    "stationname": "Phnom Penh",
    "lat": "113248N",
    "long": "1045039E"
  },
  {
    "stationcode": "KOS",
    "stationname": "Sihanoukville Airport",
    "lat": "103447N",
    "long": "1033813E"
  },
  {
    "stationcode": "REP",
    "stationname": "Siem Reap T1",
    "lat": "132400N",
    "long": "1034900E"
  },
  {
    "stationcode": "SAI",
    "stationname": "Siem Reap Angkor International Airport",
    "lat": "132210N",
    "long": "1041324E"
  },
  {
    "stationcode": "ICN",
    "stationname": "Seoul T1",
    "lat": "372809N",
    "long": "1262702E"
  },
  {
    "stationcode": "MWX",
    "stationname": "Muan (Charter)",
    "lat": "345929N",
    "long": "1262258E"
  },
  {
    "stationcode": "CJU",
    "stationname": "Jeju",
    "lat": "333041N",
    "long": "1262935E"
  },
  {
    "stationcode": "PUS",
    "stationname": "Busan",
    "lat": "351036N",
    "long": "1285633E"
  },
  {
    "stationcode": "VTE",
    "stationname": "Vientiane",
    "lat": "175918N",
    "long": "1023348E"
  },
  {
    "stationcode": "LPQ",
    "stationname": "Luang Prabang",
    "lat": "195352N",
    "long": "1020939E"
  },
  {
    "stationcode": "BEY",
    "stationname": "Beirut International Airport",
    "lat": "334846N",
    "long": "0352925E"
  },
  {
    "stationcode": "HRI",
    "stationname": "Mattala Rajapaksa Int'l Airpot",
    "lat": "061702N",
    "long": "0810726E"
  },
  {
    "stationcode": "CMB",
    "stationname": "Colombo",
    "lat": "071049N",
    "long": "0795307E"
  },
  {
    "stationcode": "MDL",
    "stationname": "Mandalay",
    "lat": "214208N",
    "long": "0955841E"
  },
  {
    "stationcode": "RGN",
    "stationname": "Yangon T1",
    "lat": "165423N",
    "long": "0960820E"
  },
  {
    "stationcode": "NYT",
    "stationname": "Nay Pyi Taw",
    "lat": "193721N",
    "long": "0961205E"
  },
  {
    "stationcode": "MFM",
    "stationname": "Macau",
    "lat": "220858N",
    "long": "1133530E"
  },
  {
    "stationcode": "M1A",
    "stationname": "Zhuhai - Macao Border Gate",
    "lat": "220923N",
    "long": "1133422E"
  },
  {
    "stationcode": "M1B",
    "stationname": "Zhuhai - Hengqin Port",
    "lat": "220923N",
    "long": "1133422E"
  },
  {
    "stationcode": "MRU",
    "stationname": "S. Seewoosagur Ram. International Airport",
    "lat": "202539S",
    "long": "0574035E"
  },
  {
    "stationcode": "MLE",
    "stationname": "Male",
    "lat": "041130N",
    "long": "0733145E"
  },
  {
    "stationcode": "TGG",
    "stationname": "Kuala Terengganu",
    "lat": "052253N",
    "long": "1030617E"
  },
  {
    "stationcode": "KUL",
    "stationname": "Kuala Lumpur T2",
    "lat": "024444N",
    "long": "1014253E"
  },
  {
    "stationcode": "TWU",
    "stationname": "Tawau",
    "lat": "041848N",
    "long": "1180719E"
  },
  {
    "stationcode": "SDK",
    "stationname": "Sandakan",
    "lat": "055405N",
    "long": "1180340E"
  },
  {
    "stationcode": "IPH",
    "stationname": "Ipoh Airport T1",
    "lat": "043409N",
    "long": "1010535E"
  },
  {
    "stationcode": "LGK",
    "stationname": "Langkawi",
    "lat": "062019N",
    "long": "0994356E"
  },
  {
    "stationcode": "SZB",
    "stationname": "Sultan Abdul Aziz Shah Airport",
    "lat": "030718N",
    "long": "1013316E"
  },
  {
    "stationcode": "KUA",
    "stationname": "Kuantan",
    "lat": "034651N",
    "long": "1031234E"
  },
  {
    "stationcode": "MKZ",
    "stationname": "Melaka",
    "lat": "021549N",
    "long": "1021507E"
  },
  {
    "stationcode": "KCH",
    "stationname": "Kuching",
    "lat": "012903N",
    "long": "1102038E"
  },
  {
    "stationcode": "BTU",
    "stationname": "Bintulu",
    "lat": "030727N",
    "long": "1130111E"
  },
  {
    "stationcode": "AOR",
    "stationname": "Alor Setar",
    "lat": "061140N",
    "long": "1002403E"
  },
  {
    "stationcode": "JHB",
    "stationname": "Johor Bahru",
    "lat": "013820N",
    "long": "1034013E"
  },
  {
    "stationcode": "MYY",
    "stationname": "Miri",
    "lat": "041931N",
    "long": "1135918E"
  },
  {
    "stationcode": "LBU",
    "stationname": "Labuan T1",
    "lat": "051750N",
    "long": "1151509E"
  },
  {
    "stationcode": "PEN",
    "stationname": "Penang",
    "lat": "051750N",
    "long": "1001636E"
  },
  {
    "stationcode": "SBW",
    "stationname": "Sibu",
    "lat": "021551N",
    "long": "1115857E"
  },
  {
    "stationcode": "KBR",
    "stationname": "Kota Bharu",
    "lat": "061003N",
    "long": "1021728E"
  },
  {
    "stationcode": "BKI",
    "stationname": "Kota Kinabalu T1",
    "lat": "055614N",
    "long": "1160311E"
  },
  {
    "stationcode": "KTM",
    "stationname": "Kathmandu T1",
    "lat": "274148N",
    "long": "0852133E"
  },
  {
    "stationcode": "MCT",
    "stationname": "Muscat",
    "lat": "233534N",
    "long": "0581654E"
  },
  {
    "stationcode": "PPS",
    "stationname": "Puerto Princesa",
    "lat": "094432N",
    "long": "1184514E"
  },
  {
    "stationcode": "TAG",
    "stationname": "Panglao International Airport",
    "lat": "093937N",
    "long": "1235115E"
  },
  {
    "stationcode": "CBO",
    "stationname": "Awang Airport",
    "lat": "070955N",
    "long": "1241235E"
  },
  {
    "stationcode": "CEB",
    "stationname": "Cebu",
    "lat": "101827N",
    "long": "1235846E"
  },
  {
    "stationcode": "CRK",
    "stationname": "Clark",
    "lat": "151110N",
    "long": "1203337E"
  },
  {
    "stationcode": "CGY",
    "stationname": "Cagayan de Oro",
    "lat": "083645N",
    "long": "1242725E"
  },
  {
    "stationcode": "RXS",
    "stationname": "Roxas City Airport",
    "lat": "113604N",
    "long": "1224502E"
  },
  {
    "stationcode": "DGT",
    "stationname": "Dumaguete Airport",
    "lat": "092004N",
    "long": "1231800E"
  },
  {
    "stationcode": "PAG",
    "stationname": "Pagadian Airport",
    "lat": "074948N",
    "long": "1232740E"
  },
  {
    "stationcode": "VRC",
    "stationname": "Virac Airport",
    "lat": "133438N",
    "long": "1241220E"
  },
  {
    "stationcode": "TUG",
    "stationname": "Tuguegarao Airport",
    "lat": "173830N",
    "long": "1214401E"
  },
  {
    "stationcode": "KLO",
    "stationname": "Kalibo Boracay",
    "lat": "114104N",
    "long": "1222252E"
  },
  {
    "stationcode": "TWT",
    "stationname": "Tawitawi Airport",
    "lat": "050242N",
    "long": "1194433E"
  },
  {
    "stationcode": "BCD",
    "stationname": "Bacolod",
    "lat": "104636N",
    "long": "1230109E"
  },
  {
    "stationcode": "TAC",
    "stationname": "Tacloban",
    "lat": "111339N",
    "long": "1250140E"
  },
  {
    "stationcode": "OZC",
    "stationname": "Labo Airport",
    "lat": "081043N",
    "long": "1235030E"
  },
  {
    "stationcode": "DVO",
    "stationname": "Davao",
    "lat": "070731N",
    "long": "1253844E"
  },
  {
    "stationcode": "MNL",
    "stationname": "Manila",
    "lat": "143036N",
    "long": "1210106E"
  },
  {
    "stationcode": "GES",
    "stationname": "General Santos",
    "lat": "060623N",
    "long": "1251407E"
  },
  {
    "stationcode": "ILO",
    "stationname": "Iloilo",
    "lat": "104247N",
    "long": "1223243E"
  },
  {
    "stationcode": "ZAM",
    "stationname": "Zamboanga Airport",
    "lat": "065521N",
    "long": "1220335E"
  },
  {
    "stationcode": "MPH",
    "stationname": "Caticlan Boracay",
    "lat": "115528N",
    "long": "1215715E"
  },
  {
    "stationcode": "LHE",
    "stationname": "Lahore Airport",
    "lat": "313118N",
    "long": "0742413E"
  },
  {
    "stationcode": "ROR",
    "stationname": "Airai Airport",
    "lat": "072152N",
    "long": "1343311E"
  },
  {
    "stationcode": "DOH",
    "stationname": "Doha Airport",
    "lat": "251628N",
    "long": "0513630E"
  },
  {
    "stationcode": "MED",
    "stationname": "Mohammad Bin Abdulaziz Airport",
    "lat": "243312N",
    "long": "0394218E"
  },
  {
    "stationcode": "RUH",
    "stationname": "King Khaled International Airport",
    "lat": "245728N",
    "long": "0464156E"
  },
  {
    "stationcode": "ULH",
    "stationname": "Majeed Bin Abdulaziz Airport",
    "lat": "262923N",
    "long": "0380619E"
  },
  {
    "stationcode": "JED",
    "stationname": "Jeddah",
    "lat": "214046N",
    "long": "0390924E"
  },
  {
    "stationcode": "DJJ",
    "stationname": "Sentani Airport",
    "lat": "023437S",
    "long": "1403059E"
  },
  {
    "stationcode": "SIN",
    "stationname": "Singapore T4",
    "lat": "012141N",
    "long": "1035920E"
  },
  {
    "stationcode": "DKR",
    "stationname": "Yoff Airport",
    "lat": "144433N",
    "long": "0172936W"
  },
  {
    "stationcode": "3AD",
    "stationname": "Kalasin (Kud Nam Kin Park)",
    "lat": "135440N",
    "long": "1003630E"
  },
  {
    "stationcode": "1AE",
    "stationname": "Lipe (Sunrise Beach)",
    "lat": "135440N",
    "long": "1003630E"
  },
  {
    "stationcode": "L1D",
    "stationname": "Pakbara Pier",
    "lat": "",
    "long": ""
  },
  {
    "stationcode": "L2A",
    "stationname": "Lompraya Pier",
    "lat": "100524N",
    "long": "0995017E"
  },
  {
    "stationcode": "1AD",
    "stationname": "Lanta (Saladan Pier)",
    "lat": "135440N",
    "long": "1003630E"
  },
  {
    "stationcode": "3AV",
    "stationname": "Buri Ram Downtown (Best Western Hotel)",
    "lat": "",
    "long": ""
  },
  {
    "stationcode": "BFV",
    "stationname": "Buri Ram",
    "lat": "151336N",
    "long": "1031504E"
  },
  {
    "stationcode": "1AL",
    "stationname": "Ao Railay - Floating Pier East",
    "lat": "010101N",
    "long": "0010101E"
  },
  {
    "stationcode": "L1C",
    "stationname": "Nopparatthara Pier",
    "lat": "",
    "long": ""
  },
  {
    "stationcode": "3AH",
    "stationname": "Khaolak Noi's travel center",
    "lat": "",
    "long": ""
  },
  {
    "stationcode": "UBP",
    "stationname": "Ubon Ratchathani",
    "lat": "151505N",
    "long": "1045213E"
  },
  {
    "stationcode": "CJM",
    "stationname": "Chumphon",
    "lat": "104241N",
    "long": "0992142E"
  },
  {
    "stationcode": "1AH",
    "stationname": "Tao Island (Seatran Pier)",
    "lat": "135440N",
    "long": "1003630E"
  },
  {
    "stationcode": "2AH",
    "stationname": "KohTao Lomprayah Pier (Chumphon)",
    "lat": "100524N",
    "long": "0995017E"
  },
  {
    "stationcode": "3AO",
    "stationname": "Phuket - Banana Walk Patong Beach ('Triple One Travel' Counter)",
    "lat": "010101N",
    "long": "0110101E"
  },
  {
    "stationcode": "3AR",
    "stationname": "Passione by Laemtong Rayong",
    "lat": "135440N",
    "long": "1003630E"
  },
  {
    "stationcode": "KOP",
    "stationname": "Nakhon Phanom",
    "lat": "172307N",
    "long": "1043831E"
  },
  {
    "stationcode": "L1F",
    "stationname": "Nopparattara Pier",
    "lat": "",
    "long": ""
  },
  {
    "stationcode": "UTP",
    "stationname": "Utapao Airport",
    "lat": "124047N",
    "long": "1010018E"
  },
  {
    "stationcode": "HDY",
    "stationname": "Hat Yai T2",
    "lat": "065558N",
    "long": "1002342E"
  },
  {
    "stationcode": "1AN",
    "stationname": "Koh Yao Yai - Klong Hia Pier",
    "lat": "010101N",
    "long": "0010101E"
  },
  {
    "stationcode": "ROI",
    "stationname": "Roi Et",
    "lat": "160655N",
    "long": "1034637E"
  },
  {
    "stationcode": "CNX",
    "stationname": "Chiang Mai",
    "lat": "184617N",
    "long": "0985746E"
  },
  {
    "stationcode": "1AB",
    "stationname": "Phangan (Thong Sala Pier)",
    "lat": "",
    "long": ""
  },
  {
    "stationcode": "3AA",
    "stationname": "Pai (Pai Bus Station)",
    "lat": "",
    "long": ""
  },
  {
    "stationcode": "3AI",
    "stationname": "Surin (The Chang Knowledge Park)",
    "lat": "",
    "long": ""
  },
  {
    "stationcode": "1AQ",
    "stationname": "Koh Libong - Maprao Pier",
    "lat": "010101N",
    "long": "0010101E"
  },
  {
    "stationcode": "1AC",
    "stationname": "Koh Phi Phi - Tonsai Pier",
    "lat": "135440N",
    "long": "1003630E"
  },
  {
    "stationcode": "3AJ",
    "stationname": "Chiang Khan (The Old ChiangKhan)",
    "lat": "135440N",
    "long": "1003630E"
  },
  {
    "stationcode": "HHQ",
    "stationname": "Hua Hin Airport",
    "lat": "123749N",
    "long": "0995712E"
  },
  {
    "stationcode": "L1J",
    "stationname": "Chao Fah Pier",
    "lat": "010101N",
    "long": "0010101E"
  },
  {
    "stationcode": "CEI",
    "stationname": "Chiang Rai",
    "lat": "195708N",
    "long": "0995259E"
  },
  {
    "stationcode": "3AK",
    "stationname": "Pattaya-Downtown CentralFestival",
    "lat": "135440N",
    "long": "1003630E"
  },
  {
    "stationcode": "3AN",
    "stationname": "Phuket Town – Royal Phuket City",
    "lat": "010101N",
    "long": "0010101E"
  },
  {
    "stationcode": "3AS",
    "stationname": "Ao Nang - Ao Nang Center Bus Stop",
    "lat": "010101N",
    "long": "0010101E"
  },
  {
    "stationcode": "SNO",
    "stationname": "Sakon Nakhon",
    "lat": "171149N",
    "long": "1040657E"
  },
  {
    "stationcode": "L1S",
    "stationname": "Samed Pier (Na Dan)",
    "lat": "",
    "long": ""
  },
  {
    "stationcode": "LOE",
    "stationname": "Loei Airport",
    "lat": "172621N",
    "long": "1014319E"
  },
  {
    "stationcode": "L1H",
    "stationname": "Klong Jilad Pier",
    "lat": "080459N",
    "long": "0010101E"
  },
  {
    "stationcode": "1AJ",
    "stationname": "Samed (Na Dan Pier)",
    "lat": "",
    "long": ""
  },
  {
    "stationcode": "NNT",
    "stationname": "Nan",
    "lat": "184828N",
    "long": "1004700E"
  },
  {
    "stationcode": "DMK",
    "stationname": "Don Mueang",
    "lat": "135445N",
    "long": "1003624E"
  },
  {
    "stationcode": "HKT",
    "stationname": "Phuket",
    "lat": "080644N",
    "long": "0981819E"
  },
  {
    "stationcode": "NST",
    "stationname": "Nakhon Si Thammarat",
    "lat": "083223N",
    "long": "0995641E"
  },
  {
    "stationcode": "3AB",
    "stationname": "Sukhothai (Wake Up at Muang Kao)",
    "lat": "135440N",
    "long": "1003630E"
  },
  {
    "stationcode": "L1E",
    "stationname": "Pakmeng Pier",
    "lat": "",
    "long": ""
  },
  {
    "stationcode": "1AP",
    "stationname": "Koh Kradan - Koh Kradan Beach",
    "lat": "010101N",
    "long": "0010101E"
  },
  {
    "stationcode": "1AF",
    "stationname": "Ngai (KohNgai Villa)",
    "lat": "",
    "long": ""
  },
  {
    "stationcode": "3AG",
    "stationname": "Khanom",
    "lat": "135440N",
    "long": "1003630E"
  },
  {
    "stationcode": "PHS",
    "stationname": "Phitsanulok",
    "lat": "164659N",
    "long": "1001645E"
  },
  {
    "stationcode": "3AL",
    "stationname": "Hua Hin City",
    "lat": "",
    "long": ""
  },
  {
    "stationcode": "KKC",
    "stationname": "Khon Kaen",
    "lat": "162752N",
    "long": "1024656E"
  },
  {
    "stationcode": "L1G",
    "stationname": "Hua Hin Pier",
    "lat": "",
    "long": ""
  },
  {
    "stationcode": "1AI",
    "stationname": "Laan Island",
    "lat": "",
    "long": ""
  },
  {
    "stationcode": "L1A",
    "stationname": "Donsak Pier",
    "lat": "091901N",
    "long": "0994140E"
  },
  {
    "stationcode": "L1L",
    "stationname": "Laem Balihai Pier",
    "lat": "",
    "long": ""
  },
  {
    "stationcode": "1AO",
    "stationname": "Koh Mook - Koh Mook Pier",
    "lat": "010101N",
    "long": "0010101E"
  },
  {
    "stationcode": "UTH",
    "stationname": "Udon Thani TB",
    "lat": "172311N",
    "long": "1024718E"
  },
  {
    "stationcode": "TST",
    "stationname": "Trang",
    "lat": "073031N",
    "long": "0993657E"
  },
  {
    "stationcode": "L1B",
    "stationname": "Rassada Pier",
    "lat": "",
    "long": ""
  },
  {
    "stationcode": "3AP",
    "stationname": "Phrae - Phrae Bus Terminal",
    "lat": "180842N",
    "long": "1000826E"
  },
  {
    "stationcode": "UNN",
    "stationname": "Ranong",
    "lat": "094626N",
    "long": "0983513E"
  },
  {
    "stationcode": "1AA",
    "stationname": "Samui (Nathon Pier)",
    "lat": "093300N",
    "long": "1000400E"
  },
  {
    "stationcode": "BKK",
    "stationname": "Bangkok",
    "lat": "134109N",
    "long": "1004456E"
  },
  {
    "stationcode": "L1M",
    "stationname": "Hat Yao Pier",
    "lat": "010101N",
    "long": "0010101E"
  },
  {
    "stationcode": "1AG",
    "stationname": "Yao Noi (Manoh pier)",
    "lat": "135440N",
    "long": "1003630E"
  },
  {
    "stationcode": "3AC",
    "stationname": "Mukdahan (Mukdahan Grand Hotel)",
    "lat": "",
    "long": ""
  },
  {
    "stationcode": "URT",
    "stationname": "Surat Thani",
    "lat": "090810N",
    "long": "0990821E"
  },
  {
    "stationcode": "KBV",
    "stationname": "Krabi",
    "lat": "080545N",
    "long": "0985920E"
  },
  {
    "stationcode": "3AF",
    "stationname": "Roi Et (Roi Et Plaza)",
    "lat": "",
    "long": ""
  },
  {
    "stationcode": "3AE",
    "stationname": "Mahasarakham (Sermthai Complex)",
    "lat": "135440N",
    "long": "1003630E"
  },
  {
    "stationcode": "NAW",
    "stationname": "Narathiwat",
    "lat": "063124N",
    "long": "1014441E"
  },
  {
    "stationcode": "1AK",
    "stationname": "Koh Jum - Koh Jum Pier",
    "lat": "010101N",
    "long": "0010101E"
  },
  {
    "stationcode": "L1I",
    "stationname": "Ao Po Pier",
    "lat": "010101N",
    "long": "0010101E"
  },
  {
    "stationcode": "DIL",
    "stationname": "President Nicolau Lobato International Airport",
    "lat": "083248S",
    "long": "1253129E"
  },
  {
    "stationcode": "CRZ",
    "stationname": "Turkmenabad Airport",
    "lat": "390500N",
    "long": "0633643E"
  },
  {
    "stationcode": "TUN",
    "stationname": "Carthage Airport",
    "lat": "365104N",
    "long": "0101338E"
  },
  {
    "stationcode": "IST",
    "stationname": "Ataturk Airport",
    "lat": "411614N",
    "long": "0284433E"
  },
  {
    "stationcode": "TPE",
    "stationname": "Taipei T1",
    "lat": "250440N",
    "long": "1211358E"
  },
  {
    "stationcode": "KHH",
    "stationname": "Kaohsiung International Airport",
    "lat": "223421N",
    "long": "1202043E"
  },
  {
    "stationcode": "HAN",
    "stationname": "Hanoi T2",
    "lat": "211316N",
    "long": "1054826E"
  },
  {
    "stationcode": "VCA",
    "stationname": "Can Tho",
    "lat": "100305N",
    "long": "1054548E"
  },
  {
    "stationcode": "PQC",
    "stationname": "Phu Quoc Airport",
    "lat": "101012N",
    "long": "1035936E"
  },
  {
    "stationcode": "DAD",
    "stationname": "Da Nang T2",
    "lat": "160238N",
    "long": "1081158E"
  },
  {
    "stationcode": "DLI",
    "stationname": "Da Lat",
    "lat": "114500N",
    "long": "1082200E"
  },
  {
    "stationcode": "SGN",
    "stationname": "Ho Chi Minh City T2",
    "lat": "104908N",
    "long": "1063907E"
  },
  {
    "stationcode": "CXR",
    "stationname": "Cam Ranh Airport",
    "lat": "115953N",
    "long": "1091309E"
  },
  {
    "stationcode": "MEL",
    "stationname": "Melbourne - Tullamarine T2",
    "lat": "373942S",
    "long": "1445000E"
  },
  {
    "stationcode": "SYD",
    "stationname": "Sydney T1",
    "lat": "335639S",
    "long": "1511033E"
  },
  {
    "stationcode": "AVV",
    "stationname": "Avalon Airport",
    "lat": "380222S",
    "long": "1442810E"
  },
  {
    "stationcode": "PER",
    "stationname": "Perth T1",
    "lat": "315625S",
    "long": "1155801E"
  },
  {
    "stationcode": "FIH",
    "stationname": "Ndjili International Airport",
    "lat": "042300S",
    "long": "0152600E"
  },
  {
    "stationcode": "FBM",
    "stationname": "Lubumbashi International Airport",
    "lat": "113529S",
    "long": "0273151E"
  },
  {
    "stationcode": "PGK",
    "stationname": "Pangkalpinang Airport",
    "lat": "020949S",
    "long": "1060821E"
  },
  {
    "stationcode": "SRG",
    "stationname": "Semarang",
    "lat": "065817S",
    "long": "1102227E"
  },
  {
    "stationcode": "TJQ",
    "stationname": "Belitung",
    "lat": "024444S",
    "long": "1074517E"
  },
  {
    "stationcode": "PNK",
    "stationname": "Supadio Airport",
    "lat": "000903S",
    "long": "1092414E"
  },
  {
    "stationcode": "KJT",
    "stationname": "Majalengka / Kertajati",
    "lat": "063905S",
    "long": "1081010E"
  },
  {
    "stationcode": "KNO",
    "stationname": "Medan",
    "lat": "033816N",
    "long": "0985219E"
  },
  {
    "stationcode": "LUW",
    "stationname": "Luwuk Airport",
    "lat": "010217S",
    "long": "1224622E"
  },
  {
    "stationcode": "BEJ",
    "stationname": "Berau Airport",
    "lat": "020919N",
    "long": "1172558E"
  },
  {
    "stationcode": "MWS",
    "stationname": "IMIP PRIVATE AIRPORT",
    "lat": "024803S",
    "long": "1220835E"
  },
  {
    "stationcode": "LBJ",
    "stationname": "Labuan Bajo - Komodo Island",
    "lat": "083100S",
    "long": "1195300E"
  },
  {
    "stationcode": "LOP",
    "stationname": "Lombok",
    "lat": "084526S",
    "long": "1161636E"
  },
  {
    "stationcode": "BDJ",
    "stationname": "Sjamsudin Noor Airport",
    "lat": "032800S",
    "long": "1144500E"
  },
  {
    "stationcode": "SOQ",
    "stationname": "Sorong",
    "lat": "005535S",
    "long": "1310716E"
  },
  {
    "stationcode": "TTE",
    "stationname": "Babullah Airport",
    "lat": "004953N",
    "long": "1272253E"
  },
  {
    "stationcode": "CHC",
    "stationname": "Christchurch",
    "lat": "432922S",
    "long": "1723156E"
  },
  {
    "stationcode": "AKL",
    "stationname": "Auckland",
    "lat": "370035S",
    "long": "1744730E"
  },
  {
    "stationcode": "DME",
    "stationname": "Domodedovo Airport",
    "lat": "552432N",
    "long": "0375423E"
  },
  {
    "stationcode": "BNE",
    "stationname": "Brisbane International Airport",
    "lat": "272303S",
    "long": "1530703E"
  },
  {
    "stationcode": "OOL",
    "stationname": "Gold Coast",
    "lat": "281100S",
    "long": "1532900E"
  },
  {
    "stationcode": "DRW",
    "stationname": "Darwin",
    "lat": "122453S",
    "long": "1305236E"
  },
  {
    "stationcode": "MZJ",
    "stationname": "Marana Airport",
    "lat": "323037N",
    "long": "1111931W"
  },
  {
    "stationcode": "BJS",
    "stationname": "inserted by conversion",
    "lat": "",
    "long": ""
  },
  {
    "stationcode": "SDZ",
    "stationname": "inserted by conversion",
    "lat": "",
    "long": ""
  },
  {
    "stationcode": "AAN",
    "stationname": "Al Ain Airport",
    "lat": "241542N",
    "long": "0553633E"
  },
  {
    "stationcode": "XSB",
    "stationname": "Sir Bani Yas Island Airport",
    "lat": "241658N",
    "long": "0523454E"
  },
  {
    "stationcode": "FJR",
    "stationname": "Fujairah International Airport",
    "lat": "250644N",
    "long": "0561926E"
  },
  {
    "stationcode": "AZI",
    "stationname": "Bateen Airport",
    "lat": "242540N",
    "long": "0542733E"
  },
  {
    "stationcode": "ZXZ",
    "stationname": "Jumeirah Beach Dubai Airport",
    "lat": "252500N",
    "long": "0552800E"
  },
  {
    "stationcode": "RKT",
    "stationname": "Ra'sal-Khaymah Airport",
    "lat": "253558N",
    "long": "0555639E"
  },
  {
    "stationcode": "KBL",
    "stationname": "Khwaja Rawash Airport",
    "lat": "343357N",
    "long": "0691245E"
  },
  {
    "stationcode": "SGA",
    "stationname": "Sheghnan Airport",
    "lat": "380200N",
    "long": "0714300E"
  },
  {
    "stationcode": "GRG",
    "stationname": "Gardez Airport",
    "lat": "333700N",
    "long": "0690700E"
  },
  {
    "stationcode": "ZAJ",
    "stationname": "Zaranj Airport",
    "lat": "305817N",
    "long": "0615159E"
  },
  {
    "stationcode": "HEA",
    "stationname": "Herat Airport",
    "lat": "341225N",
    "long": "0621336E"
  },
  {
    "stationcode": "FAH",
    "stationname": "Farah Airport",
    "lat": "322158N",
    "long": "0620956E"
  },
  {
    "stationcode": "KDH",
    "stationname": "Kandahar Airport",
    "lat": "313020N",
    "long": "0655052E"
  },
  {
    "stationcode": "DAZ",
    "stationname": "Darwaz Airport",
    "lat": "382800N",
    "long": "0705300E"
  },
  {
    "stationcode": "KWH",
    "stationname": "Khwahan Airport",
    "lat": "375300N",
    "long": "0701200E"
  },
  {
    "stationcode": "BIN",
    "stationname": "Bamiyan Airport",
    "lat": "344800N",
    "long": "0674900E"
  },
  {
    "stationcode": "JAA",
    "stationname": "Jalalabad Airport",
    "lat": "342400N",
    "long": "0702954E"
  },
  {
    "stationcode": "IMZ",
    "stationname": "Nimroz Airport",
    "lat": "310600N",
    "long": "0625600E"
  },
  {
    "stationcode": "MZR",
    "stationname": "Mazar-I-Sharif Airport",
    "lat": "364225N",
    "long": "0671234E"
  },
  {
    "stationcode": "FBD",
    "stationname": "Faizabad Airport",
    "lat": "370721N",
    "long": "0703105E"
  },
  {
    "stationcode": "URN",
    "stationname": "Urgoon Airport",
    "lat": "325700N",
    "long": "0691100E"
  },
  {
    "stationcode": "URZ",
    "stationname": "Uruzgan Airport",
    "lat": "325400N",
    "long": "0663700E"
  },
  {
    "stationcode": "OAI",
    "stationname": "Bagram Airport",
    "lat": "345647N",
    "long": "0691554E"
  },
  {
    "stationcode": "MMZ",
    "stationname": "Maimana Airport",
    "lat": "355533N",
    "long": "0644557E"
  },
  {
    "stationcode": "KUR",
    "stationname": "Kuran-O-Munjan Airport",
    "lat": "382400N",
    "long": "0710700E"
  },
  {
    "stationcode": "SBF",
    "stationname": "Sardeh Band Airport",
    "lat": "333700N",
    "long": "0683200E"
  },
  {
    "stationcode": "BST",
    "stationname": "Bost Airport",
    "lat": "313337N",
    "long": "0642154E"
  },
  {
    "stationcode": "TII",
    "stationname": "Tirinkot Airport",
    "lat": "323626N",
    "long": "0655148E"
  },
  {
    "stationcode": "GZI",
    "stationname": "Ghazni Airport",
    "lat": "333153N",
    "long": "0682447E"
  },
  {
    "stationcode": "UND",
    "stationname": "Kunduz Airport",
    "lat": "363955N",
    "long": "0685438E"
  },
  {
    "stationcode": "LQN",
    "stationname": "Qala Nau Airport",
    "lat": "345911N",
    "long": "0630708E"
  },
  {
    "stationcode": "CCN",
    "stationname": "Chakcharan Airport",
    "lat": "343135N",
    "long": "0651615E"
  },
  {
    "stationcode": "KHT",
    "stationname": "Khost Airport",
    "lat": "332002N",
    "long": "0695710E"
  },
  {
    "stationcode": "TQN",
    "stationname": "Taluquan Airport",
    "lat": "364600N",
    "long": "0693200E"
  },
  {
    "stationcode": "ANU",
    "stationname": "V.C. Bird International Airport",
    "lat": "170812N",
    "long": "0614734W"
  },
  {
    "stationcode": "BBQ",
    "stationname": "Barbuda Airport",
    "lat": "173810N",
    "long": "0614938W"
  },
  {
    "stationcode": "AXA",
    "stationname": "Wallblake Airport",
    "lat": "181217N",
    "long": "0630318W"
  },
  {
    "stationcode": "KFZ",
    "stationname": "Kukes International",
    "lat": "420209N",
    "long": "0202502E"
  },
  {
    "stationcode": "TIA",
    "stationname": "Rinas Airport",
    "lat": "412454N",
    "long": "0194315E"
  },
  {
    "stationcode": "EVN",
    "stationname": "Yerevan Airport",
    "lat": "400850N",
    "long": "0442345E"
  },
  {
    "stationcode": "LWN",
    "stationname": "Leninakan Airport",
    "lat": "404501N",
    "long": "0435134E"
  },
  {
    "stationcode": "BON",
    "stationname": "Flamingo International Airport",
    "lat": "120752N",
    "long": "0681607W"
  },
  {
    "stationcode": "EUX",
    "stationname": "F D Roosevelt Airport",
    "lat": "172947N",
    "long": "0625846W"
  },
  {
    "stationcode": "SAB",
    "stationname": "J. Yrausquin Airport",
    "lat": "173844N",
    "long": "0631314W"
  },
  {
    "stationcode": "SXM",
    "stationname": "Princ. Juliana Airport",
    "lat": "180237N",
    "long": "0630643W"
  },
  {
    "stationcode": "CUR",
    "stationname": "Aeropuerto Hato",
    "lat": "121120N",
    "long": "0685735W"
  },
  {
    "stationcode": "LLT",
    "stationname": "Lobito Airport",
    "lat": "122216S",
    "long": "0133212E"
  },
  {
    "stationcode": "SPP",
    "stationname": "Menongue Airport",
    "lat": "143931S",
    "long": "0174317E"
  },
  {
    "stationcode": "NOV",
    "stationname": "Huambo Airport",
    "lat": "124833S",
    "long": "0154539E"
  },
  {
    "stationcode": "PBN",
    "stationname": "Porto Amboim Airport",
    "lat": "104300S",
    "long": "0134500E"
  },
  {
    "stationcode": "ARZ",
    "stationname": "N'Zeto Airport",
    "lat": "071300S",
    "long": "0125200E"
  },
  {
    "stationcode": "KNP",
    "stationname": "Capanda Airport",
    "lat": "094601S",
    "long": "0152701E"
  },
  {
    "stationcode": "BUG",
    "stationname": "Gen V Deslandes Airport",
    "lat": "123624S",
    "long": "0132415E"
  },
  {
    "stationcode": "DUE",
    "stationname": "Dundo Airport",
    "lat": "072402S",
    "long": "0204908E"
  },
  {
    "stationcode": "NGV",
    "stationname": "Ngiva Airport",
    "lat": "170300S",
    "long": "0154700E"
  },
  {
    "stationcode": "SSY",
    "stationname": "M'Banza Congo Airport",
    "lat": "061611S",
    "long": "0141450E"
  },
  {
    "stationcode": "JMB",
    "stationname": "Jamba Airport",
    "lat": "144201S",
    "long": "0160500E"
  },
  {
    "stationcode": "VPE",
    "stationname": "Ongiva Airport",
    "lat": "170238S",
    "long": "0154105E"
  },
  {
    "stationcode": "CFF",
    "stationname": "Cafunfo Airport",
    "lat": "084600S",
    "long": "0180000E"
  },
  {
    "stationcode": "SVP",
    "stationname": "Kuito Airport",
    "lat": "122416S",
    "long": "0165654E"
  },
  {
    "stationcode": "SDD",
    "stationname": "Lubango Airport",
    "lat": "145528S",
    "long": "0133436E"
  },
  {
    "stationcode": "LAD",
    "stationname": "4 de Fevereiro Airport",
    "lat": "085130S",
    "long": "0131352E"
  },
  {
    "stationcode": "CTI",
    "stationname": "Cuito Cuanavale Airport",
    "lat": "151000S",
    "long": "0191000E"
  },
  {
    "stationcode": "PGI",
    "stationname": "Chitato Airport",
    "lat": "072100S",
    "long": "0205700E"
  },
  {
    "stationcode": "SZA",
    "stationname": "Soyo Airport",
    "lat": "060828S",
    "long": "0122221E"
  },
  {
    "stationcode": "MSZ",
    "stationname": "Namibe Airport",
    "lat": "151542S",
    "long": "0120850E"
  },
  {
    "stationcode": "CNZ",
    "stationname": "Cangamba Airport",
    "lat": "134100S",
    "long": "0195300E"
  },
  {
    "stationcode": "GGC",
    "stationname": "Lumbala Airport",
    "lat": "140700S",
    "long": "0212600E"
  },
  {
    "stationcode": "NDF",
    "stationname": "Ndalatandos Airport",
    "lat": "092000S",
    "long": "0145000E"
  },
  {
    "stationcode": "LBZ",
    "stationname": "Lukapa Airport",
    "lat": "082300S",
    "long": "0204000E"
  },
  {
    "stationcode": "LZM",
    "stationname": "Luzamba Airport",
    "lat": "090300S",
    "long": "0180500E"
  },
  {
    "stationcode": "LUO",
    "stationname": "Luena Airport",
    "lat": "114602S",
    "long": "0195338E"
  },
  {
    "stationcode": "MEG",
    "stationname": "Malange Airport",
    "lat": "093131S",
    "long": "0161847E"
  },
  {
    "stationcode": "AZZ",
    "stationname": "Ambriz Airport",
    "lat": "075300S",
    "long": "0130900E"
  },
  {
    "stationcode": "CEO",
    "stationname": "Waco Kungo Airport",
    "lat": "112700S",
    "long": "0150500E"
  },
  {
    "stationcode": "CAB",
    "stationname": "Cabinda Airport",
    "lat": "053550S",
    "long": "0121117E"
  },
  {
    "stationcode": "CBT",
    "stationname": "Catumbela Airport",
    "lat": "122853S",
    "long": "0132913E"
  },
  {
    "stationcode": "GXG",
    "stationname": "Negage Airport",
    "lat": "074519S",
    "long": "0151717E"
  },
  {
    "stationcode": "CTV",
    "stationname": "Catoca",
    "lat": "092557S",
    "long": "0201843E"
  },
  {
    "stationcode": "UAL",
    "stationname": "Luau Airport",
    "lat": "104253S",
    "long": "0221350E"
  },
  {
    "stationcode": "VHC",
    "stationname": "Saurimo Airport",
    "lat": "094121S",
    "long": "0202554E"
  },
  {
    "stationcode": "NDD",
    "stationname": "Sumbe Airport",
    "lat": "111000S",
    "long": "0135200E"
  },
  {
    "stationcode": "UGO",
    "stationname": "Uige Airport",
    "lat": "073605S",
    "long": "0150141E"
  },
  {
    "stationcode": "NZA",
    "stationname": "Nzagi Airport",
    "lat": "082300S",
    "long": "0212600E"
  },
  {
    "stationcode": "NRQ",
    "stationname": "N'Riquinha Airport",
    "lat": "154500S",
    "long": "0214200E"
  },
  {
    "stationcode": "XGN",
    "stationname": "Xangongo Airport",
    "lat": "164500S",
    "long": "0145802E"
  },
  {
    "stationcode": "ANL",
    "stationname": "Andulo Airport",
    "lat": "113000S",
    "long": "0164500E"
  },
  {
    "stationcode": "DRC",
    "stationname": "Dirico Airport",
    "lat": "175800S",
    "long": "0204700E"
  },
  {
    "stationcode": "CAV",
    "stationname": "Cazombo Airport",
    "lat": "115300S",
    "long": "0225600E"
  },
  {
    "stationcode": "NCJ",
    "stationname": "Sunchales Airport",
    "lat": "305728S",
    "long": "0613130W"
  },
  {
    "stationcode": "OFU",
    "stationname": "Ofu Airport",
    "lat": "141104S",
    "long": "1694013W"
  },
  {
    "stationcode": "FTI",
    "stationname": "Fituita Airport",
    "lat": "141259S",
    "long": "1692525W"
  },
  {
    "stationcode": "PPG",
    "stationname": "Pago Pago International Airport",
    "lat": "142001S",
    "long": "1704245W"
  },
  {
    "stationcode": "TAV",
    "stationname": "Tau Airport",
    "lat": "141500S",
    "long": "1693000W"
  },
  {
    "stationcode": "INN",
    "stationname": "Innsbruck Airport",
    "lat": "471537N",
    "long": "0112038E"
  },
  {
    "stationcode": "LNZ",
    "stationname": "Blue Danube Airport",
    "lat": "481400N",
    "long": "0141115E"
  },
  {
    "stationcode": "HOH",
    "stationname": "Hohenems/Dornbirn Airport",
    "lat": "472304N",
    "long": "0094159E"
  },
  {
    "stationcode": "SZG",
    "stationname": "W. A. Mozart Airport",
    "lat": "474736N",
    "long": "0130016E"
  },
  {
    "stationcode": "GRZ",
    "stationname": "Graz Airport",
    "lat": "465937N",
    "long": "0152624E"
  },
  {
    "stationcode": "VIE",
    "stationname": "Vienna International Airport",
    "lat": "480637N",
    "long": "0163441E"
  },
  {
    "stationcode": "KLU",
    "stationname": "Klagenfurt Airport",
    "lat": "463834N",
    "long": "0142016E"
  },
  {
    "stationcode": "AUA",
    "stationname": "Reina Beatrix Airport",
    "lat": "123005N",
    "long": "0700055W"
  },
  {
    "stationcode": "YLV",
    "stationname": "Yevlakh Airport",
    "lat": "403808N",
    "long": "0470816E"
  },
  {
    "stationcode": "LLK",
    "stationname": "Lankaran Airport",
    "lat": "384532N",
    "long": "0484823E"
  },
  {
    "stationcode": "GYD",
    "stationname": "Heydar Aliyev International Airport",
    "lat": "402803N",
    "long": "0500248E"
  },
  {
    "stationcode": "KVD",
    "stationname": "Gyandzha Airport",
    "lat": "404400N",
    "long": "0461900E"
  },
  {
    "stationcode": "ZZE",
    "stationname": "Zangilan Airport",
    "lat": "390556N",
    "long": "0464404E"
  },
  {
    "stationcode": "GNJ",
    "stationname": "Ganja Airport",
    "lat": "404406N",
    "long": "0461903E"
  },
  {
    "stationcode": "FZL",
    "stationname": "FUZULI INTERNATIONAL AIRPORT",
    "lat": "393541N",
    "long": "0471147E"
  },
  {
    "stationcode": "ZXT",
    "stationname": "Zabrat Airport",
    "lat": "402906N",
    "long": "0495901E"
  },
  {
    "stationcode": "ZTU",
    "stationname": "Zagatala Airport",
    "lat": "413344N",
    "long": "0464002E"
  },
  {
    "stationcode": "NAJ",
    "stationname": "Nakhichevan Airport",
    "lat": "391120N",
    "long": "0452730E"
  },
  {
    "stationcode": "OMO",
    "stationname": "Mostar Airport",
    "lat": "431658N",
    "long": "0175045E"
  },
  {
    "stationcode": "TZL",
    "stationname": "Tuzla International Airport",
    "lat": "442731N",
    "long": "0184332E"
  },
  {
    "stationcode": "BNX",
    "stationname": "Banja Luka Airport",
    "lat": "445600N",
    "long": "0171800E"
  },
  {
    "stationcode": "BGI",
    "stationname": "Grantley Adams International Airport",
    "lat": "130429N",
    "long": "0592933W"
  },
  {
    "stationcode": "ZYL",
    "stationname": "Civil Airport",
    "lat": "245748N",
    "long": "0915200E"
  },
  {
    "stationcode": "BZL",
    "stationname": "Barisal Airport",
    "lat": "224804N",
    "long": "0901804E"
  },
  {
    "stationcode": "SPD",
    "stationname": "Saidpur Airport",
    "lat": "254533N",
    "long": "0885432E"
  },
  {
    "stationcode": "RAU",
    "stationname": "Rangpur Airport",
    "lat": "254200N",
    "long": "0892200E"
  },
  {
    "stationcode": "SDW",
    "stationname": "Sandwip Airport",
    "lat": "160011N",
    "long": "0733158E"
  },
  {
    "stationcode": "RJH",
    "stationname": "Rajshahi Airport",
    "lat": "242600N",
    "long": "0883700E"
  },
  {
    "stationcode": "CGP",
    "stationname": "Patenga Airport",
    "lat": "221459N",
    "long": "0914848E"
  },
  {
    "stationcode": "KHL",
    "stationname": "Khulna Airport",
    "lat": "224800N",
    "long": "0893300E"
  },
  {
    "stationcode": "SAJ",
    "stationname": "Sirajganj Airport",
    "lat": "242500N",
    "long": "0894700E"
  },
  {
    "stationcode": "CLA",
    "stationname": "Comilla Airport",
    "lat": "232600N",
    "long": "0911100E"
  },
  {
    "stationcode": "TKR",
    "stationname": "Thakurgaon Airport",
    "lat": "260100N",
    "long": "0882800E"
  },
  {
    "stationcode": "ZHM",
    "stationname": "Shamshernagar Airport",
    "lat": "242355N",
    "long": "0915516E"
  },
  {
    "stationcode": "IRD",
    "stationname": "Ishurdi Airport",
    "lat": "240913N",
    "long": "0890256E"
  },
  {
    "stationcode": "JSR",
    "stationname": "Jessore Airport",
    "lat": "231102N",
    "long": "0890939E"
  },
  {
    "stationcode": "CXB",
    "stationname": "Coxs Bazar Airport",
    "lat": "212708N",
    "long": "0915750E"
  },
  {
    "stationcode": "KJK",
    "stationname": "Wevelgem Airport",
    "lat": "504902N",
    "long": "0031217E"
  },
  {
    "stationcode": "LGG",
    "stationname": "Liege Airport",
    "lat": "503815N",
    "long": "0052636E"
  },
  {
    "stationcode": "OBL",
    "stationname": "Oostmalle Airport",
    "lat": "511553N",
    "long": "0044512E"
  },
  {
    "stationcode": "BRU",
    "stationname": "Brussels Airport",
    "lat": "505405N",
    "long": "0042904E"
  },
  {
    "stationcode": "OST",
    "stationname": "Oostende/Brugge Airport",
    "lat": "511159N",
    "long": "0025231E"
  },
  {
    "stationcode": "CRL",
    "stationname": "Brussels S. Charleroi Airport",
    "lat": "502733N",
    "long": "0042714E"
  },
  {
    "stationcode": "ANR",
    "stationname": "Antwerp-Brussels North Airport",
    "lat": "511122N",
    "long": "0042737E"
  },
  {
    "stationcode": "XBG",
    "stationname": "Bogande Airport",
    "lat": "125900N",
    "long": "0000800W"
  },
  {
    "stationcode": "DOR",
    "stationname": "Dori Airport",
    "lat": "140200N",
    "long": "0000200W"
  },
  {
    "stationcode": "XAR",
    "stationname": "Aribinda Airport",
    "lat": "141400N",
    "long": "0005200W"
  },
  {
    "stationcode": "BNR",
    "stationname": "Banfora Airport",
    "lat": "103800N",
    "long": "0044600W"
  },
  {
    "stationcode": "ARL",
    "stationname": "Arly Airport",
    "lat": "113547N",
    "long": "0012852E"
  },
  {
    "stationcode": "XDE",
    "stationname": "Diebougou Airport",
    "lat": "105800N",
    "long": "0031500W"
  },
  {
    "stationcode": "XNU",
    "stationname": "Nouna Airport",
    "lat": "124400N",
    "long": "0035200W"
  },
  {
    "stationcode": "OUA",
    "stationname": "Ouagadougou Airport",
    "lat": "122112N",
    "long": "0013045W"
  },
  {
    "stationcode": "XSE",
    "stationname": "Sebba Airport",
    "lat": "132600N",
    "long": "0003200E"
  },
  {
    "stationcode": "XKY",
    "stationname": "Kaya Airport",
    "lat": "130500N",
    "long": "0010500W"
  },
  {
    "stationcode": "XGG",
    "stationname": "Gorum-Gorum Airport",
    "lat": "142600N",
    "long": "0001400W"
  },
  {
    "stationcode": "XPA",
    "stationname": "Pama Airport",
    "lat": "111500N",
    "long": "0004200E"
  },
  {
    "stationcode": "XGA",
    "stationname": "Gaoua Airport",
    "lat": "102000N",
    "long": "0031100W"
  },
  {
    "stationcode": "OUG",
    "stationname": "Ouahigouya Airport",
    "lat": "133400N",
    "long": "0022600W"
  },
  {
    "stationcode": "XDJ",
    "stationname": "Djibo Airport",
    "lat": "140600N",
    "long": "0013800W"
  },
  {
    "stationcode": "BOY",
    "stationname": "Borgo Airport",
    "lat": "110936N",
    "long": "0041951W"
  },
  {
    "stationcode": "XLU",
    "stationname": "Leo Airport",
    "lat": "110600N",
    "long": "0020600W"
  },
  {
    "stationcode": "DGU",
    "stationname": "Dedougou Airport",
    "lat": "122800N",
    "long": "0032900W"
  },
  {
    "stationcode": "XBO",
    "stationname": "Boulsa Airport",
    "lat": "123900N",
    "long": "0003400W"
  },
  {
    "stationcode": "FNG",
    "stationname": "Fada Ngourma Airport",
    "lat": "120400N",
    "long": "0002100E"
  },
  {
    "stationcode": "DIP",
    "stationname": "Diapaga Airport",
    "lat": "120300N",
    "long": "0014700E"
  },
  {
    "stationcode": "XZA",
    "stationname": "Zabre Airport",
    "lat": "110800N",
    "long": "0010000W"
  },
  {
    "stationcode": "TEG",
    "stationname": "Tenkodogo Airport",
    "lat": "114700N",
    "long": "0002200W"
  },
  {
    "stationcode": "PUP",
    "stationname": "Po Airport",
    "lat": "111000N",
    "long": "0010900W"
  },
  {
    "stationcode": "TMQ",
    "stationname": "Tambao Airport",
    "lat": "144700N",
    "long": "0002000E"
  },
  {
    "stationcode": "XKA",
    "stationname": "Kantchari Airport",
    "lat": "122900N",
    "long": "0013100E"
  },
  {
    "stationcode": "TUQ",
    "stationname": "Tougan Airport",
    "lat": "130333N",
    "long": "0030437W"
  },
  {
    "stationcode": "VAR",
    "stationname": "Varna Airport",
    "lat": "431355N",
    "long": "0274930E"
  },
  {
    "stationcode": "SZR",
    "stationname": "Kolyu Ganchevo Airport",
    "lat": "422236N",
    "long": "0253919E"
  },
  {
    "stationcode": "SOF",
    "stationname": "Vrazhdebna Airport",
    "lat": "424142N",
    "long": "0232430E"
  },
  {
    "stationcode": "GOZ",
    "stationname": "Gorna Oryahovitsa Airport",
    "lat": "430905N",
    "long": "0254246E"
  },
  {
    "stationcode": "TGV",
    "stationname": "Bukhovtsi Airport",
    "lat": "431824N",
    "long": "0264203E"
  },
  {
    "stationcode": "JAM",
    "stationname": "Bezmer Airport",
    "lat": "422718N",
    "long": "0262108E"
  },
  {
    "stationcode": "ROU",
    "stationname": "Shtruklevo Airport",
    "lat": "434141N",
    "long": "0260324E"
  },
  {
    "stationcode": "KDR",
    "stationname": "Kardjali Airport",
    "lat": "061300S",
    "long": "1493300E"
  },
  {
    "stationcode": "SLS",
    "stationname": "Polkovnik-Lambrinovo Airport",
    "lat": "440319N",
    "long": "0271044E"
  },
  {
    "stationcode": "HKV",
    "stationname": "Malevo Airport",
    "lat": "415218N",
    "long": "0253617E"
  },
  {
    "stationcode": "BOJ",
    "stationname": "Burgas Airport",
    "lat": "423411N",
    "long": "0273055E"
  },
  {
    "stationcode": "PDV",
    "stationname": "Krumovo Airport",
    "lat": "420404N",
    "long": "0245103E"
  },
  {
    "stationcode": "BAH",
    "stationname": "Bahrain International Airport",
    "lat": "261615N",
    "long": "0503815E"
  },
  {
    "stationcode": "GBQ",
    "stationname": "Muharraq Airport",
    "lat": "261458N",
    "long": "0503908E"
  },
  {
    "stationcode": "GID",
    "stationname": "Gitega Airport",
    "lat": "032500S",
    "long": "0295500E"
  },
  {
    "stationcode": "BJM",
    "stationname": "Bujumbura International Airport",
    "lat": "031926S",
    "long": "0291907E"
  },
  {
    "stationcode": "KRE",
    "stationname": "Kirundo Airport",
    "lat": "023300S",
    "long": "0300600E"
  },
  {
    "stationcode": "COO",
    "stationname": "Cotonou Airport",
    "lat": "062126N",
    "long": "0022304E"
  },
  {
    "stationcode": "SVF",
    "stationname": "Save Airport",
    "lat": "080200N",
    "long": "0022900E"
  },
  {
    "stationcode": "NAE",
    "stationname": "Natitingou Airport",
    "lat": "101900N",
    "long": "0012200E"
  },
  {
    "stationcode": "KDC",
    "stationname": "Kandi Airport",
    "lat": "110800N",
    "long": "0025600E"
  },
  {
    "stationcode": "PKO",
    "stationname": "Parakou Airport",
    "lat": "092125N",
    "long": "0023633E"
  },
  {
    "stationcode": "DJA",
    "stationname": "Djougou Airport",
    "lat": "094200N",
    "long": "0014000E"
  },
  {
    "stationcode": "SBH",
    "stationname": "St. Barthelemy Airport",
    "lat": "175416N",
    "long": "0625037W"
  },
  {
    "stationcode": "BDA",
    "stationname": "L.F. Wade International Airport",
    "lat": "322155N",
    "long": "0644124W"
  },
  {
    "stationcode": "SRJ",
    "stationname": "Capitan G Q Guardia Airport",
    "lat": "145133S",
    "long": "0664415W"
  },
  {
    "stationcode": "SJS",
    "stationname": "San Jose Airport",
    "lat": "174949S",
    "long": "0604436W"
  },
  {
    "stationcode": "SJB",
    "stationname": "San Joaquin Airport",
    "lat": "130500S",
    "long": "0644800W"
  },
  {
    "stationcode": "ORU",
    "stationname": "Oruro Airport",
    "lat": "175709S",
    "long": "0670433W"
  },
  {
    "stationcode": "SNM",
    "stationname": "San Ignacio De Moxos Airport",
    "lat": "145200S",
    "long": "0653500W"
  },
  {
    "stationcode": "SNG",
    "stationname": "San Ignacio De Velasco Airport",
    "lat": "162303S",
    "long": "0605747W"
  },
  {
    "stationcode": "SRD",
    "stationname": "San Ramon Airport",
    "lat": "131700S",
    "long": "0644200W"
  },
  {
    "stationcode": "BVK",
    "stationname": "Huacaraje Airport",
    "lat": "133300S",
    "long": "0634500W"
  },
  {
    "stationcode": "BYC",
    "stationname": "Yacuiba Airport",
    "lat": "215740S",
    "long": "0633907W"
  },
  {
    "stationcode": "APB",
    "stationname": "Apolo Airport",
    "lat": "144300S",
    "long": "0683000W"
  },
  {
    "stationcode": "TJA",
    "stationname": "Tarija Airport",
    "lat": "213304S",
    "long": "0644230W"
  },
  {
    "stationcode": "GYA",
    "stationname": "Guayaramerin Airport",
    "lat": "104904S",
    "long": "0652050W"
  },
  {
    "stationcode": "VVI",
    "stationname": "Viru Viru International Airport",
    "lat": "173841S",
    "long": "0630807W"
  },
  {
    "stationcode": "RIB",
    "stationname": "Gen Buech Airport",
    "lat": "110038S",
    "long": "0660425W"
  },
  {
    "stationcode": "LPB",
    "stationname": "El Alto Airport",
    "lat": "163048S",
    "long": "0681132W"
  },
  {
    "stationcode": "RBO",
    "stationname": "Robore Airport",
    "lat": "182000S",
    "long": "0594500W"
  },
  {
    "stationcode": "ASC",
    "stationname": "Ascension Airport",
    "lat": "155550S",
    "long": "0630925W"
  },
  {
    "stationcode": "SRE",
    "stationname": "Sucre Airport",
    "lat": "191445S",
    "long": "0650852W"
  },
  {
    "stationcode": "CIJ",
    "stationname": "E. Beltram Airport",
    "lat": "110226S",
    "long": "0684659W"
  },
  {
    "stationcode": "SJV",
    "stationname": "San Javier Airport",
    "lat": "161400S",
    "long": "0622500W"
  },
  {
    "stationcode": "CEP",
    "stationname": "Concepcion Airport",
    "lat": "161400S",
    "long": "0620500W"
  },
  {
    "stationcode": "SRB",
    "stationname": "Santa Rosa Airport",
    "lat": "141000S",
    "long": "0665300W"
  },
  {
    "stationcode": "CAM",
    "stationname": "Camiri Airport",
    "lat": "200100S",
    "long": "0633400W"
  },
  {
    "stationcode": "CBB",
    "stationname": "J Wilsterman Airport",
    "lat": "172516S",
    "long": "0661038W"
  },
  {
    "stationcode": "VAH",
    "stationname": "Vallegrande Airport",
    "lat": "182900S",
    "long": "0640600W"
  },
  {
    "stationcode": "RBQ",
    "stationname": "Rurrenabaque Airport",
    "lat": "142536S",
    "long": "0672956W"
  },
  {
    "stationcode": "TDD",
    "stationname": "Trinidad Airport",
    "lat": "144918S",
    "long": "0645454W"
  },
  {
    "stationcode": "SRZ",
    "stationname": "El Trompillo Airport",
    "lat": "174814S",
    "long": "0631038W"
  },
  {
    "stationcode": "PSZ",
    "stationname": "Puerto Suarez Airport",
    "lat": "185850S",
    "long": "0574926W"
  },
  {
    "stationcode": "BJO",
    "stationname": "Bermejo Airport",
    "lat": "225200S",
    "long": "0642000W"
  },
  {
    "stationcode": "POI",
    "stationname": "Potosi Airport",
    "lat": "193236S",
    "long": "0654326W"
  },
  {
    "stationcode": "VLM",
    "stationname": "Villamontes Airport",
    "lat": "211500S",
    "long": "0633000W"
  },
  {
    "stationcode": "MHW",
    "stationname": "Monteagudo Airport",
    "lat": "194924S",
    "long": "0635746W"
  },
  {
    "stationcode": "PUR",
    "stationname": "Puerto Rico Airport",
    "lat": "110700S",
    "long": "0673000W"
  },
  {
    "stationcode": "BVL",
    "stationname": "Baures Airport",
    "lat": "133500S",
    "long": "0633500W"
  },
  {
    "stationcode": "MQK",
    "stationname": "San Matias Airport",
    "lat": "162004S",
    "long": "0582308W"
  },
  {
    "stationcode": "MGD",
    "stationname": "Magdalena Airport",
    "lat": "130200S",
    "long": "0642500W"
  },
  {
    "stationcode": "REY",
    "stationname": "Reyes Airport",
    "lat": "141900S",
    "long": "0671600W"
  },
  {
    "stationcode": "SBL",
    "stationname": "Yacuma Airport",
    "lat": "134545S",
    "long": "0652605W"
  },
  {
    "stationcode": "UYU",
    "stationname": "Uyuni Airport",
    "lat": "202700S",
    "long": "0665033W"
  },
  {
    "stationcode": "TCB",
    "stationname": "Treasure Cay Airport",
    "lat": "264406N",
    "long": "0772220W"
  },
  {
    "stationcode": "CAT",
    "stationname": "Cat Island Airport",
    "lat": "384319N",
    "long": "0092113W"
  },
  {
    "stationcode": "ZSA",
    "stationname": "San Salvador Airport",
    "lat": "240348N",
    "long": "0743126W"
  },
  {
    "stationcode": "COX",
    "stationname": "Congo Town Airport",
    "lat": "240900N",
    "long": "0773500W"
  },
  {
    "stationcode": "MYG",
    "stationname": "Mayaguana Airport",
    "lat": "222246N",
    "long": "0730049W"
  },
  {
    "stationcode": "SAQ",
    "stationname": "San Andros Airport",
    "lat": "250314N",
    "long": "0780256W"
  },
  {
    "stationcode": "PID",
    "stationname": "Paradise Island Airport",
    "lat": "250500N",
    "long": "0771800W"
  },
  {
    "stationcode": "NAS",
    "stationname": "Nassau International Airport",
    "lat": "250226N",
    "long": "0772819W"
  },
  {
    "stationcode": "TZN",
    "stationname": "South Andros Airport",
    "lat": "244150N",
    "long": "0774746W"
  },
  {
    "stationcode": "WKR",
    "stationname": "Walkers Cay Airport",
    "lat": "271600N",
    "long": "0782400W"
  },
  {
    "stationcode": "SML",
    "stationname": "Estate Airstrip",
    "lat": "233459N",
    "long": "0751607W"
  },
  {
    "stationcode": "CXY",
    "stationname": "Cat Cays Airport",
    "lat": "253318N",
    "long": "0791632W"
  },
  {
    "stationcode": "NMC",
    "stationname": "Norman's Cay Airport",
    "lat": "243500N",
    "long": "0765000W"
  },
  {
    "stationcode": "WTD",
    "stationname": "West End Airport",
    "lat": "264100N",
    "long": "0785900W"
  },
  {
    "stationcode": "GHC",
    "stationname": "Great Harbour Airport",
    "lat": "254501N",
    "long": "0775100W"
  },
  {
    "stationcode": "TYM",
    "stationname": "Staniel Cay Airport",
    "lat": "240909N",
    "long": "0762602W"
  },
  {
    "stationcode": "NET",
    "stationname": "New Bight Airport",
    "lat": "171600N",
    "long": "0875100W"
  },
  {
    "stationcode": "MAY",
    "stationname": "Mangrove Cay Airport",
    "lat": "241717N",
    "long": "0774054W"
  },
  {
    "stationcode": "GGT",
    "stationname": "Exuma International Airport",
    "lat": "233345N",
    "long": "0755241W"
  },
  {
    "stationcode": "FPO",
    "stationname": "Grand Bahama International Airport",
    "lat": "263331N",
    "long": "0784144W"
  },
  {
    "stationcode": "RCY",
    "stationname": "Rum Cay Airport",
    "lat": "233906N",
    "long": "0745042W"
  },
  {
    "stationcode": "SWL",
    "stationname": "Spanish Wells Airport",
    "lat": "253300N",
    "long": "0764500W"
  },
  {
    "stationcode": "DCT",
    "stationname": "Duncan Town Airport",
    "lat": "221100N",
    "long": "0754400W"
  },
  {
    "stationcode": "GBI",
    "stationname": "Aux Ab Airport",
    "lat": "171828N",
    "long": "0765731E"
  },
  {
    "stationcode": "BIM",
    "stationname": "Bimini International Airport",
    "lat": "254200N",
    "long": "0791553W"
  },
  {
    "stationcode": "RSD",
    "stationname": "South Eleuthera Airport",
    "lat": "245330N",
    "long": "0761040W"
  },
  {
    "stationcode": "PPO",
    "stationname": "Powell Point Airport",
    "lat": "245400N",
    "long": "0762100W"
  },
  {
    "stationcode": "IGA",
    "stationname": "Inagua Airport",
    "lat": "205900N",
    "long": "0734000W"
  },
  {
    "stationcode": "AXP",
    "stationname": "Springpoint Airport",
    "lat": "222631N",
    "long": "0735815W"
  },
  {
    "stationcode": "ATC",
    "stationname": "Arthur's Town Airport",
    "lat": "243746N",
    "long": "0754026W"
  },
  {
    "stationcode": "CEL",
    "stationname": "Cape Eleuthera Airport",
    "lat": "292214S",
    "long": "0504956W"
  },
  {
    "stationcode": "GHB",
    "stationname": "Governor's Harbour Airport",
    "lat": "251705N",
    "long": "0761952W"
  },
  {
    "stationcode": "MHH",
    "stationname": "Marsh Harbour International Airport",
    "lat": "263041N",
    "long": "0770501W"
  },
  {
    "stationcode": "GTC",
    "stationname": "Green Turtle Airport",
    "lat": "024433S",
    "long": "0314225E"
  },
  {
    "stationcode": "MSK",
    "stationname": "Mastic Point Airport",
    "lat": "034504N",
    "long": "0712723W"
  },
  {
    "stationcode": "CCZ",
    "stationname": "Chub Cay Airport",
    "lat": "252502N",
    "long": "0775251W"
  },
  {
    "stationcode": "CRI",
    "stationname": "Crooked Island Airport",
    "lat": "224500N",
    "long": "0740900W"
  },
  {
    "stationcode": "LGI",
    "stationname": "Deadmans Cay Airport",
    "lat": "231044N",
    "long": "0750537W"
  },
  {
    "stationcode": "ELH",
    "stationname": "North Eleuthera International Airport",
    "lat": "252830N",
    "long": "0764101W"
  },
  {
    "stationcode": "TBI",
    "stationname": "The Bight Airport",
    "lat": "241856N",
    "long": "0752713W"
  },
  {
    "stationcode": "ASD",
    "stationname": "Andros Town Airport",
    "lat": "244154N",
    "long": "0774744W"
  },
  {
    "stationcode": "PWN",
    "stationname": "Pitts Town Airport",
    "lat": "225000N",
    "long": "0742100W"
  },
  {
    "stationcode": "HBI",
    "stationname": "Harbour Island Airport",
    "lat": "253100N",
    "long": "0763600W"
  },
  {
    "stationcode": "GLU",
    "stationname": "Gelephu Airport",
    "lat": "265306N",
    "long": "0902752E"
  },
  {
    "stationcode": "YON",
    "stationname": "Yonphula Airport",
    "lat": "271522N",
    "long": "0913055E"
  },
  {
    "stationcode": "PBH",
    "stationname": "Paro Airport",
    "lat": "272411N",
    "long": "0892529E"
  },
  {
    "stationcode": "PKW",
    "stationname": "Selebi-Phikwe Airport",
    "lat": "220300S",
    "long": "0274900E"
  },
  {
    "stationcode": "SXN",
    "stationname": "Suapan Airport",
    "lat": "203300S",
    "long": "0260700E"
  },
  {
    "stationcode": "JWA",
    "stationname": "Jwaneng Airport",
    "lat": "243609S",
    "long": "0244128E"
  },
  {
    "stationcode": "HUK",
    "stationname": "Hukuntsi Airport",
    "lat": "235900S",
    "long": "0214500E"
  },
  {
    "stationcode": "GBE",
    "stationname": "Sir Seretse Khama International Airport",
    "lat": "243319S",
    "long": "0255506E"
  },
  {
    "stationcode": "TLD",
    "stationname": "Limpopo Valley Airfield",
    "lat": "221000S",
    "long": "0290600E"
  },
  {
    "stationcode": "TBY",
    "stationname": "Tsabong Airport",
    "lat": "260400S",
    "long": "0222800E"
  },
  {
    "stationcode": "FRW",
    "stationname": "Francistown Airport",
    "lat": "210935S",
    "long": "0272828E"
  },
  {
    "stationcode": "ORP",
    "stationname": "Orapa Airport",
    "lat": "211558S",
    "long": "0251912E"
  },
  {
    "stationcode": "LOQ",
    "stationname": "Lobatse Airport",
    "lat": "251200S",
    "long": "0254000E"
  },
  {
    "stationcode": "GNZ",
    "stationname": "Ghanzi Airport",
    "lat": "214200S",
    "long": "0214000E"
  },
  {
    "stationcode": "BBK",
    "stationname": "Kasane Airport",
    "lat": "174958S",
    "long": "0250945E"
  },
  {
    "stationcode": "KHW",
    "stationname": "Khwai River Lodge Airport",
    "lat": "190800S",
    "long": "0235000E"
  },
  {
    "stationcode": "SVT",
    "stationname": "Savuti Airport",
    "lat": "183800S",
    "long": "0240500E"
  },
  {
    "stationcode": "SWX",
    "stationname": "Shakawe Airport",
    "lat": "182300S",
    "long": "0215000E"
  },
  {
    "stationcode": "MUB",
    "stationname": "Maun Airport",
    "lat": "195821S",
    "long": "0232552E"
  },
  {
    "stationcode": "MHP",
    "stationname": "Minsk International 1 Airport",
    "lat": "535152N",
    "long": "0273223E"
  },
  {
    "stationcode": "VTB",
    "stationname": "Vitebsk Airport",
    "lat": "550735N",
    "long": "0302101E"
  },
  {
    "stationcode": "GNA",
    "stationname": "Grodno Airport",
    "lat": "533620N",
    "long": "0240314E"
  },
  {
    "stationcode": "MSQ",
    "stationname": "Minsk International 2 Airport",
    "lat": "535257N",
    "long": "0280151E"
  },
  {
    "stationcode": "BQT",
    "stationname": "Brest Airport",
    "lat": "520630N",
    "long": "0235349E"
  },
  {
    "stationcode": "GME",
    "stationname": "Gomel Airport",
    "lat": "523137N",
    "long": "0310100E"
  },
  {
    "stationcode": "MVQ",
    "stationname": "Mogilev Airport",
    "lat": "535700N",
    "long": "0300800E"
  },
  {
    "stationcode": "BGK",
    "stationname": "Big Creek Airport",
    "lat": "163100N",
    "long": "0882500W"
  },
  {
    "stationcode": "SJX",
    "stationname": "Sartaneja Airport",
    "lat": "181800N",
    "long": "0882000W"
  },
  {
    "stationcode": "CYD",
    "stationname": "Town Airstrip",
    "lat": "170618N",
    "long": "0890625W"
  },
  {
    "stationcode": "ORZ",
    "stationname": "Orange Walk Airport",
    "lat": "180432N",
    "long": "0883255W"
  },
  {
    "stationcode": "DGA",
    "stationname": "Dangriga Airport",
    "lat": "165857N",
    "long": "0881352W"
  },
  {
    "stationcode": "STU",
    "stationname": "Santa Cruz Airport",
    "lat": "181600N",
    "long": "0882700W"
  },
  {
    "stationcode": "MDB",
    "stationname": "Melinda Airport",
    "lat": "165959N",
    "long": "0881900W"
  },
  {
    "stationcode": "SVK",
    "stationname": "Silver Creek Airport",
    "lat": "164343N",
    "long": "0882209W"
  },
  {
    "stationcode": "TZA",
    "stationname": "Belize City Municipal Airport",
    "lat": "173059N",
    "long": "0881144W"
  },
  {
    "stationcode": "CYC",
    "stationname": "Caye Chapel Airport",
    "lat": "174101N",
    "long": "0880242W"
  },
  {
    "stationcode": "MZE",
    "stationname": "Manatee Airport",
    "lat": "171643N",
    "long": "0890139W"
  },
  {
    "stationcode": "BCV",
    "stationname": "Belmopan Airport",
    "lat": "171611N",
    "long": "0884636W"
  },
  {
    "stationcode": "INB",
    "stationname": "Independence Airport",
    "lat": "163203N",
    "long": "0882635W"
  },
  {
    "stationcode": "BZE",
    "stationname": "Philip S.W. Goldson International Airport",
    "lat": "173221N",
    "long": "0881830W"
  },
  {
    "stationcode": "PND",
    "stationname": "Punta Gorda Airport",
    "lat": "160608N",
    "long": "0884830W"
  },
  {
    "stationcode": "SPR",
    "stationname": "San Pedro Airport",
    "lat": "175500N",
    "long": "0875800W"
  },
  {
    "stationcode": "CZH",
    "stationname": "Corozal Airport",
    "lat": "182257N",
    "long": "0882439W"
  },
  {
    "stationcode": "CUK",
    "stationname": "Caye Caulker Airport",
    "lat": "174407N",
    "long": "0880157W"
  },
  {
    "stationcode": "SQS",
    "stationname": "Matthew Spain Airport",
    "lat": "171110N",
    "long": "0890036W"
  },
  {
    "stationcode": "PLJ",
    "stationname": "Placencia Airport",
    "lat": "163214N",
    "long": "0882140W"
  },
  {
    "stationcode": "CCK",
    "stationname": "Cocos Islands Airport",
    "lat": "121115S",
    "long": "0964953E"
  },
  {
    "stationcode": "KYB",
    "stationname": "Yangoonabie Airport",
    "lat": "015700S",
    "long": "1245800E"
  },
  {
    "stationcode": "BSN",
    "stationname": "Bossangoa Airport",
    "lat": "063000N",
    "long": "0172600E"
  },
  {
    "stationcode": "NDL",
    "stationname": "Ndele Airport",
    "lat": "082400N",
    "long": "0203900E"
  },
  {
    "stationcode": "BCF",
    "stationname": "Bouca Airport",
    "lat": "063000N",
    "long": "0181700E"
  },
  {
    "stationcode": "GDA",
    "stationname": "Gounda Airport",
    "lat": "092000N",
    "long": "0211100E"
  },
  {
    "stationcode": "BBY",
    "stationname": "Bambari Airport",
    "lat": "055100N",
    "long": "0203800E"
  },
  {
    "stationcode": "BMF",
    "stationname": "Bakouma Airport",
    "lat": "054400N",
    "long": "0224800E"
  },
  {
    "stationcode": "BTG",
    "stationname": "Batangafo Airport",
    "lat": "071800N",
    "long": "0181800E"
  },
  {
    "stationcode": "BGU",
    "stationname": "Bangassou Airport",
    "lat": "044700N",
    "long": "0224800E"
  },
  {
    "stationcode": "BEM",
    "stationname": "Bossembele Airport",
    "lat": "322400N",
    "long": "0061902W"
  },
  {
    "stationcode": "GDI",
    "stationname": "Gordil Airport",
    "lat": "094400N",
    "long": "0213500E"
  },
  {
    "stationcode": "CRF",
    "stationname": "Carnot Airport",
    "lat": "045600N",
    "long": "0155400E"
  },
  {
    "stationcode": "BOP",
    "stationname": "Bouar Airport",
    "lat": "055800N",
    "long": "0153800E"
  },
  {
    "stationcode": "MKI",
    "stationname": "M'boki Airport",
    "lat": "051900N",
    "long": "0255700E"
  },
  {
    "stationcode": "BOZ",
    "stationname": "Bozoum Airport",
    "lat": "061900N",
    "long": "0162300E"
  },
  {
    "stationcode": "KWD",
    "stationname": "Kawadjia Airport",
    "lat": "075800N",
    "long": "0233200E"
  },
  {
    "stationcode": "BIV",
    "stationname": "Bria Airport",
    "lat": "063200N",
    "long": "0215900E"
  },
  {
    "stationcode": "ODJ",
    "stationname": "Ouanda Djalle Airport",
    "lat": "085500N",
    "long": "0223500E"
  },
  {
    "stationcode": "IMO",
    "stationname": "Zemio Airport",
    "lat": "050009N",
    "long": "0250608E"
  },
  {
    "stationcode": "AIG",
    "stationname": "Yalinga Airport",
    "lat": "063100N",
    "long": "0231500E"
  },
  {
    "stationcode": "BBT",
    "stationname": "Berberati Airport",
    "lat": "041300N",
    "long": "0154700E"
  },
  {
    "stationcode": "KOL",
    "stationname": "Koumala Airport",
    "lat": "083000N",
    "long": "0211500E"
  },
  {
    "stationcode": "ODA",
    "stationname": "Ouadda Airport",
    "lat": "080000N",
    "long": "0222500E"
  },
  {
    "stationcode": "IRO",
    "stationname": "Birao Airport",
    "lat": "101413N",
    "long": "0224257E"
  },
  {
    "stationcode": "BGF",
    "stationname": "Bangui Airport",
    "lat": "042352N",
    "long": "0183112E"
  },
  {
    "stationcode": "RFA",
    "stationname": "Rafai Airport",
    "lat": "045900N",
    "long": "0235500E"
  },
  {
    "stationcode": "KMK",
    "stationname": "Makabana Airport",
    "lat": "032853S",
    "long": "0123548E"
  },
  {
    "stationcode": "MUY",
    "stationname": "Mouyondzi Airport",
    "lat": "035900S",
    "long": "0135510E"
  },
  {
    "stationcode": "FTX",
    "stationname": "Owando Airport",
    "lat": "003153S",
    "long": "0155701E"
  },
  {
    "stationcode": "SIB",
    "stationname": "Sibiti Airport",
    "lat": "034400S",
    "long": "0132400E"
  },
  {
    "stationcode": "PNR",
    "stationname": "Pointe Noire Airport",
    "lat": "044858S",
    "long": "0115312E"
  },
  {
    "stationcode": "KNJ",
    "stationname": "Kindamba Airport",
    "lat": "034400S",
    "long": "0141300E"
  },
  {
    "stationcode": "BTB",
    "stationname": "Betou Airport",
    "lat": "030300N",
    "long": "0183100E"
  },
  {
    "stationcode": "GMM",
    "stationname": "Gamboma Airport",
    "lat": "015600S",
    "long": "0155200E"
  },
  {
    "stationcode": "EPN",
    "stationname": "Epena Airport",
    "lat": "012200N",
    "long": "0172900E"
  },
  {
    "stationcode": "NKY",
    "stationname": "Nkayi Airport",
    "lat": "041322S",
    "long": "0131712E"
  },
  {
    "stationcode": "LCO",
    "stationname": "Lague Airport",
    "lat": "014800S",
    "long": "0145200E"
  },
  {
    "stationcode": "MSX",
    "stationname": "Mossendjo Airport",
    "lat": "025700S",
    "long": "0124200E"
  },
  {
    "stationcode": "EWO",
    "stationname": "Ewo Airport",
    "lat": "005300S",
    "long": "0144800E"
  },
  {
    "stationcode": "OLL",
    "stationname": "Ollombo Airport",
    "lat": "011333S",
    "long": "0155437E"
  },
  {
    "stationcode": "BOE",
    "stationname": "Boundji Airport",
    "lat": "010200S",
    "long": "0152300E"
  },
  {
    "stationcode": "LKC",
    "stationname": "Lekana Airport",
    "lat": "021900S",
    "long": "0143600E"
  },
  {
    "stationcode": "KEE",
    "stationname": "Kelle Airport",
    "lat": "000600S",
    "long": "0143300E"
  },
  {
    "stationcode": "SOE",
    "stationname": "Souanke Airport",
    "lat": "020500N",
    "long": "0140300E"
  },
  {
    "stationcode": "OKG",
    "stationname": "Okoyo Airport",
    "lat": "012800S",
    "long": "0150400E"
  },
  {
    "stationcode": "OUE",
    "stationname": "Ouesso Airport",
    "lat": "013700N",
    "long": "0160200E"
  },
  {
    "stationcode": "ION",
    "stationname": "Impfondo Airport",
    "lat": "013700N",
    "long": "0180400E"
  },
  {
    "stationcode": "DIS",
    "stationname": "Loubomo Airport",
    "lat": "041221S",
    "long": "0123934E"
  },
  {
    "stationcode": "DJM",
    "stationname": "Djambala Airport",
    "lat": "023200S",
    "long": "0144500E"
  },
  {
    "stationcode": "MKJ",
    "stationname": "Makoua Airport",
    "lat": "000100S",
    "long": "0153500E"
  },
  {
    "stationcode": "ANJ",
    "stationname": "Zanaga Airport",
    "lat": "025100S",
    "long": "0135000E"
  },
  {
    "stationcode": "BZV",
    "stationname": "Maya Maya Airport",
    "lat": "041506S",
    "long": "0151511E"
  },
  {
    "stationcode": "BRN",
    "stationname": "Belp Airport",
    "lat": "465451N",
    "long": "0072950E"
  },
  {
    "stationcode": "SMV",
    "stationname": "Engadin",
    "lat": "463203N",
    "long": "0095303E"
  },
  {
    "stationcode": "ACO",
    "stationname": "Ascona Airport",
    "lat": "460934N",
    "long": "0084659E"
  },
  {
    "stationcode": "SIR",
    "stationname": "Sion Airport",
    "lat": "461321N",
    "long": "0072024E"
  },
  {
    "stationcode": "BXO",
    "stationname": "Buochs Airport",
    "lat": "465800N",
    "long": "0082300E"
  },
  {
    "stationcode": "LUG",
    "stationname": "Agno",
    "lat": "460015N",
    "long": "0085438E"
  },
  {
    "stationcode": "EML",
    "stationname": "Emmen Airport",
    "lat": "470532N",
    "long": "0081817E"
  },
  {
    "stationcode": "ACH",
    "stationname": "St Gallen-Altenrhein Airport",
    "lat": "472906N",
    "long": "0093339E"
  },
  {
    "stationcode": "GVA",
    "stationname": "Geneva International Airport",
    "lat": "461417N",
    "long": "0060632E"
  },
  {
    "stationcode": "BSL",
    "stationname": "EuroAirport Swiss",
    "lat": "473604N",
    "long": "0073118E"
  },
  {
    "stationcode": "ZRH",
    "stationname": "Zurich Airport",
    "lat": "472730N",
    "long": "0083306E"
  },
  {
    "stationcode": "BYK",
    "stationname": "Bouake Airport",
    "lat": "074413N",
    "long": "0050416W"
  },
  {
    "stationcode": "ZSS",
    "stationname": "Sassandra Airport",
    "lat": "045600N",
    "long": "0060800W"
  },
  {
    "stationcode": "OGO",
    "stationname": "Abengourou Airport",
    "lat": "064400N",
    "long": "0032900W"
  },
  {
    "stationcode": "LKT",
    "stationname": "Lakota Airport",
    "lat": "055000N",
    "long": "0053000W"
  },
  {
    "stationcode": "BBV",
    "stationname": "Bereby Airport",
    "lat": "044000N",
    "long": "0065700W"
  },
  {
    "stationcode": "DJO",
    "stationname": "Daloa Airport",
    "lat": "064735N",
    "long": "0062823W"
  },
  {
    "stationcode": "BUU",
    "stationname": "Buyo Airport",
    "lat": "013230S",
    "long": "1021054E"
  },
  {
    "stationcode": "FEK",
    "stationname": "Ferkessedougou Airport",
    "lat": "093600N",
    "long": "0051200W"
  },
  {
    "stationcode": "TGX",
    "stationname": "Tingrela Airport",
    "lat": "310303N",
    "long": "1165843E"
  },
  {
    "stationcode": "TOZ",
    "stationname": "Touba Airport",
    "lat": "081700N",
    "long": "0074100W"
  },
  {
    "stationcode": "ABO",
    "stationname": "Aboisso Airport",
    "lat": "052800N",
    "long": "0031200W"
  },
  {
    "stationcode": "ASK",
    "stationname": "Yamoussoukro Airport",
    "lat": "065414N",
    "long": "0052156W"
  },
  {
    "stationcode": "DNC",
    "stationname": "Danane Airport",
    "lat": "072100N",
    "long": "0081000W"
  },
  {
    "stationcode": "BDK",
    "stationname": "Bondoukou Airport",
    "lat": "080106N",
    "long": "0024543W"
  },
  {
    "stationcode": "GGN",
    "stationname": "Gagnoa Airport",
    "lat": "060800N",
    "long": "0055600W"
  },
  {
    "stationcode": "ZUE",
    "stationname": "Zuenoula Airport",
    "lat": "072600N",
    "long": "0060300W"
  },
  {
    "stationcode": "BGG",
    "stationname": "Bongouanou Airport",
    "lat": "385141N",
    "long": "0403533E"
  },
  {
    "stationcode": "GGO",
    "stationname": "Guilo Airport",
    "lat": "063300N",
    "long": "0072900W"
  },
  {
    "stationcode": "KTC",
    "stationname": "Katiola Airport",
    "lat": "080800N",
    "long": "0050600W"
  },
  {
    "stationcode": "BRZ",
    "stationname": "Borotou Airport",
    "lat": "453155N",
    "long": "0101246E"
  },
  {
    "stationcode": "DIV",
    "stationname": "Divo Airport",
    "lat": "055000N",
    "long": "0052200W"
  },
  {
    "stationcode": "ABJ",
    "stationname": "Felix Houphouet Boigny Airport",
    "lat": "051523N",
    "long": "0035537W"
  },
  {
    "stationcode": "MOK",
    "stationname": "Mankono Airport",
    "lat": "434519N",
    "long": "0590155E"
  },
  {
    "stationcode": "DIM",
    "stationname": "Dimbokro Airport",
    "lat": "063900N",
    "long": "0044200W"
  },
  {
    "stationcode": "MJC",
    "stationname": "Man Airport",
    "lat": "071619N",
    "long": "0073515W"
  },
  {
    "stationcode": "XMB",
    "stationname": "M'Bahiakro Airport",
    "lat": "350654N",
    "long": "1290232E"
  },
  {
    "stationcode": "TXU",
    "stationname": "Tabou Airport",
    "lat": "042600N",
    "long": "0072200W"
  },
  {
    "stationcode": "BQO",
    "stationname": "Bouna Airport",
    "lat": "091600N",
    "long": "0030000W"
  },
  {
    "stationcode": "SEO",
    "stationname": "Seguela Airport",
    "lat": "075732N",
    "long": "0063841W"
  },
  {
    "stationcode": "KEO",
    "stationname": "Odienne Airport",
    "lat": "093220N",
    "long": "0073345W"
  },
  {
    "stationcode": "TBX",
    "stationname": "Taabo Airport",
    "lat": "005900N",
    "long": "1073401E"
  },
  {
    "stationcode": "HGO",
    "stationname": "Korhogo Airport",
    "lat": "092314N",
    "long": "0053326W"
  },
  {
    "stationcode": "SPY",
    "stationname": "San Pedro Airport",
    "lat": "044448N",
    "long": "0063939W"
  },
  {
    "stationcode": "OFI",
    "stationname": "Ouango Fitini Airport",
    "lat": "093600N",
    "long": "0040200W"
  },
  {
    "stationcode": "BXI",
    "stationname": "Boundiali Airport",
    "lat": "093100N",
    "long": "0062900W"
  },
  {
    "stationcode": "MOI",
    "stationname": "Mitiaro Island Airport",
    "lat": "195032S",
    "long": "1574214W"
  },
  {
    "stationcode": "MUK",
    "stationname": "Mauke Island Airport",
    "lat": "200811S",
    "long": "1572045W"
  },
  {
    "stationcode": "MHX",
    "stationname": "Manihiki Island Airport",
    "lat": "102208S",
    "long": "1610000W"
  },
  {
    "stationcode": "AIT",
    "stationname": "Aitutaki Airport",
    "lat": "184952S",
    "long": "1594553W"
  },
  {
    "stationcode": "MGS",
    "stationname": "Mangaia Island Airport",
    "lat": "215338S",
    "long": "1575430W"
  },
  {
    "stationcode": "AIU",
    "stationname": "Atiu Island Airport",
    "lat": "195800S",
    "long": "1580730W"
  },
  {
    "stationcode": "PYE",
    "stationname": "Penrhyn Island Airport",
    "lat": "090024S",
    "long": "1580212W"
  },
  {
    "stationcode": "PZK",
    "stationname": "Puka Puka Island Airport",
    "lat": "105454S",
    "long": "1685027W"
  },
  {
    "stationcode": "RAR",
    "stationname": "Rarotonga Airport",
    "lat": "211210S",
    "long": "1594754W"
  },
  {
    "stationcode": "BLC",
    "stationname": "Bali Airport",
    "lat": "055400N",
    "long": "0100000E"
  },
  {
    "stationcode": "MMF",
    "stationname": "Mamfe Airport",
    "lat": "054300N",
    "long": "0091800E"
  },
  {
    "stationcode": "BFX",
    "stationname": "Bafoussam Airport",
    "lat": "053215N",
    "long": "0102115E"
  },
  {
    "stationcode": "YAO",
    "stationname": "Yaounde Airport",
    "lat": "035001N",
    "long": "0113125E"
  },
  {
    "stationcode": "DSC",
    "stationname": "Dschang Airport",
    "lat": "052651N",
    "long": "0100405E"
  },
  {
    "stationcode": "BTA",
    "stationname": "Bertoua Airport",
    "lat": "043257N",
    "long": "0134335E"
  },
  {
    "stationcode": "KBI",
    "stationname": "Kribi Airport",
    "lat": "025200N",
    "long": "0095900E"
  },
  {
    "stationcode": "NGE",
    "stationname": "Ngaoundere Airport",
    "lat": "072125N",
    "long": "0133333E"
  },
  {
    "stationcode": "VCC",
    "stationname": "Limbe Airport",
    "lat": "282556S",
    "long": "0510130W"
  },
  {
    "stationcode": "MVR",
    "stationname": "Salam Airport",
    "lat": "102705N",
    "long": "0141527E"
  },
  {
    "stationcode": "NSI",
    "stationname": "Nsimalen Airport",
    "lat": "034321N",
    "long": "0113312E"
  },
  {
    "stationcode": "NKS",
    "stationname": "Nkongsamba Airport",
    "lat": "045700N",
    "long": "0095600E"
  },
  {
    "stationcode": "DLA",
    "stationname": "Douala Airport",
    "lat": "040022N",
    "long": "0094310E"
  },
  {
    "stationcode": "GOU",
    "stationname": "Garoua Airport",
    "lat": "092009N",
    "long": "0132212E"
  },
  {
    "stationcode": "KLE",
    "stationname": "Kaele Airport",
    "lat": "100600N",
    "long": "0142700E"
  },
  {
    "stationcode": "FOM",
    "stationname": "Foumban Airport",
    "lat": "053800N",
    "long": "0104500E"
  },
  {
    "stationcode": "TKC",
    "stationname": "Tiko Airport",
    "lat": "040700N",
    "long": "0092000E"
  },
  {
    "stationcode": "KOB",
    "stationname": "Koutaba Airport",
    "lat": "053900N",
    "long": "0104500E"
  },
  {
    "stationcode": "GXX",
    "stationname": "Yagoua Airport",
    "lat": "102137N",
    "long": "0151414E"
  },
  {
    "stationcode": "EBW",
    "stationname": "Ebolowa Airport",
    "lat": "025100N",
    "long": "0111100E"
  },
  {
    "stationcode": "BPC",
    "stationname": "Bamenda Airport",
    "lat": "060218N",
    "long": "0100723E"
  },
  {
    "stationcode": "OUR",
    "stationname": "Batouri Airport",
    "lat": "042815N",
    "long": "0142125E"
  },
  {
    "stationcode": "DYG",
    "stationname": "Dayong Airport",
    "lat": "290616N",
    "long": "1102635E"
  },
  {
    "stationcode": "NLH",
    "stationname": "Luguhu Airport",
    "lat": "273225N",
    "long": "1004533E"
  },
  {
    "stationcode": "FUO",
    "stationname": "Foshan Airport",
    "lat": "230454N",
    "long": "1130412E"
  },
  {
    "stationcode": "LYI",
    "stationname": "Linyi Airport",
    "lat": "350258N",
    "long": "1182443E"
  },
  {
    "stationcode": "LLV",
    "stationname": "Luliang Airport",
    "lat": "374053N",
    "long": "1110833E"
  },
  {
    "stationcode": "JUH",
    "stationname": "Jiuhuashan Airport",
    "lat": "304425N",
    "long": "1174112E"
  },
  {
    "stationcode": "RKZ",
    "stationname": "He-Ping Airport",
    "lat": "292107N",
    "long": "0891836E"
  },
  {
    "stationcode": "HIA",
    "stationname": "Lianshui Airport",
    "lat": "334715N",
    "long": "1190740E"
  },
  {
    "stationcode": "LZN",
    "stationname": "Nangan Airport (Matsu Islands)",
    "lat": "260934N",
    "long": "1195730E"
  },
  {
    "stationcode": "HZA",
    "stationname": "Heze Mudan",
    "lat": "351247N",
    "long": "1154413E"
  },
  {
    "stationcode": "HLJ",
    "stationname": "Beidahuang General",
    "lat": "460741N",
    "long": "1254624E"
  },
  {
    "stationcode": "AKA",
    "stationname": "Ankang Airport",
    "lat": "324228N",
    "long": "1085556E"
  },
  {
    "stationcode": "AKU",
    "stationname": "Aksu Airport",
    "lat": "411546N",
    "long": "0801731E"
  },
  {
    "stationcode": "AVA",
    "stationname": "An Shun/Huang Guo Shu Airport",
    "lat": "261526N",
    "long": "1055222E"
  },
  {
    "stationcode": "WEH",
    "stationname": "Weihai Airport",
    "lat": "371114N",
    "long": "1221344E"
  },
  {
    "stationcode": "LJG",
    "stationname": "Lijiang Airport",
    "lat": "264027N",
    "long": "1001437E"
  },
  {
    "stationcode": "JGN",
    "stationname": "Jiayuguan Airport",
    "lat": "395126N",
    "long": "0982029E"
  },
  {
    "stationcode": "JIQ",
    "stationname": "Zhoubai Airport",
    "lat": "293053N",
    "long": "1084954E"
  },
  {
    "stationcode": "NLT",
    "stationname": "Nalati Airport",
    "lat": "432556N",
    "long": "0832256E"
  },
  {
    "stationcode": "WUZ",
    "stationname": "Changzhoudao Airport",
    "lat": "232713N",
    "long": "1111525E"
  },
  {
    "stationcode": "TCZ",
    "stationname": "Hump Airport",
    "lat": "245616N",
    "long": "0982908E"
  },
  {
    "stationcode": "HDG",
    "stationname": "Hebei Handan Airport",
    "lat": "363133N",
    "long": "1142530E"
  },
  {
    "stationcode": "LYA",
    "stationname": "Luoyang Airport",
    "lat": "344429N",
    "long": "1122322E"
  },
  {
    "stationcode": "YTW",
    "stationname": "Wanfang",
    "lat": "364859N",
    "long": "0814653E"
  },
  {
    "stationcode": "AZJ",
    "stationname": "Dalu Airport",
    "lat": "321409N",
    "long": "1194314E"
  },
  {
    "stationcode": "SIA",
    "stationname": "Xiguan Airport",
    "lat": "342234N",
    "long": "1090726E"
  },
  {
    "stationcode": "YIE",
    "stationname": "Yiershi Airport",
    "lat": "471836N",
    "long": "1195939E"
  },
  {
    "stationcode": "JUZ",
    "stationname": "Juzhou Airport",
    "lat": "285802N",
    "long": "1185400E"
  },
  {
    "stationcode": "KCA",
    "stationname": "Kuqa Airport",
    "lat": "414305N",
    "long": "0825912E"
  },
  {
    "stationcode": "SYM",
    "stationname": "Simao Airport",
    "lat": "224741N",
    "long": "1005733E"
  },
  {
    "stationcode": "LNJ",
    "stationname": "Lincang Airport",
    "lat": "234420N",
    "long": "1000130E"
  },
  {
    "stationcode": "PNJ",
    "stationname": "Sha He Kou Airport",
    "lat": "374832N",
    "long": "1204846E"
  },
  {
    "stationcode": "DCY",
    "stationname": "Yading Airport",
    "lat": "291900N",
    "long": "1000336E"
  },
  {
    "stationcode": "WUA",
    "stationname": "Wu Hai Airport",
    "lat": "394733N",
    "long": "1064758E"
  },
  {
    "stationcode": "XIC",
    "stationname": "Xichang Airport",
    "lat": "275800N",
    "long": "1021300E"
  },
  {
    "stationcode": "HYN",
    "stationname": "Huangyan Airport",
    "lat": "283355N",
    "long": "1212549E"
  },
  {
    "stationcode": "HSF",
    "stationname": "SUIFENHE DONGNING",
    "lat": "442706N",
    "long": "1305056E"
  },
  {
    "stationcode": "TVS",
    "stationname": "Tangshan Airport",
    "lat": "394310N",
    "long": "1175936E"
  },
  {
    "stationcode": "EHU",
    "stationname": "Ezhou Huahu Airport",
    "lat": "302029N",
    "long": "1150146E"
  },
  {
    "stationcode": "FYN",
    "stationname": "Fuyun Airport",
    "lat": "464804N",
    "long": "0893218E"
  },
  {
    "stationcode": "RHT",
    "stationname": "Alxa Right Banner Airport",
    "lat": "391247N",
    "long": "1013840E"
  },
  {
    "stationcode": "TLQ",
    "stationname": "Turpan Airport",
    "lat": "430149N",
    "long": "0890550E"
  },
  {
    "stationcode": "YBP",
    "stationname": "Yibin Airport",
    "lat": "284801N",
    "long": "1043242E"
  },
  {
    "stationcode": "DBC",
    "stationname": "Chang'an Airport",
    "lat": "453018N",
    "long": "1230108E"
  },
  {
    "stationcode": "WUT",
    "stationname": "Wutaishan Airport",
    "lat": "383552N",
    "long": "1125810E"
  },
  {
    "stationcode": "HTN",
    "stationname": "Hotan Airport",
    "lat": "370217N",
    "long": "0795154E"
  },
  {
    "stationcode": "DGM",
    "stationname": "Dongguan Airport",
    "lat": "070630N",
    "long": "0795212E"
  },
  {
    "stationcode": "OTQ",
    "stationname": "Otog Front Banner Oljoq",
    "lat": "381030N",
    "long": "1072343E"
  },
  {
    "stationcode": "LFQ",
    "stationname": "Qiaoli Airport",
    "lat": "360223N",
    "long": "1112930E"
  },
  {
    "stationcode": "JHG",
    "stationname": "Gasa Airport",
    "lat": "215823N",
    "long": "1004547E"
  },
  {
    "stationcode": "WZQ",
    "stationname": "Urad Middle Banner Airport",
    "lat": "414137N",
    "long": "1082241E"
  },
  {
    "stationcode": "LUM",
    "stationname": "Mangshi Airport",
    "lat": "242405N",
    "long": "0983156E"
  },
  {
    "stationcode": "AHJ",
    "stationname": "Aba Hongyuan Airport",
    "lat": "323153N",
    "long": "1022107E"
  },
  {
    "stationcode": "FUG",
    "stationname": "Fuyang Airport",
    "lat": "325251N",
    "long": "1154401E"
  },
  {
    "stationcode": "ZMY",
    "stationname": "Harbour Airport",
    "lat": "310000N",
    "long": "1211200E"
  },
  {
    "stationcode": "AEB",
    "stationname": "Baise Airport",
    "lat": "234312N",
    "long": "1065737E"
  },
  {
    "stationcode": "YIC",
    "stationname": "Mingyueshan Airport",
    "lat": "274812N",
    "long": "1141828E"
  },
  {
    "stationcode": "INC",
    "stationname": "Yinchuan Airport",
    "lat": "382936N",
    "long": "1060043E"
  },
  {
    "stationcode": "HXD",
    "stationname": "Delingha Airport",
    "lat": "370730N",
    "long": "0971607E"
  },
  {
    "stationcode": "HMI",
    "stationname": "Hami Airport",
    "lat": "425027N",
    "long": "0934015E"
  },
  {
    "stationcode": "ZAT",
    "stationname": "Zhaotong Airport",
    "lat": "271933N",
    "long": "1034517E"
  },
  {
    "stationcode": "ERL",
    "stationname": "Saiwusu Airport",
    "lat": "432524N",
    "long": "1120528E"
  },
  {
    "stationcode": "NAY",
    "stationname": "Nanyuan Airport",
    "lat": "394711N",
    "long": "1162311E"
  },
  {
    "stationcode": "BHY",
    "stationname": "Beihai Airport",
    "lat": "213222N",
    "long": "1091738E"
  },
  {
    "stationcode": "JIL",
    "stationname": "Jilin Airport",
    "lat": "440010N",
    "long": "1262342E"
  },
  {
    "stationcode": "JXA",
    "stationname": "Xingkaihu Airport",
    "lat": "451733N",
    "long": "1311131E"
  },
  {
    "stationcode": "TEN",
    "stationname": "Tongren Airport",
    "lat": "275300N",
    "long": "1091830E"
  },
  {
    "stationcode": "CNI",
    "stationname": "Changhai Airport",
    "lat": "391600N",
    "long": "1223100E"
  },
  {
    "stationcode": "ZYI",
    "stationname": "Zunyi Airport",
    "lat": "274840N",
    "long": "1071446E"
  },
  {
    "stationcode": "XIL",
    "stationname": "Xilinhot Airport",
    "lat": "435456N",
    "long": "1155748E"
  },
  {
    "stationcode": "XNT",
    "stationname": "Xingtai Airport",
    "lat": "365300N",
    "long": "1142546E"
  },
  {
    "stationcode": "DDG",
    "stationname": "Dandong Airport",
    "lat": "400132N",
    "long": "1241713E"
  },
  {
    "stationcode": "JNG",
    "stationname": "Jining Airport",
    "lat": "351735N",
    "long": "1162048E"
  },
  {
    "stationcode": "GOQ",
    "stationname": "Golmud Airport",
    "lat": "362402N",
    "long": "0944718E"
  },
  {
    "stationcode": "HSJ",
    "stationname": "Shangjie Airport",
    "lat": "345032N",
    "long": "1131627E"
  },
  {
    "stationcode": "ZHY",
    "stationname": "Zhongwei Airport",
    "lat": "373422N",
    "long": "1050905E"
  },
  {
    "stationcode": "YIN",
    "stationname": "Yining Airport",
    "lat": "435719N",
    "long": "0811944E"
  },
  {
    "stationcode": "LLF",
    "stationname": "Ling Ling Airport",
    "lat": "262030N",
    "long": "1113632E"
  },
  {
    "stationcode": "WDS",
    "stationname": "Wudangshan Airport",
    "lat": "323536N",
    "long": "1105422E"
  },
  {
    "stationcode": "BSD",
    "stationname": "Baoshan Airport",
    "lat": "250313N",
    "long": "0991005E"
  },
  {
    "stationcode": "DZU",
    "stationname": "Dazu Airport",
    "lat": "294200N",
    "long": "1054800E"
  },
  {
    "stationcode": "CIF",
    "stationname": "Chifeng Airport",
    "lat": "420936N",
    "long": "1185027E"
  },
  {
    "stationcode": "BAV",
    "stationname": "Baotou Airport",
    "lat": "403338N",
    "long": "1095947E"
  },
  {
    "stationcode": "EJN",
    "stationname": "Taolai Airport",
    "lat": "420053N",
    "long": "1010008E"
  },
  {
    "stationcode": "YNZ",
    "stationname": "Yancheng Airport",
    "lat": "332534N",
    "long": "1201211E"
  },
  {
    "stationcode": "CIH",
    "stationname": "Changzhi Airport",
    "lat": "361451N",
    "long": "1130733E"
  },
  {
    "stationcode": "NDG",
    "stationname": "Qiqihar Airport",
    "lat": "471423N",
    "long": "1235505E"
  },
  {
    "stationcode": "LXI",
    "stationname": "Linxi Airport",
    "lat": "433600N",
    "long": "1180200E"
  },
  {
    "stationcode": "WXN",
    "stationname": "Wanxian Airport",
    "lat": "304814N",
    "long": "1082538E"
  },
  {
    "stationcode": "WNH",
    "stationname": "Wenshan Puzhehei",
    "lat": "233329N",
    "long": "1041932E"
  },
  {
    "stationcode": "YNJ",
    "stationname": "Yanji Airport",
    "lat": "423900N",
    "long": "1293000E"
  },
  {
    "stationcode": "WHA",
    "stationname": "Xuanzhou Airport",
    "lat": "310616N",
    "long": "1184001E"
  },
  {
    "stationcode": "YUA",
    "stationname": "Yuanmou Airport",
    "lat": "253900N",
    "long": "1015500E"
  },
  {
    "stationcode": "KNC",
    "stationname": "Ji'An Airport",
    "lat": "270600N",
    "long": "1145900E"
  },
  {
    "stationcode": "HUZ",
    "stationname": "Huizhou Airport",
    "lat": "230256N",
    "long": "1143601E"
  },
  {
    "stationcode": "TCG",
    "stationname": "Tacheng Airport",
    "lat": "464022N",
    "long": "0832028E"
  },
  {
    "stationcode": "NBS",
    "stationname": "Changbaishan Airport",
    "lat": "420354N",
    "long": "1273609E"
  },
  {
    "stationcode": "SHP",
    "stationname": "Qinhuangdao Airport",
    "lat": "395600N",
    "long": "1193000E"
  },
  {
    "stationcode": "DAX",
    "stationname": "Daxian Airport",
    "lat": "310755N",
    "long": "1072550E"
  },
  {
    "stationcode": "LPF",
    "stationname": "Yue Zhao Airport",
    "lat": "263631N",
    "long": "1045842E"
  },
  {
    "stationcode": "JNZ",
    "stationname": "Liaoning Province Airport",
    "lat": "410609N",
    "long": "1210343E"
  },
  {
    "stationcode": "SHF",
    "stationname": "Shanhaiguan Airport",
    "lat": "441610N",
    "long": "0860345E"
  },
  {
    "stationcode": "CHW",
    "stationname": "Jiuquan Airport",
    "lat": "395100N",
    "long": "0982500E"
  },
  {
    "stationcode": "LXA",
    "stationname": "Lhasa Airport",
    "lat": "291753N",
    "long": "0905444E"
  },
  {
    "stationcode": "HZG",
    "stationname": "Hanzhong Airport",
    "lat": "330802N",
    "long": "1071217E"
  },
  {
    "stationcode": "NZH",
    "stationname": "Manzhouli Airport",
    "lat": "493400N",
    "long": "1171950E"
  },
  {
    "stationcode": "TYC",
    "stationname": "Taiyuan Yaocheng",
    "lat": "373015N",
    "long": "1122527E"
  },
  {
    "stationcode": "ZGC",
    "stationname": "Zhongchuan Airport",
    "lat": "360300N",
    "long": "1035100E"
  },
  {
    "stationcode": "DNH",
    "stationname": "Dunhuang Airport",
    "lat": "400532N",
    "long": "0944054E"
  },
  {
    "stationcode": "YIW",
    "stationname": "Yiwu Airport",
    "lat": "292042N",
    "long": "1200157E"
  },
  {
    "stationcode": "LCX",
    "stationname": "Liancheng Airport",
    "lat": "254032N",
    "long": "1164444E"
  },
  {
    "stationcode": "WEF",
    "stationname": "Weifang Airport",
    "lat": "363849N",
    "long": "1190707E"
  },
  {
    "stationcode": "NZL",
    "stationname": "Chengjisihan Airport",
    "lat": "475158N",
    "long": "1224604E"
  },
  {
    "stationcode": "KGT",
    "stationname": "Kangding Airport",
    "lat": "300841N",
    "long": "1014416E"
  },
  {
    "stationcode": "UCB",
    "stationname": "Ulanqab Airport",
    "lat": "410748N",
    "long": "1130634E"
  },
  {
    "stationcode": "RUG",
    "stationname": "Rugao Airport",
    "lat": "321529N",
    "long": "1203006E"
  },
  {
    "stationcode": "LZG",
    "stationname": "Langzhong Gucheng Airport",
    "lat": "313009N",
    "long": "1060204E"
  },
  {
    "stationcode": "KRL",
    "stationname": "Korla Airport",
    "lat": "414157N",
    "long": "0860749E"
  },
  {
    "stationcode": "SZO",
    "stationname": "Shanzhou Airport",
    "lat": "220400N",
    "long": "1132300E"
  },
  {
    "stationcode": "GHN",
    "stationname": "Guanghan Airport",
    "lat": "305655N",
    "long": "1041947E"
  },
  {
    "stationcode": "KRY",
    "stationname": "Karamay Airport",
    "lat": "452758N",
    "long": "0845714E"
  },
  {
    "stationcode": "ACF",
    "stationname": "Alaer Talimu Airport",
    "lat": "402658N",
    "long": "0811603E"
  },
  {
    "stationcode": "CGD",
    "stationname": "Changde Airport",
    "lat": "285507N",
    "long": "1113827E"
  },
  {
    "stationcode": "HCJ",
    "stationname": "Jin Cheng Jiang Airport",
    "lat": "244703N",
    "long": "1074201E"
  },
  {
    "stationcode": "HCZ",
    "stationname": "Beihu",
    "lat": "254512N",
    "long": "1125040E"
  },
  {
    "stationcode": "LZO",
    "stationname": "Luzhou Airport",
    "lat": "285300N",
    "long": "1052100E"
  },
  {
    "stationcode": "JIC",
    "stationname": "Jinchuan Airport",
    "lat": "383231N",
    "long": "1022055E"
  },
  {
    "stationcode": "HZH",
    "stationname": "Liping Airport",
    "lat": "261923N",
    "long": "1090906E"
  },
  {
    "stationcode": "HZU",
    "stationname": "Chengdu Huaizhou Airport",
    "lat": "304041N",
    "long": "1043147E"
  },
  {
    "stationcode": "GMQ",
    "stationname": "Maqin Airport",
    "lat": "342427N",
    "long": "1001920E"
  },
  {
    "stationcode": "ENY",
    "stationname": "Yan'an Airport",
    "lat": "363812N",
    "long": "1093312E"
  },
  {
    "stationcode": "HLH",
    "stationname": "Ulanhot Airport",
    "lat": "461141N",
    "long": "1220030E"
  },
  {
    "stationcode": "DTU",
    "stationname": "Dedu Airport",
    "lat": "483022N",
    "long": "1261152E"
  },
  {
    "stationcode": "FYJ",
    "stationname": "Fuyuan Airport",
    "lat": "481152N",
    "long": "1342152E"
  },
  {
    "stationcode": "TNH",
    "stationname": "Tonghua Liuhe Airport",
    "lat": "421511N",
    "long": "1254216E"
  },
  {
    "stationcode": "LLB",
    "stationname": "Libo Airport",
    "lat": "252654N",
    "long": "1075739E"
  },
  {
    "stationcode": "SZV",
    "stationname": "Suzhou Airport",
    "lat": "311548N",
    "long": "1202404E"
  },
  {
    "stationcode": "JDE",
    "stationname": "Jiande Qiandaohu Airport",
    "lat": "292133N",
    "long": "1191046E"
  },
  {
    "stationcode": "JMU",
    "stationname": "Jiamusi Airport",
    "lat": "465036N",
    "long": "1302755E"
  },
  {
    "stationcode": "LUZ",
    "stationname": "Lushan Airport",
    "lat": "511353N",
    "long": "0224126E"
  },
  {
    "stationcode": "HSC",
    "stationname": "Shaoguan Airport",
    "lat": "245000N",
    "long": "1133700E"
  },
  {
    "stationcode": "WHU",
    "stationname": "Wuhu Airport",
    "lat": "312200N",
    "long": "1182200E"
  },
  {
    "stationcode": "FUD",
    "stationname": "Sui Fen He Airport",
    "lat": "442249N",
    "long": "1310730E"
  },
  {
    "stationcode": "XUZ",
    "stationname": "Xuzhou Airport",
    "lat": "341346N",
    "long": "1171445E"
  },
  {
    "stationcode": "KOW",
    "stationname": "Ganzhou Airport",
    "lat": "255105N",
    "long": "1144631E"
  },
  {
    "stationcode": "YNT",
    "stationname": "Laishan Airport",
    "lat": "373926N",
    "long": "1205914E"
  },
  {
    "stationcode": "XEN",
    "stationname": "Xingcheng Airport",
    "lat": "404400N",
    "long": "1183700E"
  },
  {
    "stationcode": "DFA",
    "stationname": "Danfeng Shangzhen Gen.",
    "lat": "334228N",
    "long": "1101505E"
  },
  {
    "stationcode": "DLU",
    "stationname": "Dali Airport",
    "lat": "253901N",
    "long": "1001910E"
  },
  {
    "stationcode": "HEK",
    "stationname": "Heihe Airport",
    "lat": "501018N",
    "long": "1271834E"
  },
  {
    "stationcode": "AYN",
    "stationname": "Anyang Airport",
    "lat": "360600N",
    "long": "1142100E"
  },
  {
    "stationcode": "YKH",
    "stationname": "Yingkou Airport",
    "lat": "403231N",
    "long": "1222127E"
  },
  {
    "stationcode": "DOY",
    "stationname": "Dongying Airport",
    "lat": "373028N",
    "long": "1184722E"
  },
  {
    "stationcode": "AXF",
    "stationname": "Alxa Left Banner Airport",
    "lat": "384926N",
    "long": "1053734E"
  },
  {
    "stationcode": "KJH",
    "stationname": "Kai Li Airport",
    "lat": "265819N",
    "long": "1075915E"
  },
  {
    "stationcode": "YZY",
    "stationname": "Zhangye Airport",
    "lat": "384827N",
    "long": "1003939E"
  },
  {
    "stationcode": "HLD",
    "stationname": "Hailar Airport",
    "lat": "491231N",
    "long": "1194900E"
  },
  {
    "stationcode": "JZH",
    "stationname": "Jiu Zhai Huang Long Airport",
    "lat": "325124N",
    "long": "1034059E"
  },
  {
    "stationcode": "BAR",
    "stationname": "Bo'ao Airport",
    "lat": "190821N",
    "long": "1102713E"
  },
  {
    "stationcode": "NTG",
    "stationname": "Nantong Airport",
    "lat": "320420N",
    "long": "1205849E"
  },
  {
    "stationcode": "HUO",
    "stationname": "Huolinhe Airport",
    "lat": "453503N",
    "long": "1194418E"
  },
  {
    "stationcode": "DAT",
    "stationname": "Datong Airport",
    "lat": "394308N",
    "long": "1130846E"
  },
  {
    "stationcode": "ENH",
    "stationname": "Enshi Airport",
    "lat": "301916N",
    "long": "1092907E"
  },
  {
    "stationcode": "JIU",
    "stationname": "Jiujiang Airport",
    "lat": "292843N",
    "long": "1154808E"
  },
  {
    "stationcode": "BPL",
    "stationname": "Bole Airport",
    "lat": "445345N",
    "long": "0821800E"
  },
  {
    "stationcode": "MIG",
    "stationname": "Mian Yang Airport",
    "lat": "312544N",
    "long": "1044427E"
  },
  {
    "stationcode": "YLN",
    "stationname": "Yilan Airport",
    "lat": "461900N",
    "long": "1293400E"
  },
  {
    "stationcode": "WUS",
    "stationname": "Fujian Province Airport",
    "lat": "274225N",
    "long": "1180011E"
  },
  {
    "stationcode": "LDS",
    "stationname": "Lindu Airport",
    "lat": "474505N",
    "long": "1290104E"
  },
  {
    "stationcode": "ZUH",
    "stationname": "Zhuhai Airport",
    "lat": "220024N",
    "long": "1132234E"
  },
  {
    "stationcode": "DZH",
    "stationname": "Dazhou Jinya",
    "lat": "310256N",
    "long": "1072610E"
  },
  {
    "stationcode": "LYG",
    "stationname": "Lianyungang Airport",
    "lat": "342500N",
    "long": "1191051E"
  },
  {
    "stationcode": "DQA",
    "stationname": "Saertu Airport",
    "lat": "464500N",
    "long": "1250821E"
  },
  {
    "stationcode": "JGD",
    "stationname": "Jia Ge Da Qi Airport",
    "lat": "502241N",
    "long": "1240656E"
  },
  {
    "stationcode": "JDZ",
    "stationname": "Jingdezhen Airport",
    "lat": "292021N",
    "long": "1171035E"
  },
  {
    "stationcode": "ZQZ",
    "stationname": "Zhangjiakou Airport",
    "lat": "404421N",
    "long": "1145551E"
  },
  {
    "stationcode": "LZY",
    "stationname": "Lin Zhi Airport",
    "lat": "291821N",
    "long": "0942019E"
  },
  {
    "stationcode": "ACX",
    "stationname": "Xingyi Airport",
    "lat": "250508N",
    "long": "1045734E"
  },
  {
    "stationcode": "YUS",
    "stationname": "Yushu Batang Airport",
    "lat": "324936N",
    "long": "0970719E"
  },
  {
    "stationcode": "KJI",
    "stationname": "Kanasi Airport",
    "lat": "481318N",
    "long": "0865946E"
  },
  {
    "stationcode": "XFN",
    "stationname": "Xiangfan Airport",
    "lat": "320858N",
    "long": "1121734E"
  },
  {
    "stationcode": "DIG",
    "stationname": "Diqing Airport",
    "lat": "274730N",
    "long": "0994041E"
  },
  {
    "stationcode": "AOG",
    "stationname": "Anshan Airport",
    "lat": "410620N",
    "long": "1225122E"
  },
  {
    "stationcode": "JGS",
    "stationname": "Ji An/Jing Gang Shan Airport",
    "lat": "265359N",
    "long": "1144415E"
  },
  {
    "stationcode": "MDG",
    "stationname": "Mudanjiang Airport",
    "lat": "443127N",
    "long": "1293408E"
  },
  {
    "stationcode": "GYS",
    "stationname": "Guang Yuan Airport",
    "lat": "322324N",
    "long": "1054135E"
  },
  {
    "stationcode": "AQG",
    "stationname": "Anqing Airport",
    "lat": "303457N",
    "long": "1170301E"
  },
  {
    "stationcode": "NAO",
    "stationname": "Nanchong Airport",
    "lat": "304738N",
    "long": "1060935E"
  },
  {
    "stationcode": "YCU",
    "stationname": "Yun Cheng Airport",
    "lat": "350659N",
    "long": "1110201E"
  },
  {
    "stationcode": "CHG",
    "stationname": "Chaoyang Airport",
    "lat": "413229N",
    "long": "1202602E"
  },
  {
    "stationcode": "TGO",
    "stationname": "Tongliao Airport",
    "lat": "433327N",
    "long": "1221200E"
  },
  {
    "stationcode": "LZD",
    "stationname": "Lanzhoudong Airport",
    "lat": "360203N",
    "long": "1035104E"
  },
  {
    "stationcode": "HNY",
    "stationname": "Hengyang Airport",
    "lat": "265420N",
    "long": "1123742E"
  },
  {
    "stationcode": "XIN",
    "stationname": "Xingning Airport",
    "lat": "240900N",
    "long": "1154500E"
  },
  {
    "stationcode": "NNY",
    "stationname": "Nanyang Airport",
    "lat": "325852N",
    "long": "1123656E"
  },
  {
    "stationcode": "AAT",
    "stationname": "Altay Airport",
    "lat": "474502N",
    "long": "0880509E"
  },
  {
    "stationcode": "SHS",
    "stationname": "Shashi Airport",
    "lat": "301807N",
    "long": "1122656E"
  },
  {
    "stationcode": "SXJ",
    "stationname": "Shanshan Airport",
    "lat": "424900N",
    "long": "0901400E"
  },
  {
    "stationcode": "LIA",
    "stationname": "Liangping Airport",
    "lat": "304900N",
    "long": "1074200E"
  },
  {
    "stationcode": "OHE",
    "stationname": "Gu-Lian Airport",
    "lat": "525459N",
    "long": "1222520E"
  },
  {
    "stationcode": "HSN",
    "stationname": "Zhoushan Airport",
    "lat": "295605N",
    "long": "1222143E"
  },
  {
    "stationcode": "IQM",
    "stationname": "Qiemo Airport",
    "lat": "380858N",
    "long": "0853159E"
  },
  {
    "stationcode": "IQN",
    "stationname": "Qingyang Airport",
    "lat": "354809N",
    "long": "1073559E"
  },
  {
    "stationcode": "BFU",
    "stationname": "Bengbu Airport",
    "lat": "325700N",
    "long": "1172000E"
  },
  {
    "stationcode": "BPX",
    "stationname": "Bangda Airport",
    "lat": "303304N",
    "long": "0970638E"
  },
  {
    "stationcode": "HJJ",
    "stationname": "Zhi Jiang Airport",
    "lat": "272629N",
    "long": "1094201E"
  },
  {
    "stationcode": "GXH",
    "stationname": "Gannan Xiahe Airport",
    "lat": "344908N",
    "long": "1023715E"
  },
  {
    "stationcode": "KHG",
    "stationname": "Kashi Airport",
    "lat": "393233N",
    "long": "0760112E"
  },
  {
    "stationcode": "PZI",
    "stationname": "Pan Zhi Hua Bao An Ying",
    "lat": "263224N",
    "long": "1014753E"
  },
  {
    "stationcode": "NGQ",
    "stationname": "Ali Airport",
    "lat": "320559N",
    "long": "0800312E"
  },
  {
    "stationcode": "LZH",
    "stationname": "Liuzhou Airport",
    "lat": "241228N",
    "long": "1092329E"
  },
  {
    "stationcode": "YTY",
    "stationname": "Taizhou Airport",
    "lat": "323347N",
    "long": "1194308E"
  },
  {
    "stationcode": "HPG",
    "stationname": "Hongping Airport",
    "lat": "313800N",
    "long": "1102016E"
  },
  {
    "stationcode": "GYU",
    "stationname": "Liu Pan Shan Airport",
    "lat": "360444N",
    "long": "1061259E"
  },
  {
    "stationcode": "RLK",
    "stationname": "Tianjitai Airport",
    "lat": "405535N",
    "long": "1074420E"
  },
  {
    "stationcode": "HHA",
    "stationname": "Changsha Huanghua Airport",
    "lat": "281400N",
    "long": "1130900E"
  },
  {
    "stationcode": "THQ",
    "stationname": "Tianshui Airport",
    "lat": "343335N",
    "long": "1055136E"
  },
  {
    "stationcode": "ZHA",
    "stationname": "Zhanjiang Airport",
    "lat": "212910N",
    "long": "1103536E"
  },
  {
    "stationcode": "LHK",
    "stationname": "Guanghua Airport",
    "lat": "321600N",
    "long": "1113800E"
  },
  {
    "stationcode": "SQB",
    "stationname": "Santa Ana Airport",
    "lat": "044600N",
    "long": "0755700W"
  },
  {
    "stationcode": "AYG",
    "stationname": "Yaguara Airport",
    "lat": "024000N",
    "long": "0753100W"
  },
  {
    "stationcode": "CPB",
    "stationname": "Capurgana Airport",
    "lat": "083800N",
    "long": "0772000W"
  },
  {
    "stationcode": "NBB",
    "stationname": "Barrancominas Airport",
    "lat": "032000N",
    "long": "0702000W"
  },
  {
    "stationcode": "TIB",
    "stationname": "Tibu Airport",
    "lat": "083800N",
    "long": "0724400W"
  },
  {
    "stationcode": "CIM",
    "stationname": "Cimitarra Airport",
    "lat": "062900N",
    "long": "0740200W"
  },
  {
    "stationcode": "PLA",
    "stationname": "Planadas Airport",
    "lat": "031800N",
    "long": "0754200W"
  },
  {
    "stationcode": "SJR",
    "stationname": "San Juan De Uraba Airport",
    "lat": "084600N",
    "long": "0763200W"
  },
  {
    "stationcode": "LMC",
    "stationname": "Lamacarena Airport",
    "lat": "021031N",
    "long": "0734712W"
  },
  {
    "stationcode": "SQF",
    "stationname": "Solano Airport",
    "lat": "004300N",
    "long": "0751600W"
  },
  {
    "stationcode": "NAD",
    "stationname": "Macanal Airport",
    "lat": "023400N",
    "long": "0673500W"
  },
  {
    "stationcode": "URR",
    "stationname": "Urrao Airport",
    "lat": "062000N",
    "long": "0761100W"
  },
  {
    "stationcode": "LMX",
    "stationname": "Lopez De Micay Airport",
    "lat": "030300N",
    "long": "0773300W"
  },
  {
    "stationcode": "BOG",
    "stationname": "Eldorado Airport",
    "lat": "044206N",
    "long": "0740849W"
  },
  {
    "stationcode": "CRC",
    "stationname": "Cartago Airport",
    "lat": "044530N",
    "long": "0755723W"
  },
  {
    "stationcode": "TTM",
    "stationname": "Tablon De Tamara Airport",
    "lat": "054300N",
    "long": "0720600W"
  },
  {
    "stationcode": "EJA",
    "stationname": "Variguies Airport",
    "lat": "070128N",
    "long": "0734824W"
  },
  {
    "stationcode": "IBE",
    "stationname": "Ibague Airport",
    "lat": "042516N",
    "long": "0750801W"
  },
  {
    "stationcode": "GGL",
    "stationname": "Gilgal Airport",
    "lat": "082000N",
    "long": "0770500W"
  },
  {
    "stationcode": "AYA",
    "stationname": "Ayapel Airport",
    "lat": "081800N",
    "long": "0750900W"
  },
  {
    "stationcode": "HTZ",
    "stationname": "Hato Corozal Airport",
    "lat": "061000N",
    "long": "0714500W"
  },
  {
    "stationcode": "ELB",
    "stationname": "San Bernado Airport",
    "lat": "090300N",
    "long": "0735800W"
  },
  {
    "stationcode": "OTU",
    "stationname": "Otu Airport",
    "lat": "065900N",
    "long": "0744300W"
  },
  {
    "stationcode": "BAQ",
    "stationname": "E Cortissoz Airport",
    "lat": "105323N",
    "long": "0744651W"
  },
  {
    "stationcode": "SMR",
    "stationname": "Simon Bolivar Airport",
    "lat": "110711N",
    "long": "0741350W"
  },
  {
    "stationcode": "ULQ",
    "stationname": "Farfan Airport",
    "lat": "040600N",
    "long": "0761400W"
  },
  {
    "stationcode": "CQT",
    "stationname": "Caquetania Airport",
    "lat": "020200N",
    "long": "0741300W"
  },
  {
    "stationcode": "BSC",
    "stationname": "Bahia Solano Airport",
    "lat": "061211N",
    "long": "0772343W"
  },
  {
    "stationcode": "LET",
    "stationname": "Gen. A.V. Cobo Airport",
    "lat": "041137S",
    "long": "0695635W"
  },
  {
    "stationcode": "SRS",
    "stationname": "San Marcos Airport",
    "lat": "084100N",
    "long": "0751000W"
  },
  {
    "stationcode": "ARF",
    "stationname": "Acaricuara Airport",
    "lat": "003200N",
    "long": "0700800W"
  },
  {
    "stationcode": "ARO",
    "stationname": "Arboletas Airport",
    "lat": "085200N",
    "long": "0762600W"
  },
  {
    "stationcode": "MFB",
    "stationname": "Monfort Airport",
    "lat": "003800N",
    "long": "0694500W"
  },
  {
    "stationcode": "TLU",
    "stationname": "Tolu Airport",
    "lat": "093043N",
    "long": "0753512W"
  },
  {
    "stationcode": "NAR",
    "stationname": "Nare Airport",
    "lat": "061200N",
    "long": "0743700W"
  },
  {
    "stationcode": "PLT",
    "stationname": "Plato Airport",
    "lat": "094800N",
    "long": "0744700W"
  },
  {
    "stationcode": "PRE",
    "stationname": "Pore Airport",
    "lat": "054400N",
    "long": "0715900W"
  },
  {
    "stationcode": "MOY",
    "stationname": "Monterrey Airport",
    "lat": "045500N",
    "long": "0725100W"
  },
  {
    "stationcode": "SNT",
    "stationname": "Sabana De Torres Airport",
    "lat": "074000N",
    "long": "0730030W"
  },
  {
    "stationcode": "ULS",
    "stationname": "Mulatos Airport",
    "lat": "083900N",
    "long": "0764500W"
  },
  {
    "stationcode": "FLA",
    "stationname": "Capitolio Airport",
    "lat": "013521N",
    "long": "0753352W"
  },
  {
    "stationcode": "GAA",
    "stationname": "Guamal Airport",
    "lat": "090900N",
    "long": "0741400W"
  },
  {
    "stationcode": "PCC",
    "stationname": "Puerto Rico Airport",
    "lat": "015500N",
    "long": "0751000W"
  },
  {
    "stationcode": "AZT",
    "stationname": "Zapatoca Airport",
    "lat": "064900N",
    "long": "0731700W"
  },
  {
    "stationcode": "PSO",
    "stationname": "Cano Airport",
    "lat": "012346N",
    "long": "0771728W"
  },
  {
    "stationcode": "PEI",
    "stationname": "Matecana Airport",
    "lat": "044846N",
    "long": "0754422W"
  },
  {
    "stationcode": "MDE",
    "stationname": "Jose Maria Cordova Airport",
    "lat": "060952N",
    "long": "0752523W"
  },
  {
    "stationcode": "TAU",
    "stationname": "Tauramena Airport",
    "lat": "050000N",
    "long": "0724400W"
  },
  {
    "stationcode": "LAT",
    "stationname": "La Uribe Airport",
    "lat": "044500N",
    "long": "0740300W"
  },
  {
    "stationcode": "TDA",
    "stationname": "Trinidad Airport",
    "lat": "051600N",
    "long": "0713800W"
  },
  {
    "stationcode": "ISD",
    "stationname": "Iscuande Airport",
    "lat": "022630N",
    "long": "0775800W"
  },
  {
    "stationcode": "SOH",
    "stationname": "Solita Airport",
    "lat": "005235N",
    "long": "0753710W"
  },
  {
    "stationcode": "ORC",
    "stationname": "Orocue Airport",
    "lat": "045400N",
    "long": "0712000W"
  },
  {
    "stationcode": "NCI",
    "stationname": "Necocli Airport",
    "lat": "082700N",
    "long": "0764700W"
  },
  {
    "stationcode": "SMC",
    "stationname": "Santa Maria Airport",
    "lat": "081000N",
    "long": "0770300W"
  },
  {
    "stationcode": "TME",
    "stationname": "Tame Airport",
    "lat": "062704N",
    "long": "0714537W"
  },
  {
    "stationcode": "TQS",
    "stationname": "Tres Esquinas Airport",
    "lat": "004400N",
    "long": "0751400W"
  },
  {
    "stationcode": "PBE",
    "stationname": "Puerto Berrio Airport",
    "lat": "062900N",
    "long": "0742900W"
  },
  {
    "stationcode": "ADZ",
    "stationname": "San Andres Island Airport",
    "lat": "123501N",
    "long": "0814240W"
  },
  {
    "stationcode": "SQE",
    "stationname": "San Luis De Pale Airport",
    "lat": "052400N",
    "long": "0714200W"
  },
  {
    "stationcode": "PCR",
    "stationname": "Puerto Carreno Airport",
    "lat": "061105N",
    "long": "0672935W"
  },
  {
    "stationcode": "AYC",
    "stationname": "Ayacucho Airport",
    "lat": "083600N",
    "long": "0733700W"
  },
  {
    "stationcode": "ELJ",
    "stationname": "El Recreo Airport",
    "lat": "020000N",
    "long": "0740800W"
  },
  {
    "stationcode": "VGZ",
    "stationname": "Villagarzon Airport",
    "lat": "005856N",
    "long": "0763615W"
  },
  {
    "stationcode": "SJG",
    "stationname": "San Pedro Jagua Airport",
    "lat": "043900N",
    "long": "0732000W"
  },
  {
    "stationcode": "SSL",
    "stationname": "Santa Rosalia Airport",
    "lat": "012800N",
    "long": "0721400W"
  },
  {
    "stationcode": "PDA",
    "stationname": "Puerto Inirida Airport",
    "lat": "035113N",
    "long": "0675422W"
  },
  {
    "stationcode": "JUO",
    "stationname": "Jurado Airport",
    "lat": "063100N",
    "long": "0763600W"
  },
  {
    "stationcode": "MHF",
    "stationname": "Morichal Airport",
    "lat": "014500N",
    "long": "0695500W"
  },
  {
    "stationcode": "AYI",
    "stationname": "Yari Airport",
    "lat": "002300S",
    "long": "0721600W"
  },
  {
    "stationcode": "RAV",
    "stationname": "Cravo Norte Airport",
    "lat": "061900N",
    "long": "0701100W"
  },
  {
    "stationcode": "TRB",
    "stationname": "Gonzalo Airport",
    "lat": "080600N",
    "long": "0764400W"
  },
  {
    "stationcode": "AUC",
    "stationname": "Arauca Airport",
    "lat": "070408N",
    "long": "0704413W"
  },
  {
    "stationcode": "TCO",
    "stationname": "La Florida Airport",
    "lat": "014852N",
    "long": "0784457W"
  },
  {
    "stationcode": "MND",
    "stationname": "Medina Airport",
    "lat": "043100N",
    "long": "0731700W"
  },
  {
    "stationcode": "SRO",
    "stationname": "Santana Ramos Airport",
    "lat": "021300N",
    "long": "0751500W"
  },
  {
    "stationcode": "CSR",
    "stationname": "Casuarito Airport",
    "lat": "055000N",
    "long": "0680800W"
  },
  {
    "stationcode": "CPL",
    "stationname": "Chaparral Airport",
    "lat": "034200N",
    "long": "0754500W"
  },
  {
    "stationcode": "BUN",
    "stationname": "Buenaventura Airport",
    "lat": "034930N",
    "long": "0765945W"
  },
  {
    "stationcode": "MGN",
    "stationname": "Baracoa Airport",
    "lat": "091600N",
    "long": "0745300W"
  },
  {
    "stationcode": "PYN",
    "stationname": "Payan Airport",
    "lat": "014800N",
    "long": "0781000W"
  },
  {
    "stationcode": "LRI",
    "stationname": "Lorica Airport",
    "lat": "090200N",
    "long": "0754200W"
  },
  {
    "stationcode": "MTR",
    "stationname": "S. Jeronimo Airport",
    "lat": "084925N",
    "long": "0754933W"
  },
  {
    "stationcode": "PLC",
    "stationname": "Planeta Rica Airport",
    "lat": "082400N",
    "long": "0753600W"
  },
  {
    "stationcode": "URI",
    "stationname": "Uribe Airport",
    "lat": "031300N",
    "long": "0742400W"
  },
  {
    "stationcode": "GCA",
    "stationname": "Guacamaya Airport",
    "lat": "021700N",
    "long": "0745700W"
  },
  {
    "stationcode": "ECR",
    "stationname": "El Charco Airport",
    "lat": "023000N",
    "long": "0780030W"
  },
  {
    "stationcode": "CZU",
    "stationname": "Corozal Airport",
    "lat": "091958N",
    "long": "0751708W"
  },
  {
    "stationcode": "NUH",
    "stationname": "Nunchia Airport",
    "lat": "053900N",
    "long": "0721200W"
  },
  {
    "stationcode": "IPI",
    "stationname": "San Luis Airport",
    "lat": "005159N",
    "long": "0774242W"
  },
  {
    "stationcode": "GIR",
    "stationname": "Girardot Airport",
    "lat": "041700N",
    "long": "0744800W"
  },
  {
    "stationcode": "EBG",
    "stationname": "El Bagre Airport",
    "lat": "073700N",
    "long": "0744900W"
  },
  {
    "stationcode": "TCD",
    "stationname": "Tarapaca Airport",
    "lat": "025345S",
    "long": "0694500W"
  },
  {
    "stationcode": "ARQ",
    "stationname": "Arauquita Airport",
    "lat": "070210N",
    "long": "0712625W"
  },
  {
    "stationcode": "LGT",
    "stationname": "Las Gaviotas Airport",
    "lat": "043500N",
    "long": "0705000W"
  },
  {
    "stationcode": "VAB",
    "stationname": "Yavarate Airport",
    "lat": "010700N",
    "long": "0704500W"
  },
  {
    "stationcode": "GRA",
    "stationname": "Gamarra Airport",
    "lat": "081200N",
    "long": "0734700W"
  },
  {
    "stationcode": "SVI",
    "stationname": "San Vicente Airport",
    "lat": "020908N",
    "long": "0744559W"
  },
  {
    "stationcode": "PUU",
    "stationname": "Puerto Asis Airport",
    "lat": "003019N",
    "long": "0763003W"
  },
  {
    "stationcode": "TBD",
    "stationname": "Timbiqui Airport",
    "lat": "024600N",
    "long": "0773900W"
  },
  {
    "stationcode": "CUC",
    "stationname": "Camilo Dazo Airport",
    "lat": "075539N",
    "long": "0723042W"
  },
  {
    "stationcode": "ECO",
    "stationname": "El Encanto Airport",
    "lat": "013700S",
    "long": "0731400W"
  },
  {
    "stationcode": "UNC",
    "stationname": "Unguia Airport",
    "lat": "080200N",
    "long": "0770500W"
  },
  {
    "stationcode": "PZA",
    "stationname": "Casanare Airport",
    "lat": "054700N",
    "long": "0714900W"
  },
  {
    "stationcode": "CCO",
    "stationname": "Carimagua Airport",
    "lat": "043400N",
    "long": "0712000W"
  },
  {
    "stationcode": "BAC",
    "stationname": "Barranca De Upia Airport",
    "lat": "043500N",
    "long": "0725800W"
  },
  {
    "stationcode": "LCR",
    "stationname": "La Chorrera Airport",
    "lat": "011012N",
    "long": "0752202W"
  },
  {
    "stationcode": "UIB",
    "stationname": "Quibdo Airport",
    "lat": "054127N",
    "long": "0763828W"
  },
  {
    "stationcode": "CLO",
    "stationname": "Alfonso B. Aragon Airport",
    "lat": "033236N",
    "long": "0762254W"
  },
  {
    "stationcode": "PTX",
    "stationname": "Pitalito Airport",
    "lat": "015125N",
    "long": "0760513W"
  },
  {
    "stationcode": "BGA",
    "stationname": "Palo Negro Airport",
    "lat": "070735N",
    "long": "0731105W"
  },
  {
    "stationcode": "EYP",
    "stationname": "El Yopal Airport",
    "lat": "051909N",
    "long": "0722302W"
  },
  {
    "stationcode": "OCV",
    "stationname": "Aguasclaras Airport",
    "lat": "081500N",
    "long": "0734700W"
  },
  {
    "stationcode": "IGO",
    "stationname": "Chigorodo Airport",
    "lat": "074106N",
    "long": "0764141W"
  },
  {
    "stationcode": "IVO",
    "stationname": "Chivolo Airport",
    "lat": "100100N",
    "long": "0744100W"
  },
  {
    "stationcode": "MVP",
    "stationname": "Mitu Airport",
    "lat": "011513N",
    "long": "0701402W"
  },
  {
    "stationcode": "MFS",
    "stationname": "Miraflores Airport",
    "lat": "010200N",
    "long": "0720800W"
  },
  {
    "stationcode": "MIX",
    "stationname": "Miriti Airport",
    "lat": "010800S",
    "long": "0701500W"
  },
  {
    "stationcode": "GPI",
    "stationname": "Guapi Airport",
    "lat": "023412N",
    "long": "0775354W"
  },
  {
    "stationcode": "AXM",
    "stationname": "El Eden Airport",
    "lat": "042710N",
    "long": "0754559W"
  },
  {
    "stationcode": "RVE",
    "stationname": "Saravena Airport",
    "lat": "065707N",
    "long": "0715126W"
  },
  {
    "stationcode": "API",
    "stationname": "Apiay Airport",
    "lat": "040434N",
    "long": "0733346W"
  },
  {
    "stationcode": "PPN",
    "stationname": "Guillermo Le=n Valencia Airport",
    "lat": "022716N",
    "long": "0763634W"
  },
  {
    "stationcode": "MTB",
    "stationname": "Montel Libano Airport",
    "lat": "075818N",
    "long": "0752559W"
  },
  {
    "stationcode": "RON",
    "stationname": "Rondon Airport",
    "lat": "061800N",
    "long": "0710500W"
  },
  {
    "stationcode": "LPE",
    "stationname": "La Primavera Airport",
    "lat": "052846N",
    "long": "0702515W"
  },
  {
    "stationcode": "VUP",
    "stationname": "Valledupar Airport",
    "lat": "102606N",
    "long": "0731458W"
  },
  {
    "stationcode": "MMP",
    "stationname": "Mompos Airport",
    "lat": "091532N",
    "long": "0742618W"
  },
  {
    "stationcode": "GMC",
    "stationname": "Guerima Airport",
    "lat": "033800N",
    "long": "0711000W"
  },
  {
    "stationcode": "SSD",
    "stationname": "San Felipe Airport",
    "lat": "015500N",
    "long": "0670600W"
  },
  {
    "stationcode": "COG",
    "stationname": "Mandinga Airport",
    "lat": "050419N",
    "long": "0764036W"
  },
  {
    "stationcode": "NPU",
    "stationname": "San Pedro Uraba Airport",
    "lat": "081800N",
    "long": "0762500W"
  },
  {
    "stationcode": "RCH",
    "stationname": "Riohacha Airport",
    "lat": "113134N",
    "long": "0725533W"
  },
  {
    "stationcode": "MQU",
    "stationname": "Mariquita Airport",
    "lat": "051300N",
    "long": "0745300W"
  },
  {
    "stationcode": "ACD",
    "stationname": "Acandi Airport",
    "lat": "083100N",
    "long": "0771800W"
  },
  {
    "stationcode": "ACL",
    "stationname": "Aguaclara Airport",
    "lat": "044500N",
    "long": "0730000W"
  },
  {
    "stationcode": "ACM",
    "stationname": "Arica Airport",
    "lat": "020800S",
    "long": "0714700W"
  },
  {
    "stationcode": "SOX",
    "stationname": "Sogamoso Airport",
    "lat": "054100N",
    "long": "0725700W"
  },
  {
    "stationcode": "PAL",
    "stationname": "Palanquero Airport",
    "lat": "052900N",
    "long": "0743900W"
  },
  {
    "stationcode": "LPD",
    "stationname": "La Pedrera Airport",
    "lat": "011932S",
    "long": "0693453W"
  },
  {
    "stationcode": "DZI",
    "stationname": "Codazzi Airport",
    "lat": "100548N",
    "long": "0731402W"
  },
  {
    "stationcode": "CVE",
    "stationname": "Covenas Airport",
    "lat": "092400N",
    "long": "0754400W"
  },
  {
    "stationcode": "LGZ",
    "stationname": "Leguizamo Airport",
    "lat": "282536N",
    "long": "0922046E"
  },
  {
    "stationcode": "MZL",
    "stationname": "Santaguida Airport",
    "lat": "050147N",
    "long": "0752753W"
  },
  {
    "stationcode": "HRR",
    "stationname": "Herrera Airport",
    "lat": "031300N",
    "long": "0755100W"
  },
  {
    "stationcode": "PVA",
    "stationname": "Providencia Airport",
    "lat": "132131N",
    "long": "0812130W"
  },
  {
    "stationcode": "CTG",
    "stationname": "Rafael Nunez Airport",
    "lat": "102633N",
    "long": "0753047W"
  },
  {
    "stationcode": "NVA",
    "stationname": "La Marguita Airport",
    "lat": "025701N",
    "long": "0751738W"
  },
  {
    "stationcode": "MCJ",
    "stationname": "Maicao Airport",
    "lat": "112324N",
    "long": "0721423W"
  },
  {
    "stationcode": "ACR",
    "stationname": "Araracuara Airport",
    "lat": "003603S",
    "long": "0722347W"
  },
  {
    "stationcode": "EUO",
    "stationname": "Paratebueno Airport",
    "lat": "042000N",
    "long": "0731500W"
  },
  {
    "stationcode": "PYA",
    "stationname": "Puerto Boyaca Airport",
    "lat": "055621N",
    "long": "0742726W"
  },
  {
    "stationcode": "NQU",
    "stationname": "Nuqui Airport",
    "lat": "054236N",
    "long": "0771546W"
  },
  {
    "stationcode": "VVC",
    "stationname": "La Vanguardia Airport",
    "lat": "040951N",
    "long": "0733700W"
  },
  {
    "stationcode": "ADN",
    "stationname": "Andes Airport",
    "lat": "054000N",
    "long": "0755300W"
  },
  {
    "stationcode": "SJH",
    "stationname": "San Juan Del Cesar Airport",
    "lat": "104600N",
    "long": "0730100W"
  },
  {
    "stationcode": "MQR",
    "stationname": "Mosquera Airport",
    "lat": "024000N",
    "long": "0782030W"
  },
  {
    "stationcode": "CUI",
    "stationname": "Currillo Airport",
    "lat": "044000N",
    "long": "0720000W"
  },
  {
    "stationcode": "CUO",
    "stationname": "Caruru Airport",
    "lat": "010200N",
    "long": "0711400W"
  },
  {
    "stationcode": "APO",
    "stationname": "Apartado Airport",
    "lat": "074845N",
    "long": "0764300W"
  },
  {
    "stationcode": "BHF",
    "stationname": "Bahia Cupica Airport",
    "lat": "063300N",
    "long": "0772000W"
  },
  {
    "stationcode": "CAQ",
    "stationname": "Caucasia Airport",
    "lat": "075807N",
    "long": "0751154W"
  },
  {
    "stationcode": "LQM",
    "stationname": "Puerto Leguizamo Airport",
    "lat": "001056S",
    "long": "0744615W"
  },
  {
    "stationcode": "EOH",
    "stationname": "Enrique Olaya Herrera Airport",
    "lat": "061312N",
    "long": "0753526W"
  },
  {
    "stationcode": "SCA",
    "stationname": "Santa Catalina Airport",
    "lat": "083000N",
    "long": "0761030W"
  },
  {
    "stationcode": "CJD",
    "stationname": "Candilejas Airport",
    "lat": "020400N",
    "long": "0743500W"
  },
  {
    "stationcode": "SJE",
    "stationname": "San Jose Del Gua Airport",
    "lat": "023447N",
    "long": "0723822W"
  },
  {
    "stationcode": "AFI",
    "stationname": "Amalfi Airport",
    "lat": "065500N",
    "long": "0750400W"
  },
  {
    "stationcode": "RFR",
    "stationname": "Rio Frio Airport",
    "lat": "102000N",
    "long": "0835300W"
  },
  {
    "stationcode": "JAP",
    "stationname": "Punta Renes Airport",
    "lat": "095800N",
    "long": "0845000W"
  },
  {
    "stationcode": "OTR",
    "stationname": "Coto 47 Airport",
    "lat": "083607N",
    "long": "0825808W"
  },
  {
    "stationcode": "TTQ",
    "stationname": "Tortuquero Airport",
    "lat": "102105N",
    "long": "0833747W"
  },
  {
    "stationcode": "SJO",
    "stationname": "Juan Santamaria International Airport",
    "lat": "100000N",
    "long": "0841200W"
  },
  {
    "stationcode": "LSL",
    "stationname": "Los Chiles Airport",
    "lat": "110000N",
    "long": "0845000W"
  },
  {
    "stationcode": "PJM",
    "stationname": "Puerto Jimenez Airport",
    "lat": "083210N",
    "long": "0831805W"
  },
  {
    "stationcode": "BAI",
    "stationname": "Buenos Aires Airport",
    "lat": "091000N",
    "long": "0832000W"
  },
  {
    "stationcode": "GPL",
    "stationname": "Guapiles Airport",
    "lat": "101300N",
    "long": "0834600W"
  },
  {
    "stationcode": "CSC",
    "stationname": "Canas Airport",
    "lat": "102500N",
    "long": "0850700W"
  },
  {
    "stationcode": "TMU",
    "stationname": "Tambor Airport",
    "lat": "094423N",
    "long": "0850101W"
  },
  {
    "stationcode": "LIO",
    "stationname": "Limon Airport",
    "lat": "095743N",
    "long": "0830137W"
  },
  {
    "stationcode": "FMG",
    "stationname": "Flamingo Airport",
    "lat": "102500N",
    "long": "0854700W"
  },
  {
    "stationcode": "RIK",
    "stationname": "Carrillo Airport",
    "lat": "095200N",
    "long": "0852900W"
  },
  {
    "stationcode": "TNO",
    "stationname": "Tamarindo Airport",
    "lat": "101857N",
    "long": "0854846W"
  },
  {
    "stationcode": "XQP",
    "stationname": "Quepos Airport",
    "lat": "092635N",
    "long": "0840747W"
  },
  {
    "stationcode": "PBP",
    "stationname": "Punta Islita Airport",
    "lat": "095124N",
    "long": "0852216W"
  },
  {
    "stationcode": "LIR",
    "stationname": "Liberia Airport",
    "lat": "103536N",
    "long": "0853240W"
  },
  {
    "stationcode": "LCS",
    "stationname": "Las Canas Airport",
    "lat": "241557N",
    "long": "0975415E"
  },
  {
    "stationcode": "SZC",
    "stationname": "Guanacaste Airport",
    "lat": "665350N",
    "long": "0525214W"
  },
  {
    "stationcode": "NCT",
    "stationname": "Guanacaste Airport",
    "lat": "101200N",
    "long": "0852800W"
  },
  {
    "stationcode": "BCL",
    "stationname": "Barra Colorado Airport",
    "lat": "104607N",
    "long": "0833508W"
  },
  {
    "stationcode": "UPL",
    "stationname": "Upala Airport",
    "lat": "104700N",
    "long": "0850200W"
  },
  {
    "stationcode": "PLD",
    "stationname": "Playa Samara Airport",
    "lat": "095214N",
    "long": "0852854W"
  },
  {
    "stationcode": "DRK",
    "stationname": "Drake Bay Airport",
    "lat": "084309N",
    "long": "0833831W"
  },
  {
    "stationcode": "NOB",
    "stationname": "Nosara Beach Airport",
    "lat": "095835N",
    "long": "0853911W"
  },
  {
    "stationcode": "TOO",
    "stationname": "San Vito Airport",
    "lat": "085000N",
    "long": "0825800W"
  },
  {
    "stationcode": "SYQ",
    "stationname": "Tobias Bolanos International Airport",
    "lat": "095726N",
    "long": "0840823W"
  },
  {
    "stationcode": "FON",
    "stationname": "Fortuna Airport",
    "lat": "102811N",
    "long": "0843444W"
  },
  {
    "stationcode": "PMZ",
    "stationname": "Palmur'Sur Airport",
    "lat": "085703N",
    "long": "0832807W"
  },
  {
    "stationcode": "GLF",
    "stationname": "Golfito Airport",
    "lat": "083912N",
    "long": "0831053W"
  },
  {
    "stationcode": "BWW",
    "stationname": "Las Brujas Airport",
    "lat": "223717N",
    "long": "0790850W"
  },
  {
    "stationcode": "TND",
    "stationname": "Trinidad Airport",
    "lat": "214800N",
    "long": "0800000W"
  },
  {
    "stationcode": "NBW",
    "stationname": "Mariana Grajales Airport",
    "lat": "195424N",
    "long": "0751219W"
  },
  {
    "stationcode": "MJG",
    "stationname": "Mayajigua Airport",
    "lat": "214530N",
    "long": "0790000W"
  },
  {
    "stationcode": "SZJ",
    "stationname": "Siguanea Airport",
    "lat": "213800N",
    "long": "0825700W"
  },
  {
    "stationcode": "UMA",
    "stationname": "Punta De Maisi Airport",
    "lat": "201500N",
    "long": "0740900W"
  },
  {
    "stationcode": "LCL",
    "stationname": "La Coloma Airport",
    "lat": "222010N",
    "long": "0833835W"
  },
  {
    "stationcode": "ICR",
    "stationname": "Nicaro Airport",
    "lat": "205900N",
    "long": "0755830W"
  },
  {
    "stationcode": "VTU",
    "stationname": "Las Tunas Airport",
    "lat": "205916N",
    "long": "0765609W"
  },
  {
    "stationcode": "UPB",
    "stationname": "Playa Baracoa Airport",
    "lat": "230158N",
    "long": "0823446W"
  },
  {
    "stationcode": "UPA",
    "stationname": "Punta Alegre Airport",
    "lat": "222300N",
    "long": "0784900W"
  },
  {
    "stationcode": "VRO",
    "stationname": "Kawama Airport",
    "lat": "230800N",
    "long": "0811800W"
  },
  {
    "stationcode": "SNU",
    "stationname": "Santa Clara Airport",
    "lat": "222931N",
    "long": "0795631W"
  },
  {
    "stationcode": "SCU",
    "stationname": "Antonio Maceo Airport",
    "lat": "195811N",
    "long": "0755007W"
  },
  {
    "stationcode": "GER",
    "stationname": "Rafael Cabrera Airport",
    "lat": "215005N",
    "long": "0824702W"
  },
  {
    "stationcode": "CYO",
    "stationname": "Cayo Largo Del Sur Airport",
    "lat": "213659N",
    "long": "0813246W"
  },
  {
    "stationcode": "CFG",
    "stationname": "Cienfuegos Airport",
    "lat": "220900N",
    "long": "0802451W"
  },
  {
    "stationcode": "GAO",
    "stationname": "Los Canos Airport",
    "lat": "200507N",
    "long": "0750930W"
  },
  {
    "stationcode": "SNJ",
    "stationname": "San Julian Airport",
    "lat": "220507N",
    "long": "0840901W"
  },
  {
    "stationcode": "BYM",
    "stationname": "C.M. de Cespedes Airport",
    "lat": "202347N",
    "long": "0763717W"
  },
  {
    "stationcode": "CCC",
    "stationname": "Cayo Coco Airport",
    "lat": "222740N",
    "long": "0781942W"
  },
  {
    "stationcode": "MOA",
    "stationname": "Orestes Acosta Airport",
    "lat": "203914N",
    "long": "0745520W"
  },
  {
    "stationcode": "HOG",
    "stationname": "Frank Pais Airport",
    "lat": "204707N",
    "long": "0761854W"
  },
  {
    "stationcode": "BCA",
    "stationname": "Baracoa Airport",
    "lat": "202155N",
    "long": "0743022W"
  },
  {
    "stationcode": "USS",
    "stationname": "Sancti Spiritus Airport",
    "lat": "215820N",
    "long": "0792649W"
  },
  {
    "stationcode": "AVI",
    "stationname": "Maximo Gomez Airport",
    "lat": "220137N",
    "long": "0784723W"
  },
  {
    "stationcode": "CMW",
    "stationname": "Ign Agramonte International Airport",
    "lat": "212513N",
    "long": "0775051W"
  },
  {
    "stationcode": "HAV",
    "stationname": "Jose Marti International Airport",
    "lat": "225925N",
    "long": "0822428W"
  },
  {
    "stationcode": "PST",
    "stationname": "Preston Airport",
    "lat": "204500N",
    "long": "0754000W"
  },
  {
    "stationcode": "VRA",
    "stationname": "Juan Gualberto Gomez Airport",
    "lat": "230223N",
    "long": "0812612W"
  },
  {
    "stationcode": "MZO",
    "stationname": "Sierra Maestra Airport",
    "lat": "201717N",
    "long": "0770521W"
  },
  {
    "stationcode": "BVC",
    "stationname": "Rabil Airport",
    "lat": "160812N",
    "long": "0225320W"
  },
  {
    "stationcode": "MTI",
    "stationname": "Mosteiros Airport",
    "lat": "150000N",
    "long": "0242600W"
  },
  {
    "stationcode": "BVR",
    "stationname": "Esperadinha Airport",
    "lat": "145200N",
    "long": "0244400W"
  },
  {
    "stationcode": "NTO",
    "stationname": "Santo Antao Airport",
    "lat": "170300N",
    "long": "0251000W"
  },
  {
    "stationcode": "SNE",
    "stationname": "Preguica Airport",
    "lat": "163519N",
    "long": "0241703W"
  },
  {
    "stationcode": "VXE",
    "stationname": "San Pedro Airport",
    "lat": "165001N",
    "long": "0250317W"
  },
  {
    "stationcode": "MMO",
    "stationname": "Vila Do Maio Airport",
    "lat": "150922N",
    "long": "0231248W"
  },
  {
    "stationcode": "RAI",
    "stationname": "Francisco Mendes Airport",
    "lat": "145629N",
    "long": "0232905W"
  },
  {
    "stationcode": "SID",
    "stationname": "Amilcar Cabral International Airport",
    "lat": "164429N",
    "long": "0225658W"
  },
  {
    "stationcode": "SFL",
    "stationname": "Sao Filipe Airport",
    "lat": "145312N",
    "long": "0242855W"
  },
  {
    "stationcode": "GEC",
    "stationname": "Gecitkale Airport",
    "lat": "351410N",
    "long": "0334312E"
  },
  {
    "stationcode": "LCA",
    "stationname": "Larnaca Airport",
    "lat": "345230N",
    "long": "0333729E"
  },
  {
    "stationcode": "ECN",
    "stationname": "Ercan Airport",
    "lat": "350935N",
    "long": "0332918E"
  },
  {
    "stationcode": "NIC",
    "stationname": "Nicosia Airport",
    "lat": "350900N",
    "long": "0331600E"
  },
  {
    "stationcode": "PFO",
    "stationname": "Paphos International Airport",
    "lat": "344305N",
    "long": "0322909E"
  },
  {
    "stationcode": "MKA",
    "stationname": "Marianske Lazne Airport",
    "lat": "495522N",
    "long": "0124329E"
  },
  {
    "stationcode": "PRG",
    "stationname": "Prague",
    "lat": "500603N",
    "long": "0141536E"
  },
  {
    "stationcode": "PRV",
    "stationname": "Prerov Airport",
    "lat": "492533N",
    "long": "0172417E"
  },
  {
    "stationcode": "KLV",
    "stationname": "Karlovy Vary International Airport",
    "lat": "501211N",
    "long": "0125454E"
  },
  {
    "stationcode": "GTW",
    "stationname": "Holesov Airport",
    "lat": "491852N",
    "long": "0173408E"
  },
  {
    "stationcode": "UHE",
    "stationname": "Kunovice Airport",
    "lat": "490146N",
    "long": "0172623E"
  },
  {
    "stationcode": "BRQ",
    "stationname": "Turany Airport",
    "lat": "490905N",
    "long": "0164140E"
  },
  {
    "stationcode": "ZBE",
    "stationname": "Zabreh Airport",
    "lat": "495542N",
    "long": "0180442E"
  },
  {
    "stationcode": "OSR",
    "stationname": "Leos Janacek Airport",
    "lat": "494146N",
    "long": "0180640E"
  },
  {
    "stationcode": "PED",
    "stationname": "Pardubice Airport",
    "lat": "500048N",
    "long": "0154419E"
  },
  {
    "stationcode": "OLO",
    "stationname": "Neredin Airport",
    "lat": "493518N",
    "long": "0171230E"
  },
  {
    "stationcode": "VOD",
    "stationname": "Vodochody Airport",
    "lat": "501300N",
    "long": "0142344E"
  },
  {
    "stationcode": "MHG",
    "stationname": "Mannheim City Airport",
    "lat": "492822N",
    "long": "0083051E"
  },
  {
    "stationcode": "NRD",
    "stationname": "Norderney Airport",
    "lat": "534300N",
    "long": "0071400E"
  },
  {
    "stationcode": "WBG",
    "stationname": "Jagel Airport",
    "lat": "542734N",
    "long": "0093059E"
  },
  {
    "stationcode": "THF",
    "stationname": "Tempelhof Airport",
    "lat": "522829N",
    "long": "0132412E"
  },
  {
    "stationcode": "AOC",
    "stationname": "Altenburg Nobitz Airport",
    "lat": "505855N",
    "long": "0123023E"
  },
  {
    "stationcode": "LBC",
    "stationname": "Luebeck-Blankensee Airport",
    "lat": "534819N",
    "long": "0104309E"
  },
  {
    "stationcode": "KEL",
    "stationname": "Holtenau Airport",
    "lat": "542247N",
    "long": "0100842E"
  },
  {
    "stationcode": "DRS",
    "stationname": "Dresden Airport",
    "lat": "510804N",
    "long": "0134605E"
  },
  {
    "stationcode": "LGO",
    "stationname": "Langeoog Airport",
    "lat": "534500N",
    "long": "0072900E"
  },
  {
    "stationcode": "KOQ",
    "stationname": "Koethen Airport",
    "lat": "514300N",
    "long": "0115700E"
  },
  {
    "stationcode": "SCN",
    "stationname": "Saarbruecken Airport",
    "lat": "491253N",
    "long": "0070638E"
  },
  {
    "stationcode": "FNB",
    "stationname": "Trollenhagen",
    "lat": "533608N",
    "long": "0131822E"
  },
  {
    "stationcode": "MUC",
    "stationname": "Munich International",
    "lat": "482114N",
    "long": "0114710E"
  },
  {
    "stationcode": "GTI",
    "stationname": "Guettin Airport",
    "lat": "542300N",
    "long": "0121900E"
  },
  {
    "stationcode": "DTM",
    "stationname": "Dortmund Airport",
    "lat": "513106N",
    "long": "0073644E"
  },
  {
    "stationcode": "RMS",
    "stationname": "Ramstein Airport",
    "lat": "492613N",
    "long": "0073601E"
  },
  {
    "stationcode": "IES",
    "stationname": "Goehlis Airport",
    "lat": "511737N",
    "long": "0132122E"
  },
  {
    "stationcode": "OHR",
    "stationname": "Wyk Airport",
    "lat": "544107N",
    "long": "0083155E"
  },
  {
    "stationcode": "REB",
    "stationname": "Laerz",
    "lat": "531823N",
    "long": "0124511E"
  },
  {
    "stationcode": "BFE",
    "stationname": "Windelsbleiche",
    "lat": "515753N",
    "long": "0083241E"
  },
  {
    "stationcode": "BRE",
    "stationname": "Bremen Airport",
    "lat": "530251N",
    "long": "0084712E"
  },
  {
    "stationcode": "FRZ",
    "stationname": "Fritzlar Air Base",
    "lat": "510652N",
    "long": "0091709E"
  },
  {
    "stationcode": "ESS",
    "stationname": "Muelheim",
    "lat": "512408N",
    "long": "0065614E"
  },
  {
    "stationcode": "SZW",
    "stationname": "Parchim Airport",
    "lat": "532538N",
    "long": "0114659E"
  },
  {
    "stationcode": "BWE",
    "stationname": "Braunschweig Airport",
    "lat": "521909N",
    "long": "0103322E"
  },
  {
    "stationcode": "BBH",
    "stationname": "Barth",
    "lat": "542015N",
    "long": "0124200E"
  },
  {
    "stationcode": "GHF",
    "stationname": "Giebelstadt Airport",
    "lat": "493900N",
    "long": "0095800E"
  },
  {
    "stationcode": "XFW",
    "stationname": "Finkenwerder Airport",
    "lat": "533209N",
    "long": "0095013E"
  },
  {
    "stationcode": "FMO",
    "stationname": "Muenster Airport",
    "lat": "520805N",
    "long": "0074105E"
  },
  {
    "stationcode": "HDF",
    "stationname": "Heringsdorf Airport",
    "lat": "535243N",
    "long": "0140908E"
  },
  {
    "stationcode": "MGL",
    "stationname": "Moenchengladbach Airport",
    "lat": "511349N",
    "long": "0063016E"
  },
  {
    "stationcode": "FEL",
    "stationname": "Fuerstenfeldbruck Airport",
    "lat": "481000N",
    "long": "0111500E"
  },
  {
    "stationcode": "WIE",
    "stationname": "Wiesbaden Air Base",
    "lat": "500259N",
    "long": "0081931E"
  },
  {
    "stationcode": "BMR",
    "stationname": "Baltrum Airport",
    "lat": "534400N",
    "long": "0072300E"
  },
  {
    "stationcode": "FDH",
    "stationname": "Friedrichshafen Airport",
    "lat": "474017N",
    "long": "0093041E"
  },
  {
    "stationcode": "NRN",
    "stationname": "Weeze Airport",
    "lat": "513609N",
    "long": "0060832E"
  },
  {
    "stationcode": "SGE",
    "stationname": "Siegerland Airport",
    "lat": "505200N",
    "long": "0080200E"
  },
  {
    "stationcode": "IGS",
    "stationname": "Manching Airport",
    "lat": "484256N",
    "long": "0113202E"
  },
  {
    "stationcode": "CBU",
    "stationname": "Drewitz",
    "lat": "515322N",
    "long": "0143155E"
  },
  {
    "stationcode": "DUS",
    "stationname": "Duesseldorf International",
    "lat": "511722N",
    "long": "0064600E"
  },
  {
    "stationcode": "ERF",
    "stationname": "Erfurt Airport",
    "lat": "505847N",
    "long": "0105729E"
  },
  {
    "stationcode": "CSO",
    "stationname": "Cochstedt Airport",
    "lat": "515121N",
    "long": "0112505E"
  },
  {
    "stationcode": "AGE",
    "stationname": "Flugplatz Airport",
    "lat": "534714N",
    "long": "0075457E"
  },
  {
    "stationcode": "PAD",
    "stationname": "Paderborn Airport",
    "lat": "513651N",
    "long": "0083659E"
  },
  {
    "stationcode": "EME",
    "stationname": "Emden Airport",
    "lat": "532328N",
    "long": "0071332E"
  },
  {
    "stationcode": "TXL",
    "stationname": "Tegel Airport",
    "lat": "523335N",
    "long": "0131716E"
  },
  {
    "stationcode": "KSF",
    "stationname": "Calden",
    "lat": "512430N",
    "long": "0092239E"
  },
  {
    "stationcode": "HEI",
    "stationname": "Heide-Buesum Airport",
    "lat": "540912N",
    "long": "0085406E"
  },
  {
    "stationcode": "PEF",
    "stationname": "Peenemuende Airport",
    "lat": "541000N",
    "long": "0134600E"
  },
  {
    "stationcode": "OBF",
    "stationname": "Oberpfaffenhofen Airport",
    "lat": "480500N",
    "long": "0111700E"
  },
  {
    "stationcode": "IZE",
    "stationname": "EDHF Airport",
    "lat": "535941N",
    "long": "0093443E"
  },
  {
    "stationcode": "BMK",
    "stationname": "Borkum Airport",
    "lat": "533544N",
    "long": "0064233E"
  },
  {
    "stationcode": "QHU",
    "stationname": "Schwesing",
    "lat": "543036N",
    "long": "0090818E"
  },
  {
    "stationcode": "FKB",
    "stationname": "Baden Airpark",
    "lat": "484646N",
    "long": "0080450E"
  },
  {
    "stationcode": "EUM",
    "stationname": "Neumuenster Airport",
    "lat": "540447N",
    "long": "0095631E"
  },
  {
    "stationcode": "BRV",
    "stationname": "Bremerhaven Airport",
    "lat": "533025N",
    "long": "0083422E"
  },
  {
    "stationcode": "STR",
    "stationname": "Stuttgart Airport",
    "lat": "484124N",
    "long": "0091319E"
  },
  {
    "stationcode": "JUI",
    "stationname": "Juist Airport",
    "lat": "534052N",
    "long": "0070321E"
  },
  {
    "stationcode": "NUE",
    "stationname": "Nuremberg Airport",
    "lat": "492955N",
    "long": "0110401E"
  },
  {
    "stationcode": "PSH",
    "stationname": "St. Peter Airport",
    "lat": "541832N",
    "long": "0084113E"
  },
  {
    "stationcode": "VAC",
    "stationname": "Varrelbusch Airport",
    "lat": "525500N",
    "long": "0080300E"
  },
  {
    "stationcode": "LEJ",
    "stationname": "Leipzig/Halle Airport",
    "lat": "512557N",
    "long": "0121430E"
  },
  {
    "stationcode": "GKE",
    "stationname": "Air Base",
    "lat": "505739N",
    "long": "0060233E"
  },
  {
    "stationcode": "BER",
    "stationname": "Brandenburg Airport",
    "lat": "522200N",
    "long": "0133012E"
  },
  {
    "stationcode": "KZG",
    "stationname": "Kitzingen Airport",
    "lat": "494400N",
    "long": "0100900E"
  },
  {
    "stationcode": "SXF",
    "stationname": "Schoenefeld Airport",
    "lat": "522200N",
    "long": "0133000E"
  },
  {
    "stationcode": "BYU",
    "stationname": "Bindlacher Berg",
    "lat": "495904N",
    "long": "0113819E"
  },
  {
    "stationcode": "FMM",
    "stationname": "Allgaeu Airport",
    "lat": "475917N",
    "long": "0101415E"
  },
  {
    "stationcode": "XLW",
    "stationname": "Lemwerder Airport",
    "lat": "530807N",
    "long": "0083704E"
  },
  {
    "stationcode": "LHA",
    "stationname": "Black Forest",
    "lat": "482209N",
    "long": "0074940E"
  },
  {
    "stationcode": "WVN",
    "stationname": "Mariensiel",
    "lat": "533017N",
    "long": "0080312E"
  },
  {
    "stationcode": "FRA",
    "stationname": "Frankfurt International Airport",
    "lat": "500159N",
    "long": "0083414E"
  },
  {
    "stationcode": "HGL",
    "stationname": "Duene",
    "lat": "541107N",
    "long": "0075457E"
  },
  {
    "stationcode": "NOD",
    "stationname": "Norddeich",
    "lat": "533759N",
    "long": "0071122E"
  },
  {
    "stationcode": "ZIW",
    "stationname": "Kehl Airport",
    "lat": "483400N",
    "long": "0074900E"
  },
  {
    "stationcode": "RLG",
    "stationname": "Laage Airport",
    "lat": "535505N",
    "long": "0121642E"
  },
  {
    "stationcode": "UPF",
    "stationname": "Pforheim Airport",
    "lat": "485400N",
    "long": "0084200E"
  },
  {
    "stationcode": "HAJ",
    "stationname": "Hannover Airport",
    "lat": "522738N",
    "long": "0094122E"
  },
  {
    "stationcode": "HHN",
    "stationname": "Hahn Airport",
    "lat": "495655N",
    "long": "0071550E"
  },
  {
    "stationcode": "FLF",
    "stationname": "Schaferhaus Airport",
    "lat": "544618N",
    "long": "0092242E"
  },
  {
    "stationcode": "AGB",
    "stationname": "Augsburg Airport",
    "lat": "482531N",
    "long": "0105554E"
  },
  {
    "stationcode": "EIB",
    "stationname": "Kindel",
    "lat": "505934N",
    "long": "0102822E"
  },
  {
    "stationcode": "FCN",
    "stationname": "Nordholz",
    "lat": "534604N",
    "long": "0083931E"
  },
  {
    "stationcode": "HOQ",
    "stationname": "Plauen",
    "lat": "501720N",
    "long": "0115118E"
  },
  {
    "stationcode": "HAM",
    "stationname": "Hamburg Airport",
    "lat": "533749N",
    "long": "0095918E"
  },
  {
    "stationcode": "GUT",
    "stationname": "Guetersloh Airport",
    "lat": "515522N",
    "long": "0081823E"
  },
  {
    "stationcode": "URD",
    "stationname": "Burg Feuerstein Airport",
    "lat": "494800N",
    "long": "0110800E"
  },
  {
    "stationcode": "GWT",
    "stationname": "Sylt Airport",
    "lat": "545448N",
    "long": "0082026E"
  },
  {
    "stationcode": "CGN",
    "stationname": "Cologne/Bonn Airport",
    "lat": "505157N",
    "long": "0070834E"
  },
  {
    "stationcode": "AAH",
    "stationname": "Merzbrueck Airport",
    "lat": "504923N",
    "long": "0061111E"
  },
  {
    "stationcode": "RBM",
    "stationname": "Wallmuehle Airport",
    "lat": "485403N",
    "long": "0123105E"
  },
  {
    "stationcode": "JIB",
    "stationname": "Ambouli Airport",
    "lat": "113250N",
    "long": "0430934E"
  },
  {
    "stationcode": "AII",
    "stationname": "Ali-Sabieh Airport",
    "lat": "110900N",
    "long": "0424300E"
  },
  {
    "stationcode": "TDJ",
    "stationname": "Tadjoura Airport",
    "lat": "114800N",
    "long": "0425400E"
  },
  {
    "stationcode": "OBC",
    "stationname": "Obock Airport",
    "lat": "115806N",
    "long": "0431644E"
  },
  {
    "stationcode": "MHI",
    "stationname": "Musha Airport",
    "lat": "112000N",
    "long": "0430100E"
  },
  {
    "stationcode": "STA",
    "stationname": "Stauning Airport",
    "lat": "555924N",
    "long": "0082114E"
  },
  {
    "stationcode": "MRW",
    "stationname": "Maribo Airport",
    "lat": "544158N",
    "long": "0112624E"
  },
  {
    "stationcode": "CPH",
    "stationname": "Copenhagen Airport",
    "lat": "553713N",
    "long": "0123901E"
  },
  {
    "stationcode": "KRP",
    "stationname": "Karup Airport",
    "lat": "561751N",
    "long": "0090729E"
  },
  {
    "stationcode": "SQW",
    "stationname": "Skive Airport",
    "lat": "563301N",
    "long": "0091023E"
  },
  {
    "stationcode": "AAL",
    "stationname": "Aalborg Airport",
    "lat": "570534N",
    "long": "0095057E"
  },
  {
    "stationcode": "BLL",
    "stationname": "Billund Airport",
    "lat": "554425N",
    "long": "0090906E"
  },
  {
    "stationcode": "BYR",
    "stationname": "Laeso Airport",
    "lat": "571638N",
    "long": "0110000E"
  },
  {
    "stationcode": "RNN",
    "stationname": "Bornholm Airport",
    "lat": "550348N",
    "long": "0144537E"
  },
  {
    "stationcode": "EBJ",
    "stationname": "Esbjerg Airport",
    "lat": "553135N",
    "long": "0083309E"
  },
  {
    "stationcode": "SGD",
    "stationname": "Sonderborg Airport",
    "lat": "545752N",
    "long": "0094730E"
  },
  {
    "stationcode": "CNL",
    "stationname": "Sindal Airport",
    "lat": "573013N",
    "long": "0101346E"
  },
  {
    "stationcode": "ODE",
    "stationname": "Beldringe Airport",
    "lat": "552836N",
    "long": "0101951E"
  },
  {
    "stationcode": "AAR",
    "stationname": "Tirstrup Airport",
    "lat": "561814N",
    "long": "0103710E"
  },
  {
    "stationcode": "TED",
    "stationname": "Thisted Airport",
    "lat": "570408N",
    "long": "0084219E"
  },
  {
    "stationcode": "RKE",
    "stationname": "Roskilde Airport",
    "lat": "553509N",
    "long": "0120754E"
  },
  {
    "stationcode": "SKS",
    "stationname": "Vojens Airport",
    "lat": "551332N",
    "long": "0091550E"
  },
  {
    "stationcode": "VEJ",
    "stationname": "Vejle Airport",
    "lat": "554200N",
    "long": "0093200E"
  },
  {
    "stationcode": "DCF",
    "stationname": "Cane Field Airport",
    "lat": "152012N",
    "long": "0612332W"
  },
  {
    "stationcode": "DOM",
    "stationname": "Melville Hall Airport",
    "lat": "153249N",
    "long": "0611800W"
  },
  {
    "stationcode": "BRX",
    "stationname": "Barahona Airport",
    "lat": "181300N",
    "long": "0710700W"
  },
  {
    "stationcode": "SJM",
    "stationname": "San Juan Airport",
    "lat": "185000N",
    "long": "0711400W"
  },
  {
    "stationcode": "POP",
    "stationname": "La Union Airport",
    "lat": "194528N",
    "long": "0703412W"
  },
  {
    "stationcode": "EPS",
    "stationname": "El Portillo Airport",
    "lat": "191500N",
    "long": "0693000W"
  },
  {
    "stationcode": "JBQ",
    "stationname": "Dr. Joaquin Balaguer Airport",
    "lat": "183420N",
    "long": "0695908W"
  },
  {
    "stationcode": "SNX",
    "stationname": "Sabana De Mar Airport",
    "lat": "190700N",
    "long": "0692400W"
  },
  {
    "stationcode": "LRM",
    "stationname": "La Romana Airport",
    "lat": "182703N",
    "long": "0685443W"
  },
  {
    "stationcode": "COZ",
    "stationname": "Constanza Airport",
    "lat": "185400N",
    "long": "0704300W"
  },
  {
    "stationcode": "AZS",
    "stationname": "Arroyo Barril International",
    "lat": "191602N",
    "long": "0694432W"
  },
  {
    "stationcode": "CBJ",
    "stationname": "Cabo Rojo Airport",
    "lat": "175600N",
    "long": "0713900W"
  },
  {
    "stationcode": "PUJ",
    "stationname": "Punta Cana Airport",
    "lat": "183403N",
    "long": "0682148W"
  },
  {
    "stationcode": "STI",
    "stationname": "Santiago Municipal Airport",
    "lat": "192422N",
    "long": "0703617W"
  },
  {
    "stationcode": "SDQ",
    "stationname": "Las Americas Airport",
    "lat": "182545N",
    "long": "0694009W"
  },
  {
    "stationcode": "HEX",
    "stationname": "Herrera Airport",
    "lat": "182830N",
    "long": "0695830W"
  },
  {
    "stationcode": "TMR",
    "stationname": "Aguemar Airport",
    "lat": "224841N",
    "long": "0052704E"
  },
  {
    "stationcode": "LOO",
    "stationname": "L'Mekrareg Airport",
    "lat": "334553N",
    "long": "0025539E"
  },
  {
    "stationcode": "BMW",
    "stationname": "Bordj Badji Mokhtar Airport",
    "lat": "212239N",
    "long": "0005525E"
  },
  {
    "stationcode": "TGR",
    "stationname": "Touggourt Airport",
    "lat": "330404N",
    "long": "0060519E"
  },
  {
    "stationcode": "ELU",
    "stationname": "Guemar Airport",
    "lat": "333046N",
    "long": "0064658E"
  },
  {
    "stationcode": "INZ",
    "stationname": "In Salah Airport",
    "lat": "271504N",
    "long": "0023043E"
  },
  {
    "stationcode": "CBH",
    "stationname": "Leger Airport",
    "lat": "313846N",
    "long": "0021644W"
  },
  {
    "stationcode": "BJA",
    "stationname": "Bejaia Airport",
    "lat": "364243N",
    "long": "0050412E"
  },
  {
    "stationcode": "GJL",
    "stationname": "Jijel Airport",
    "lat": "364742N",
    "long": "0055225E"
  },
  {
    "stationcode": "GBB",
    "stationname": "Gara-Djebilet Airport",
    "lat": "404833N",
    "long": "0474330E"
  },
  {
    "stationcode": "TEE",
    "stationname": "Tebessa Airport",
    "lat": "352554N",
    "long": "0080715E"
  },
  {
    "stationcode": "CFK",
    "stationname": "Abou Bakr Belkaid Airport",
    "lat": "361246N",
    "long": "0011954E"
  },
  {
    "stationcode": "INF",
    "stationname": "In Guezzam Airport",
    "lat": "193340N",
    "long": "0054505E"
  },
  {
    "stationcode": "VVZ",
    "stationname": "Illizi Airport",
    "lat": "264325N",
    "long": "0083722E"
  },
  {
    "stationcode": "BLJ",
    "stationname": "Batna Airport",
    "lat": "354508N",
    "long": "0061831E"
  },
  {
    "stationcode": "ELG",
    "stationname": "El Golea Airport",
    "lat": "303454N",
    "long": "0025142E"
  },
  {
    "stationcode": "MUW",
    "stationname": "Mascara Airport",
    "lat": "351227N",
    "long": "0000846E"
  },
  {
    "stationcode": "MZW",
    "stationname": "Mechria Airport",
    "lat": "333209N",
    "long": "0001433W"
  },
  {
    "stationcode": "BFW",
    "stationname": "Sidi Belabbes Airport",
    "lat": "351018N",
    "long": "0003522W"
  },
  {
    "stationcode": "HRM",
    "stationname": "Tilrempt Airport",
    "lat": "325600N",
    "long": "0031800E"
  },
  {
    "stationcode": "QZN",
    "stationname": "Relizane Airport",
    "lat": "355000N",
    "long": "0003200E"
  },
  {
    "stationcode": "TIN",
    "stationname": "Tindouf Airport",
    "lat": "274201N",
    "long": "0081002W"
  },
  {
    "stationcode": "TAF",
    "stationname": "Tafaraoui Airport",
    "lat": "353300N",
    "long": "0003200W"
  },
  {
    "stationcode": "BUJ",
    "stationname": "Ain Eddis Airport",
    "lat": "352000N",
    "long": "0041200E"
  },
  {
    "stationcode": "IAM",
    "stationname": "In Amenas Airport",
    "lat": "280306N",
    "long": "0093834E"
  },
  {
    "stationcode": "ORN",
    "stationname": "Es Senia Airport",
    "lat": "353726N",
    "long": "0003716W"
  },
  {
    "stationcode": "KDF",
    "stationname": "Kouba Airport",
    "lat": "195006N",
    "long": "1555917E"
  },
  {
    "stationcode": "GHA",
    "stationname": "Noumerate Airport",
    "lat": "322303N",
    "long": "0034739E"
  },
  {
    "stationcode": "QSF",
    "stationname": "Setif Airport",
    "lat": "361041N",
    "long": "0051928E"
  },
  {
    "stationcode": "BSK",
    "stationname": "Biskra Airport",
    "lat": "344736N",
    "long": "0054418E"
  },
  {
    "stationcode": "CZL",
    "stationname": "Ain El Bey Airport",
    "lat": "361634N",
    "long": "0063713E"
  },
  {
    "stationcode": "AZR",
    "stationname": "Adrar Airport",
    "lat": "275015N",
    "long": "0001111W"
  },
  {
    "stationcode": "HME",
    "stationname": "Oued Irara Airport",
    "lat": "314023N",
    "long": "0060826E"
  },
  {
    "stationcode": "OGX",
    "stationname": "Ain Beida Airport",
    "lat": "315526N",
    "long": "0052438E"
  },
  {
    "stationcode": "TMX",
    "stationname": "Timimoun Airport",
    "lat": "291413N",
    "long": "0001637E"
  },
  {
    "stationcode": "EBH",
    "stationname": "El Bayadh Airport",
    "lat": "334315N",
    "long": "0010529E"
  },
  {
    "stationcode": "MQV",
    "stationname": "Mostaganem Airport",
    "lat": "355400N",
    "long": "0005900E"
  },
  {
    "stationcode": "SKI",
    "stationname": "Skikda Airport",
    "lat": "365210N",
    "long": "0065700E"
  },
  {
    "stationcode": "DJG",
    "stationname": "Inedbirenne Airport",
    "lat": "241734N",
    "long": "0092709E"
  },
  {
    "stationcode": "AAE",
    "stationname": "Les Salines Airport",
    "lat": "364920N",
    "long": "0074833E"
  },
  {
    "stationcode": "TID",
    "stationname": "Bouchekif Airport",
    "lat": "352030N",
    "long": "0012801E"
  },
  {
    "stationcode": "TLM",
    "stationname": "Zenata Airport",
    "lat": "350048N",
    "long": "0012728W"
  },
  {
    "stationcode": "KDL",
    "stationname": "Kardla Airport",
    "lat": "585927N",
    "long": "0224951E"
  },
  {
    "stationcode": "TAY",
    "stationname": "Ulenurme Airport",
    "lat": "581827N",
    "long": "0264126E"
  },
  {
    "stationcode": "EPU",
    "stationname": "Parnu Airport",
    "lat": "582509N",
    "long": "0242823E"
  },
  {
    "stationcode": "TLL",
    "stationname": "Lennart Meri Airport",
    "lat": "592448N",
    "long": "0244957E"
  },
  {
    "stationcode": "URE",
    "stationname": "Kuressaare Airport",
    "lat": "581348N",
    "long": "0223034E"
  },
  {
    "stationcode": "QUF",
    "stationname": "Pirita Harbour Airport",
    "lat": "592602N",
    "long": "0244341E"
  },
  {
    "stationcode": "RMF",
    "stationname": "Marsa Alam Airport",
    "lat": "253329N",
    "long": "0343458E"
  },
  {
    "stationcode": "DBB",
    "stationname": "Alalamain International Airport",
    "lat": "305528N",
    "long": "0282741E"
  },
  {
    "stationcode": "GSQ",
    "stationname": "Shark Elowainat Airport",
    "lat": "223500N",
    "long": "0284300E"
  },
  {
    "stationcode": "LXR",
    "stationname": "Luxor Airport",
    "lat": "254016N",
    "long": "0324224E"
  },
  {
    "stationcode": "SKV",
    "stationname": "Mount Sinai Airport",
    "lat": "284100N",
    "long": "0340400E"
  },
  {
    "stationcode": "ELT",
    "stationname": "Tour Sinai City Airport",
    "lat": "281200N",
    "long": "0333900E"
  },
  {
    "stationcode": "SEW",
    "stationname": "Siwa Airport",
    "lat": "292046N",
    "long": "0253021E"
  },
  {
    "stationcode": "ABS",
    "stationname": "Abu Simbel Airport",
    "lat": "222233N",
    "long": "0313642E"
  },
  {
    "stationcode": "SSH",
    "stationname": "Ophira Airport",
    "lat": "275842N",
    "long": "0342335E"
  },
  {
    "stationcode": "QUD",
    "stationname": "Damanhur Airport",
    "lat": "310300N",
    "long": "0302800E"
  },
  {
    "stationcode": "EMY",
    "stationname": "El Minya Airport",
    "lat": "280500N",
    "long": "0304400E"
  },
  {
    "stationcode": "TFR",
    "stationname": "Ramadan Airport",
    "lat": "300258N",
    "long": "0315023E"
  },
  {
    "stationcode": "ALY",
    "stationname": "El Nohza Airport",
    "lat": "311102N",
    "long": "0295656E"
  },
  {
    "stationcode": "HMB",
    "stationname": "Mubarak International Airport",
    "lat": "262034N",
    "long": "0314434E"
  },
  {
    "stationcode": "ATZ",
    "stationname": "Assiut Airport",
    "lat": "270200N",
    "long": "0310000E"
  },
  {
    "stationcode": "UVL",
    "stationname": "Kharga Airport",
    "lat": "252836N",
    "long": "0303524E"
  },
  {
    "stationcode": "ASW",
    "stationname": "Aswan Airport",
    "lat": "235754N",
    "long": "0324918E"
  },
  {
    "stationcode": "AUE",
    "stationname": "Abu Rudeis Airport",
    "lat": "285800N",
    "long": "0331100E"
  },
  {
    "stationcode": "PSD",
    "stationname": "Port Said Airport",
    "lat": "311700N",
    "long": "0321400E"
  },
  {
    "stationcode": "AAC",
    "stationname": "Al Arish Airport",
    "lat": "310424N",
    "long": "0335009E"
  },
  {
    "stationcode": "TCP",
    "stationname": "Taba International Airport",
    "lat": "293517N",
    "long": "0344635E"
  },
  {
    "stationcode": "EES",
    "stationname": "Berenice International",
    "lat": "235912N",
    "long": "0352733E"
  },
  {
    "stationcode": "SQK",
    "stationname": "Sidi Barani Airport",
    "lat": "313600N",
    "long": "0255600E"
  },
  {
    "stationcode": "MUH",
    "stationname": "Mersa Matruh Airport",
    "lat": "312000N",
    "long": "0271300E"
  },
  {
    "stationcode": "HRG",
    "stationname": "Hurghada Airport",
    "lat": "271043N",
    "long": "0334805E"
  },
  {
    "stationcode": "DAK",
    "stationname": "Dakhla Airport",
    "lat": "252500N",
    "long": "0290000E"
  },
  {
    "stationcode": "HBE",
    "stationname": "Borg El Arab Airport",
    "lat": "305504N",
    "long": "0294147E"
  },
  {
    "stationcode": "RDL",
    "stationname": "Bardawil International",
    "lat": "302434N",
    "long": "0330907E"
  },
  {
    "stationcode": "TES",
    "stationname": "Tessenei Airport",
    "lat": "150700N",
    "long": "0364100E"
  },
  {
    "stationcode": "ASA",
    "stationname": "Assab Airport",
    "lat": "130418N",
    "long": "0423842E"
  },
  {
    "stationcode": "ASM",
    "stationname": "Asmara International Airport",
    "lat": "151731N",
    "long": "0385438E"
  },
  {
    "stationcode": "MSW",
    "stationname": "Massawa Airport",
    "lat": "154012N",
    "long": "0392212E"
  },
  {
    "stationcode": "SXU",
    "stationname": "Soddu Airport",
    "lat": "065000N",
    "long": "0374500E"
  },
  {
    "stationcode": "DGC",
    "stationname": "Degahbur Airport",
    "lat": "081400N",
    "long": "0433500E"
  },
  {
    "stationcode": "MUJ",
    "stationname": "Mui Airport",
    "lat": "055153N",
    "long": "0354455E"
  },
  {
    "stationcode": "ADD",
    "stationname": "Bole Airport",
    "lat": "085840N",
    "long": "0384753E"
  },
  {
    "stationcode": "GHD",
    "stationname": "Ghimbi Airport",
    "lat": "091000N",
    "long": "0354900E"
  },
  {
    "stationcode": "ASO",
    "stationname": "Asosa Airport",
    "lat": "100107N",
    "long": "0343511E"
  },
  {
    "stationcode": "FNH",
    "stationname": "Fincha Airport",
    "lat": "093500N",
    "long": "0372600E"
  },
  {
    "stationcode": "WAC",
    "stationname": "Waca Airport",
    "lat": "071000N",
    "long": "0371000E"
  },
  {
    "stationcode": "ABK",
    "stationname": "Kabri Dar Airport",
    "lat": "064402N",
    "long": "0441519E"
  },
  {
    "stationcode": "AXU",
    "stationname": "Axum Airport",
    "lat": "140848N",
    "long": "0384622E"
  },
  {
    "stationcode": "SKR",
    "stationname": "Shakiso Airport",
    "lat": "054133N",
    "long": "0385836E"
  },
  {
    "stationcode": "MQX",
    "stationname": "Makale Airport",
    "lat": "132806N",
    "long": "0393135E"
  },
  {
    "stationcode": "SZE",
    "stationname": "Semera Airport",
    "lat": "114711N",
    "long": "0405935E"
  },
  {
    "stationcode": "GOB",
    "stationname": "Goba Airport",
    "lat": "070708N",
    "long": "0400242E"
  },
  {
    "stationcode": "MKS",
    "stationname": "Mekane Selam Airport",
    "lat": "104500N",
    "long": "0384600E"
  },
  {
    "stationcode": "BEI",
    "stationname": "Beica Airport",
    "lat": "092331N",
    "long": "0343110E"
  },
  {
    "stationcode": "HIL",
    "stationname": "Shillavo Airport",
    "lat": "060500N",
    "long": "0444800E"
  },
  {
    "stationcode": "DSE",
    "stationname": "Combolcha Airport",
    "lat": "110500N",
    "long": "0394300E"
  },
  {
    "stationcode": "NDM",
    "stationname": "Mendi Airport",
    "lat": "094600N",
    "long": "0350600E"
  },
  {
    "stationcode": "BJR",
    "stationname": "Bahar Dar Airport",
    "lat": "113629N",
    "long": "0371918E"
  },
  {
    "stationcode": "AWH",
    "stationname": "Awareh Airport",
    "lat": "081600N",
    "long": "0441100E"
  },
  {
    "stationcode": "GNN",
    "stationname": "Ghinnir Airport",
    "lat": "070900N",
    "long": "0404300E"
  },
  {
    "stationcode": "LFO",
    "stationname": "Kelafo Airport",
    "lat": "053600N",
    "long": "0442000E"
  },
  {
    "stationcode": "NEJ",
    "stationname": "Nejjo Airport",
    "lat": "093000N",
    "long": "0352800E"
  },
  {
    "stationcode": "SQJ",
    "stationname": "Shehdi Airport",
    "lat": "262534N",
    "long": "1175000E"
  },
  {
    "stationcode": "AMH",
    "stationname": "Arba Mintch Airport",
    "lat": "060222N",
    "long": "0373526E"
  },
  {
    "stationcode": "ALK",
    "stationname": "Asela Airport",
    "lat": "075800N",
    "long": "0390700E"
  },
  {
    "stationcode": "ETE",
    "stationname": "Genda Wuha Airport",
    "lat": "125600N",
    "long": "0361000E"
  },
  {
    "stationcode": "SHC",
    "stationname": "Indaselassie Airport",
    "lat": "140446N",
    "long": "0381617E"
  },
  {
    "stationcode": "AWA",
    "stationname": "Awasa Airport",
    "lat": "070403N",
    "long": "0382926E"
  },
  {
    "stationcode": "PWI",
    "stationname": "Beles Airport",
    "lat": "112000N",
    "long": "0362500E"
  },
  {
    "stationcode": "BCO",
    "stationname": "Jinka Airport",
    "lat": "054702N",
    "long": "0363345E"
  },
  {
    "stationcode": "GLC",
    "stationname": "Geladi Airport",
    "lat": "065800N",
    "long": "0462500E"
  },
  {
    "stationcode": "OTA",
    "stationname": "Mota Airport",
    "lat": "110400N",
    "long": "0375300E"
  },
  {
    "stationcode": "MYS",
    "stationname": "Moyale Airport",
    "lat": "032800N",
    "long": "0390600E"
  },
  {
    "stationcode": "JIM",
    "stationname": "Jimma Airport",
    "lat": "073946N",
    "long": "0364905E"
  },
  {
    "stationcode": "TIE",
    "stationname": "Tippi Airport",
    "lat": "070700N",
    "long": "0352300E"
  },
  {
    "stationcode": "LLI",
    "stationname": "Lalibela Airport",
    "lat": "115830N",
    "long": "0385848E"
  },
  {
    "stationcode": "MKD",
    "stationname": "Chagni Airport",
    "lat": "110200N",
    "long": "0362800E"
  },
  {
    "stationcode": "NEK",
    "stationname": "Nekemt Airport",
    "lat": "090200N",
    "long": "0363500E"
  },
  {
    "stationcode": "BCY",
    "stationname": "Bulchi Airport",
    "lat": "061300N",
    "long": "0364000E"
  },
  {
    "stationcode": "GDQ",
    "stationname": "Gondar Airport",
    "lat": "123112N",
    "long": "0372603E"
  },
  {
    "stationcode": "TUJ",
    "stationname": "Tum Airport",
    "lat": "061500N",
    "long": "0352800E"
  },
  {
    "stationcode": "WRA",
    "stationname": "Warder Airport",
    "lat": "065800N",
    "long": "0452000E"
  },
  {
    "stationcode": "DIR",
    "stationname": "Aba Tenna D Yilma Airport",
    "lat": "093729N",
    "long": "0415115E"
  },
  {
    "stationcode": "DBT",
    "stationname": "Debra Tabor Airport",
    "lat": "115800N",
    "long": "0380000E"
  },
  {
    "stationcode": "MHJ",
    "stationname": "Misrak Gashamo Airport",
    "lat": "492846N",
    "long": "0082809E"
  },
  {
    "stationcode": "HUE",
    "stationname": "Humera Airport",
    "lat": "135000N",
    "long": "0365246E"
  },
  {
    "stationcode": "DBM",
    "stationname": "Debra Marcos Airport",
    "lat": "102000N",
    "long": "0374600E"
  },
  {
    "stationcode": "GOR",
    "stationname": "Gore Airport",
    "lat": "081000N",
    "long": "0353300E"
  },
  {
    "stationcode": "GMB",
    "stationname": "Gambela Airport",
    "lat": "080744N",
    "long": "0343347E"
  },
  {
    "stationcode": "EGL",
    "stationname": "Neghelli Airport",
    "lat": "051700N",
    "long": "0394200E"
  },
  {
    "stationcode": "XBL",
    "stationname": "Buno Bedelle Airport",
    "lat": "082700N",
    "long": "0362000E"
  },
  {
    "stationcode": "JIJ",
    "stationname": "Jigiga Airport",
    "lat": "092148N",
    "long": "0424723E"
  },
  {
    "stationcode": "DEM",
    "stationname": "Dembidollo Airport",
    "lat": "083317N",
    "long": "0345127E"
  },
  {
    "stationcode": "MZX",
    "stationname": "Mena Airport",
    "lat": "062100N",
    "long": "0394300E"
  },
  {
    "stationcode": "MTF",
    "stationname": "Mizan Teferi Airport",
    "lat": "065800N",
    "long": "0353200E"
  },
  {
    "stationcode": "GDE",
    "stationname": "Gode/Iddidole Airport",
    "lat": "055606N",
    "long": "0433443E"
  },
  {
    "stationcode": "KAJ",
    "stationname": "Kajaani Airport",
    "lat": "641708N",
    "long": "0274133E"
  },
  {
    "stationcode": "HEL",
    "stationname": "Helsinki-Vantaa Airport",
    "lat": "601902N",
    "long": "0245748E"
  },
  {
    "stationcode": "MIK",
    "stationname": "Mikkeli Airport",
    "lat": "614200N",
    "long": "0271300E"
  },
  {
    "stationcode": "KEV",
    "stationname": "Halli Airport",
    "lat": "615123N",
    "long": "0244721E"
  },
  {
    "stationcode": "MHQ",
    "stationname": "Mariehamn Airport",
    "lat": "600718N",
    "long": "0195351E"
  },
  {
    "stationcode": "OUL",
    "stationname": "Oulu Airport",
    "lat": "645548N",
    "long": "0252116E"
  },
  {
    "stationcode": "KEM",
    "stationname": "Kemi/Tornio Airport",
    "lat": "654643N",
    "long": "0243451E"
  },
  {
    "stationcode": "UTI",
    "stationname": "Utti Airport",
    "lat": "605347N",
    "long": "0265617E"
  },
  {
    "stationcode": "SOT",
    "stationname": "Sodankyla Airport",
    "lat": "672300N",
    "long": "0263700E"
  },
  {
    "stationcode": "TMP",
    "stationname": "Tampere-Pirkkala Airport",
    "lat": "612451N",
    "long": "0233616E"
  },
  {
    "stationcode": "IVL",
    "stationname": "Ivalo Airport",
    "lat": "683626N",
    "long": "0272419E"
  },
  {
    "stationcode": "YLI",
    "stationname": "Ylivieska Airport",
    "lat": "640316N",
    "long": "0244344E"
  },
  {
    "stationcode": "KAO",
    "stationname": "Kuusamo Airport",
    "lat": "655915N",
    "long": "0291422E"
  },
  {
    "stationcode": "POR",
    "stationname": "Pori Airport",
    "lat": "612742N",
    "long": "0214800E"
  },
  {
    "stationcode": "JOE",
    "stationname": "Joensuu Airport",
    "lat": "623946N",
    "long": "0293627E"
  },
  {
    "stationcode": "ENF",
    "stationname": "Enontekio Airport",
    "lat": "682145N",
    "long": "0232528E"
  },
  {
    "stationcode": "JYV",
    "stationname": "Jyvaskyla Airport",
    "lat": "622357N",
    "long": "0254055E"
  },
  {
    "stationcode": "KUO",
    "stationname": "Kuopio Airport",
    "lat": "630020N",
    "long": "0274754E"
  },
  {
    "stationcode": "HYV",
    "stationname": "Hyvinkaa Airport",
    "lat": "603915N",
    "long": "0245304E"
  },
  {
    "stationcode": "KTT",
    "stationname": "Kittila Airport",
    "lat": "674204N",
    "long": "0245049E"
  },
  {
    "stationcode": "KAU",
    "stationname": "Kauhava Airport",
    "lat": "630600N",
    "long": "0230500E"
  },
  {
    "stationcode": "RVN",
    "stationname": "Rovaniemi Airport",
    "lat": "663353N",
    "long": "0254949E"
  },
  {
    "stationcode": "KOK",
    "stationname": "Kruunupyy Airport",
    "lat": "634316N",
    "long": "0230835E"
  },
  {
    "stationcode": "VAA",
    "stationname": "Vaasa Airport",
    "lat": "630242N",
    "long": "0214541E"
  },
  {
    "stationcode": "KHJ",
    "stationname": "Kauhajoki Airport",
    "lat": "622747N",
    "long": "0222341E"
  },
  {
    "stationcode": "KTQ",
    "stationname": "Kitee Airport",
    "lat": "620957N",
    "long": "0300436E"
  },
  {
    "stationcode": "SVL",
    "stationname": "Savonlinna Airport",
    "lat": "615700N",
    "long": "0285700E"
  },
  {
    "stationcode": "VRK",
    "stationname": "Varkaus Airport",
    "lat": "621021N",
    "long": "0275154E"
  },
  {
    "stationcode": "HEM",
    "stationname": "Helsinke-Malmi Airport",
    "lat": "601513N",
    "long": "0250205E"
  },
  {
    "stationcode": "SJY",
    "stationname": "Ilmajoki Airport",
    "lat": "624137N",
    "long": "0224955E"
  },
  {
    "stationcode": "TKU",
    "stationname": "Turku Airport",
    "lat": "603051N",
    "long": "0221614E"
  },
  {
    "stationcode": "LPP",
    "stationname": "Lappeenranta Airport",
    "lat": "610240N",
    "long": "0280840E"
  },
  {
    "stationcode": "ONU",
    "stationname": "Ono I Lau Airport",
    "lat": "203900S",
    "long": "1784200W"
  },
  {
    "stationcode": "RTA",
    "stationname": "Rotuma Island Airport",
    "lat": "122858S",
    "long": "1770418E"
  },
  {
    "stationcode": "CST",
    "stationname": "Castaway Airport",
    "lat": "173710S",
    "long": "1770720E"
  },
  {
    "stationcode": "LKB",
    "stationname": "Lakeba Airport",
    "lat": "181157S",
    "long": "1784901W"
  },
  {
    "stationcode": "SUV",
    "stationname": "Nausori Airport",
    "lat": "180241S",
    "long": "1783347E"
  },
  {
    "stationcode": "SVU",
    "stationname": "Savusavu Airport",
    "lat": "164757S",
    "long": "1792020E"
  },
  {
    "stationcode": "KXF",
    "stationname": "Koro Airport",
    "lat": "172045S",
    "long": "1792519E"
  },
  {
    "stationcode": "BFJ",
    "stationname": "Ba Airport",
    "lat": "271602N",
    "long": "1052817E"
  },
  {
    "stationcode": "KVU",
    "stationname": "Korolevu Airport",
    "lat": "181300S",
    "long": "1774400E"
  },
  {
    "stationcode": "YAS",
    "stationname": "Yasawa Island Airport",
    "lat": "164535S",
    "long": "1773246E"
  },
  {
    "stationcode": "NGI",
    "stationname": "Ngau Island Airport",
    "lat": "180656S",
    "long": "1792023E"
  },
  {
    "stationcode": "AQS",
    "stationname": "Saqani Airport",
    "lat": "164500S",
    "long": "1792100E"
  },
  {
    "stationcode": "VTF",
    "stationname": "Vatulele Airport",
    "lat": "183300S",
    "long": "1773700E"
  },
  {
    "stationcode": "LEV",
    "stationname": "Levuka Airfield",
    "lat": "174245S",
    "long": "1784541E"
  },
  {
    "stationcode": "NAN",
    "stationname": "Nadi International Airport",
    "lat": "174518S",
    "long": "1772637E"
  },
  {
    "stationcode": "PHR",
    "stationname": "Pacific Harbor Airport",
    "lat": "181524S",
    "long": "1780300E"
  },
  {
    "stationcode": "BVF",
    "stationname": "Dama Airport",
    "lat": "164800S",
    "long": "1783700E"
  },
  {
    "stationcode": "NTA",
    "stationname": "Natadola Airport",
    "lat": "180500S",
    "long": "1771900E"
  },
  {
    "stationcode": "LUC",
    "stationname": "Laucala Island Airport",
    "lat": "164459S",
    "long": "1794002W"
  },
  {
    "stationcode": "MNF",
    "stationname": "Mana Island Airstrip",
    "lat": "174022S",
    "long": "1770555E"
  },
  {
    "stationcode": "VBV",
    "stationname": "Vanuabalavu Airport",
    "lat": "171341S",
    "long": "1785704W"
  },
  {
    "stationcode": "KAY",
    "stationname": "Wakaya Island Airport",
    "lat": "173743S",
    "long": "1790036E"
  },
  {
    "stationcode": "VAU",
    "stationname": "Vatukoula Airport",
    "lat": "173000S",
    "long": "1775030E"
  },
  {
    "stationcode": "PTF",
    "stationname": "Malololailai Airport",
    "lat": "174642S",
    "long": "1771150E"
  },
  {
    "stationcode": "TVU",
    "stationname": "Matei Airport",
    "lat": "164127S",
    "long": "1795239W"
  },
  {
    "stationcode": "ICI",
    "stationname": "Cicia Airport",
    "lat": "174436S",
    "long": "1792032W"
  },
  {
    "stationcode": "TTL",
    "stationname": "Turtle Island Airport",
    "lat": "165730S",
    "long": "1772220E"
  },
  {
    "stationcode": "KDV",
    "stationname": "Kadavu Airport",
    "lat": "190330S",
    "long": "1780926E"
  },
  {
    "stationcode": "MFJ",
    "stationname": "Moala Airport",
    "lat": "183401S",
    "long": "1795710E"
  },
  {
    "stationcode": "RBI",
    "stationname": "Rabi Airport",
    "lat": "170200S",
    "long": "1785500E"
  },
  {
    "stationcode": "LBS",
    "stationname": "Labasa Airport",
    "lat": "162804S",
    "long": "1792023E"
  },
  {
    "stationcode": "BXL",
    "stationname": "Blue Lagoon Airport",
    "lat": "165701S",
    "long": "1772246E"
  },
  {
    "stationcode": "PSY",
    "stationname": "Port Stanley Airport",
    "lat": "514500S",
    "long": "0590000W"
  },
  {
    "stationcode": "MPN",
    "stationname": "Mount Pleasant Airport",
    "lat": "514922S",
    "long": "0582644W"
  },
  {
    "stationcode": "FAE",
    "stationname": "Vagar Airport",
    "lat": "620359N",
    "long": "0071735W"
  },
  {
    "stationcode": "SOZ",
    "stationname": "Solenzara Airport",
    "lat": "415535N",
    "long": "0092419E"
  },
  {
    "stationcode": "ETZ",
    "stationname": "Metz-Nancy-Lorraine Airport",
    "lat": "485842N",
    "long": "0061448E"
  },
  {
    "stationcode": "CSF",
    "stationname": "Creil Airport",
    "lat": "491513N",
    "long": "0023109E"
  },
  {
    "stationcode": "URO",
    "stationname": "Boos Airport",
    "lat": "492319N",
    "long": "0011115E"
  },
  {
    "stationcode": "XCR",
    "stationname": "Vatry Airport",
    "lat": "484624N",
    "long": "0041222E"
  },
  {
    "stationcode": "MFX",
    "stationname": "Meribel Airport",
    "lat": "452427N",
    "long": "0063439E"
  },
  {
    "stationcode": "LIL",
    "stationname": "Lesquin Airport",
    "lat": "503348N",
    "long": "0030513E"
  },
  {
    "stationcode": "TUF",
    "stationname": "St Symphorien Airport",
    "lat": "472555N",
    "long": "0004323E"
  },
  {
    "stationcode": "JLP",
    "stationname": "Juan Les Pins Airport",
    "lat": "433900N",
    "long": "0070600E"
  },
  {
    "stationcode": "AVN",
    "stationname": "Avignon-Caum Airport",
    "lat": "435424N",
    "long": "0045407E"
  },
  {
    "stationcode": "FSC",
    "stationname": "Sud Corse Airport",
    "lat": "413008N",
    "long": "0090548E"
  },
  {
    "stationcode": "BOR",
    "stationname": "Fontaine Airport",
    "lat": "473920N",
    "long": "0070042E"
  },
  {
    "stationcode": "BOU",
    "stationname": "Bourges Airport",
    "lat": "470339N",
    "long": "0022212E"
  },
  {
    "stationcode": "CFR",
    "stationname": "Carpiquet Airport",
    "lat": "491024N",
    "long": "0002700W"
  },
  {
    "stationcode": "LYN",
    "stationname": "Bron Airport",
    "lat": "454346N",
    "long": "0045620E"
  },
  {
    "stationcode": "PGF",
    "stationname": "Llabanere Airport",
    "lat": "424427N",
    "long": "0025211E"
  },
  {
    "stationcode": "MXN",
    "stationname": "Morlaix Airport",
    "lat": "483616N",
    "long": "0034853W"
  },
  {
    "stationcode": "FNI",
    "stationname": "Garons Airport",
    "lat": "434500N",
    "long": "0042500E"
  },
  {
    "stationcode": "VNE",
    "stationname": "Meucon Airport",
    "lat": "474309N",
    "long": "0024324W"
  },
  {
    "stationcode": "EPL",
    "stationname": "Mirecourt Airport",
    "lat": "481930N",
    "long": "0060400E"
  },
  {
    "stationcode": "SXB",
    "stationname": "Entzheim Airport",
    "lat": "483231N",
    "long": "0073804E"
  },
  {
    "stationcode": "NTE",
    "stationname": "Nantes Atlantique Airport",
    "lat": "470930N",
    "long": "0013635W"
  },
  {
    "stationcode": "SXD",
    "stationname": "Sophia Antipolis Airport",
    "lat": "433700N",
    "long": "0070300E"
  },
  {
    "stationcode": "QYW",
    "stationname": "Cannes Vieux Port Ferry Service",
    "lat": "433220N",
    "long": "0070240E"
  },
  {
    "stationcode": "LIG",
    "stationname": "Bellegarde Airport",
    "lat": "455139N",
    "long": "0011049E"
  },
  {
    "stationcode": "RYN",
    "stationname": "Medis Airport",
    "lat": "453752N",
    "long": "0005832W"
  },
  {
    "stationcode": "LPY",
    "stationname": "Loudes Airport",
    "lat": "450447N",
    "long": "0034548E"
  },
  {
    "stationcode": "CCF",
    "stationname": "Salvaza Airport",
    "lat": "431257N",
    "long": "0021831E"
  },
  {
    "stationcode": "POX",
    "stationname": "Cormeille En Vexin Airport",
    "lat": "490548N",
    "long": "0020227E"
  },
  {
    "stationcode": "DLE",
    "stationname": "Tavaux Airport",
    "lat": "470234N",
    "long": "0052606E"
  },
  {
    "stationcode": "VTL",
    "stationname": "Vittel Airport",
    "lat": "481326N",
    "long": "0055607E"
  },
  {
    "stationcode": "LEH",
    "stationname": "Octeville Airport",
    "lat": "493202N",
    "long": "0000517E"
  },
  {
    "stationcode": "TLN",
    "stationname": "Hyeres Airport",
    "lat": "430600N",
    "long": "0060900E"
  },
  {
    "stationcode": "CFE",
    "stationname": "Aulnat Airport",
    "lat": "454709N",
    "long": "0030945E"
  },
  {
    "stationcode": "AVF",
    "stationname": "Avoriaz Airport",
    "lat": "461200N",
    "long": "0064600E"
  },
  {
    "stationcode": "NIT",
    "stationname": "Niort Airport",
    "lat": "461848N",
    "long": "0002340W"
  },
  {
    "stationcode": "XPZ",
    "stationname": "Saint Tropez Harbour Ferry Service",
    "lat": "431700N",
    "long": "0063800E"
  },
  {
    "stationcode": "RNE",
    "stationname": "Renaison Airport",
    "lat": "460310N",
    "long": "0035959E"
  },
  {
    "stationcode": "CMF",
    "stationname": "Chambery Airport",
    "lat": "453821N",
    "long": "0055248E"
  },
  {
    "stationcode": "CEQ",
    "stationname": "Mandelieu Airport",
    "lat": "433247N",
    "long": "0065715E"
  },
  {
    "stationcode": "LDV",
    "stationname": "Landivisiau Airport",
    "lat": "483149N",
    "long": "0040906W"
  },
  {
    "stationcode": "LVA",
    "stationname": "Entrammes Airport",
    "lat": "480156N",
    "long": "0004434W"
  },
  {
    "stationcode": "MLH",
    "stationname": "EuroAirport French",
    "lat": "473603N",
    "long": "0073145E"
  },
  {
    "stationcode": "TGF",
    "stationname": "Tignes Airport",
    "lat": "452900N",
    "long": "0065400E"
  },
  {
    "stationcode": "AUF",
    "stationname": "Aeroport Auxerre Branches",
    "lat": "475047N",
    "long": "0032948E"
  },
  {
    "stationcode": "NVS",
    "stationname": "Nevers Airport",
    "lat": "470013N",
    "long": "0030639E"
  },
  {
    "stationcode": "XYT",
    "stationname": "Montaudran Airport",
    "lat": "344512N",
    "long": "1274457E"
  },
  {
    "stationcode": "VHY",
    "stationname": "Charmeil Airport",
    "lat": "461018N",
    "long": "0032415E"
  },
  {
    "stationcode": "CVF",
    "stationname": "Courchevel Airport",
    "lat": "452345N",
    "long": "0063757E"
  },
  {
    "stationcode": "DCM",
    "stationname": "Mazamet Airport",
    "lat": "433318N",
    "long": "0021726E"
  },
  {
    "stationcode": "LTT",
    "stationname": "La Mole Airport",
    "lat": "431218N",
    "long": "0062850E"
  },
  {
    "stationcode": "LBY",
    "stationname": "Montoir Airport",
    "lat": "471722N",
    "long": "0022047W"
  },
  {
    "stationcode": "PGX",
    "stationname": "Perigueux Airport",
    "lat": "451151N",
    "long": "0004855E"
  },
  {
    "stationcode": "BZR",
    "stationname": "Beziers Vias Airport",
    "lat": "431924N",
    "long": "0032112E"
  },
  {
    "stationcode": "CDG",
    "stationname": "Charles De Gaulle Airport",
    "lat": "490035N",
    "long": "0023252E"
  },
  {
    "stationcode": "BVA",
    "stationname": "Beauvais-Tille Airport",
    "lat": "492716N",
    "long": "0020646E"
  },
  {
    "stationcode": "NCE",
    "stationname": "Cote D'Azur Airport",
    "lat": "433955N",
    "long": "0071254E"
  },
  {
    "stationcode": "ANE",
    "stationname": "Arville Airport",
    "lat": "473337N",
    "long": "0001844W"
  },
  {
    "stationcode": "LBG",
    "stationname": "Le Bourget Airport",
    "lat": "485810N",
    "long": "0022629E"
  },
  {
    "stationcode": "BVE",
    "stationname": "Laroche Airport",
    "lat": "450906N",
    "long": "0012819E"
  },
  {
    "stationcode": "LTQ",
    "stationname": "Le Touquet Airport",
    "lat": "503053N",
    "long": "0013739E"
  },
  {
    "stationcode": "MCU",
    "stationname": "Gueret (Lepaud) Airport",
    "lat": "461334N",
    "long": "0022146E"
  },
  {
    "stationcode": "PLG",
    "stationname": "La Plagne Airport",
    "lat": "443800N",
    "long": "0023400E"
  },
  {
    "stationcode": "LAI",
    "stationname": "Servel Airport",
    "lat": "484518N",
    "long": "0032828W"
  },
  {
    "stationcode": "NCY",
    "stationname": "Annecy-Meythet Airport",
    "lat": "455551N",
    "long": "0060623E"
  },
  {
    "stationcode": "BOD",
    "stationname": "Bordeaux Airport",
    "lat": "444943N",
    "long": "0004255W"
  },
  {
    "stationcode": "BAE",
    "stationname": "Barcelonnette Airport",
    "lat": "442318N",
    "long": "0063637E"
  },
  {
    "stationcode": "FRJ",
    "stationname": "Frejus Airport",
    "lat": "432600N",
    "long": "0064400E"
  },
  {
    "stationcode": "CER",
    "stationname": "Maupertus Airport",
    "lat": "493903N",
    "long": "0012831W"
  },
  {
    "stationcode": "LYS",
    "stationname": "Lyon Satolas Airport",
    "lat": "454332N",
    "long": "0050454E"
  },
  {
    "stationcode": "LDE",
    "stationname": "Tarbes Ossun Lourdes Airport",
    "lat": "431055N",
    "long": "0000013W"
  },
  {
    "stationcode": "SYT",
    "stationname": "Charolais Bourgogne Sud Airport",
    "lat": "462423N",
    "long": "0040116E"
  },
  {
    "stationcode": "DXA",
    "stationname": "Deux Alpes Airport",
    "lat": "445900N",
    "long": "0060900E"
  },
  {
    "stationcode": "MPL",
    "stationname": "Mediterranee Airport",
    "lat": "433451N",
    "long": "0035803E"
  },
  {
    "stationcode": "LRH",
    "stationname": "Laleu Airport",
    "lat": "461045N",
    "long": "0011143W"
  },
  {
    "stationcode": "CQF",
    "stationname": "Calais Airport",
    "lat": "505744N",
    "long": "0015719E"
  },
  {
    "stationcode": "VIY",
    "stationname": "Villa Coublay Airport",
    "lat": "484627N",
    "long": "0021130E"
  },
  {
    "stationcode": "RNS",
    "stationname": "St Jacques Airport",
    "lat": "480407N",
    "long": "0014353W"
  },
  {
    "stationcode": "XGB",
    "stationname": "Gare Montparnasse Airport",
    "lat": "490100N",
    "long": "0023355E"
  },
  {
    "stationcode": "AJA",
    "stationname": "Campo Dell Oro Airport",
    "lat": "415526N",
    "long": "0084809E"
  },
  {
    "stationcode": "DPE",
    "stationname": "Dieppe Airport",
    "lat": "495257N",
    "long": "0010507E"
  },
  {
    "stationcode": "MEN",
    "stationname": "Brenoux Airport",
    "lat": "443015N",
    "long": "0033139E"
  },
  {
    "stationcode": "EDM",
    "stationname": "Les Ajoncs Airport",
    "lat": "464209N",
    "long": "0012254W"
  },
  {
    "stationcode": "DIJ",
    "stationname": "Dijon Airport",
    "lat": "471636N",
    "long": "0050537E"
  },
  {
    "stationcode": "CMR",
    "stationname": "Colmar-Houssen Airport",
    "lat": "480637N",
    "long": "0072133E"
  },
  {
    "stationcode": "MRS",
    "stationname": "Marseille Airport",
    "lat": "432608N",
    "long": "0051259E"
  },
  {
    "stationcode": "SBK",
    "stationname": "Tremuson Airport",
    "lat": "483215N",
    "long": "0025124W"
  },
  {
    "stationcode": "AUR",
    "stationname": "Aurillac Airport",
    "lat": "445351N",
    "long": "0022500E"
  },
  {
    "stationcode": "UIP",
    "stationname": "Pluguffan Airport",
    "lat": "475828N",
    "long": "0041015W"
  },
  {
    "stationcode": "GNB",
    "stationname": "Saint Geoirs Airport",
    "lat": "452147N",
    "long": "0051958E"
  },
  {
    "stationcode": "ORE",
    "stationname": "Orleans Airport",
    "lat": "475351N",
    "long": "0020951E"
  },
  {
    "stationcode": "TNF",
    "stationname": "Toussus-le-Noble Airport",
    "lat": "484459N",
    "long": "0020640E"
  },
  {
    "stationcode": "MZM",
    "stationname": "Frescaty Airport",
    "lat": "490435N",
    "long": "0060802E"
  },
  {
    "stationcode": "GAT",
    "stationname": "Tallard Airport",
    "lat": "442718N",
    "long": "0060216E"
  },
  {
    "stationcode": "RHE",
    "stationname": "Reims Airport",
    "lat": "491837N",
    "long": "0035938E"
  },
  {
    "stationcode": "HZB",
    "stationname": "Merville/Calonne Airport",
    "lat": "503700N",
    "long": "0023824E"
  },
  {
    "stationcode": "LME",
    "stationname": "Arnage Airport",
    "lat": "475654N",
    "long": "0001206E"
  },
  {
    "stationcode": "CHR",
    "stationname": "Chateauroux Airport",
    "lat": "465137N",
    "long": "0014316E"
  },
  {
    "stationcode": "IDY",
    "stationname": "Ile 'd' Yeu Airport",
    "lat": "464307N",
    "long": "0022328W"
  },
  {
    "stationcode": "SCP",
    "stationname": "Saint Crepin Airport",
    "lat": "444206N",
    "long": "0063601E"
  },
  {
    "stationcode": "LBI",
    "stationname": "Le Sequestre Airport",
    "lat": "435448N",
    "long": "0020700E"
  },
  {
    "stationcode": "SEC",
    "stationname": "Serre Chevalier Airport",
    "lat": "445500N",
    "long": "0063000E"
  },
  {
    "stationcode": "DNR",
    "stationname": "Pleurtuit Airport",
    "lat": "483516N",
    "long": "0020448W"
  },
  {
    "stationcode": "CET",
    "stationname": "Le Pontreau Airport",
    "lat": "470455N",
    "long": "0005238W"
  },
  {
    "stationcode": "LRT",
    "stationname": "Lann Bihoue Airport",
    "lat": "474538N",
    "long": "0032624W"
  },
  {
    "stationcode": "DOL",
    "stationname": "Saint Gatien Airport",
    "lat": "492148N",
    "long": "0000936E"
  },
  {
    "stationcode": "SNR",
    "stationname": "Montoir Airport",
    "lat": "471838N",
    "long": "0020924W"
  },
  {
    "stationcode": "XEX",
    "stationname": "Aerogare des Invalides",
    "lat": "485000N",
    "long": "0023900E"
  },
  {
    "stationcode": "RDZ",
    "stationname": "Marcillac Airport",
    "lat": "442200N",
    "long": "0023100E"
  },
  {
    "stationcode": "PRP",
    "stationname": "Propriano Airport",
    "lat": "413941N",
    "long": "0085342E"
  },
  {
    "stationcode": "BIA",
    "stationname": "Poretta Airport",
    "lat": "423257N",
    "long": "0092910E"
  },
  {
    "stationcode": "ENC",
    "stationname": "Essey Airport",
    "lat": "484132N",
    "long": "0061334E"
  },
  {
    "stationcode": "CTT",
    "stationname": "Le Castellet Airport",
    "lat": "431508N",
    "long": "0054710E"
  },
  {
    "stationcode": "AGF",
    "stationname": "La Garenne Airport",
    "lat": "441029N",
    "long": "0003526E"
  },
  {
    "stationcode": "EBU",
    "stationname": "Boutheon Airport",
    "lat": "453229N",
    "long": "0041749E"
  },
  {
    "stationcode": "EVX",
    "stationname": "Evreux Airport",
    "lat": "490143N",
    "long": "0011312E"
  },
  {
    "stationcode": "AHZ",
    "stationname": "Alpe D Huez Airport",
    "lat": "450518N",
    "long": "0060505E"
  },
  {
    "stationcode": "CNG",
    "stationname": "Parvaud Airport",
    "lat": "453930N",
    "long": "0001903W"
  },
  {
    "stationcode": "OBS",
    "stationname": "Vals-Lanas Airport",
    "lat": "443222N",
    "long": "0042218E"
  },
  {
    "stationcode": "BIQ",
    "stationname": "Biarritz Parme Airport",
    "lat": "432806N",
    "long": "0013152W"
  },
  {
    "stationcode": "ANG",
    "stationname": "Brie-Champniers Airport",
    "lat": "454346N",
    "long": "0001309E"
  },
  {
    "stationcode": "VAZ",
    "stationname": "Val D'Isere Airport",
    "lat": "452700N",
    "long": "0065900E"
  },
  {
    "stationcode": "PIS",
    "stationname": "Biard Airport",
    "lat": "463515N",
    "long": "0001824E"
  },
  {
    "stationcode": "LSO",
    "stationname": "Talmont Airport",
    "lat": "462837N",
    "long": "0014322W"
  },
  {
    "stationcode": "MVV",
    "stationname": "Megeve Airport",
    "lat": "454915N",
    "long": "0063908E"
  },
  {
    "stationcode": "VAF",
    "stationname": "Chabeuil Airport",
    "lat": "445513N",
    "long": "0045813E"
  },
  {
    "stationcode": "CLY",
    "stationname": "Sainte Catherine Airport",
    "lat": "423113N",
    "long": "0084735E"
  },
  {
    "stationcode": "BES",
    "stationname": "Guipavas Airport",
    "lat": "482650N",
    "long": "0042518W"
  },
  {
    "stationcode": "DLP",
    "stationname": "Disneyland Paris Airport",
    "lat": "485200N",
    "long": "0024800E"
  },
  {
    "stationcode": "GFR",
    "stationname": "Granville Airport",
    "lat": "485258N",
    "long": "0013350W"
  },
  {
    "stationcode": "ZAO",
    "stationname": "Laberandie Airport",
    "lat": "442102N",
    "long": "0012843E"
  },
  {
    "stationcode": "EGC",
    "stationname": "Roumanieres Airport",
    "lat": "444928N",
    "long": "0003114E"
  },
  {
    "stationcode": "RCO",
    "stationname": "Saint Agnant Airport",
    "lat": "455322N",
    "long": "0005857W"
  },
  {
    "stationcode": "PUF",
    "stationname": "Uzein Airport",
    "lat": "432248N",
    "long": "0002507W"
  },
  {
    "stationcode": "GKO",
    "stationname": "Kongoboumba Airport",
    "lat": "000500S",
    "long": "0112800E"
  },
  {
    "stationcode": "OKN",
    "stationname": "Okondja Airport",
    "lat": "004000S",
    "long": "0134000E"
  },
  {
    "stationcode": "BMM",
    "stationname": "Bitam Airport",
    "lat": "020500N",
    "long": "0112900E"
  },
  {
    "stationcode": "LBQ",
    "stationname": "Lambarene Airport",
    "lat": "004300S",
    "long": "0101400E"
  },
  {
    "stationcode": "AKE",
    "stationname": "Akieni Airport",
    "lat": "011020S",
    "long": "0135500E"
  },
  {
    "stationcode": "KDN",
    "stationname": "Ndende Airport",
    "lat": "022200S",
    "long": "0112500E"
  },
  {
    "stationcode": "MKU",
    "stationname": "Makokou Airport",
    "lat": "003445N",
    "long": "0125327E"
  },
  {
    "stationcode": "MDV",
    "stationname": "Medouneu Airport",
    "lat": "010100N",
    "long": "0104500E"
  },
  {
    "stationcode": "MBC",
    "stationname": "Mbigou Airport",
    "lat": "015300S",
    "long": "0115600E"
  },
  {
    "stationcode": "LEO",
    "stationname": "Leconi Airport",
    "lat": "014100S",
    "long": "0141800E"
  },
  {
    "stationcode": "ZKM",
    "stationname": "Sette Cama Airport",
    "lat": "023200S",
    "long": "0094500E"
  },
  {
    "stationcode": "OYE",
    "stationname": "Oyem Airport",
    "lat": "013235N",
    "long": "0113453E"
  },
  {
    "stationcode": "MVX",
    "stationname": "Minvoul Airport",
    "lat": "020900N",
    "long": "0120800E"
  },
  {
    "stationcode": "OWE",
    "stationname": "Owendo Airport",
    "lat": "001700N",
    "long": "0093000E"
  },
  {
    "stationcode": "MVB",
    "stationname": "Franceville/Mvengue Airport",
    "lat": "013922S",
    "long": "0132617E"
  },
  {
    "stationcode": "MYB",
    "stationname": "Mayoumba Airport",
    "lat": "032700S",
    "long": "0104100E"
  },
  {
    "stationcode": "POG",
    "stationname": "Port Gentil Airport",
    "lat": "004242S",
    "long": "0084516E"
  },
  {
    "stationcode": "MJL",
    "stationname": "Mouila Airport",
    "lat": "015043S",
    "long": "0110324E"
  },
  {
    "stationcode": "AWE",
    "stationname": "Alowe Airport",
    "lat": "004500S",
    "long": "0092700E"
  },
  {
    "stationcode": "NKA",
    "stationname": "Nkan Airport",
    "lat": "004200N",
    "long": "0095900E"
  },
  {
    "stationcode": "LTL",
    "stationname": "Lastourville Airport",
    "lat": "005000S",
    "long": "0124500E"
  },
  {
    "stationcode": "OMB",
    "stationname": "Omboue Airport",
    "lat": "013429S",
    "long": "0091546E"
  },
  {
    "stationcode": "IGE",
    "stationname": "Iguela Airport",
    "lat": "015500S",
    "long": "0091900E"
  },
  {
    "stationcode": "KDJ",
    "stationname": "Ndjole Airport",
    "lat": "001500S",
    "long": "0104500E"
  },
  {
    "stationcode": "BGB",
    "stationname": "Booue Airport",
    "lat": "000600S",
    "long": "0115600E"
  },
  {
    "stationcode": "LBV",
    "stationname": "Libreville Airport",
    "lat": "002733N",
    "long": "0092455E"
  },
  {
    "stationcode": "KOU",
    "stationname": "Koulamoutou Airport",
    "lat": "011105S",
    "long": "0122629E"
  },
  {
    "stationcode": "WGY",
    "stationname": "Wagny Airport",
    "lat": "003100N",
    "long": "0102600E"
  },
  {
    "stationcode": "TCH",
    "stationname": "Tchibanga Airport",
    "lat": "024818S",
    "long": "0105719E"
  },
  {
    "stationcode": "GIM",
    "stationname": "Miele Mimbale Airport",
    "lat": "000100S",
    "long": "0112400E"
  },
  {
    "stationcode": "BGP",
    "stationname": "Bongo Airport",
    "lat": "021000S",
    "long": "0101300E"
  },
  {
    "stationcode": "MFF",
    "stationname": "Moanda Airport",
    "lat": "013213S",
    "long": "0131615E"
  },
  {
    "stationcode": "OUU",
    "stationname": "Ouanga Airport",
    "lat": "025900S",
    "long": "0101800E"
  },
  {
    "stationcode": "KMD",
    "stationname": "Mandji Airport",
    "lat": "014200S",
    "long": "0102300E"
  },
  {
    "stationcode": "MGX",
    "stationname": "Moabi Airport",
    "lat": "022600S",
    "long": "0110000E"
  },
  {
    "stationcode": "MVG",
    "stationname": "Mevang Airport",
    "lat": "000500N",
    "long": "0110500E"
  },
  {
    "stationcode": "FOU",
    "stationname": "Fougamou Airport",
    "lat": "011700S",
    "long": "0103700E"
  },
  {
    "stationcode": "MKB",
    "stationname": "Mekambo Airport",
    "lat": "005800N",
    "long": "0135700E"
  },
  {
    "stationcode": "BAW",
    "stationname": "Biawonque Airport",
    "lat": "004000S",
    "long": "0092700E"
  },
  {
    "stationcode": "MZC",
    "stationname": "Mitzic Airport",
    "lat": "004700N",
    "long": "0113400E"
  },
  {
    "stationcode": "MGO",
    "stationname": "Manega Airport",
    "lat": "014400S",
    "long": "0101300E"
  },
  {
    "stationcode": "GAX",
    "stationname": "Gamba Airport",
    "lat": "024514S",
    "long": "0100025E"
  },
  {
    "stationcode": "PZE",
    "stationname": "Penzance Airport",
    "lat": "500741N",
    "long": "0053107W"
  },
  {
    "stationcode": "ENK",
    "stationname": "Saint Angelo Airport",
    "lat": "542355N",
    "long": "0073907W"
  },
  {
    "stationcode": "GLO",
    "stationname": "Gloucestershire Airport",
    "lat": "515338N",
    "long": "0021001W"
  },
  {
    "stationcode": "WRY",
    "stationname": "Westray Airport",
    "lat": "592101N",
    "long": "0025700W"
  },
  {
    "stationcode": "CAX",
    "stationname": "Carlisle Airport",
    "lat": "545615N",
    "long": "0024833W"
  },
  {
    "stationcode": "NQT",
    "stationname": "Nottingham Airport",
    "lat": "525512N",
    "long": "0010445W"
  },
  {
    "stationcode": "NHT",
    "stationname": "Northolt Airport",
    "lat": "513311N",
    "long": "0002506W"
  },
  {
    "stationcode": "ULL",
    "stationname": "Mull Airport",
    "lat": "562500N",
    "long": "0062000W"
  },
  {
    "stationcode": "NDY",
    "stationname": "Sanday Airport",
    "lat": "591501N",
    "long": "0023430W"
  },
  {
    "stationcode": "PIK",
    "stationname": "Prestwick Airport",
    "lat": "553034N",
    "long": "0043512W"
  },
  {
    "stationcode": "PSL",
    "stationname": "Perth International Airport",
    "lat": "562621N",
    "long": "0032220W"
  },
  {
    "stationcode": "WEM",
    "stationname": "West Malling Airport",
    "lat": "511800N",
    "long": "0002500E"
  },
  {
    "stationcode": "BRF",
    "stationname": "Bradford Airport",
    "lat": "534810N",
    "long": "0014500W"
  },
  {
    "stationcode": "BBP",
    "stationname": "Bembridge Airport",
    "lat": "504041N",
    "long": "0010633W"
  },
  {
    "stationcode": "FEA",
    "stationname": "Fetlar Airport",
    "lat": "603500N",
    "long": "0005800W"
  },
  {
    "stationcode": "GSY",
    "stationname": "Binbrook Airport",
    "lat": "533500N",
    "long": "0000200W"
  },
  {
    "stationcode": "IPW",
    "stationname": "Ipswich Airport",
    "lat": "520200N",
    "long": "0011200E"
  },
  {
    "stationcode": "EDI",
    "stationname": "Edinburgh Airport",
    "lat": "555700N",
    "long": "0032221W"
  },
  {
    "stationcode": "BWF",
    "stationname": "Walney Island Airport",
    "lat": "540700N",
    "long": "0031400W"
  },
  {
    "stationcode": "IOM",
    "stationname": "Ronaldsway Airport",
    "lat": "540500N",
    "long": "0043727W"
  },
  {
    "stationcode": "LSI",
    "stationname": "Sumburgh Airport",
    "lat": "595244N",
    "long": "0011744W"
  },
  {
    "stationcode": "LWK",
    "stationname": "Lerwick/Tingwall Airport",
    "lat": "601132N",
    "long": "0011437W"
  },
  {
    "stationcode": "FIE",
    "stationname": "Fair Isle Airport",
    "lat": "593205N",
    "long": "0013743W"
  },
  {
    "stationcode": "RCS",
    "stationname": "Rochester Airport",
    "lat": "512107N",
    "long": "0003012E"
  },
  {
    "stationcode": "BEQ",
    "stationname": "Honington Airport",
    "lat": "522030N",
    "long": "0004626E"
  },
  {
    "stationcode": "BOL",
    "stationname": "Bally Kelly Airport",
    "lat": "550400N",
    "long": "0070100W"
  },
  {
    "stationcode": "CAL",
    "stationname": "Machrihanish Airport",
    "lat": "552614N",
    "long": "0054111W"
  },
  {
    "stationcode": "LBA",
    "stationname": "Leeds/Bradford Airport",
    "lat": "535157N",
    "long": "0013938W"
  },
  {
    "stationcode": "PME",
    "stationname": "Portsmouth Airport",
    "lat": "505000N",
    "long": "0010300W"
  },
  {
    "stationcode": "QUG",
    "stationname": "Goodwood Airport",
    "lat": "505133N",
    "long": "0004533W"
  },
  {
    "stationcode": "NWI",
    "stationname": "Norwich Airport",
    "lat": "524100N",
    "long": "0011700E"
  },
  {
    "stationcode": "HUY",
    "stationname": "Humberside Airport",
    "lat": "533428N",
    "long": "0002103W"
  },
  {
    "stationcode": "MSE",
    "stationname": "Kent International Airport",
    "lat": "512032N",
    "long": "0012046E"
  },
  {
    "stationcode": "FLH",
    "stationname": "Flotta Airport",
    "lat": "585000N",
    "long": "0030900W"
  },
  {
    "stationcode": "BLK",
    "stationname": "Blackpool Airport",
    "lat": "534617N",
    "long": "0030142W"
  },
  {
    "stationcode": "BQH",
    "stationname": "Biggin Hill Airport",
    "lat": "511951N",
    "long": "0000156E"
  },
  {
    "stationcode": "HOY",
    "stationname": "Hoy Island Airport",
    "lat": "585000N",
    "long": "0031800W"
  },
  {
    "stationcode": "GCI",
    "stationname": "Guernsey Airport",
    "lat": "492605N",
    "long": "0023607W"
  },
  {
    "stationcode": "FZO",
    "stationname": "Filton Airport",
    "lat": "513110N",
    "long": "0023525W"
  },
  {
    "stationcode": "VLY",
    "stationname": "Anglesey Airport",
    "lat": "531454N",
    "long": "0043207W"
  },
  {
    "stationcode": "BHX",
    "stationname": "Birmingham International Airport",
    "lat": "522713N",
    "long": "0014452W"
  },
  {
    "stationcode": "SZD",
    "stationname": "Sheffield City Airport",
    "lat": "532358N",
    "long": "0012323W"
  },
  {
    "stationcode": "BRR",
    "stationname": "North Bay Airport",
    "lat": "570132N",
    "long": "0072659W"
  },
  {
    "stationcode": "LDY",
    "stationname": "Eglinton Airport",
    "lat": "550236N",
    "long": "0070912W"
  },
  {
    "stationcode": "EMA",
    "stationname": "Nottingham East Midlands Airport",
    "lat": "524952N",
    "long": "0011941W"
  },
  {
    "stationcode": "HYC",
    "stationname": "High Wycombe Airport",
    "lat": "513800N",
    "long": "0004600W"
  },
  {
    "stationcode": "LEQ",
    "stationname": "Lands End Airport",
    "lat": "500610N",
    "long": "0054014W"
  },
  {
    "stationcode": "SKL",
    "stationname": "Broadford Airport",
    "lat": "572000N",
    "long": "0061500W"
  },
  {
    "stationcode": "PSV",
    "stationname": "Papa Stour Airport",
    "lat": "601919N",
    "long": "0014136W"
  },
  {
    "stationcode": "FSS",
    "stationname": "Kinloss Airport",
    "lat": "573900N",
    "long": "0033400W"
  },
  {
    "stationcode": "FOA",
    "stationname": "Foula Airport",
    "lat": "600720N",
    "long": "0020313W"
  },
  {
    "stationcode": "PLH",
    "stationname": "Plymouth Airport",
    "lat": "502522N",
    "long": "0040621W"
  },
  {
    "stationcode": "TRE",
    "stationname": "Tiree Airport",
    "lat": "562957N",
    "long": "0065209W"
  },
  {
    "stationcode": "ACI",
    "stationname": "The Blaye Airport",
    "lat": "494225N",
    "long": "0021256W"
  },
  {
    "stationcode": "SOU",
    "stationname": "Eastleigh Airport",
    "lat": "505706N",
    "long": "0012107W"
  },
  {
    "stationcode": "WHS",
    "stationname": "Whalsay Airport",
    "lat": "602200N",
    "long": "0005900W"
  },
  {
    "stationcode": "OBN",
    "stationname": "Connel Airport",
    "lat": "562800N",
    "long": "0052400W"
  },
  {
    "stationcode": "TSO",
    "stationname": "Tresco Airport",
    "lat": "495644N",
    "long": "0061953W"
  },
  {
    "stationcode": "CVT",
    "stationname": "Baginton Airport",
    "lat": "522211N",
    "long": "0012847W"
  },
  {
    "stationcode": "ORM",
    "stationname": "Northampton Airport",
    "lat": "521819N",
    "long": "0004734W"
  },
  {
    "stationcode": "ISC",
    "stationname": "St. Mary's Airport",
    "lat": "495448N",
    "long": "0061730W"
  },
  {
    "stationcode": "NCL",
    "stationname": "Newcastle Airport",
    "lat": "550215N",
    "long": "0014130W"
  },
  {
    "stationcode": "NQY",
    "stationname": "Newquay Cornwall Airport",
    "lat": "502636N",
    "long": "0050006W"
  },
  {
    "stationcode": "CRN",
    "stationname": "Cromarty Airport",
    "lat": "574000N",
    "long": "0040200W"
  },
  {
    "stationcode": "WRT",
    "stationname": "Warton Airport",
    "lat": "534442N",
    "long": "0025300W"
  },
  {
    "stationcode": "BHD",
    "stationname": "Belfast City Airport",
    "lat": "543705N",
    "long": "0055221W"
  },
  {
    "stationcode": "DSA",
    "stationname": "Doncaster Sheffield Airport",
    "lat": "532831N",
    "long": "0010014W"
  },
  {
    "stationcode": "UNT",
    "stationname": "Baltasound Airport",
    "lat": "604500N",
    "long": "0005100W"
  },
  {
    "stationcode": "OXF",
    "stationname": "Kidlington Airport",
    "lat": "515013N",
    "long": "0011912W"
  },
  {
    "stationcode": "MHZ",
    "stationname": "Mildenhall Airport",
    "lat": "522100N",
    "long": "0003000E"
  },
  {
    "stationcode": "NRL",
    "stationname": "North Ronaldsay Airport",
    "lat": "592203N",
    "long": "0022604W"
  },
  {
    "stationcode": "SWS",
    "stationname": "Fairwood Comm Airport",
    "lat": "513619N",
    "long": "0040404W"
  },
  {
    "stationcode": "BSH",
    "stationname": "Brighton Airport",
    "lat": "504945N",
    "long": "0000827W"
  },
  {
    "stationcode": "SCS",
    "stationname": "Scatsta Airport",
    "lat": "602600N",
    "long": "0011700W"
  },
  {
    "stationcode": "WIC",
    "stationname": "Wick Airport",
    "lat": "582732N",
    "long": "0030535W"
  },
  {
    "stationcode": "DND",
    "stationname": "Dundee Airport",
    "lat": "562708N",
    "long": "0030132W"
  },
  {
    "stationcode": "HEN",
    "stationname": "Hendon Airport",
    "lat": "600852N",
    "long": "0245528E"
  },
  {
    "stationcode": "LPL",
    "stationname": "Liverpool John Lennon Airport",
    "lat": "532001N",
    "long": "0025059W"
  },
  {
    "stationcode": "JER",
    "stationname": "States Airport",
    "lat": "491228N",
    "long": "0021143W"
  },
  {
    "stationcode": "CSA",
    "stationname": "Colonsay Island Airport",
    "lat": "560328N",
    "long": "0061435W"
  },
  {
    "stationcode": "KYN",
    "stationname": "Milton Keynes Airport",
    "lat": "520203N",
    "long": "0004628W"
  },
  {
    "stationcode": "WFD",
    "stationname": "Woodford Airport",
    "lat": "532016N",
    "long": "0020856W"
  },
  {
    "stationcode": "HRT",
    "stationname": "Linton-On-Ouse Airport",
    "lat": "540300N",
    "long": "0011500W"
  },
  {
    "stationcode": "HLY",
    "stationname": "Holyhead Airport",
    "lat": "531900N",
    "long": "0043800W"
  },
  {
    "stationcode": "ILY",
    "stationname": "Glenegedale Airport",
    "lat": "554055N",
    "long": "0061524W"
  },
  {
    "stationcode": "YEO",
    "stationname": "Yeovilton Airport",
    "lat": "510100N",
    "long": "0023800W"
  },
  {
    "stationcode": "BBS",
    "stationname": "Blackbush Airport",
    "lat": "511926N",
    "long": "0005051W"
  },
  {
    "stationcode": "CEG",
    "stationname": "Chester Airport",
    "lat": "531041N",
    "long": "0025840W"
  },
  {
    "stationcode": "ADX",
    "stationname": "Leuchars Airport",
    "lat": "562200N",
    "long": "0025200W"
  },
  {
    "stationcode": "EXT",
    "stationname": "Exeter Airport",
    "lat": "504404N",
    "long": "0032450W"
  },
  {
    "stationcode": "SYY",
    "stationname": "Stornoway Airport",
    "lat": "581258N",
    "long": "0061945W"
  },
  {
    "stationcode": "KOI",
    "stationname": "Kirkwall Airport",
    "lat": "585728N",
    "long": "0025419W"
  },
  {
    "stationcode": "EOI",
    "stationname": "Eday Airport",
    "lat": "591126N",
    "long": "0024620W"
  },
  {
    "stationcode": "LYX",
    "stationname": "London Ashford Airport",
    "lat": "505722N",
    "long": "0005621E"
  },
  {
    "stationcode": "LCY",
    "stationname": "London City Airport",
    "lat": "513018N",
    "long": "0000315E"
  },
  {
    "stationcode": "SOY",
    "stationname": "Stronsay Airport",
    "lat": "590919N",
    "long": "0023831W"
  },
  {
    "stationcode": "UPV",
    "stationname": "Upavon Airport",
    "lat": "511800N",
    "long": "0014900W"
  },
  {
    "stationcode": "SEN",
    "stationname": "Southend Municipal Airport",
    "lat": "513417N",
    "long": "0004144E"
  },
  {
    "stationcode": "COL",
    "stationname": "Coll Island Airport",
    "lat": "563607N",
    "long": "0063704W"
  },
  {
    "stationcode": "CWL",
    "stationname": "Cardiff Airport",
    "lat": "512348N",
    "long": "0032036W"
  },
  {
    "stationcode": "DOC",
    "stationname": "Dornoch Airport",
    "lat": "575300N",
    "long": "0040500W"
  },
  {
    "stationcode": "CBG",
    "stationname": "Cambridge Airport",
    "lat": "521200N",
    "long": "0001100E"
  },
  {
    "stationcode": "LTN",
    "stationname": "Luton Airport",
    "lat": "515229N",
    "long": "0002206W"
  },
  {
    "stationcode": "BRS",
    "stationname": "Bristol Airport",
    "lat": "512257N",
    "long": "0024308W"
  },
  {
    "stationcode": "BOH",
    "stationname": "Bournemouth International Airport",
    "lat": "504648N",
    "long": "0015033W"
  },
  {
    "stationcode": "OUK",
    "stationname": "Outer Skerries Airport",
    "lat": "602531N",
    "long": "0004457W"
  },
  {
    "stationcode": "LHR",
    "stationname": "Heathrow Airport",
    "lat": "512839N",
    "long": "0002741W"
  },
  {
    "stationcode": "MAN",
    "stationname": "Manchester International Airport",
    "lat": "532113N",
    "long": "0021630W"
  },
  {
    "stationcode": "BEB",
    "stationname": "Benbecula Airport",
    "lat": "572839N",
    "long": "0072159W"
  },
  {
    "stationcode": "ABZ",
    "stationname": "Dyce Airport",
    "lat": "571215N",
    "long": "0021201W"
  },
  {
    "stationcode": "HAW",
    "stationname": "Haverfordwest Airport",
    "lat": "514959N",
    "long": "0045740W"
  },
  {
    "stationcode": "HTF",
    "stationname": "Hatfield Airport",
    "lat": "514554N",
    "long": "0001454W"
  },
  {
    "stationcode": "ESH",
    "stationname": "Shoreham Airport",
    "lat": "505008N",
    "long": "0001750W"
  },
  {
    "stationcode": "GLA",
    "stationname": "Glasgow International Airport",
    "lat": "555219N",
    "long": "0042600W"
  },
  {
    "stationcode": "BFS",
    "stationname": "Belfast International Airport",
    "lat": "543927N",
    "long": "0061257W"
  },
  {
    "stationcode": "PPW",
    "stationname": "Papa Westray Airport",
    "lat": "592105N",
    "long": "0025400W"
  },
  {
    "stationcode": "MME",
    "stationname": "Durham Tees Valley Airport",
    "lat": "543100N",
    "long": "0012500W"
  },
  {
    "stationcode": "ADV",
    "stationname": "Andover Airport",
    "lat": "112408N",
    "long": "0260707E"
  },
  {
    "stationcode": "INV",
    "stationname": "Inverness Airport",
    "lat": "573224N",
    "long": "0040300W"
  },
  {
    "stationcode": "FAB",
    "stationname": "Farnborough Airport",
    "lat": "511633N",
    "long": "0004635W"
  },
  {
    "stationcode": "BWY",
    "stationname": "Bentwaters Street Airport",
    "lat": "520600N",
    "long": "0011900E"
  },
  {
    "stationcode": "BUT",
    "stationname": "Burtonwood Airport",
    "lat": "273347N",
    "long": "0904448E"
  },
  {
    "stationcode": "GND",
    "stationname": "Point Saline International Airport",
    "lat": "120015N",
    "long": "0614710W"
  },
  {
    "stationcode": "CRU",
    "stationname": "Carriacou Island Airport",
    "lat": "122836N",
    "long": "0612819W"
  },
  {
    "stationcode": "KUT",
    "stationname": "Kutaisi Airport",
    "lat": "421036N",
    "long": "0422857E"
  },
  {
    "stationcode": "BUS",
    "stationname": "Batumi Airport",
    "lat": "413637N",
    "long": "0413559E"
  },
  {
    "stationcode": "ZUB",
    "stationname": "Sarpi Border Cross Airport",
    "lat": "413111N",
    "long": "0413250E"
  },
  {
    "stationcode": "SUI",
    "stationname": "Babusheri Airport",
    "lat": "425130N",
    "long": "0410741E"
  },
  {
    "stationcode": "MPY",
    "stationname": "Maripasoula Airport",
    "lat": "034000N",
    "long": "0540200W"
  },
  {
    "stationcode": "OYC",
    "stationname": "Camopi",
    "lat": "031022N",
    "long": "0522015W"
  },
  {
    "stationcode": "XAU",
    "stationname": "Saul Airport",
    "lat": "033700N",
    "long": "0531200W"
  },
  {
    "stationcode": "OYP",
    "stationname": "St. Georges de L/Oyapock Airport",
    "lat": "035400N",
    "long": "0514800W"
  },
  {
    "stationcode": "CAY",
    "stationname": "Rochambeau Airport",
    "lat": "044911N",
    "long": "0522138W"
  },
  {
    "stationcode": "REI",
    "stationname": "Regina Airport",
    "lat": "041900N",
    "long": "0520800W"
  },
  {
    "stationcode": "LDX",
    "stationname": "Saint Laurent du Maroni Airport",
    "lat": "052900N",
    "long": "0540200W"
  },
  {
    "stationcode": "TML",
    "stationname": "Tamale Airport",
    "lat": "093326N",
    "long": "0005148W"
  },
  {
    "stationcode": "KMS",
    "stationname": "Kumasi Airport",
    "lat": "064252N",
    "long": "0013527W"
  },
  {
    "stationcode": "ACC",
    "stationname": "Kotoka Airport",
    "lat": "053619N",
    "long": "0001000W"
  },
  {
    "stationcode": "TKD",
    "stationname": "Takoradi Airport",
    "lat": "045344N",
    "long": "0014629W"
  },
  {
    "stationcode": "NYI",
    "stationname": "Sunyani Airport",
    "lat": "072143N",
    "long": "0021944W"
  },
  {
    "stationcode": "WNE",
    "stationname": "Wora Na Ye Airport",
    "lat": "012100S",
    "long": "0092000E"
  },
  {
    "stationcode": "GIB",
    "stationname": "North Front Airport",
    "lat": "360904N",
    "long": "0052059W"
  },
  {
    "stationcode": "BJL",
    "stationname": "Yundum International Airport",
    "lat": "132017N",
    "long": "0163908W"
  },
  {
    "stationcode": "FAA",
    "stationname": "Faranah Airport",
    "lat": "100210N",
    "long": "0104613W"
  },
  {
    "stationcode": "BKJ",
    "stationname": "Boke Airport",
    "lat": "105800N",
    "long": "0141700W"
  },
  {
    "stationcode": "FIG",
    "stationname": "Fria Airport",
    "lat": "102100N",
    "long": "0133400W"
  },
  {
    "stationcode": "SBI",
    "stationname": "Sambailo Airport",
    "lat": "122900N",
    "long": "0131800W"
  },
  {
    "stationcode": "LEK",
    "stationname": "Labe Airport",
    "lat": "111942N",
    "long": "0121803W"
  },
  {
    "stationcode": "KSI",
    "stationname": "Kissidougou Airport",
    "lat": "091000N",
    "long": "0100700W"
  },
  {
    "stationcode": "KNN",
    "stationname": "Kankan Airport",
    "lat": "102200N",
    "long": "0091400W"
  },
  {
    "stationcode": "GII",
    "stationname": "Siguiri Airport",
    "lat": "112400N",
    "long": "0091100W"
  },
  {
    "stationcode": "CKY",
    "stationname": "Conakry Airport",
    "lat": "093437N",
    "long": "0133643W"
  },
  {
    "stationcode": "MCA",
    "stationname": "Macenta Airport",
    "lat": "083200N",
    "long": "0092800W"
  },
  {
    "stationcode": "NZE",
    "stationname": "Nzerekore Airport",
    "lat": "074900N",
    "long": "0084200W"
  },
  {
    "stationcode": "SFC",
    "stationname": "Saint Francois Airport",
    "lat": "161528N",
    "long": "0611545W"
  },
  {
    "stationcode": "PTP",
    "stationname": "Le Raizet Airport",
    "lat": "161555N",
    "long": "0613155W"
  },
  {
    "stationcode": "DSD",
    "stationname": "La Desirade Airport",
    "lat": "162000N",
    "long": "0610100W"
  },
  {
    "stationcode": "GBJ",
    "stationname": "Les Bases Airport",
    "lat": "155208N",
    "long": "0611612W"
  },
  {
    "stationcode": "HTB",
    "stationname": "Terre-de-Bas Airport",
    "lat": "154300N",
    "long": "0613800W"
  },
  {
    "stationcode": "BBR",
    "stationname": "Baillif Airport",
    "lat": "161000N",
    "long": "0614500W"
  },
  {
    "stationcode": "LSS",
    "stationname": "Terre-de-Haut Airport",
    "lat": "155152N",
    "long": "0613450W"
  },
  {
    "stationcode": "SSG",
    "stationname": "Santa Isabel Airport",
    "lat": "034510N",
    "long": "0084220E"
  },
  {
    "stationcode": "BSG",
    "stationname": "Bata Airport",
    "lat": "015420N",
    "long": "0094820E"
  },
  {
    "stationcode": "GEM",
    "stationname": "Mongomo Airport",
    "lat": "014129N",
    "long": "0110126E"
  },
  {
    "stationcode": "NBN",
    "stationname": "Annobon Airport",
    "lat": "012434S",
    "long": "0053719E"
  },
  {
    "stationcode": "OCS",
    "stationname": "Corisco Island Airport",
    "lat": "005425N",
    "long": "0091949E"
  },
  {
    "stationcode": "SPJ",
    "stationname": "Sparta Airport",
    "lat": "365826N",
    "long": "0223135E"
  },
  {
    "stationcode": "ZTH",
    "stationname": "Dionsios Solomos Airport",
    "lat": "374503N",
    "long": "0205303E"
  },
  {
    "stationcode": "KIT",
    "stationname": "Kythira Airport",
    "lat": "361627N",
    "long": "0230101E"
  },
  {
    "stationcode": "LRS",
    "stationname": "Leros Airport",
    "lat": "371106N",
    "long": "0264801E"
  },
  {
    "stationcode": "PKH",
    "stationname": "Kanaris Airport",
    "lat": "371756N",
    "long": "0230856E"
  },
  {
    "stationcode": "KGS",
    "stationname": "Ippokratis Airport",
    "lat": "364736N",
    "long": "0270530E"
  },
  {
    "stationcode": "CFU",
    "stationname": "Ioannis Kapodistrias Airport",
    "lat": "393607N",
    "long": "0195442E"
  },
  {
    "stationcode": "KVA",
    "stationname": "Megas Alexandros Airport",
    "lat": "405448N",
    "long": "0243709E"
  },
  {
    "stationcode": "JKH",
    "stationname": "Omiros Airport",
    "lat": "382035N",
    "long": "0260826E"
  },
  {
    "stationcode": "JSH",
    "stationname": "Siteia Airport",
    "lat": "351258N",
    "long": "0260605E"
  },
  {
    "stationcode": "KLX",
    "stationname": "Kalamata Airport",
    "lat": "370406N",
    "long": "0220132E"
  },
  {
    "stationcode": "AOK",
    "stationname": "Karpathos Airport",
    "lat": "352517N",
    "long": "0270846E"
  },
  {
    "stationcode": "SMI",
    "stationname": "Aristarchos of Samos Airport",
    "lat": "374124N",
    "long": "0265442E"
  },
  {
    "stationcode": "LXS",
    "stationname": "Limnos Airport",
    "lat": "395501N",
    "long": "0251411E"
  },
  {
    "stationcode": "JTY",
    "stationname": "Astypalaia Island Airport",
    "lat": "363448N",
    "long": "0262232E"
  },
  {
    "stationcode": "JNX",
    "stationname": "Naxos Airport",
    "lat": "370456N",
    "long": "0252207E"
  },
  {
    "stationcode": "DRM",
    "stationname": "Drama Airport",
    "lat": "410900N",
    "long": "0241000E"
  },
  {
    "stationcode": "JTR",
    "stationname": "Santorini Airport",
    "lat": "362357N",
    "long": "0252846E"
  },
  {
    "stationcode": "GPA",
    "stationname": "Araxos Airport",
    "lat": "380904N",
    "long": "0212532E"
  },
  {
    "stationcode": "VOL",
    "stationname": "Nea Anchialos Airport",
    "lat": "391311N",
    "long": "0224740E"
  },
  {
    "stationcode": "JSY",
    "stationname": "Dimitrios Vikelas Airport",
    "lat": "372522N",
    "long": "0245700E"
  },
  {
    "stationcode": "JSS",
    "stationname": "Spetsai Island Airport",
    "lat": "371500N",
    "long": "0231000E"
  },
  {
    "stationcode": "PVK",
    "stationname": "Aktion Airport",
    "lat": "385532N",
    "long": "0204555E"
  },
  {
    "stationcode": "PYR",
    "stationname": "Andravida AB Airport",
    "lat": "375515N",
    "long": "0211733E"
  },
  {
    "stationcode": "LRA",
    "stationname": "Thessalia Airport",
    "lat": "393901N",
    "long": "0222756E"
  },
  {
    "stationcode": "MLO",
    "stationname": "Milos Airport",
    "lat": "364148N",
    "long": "0242822E"
  },
  {
    "stationcode": "JIK",
    "stationname": "Ikaria Island Airport",
    "lat": "374058N",
    "long": "0262049E"
  },
  {
    "stationcode": "RHO",
    "stationname": "Diagoras Airport",
    "lat": "362420N",
    "long": "0280510E"
  },
  {
    "stationcode": "KSJ",
    "stationname": "Kasos Island Airport",
    "lat": "352517N",
    "long": "0265436E"
  },
  {
    "stationcode": "SKU",
    "stationname": "Skyros Airport",
    "lat": "385806N",
    "long": "0242917E"
  },
  {
    "stationcode": "JSI",
    "stationname": "Alex. Papadiamantis Airport",
    "lat": "391038N",
    "long": "0233013E"
  },
  {
    "stationcode": "EFL",
    "stationname": "Kefallinia Airport",
    "lat": "380712N",
    "long": "0203002E"
  },
  {
    "stationcode": "SKG",
    "stationname": "Makedonia Airport",
    "lat": "403111N",
    "long": "0225815E"
  },
  {
    "stationcode": "KZS",
    "stationname": "Megisti Airport",
    "lat": "360831N",
    "long": "0293435E"
  },
  {
    "stationcode": "MJT",
    "stationname": "Odysseas Elytis Airport",
    "lat": "390312N",
    "long": "0263608E"
  },
  {
    "stationcode": "PAS",
    "stationname": "Paros Airport",
    "lat": "370037N",
    "long": "0250743E"
  },
  {
    "stationcode": "JMK",
    "stationname": "Mykonos Airport",
    "lat": "372606N",
    "long": "0252053E"
  },
  {
    "stationcode": "AGQ",
    "stationname": "G.Karaiskakis Airport",
    "lat": "383607N",
    "long": "0212104E"
  },
  {
    "stationcode": "IOA",
    "stationname": "King Pyrros Airport",
    "lat": "394147N",
    "long": "0204921E"
  },
  {
    "stationcode": "HER",
    "stationname": "Nikos Kazantzakis Airport",
    "lat": "352023N",
    "long": "0251049E"
  },
  {
    "stationcode": "KSO",
    "stationname": "Aristotelis Airport",
    "lat": "402647N",
    "long": "0211656E"
  },
  {
    "stationcode": "ATH",
    "stationname": "Eleftherios Venizelos Airport",
    "lat": "375611N",
    "long": "0235640E"
  },
  {
    "stationcode": "JKL",
    "stationname": "Kalymnos Island Airport",
    "lat": "365748N",
    "long": "0265628E"
  },
  {
    "stationcode": "KZI",
    "stationname": "Filippos Airport",
    "lat": "401710N",
    "long": "0215027E"
  },
  {
    "stationcode": "AXD",
    "stationname": "Dimokritos Airport",
    "lat": "405121N",
    "long": "0255723E"
  },
  {
    "stationcode": "CHQ",
    "stationname": "Ioannis Daskalogiannis Airport",
    "lat": "353154N",
    "long": "0240859E"
  },
  {
    "stationcode": "PCG",
    "stationname": "Paso Caballos Airport",
    "lat": "171536N",
    "long": "0901450W"
  },
  {
    "stationcode": "PBR",
    "stationname": "Puerto Barrios Airport",
    "lat": "154355N",
    "long": "0883000W"
  },
  {
    "stationcode": "FRS",
    "stationname": "Santa Elena Airport",
    "lat": "165456N",
    "long": "0895211W"
  },
  {
    "stationcode": "GUA",
    "stationname": "La Aurora Airport",
    "lat": "143504N",
    "long": "0903138W"
  },
  {
    "stationcode": "HUG",
    "stationname": "Huehuetenango Airport",
    "lat": "152000N",
    "long": "0912800W"
  },
  {
    "stationcode": "AAZ",
    "stationname": "Quetzaltenango Airport",
    "lat": "145150N",
    "long": "0913113W"
  },
  {
    "stationcode": "CTF",
    "stationname": "Coatepeque Airport",
    "lat": "144140N",
    "long": "0915257W"
  },
  {
    "stationcode": "CBV",
    "stationname": "Coban Airport",
    "lat": "152808N",
    "long": "0902425W"
  },
  {
    "stationcode": "LOX",
    "stationname": "Los Tablones Airport",
    "lat": "144734N",
    "long": "0891210W"
  },
  {
    "stationcode": "CMM",
    "stationname": "Carmelita Airport",
    "lat": "173000N",
    "long": "0901000W"
  },
  {
    "stationcode": "RUV",
    "stationname": "Rubelsanto Airport",
    "lat": "155000N",
    "long": "0902000W"
  },
  {
    "stationcode": "ENJ",
    "stationname": "El Naranjo Airport",
    "lat": "140600N",
    "long": "0905000W"
  },
  {
    "stationcode": "MCR",
    "stationname": "Melchor De Menco Airport",
    "lat": "170000N",
    "long": "0920030W"
  },
  {
    "stationcode": "PKJ",
    "stationname": "Playa Grande Airport",
    "lat": "153833N",
    "long": "0904543W"
  },
  {
    "stationcode": "TKM",
    "stationname": "El Peten Airport",
    "lat": "171335N",
    "long": "0893618W"
  },
  {
    "stationcode": "DON",
    "stationname": "Dos Lagunas Airport",
    "lat": "173800N",
    "long": "0894000W"
  },
  {
    "stationcode": "AQB",
    "stationname": "Quiche Airport",
    "lat": "150036N",
    "long": "0910900W"
  },
  {
    "stationcode": "RER",
    "stationname": "Base Aerea Del Sur Airport",
    "lat": "143119N",
    "long": "0914148W"
  },
  {
    "stationcode": "GSJ",
    "stationname": "San Jose Airport",
    "lat": "135610N",
    "long": "0905009W"
  },
  {
    "stationcode": "CIQ",
    "stationname": "Chiquimula Airport",
    "lat": "144800N",
    "long": "0893300W"
  },
  {
    "stationcode": "PON",
    "stationname": "Poptun Airport",
    "lat": "162100N",
    "long": "0892600W"
  },
  {
    "stationcode": "UAX",
    "stationname": "Uaxactun Airport",
    "lat": "172300N",
    "long": "0894000W"
  },
  {
    "stationcode": "LCF",
    "stationname": "Las Vegas Airport",
    "lat": "154004N",
    "long": "0885652W"
  },
  {
    "stationcode": "GUM",
    "stationname": "A.B. Won Pat International Airport",
    "lat": "132900N",
    "long": "1444746E"
  },
  {
    "stationcode": "OXB",
    "stationname": "Osvaldo Vieira Airport",
    "lat": "115341N",
    "long": "0153913W"
  },
  {
    "stationcode": "BQE",
    "stationname": "Bubaque Airport",
    "lat": "111800N",
    "long": "0155100W"
  },
  {
    "stationcode": "AHL",
    "stationname": "Aishalton Airport",
    "lat": "022900N",
    "long": "0591900W"
  },
  {
    "stationcode": "GEO",
    "stationname": "Cheddi Jagan International Airport",
    "lat": "063000N",
    "long": "0581500W"
  },
  {
    "stationcode": "SKM",
    "stationname": "Skeldon Airport",
    "lat": "055240N",
    "long": "0571030W"
  },
  {
    "stationcode": "USI",
    "stationname": "Mabaruma Airport",
    "lat": "081210N",
    "long": "0594830W"
  },
  {
    "stationcode": "SDC",
    "stationname": "Sandcreek Airport",
    "lat": "030000N",
    "long": "0593100W"
  },
  {
    "stationcode": "MHA",
    "stationname": "Mahdia Airport",
    "lat": "051500N",
    "long": "0590900W"
  },
  {
    "stationcode": "PKM",
    "stationname": "Port Kaituma Airport",
    "lat": "082000N",
    "long": "0593800W"
  },
  {
    "stationcode": "MYM",
    "stationname": "Monkey Mountain Airport",
    "lat": "043600N",
    "long": "0593800W"
  },
  {
    "stationcode": "GFO",
    "stationname": "Bartica Airport",
    "lat": "062500N",
    "long": "0583500W"
  },
  {
    "stationcode": "KRM",
    "stationname": "Karanambo Airport",
    "lat": "034500N",
    "long": "0591900W"
  },
  {
    "stationcode": "BMJ",
    "stationname": "Baramita Airport",
    "lat": "072213N",
    "long": "0602917W"
  },
  {
    "stationcode": "PMT",
    "stationname": "Paramakotoi Airport",
    "lat": "045000N",
    "long": "0593300W"
  },
  {
    "stationcode": "BCG",
    "stationname": "Bemichi Airport",
    "lat": "073827N",
    "long": "0585739W"
  },
  {
    "stationcode": "VEG",
    "stationname": "Maikwak Airport",
    "lat": "053300N",
    "long": "0591700W"
  },
  {
    "stationcode": "KPG",
    "stationname": "Kurupung Airport",
    "lat": "060935N",
    "long": "0601623W"
  },
  {
    "stationcode": "OGL",
    "stationname": "Ogle Airport",
    "lat": "064825N",
    "long": "0580624W"
  },
  {
    "stationcode": "LUB",
    "stationname": "Lumid Pau Airport",
    "lat": "022400N",
    "long": "0592600W"
  },
  {
    "stationcode": "KTO",
    "stationname": "Kato Airport",
    "lat": "043900N",
    "long": "0594900W"
  },
  {
    "stationcode": "IMB",
    "stationname": "Imbaimadai Airport",
    "lat": "054300N",
    "long": "0601700W"
  },
  {
    "stationcode": "EKE",
    "stationname": "Ekereku Airport",
    "lat": "064000N",
    "long": "0605100W"
  },
  {
    "stationcode": "KKG",
    "stationname": "Konawaruk Airport",
    "lat": "051300N",
    "long": "0590000W"
  },
  {
    "stationcode": "PIQ",
    "stationname": "Pipillipai Airport",
    "lat": "052000N",
    "long": "0602000W"
  },
  {
    "stationcode": "KAR",
    "stationname": "Kamarang Airport",
    "lat": "055300N",
    "long": "0603700W"
  },
  {
    "stationcode": "MWJ",
    "stationname": "Matthews Ridge Airport",
    "lat": "072918N",
    "long": "0601106W"
  },
  {
    "stationcode": "PRR",
    "stationname": "Paruima Airport",
    "lat": "054900N",
    "long": "0610100W"
  },
  {
    "stationcode": "ORJ",
    "stationname": "Orinduik Airport",
    "lat": "044300N",
    "long": "0600200W"
  },
  {
    "stationcode": "KAI",
    "stationname": "Kaieteur Airport",
    "lat": "050900N",
    "long": "0592900W"
  },
  {
    "stationcode": "KRG",
    "stationname": "Karasabai Airport",
    "lat": "040100N",
    "long": "0593100W"
  },
  {
    "stationcode": "NAI",
    "stationname": "Annai Airport",
    "lat": "035734N",
    "long": "0590728W"
  },
  {
    "stationcode": "LTM",
    "stationname": "Lethem Airport",
    "lat": "032400N",
    "long": "0594800W"
  },
  {
    "stationcode": "KTZ",
    "stationname": "Kwun Tong Airport",
    "lat": "221900N",
    "long": "1141300E"
  },
  {
    "stationcode": "EDQ",
    "stationname": "Erandique Airport",
    "lat": "140800N",
    "long": "0882500W"
  },
  {
    "stationcode": "SET",
    "stationname": "San Esteban Airport",
    "lat": "080345S",
    "long": "0381934W"
  },
  {
    "stationcode": "CAA",
    "stationname": "Catacamas Airport",
    "lat": "145300N",
    "long": "0855300W"
  },
  {
    "stationcode": "UII",
    "stationname": "Utila Airport",
    "lat": "160644N",
    "long": "0865259W"
  },
  {
    "stationcode": "CYL",
    "stationname": "Coyoles Airport",
    "lat": "152900N",
    "long": "0864100W"
  },
  {
    "stationcode": "OAN",
    "stationname": "Olanchito Airport",
    "lat": "153125N",
    "long": "0863807W"
  },
  {
    "stationcode": "GUO",
    "stationname": "Gualaco Airport",
    "lat": "150700N",
    "long": "0860800W"
  },
  {
    "stationcode": "MRJ",
    "stationname": "Marcala Airport",
    "lat": "140800N",
    "long": "0880200W"
  },
  {
    "stationcode": "LCE",
    "stationname": "Goloson International Airport",
    "lat": "154433N",
    "long": "0865111W"
  },
  {
    "stationcode": "BHG",
    "stationname": "Brus Laguna Airport",
    "lat": "155000N",
    "long": "0845000W"
  },
  {
    "stationcode": "RTB",
    "stationname": "Roatan Airport",
    "lat": "161901N",
    "long": "0863123W"
  },
  {
    "stationcode": "CDD",
    "stationname": "Cauquira Airport",
    "lat": "151920N",
    "long": "0833610W"
  },
  {
    "stationcode": "TJI",
    "stationname": "Capiro Airport",
    "lat": "155800N",
    "long": "0855200W"
  },
  {
    "stationcode": "ENQ",
    "stationname": "Coronel Enrique Soto Cano AB Airport",
    "lat": "142209N",
    "long": "0873702W"
  },
  {
    "stationcode": "JUT",
    "stationname": "Juticalpa Airport",
    "lat": "144800N",
    "long": "0860800W"
  },
  {
    "stationcode": "RUY",
    "stationname": "Copan Airport",
    "lat": "145000N",
    "long": "0890800W"
  },
  {
    "stationcode": "PCH",
    "stationname": "Palacios Airport",
    "lat": "155718N",
    "long": "0845629W"
  },
  {
    "stationcode": "SDH",
    "stationname": "Santa Rosa De Copan Airport",
    "lat": "144900N",
    "long": "0884500W"
  },
  {
    "stationcode": "PEU",
    "stationname": "Puerto Lempira Airport",
    "lat": "151544N",
    "long": "0834704W"
  },
  {
    "stationcode": "AHS",
    "stationname": "Ahuas Airport",
    "lat": "152630N",
    "long": "0842530W"
  },
  {
    "stationcode": "LUI",
    "stationname": "La Union Airport",
    "lat": "150800N",
    "long": "0863500W"
  },
  {
    "stationcode": "LEZ",
    "stationname": "La Esperanza Airport",
    "lat": "141200N",
    "long": "0881000W"
  },
  {
    "stationcode": "XPL",
    "stationname": "Palmerola Air Base",
    "lat": "142808N",
    "long": "0873702W"
  },
  {
    "stationcode": "ORO",
    "stationname": "Yoro Airport",
    "lat": "150730N",
    "long": "0870830W"
  },
  {
    "stationcode": "TGU",
    "stationname": "Toncontin Airport",
    "lat": "140339N",
    "long": "0871302W"
  },
  {
    "stationcode": "TEA",
    "stationname": "Tela Airport",
    "lat": "154600N",
    "long": "0872700W"
  },
  {
    "stationcode": "LMH",
    "stationname": "Limon Airport",
    "lat": "155200N",
    "long": "0853300W"
  },
  {
    "stationcode": "SCD",
    "stationname": "Sulaco Airport",
    "lat": "145530N",
    "long": "0871530W"
  },
  {
    "stationcode": "TCF",
    "stationname": "Tocoa Airport",
    "lat": "154200N",
    "long": "0860100W"
  },
  {
    "stationcode": "IRN",
    "stationname": "Iriona Airport",
    "lat": "155700N",
    "long": "0851100W"
  },
  {
    "stationcode": "LLH",
    "stationname": "Las Limas Airport",
    "lat": "152700N",
    "long": "0875400W"
  },
  {
    "stationcode": "GJA",
    "stationname": "Guanaja Airport",
    "lat": "162638N",
    "long": "0855414W"
  },
  {
    "stationcode": "VTA",
    "stationname": "Victoria Airport",
    "lat": "145700N",
    "long": "0872400W"
  },
  {
    "stationcode": "GAC",
    "stationname": "Gracias Airport",
    "lat": "143000N",
    "long": "0883200W"
  },
  {
    "stationcode": "SAP",
    "stationname": "Ramon Villeda Morales Airport",
    "lat": "152710N",
    "long": "0875525W"
  },
  {
    "stationcode": "DBV",
    "stationname": "Dubrovnik Airport",
    "lat": "423341N",
    "long": "0181606E"
  },
  {
    "stationcode": "ZAG",
    "stationname": "Pleso Airport",
    "lat": "454435N",
    "long": "0160413E"
  },
  {
    "stationcode": "LSZ",
    "stationname": "Mali Losinj Airport",
    "lat": "443401N",
    "long": "0142338E"
  },
  {
    "stationcode": "OSI",
    "stationname": "Osijek Airport",
    "lat": "452746N",
    "long": "0184837E"
  },
  {
    "stationcode": "ZAD",
    "stationname": "Zadar Airport",
    "lat": "440552N",
    "long": "0152124E"
  },
  {
    "stationcode": "PUY",
    "stationname": "Pula Airport",
    "lat": "445337N",
    "long": "0135520E"
  },
  {
    "stationcode": "BWK",
    "stationname": "Bol Airport",
    "lat": "431709N",
    "long": "0164047E"
  },
  {
    "stationcode": "SPU",
    "stationname": "Split Airport",
    "lat": "433130N",
    "long": "0161732E"
  },
  {
    "stationcode": "RJK",
    "stationname": "Rijeka Airport",
    "lat": "451257N",
    "long": "0143406E"
  },
  {
    "stationcode": "PAX",
    "stationname": "Port De Paix Airport",
    "lat": "195600N",
    "long": "0725030W"
  },
  {
    "stationcode": "PAP",
    "stationname": "Mais Gate Airport",
    "lat": "183448N",
    "long": "0721733W"
  },
  {
    "stationcode": "JEE",
    "stationname": "Jeremie Airport",
    "lat": "184000N",
    "long": "0741000W"
  },
  {
    "stationcode": "CAP",
    "stationname": "Cap Haitien Airport",
    "lat": "194359N",
    "long": "0721141W"
  },
  {
    "stationcode": "CYA",
    "stationname": "Les Cayes Airport",
    "lat": "181600N",
    "long": "0734700W"
  },
  {
    "stationcode": "JAK",
    "stationname": "Jacmel Airport",
    "lat": "181500N",
    "long": "0723100W"
  },
  {
    "stationcode": "SOB",
    "stationname": "FlyBalaton Airport",
    "lat": "464110N",
    "long": "0170933E"
  },
  {
    "stationcode": "DEB",
    "stationname": "Debrecen Airport",
    "lat": "472920N",
    "long": "0213655E"
  },
  {
    "stationcode": "MCQ",
    "stationname": "Miskolc Airport",
    "lat": "480820N",
    "long": "0204740E"
  },
  {
    "stationcode": "PEV",
    "stationname": "Pecs Airport",
    "lat": "455922N",
    "long": "0181432E"
  },
  {
    "stationcode": "BUD",
    "stationname": "Ferihegy Airport",
    "lat": "472613N",
    "long": "0191520E"
  },
  {
    "stationcode": "CLB",
    "stationname": "Castlebar Airport",
    "lat": "535200N",
    "long": "0091800W"
  },
  {
    "stationcode": "KKY",
    "stationname": "Kilkenny Airport",
    "lat": "523903N",
    "long": "0071746W"
  },
  {
    "stationcode": "WEX",
    "stationname": "Castlebridge Airport",
    "lat": "522000N",
    "long": "0062800W"
  },
  {
    "stationcode": "SXL",
    "stationname": "Collooney Airport",
    "lat": "541649N",
    "long": "0083557W"
  },
  {
    "stationcode": "BLY",
    "stationname": "Belmullet Airport",
    "lat": "541322N",
    "long": "0100151W"
  },
  {
    "stationcode": "INQ",
    "stationname": "Inisheer Airport",
    "lat": "530331N",
    "long": "0093022W"
  },
  {
    "stationcode": "BYT",
    "stationname": "Bantry Airport",
    "lat": "514008N",
    "long": "0092904W"
  },
  {
    "stationcode": "GWY",
    "stationname": "Carnmore Airport",
    "lat": "531801N",
    "long": "0085628W"
  },
  {
    "stationcode": "LTR",
    "stationname": "Letterkenny Airport",
    "lat": "545705N",
    "long": "0074019W"
  },
  {
    "stationcode": "CHE",
    "stationname": "Reenroe Airport",
    "lat": "592653N",
    "long": "0244512E"
  },
  {
    "stationcode": "SNN",
    "stationname": "Shannon Airport",
    "lat": "524207N",
    "long": "0085529W"
  },
  {
    "stationcode": "IIA",
    "stationname": "Inishmaan Airport",
    "lat": "530531N",
    "long": "0093412W"
  },
  {
    "stationcode": "ORK",
    "stationname": "Cork Airport",
    "lat": "515029N",
    "long": "0082928W"
  },
  {
    "stationcode": "DUB",
    "stationname": "Dublin Airport",
    "lat": "532517N",
    "long": "0061612W"
  },
  {
    "stationcode": "KIR",
    "stationname": "Kerry County Airport",
    "lat": "521051N",
    "long": "0093126W"
  },
  {
    "stationcode": "WAT",
    "stationname": "Waterford Airport",
    "lat": "521114N",
    "long": "0070513W"
  },
  {
    "stationcode": "NOC",
    "stationname": "Ireland West Knock Airport",
    "lat": "535437N",
    "long": "0084907W"
  },
  {
    "stationcode": "CFN",
    "stationname": "Donegal Airport",
    "lat": "550239N",
    "long": "0082028W"
  },
  {
    "stationcode": "IOR",
    "stationname": "Kilronan Airport",
    "lat": "530625N",
    "long": "0093914W"
  },
  {
    "stationcode": "NNR",
    "stationname": "Connemara Airport",
    "lat": "531350N",
    "long": "0092804W"
  },
  {
    "stationcode": "HFA",
    "stationname": "Haifa Airport",
    "lat": "324841N",
    "long": "0350236E"
  },
  {
    "stationcode": "SDV",
    "stationname": "Sde-Dov Airport",
    "lat": "320653N",
    "long": "0344656E"
  },
  {
    "stationcode": "KSW",
    "stationname": "Kiryat Shmona Airport",
    "lat": "331300N",
    "long": "0353400E"
  },
  {
    "stationcode": "SED",
    "stationname": "Min'hat Hashnayim Airport",
    "lat": "310900N",
    "long": "0352300E"
  },
  {
    "stationcode": "EIY",
    "stationname": "Ein Yahav Airport",
    "lat": "303800N",
    "long": "0351100E"
  },
  {
    "stationcode": "BEV",
    "stationname": "Beer Sheba Airport",
    "lat": "311700N",
    "long": "0344300E"
  },
  {
    "stationcode": "MIP",
    "stationname": "Mitspeh Ramon Airport",
    "lat": "303900N",
    "long": "0344800E"
  },
  {
    "stationcode": "YOT",
    "stationname": "Yotvata Airport",
    "lat": "295400N",
    "long": "0350400E"
  },
  {
    "stationcode": "MTZ",
    "stationname": "Masada Airport",
    "lat": "312000N",
    "long": "0352300E"
  },
  {
    "stationcode": "GHK",
    "stationname": "Gush Katif Airport",
    "lat": "632605N",
    "long": "1091201W"
  },
  {
    "stationcode": "ETH",
    "stationname": "Elat Airport",
    "lat": "293330N",
    "long": "0345732E"
  },
  {
    "stationcode": "JRS",
    "stationname": "Jerusalem Airport",
    "lat": "315203N",
    "long": "0351252E"
  },
  {
    "stationcode": "VDA",
    "stationname": "Ovda Airport",
    "lat": "295625N",
    "long": "0345609E"
  },
  {
    "stationcode": "RPN",
    "stationname": "Rosh Pina Airport",
    "lat": "325900N",
    "long": "0353400E"
  },
  {
    "stationcode": "ISK",
    "stationname": "Gandhinagar Airport",
    "lat": "195751N",
    "long": "0734827E"
  },
  {
    "stationcode": "SHL",
    "stationname": "Shillong Airport",
    "lat": "254213N",
    "long": "0915839E"
  },
  {
    "stationcode": "PYB",
    "stationname": "Jeypore Airport",
    "lat": "185100N",
    "long": "0823500E"
  },
  {
    "stationcode": "DBR",
    "stationname": "Darbhanga Airport",
    "lat": "261137N",
    "long": "0855501E"
  },
  {
    "stationcode": "IXM",
    "stationname": "Madurai",
    "lat": "094957N",
    "long": "0780517E"
  },
  {
    "stationcode": "KNU",
    "stationname": "Kanpur Airport",
    "lat": "262626N",
    "long": "0802202E"
  },
  {
    "stationcode": "IXY",
    "stationname": "Kandla Airport",
    "lat": "230646N",
    "long": "0700602E"
  },
  {
    "stationcode": "AGR",
    "stationname": "Kheria Airport",
    "lat": "270921N",
    "long": "0775739E"
  },
  {
    "stationcode": "IXK",
    "stationname": "Keshod Airport",
    "lat": "211900N",
    "long": "0701600E"
  },
  {
    "stationcode": "MZA",
    "stationname": "Muzaffarnagar Airport",
    "lat": "292600N",
    "long": "0774000E"
  },
  {
    "stationcode": "JLR",
    "stationname": "Jabalpur Airport",
    "lat": "231050N",
    "long": "0800320E"
  },
  {
    "stationcode": "GWL",
    "stationname": "Gwalior Airport",
    "lat": "261736N",
    "long": "0781340E"
  },
  {
    "stationcode": "DAE",
    "stationname": "Daparizo Airport",
    "lat": "273000N",
    "long": "0942000E"
  },
  {
    "stationcode": "IXL",
    "stationname": "Leh Airport",
    "lat": "340809N",
    "long": "0773247E"
  },
  {
    "stationcode": "DIB",
    "stationname": "Chabua Airport",
    "lat": "272900N",
    "long": "0950100E"
  },
  {
    "stationcode": "MOH",
    "stationname": "Mohanbari Airport",
    "lat": "021210S",
    "long": "1213940E"
  },
  {
    "stationcode": "BHJ",
    "stationname": "Rudra Mata Airport",
    "lat": "231716N",
    "long": "0694013E"
  },
  {
    "stationcode": "GUX",
    "stationname": "Guna Airport",
    "lat": "242900N",
    "long": "0771900E"
  },
  {
    "stationcode": "BEK",
    "stationname": "Bareli Airport",
    "lat": "282520N",
    "long": "0792703E"
  },
  {
    "stationcode": "JGA",
    "stationname": "Govardhanpur Airport",
    "lat": "222756N",
    "long": "0700045E"
  },
  {
    "stationcode": "JGB",
    "stationname": "Jagdalpur Airport",
    "lat": "190400N",
    "long": "0820200E"
  },
  {
    "stationcode": "RAJ",
    "stationname": "Civil Airport",
    "lat": "221833N",
    "long": "0704646E"
  },
  {
    "stationcode": "BKB",
    "stationname": "Bikaner Airport",
    "lat": "280100N",
    "long": "0731800E"
  },
  {
    "stationcode": "CDP",
    "stationname": "Cuddapah Airport",
    "lat": "143046N",
    "long": "0784621E"
  },
  {
    "stationcode": "SLV",
    "stationname": "Simla Airport",
    "lat": "310454N",
    "long": "0770405E"
  },
  {
    "stationcode": "HBX",
    "stationname": "Hubli Airport",
    "lat": "152142N",
    "long": "0750513E"
  },
  {
    "stationcode": "KJB",
    "stationname": "Kurnool Airport",
    "lat": "154253N",
    "long": "0781042E"
  },
  {
    "stationcode": "IXI",
    "stationname": "Lilabari Airport",
    "lat": "271740N",
    "long": "0940547E"
  },
  {
    "stationcode": "TNI",
    "stationname": "Satna Airport",
    "lat": "243300N",
    "long": "0805000E"
  },
  {
    "stationcode": "IXV",
    "stationname": "Along Airport",
    "lat": "281032N",
    "long": "0944808E"
  },
  {
    "stationcode": "HSS",
    "stationname": "Hissar Airport",
    "lat": "291000N",
    "long": "0754800E"
  },
  {
    "stationcode": "MYQ",
    "stationname": "Mysore Airport",
    "lat": "121354N",
    "long": "0763926E"
  },
  {
    "stationcode": "TCR",
    "stationname": "Tuticorin Airport",
    "lat": "084324N",
    "long": "0780135E"
  },
  {
    "stationcode": "PBD",
    "stationname": "Porbandar Airport",
    "lat": "213855N",
    "long": "0693926E"
  },
  {
    "stationcode": "IXG",
    "stationname": "Sambre Airport",
    "lat": "155133N",
    "long": "0743706E"
  },
  {
    "stationcode": "NVY",
    "stationname": "Neyveli Airport",
    "lat": "113600N",
    "long": "0792600E"
  },
  {
    "stationcode": "VDY",
    "stationname": "Jindal Vidyanagar Airport",
    "lat": "151029N",
    "long": "0763805E"
  },
  {
    "stationcode": "KLH",
    "stationname": "Kolhapur Airport",
    "lat": "163953N",
    "long": "0741722E"
  },
  {
    "stationcode": "TEI",
    "stationname": "Tezu Airport",
    "lat": "275700N",
    "long": "0960800E"
  },
  {
    "stationcode": "AJL",
    "stationname": "Aizawl Airport",
    "lat": "235020N",
    "long": "0923713E"
  },
  {
    "stationcode": "SSE",
    "stationname": "Sholapur Airport",
    "lat": "173800N",
    "long": "0755600E"
  },
  {
    "stationcode": "DBD",
    "stationname": "Dhanbad Airport",
    "lat": "234800N",
    "long": "0862700E"
  },
  {
    "stationcode": "VGA",
    "stationname": "Vijayawada Airport",
    "lat": "163146N",
    "long": "0804750E"
  },
  {
    "stationcode": "IXN",
    "stationname": "Khowai Airport",
    "lat": "240400N",
    "long": "0913800E"
  },
  {
    "stationcode": "RRK",
    "stationname": "Rourkela Airport",
    "lat": "221600N",
    "long": "0844900E"
  },
  {
    "stationcode": "TIR",
    "stationname": "Tirupati Airport",
    "lat": "133800N",
    "long": "0793300E"
  },
  {
    "stationcode": "SXV",
    "stationname": "Salem Airport",
    "lat": "114652N",
    "long": "0780349E"
  },
  {
    "stationcode": "RDP",
    "stationname": "Kazi Nazrul Islam Airport",
    "lat": "233727N",
    "long": "0871432E"
  },
  {
    "stationcode": "IXQ",
    "stationname": "Kamalpur Airport",
    "lat": "240800N",
    "long": "0914900E"
  },
  {
    "stationcode": "RUP",
    "stationname": "Rupsi Airport",
    "lat": "260828N",
    "long": "0895424E"
  },
  {
    "stationcode": "AGX",
    "stationname": "Agatti Island Airport",
    "lat": "110000N",
    "long": "0720000E"
  },
  {
    "stationcode": "BDQ",
    "stationname": "Vadodara Airport",
    "lat": "222010N",
    "long": "0731335E"
  },
  {
    "stationcode": "CJB",
    "stationname": "Coimbatore",
    "lat": "110148N",
    "long": "0770236E"
  },
  {
    "stationcode": "IXH",
    "stationname": "Kailashahar Airport",
    "lat": "241900N",
    "long": "0920100E"
  },
  {
    "stationcode": "IXT",
    "stationname": "Pasighat Airport",
    "lat": "280402N",
    "long": "0952011E"
  },
  {
    "stationcode": "PNY",
    "stationname": "Pondicherry Airport",
    "lat": "115806N",
    "long": "0794841E"
  },
  {
    "stationcode": "DHM",
    "stationname": "Gaggal Airport",
    "lat": "320955N",
    "long": "0761538E"
  },
  {
    "stationcode": "BEP",
    "stationname": "Bellary Airport",
    "lat": "151100N",
    "long": "0765400E"
  },
  {
    "stationcode": "IXP",
    "stationname": "Pathankot Airport",
    "lat": "321402N",
    "long": "0753805E"
  },
  {
    "stationcode": "PAB",
    "stationname": "Bilaspur Airport",
    "lat": "220000N",
    "long": "0820400E"
  },
  {
    "stationcode": "LDA",
    "stationname": "Malda Airport",
    "lat": "250200N",
    "long": "0880900E"
  },
  {
    "stationcode": "IXE",
    "stationname": "Bajpe Airport",
    "lat": "125741N",
    "long": "0745324E"
  },
  {
    "stationcode": "IXU",
    "stationname": "Chikkalthana Airport",
    "lat": "195146N",
    "long": "0752353E"
  },
  {
    "stationcode": "CCJ",
    "stationname": "Calicut Airport",
    "lat": "110813N",
    "long": "0755719E"
  },
  {
    "stationcode": "DMU",
    "stationname": "Dimapur Airport",
    "lat": "255257N",
    "long": "0934628E"
  },
  {
    "stationcode": "RMD",
    "stationname": "Ramagundam Airport",
    "lat": "184600N",
    "long": "0792400E"
  },
  {
    "stationcode": "HJR",
    "stationname": "Khajuraho Airport",
    "lat": "244902N",
    "long": "0795507E"
  },
  {
    "stationcode": "DEP",
    "stationname": "Deparizo Airport",
    "lat": "275908N",
    "long": "0941323E"
  },
  {
    "stationcode": "RTC",
    "stationname": "Ratnagiri Airport",
    "lat": "170000N",
    "long": "0731900E"
  },
  {
    "stationcode": "JRH",
    "stationname": "Rowriah Airport",
    "lat": "264354N",
    "long": "0941032E"
  },
  {
    "stationcode": "DAI",
    "stationname": "Darjeeling Airport",
    "lat": "270200N",
    "long": "0881600E"
  },
  {
    "stationcode": "IXW",
    "stationname": "Sonari Airport",
    "lat": "224900N",
    "long": "0861000E"
  },
  {
    "stationcode": "WGC",
    "stationname": "Warangal Airport",
    "lat": "175500N",
    "long": "0793600E"
  },
  {
    "stationcode": "BUP",
    "stationname": "Bhatinda Airport",
    "lat": "301613N",
    "long": "0744521E"
  },
  {
    "stationcode": "CBD",
    "stationname": "Car Nicobar Airport",
    "lat": "090917N",
    "long": "0924912E"
  },
  {
    "stationcode": "LUH",
    "stationname": "Ludhiana Airport",
    "lat": "305115N",
    "long": "0755713E"
  },
  {
    "stationcode": "DIU",
    "stationname": "Diu Airport",
    "lat": "204250N",
    "long": "0705518E"
  },
  {
    "stationcode": "TEZ",
    "stationname": "Salonibari Airport",
    "lat": "264224N",
    "long": "0924642E"
  },
  {
    "stationcode": "PGH",
    "stationname": "Pantnagar Airport",
    "lat": "290155N",
    "long": "0792825E"
  },
  {
    "stationcode": "KTU",
    "stationname": "Kota Airport",
    "lat": "251000N",
    "long": "0755100E"
  },
  {
    "stationcode": "JDH",
    "stationname": "Jodhpur Airport",
    "lat": "261504N",
    "long": "0730256E"
  },
  {
    "stationcode": "GDB",
    "stationname": "Birsi Airport",
    "lat": "213129N",
    "long": "0801718E"
  },
  {
    "stationcode": "RJA",
    "stationname": "Rajahmundry Airport",
    "lat": "170636N",
    "long": "0814910E"
  },
  {
    "stationcode": "RJI",
    "stationname": "Rajouri Airport",
    "lat": "332207N",
    "long": "0741809E"
  },
  {
    "stationcode": "BPM",
    "stationname": "Begumpet Airport",
    "lat": "172712N",
    "long": "0782749E"
  },
  {
    "stationcode": "PUT",
    "stationname": "Puttaprathe Airport",
    "lat": "140800N",
    "long": "0774700E"
  },
  {
    "stationcode": "IXD",
    "stationname": "Bamrauli Airport",
    "lat": "252624N",
    "long": "0814402E"
  },
  {
    "stationcode": "NMB",
    "stationname": "Daman Airport",
    "lat": "202500N",
    "long": "0725100E"
  },
  {
    "stationcode": "BHO",
    "stationname": "Bhopal Airport",
    "lat": "231715N",
    "long": "0772015E"
  },
  {
    "stationcode": "LTU",
    "stationname": "Latur Airport",
    "lat": "182442N",
    "long": "0762752E"
  },
  {
    "stationcode": "TJV",
    "stationname": "Thanjavur Airport",
    "lat": "104800N",
    "long": "0790900E"
  },
  {
    "stationcode": "GOP",
    "stationname": "Gorakhpur Airport",
    "lat": "264428N",
    "long": "0832700E"
  },
  {
    "stationcode": "RGH",
    "stationname": "Balurghat Airport",
    "lat": "251600N",
    "long": "0884800E"
  },
  {
    "stationcode": "NDC",
    "stationname": "Nanded Airport",
    "lat": "191055N",
    "long": "0771922E"
  },
  {
    "stationcode": "OMN",
    "stationname": "Osmanabad Airport",
    "lat": "180900N",
    "long": "0760600E"
  },
  {
    "stationcode": "COH",
    "stationname": "Cooch Behar Airport",
    "lat": "262000N",
    "long": "0892800E"
  },
  {
    "stationcode": "MZU",
    "stationname": "Muzaffarpur Airport",
    "lat": "260707N",
    "long": "0851844E"
  },
  {
    "stationcode": "REW",
    "stationname": "Rewa Airport",
    "lat": "243200N",
    "long": "0811800E"
  },
  {
    "stationcode": "BHU",
    "stationname": "Bhavnagar Airport",
    "lat": "214508N",
    "long": "0721107E"
  },
  {
    "stationcode": "KUU",
    "stationname": "Bhuntar Airport",
    "lat": "315236N",
    "long": "0770916E"
  },
  {
    "stationcode": "AKD",
    "stationname": "Akola Airport",
    "lat": "204000N",
    "long": "0770500E"
  },
  {
    "stationcode": "ZER",
    "stationname": "Zero Airport",
    "lat": "273400N",
    "long": "0935000E"
  },
  {
    "stationcode": "OSM",
    "stationname": "Mosul Airport",
    "lat": "361821N",
    "long": "0430851E"
  },
  {
    "stationcode": "RQW",
    "stationname": "Qayyarah Airport",
    "lat": "354601N",
    "long": "0430730E"
  },
  {
    "stationcode": "BSR",
    "stationname": "Basra International Airport",
    "lat": "303257N",
    "long": "0473944E"
  },
  {
    "stationcode": "NJF",
    "stationname": "Najaf International Airport",
    "lat": "315923N",
    "long": "0442415E"
  },
  {
    "stationcode": "EBL",
    "stationname": "Erbil International Airport",
    "lat": "361416N",
    "long": "0435747E"
  },
  {
    "stationcode": "ISU",
    "stationname": "Sulaymaniyah International Airport",
    "lat": "353345N",
    "long": "0451857E"
  },
  {
    "stationcode": "BMN",
    "stationname": "Bamerny Airport",
    "lat": "370600N",
    "long": "0431600E"
  },
  {
    "stationcode": "KIK",
    "stationname": "Kirkuk Airport",
    "lat": "353100N",
    "long": "0441800E"
  },
  {
    "stationcode": "KIH",
    "stationname": "Kish Island Airport",
    "lat": "263139N",
    "long": "0535845E"
  },
  {
    "stationcode": "GSM",
    "stationname": "Gheshm Airport",
    "lat": "264507N",
    "long": "0555350E"
  },
  {
    "stationcode": "ACP",
    "stationname": "Sahand Airport",
    "lat": "372053N",
    "long": "0460759E"
  },
  {
    "stationcode": "KNR",
    "stationname": "Jam Airport",
    "lat": "274900N",
    "long": "0522100E"
  },
  {
    "stationcode": "KLM",
    "stationname": "Kalaleh Airport",
    "lat": "372259N",
    "long": "0552714E"
  },
  {
    "stationcode": "RZR",
    "stationname": "Ramsar Airport",
    "lat": "365433N",
    "long": "0504048E"
  },
  {
    "stationcode": "GBT",
    "stationname": "Gorgon Airport",
    "lat": "365441N",
    "long": "0542353E"
  },
  {
    "stationcode": "IFN",
    "stationname": "Isfahan Airport",
    "lat": "324506N",
    "long": "0515211E"
  },
  {
    "stationcode": "TBZ",
    "stationname": "Tabriz Airport",
    "lat": "380754N",
    "long": "0461436E"
  },
  {
    "stationcode": "TCX",
    "stationname": "Tabas Airport",
    "lat": "334002N",
    "long": "0565336E"
  },
  {
    "stationcode": "RUD",
    "stationname": "Shahrood Airport",
    "lat": "362515N",
    "long": "0550522E"
  },
  {
    "stationcode": "MRX",
    "stationname": "Mahshahr Airport",
    "lat": "303308N",
    "long": "0490921E"
  },
  {
    "stationcode": "IIL",
    "stationname": "Ilaam Airport",
    "lat": "333508N",
    "long": "0462418E"
  },
  {
    "stationcode": "SYJ",
    "stationname": "Sirjan Airport",
    "lat": "293312N",
    "long": "0554010E"
  },
  {
    "stationcode": "AJK",
    "stationname": "Araak Airport",
    "lat": "340817N",
    "long": "0495049E"
  },
  {
    "stationcode": "HDM",
    "stationname": "Hamadan Airport",
    "lat": "345203N",
    "long": "0483323E"
  },
  {
    "stationcode": "IHR",
    "stationname": "Iran Shahr Airport",
    "lat": "271410N",
    "long": "0604312E"
  },
  {
    "stationcode": "AZD",
    "stationname": "Yazd Airport",
    "lat": "315404N",
    "long": "0541655E"
  },
  {
    "stationcode": "RAS",
    "stationname": "Rasht Airport",
    "lat": "371924N",
    "long": "0493704E"
  },
  {
    "stationcode": "BDH",
    "stationname": "Bandar Lengeh Airport",
    "lat": "263153N",
    "long": "0544939E"
  },
  {
    "stationcode": "JWN",
    "stationname": "Zanjan Airport",
    "lat": "364627N",
    "long": "0482130E"
  },
  {
    "stationcode": "ZAH",
    "stationname": "Zahedan Airport",
    "lat": "292824N",
    "long": "0605412E"
  },
  {
    "stationcode": "CKT",
    "stationname": "Sarakhs Airport",
    "lat": "362917N",
    "long": "0610412E"
  },
  {
    "stationcode": "PGU",
    "stationname": "Persian Gulf Intl",
    "lat": "272246N",
    "long": "0524416E"
  },
  {
    "stationcode": "AKW",
    "stationname": "Aghajari Airport",
    "lat": "304500N",
    "long": "0494100E"
  },
  {
    "stationcode": "BUZ",
    "stationname": "Bushehr Airport",
    "lat": "285658N",
    "long": "0504949E"
  },
  {
    "stationcode": "NUJ",
    "stationname": "Nojeh Airport",
    "lat": "351200N",
    "long": "0484000E"
  },
  {
    "stationcode": "DEF",
    "stationname": "Dezful Airport",
    "lat": "322634N",
    "long": "0482318E"
  },
  {
    "stationcode": "KHY",
    "stationname": "Khoy Airport",
    "lat": "382520N",
    "long": "0445836E"
  },
  {
    "stationcode": "ZBR",
    "stationname": "Chah-Bahar Airport",
    "lat": "252640N",
    "long": "0602301E"
  },
  {
    "stationcode": "SYZ",
    "stationname": "Shiraz Airport",
    "lat": "293225N",
    "long": "0523519E"
  },
  {
    "stationcode": "OMH",
    "stationname": "Umieh Airport",
    "lat": "373944N",
    "long": "0450349E"
  },
  {
    "stationcode": "KER",
    "stationname": "Kerman Airport",
    "lat": "301628N",
    "long": "0565704E"
  },
  {
    "stationcode": "BJB",
    "stationname": "Bojnord Airport",
    "lat": "372927N",
    "long": "0571833E"
  },
  {
    "stationcode": "JYR",
    "stationname": "Jiroft Airport",
    "lat": "284338N",
    "long": "0574013E"
  },
  {
    "stationcode": "LRR",
    "stationname": "Lar Airport",
    "lat": "274023N",
    "long": "0542314E"
  },
  {
    "stationcode": "PFQ",
    "stationname": "Parsabad Airport",
    "lat": "393621N",
    "long": "0475235E"
  },
  {
    "stationcode": "ABD",
    "stationname": "Abadan Airport",
    "lat": "302216N",
    "long": "0481342E"
  },
  {
    "stationcode": "KHD",
    "stationname": "Khorramabad Airport",
    "lat": "332619N",
    "long": "0481651E"
  },
  {
    "stationcode": "TEW",
    "stationname": "Tohid Airport",
    "lat": "274932N",
    "long": "0522113E"
  },
  {
    "stationcode": "KSH",
    "stationname": "Kermanshah Airport",
    "lat": "342053N",
    "long": "0470924E"
  },
  {
    "stationcode": "KHK",
    "stationname": "Khark Airport",
    "lat": "291538N",
    "long": "0501920E"
  },
  {
    "stationcode": "LFM",
    "stationname": "Lamerd Airport",
    "lat": "272223N",
    "long": "0531117E"
  },
  {
    "stationcode": "AFZ",
    "stationname": "Sabzevar Airport",
    "lat": "361007N",
    "long": "0573547E"
  },
  {
    "stationcode": "YES",
    "stationname": "Yasouj Airport",
    "lat": "304149N",
    "long": "0513300E"
  },
  {
    "stationcode": "AEU",
    "stationname": "Abu Musa Airport",
    "lat": "255232N",
    "long": "0550157E"
  },
  {
    "stationcode": "IFH",
    "stationname": "Hesa Airport",
    "lat": "325500N",
    "long": "0513300E"
  },
  {
    "stationcode": "BBL",
    "stationname": "Babolsar Airport",
    "lat": "272413S",
    "long": "1414842E"
  },
  {
    "stationcode": "ACZ",
    "stationname": "Zabol Airport",
    "lat": "310554N",
    "long": "0613238E"
  },
  {
    "stationcode": "HDR",
    "stationname": "Havadarya Airport",
    "lat": "270300N",
    "long": "0561000E"
  },
  {
    "stationcode": "RZY",
    "stationname": "Rezayieh Airport",
    "lat": "374010N",
    "long": "0450413E"
  },
  {
    "stationcode": "ADU",
    "stationname": "Ardabil Airport",
    "lat": "381932N",
    "long": "0482527E"
  },
  {
    "stationcode": "GCH",
    "stationname": "Gachsaran Airport",
    "lat": "302010N",
    "long": "0504948E"
  },
  {
    "stationcode": "BND",
    "stationname": "Bandar Abbas Airport",
    "lat": "271307N",
    "long": "0562240E"
  },
  {
    "stationcode": "FAZ",
    "stationname": "Fasa Airport",
    "lat": "285331N",
    "long": "0534324E"
  },
  {
    "stationcode": "OMI",
    "stationname": "Omidieh Airport",
    "lat": "305000N",
    "long": "0493200E"
  },
  {
    "stationcode": "XBJ",
    "stationname": "Birjand Airport",
    "lat": "325338N",
    "long": "0591719E"
  },
  {
    "stationcode": "PYK",
    "stationname": "Payam International Airport",
    "lat": "354644N",
    "long": "0504920E"
  },
  {
    "stationcode": "CQD",
    "stationname": "Shahre-Kord Airport",
    "lat": "321754N",
    "long": "0505026E"
  },
  {
    "stationcode": "THR",
    "stationname": "Mehrabad Airport",
    "lat": "354120N",
    "long": "0511853E"
  },
  {
    "stationcode": "JAR",
    "stationname": "Jahrom Airport",
    "lat": "283512N",
    "long": "0533444E"
  },
  {
    "stationcode": "GZW",
    "stationname": "Ghazvin Airport",
    "lat": "361400N",
    "long": "0500200E"
  },
  {
    "stationcode": "KHA",
    "stationname": "Khaneh Airport",
    "lat": "364400N",
    "long": "0450900E"
  },
  {
    "stationcode": "NSH",
    "stationname": "Now Shahr Airport",
    "lat": "363948N",
    "long": "0512753E"
  },
  {
    "stationcode": "IAQ",
    "stationname": "Bahregan Airport",
    "lat": "295005N",
    "long": "0501604E"
  },
  {
    "stationcode": "LVP",
    "stationname": "Lavan Island Airport",
    "lat": "264837N",
    "long": "0532121E"
  },
  {
    "stationcode": "MHD",
    "stationname": "Mashad Airport",
    "lat": "361403N",
    "long": "0593842E"
  },
  {
    "stationcode": "SXI",
    "stationname": "Sirri Island Airport",
    "lat": "255430N",
    "long": "0543230E"
  },
  {
    "stationcode": "RJN",
    "stationname": "Rafsanjan Airport",
    "lat": "301751N",
    "long": "0560303E"
  },
  {
    "stationcode": "SDG",
    "stationname": "Sanandaj Airport",
    "lat": "351503N",
    "long": "0470040E"
  },
  {
    "stationcode": "BXR",
    "stationname": "Bam Airport",
    "lat": "290503N",
    "long": "0582659E"
  },
  {
    "stationcode": "AWZ",
    "stationname": "Ahwaz Airport",
    "lat": "312015N",
    "long": "0484537E"
  },
  {
    "stationcode": "BSM",
    "stationname": "Bishe-Kola Airport",
    "lat": "363900N",
    "long": "0522100E"
  },
  {
    "stationcode": "SRY",
    "stationname": "Dashte Naz Airport",
    "lat": "363700N",
    "long": "0531100E"
  },
  {
    "stationcode": "NOR",
    "stationname": "Nordfjordur Airport",
    "lat": "650755N",
    "long": "0134447W"
  },
  {
    "stationcode": "GUU",
    "stationname": "Grundarf Jordur Airport",
    "lat": "645929N",
    "long": "0231329W"
  },
  {
    "stationcode": "RHA",
    "stationname": "Reykholar Airport",
    "lat": "652659N",
    "long": "0221348W"
  },
  {
    "stationcode": "KEF",
    "stationname": "Keflavik International Airport",
    "lat": "635906N",
    "long": "0223620W"
  },
  {
    "stationcode": "HVM",
    "stationname": "Hvammstangi Airport",
    "lat": "652500N",
    "long": "0210000W"
  },
  {
    "stationcode": "IFJ",
    "stationname": "Isafjordur Airport",
    "lat": "660329N",
    "long": "0230807W"
  },
  {
    "stationcode": "VPN",
    "stationname": "Vopnafjordur Airport",
    "lat": "654314N",
    "long": "0145102W"
  },
  {
    "stationcode": "THO",
    "stationname": "Thorshofn Airport",
    "lat": "661306N",
    "long": "0152005W"
  },
  {
    "stationcode": "SIJ",
    "stationname": "Siglufjordur Airport",
    "lat": "660800N",
    "long": "0185500W"
  },
  {
    "stationcode": "SUY",
    "stationname": "Sudureyri Airport",
    "lat": "621106N",
    "long": "1173806E"
  },
  {
    "stationcode": "HVK",
    "stationname": "Holmavik Airport",
    "lat": "654217N",
    "long": "0214147W"
  },
  {
    "stationcode": "SYK",
    "stationname": "Stykkisholmur Airport",
    "lat": "650329N",
    "long": "0224739W"
  },
  {
    "stationcode": "OLI",
    "stationname": "Rif Airport",
    "lat": "645441N",
    "long": "0234923W"
  },
  {
    "stationcode": "AEY",
    "stationname": "Akureyri Airport",
    "lat": "653931N",
    "long": "0180420W"
  },
  {
    "stationcode": "RFN",
    "stationname": "Raufarhofn Airport",
    "lat": "662500N",
    "long": "0155500W"
  },
  {
    "stationcode": "DJU",
    "stationname": "Djupivogur Airport",
    "lat": "643839N",
    "long": "0141658W"
  },
  {
    "stationcode": "SEJ",
    "stationname": "Seydisfjordur Airport",
    "lat": "405818N",
    "long": "0053854W"
  },
  {
    "stationcode": "FLI",
    "stationname": "Flateyri Airport",
    "lat": "660051N",
    "long": "0232628W"
  },
  {
    "stationcode": "VEY",
    "stationname": "Vestmannaeyjar Airport",
    "lat": "632530N",
    "long": "0201645W"
  },
  {
    "stationcode": "HZK",
    "stationname": "Husavik Airport",
    "lat": "655709N",
    "long": "0172533W"
  },
  {
    "stationcode": "GJR",
    "stationname": "Gjogur Airport",
    "lat": "655943N",
    "long": "0211937W"
  },
  {
    "stationcode": "EGS",
    "stationname": "Egilsstadir Airport",
    "lat": "651700N",
    "long": "0142405W"
  },
  {
    "stationcode": "BIU",
    "stationname": "Bildudalur Airport",
    "lat": "653829N",
    "long": "0233246W"
  },
  {
    "stationcode": "FAG",
    "stationname": "Fagurholsmyri Airport",
    "lat": "635229N",
    "long": "0163828W"
  },
  {
    "stationcode": "MVA",
    "stationname": "Reykiahlid Airport",
    "lat": "653921N",
    "long": "0165505W"
  },
  {
    "stationcode": "SAK",
    "stationname": "Saudarkrokur Airport",
    "lat": "654354N",
    "long": "0193422W"
  },
  {
    "stationcode": "BJD",
    "stationname": "Bakkafjordur Airport",
    "lat": "660119N",
    "long": "0144928W"
  },
  {
    "stationcode": "TLK",
    "stationname": "Talknafjordur Airport",
    "lat": "595254N",
    "long": "1110244E"
  },
  {
    "stationcode": "HFN",
    "stationname": "Hornafjordur Airport",
    "lat": "641744N",
    "long": "0151338W"
  },
  {
    "stationcode": "BGJ",
    "stationname": "Borgarfjordur Eystri Airport",
    "lat": "653059N",
    "long": "0134818W"
  },
  {
    "stationcode": "TEY",
    "stationname": "Thingeyri Airport",
    "lat": "655213N",
    "long": "0233336W"
  },
  {
    "stationcode": "BXV",
    "stationname": "Breiddalsvik Airport",
    "lat": "644724N",
    "long": "0140122W"
  },
  {
    "stationcode": "GRY",
    "stationname": "Grimsey Airport",
    "lat": "663245N",
    "long": "0180102W"
  },
  {
    "stationcode": "BLO",
    "stationname": "Blonduos Airport",
    "lat": "653842N",
    "long": "0201715W"
  },
  {
    "stationcode": "OFJ",
    "stationname": "Olafsfjordur Airport",
    "lat": "660427N",
    "long": "0184004W"
  },
  {
    "stationcode": "OPA",
    "stationname": "Kopasker Airport",
    "lat": "661839N",
    "long": "0162800W"
  },
  {
    "stationcode": "FAS",
    "stationname": "Faskrudsfjordur Airport",
    "lat": "645554N",
    "long": "0140338W"
  },
  {
    "stationcode": "RKV",
    "stationname": "Reykjavik Domestic Airport",
    "lat": "640748N",
    "long": "0215626W"
  },
  {
    "stationcode": "PFJ",
    "stationname": "Patrekstjord Airport",
    "lat": "653321N",
    "long": "0235754W"
  },
  {
    "stationcode": "PEJ",
    "stationname": "Peschiei Airport",
    "lat": "414206N",
    "long": "0154350E"
  },
  {
    "stationcode": "SAY",
    "stationname": "Siena Airport",
    "lat": "431531N",
    "long": "0111518E"
  },
  {
    "stationcode": "LMP",
    "stationname": "Lampedusa Airport",
    "lat": "352953N",
    "long": "0123706E"
  },
  {
    "stationcode": "DCI",
    "stationname": "Rafsu Decimomannu Airport",
    "lat": "392115N",
    "long": "0085821E"
  },
  {
    "stationcode": "JLO",
    "stationname": "Jesolo Airport",
    "lat": "453200N",
    "long": "0124300E"
  },
  {
    "stationcode": "GOA",
    "stationname": "Cristoforo Colombo Airport",
    "lat": "442448N",
    "long": "0085015E"
  },
  {
    "stationcode": "TTB",
    "stationname": "Arbatax Airport",
    "lat": "395509N",
    "long": "0094057E"
  },
  {
    "stationcode": "VCE",
    "stationname": "Marco Polo Airport",
    "lat": "453019N",
    "long": "0122107E"
  },
  {
    "stationcode": "RRO",
    "stationname": "Sorrento Airport",
    "lat": "403700N",
    "long": "0142200E"
  },
  {
    "stationcode": "PSA",
    "stationname": "Gal Galilei Airport",
    "lat": "434058N",
    "long": "0102344E"
  },
  {
    "stationcode": "RAN",
    "stationname": "La Spreta Airport",
    "lat": "442152N",
    "long": "0121329E"
  },
  {
    "stationcode": "GRS",
    "stationname": "Baccarini Airport",
    "lat": "424540N",
    "long": "0110415E"
  },
  {
    "stationcode": "SUF",
    "stationname": "S Eufemia Airport",
    "lat": "385430N",
    "long": "0161430E"
  },
  {
    "stationcode": "TRN",
    "stationname": "Citta Di Torino Airport",
    "lat": "451209N",
    "long": "0073858E"
  },
  {
    "stationcode": "PRJ",
    "stationname": "Capri Airport",
    "lat": "403300N",
    "long": "0141400E"
  },
  {
    "stationcode": "PNL",
    "stationname": "Pantelleria Airport",
    "lat": "364854N",
    "long": "0115805E"
  },
  {
    "stationcode": "CTA",
    "stationname": "Fontanarossa Airport",
    "lat": "372800N",
    "long": "0150350E"
  },
  {
    "stationcode": "MXP",
    "stationname": "Malpensa Airport",
    "lat": "453748N",
    "long": "0084323E"
  },
  {
    "stationcode": "VBS",
    "stationname": "Montichiari Airport",
    "lat": "452544N",
    "long": "0101950E"
  },
  {
    "stationcode": "GBN",
    "stationname": "San Giovanni Rotondo Airport",
    "lat": "414206N",
    "long": "0154350E"
  },
  {
    "stationcode": "EBA",
    "stationname": "Marina Di Campo Airport",
    "lat": "424542N",
    "long": "0101423E"
  },
  {
    "stationcode": "BRI",
    "stationname": "Palese Airport",
    "lat": "410817N",
    "long": "0164554E"
  },
  {
    "stationcode": "VRN",
    "stationname": "Verona Airport",
    "lat": "452347N",
    "long": "0105317E"
  },
  {
    "stationcode": "QMM",
    "stationname": "Marina Di Massa Airport",
    "lat": "424600N",
    "long": "0101400E"
  },
  {
    "stationcode": "REG",
    "stationname": "Tito Menniti Airport",
    "lat": "380419N",
    "long": "0153913E"
  },
  {
    "stationcode": "BLX",
    "stationname": "Belluno Airport",
    "lat": "461002N",
    "long": "0121452E"
  },
  {
    "stationcode": "BDS",
    "stationname": "Papola Casale Airport",
    "lat": "403938N",
    "long": "0175653E"
  },
  {
    "stationcode": "AHO",
    "stationname": "Fertilia Airport",
    "lat": "403749N",
    "long": "0081723E"
  },
  {
    "stationcode": "UDN",
    "stationname": "Udine Airfield",
    "lat": "460155N",
    "long": "0131112E"
  },
  {
    "stationcode": "OLB",
    "stationname": "Costa Smeralda Airport",
    "lat": "405355N",
    "long": "0093104E"
  },
  {
    "stationcode": "ISH",
    "stationname": "Ischia Airport",
    "lat": "404400N",
    "long": "0135700E"
  },
  {
    "stationcode": "RMI",
    "stationname": "Miramare Airport",
    "lat": "440110N",
    "long": "0123634E"
  },
  {
    "stationcode": "LCC",
    "stationname": "Galatina Airport",
    "lat": "401421N",
    "long": "0180800E"
  },
  {
    "stationcode": "SWK",
    "stationname": "Segrate Airport",
    "lat": "452900N",
    "long": "0091900E"
  },
  {
    "stationcode": "PSR",
    "stationname": "Liberi Airport",
    "lat": "422554N",
    "long": "0141112E"
  },
  {
    "stationcode": "FLR",
    "stationname": "Peretola Airport",
    "lat": "434831N",
    "long": "0111210E"
  },
  {
    "stationcode": "CRV",
    "stationname": "Crotone Airport",
    "lat": "385957N",
    "long": "0170447E"
  },
  {
    "stationcode": "VIF",
    "stationname": "Vieste Airport",
    "lat": "415307N",
    "long": "0161034E"
  },
  {
    "stationcode": "TQR",
    "stationname": "San Domino Island Airport",
    "lat": "420710N",
    "long": "0153009E"
  },
  {
    "stationcode": "LCV",
    "stationname": "Lucca Airport",
    "lat": "434947N",
    "long": "0103444E"
  },
  {
    "stationcode": "CAG",
    "stationname": "Elmas Airport",
    "lat": "391505N",
    "long": "0090328E"
  },
  {
    "stationcode": "QAQ",
    "stationname": "L'Aquila Airport",
    "lat": "422253N",
    "long": "0131834E"
  },
  {
    "stationcode": "BZO",
    "stationname": "Bolzano Airport",
    "lat": "462739N",
    "long": "0111936E"
  },
  {
    "stationcode": "QSR",
    "stationname": "Salerno Pontecagnano Airport",
    "lat": "403712N",
    "long": "0145445E"
  },
  {
    "stationcode": "FNU",
    "stationname": "Fenosu Airport",
    "lat": "395342N",
    "long": "0083818E"
  },
  {
    "stationcode": "CDF",
    "stationname": "Fiames Airport",
    "lat": "463200N",
    "long": "0120800E"
  },
  {
    "stationcode": "BLQ",
    "stationname": "Guglielmo Marconi Airport",
    "lat": "443151N",
    "long": "0111749E"
  },
  {
    "stationcode": "PEG",
    "stationname": "Sant Egidio Airport",
    "lat": "430550N",
    "long": "0123037E"
  },
  {
    "stationcode": "VIC",
    "stationname": "Vicenza Airport",
    "lat": "453418N",
    "long": "0113137E"
  },
  {
    "stationcode": "PMO",
    "stationname": "Punta Raisi Airport",
    "lat": "381055N",
    "long": "0130558E"
  },
  {
    "stationcode": "FRL",
    "stationname": "Luigi Ridolfi Airport",
    "lat": "441144N",
    "long": "0120411E"
  },
  {
    "stationcode": "NAP",
    "stationname": "Naples Airport",
    "lat": "405304N",
    "long": "0141727E"
  },
  {
    "stationcode": "PMF",
    "stationname": "Parma Airport",
    "lat": "444920N",
    "long": "0101743E"
  },
  {
    "stationcode": "AOT",
    "stationname": "Corrado Gex Airport",
    "lat": "454419N",
    "long": "0072205E"
  },
  {
    "stationcode": "TRS",
    "stationname": "Dei Legionari Airport",
    "lat": "454939N",
    "long": "0132820E"
  },
  {
    "stationcode": "CIA",
    "stationname": "Ciampino Airport",
    "lat": "414756N",
    "long": "0123542E"
  },
  {
    "stationcode": "AVB",
    "stationname": "Aviano Airport",
    "lat": "460155N",
    "long": "0123547E"
  },
  {
    "stationcode": "FOG",
    "stationname": "Gino Lisa Airport",
    "lat": "412600N",
    "long": "0153205E"
  },
  {
    "stationcode": "TAR",
    "stationname": "M. A. Grottag Airport",
    "lat": "403046N",
    "long": "0172417E"
  },
  {
    "stationcode": "ALL",
    "stationname": "Albenga Airport",
    "lat": "440245N",
    "long": "0080732E"
  },
  {
    "stationcode": "AOI",
    "stationname": "Falconara Airport",
    "lat": "433700N",
    "long": "0132137E"
  },
  {
    "stationcode": "TPS",
    "stationname": "Birgi Airport",
    "lat": "375427N",
    "long": "0122939E"
  },
  {
    "stationcode": "CIY",
    "stationname": "Comiso Airport",
    "lat": "365941N",
    "long": "0143625E"
  },
  {
    "stationcode": "BGY",
    "stationname": "Orio Al Serio Airport",
    "lat": "454008N",
    "long": "0094201E"
  },
  {
    "stationcode": "CUF",
    "stationname": "Levaldigi Airport",
    "lat": "443251N",
    "long": "0073723E"
  },
  {
    "stationcode": "TSF",
    "stationname": "Treviso Airport",
    "lat": "453903N",
    "long": "0121152E"
  },
  {
    "stationcode": "LIN",
    "stationname": "Linate Airport",
    "lat": "452658N",
    "long": "0091642E"
  },
  {
    "stationcode": "MVJ",
    "stationname": "Marlboro Airport",
    "lat": "180118N",
    "long": "0772943W"
  },
  {
    "stationcode": "MBJ",
    "stationname": "Sangster International Airport",
    "lat": "183013N",
    "long": "0775448W"
  },
  {
    "stationcode": "KIN",
    "stationname": "Norman Manley Airport",
    "lat": "175608N",
    "long": "0764715W"
  },
  {
    "stationcode": "NEG",
    "stationname": "Negril Airport",
    "lat": "181830N",
    "long": "0782000W"
  },
  {
    "stationcode": "OCJ",
    "stationname": "Boscobel Airport",
    "lat": "182400N",
    "long": "0765900W"
  },
  {
    "stationcode": "POT",
    "stationname": "Ken Jones Airport",
    "lat": "181200N",
    "long": "0763200W"
  },
  {
    "stationcode": "KTP",
    "stationname": "Tinson Airport",
    "lat": "180100N",
    "long": "0764900W"
  },
  {
    "stationcode": "AQJ",
    "stationname": "King Hussein International Airport",
    "lat": "293642N",
    "long": "0350105E"
  },
  {
    "stationcode": "OMF",
    "stationname": "King Hussein Airport",
    "lat": "322000N",
    "long": "0361400E"
  },
  {
    "stationcode": "MPQ",
    "stationname": "Maan Airport",
    "lat": "301000N",
    "long": "0354800E"
  },
  {
    "stationcode": "ADJ",
    "stationname": "Civil - Marka Airport",
    "lat": "315822N",
    "long": "0355930E"
  },
  {
    "stationcode": "TRA",
    "stationname": "Tarama Airport",
    "lat": "243914N",
    "long": "1244032E"
  },
  {
    "stationcode": "OKD",
    "stationname": "Okadama Airport",
    "lat": "430658N",
    "long": "1412249E"
  },
  {
    "stationcode": "AKJ",
    "stationname": "Asahikawa Airport",
    "lat": "434015N",
    "long": "1422651E"
  },
  {
    "stationcode": "KJP",
    "stationname": "Kerama Airport",
    "lat": "261000N",
    "long": "1271800E"
  },
  {
    "stationcode": "HTR",
    "stationname": "Hateruma Airport",
    "lat": "240300N",
    "long": "1234700E"
  },
  {
    "stationcode": "OBO",
    "stationname": "Obihiro Airport",
    "lat": "424400N",
    "long": "1431300E"
  },
  {
    "stationcode": "MMJ",
    "stationname": "Matsumoto Airport",
    "lat": "361000N",
    "long": "1375522E"
  },
  {
    "stationcode": "KCZ",
    "stationname": "Kochi Airport",
    "lat": "333246N",
    "long": "1334010E"
  },
  {
    "stationcode": "FUJ",
    "stationname": "Fukue Airport",
    "lat": "324000N",
    "long": "1285000E"
  },
  {
    "stationcode": "IBR",
    "stationname": "Ibaraki Airport",
    "lat": "361042N",
    "long": "1402505E"
  },
  {
    "stationcode": "ASJ",
    "stationname": "Amami O Shima Airport",
    "lat": "282550N",
    "long": "1294245E"
  },
  {
    "stationcode": "ISG",
    "stationname": "Ishigaki Airport",
    "lat": "242347N",
    "long": "1241442E"
  },
  {
    "stationcode": "RNJ",
    "stationname": "Yoronjima Airport",
    "lat": "270238N",
    "long": "1282407E"
  },
  {
    "stationcode": "HIJ",
    "stationname": "Hiroshima International Airport",
    "lat": "342610N",
    "long": "1325510E"
  },
  {
    "stationcode": "IKI",
    "stationname": "Iki Airport",
    "lat": "334500N",
    "long": "1294700E"
  },
  {
    "stationcode": "IWJ",
    "stationname": "Iwami Airport",
    "lat": "344035N",
    "long": "1314725E"
  },
  {
    "stationcode": "SDO",
    "stationname": "Ryotsu Sado Island Airport",
    "lat": "380800N",
    "long": "1382500E"
  },
  {
    "stationcode": "ITM",
    "stationname": "Itami Airport",
    "lat": "344708N",
    "long": "1352618E"
  },
  {
    "stationcode": "TAK",
    "stationname": "Takamatsu Airport",
    "lat": "341307N",
    "long": "1340107E"
  },
  {
    "stationcode": "SDS",
    "stationname": "Sado Shima Airport",
    "lat": "380340N",
    "long": "1382450E"
  },
  {
    "stationcode": "IWK",
    "stationname": "Iwakuni Airport",
    "lat": "340846N",
    "long": "1321450E"
  },
  {
    "stationcode": "SHB",
    "stationname": "Nakashibetsu Airport",
    "lat": "433439N",
    "long": "1445736E"
  },
  {
    "stationcode": "UKB",
    "stationname": "Kobe Airport",
    "lat": "343758N",
    "long": "1351326E"
  },
  {
    "stationcode": "SHM",
    "stationname": "Shirahama Airport",
    "lat": "333944N",
    "long": "1352152E"
  },
  {
    "stationcode": "MYJ",
    "stationname": "Matsuyama Airport",
    "lat": "334920N",
    "long": "1324215E"
  },
  {
    "stationcode": "NTQ",
    "stationname": "Noto Airport",
    "lat": "371733N",
    "long": "1365732E"
  },
  {
    "stationcode": "IZO",
    "stationname": "Izumo Airport",
    "lat": "352449N",
    "long": "1325324E"
  },
  {
    "stationcode": "OIT",
    "stationname": "Oita Airport",
    "lat": "332900N",
    "long": "1314400E"
  },
  {
    "stationcode": "IHA",
    "stationname": "Niihama Airport",
    "lat": "335700N",
    "long": "1331800E"
  },
  {
    "stationcode": "HKD",
    "stationname": "Hakodate Airport",
    "lat": "414612N",
    "long": "1404919E"
  },
  {
    "stationcode": "KKX",
    "stationname": "Kikaiga Shima Airport",
    "lat": "281918N",
    "long": "1295536E"
  },
  {
    "stationcode": "HSG",
    "stationname": "Saga Airport",
    "lat": "330847N",
    "long": "1301816E"
  },
  {
    "stationcode": "KOJ",
    "stationname": "Kagoshima Airport",
    "lat": "314812N",
    "long": "1304310E"
  },
  {
    "stationcode": "KTD",
    "stationname": "Kitadaito Airport",
    "lat": "255641N",
    "long": "1311938E"
  },
  {
    "stationcode": "KUM",
    "stationname": "Yakushima Airport",
    "lat": "302308N",
    "long": "1303934E"
  },
  {
    "stationcode": "RBJ",
    "stationname": "Rebun Airport",
    "lat": "452700N",
    "long": "1410300E"
  },
  {
    "stationcode": "AGJ",
    "stationname": "Aguni Airport",
    "lat": "263500N",
    "long": "1271500E"
  },
  {
    "stationcode": "HAC",
    "stationname": "Hachijo Jima Airport",
    "lat": "330654N",
    "long": "1394709E"
  },
  {
    "stationcode": "SHI",
    "stationname": "Shimojishima Airport",
    "lat": "244938N",
    "long": "1250842E"
  },
  {
    "stationcode": "UBJ",
    "stationname": "Ube Airport",
    "lat": "335548N",
    "long": "1311643E"
  },
  {
    "stationcode": "FSZ",
    "stationname": "Mount Fuji Airport",
    "lat": "344749N",
    "long": "1381051E"
  },
  {
    "stationcode": "TJH",
    "stationname": "Tajima Airport",
    "lat": "353048N",
    "long": "1344714E"
  },
  {
    "stationcode": "TNE",
    "stationname": "Tanegashima Airport",
    "lat": "303243N",
    "long": "1305706E"
  },
  {
    "stationcode": "MBE",
    "stationname": "Monbetsu Airport",
    "lat": "441600N",
    "long": "1432300E"
  },
  {
    "stationcode": "NGS",
    "stationname": "Nagasaki Airport",
    "lat": "325443N",
    "long": "1295503E"
  },
  {
    "stationcode": "YOK",
    "stationname": "Yokohoma Airport",
    "lat": "352800N",
    "long": "1393800E"
  },
  {
    "stationcode": "HIW",
    "stationname": "Hiroshima West Airport",
    "lat": "342100N",
    "long": "1322400E"
  },
  {
    "stationcode": "MMY",
    "stationname": "Hirara Airport",
    "lat": "244658N",
    "long": "1251742E"
  },
  {
    "stationcode": "OKE",
    "stationname": "Okino Erabu Airport",
    "lat": "272556N",
    "long": "1284221E"
  },
  {
    "stationcode": "MMD",
    "stationname": "Maridor Airport",
    "lat": "255050N",
    "long": "1311550E"
  },
  {
    "stationcode": "ONJ",
    "stationname": "Odate Noshiro Airport",
    "lat": "401131N",
    "long": "1402217E"
  },
  {
    "stationcode": "OKI",
    "stationname": "Oki Island Airport",
    "lat": "361052N",
    "long": "1331929E"
  },
  {
    "stationcode": "MMB",
    "stationname": "Memanbetsu Airport",
    "lat": "435250N",
    "long": "1440951E"
  },
  {
    "stationcode": "IEJ",
    "stationname": "Iejima Airport",
    "lat": "264400N",
    "long": "1274700E"
  },
  {
    "stationcode": "TOY",
    "stationname": "Toyama Airport",
    "lat": "363839N",
    "long": "1371120E"
  },
  {
    "stationcode": "KUH",
    "stationname": "Kushiro Airport",
    "lat": "430223N",
    "long": "1441214E"
  },
  {
    "stationcode": "BPU",
    "stationname": "Beppu Airport",
    "lat": "331500N",
    "long": "1313000E"
  },
  {
    "stationcode": "MYE",
    "stationname": "Miyake Jima Airport",
    "lat": "340425N",
    "long": "1393337E"
  },
  {
    "stationcode": "KMI",
    "stationname": "Miyazaki Airport",
    "lat": "315238N",
    "long": "1312655E"
  },
  {
    "stationcode": "MSJ",
    "stationname": "Misawa Airport",
    "lat": "404212N",
    "long": "1412206E"
  },
  {
    "stationcode": "KUJ",
    "stationname": "Kushimoto Airport",
    "lat": "332600N",
    "long": "1354700E"
  },
  {
    "stationcode": "KMJ",
    "stationname": "Kumamoto Airport",
    "lat": "325014N",
    "long": "1305118E"
  },
  {
    "stationcode": "FKJ",
    "stationname": "Fukui Airport",
    "lat": "360500N",
    "long": "1361500E"
  },
  {
    "stationcode": "SYO",
    "stationname": "Shonai Airport",
    "lat": "384834N",
    "long": "1394726E"
  },
  {
    "stationcode": "OIR",
    "stationname": "Okushiri Airport",
    "lat": "420418N",
    "long": "1392559E"
  },
  {
    "stationcode": "TKS",
    "stationname": "Tokushima Airport",
    "lat": "340747N",
    "long": "1343606E"
  },
  {
    "stationcode": "KIJ",
    "stationname": "Niigata Airport",
    "lat": "375721N",
    "long": "1390715E"
  },
  {
    "stationcode": "OGN",
    "stationname": "Yonaguni Airport",
    "lat": "242801N",
    "long": "1225840E"
  },
  {
    "stationcode": "KKJ",
    "stationname": "Kokura Airport",
    "lat": "334959N",
    "long": "1305658E"
  },
  {
    "stationcode": "GAJ",
    "stationname": "Junmachi Airport",
    "lat": "382400N",
    "long": "1402300E"
  },
  {
    "stationcode": "UEO",
    "stationname": "Kume-jima Airport",
    "lat": "262155N",
    "long": "1264259E"
  },
  {
    "stationcode": "TSJ",
    "stationname": "Tsushima Airport",
    "lat": "341706N",
    "long": "1291950E"
  },
  {
    "stationcode": "AXJ",
    "stationname": "Amakusa Airport",
    "lat": "322856N",
    "long": "1300932E"
  },
  {
    "stationcode": "OMJ",
    "stationname": "Omura Airport",
    "lat": "325500N",
    "long": "1295600E"
  },
  {
    "stationcode": "TTJ",
    "stationname": "Tottori Airport",
    "lat": "353136N",
    "long": "1341013E"
  },
  {
    "stationcode": "RIS",
    "stationname": "Rishiri Airport",
    "lat": "451431N",
    "long": "1411111E"
  },
  {
    "stationcode": "YGJ",
    "stationname": "Miho Airport",
    "lat": "352929N",
    "long": "1331432E"
  },
  {
    "stationcode": "TKN",
    "stationname": "Tokunoshima Airport",
    "lat": "275000N",
    "long": "1285300E"
  },
  {
    "stationcode": "HHE",
    "stationname": "Hachinohe Airport",
    "lat": "403300N",
    "long": "1412900E"
  },
  {
    "stationcode": "HNA",
    "stationname": "Hanamaki Airport",
    "lat": "392542N",
    "long": "1410807E"
  },
  {
    "stationcode": "MUS",
    "stationname": "Marcus Island Airport",
    "lat": "241800N",
    "long": "1535800E"
  },
  {
    "stationcode": "OKJ",
    "stationname": "Okayama Airport",
    "lat": "344525N",
    "long": "1335119E"
  },
  {
    "stationcode": "KMQ",
    "stationname": "Komatsu Airport",
    "lat": "362341N",
    "long": "1362424E"
  },
  {
    "stationcode": "UKY",
    "stationname": "Kyoto Airport",
    "lat": "350200N",
    "long": "1354500E"
  },
  {
    "stationcode": "AXT",
    "stationname": "Akita Airport",
    "lat": "393642N",
    "long": "1401311E"
  },
  {
    "stationcode": "WKJ",
    "stationname": "Hokkaido Airport",
    "lat": "452415N",
    "long": "1414803E"
  },
  {
    "stationcode": "AOJ",
    "stationname": "Aomori Airport",
    "lat": "404405N",
    "long": "1404127E"
  },
  {
    "stationcode": "OIM",
    "stationname": "Oshima Airport",
    "lat": "344655N",
    "long": "1392137E"
  },
  {
    "stationcode": "IIN",
    "stationname": "Nishinoomote Airport",
    "lat": "304300N",
    "long": "1305900E"
  },
  {
    "stationcode": "NYE",
    "stationname": "Nyeri Airport",
    "lat": "002200S",
    "long": "0365800E"
  },
  {
    "stationcode": "UAS",
    "stationname": "Samburu Airport",
    "lat": "003203N",
    "long": "0373155E"
  },
  {
    "stationcode": "UKA",
    "stationname": "Ukunda Airport",
    "lat": "041748S",
    "long": "0393417E"
  },
  {
    "stationcode": "OLX",
    "stationname": "Olkiombo Airport",
    "lat": "012432S",
    "long": "0350638E"
  },
  {
    "stationcode": "WIL",
    "stationname": "Wilson Airport",
    "lat": "011918S",
    "long": "0364853E"
  },
  {
    "stationcode": "EYS",
    "stationname": "Elive Springs Airport",
    "lat": "031500N",
    "long": "0355800E"
  },
  {
    "stationcode": "LAU",
    "stationname": "Lamu Airport",
    "lat": "021509S",
    "long": "0405447E"
  },
  {
    "stationcode": "ASV",
    "stationname": "Amboseli Airport",
    "lat": "023842S",
    "long": "0371510E"
  },
  {
    "stationcode": "ILU",
    "stationname": "Kilaguni Airport",
    "lat": "025400S",
    "long": "0380400E"
  },
  {
    "stationcode": "LBN",
    "stationname": "Lake Baringo Airport",
    "lat": "003613N",
    "long": "0360049E"
  },
  {
    "stationcode": "MRE",
    "stationname": "Mara Lodges Airport",
    "lat": "012416S",
    "long": "0350034E"
  },
  {
    "stationcode": "KEY",
    "stationname": "Kericho Airport",
    "lat": "002500S",
    "long": "0351500E"
  },
  {
    "stationcode": "LBK",
    "stationname": "Liboi Airport",
    "lat": "002400N",
    "long": "0405700E"
  },
  {
    "stationcode": "KIU",
    "stationname": "Kiunga Airport",
    "lat": "014500S",
    "long": "0412900E"
  },
  {
    "stationcode": "GAS",
    "stationname": "Garissa Airport",
    "lat": "002749S",
    "long": "0393854E"
  },
  {
    "stationcode": "JJM",
    "stationname": "Mulika Lodge Airport",
    "lat": "001341N",
    "long": "0381020E"
  },
  {
    "stationcode": "NZO",
    "stationname": "Nzoia Airport",
    "lat": "000800N",
    "long": "0340500E"
  },
  {
    "stationcode": "NUU",
    "stationname": "Nakuru Airport",
    "lat": "001754S",
    "long": "0360933E"
  },
  {
    "stationcode": "MBA",
    "stationname": "Moi International Airport",
    "lat": "040141S",
    "long": "0393559E"
  },
  {
    "stationcode": "BMQ",
    "stationname": "Bamburi Airport",
    "lat": "035700S",
    "long": "0394400E"
  },
  {
    "stationcode": "KTJ",
    "stationname": "Kichwa Tembo Airport",
    "lat": "011549S",
    "long": "0350140E"
  },
  {
    "stationcode": "MUM",
    "stationname": "Mumias Airport",
    "lat": "002000N",
    "long": "0342900E"
  },
  {
    "stationcode": "MYD",
    "stationname": "Malindi Airport",
    "lat": "031345S",
    "long": "0400606E"
  },
  {
    "stationcode": "LOK",
    "stationname": "Lodwar Airport",
    "lat": "030718N",
    "long": "0353620E"
  },
  {
    "stationcode": "LKG",
    "stationname": "Lokichoggio Airport",
    "lat": "041215N",
    "long": "0342050E"
  },
  {
    "stationcode": "KRV",
    "stationname": "Kerio Valley Airport",
    "lat": "025900N",
    "long": "0360700E"
  },
  {
    "stationcode": "RBT",
    "stationname": "Marsabit Airport",
    "lat": "022000N",
    "long": "0375900E"
  },
  {
    "stationcode": "KTL",
    "stationname": "Kitale Airport",
    "lat": "005824N",
    "long": "0345735E"
  },
  {
    "stationcode": "EDL",
    "stationname": "Eldoret Airport",
    "lat": "002417N",
    "long": "0351420E"
  },
  {
    "stationcode": "NDE",
    "stationname": "Mandera Airport",
    "lat": "035603N",
    "long": "0415040E"
  },
  {
    "stationcode": "OYL",
    "stationname": "Moyale Airport",
    "lat": "032810N",
    "long": "0390607E"
  },
  {
    "stationcode": "GGM",
    "stationname": "Kakamega Airport",
    "lat": "001617N",
    "long": "0344715E"
  },
  {
    "stationcode": "NBO",
    "stationname": "Jomo Kenyatta International Airport",
    "lat": "011900S",
    "long": "0365551E"
  },
  {
    "stationcode": "KLK",
    "stationname": "Kalokol Airport",
    "lat": "033100N",
    "long": "0355000E"
  },
  {
    "stationcode": "KWY",
    "stationname": "Kiwayu Airport",
    "lat": "020100S",
    "long": "0411600E"
  },
  {
    "stationcode": "LKU",
    "stationname": "Lake Rudolf Airport",
    "lat": "032500N",
    "long": "0355300E"
  },
  {
    "stationcode": "VPG",
    "stationname": "Vipingo Airport",
    "lat": "035034S",
    "long": "0394818E"
  },
  {
    "stationcode": "KIS",
    "stationname": "Kisumu Airport",
    "lat": "000508S",
    "long": "0344347E"
  },
  {
    "stationcode": "HOA",
    "stationname": "Hola Airport",
    "lat": "012900S",
    "long": "0400200E"
  },
  {
    "stationcode": "NYK",
    "stationname": "Nanyuki Airport",
    "lat": "000141N",
    "long": "0370129E"
  },
  {
    "stationcode": "WJR",
    "stationname": "Wajir Airport",
    "lat": "014705N",
    "long": "0395558E"
  },
  {
    "stationcode": "FER",
    "stationname": "Fergusons Gulf Airport",
    "lat": "033100N",
    "long": "0355500E"
  },
  {
    "stationcode": "LOY",
    "stationname": "Loyangalani Airport",
    "lat": "024549N",
    "long": "0364306E"
  },
  {
    "stationcode": "OSS",
    "stationname": "Osh Airport",
    "lat": "403632N",
    "long": "0724736E"
  },
  {
    "stationcode": "FRU",
    "stationname": "Bishkek Airport",
    "lat": "430341N",
    "long": "0742839E"
  },
  {
    "stationcode": "MWV",
    "stationname": "Mundulkiri Airport",
    "lat": "122738N",
    "long": "1071127E"
  },
  {
    "stationcode": "DSY",
    "stationname": "Dara Sakor Internaional",
    "lat": "105430N",
    "long": "1031341E"
  },
  {
    "stationcode": "SVR",
    "stationname": "Svay Rieng Airport",
    "lat": "760108N",
    "long": "0650553W"
  },
  {
    "stationcode": "KMT",
    "stationname": "Kampot Airport",
    "lat": "103800N",
    "long": "1041000E"
  },
  {
    "stationcode": "TNX",
    "stationname": "Stung Treng Airport",
    "lat": "133155N",
    "long": "1060052E"
  },
  {
    "stationcode": "KZD",
    "stationname": "Krakor Airport",
    "lat": "123200N",
    "long": "1040900E"
  },
  {
    "stationcode": "KZK",
    "stationname": "Kompong Thom Airport",
    "lat": "123500N",
    "long": "1045100E"
  },
  {
    "stationcode": "BBM",
    "stationname": "Battambang Airport",
    "lat": "130544N",
    "long": "1031328E"
  },
  {
    "stationcode": "KTI",
    "stationname": "Kratie Airport",
    "lat": "122900N",
    "long": "1060300E"
  },
  {
    "stationcode": "RBE",
    "stationname": "Ratanakiri Airport",
    "lat": "134000N",
    "long": "1065900E"
  },
  {
    "stationcode": "KKZ",
    "stationname": "Koh Kong Airport",
    "lat": "113800N",
    "long": "1025800E"
  },
  {
    "stationcode": "OMY",
    "stationname": "Oddor Meanche Airport",
    "lat": "134900N",
    "long": "1045800E"
  },
  {
    "stationcode": "KZC",
    "stationname": "Kompong-Chhna Airport",
    "lat": "121400N",
    "long": "1044000E"
  },
  {
    "stationcode": "PAI",
    "stationname": "Pailin Airport",
    "lat": "120500N",
    "long": "1023600E"
  },
  {
    "stationcode": "HAH",
    "stationname": "Prince Said Ibrahim In Airport",
    "lat": "113201S",
    "long": "0431619E"
  },
  {
    "stationcode": "YVA",
    "stationname": "Iconi Airport",
    "lat": "114247S",
    "long": "0431434E"
  },
  {
    "stationcode": "AJN",
    "stationname": "Ouani Airport",
    "lat": "120752S",
    "long": "0442547E"
  },
  {
    "stationcode": "NWA",
    "stationname": "Moheli Airport",
    "lat": "121753S",
    "long": "0434559E"
  },
  {
    "stationcode": "NEV",
    "stationname": "Newcastle Airport",
    "lat": "171220N",
    "long": "0623524W"
  },
  {
    "stationcode": "SKB",
    "stationname": "Golden Rock Airport",
    "lat": "171840N",
    "long": "0624307W"
  },
  {
    "stationcode": "UJU",
    "stationname": "Uiju Airport",
    "lat": "400906N",
    "long": "1242954E"
  },
  {
    "stationcode": "RGO",
    "stationname": "Orang Airport",
    "lat": "412603N",
    "long": "1293804E"
  },
  {
    "stationcode": "DSO",
    "stationname": "Sondok Airport",
    "lat": "394101N",
    "long": "1272804E"
  },
  {
    "stationcode": "YJS",
    "stationname": "Samjiyon Airport",
    "lat": "415408N",
    "long": "1282506E"
  },
  {
    "stationcode": "FNJ",
    "stationname": "Sunan",
    "lat": "391325N",
    "long": "1254012E"
  },
  {
    "stationcode": "WOS",
    "stationname": "Wonson Airport",
    "lat": "390904N",
    "long": "1272905E"
  },
  {
    "stationcode": "HMY",
    "stationname": "Seosan Airport",
    "lat": "364245N",
    "long": "1263239E"
  },
  {
    "stationcode": "TAE",
    "stationname": "Daegu Airport",
    "lat": "355300N",
    "long": "1284000E"
  },
  {
    "stationcode": "SYS",
    "stationname": "Yeosu Airport",
    "lat": "715540N",
    "long": "1140448E"
  },
  {
    "stationcode": "UJN",
    "stationname": "Uljin Airport",
    "lat": "364637N",
    "long": "1292742E"
  },
  {
    "stationcode": "GMP",
    "stationname": "Gimpo International Airport",
    "lat": "373330N",
    "long": "1264726E"
  },
  {
    "stationcode": "CJJ",
    "stationname": "Cheongju Airport",
    "lat": "364300N",
    "long": "1273024E"
  },
  {
    "stationcode": "YNY",
    "stationname": "Yangyang Airport",
    "lat": "380341N",
    "long": "1284009E"
  },
  {
    "stationcode": "SWU",
    "stationname": "Suwon Airport",
    "lat": "371402N",
    "long": "1270005E"
  },
  {
    "stationcode": "KPO",
    "stationname": "Pohang Airport",
    "lat": "355916N",
    "long": "1292514E"
  },
  {
    "stationcode": "USN",
    "stationname": "Ulsan Airport",
    "lat": "353535N",
    "long": "1292120E"
  },
  {
    "stationcode": "YEC",
    "stationname": "Yecheon Airport",
    "lat": "363730N",
    "long": "1282118E"
  },
  {
    "stationcode": "SSN",
    "stationname": "Seoul Ab Airport",
    "lat": "372637N",
    "long": "1270646E"
  },
  {
    "stationcode": "HIN",
    "stationname": "Sacheon Airport",
    "lat": "351047N",
    "long": "1280627E"
  },
  {
    "stationcode": "MPK",
    "stationname": "Mokpo Airport",
    "lat": "344524N",
    "long": "1262306E"
  },
  {
    "stationcode": "KWJ",
    "stationname": "Gwangju Airport",
    "lat": "350735N",
    "long": "1264832E"
  },
  {
    "stationcode": "JDG",
    "stationname": "Jeongseok Airport",
    "lat": "332354N",
    "long": "1264247E"
  },
  {
    "stationcode": "KAG",
    "stationname": "Gangneung Airport",
    "lat": "374500N",
    "long": "1285700E"
  },
  {
    "stationcode": "SHO",
    "stationname": "Seolak Airport",
    "lat": "262125S",
    "long": "0314302E"
  },
  {
    "stationcode": "CHF",
    "stationname": "Jinhae Airport",
    "lat": "350800N",
    "long": "1284200E"
  },
  {
    "stationcode": "CHN",
    "stationname": "Jeonju Airport",
    "lat": "355243N",
    "long": "1270712E"
  },
  {
    "stationcode": "RSU",
    "stationname": "Yeosu Airport",
    "lat": "345032N",
    "long": "1273701E"
  },
  {
    "stationcode": "SUK",
    "stationname": "Samcheok Airport",
    "lat": "674732N",
    "long": "1302333E"
  },
  {
    "stationcode": "OSN",
    "stationname": "Ab Airport",
    "lat": "370527N",
    "long": "1270147E"
  },
  {
    "stationcode": "WJU",
    "stationname": "Wonju Airport",
    "lat": "372617N",
    "long": "1275737E"
  },
  {
    "stationcode": "KUZ",
    "stationname": "Gunsan Air Base",
    "lat": "655150N",
    "long": "0365944W"
  },
  {
    "stationcode": "KUV",
    "stationname": "Gunsan Airport",
    "lat": "355414N",
    "long": "1263657E"
  },
  {
    "stationcode": "KWI",
    "stationname": "Kuwait International Airport",
    "lat": "291336N",
    "long": "0475808E"
  },
  {
    "stationcode": "GCM",
    "stationname": "Owen Roberts International Airport",
    "lat": "191734N",
    "long": "0812128W"
  },
  {
    "stationcode": "CYB",
    "stationname": "Gerrard-Smith Airport",
    "lat": "194113N",
    "long": "0795258W"
  },
  {
    "stationcode": "LYB",
    "stationname": "Little Cayman Airport",
    "lat": "194000N",
    "long": "0800500W"
  },
  {
    "stationcode": "ZVK",
    "stationname": "Savannakhet Airport",
    "lat": "163339N",
    "long": "1044550E"
  },
  {
    "stationcode": "VGG",
    "stationname": "Vangrieng Airport",
    "lat": "623159N",
    "long": "0110100E"
  },
  {
    "stationcode": "PKS",
    "stationname": "Paksane Airport",
    "lat": "182200N",
    "long": "1034100E"
  },
  {
    "stationcode": "PCQ",
    "stationname": "Bounneua Airport",
    "lat": "213847N",
    "long": "1015400E"
  },
  {
    "stationcode": "KOG",
    "stationname": "Khong Airport",
    "lat": "140600N",
    "long": "1055000E"
  },
  {
    "stationcode": "AOU",
    "stationname": "Attopeu Airport",
    "lat": "144912N",
    "long": "1064914E"
  },
  {
    "stationcode": "LXG",
    "stationname": "Luang Namtha Airport",
    "lat": "205744N",
    "long": "1012411E"
  },
  {
    "stationcode": "XAY",
    "stationname": "Xayabury Airport",
    "lat": "184000N",
    "long": "1044300E"
  },
  {
    "stationcode": "PKZ",
    "stationname": "Pakse Airport",
    "lat": "150755N",
    "long": "1054653E"
  },
  {
    "stationcode": "HOE",
    "stationname": "Houeisay Airport",
    "lat": "201529N",
    "long": "1002614E"
  },
  {
    "stationcode": "NEU",
    "stationname": "Sam Neua Airport",
    "lat": "202507N",
    "long": "1040403E"
  },
  {
    "stationcode": "XKH",
    "stationname": "Xieng Khouang Airport",
    "lat": "192656N",
    "long": "1030934E"
  },
  {
    "stationcode": "ODY",
    "stationname": "Oudomxay Airport",
    "lat": "204058N",
    "long": "1015938E"
  },
  {
    "stationcode": "UDO",
    "stationname": "Udomxay Airport",
    "lat": "190800N",
    "long": "1034900E"
  },
  {
    "stationcode": "XIE",
    "stationname": "Xienglom Airport",
    "lat": "193700N",
    "long": "1004930E"
  },
  {
    "stationcode": "OUI",
    "stationname": "Ban Houel Airport",
    "lat": "482748N",
    "long": "0050350W"
  },
  {
    "stationcode": "VNG",
    "stationname": "Viengxay Airport",
    "lat": "185600N",
    "long": "1023000E"
  },
  {
    "stationcode": "ZBY",
    "stationname": "Sayaboury Airport",
    "lat": "191400N",
    "long": "1014400E"
  },
  {
    "stationcode": "SND",
    "stationname": "Seno Airport",
    "lat": "164000N",
    "long": "1050100E"
  },
  {
    "stationcode": "THK",
    "stationname": "Thakhek Airport",
    "lat": "172400N",
    "long": "1044900E"
  },
  {
    "stationcode": "UON",
    "stationname": "Muong Sai Airport",
    "lat": "204200N",
    "long": "1015900E"
  },
  {
    "stationcode": "VNA",
    "stationname": "Saravane Airport",
    "lat": "154200N",
    "long": "1062600E"
  },
  {
    "stationcode": "KYE",
    "stationname": "Kleyate Airport",
    "lat": "343510N",
    "long": "0360010E"
  },
  {
    "stationcode": "UVF",
    "stationname": "Hewanorra Airport",
    "lat": "134400N",
    "long": "0605709W"
  },
  {
    "stationcode": "SLU",
    "stationname": "Vigie Field",
    "lat": "140113N",
    "long": "0605935W"
  },
  {
    "stationcode": "MNH",
    "stationname": "Minneriya Airport",
    "lat": "233828N",
    "long": "0572911E"
  },
  {
    "stationcode": "WRZ",
    "stationname": "Weerawila Airport",
    "lat": "061516N",
    "long": "0811407E"
  },
  {
    "stationcode": "ACJ",
    "stationname": "Anuradhapura Airport",
    "lat": "081800N",
    "long": "0802536E"
  },
  {
    "stationcode": "JAF",
    "stationname": "Kankesanturai Airport",
    "lat": "094732N",
    "long": "0800412E"
  },
  {
    "stationcode": "KDW",
    "stationname": "Victoria Resevour Kandy Airport",
    "lat": "071550N",
    "long": "0804549E"
  },
  {
    "stationcode": "TRR",
    "stationname": "China Bay Airport",
    "lat": "083223N",
    "long": "0811055E"
  },
  {
    "stationcode": "KCT",
    "stationname": "Koggala Airport",
    "lat": "055937N",
    "long": "0801913E"
  },
  {
    "stationcode": "DIW",
    "stationname": "Mawella Lagoon Airport",
    "lat": "055927N",
    "long": "0804400E"
  },
  {
    "stationcode": "NUF",
    "stationname": "Castlereigh Reservoir Airport",
    "lat": "065126N",
    "long": "0803517E"
  },
  {
    "stationcode": "DWO",
    "stationname": "Diyawanna Oya Airport",
    "lat": "065420N",
    "long": "0795433E"
  },
  {
    "stationcode": "KEZ",
    "stationname": "Kelaniya River Airport",
    "lat": "065806N",
    "long": "0795252E"
  },
  {
    "stationcode": "ADP",
    "stationname": "Ampara Airport",
    "lat": "072015N",
    "long": "0813733E"
  },
  {
    "stationcode": "NUA",
    "stationname": "Gregory's Lake Airport",
    "lat": "065724N",
    "long": "0804648E"
  },
  {
    "stationcode": "KDZ",
    "stationname": "Polgolla Reservoir Airport",
    "lat": "071936N",
    "long": "0803830E"
  },
  {
    "stationcode": "BJT",
    "stationname": "Bentota River Airport",
    "lat": "062500N",
    "long": "0795900E"
  },
  {
    "stationcode": "DBU",
    "stationname": "Dambula Oya Tank Airport",
    "lat": "075157N",
    "long": "0803738E"
  },
  {
    "stationcode": "HTT",
    "stationname": "Mahinda Rajapakse Int'l Airport",
    "lat": "381519N",
    "long": "0905046E"
  },
  {
    "stationcode": "RML",
    "stationname": "Ratmalana Airport",
    "lat": "064916N",
    "long": "0795308E"
  },
  {
    "stationcode": "KDY",
    "stationname": "Mahaweli Airport",
    "lat": "624718N",
    "long": "1365105E"
  },
  {
    "stationcode": "GOY",
    "stationname": "Amparai Airport",
    "lat": "072000N",
    "long": "0813700E"
  },
  {
    "stationcode": "TTW",
    "stationname": "Tissa Tank Airport",
    "lat": "061706N",
    "long": "0811700E"
  },
  {
    "stationcode": "BTC",
    "stationname": "Batticaloa Airport",
    "lat": "074217N",
    "long": "0814036E"
  },
  {
    "stationcode": "AFK",
    "stationname": "Kondavattavan Tank Airport",
    "lat": "071731N",
    "long": "0813807E"
  },
  {
    "stationcode": "HIM",
    "stationname": "Hingurakgoda Airport",
    "lat": "080301N",
    "long": "0805856E"
  },
  {
    "stationcode": "KTY",
    "stationname": "Katukurunda Airport",
    "lat": "063310N",
    "long": "0795835E"
  },
  {
    "stationcode": "RVC",
    "stationname": "Rivercess Airport",
    "lat": "052800N",
    "long": "0093500W"
  },
  {
    "stationcode": "XSA",
    "stationname": "AFC Airport",
    "lat": "050100N",
    "long": "0090300W"
  },
  {
    "stationcode": "WES",
    "stationname": "Weasua Airport",
    "lat": "071700N",
    "long": "0102900W"
  },
  {
    "stationcode": "VOI",
    "stationname": "Voinjama Airport",
    "lat": "082500N",
    "long": "0094500W"
  },
  {
    "stationcode": "BYL",
    "stationname": "Bella Yella Airport",
    "lat": "072200N",
    "long": "0100000W"
  },
  {
    "stationcode": "GRC",
    "stationname": "Grand Cess Airport",
    "lat": "043600N",
    "long": "0081000W"
  },
  {
    "stationcode": "NIA",
    "stationname": "Nimba Airport",
    "lat": "073000N",
    "long": "0083500W"
  },
  {
    "stationcode": "MLW",
    "stationname": "Sprigg Payne Airport",
    "lat": "061721N",
    "long": "0104531W"
  },
  {
    "stationcode": "SNI",
    "stationname": "R. E. Murray Airport",
    "lat": "050200N",
    "long": "0090352W"
  },
  {
    "stationcode": "THC",
    "stationname": "Tchien Airport",
    "lat": "060400N",
    "long": "0080800W"
  },
  {
    "stationcode": "WOI",
    "stationname": "Wologissi Airport",
    "lat": "081600N",
    "long": "0094500W"
  },
  {
    "stationcode": "UCN",
    "stationname": "Buchanan Airport",
    "lat": "055400N",
    "long": "0100500W"
  },
  {
    "stationcode": "ROB",
    "stationname": "Roberts International Airport",
    "lat": "061402N",
    "long": "0102144W"
  },
  {
    "stationcode": "SAZ",
    "stationname": "Sasstown Airport",
    "lat": "044000N",
    "long": "0082600W"
  },
  {
    "stationcode": "FOY",
    "stationname": "Foya Airport",
    "lat": "082200N",
    "long": "0101300W"
  },
  {
    "stationcode": "TPT",
    "stationname": "Tapeta Airport",
    "lat": "063000N",
    "long": "0085200W"
  },
  {
    "stationcode": "CPA",
    "stationname": "A. Tubman Airport",
    "lat": "042300N",
    "long": "0074200W"
  },
  {
    "stationcode": "UTG",
    "stationname": "Quthing Airport",
    "lat": "303000S",
    "long": "0273600E"
  },
  {
    "stationcode": "MSU",
    "stationname": "Moshoeshoe International Airport",
    "lat": "292744S",
    "long": "0273309E"
  },
  {
    "stationcode": "THB",
    "stationname": "Thaba-Tseka Airport",
    "lat": "295200S",
    "long": "0281000E"
  },
  {
    "stationcode": "TKO",
    "stationname": "Tlokoeng Airport",
    "lat": "291400S",
    "long": "0285300E"
  },
  {
    "stationcode": "SOK",
    "stationname": "Semongkong Airport",
    "lat": "294400S",
    "long": "0284600E"
  },
  {
    "stationcode": "LES",
    "stationname": "Lesobeng Airport",
    "lat": "294800S",
    "long": "0280800E"
  },
  {
    "stationcode": "UNE",
    "stationname": "Qachas Airport",
    "lat": "300600S",
    "long": "0284200E"
  },
  {
    "stationcode": "NKU",
    "stationname": "Nkaus Airport",
    "lat": "300119S",
    "long": "0281149E"
  },
  {
    "stationcode": "SKQ",
    "stationname": "Sekakes Airport",
    "lat": "295800S",
    "long": "0282700E"
  },
  {
    "stationcode": "MKH",
    "stationname": "Mokhotlong Airport",
    "lat": "291700S",
    "long": "0290400E"
  },
  {
    "stationcode": "MFC",
    "stationname": "Mafeteng Airport",
    "lat": "294800S",
    "long": "0271500E"
  },
  {
    "stationcode": "LRB",
    "stationname": "Leribe Airport",
    "lat": "285800S",
    "long": "0280000E"
  },
  {
    "stationcode": "PEL",
    "stationname": "Pelaneng Airport",
    "lat": "303300S",
    "long": "0275500E"
  },
  {
    "stationcode": "SHK",
    "stationname": "Sehonghong Airport",
    "lat": "294300S",
    "long": "0284600E"
  },
  {
    "stationcode": "SHZ",
    "stationname": "Seshutes Airport",
    "lat": "303500S",
    "long": "0280000E"
  },
  {
    "stationcode": "MSG",
    "stationname": "Matsaile Airport",
    "lat": "294400S",
    "long": "0284500E"
  },
  {
    "stationcode": "LEF",
    "stationname": "Lebakeng Airport",
    "lat": "294700S",
    "long": "0283500E"
  },
  {
    "stationcode": "VNO",
    "stationname": "Vilnius Airport",
    "lat": "543803N",
    "long": "0251709E"
  },
  {
    "stationcode": "PLQ",
    "stationname": "Palanga International Airport",
    "lat": "555824N",
    "long": "0210538E"
  },
  {
    "stationcode": "KUN",
    "stationname": "Kaunas Airport",
    "lat": "545750N",
    "long": "0240505E"
  },
  {
    "stationcode": "SQQ",
    "stationname": "Siauliai Airport",
    "lat": "555403N",
    "long": "0232315E"
  },
  {
    "stationcode": "PNV",
    "stationname": "Panevezys Airport",
    "lat": "554400N",
    "long": "0242300E"
  },
  {
    "stationcode": "LUX",
    "stationname": "Luxembourg Airport",
    "lat": "493724N",
    "long": "0061216E"
  },
  {
    "stationcode": "VNT",
    "stationname": "Ventspils Airport",
    "lat": "572128N",
    "long": "0213239E"
  },
  {
    "stationcode": "LPX",
    "stationname": "Liepaya Airport",
    "lat": "563103N",
    "long": "0210549E"
  },
  {
    "stationcode": "RIX",
    "stationname": "Riga Airport",
    "lat": "565530N",
    "long": "0235820E"
  },
  {
    "stationcode": "DGP",
    "stationname": "Daugavpils Airport",
    "lat": "555642N",
    "long": "0264003E"
  },
  {
    "stationcode": "AKF",
    "stationname": "Kufrah Airport",
    "lat": "241200N",
    "long": "0232000E"
  },
  {
    "stationcode": "SRX",
    "stationname": "Sert Airport",
    "lat": "310348N",
    "long": "0163541E"
  },
  {
    "stationcode": "QUB",
    "stationname": "Ubari Airport",
    "lat": "263406N",
    "long": "0124904E"
  },
  {
    "stationcode": "LTD",
    "stationname": "Ghadames Airport",
    "lat": "300906N",
    "long": "0094255E"
  },
  {
    "stationcode": "NFR",
    "stationname": "Nafoora Airport",
    "lat": "291300N",
    "long": "0213500E"
  },
  {
    "stationcode": "HUQ",
    "stationname": "Houn Airport",
    "lat": "290300N",
    "long": "0155600E"
  },
  {
    "stationcode": "TOB",
    "stationname": "Tobruk Airport",
    "lat": "315118N",
    "long": "0235347E"
  },
  {
    "stationcode": "GHT",
    "stationname": "Ghat Airport",
    "lat": "250844N",
    "long": "0100834E"
  },
  {
    "stationcode": "QMQ",
    "stationname": "Muzuq Airport",
    "lat": "255500N",
    "long": "0135500E"
  },
  {
    "stationcode": "LMQ",
    "stationname": "Marsa el Brega Airport",
    "lat": "303000N",
    "long": "0191000E"
  },
  {
    "stationcode": "SEB",
    "stationname": "Sebha Airport",
    "lat": "265913N",
    "long": "0142821E"
  },
  {
    "stationcode": "LAQ",
    "stationname": "La Braq Airport",
    "lat": "324719N",
    "long": "0215752E"
  },
  {
    "stationcode": "MRA",
    "stationname": "Misurata Airport",
    "lat": "321919N",
    "long": "0150341E"
  },
  {
    "stationcode": "DNF",
    "stationname": "Martuba Airport",
    "lat": "323300N",
    "long": "0224600E"
  },
  {
    "stationcode": "ZIS",
    "stationname": "Zintan Airport",
    "lat": "314631N",
    "long": "0121458E"
  },
  {
    "stationcode": "TIP",
    "stationname": "Tripoli International Airport",
    "lat": "323949N",
    "long": "0130932E"
  },
  {
    "stationcode": "BCQ",
    "stationname": "Brack Airport",
    "lat": "274100N",
    "long": "0141500E"
  },
  {
    "stationcode": "BEN",
    "stationname": "Benina International Airport",
    "lat": "320548N",
    "long": "0201610E"
  },
  {
    "stationcode": "WAX",
    "stationname": "Zwara Airport",
    "lat": "325709N",
    "long": "0120056E"
  },
  {
    "stationcode": "MJI",
    "stationname": "Mitiga Tripoli Airport",
    "lat": "325336N",
    "long": "0131723E"
  },
  {
    "stationcode": "SII",
    "stationname": "Sidi Ifni Airport",
    "lat": "292200N",
    "long": "0101100W"
  },
  {
    "stationcode": "VIL",
    "stationname": "Dakhla Airport",
    "lat": "234306N",
    "long": "0155555W"
  },
  {
    "stationcode": "CAS",
    "stationname": "Anfa Airport",
    "lat": "333335N",
    "long": "0073947W"
  },
  {
    "stationcode": "SEK",
    "stationname": "Ksar Es Souk Airport",
    "lat": "672829N",
    "long": "1534346E"
  },
  {
    "stationcode": "TFY",
    "stationname": "Tarfaya Airport",
    "lat": "051240S",
    "long": "1035611E"
  },
  {
    "stationcode": "OZG",
    "stationname": "Zagora Airport",
    "lat": "301550N",
    "long": "0055111W"
  },
  {
    "stationcode": "NDR",
    "stationname": "Nador Airport",
    "lat": "345920N",
    "long": "0030142W"
  },
  {
    "stationcode": "EUN",
    "stationname": "Hassan I Airport",
    "lat": "270906N",
    "long": "0131309W"
  },
  {
    "stationcode": "SFI",
    "stationname": "Safi Airport",
    "lat": "321800N",
    "long": "0092000W"
  },
  {
    "stationcode": "ESU",
    "stationname": "Essaouira Airport",
    "lat": "312351N",
    "long": "0094054W"
  },
  {
    "stationcode": "FEZ",
    "stationname": "Sais Airport",
    "lat": "335600N",
    "long": "0045800W"
  },
  {
    "stationcode": "AGA",
    "stationname": "Agadir Almassira Airport",
    "lat": "301930N",
    "long": "0092447W"
  },
  {
    "stationcode": "RBA",
    "stationname": "Sale Airport",
    "lat": "340300N",
    "long": "0064525W"
  },
  {
    "stationcode": "MEK",
    "stationname": "Meknes Airport",
    "lat": "335300N",
    "long": "0053100W"
  },
  {
    "stationcode": "TTU",
    "stationname": "Sania Ramel Airport",
    "lat": "353524N",
    "long": "0051941W"
  },
  {
    "stationcode": "TTA",
    "stationname": "Tan Tan Airport",
    "lat": "282654N",
    "long": "0110941W"
  },
  {
    "stationcode": "ERH",
    "stationname": "Errachidia Airport",
    "lat": "315649N",
    "long": "0042356W"
  },
  {
    "stationcode": "AHU",
    "stationname": "Charif Al Idrissi Airport",
    "lat": "351038N",
    "long": "0035022W"
  },
  {
    "stationcode": "OZZ",
    "stationname": "Ouarzazate Airport",
    "lat": "305621N",
    "long": "0065434W"
  },
  {
    "stationcode": "CMN",
    "stationname": "Mohamed V Airport",
    "lat": "332203N",
    "long": "0073524W"
  },
  {
    "stationcode": "SMW",
    "stationname": "Smara Airport",
    "lat": "264400N",
    "long": "0114100W"
  },
  {
    "stationcode": "RAK",
    "stationname": "Menara Airport",
    "lat": "313625N",
    "long": "0080211W"
  },
  {
    "stationcode": "TNG",
    "stationname": "Boukhalef Airport",
    "lat": "354300N",
    "long": "0055500W"
  },
  {
    "stationcode": "GLN",
    "stationname": "Goulimime Airport",
    "lat": "290136N",
    "long": "0100301W"
  },
  {
    "stationcode": "GMD",
    "stationname": "Ben Slimane Airport",
    "lat": "333915N",
    "long": "0071332W"
  },
  {
    "stationcode": "UAR",
    "stationname": "Bouarfa Airport",
    "lat": "323051N",
    "long": "0015854W"
  },
  {
    "stationcode": "OUD",
    "stationname": "Les Angades Airport",
    "lat": "344714N",
    "long": "0015526W"
  },
  {
    "stationcode": "KIV",
    "stationname": "Chisinau Airport",
    "lat": "465540N",
    "long": "0285551E"
  },
  {
    "stationcode": "BZY",
    "stationname": "Beltsy Airport",
    "lat": "474500N",
    "long": "0275600E"
  },
  {
    "stationcode": "TGD",
    "stationname": "Golubovci Airport",
    "lat": "422130N",
    "long": "0191507E"
  },
  {
    "stationcode": "TIV",
    "stationname": "Tivat Airport",
    "lat": "422417N",
    "long": "0184324E"
  },
  {
    "stationcode": "ZBK",
    "stationname": "Zabljak Airport",
    "lat": "430700N",
    "long": "0191400E"
  },
  {
    "stationcode": "IVG",
    "stationname": "Ivangrad Airport",
    "lat": "425021N",
    "long": "0195144E"
  },
  {
    "stationcode": "HNO",
    "stationname": "Hercegnovi Airport",
    "lat": "423000N",
    "long": "0183300E"
  },
  {
    "stationcode": "SFG",
    "stationname": "Esperance Airport",
    "lat": "180603N",
    "long": "0630257W"
  },
  {
    "stationcode": "CCE",
    "stationname": "Grand Case Airport",
    "lat": "180300N",
    "long": "0630700W"
  },
  {
    "stationcode": "NKO",
    "stationname": "Ankokoambo Airport",
    "lat": "163500S",
    "long": "0442700E"
  },
  {
    "stationcode": "TZO",
    "stationname": "Tsimiroro Airport",
    "lat": "182055S",
    "long": "0450041E"
  },
  {
    "stationcode": "HVA",
    "stationname": "Analalava Airport",
    "lat": "143800S",
    "long": "0474600E"
  },
  {
    "stationcode": "AHY",
    "stationname": "Ambatolahy Airport",
    "lat": "200100S",
    "long": "0453200E"
  },
  {
    "stationcode": "OVA",
    "stationname": "Bekily Airport",
    "lat": "241400S",
    "long": "0451800E"
  },
  {
    "stationcode": "ILK",
    "stationname": "Ilaka Airport",
    "lat": "194500S",
    "long": "0484900E"
  },
  {
    "stationcode": "WOR",
    "stationname": "Moramba Airport",
    "lat": "145300S",
    "long": "0471700E"
  },
  {
    "stationcode": "VVB",
    "stationname": "Mahanoro Airport",
    "lat": "195000S",
    "long": "0484800E"
  },
  {
    "stationcode": "WTS",
    "stationname": "Tsiroanomandidy Airport",
    "lat": "184500S",
    "long": "0460300E"
  },
  {
    "stationcode": "BMD",
    "stationname": "Belo Airport",
    "lat": "194113S",
    "long": "0443234E"
  },
  {
    "stationcode": "AMY",
    "stationname": "Ambatomainty Airport",
    "lat": "174100S",
    "long": "0454000E"
  },
  {
    "stationcode": "WBO",
    "stationname": "Beroroha Airport",
    "lat": "213600S",
    "long": "0450800E"
  },
  {
    "stationcode": "TDV",
    "stationname": "Tanandava Airport",
    "lat": "214200S",
    "long": "0434400E"
  },
  {
    "stationcode": "DOA",
    "stationname": "Doany Airport",
    "lat": "142200S",
    "long": "0493100E"
  },
  {
    "stationcode": "BKU",
    "stationname": "Betioky Airport",
    "lat": "234400S",
    "long": "0442300E"
  },
  {
    "stationcode": "TVA",
    "stationname": "Morafenobe Airport",
    "lat": "175100S",
    "long": "0445500E"
  },
  {
    "stationcode": "ATJ",
    "stationname": "Antsirabe Airport",
    "lat": "195016S",
    "long": "0470355E"
  },
  {
    "stationcode": "MJA",
    "stationname": "Manja Airport",
    "lat": "212500S",
    "long": "0441900E"
  },
  {
    "stationcode": "WTA",
    "stationname": "Tambohorano Airport",
    "lat": "172900S",
    "long": "0435800E"
  },
  {
    "stationcode": "NOS",
    "stationname": "Fascene Airport",
    "lat": "131840S",
    "long": "0481850E"
  },
  {
    "stationcode": "AMP",
    "stationname": "Ampanihy Airport",
    "lat": "244200S",
    "long": "0444400E"
  },
  {
    "stationcode": "RVA",
    "stationname": "Farafangana Airport",
    "lat": "224819S",
    "long": "0474914E"
  },
  {
    "stationcode": "WAM",
    "stationname": "Ambatondrazaka Airport",
    "lat": "174800S",
    "long": "0482600E"
  },
  {
    "stationcode": "IVA",
    "stationname": "Ambanja Airport",
    "lat": "132906S",
    "long": "0483758E"
  },
  {
    "stationcode": "WBE",
    "stationname": "Bealanana Airport",
    "lat": "143200S",
    "long": "0484200E"
  },
  {
    "stationcode": "TLE",
    "stationname": "Tulear Airport",
    "lat": "232323S",
    "long": "0434327E"
  },
  {
    "stationcode": "WMN",
    "stationname": "Maroantsetra Airport",
    "lat": "152612S",
    "long": "0494118E"
  },
  {
    "stationcode": "WMV",
    "stationname": "Madirovalo Airport",
    "lat": "162700S",
    "long": "0463400E"
  },
  {
    "stationcode": "VOH",
    "stationname": "Vohemar Airport",
    "lat": "132200S",
    "long": "0500000E"
  },
  {
    "stationcode": "WAI",
    "stationname": "Antsohihy Airport",
    "lat": "145356S",
    "long": "0475938E"
  },
  {
    "stationcode": "BPY",
    "stationname": "Besalampy Airport",
    "lat": "164431S",
    "long": "0442853E"
  },
  {
    "stationcode": "ZVA",
    "stationname": "Miandrivazo Airport",
    "lat": "193300S",
    "long": "0452700E"
  },
  {
    "stationcode": "ANM",
    "stationname": "Antsirabato",
    "lat": "150000S",
    "long": "0501900E"
  },
  {
    "stationcode": "SMS",
    "stationname": "Sainte Marie Airport",
    "lat": "170500S",
    "long": "0494900E"
  },
  {
    "stationcode": "WVK",
    "stationname": "Manakara Airport",
    "lat": "220711S",
    "long": "0480118E"
  },
  {
    "stationcode": "TTS",
    "stationname": "Tsaratanana Airport",
    "lat": "164400S",
    "long": "0473700E"
  },
  {
    "stationcode": "OHB",
    "stationname": "Ambohibary Airport",
    "lat": "185501S",
    "long": "0484307E"
  },
  {
    "stationcode": "WAQ",
    "stationname": "Antsalova Airport",
    "lat": "184200S",
    "long": "0443700E"
  },
  {
    "stationcode": "DVD",
    "stationname": "Andavadoaka Airport",
    "lat": "220442S",
    "long": "0431533E"
  },
  {
    "stationcode": "DIE",
    "stationname": "Antsiranana/Arrachart Airport",
    "lat": "122046S",
    "long": "0491733E"
  },
  {
    "stationcode": "VAT",
    "stationname": "Vatomandry Airport",
    "lat": "192300S",
    "long": "0485700E"
  },
  {
    "stationcode": "VND",
    "stationname": "Vangaindrano Airport",
    "lat": "232100S",
    "long": "0473400E"
  },
  {
    "stationcode": "TMM",
    "stationname": "Tamatave Airport",
    "lat": "180634S",
    "long": "0492333E"
  },
  {
    "stationcode": "WML",
    "stationname": "Malaimbandy Airport",
    "lat": "202100S",
    "long": "0453300E"
  },
  {
    "stationcode": "DWB",
    "stationname": "Soalala Airport",
    "lat": "160600S",
    "long": "0452200E"
  },
  {
    "stationcode": "WMP",
    "stationname": "Mampikony Airport",
    "lat": "160430S",
    "long": "0473850E"
  },
  {
    "stationcode": "SVB",
    "stationname": "Sambava Airport",
    "lat": "141643S",
    "long": "0501029E"
  },
  {
    "stationcode": "MXT",
    "stationname": "Maintirano Airport",
    "lat": "180303S",
    "long": "0440156E"
  },
  {
    "stationcode": "FTU",
    "stationname": "Marillac Airport",
    "lat": "250217S",
    "long": "0465722E"
  },
  {
    "stationcode": "WAD",
    "stationname": "Andriamena Airport",
    "lat": "172700S",
    "long": "0473100E"
  },
  {
    "stationcode": "WMA",
    "stationname": "Mandritsara Airport",
    "lat": "155000S",
    "long": "0485000E"
  },
  {
    "stationcode": "MOQ",
    "stationname": "Morondava Airport",
    "lat": "201705S",
    "long": "0441903E"
  },
  {
    "stationcode": "WBD",
    "stationname": "Befandriana Airport",
    "lat": "151200S",
    "long": "0482900E"
  },
  {
    "stationcode": "WMD",
    "stationname": "Mandabe Airport",
    "lat": "210200S",
    "long": "0445700E"
  },
  {
    "stationcode": "WPB",
    "stationname": "Port Berge Airport",
    "lat": "153500S",
    "long": "0473700E"
  },
  {
    "stationcode": "IHO",
    "stationname": "Ihosy Airport",
    "lat": "222405S",
    "long": "0460800E"
  },
  {
    "stationcode": "MNJ",
    "stationname": "Mananjary Airport",
    "lat": "211206S",
    "long": "0482130E"
  },
  {
    "stationcode": "ZWA",
    "stationname": "Andapa Airport",
    "lat": "143900S",
    "long": "0493700E"
  },
  {
    "stationcode": "RLR",
    "stationname": "Relais de la Reine Airport",
    "lat": "223843S",
    "long": "0451925E"
  },
  {
    "stationcode": "WAK",
    "stationname": "Ankazoabo Airport",
    "lat": "221800S",
    "long": "0443200E"
  },
  {
    "stationcode": "TNR",
    "stationname": "Madagascar",
    "lat": "184800S",
    "long": "0472900E"
  },
  {
    "stationcode": "AMB",
    "stationname": "Ambilobe Airport",
    "lat": "131100S",
    "long": "0485900E"
  },
  {
    "stationcode": "WFI",
    "stationname": "Fianarantsoa Airport",
    "lat": "212630S",
    "long": "0470642E"
  },
  {
    "stationcode": "WMR",
    "stationname": "Mananara Airport",
    "lat": "160948S",
    "long": "0494612E"
  },
  {
    "stationcode": "JVA",
    "stationname": "Ankavandra Airport",
    "lat": "184800S",
    "long": "0451700E"
  },
  {
    "stationcode": "BSV",
    "stationname": "Besakoa Airport",
    "lat": "154000S",
    "long": "0470400E"
  },
  {
    "stationcode": "MXM",
    "stationname": "Morombe Airport",
    "lat": "214505S",
    "long": "0432235E"
  },
  {
    "stationcode": "MJN",
    "stationname": "Amborovy Airport",
    "lat": "153957S",
    "long": "0462111E"
  },
  {
    "stationcode": "KWA",
    "stationname": "Kwajalein Airport",
    "lat": "084312N",
    "long": "1674354E"
  },
  {
    "stationcode": "MIJ",
    "stationname": "Mili Island Airport",
    "lat": "060505N",
    "long": "1714351E"
  },
  {
    "stationcode": "AMR",
    "stationname": "Arno Airport",
    "lat": "365005N",
    "long": "0022723W"
  },
  {
    "stationcode": "EBN",
    "stationname": "Ebadon Airport",
    "lat": "092000N",
    "long": "1664800E"
  },
  {
    "stationcode": "WTO",
    "stationname": "Wotho Airport",
    "lat": "101025N",
    "long": "1660010E"
  },
  {
    "stationcode": "UIT",
    "stationname": "Jaluit Airport",
    "lat": "055433N",
    "long": "1693814E"
  },
  {
    "stationcode": "NMU",
    "stationname": "Namu Airport",
    "lat": "080000N",
    "long": "1680800E"
  },
  {
    "stationcode": "JEJ",
    "stationname": "Jeh Airport",
    "lat": "073354N",
    "long": "1685743E"
  },
  {
    "stationcode": "LML",
    "stationname": "Lae Airport",
    "lat": "085519N",
    "long": "1661600E"
  },
  {
    "stationcode": "EBO",
    "stationname": "Ebon Airport",
    "lat": "043553N",
    "long": "1684506E"
  },
  {
    "stationcode": "KIO",
    "stationname": "Kili Island Airport",
    "lat": "053838N",
    "long": "1690728E"
  },
  {
    "stationcode": "UJE",
    "stationname": "Ujae Island Airport",
    "lat": "085543N",
    "long": "1654547E"
  },
  {
    "stationcode": "IMI",
    "stationname": "Ine Island Airport",
    "lat": "065900N",
    "long": "1713700E"
  },
  {
    "stationcode": "TBV",
    "stationname": "Tabal Airport",
    "lat": "100200N",
    "long": "1695900E"
  },
  {
    "stationcode": "JAT",
    "stationname": "Jabot Airport",
    "lat": "070000N",
    "long": "1685900E"
  },
  {
    "stationcode": "EAL",
    "stationname": "Elenak Airport",
    "lat": "092349N",
    "long": "1672815E"
  },
  {
    "stationcode": "LOF",
    "stationname": "Loen Airport",
    "lat": "074800N",
    "long": "1681700E"
  },
  {
    "stationcode": "WTE",
    "stationname": "Wotje Airport",
    "lat": "092731N",
    "long": "1701419E"
  },
  {
    "stationcode": "MJB",
    "stationname": "Mejit Island Airport",
    "lat": "101700N",
    "long": "1705207E"
  },
  {
    "stationcode": "BII",
    "stationname": "Enyu Airfield",
    "lat": "113122N",
    "long": "1653356E"
  },
  {
    "stationcode": "UTK",
    "stationname": "Utirik Island Airport",
    "lat": "111320N",
    "long": "1695103E"
  },
  {
    "stationcode": "AIC",
    "stationname": "Airok Airport",
    "lat": "071646N",
    "long": "1684933E"
  },
  {
    "stationcode": "LIK",
    "stationname": "Likiep Airport",
    "lat": "094924N",
    "long": "1691827E"
  },
  {
    "stationcode": "TIC",
    "stationname": "Tinak Airport",
    "lat": "070300N",
    "long": "1714700E"
  },
  {
    "stationcode": "MJE",
    "stationname": "Majkin Airport",
    "lat": "080948N",
    "long": "1681028E"
  },
  {
    "stationcode": "MAJ",
    "stationname": "Amata Kabua International Airport",
    "lat": "070353N",
    "long": "1711619E"
  },
  {
    "stationcode": "ENT",
    "stationname": "Enewetak Island Airport",
    "lat": "112027N",
    "long": "1621940E"
  },
  {
    "stationcode": "AIP",
    "stationname": "Ailinglapalap Island Airport",
    "lat": "312558N",
    "long": "0754532E"
  },
  {
    "stationcode": "EJT",
    "stationname": "Enijet Airport",
    "lat": "060226N",
    "long": "1715859E"
  },
  {
    "stationcode": "RNP",
    "stationname": "Rongelap Airport",
    "lat": "110927N",
    "long": "1665315E"
  },
  {
    "stationcode": "KBT",
    "stationname": "Kaben Airport",
    "lat": "085403N",
    "long": "1705037E"
  },
  {
    "stationcode": "NDK",
    "stationname": "Namdrik Island Airport",
    "lat": "053759N",
    "long": "1680728E"
  },
  {
    "stationcode": "MAV",
    "stationname": "Maloelap Airport",
    "lat": "084220N",
    "long": "1711351E"
  },
  {
    "stationcode": "AUL",
    "stationname": "Aur Island Airport",
    "lat": "081809N",
    "long": "1710941E"
  },
  {
    "stationcode": "AIM",
    "stationname": "Ailuk Island Airport",
    "lat": "101301N",
    "long": "1695901E"
  },
  {
    "stationcode": "WJA",
    "stationname": "Woja Airport",
    "lat": "072704N",
    "long": "1683301E"
  },
  {
    "stationcode": "OHD",
    "stationname": "Ohrid Airport",
    "lat": "411106N",
    "long": "0204435E"
  },
  {
    "stationcode": "SKP",
    "stationname": "Skopje Airport",
    "lat": "415800N",
    "long": "0213800E"
  },
  {
    "stationcode": "KNZ",
    "stationname": "Kenieba Airport",
    "lat": "125000N",
    "long": "0111500W"
  },
  {
    "stationcode": "KYS",
    "stationname": "Kayes Airport",
    "lat": "142854N",
    "long": "0112417W"
  },
  {
    "stationcode": "EYL",
    "stationname": "Yelimane Airport",
    "lat": "150800N",
    "long": "0103400W"
  },
  {
    "stationcode": "KTX",
    "stationname": "Koutiala Airport",
    "lat": "122300N",
    "long": "0052800W"
  },
  {
    "stationcode": "GAQ",
    "stationname": "Gao Airport",
    "lat": "161454N",
    "long": "0000020W"
  },
  {
    "stationcode": "NRM",
    "stationname": "Nara Airport",
    "lat": "151500N",
    "long": "0071700W"
  },
  {
    "stationcode": "SZU",
    "stationname": "Segou Airport",
    "lat": "132600N",
    "long": "0061700W"
  },
  {
    "stationcode": "MZI",
    "stationname": "Mopti Airport",
    "lat": "143046N",
    "long": "0040446W"
  },
  {
    "stationcode": "KSS",
    "stationname": "Sikasso Airport",
    "lat": "111900N",
    "long": "0054000W"
  },
  {
    "stationcode": "GUD",
    "stationname": "Goundam Airport",
    "lat": "162100N",
    "long": "0033600W"
  },
  {
    "stationcode": "BKO",
    "stationname": "Bamako Airport",
    "lat": "123201N",
    "long": "0075700W"
  },
  {
    "stationcode": "NIX",
    "stationname": "Nioro Airport",
    "lat": "151410N",
    "long": "0093500W"
  },
  {
    "stationcode": "TOM",
    "stationname": "Tombouctou Airport",
    "lat": "164402N",
    "long": "0035955W"
  },
  {
    "stationcode": "TVY",
    "stationname": "Dawe Airport",
    "lat": "140554N",
    "long": "0981219E"
  },
  {
    "stationcode": "NYW",
    "stationname": "Monywa Airport",
    "lat": "221320N",
    "long": "0950535E"
  },
  {
    "stationcode": "TIO",
    "stationname": "Tilin Airport",
    "lat": "214200N",
    "long": "0940400E"
  },
  {
    "stationcode": "XYE",
    "stationname": "Ye Airport",
    "lat": "151500N",
    "long": "0975100E"
  },
  {
    "stationcode": "KHM",
    "stationname": "Khamtis Airport",
    "lat": "255916N",
    "long": "0954027E"
  },
  {
    "stationcode": "SNW",
    "stationname": "Thandwe Airport",
    "lat": "182739N",
    "long": "0941800E"
  },
  {
    "stationcode": "BPE",
    "stationname": "Bagan Airport",
    "lat": "393959N",
    "long": "1190336E"
  },
  {
    "stationcode": "PBU",
    "stationname": "Putao Airport",
    "lat": "271948N",
    "long": "0972535E"
  },
  {
    "stationcode": "VBP",
    "stationname": "Bokpyin Airport",
    "lat": "110857N",
    "long": "0984410E"
  },
  {
    "stationcode": "HOX",
    "stationname": "Homalin Airport",
    "lat": "245355N",
    "long": "0945453E"
  },
  {
    "stationcode": "NMT",
    "stationname": "Namtu Airport",
    "lat": "230500N",
    "long": "0972400E"
  },
  {
    "stationcode": "KMV",
    "stationname": "Kalemyo Airport",
    "lat": "231120N",
    "long": "0940307E"
  },
  {
    "stationcode": "THL",
    "stationname": "Tachilek Airport",
    "lat": "202858N",
    "long": "0995600E"
  },
  {
    "stationcode": "PPU",
    "stationname": "Papun Airport",
    "lat": "180400N",
    "long": "0972700E"
  },
  {
    "stationcode": "PRU",
    "stationname": "Prome Airport",
    "lat": "185600N",
    "long": "0951300E"
  },
  {
    "stationcode": "KET",
    "stationname": "Keng Tung Airport",
    "lat": "211806N",
    "long": "0993810E"
  },
  {
    "stationcode": "VBC",
    "stationname": "Chan Mya Thazi Airport",
    "lat": "215625N",
    "long": "0960522E"
  },
  {
    "stationcode": "MOG",
    "stationname": "Mong Hsat Airport",
    "lat": "203106N",
    "long": "0991530E"
  },
  {
    "stationcode": "MGU",
    "stationname": "Manaung Airport",
    "lat": "185047N",
    "long": "0934100E"
  },
  {
    "stationcode": "LSH",
    "stationname": "Lashio Airport",
    "lat": "225840N",
    "long": "0974508E"
  },
  {
    "stationcode": "GAW",
    "stationname": "Gangaw Airport",
    "lat": "221000N",
    "long": "0940800E"
  },
  {
    "stationcode": "VBA",
    "stationname": "Ann Airport",
    "lat": "194612N",
    "long": "0940134E"
  },
  {
    "stationcode": "MGZ",
    "stationname": "Myeik Airport",
    "lat": "122623N",
    "long": "0983717E"
  },
  {
    "stationcode": "MOE",
    "stationname": "Momeik Airport",
    "lat": "230600N",
    "long": "0963900E"
  },
  {
    "stationcode": "BMO",
    "stationname": "Bhamo Airport",
    "lat": "241600N",
    "long": "0971500E"
  },
  {
    "stationcode": "GWA",
    "stationname": "Gwa Airport",
    "lat": "175000N",
    "long": "0943000E"
  },
  {
    "stationcode": "NMS",
    "stationname": "Namsang Airport",
    "lat": "205328N",
    "long": "0974406E"
  },
  {
    "stationcode": "MYT",
    "stationname": "Myitkyina Airport",
    "lat": "252303N",
    "long": "0972114E"
  },
  {
    "stationcode": "KYP",
    "stationname": "Kyaukpyu Airport",
    "lat": "192535N",
    "long": "0933205E"
  },
  {
    "stationcode": "MGK",
    "stationname": "Mong Ton Airport",
    "lat": "201700N",
    "long": "0985500E"
  },
  {
    "stationcode": "BSX",
    "stationname": "Bassein Airport",
    "lat": "164842N",
    "long": "0944622E"
  },
  {
    "stationcode": "MNU",
    "stationname": "Maulmyine Airport",
    "lat": "162641N",
    "long": "0973938E"
  },
  {
    "stationcode": "HEB",
    "stationname": "Henzada Airport",
    "lat": "173834N",
    "long": "0952854E"
  },
  {
    "stationcode": "KAW",
    "stationname": "Kawthaung Airport",
    "lat": "100300N",
    "long": "0983100E"
  },
  {
    "stationcode": "MWQ",
    "stationname": "Magwe Airport",
    "lat": "200913N",
    "long": "0945807E"
  },
  {
    "stationcode": "PAU",
    "stationname": "Pauk Airport",
    "lat": "212700N",
    "long": "0943100E"
  },
  {
    "stationcode": "HEH",
    "stationname": "Heho Airport",
    "lat": "204449N",
    "long": "0964731E"
  },
  {
    "stationcode": "LIW",
    "stationname": "Loikaw Airport",
    "lat": "194129N",
    "long": "0971253E"
  },
  {
    "stationcode": "AKY",
    "stationname": "Civil Airport",
    "lat": "200758N",
    "long": "0925221E"
  },
  {
    "stationcode": "NYU",
    "stationname": "Nyaung-u Airport",
    "lat": "211039N",
    "long": "0945550E"
  },
  {
    "stationcode": "PAA",
    "stationname": "Pa-an Airport",
    "lat": "165338N",
    "long": "0974030E"
  },
  {
    "stationcode": "KYT",
    "stationname": "Kyauktaw Airport",
    "lat": "212400N",
    "long": "0940800E"
  },
  {
    "stationcode": "PKK",
    "stationname": "Pakokku Airport",
    "lat": "212000N",
    "long": "0950600E"
  },
  {
    "stationcode": "MXV",
    "stationname": "Moron Airport",
    "lat": "493948N",
    "long": "1000558E"
  },
  {
    "stationcode": "ERT",
    "stationname": "Erdenet Airport",
    "lat": "490400N",
    "long": "1040900E"
  },
  {
    "stationcode": "UUN",
    "stationname": "Baruun-Urt Airport",
    "lat": "463937N",
    "long": "1131707E"
  },
  {
    "stationcode": "ULZ",
    "stationname": "Uliastai Airport",
    "lat": "474237N",
    "long": "0963132E"
  },
  {
    "stationcode": "HBU",
    "stationname": "Hovd Airport",
    "lat": "460607N",
    "long": "0913503E"
  },
  {
    "stationcode": "UGA",
    "stationname": "Bulgan Airport",
    "lat": "484800N",
    "long": "1033300E"
  },
  {
    "stationcode": "HVD",
    "stationname": "Khovd Airport",
    "lat": "475715N",
    "long": "0913741E"
  },
  {
    "stationcode": "UNR",
    "stationname": "Underkhaan Airport",
    "lat": "471817N",
    "long": "1103632E"
  },
  {
    "stationcode": "COQ",
    "stationname": "Choibalsan Airport",
    "lat": "480808N",
    "long": "1143847E"
  },
  {
    "stationcode": "TNZ",
    "stationname": "Tosontsengel Airport",
    "lat": "484418N",
    "long": "0981746E"
  },
  {
    "stationcode": "ULO",
    "stationname": "Ulaangom Airport",
    "lat": "495814N",
    "long": "0920452E"
  },
  {
    "stationcode": "MXW",
    "stationname": "Mandalgobi Airport",
    "lat": "454600N",
    "long": "1061700E"
  },
  {
    "stationcode": "ULG",
    "stationname": "Ulgit Airport",
    "lat": "485936N",
    "long": "0895519E"
  },
  {
    "stationcode": "BYN",
    "stationname": "Bayankhongor Airport",
    "lat": "460955N",
    "long": "1004212E"
  },
  {
    "stationcode": "LTI",
    "stationname": "Altai Airport",
    "lat": "462236N",
    "long": "0961319E"
  },
  {
    "stationcode": "HTM",
    "stationname": "Khatgal Airport",
    "lat": "502700N",
    "long": "1001000E"
  },
  {
    "stationcode": "TSZ",
    "stationname": "Tsetserleg Airport",
    "lat": "474400N",
    "long": "1014600E"
  },
  {
    "stationcode": "HJT",
    "stationname": "Khujirt Airport",
    "lat": "465400N",
    "long": "1024600E"
  },
  {
    "stationcode": "ULN",
    "stationname": "Buyant Uhaa Airport",
    "lat": "475035N",
    "long": "1064600E"
  },
  {
    "stationcode": "AVK",
    "stationname": "Arvaikheer Airport",
    "lat": "461600N",
    "long": "1024700E"
  },
  {
    "stationcode": "DLZ",
    "stationname": "Dalanzadgad Airport",
    "lat": "433630N",
    "long": "1042219E"
  },
  {
    "stationcode": "UGT",
    "stationname": "Umnugobitour Airport",
    "lat": "434502N",
    "long": "1040713E"
  },
  {
    "stationcode": "KHR",
    "stationname": "Kharkhorin Airport",
    "lat": "471449N",
    "long": "1024934E"
  },
  {
    "stationcode": "SPN",
    "stationname": "Saipan International Airport",
    "lat": "150708N",
    "long": "1454346E"
  },
  {
    "stationcode": "ROP",
    "stationname": "Rota Airport",
    "lat": "141028N",
    "long": "1451433E"
  },
  {
    "stationcode": "TIQ",
    "stationname": "Tinian Airport",
    "lat": "145957N",
    "long": "1453710E"
  },
  {
    "stationcode": "FDF",
    "stationname": "Lamentin Airport",
    "lat": "143528N",
    "long": "0610011W"
  },
  {
    "stationcode": "ATR",
    "stationname": "Mouakchott Airport",
    "lat": "203000N",
    "long": "0130300W"
  },
  {
    "stationcode": "CGT",
    "stationname": "Chinguitti Airport",
    "lat": "202700N",
    "long": "0122200W"
  },
  {
    "stationcode": "MBR",
    "stationname": "Mbout Airport",
    "lat": "160000N",
    "long": "0123030W"
  },
  {
    "stationcode": "TIY",
    "stationname": "Tidjikja Airport",
    "lat": "183300N",
    "long": "0112600W"
  },
  {
    "stationcode": "AJJ",
    "stationname": "Akjoujt Airport",
    "lat": "194400N",
    "long": "0142300W"
  },
  {
    "stationcode": "EMN",
    "stationname": "Nema Airport",
    "lat": "163719N",
    "long": "0071900W"
  },
  {
    "stationcode": "MOM",
    "stationname": "Moudjeria Airport",
    "lat": "174500N",
    "long": "0123000W"
  },
  {
    "stationcode": "KFA",
    "stationname": "Kiffa Airport",
    "lat": "163500N",
    "long": "0112400W"
  },
  {
    "stationcode": "ZLG",
    "stationname": "El Gouera Airport",
    "lat": "205032N",
    "long": "0170442W"
  },
  {
    "stationcode": "THI",
    "stationname": "Tichitt Airport",
    "lat": "182700N",
    "long": "0093000W"
  },
  {
    "stationcode": "OTL",
    "stationname": "Boutilimit Airport",
    "lat": "173200N",
    "long": "0144300W"
  },
  {
    "stationcode": "NDB",
    "stationname": "Nouadhibou Airport",
    "lat": "205559N",
    "long": "0170148W"
  },
  {
    "stationcode": "FGD",
    "stationname": "Fderik Airport",
    "lat": "223000N",
    "long": "0124030W"
  },
  {
    "stationcode": "TMD",
    "stationname": "Timbedra Airport",
    "lat": "161400N",
    "long": "0081000W"
  },
  {
    "stationcode": "NKC",
    "stationname": "Nouakchott Airport",
    "lat": "180552N",
    "long": "0155653W"
  },
  {
    "stationcode": "LEG",
    "stationname": "Aleg Airport",
    "lat": "171000N",
    "long": "0135700W"
  },
  {
    "stationcode": "BGH",
    "stationname": "Abbaye Airport",
    "lat": "163800N",
    "long": "0141200W"
  },
  {
    "stationcode": "THT",
    "stationname": "Tamchakett Airport",
    "lat": "171400N",
    "long": "0104900W"
  },
  {
    "stationcode": "OUZ",
    "stationname": "Zouerate Airport",
    "lat": "224528N",
    "long": "0122834W"
  },
  {
    "stationcode": "AEO",
    "stationname": "Aioun El Atrouss Airport",
    "lat": "164200N",
    "long": "0093900W"
  },
  {
    "stationcode": "SEY",
    "stationname": "Selibaby Airport",
    "lat": "151100N",
    "long": "0121200W"
  },
  {
    "stationcode": "KED",
    "stationname": "Kaedi Airport",
    "lat": "161000N",
    "long": "0133100W"
  },
  {
    "stationcode": "MNI",
    "stationname": "Bramble Airport",
    "lat": "164729N",
    "long": "0621139W"
  },
  {
    "stationcode": "MLA",
    "stationname": "Luqa Airport",
    "lat": "355114N",
    "long": "0142859E"
  },
  {
    "stationcode": "GZM",
    "stationname": "Gozo Airport",
    "lat": "360202N",
    "long": "0141203E"
  },
  {
    "stationcode": "RRG",
    "stationname": "Rodrigues Island Airport",
    "lat": "194500S",
    "long": "0632100E"
  },
  {
    "stationcode": "VAM",
    "stationname": "Maamigili Airport",
    "lat": "032815N",
    "long": "0725012E"
  },
  {
    "stationcode": "GAN",
    "stationname": "Gan/Seenu Airport",
    "lat": "004136S",
    "long": "0730920E"
  },
  {
    "stationcode": "KDO",
    "stationname": "Kadhdhoo Airport",
    "lat": "015130N",
    "long": "0733111E"
  },
  {
    "stationcode": "DRV",
    "stationname": "Dharavandhoo Airport",
    "lat": "050925N",
    "long": "0730748E"
  },
  {
    "stationcode": "HRF",
    "stationname": "Hoarafushi",
    "lat": "065823N",
    "long": "0725340E"
  },
  {
    "stationcode": "KDM",
    "stationname": "Kaadedhdhoo Airport",
    "lat": "002900S",
    "long": "0730000E"
  },
  {
    "stationcode": "IFU",
    "stationname": "Ifuru Airport",
    "lat": "054226N",
    "long": "0730132E"
  },
  {
    "stationcode": "GKK",
    "stationname": "Kooddoo Island Airport",
    "lat": "004359N",
    "long": "0732603E"
  },
  {
    "stationcode": "HAQ",
    "stationname": "Hanimaadhoo Airport",
    "lat": "064447N",
    "long": "0731007E"
  },
  {
    "stationcode": "LMV",
    "stationname": "Madivaru Airport",
    "lat": "052734N",
    "long": "0732214E"
  },
  {
    "stationcode": "TMF",
    "stationname": "Thimarafushi Airport",
    "lat": "021238N",
    "long": "0730909E"
  },
  {
    "stationcode": "FVM",
    "stationname": "Fuvahmulak Airport",
    "lat": "001834S",
    "long": "0732558E"
  },
  {
    "stationcode": "DWA",
    "stationname": "Dwamawa Airport",
    "lat": "123105S",
    "long": "0340756E"
  },
  {
    "stationcode": "VUU",
    "stationname": "MVUU Camp",
    "lat": "145019S",
    "long": "0351807E"
  },
  {
    "stationcode": "KBQ",
    "stationname": "Kasungu Airport",
    "lat": "130100S",
    "long": "0332800E"
  },
  {
    "stationcode": "CEH",
    "stationname": "Chelinda Airport",
    "lat": "103326S",
    "long": "0334753E"
  },
  {
    "stationcode": "MYZ",
    "stationname": "Monkey Bay Airport",
    "lat": "140600S",
    "long": "0345500E"
  },
  {
    "stationcode": "CMK",
    "stationname": "Club Makokola Airport",
    "lat": "141824S",
    "long": "0350757E"
  },
  {
    "stationcode": "LMB",
    "stationname": "Salima Airport",
    "lat": "134500S",
    "long": "0343400E"
  },
  {
    "stationcode": "KGJ",
    "stationname": "Karonga Airport",
    "lat": "095700S",
    "long": "0335500E"
  },
  {
    "stationcode": "LLW",
    "stationname": "Lilongwe International Airport",
    "lat": "134700S",
    "long": "0334700E"
  },
  {
    "stationcode": "LIX",
    "stationname": "Likoma Airport",
    "lat": "120449S",
    "long": "0344411E"
  },
  {
    "stationcode": "MAI",
    "stationname": "Mangochi Airport",
    "lat": "142900S",
    "long": "0351600E"
  },
  {
    "stationcode": "BLZ",
    "stationname": "Chileka Airport",
    "lat": "154045S",
    "long": "0345826E"
  },
  {
    "stationcode": "ZZU",
    "stationname": "Mzuzu Airport",
    "lat": "112641S",
    "long": "0340042E"
  },
  {
    "stationcode": "TMG",
    "stationname": "Tomanggong Airport",
    "lat": "052400N",
    "long": "1183900E"
  },
  {
    "stationcode": "BLG",
    "stationname": "Belaga Airport",
    "lat": "023900N",
    "long": "1134600E"
  },
  {
    "stationcode": "GTK",
    "stationname": "Sungei Tekai Airport",
    "lat": "023600N",
    "long": "1025500E"
  },
  {
    "stationcode": "KGU",
    "stationname": "Keningau Airport",
    "lat": "052200N",
    "long": "1161000E"
  },
  {
    "stationcode": "BWH",
    "stationname": "Butterworth Airport",
    "lat": "052500N",
    "long": "1002400E"
  },
  {
    "stationcode": "PKG",
    "stationname": "Pangkor Airport",
    "lat": "041441N",
    "long": "1003312E"
  },
  {
    "stationcode": "MKM",
    "stationname": "Mukah Airport",
    "lat": "025425N",
    "long": "1120447E"
  },
  {
    "stationcode": "SXS",
    "stationname": "Sahabat 16 Airport",
    "lat": "050523N",
    "long": "1190530E"
  },
  {
    "stationcode": "MEP",
    "stationname": "Mersing Airport",
    "lat": "022600N",
    "long": "1035000E"
  },
  {
    "stationcode": "LLM",
    "stationname": "Long Lama Airport",
    "lat": "101633S",
    "long": "1661728E"
  },
  {
    "stationcode": "MZS",
    "stationname": "Mostyn Airport",
    "lat": "043700N",
    "long": "1180900E"
  },
  {
    "stationcode": "TPG",
    "stationname": "Taiping Airport",
    "lat": "045100N",
    "long": "1004400E"
  },
  {
    "stationcode": "LBP",
    "stationname": "Long Banga Airfield",
    "lat": "031208N",
    "long": "1152408E"
  },
  {
    "stationcode": "ODN",
    "stationname": "Long Seridan Airport",
    "lat": "035839N",
    "long": "1150406E"
  },
  {
    "stationcode": "LMN",
    "stationname": "Limbang Airport",
    "lat": "044829N",
    "long": "1150037E"
  },
  {
    "stationcode": "MZV",
    "stationname": "Mulu Airport",
    "lat": "040302N",
    "long": "1144833E"
  },
  {
    "stationcode": "LAC",
    "stationname": "Layang-Layang Airstrip",
    "lat": "072219N",
    "long": "1135041E"
  },
  {
    "stationcode": "SXT",
    "stationname": "Taman Negara Airport",
    "lat": "041950N",
    "long": "1022348E"
  },
  {
    "stationcode": "LWY",
    "stationname": "Lawas Airport",
    "lat": "045059N",
    "long": "1152429E"
  },
  {
    "stationcode": "BSE",
    "stationname": "Sematan Airport",
    "lat": "014800N",
    "long": "1094600E"
  },
  {
    "stationcode": "RNU",
    "stationname": "Ranau Airport",
    "lat": "055700N",
    "long": "1164000E"
  },
  {
    "stationcode": "LDU",
    "stationname": "Lahad Datu Airport",
    "lat": "050149N",
    "long": "1181942E"
  },
  {
    "stationcode": "GTB",
    "stationname": "Genting Airport",
    "lat": "020700N",
    "long": "1114200E"
  },
  {
    "stationcode": "BBN",
    "stationname": "Bario Airport",
    "lat": "034405N",
    "long": "1152842E"
  },
  {
    "stationcode": "TOD",
    "stationname": "Tioman Airport",
    "lat": "024909N",
    "long": "1040936E"
  },
  {
    "stationcode": "SMM",
    "stationname": "Semporna Airport",
    "lat": "042700N",
    "long": "1183600E"
  },
  {
    "stationcode": "KTE",
    "stationname": "Kerteh Airport",
    "lat": "043227N",
    "long": "1032533E"
  },
  {
    "stationcode": "TEL",
    "stationname": "Telupid Airport",
    "lat": "053747N",
    "long": "1170730E"
  },
  {
    "stationcode": "LKH",
    "stationname": "Long Akah Airport",
    "lat": "031843N",
    "long": "1144713E"
  },
  {
    "stationcode": "LSM",
    "stationname": "Lawas Airport",
    "lat": "041300N",
    "long": "1153600E"
  },
  {
    "stationcode": "GSA",
    "stationname": "Long Pasia Airport",
    "lat": "042434N",
    "long": "1154328E"
  },
  {
    "stationcode": "KUD",
    "stationname": "Kudat Airport",
    "lat": "065524N",
    "long": "1165014E"
  },
  {
    "stationcode": "PAY",
    "stationname": "Pamol Airport",
    "lat": "060000N",
    "long": "1172400E"
  },
  {
    "stationcode": "LSU",
    "stationname": "Long Sukang Airport",
    "lat": "043300N",
    "long": "1153000E"
  },
  {
    "stationcode": "MUR",
    "stationname": "Marudi Airport",
    "lat": "041046N",
    "long": "1141939E"
  },
  {
    "stationcode": "LGL",
    "stationname": "Long Lellang Airport",
    "lat": "032516N",
    "long": "1150912E"
  },
  {
    "stationcode": "SPT",
    "stationname": "Sipitang Airport",
    "lat": "050500N",
    "long": "1153300E"
  },
  {
    "stationcode": "SPE",
    "stationname": "Sepulot Airport",
    "lat": "044300N",
    "long": "1162800E"
  },
  {
    "stationcode": "SWY",
    "stationname": "Sitiawan Airport",
    "lat": "041300N",
    "long": "1004200E"
  },
  {
    "stationcode": "SGG",
    "stationname": "Simanggang Airport",
    "lat": "655421N",
    "long": "0362242W"
  },
  {
    "stationcode": "RDN",
    "stationname": "LTS Pulau Redang Airport",
    "lat": "054551N",
    "long": "1030017E"
  },
  {
    "stationcode": "KPI",
    "stationname": "Kapit Airport",
    "lat": "020000N",
    "long": "1125500E"
  },
  {
    "stationcode": "TGC",
    "stationname": "Tanjung Manis Airport",
    "lat": "021040N",
    "long": "1111207E"
  },
  {
    "stationcode": "BKM",
    "stationname": "Bakalalan Airport",
    "lat": "035826N",
    "long": "1153702E"
  },
  {
    "stationcode": "VPY",
    "stationname": "Chimoio Airport",
    "lat": "190905S",
    "long": "0332544E"
  },
  {
    "stationcode": "LBM",
    "stationname": "Luabo Airport",
    "lat": "183000S",
    "long": "0361000E"
  },
  {
    "stationcode": "POL",
    "stationname": "Pemba Airport",
    "lat": "125912S",
    "long": "0403121E"
  },
  {
    "stationcode": "VJB",
    "stationname": "Xai Xai Airport",
    "lat": "250200S",
    "long": "0333700E"
  },
  {
    "stationcode": "MJS",
    "stationname": "Maganja Da Costa Airport",
    "lat": "174100S",
    "long": "0373000E"
  },
  {
    "stationcode": "IBO",
    "stationname": "Ibo Airport",
    "lat": "122101S",
    "long": "0403609E"
  },
  {
    "stationcode": "PEB",
    "stationname": "Pebane Airport",
    "lat": "171500S",
    "long": "0381100E"
  },
  {
    "stationcode": "NTC",
    "stationname": "Santa Carolina Airport",
    "lat": "214000S",
    "long": "0353000E"
  },
  {
    "stationcode": "CMZ",
    "stationname": "Caia Airport",
    "lat": "175000S",
    "long": "0352100E"
  },
  {
    "stationcode": "MPM",
    "stationname": "Maputo International Airport",
    "lat": "255515S",
    "long": "0323421E"
  },
  {
    "stationcode": "BZB",
    "stationname": "Bazaruto Island Airport",
    "lat": "213205S",
    "long": "0352930E"
  },
  {
    "stationcode": "RRM",
    "stationname": "Marromeu Airport",
    "lat": "182000S",
    "long": "0355600E"
  },
  {
    "stationcode": "MTU",
    "stationname": "Montepuez Airport",
    "lat": "130740S",
    "long": "0390010E"
  },
  {
    "stationcode": "DGK",
    "stationname": "Dugong Beach Lodge Airport",
    "lat": "221038S",
    "long": "0352525E"
  },
  {
    "stationcode": "BEW",
    "stationname": "Beira Airport",
    "lat": "194747S",
    "long": "0345427E"
  },
  {
    "stationcode": "INH",
    "stationname": "Inhambane Airport",
    "lat": "235235S",
    "long": "0352431E"
  },
  {
    "stationcode": "UEL",
    "stationname": "Quelimane Airport",
    "lat": "175120S",
    "long": "0365209E"
  },
  {
    "stationcode": "IBL",
    "stationname": "Indigo Bay Lodge",
    "lat": "214207S",
    "long": "0352654E"
  },
  {
    "stationcode": "BCW",
    "stationname": "Benguera Island Airport",
    "lat": "215305S",
    "long": "0352530E"
  },
  {
    "stationcode": "MUD",
    "stationname": "Mueda Airport",
    "lat": "113900S",
    "long": "0393300E"
  },
  {
    "stationcode": "MFW",
    "stationname": "Magaruque Airport",
    "lat": "215820S",
    "long": "0353530E"
  },
  {
    "stationcode": "NND",
    "stationname": "Nangade Airport",
    "lat": "110500S",
    "long": "0393600E"
  },
  {
    "stationcode": "MMW",
    "stationname": "Moma Airport",
    "lat": "164500S",
    "long": "0391300E"
  },
  {
    "stationcode": "TGS",
    "stationname": "Chokwe Airport",
    "lat": "243700S",
    "long": "0322500E"
  },
  {
    "stationcode": "BJN",
    "stationname": "Bajone Airport",
    "lat": "171100S",
    "long": "0375700E"
  },
  {
    "stationcode": "PDD",
    "stationname": "Ponta De Ouro",
    "lat": "265000S",
    "long": "0325000E"
  },
  {
    "stationcode": "AME",
    "stationname": "Alto Molocue Airport",
    "lat": "153900S",
    "long": "0374100E"
  },
  {
    "stationcode": "VJQ",
    "stationname": "Gurue Airport",
    "lat": "153000S",
    "long": "0370100E"
  },
  {
    "stationcode": "VXC",
    "stationname": "Lichinga Airport",
    "lat": "131626S",
    "long": "0351559E"
  },
  {
    "stationcode": "APL",
    "stationname": "Nampula Airport",
    "lat": "150620S",
    "long": "0391654E"
  },
  {
    "stationcode": "IHC",
    "stationname": "Inhaca Airport",
    "lat": "260000S",
    "long": "0325600E"
  },
  {
    "stationcode": "INE",
    "stationname": "Chinde Airport",
    "lat": "183700S",
    "long": "0362400E"
  },
  {
    "stationcode": "VNX",
    "stationname": "Vilanculos Airport",
    "lat": "220106S",
    "long": "0351848E"
  },
  {
    "stationcode": "MNC",
    "stationname": "Nacala Airport",
    "lat": "142909S",
    "long": "0404245E"
  },
  {
    "stationcode": "TET",
    "stationname": "Matundo Airport",
    "lat": "160612S",
    "long": "0333820E"
  },
  {
    "stationcode": "TCV",
    "stationname": "Tete Airport",
    "lat": "232350N",
    "long": "0752949W"
  },
  {
    "stationcode": "VHO",
    "stationname": "Vila Coutinho Airport",
    "lat": "143700S",
    "long": "0341900E"
  },
  {
    "stationcode": "MZB",
    "stationname": "Mocimboa da Praia Airport",
    "lat": "112136S",
    "long": "0402121E"
  },
  {
    "stationcode": "LMZ",
    "stationname": "Palma Airport",
    "lat": "104600S",
    "long": "0402900E"
  },
  {
    "stationcode": "LFB",
    "stationname": "Lumbo Airport",
    "lat": "150141S",
    "long": "0404019E"
  },
  {
    "stationcode": "FXO",
    "stationname": "Cuamba Airport",
    "lat": "144855S",
    "long": "0363150E"
  },
  {
    "stationcode": "IDC",
    "stationname": "Chilonzuine Island Airport",
    "lat": "221050S",
    "long": "0353120E"
  },
  {
    "stationcode": "IMG",
    "stationname": "Inhaminga Airport",
    "lat": "182400S",
    "long": "0350000E"
  },
  {
    "stationcode": "ANO",
    "stationname": "Angoche Airport",
    "lat": "161000S",
    "long": "0395600E"
  },
  {
    "stationcode": "TSB",
    "stationname": "Tsumeb Airport",
    "lat": "191600S",
    "long": "0174400E"
  },
  {
    "stationcode": "OKF",
    "stationname": "Okaukuejo Airport",
    "lat": "191000S",
    "long": "0155600E"
  },
  {
    "stationcode": "OTJ",
    "stationname": "Otjiwarongo Airport",
    "lat": "202550S",
    "long": "0163945E"
  },
  {
    "stationcode": "WDH",
    "stationname": "Hosea Kutako International Airport",
    "lat": "222848S",
    "long": "0172815E"
  },
  {
    "stationcode": "OMD",
    "stationname": "Oranjemund Airport",
    "lat": "283512S",
    "long": "0162647E"
  },
  {
    "stationcode": "RHN",
    "stationname": "Rosh Pina Airport",
    "lat": "275800S",
    "long": "0164200E"
  },
  {
    "stationcode": "OKU",
    "stationname": "Mokuti Lodge Airport",
    "lat": "184830S",
    "long": "0170300E"
  },
  {
    "stationcode": "KAS",
    "stationname": "Karasburg Airport",
    "lat": "280147S",
    "long": "0184419E"
  },
  {
    "stationcode": "NDU",
    "stationname": "Rundu Airport",
    "lat": "175722S",
    "long": "0194322E"
  },
  {
    "stationcode": "SWP",
    "stationname": "Swakopmund Airport",
    "lat": "224000S",
    "long": "0143400E"
  },
  {
    "stationcode": "LHU",
    "stationname": "Lianshulu Airport",
    "lat": "180656S",
    "long": "0232330E"
  },
  {
    "stationcode": "OMG",
    "stationname": "Omega Airport",
    "lat": "180000S",
    "long": "0220400E"
  },
  {
    "stationcode": "OHI",
    "stationname": "Oshakati Airport",
    "lat": "174700S",
    "long": "0154200E"
  },
  {
    "stationcode": "GFY",
    "stationname": "Grootfontein Airport",
    "lat": "193600S",
    "long": "0180800E"
  },
  {
    "stationcode": "GOG",
    "stationname": "Gobabis Airport",
    "lat": "223017S",
    "long": "0185829E"
  },
  {
    "stationcode": "MPA",
    "stationname": "Mpacha Airport",
    "lat": "173800S",
    "long": "0241100E"
  },
  {
    "stationcode": "ERS",
    "stationname": "Eros Airport",
    "lat": "223644S",
    "long": "0170450E"
  },
  {
    "stationcode": "OPW",
    "stationname": "Opuwa Airport",
    "lat": "180400S",
    "long": "0135000E"
  },
  {
    "stationcode": "OGV",
    "stationname": "Ongava Game Reserve Airport",
    "lat": "191916S",
    "long": "0155407E"
  },
  {
    "stationcode": "TCY",
    "stationname": "Terrace Bay Airport",
    "lat": "195900S",
    "long": "0130000E"
  },
  {
    "stationcode": "LUD",
    "stationname": "Luderitz Airport",
    "lat": "264115S",
    "long": "0151434E"
  },
  {
    "stationcode": "BQI",
    "stationname": "Bagani Airport",
    "lat": "180705S",
    "long": "0213747E"
  },
  {
    "stationcode": "SZM",
    "stationname": "Sesriem Airport",
    "lat": "243500S",
    "long": "0155000E"
  },
  {
    "stationcode": "MQG",
    "stationname": "Midgard Airport",
    "lat": "220500S",
    "long": "0172200E"
  },
  {
    "stationcode": "MJO",
    "stationname": "Mount Etjo Lodge Airport",
    "lat": "210100S",
    "long": "0162800E"
  },
  {
    "stationcode": "HAL",
    "stationname": "Halali Airport",
    "lat": "195800S",
    "long": "0130400E"
  },
  {
    "stationcode": "WVB",
    "stationname": "Rooikop Airport",
    "lat": "225848S",
    "long": "0143843E"
  },
  {
    "stationcode": "OND",
    "stationname": "Ondangwa Airport",
    "lat": "175250S",
    "long": "0155650E"
  },
  {
    "stationcode": "AIW",
    "stationname": "Ai-Ais Airport",
    "lat": "275900S",
    "long": "0173500E"
  },
  {
    "stationcode": "KMP",
    "stationname": "J.G.H. Van Der Wath Airport",
    "lat": "263200S",
    "long": "0180600E"
  },
  {
    "stationcode": "ADI",
    "stationname": "Arandis Airport",
    "lat": "222400S",
    "long": "0150000E"
  },
  {
    "stationcode": "NNI",
    "stationname": "Namutoni Airport",
    "lat": "184900S",
    "long": "0165500E"
  },
  {
    "stationcode": "MEE",
    "stationname": "Mare Airport",
    "lat": "212904S",
    "long": "1680229E"
  },
  {
    "stationcode": "KOC",
    "stationname": "Koumac Airport",
    "lat": "203247S",
    "long": "1641520E"
  },
  {
    "stationcode": "IOU",
    "stationname": "Ile Ouen Airport",
    "lat": "222750S",
    "long": "1664659E"
  },
  {
    "stationcode": "KNQ",
    "stationname": "Kone Airport",
    "lat": "210314S",
    "long": "1645020E"
  },
  {
    "stationcode": "ILP",
    "stationname": "Ile Des Pins Airport",
    "lat": "223520S",
    "long": "1672721E"
  },
  {
    "stationcode": "NOU",
    "stationname": "Tontouta Airport",
    "lat": "220052S",
    "long": "1661247E"
  },
  {
    "stationcode": "HLU",
    "stationname": "Houailou Airport",
    "lat": "210200S",
    "long": "1660400E"
  },
  {
    "stationcode": "HNG",
    "stationname": "Hienghene Airport",
    "lat": "204000S",
    "long": "1645400E"
  },
  {
    "stationcode": "TOU",
    "stationname": "Touho Airport",
    "lat": "204724S",
    "long": "1651534E"
  },
  {
    "stationcode": "GEA",
    "stationname": "Magenta Airport",
    "lat": "221530S",
    "long": "1662822E"
  },
  {
    "stationcode": "PDC",
    "stationname": "Mueo Airport",
    "lat": "212100S",
    "long": "1650000E"
  },
  {
    "stationcode": "TGJ",
    "stationname": "Tiga Airport",
    "lat": "210536S",
    "long": "1674813E"
  },
  {
    "stationcode": "PUV",
    "stationname": "Poum Airport",
    "lat": "201500S",
    "long": "1640300E"
  },
  {
    "stationcode": "UVE",
    "stationname": "Ouvea Airport",
    "lat": "203827S",
    "long": "1663422E"
  },
  {
    "stationcode": "BMY",
    "stationname": "Belep Island Airport",
    "lat": "194314S",
    "long": "1633940E"
  },
  {
    "stationcode": "LIF",
    "stationname": "Lifou Airport",
    "lat": "204629S",
    "long": "1671424E"
  },
  {
    "stationcode": "MFQ",
    "stationname": "Maradi Airport",
    "lat": "133007N",
    "long": "0070711E"
  },
  {
    "stationcode": "BKN",
    "stationname": "Birni Nkoni Airport",
    "lat": "392849N",
    "long": "0542157E"
  },
  {
    "stationcode": "THZ",
    "stationname": "Tahoua Airport",
    "lat": "145233N",
    "long": "0051557E"
  },
  {
    "stationcode": "RLT",
    "stationname": "Arlit Airport",
    "lat": "184727N",
    "long": "0072157E"
  },
  {
    "stationcode": "ZND",
    "stationname": "Zinder Airport",
    "lat": "134644N",
    "long": "0085901E"
  },
  {
    "stationcode": "NIM",
    "stationname": "Niamey Airport",
    "lat": "132852N",
    "long": "0021036E"
  },
  {
    "stationcode": "AJY",
    "stationname": "Agades Airport",
    "lat": "165745N",
    "long": "0075925E"
  },
  {
    "stationcode": "NLK",
    "stationname": "Norfolk Island Airport",
    "lat": "290230S",
    "long": "1675619E"
  },
  {
    "stationcode": "QUO",
    "stationname": "Akwa Ibom Int'l Airport",
    "lat": "045226N",
    "long": "0080529E"
  },
  {
    "stationcode": "JOS",
    "stationname": "Jos Airport",
    "lat": "093823N",
    "long": "0085212E"
  },
  {
    "stationcode": "KAD",
    "stationname": "Kaduna Airport",
    "lat": "104142N",
    "long": "0071914E"
  },
  {
    "stationcode": "ILR",
    "stationname": "Ilorin Airport",
    "lat": "082625N",
    "long": "0042938E"
  },
  {
    "stationcode": "BCU",
    "stationname": "Bauchi Airport",
    "lat": "101733N",
    "long": "0094902E"
  },
  {
    "stationcode": "LOS",
    "stationname": "Murtala Muhammed Airport",
    "lat": "063520N",
    "long": "0031935E"
  },
  {
    "stationcode": "YOL",
    "stationname": "Yola Airport",
    "lat": "091558N",
    "long": "0122556E"
  },
  {
    "stationcode": "IBA",
    "stationname": "Ibadan Airport",
    "lat": "072145N",
    "long": "0035842E"
  },
  {
    "stationcode": "GMO",
    "stationname": "Gombe Airport",
    "lat": "101753N",
    "long": "0105356E"
  },
  {
    "stationcode": "ENU",
    "stationname": "Enugu Airport",
    "lat": "062827N",
    "long": "0073343E"
  },
  {
    "stationcode": "BNI",
    "stationname": "Benin City Airport",
    "lat": "061901N",
    "long": "0053558E"
  },
  {
    "stationcode": "DKA",
    "stationname": "Katsina Airport",
    "lat": "130019N",
    "long": "0073931E"
  },
  {
    "stationcode": "MXJ",
    "stationname": "Minna Airport",
    "lat": "093700N",
    "long": "0063200E"
  },
  {
    "stationcode": "CBQ",
    "stationname": "Calabar Airport",
    "lat": "045834N",
    "long": "0082050E"
  },
  {
    "stationcode": "PHC",
    "stationname": "Port Harcourt Airport",
    "lat": "050100N",
    "long": "0065700E"
  },
  {
    "stationcode": "ZAR",
    "stationname": "Zaria Airport",
    "lat": "110800N",
    "long": "0074100E"
  },
  {
    "stationcode": "PHG",
    "stationname": "Port Harcourt City Airport",
    "lat": "045043N",
    "long": "0070116E"
  },
  {
    "stationcode": "ABV",
    "stationname": "Abuja International Airport",
    "lat": "090024N",
    "long": "0071547E"
  },
  {
    "stationcode": "MDI",
    "stationname": "Makurdi Airport",
    "lat": "074300N",
    "long": "0083600E"
  },
  {
    "stationcode": "ABB",
    "stationname": "Asaba International Airport",
    "lat": "061211N",
    "long": "0064002E"
  },
  {
    "stationcode": "QRW",
    "stationname": "Osubi Airport",
    "lat": "053556N",
    "long": "0054913E"
  },
  {
    "stationcode": "AKR",
    "stationname": "Akure Airport",
    "lat": "071455N",
    "long": "0051807E"
  },
  {
    "stationcode": "SKO",
    "stationname": "Sokoto Airport",
    "lat": "125459N",
    "long": "0051226E"
  },
  {
    "stationcode": "KAN",
    "stationname": "Aminu Kano International Airport",
    "lat": "120251N",
    "long": "0083129E"
  },
  {
    "stationcode": "MIU",
    "stationname": "Maiduguri Airport",
    "lat": "115039N",
    "long": "0130405E"
  },
  {
    "stationcode": "QOW",
    "stationname": "Sam Mbakwe Airport",
    "lat": "052421N",
    "long": "0071204E"
  },
  {
    "stationcode": "BEF",
    "stationname": "Bluefields Airport",
    "lat": "115927N",
    "long": "0834627W"
  },
  {
    "stationcode": "WSP",
    "stationname": "Waspam Airport",
    "lat": "144429N",
    "long": "0835806W"
  },
  {
    "stationcode": "BZA",
    "stationname": "San Pedro Airport",
    "lat": "140148N",
    "long": "0843728W"
  },
  {
    "stationcode": "NCR",
    "stationname": "San Carlos Airport",
    "lat": "110810N",
    "long": "0844607W"
  },
  {
    "stationcode": "SIU",
    "stationname": "Siuna Airport",
    "lat": "134344N",
    "long": "0844639W"
  },
  {
    "stationcode": "ECI",
    "stationname": "Costa Esmeralda Airport",
    "lat": "112539N",
    "long": "0860200W"
  },
  {
    "stationcode": "RNI",
    "stationname": "Corn Island Airport",
    "lat": "121025N",
    "long": "0830337W"
  },
  {
    "stationcode": "MGA",
    "stationname": "Augusto C Sandino Airport",
    "lat": "120828N",
    "long": "0861007W"
  },
  {
    "stationcode": "RFS",
    "stationname": "Rosita Airport",
    "lat": "135326N",
    "long": "0842435W"
  },
  {
    "stationcode": "NVG",
    "stationname": "Nueva Guinea Airport",
    "lat": "114000N",
    "long": "0842700W"
  },
  {
    "stationcode": "PUZ",
    "stationname": "Puerto Cabezas Airport",
    "lat": "140247N",
    "long": "0832312W"
  },
  {
    "stationcode": "WOE",
    "stationname": "Woensdrecht Ab Airport",
    "lat": "512657N",
    "long": "0042031E"
  },
  {
    "stationcode": "LID",
    "stationname": "Valkenburg Airport",
    "lat": "520900N",
    "long": "0043000E"
  },
  {
    "stationcode": "DHR",
    "stationname": "De Kooy Airport",
    "lat": "525528N",
    "long": "0044651E"
  },
  {
    "stationcode": "RTM",
    "stationname": "Rotterdam Airport",
    "lat": "515711N",
    "long": "0042551E"
  },
  {
    "stationcode": "GRQ",
    "stationname": "Eelde Airport",
    "lat": "530730N",
    "long": "0063500E"
  },
  {
    "stationcode": "QRH",
    "stationname": "Metropolitan Area Airport",
    "lat": "515527N",
    "long": "0042813E"
  },
  {
    "stationcode": "UTC",
    "stationname": "Soesterberg Airport",
    "lat": "520742N",
    "long": "0051637E"
  },
  {
    "stationcode": "AMS",
    "stationname": "Amsterdam-Schiphol Airport",
    "lat": "521829N",
    "long": "0044551E"
  },
  {
    "stationcode": "EIN",
    "stationname": "Eindhoven Airport",
    "lat": "512700N",
    "long": "0052228E"
  },
  {
    "stationcode": "GLZ",
    "stationname": "Gilze-Rijen Airport",
    "lat": "513405N",
    "long": "0045559E"
  },
  {
    "stationcode": "BZM",
    "stationname": "Woensdrecht Airport",
    "lat": "174146S",
    "long": "0450516E"
  },
  {
    "stationcode": "UDE",
    "stationname": "Volkel Airport",
    "lat": "513939N",
    "long": "0054233E"
  },
  {
    "stationcode": "MST",
    "stationname": "Maastricht/Aachen Airport",
    "lat": "505457N",
    "long": "0054637E"
  },
  {
    "stationcode": "LWR",
    "stationname": "Leeuwarden Airport",
    "lat": "531335N",
    "long": "0054512E"
  },
  {
    "stationcode": "ENS",
    "stationname": "Twente Airport",
    "lat": "521630N",
    "long": "0065311E"
  },
  {
    "stationcode": "LEY",
    "stationname": "Lelystad Airport",
    "lat": "522800N",
    "long": "0053200E"
  },
  {
    "stationcode": "VAW",
    "stationname": "Vardoe Airport",
    "lat": "702119N",
    "long": "0310242E"
  },
  {
    "stationcode": "HMR",
    "stationname": "Hamar Airport",
    "lat": "604800N",
    "long": "0110600E"
  },
  {
    "stationcode": "MOL",
    "stationname": "Aro Airport",
    "lat": "624455N",
    "long": "0071616E"
  },
  {
    "stationcode": "MJF",
    "stationname": "Kjaerstad Airport",
    "lat": "654702N",
    "long": "0131254E"
  },
  {
    "stationcode": "MQN",
    "stationname": "Mo I Rana Airport",
    "lat": "662200N",
    "long": "0141800E"
  },
  {
    "stationcode": "GLL",
    "stationname": "Klanten Airport",
    "lat": "604700N",
    "long": "0090300E"
  },
  {
    "stationcode": "FDE",
    "stationname": "Bringeland Airport",
    "lat": "612331N",
    "long": "0054538E"
  },
  {
    "stationcode": "BGO",
    "stationname": "Flesland Airport",
    "lat": "601737N",
    "long": "0051310E"
  },
  {
    "stationcode": "NTB",
    "stationname": "Notodden Airport",
    "lat": "593358N",
    "long": "0091243E"
  },
  {
    "stationcode": "SRP",
    "stationname": "Stord Airport",
    "lat": "594732N",
    "long": "0052028E"
  },
  {
    "stationcode": "ALF",
    "stationname": "Alta Airport",
    "lat": "695834N",
    "long": "0232218E"
  },
  {
    "stationcode": "HOV",
    "stationname": "Hovden Airport",
    "lat": "621045N",
    "long": "0060416E"
  },
  {
    "stationcode": "RRS",
    "stationname": "Roros Airport",
    "lat": "623444N",
    "long": "0112041E"
  },
  {
    "stationcode": "TRD",
    "stationname": "Vaernes Airport",
    "lat": "632700N",
    "long": "0105600E"
  },
  {
    "stationcode": "VDS",
    "stationname": "Vadso Airport",
    "lat": "700352N",
    "long": "0295015E"
  },
  {
    "stationcode": "HAA",
    "stationname": "Hasvik Airport",
    "lat": "702912N",
    "long": "0220823E"
  },
  {
    "stationcode": "QFQ",
    "stationname": "Maloy Harbour",
    "lat": "615700N",
    "long": "0050600E"
  },
  {
    "stationcode": "OSL",
    "stationname": "Oslo Airport",
    "lat": "601200N",
    "long": "0110500E"
  },
  {
    "stationcode": "RET",
    "stationname": "Stolport Airport",
    "lat": "673139N",
    "long": "0120611E"
  },
  {
    "stationcode": "KRS",
    "stationname": "Kjevik Airport",
    "lat": "581211N",
    "long": "0080503E"
  },
  {
    "stationcode": "LKN",
    "stationname": "Leknes Airport",
    "lat": "680916N",
    "long": "0133643E"
  },
  {
    "stationcode": "SOJ",
    "stationname": "Sorkjosen Airport",
    "lat": "694713N",
    "long": "0205734E"
  },
  {
    "stationcode": "ZXQ",
    "stationname": "Solstad Airport",
    "lat": "601100N",
    "long": "0053100E"
  },
  {
    "stationcode": "SDN",
    "stationname": "Sandane Airport",
    "lat": "614948N",
    "long": "0060627E"
  },
  {
    "stationcode": "KSU",
    "stationname": "Kvernberget Airport",
    "lat": "630642N",
    "long": "0074928E"
  },
  {
    "stationcode": "BDU",
    "stationname": "Bardufoss Airport",
    "lat": "690321N",
    "long": "0183225E"
  },
  {
    "stationcode": "NVK",
    "stationname": "Framnes Airport",
    "lat": "682530N",
    "long": "0172530E"
  },
  {
    "stationcode": "VDB",
    "stationname": "Valdres Airport",
    "lat": "610032N",
    "long": "0091739E"
  },
  {
    "stationcode": "TOS",
    "stationname": "Tromso/Langnes Airport",
    "lat": "694100N",
    "long": "0185508E"
  },
  {
    "stationcode": "BJF",
    "stationname": "Batsfjord Airport",
    "lat": "703601N",
    "long": "0294133E"
  },
  {
    "stationcode": "SKE",
    "stationname": "Skien Airport",
    "lat": "591106N",
    "long": "0093401E"
  },
  {
    "stationcode": "BNN",
    "stationname": "Bronnoy Airport",
    "lat": "652740N",
    "long": "0121303E"
  },
  {
    "stationcode": "QFK",
    "stationname": "Selje Harbour",
    "lat": "620300N",
    "long": "0052200E"
  },
  {
    "stationcode": "FAN",
    "stationname": "Lista Airport",
    "lat": "580606N",
    "long": "0063732E"
  },
  {
    "stationcode": "LKL",
    "stationname": "Banak Airport",
    "lat": "700408N",
    "long": "0245825E"
  },
  {
    "stationcode": "SOG",
    "stationname": "Haukasen Airport",
    "lat": "610924N",
    "long": "0070822E"
  },
  {
    "stationcode": "SYG",
    "stationname": "Spitsberg Airport",
    "lat": "273427N",
    "long": "1113016E"
  },
  {
    "stationcode": "RVK",
    "stationname": "Ryumsjoen Airport",
    "lat": "645003N",
    "long": "0110808E"
  },
  {
    "stationcode": "BOO",
    "stationname": "Bodo Airport",
    "lat": "671609N",
    "long": "0142155E"
  },
  {
    "stationcode": "BVG",
    "stationname": "Berlevag Airport",
    "lat": "705216N",
    "long": "0290144E"
  },
  {
    "stationcode": "FRO",
    "stationname": "Flora Airport",
    "lat": "613501N",
    "long": "0050129E"
  },
  {
    "stationcode": "TRF",
    "stationname": "Sandefjord Airport",
    "lat": "591112N",
    "long": "0101531E"
  },
  {
    "stationcode": "ANX",
    "stationname": "Andenes Airport",
    "lat": "691733N",
    "long": "0160839E"
  },
  {
    "stationcode": "RYG",
    "stationname": "Moss Rygge Airport",
    "lat": "592244N",
    "long": "0104708E"
  },
  {
    "stationcode": "DLD",
    "stationname": "Dagali Airport",
    "lat": "602800N",
    "long": "0082900E"
  },
  {
    "stationcode": "AES",
    "stationname": "Vigra Airport",
    "lat": "623345N",
    "long": "0060619E"
  },
  {
    "stationcode": "SVJ",
    "stationname": "Helle Airport",
    "lat": "681438N",
    "long": "0144010E"
  },
  {
    "stationcode": "SKN",
    "stationname": "Skagen Airport",
    "lat": "683452N",
    "long": "0150116E"
  },
  {
    "stationcode": "HVG",
    "stationname": "Valan Airport",
    "lat": "710035N",
    "long": "0255849E"
  },
  {
    "stationcode": "OSY",
    "stationname": "Namsos Airport",
    "lat": "642818N",
    "long": "0113414E"
  },
  {
    "stationcode": "OLA",
    "stationname": "Orland Airport",
    "lat": "634200N",
    "long": "0093700E"
  },
  {
    "stationcode": "XZD",
    "stationname": "Kongsvinger Airport",
    "lat": "601115N",
    "long": "0120006E"
  },
  {
    "stationcode": "VRY",
    "stationname": "Stolport Airport",
    "lat": "674126N",
    "long": "0124048E"
  },
  {
    "stationcode": "SSJ",
    "stationname": "Stokka Airport",
    "lat": "655730N",
    "long": "0122817E"
  },
  {
    "stationcode": "EVE",
    "stationname": "Evenes Airport",
    "lat": "682929N",
    "long": "0164041E"
  },
  {
    "stationcode": "SVG",
    "stationname": "Sola Airport",
    "lat": "585247N",
    "long": "0053805E"
  },
  {
    "stationcode": "MEH",
    "stationname": "Mehamn Airport",
    "lat": "710143N",
    "long": "0274937E"
  },
  {
    "stationcode": "HAU",
    "stationname": "Haugesund Airport",
    "lat": "592043N",
    "long": "0051230E"
  },
  {
    "stationcode": "HFT",
    "stationname": "Hammerfest Airport",
    "lat": "704045N",
    "long": "0233958E"
  },
  {
    "stationcode": "KKN",
    "stationname": "Hoeybuktmoen Airport",
    "lat": "694333N",
    "long": "0295329E"
  },
  {
    "stationcode": "JUM",
    "stationname": "Jumla Airport",
    "lat": "291628N",
    "long": "0821137E"
  },
  {
    "stationcode": "DOP",
    "stationname": "Dolpa Airport",
    "lat": "285908N",
    "long": "0824908E"
  },
  {
    "stationcode": "FEB",
    "stationname": "Sanfebagar Airport",
    "lat": "291400N",
    "long": "0811300E"
  },
  {
    "stationcode": "BIT",
    "stationname": "Baitadi Airport",
    "lat": "293200N",
    "long": "0802600E"
  },
  {
    "stationcode": "TMI",
    "stationname": "Tumling Tar Airport",
    "lat": "271900N",
    "long": "0871136E"
  },
  {
    "stationcode": "JKR",
    "stationname": "Janakpur Airport",
    "lat": "264232N",
    "long": "0855527E"
  },
  {
    "stationcode": "DHI",
    "stationname": "Dhangarhi Airport",
    "lat": "284517N",
    "long": "0803457E"
  },
  {
    "stationcode": "BGL",
    "stationname": "Baglung Airport",
    "lat": "281300N",
    "long": "0834000E"
  },
  {
    "stationcode": "KEP",
    "stationname": "Nepalganj Airport",
    "lat": "280613N",
    "long": "0814001E"
  },
  {
    "stationcode": "SYH",
    "stationname": "Syangboche Airport",
    "lat": "274500N",
    "long": "0864400E"
  },
  {
    "stationcode": "BDP",
    "stationname": "Bhadrapur Airport",
    "lat": "263414N",
    "long": "0880449E"
  },
  {
    "stationcode": "GKH",
    "stationname": "Gorkha Airport",
    "lat": "280219N",
    "long": "0842800E"
  },
  {
    "stationcode": "MEY",
    "stationname": "Meghauli Airport",
    "lat": "273439N",
    "long": "0841344E"
  },
  {
    "stationcode": "HRJ",
    "stationname": "Chaurjhari Airport",
    "lat": "280000N",
    "long": "0822000E"
  },
  {
    "stationcode": "SIH",
    "stationname": "Silgarhi Doti Airport",
    "lat": "291600N",
    "long": "0805900E"
  },
  {
    "stationcode": "NGX",
    "stationname": "Manang Airport",
    "lat": "283800N",
    "long": "0840000E"
  },
  {
    "stationcode": "LDN",
    "stationname": "Lamidanda Airport",
    "lat": "271510N",
    "long": "0864019E"
  },
  {
    "stationcode": "RUM",
    "stationname": "Rumjatar Airport",
    "lat": "271810N",
    "long": "0863304E"
  },
  {
    "stationcode": "BHR",
    "stationname": "Bharatpur Airport",
    "lat": "274041N",
    "long": "0842541E"
  },
  {
    "stationcode": "TPJ",
    "stationname": "Taplejung Airport",
    "lat": "271800N",
    "long": "0874200E"
  },
  {
    "stationcode": "RPA",
    "stationname": "Rolpa Airport",
    "lat": "284200N",
    "long": "0823900E"
  },
  {
    "stationcode": "TPU",
    "stationname": "Tikapur Airport",
    "lat": "283300N",
    "long": "0811100E"
  },
  {
    "stationcode": "SKH",
    "stationname": "Surkhet Airport",
    "lat": "283510N",
    "long": "0813809E"
  },
  {
    "stationcode": "IMK",
    "stationname": "Simikot Airport",
    "lat": "295815N",
    "long": "0814905E"
  },
  {
    "stationcode": "JIR",
    "stationname": "Jiri Airport",
    "lat": "273800N",
    "long": "0861400E"
  },
  {
    "stationcode": "BWA",
    "stationname": "Bhairawa Airport",
    "lat": "273021N",
    "long": "0832459E"
  },
  {
    "stationcode": "BHP",
    "stationname": "Bhojpur Airport",
    "lat": "270851N",
    "long": "0870305E"
  },
  {
    "stationcode": "RUK",
    "stationname": "Rukumkot Airport",
    "lat": "285700N",
    "long": "0821600E"
  },
  {
    "stationcode": "LUA",
    "stationname": "Lukla Airport",
    "lat": "274112N",
    "long": "0864346E"
  },
  {
    "stationcode": "BIR",
    "stationname": "Biratnagar Airport",
    "lat": "262857N",
    "long": "0871600E"
  },
  {
    "stationcode": "DAP",
    "stationname": "Darchula Airport",
    "lat": "294000N",
    "long": "0803000E"
  },
  {
    "stationcode": "PPL",
    "stationname": "Phaplu Airport",
    "lat": "273102N",
    "long": "0863506E"
  },
  {
    "stationcode": "LTG",
    "stationname": "Langtang Airport",
    "lat": "281000N",
    "long": "0853000E"
  },
  {
    "stationcode": "JMO",
    "stationname": "Jomsom Airport",
    "lat": "284650N",
    "long": "0834323E"
  },
  {
    "stationcode": "XMG",
    "stationname": "Mahendranagar Airport",
    "lat": "285800N",
    "long": "0800900E"
  },
  {
    "stationcode": "RHP",
    "stationname": "Ramechhap Airport",
    "lat": "272400N",
    "long": "0860500E"
  },
  {
    "stationcode": "DNP",
    "stationname": "Dang Airport",
    "lat": "280700N",
    "long": "0821900E"
  },
  {
    "stationcode": "PKR",
    "stationname": "Pokhara Airport",
    "lat": "281202N",
    "long": "0835853E"
  },
  {
    "stationcode": "BJH",
    "stationname": "Bajhang Airport",
    "lat": "293300N",
    "long": "0811500E"
  },
  {
    "stationcode": "MWP",
    "stationname": "Mountain Airport",
    "lat": "280000N",
    "long": "0852000E"
  },
  {
    "stationcode": "BJU",
    "stationname": "Bajura Airport",
    "lat": "292100N",
    "long": "0811800E"
  },
  {
    "stationcode": "RJB",
    "stationname": "Rajbiraj Airport",
    "lat": "263036N",
    "long": "0864405E"
  },
  {
    "stationcode": "SIF",
    "stationname": "Simara Airport",
    "lat": "270931N",
    "long": "0845848E"
  },
  {
    "stationcode": "INU",
    "stationname": "Nauru Island International Airport",
    "lat": "003244S",
    "long": "1665501E"
  },
  {
    "stationcode": "IUE",
    "stationname": "Hanan Airport",
    "lat": "190440S",
    "long": "1695536W"
  },
  {
    "stationcode": "BYB",
    "stationname": "Dibaa Airport",
    "lat": "253700N",
    "long": "0561500E"
  },
  {
    "stationcode": "OMM",
    "stationname": "Marmul Airport",
    "lat": "180818N",
    "long": "0551048E"
  },
  {
    "stationcode": "AHW",
    "stationname": "Saihrawl Airport",
    "lat": "212034N",
    "long": "0564556E"
  },
  {
    "stationcode": "KHS",
    "stationname": "Khasab Airport",
    "lat": "261016N",
    "long": "0561426E"
  },
  {
    "stationcode": "RMB",
    "stationname": "Buraimi Airport",
    "lat": "241500N",
    "long": "0554700E"
  },
  {
    "stationcode": "BJQ",
    "stationname": "Bahja Airport",
    "lat": "195230N",
    "long": "0560343E"
  },
  {
    "stationcode": "UKH",
    "stationname": "Mukhaizna Airport",
    "lat": "192316N",
    "long": "0562400E"
  },
  {
    "stationcode": "MSH",
    "stationname": "Masirah Airport",
    "lat": "204032N",
    "long": "0581500E"
  },
  {
    "stationcode": "RNM",
    "stationname": "Qarn Alam Airport",
    "lat": "212237N",
    "long": "0570324E"
  },
  {
    "stationcode": "WWG",
    "stationname": "Al Koude Airport",
    "lat": "233221N",
    "long": "0581119E"
  },
  {
    "stationcode": "FAU",
    "stationname": "Fahud Airport",
    "lat": "222103N",
    "long": "0562923E"
  },
  {
    "stationcode": "LKW",
    "stationname": "Lekhawair Airport",
    "lat": "224817N",
    "long": "0552157E"
  },
  {
    "stationcode": "DQM",
    "stationname": "Duqm Int'l Airport",
    "lat": "193000N",
    "long": "0573834E"
  },
  {
    "stationcode": "AOM",
    "stationname": "Adam Airport",
    "lat": "222950N",
    "long": "0572300E"
  },
  {
    "stationcode": "JNJ",
    "stationname": "Jaaluni Airport",
    "lat": "192830N",
    "long": "0571830E"
  },
  {
    "stationcode": "TTH",
    "stationname": "Thumrait Airport",
    "lat": "174000N",
    "long": "0540105E"
  },
  {
    "stationcode": "SLL",
    "stationname": "Salalah Airport",
    "lat": "170219N",
    "long": "0540529E"
  },
  {
    "stationcode": "SUH",
    "stationname": "Sur Airport",
    "lat": "223300N",
    "long": "0593000E"
  },
  {
    "stationcode": "OHS",
    "stationname": "Sohar Airport",
    "lat": "242310N",
    "long": "0563732E"
  },
  {
    "stationcode": "CHX",
    "stationname": "Changuinola Airport",
    "lat": "092732N",
    "long": "0823055W"
  },
  {
    "stationcode": "NBL",
    "stationname": "San Blas Airport",
    "lat": "092800N",
    "long": "0790000W"
  },
  {
    "stationcode": "ELE",
    "stationname": "El Real Airport",
    "lat": "080700N",
    "long": "0774300W"
  },
  {
    "stationcode": "RIT",
    "stationname": "Rio Tigre Airport",
    "lat": "075000N",
    "long": "0804800W"
  },
  {
    "stationcode": "CDE",
    "stationname": "Caledonia Airport",
    "lat": "082000N",
    "long": "0773300W"
  },
  {
    "stationcode": "IVI",
    "stationname": "Isla Viveros Airport",
    "lat": "082818N",
    "long": "0790002W"
  },
  {
    "stationcode": "TUE",
    "stationname": "Tupile Airport",
    "lat": "093421N",
    "long": "0792914W"
  },
  {
    "stationcode": "PYC",
    "stationname": "Playon Chico Airport",
    "lat": "091835N",
    "long": "0781401W"
  },
  {
    "stationcode": "TUW",
    "stationname": "Tubala Airport",
    "lat": "084400N",
    "long": "0774100W"
  },
  {
    "stationcode": "SYP",
    "stationname": "Santiago Airport",
    "lat": "080600N",
    "long": "0805900W"
  },
  {
    "stationcode": "ACU",
    "stationname": "Achutupo Airport",
    "lat": "082600N",
    "long": "0775800W"
  },
  {
    "stationcode": "SAX",
    "stationname": "Sambu Airport",
    "lat": "080131N",
    "long": "0781231W"
  },
  {
    "stationcode": "OGM",
    "stationname": "Ogobsucum Airport",
    "lat": "090815N",
    "long": "0775600W"
  },
  {
    "stationcode": "OTD",
    "stationname": "Contadora Airport",
    "lat": "083745N",
    "long": "0790205W"
  },
  {
    "stationcode": "CTD",
    "stationname": "Chitre Airport",
    "lat": "075917N",
    "long": "0802436W"
  },
  {
    "stationcode": "AML",
    "stationname": "Puerto Armuelles Airport",
    "lat": "081800N",
    "long": "0825200W"
  },
  {
    "stationcode": "PUE",
    "stationname": "Puerto Obaldia Airport",
    "lat": "084002N",
    "long": "0772507W"
  },
  {
    "stationcode": "SFW",
    "stationname": "Santa Fe Airport",
    "lat": "085000N",
    "long": "0811000W"
  },
  {
    "stationcode": "RSI",
    "stationname": "Rio Sidra Airport",
    "lat": "252425N",
    "long": "0370746E"
  },
  {
    "stationcode": "BLB",
    "stationname": "Balboa Airport",
    "lat": "085452N",
    "long": "0793559W"
  },
  {
    "stationcode": "PTY",
    "stationname": "Tocumen International Airport",
    "lat": "090500N",
    "long": "0792300W"
  },
  {
    "stationcode": "NMG",
    "stationname": "San Miguel Airport",
    "lat": "082700N",
    "long": "0785500W"
  },
  {
    "stationcode": "BFQ",
    "stationname": "Bahia Pinas Airport",
    "lat": "073505N",
    "long": "0781052W"
  },
  {
    "stationcode": "PAC",
    "stationname": "Paitilla Airport",
    "lat": "085824N",
    "long": "0793320W"
  },
  {
    "stationcode": "TJC",
    "stationname": "Ticantiki Airport",
    "lat": "092430N",
    "long": "0782920W"
  },
  {
    "stationcode": "UTU",
    "stationname": "Ustupo Airport",
    "lat": "092500N",
    "long": "0783600W"
  },
  {
    "stationcode": "CTE",
    "stationname": "Carti Airport",
    "lat": "091900N",
    "long": "0790600W"
  },
  {
    "stationcode": "MPI",
    "stationname": "Mamitupo Airport",
    "lat": "091100N",
    "long": "0775900W"
  },
  {
    "stationcode": "JQE",
    "stationname": "Jaque Airport",
    "lat": "073102N",
    "long": "0780925W"
  },
  {
    "stationcode": "RIZ",
    "stationname": "Rio Alzucar Airport",
    "lat": "081500N",
    "long": "0805000W"
  },
  {
    "stationcode": "RIH",
    "stationname": "Scarlett Martinez Intl Airport",
    "lat": "082231N",
    "long": "0800742W"
  },
  {
    "stationcode": "CZJ",
    "stationname": "Corazon De Jesus Airport",
    "lat": "092640N",
    "long": "0783507W"
  },
  {
    "stationcode": "PVE",
    "stationname": "El Porvenir Airport",
    "lat": "093332N",
    "long": "0785651W"
  },
  {
    "stationcode": "PDM",
    "stationname": "Capt. J. Montenegro Airport",
    "lat": "073312N",
    "long": "0800117W"
  },
  {
    "stationcode": "PYV",
    "stationname": "Yaviza Airport",
    "lat": "080800N",
    "long": "0774200W"
  },
  {
    "stationcode": "BOC",
    "stationname": "Bocas Del Toro Airport",
    "lat": "092027N",
    "long": "0821503W"
  },
  {
    "stationcode": "PLP",
    "stationname": "La Palma Airport",
    "lat": "082413N",
    "long": "0780832W"
  },
  {
    "stationcode": "ONX",
    "stationname": "Colon Airport",
    "lat": "092200N",
    "long": "0795400W"
  },
  {
    "stationcode": "MPP",
    "stationname": "Mulatupo Airport",
    "lat": "085647N",
    "long": "0774503W"
  },
  {
    "stationcode": "AIL",
    "stationname": "Ailigandi Airport",
    "lat": "091400N",
    "long": "0780100W"
  },
  {
    "stationcode": "DAV",
    "stationname": "Enrique Malek Airport",
    "lat": "082328N",
    "long": "0822606W"
  },
  {
    "stationcode": "NGN",
    "stationname": "Nargana Airport",
    "lat": "090400N",
    "long": "0780100W"
  },
  {
    "stationcode": "GHE",
    "stationname": "Garachine Airport",
    "lat": "080353N",
    "long": "0782202W"
  },
  {
    "stationcode": "TYL",
    "stationname": "Talara Airport",
    "lat": "043436S",
    "long": "0811515W"
  },
  {
    "stationcode": "PIO",
    "stationname": "Pisco Airport",
    "lat": "134442S",
    "long": "0761313W"
  },
  {
    "stationcode": "JAE",
    "stationname": "Aeropuerto de Shumba",
    "lat": "053537S",
    "long": "0784625W"
  },
  {
    "stationcode": "AYP",
    "stationname": "Yanamilla Airport",
    "lat": "130917S",
    "long": "0741216W"
  },
  {
    "stationcode": "PEM",
    "stationname": "Puerto Maldonado Airport",
    "lat": "123659S",
    "long": "0691345W"
  },
  {
    "stationcode": "APE",
    "stationname": "San Juan Aposento Airport",
    "lat": "115600S",
    "long": "0690500W"
  },
  {
    "stationcode": "CHM",
    "stationname": "Chimbote Airport",
    "lat": "090500S",
    "long": "0783600W"
  },
  {
    "stationcode": "ANS",
    "stationname": "Andahuaylas Airport",
    "lat": "134223S",
    "long": "0732101W"
  },
  {
    "stationcode": "MBP",
    "stationname": "Moyobamba Airport",
    "lat": "055830S",
    "long": "0770100W"
  },
  {
    "stationcode": "TGI",
    "stationname": "Tingo Maria Airport",
    "lat": "091724S",
    "long": "0760019W"
  },
  {
    "stationcode": "AOP",
    "stationname": "Andoas Airport",
    "lat": "024729S",
    "long": "0762829W"
  },
  {
    "stationcode": "MFT",
    "stationname": "Machu Picchu Airport",
    "lat": "130700S",
    "long": "0723400W"
  },
  {
    "stationcode": "LIM",
    "stationname": "J Chavez International Airport",
    "lat": "120119S",
    "long": "0770652W"
  },
  {
    "stationcode": "BLP",
    "stationname": "Bellavista Airport",
    "lat": "070339S",
    "long": "0763456W"
  },
  {
    "stationcode": "RIM",
    "stationname": "Rodriguez De Mendoza Airport",
    "lat": "062331S",
    "long": "0773006W"
  },
  {
    "stationcode": "RIJ",
    "stationname": "Rioja Airport",
    "lat": "060300S",
    "long": "0770900W"
  },
  {
    "stationcode": "TRU",
    "stationname": "Trujillo Airport",
    "lat": "080505S",
    "long": "0790634W"
  },
  {
    "stationcode": "UMI",
    "stationname": "Quincemil Airport",
    "lat": "131600S",
    "long": "0703800W"
  },
  {
    "stationcode": "ALD",
    "stationname": "Alerta Airport",
    "lat": "114200S",
    "long": "0692100W"
  },
  {
    "stationcode": "CJA",
    "stationname": "Cajamarca Airport",
    "lat": "070821S",
    "long": "0782922W"
  },
  {
    "stationcode": "AQP",
    "stationname": "Rodriguez Ballon Airport",
    "lat": "162027S",
    "long": "0713421W"
  },
  {
    "stationcode": "PIU",
    "stationname": "Piura Airport",
    "lat": "051221S",
    "long": "0803659W"
  },
  {
    "stationcode": "SQU",
    "stationname": "Saposoa Airport",
    "lat": "065700S",
    "long": "0764500W"
  },
  {
    "stationcode": "CIX",
    "stationname": "Cornel Ruiz Airport",
    "lat": "064715S",
    "long": "0794941W"
  },
  {
    "stationcode": "SJA",
    "stationname": "San Juan Airport",
    "lat": "152100S",
    "long": "0750900W"
  },
  {
    "stationcode": "YMS",
    "stationname": "Yurimaguas Airport",
    "lat": "055400S",
    "long": "0760600W"
  },
  {
    "stationcode": "CUZ",
    "stationname": "Velazco Astete Airport",
    "lat": "133209S",
    "long": "0715620W"
  },
  {
    "stationcode": "IBP",
    "stationname": "Iberia Airport",
    "lat": "112200S",
    "long": "0693400W"
  },
  {
    "stationcode": "TBP",
    "stationname": "Tumbes Airport",
    "lat": "033309S",
    "long": "0802253W"
  },
  {
    "stationcode": "JJI",
    "stationname": "Juanjui Airport",
    "lat": "070900S",
    "long": "0764300W"
  },
  {
    "stationcode": "ILQ",
    "stationname": "Ilo Airport",
    "lat": "174142S",
    "long": "0712039W"
  },
  {
    "stationcode": "HUU",
    "stationname": "Huanuco Airport",
    "lat": "095248S",
    "long": "0761226W"
  },
  {
    "stationcode": "TPP",
    "stationname": "Tarapoto Airport",
    "lat": "063031S",
    "long": "0762224W"
  },
  {
    "stationcode": "SMG",
    "stationname": "Santa Maria Airport",
    "lat": "122329S",
    "long": "0764521W"
  },
  {
    "stationcode": "NCZ",
    "stationname": "Maria Reiche Neuman Airport",
    "lat": "145115S",
    "long": "0745742W"
  },
  {
    "stationcode": "JUL",
    "stationname": "Juliaca Airport",
    "lat": "152802S",
    "long": "0700929W"
  },
  {
    "stationcode": "TDP",
    "stationname": "Trompeteros Airport",
    "lat": "034827S",
    "long": "0750252W"
  },
  {
    "stationcode": "NZC",
    "stationname": "Maria Reiche Neuman Airport",
    "lat": "145115S",
    "long": "0745742W"
  },
  {
    "stationcode": "SYC",
    "stationname": "Shiringayoc Airport",
    "lat": "125400S",
    "long": "0691000W"
  },
  {
    "stationcode": "PCL",
    "stationname": "Capitan Rolden Airport",
    "lat": "082241S",
    "long": "0743427W"
  },
  {
    "stationcode": "CHH",
    "stationname": "Chachapoyas Airport",
    "lat": "061207S",
    "long": "0775123W"
  },
  {
    "stationcode": "ATA",
    "stationname": "Anta Airport",
    "lat": "092102S",
    "long": "0773551W"
  },
  {
    "stationcode": "VVN",
    "stationname": "Malvinas Airport",
    "lat": "115117S",
    "long": "0725623W"
  },
  {
    "stationcode": "TCQ",
    "stationname": "Tacna Airport",
    "lat": "180312S",
    "long": "0701633W"
  },
  {
    "stationcode": "JAU",
    "stationname": "Jauja Airport",
    "lat": "114703S",
    "long": "0752823W"
  },
  {
    "stationcode": "IQT",
    "stationname": "C.F. Secada Airport",
    "lat": "034705S",
    "long": "0731832W"
  },
  {
    "stationcode": "CDY",
    "stationname": "Cagayan De Sulu Airport",
    "lat": "070100N",
    "long": "1183000E"
  },
  {
    "stationcode": "ICO",
    "stationname": "Sicogon Island Airport",
    "lat": "112736N",
    "long": "1231502E"
  },
  {
    "stationcode": "TBH",
    "stationname": "Tablas Airport",
    "lat": "121841N",
    "long": "1220448E"
  },
  {
    "stationcode": "BXU",
    "stationname": "Butuan Airport",
    "lat": "085702N",
    "long": "1252853E"
  },
  {
    "stationcode": "MBT",
    "stationname": "Masbate Airport",
    "lat": "122208N",
    "long": "1233746E"
  },
  {
    "stationcode": "BQA",
    "stationname": "Baler Airport",
    "lat": "154347N",
    "long": "1213001E"
  },
  {
    "stationcode": "MBO",
    "stationname": "Mamburao Airport",
    "lat": "131227N",
    "long": "1203621E"
  },
  {
    "stationcode": "BNQ",
    "stationname": "Baganga Airport",
    "lat": "073500N",
    "long": "1263400E"
  },
  {
    "stationcode": "SSV",
    "stationname": "Siasi Airport",
    "lat": "053300N",
    "long": "1204900E"
  },
  {
    "stationcode": "XMA",
    "stationname": "Maramag Airport",
    "lat": "074600N",
    "long": "1250000E"
  },
  {
    "stationcode": "MXI",
    "stationname": "Mati Airport",
    "lat": "065700N",
    "long": "1261300E"
  },
  {
    "stationcode": "CUJ",
    "stationname": "Culion Airport",
    "lat": "115300N",
    "long": "1200100E"
  },
  {
    "stationcode": "CGM",
    "stationname": "Mambajao Airport",
    "lat": "091513N",
    "long": "1244225E"
  },
  {
    "stationcode": "RZP",
    "stationname": "CLR Airport",
    "lat": "104937N",
    "long": "1193005E"
  },
  {
    "stationcode": "OMC",
    "stationname": "Ormoc Airport",
    "lat": "110309N",
    "long": "1243355E"
  },
  {
    "stationcode": "TDG",
    "stationname": "Tandag Airport",
    "lat": "090420N",
    "long": "1261017E"
  },
  {
    "stationcode": "JOL",
    "stationname": "Jolo Airport",
    "lat": "060319N",
    "long": "1210032E"
  },
  {
    "stationcode": "XCN",
    "stationname": "Coron Airport",
    "lat": "120000N",
    "long": "1201200E"
  },
  {
    "stationcode": "USU",
    "stationname": "Busuanga Airport",
    "lat": "120715N",
    "long": "1200556E"
  },
  {
    "stationcode": "XSO",
    "stationname": "Siocon Airport",
    "lat": "074200N",
    "long": "1220800E"
  },
  {
    "stationcode": "WNP",
    "stationname": "Naga Airport",
    "lat": "133506N",
    "long": "1231613E"
  },
  {
    "stationcode": "DSG",
    "stationname": "Dilasag Airport",
    "lat": "162646N",
    "long": "1221225E"
  },
  {
    "stationcode": "SUG",
    "stationname": "Surigao Airport",
    "lat": "094539N",
    "long": "1252852E"
  },
  {
    "stationcode": "LAO",
    "stationname": "Laoag Airport",
    "lat": "181041N",
    "long": "1203153E"
  },
  {
    "stationcode": "MLP",
    "stationname": "Malabang Airport",
    "lat": "073800N",
    "long": "1240400E"
  },
  {
    "stationcode": "LBX",
    "stationname": "Lubang Airport",
    "lat": "135121N",
    "long": "1200620E"
  },
  {
    "stationcode": "BSO",
    "stationname": "Basco Airport",
    "lat": "202705N",
    "long": "1215848E"
  },
  {
    "stationcode": "IGN",
    "stationname": "Maria Cristina Airport",
    "lat": "080900N",
    "long": "1241300E"
  },
  {
    "stationcode": "IAO",
    "stationname": "Sayak Airport",
    "lat": "095133N",
    "long": "1260055E"
  },
  {
    "stationcode": "DTE",
    "stationname": "Camarines Norte Airport",
    "lat": "140800N",
    "long": "1225900E"
  },
  {
    "stationcode": "MRQ",
    "stationname": "Marinduque Airport",
    "lat": "132143N",
    "long": "1214931E"
  },
  {
    "stationcode": "LGP",
    "stationname": "Legaspi Airport",
    "lat": "130926N",
    "long": "1234404E"
  },
  {
    "stationcode": "BPH",
    "stationname": "Bislig Airport",
    "lat": "081200N",
    "long": "1262000E"
  },
  {
    "stationcode": "DPL",
    "stationname": "Dipolog Airport",
    "lat": "083615N",
    "long": "1232033E"
  },
  {
    "stationcode": "SFS",
    "stationname": "Subic Bay International Airport",
    "lat": "144740N",
    "long": "1201617E"
  },
  {
    "stationcode": "SFE",
    "stationname": "San Fernando Airport",
    "lat": "163544N",
    "long": "1201812E"
  },
  {
    "stationcode": "CRM",
    "stationname": "National Airport",
    "lat": "123009N",
    "long": "1243809E"
  },
  {
    "stationcode": "AAV",
    "stationname": "Alah Airport",
    "lat": "062000N",
    "long": "1244600E"
  },
  {
    "stationcode": "SGS",
    "stationname": "Sanga Sanga Airport",
    "lat": "051400N",
    "long": "1210900E"
  },
  {
    "stationcode": "SJI",
    "stationname": "McGuire Field",
    "lat": "122147N",
    "long": "1210237E"
  },
  {
    "stationcode": "BAG",
    "stationname": "Loakan Airport",
    "lat": "162230N",
    "long": "1203711E"
  },
  {
    "stationcode": "EUQ",
    "stationname": "Evelio Javier  Airport",
    "lat": "104604N",
    "long": "1215557E"
  },
  {
    "stationcode": "CYU",
    "stationname": "Cuyo Airport",
    "lat": "105131N",
    "long": "1210411E"
  },
  {
    "stationcode": "CYZ",
    "stationname": "Cauayan Airport",
    "lat": "165544N",
    "long": "1214519E"
  },
  {
    "stationcode": "LWA",
    "stationname": "Lwbak Airport",
    "lat": "063800N",
    "long": "1240336E"
  },
  {
    "stationcode": "IPE",
    "stationname": "Ipil Airport",
    "lat": "074700N",
    "long": "1223500E"
  },
  {
    "stationcode": "CYP",
    "stationname": "Calbayog Airport",
    "lat": "120422N",
    "long": "1243242E"
  },
  {
    "stationcode": "CGG",
    "stationname": "Casiguran Airport",
    "lat": "162200N",
    "long": "1220700E"
  },
  {
    "stationcode": "TGB",
    "stationname": "Tagbita Airport",
    "lat": "084300N",
    "long": "1172100E"
  },
  {
    "stationcode": "ENI",
    "stationname": "El Nido Airport",
    "lat": "111209N",
    "long": "1192459E"
  },
  {
    "stationcode": "DRP",
    "stationname": "Legazpi PH Airport",
    "lat": "130642N",
    "long": "1234052E"
  },
  {
    "stationcode": "BHW",
    "stationname": "Bhagatanwala Airport",
    "lat": "320400N",
    "long": "0725700E"
  },
  {
    "stationcode": "DBA",
    "stationname": "Dalbandin Airport",
    "lat": "285232N",
    "long": "0642414E"
  },
  {
    "stationcode": "RZS",
    "stationname": "Sawan Airport",
    "lat": "265802N",
    "long": "0685244E"
  },
  {
    "stationcode": "KBH",
    "stationname": "Kalat Airport",
    "lat": "034730S",
    "long": "0323848E"
  },
  {
    "stationcode": "SKZ",
    "stationname": "Sukkur Airport",
    "lat": "274317N",
    "long": "0684735E"
  },
  {
    "stationcode": "CHB",
    "stationname": "Chilas Airport",
    "lat": "352500N",
    "long": "0740500E"
  },
  {
    "stationcode": "TUK",
    "stationname": "Turbat Airport",
    "lat": "255911N",
    "long": "0630149E"
  },
  {
    "stationcode": "BDN",
    "stationname": "Talhar Airport",
    "lat": "245030N",
    "long": "0685019E"
  },
  {
    "stationcode": "CJL",
    "stationname": "Chitral Airport",
    "lat": "355312N",
    "long": "0714802E"
  },
  {
    "stationcode": "MJD",
    "stationname": "Mohenjo Daro Airport",
    "lat": "272007N",
    "long": "0680835E"
  },
  {
    "stationcode": "ZIZ",
    "stationname": "Zamzama Airport",
    "lat": "264240N",
    "long": "0674003E"
  },
  {
    "stationcode": "SKT",
    "stationname": "Sialkot Airport",
    "lat": "323207N",
    "long": "0742150E"
  },
  {
    "stationcode": "SGI",
    "stationname": "Sargodha Airport",
    "lat": "320500N",
    "long": "0724000E"
  },
  {
    "stationcode": "GRT",
    "stationname": "Gujrat Airport",
    "lat": "323400N",
    "long": "0740500E"
  },
  {
    "stationcode": "DSK",
    "stationname": "Dera Ismail Khan Airport",
    "lat": "315500N",
    "long": "0705400E"
  },
  {
    "stationcode": "JIW",
    "stationname": "Jiwani Airport",
    "lat": "250500N",
    "long": "0614900E"
  },
  {
    "stationcode": "AAW",
    "stationname": "Abbottabad Airport",
    "lat": "341200N",
    "long": "0731500E"
  },
  {
    "stationcode": "PSI",
    "stationname": "Pasni Airport",
    "lat": "251726N",
    "long": "0632042E"
  },
  {
    "stationcode": "JAG",
    "stationname": "Jacobabad Airport",
    "lat": "281700N",
    "long": "0682600E"
  },
  {
    "stationcode": "KDU",
    "stationname": "Skardu Airport",
    "lat": "351959N",
    "long": "0753247E"
  },
  {
    "stationcode": "MUX",
    "stationname": "Multan Airport",
    "lat": "301212N",
    "long": "0712509E"
  },
  {
    "stationcode": "GWD",
    "stationname": "Gwadar Airport",
    "lat": "251350N",
    "long": "0622020E"
  },
  {
    "stationcode": "KHI",
    "stationname": "Quaid-E-Azam International Airport",
    "lat": "245424N",
    "long": "0670939E"
  },
  {
    "stationcode": "LYP",
    "stationname": "Faisalabad Airport",
    "lat": "312154N",
    "long": "0725941E"
  },
  {
    "stationcode": "LRG",
    "stationname": "Lora Lai Airport",
    "lat": "302200N",
    "long": "0683700E"
  },
  {
    "stationcode": "PJG",
    "stationname": "Panjgur Airport",
    "lat": "265716N",
    "long": "0640757E"
  },
  {
    "stationcode": "NHS",
    "stationname": "Nushki Airport",
    "lat": "293300N",
    "long": "0660000E"
  },
  {
    "stationcode": "RAZ",
    "stationname": "Rawala Kot Airport",
    "lat": "335200N",
    "long": "0734600E"
  },
  {
    "stationcode": "PAJ",
    "stationname": "Para Chinar Airport",
    "lat": "335450N",
    "long": "0700500E"
  },
  {
    "stationcode": "HRA",
    "stationname": "Mansehra Airport",
    "lat": "503224N",
    "long": "0150025E"
  },
  {
    "stationcode": "ISB",
    "stationname": "Benazir Bhutto Intl Airport",
    "lat": "333650N",
    "long": "0730615E"
  },
  {
    "stationcode": "SDT",
    "stationname": "Saidu Sharif Airport",
    "lat": "344849N",
    "long": "0722110E"
  },
  {
    "stationcode": "ORW",
    "stationname": "Ormara Airport",
    "lat": "251800N",
    "long": "0643500E"
  },
  {
    "stationcode": "DDU",
    "stationname": "Dadu Airport",
    "lat": "264417N",
    "long": "0674002E"
  },
  {
    "stationcode": "SUL",
    "stationname": "Sui Airport",
    "lat": "283800N",
    "long": "0691200E"
  },
  {
    "stationcode": "MWD",
    "stationname": "Mianwali Airport",
    "lat": "323500N",
    "long": "0713300E"
  },
  {
    "stationcode": "HDD",
    "stationname": "Hyderabad Airport",
    "lat": "251906N",
    "long": "0682159E"
  },
  {
    "stationcode": "SWV",
    "stationname": "Shikarpur Airport",
    "lat": "615517N",
    "long": "1591347E"
  },
  {
    "stationcode": "SWN",
    "stationname": "Sahiwal Airport",
    "lat": "304000N",
    "long": "0730600E"
  },
  {
    "stationcode": "SYW",
    "stationname": "Sehwen Sharif Airport",
    "lat": "262500N",
    "long": "0675100E"
  },
  {
    "stationcode": "REQ",
    "stationname": "Reko Diq Airport",
    "lat": "290249N",
    "long": "0621144E"
  },
  {
    "stationcode": "GIL",
    "stationname": "Gilgit Airport",
    "lat": "355508N",
    "long": "0742001E"
  },
  {
    "stationcode": "TFT",
    "stationname": "Taftan Airport",
    "lat": "285800N",
    "long": "0613600E"
  },
  {
    "stationcode": "KCF",
    "stationname": "Kadanwari Airport",
    "lat": "271203N",
    "long": "0690912E"
  },
  {
    "stationcode": "KDD",
    "stationname": "Khuzdar Airport",
    "lat": "274700N",
    "long": "0663800E"
  },
  {
    "stationcode": "WGB",
    "stationname": "Bahawalnagar Airport",
    "lat": "300000N",
    "long": "0731500E"
  },
  {
    "stationcode": "MPD",
    "stationname": "Mirpur Khas Airport",
    "lat": "253200N",
    "long": "0690100E"
  },
  {
    "stationcode": "ATG",
    "stationname": "Attock Airport",
    "lat": "335400N",
    "long": "0721500E"
  },
  {
    "stationcode": "XJM",
    "stationname": "Mangla Airport",
    "lat": "333710N",
    "long": "0730602E"
  },
  {
    "stationcode": "PZH",
    "stationname": "Zhob Airport",
    "lat": "312130N",
    "long": "0692749E"
  },
  {
    "stationcode": "WNS",
    "stationname": "Nawabshah Airport",
    "lat": "261309N",
    "long": "0682324E"
  },
  {
    "stationcode": "BHV",
    "stationname": "Bahawalpur Airport",
    "lat": "292053N",
    "long": "0714305E"
  },
  {
    "stationcode": "WAF",
    "stationname": "Wana Airport",
    "lat": "321700N",
    "long": "0693400E"
  },
  {
    "stationcode": "PEW",
    "stationname": "Peshawar Airport",
    "lat": "335938N",
    "long": "0713052E"
  },
  {
    "stationcode": "TLB",
    "stationname": "Tarbela Airport",
    "lat": "340800N",
    "long": "0724900E"
  },
  {
    "stationcode": "UET",
    "stationname": "Quetta Airport",
    "lat": "301513N",
    "long": "0665610E"
  },
  {
    "stationcode": "OHT",
    "stationname": "Kohat Airport",
    "lat": "333400N",
    "long": "0712700E"
  },
  {
    "stationcode": "CWP",
    "stationname": "Campbellpore Airport",
    "lat": "334600N",
    "long": "0722600E"
  },
  {
    "stationcode": "RYK",
    "stationname": "Rahim Yar Khan Airport",
    "lat": "282302N",
    "long": "0701646E"
  },
  {
    "stationcode": "SBQ",
    "stationname": "Sibi Airport",
    "lat": "293500N",
    "long": "0675100E"
  },
  {
    "stationcode": "BNP",
    "stationname": "Bannu Airport",
    "lat": "325800N",
    "long": "0703100E"
  },
  {
    "stationcode": "DEA",
    "stationname": "Dera Ghazi Khan Airport",
    "lat": "295738N",
    "long": "0702907E"
  },
  {
    "stationcode": "MFG",
    "stationname": "Muzaffarabad Airport",
    "lat": "342000N",
    "long": "0733100E"
  },
  {
    "stationcode": "KTW",
    "stationname": "Pyrzowice Airport",
    "lat": "502900N",
    "long": "0190500E"
  },
  {
    "stationcode": "QDM",
    "stationname": "Shek Mun Airport",
    "lat": "222400N",
    "long": "1141500E"
  },
  {
    "stationcode": "SZY",
    "stationname": "Mazury Airport",
    "lat": "532850N",
    "long": "0205614E"
  },
  {
    "stationcode": "BXP",
    "stationname": "Biala Podlaska Airport",
    "lat": "520011N",
    "long": "0230837E"
  },
  {
    "stationcode": "WMI",
    "stationname": "Modlin Airport",
    "lat": "522704N",
    "long": "0203906E"
  },
  {
    "stationcode": "OSZ",
    "stationname": "Koszalin Airport",
    "lat": "540300N",
    "long": "0161600E"
  },
  {
    "stationcode": "RDO",
    "stationname": "Radom Airport",
    "lat": "512319N",
    "long": "0211241E"
  },
  {
    "stationcode": "SZZ",
    "stationname": "Goleniow Airport",
    "lat": "533500N",
    "long": "0145400E"
  },
  {
    "stationcode": "LCJ",
    "stationname": "Lodz Lublinek Airport",
    "lat": "514319N",
    "long": "0192353E"
  },
  {
    "stationcode": "WRO",
    "stationname": "Strachowice Airport",
    "lat": "510610N",
    "long": "0165309E"
  },
  {
    "stationcode": "GDN",
    "stationname": "Rebiechowo Airport",
    "lat": "542239N",
    "long": "0182758E"
  },
  {
    "stationcode": "RZE",
    "stationname": "Jasionka Airport",
    "lat": "500700N",
    "long": "0220100E"
  },
  {
    "stationcode": "IEG",
    "stationname": "Babimost Airport",
    "lat": "520819N",
    "long": "0154755E"
  },
  {
    "stationcode": "KRK",
    "stationname": "J. Paul II Balice International Airport",
    "lat": "500440N",
    "long": "0194705E"
  },
  {
    "stationcode": "WAW",
    "stationname": "Frederic Chopin Airport",
    "lat": "520957N",
    "long": "0205802E"
  },
  {
    "stationcode": "POZ",
    "stationname": "Lawica Airport",
    "lat": "522516N",
    "long": "0164935E"
  },
  {
    "stationcode": "CZW",
    "stationname": "Czestochowa Airport",
    "lat": "504900N",
    "long": "0190600E"
  },
  {
    "stationcode": "OSP",
    "stationname": "Redzikowo Airport",
    "lat": "542800N",
    "long": "0170100E"
  },
  {
    "stationcode": "BZG",
    "stationname": "Bydgoszcz Airport",
    "lat": "530548N",
    "long": "0175840E"
  },
  {
    "stationcode": "MQC",
    "stationname": "Miquelon Airport",
    "lat": "470800N",
    "long": "0562300W"
  },
  {
    "stationcode": "FSP",
    "stationname": "Saint Pierre Airport",
    "lat": "464548N",
    "long": "0561028W"
  },
  {
    "stationcode": "CPX",
    "stationname": "Culebra Airport",
    "lat": "181845N",
    "long": "0651811W"
  },
  {
    "stationcode": "ARE",
    "stationname": "Arecibo Airport",
    "lat": "182700N",
    "long": "0664000W"
  },
  {
    "stationcode": "FAJ",
    "stationname": "Fajardo Airport",
    "lat": "182300N",
    "long": "0654500W"
  },
  {
    "stationcode": "MAZ",
    "stationname": "Eugenio M. De Hostos Airport",
    "lat": "181521N",
    "long": "0670855W"
  },
  {
    "stationcode": "BQN",
    "stationname": "Borinquen Airport",
    "lat": "182942N",
    "long": "0670746W"
  },
  {
    "stationcode": "VQS",
    "stationname": "Vieques Airport",
    "lat": "180805N",
    "long": "0652937W"
  },
  {
    "stationcode": "SJU",
    "stationname": "Luis Munoz Marin International Airport",
    "lat": "182622N",
    "long": "0660007W"
  },
  {
    "stationcode": "PPD",
    "stationname": "Palmas Del Mar Air Strip",
    "lat": "180900N",
    "long": "0655000W"
  },
  {
    "stationcode": "HUC",
    "stationname": "Humacao Airport",
    "lat": "180800N",
    "long": "0654900W"
  },
  {
    "stationcode": "SIG",
    "stationname": "Isla Grande Airport",
    "lat": "182725N",
    "long": "0660553W"
  },
  {
    "stationcode": "DDP",
    "stationname": "Dorado Beach Airport",
    "lat": "182900N",
    "long": "0660900W"
  },
  {
    "stationcode": "PSE",
    "stationname": "Mercedita Airport",
    "lat": "180030N",
    "long": "0663347W"
  },
  {
    "stationcode": "NRR",
    "stationname": "J. Aponte de la Torre Airport",
    "lat": "181500N",
    "long": "0653800W"
  },
  {
    "stationcode": "GZA",
    "stationname": "Gaza International Airport",
    "lat": "311447N",
    "long": "0341634E"
  },
  {
    "stationcode": "FLM",
    "stationname": "Filadelfia Airport",
    "lat": "222130S",
    "long": "0600218W"
  },
  {
    "stationcode": "ASU",
    "stationname": "Silvio Pettirossi Airport",
    "lat": "251423S",
    "long": "0573109W"
  },
  {
    "stationcode": "ESG",
    "stationname": "Mariscal Estigarribia Airport",
    "lat": "220242S",
    "long": "0603718W"
  },
  {
    "stationcode": "AYO",
    "stationname": "Ayolas Airport",
    "lat": "272200S",
    "long": "0565100W"
  },
  {
    "stationcode": "BFA",
    "stationname": "Bahia Negra Airport",
    "lat": "201358S",
    "long": "0581013W"
  },
  {
    "stationcode": "CIO",
    "stationname": "MCAL Lopez Airport",
    "lat": "232604S",
    "long": "0572506W"
  },
  {
    "stationcode": "PCJ",
    "stationname": "Puerto La Victoria Airport",
    "lat": "221658S",
    "long": "0575543W"
  },
  {
    "stationcode": "ENO",
    "stationname": "Teniente Prim Alarcon Airport",
    "lat": "271335S",
    "long": "0555014W"
  },
  {
    "stationcode": "PBT",
    "stationname": "Puerto Leda Airport",
    "lat": "203700S",
    "long": "0580137W"
  },
  {
    "stationcode": "OLK",
    "stationname": "Fuerte Olimpo Airport",
    "lat": "210245S",
    "long": "0575258W"
  },
  {
    "stationcode": "AGT",
    "stationname": "Alejo Garcia Airport",
    "lat": "252720S",
    "long": "0545037W"
  },
  {
    "stationcode": "PJC",
    "stationname": "Pedro Juan Caballero Airport",
    "lat": "223309S",
    "long": "0554214W"
  },
  {
    "stationcode": "VMI",
    "stationname": "Vallemi Airport",
    "lat": "221500S",
    "long": "0580000W"
  },
  {
    "stationcode": "PIL",
    "stationname": "Pilar Airport",
    "lat": "265100S",
    "long": "0581900W"
  },
  {
    "stationcode": "RUN",
    "stationname": "Gillot Airport",
    "lat": "205314S",
    "long": "0553037E"
  },
  {
    "stationcode": "ZSE",
    "stationname": "St. Pierre de la Reunion Airport",
    "lat": "211915S",
    "long": "0552530E"
  },
  {
    "stationcode": "CND",
    "stationname": "Kogalniceanu Airport",
    "lat": "442100N",
    "long": "0282900E"
  },
  {
    "stationcode": "OTP",
    "stationname": "Otopeni International Airport",
    "lat": "443420N",
    "long": "0260608E"
  },
  {
    "stationcode": "CLJ",
    "stationname": "Napoca Airport",
    "lat": "464707N",
    "long": "0234110E"
  },
  {
    "stationcode": "CSB",
    "stationname": "Caransebes Airport",
    "lat": "452500N",
    "long": "0221500E"
  },
  {
    "stationcode": "BAY",
    "stationname": "Baia Mare Airport",
    "lat": "473930N",
    "long": "0232806E"
  },
  {
    "stationcode": "TCE",
    "stationname": "Tulcea Airport",
    "lat": "450351N",
    "long": "0284251E"
  },
  {
    "stationcode": "OMR",
    "stationname": "Oradea Airport",
    "lat": "470131N",
    "long": "0215409E"
  },
  {
    "stationcode": "ARW",
    "stationname": "Arad Airport",
    "lat": "461036N",
    "long": "0211543E"
  },
  {
    "stationcode": "DVA",
    "stationname": "Deva Airport",
    "lat": "455300N",
    "long": "0225500E"
  },
  {
    "stationcode": "SBZ",
    "stationname": "Sibiu Airport",
    "lat": "454708N",
    "long": "0240529E"
  },
  {
    "stationcode": "BCM",
    "stationname": "Bacau Airport",
    "lat": "463119N",
    "long": "0265437E"
  },
  {
    "stationcode": "CRA",
    "stationname": "Craiova Airport",
    "lat": "441905N",
    "long": "0235319E"
  },
  {
    "stationcode": "TGM",
    "stationname": "Tirgu Mures Airport",
    "lat": "462804N",
    "long": "0242445E"
  },
  {
    "stationcode": "IAS",
    "stationname": "Iasi Airport",
    "lat": "471043N",
    "long": "0273714E"
  },
  {
    "stationcode": "BBU",
    "stationname": "Baneasa Airport",
    "lat": "443012N",
    "long": "0260608E"
  },
  {
    "stationcode": "SUJ",
    "stationname": "Satu Mare Airport",
    "lat": "474200N",
    "long": "0225300E"
  },
  {
    "stationcode": "TSR",
    "stationname": "Timisoara Airport",
    "lat": "454836N",
    "long": "0212016E"
  },
  {
    "stationcode": "SCV",
    "stationname": "Salcea Airport",
    "lat": "474122N",
    "long": "0262122E"
  },
  {
    "stationcode": "UZC",
    "stationname": "Ponikve Airport",
    "lat": "435400N",
    "long": "0194210E"
  },
  {
    "stationcode": "PRN",
    "stationname": "Pristina International Airport",
    "lat": "423422N",
    "long": "0210209E"
  },
  {
    "stationcode": "BJY",
    "stationname": "Batajnica Airport",
    "lat": "445605N",
    "long": "0201524E"
  },
  {
    "stationcode": "BEG",
    "stationname": "Beograd Airport",
    "lat": "444906N",
    "long": "0201833E"
  },
  {
    "stationcode": "INI",
    "stationname": "Nis Airport",
    "lat": "432014N",
    "long": "0215113E"
  },
  {
    "stationcode": "GYI",
    "stationname": "Gisenyi Airport",
    "lat": "014200S",
    "long": "0291500E"
  },
  {
    "stationcode": "KGL",
    "stationname": "Kigali International Airport",
    "lat": "015807S",
    "long": "0300822E"
  },
  {
    "stationcode": "BTQ",
    "stationname": "Butare Airport",
    "lat": "023600S",
    "long": "0294400E"
  },
  {
    "stationcode": "KME",
    "stationname": "Kamembe Airport",
    "lat": "022744S",
    "long": "0285429E"
  },
  {
    "stationcode": "RHG",
    "stationname": "Ruhengeri Airport",
    "lat": "013000S",
    "long": "0293800E"
  },
  {
    "stationcode": "MJH",
    "stationname": "Majma Airport",
    "lat": "255000N",
    "long": "0452200E"
  },
  {
    "stationcode": "EJH",
    "stationname": "Wedjh Airport",
    "lat": "261155N",
    "long": "0362835E"
  },
  {
    "stationcode": "TUI",
    "stationname": "Turaif Airport",
    "lat": "314134N",
    "long": "0384352E"
  },
  {
    "stationcode": "TUU",
    "stationname": "Tabuk Airport",
    "lat": "282156N",
    "long": "0363708E"
  },
  {
    "stationcode": "KMC",
    "stationname": "King Khalid Military City Airport",
    "lat": "275403N",
    "long": "0453142E"
  },
  {
    "stationcode": "RAH",
    "stationname": "Rafha Airport",
    "lat": "293735N",
    "long": "0432926E"
  },
  {
    "stationcode": "SHW",
    "stationname": "Sharurah Airport",
    "lat": "172810N",
    "long": "0470642E"
  },
  {
    "stationcode": "EAM",
    "stationname": "Nejran Airport",
    "lat": "173642N",
    "long": "0442547E"
  },
  {
    "stationcode": "DMM",
    "stationname": "King Fahad International Airport",
    "lat": "262816N",
    "long": "0494752E"
  },
  {
    "stationcode": "SLF",
    "stationname": "Sulayel Airport",
    "lat": "202800N",
    "long": "0453700E"
  },
  {
    "stationcode": "KMX",
    "stationname": "Khamis Mushait Airport",
    "lat": "181800N",
    "long": "0424800E"
  },
  {
    "stationcode": "URY",
    "stationname": "Gurayat Airport",
    "lat": "312443N",
    "long": "0371646E"
  },
  {
    "stationcode": "HOF",
    "stationname": "Alahsa Airport",
    "lat": "251707N",
    "long": "0492907E"
  },
  {
    "stationcode": "ABT",
    "stationname": "Al-Aqiq Airport",
    "lat": "201708N",
    "long": "0413800E"
  },
  {
    "stationcode": "AHB",
    "stationname": "Abha Airport",
    "lat": "181425N",
    "long": "0423924E"
  },
  {
    "stationcode": "HAS",
    "stationname": "Hail Airport",
    "lat": "272617N",
    "long": "0414111E"
  },
  {
    "stationcode": "GIZ",
    "stationname": "Jazan Airport",
    "lat": "165404N",
    "long": "0423509E"
  },
  {
    "stationcode": "YNB",
    "stationname": "Yanbu Airport",
    "lat": "240839N",
    "long": "0380348E"
  },
  {
    "stationcode": "TIF",
    "stationname": "Taif Airport",
    "lat": "212918N",
    "long": "0403236E"
  },
  {
    "stationcode": "DWD",
    "stationname": "Dawadmi Airport",
    "lat": "242700N",
    "long": "0440716E"
  },
  {
    "stationcode": "AQI",
    "stationname": "Qaisumah Airport",
    "lat": "282007N",
    "long": "0460730E"
  },
  {
    "stationcode": "AJF",
    "stationname": "Jouf Airport",
    "lat": "294706N",
    "long": "0400600E"
  },
  {
    "stationcode": "BHH",
    "stationname": "Bisha Airport",
    "lat": "195904N",
    "long": "0423715E"
  },
  {
    "stationcode": "UZH",
    "stationname": "Unayzah Airport",
    "lat": "260600N",
    "long": "0435600E"
  },
  {
    "stationcode": "ZUL",
    "stationname": "Zilfi Airport",
    "lat": "261800N",
    "long": "0444900E"
  },
  {
    "stationcode": "ELQ",
    "stationname": "Gassim Airport",
    "lat": "261810N",
    "long": "0434626E"
  },
  {
    "stationcode": "RAE",
    "stationname": "Arar Airport",
    "lat": "305400N",
    "long": "0410800E"
  },
  {
    "stationcode": "WAE",
    "stationname": "Wadi Ad Dawasir Airport",
    "lat": "203015N",
    "long": "0451158E"
  },
  {
    "stationcode": "HBT",
    "stationname": "Hafr Albatin Airport",
    "lat": "060722N",
    "long": "0810615E"
  },
  {
    "stationcode": "MNY",
    "stationname": "Mono Airport",
    "lat": "072500S",
    "long": "1553355E"
  },
  {
    "stationcode": "MUA",
    "stationname": "Munda Airport",
    "lat": "081938S",
    "long": "1571542E"
  },
  {
    "stationcode": "PRS",
    "stationname": "Parasi Airport",
    "lat": "093831S",
    "long": "1612533E"
  },
  {
    "stationcode": "ANH",
    "stationname": "Anuha Island Resort Airport",
    "lat": "085900S",
    "long": "1603000E"
  },
  {
    "stationcode": "GTA",
    "stationname": "Gatokae Aerodrom",
    "lat": "084419S",
    "long": "1581210E"
  },
  {
    "stationcode": "AFT",
    "stationname": "Afutara Aerodrome",
    "lat": "091128S",
    "long": "1605657E"
  },
  {
    "stationcode": "EGM",
    "stationname": "Sege Airport",
    "lat": "083443S",
    "long": "1575232E"
  },
  {
    "stationcode": "CHY",
    "stationname": "Choiseul Bay Airport",
    "lat": "064245S",
    "long": "1562346E"
  },
  {
    "stationcode": "KGE",
    "stationname": "Kagau Airport",
    "lat": "071942S",
    "long": "1573500E"
  },
  {
    "stationcode": "KWS",
    "stationname": "Kwailabesi Aerodrome",
    "lat": "082121S",
    "long": "1604631E"
  },
  {
    "stationcode": "RNA",
    "stationname": "Ulawa Airport",
    "lat": "095139S",
    "long": "1615847E"
  },
  {
    "stationcode": "OTV",
    "stationname": "Ontong Java Airport",
    "lat": "053027S",
    "long": "1593133E"
  },
  {
    "stationcode": "IRA",
    "stationname": "Kirakira Airport",
    "lat": "102657S",
    "long": "1615355E"
  },
  {
    "stationcode": "VAO",
    "stationname": "Suavanao Airstrip",
    "lat": "073507S",
    "long": "1584351E"
  },
  {
    "stationcode": "SCZ",
    "stationname": "Santa Cruz Island Airport",
    "lat": "104311S",
    "long": "1654747E"
  },
  {
    "stationcode": "GZO",
    "stationname": "Gizo Airport",
    "lat": "080551S",
    "long": "1565149E"
  },
  {
    "stationcode": "TLG",
    "stationname": "Tulaghi Airport",
    "lat": "090600S",
    "long": "1600900E"
  },
  {
    "stationcode": "RUS",
    "stationname": "Marau Sound Airport",
    "lat": "095142S",
    "long": "1604932E"
  },
  {
    "stationcode": "TAA",
    "stationname": "Tarapaina Airport",
    "lat": "092200S",
    "long": "1611200E"
  },
  {
    "stationcode": "GEF",
    "stationname": "Geva Airstrip",
    "lat": "073440S",
    "long": "1563550E"
  },
  {
    "stationcode": "AVU",
    "stationname": "Avu Avu Airport",
    "lat": "095029S",
    "long": "1602301E"
  },
  {
    "stationcode": "RNL",
    "stationname": "Rennell Airport",
    "lat": "113304S",
    "long": "1600350E"
  },
  {
    "stationcode": "MBU",
    "stationname": "Mbambanakira Airport",
    "lat": "094451S",
    "long": "1595023E"
  },
  {
    "stationcode": "GSI",
    "stationname": "Guadalcanal Airport",
    "lat": "041704N",
    "long": "0542243W"
  },
  {
    "stationcode": "VEV",
    "stationname": "Barakoma Airport",
    "lat": "075500S",
    "long": "1564200E"
  },
  {
    "stationcode": "BAS",
    "stationname": "Balalae Airport",
    "lat": "065928S",
    "long": "1555309E"
  },
  {
    "stationcode": "KUE",
    "stationname": "Kukundu Airport",
    "lat": "080100S",
    "long": "1565700E"
  },
  {
    "stationcode": "ONE",
    "stationname": "Onepusu Airport",
    "lat": "091000S",
    "long": "1610400E"
  },
  {
    "stationcode": "RRI",
    "stationname": "Barora Airport",
    "lat": "073600S",
    "long": "1582400E"
  },
  {
    "stationcode": "AKS",
    "stationname": "Gwaunaru'u Airport",
    "lat": "084209S",
    "long": "1604055E"
  },
  {
    "stationcode": "SVY",
    "stationname": "Savo Airport",
    "lat": "091000S",
    "long": "1595000E"
  },
  {
    "stationcode": "RBV",
    "stationname": "Ramata Airport",
    "lat": "081004S",
    "long": "1573831E"
  },
  {
    "stationcode": "HIR",
    "stationname": "Henderson International Airport",
    "lat": "092546S",
    "long": "1600304E"
  },
  {
    "stationcode": "NNB",
    "stationname": "Santa Ana Airport",
    "lat": "105054S",
    "long": "1622715E"
  },
  {
    "stationcode": "JJA",
    "stationname": "Jajao Airport",
    "lat": "081248S",
    "long": "1591600E"
  },
  {
    "stationcode": "VIU",
    "stationname": "Viru Harbour Airstrip",
    "lat": "083000S",
    "long": "1574100E"
  },
  {
    "stationcode": "ATD",
    "stationname": "Atoifi Airport",
    "lat": "085224S",
    "long": "1610041E"
  },
  {
    "stationcode": "RIN",
    "stationname": "Ringi Cove Airport",
    "lat": "080732S",
    "long": "1570835E"
  },
  {
    "stationcode": "NAZ",
    "stationname": "Nana Airport",
    "lat": "103920S",
    "long": "1621221E"
  },
  {
    "stationcode": "KWR",
    "stationname": "Kwai Harbour Airport",
    "lat": "083400S",
    "long": "1604400E"
  },
  {
    "stationcode": "BPF",
    "stationname": "Batuna Airport",
    "lat": "083347S",
    "long": "1580709E"
  },
  {
    "stationcode": "FRE",
    "stationname": "Fera Island Airport",
    "lat": "080627S",
    "long": "1593438E"
  },
  {
    "stationcode": "XYA",
    "stationname": "Yandina Airport",
    "lat": "090356S",
    "long": "1591254E"
  },
  {
    "stationcode": "BNY",
    "stationname": "Bellona Airport",
    "lat": "111808S",
    "long": "1594756E"
  },
  {
    "stationcode": "BDI",
    "stationname": "Bird Island Airport",
    "lat": "034300S",
    "long": "0551300E"
  },
  {
    "stationcode": "SEZ",
    "stationname": "Seychelles International Airport",
    "lat": "044028S",
    "long": "0553119E"
  },
  {
    "stationcode": "DEI",
    "stationname": "Denis Island Airport",
    "lat": "034800S",
    "long": "0554000E"
  },
  {
    "stationcode": "FRK",
    "stationname": "Fregate Island Airport",
    "lat": "043500S",
    "long": "0555700E"
  },
  {
    "stationcode": "PRI",
    "stationname": "Praslin Island Airport",
    "lat": "041909S",
    "long": "0554129E"
  },
  {
    "stationcode": "DES",
    "stationname": "Desroches Airport",
    "lat": "054149S",
    "long": "0533921E"
  },
  {
    "stationcode": "MWE",
    "stationname": "Merowe Airport",
    "lat": "182633N",
    "long": "0315036E"
  },
  {
    "stationcode": "DOG",
    "stationname": "Dongola Airport",
    "lat": "190909N",
    "long": "0302549E"
  },
  {
    "stationcode": "NHF",
    "stationname": "New Halfa Airport",
    "lat": "152100N",
    "long": "0354400E"
  },
  {
    "stationcode": "WHF",
    "stationname": "Wadi Halfa Airport",
    "lat": "214810N",
    "long": "0313119E"
  },
  {
    "stationcode": "KDX",
    "stationname": "Kadugli Airport",
    "lat": "110817N",
    "long": "0294203E"
  },
  {
    "stationcode": "JUB",
    "stationname": "Juba Airport",
    "lat": "045219N",
    "long": "0313604E"
  },
  {
    "stationcode": "KSL",
    "stationname": "Kassala Airport",
    "lat": "152315N",
    "long": "0361944E"
  },
  {
    "stationcode": "DNX",
    "stationname": "Galegu Airport",
    "lat": "120000N",
    "long": "0353000E"
  },
  {
    "stationcode": "DNI",
    "stationname": "Wad Medani Airport",
    "lat": "142300N",
    "long": "0333200E"
  },
  {
    "stationcode": "KJN",
    "stationname": "Kenana Airport",
    "lat": "131000N",
    "long": "0324300E"
  },
  {
    "stationcode": "KST",
    "stationname": "Kosti Airport",
    "lat": "131100N",
    "long": "0324400E"
  },
  {
    "stationcode": "BJE",
    "stationname": "Baleela Airport",
    "lat": "111233N",
    "long": "0283136E"
  },
  {
    "stationcode": "AEE",
    "stationname": "Adareil Airport",
    "lat": "100340N",
    "long": "0325743E"
  },
  {
    "stationcode": "GBU",
    "stationname": "Khashm El Girba Airport",
    "lat": "145535N",
    "long": "0355320E"
  },
  {
    "stationcode": "NUD",
    "stationname": "En Nahud Airport",
    "lat": "124200N",
    "long": "0282500E"
  },
  {
    "stationcode": "AAD",
    "stationname": "Ad-Dabbah Airport",
    "lat": "060546N",
    "long": "0463815E"
  },
  {
    "stationcode": "RBX",
    "stationname": "Rumbek Airport",
    "lat": "064930N",
    "long": "0294009E"
  },
  {
    "stationcode": "PZU",
    "stationname": "Port Sudan Airport",
    "lat": "192601N",
    "long": "0371403E"
  },
  {
    "stationcode": "UYL",
    "stationname": "Nyala Airport",
    "lat": "120313N",
    "long": "0245722E"
  },
  {
    "stationcode": "WUU",
    "stationname": "Wau Airport",
    "lat": "074333N",
    "long": "0275824E"
  },
  {
    "stationcode": "GSU",
    "stationname": "Gedaref Airport",
    "lat": "140800N",
    "long": "0351900E"
  },
  {
    "stationcode": "RSS",
    "stationname": "Roseires Airport",
    "lat": "114707N",
    "long": "0342012E"
  },
  {
    "stationcode": "EBD",
    "stationname": "El Obeid Airport",
    "lat": "130910N",
    "long": "0301358E"
  },
  {
    "stationcode": "MAK",
    "stationname": "Malakal Airport",
    "lat": "093332N",
    "long": "0313908E"
  },
  {
    "stationcode": "EDB",
    "stationname": "Eldebba Airport",
    "lat": "181500N",
    "long": "0311500E"
  },
  {
    "stationcode": "ATB",
    "stationname": "Atbara Airport",
    "lat": "174235N",
    "long": "0340326E"
  },
  {
    "stationcode": "HGI",
    "stationname": "Higlieg Airport",
    "lat": "265804N",
    "long": "0933826E"
  },
  {
    "stationcode": "ELF",
    "stationname": "El Fasher Airport",
    "lat": "133654N",
    "long": "0251929E"
  },
  {
    "stationcode": "EGN",
    "stationname": "Geneina Airport",
    "lat": "132901N",
    "long": "0222821E"
  },
  {
    "stationcode": "KRT",
    "stationname": "Civil Airport",
    "lat": "153522N",
    "long": "0323311E"
  },
  {
    "stationcode": "IDB",
    "stationname": "Idre Airport",
    "lat": "615200N",
    "long": "0124300E"
  },
  {
    "stationcode": "RNB",
    "stationname": "Kallinge Airport",
    "lat": "561600N",
    "long": "0151554E"
  },
  {
    "stationcode": "HFS",
    "stationname": "Hagfors Airport",
    "lat": "600112N",
    "long": "0133444E"
  },
  {
    "stationcode": "THN",
    "stationname": "Trollhattan Airport",
    "lat": "581905N",
    "long": "0122042E"
  },
  {
    "stationcode": "BLE",
    "stationname": "Dala Airport",
    "lat": "602519N",
    "long": "0153055E"
  },
  {
    "stationcode": "VVK",
    "stationname": "Vastervik Airport",
    "lat": "574700N",
    "long": "0163200E"
  },
  {
    "stationcode": "ARN",
    "stationname": "Arlanda Airport",
    "lat": "593907N",
    "long": "0175507E"
  },
  {
    "stationcode": "GSE",
    "stationname": "Saeve Airport",
    "lat": "574629N",
    "long": "0115213E"
  },
  {
    "stationcode": "AJR",
    "stationname": "Arvidsjaur Airport",
    "lat": "653525N",
    "long": "0191655E"
  },
  {
    "stationcode": "TYF",
    "stationname": "Torsby Airport",
    "lat": "600927N",
    "long": "0125929E"
  },
  {
    "stationcode": "JKG",
    "stationname": "Axamo Airport",
    "lat": "574527N",
    "long": "0140407E"
  },
  {
    "stationcode": "NYO",
    "stationname": "Skavsta Airport",
    "lat": "584719N",
    "long": "0165444E"
  },
  {
    "stationcode": "HMV",
    "stationname": "Hemavan Airport",
    "lat": "654822N",
    "long": "0150458E"
  },
  {
    "stationcode": "GVX",
    "stationname": "Sandviken Airport",
    "lat": "603600N",
    "long": "0165700E"
  },
  {
    "stationcode": "KID",
    "stationname": "Kristianstad Airport",
    "lat": "555510N",
    "long": "0140520E"
  },
  {
    "stationcode": "GOT",
    "stationname": "Landvetter Airport",
    "lat": "574000N",
    "long": "0121700E"
  },
  {
    "stationcode": "SOO",
    "stationname": "Soderhamn Airport",
    "lat": "611600N",
    "long": "0170600E"
  },
  {
    "stationcode": "MXX",
    "stationname": "Mora Airport",
    "lat": "605728N",
    "long": "0143041E"
  },
  {
    "stationcode": "ORB",
    "stationname": "Orebro-Bofors Airport",
    "lat": "591325N",
    "long": "0150217E"
  },
  {
    "stationcode": "PJA",
    "stationname": "Pajala Airport",
    "lat": "671444N",
    "long": "0230408E"
  },
  {
    "stationcode": "MMX",
    "stationname": "Sturup Airport",
    "lat": "553233N",
    "long": "0132230E"
  },
  {
    "stationcode": "LYC",
    "stationname": "Lycksele Airport",
    "lat": "643254N",
    "long": "0184258E"
  },
  {
    "stationcode": "VBY",
    "stationname": "Visby Airport",
    "lat": "573947N",
    "long": "0182058E"
  },
  {
    "stationcode": "HLF",
    "stationname": "Hultsfred Airport",
    "lat": "573100N",
    "long": "0155000E"
  },
  {
    "stationcode": "LPI",
    "stationname": "Linkoping Airport",
    "lat": "582408N",
    "long": "0153132E"
  },
  {
    "stationcode": "VXO",
    "stationname": "Vaxjo Airport",
    "lat": "565600N",
    "long": "0144400E"
  },
  {
    "stationcode": "VHM",
    "stationname": "Vilhelmina Airport",
    "lat": "643445N",
    "long": "0165001E"
  },
  {
    "stationcode": "KRN",
    "stationname": "Kiruna Airport",
    "lat": "674919N",
    "long": "0202012E"
  },
  {
    "stationcode": "BMA",
    "stationname": "Bromma Airport",
    "lat": "592116N",
    "long": "0175630E"
  },
  {
    "stationcode": "KLR",
    "stationname": "Kalmar Airport",
    "lat": "564108N",
    "long": "0161715E"
  },
  {
    "stationcode": "VST",
    "stationname": "Vasteras/Hasslo Airport",
    "lat": "593522N",
    "long": "0163801E"
  },
  {
    "stationcode": "GEV",
    "stationname": "Gallivare Airport",
    "lat": "670757N",
    "long": "0204853E"
  },
  {
    "stationcode": "KRF",
    "stationname": "Kramfors Airport",
    "lat": "630258N",
    "long": "0174622E"
  },
  {
    "stationcode": "EVG",
    "stationname": "Sveg Airport",
    "lat": "620252N",
    "long": "0142523E"
  },
  {
    "stationcode": "SDL",
    "stationname": "Sundsvall/Harnosand Airport",
    "lat": "623200N",
    "long": "0172700E"
  },
  {
    "stationcode": "SQO",
    "stationname": "Gunnarn Airport",
    "lat": "645739N",
    "long": "0174148E"
  },
  {
    "stationcode": "HAD",
    "stationname": "Halmstad Airport",
    "lat": "564128N",
    "long": "0124913E"
  },
  {
    "stationcode": "KVB",
    "stationname": "Skovde Airport",
    "lat": "582723N",
    "long": "0135822E"
  },
  {
    "stationcode": "NRK",
    "stationname": "Kungsangen Airport",
    "lat": "583510N",
    "long": "0161455E"
  },
  {
    "stationcode": "AGH",
    "stationname": "Angelholm Airport",
    "lat": "561746N",
    "long": "0125050E"
  },
  {
    "stationcode": "LDK",
    "stationname": "Hovby Airport",
    "lat": "582800N",
    "long": "0131007E"
  },
  {
    "stationcode": "KSD",
    "stationname": "Karlstad Airport",
    "lat": "592641N",
    "long": "0132015E"
  },
  {
    "stationcode": "LLA",
    "stationname": "Kallax Airport",
    "lat": "653238N",
    "long": "0220719E"
  },
  {
    "stationcode": "HUV",
    "stationname": "Hudiksvall Airport",
    "lat": "614601N",
    "long": "0170503E"
  },
  {
    "stationcode": "KSK",
    "stationname": "Karlskoga Airport",
    "lat": "592000N",
    "long": "0143100E"
  },
  {
    "stationcode": "EKT",
    "stationname": "Eskilstuna Airport",
    "lat": "592100N",
    "long": "0164300E"
  },
  {
    "stationcode": "OSK",
    "stationname": "Oskarshamn Airport",
    "lat": "572102N",
    "long": "0162953E"
  },
  {
    "stationcode": "SFT",
    "stationname": "Skelleftea Airport",
    "lat": "643730N",
    "long": "0210442E"
  },
  {
    "stationcode": "UME",
    "stationname": "Umea Airport",
    "lat": "634731N",
    "long": "0201658E"
  },
  {
    "stationcode": "OSD",
    "stationname": "Froesoe Airport",
    "lat": "631140N",
    "long": "0143001E"
  },
  {
    "stationcode": "OER",
    "stationname": "Ornskoldsvik Airport",
    "lat": "632430N",
    "long": "0185930E"
  },
  {
    "stationcode": "XSP",
    "stationname": "Seletar Airport",
    "lat": "012501N",
    "long": "1035204E"
  },
  {
    "stationcode": "TGA",
    "stationname": "Tengah Airport",
    "lat": "012300N",
    "long": "1034300E"
  },
  {
    "stationcode": "QPG",
    "stationname": "Paya Lebar Airport",
    "lat": "012133N",
    "long": "1035433E"
  },
  {
    "stationcode": "HLE",
    "stationname": "Saint Helena Airport",
    "lat": "155729S",
    "long": "0053842W"
  },
  {
    "stationcode": "ASI",
    "stationname": "Wideawake Field",
    "lat": "075800S",
    "long": "0142400W"
  },
  {
    "stationcode": "POW",
    "stationname": "Portoroz Airport",
    "lat": "452824N",
    "long": "0133654E"
  },
  {
    "stationcode": "LJU",
    "stationname": "Brnik Airport",
    "lat": "461329N",
    "long": "0142739E"
  },
  {
    "stationcode": "MBX",
    "stationname": "Maribor Airport",
    "lat": "462848N",
    "long": "0154110E"
  },
  {
    "stationcode": "LYR",
    "stationname": "Svalbard Airport",
    "lat": "781446N",
    "long": "0152756E"
  },
  {
    "stationcode": "LUE",
    "stationname": "Lucenec Airport",
    "lat": "482100N",
    "long": "0194430E"
  },
  {
    "stationcode": "BTS",
    "stationname": "Ivanka Airport",
    "lat": "481000N",
    "long": "0171300E"
  },
  {
    "stationcode": "ILZ",
    "stationname": "Zilina Airport",
    "lat": "491354N",
    "long": "0183649E"
  },
  {
    "stationcode": "KSC",
    "stationname": "Barca Airport",
    "lat": "483947N",
    "long": "0211428E"
  },
  {
    "stationcode": "SLD",
    "stationname": "Sliac Airport",
    "lat": "483816N",
    "long": "0190803E"
  },
  {
    "stationcode": "PZY",
    "stationname": "Piestany Airport",
    "lat": "483732N",
    "long": "0174944E"
  },
  {
    "stationcode": "TAT",
    "stationname": "Poprad/Tatry Airport",
    "lat": "490425N",
    "long": "0201428E"
  },
  {
    "stationcode": "POV",
    "stationname": "Presov Airport",
    "lat": "490000N",
    "long": "0211500E"
  },
  {
    "stationcode": "FNA",
    "stationname": "Lungi International Airport",
    "lat": "083703N",
    "long": "0131149W"
  },
  {
    "stationcode": "KBA",
    "stationname": "Kabala Airport",
    "lat": "093500N",
    "long": "0113300W"
  },
  {
    "stationcode": "WYE",
    "stationname": "Yengema Airport",
    "lat": "083700N",
    "long": "0110300W"
  },
  {
    "stationcode": "HGS",
    "stationname": "Hastings Airport",
    "lat": "082400N",
    "long": "0130800W"
  },
  {
    "stationcode": "SRK",
    "stationname": "Sierra Leone Airport",
    "lat": "774711N",
    "long": "0703818W"
  },
  {
    "stationcode": "KBS",
    "stationname": "Bo Airport",
    "lat": "075700N",
    "long": "0114600W"
  },
  {
    "stationcode": "BTE",
    "stationname": "Bonthe Airport",
    "lat": "073100N",
    "long": "0123000W"
  },
  {
    "stationcode": "DSL",
    "stationname": "Daru Airport",
    "lat": "075900N",
    "long": "0105000W"
  },
  {
    "stationcode": "GBK",
    "stationname": "Gbangbatok Airport",
    "lat": "074801N",
    "long": "0122200W"
  },
  {
    "stationcode": "KEN",
    "stationname": "Kenema Airport",
    "lat": "075200N",
    "long": "0111200W"
  },
  {
    "stationcode": "SMY",
    "stationname": "Simenti Airport",
    "lat": "131000N",
    "long": "0131000W"
  },
  {
    "stationcode": "ZIG",
    "stationname": "Ziguinchor Airport",
    "lat": "123320N",
    "long": "0161654W"
  },
  {
    "stationcode": "XLS",
    "stationname": "St. Louis Airport",
    "lat": "160303N",
    "long": "0162747W"
  },
  {
    "stationcode": "CSK",
    "stationname": "Cap Skirring Airport",
    "lat": "122341N",
    "long": "0164451W"
  },
  {
    "stationcode": "POD",
    "stationname": "Podor Airport",
    "lat": "164034N",
    "long": "0145745W"
  },
  {
    "stationcode": "KGG",
    "stationname": "Kedougou Airport",
    "lat": "123400N",
    "long": "0123000W"
  },
  {
    "stationcode": "TUD",
    "stationname": "Tambacounda Airport",
    "lat": "134413N",
    "long": "0133911W"
  },
  {
    "stationcode": "NIK",
    "stationname": "Niokolo Koba Airport",
    "lat": "130430N",
    "long": "0124300W"
  },
  {
    "stationcode": "KDA",
    "stationname": "Kolda Airport",
    "lat": "125356N",
    "long": "0145806W"
  },
  {
    "stationcode": "RDT",
    "stationname": "Richard Toll Airport",
    "lat": "162600N",
    "long": "0153900W"
  },
  {
    "stationcode": "KLC",
    "stationname": "Kaolack Airport",
    "lat": "140500N",
    "long": "0163000W"
  },
  {
    "stationcode": "BXE",
    "stationname": "Bakel Airport",
    "lat": "145200N",
    "long": "0122900W"
  },
  {
    "stationcode": "MAX",
    "stationname": "Matam Airport",
    "lat": "153600N",
    "long": "0131900W"
  },
  {
    "stationcode": "BSA",
    "stationname": "Bossaso Airport",
    "lat": "111632N",
    "long": "0490928E"
  },
  {
    "stationcode": "GBM",
    "stationname": "Garbaharey Airport",
    "lat": "032000N",
    "long": "0421100E"
  },
  {
    "stationcode": "BBO",
    "stationname": "Berbera Airport",
    "lat": "102321N",
    "long": "0445628E"
  },
  {
    "stationcode": "CMO",
    "stationname": "Obbia Airport",
    "lat": "052200N",
    "long": "0483100E"
  },
  {
    "stationcode": "GLK",
    "stationname": "Galcaio Airport",
    "lat": "064653N",
    "long": "0472716E"
  },
  {
    "stationcode": "GSR",
    "stationname": "Gardo Airport",
    "lat": "093100N",
    "long": "0490500E"
  },
  {
    "stationcode": "HCM",
    "stationname": "Eli Airport",
    "lat": "080000N",
    "long": "0495530E"
  },
  {
    "stationcode": "BSY",
    "stationname": "Bardera Airport",
    "lat": "022100N",
    "long": "0422000E"
  },
  {
    "stationcode": "BUO",
    "stationname": "Burao Airport",
    "lat": "093138N",
    "long": "0453319E"
  },
  {
    "stationcode": "KMU",
    "stationname": "Kismayu Airport",
    "lat": "002238S",
    "long": "0422734E"
  },
  {
    "stationcode": "BIB",
    "stationname": "Baidoa Airport",
    "lat": "030609N",
    "long": "0433740E"
  },
  {
    "stationcode": "ALU",
    "stationname": "Alula Airport",
    "lat": "115800N",
    "long": "0504500E"
  },
  {
    "stationcode": "LGX",
    "stationname": "Lugh Ganane Airport",
    "lat": "034800N",
    "long": "0420300E"
  },
  {
    "stationcode": "CMS",
    "stationname": "Scusciuban Airport",
    "lat": "101800N",
    "long": "0501359E"
  },
  {
    "stationcode": "LKR",
    "stationname": "Las Khoreh Airport",
    "lat": "110900N",
    "long": "0481200E"
  },
  {
    "stationcode": "MGQ",
    "stationname": "Mogadishu International Airport",
    "lat": "020053N",
    "long": "0451818E"
  },
  {
    "stationcode": "ERA",
    "stationname": "Erigavo Airport",
    "lat": "103700N",
    "long": "0472400E"
  },
  {
    "stationcode": "HGA",
    "stationname": "Hargeisa Airport",
    "lat": "093050N",
    "long": "0440502E"
  },
  {
    "stationcode": "CXN",
    "stationname": "Candala Airport",
    "lat": "112939N",
    "long": "0495431E"
  },
  {
    "stationcode": "BXX",
    "stationname": "Borama Airport",
    "lat": "095800N",
    "long": "0430700E"
  },
  {
    "stationcode": "GGR",
    "stationname": "Garoe Airport",
    "lat": "082729N",
    "long": "0483403E"
  },
  {
    "stationcode": "ABN",
    "stationname": "Albina Airport",
    "lat": "053100N",
    "long": "0540300W"
  },
  {
    "stationcode": "BTO",
    "stationname": "Botopasie Airport",
    "lat": "041306N",
    "long": "0552648W"
  },
  {
    "stationcode": "OEM",
    "stationname": "Vincent Fayks Airport",
    "lat": "032100N",
    "long": "0552700W"
  },
  {
    "stationcode": "TOT",
    "stationname": "Coronie Airport",
    "lat": "055157N",
    "long": "0561939W"
  },
  {
    "stationcode": "MOJ",
    "stationname": "Moengo Airport",
    "lat": "053700N",
    "long": "0542400W"
  },
  {
    "stationcode": "ICK",
    "stationname": "Nieuw Nickerie Airport",
    "lat": "055600N",
    "long": "0565900W"
  },
  {
    "stationcode": "AGI",
    "stationname": "Wageningen Airport",
    "lat": "054600N",
    "long": "0564100W"
  },
  {
    "stationcode": "WSO",
    "stationname": "Washabo Airport",
    "lat": "051300N",
    "long": "0571100W"
  },
  {
    "stationcode": "DOE",
    "stationname": "Djoemoe Airport",
    "lat": "040100N",
    "long": "0552900W"
  },
  {
    "stationcode": "ORG",
    "stationname": "Zorg En Hoop Airport",
    "lat": "054840N",
    "long": "0551127W"
  },
  {
    "stationcode": "PBM",
    "stationname": "Zanderij International Airport",
    "lat": "052705N",
    "long": "0551128W"
  },
  {
    "stationcode": "KCB",
    "stationname": "Tepoe Airstrip",
    "lat": "030900N",
    "long": "0554300W"
  },
  {
    "stationcode": "SMZ",
    "stationname": "Stoelmanseiland Airport",
    "lat": "042100N",
    "long": "0542500W"
  },
  {
    "stationcode": "DRJ",
    "stationname": "Drietabbetje Airport",
    "lat": "040640N",
    "long": "0544023W"
  },
  {
    "stationcode": "LDO",
    "stationname": "Ladouanie Airport",
    "lat": "042300N",
    "long": "0552500W"
  },
  {
    "stationcode": "AAJ",
    "stationname": "Cayana Airstrip",
    "lat": "035400N",
    "long": "0552200W"
  },
  {
    "stationcode": "TMS",
    "stationname": "Sao Tome Island Airport",
    "lat": "002241N",
    "long": "0064244E"
  },
  {
    "stationcode": "PGP",
    "stationname": "Porto Alegre Airport",
    "lat": "000200N",
    "long": "0063200E"
  },
  {
    "stationcode": "PCP",
    "stationname": "Principe Airport",
    "lat": "014000N",
    "long": "0072500E"
  },
  {
    "stationcode": "SAL",
    "stationname": "Comalapa International Airport",
    "lat": "132629N",
    "long": "0890320W"
  },
  {
    "stationcode": "LTK",
    "stationname": "Latakia Airport",
    "lat": "352404N",
    "long": "0355655E"
  },
  {
    "stationcode": "KAC",
    "stationname": "Kameshli Airport",
    "lat": "370114N",
    "long": "0411129E"
  },
  {
    "stationcode": "ALP",
    "stationname": "Nejrab Airport",
    "lat": "361050N",
    "long": "0371328E"
  },
  {
    "stationcode": "DEZ",
    "stationname": "Al Jafrah Airport",
    "lat": "351700N",
    "long": "0401000E"
  },
  {
    "stationcode": "SOR",
    "stationname": "Al Thaurah Airport",
    "lat": "342400N",
    "long": "0401000E"
  },
  {
    "stationcode": "DAM",
    "stationname": "Damascus International Airport",
    "lat": "332450N",
    "long": "0363104E"
  },
  {
    "stationcode": "PMS",
    "stationname": "Palmyra Airport",
    "lat": "343400N",
    "long": "0381800E"
  },
  {
    "stationcode": "MTS",
    "stationname": "Matsapha International Airport",
    "lat": "263127S",
    "long": "0311841E"
  },
  {
    "stationcode": "MDS",
    "stationname": "Middle Caicos Airport",
    "lat": "214700N",
    "long": "0714600W"
  },
  {
    "stationcode": "PLS",
    "stationname": "Providenciales International Airport",
    "lat": "214625N",
    "long": "0721557W"
  },
  {
    "stationcode": "GDT",
    "stationname": "Grand Turk Island Airport",
    "lat": "212643N",
    "long": "0710843W"
  },
  {
    "stationcode": "XSC",
    "stationname": "South Caicos International Airport",
    "lat": "213057N",
    "long": "0713143W"
  },
  {
    "stationcode": "NCA",
    "stationname": "North Caicos Airport",
    "lat": "215303N",
    "long": "0715622W"
  },
  {
    "stationcode": "SLX",
    "stationname": "Salt Cay Airport",
    "lat": "212009N",
    "long": "0711202W"
  },
  {
    "stationcode": "PIC",
    "stationname": "Pine Cay Airport",
    "lat": "215500N",
    "long": "0715900W"
  },
  {
    "stationcode": "AKM",
    "stationname": "Zakouma Airport",
    "lat": "105300N",
    "long": "0194900E"
  },
  {
    "stationcode": "AOD",
    "stationname": "Abou Deia Airport",
    "lat": "112800N",
    "long": "0191700E"
  },
  {
    "stationcode": "BKR",
    "stationname": "Bokoro Airport",
    "lat": "122259N",
    "long": "0170402E"
  },
  {
    "stationcode": "AMO",
    "stationname": "Mao Airport",
    "lat": "140700N",
    "long": "0151900E"
  },
  {
    "stationcode": "ATV",
    "stationname": "Ati Airport",
    "lat": "131400N",
    "long": "0181900E"
  },
  {
    "stationcode": "LTC",
    "stationname": "Lai Airport",
    "lat": "092500N",
    "long": "0160100E"
  },
  {
    "stationcode": "OGR",
    "stationname": "Bongor Airport",
    "lat": "101722N",
    "long": "0152300E"
  },
  {
    "stationcode": "AEH",
    "stationname": "Abecher Airport",
    "lat": "135051N",
    "long": "0205040E"
  },
  {
    "stationcode": "MQQ",
    "stationname": "Moundou Airport",
    "lat": "083735N",
    "long": "0160421E"
  },
  {
    "stationcode": "FYT",
    "stationname": "Faya Airport",
    "lat": "175500N",
    "long": "0190635E"
  },
  {
    "stationcode": "OTC",
    "stationname": "Bol Airport",
    "lat": "132600N",
    "long": "0144400E"
  },
  {
    "stationcode": "AMC",
    "stationname": "Am Timan Airport",
    "lat": "110200N",
    "long": "0201700E"
  },
  {
    "stationcode": "OUT",
    "stationname": "Bousso Airport",
    "lat": "102900N",
    "long": "0164300E"
  },
  {
    "stationcode": "SRH",
    "stationname": "Sarh Airport",
    "lat": "090846N",
    "long": "0182233E"
  },
  {
    "stationcode": "OUM",
    "stationname": "Oum Hadjer Airport",
    "lat": "131600N",
    "long": "0194300E"
  },
  {
    "stationcode": "NDJ",
    "stationname": "Ndjamena Airport",
    "lat": "120801N",
    "long": "0150202E"
  },
  {
    "stationcode": "MVO",
    "stationname": "Mongo Airport",
    "lat": "121200N",
    "long": "0184700E"
  },
  {
    "stationcode": "MEF",
    "stationname": "Melfi Airport",
    "lat": "110300N",
    "long": "0175700E"
  },
  {
    "stationcode": "PLF",
    "stationname": "Pala Airport",
    "lat": "092300N",
    "long": "0145600E"
  },
  {
    "stationcode": "LFW",
    "stationname": "Lome Airport",
    "lat": "060956N",
    "long": "0011516E"
  },
  {
    "stationcode": "LRL",
    "stationname": "Niamtougou Airport",
    "lat": "094603N",
    "long": "0010529E"
  },
  {
    "stationcode": "MAQ",
    "stationname": "Mae Sot Airport",
    "lat": "164159N",
    "long": "0983237E"
  },
  {
    "stationcode": "SGZ",
    "stationname": "Songkhla Airport",
    "lat": "072000N",
    "long": "1003200E"
  },
  {
    "stationcode": "TKH",
    "stationname": "Takhli Airport",
    "lat": "151500N",
    "long": "1002100E"
  },
  {
    "stationcode": "PYY",
    "stationname": "Pai Airport",
    "lat": "192215N",
    "long": "0982608E"
  },
  {
    "stationcode": "NAK",
    "stationname": "Nakhon Ratchasima Airport",
    "lat": "145659N",
    "long": "1021847E"
  },
  {
    "stationcode": "PHZ",
    "stationname": "Phi Phi Island Airport",
    "lat": "081000N",
    "long": "0981700E"
  },
  {
    "stationcode": "BAO",
    "stationname": "Udorn Airport",
    "lat": "473851N",
    "long": "0233309E"
  },
  {
    "stationcode": "PAN",
    "stationname": "Pattani Airport",
    "lat": "064700N",
    "long": "1011000E"
  },
  {
    "stationcode": "KKM",
    "stationname": "Lop Buri Airport",
    "lat": "145229N",
    "long": "1003949E"
  },
  {
    "stationcode": "HGN",
    "stationname": "Mae Hong Son Airport",
    "lat": "191806N",
    "long": "0975830E"
  },
  {
    "stationcode": "LPT",
    "stationname": "Lampang Airport",
    "lat": "181620N",
    "long": "0993015E"
  },
  {
    "stationcode": "UTR",
    "stationname": "Uttaradit Airport",
    "lat": "174010N",
    "long": "1001438E"
  },
  {
    "stationcode": "PBS",
    "stationname": "Patong Beach Airport",
    "lat": "075343N",
    "long": "0981730E"
  },
  {
    "stationcode": "TKT",
    "stationname": "Tak Airport",
    "lat": "165300N",
    "long": "0991000E"
  },
  {
    "stationcode": "TDX",
    "stationname": "Trat Airport",
    "lat": "121605N",
    "long": "1021901E"
  },
  {
    "stationcode": "PYX",
    "stationname": "Pattaya Airport",
    "lat": "125700N",
    "long": "1005300E"
  },
  {
    "stationcode": "PRH",
    "stationname": "Phrae Airport",
    "lat": "180754N",
    "long": "1000953E"
  },
  {
    "stationcode": "PHY",
    "stationname": "Phetchabun Airport",
    "lat": "164037N",
    "long": "1011142E"
  },
  {
    "stationcode": "KDT",
    "stationname": "Kamphangsaen Airport",
    "lat": "140600N",
    "long": "0995500E"
  },
  {
    "stationcode": "PXR",
    "stationname": "Surin Airport",
    "lat": "145200N",
    "long": "1033000E"
  },
  {
    "stationcode": "THS",
    "stationname": "Sukhothai Airport",
    "lat": "171416N",
    "long": "0994906E"
  },
  {
    "stationcode": "PMM",
    "stationname": "Phanom Sarakham Airport",
    "lat": "134500N",
    "long": "1012100E"
  },
  {
    "stationcode": "USM",
    "stationname": "Koh Samui Airport",
    "lat": "093301N",
    "long": "1000345E"
  },
  {
    "stationcode": "LBD",
    "stationname": "Khudzhand Airport",
    "lat": "401255N",
    "long": "0694141E"
  },
  {
    "stationcode": "DYU",
    "stationname": "Dushanbe Airport",
    "lat": "383236N",
    "long": "0684930E"
  },
  {
    "stationcode": "KQT",
    "stationname": "Kurgan Tube Airport",
    "lat": "375202N",
    "long": "0685153E"
  },
  {
    "stationcode": "TJU",
    "stationname": "Kulyab Airport",
    "lat": "375918N",
    "long": "0694819E"
  },
  {
    "stationcode": "UAI",
    "stationname": "Suai Airport",
    "lat": "091800S",
    "long": "1251700E"
  },
  {
    "stationcode": "KRW",
    "stationname": "Turkmanbashi (Charter)",
    "lat": "400339N",
    "long": "0530029E"
  },
  {
    "stationcode": "ASB",
    "stationname": "Ashgabat (Charter)",
    "lat": "375913N",
    "long": "0582139E"
  },
  {
    "stationcode": "TAZ",
    "stationname": "Dashoguz Airport",
    "lat": "414542N",
    "long": "0595033E"
  },
  {
    "stationcode": "MYP",
    "stationname": "Mary Airport",
    "lat": "373723N",
    "long": "0615345E"
  },
  {
    "stationcode": "MIR",
    "stationname": "Habib Bourguiba International Airport",
    "lat": "354525N",
    "long": "0104510E"
  },
  {
    "stationcode": "GAF",
    "stationname": "Gafsa Airport",
    "lat": "342519N",
    "long": "0084921E"
  },
  {
    "stationcode": "EBM",
    "stationname": "El Borma Airport",
    "lat": "314214N",
    "long": "0091518E"
  },
  {
    "stationcode": "DJE",
    "stationname": "Melita Airport",
    "lat": "335230N",
    "long": "0104632E"
  },
  {
    "stationcode": "SFA",
    "stationname": "Sfax El Maou Airport",
    "lat": "344305N",
    "long": "0104128E"
  },
  {
    "stationcode": "TOE",
    "stationname": "Tozeur Airport",
    "lat": "335623N",
    "long": "0080638E"
  },
  {
    "stationcode": "GAE",
    "stationname": "Gabes Airport",
    "lat": "334357N",
    "long": "0095458E"
  },
  {
    "stationcode": "TBJ",
    "stationname": "Tabarka Airport",
    "lat": "365848N",
    "long": "0085237E"
  },
  {
    "stationcode": "NBE",
    "stationname": "Zine Elabidine Ben Ali Airport",
    "lat": "360432N",
    "long": "0102618E"
  },
  {
    "stationcode": "VAV",
    "stationname": "Lupepau'u Airport",
    "lat": "183508S",
    "long": "1735750W"
  },
  {
    "stationcode": "TBU",
    "stationname": "Fua'Amotu International Airport",
    "lat": "211440S",
    "long": "1750837W"
  },
  {
    "stationcode": "HPA",
    "stationname": "Salote Pilolevu Airport",
    "lat": "194641S",
    "long": "1742029W"
  },
  {
    "stationcode": "NTT",
    "stationname": "Kuini Lavenia Airport",
    "lat": "155839S",
    "long": "1734729W"
  },
  {
    "stationcode": "EUA",
    "stationname": "Kaufana Airport",
    "lat": "212238S",
    "long": "1745727W"
  },
  {
    "stationcode": "NFO",
    "stationname": "Mata'aho Airport",
    "lat": "153415S",
    "long": "1753745W"
  },
  {
    "stationcode": "ESK",
    "stationname": "Eskisehir Airport",
    "lat": "394600N",
    "long": "0303000E"
  },
  {
    "stationcode": "SXZ",
    "stationname": "Siirt Airport",
    "lat": "375843N",
    "long": "0415022E"
  },
  {
    "stationcode": "BDM",
    "stationname": "Bandirma Airport",
    "lat": "401900N",
    "long": "0275900E"
  },
  {
    "stationcode": "ANK",
    "stationname": "Etimesgut Airport",
    "lat": "395710N",
    "long": "0324110E"
  },
  {
    "stationcode": "GNY",
    "stationname": "Guney Anadolu Projesi Airport",
    "lat": "370540N",
    "long": "0385049E"
  },
  {
    "stationcode": "KSY",
    "stationname": "Kars Airport",
    "lat": "403344N",
    "long": "0430654E"
  },
  {
    "stationcode": "BAL",
    "stationname": "Batman Airport",
    "lat": "375544N",
    "long": "0410700E"
  },
  {
    "stationcode": "AFY",
    "stationname": "Afyon Airport",
    "lat": "384400N",
    "long": "0303600E"
  },
  {
    "stationcode": "OGU",
    "stationname": "OrdueGiresun Airport",
    "lat": "405801N",
    "long": "0380509E"
  },
  {
    "stationcode": "EZS",
    "stationname": "Elazig Airport",
    "lat": "383630N",
    "long": "0391730E"
  },
  {
    "stationcode": "VAN",
    "stationname": "Van Airport",
    "lat": "382731N",
    "long": "0431956E"
  },
  {
    "stationcode": "MQJ",
    "stationname": "Merkez Airport",
    "lat": "662703N",
    "long": "1431541E"
  },
  {
    "stationcode": "KCM",
    "stationname": "Kahramanmaras Airport",
    "lat": "373220N",
    "long": "0365712E"
  },
  {
    "stationcode": "SIC",
    "stationname": "Sinop Airport",
    "lat": "091419N",
    "long": "0781329W"
  },
  {
    "stationcode": "ADB",
    "stationname": "Adnan Menderes Airport",
    "lat": "381733N",
    "long": "0270925E"
  },
  {
    "stationcode": "MSR",
    "stationname": "Mus Airport",
    "lat": "384330N",
    "long": "0413730E"
  },
  {
    "stationcode": "CKZ",
    "stationname": "Canakkale Airport",
    "lat": "400816N",
    "long": "0262536E"
  },
  {
    "stationcode": "DIY",
    "stationname": "Diyarbakir Airport",
    "lat": "375338N",
    "long": "0401204E"
  },
  {
    "stationcode": "BZI",
    "stationname": "Balikesir Airport",
    "lat": "393700N",
    "long": "0275610E"
  },
  {
    "stationcode": "SFQ",
    "stationname": "Sanliurfa Airport",
    "lat": "370507N",
    "long": "0385100E"
  },
  {
    "stationcode": "AJI",
    "stationname": "Agri Airport",
    "lat": "393914N",
    "long": "0430135E"
  },
  {
    "stationcode": "KZR",
    "stationname": "Zafer Airport",
    "lat": "390646N",
    "long": "0300743E"
  },
  {
    "stationcode": "GKD",
    "stationname": "Gokceada Airport",
    "lat": "401204N",
    "long": "0255256E"
  },
  {
    "stationcode": "ISE",
    "stationname": "Isparta Airport",
    "lat": "375158N",
    "long": "0302256E"
  },
  {
    "stationcode": "SSX",
    "stationname": "Samsun Airport",
    "lat": "244903S",
    "long": "0313239E"
  },
  {
    "stationcode": "YKO",
    "stationname": "Hakkari Yuksekova Airport",
    "lat": "373258N",
    "long": "0441418E"
  },
  {
    "stationcode": "USQ",
    "stationname": "Usak Airport",
    "lat": "384053N",
    "long": "0292818E"
  },
  {
    "stationcode": "VAS",
    "stationname": "Sivas Airport",
    "lat": "394850N",
    "long": "0365415E"
  },
  {
    "stationcode": "MLX",
    "stationname": "Malatya Airport",
    "lat": "382116N",
    "long": "0381513E"
  },
  {
    "stationcode": "GZT",
    "stationname": "Gaziantep Airport",
    "lat": "365650N",
    "long": "0372843E"
  },
  {
    "stationcode": "KCO",
    "stationname": "Cengiz Topel Airport",
    "lat": "404400N",
    "long": "0300500E"
  },
  {
    "stationcode": "YEI",
    "stationname": "Yenisehir Airport",
    "lat": "401400N",
    "long": "0293300E"
  },
  {
    "stationcode": "EDO",
    "stationname": "Edremit/Korfez Airport",
    "lat": "393317N",
    "long": "0270050E"
  },
  {
    "stationcode": "IGL",
    "stationname": "Cigli Military Airport",
    "lat": "383100N",
    "long": "0270100E"
  },
  {
    "stationcode": "BTZ",
    "stationname": "Bursa Airport",
    "lat": "401404N",
    "long": "0290035E"
  },
  {
    "stationcode": "MQM",
    "stationname": "Mardin Airport",
    "lat": "371407N",
    "long": "0403806E"
  },
  {
    "stationcode": "KFS",
    "stationname": "Kastamonu Airport",
    "lat": "411854N",
    "long": "0334746E"
  },
  {
    "stationcode": "ADF",
    "stationname": "Adiyaman Airport",
    "lat": "374353N",
    "long": "0382808E"
  },
  {
    "stationcode": "NAV",
    "stationname": "Nevsehir Airport",
    "lat": "384619N",
    "long": "0343204E"
  },
  {
    "stationcode": "SZF",
    "stationname": "Carsamba Airport",
    "lat": "411516N",
    "long": "0363402E"
  },
  {
    "stationcode": "AOE",
    "stationname": "Anadolu University Airport",
    "lat": "394836N",
    "long": "0303101E"
  },
  {
    "stationcode": "ESB",
    "stationname": "Esenboga Airport",
    "lat": "400741N",
    "long": "0325942E"
  },
  {
    "stationcode": "ERZ",
    "stationname": "Erzurum Airport",
    "lat": "395723N",
    "long": "0411013E"
  },
  {
    "stationcode": "NKT",
    "stationname": "Sirnak Airport",
    "lat": "372149N",
    "long": "0420343E"
  },
  {
    "stationcode": "HTY",
    "stationname": "Hatay Airport",
    "lat": "362135N",
    "long": "0361705E"
  },
  {
    "stationcode": "CII",
    "stationname": "Cildir Airport",
    "lat": "374859N",
    "long": "0275314E"
  },
  {
    "stationcode": "DLM",
    "stationname": "Dalaman Airport",
    "lat": "364247N",
    "long": "0284733E"
  },
  {
    "stationcode": "ONQ",
    "stationname": "Zonguldak Airport",
    "lat": "413020N",
    "long": "0320519E"
  },
  {
    "stationcode": "TZX",
    "stationname": "Trabzon Airport",
    "lat": "405955N",
    "long": "0394635E"
  },
  {
    "stationcode": "ADA",
    "stationname": "Adana Airport",
    "lat": "365856N",
    "long": "0351649E"
  },
  {
    "stationcode": "NOP",
    "stationname": "Sinop Airport",
    "lat": "420117N",
    "long": "0350439E"
  },
  {
    "stationcode": "GZP",
    "stationname": "Gazipasa Airport",
    "lat": "361753N",
    "long": "0321725E"
  },
  {
    "stationcode": "BJV",
    "stationname": "Milas Airport",
    "lat": "371502N",
    "long": "0273952E"
  },
  {
    "stationcode": "BXN",
    "stationname": "Imsik Airport",
    "lat": "370821N",
    "long": "0273959E"
  },
  {
    "stationcode": "TEQ",
    "stationname": "Corlu Airport",
    "lat": "410820N",
    "long": "0275510E"
  },
  {
    "stationcode": "MZH",
    "stationname": "Amasya Merzifon Airport",
    "lat": "404943N",
    "long": "0353113E"
  },
  {
    "stationcode": "DNZ",
    "stationname": "Cardak Airport",
    "lat": "374708N",
    "long": "0294205E"
  },
  {
    "stationcode": "KYA",
    "stationname": "Konya Airport",
    "lat": "375844N",
    "long": "0323343E"
  },
  {
    "stationcode": "ERC",
    "stationname": "Erzincan Airport",
    "lat": "394237N",
    "long": "0393137E"
  },
  {
    "stationcode": "TJK",
    "stationname": "Tokat Airport",
    "lat": "401826N",
    "long": "0362200E"
  },
  {
    "stationcode": "AYT",
    "stationname": "Antalya Airport",
    "lat": "365355N",
    "long": "0304802E"
  },
  {
    "stationcode": "IGD",
    "stationname": "Igdir Airport",
    "lat": "395822N",
    "long": "0435304E"
  },
  {
    "stationcode": "SAW",
    "stationname": "Sabiha Gokcen Airport",
    "lat": "405355N",
    "long": "0291833E"
  },
  {
    "stationcode": "ASR",
    "stationname": "Kayseri Airport",
    "lat": "384613N",
    "long": "0352944E"
  },
  {
    "stationcode": "TAB",
    "stationname": "Tobago Airport",
    "lat": "110847N",
    "long": "0605012W"
  },
  {
    "stationcode": "POS",
    "stationname": "Piarco Airport",
    "lat": "103600N",
    "long": "0612100W"
  },
  {
    "stationcode": "FUN",
    "stationname": "Funafuti Atol International Airport",
    "lat": "083131S",
    "long": "1791145E"
  },
  {
    "stationcode": "DSX",
    "stationname": "Dongsha Island Airport",
    "lat": "204224N",
    "long": "1164317E"
  },
  {
    "stationcode": "HCN",
    "stationname": "Hengchun Airport",
    "lat": "220228N",
    "long": "1204349E"
  },
  {
    "stationcode": "SMT",
    "stationname": "Sun Moon Lake Airport",
    "lat": "122846S",
    "long": "0554022W"
  },
  {
    "stationcode": "HSZ",
    "stationname": "Hsinchu Airport",
    "lat": "244900N",
    "long": "1205600E"
  },
  {
    "stationcode": "GNI",
    "stationname": "Green Island Airport",
    "lat": "224027N",
    "long": "1212758E"
  },
  {
    "stationcode": "KYD",
    "stationname": "Orchid Island Airport",
    "lat": "220500N",
    "long": "1213000E"
  },
  {
    "stationcode": "TSA",
    "stationname": "Sung Shan Airport",
    "lat": "250411N",
    "long": "1213309E"
  },
  {
    "stationcode": "RMQ",
    "stationname": "ChingChuanKang Airport",
    "lat": "241554N",
    "long": "1203715E"
  },
  {
    "stationcode": "TTT",
    "stationname": "Taitung Airport",
    "lat": "224735N",
    "long": "1211055E"
  },
  {
    "stationcode": "CYI",
    "stationname": "Chiayi Airport",
    "lat": "232748N",
    "long": "1202326E"
  },
  {
    "stationcode": "MZG",
    "stationname": "Makung Airport",
    "lat": "233407N",
    "long": "1193742E"
  },
  {
    "stationcode": "TNN",
    "stationname": "Tainan Airport",
    "lat": "225707N",
    "long": "1201200E"
  },
  {
    "stationcode": "MFK",
    "stationname": "Matsu Airport",
    "lat": "261326N",
    "long": "1200010E"
  },
  {
    "stationcode": "WOT",
    "stationname": "Wonan Airport",
    "lat": "232500N",
    "long": "1193000E"
  },
  {
    "stationcode": "KNH",
    "stationname": "Shang-Yi Airport",
    "lat": "242547N",
    "long": "1182142E"
  },
  {
    "stationcode": "CMJ",
    "stationname": "Chi Mei Airport",
    "lat": "231500N",
    "long": "1192500E"
  },
  {
    "stationcode": "TXG",
    "stationname": "Taichung Airport",
    "lat": "240900N",
    "long": "1203846E"
  },
  {
    "stationcode": "LHN",
    "stationname": "Lishan Airport",
    "lat": "232600N",
    "long": "1211800E"
  },
  {
    "stationcode": "HUN",
    "stationname": "Hualien Airport",
    "lat": "240123N",
    "long": "1213704E"
  },
  {
    "stationcode": "PIF",
    "stationname": "Pingtung Airport",
    "lat": "224201N",
    "long": "1202855E"
  },
  {
    "stationcode": "ARK",
    "stationname": "Arusha Airport",
    "lat": "032202S",
    "long": "0363730E"
  },
  {
    "stationcode": "MUZ",
    "stationname": "Musoma Airport",
    "lat": "013000S",
    "long": "0334800E"
  },
  {
    "stationcode": "IRI",
    "stationname": "Nduli Airport",
    "lat": "074012S",
    "long": "0354507E"
  },
  {
    "stationcode": "SHY",
    "stationname": "Shinyanga Airport",
    "lat": "033600S",
    "long": "0333000E"
  },
  {
    "stationcode": "TGT",
    "stationname": "Tanga Airport",
    "lat": "050533S",
    "long": "0390418E"
  },
  {
    "stationcode": "NCH",
    "stationname": "Nachingwea Airport",
    "lat": "102200S",
    "long": "0384800E"
  },
  {
    "stationcode": "MWN",
    "stationname": "Mwadui Airport",
    "lat": "033300S",
    "long": "0333600E"
  },
  {
    "stationcode": "XMI",
    "stationname": "Masasi Airport",
    "lat": "104400S",
    "long": "0384600E"
  },
  {
    "stationcode": "GIT",
    "stationname": "Geita Airport",
    "lat": "025200S",
    "long": "0321000E"
  },
  {
    "stationcode": "JRO",
    "stationname": "Kilimanjaro Airport",
    "lat": "032600S",
    "long": "0370400E"
  },
  {
    "stationcode": "MFA",
    "stationname": "Mafia Airport",
    "lat": "075446S",
    "long": "0393958E"
  },
  {
    "stationcode": "LKY",
    "stationname": "Lake Manyara Airport",
    "lat": "032232S",
    "long": "0354905E"
  },
  {
    "stationcode": "TKQ",
    "stationname": "Kigoma Airport",
    "lat": "045317S",
    "long": "0294018E"
  },
  {
    "stationcode": "ZNZ",
    "stationname": "Kisauni Airport",
    "lat": "061309S",
    "long": "0391319E"
  },
  {
    "stationcode": "LDI",
    "stationname": "Kikwetu Airport",
    "lat": "095000S",
    "long": "0394700E"
  },
  {
    "stationcode": "PMA",
    "stationname": "Wawi Airport",
    "lat": "051432S",
    "long": "0394807E"
  },
  {
    "stationcode": "TBO",
    "stationname": "Tabora Airport",
    "lat": "050436S",
    "long": "0324952E"
  },
  {
    "stationcode": "DAR",
    "stationname": "Dar Es Salaam International Airport",
    "lat": "065231S",
    "long": "0391207E"
  },
  {
    "stationcode": "BKZ",
    "stationname": "Bukoba Airport",
    "lat": "011957S",
    "long": "0314919E"
  },
  {
    "stationcode": "MWZ",
    "stationname": "Mwanza Airport",
    "lat": "022641S",
    "long": "0325541E"
  },
  {
    "stationcode": "LUY",
    "stationname": "Lushoto Airport",
    "lat": "430054N",
    "long": "0073307W"
  },
  {
    "stationcode": "SUT",
    "stationname": "Sumbawanga Airport",
    "lat": "075654S",
    "long": "0313642E"
  },
  {
    "stationcode": "SGX",
    "stationname": "Songea Airport",
    "lat": "104100S",
    "long": "0353500E"
  },
  {
    "stationcode": "MBI",
    "stationname": "Mbeya Airport",
    "lat": "085511S",
    "long": "0332750E"
  },
  {
    "stationcode": "GTZ",
    "stationname": "Kirawira B Airport",
    "lat": "020939S",
    "long": "0341330E"
  },
  {
    "stationcode": "MYW",
    "stationname": "Mtwara Airport",
    "lat": "102020S",
    "long": "0401137E"
  },
  {
    "stationcode": "DOD",
    "stationname": "Dodoma Airport",
    "lat": "061014S",
    "long": "0354509E"
  },
  {
    "stationcode": "JOM",
    "stationname": "Njombe Airport",
    "lat": "092127S",
    "long": "0344630E"
  },
  {
    "stationcode": "SEU",
    "stationname": "Seronera Airport",
    "lat": "022726S",
    "long": "0344919E"
  },
  {
    "stationcode": "KIY",
    "stationname": "Kilwa Airport",
    "lat": "085437S",
    "long": "0393031E"
  },
  {
    "stationcode": "IFO",
    "stationname": "Ivano-Frankovsk Airport",
    "lat": "485303N",
    "long": "0244110E"
  },
  {
    "stationcode": "KCP",
    "stationname": "Kamenets-Podolskiy Airport",
    "lat": "484100N",
    "long": "0263700E"
  },
  {
    "stationcode": "IEV",
    "stationname": "Kiev-Zhulhany Airport",
    "lat": "502406N",
    "long": "0302659E"
  },
  {
    "stationcode": "ERD",
    "stationname": "Berdyansk Airport",
    "lat": "464400N",
    "long": "0364700E"
  },
  {
    "stationcode": "DNK",
    "stationname": "Dnepropetrovsk Airport",
    "lat": "482126N",
    "long": "0350602E"
  },
  {
    "stationcode": "KBP",
    "stationname": "Kiev-Borispol Airport",
    "lat": "502042N",
    "long": "0305341E"
  },
  {
    "stationcode": "HRK",
    "stationname": "Kharkov Airport",
    "lat": "495534N",
    "long": "0361701E"
  },
  {
    "stationcode": "UKS",
    "stationname": "Belbek Airport",
    "lat": "444130N",
    "long": "0333433E"
  },
  {
    "stationcode": "UDJ",
    "stationname": "Uzhgorod Airport",
    "lat": "483803N",
    "long": "0221548E"
  },
  {
    "stationcode": "UMY",
    "stationname": "Sumy Airport",
    "lat": "505600N",
    "long": "0344700E"
  },
  {
    "stationcode": "CEJ",
    "stationname": "Chernigov Airport",
    "lat": "400403N",
    "long": "0020812W"
  },
  {
    "stationcode": "KHE",
    "stationname": "Kherson Airport",
    "lat": "464038N",
    "long": "0323026E"
  },
  {
    "stationcode": "UCK",
    "stationname": "Lutsk Airport",
    "lat": "504500N",
    "long": "0252000E"
  },
  {
    "stationcode": "CKC",
    "stationname": "Cherkassy Airport",
    "lat": "492500N",
    "long": "0320000E"
  },
  {
    "stationcode": "VIN",
    "stationname": "Vinnica Airport",
    "lat": "491442N",
    "long": "0283637E"
  },
  {
    "stationcode": "VSG",
    "stationname": "Luhans'k Airport",
    "lat": "482505N",
    "long": "0392245E"
  },
  {
    "stationcode": "SIP",
    "stationname": "Simferopol Airport",
    "lat": "450308N",
    "long": "0335826E"
  },
  {
    "stationcode": "DOK",
    "stationname": "Donetsk Airport",
    "lat": "480425N",
    "long": "0374423E"
  },
  {
    "stationcode": "NLV",
    "stationname": "Nikolaev Airport",
    "lat": "470328N",
    "long": "0315511E"
  },
  {
    "stationcode": "KWG",
    "stationname": "Krivoy Rog Airport",
    "lat": "480300N",
    "long": "0331300E"
  },
  {
    "stationcode": "ODS",
    "stationname": "Odessa-Central Airport",
    "lat": "462536N",
    "long": "0304035E"
  },
  {
    "stationcode": "KHC",
    "stationname": "Kerch Airport",
    "lat": "452222N",
    "long": "0362408E"
  },
  {
    "stationcode": "RWN",
    "stationname": "Rovno Airport",
    "lat": "503600N",
    "long": "0260900E"
  },
  {
    "stationcode": "ZTR",
    "stationname": "Zhitomir Airport",
    "lat": "501500N",
    "long": "0283900E"
  },
  {
    "stationcode": "KGO",
    "stationname": "Kirovograd Airport",
    "lat": "483300N",
    "long": "0321800E"
  },
  {
    "stationcode": "LWO",
    "stationname": "Snilow Airport",
    "lat": "494845N",
    "long": "0235722E"
  },
  {
    "stationcode": "OZH",
    "stationname": "Zaporozhye Airport",
    "lat": "475201N",
    "long": "0351856E"
  },
  {
    "stationcode": "MPW",
    "stationname": "Mariupol Airport",
    "lat": "470434N",
    "long": "0372658E"
  },
  {
    "stationcode": "PLV",
    "stationname": "Poltava Airport",
    "lat": "493410N",
    "long": "0342345E"
  },
  {
    "stationcode": "KHU",
    "stationname": "Kremenchug Airport",
    "lat": "490600N",
    "long": "0332700E"
  },
  {
    "stationcode": "TNL",
    "stationname": "Ternopol Airport",
    "lat": "493400N",
    "long": "0253600E"
  },
  {
    "stationcode": "HMJ",
    "stationname": "Khmelnitskiy Airport",
    "lat": "492500N",
    "long": "0270000E"
  },
  {
    "stationcode": "SEV",
    "stationname": "Severodoneck Airport",
    "lat": "485400N",
    "long": "0383300E"
  },
  {
    "stationcode": "CWC",
    "stationname": "Chernovtsy Airport",
    "lat": "481534N",
    "long": "0255849E"
  },
  {
    "stationcode": "MXR",
    "stationname": "Mirgorod Airport",
    "lat": "495800N",
    "long": "0333600E"
  },
  {
    "stationcode": "KRQ",
    "stationname": "Kramatorsk Airport",
    "lat": "484300N",
    "long": "0373700E"
  },
  {
    "stationcode": "PAF",
    "stationname": "Pakuba Airport",
    "lat": "021203N",
    "long": "0313306E"
  },
  {
    "stationcode": "MBQ",
    "stationname": "Mbarara Airport",
    "lat": "003300S",
    "long": "0303600E"
  },
  {
    "stationcode": "KBG",
    "stationname": "Kabalega Falls Airport",
    "lat": "022000N",
    "long": "0313000E"
  },
  {
    "stationcode": "ULU",
    "stationname": "Gulu Airport",
    "lat": "024821N",
    "long": "0321619E"
  },
  {
    "stationcode": "RUA",
    "stationname": "Arua Airport",
    "lat": "030259N",
    "long": "0305443E"
  },
  {
    "stationcode": "KHX",
    "stationname": "Kihihi Airport",
    "lat": "004310S",
    "long": "0294200E"
  },
  {
    "stationcode": "EBB",
    "stationname": "Entebbe Airport",
    "lat": "000233N",
    "long": "0322637E"
  },
  {
    "stationcode": "SRT",
    "stationname": "Soroti Airport",
    "lat": "014300N",
    "long": "0333700E"
  },
  {
    "stationcode": "KCU",
    "stationname": "Masindi Airport",
    "lat": "014100N",
    "long": "0314300E"
  },
  {
    "stationcode": "OYG",
    "stationname": "Moyo Airport",
    "lat": "033900N",
    "long": "0314300E"
  },
  {
    "stationcode": "TRY",
    "stationname": "Tororo Airport",
    "lat": "004200N",
    "long": "0341100E"
  },
  {
    "stationcode": "JIN",
    "stationname": "Jinja Airport",
    "lat": "002707N",
    "long": "0331140E"
  },
  {
    "stationcode": "KSE",
    "stationname": "Kasese Airport",
    "lat": "001106N",
    "long": "0300607E"
  },
  {
    "stationcode": "TAW",
    "stationname": "Tacuarembo Airport",
    "lat": "314500S",
    "long": "0555500W"
  },
  {
    "stationcode": "STY",
    "stationname": "Salto Airport",
    "lat": "312600S",
    "long": "0575900W"
  },
  {
    "stationcode": "PDP",
    "stationname": "Punta Del Este Airport",
    "lat": "345125S",
    "long": "0550603W"
  },
  {
    "stationcode": "MLZ",
    "stationname": "Melo Airport",
    "lat": "322000S",
    "long": "0541200W"
  },
  {
    "stationcode": "ATI",
    "stationname": "Artigas Airport",
    "lat": "302500S",
    "long": "0563100W"
  },
  {
    "stationcode": "BUV",
    "stationname": "Bella Union Airport",
    "lat": "302000S",
    "long": "0570500W"
  },
  {
    "stationcode": "DZO",
    "stationname": "Durazno Airport",
    "lat": "332200S",
    "long": "0563100W"
  },
  {
    "stationcode": "RVY",
    "stationname": "Rivera Airport",
    "lat": "305800S",
    "long": "0552800W"
  },
  {
    "stationcode": "PDU",
    "stationname": "Paysandu Airport",
    "lat": "322200S",
    "long": "0580300W"
  },
  {
    "stationcode": "VCH",
    "stationname": "Vichadero Airport",
    "lat": "314530S",
    "long": "0543650W"
  },
  {
    "stationcode": "CYR",
    "stationname": "Colonia Airport",
    "lat": "342724S",
    "long": "0574615W"
  },
  {
    "stationcode": "TYT",
    "stationname": "Treinta-y-Tres Airport",
    "lat": "331200S",
    "long": "0542100W"
  },
  {
    "stationcode": "MVD",
    "stationname": "Carrasco Airport",
    "lat": "345000S",
    "long": "0560200W"
  },
  {
    "stationcode": "NMA",
    "stationname": "Namangan Airport",
    "lat": "405906N",
    "long": "0713325E"
  },
  {
    "stationcode": "TAS",
    "stationname": "Vostochny Airport",
    "lat": "411600N",
    "long": "0691300E"
  },
  {
    "stationcode": "NCU",
    "stationname": "Nukus Airport",
    "lat": "422912N",
    "long": "0593722E"
  },
  {
    "stationcode": "FEG",
    "stationname": "Fergana Airport",
    "lat": "402141N",
    "long": "0714441E"
  },
  {
    "stationcode": "KSQ",
    "stationname": "Karshi Airport",
    "lat": "384807N",
    "long": "0654629E"
  },
  {
    "stationcode": "TMJ",
    "stationname": "Termez Airport",
    "lat": "371607N",
    "long": "0671709E"
  },
  {
    "stationcode": "BHK",
    "stationname": "Bukhara Airport",
    "lat": "394559N",
    "long": "0642848E"
  },
  {
    "stationcode": "AFS",
    "stationname": "Zarafshan Airport",
    "lat": "413657N",
    "long": "0641359E"
  },
  {
    "stationcode": "AZN",
    "stationname": "Andizhan Airport",
    "lat": "404340N",
    "long": "0721738E"
  },
  {
    "stationcode": "UGC",
    "stationname": "Urgench Airport",
    "lat": "413501N",
    "long": "0603832E"
  },
  {
    "stationcode": "NVI",
    "stationname": "Navoi Airport",
    "lat": "400659N",
    "long": "0650957E"
  },
  {
    "stationcode": "SKD",
    "stationname": "Samarkand Airport",
    "lat": "394202N",
    "long": "0665905E"
  },
  {
    "stationcode": "PLI",
    "stationname": "Palm Island Airport",
    "lat": "123500N",
    "long": "0612300W"
  },
  {
    "stationcode": "BQU",
    "stationname": "Bequia Airport",
    "lat": "125900N",
    "long": "0611500W"
  },
  {
    "stationcode": "UNI",
    "stationname": "Union Island Airport",
    "lat": "123700N",
    "long": "0612600W"
  },
  {
    "stationcode": "CIW",
    "stationname": "Canouan Island Airport",
    "lat": "124157N",
    "long": "0612033W"
  },
  {
    "stationcode": "MQS",
    "stationname": "Mustique Airport",
    "lat": "125318N",
    "long": "0611045W"
  },
  {
    "stationcode": "SVD",
    "stationname": "E. T. Joshua",
    "lat": "130800N",
    "long": "0611200W"
  },
  {
    "stationcode": "KAV",
    "stationname": "Kavanayen Airport",
    "lat": "054000N",
    "long": "0614600W"
  },
  {
    "stationcode": "HGE",
    "stationname": "Higuerote Airport",
    "lat": "102744N",
    "long": "0660534W"
  },
  {
    "stationcode": "PPZ",
    "stationname": "Puerto Paez Airport",
    "lat": "061200N",
    "long": "0672700W"
  },
  {
    "stationcode": "PDZ",
    "stationname": "Pedernales Airport",
    "lat": "095800N",
    "long": "0621400W"
  },
  {
    "stationcode": "PYH",
    "stationname": "Puerto Ayacucho Airport",
    "lat": "053712N",
    "long": "0673622W"
  },
  {
    "stationcode": "SFD",
    "stationname": "Las Flecheras Airport",
    "lat": "075300N",
    "long": "0672638W"
  },
  {
    "stationcode": "ICA",
    "stationname": "Icabaru Airport",
    "lat": "042000N",
    "long": "0614400W"
  },
  {
    "stationcode": "EOR",
    "stationname": "El Dorado Airporr",
    "lat": "064400N",
    "long": "0613500W"
  },
  {
    "stationcode": "VLN",
    "stationname": "Valencia Airport",
    "lat": "100859N",
    "long": "0675542W"
  },
  {
    "stationcode": "CBL",
    "stationname": "Ciudad Bolivar Airport",
    "lat": "080719N",
    "long": "0633214W"
  },
  {
    "stationcode": "KTV",
    "stationname": "Kamarata Airport",
    "lat": "054500N",
    "long": "0622500W"
  },
  {
    "stationcode": "TMO",
    "stationname": "Tumeremo Airport",
    "lat": "071800N",
    "long": "0613000W"
  },
  {
    "stationcode": "SOM",
    "stationname": "El Tigre Airport",
    "lat": "085643N",
    "long": "0640904W"
  },
  {
    "stationcode": "CBS",
    "stationname": "Oro Negro Airport",
    "lat": "101955N",
    "long": "0711912W"
  },
  {
    "stationcode": "MRD",
    "stationname": "A Carnevalli Airport",
    "lat": "083456N",
    "long": "0710940W"
  },
  {
    "stationcode": "CLZ",
    "stationname": "Calabozo Airport",
    "lat": "085600N",
    "long": "0672600W"
  },
  {
    "stationcode": "MAR",
    "stationname": "La Chinita Airport",
    "lat": "103330N",
    "long": "0714340W"
  },
  {
    "stationcode": "CCS",
    "stationname": "Simon Bolivar Airport",
    "lat": "103623N",
    "long": "0665921W"
  },
  {
    "stationcode": "MYC",
    "stationname": "Maracay Airport",
    "lat": "101500N",
    "long": "0673858W"
  },
  {
    "stationcode": "VLV",
    "stationname": "Carvajal Airport",
    "lat": "092027N",
    "long": "0703503W"
  },
  {
    "stationcode": "SNF",
    "stationname": "San Felipe Airport",
    "lat": "101649N",
    "long": "0684514W"
  },
  {
    "stationcode": "CXA",
    "stationname": "Caicara De Oro Airport",
    "lat": "073200N",
    "long": "0660900W"
  },
  {
    "stationcode": "WOK",
    "stationname": "Wonken Airport",
    "lat": "051500N",
    "long": "0614400W"
  },
  {
    "stationcode": "SCI",
    "stationname": "San Cristobal Airport",
    "lat": "074800N",
    "long": "0721211W"
  },
  {
    "stationcode": "MUN",
    "stationname": "Quiriquire Airport",
    "lat": "094457N",
    "long": "0630912W"
  },
  {
    "stationcode": "CUM",
    "stationname": "Cumana Airport",
    "lat": "102701N",
    "long": "0640750W"
  },
  {
    "stationcode": "SBB",
    "stationname": "Santa Barbara Ba Airport",
    "lat": "075000N",
    "long": "0711000W"
  },
  {
    "stationcode": "LRV",
    "stationname": "Los Roques Airport",
    "lat": "115650N",
    "long": "0664018W"
  },
  {
    "stationcode": "PTM",
    "stationname": "Palmarito Airport",
    "lat": "073400N",
    "long": "0701100W"
  },
  {
    "stationcode": "VIG",
    "stationname": "El Vigia Airport",
    "lat": "083727N",
    "long": "0714022W"
  },
  {
    "stationcode": "PZO",
    "stationname": "Puerto Ordaz Airport",
    "lat": "081719N",
    "long": "0624537W"
  },
  {
    "stationcode": "PBL",
    "stationname": "Puerto Cabello Airport",
    "lat": "102850N",
    "long": "0680421W"
  },
  {
    "stationcode": "CGU",
    "stationname": "Ciudad Guayana Airport",
    "lat": "081818N",
    "long": "0624404W"
  },
  {
    "stationcode": "STD",
    "stationname": "Mayo Guerrero Airport",
    "lat": "073500N",
    "long": "0720400W"
  },
  {
    "stationcode": "GDO",
    "stationname": "Vare Maria Airport",
    "lat": "071240N",
    "long": "0704523W"
  },
  {
    "stationcode": "TUV",
    "stationname": "Tucupita Airport",
    "lat": "090521N",
    "long": "0620541W"
  },
  {
    "stationcode": "CZE",
    "stationname": "Coro Airport",
    "lat": "112454N",
    "long": "0694054W"
  },
  {
    "stationcode": "VDP",
    "stationname": "Valle de la Pascua Airport",
    "lat": "091300N",
    "long": "0660100W"
  },
  {
    "stationcode": "SFX",
    "stationname": "San Felix Airport",
    "lat": "081300N",
    "long": "0622300W"
  },
  {
    "stationcode": "CAJ",
    "stationname": "Canaima Airport",
    "lat": "061404N",
    "long": "0625119W"
  },
  {
    "stationcode": "LAG",
    "stationname": "La Guaira Airport",
    "lat": "103600N",
    "long": "0665600W"
  },
  {
    "stationcode": "SVV",
    "stationname": "San Salvador De Airport",
    "lat": "070000N",
    "long": "0620000W"
  },
  {
    "stationcode": "URM",
    "stationname": "Uriman Airport",
    "lat": "052030N",
    "long": "0624530W"
  },
  {
    "stationcode": "BRM",
    "stationname": "Barquisimeto Airport",
    "lat": "100234N",
    "long": "0692131W"
  },
  {
    "stationcode": "LSP",
    "stationname": "Josefa Camejo Airport",
    "lat": "114316N",
    "long": "0701120W"
  },
  {
    "stationcode": "AAO",
    "stationname": "Anaco Airport",
    "lat": "092500N",
    "long": "0642800W"
  },
  {
    "stationcode": "LGY",
    "stationname": "Lagunillas Airport",
    "lat": "083100N",
    "long": "0712400W"
  },
  {
    "stationcode": "GUQ",
    "stationname": "Guanare Airport",
    "lat": "090200N",
    "long": "0694500W"
  },
  {
    "stationcode": "ICC",
    "stationname": "A.M. Salazar Marcano Airport",
    "lat": "104740N",
    "long": "0635854W"
  },
  {
    "stationcode": "STB",
    "stationname": "L Delicias Airport",
    "lat": "085900N",
    "long": "0715630W"
  },
  {
    "stationcode": "EOZ",
    "stationname": "Elorza Airport",
    "lat": "070335N",
    "long": "0692948W"
  },
  {
    "stationcode": "CUV",
    "stationname": "Casigua Airport",
    "lat": "084600N",
    "long": "0723300W"
  },
  {
    "stationcode": "LPJ",
    "stationname": "Pijiguaos Airport",
    "lat": "070000N",
    "long": "0674000W"
  },
  {
    "stationcode": "GUI",
    "stationname": "Guiria Airport",
    "lat": "103400N",
    "long": "0621800W"
  },
  {
    "stationcode": "CUP",
    "stationname": "Carupano Airport",
    "lat": "103936N",
    "long": "0631542W"
  },
  {
    "stationcode": "BNS",
    "stationname": "Barinas Airport",
    "lat": "083710N",
    "long": "0701315W"
  },
  {
    "stationcode": "PPH",
    "stationname": "Peraitepuy Airport",
    "lat": "043400N",
    "long": "0612900W"
  },
  {
    "stationcode": "LFR",
    "stationname": "La Fria Airport",
    "lat": "081421N",
    "long": "0721616W"
  },
  {
    "stationcode": "SVZ",
    "stationname": "San Antonio Airport",
    "lat": "075035N",
    "long": "0722617W"
  },
  {
    "stationcode": "SNV",
    "stationname": "Santa Elena Airport",
    "lat": "043316N",
    "long": "0610837W"
  },
  {
    "stationcode": "VCR",
    "stationname": "Carora Airport",
    "lat": "101100N",
    "long": "0700500W"
  },
  {
    "stationcode": "PMV",
    "stationname": "DelCaribe Gen S Marino Airport",
    "lat": "105447N",
    "long": "0635803W"
  },
  {
    "stationcode": "BLA",
    "stationname": "Gen J A Anzoategui Airport",
    "lat": "100626N",
    "long": "0644121W"
  },
  {
    "stationcode": "ELX",
    "stationname": "El Tigre Airport",
    "lat": "085300N",
    "long": "0641600W"
  },
  {
    "stationcode": "AGV",
    "stationname": "Acarigua Airport",
    "lat": "093300N",
    "long": "0691400W"
  },
  {
    "stationcode": "VIJ",
    "stationname": "Virgin Gorda Airport",
    "lat": "182646N",
    "long": "0642541W"
  },
  {
    "stationcode": "NGD",
    "stationname": "Anegada Airport",
    "lat": "184400N",
    "long": "0641900W"
  },
  {
    "stationcode": "EIS",
    "stationname": "Beef Island Airport",
    "lat": "182641N",
    "long": "0643235W"
  },
  {
    "stationcode": "RAD",
    "stationname": "Road Town Airport",
    "lat": "182525N",
    "long": "0643648W"
  },
  {
    "stationcode": "NSX",
    "stationname": "North Sound Virgin Gorda Hovercraft/Launch Point",
    "lat": "183000N",
    "long": "0642200W"
  },
  {
    "stationcode": "STT",
    "stationname": "Cyril E. King Airport",
    "lat": "182015N",
    "long": "0645810W"
  },
  {
    "stationcode": "STX",
    "stationname": "Alexander Hamilton Airport",
    "lat": "174207N",
    "long": "0644755W"
  },
  {
    "stationcode": "SJF",
    "stationname": "St. John Island Airport",
    "lat": "182000N",
    "long": "0644500W"
  },
  {
    "stationcode": "PXU",
    "stationname": "Pleiku Airport",
    "lat": "140016N",
    "long": "1080102E"
  },
  {
    "stationcode": "VSO",
    "stationname": "Phuoc-long Airport",
    "lat": "092600N",
    "long": "1052800E"
  },
  {
    "stationcode": "UIH",
    "stationname": "Qui Nhon Airport",
    "lat": "135714N",
    "long": "1090236E"
  },
  {
    "stationcode": "PHU",
    "stationname": "Phu Vinh Airport",
    "lat": "111755N",
    "long": "1064815E"
  },
  {
    "stationcode": "DIN",
    "stationname": "Dien Bien Airport",
    "lat": "212400N",
    "long": "1030016E"
  },
  {
    "stationcode": "TBB",
    "stationname": "Tuy Hoa Airport",
    "lat": "130258N",
    "long": "1092001E"
  },
  {
    "stationcode": "XVL",
    "stationname": "Vin Long Airport",
    "lat": "101500N",
    "long": "1055700E"
  },
  {
    "stationcode": "KON",
    "stationname": "Kontum Airport",
    "lat": "142122N",
    "long": "1080056E"
  },
  {
    "stationcode": "VTG",
    "stationname": "Vung Tau Airport",
    "lat": "102228N",
    "long": "1070528E"
  },
  {
    "stationcode": "XLO",
    "stationname": "Long Xuyen Airport",
    "lat": "102900N",
    "long": "1052700E"
  },
  {
    "stationcode": "THD",
    "stationname": "Tho Xuan Airport",
    "lat": "195409N",
    "long": "1052809E"
  },
  {
    "stationcode": "CSJ",
    "stationname": "Cape St. Jacques Airport",
    "lat": "100200N",
    "long": "1110000E"
  },
  {
    "stationcode": "HPH",
    "stationname": "Catbi Airport",
    "lat": "204922N",
    "long": "1064338E"
  },
  {
    "stationcode": "TMK",
    "stationname": "Tamky Airport",
    "lat": "153400N",
    "long": "1082900E"
  },
  {
    "stationcode": "BMV",
    "stationname": "Phung-Duc Airport",
    "lat": "124006N",
    "long": "1080713E"
  },
  {
    "stationcode": "PHH",
    "stationname": "Phan Thiet Airport",
    "lat": "105400N",
    "long": "1080400E"
  },
  {
    "stationcode": "VCS",
    "stationname": "Coong Airport",
    "lat": "084356N",
    "long": "1063757E"
  },
  {
    "stationcode": "SQH",
    "stationname": "Na-San Airport",
    "lat": "211310N",
    "long": "1040130E"
  },
  {
    "stationcode": "HBN",
    "stationname": "Phu-bon Airport",
    "lat": "120000N",
    "long": "1060000E"
  },
  {
    "stationcode": "NHA",
    "stationname": "Nha Trang Airport",
    "lat": "121339N",
    "long": "1091132E"
  },
  {
    "stationcode": "HUI",
    "stationname": "Phu Bai Airport",
    "lat": "162403N",
    "long": "1074211E"
  },
  {
    "stationcode": "XNG",
    "stationname": "Quang Ngai Airport",
    "lat": "150700N",
    "long": "1084500E"
  },
  {
    "stationcode": "CAH",
    "stationname": "Ca Mau Airport",
    "lat": "091040N",
    "long": "1051039E"
  },
  {
    "stationcode": "PHA",
    "stationname": "Phan Rang Airport",
    "lat": "113700N",
    "long": "1085700E"
  },
  {
    "stationcode": "HOO",
    "stationname": "Nhon Co  Airport",
    "lat": "115900N",
    "long": "1073400E"
  },
  {
    "stationcode": "VCL",
    "stationname": "Chulai Airport",
    "lat": "152427N",
    "long": "1084226E"
  },
  {
    "stationcode": "VDH",
    "stationname": "Dong Hoi Airport",
    "lat": "173055N",
    "long": "1063527E"
  },
  {
    "stationcode": "SOA",
    "stationname": "Soc Trang Airport",
    "lat": "090000N",
    "long": "1065000E"
  },
  {
    "stationcode": "VII",
    "stationname": "Vinh City Airport",
    "lat": "184415N",
    "long": "1054015E"
  },
  {
    "stationcode": "VKG",
    "stationname": "Rach Gia Airport",
    "lat": "095730N",
    "long": "1050800E"
  },
  {
    "stationcode": "EPI",
    "stationname": "Epi Airport",
    "lat": "164300S",
    "long": "1681500E"
  },
  {
    "stationcode": "PBJ",
    "stationname": "Paama Airport",
    "lat": "162555S",
    "long": "1681409E"
  },
  {
    "stationcode": "DLY",
    "stationname": "Dillons Bay Airport",
    "lat": "184614S",
    "long": "1690014E"
  },
  {
    "stationcode": "WLH",
    "stationname": "Walaha Airport",
    "lat": "152446S",
    "long": "1674130E"
  },
  {
    "stationcode": "LNB",
    "stationname": "Lamen Bay Airport",
    "lat": "163506S",
    "long": "1680935E"
  },
  {
    "stationcode": "AUY",
    "stationname": "Aneityum Airport",
    "lat": "201458S",
    "long": "1694615E"
  },
  {
    "stationcode": "LOD",
    "stationname": "Longana Airport",
    "lat": "151821S",
    "long": "1675804E"
  },
  {
    "stationcode": "TGH",
    "stationname": "Tongoa Airport",
    "lat": "165330S",
    "long": "1683310E"
  },
  {
    "stationcode": "IPA",
    "stationname": "Ipota Airport",
    "lat": "185121S",
    "long": "1691658E"
  },
  {
    "stationcode": "SON",
    "stationname": "Pekoa Airport",
    "lat": "153018S",
    "long": "1671311E"
  },
  {
    "stationcode": "CCV",
    "stationname": "Craig Cove Airport",
    "lat": "161552S",
    "long": "1675527E"
  },
  {
    "stationcode": "OLJ",
    "stationname": "West Cost Santo Airport",
    "lat": "145254S",
    "long": "1663330E"
  },
  {
    "stationcode": "MWF",
    "stationname": "Maewo Airport",
    "lat": "145952S",
    "long": "1680454E"
  },
  {
    "stationcode": "VLI",
    "stationname": "Bauerfield Airport",
    "lat": "174200S",
    "long": "1681910E"
  },
  {
    "stationcode": "AWD",
    "stationname": "Aniwa Airport",
    "lat": "191415S",
    "long": "1693604E"
  },
  {
    "stationcode": "LPM",
    "stationname": "Lamap Airport",
    "lat": "162728S",
    "long": "1674944E"
  },
  {
    "stationcode": "SSR",
    "stationname": "Sara Airport",
    "lat": "152815S",
    "long": "1680909E"
  },
  {
    "stationcode": "EAE",
    "stationname": "Emae Airport",
    "lat": "170517S",
    "long": "1682030E"
  },
  {
    "stationcode": "TOH",
    "stationname": "Torres Airstrip",
    "lat": "131940S",
    "long": "1663819E"
  },
  {
    "stationcode": "LNE",
    "stationname": "Lonorore Airport",
    "lat": "155151S",
    "long": "1681016E"
  },
  {
    "stationcode": "ULB",
    "stationname": "Ulei Airport",
    "lat": "161947S",
    "long": "1681804E"
  },
  {
    "stationcode": "VLS",
    "stationname": "Valesdir Airport",
    "lat": "164745S",
    "long": "1681037E"
  },
  {
    "stationcode": "SWJ",
    "stationname": "South West Bay Airport",
    "lat": "162911S",
    "long": "1672650E"
  },
  {
    "stationcode": "UIQ",
    "stationname": "Quine Hill Airport",
    "lat": "173300S",
    "long": "1682500E"
  },
  {
    "stationcode": "GBA",
    "stationname": "Big Bay Airport",
    "lat": "150000S",
    "long": "1660000E"
  },
  {
    "stationcode": "SLH",
    "stationname": "Sola Airport",
    "lat": "135107S",
    "long": "1673214E"
  },
  {
    "stationcode": "NUS",
    "stationname": "Norsup Airport",
    "lat": "160441S",
    "long": "1672359E"
  },
  {
    "stationcode": "ZGU",
    "stationname": "Gaua Airport",
    "lat": "141311S",
    "long": "1673518E"
  },
  {
    "stationcode": "FTA",
    "stationname": "Futuna Airport",
    "lat": "193059S",
    "long": "1701357E"
  },
  {
    "stationcode": "RCL",
    "stationname": "Redcliffe Airport",
    "lat": "152818S",
    "long": "1675008E"
  },
  {
    "stationcode": "MTV",
    "stationname": "Mota Lava Airport",
    "lat": "133958S",
    "long": "1674241E"
  },
  {
    "stationcode": "TAH",
    "stationname": "Tanna Airport",
    "lat": "192718S",
    "long": "1691326E"
  },
  {
    "stationcode": "FUT",
    "stationname": "Futuna Island Airport",
    "lat": "141841S",
    "long": "1780358W"
  },
  {
    "stationcode": "WLS",
    "stationname": "Wallis Island Airport",
    "lat": "131418S",
    "long": "1761156W"
  },
  {
    "stationcode": "APW",
    "stationname": "Faleolo Airport",
    "lat": "134947S",
    "long": "1715950W"
  },
  {
    "stationcode": "MXS",
    "stationname": "Maota Savall Island Airport",
    "lat": "134434S",
    "long": "1721530W"
  },
  {
    "stationcode": "LAV",
    "stationname": "Lalomalava Airport",
    "lat": "480434N",
    "long": "0004539W"
  },
  {
    "stationcode": "FGI",
    "stationname": "Fagali I Airport",
    "lat": "135058S",
    "long": "1714423W"
  },
  {
    "stationcode": "AAU",
    "stationname": "Asau Airport",
    "lat": "132730S",
    "long": "1723600W"
  },
  {
    "stationcode": "KAM",
    "stationname": "Kamaran Island Airport",
    "lat": "152200N",
    "long": "0423500E"
  },
  {
    "stationcode": "SYE",
    "stationname": "Sadah Airport",
    "lat": "165800N",
    "long": "0434400E"
  },
  {
    "stationcode": "BUK",
    "stationname": "Albuq Airport",
    "lat": "155000N",
    "long": "0434600E"
  },
  {
    "stationcode": "AAY",
    "stationname": "Al Ghaydah Airport",
    "lat": "161137N",
    "long": "0521023E"
  },
  {
    "stationcode": "LDR",
    "stationname": "Lodar Airport",
    "lat": "135300N",
    "long": "0455200E"
  },
  {
    "stationcode": "AXK",
    "stationname": "Ataq Airport",
    "lat": "143308N",
    "long": "0464932E"
  },
  {
    "stationcode": "EAB",
    "stationname": "Abbse Airport",
    "lat": "151800N",
    "long": "0435800E"
  },
  {
    "stationcode": "DMR",
    "stationname": "Dhamar Airport",
    "lat": "143200N",
    "long": "0442600E"
  },
  {
    "stationcode": "UKR",
    "stationname": "Mukeiras Airport",
    "lat": "135600N",
    "long": "0454100E"
  },
  {
    "stationcode": "RIY",
    "stationname": "Riyan Airport",
    "lat": "143958N",
    "long": "0492240E"
  },
  {
    "stationcode": "MYN",
    "stationname": "Mareb Airport",
    "lat": "152900N",
    "long": "0452000E"
  },
  {
    "stationcode": "IHN",
    "stationname": "Qishn Airport",
    "lat": "152600N",
    "long": "0514200E"
  },
  {
    "stationcode": "SAH",
    "stationname": "Sana'a International Airport",
    "lat": "152843N",
    "long": "0441309E"
  },
  {
    "stationcode": "WDA",
    "stationname": "Wadi Ain Airport",
    "lat": "145200N",
    "long": "0453300E"
  },
  {
    "stationcode": "GXF",
    "stationname": "Seiyun Airport",
    "lat": "155758N",
    "long": "0484718E"
  },
  {
    "stationcode": "MKX",
    "stationname": "Mukalla Airport",
    "lat": "143200N",
    "long": "0490800E"
  },
  {
    "stationcode": "TAI",
    "stationname": "Al Janad Airport",
    "lat": "134120N",
    "long": "0440815E"
  },
  {
    "stationcode": "BYD",
    "stationname": "Beidah Airport",
    "lat": "140800N",
    "long": "0463300E"
  },
  {
    "stationcode": "ADE",
    "stationname": "Aden International Airport",
    "lat": "124946N",
    "long": "0450144E"
  },
  {
    "stationcode": "RXA",
    "stationname": "Raudha Airport",
    "lat": "142700N",
    "long": "0471700E"
  },
  {
    "stationcode": "BHN",
    "stationname": "Beihan Airport",
    "lat": "144700N",
    "long": "0454400E"
  },
  {
    "stationcode": "DAH",
    "stationname": "Dathina Airport",
    "lat": "135200N",
    "long": "0460800E"
  },
  {
    "stationcode": "HOD",
    "stationname": "Hodeidah Airport",
    "lat": "144511N",
    "long": "0425835E"
  },
  {
    "stationcode": "SCT",
    "stationname": "Socotra Airport",
    "lat": "123751N",
    "long": "0535420E"
  },
  {
    "stationcode": "DHL",
    "stationname": "Dhala Airport",
    "lat": "134415N",
    "long": "0444345E"
  },
  {
    "stationcode": "AJO",
    "stationname": "Aljouf Airport",
    "lat": "154300N",
    "long": "0441500E"
  },
  {
    "stationcode": "MFY",
    "stationname": "Mayfa'ah Airport",
    "lat": "141800N",
    "long": "0473600E"
  },
  {
    "stationcode": "DZA",
    "stationname": "Dzaoudzi Airport",
    "lat": "124824S",
    "long": "0451650E"
  },
  {
    "stationcode": "SBU",
    "stationname": "Springbok Airport",
    "lat": "294100S",
    "long": "0175700E"
  },
  {
    "stationcode": "PTG",
    "stationname": "Polokwane Airport",
    "lat": "235043S",
    "long": "0292731E"
  },
  {
    "stationcode": "NLP",
    "stationname": "Nelspruit Airport",
    "lat": "253000S",
    "long": "0305500E"
  },
  {
    "stationcode": "LMR",
    "stationname": "Lima Acres Airport",
    "lat": "282200S",
    "long": "0232700E"
  },
  {
    "stationcode": "GSS",
    "stationname": "Sabi Sabi Airport",
    "lat": "250702S",
    "long": "0304758E"
  },
  {
    "stationcode": "KHO",
    "stationname": "Khoka Moya Airport",
    "lat": "243500S",
    "long": "0312500E"
  },
  {
    "stationcode": "ELL",
    "stationname": "Ellisras Airport",
    "lat": "234334S",
    "long": "0274120E"
  },
  {
    "stationcode": "ASS",
    "stationname": "Arathusa Safari Lodge Airport",
    "lat": "244428S",
    "long": "0313106E"
  },
  {
    "stationcode": "CPT",
    "stationname": "Cape Town International Airport",
    "lat": "335753S",
    "long": "0183606E"
  },
  {
    "stationcode": "HLA",
    "stationname": "Lanseria Airport",
    "lat": "255619S",
    "long": "0275534E"
  },
  {
    "stationcode": "LAY",
    "stationname": "Ladysmith Airport",
    "lat": "283500S",
    "long": "0294500E"
  },
  {
    "stationcode": "SZK",
    "stationname": "Skukuza Airport",
    "lat": "245752S",
    "long": "0313526E"
  },
  {
    "stationcode": "UTW",
    "stationname": "Queenstown Airport",
    "lat": "315500S",
    "long": "0265300E"
  },
  {
    "stationcode": "AFD",
    "stationname": "Port Alfred Airport",
    "lat": "333500S",
    "long": "0265300E"
  },
  {
    "stationcode": "VRU",
    "stationname": "Vryburg Airport",
    "lat": "265900S",
    "long": "0244400E"
  },
  {
    "stationcode": "HRS",
    "stationname": "Harrismith Airport",
    "lat": "281400S",
    "long": "0290600E"
  },
  {
    "stationcode": "UTE",
    "stationname": "Butterworth Airport",
    "lat": "322300S",
    "long": "0280400E"
  },
  {
    "stationcode": "BIY",
    "stationname": "Bisho Airport",
    "lat": "325400S",
    "long": "0271700E"
  },
  {
    "stationcode": "NGL",
    "stationname": "Ngala Airfield",
    "lat": "242327S",
    "long": "0311940E"
  },
  {
    "stationcode": "DUR",
    "stationname": "Louis Botha Airport",
    "lat": "293652S",
    "long": "0310659E"
  },
  {
    "stationcode": "MZF",
    "stationname": "Wild Coast Sun Airport",
    "lat": "310600S",
    "long": "0301000E"
  },
  {
    "stationcode": "MBD",
    "stationname": "Mmabatho International Airport",
    "lat": "254754S",
    "long": "0253253E"
  },
  {
    "stationcode": "NTY",
    "stationname": "Pilansberg Airport",
    "lat": "252002S",
    "long": "0271024E"
  },
  {
    "stationcode": "ZEC",
    "stationname": "Secunda Airport",
    "lat": "263200S",
    "long": "0291000E"
  },
  {
    "stationcode": "TCU",
    "stationname": "Thaba Nchu Airport",
    "lat": "291700S",
    "long": "0265200E"
  },
  {
    "stationcode": "VIR",
    "stationname": "Virginia Airport",
    "lat": "294616S",
    "long": "0310330E"
  },
  {
    "stationcode": "ROD",
    "stationname": "Robertson Airport",
    "lat": "334600S",
    "long": "0195000E"
  },
  {
    "stationcode": "LLE",
    "stationname": "Malelane Airport",
    "lat": "252800S",
    "long": "0313400E"
  },
  {
    "stationcode": "RCB",
    "stationname": "Richards Bay Airport",
    "lat": "284426S",
    "long": "0320535E"
  },
  {
    "stationcode": "CDO",
    "stationname": "Cradock Airport",
    "lat": "320800S",
    "long": "0253600E"
  },
  {
    "stationcode": "LTA",
    "stationname": "Letaba Airport",
    "lat": "234900S",
    "long": "0302000E"
  },
  {
    "stationcode": "SIS",
    "stationname": "Sishen Airport",
    "lat": "273900S",
    "long": "0230000E"
  },
  {
    "stationcode": "GIY",
    "stationname": "Giyani Airport",
    "lat": "231700S",
    "long": "0304000E"
  },
  {
    "stationcode": "TDT",
    "stationname": "Tanda Tula Airport",
    "lat": "243200S",
    "long": "0311800E"
  },
  {
    "stationcode": "KMH",
    "stationname": "Kuruman Airport",
    "lat": "272800S",
    "long": "0232500E"
  },
  {
    "stationcode": "KIM",
    "stationname": "Kimberley Airport",
    "lat": "284810S",
    "long": "0244555E"
  },
  {
    "stationcode": "AAM",
    "stationname": "Mala Mala Airport",
    "lat": "244905S",
    "long": "0313241E"
  },
  {
    "stationcode": "PRK",
    "stationname": "Prieska Airport",
    "lat": "294000S",
    "long": "0224200E"
  },
  {
    "stationcode": "MGH",
    "stationname": "Margate Airport",
    "lat": "305127S",
    "long": "0302035E"
  },
  {
    "stationcode": "MQP",
    "stationname": "Kruger Mpumalanga International Airport",
    "lat": "252259S",
    "long": "0310620E"
  },
  {
    "stationcode": "GRJ",
    "stationname": "George Airport",
    "lat": "340020S",
    "long": "0222244E"
  },
  {
    "stationcode": "LCD",
    "stationname": "Louis Trichardt Airport",
    "lat": "230343S",
    "long": "0295153E"
  },
  {
    "stationcode": "SDB",
    "stationname": "Langebaanweg Airport",
    "lat": "325823S",
    "long": "0180944E"
  },
  {
    "stationcode": "KXE",
    "stationname": "Klerksdorp Airport",
    "lat": "265800S",
    "long": "0263900E"
  },
  {
    "stationcode": "ULX",
    "stationname": "Ulusaba Airport",
    "lat": "244659S",
    "long": "0312116E"
  },
  {
    "stationcode": "NCS",
    "stationname": "Newcastle Airport",
    "lat": "274500S",
    "long": "0300000E"
  },
  {
    "stationcode": "LUJ",
    "stationname": "Lusikisiki Airport",
    "lat": "312200S",
    "long": "0293500E"
  },
  {
    "stationcode": "JOH",
    "stationname": "Port St. Johns Airport",
    "lat": "313700S",
    "long": "0293200E"
  },
  {
    "stationcode": "WKF",
    "stationname": "Waterkloof (FAWK)",
    "lat": "254948S",
    "long": "0281321E"
  },
  {
    "stationcode": "BFN",
    "stationname": "Bloemfontein International Airport",
    "lat": "290534S",
    "long": "0261809E"
  },
  {
    "stationcode": "PBZ",
    "stationname": "Plettenberg Airport",
    "lat": "340517S",
    "long": "0231936E"
  },
  {
    "stationcode": "VRE",
    "stationname": "Vredendal Airport",
    "lat": "314100S",
    "long": "0183500E"
  },
  {
    "stationcode": "QRA",
    "stationname": "Randgermiston Airport",
    "lat": "261600S",
    "long": "0280900E"
  },
  {
    "stationcode": "EMG",
    "stationname": "Empangeni Airport",
    "lat": "284400S",
    "long": "0315400E"
  },
  {
    "stationcode": "FCB",
    "stationname": "Ficksburg Sentra Oes Airport",
    "lat": "284930S",
    "long": "0275430E"
  },
  {
    "stationcode": "LDZ",
    "stationname": "Londolozi Airport",
    "lat": "244829S",
    "long": "0313015E"
  },
  {
    "stationcode": "PZL",
    "stationname": "Zulu Inyala Airport",
    "lat": "275059S",
    "long": "0321836E"
  },
  {
    "stationcode": "KLZ",
    "stationname": "Kleinzee Airport",
    "lat": "294100S",
    "long": "0170400E"
  },
  {
    "stationcode": "MZY",
    "stationname": "Mossel Bay Airport",
    "lat": "341100S",
    "long": "0220800E"
  },
  {
    "stationcode": "ALJ",
    "stationname": "Kortdoorn Airport",
    "lat": "283400S",
    "long": "0163200E"
  },
  {
    "stationcode": "PLZ",
    "stationname": "Port Elizabeth Airport",
    "lat": "335906S",
    "long": "0253702E"
  },
  {
    "stationcode": "UTN",
    "stationname": "Upington Airport",
    "lat": "282402S",
    "long": "0211512E"
  },
  {
    "stationcode": "PZB",
    "stationname": "Pietermaritzburg Airport",
    "lat": "293856S",
    "long": "0302355E"
  },
  {
    "stationcode": "TSD",
    "stationname": "Tshipise Airport",
    "lat": "223800S",
    "long": "0301100E"
  },
  {
    "stationcode": "ADY",
    "stationname": "Alldays Airport",
    "lat": "224000S",
    "long": "0290600E"
  },
  {
    "stationcode": "KIG",
    "stationname": "Koingnaas Airport",
    "lat": "301200S",
    "long": "0171600E"
  },
  {
    "stationcode": "MWR",
    "stationname": "Motswari Airport",
    "lat": "241200S",
    "long": "0312400E"
  },
  {
    "stationcode": "OUH",
    "stationname": "Oudtshoorn Airport",
    "lat": "333609S",
    "long": "0221118E"
  },
  {
    "stationcode": "MZQ",
    "stationname": "Mkuze Airport",
    "lat": "273800S",
    "long": "0320300E"
  },
  {
    "stationcode": "MBM",
    "stationname": "Mkambati Airport",
    "lat": "311702S",
    "long": "0295920E"
  },
  {
    "stationcode": "UTT",
    "stationname": "Umtata Airport",
    "lat": "313252S",
    "long": "0284027E"
  },
  {
    "stationcode": "VYD",
    "stationname": "Vryheid Airport",
    "lat": "274700S",
    "long": "0304800E"
  },
  {
    "stationcode": "PCF",
    "stationname": "Potchefstroom Airport",
    "lat": "264005S",
    "long": "0270505E"
  },
  {
    "stationcode": "DUK",
    "stationname": "Dukuduk Airport",
    "lat": "282200S",
    "long": "0321408E"
  },
  {
    "stationcode": "JNB",
    "stationname": "O.R. Tambo International Airport",
    "lat": "260821S",
    "long": "0281446E"
  },
  {
    "stationcode": "HDS",
    "stationname": "Hoedspruit Airport",
    "lat": "242055S",
    "long": "0305649E"
  },
  {
    "stationcode": "THY",
    "stationname": "Thohoyandou Airport",
    "lat": "230437S",
    "long": "0302301E"
  },
  {
    "stationcode": "ULD",
    "stationname": "Ulundi Airport",
    "lat": "281914S",
    "long": "0312500E"
  },
  {
    "stationcode": "MEZ",
    "stationname": "Messina Airport",
    "lat": "222200S",
    "long": "0295900E"
  },
  {
    "stationcode": "HLW",
    "stationname": "Hluhluwe Airport",
    "lat": "280100S",
    "long": "0321500E"
  },
  {
    "stationcode": "PRY",
    "stationname": "Wonderboom Airport",
    "lat": "253900S",
    "long": "0281300E"
  },
  {
    "stationcode": "INY",
    "stationname": "Inyati Airport",
    "lat": "244700S",
    "long": "0312300E"
  },
  {
    "stationcode": "KOF",
    "stationname": "Komatipoort Airport",
    "lat": "252600S",
    "long": "0315600E"
  },
  {
    "stationcode": "PHW",
    "stationname": "Phalaborwa Airport",
    "lat": "235613S",
    "long": "0310922E"
  },
  {
    "stationcode": "GCJ",
    "stationname": "Johannesburg-Grand Central Airport",
    "lat": "255911S",
    "long": "0280825E"
  },
  {
    "stationcode": "ELS",
    "stationname": "East London Airport",
    "lat": "330210S",
    "long": "0274926E"
  },
  {
    "stationcode": "AGZ",
    "stationname": "Aggeneys Airport",
    "lat": "291700S",
    "long": "0184900E"
  },
  {
    "stationcode": "HZV",
    "stationname": "Hazyview Airport",
    "lat": "250400S",
    "long": "0310750E"
  },
  {
    "stationcode": "WEL",
    "stationname": "Welkom Airport",
    "lat": "280000S",
    "long": "0264000E"
  },
  {
    "stationcode": "RVO",
    "stationname": "Reivilo Airport",
    "lat": "273600S",
    "long": "0240800E"
  },
  {
    "stationcode": "OVG",
    "stationname": "Overberg (FAOB)",
    "lat": "343400S",
    "long": "0201500E"
  },
  {
    "stationcode": "MFU",
    "stationname": "Mfuwe Airport",
    "lat": "131535S",
    "long": "0315602E"
  },
  {
    "stationcode": "CIP",
    "stationname": "Chipata Airport",
    "lat": "133336S",
    "long": "0323515E"
  },
  {
    "stationcode": "SJQ",
    "stationname": "Sesheke Airport",
    "lat": "172700S",
    "long": "0242200E"
  },
  {
    "stationcode": "KMZ",
    "stationname": "Kaoma Airport",
    "lat": "144800S",
    "long": "0244700E"
  },
  {
    "stationcode": "BBZ",
    "stationname": "Zambezi Airport",
    "lat": "133200S",
    "long": "0230700E"
  },
  {
    "stationcode": "LXU",
    "stationname": "Lukulu Airport",
    "lat": "142200S",
    "long": "0231600E"
  },
  {
    "stationcode": "MNS",
    "stationname": "Mansa Airport",
    "lat": "110818S",
    "long": "0285230E"
  },
  {
    "stationcode": "JEK",
    "stationname": "Jeki Airstrip",
    "lat": "153800S",
    "long": "0293613E"
  },
  {
    "stationcode": "ZGM",
    "stationname": "Ngoma Airport",
    "lat": "155800S",
    "long": "0255700E"
  },
  {
    "stationcode": "SXG",
    "stationname": "Senanga Airport",
    "lat": "160600S",
    "long": "0231600E"
  },
  {
    "stationcode": "NLA",
    "stationname": "Ndola Airport",
    "lat": "125743S",
    "long": "0283051E"
  },
  {
    "stationcode": "ZKB",
    "stationname": "Kasaba Bay Airport",
    "lat": "083200S",
    "long": "0304200E"
  },
  {
    "stationcode": "SLI",
    "stationname": "Solwezi Airport",
    "lat": "121026S",
    "long": "0262200E"
  },
  {
    "stationcode": "MMQ",
    "stationname": "Mbala Airport",
    "lat": "085128S",
    "long": "0312003E"
  },
  {
    "stationcode": "RYL",
    "stationname": "Royal Airstrip",
    "lat": "154330S",
    "long": "0291814E"
  },
  {
    "stationcode": "KIW",
    "stationname": "Southdowns Airport",
    "lat": "125402S",
    "long": "0280902E"
  },
  {
    "stationcode": "CGJ",
    "stationname": "Chingola Airport",
    "lat": "123200S",
    "long": "0275200E"
  },
  {
    "stationcode": "LVI",
    "stationname": "Livingstone Airport",
    "lat": "174918S",
    "long": "0254922E"
  },
  {
    "stationcode": "ZKP",
    "stationname": "Kasompe Airport",
    "lat": "654457N",
    "long": "1505318E"
  },
  {
    "stationcode": "KLB",
    "stationname": "Kalabo Airport",
    "lat": "145956S",
    "long": "0223854E"
  },
  {
    "stationcode": "LUN",
    "stationname": "Lusaka Airport",
    "lat": "151945S",
    "long": "0282710E"
  },
  {
    "stationcode": "KAA",
    "stationname": "Kasama Airport",
    "lat": "101305S",
    "long": "0310805E"
  },
  {
    "stationcode": "MNR",
    "stationname": "Mongu Airport",
    "lat": "151519S",
    "long": "0230923E"
  },
  {
    "stationcode": "MVZ",
    "stationname": "Masvingo Airport",
    "lat": "200319S",
    "long": "0305133E"
  },
  {
    "stationcode": "KAB",
    "stationname": "Kariba Airport",
    "lat": "163111S",
    "long": "0285306E"
  },
  {
    "stationcode": "WKI",
    "stationname": "Hwange Airport",
    "lat": "182200S",
    "long": "0262900E"
  },
  {
    "stationcode": "VFA",
    "stationname": "Victoria Falls Airport",
    "lat": "180535S",
    "long": "0255025E"
  },
  {
    "stationcode": "HWN",
    "stationname": "Hwange National Park Airport",
    "lat": "183748S",
    "long": "0270116E"
  },
  {
    "stationcode": "CHJ",
    "stationname": "Chipinge Airport",
    "lat": "201200S",
    "long": "0323800E"
  },
  {
    "stationcode": "UTA",
    "stationname": "Mutare Airport",
    "lat": "185800S",
    "long": "0322700E"
  },
  {
    "stationcode": "MJW",
    "stationname": "Mahenye Airfield",
    "lat": "211400S",
    "long": "0322000E"
  },
  {
    "stationcode": "BUQ",
    "stationname": "Bulawayo Airport",
    "lat": "200059S",
    "long": "0283707E"
  },
  {
    "stationcode": "GWE",
    "stationname": "Gweru Airport",
    "lat": "193300S",
    "long": "0294400E"
  },
  {
    "stationcode": "BFO",
    "stationname": "Buffalo Range Airport",
    "lat": "210029S",
    "long": "0313443E"
  },
  {
    "stationcode": "HRE",
    "stationname": "Harare Airport",
    "lat": "175555S",
    "long": "0310534E"
  },
  {
    "stationcode": "BZH",
    "stationname": "Bumi Hills Airport",
    "lat": "164900S",
    "long": "0282100E"
  },
  {
    "stationcode": "CPG",
    "stationname": "Carmen De Patagones Airport",
    "lat": "404800S",
    "long": "0625900W"
  },
  {
    "stationcode": "UZU",
    "stationname": "Curuzu Cuatia Airport",
    "lat": "294700S",
    "long": "0580100W"
  },
  {
    "stationcode": "SZQ",
    "stationname": "Saenz Pena Airport",
    "lat": "343700S",
    "long": "0583200W"
  },
  {
    "stationcode": "SFN",
    "stationname": "Santa Fe Airport",
    "lat": "314239S",
    "long": "0604841W"
  },
  {
    "stationcode": "OVR",
    "stationname": "Olavarria Airport",
    "lat": "365320S",
    "long": "0601340W"
  },
  {
    "stationcode": "MDX",
    "stationname": "Mercedes Airport",
    "lat": "291301S",
    "long": "0580500W"
  },
  {
    "stationcode": "JNI",
    "stationname": "Junin Airport",
    "lat": "343500S",
    "long": "0605800W"
  },
  {
    "stationcode": "RCU",
    "stationname": "Rio Cuarto Airport",
    "lat": "330540S",
    "long": "0641541W"
  },
  {
    "stationcode": "VGS",
    "stationname": "General Villegas Airport",
    "lat": "350200S",
    "long": "0630100W"
  },
  {
    "stationcode": "AOL",
    "stationname": "Paso de los Libres Airport",
    "lat": "294100S",
    "long": "0570900W"
  },
  {
    "stationcode": "NEC",
    "stationname": "Necochea Airport",
    "lat": "382920S",
    "long": "0584857W"
  },
  {
    "stationcode": "ELO",
    "stationname": "Eldorado Airport",
    "lat": "262400S",
    "long": "0543800W"
  },
  {
    "stationcode": "EPA",
    "stationname": "El Palomar Airport",
    "lat": "343636S",
    "long": "0583645W"
  },
  {
    "stationcode": "BHI",
    "stationname": "Comandante Airport",
    "lat": "384338S",
    "long": "0620912W"
  },
  {
    "stationcode": "PRQ",
    "stationname": "Pres. Roque Saenz Pena Airport",
    "lat": "264513S",
    "long": "0602932W"
  },
  {
    "stationcode": "SST",
    "stationname": "Santa Teresita Airport",
    "lat": "363232S",
    "long": "0594317W"
  },
  {
    "stationcode": "IGR",
    "stationname": "Cataratas Airport",
    "lat": "254430S",
    "long": "0542757W"
  },
  {
    "stationcode": "EZE",
    "stationname": "Ministro Pistarini Airport",
    "lat": "344920S",
    "long": "0583209W"
  },
  {
    "stationcode": "SDE",
    "stationname": "Santiago Del Estero Airport",
    "lat": "274555S",
    "long": "0641920W"
  },
  {
    "stationcode": "VDR",
    "stationname": "Villa Dolores Airport",
    "lat": "315700S",
    "long": "0650900W"
  },
  {
    "stationcode": "LPG",
    "stationname": "La Plata Airport",
    "lat": "345500S",
    "long": "0575700W"
  },
  {
    "stationcode": "MCS",
    "stationname": "Monte Caseros Airport",
    "lat": "301600S",
    "long": "0573800W"
  },
  {
    "stationcode": "TUC",
    "stationname": "Benj Matienzo Airport",
    "lat": "265027S",
    "long": "0650617W"
  },
  {
    "stationcode": "MJR",
    "stationname": "Miramar Airport",
    "lat": "381600S",
    "long": "0575100W"
  },
  {
    "stationcode": "MDQ",
    "stationname": "Mar Del Plata Airport",
    "lat": "375603S",
    "long": "0573424W"
  },
  {
    "stationcode": "CSZ",
    "stationname": "Brigadier Hector Ruiz",
    "lat": "372647S",
    "long": "0615322W"
  },
  {
    "stationcode": "COC",
    "stationname": "Concordia Airport",
    "lat": "311800S",
    "long": "0580100W"
  },
  {
    "stationcode": "ROS",
    "stationname": "Fisherton Airport",
    "lat": "325413S",
    "long": "0604704W"
  },
  {
    "stationcode": "LLS",
    "stationname": "Las Lomitas Airport",
    "lat": "244200S",
    "long": "0603600W"
  },
  {
    "stationcode": "RES",
    "stationname": "Resistencia Airport",
    "lat": "272705S",
    "long": "0590303W"
  },
  {
    "stationcode": "VLG",
    "stationname": "Villa Gesell Airport",
    "lat": "371407S",
    "long": "0570147W"
  },
  {
    "stationcode": "OYA",
    "stationname": "Goya Airport",
    "lat": "290622S",
    "long": "0591309W"
  },
  {
    "stationcode": "PSS",
    "stationname": "Posadas Airport",
    "lat": "272309S",
    "long": "0555814W"
  },
  {
    "stationcode": "AEP",
    "stationname": "Jorge Newbery Airport",
    "lat": "343332S",
    "long": "0582459W"
  },
  {
    "stationcode": "PEH",
    "stationname": "Pehuajo Airport",
    "lat": "354800S",
    "long": "0615300W"
  },
  {
    "stationcode": "TDL",
    "stationname": "Tandil Airport",
    "lat": "371400S",
    "long": "0591200W"
  },
  {
    "stationcode": "OYO",
    "stationname": "Tres Arroyos Airport",
    "lat": "382200S",
    "long": "0601500W"
  },
  {
    "stationcode": "RHD",
    "stationname": "Rio Hondo Airport",
    "lat": "272825S",
    "long": "0645424W"
  },
  {
    "stationcode": "PRA",
    "stationname": "Parana Airport",
    "lat": "314737S",
    "long": "0602852W"
  },
  {
    "stationcode": "LCM",
    "stationname": "La Cumbre Airport",
    "lat": "310022S",
    "long": "0643155W"
  },
  {
    "stationcode": "FMA",
    "stationname": "El Pucu Airport",
    "lat": "261246S",
    "long": "0581341W"
  },
  {
    "stationcode": "COR",
    "stationname": "Pajas Blancas Airport",
    "lat": "311836S",
    "long": "0641230W"
  },
  {
    "stationcode": "CLX",
    "stationname": "Clorinda Airport",
    "lat": "252000S",
    "long": "0574000W"
  },
  {
    "stationcode": "CNQ",
    "stationname": "Camba Punta Airport",
    "lat": "272644S",
    "long": "0584543W"
  },
  {
    "stationcode": "RCQ",
    "stationname": "Reconquista Airport",
    "lat": "291238S",
    "long": "0594127W"
  },
  {
    "stationcode": "CRR",
    "stationname": "Ceres Airport",
    "lat": "295200S",
    "long": "0615000W"
  },
  {
    "stationcode": "CNT",
    "stationname": "Charata Airport",
    "lat": "271300S",
    "long": "0611300W"
  },
  {
    "stationcode": "RAF",
    "stationname": "Rafaela Airport",
    "lat": "311657S",
    "long": "0613006W"
  },
  {
    "stationcode": "GHU",
    "stationname": "Gualeguaychu Airport",
    "lat": "330100S",
    "long": "0583500W"
  },
  {
    "stationcode": "VME",
    "stationname": "Villa Mercedes Airport",
    "lat": "334331S",
    "long": "0652241W"
  },
  {
    "stationcode": "RLO",
    "stationname": "Valle Del Conlara Airport",
    "lat": "322304S",
    "long": "0651107W"
  },
  {
    "stationcode": "LUQ",
    "stationname": "San Luis Airport",
    "lat": "331620S",
    "long": "0662124W"
  },
  {
    "stationcode": "MBF",
    "stationname": "Mount Buffalo Airport",
    "lat": "364500S",
    "long": "1464500E"
  },
  {
    "stationcode": "RSE",
    "stationname": "Au-Rose Bay Airport",
    "lat": "334800S",
    "long": "1511300E"
  },
  {
    "stationcode": "SWC",
    "stationname": "Stawell Airport",
    "lat": "370400S",
    "long": "1424600E"
  },
  {
    "stationcode": "MQL",
    "stationname": "Mildura Airport",
    "lat": "341339S",
    "long": "1420506E"
  },
  {
    "stationcode": "WOL",
    "stationname": "Wollongong Airport",
    "lat": "343326S",
    "long": "1504712E"
  },
  {
    "stationcode": "GEX",
    "stationname": "Geelong Airport",
    "lat": "381332S",
    "long": "1442000E"
  },
  {
    "stationcode": "CBX",
    "stationname": "Condobolin Airport",
    "lat": "330400S",
    "long": "1471200E"
  },
  {
    "stationcode": "MEB",
    "stationname": "Essendon Airport",
    "lat": "374400S",
    "long": "1445400E"
  },
  {
    "stationcode": "PTE",
    "stationname": "Port Stephens Airport",
    "lat": "422518N",
    "long": "0083800W"
  },
  {
    "stationcode": "XCO",
    "stationname": "Colac Airport",
    "lat": "382000S",
    "long": "1433500E"
  },
  {
    "stationcode": "MTL",
    "stationname": "Maitland Airport",
    "lat": "324400S",
    "long": "1513300E"
  },
  {
    "stationcode": "WHL",
    "stationname": "Welshpool Airport",
    "lat": "384120S",
    "long": "1462700E"
  },
  {
    "stationcode": "ABX",
    "stationname": "Albury Airport",
    "lat": "360404S",
    "long": "1465729E"
  },
  {
    "stationcode": "KRA",
    "stationname": "Kerang Airport",
    "lat": "354500S",
    "long": "1435600E"
  },
  {
    "stationcode": "WEW",
    "stationname": "Wee Waa Airport",
    "lat": "301400S",
    "long": "1492600E"
  },
  {
    "stationcode": "HTU",
    "stationname": "Hopetown Airport",
    "lat": "354300S",
    "long": "1422100E"
  },
  {
    "stationcode": "TGN",
    "stationname": "La Trobe Regional Airport",
    "lat": "381200S",
    "long": "1463200E"
  },
  {
    "stationcode": "TMW",
    "stationname": "Tamworth Airport",
    "lat": "310455S",
    "long": "1505044E"
  },
  {
    "stationcode": "CFS",
    "stationname": "Coffs Harbour Airport",
    "lat": "301914S",
    "long": "1530659E"
  },
  {
    "stationcode": "WGA",
    "stationname": "Forrest Hill Airport",
    "lat": "350955S",
    "long": "1472759E"
  },
  {
    "stationcode": "CWT",
    "stationname": "Cowra Airport",
    "lat": "335100S",
    "long": "1483900E"
  },
  {
    "stationcode": "CRB",
    "stationname": "Collarenebri Airport",
    "lat": "293133S",
    "long": "1483433E"
  },
  {
    "stationcode": "HSM",
    "stationname": "Horsham Airport",
    "lat": "364000S",
    "long": "1421000E"
  },
  {
    "stationcode": "FOT",
    "stationname": "Forster Airport",
    "lat": "321100S",
    "long": "1523100E"
  },
  {
    "stationcode": "CES",
    "stationname": "Cessnock Airport",
    "lat": "324706S",
    "long": "1512025E"
  },
  {
    "stationcode": "NBH",
    "stationname": "Nambucca Heads Airport",
    "lat": "303900S",
    "long": "1530000E"
  },
  {
    "stationcode": "FLC",
    "stationname": "Falls Creek Airport",
    "lat": "345900S",
    "long": "1503600E"
  },
  {
    "stationcode": "SWZ",
    "stationname": "Sydney West Airport",
    "lat": "335317S",
    "long": "1504318E"
  },
  {
    "stationcode": "LSY",
    "stationname": "Lismore Airport",
    "lat": "284946S",
    "long": "1531528E"
  },
  {
    "stationcode": "FLY",
    "stationname": "Finley Airport",
    "lat": "353900S",
    "long": "1453500E"
  },
  {
    "stationcode": "NAA",
    "stationname": "Narrabri Airport",
    "lat": "301909S",
    "long": "1494938E"
  },
  {
    "stationcode": "GLI",
    "stationname": "Glen Innes Airport",
    "lat": "294100S",
    "long": "1514200E"
  },
  {
    "stationcode": "FRB",
    "stationname": "Forbes Airport",
    "lat": "332200S",
    "long": "1475600E"
  },
  {
    "stationcode": "WGT",
    "stationname": "Wangaratta Airport",
    "lat": "362457S",
    "long": "1461830E"
  },
  {
    "stationcode": "BHS",
    "stationname": "Raglan Airport",
    "lat": "332434S",
    "long": "1493907E"
  },
  {
    "stationcode": "GUH",
    "stationname": "Gunnedah Airport",
    "lat": "305700S",
    "long": "1501400E"
  },
  {
    "stationcode": "EVH",
    "stationname": "Evans Head Airport",
    "lat": "290600S",
    "long": "1532500E"
  },
  {
    "stationcode": "SHT",
    "stationname": "Shepparton Airport",
    "lat": "362600S",
    "long": "1452400E"
  },
  {
    "stationcode": "NGA",
    "stationname": "Young Airport",
    "lat": "341500S",
    "long": "1481500E"
  },
  {
    "stationcode": "KPS",
    "stationname": "Kempsey Airport",
    "lat": "310400S",
    "long": "1524600E"
  },
  {
    "stationcode": "CLH",
    "stationname": "Coolah Airport",
    "lat": "315000S",
    "long": "1494200E"
  },
  {
    "stationcode": "BNK",
    "stationname": "Ballina Byron Airport",
    "lat": "285002S",
    "long": "1533345E"
  },
  {
    "stationcode": "ARY",
    "stationname": "Ararat Airport",
    "lat": "371700S",
    "long": "1425600E"
  },
  {
    "stationcode": "WKB",
    "stationname": "Warracknabeal Airport",
    "lat": "361900S",
    "long": "1422500E"
  },
  {
    "stationcode": "BEO",
    "stationname": "Belmont Airport",
    "lat": "330200S",
    "long": "1514000E"
  },
  {
    "stationcode": "HXX",
    "stationname": "Hay Airport",
    "lat": "343100S",
    "long": "1445000E"
  },
  {
    "stationcode": "IVR",
    "stationname": "Inverell Airport",
    "lat": "295313S",
    "long": "1510842E"
  },
  {
    "stationcode": "CWW",
    "stationname": "Corowa Airport",
    "lat": "355900S",
    "long": "1462100E"
  },
  {
    "stationcode": "RBC",
    "stationname": "Robinvale Airport",
    "lat": "343900S",
    "long": "1424700E"
  },
  {
    "stationcode": "COJ",
    "stationname": "Coonabarabran Airport",
    "lat": "312000S",
    "long": "1491600E"
  },
  {
    "stationcode": "NRA",
    "stationname": "Narrandera Airport",
    "lat": "344208S",
    "long": "1463044E"
  },
  {
    "stationcode": "WMB",
    "stationname": "Warrnambool Airport",
    "lat": "381740S",
    "long": "1422649E"
  },
  {
    "stationcode": "MIM",
    "stationname": "Merimbula Airport",
    "lat": "365431S",
    "long": "1495405E"
  },
  {
    "stationcode": "WIO",
    "stationname": "Wilcannia Airport",
    "lat": "313400S",
    "long": "1432300E"
  },
  {
    "stationcode": "PQQ",
    "stationname": "Port Macquarie Airport",
    "lat": "312608S",
    "long": "1525144E"
  },
  {
    "stationcode": "OYN",
    "stationname": "Ouyen Airport",
    "lat": "365555S",
    "long": "1421900E"
  },
  {
    "stationcode": "NSO",
    "stationname": "Scone Airport",
    "lat": "320216S",
    "long": "1504954E"
  },
  {
    "stationcode": "TCW",
    "stationname": "Tocumwal Airport",
    "lat": "354900S",
    "long": "1453400E"
  },
  {
    "stationcode": "GUL",
    "stationname": "Goulburn Airport",
    "lat": "344900S",
    "long": "1494400E"
  },
  {
    "stationcode": "CUG",
    "stationname": "Cudal Airport",
    "lat": "331600S",
    "long": "1484600E"
  },
  {
    "stationcode": "BRK",
    "stationname": "Bourke Airport",
    "lat": "300226S",
    "long": "1455648E"
  },
  {
    "stationcode": "BSJ",
    "stationname": "Bairnsdale Airport",
    "lat": "375300S",
    "long": "1473400E"
  },
  {
    "stationcode": "XMC",
    "stationname": "Mallacoota Airport",
    "lat": "373600S",
    "long": "1494300E"
  },
  {
    "stationcode": "WTB",
    "stationname": "Brisbane West Airport",
    "lat": "273336S",
    "long": "1514746E"
  },
  {
    "stationcode": "GFN",
    "stationname": "Grafton Airport",
    "lat": "294534S",
    "long": "1530148E"
  },
  {
    "stationcode": "NOA",
    "stationname": "Nowra Airport",
    "lat": "345600S",
    "long": "1503400E"
  },
  {
    "stationcode": "WAU",
    "stationname": "Wauchope Airport",
    "lat": "203828S",
    "long": "1341302E"
  },
  {
    "stationcode": "ARM",
    "stationname": "Armidale Airport",
    "lat": "303141S",
    "long": "1513702E"
  },
  {
    "stationcode": "MYA",
    "stationname": "Moruya Airport",
    "lat": "355354S",
    "long": "1500846E"
  },
  {
    "stationcode": "CMD",
    "stationname": "Cootamundra Airport",
    "lat": "343800S",
    "long": "1480300E"
  },
  {
    "stationcode": "WLC",
    "stationname": "Walcha Airport",
    "lat": "305900S",
    "long": "1513600E"
  },
  {
    "stationcode": "PHJ",
    "stationname": "Port Hunter Airport",
    "lat": "325500S",
    "long": "1514600E"
  },
  {
    "stationcode": "MRZ",
    "stationname": "Moree Airport",
    "lat": "292956S",
    "long": "1495041E"
  },
  {
    "stationcode": "LHG",
    "stationname": "Lightning Ridge Airport",
    "lat": "292400S",
    "long": "1475800E"
  },
  {
    "stationcode": "GFF",
    "stationname": "Griffith Airport",
    "lat": "341514S",
    "long": "1460342E"
  },
  {
    "stationcode": "DBO",
    "stationname": "Dubbo Airport",
    "lat": "321308S",
    "long": "1483432E"
  },
  {
    "stationcode": "DNQ",
    "stationname": "Deniliquin Airport",
    "lat": "353300S",
    "long": "1445700E"
  },
  {
    "stationcode": "BWQ",
    "stationname": "Brewarrina Airport",
    "lat": "295800S",
    "long": "1464900E"
  },
  {
    "stationcode": "BWU",
    "stationname": "Bankstown Airport",
    "lat": "335524S",
    "long": "1505925E"
  },
  {
    "stationcode": "MVH",
    "stationname": "Macksville Airport",
    "lat": "304300S",
    "long": "1525500E"
  },
  {
    "stationcode": "MHU",
    "stationname": "Mount Hotham",
    "lat": "365906S",
    "long": "1470758E"
  },
  {
    "stationcode": "NYN",
    "stationname": "Nyngan Airport",
    "lat": "313300S",
    "long": "1471100E"
  },
  {
    "stationcode": "OAG",
    "stationname": "Springhill Airport",
    "lat": "332256S",
    "long": "1490753E"
  },
  {
    "stationcode": "HLT",
    "stationname": "Hamilton Airport",
    "lat": "373850S",
    "long": "1420343E"
  },
  {
    "stationcode": "TEM",
    "stationname": "Temora Airport",
    "lat": "342500S",
    "long": "1473000E"
  },
  {
    "stationcode": "WGE",
    "stationname": "Walgett Airport",
    "lat": "300200S",
    "long": "1480800E"
  },
  {
    "stationcode": "TRO",
    "stationname": "Taree Airport",
    "lat": "315318S",
    "long": "1523055E"
  },
  {
    "stationcode": "BXG",
    "stationname": "Bendigo Airport",
    "lat": "364405S",
    "long": "1441907E"
  },
  {
    "stationcode": "SIX",
    "stationname": "Singleton Airport",
    "lat": "323400S",
    "long": "1511000E"
  },
  {
    "stationcode": "PTJ",
    "stationname": "Portland Airport",
    "lat": "381910S",
    "long": "1412800E"
  },
  {
    "stationcode": "ECH",
    "stationname": "Echuca Airport",
    "lat": "360900S",
    "long": "1444500E"
  },
  {
    "stationcode": "SWH",
    "stationname": "Swan Hill Airport",
    "lat": "352300S",
    "long": "1433300E"
  },
  {
    "stationcode": "SXE",
    "stationname": "Sale Airport",
    "lat": "380500S",
    "long": "1465800E"
  },
  {
    "stationcode": "BRJ",
    "stationname": "Bright Airport",
    "lat": "364400S",
    "long": "1465800E"
  },
  {
    "stationcode": "MBW",
    "stationname": "Moorabbin Airport",
    "lat": "375833S",
    "long": "1450608E"
  },
  {
    "stationcode": "LDH",
    "stationname": "Lord Howe Island Airport",
    "lat": "313219S",
    "long": "1590434E"
  },
  {
    "stationcode": "BLN",
    "stationname": "Benalla Airport",
    "lat": "363300S",
    "long": "1455900E"
  },
  {
    "stationcode": "CSI",
    "stationname": "Casino Airport",
    "lat": "285300S",
    "long": "1530400E"
  },
  {
    "stationcode": "WLE",
    "stationname": "Miles Airport",
    "lat": "264836S",
    "long": "1500943E"
  },
  {
    "stationcode": "UIR",
    "stationname": "Quirindi Airport",
    "lat": "313000S",
    "long": "1503100E"
  },
  {
    "stationcode": "CYG",
    "stationname": "Corryong Airport",
    "lat": "361500S",
    "long": "1475400E"
  },
  {
    "stationcode": "PKE",
    "stationname": "Parkes Airport",
    "lat": "330753S",
    "long": "1481421E"
  },
  {
    "stationcode": "GOS",
    "stationname": "Gosford Airport",
    "lat": "332600S",
    "long": "1512100E"
  },
  {
    "stationcode": "BZD",
    "stationname": "Balranald Airport",
    "lat": "343800S",
    "long": "1433300E"
  },
  {
    "stationcode": "OOM",
    "stationname": "Cooma Airport",
    "lat": "361830S",
    "long": "1485824E"
  },
  {
    "stationcode": "WWY",
    "stationname": "West Wyalong Airport",
    "lat": "335614S",
    "long": "1471129E"
  },
  {
    "stationcode": "DGE",
    "stationname": "Mudgee Airport",
    "lat": "323354S",
    "long": "1493634E"
  },
  {
    "stationcode": "NTL",
    "stationname": "Williamtown Airport",
    "lat": "324745S",
    "long": "1515000E"
  },
  {
    "stationcode": "CBR",
    "stationname": "Canberra Airport",
    "lat": "351825S",
    "long": "1491142E"
  },
  {
    "stationcode": "CDU",
    "stationname": "Camden Airport",
    "lat": "340300S",
    "long": "1504200E"
  },
  {
    "stationcode": "TUM",
    "stationname": "Tumut Airport",
    "lat": "351800S",
    "long": "1481300E"
  },
  {
    "stationcode": "RBS",
    "stationname": "Orbost Airport",
    "lat": "374200S",
    "long": "1483000E"
  },
  {
    "stationcode": "TYB",
    "stationname": "Tibooburra Airport",
    "lat": "292725S",
    "long": "1420344E"
  },
  {
    "stationcode": "CNB",
    "stationname": "Coonamble Airport",
    "lat": "305900S",
    "long": "1482300E"
  },
  {
    "stationcode": "GFE",
    "stationname": "Grenfell Airport",
    "lat": "335400S",
    "long": "1481000E"
  },
  {
    "stationcode": "CAZ",
    "stationname": "Cobar Airport",
    "lat": "313215S",
    "long": "1454740E"
  },
  {
    "stationcode": "MVK",
    "stationname": "Mulka Airport",
    "lat": "282100S",
    "long": "1383900E"
  },
  {
    "stationcode": "MWT",
    "stationname": "Moolawatana Airport",
    "lat": "295500S",
    "long": "1394300E"
  },
  {
    "stationcode": "CED",
    "stationname": "Ceduna Airport",
    "lat": "320750S",
    "long": "1334235E"
  },
  {
    "stationcode": "MRP",
    "stationname": "Marla Airport",
    "lat": "270200S",
    "long": "1330700E"
  },
  {
    "stationcode": "NAC",
    "stationname": "Naracoorte Airport",
    "lat": "365850S",
    "long": "1404324E"
  },
  {
    "stationcode": "PEA",
    "stationname": "Penneshaw Airport",
    "lat": "354400S",
    "long": "1375600E"
  },
  {
    "stationcode": "KYI",
    "stationname": "Yalata Mission Airport",
    "lat": "312900S",
    "long": "1315200E"
  },
  {
    "stationcode": "RRE",
    "stationname": "Marree Airport",
    "lat": "293900S",
    "long": "1380400E"
  },
  {
    "stationcode": "ADO",
    "stationname": "Andamooka Airport",
    "lat": "302602S",
    "long": "1370814E"
  },
  {
    "stationcode": "NUR",
    "stationname": "Nullarbor Airport",
    "lat": "312600S",
    "long": "1305500E"
  },
  {
    "stationcode": "MHC",
    "stationname": "Macmahon Camp 4 Airport",
    "lat": "422036S",
    "long": "0734258W"
  },
  {
    "stationcode": "XML",
    "stationname": "Minlaton Airport",
    "lat": "344600S",
    "long": "1373600E"
  },
  {
    "stationcode": "CFH",
    "stationname": "Clifton Hills Airport",
    "lat": "263100S",
    "long": "1392700E"
  },
  {
    "stationcode": "UMR",
    "stationname": "Woomera Airport",
    "lat": "311000S",
    "long": "1365000E"
  },
  {
    "stationcode": "CCW",
    "stationname": "Cowell Airport",
    "lat": "334000S",
    "long": "1365400E"
  },
  {
    "stationcode": "MGB",
    "stationname": "Mount Gambier Airport",
    "lat": "374515S",
    "long": "1404650E"
  },
  {
    "stationcode": "KGC",
    "stationname": "Kingscote Airport",
    "lat": "354257S",
    "long": "1373133E"
  },
  {
    "stationcode": "CPD",
    "stationname": "Coober Pedy Airport",
    "lat": "290225S",
    "long": "1344312E"
  },
  {
    "stationcode": "CWR",
    "stationname": "Cowarie Airport",
    "lat": "274300S",
    "long": "1382000E"
  },
  {
    "stationcode": "MIN",
    "stationname": "Minnipa Airport",
    "lat": "325000S",
    "long": "1350800E"
  },
  {
    "stationcode": "HWK",
    "stationname": "Wilpena Pound Airport",
    "lat": "315117S",
    "long": "1382805E"
  },
  {
    "stationcode": "KBY",
    "stationname": "Streaky Bay Airport",
    "lat": "324800S",
    "long": "1341400E"
  },
  {
    "stationcode": "LGH",
    "stationname": "Leigh Creek Airport",
    "lat": "303500S",
    "long": "1382500E"
  },
  {
    "stationcode": "WUD",
    "stationname": "Wudinna Airport",
    "lat": "330100S",
    "long": "1353710E"
  },
  {
    "stationcode": "ORR",
    "stationname": "Yorktown Airport",
    "lat": "350200S",
    "long": "1373600E"
  },
  {
    "stationcode": "MOO",
    "stationname": "Moomba Airport",
    "lat": "280558S",
    "long": "1401154E"
  },
  {
    "stationcode": "PPI",
    "stationname": "Port Pirie Airport",
    "lat": "331400S",
    "long": "1380000E"
  },
  {
    "stationcode": "PDN",
    "stationname": "Parndana Airport",
    "lat": "354800S",
    "long": "1371300E"
  },
  {
    "stationcode": "PEY",
    "stationname": "Penong Airport",
    "lat": "315500S",
    "long": "1330100E"
  },
  {
    "stationcode": "RTY",
    "stationname": "Merty Airport",
    "lat": "290000S",
    "long": "1384400E"
  },
  {
    "stationcode": "ETD",
    "stationname": "Etadunna Airport",
    "lat": "284300S",
    "long": "1383800E"
  },
  {
    "stationcode": "PLO",
    "stationname": "Port Lincoln Airport",
    "lat": "343622S",
    "long": "1355250E"
  },
  {
    "stationcode": "PDE",
    "stationname": "Pandie Pandie Airport",
    "lat": "260800S",
    "long": "1392300E"
  },
  {
    "stationcode": "MLR",
    "stationname": "Millicent Airport",
    "lat": "373459S",
    "long": "1402158E"
  },
  {
    "stationcode": "KQB",
    "stationname": "Koonibba Airport",
    "lat": "315400S",
    "long": "1332500E"
  },
  {
    "stationcode": "IDK",
    "stationname": "Indulkana Airport",
    "lat": "265800S",
    "long": "1331930E"
  },
  {
    "stationcode": "ADL",
    "stationname": "Adelaide",
    "lat": "345642S",
    "long": "1383150E"
  },
  {
    "stationcode": "CVC",
    "stationname": "Cleve Airport",
    "lat": "334200S",
    "long": "1363000E"
  },
  {
    "stationcode": "OLP",
    "stationname": "Olympic Dam Airport",
    "lat": "310000S",
    "long": "1360000E"
  },
  {
    "stationcode": "DLK",
    "stationname": "Dulkaninna Airport",
    "lat": "290100S",
    "long": "1392800E"
  },
  {
    "stationcode": "LCN",
    "stationname": "Balcanoona Airport",
    "lat": "303200S",
    "long": "1391900E"
  },
  {
    "stationcode": "TAQ",
    "stationname": "Tarcoola Airport",
    "lat": "304215S",
    "long": "1343459E"
  },
  {
    "stationcode": "ODL",
    "stationname": "Cordillo Downs Airport",
    "lat": "265000S",
    "long": "1404500E"
  },
  {
    "stationcode": "RMK",
    "stationname": "Renmark Airport",
    "lat": "341200S",
    "long": "1404100E"
  },
  {
    "stationcode": "ODD",
    "stationname": "Oodnadatta Airport",
    "lat": "273300S",
    "long": "1352600E"
  },
  {
    "stationcode": "ERB",
    "stationname": "Ernabella Airport",
    "lat": "261600S",
    "long": "1321000E"
  },
  {
    "stationcode": "AWN",
    "stationname": "Alton Downs Airport",
    "lat": "262900S",
    "long": "1391900E"
  },
  {
    "stationcode": "PUG",
    "stationname": "Port Augusta Airport",
    "lat": "323025S",
    "long": "1374300E"
  },
  {
    "stationcode": "INM",
    "stationname": "Innamincka Airport",
    "lat": "275000S",
    "long": "1405000E"
  },
  {
    "stationcode": "MNE",
    "stationname": "Mungerannie Airport",
    "lat": "280100S",
    "long": "1384000E"
  },
  {
    "stationcode": "LOC",
    "stationname": "Lock Airport",
    "lat": "333400S",
    "long": "1354600E"
  },
  {
    "stationcode": "RCN",
    "stationname": "American River Airport",
    "lat": "354700S",
    "long": "1374700E"
  },
  {
    "stationcode": "GSN",
    "stationname": "Mount Gunson Airport",
    "lat": "312738S",
    "long": "1371021E"
  },
  {
    "stationcode": "WYA",
    "stationname": "Whyalla Airport",
    "lat": "330334S",
    "long": "1373110E"
  },
  {
    "stationcode": "PXH",
    "stationname": "Prominent Hill Airport",
    "lat": "294258S",
    "long": "1353128E"
  },
  {
    "stationcode": "BHQ",
    "stationname": "Broken Hill Airport",
    "lat": "320005S",
    "long": "1412818E"
  },
  {
    "stationcode": "CRJ",
    "stationname": "Coorabie Airport",
    "lat": "315400S",
    "long": "1321800E"
  },
  {
    "stationcode": "JUR",
    "stationname": "Jurien Bay Airport",
    "lat": "301600S",
    "long": "1150300E"
  },
  {
    "stationcode": "BYP",
    "stationname": "Barimunya Airport",
    "lat": "224021S",
    "long": "1190957E"
  },
  {
    "stationcode": "FVR",
    "stationname": "Forrest River Airport",
    "lat": "150956S",
    "long": "1275028E"
  },
  {
    "stationcode": "BQW",
    "stationname": "Balgo Hills Airport",
    "lat": "200800S",
    "long": "1274800E"
  },
  {
    "stationcode": "NLS",
    "stationname": "Nicholson Airport",
    "lat": "180230S",
    "long": "1285400E"
  },
  {
    "stationcode": "CKW",
    "stationname": "Christmas Creek Mine Airport",
    "lat": "222115S",
    "long": "1193833E"
  },
  {
    "stationcode": "RVT",
    "stationname": "Ravensthorpe Airport",
    "lat": "334750S",
    "long": "1201237E"
  },
  {
    "stationcode": "TDN",
    "stationname": "Theda Station Airport",
    "lat": "144500S",
    "long": "1263100E"
  },
  {
    "stationcode": "MBN",
    "stationname": "Mt. Barnett Airport",
    "lat": "164000S",
    "long": "1255700E"
  },
  {
    "stationcode": "BME",
    "stationname": "Broome Airport",
    "lat": "175656S",
    "long": "1221342E"
  },
  {
    "stationcode": "WWI",
    "stationname": "Woodie Woodie Airport",
    "lat": "213843S",
    "long": "1211127E"
  },
  {
    "stationcode": "MJK",
    "stationname": "Shark Bay Airport",
    "lat": "255343S",
    "long": "1133433E"
  },
  {
    "stationcode": "PRD",
    "stationname": "Pardoo Airport",
    "lat": "200800S",
    "long": "1193500E"
  },
  {
    "stationcode": "SSK",
    "stationname": "Sturt Creek Airport",
    "lat": "192000S",
    "long": "1280800E"
  },
  {
    "stationcode": "LEA",
    "stationname": "Learmonth Airport",
    "lat": "221408S",
    "long": "1140519E"
  },
  {
    "stationcode": "EXM",
    "stationname": "Exmouth Gulf Airport",
    "lat": "220225S",
    "long": "1140610E"
  },
  {
    "stationcode": "CXQ",
    "stationname": "Christmas Creek Airport",
    "lat": "185242S",
    "long": "1255544E"
  },
  {
    "stationcode": "RTS",
    "stationname": "Rottnest Island Airport",
    "lat": "320023S",
    "long": "1153223E"
  },
  {
    "stationcode": "LNO",
    "stationname": "Leonora Airport",
    "lat": "285255S",
    "long": "1211845E"
  },
  {
    "stationcode": "USL",
    "stationname": "Useless Loop Airport",
    "lat": "255800S",
    "long": "1130700E"
  },
  {
    "stationcode": "KTA",
    "stationname": "Karratha Airport",
    "lat": "204244S",
    "long": "1164624E"
  },
  {
    "stationcode": "MGV",
    "stationname": "Margaret River Station Airport",
    "lat": "183700S",
    "long": "1265200E"
  },
  {
    "stationcode": "CUY",
    "stationname": "Cue Airport",
    "lat": "272700S",
    "long": "1175500E"
  },
  {
    "stationcode": "GBV",
    "stationname": "Gibb River Airport",
    "lat": "162000S",
    "long": "1262200E"
  },
  {
    "stationcode": "BQB",
    "stationname": "Bussellton Airport",
    "lat": "334115S",
    "long": "1152408E"
  },
  {
    "stationcode": "ZVG",
    "stationname": "Springvale Airport",
    "lat": "174800S",
    "long": "1273800E"
  },
  {
    "stationcode": "MIH",
    "stationname": "Mitchell Plateau Airport",
    "lat": "144700S",
    "long": "1254900E"
  },
  {
    "stationcode": "IBM",
    "stationname": "Iron Bridge",
    "lat": "211714S",
    "long": "1185254E"
  },
  {
    "stationcode": "ONS",
    "stationname": "Onslow Airport",
    "lat": "214008S",
    "long": "1150645E"
  },
  {
    "stationcode": "WRW",
    "stationname": "Warrawagine Airport",
    "lat": "205800S",
    "long": "1204200E"
  },
  {
    "stationcode": "HCQ",
    "stationname": "Halls Creek Airport",
    "lat": "181400S",
    "long": "1274000E"
  },
  {
    "stationcode": "CJF",
    "stationname": "Coondewanna Airport",
    "lat": "225754S",
    "long": "1184845E"
  },
  {
    "stationcode": "SQC",
    "stationname": "Southern Cross Airport",
    "lat": "311500S",
    "long": "1192200E"
  },
  {
    "stationcode": "MKR",
    "stationname": "Meekatharra Airport",
    "lat": "263642S",
    "long": "1183252E"
  },
  {
    "stationcode": "KAX",
    "stationname": "Kalbarri Airport",
    "lat": "274134S",
    "long": "1141533E"
  },
  {
    "stationcode": "SGP",
    "stationname": "Shay Gap Airport",
    "lat": "202900S",
    "long": "1200600E"
  },
  {
    "stationcode": "SWB",
    "stationname": "Shaw River Airport",
    "lat": "213100S",
    "long": "1192200E"
  },
  {
    "stationcode": "KBD",
    "stationname": "Kimberley Downs Airport",
    "lat": "172400S",
    "long": "1242200E"
  },
  {
    "stationcode": "DGD",
    "stationname": "Dalgaranga Airport",
    "lat": "274950S",
    "long": "1171850E"
  },
  {
    "stationcode": "CGV",
    "stationname": "Caiguna Airport",
    "lat": "321600S",
    "long": "1252800E"
  },
  {
    "stationcode": "RHL",
    "stationname": "Roy Hill Airport",
    "lat": "223800S",
    "long": "1195800E"
  },
  {
    "stationcode": "BVZ",
    "stationname": "Beverley Springs Airport",
    "lat": "164356S",
    "long": "1252621E"
  },
  {
    "stationcode": "TPR",
    "stationname": "Tom Price Airport",
    "lat": "223900S",
    "long": "1174000E"
  },
  {
    "stationcode": "KNX",
    "stationname": "Kununurra Airport",
    "lat": "154641S",
    "long": "1284227E"
  },
  {
    "stationcode": "GBW",
    "stationname": "Ginbata Airport",
    "lat": "223729S",
    "long": "1195731E"
  },
  {
    "stationcode": "MJP",
    "stationname": "Manjimup Airport",
    "lat": "341400S",
    "long": "1160900E"
  },
  {
    "stationcode": "SLJ",
    "stationname": "Solomon Airport",
    "lat": "221520S",
    "long": "1174546E"
  },
  {
    "stationcode": "YLG",
    "stationname": "Yalgoo Airport",
    "lat": "282100S",
    "long": "1164000E"
  },
  {
    "stationcode": "PHE",
    "stationname": "Port Hedland Airport",
    "lat": "202300S",
    "long": "1183800E"
  },
  {
    "stationcode": "NRG",
    "stationname": "Narrogin Airport",
    "lat": "325600S",
    "long": "1171000E"
  },
  {
    "stationcode": "TBL",
    "stationname": "Tableland Airport",
    "lat": "171800S",
    "long": "1265000E"
  },
  {
    "stationcode": "FOS",
    "stationname": "Forrest Airport",
    "lat": "305000S",
    "long": "1280600E"
  },
  {
    "stationcode": "WHB",
    "stationname": "Eliwana Camp",
    "lat": "222545S",
    "long": "1165314E"
  },
  {
    "stationcode": "WUN",
    "stationname": "Wiluna Airport",
    "lat": "263745S",
    "long": "1201314E"
  },
  {
    "stationcode": "EUC",
    "stationname": "Eucla Airport",
    "lat": "314300S",
    "long": "1285200E"
  },
  {
    "stationcode": "UBU",
    "stationname": "Kalumburu Airport",
    "lat": "142500S",
    "long": "1264000E"
  },
  {
    "stationcode": "AGY",
    "stationname": "Argyle Downs Airport",
    "lat": "482156N",
    "long": "0105311E"
  },
  {
    "stationcode": "HLL",
    "stationname": "Hillside Airport",
    "lat": "170000S",
    "long": "1280030E"
  },
  {
    "stationcode": "BXF",
    "stationname": "Belburn Airport",
    "lat": "173244S",
    "long": "1281828E"
  },
  {
    "stationcode": "LGE",
    "stationname": "Lake Gregory Airport",
    "lat": "200800S",
    "long": "1273400E"
  },
  {
    "stationcode": "WYN",
    "stationname": "Wyndham Airport",
    "lat": "153100S",
    "long": "1280900E"
  },
  {
    "stationcode": "NKB",
    "stationname": "Noonkanbah Airport",
    "lat": "183000S",
    "long": "1245000E"
  },
  {
    "stationcode": "FSL",
    "stationname": "Fossil Downs Airport",
    "lat": "181200S",
    "long": "1254500E"
  },
  {
    "stationcode": "NLL",
    "stationname": "Nullagine Airport",
    "lat": "215500S",
    "long": "1201200E"
  },
  {
    "stationcode": "LVO",
    "stationname": "Laverton Airport",
    "lat": "283649S",
    "long": "1222526E"
  },
  {
    "stationcode": "MQA",
    "stationname": "Mandora Airport",
    "lat": "195000S",
    "long": "1205000E"
  },
  {
    "stationcode": "EPR",
    "stationname": "Esperance Airport",
    "lat": "334056S",
    "long": "1214921E"
  },
  {
    "stationcode": "COY",
    "stationname": "Coolawanyah Airport",
    "lat": "214800S",
    "long": "1174600E"
  },
  {
    "stationcode": "WME",
    "stationname": "Mount Keith Airport",
    "lat": "271713S",
    "long": "1203314E"
  },
  {
    "stationcode": "GYL",
    "stationname": "Argyle Airport",
    "lat": "163810S",
    "long": "1282701E"
  },
  {
    "stationcode": "FVL",
    "stationname": "Flora Valley Airport",
    "lat": "181700S",
    "long": "1282500E"
  },
  {
    "stationcode": "PBO",
    "stationname": "Paraburdoo Airport",
    "lat": "231019S",
    "long": "1174448E"
  },
  {
    "stationcode": "ODR",
    "stationname": "Ord River Airport",
    "lat": "172100S",
    "long": "1285400E"
  },
  {
    "stationcode": "YNN",
    "stationname": "Yandi Airport",
    "lat": "224600S",
    "long": "1191400E"
  },
  {
    "stationcode": "WIT",
    "stationname": "Wittenoom Airport",
    "lat": "221600S",
    "long": "1182000E"
  },
  {
    "stationcode": "MHO",
    "stationname": "Mount House Airport",
    "lat": "171000S",
    "long": "1254000E"
  },
  {
    "stationcode": "GDD",
    "stationname": "Gordon Downs Airport",
    "lat": "185000S",
    "long": "1283200E"
  },
  {
    "stationcode": "MWB",
    "stationname": "Morawa Airport",
    "lat": "291215S",
    "long": "1160121E"
  },
  {
    "stationcode": "CIE",
    "stationname": "Collie Airport",
    "lat": "332100S",
    "long": "1160900E"
  },
  {
    "stationcode": "FIZ",
    "stationname": "Fitzroy Crossing Airport",
    "lat": "181101S",
    "long": "1253336E"
  },
  {
    "stationcode": "CBC",
    "stationname": "Cherrabun Airport",
    "lat": "184000S",
    "long": "1252200E"
  },
  {
    "stationcode": "CVQ",
    "stationname": "Carnarvon Airport",
    "lat": "245305S",
    "long": "1133959E"
  },
  {
    "stationcode": "MQZ",
    "stationname": "Margaret River Airport",
    "lat": "335700S",
    "long": "1150400E"
  },
  {
    "stationcode": "MBB",
    "stationname": "Marble Bar Airport",
    "lat": "210945S",
    "long": "1195007E"
  },
  {
    "stationcode": "ENB",
    "stationname": "Eneabba Airport",
    "lat": "300500S",
    "long": "1145900E"
  },
  {
    "stationcode": "BDW",
    "stationname": "Bedford Downs Airport",
    "lat": "171800S",
    "long": "1272800E"
  },
  {
    "stationcode": "BIW",
    "stationname": "Billiluna Airport",
    "lat": "193500S",
    "long": "1273800E"
  },
  {
    "stationcode": "NDS",
    "stationname": "Sandstone Airport",
    "lat": "280000S",
    "long": "1191500E"
  },
  {
    "stationcode": "BEE",
    "stationname": "Beagle Bay Airport",
    "lat": "165500S",
    "long": "1223300E"
  },
  {
    "stationcode": "DCN",
    "stationname": "Curtin Airport",
    "lat": "173453S",
    "long": "1234942E"
  },
  {
    "stationcode": "KNI",
    "stationname": "Katanning Airport",
    "lat": "334200S",
    "long": "1173300E"
  },
  {
    "stationcode": "OOD",
    "stationname": "Koodaideri Mine Airport",
    "lat": "223120S",
    "long": "1190544E"
  },
  {
    "stationcode": "WRN",
    "stationname": "Windarling Airport",
    "lat": "300157S",
    "long": "1192311E"
  },
  {
    "stationcode": "TKY",
    "stationname": "Turkey Creek Airport",
    "lat": "170300S",
    "long": "1281200E"
  },
  {
    "stationcode": "DNG",
    "stationname": "Doongan Airport",
    "lat": "152300S",
    "long": "1261800E"
  },
  {
    "stationcode": "MMG",
    "stationname": "Mount Magnet Airport",
    "lat": "280658S",
    "long": "1175030E"
  },
  {
    "stationcode": "DOX",
    "stationname": "Dongara Airport",
    "lat": "291500S",
    "long": "1145600E"
  },
  {
    "stationcode": "KYF",
    "stationname": "Yeelirrie Airport",
    "lat": "271640S",
    "long": "1200430E"
  },
  {
    "stationcode": "KFE",
    "stationname": "Fortescue Dave Forrest Airport",
    "lat": "221728S",
    "long": "1192618E"
  },
  {
    "stationcode": "KGI",
    "stationname": "Kalgoorlie Airport",
    "lat": "304729S",
    "long": "1212749E"
  },
  {
    "stationcode": "MUQ",
    "stationname": "Muccan Airport",
    "lat": "203800S",
    "long": "1200200E"
  },
  {
    "stationcode": "MXU",
    "stationname": "Mullewa Airport",
    "lat": "283300S",
    "long": "1153100E"
  },
  {
    "stationcode": "NIF",
    "stationname": "Nifty Airport",
    "lat": "214025S",
    "long": "1213546E"
  },
  {
    "stationcode": "NSM",
    "stationname": "Norseman Airport",
    "lat": "321200S",
    "long": "1214500E"
  },
  {
    "stationcode": "JAD",
    "stationname": "Jandakot Airport",
    "lat": "320500S",
    "long": "1155300E"
  },
  {
    "stationcode": "TTX",
    "stationname": "Truscott-Mungalalu Airport",
    "lat": "140520S",
    "long": "1262254E"
  },
  {
    "stationcode": "KDB",
    "stationname": "Kambalda Airport",
    "lat": "311200S",
    "long": "1214000E"
  },
  {
    "stationcode": "BWB",
    "stationname": "Barrow Island Airport",
    "lat": "204800S",
    "long": "1152300E"
  },
  {
    "stationcode": "BBE",
    "stationname": "Big Bell Airport",
    "lat": "172000S",
    "long": "1173500E"
  },
  {
    "stationcode": "ZNE",
    "stationname": "Newman Airport",
    "lat": "232500S",
    "long": "1194800E"
  },
  {
    "stationcode": "TEF",
    "stationname": "Telfer Airport",
    "lat": "214258S",
    "long": "1221352E"
  },
  {
    "stationcode": "GSC",
    "stationname": "Gascoyne Junction Airport",
    "lat": "250300S",
    "long": "1151200E"
  },
  {
    "stationcode": "GLY",
    "stationname": "Goldsworthy Airport",
    "lat": "200500S",
    "long": "1194000E"
  },
  {
    "stationcode": "RBU",
    "stationname": "Roebourne Airport",
    "lat": "204600S",
    "long": "1170900E"
  },
  {
    "stationcode": "WLP",
    "stationname": "West Angelas Airport",
    "lat": "230813S",
    "long": "1184225E"
  },
  {
    "stationcode": "WND",
    "stationname": "Windarra Airport",
    "lat": "281600S",
    "long": "1215000E"
  },
  {
    "stationcode": "GET",
    "stationname": "Geraldton Airport",
    "lat": "284748S",
    "long": "1144223E"
  },
  {
    "stationcode": "LDW",
    "stationname": "Lansdowne Airport",
    "lat": "173500S",
    "long": "1265000E"
  },
  {
    "stationcode": "LER",
    "stationname": "Leinster Airport",
    "lat": "275039S",
    "long": "1204216E"
  },
  {
    "stationcode": "DRY",
    "stationname": "Drysdale River Airport",
    "lat": "154000S",
    "long": "1262500E"
  },
  {
    "stationcode": "CRY",
    "stationname": "Carlton Hill Airport",
    "lat": "154200S",
    "long": "1283000E"
  },
  {
    "stationcode": "BUY",
    "stationname": "Bunbury Airport",
    "lat": "332241S",
    "long": "1154043E"
  },
  {
    "stationcode": "DNM",
    "stationname": "Denham Airport",
    "lat": "255500S",
    "long": "1133200E"
  },
  {
    "stationcode": "MYO",
    "stationname": "Myroodah Airport",
    "lat": "181200S",
    "long": "1241200E"
  },
  {
    "stationcode": "LLL",
    "stationname": "Lissadell Airport",
    "lat": "163000S",
    "long": "1284000E"
  },
  {
    "stationcode": "WLA",
    "stationname": "Wallal Downs Airport",
    "lat": "194600S",
    "long": "1203900E"
  },
  {
    "stationcode": "ALH",
    "stationname": "Albany Airport",
    "lat": "345636S",
    "long": "1174832E"
  },
  {
    "stationcode": "OCM",
    "stationname": "Boolgeeda Airport",
    "lat": "223224S",
    "long": "1171630E"
  },
  {
    "stationcode": "DRB",
    "stationname": "Derby Airport",
    "lat": "172219S",
    "long": "1233945E"
  },
  {
    "stationcode": "POA",
    "stationname": "Salgado Filho Airport",
    "lat": "295940S",
    "long": "0511017W"
  },
  {
    "stationcode": "JLS",
    "stationname": "Jales Airport",
    "lat": "201600S",
    "long": "0503300W"
  },
  {
    "stationcode": "GRU",
    "stationname": "Guarulhos International Airport",
    "lat": "232555S",
    "long": "0462810W"
  },
  {
    "stationcode": "JJG",
    "stationname": "Humberto Bortoluzzi Airport",
    "lat": "284031S",
    "long": "0490334W"
  },
  {
    "stationcode": "POO",
    "stationname": "Pocos De Caldas Airport",
    "lat": "215029S",
    "long": "0463403W"
  },
  {
    "stationcode": "PGZ",
    "stationname": "Sant'Ana Airport",
    "lat": "250309S",
    "long": "0501120W"
  },
  {
    "stationcode": "BAT",
    "stationname": "Barretos Airport",
    "lat": "203506S",
    "long": "0483543W"
  },
  {
    "stationcode": "GUJ",
    "stationname": "Guaratingueta Airport",
    "lat": "224900S",
    "long": "0451300W"
  },
  {
    "stationcode": "PVI",
    "stationname": "Paranavia Airport",
    "lat": "230400S",
    "long": "0522800W"
  },
  {
    "stationcode": "MOC",
    "stationname": "Montes Claros Airport",
    "lat": "164225S",
    "long": "0434908W"
  },
  {
    "stationcode": "NVT",
    "stationname": "Navegantes Airport",
    "lat": "265248S",
    "long": "0483905W"
  },
  {
    "stationcode": "SJP",
    "stationname": "Sao Jose Do Rio Preto Airport",
    "lat": "204900S",
    "long": "0492423W"
  },
  {
    "stationcode": "CXJ",
    "stationname": "Campo Dos Bugres Airport",
    "lat": "291149S",
    "long": "0511115W"
  },
  {
    "stationcode": "APS",
    "stationname": "Anapolis Airport",
    "lat": "162146S",
    "long": "0485539W"
  },
  {
    "stationcode": "ITQ",
    "stationname": "Itaqui Airport",
    "lat": "291000S",
    "long": "0563200W"
  },
  {
    "stationcode": "PPY",
    "stationname": "Pouso Alegre Airport",
    "lat": "221720S",
    "long": "0455510W"
  },
  {
    "stationcode": "AAI",
    "stationname": "Arraias Airport",
    "lat": "125500S",
    "long": "0465600W"
  },
  {
    "stationcode": "ESI",
    "stationname": "Espinosa Airport",
    "lat": "145600S",
    "long": "0425000W"
  },
  {
    "stationcode": "AXE",
    "stationname": "Xanxere Airport",
    "lat": "265300S",
    "long": "0522300W"
  },
  {
    "stationcode": "CTQ",
    "stationname": "Do Palmar Airport",
    "lat": "333008S",
    "long": "0532040W"
  },
  {
    "stationcode": "QSC",
    "stationname": "Sao Carlos Airport",
    "lat": "215237S",
    "long": "0475415W"
  },
  {
    "stationcode": "IDO",
    "stationname": "Santa Isabel do Morro Airport",
    "lat": "113400S",
    "long": "0503800W"
  },
  {
    "stationcode": "IZA",
    "stationname": "Reg. Da Zona da Mata Airport",
    "lat": "213044S",
    "long": "0431014W"
  },
  {
    "stationcode": "JTN",
    "stationname": "Antonio R. Nogueira Jr.",
    "lat": "240934S",
    "long": "0464729W"
  },
  {
    "stationcode": "CHD",
    "stationname": "Mucuge Airport",
    "lat": "130155S",
    "long": "0412645W"
  },
  {
    "stationcode": "BFH",
    "stationname": "Bacacheri Airport",
    "lat": "252409S",
    "long": "0491402W"
  },
  {
    "stationcode": "JTI",
    "stationname": "Jatai Airport",
    "lat": "174950S",
    "long": "0514630W"
  },
  {
    "stationcode": "BSB",
    "stationname": "Brasilia International Airport",
    "lat": "155138S",
    "long": "0475455W"
  },
  {
    "stationcode": "GUZ",
    "stationname": "Guarapari Airport",
    "lat": "203900S",
    "long": "0403100W"
  },
  {
    "stationcode": "GDR",
    "stationname": "Angra dos Reis Airport",
    "lat": "225832S",
    "long": "0441826W"
  },
  {
    "stationcode": "TEC",
    "stationname": "Telemaco Borba Airport",
    "lat": "241900S",
    "long": "0503910W"
  },
  {
    "stationcode": "BNU",
    "stationname": "Blumenau Airport",
    "lat": "265600S",
    "long": "0490300W"
  },
  {
    "stationcode": "SQM",
    "stationname": "Sao Miguel Araguaia Airport",
    "lat": "131650S",
    "long": "0501010W"
  },
  {
    "stationcode": "VIX",
    "stationname": "Eurico Sales Airport",
    "lat": "201552S",
    "long": "0401715W"
  },
  {
    "stationcode": "CFB",
    "stationname": "Cabo Frio Airport",
    "lat": "225530S",
    "long": "0420443W"
  },
  {
    "stationcode": "NNU",
    "stationname": "Nanuque Airport",
    "lat": "174700S",
    "long": "0404100W"
  },
  {
    "stationcode": "XAP",
    "stationname": "Chapeco Airport",
    "lat": "270803S",
    "long": "0523924W"
  },
  {
    "stationcode": "CSU",
    "stationname": "Santa Cruz Do Sul Airport",
    "lat": "294100S",
    "long": "0522500W"
  },
  {
    "stationcode": "GIG",
    "stationname": "Internacional Airport",
    "lat": "224845S",
    "long": "0431454W"
  },
  {
    "stationcode": "LOI",
    "stationname": "Helmuth Baungartem Airport",
    "lat": "270935S",
    "long": "0493234W"
  },
  {
    "stationcode": "PPB",
    "stationname": "A. De Barros Airport",
    "lat": "221030S",
    "long": "0512529W"
  },
  {
    "stationcode": "JCB",
    "stationname": "Joacaba Airport",
    "lat": "271017S",
    "long": "0513313W"
  },
  {
    "stationcode": "PMW",
    "stationname": "Palmas Airport",
    "lat": "101729S",
    "long": "0482125W"
  },
  {
    "stationcode": "POJ",
    "stationname": "Patos De Minas Airport",
    "lat": "184018S",
    "long": "0462927W"
  },
  {
    "stationcode": "CPQ",
    "stationname": "Campinas International Airport",
    "lat": "230037S",
    "long": "0470826W"
  },
  {
    "stationcode": "JOI",
    "stationname": "Cubatao Airport",
    "lat": "261328S",
    "long": "0484751W"
  },
  {
    "stationcode": "GEL",
    "stationname": "Sepe Tiaraju Airport",
    "lat": "281658S",
    "long": "0541013W"
  },
  {
    "stationcode": "UDI",
    "stationname": "Eduardo Gomes Airport",
    "lat": "185258S",
    "long": "0481332W"
  },
  {
    "stationcode": "NQL",
    "stationname": "Niquelandia Airport",
    "lat": "142605S",
    "long": "0482931W"
  },
  {
    "stationcode": "SDU",
    "stationname": "Santos Dumont Airport",
    "lat": "225438S",
    "long": "0430947W"
  },
  {
    "stationcode": "BGX",
    "stationname": "Bage Airport",
    "lat": "312300S",
    "long": "0540700W"
  },
  {
    "stationcode": "CKO",
    "stationname": "Cornelio Procopio Airport",
    "lat": "230908S",
    "long": "0503608W"
  },
  {
    "stationcode": "JPY",
    "stationname": "Paraty Airport",
    "lat": "231327S",
    "long": "0444326W"
  },
  {
    "stationcode": "ERM",
    "stationname": "Comandante Kraemer Airport",
    "lat": "273948S",
    "long": "0521619W"
  },
  {
    "stationcode": "UBT",
    "stationname": "Ubatuba Airport",
    "lat": "232600S",
    "long": "0450400W"
  },
  {
    "stationcode": "CGH",
    "stationname": "Congonhas Airport",
    "lat": "233736S",
    "long": "0463929W"
  },
  {
    "stationcode": "EEA",
    "stationname": "Reg do Planalto Serano",
    "lat": "273802S",
    "long": "0502124W"
  },
  {
    "stationcode": "SBJ",
    "stationname": "Sao Mateus Airport",
    "lat": "184313S",
    "long": "0394957W"
  },
  {
    "stationcode": "PYT",
    "stationname": "Paracatu Airport",
    "lat": "171435S",
    "long": "0465300W"
  },
  {
    "stationcode": "MII",
    "stationname": "Dr Gastao Vidigal Airport",
    "lat": "221149S",
    "long": "0495535W"
  },
  {
    "stationcode": "LAJ",
    "stationname": "Lages Airport",
    "lat": "274658S",
    "long": "0501650W"
  },
  {
    "stationcode": "MEA",
    "stationname": "Macae Airport",
    "lat": "222035S",
    "long": "0414558W"
  },
  {
    "stationcode": "SOD",
    "stationname": "Sorocaba Airport",
    "lat": "232900S",
    "long": "0472700W"
  },
  {
    "stationcode": "TSQ",
    "stationname": "Torres Airport",
    "lat": "291957S",
    "long": "0494446W"
  },
  {
    "stationcode": "CCM",
    "stationname": "Criciuma Airport",
    "lat": "284333S",
    "long": "0492529W"
  },
  {
    "stationcode": "GPB",
    "stationname": "Tancredo Thomaz Faria Airport",
    "lat": "252317S",
    "long": "0513121W"
  },
  {
    "stationcode": "BZC",
    "stationname": "Buzios Airport",
    "lat": "224610S",
    "long": "0415752W"
  },
  {
    "stationcode": "PSW",
    "stationname": "Passos Airport",
    "lat": "204300S",
    "long": "0463700W"
  },
  {
    "stationcode": "PLU",
    "stationname": "Pampulha Airport",
    "lat": "195104S",
    "long": "0435702W"
  },
  {
    "stationcode": "CNF",
    "stationname": "Tancredo Neves International Airport",
    "lat": "193802S",
    "long": "0435808W"
  },
  {
    "stationcode": "CBW",
    "stationname": "Campo Mourao Airport",
    "lat": "240010S",
    "long": "0522120W"
  },
  {
    "stationcode": "JDF",
    "stationname": "Francisco De Assis Airport",
    "lat": "214730S",
    "long": "0432310W"
  },
  {
    "stationcode": "JRT",
    "stationname": "Juruti Airport",
    "lat": "021113S",
    "long": "0560527W"
  },
  {
    "stationcode": "JSB",
    "stationname": "Walfrido Salmito de Almeida",
    "lat": "040235S",
    "long": "0405339W"
  },
  {
    "stationcode": "GVR",
    "stationname": "Governador Valadares Airport",
    "lat": "185343S",
    "long": "0415856W"
  },
  {
    "stationcode": "AUX",
    "stationname": "Araguaina Airport",
    "lat": "071341S",
    "long": "0481425W"
  },
  {
    "stationcode": "NTM",
    "stationname": "Miracena Do Norte Airport",
    "lat": "091100S",
    "long": "0483000W"
  },
  {
    "stationcode": "ITP",
    "stationname": "Itaperuna Airport",
    "lat": "211308S",
    "long": "0415230W"
  },
  {
    "stationcode": "VOT",
    "stationname": "Votuporanga Airport",
    "lat": "202400S",
    "long": "0495900W"
  },
  {
    "stationcode": "CLV",
    "stationname": "Caldas Novas Airport",
    "lat": "174329S",
    "long": "0483636W"
  },
  {
    "stationcode": "CZB",
    "stationname": "Carlos Ruhl Airport",
    "lat": "283800S",
    "long": "0533000W"
  },
  {
    "stationcode": "FBE",
    "stationname": "Francisco Beltrao Airport",
    "lat": "260334S",
    "long": "0530351W"
  },
  {
    "stationcode": "SSZ",
    "stationname": "Santos Airport",
    "lat": "235500S",
    "long": "0460800W"
  },
  {
    "stationcode": "RAO",
    "stationname": "Leite Lopes Airport",
    "lat": "210803S",
    "long": "0474627W"
  },
  {
    "stationcode": "CFC",
    "stationname": "Cacador Airport",
    "lat": "264726S",
    "long": "0505629W"
  },
  {
    "stationcode": "FLN",
    "stationname": "Hercilio Luz Airport",
    "lat": "274011S",
    "long": "0483302W"
  },
  {
    "stationcode": "MGF",
    "stationname": "Regional De Maringa",
    "lat": "232834S",
    "long": "0520057W"
  },
  {
    "stationcode": "ARU",
    "stationname": "Aracatuba Airport",
    "lat": "210837S",
    "long": "0502534W"
  },
  {
    "stationcode": "NSR",
    "stationname": "Serra da Capivara Airport",
    "lat": "090500S",
    "long": "0423842W"
  },
  {
    "stationcode": "AAG",
    "stationname": "Arapoti Airport",
    "lat": "240613S",
    "long": "0494724W"
  },
  {
    "stationcode": "IGU",
    "stationname": "Cataratas Airport",
    "lat": "253546S",
    "long": "0542914W"
  },
  {
    "stationcode": "FRC",
    "stationname": "Franca Airport",
    "lat": "203500S",
    "long": "0472300W"
  },
  {
    "stationcode": "RVD",
    "stationname": "Rio Verde Airport",
    "lat": "175000S",
    "long": "0505720W"
  },
  {
    "stationcode": "CCI",
    "stationname": "Concordia Airport",
    "lat": "271500S",
    "long": "0520200W"
  },
  {
    "stationcode": "PIV",
    "stationname": "Pirapora Airport",
    "lat": "172020S",
    "long": "0445600W"
  },
  {
    "stationcode": "JCS",
    "stationname": "Aeroporto De Crateus",
    "lat": "051242S",
    "long": "0404218W"
  },
  {
    "stationcode": "JPO",
    "stationname": "Brigadeiro Firmino Ayres",
    "lat": "070221S",
    "long": "0371508W"
  },
  {
    "stationcode": "SRA",
    "stationname": "Santa Rosa Airport",
    "lat": "275424S",
    "long": "0543114W"
  },
  {
    "stationcode": "BJP",
    "stationname": "Braganca Paulista Airport",
    "lat": "225700S",
    "long": "0463400W"
  },
  {
    "stationcode": "OUS",
    "stationname": "Ourinhos Airport",
    "lat": "225900S",
    "long": "0495200W"
  },
  {
    "stationcode": "LIP",
    "stationname": "Lins Airport",
    "lat": "214000S",
    "long": "0494500W"
  },
  {
    "stationcode": "LDB",
    "stationname": "Londrina Airport",
    "lat": "232001S",
    "long": "0510748W"
  },
  {
    "stationcode": "JTA",
    "stationname": "Pedro Teixeira Castelo Regiona",
    "lat": "055601S",
    "long": "0401752W"
  },
  {
    "stationcode": "GRP",
    "stationname": "Gurupi Airport",
    "lat": "114000S",
    "long": "0491300W"
  },
  {
    "stationcode": "UMU",
    "stationname": "Ernesto Geisel Airport",
    "lat": "234755S",
    "long": "0531850W"
  },
  {
    "stationcode": "RWS",
    "stationname": "Sumare Airport",
    "lat": "224802S",
    "long": "0471132W"
  },
  {
    "stationcode": "PNG",
    "stationname": "Paranagua Municipal Airport",
    "lat": "253100S",
    "long": "0483000W"
  },
  {
    "stationcode": "DIQ",
    "stationname": "Divinopolis Airport",
    "lat": "201051S",
    "long": "0445216W"
  },
  {
    "stationcode": "VIA",
    "stationname": "Videira Airport",
    "lat": "270030S",
    "long": "0510810W"
  },
  {
    "stationcode": "RIA",
    "stationname": "Base Aerea Airport",
    "lat": "294238S",
    "long": "0534115W"
  },
  {
    "stationcode": "AIF",
    "stationname": "Assis Airport",
    "lat": "224000S",
    "long": "0502500W"
  },
  {
    "stationcode": "CAW",
    "stationname": "Bartolomeu Lisandro Airport",
    "lat": "214154S",
    "long": "0411806W"
  },
  {
    "stationcode": "LEP",
    "stationname": "Leopoldina Airport",
    "lat": "213058S",
    "long": "0423917W"
  },
  {
    "stationcode": "TLZ",
    "stationname": "Catalao Airport",
    "lat": "181000S",
    "long": "0475700W"
  },
  {
    "stationcode": "CAC",
    "stationname": "Cascavel Airport",
    "lat": "250001S",
    "long": "0533003W"
  },
  {
    "stationcode": "SJK",
    "stationname": "Sao Jose dos Campos Airport",
    "lat": "231044S",
    "long": "0455314W"
  },
  {
    "stationcode": "ITR",
    "stationname": "Itumbiara Airport",
    "lat": "182642S",
    "long": "0491251W"
  },
  {
    "stationcode": "VAG",
    "stationname": "Maj. Brig. Trompowsky Airport",
    "lat": "213524S",
    "long": "0452826W"
  },
  {
    "stationcode": "DNO",
    "stationname": "Dianopolis Airport",
    "lat": "113700S",
    "long": "0465100W"
  },
  {
    "stationcode": "AQA",
    "stationname": "Araraquara Airport",
    "lat": "214843S",
    "long": "0480759W"
  },
  {
    "stationcode": "LVB",
    "stationname": "Dos Galpoes Airport",
    "lat": "305008S",
    "long": "0553733W"
  },
  {
    "stationcode": "TOW",
    "stationname": "Toledo Airport",
    "lat": "244115S",
    "long": "0534151W"
  },
  {
    "stationcode": "SQX",
    "stationname": "Sao Miguel Do Oeste Airport",
    "lat": "264650S",
    "long": "0533010W"
  },
  {
    "stationcode": "VCP",
    "stationname": "Viracopos Airport",
    "lat": "230029S",
    "long": "0470805W"
  },
  {
    "stationcode": "JDR",
    "stationname": "Sao Joao Del Rei Airport",
    "lat": "210512S",
    "long": "0441336W"
  },
  {
    "stationcode": "REZ",
    "stationname": "Resende Airport",
    "lat": "222841S",
    "long": "0442852W"
  },
  {
    "stationcode": "JTC",
    "stationname": "Arealva Airport",
    "lat": "220949S",
    "long": "0490409W"
  },
  {
    "stationcode": "AAX",
    "stationname": "Araxa Airport",
    "lat": "193348S",
    "long": "0465737W"
  },
  {
    "stationcode": "AMJ",
    "stationname": "Almenara Airport",
    "lat": "161100S",
    "long": "0404100W"
  },
  {
    "stationcode": "SFV",
    "stationname": "Santa Fe Do Sul Airport",
    "lat": "201300S",
    "long": "0505600W"
  },
  {
    "stationcode": "PTO",
    "stationname": "Pato Branco Municipal Airport",
    "lat": "261302S",
    "long": "0524140W"
  },
  {
    "stationcode": "PFB",
    "stationname": "Passo Fundo Airport",
    "lat": "281440S",
    "long": "0521945W"
  },
  {
    "stationcode": "PNB",
    "stationname": "Porto Nacional Airport",
    "lat": "104300S",
    "long": "0482400W"
  },
  {
    "stationcode": "GYN",
    "stationname": "Santa Genoveva Airport",
    "lat": "163818S",
    "long": "0491239W"
  },
  {
    "stationcode": "DTI",
    "stationname": "Diamantina Airport",
    "lat": "181400S",
    "long": "0433904W"
  },
  {
    "stationcode": "APU",
    "stationname": "Apucarana Airport",
    "lat": "233300S",
    "long": "0512900W"
  },
  {
    "stationcode": "IPN",
    "stationname": "Usiminas Airport",
    "lat": "192815S",
    "long": "0422915W"
  },
  {
    "stationcode": "GCV",
    "stationname": "Gravatai Airport",
    "lat": "295630S",
    "long": "0505939W"
  },
  {
    "stationcode": "SQY",
    "stationname": "Sao Lourenco Do Sul Airport",
    "lat": "312258S",
    "long": "0520156W"
  },
  {
    "stationcode": "BGV",
    "stationname": "Bento Goncalves Airport",
    "lat": "291000S",
    "long": "0513100W"
  },
  {
    "stationcode": "IJU",
    "stationname": "J. Batista Bos Filho Airport",
    "lat": "282500S",
    "long": "0535500W"
  },
  {
    "stationcode": "UBA",
    "stationname": "Uberaba Airport",
    "lat": "194554S",
    "long": "0475753W"
  },
  {
    "stationcode": "ARS",
    "stationname": "Aragarcas Airport",
    "lat": "155500S",
    "long": "0521500W"
  },
  {
    "stationcode": "TFL",
    "stationname": "Teofilo Otoni Airport",
    "lat": "175100S",
    "long": "0413000W"
  },
  {
    "stationcode": "CDI",
    "stationname": "Cachoeiro de Itapemirim Airport",
    "lat": "205010S",
    "long": "0411113W"
  },
  {
    "stationcode": "ITI",
    "stationname": "Itambacuri Airport",
    "lat": "180100S",
    "long": "0414200W"
  },
  {
    "stationcode": "URB",
    "stationname": "Ernesto Pochler Airport",
    "lat": "204800S",
    "long": "0513400W"
  },
  {
    "stationcode": "RIG",
    "stationname": "Rio Grande Airport",
    "lat": "320452S",
    "long": "0520953W"
  },
  {
    "stationcode": "APX",
    "stationname": "Arapongas Airport",
    "lat": "232300S",
    "long": "0515700W"
  },
  {
    "stationcode": "SSO",
    "stationname": "Sao Lourenzo Airport",
    "lat": "220600S",
    "long": "0450200W"
  },
  {
    "stationcode": "JNA",
    "stationname": "Januaria Airport",
    "lat": "152800S",
    "long": "0442300W"
  },
  {
    "stationcode": "QDV",
    "stationname": "Cmt. Rolim Adolfo Amaro",
    "lat": "231055S",
    "long": "0465637W"
  },
  {
    "stationcode": "URG",
    "stationname": "Ruben Berta Airport",
    "lat": "294656S",
    "long": "0570215W"
  },
  {
    "stationcode": "MQH",
    "stationname": "Minacu Municipal Airport",
    "lat": "133254S",
    "long": "0481142W"
  },
  {
    "stationcode": "PET",
    "stationname": "Federal Airport",
    "lat": "314306S",
    "long": "0521947W"
  },
  {
    "stationcode": "HRZ",
    "stationname": "Horizontina Airport",
    "lat": "273818S",
    "long": "0542026W"
  },
  {
    "stationcode": "CWB",
    "stationname": "Afonso Pena Airport",
    "lat": "253136S",
    "long": "0491040W"
  },
  {
    "stationcode": "ALQ",
    "stationname": "Federal Airport",
    "lat": "294800S",
    "long": "0554400W"
  },
  {
    "stationcode": "BAU",
    "stationname": "Bauru Airport",
    "lat": "410704N",
    "long": "0165210E"
  },
  {
    "stationcode": "NBV",
    "stationname": "Cana Brava Airport",
    "lat": "172300S",
    "long": "0455200W"
  },
  {
    "stationcode": "BYO",
    "stationname": "Bonito Airport",
    "lat": "211346S",
    "long": "0562722W"
  },
  {
    "stationcode": "AUB",
    "stationname": "Itauba Airport",
    "lat": "054600S",
    "long": "0632100W"
  },
  {
    "stationcode": "FDB",
    "stationname": "Principe Da Beira Airport",
    "lat": "122500S",
    "long": "0642500W"
  },
  {
    "stationcode": "DOU",
    "stationname": "Dourados Airport",
    "lat": "221205S",
    "long": "0545536W"
  },
  {
    "stationcode": "OPS",
    "stationname": "Sinop Airport",
    "lat": "115306S",
    "long": "0553510W"
  },
  {
    "stationcode": "PBX",
    "stationname": "Porto Alegre Do Norte Airport",
    "lat": "105139S",
    "long": "0514104W"
  },
  {
    "stationcode": "CFO",
    "stationname": "Confreza Airport",
    "lat": "103800S",
    "long": "0513400W"
  },
  {
    "stationcode": "PMG",
    "stationname": "Ponta Pora International Airport",
    "lat": "223200S",
    "long": "0554400W"
  },
  {
    "stationcode": "NOK",
    "stationname": "Nova Xavantina Airport",
    "lat": "144130S",
    "long": "0522100W"
  },
  {
    "stationcode": "CQA",
    "stationname": "Canarana Airport",
    "lat": "133422S",
    "long": "0521610W"
  },
  {
    "stationcode": "SWM",
    "stationname": "Suia-Missu Airport",
    "lat": "114000S",
    "long": "0512515W"
  },
  {
    "stationcode": "JIA",
    "stationname": "Juina Airport",
    "lat": "112511S",
    "long": "0584236W"
  },
  {
    "stationcode": "MBK",
    "stationname": "Matupa Airport",
    "lat": "101430S",
    "long": "0545715W"
  },
  {
    "stationcode": "TJL",
    "stationname": "Tres Lagoas Airport",
    "lat": "204516S",
    "long": "0514102W"
  },
  {
    "stationcode": "LCB",
    "stationname": "Pontes-e-Lacerda Airport",
    "lat": "130050S",
    "long": "0595950W"
  },
  {
    "stationcode": "STZ",
    "stationname": "Confresa Airport",
    "lat": "102752S",
    "long": "0500310W"
  },
  {
    "stationcode": "MTG",
    "stationname": "Mato Grosso Airport",
    "lat": "150100S",
    "long": "0600100W"
  },
  {
    "stationcode": "CGB",
    "stationname": "M. Rondon Airport",
    "lat": "153911S",
    "long": "0560700W"
  },
  {
    "stationcode": "ILB",
    "stationname": "Ilha Solteira Airport",
    "lat": "202000S",
    "long": "0512000W"
  },
  {
    "stationcode": "PBB",
    "stationname": "Paranaiba Airport",
    "lat": "194000S",
    "long": "0511100W"
  },
  {
    "stationcode": "VLP",
    "stationname": "Vila Rica Municipal Airport",
    "lat": "095851S",
    "long": "0510825W"
  },
  {
    "stationcode": "AIR",
    "stationname": "Aripuana Airport",
    "lat": "101128S",
    "long": "0592725W"
  },
  {
    "stationcode": "SXO",
    "stationname": "Sao Felix Do Araguaia Airport",
    "lat": "113757S",
    "long": "0504120W"
  },
  {
    "stationcode": "CSS",
    "stationname": "Cassilandia Airport",
    "lat": "190900S",
    "long": "0514500W"
  },
  {
    "stationcode": "PBV",
    "stationname": "Porto Dos Gauchos Airport",
    "lat": "113100S",
    "long": "0572000W"
  },
  {
    "stationcode": "IUP",
    "stationname": "Apui Airport",
    "lat": "071025S",
    "long": "0595021W"
  },
  {
    "stationcode": "JUA",
    "stationname": "Juara Airport",
    "lat": "125000S",
    "long": "0585500W"
  },
  {
    "stationcode": "CMG",
    "stationname": "Corumba Internacional Airport",
    "lat": "190043S",
    "long": "0574023W"
  },
  {
    "stationcode": "DMT",
    "stationname": "Diamantino Airport",
    "lat": "142500S",
    "long": "0562700W"
  },
  {
    "stationcode": "NVM",
    "stationname": "Brigadeiro Eduardo Gomes",
    "lat": "134916S",
    "long": "0560219W"
  },
  {
    "stationcode": "JRN",
    "stationname": "Juruena Airport",
    "lat": "101820S",
    "long": "0582920W"
  },
  {
    "stationcode": "CGR",
    "stationname": "Campo Grande Internacional Airport",
    "lat": "202807S",
    "long": "0544021W"
  },
  {
    "stationcode": "BPG",
    "stationname": "Barra Do Garcas Airport",
    "lat": "155145S",
    "long": "0522325W"
  },
  {
    "stationcode": "CCX",
    "stationname": "Caceres Airport",
    "lat": "160600S",
    "long": "0573900W"
  },
  {
    "stationcode": "RPU",
    "stationname": "Urucu Airport",
    "lat": "045300S",
    "long": "0652104W"
  },
  {
    "stationcode": "ROO",
    "stationname": "Rondonopolis Airport",
    "lat": "163513S",
    "long": "0544330W"
  },
  {
    "stationcode": "JPE",
    "stationname": "Paragominas Airport",
    "lat": "030105S",
    "long": "0471917W"
  },
  {
    "stationcode": "TGQ",
    "stationname": "Tangara Da Serra Airport",
    "lat": "143932S",
    "long": "0572640W"
  },
  {
    "stationcode": "AFL",
    "stationname": "Alta Floresta Airport",
    "lat": "095221S",
    "long": "0560621W"
  },
  {
    "stationcode": "JAW",
    "stationname": "Araripina Airport",
    "lat": "073511S",
    "long": "0403208W"
  },
  {
    "stationcode": "FEN",
    "stationname": "Fernando De Noronha Airport",
    "lat": "035118S",
    "long": "0322524W"
  },
  {
    "stationcode": "YFX",
    "stationname": "Fox Harbour Airport",
    "lat": "522223N",
    "long": "0554026W"
  },
  {
    "stationcode": "YMH",
    "stationname": "Mary's Harbour Airport",
    "lat": "521811N",
    "long": "0555052W"
  },
  {
    "stationcode": "YNF",
    "stationname": "Deer Lake/Stephenville Airport",
    "lat": "485649N",
    "long": "0575638W"
  },
  {
    "stationcode": "YJT",
    "stationname": "Stephenville Airport",
    "lat": "483239N",
    "long": "0583300W"
  },
  {
    "stationcode": "YWM",
    "stationname": "Williams Harbour Airport",
    "lat": "523403N",
    "long": "0554704W"
  },
  {
    "stationcode": "YQX",
    "stationname": "Gander Airport",
    "lat": "485652N",
    "long": "0543336W"
  },
  {
    "stationcode": "YHG",
    "stationname": "Charlottetown Airport",
    "lat": "524558N",
    "long": "0560645W"
  },
  {
    "stationcode": "YAY",
    "stationname": "St. Anthony Airport",
    "lat": "512331N",
    "long": "0560459W"
  },
  {
    "stationcode": "YYT",
    "stationname": "St. John's Airport",
    "lat": "473701N",
    "long": "0524500W"
  },
  {
    "stationcode": "YZM",
    "stationname": "Buchans Airport",
    "lat": "485000N",
    "long": "0565200W"
  },
  {
    "stationcode": "YHA",
    "stationname": "Port Hope Simpson Airport",
    "lat": "523142N",
    "long": "0561705W"
  },
  {
    "stationcode": "YDH",
    "stationname": "Daniels Harbour Airport",
    "lat": "501300N",
    "long": "0573500W"
  },
  {
    "stationcode": "YDF",
    "stationname": "Deer Lake Airport",
    "lat": "491239N",
    "long": "0572329W"
  },
  {
    "stationcode": "YQM",
    "stationname": "Metropolitan Area",
    "lat": "460625N",
    "long": "0644133W"
  },
  {
    "stationcode": "ZUM",
    "stationname": "Churchill Falls Airport",
    "lat": "533346N",
    "long": "0640622W"
  },
  {
    "stationcode": "YPS",
    "stationname": "Port Hawkesbury Airport",
    "lat": "453700N",
    "long": "0612100W"
  },
  {
    "stationcode": "YNP",
    "stationname": "Natuashish Airport",
    "lat": "555450N",
    "long": "0611104W"
  },
  {
    "stationcode": "YSV",
    "stationname": "Saglek Airport",
    "lat": "583500N",
    "long": "0623000W"
  },
  {
    "stationcode": "YSJ",
    "stationname": "Saint John Airport",
    "lat": "451914N",
    "long": "0655256W"
  },
  {
    "stationcode": "YFC",
    "stationname": "Fredericton Airport",
    "lat": "455208N",
    "long": "0663148W"
  },
  {
    "stationcode": "YWK",
    "stationname": "Wabush Airport",
    "lat": "525519N",
    "long": "0665152W"
  },
  {
    "stationcode": "YCX",
    "stationname": "Gagetown Airport",
    "lat": "455016N",
    "long": "0662613W"
  },
  {
    "stationcode": "YSU",
    "stationname": "Summerside Airport",
    "lat": "462600N",
    "long": "0635000W"
  },
  {
    "stationcode": "YDE",
    "stationname": "Paradise River Airport",
    "lat": "532600N",
    "long": "0571400W"
  },
  {
    "stationcode": "YAW",
    "stationname": "Shearwater Airport",
    "lat": "443800N",
    "long": "0633000W"
  },
  {
    "stationcode": "YRF",
    "stationname": "Cartwright Airport",
    "lat": "534058N",
    "long": "0570233W"
  },
  {
    "stationcode": "YYG",
    "stationname": "Charlottetown Airport",
    "lat": "461721N",
    "long": "0630732W"
  },
  {
    "stationcode": "YDG",
    "stationname": "Digby Airport",
    "lat": "443000N",
    "long": "0661000W"
  },
  {
    "stationcode": "YQY",
    "stationname": "Sydney Airport",
    "lat": "460941N",
    "long": "0600252W"
  },
  {
    "stationcode": "YSL",
    "stationname": "Edmunston Airport",
    "lat": "471100N",
    "long": "0675400W"
  },
  {
    "stationcode": "YHZ",
    "stationname": "Halifax International Airport",
    "lat": "445313N",
    "long": "0633046W"
  },
  {
    "stationcode": "YYR",
    "stationname": "Goose Bay Airport",
    "lat": "531910N",
    "long": "0602438W"
  },
  {
    "stationcode": "YDI",
    "stationname": "Davis Inlet Airport",
    "lat": "555600N",
    "long": "0605700W"
  },
  {
    "stationcode": "ZFB",
    "stationname": "Old Fort Bay Airport",
    "lat": "513500N",
    "long": "0575500W"
  },
  {
    "stationcode": "YMN",
    "stationname": "Makkovik Airport",
    "lat": "550439N",
    "long": "0591117W"
  },
  {
    "stationcode": "YCL",
    "stationname": "Charlo Airport",
    "lat": "475929N",
    "long": "0661953W"
  },
  {
    "stationcode": "YDP",
    "stationname": "Nain Airport",
    "lat": "563303N",
    "long": "0614057W"
  },
  {
    "stationcode": "YRG",
    "stationname": "Rigolet Airport",
    "lat": "541046N",
    "long": "0582727W"
  },
  {
    "stationcode": "YSO",
    "stationname": "Postville Airport",
    "lat": "545436N",
    "long": "0594710W"
  },
  {
    "stationcode": "YHO",
    "stationname": "Hopedale Airport",
    "lat": "552654N",
    "long": "0601347W"
  },
  {
    "stationcode": "YQI",
    "stationname": "Yarmouth Airport",
    "lat": "435000N",
    "long": "0660500W"
  },
  {
    "stationcode": "YGR",
    "stationname": "Iles De La Madeleine Airport",
    "lat": "472529N",
    "long": "0614649W"
  },
  {
    "stationcode": "YBI",
    "stationname": "Black Tickle Airport",
    "lat": "532811N",
    "long": "0554711W"
  },
  {
    "stationcode": "YCH",
    "stationname": "Miramichi Airport",
    "lat": "470029N",
    "long": "0652658W"
  },
  {
    "stationcode": "YSA",
    "stationname": "Sable Island Airport",
    "lat": "435500N",
    "long": "0595000W"
  },
  {
    "stationcode": "YZX",
    "stationname": "Greenwood Airport",
    "lat": "445900N",
    "long": "0645500W"
  },
  {
    "stationcode": "ZBF",
    "stationname": "Bathurst Airport",
    "lat": "473745N",
    "long": "0654422W"
  },
  {
    "stationcode": "YMW",
    "stationname": "Maniwaki Airport",
    "lat": "462300N",
    "long": "0755800W"
  },
  {
    "stationcode": "YSK",
    "stationname": "Sanikiluaq Airport",
    "lat": "563213N",
    "long": "0791500W"
  },
  {
    "stationcode": "XGR",
    "stationname": "Kangiqsualujjuaq Airport",
    "lat": "584241N",
    "long": "0655931W"
  },
  {
    "stationcode": "AKV",
    "stationname": "Akulivik Airport",
    "lat": "604907N",
    "long": "0780855W"
  },
  {
    "stationcode": "YVP",
    "stationname": "Kuujjuaq Airport",
    "lat": "580528N",
    "long": "0682538W"
  },
  {
    "stationcode": "YUX",
    "stationname": "Hall Beach Airport",
    "lat": "684634N",
    "long": "0811437W"
  },
  {
    "stationcode": "YXZ",
    "stationname": "Wawa Airport",
    "lat": "475900N",
    "long": "0844700W"
  },
  {
    "stationcode": "YKG",
    "stationname": "Kangirsuk Airport",
    "lat": "600138N",
    "long": "0695959W"
  },
  {
    "stationcode": "YFG",
    "stationname": "Fontanges Airport",
    "lat": "543300N",
    "long": "0711100W"
  },
  {
    "stationcode": "YOG",
    "stationname": "Ogoki Airport",
    "lat": "513929N",
    "long": "0855407W"
  },
  {
    "stationcode": "YSB",
    "stationname": "Sudbury Airport",
    "lat": "463800N",
    "long": "0804800W"
  },
  {
    "stationcode": "SUR",
    "stationname": "Summer Beaver Airport",
    "lat": "524231N",
    "long": "0883232W"
  },
  {
    "stationcode": "YRQ",
    "stationname": "Trois-Rivieres Airport",
    "lat": "462100N",
    "long": "0724100W"
  },
  {
    "stationcode": "YTQ",
    "stationname": "Tasiujuaq Airport",
    "lat": "584003N",
    "long": "0695721W"
  },
  {
    "stationcode": "XCM",
    "stationname": "Chatham Airport",
    "lat": "421836N",
    "long": "0820436W"
  },
  {
    "stationcode": "YPJ",
    "stationname": "Aupaluk Airport",
    "lat": "591743N",
    "long": "0693602W"
  },
  {
    "stationcode": "YTN",
    "stationname": "Riviere Au Tonnerre Airport",
    "lat": "501700N",
    "long": "0644500W"
  },
  {
    "stationcode": "ZSP",
    "stationname": "St. Paul Airport",
    "lat": "475500N",
    "long": "0691500W"
  },
  {
    "stationcode": "YNM",
    "stationname": "Matagami Airport",
    "lat": "494600N",
    "long": "0774800W"
  },
  {
    "stationcode": "YUY",
    "stationname": "Rouyn Airport",
    "lat": "481220N",
    "long": "0784950W"
  },
  {
    "stationcode": "YVO",
    "stationname": "Val D'Or Airport",
    "lat": "480312N",
    "long": "0774658W"
  },
  {
    "stationcode": "YPO",
    "stationname": "Peawanuk Airport",
    "lat": "545916N",
    "long": "0852634W"
  },
  {
    "stationcode": "YTS",
    "stationname": "Timmins Airport",
    "lat": "483400N",
    "long": "0812200W"
  },
  {
    "stationcode": "YCI",
    "stationname": "Caribou Island Airport",
    "lat": "615500N",
    "long": "1131500W"
  },
  {
    "stationcode": "YCM",
    "stationname": "St. Catharines Airport",
    "lat": "431200N",
    "long": "0791000W"
  },
  {
    "stationcode": "YDO",
    "stationname": "St Methode Airport",
    "lat": "485300N",
    "long": "0721400W"
  },
  {
    "stationcode": "YAM",
    "stationname": "Sault Ste. Marie Airport",
    "lat": "462906N",
    "long": "0843034W"
  },
  {
    "stationcode": "YMV",
    "stationname": "Mary River Airport",
    "lat": "712929N",
    "long": "0792137W"
  },
  {
    "stationcode": "YNS",
    "stationname": "Nemiscau Airport",
    "lat": "514127N",
    "long": "0760812W"
  },
  {
    "stationcode": "YSS",
    "stationname": "Slate Island Airport",
    "lat": "540920N",
    "long": "0013840W"
  },
  {
    "stationcode": "YKL",
    "stationname": "Schefferville Airport",
    "lat": "544821N",
    "long": "0664821W"
  },
  {
    "stationcode": "YGW",
    "stationname": "Kuujjuarapik Airport",
    "lat": "551655N",
    "long": "0774554W"
  },
  {
    "stationcode": "YBS",
    "stationname": "Musselwhite Airport",
    "lat": "523624N",
    "long": "0902237W"
  },
  {
    "stationcode": "YZG",
    "stationname": "Salluit Airport",
    "lat": "621200N",
    "long": "0753800W"
  },
  {
    "stationcode": "YQS",
    "stationname": "Pembroke Area Municipal Airport",
    "lat": "424700N",
    "long": "0811200W"
  },
  {
    "stationcode": "YTE",
    "stationname": "Cape Dorset Airport",
    "lat": "641348N",
    "long": "0763136W"
  },
  {
    "stationcode": "YIK",
    "stationname": "Ivujivik Airport",
    "lat": "622503N",
    "long": "0775532W"
  },
  {
    "stationcode": "YLX",
    "stationname": "Long Point Airport",
    "lat": "222558N",
    "long": "1100711E"
  },
  {
    "stationcode": "YTR",
    "stationname": "Trenton Airport",
    "lat": "440600N",
    "long": "0773400W"
  },
  {
    "stationcode": "YFB",
    "stationname": "Iqaluit Airport",
    "lat": "634523N",
    "long": "0683321W"
  },
  {
    "stationcode": "YPN",
    "stationname": "Port Menier Airport",
    "lat": "495011N",
    "long": "0641722W"
  },
  {
    "stationcode": "YTZ",
    "stationname": "City Centre Airport",
    "lat": "433739N",
    "long": "0792346W"
  },
  {
    "stationcode": "SSQ",
    "stationname": "La Sarre Airport",
    "lat": "485500N",
    "long": "0791000W"
  },
  {
    "stationcode": "YSI",
    "stationname": "Sans Souci Airport",
    "lat": "451600N",
    "long": "0800000W"
  },
  {
    "stationcode": "YSP",
    "stationname": "Marathon Airport",
    "lat": "484500N",
    "long": "0862100W"
  },
  {
    "stationcode": "XFZ",
    "stationname": "Charny Airport",
    "lat": "464300N",
    "long": "0711600W"
  },
  {
    "stationcode": "YTA",
    "stationname": "Pembroke And Area Airport",
    "lat": "455201N",
    "long": "0771500W"
  },
  {
    "stationcode": "YGL",
    "stationname": "La Grande Airport",
    "lat": "533747N",
    "long": "0774223W"
  },
  {
    "stationcode": "YOY",
    "stationname": "Valcartier Airport",
    "lat": "465411N",
    "long": "0713014W"
  },
  {
    "stationcode": "ZEM",
    "stationname": "East Main Airport",
    "lat": "521333N",
    "long": "0783121W"
  },
  {
    "stationcode": "YAB",
    "stationname": "Arctic Bay Airport",
    "lat": "730022N",
    "long": "0850158W"
  },
  {
    "stationcode": "YAR",
    "stationname": "Lagrande 3 Airport",
    "lat": "533300N",
    "long": "0761200W"
  },
  {
    "stationcode": "YFH",
    "stationname": "Fort Hope Airport",
    "lat": "513342N",
    "long": "0875429W"
  },
  {
    "stationcode": "YZE",
    "stationname": "Gore Bay Airport",
    "lat": "455500N",
    "long": "0822800W"
  },
  {
    "stationcode": "YGQ",
    "stationname": "Geraldton Airport",
    "lat": "494644N",
    "long": "0865615W"
  },
  {
    "stationcode": "YOO",
    "stationname": "Oshawa Municipal Airport",
    "lat": "435520N",
    "long": "0785343W"
  },
  {
    "stationcode": "YVM",
    "stationname": "Broughton Island Airport",
    "lat": "673245N",
    "long": "0640153W"
  },
  {
    "stationcode": "YKX",
    "stationname": "Kirkland Lake Airport",
    "lat": "480900N",
    "long": "0800200W"
  },
  {
    "stationcode": "YCN",
    "stationname": "Cochrane Airport",
    "lat": "490400N",
    "long": "0810100W"
  },
  {
    "stationcode": "YEM",
    "stationname": "East Manitoulin Airport",
    "lat": "455030N",
    "long": "0815130W"
  },
  {
    "stationcode": "YQB",
    "stationname": "Quebec Airport",
    "lat": "464721N",
    "long": "0712353W"
  },
  {
    "stationcode": "YAF",
    "stationname": "Asbestos Hill Airport",
    "lat": "454600N",
    "long": "0715700W"
  },
  {
    "stationcode": "YRO",
    "stationname": "Rockcliffe St. Airport",
    "lat": "452900N",
    "long": "0752900W"
  },
  {
    "stationcode": "YGA",
    "stationname": "Gagnon Airport",
    "lat": "292124N",
    "long": "1060037E"
  },
  {
    "stationcode": "YLK",
    "stationname": "Lake Simcoe Regional",
    "lat": "442907N",
    "long": "0793320W"
  },
  {
    "stationcode": "YIO",
    "stationname": "Pond Inlet Airport",
    "lat": "724125N",
    "long": "0775806W"
  },
  {
    "stationcode": "XBR",
    "stationname": "Brockville Airport",
    "lat": "443821N",
    "long": "0754502W"
  },
  {
    "stationcode": "YFE",
    "stationname": "Forestville Airport",
    "lat": "484400N",
    "long": "0690600W"
  },
  {
    "stationcode": "YYZ",
    "stationname": "Pearson International Airport",
    "lat": "434038N",
    "long": "0793750W"
  },
  {
    "stationcode": "YME",
    "stationname": "Matane Airport",
    "lat": "485100N",
    "long": "0673300W"
  },
  {
    "stationcode": "YGY",
    "stationname": "Deception Bay Airport",
    "lat": "620600N",
    "long": "0743700W"
  },
  {
    "stationcode": "XLF",
    "stationname": "Leaf Bay Airport",
    "lat": "353306N",
    "long": "1290818E"
  },
  {
    "stationcode": "YGP",
    "stationname": "Metropolitan Area Airport",
    "lat": "484600N",
    "long": "0642900W"
  },
  {
    "stationcode": "YHF",
    "stationname": "Hearst Airport",
    "lat": "494100N",
    "long": "0834000W"
  },
  {
    "stationcode": "YXP",
    "stationname": "Pangnirtung Airport",
    "lat": "660842N",
    "long": "0654249W"
  },
  {
    "stationcode": "YCV",
    "stationname": "Cartierville Airport",
    "lat": "453100N",
    "long": "0734500W"
  },
  {
    "stationcode": "YPX",
    "stationname": "Puvirnituq Airport",
    "lat": "600256N",
    "long": "0771709W"
  },
  {
    "stationcode": "YYU",
    "stationname": "Kapuskasing Airport",
    "lat": "492452N",
    "long": "0822816W"
  },
  {
    "stationcode": "YKQ",
    "stationname": "Waskaganish Airport",
    "lat": "512823N",
    "long": "0784531W"
  },
  {
    "stationcode": "YGK",
    "stationname": "Kingston Airport",
    "lat": "441325N",
    "long": "0763625W"
  },
  {
    "stationcode": "YCC",
    "stationname": "Cornwall Regional Airport",
    "lat": "450535N",
    "long": "0743402W"
  },
  {
    "stationcode": "YSC",
    "stationname": "Sherbrooke Airport",
    "lat": "452600N",
    "long": "0714100W"
  },
  {
    "stationcode": "YKF",
    "stationname": "Kitchener-Waterloo Regional Airport",
    "lat": "432739N",
    "long": "0802243W"
  },
  {
    "stationcode": "YNI",
    "stationname": "Nitchequon Airport",
    "lat": "531000N",
    "long": "0705800W"
  },
  {
    "stationcode": "YSH",
    "stationname": "Smith Falls Airport",
    "lat": "445647N",
    "long": "0755623W"
  },
  {
    "stationcode": "YGT",
    "stationname": "Igloolik Airport",
    "lat": "692159N",
    "long": "0814914W"
  },
  {
    "stationcode": "YVB",
    "stationname": "Bonaventure Airport",
    "lat": "480415N",
    "long": "0652732W"
  },
  {
    "stationcode": "YWQ",
    "stationname": "Chute-Des-Passes Airport",
    "lat": "495314N",
    "long": "0711514W"
  },
  {
    "stationcode": "YLC",
    "stationname": "Kimmirut Airport",
    "lat": "625052N",
    "long": "0695234W"
  },
  {
    "stationcode": "YLQ",
    "stationname": "La Tuque Airport",
    "lat": "472432N",
    "long": "0724721W"
  },
  {
    "stationcode": "YEB",
    "stationname": "Bar River Airport",
    "lat": "462500N",
    "long": "0840500W"
  },
  {
    "stationcode": "YXI",
    "stationname": "Killaloe Airport",
    "lat": "453300N",
    "long": "0772500W"
  },
  {
    "stationcode": "YMC",
    "stationname": "Maricourt Airstrip",
    "lat": "563400N",
    "long": "0704900W"
  },
  {
    "stationcode": "YAU",
    "stationname": "Kattiniq/Donaldson Lake Airport",
    "lat": "613946N",
    "long": "0731901W"
  },
  {
    "stationcode": "YYB",
    "stationname": "North Bay Airport",
    "lat": "462157N",
    "long": "0792517W"
  },
  {
    "stationcode": "YDK",
    "stationname": "Main Duck Island Airport",
    "lat": "435600N",
    "long": "0763700W"
  },
  {
    "stationcode": "XBW",
    "stationname": "Killineq Airport",
    "lat": "423900N",
    "long": "0804900W"
  },
  {
    "stationcode": "YBN",
    "stationname": "Borden Airport",
    "lat": "441700N",
    "long": "0795500W"
  },
  {
    "stationcode": "YPD",
    "stationname": "Parry Sound Airport",
    "lat": "452100N",
    "long": "0800200W"
  },
  {
    "stationcode": "YXU",
    "stationname": "Metropolitan Area Airport",
    "lat": "430208N",
    "long": "0810914W"
  },
  {
    "stationcode": "YCE",
    "stationname": "Centralia Airport",
    "lat": "431700N",
    "long": "0812900W"
  },
  {
    "stationcode": "YKD",
    "stationname": "Township Airport",
    "lat": "441205N",
    "long": "0813624W"
  },
  {
    "stationcode": "YKU",
    "stationname": "Chisasibi Airport",
    "lat": "534825N",
    "long": "0785525W"
  },
  {
    "stationcode": "YLH",
    "stationname": "Lansdowne House Airport",
    "lat": "521144N",
    "long": "0875600W"
  },
  {
    "stationcode": "YRI",
    "stationname": "Riviere-du-Loup Airport",
    "lat": "474600N",
    "long": "0693500W"
  },
  {
    "stationcode": "YVN",
    "stationname": "Cape Dyer Airport",
    "lat": "663531N",
    "long": "0613424W"
  },
  {
    "stationcode": "YXK",
    "stationname": "Rimouski Airport",
    "lat": "482800N",
    "long": "0683100W"
  },
  {
    "stationcode": "YOW",
    "stationname": "Ottawa International Airport",
    "lat": "451915N",
    "long": "0754022W"
  },
  {
    "stationcode": "YQN",
    "stationname": "Nakina Airport",
    "lat": "501301N",
    "long": "0864212W"
  },
  {
    "stationcode": "YXR",
    "stationname": "Earlton Airport",
    "lat": "474200N",
    "long": "0795100W"
  },
  {
    "stationcode": "ZBM",
    "stationname": "Bromont Airport",
    "lat": "451900N",
    "long": "0725000W"
  },
  {
    "stationcode": "YWA",
    "stationname": "Petawawa Airport",
    "lat": "455400N",
    "long": "0771700W"
  },
  {
    "stationcode": "YWN",
    "stationname": "Winisk Airport",
    "lat": "551500N",
    "long": "0850700W"
  },
  {
    "stationcode": "YWR",
    "stationname": "White River Airport",
    "lat": "483500N",
    "long": "0851500W"
  },
  {
    "stationcode": "YJN",
    "stationname": "Saint Jean Airport",
    "lat": "451900N",
    "long": "0731600W"
  },
  {
    "stationcode": "YZR",
    "stationname": "Sarnia Airport",
    "lat": "425950N",
    "long": "0821840W"
  },
  {
    "stationcode": "YLS",
    "stationname": "Lebel-Sur-Quevillon Airport",
    "lat": "490400N",
    "long": "0770000W"
  },
  {
    "stationcode": "YGV",
    "stationname": "Havre St. Pierre Airport",
    "lat": "501655N",
    "long": "0633641W"
  },
  {
    "stationcode": "YCY",
    "stationname": "Clyde River Airport",
    "lat": "702907N",
    "long": "0683045W"
  },
  {
    "stationcode": "YND",
    "stationname": "Gatineau Airport",
    "lat": "452735N",
    "long": "0753839W"
  },
  {
    "stationcode": "YOI",
    "stationname": "Opinaca Airport",
    "lat": "521319N",
    "long": "0763645W"
  },
  {
    "stationcode": "YTM",
    "stationname": "Mont Tremblant Intl Airport",
    "lat": "462434N",
    "long": "0744648W"
  },
  {
    "stationcode": "YMO",
    "stationname": "Moosonee Airport",
    "lat": "511722N",
    "long": "0803625W"
  },
  {
    "stationcode": "YEL",
    "stationname": "Elliot Lake Airport",
    "lat": "462100N",
    "long": "0823300W"
  },
  {
    "stationcode": "YQC",
    "stationname": "Quaqtaq Airport",
    "lat": "610248N",
    "long": "0693705W"
  },
  {
    "stationcode": "YQG",
    "stationname": "Windsor Airport",
    "lat": "421614N",
    "long": "0825743W"
  },
  {
    "stationcode": "YLP",
    "stationname": "Mingan Airport",
    "lat": "501700N",
    "long": "0640900W"
  },
  {
    "stationcode": "YAH",
    "stationname": "Lagrande 4 Airport",
    "lat": "534500N",
    "long": "0733900W"
  },
  {
    "stationcode": "YQT",
    "stationname": "Thunder Bay Airport",
    "lat": "482219N",
    "long": "0891926W"
  },
  {
    "stationcode": "YNC",
    "stationname": "Wemindji Airport",
    "lat": "530038N",
    "long": "0784952W"
  },
  {
    "stationcode": "YYY",
    "stationname": "Mont Joli Airport",
    "lat": "483631N",
    "long": "0681229W"
  },
  {
    "stationcode": "YLD",
    "stationname": "Chapleau Airport",
    "lat": "475000N",
    "long": "0832400W"
  },
  {
    "stationcode": "YHM",
    "stationname": "Hamilton Airport",
    "lat": "430943N",
    "long": "0795541W"
  },
  {
    "stationcode": "YHU",
    "stationname": "St. Hubert Airport",
    "lat": "453059N",
    "long": "0732515W"
  },
  {
    "stationcode": "YAT",
    "stationname": "Attawapiskat Airport",
    "lat": "525540N",
    "long": "0822552W"
  },
  {
    "stationcode": "YBJ",
    "stationname": "Baie Johan Beetz Airport",
    "lat": "503000N",
    "long": "0625500W"
  },
  {
    "stationcode": "YMT",
    "stationname": "Chibougamau Airport",
    "lat": "494619N",
    "long": "0743141W"
  },
  {
    "stationcode": "YNA",
    "stationname": "Natashquan Airport",
    "lat": "501124N",
    "long": "0614721W"
  },
  {
    "stationcode": "YPH",
    "stationname": "Inukjuak Airport",
    "lat": "582816N",
    "long": "0780442W"
  },
  {
    "stationcode": "YPQ",
    "stationname": "Peterborough Airport",
    "lat": "441800N",
    "long": "0781900W"
  },
  {
    "stationcode": "YKZ",
    "stationname": "Buttonville Airport",
    "lat": "435200N",
    "long": "0792300W"
  },
  {
    "stationcode": "YGZ",
    "stationname": "Grise Fiord Airport",
    "lat": "762536N",
    "long": "0825445W"
  },
  {
    "stationcode": "YHN",
    "stationname": "Hornepayne Airport",
    "lat": "491134N",
    "long": "0844536W"
  },
  {
    "stationcode": "YER",
    "stationname": "Fort Severn Airport",
    "lat": "560107N",
    "long": "0874035W"
  },
  {
    "stationcode": "YMX",
    "stationname": "Mirabel International Airport",
    "lat": "454023N",
    "long": "0740201W"
  },
  {
    "stationcode": "YYW",
    "stationname": "Armstrong Airport",
    "lat": "501750N",
    "long": "0885819W"
  },
  {
    "stationcode": "YML",
    "stationname": "Charlevoix Airport",
    "lat": "473900N",
    "long": "0701000W"
  },
  {
    "stationcode": "YFA",
    "stationname": "Fort Albany Airport",
    "lat": "521213N",
    "long": "0814142W"
  },
  {
    "stationcode": "ZKE",
    "stationname": "Kaschechewan Airport",
    "lat": "521654N",
    "long": "0814041W"
  },
  {
    "stationcode": "YBC",
    "stationname": "Baie Comeau Airport",
    "lat": "490758N",
    "long": "0681150W"
  },
  {
    "stationcode": "YUL",
    "stationname": "Pierre Elliott Trudeau International Airport",
    "lat": "452814N",
    "long": "0734427W"
  },
  {
    "stationcode": "YWP",
    "stationname": "Webequie Airport",
    "lat": "525735N",
    "long": "0872228W"
  },
  {
    "stationcode": "YTJ",
    "stationname": "Terrace Bay Airport",
    "lat": "484500N",
    "long": "0871000W"
  },
  {
    "stationcode": "YVV",
    "stationname": "Wiarton Airport",
    "lat": "444500N",
    "long": "0810900W"
  },
  {
    "stationcode": "YZV",
    "stationname": "Sept-Iles Airport",
    "lat": "501324N",
    "long": "0661556W"
  },
  {
    "stationcode": "YSR",
    "stationname": "Nanisivik Airport",
    "lat": "734500N",
    "long": "0843000W"
  },
  {
    "stationcode": "YQA",
    "stationname": "Muskoka Airport",
    "lat": "445800N",
    "long": "0791800W"
  },
  {
    "stationcode": "YTF",
    "stationname": "Alma Airport",
    "lat": "483033N",
    "long": "0713837W"
  },
  {
    "stationcode": "YTK",
    "stationname": "Tulugak Airport",
    "lat": "593000N",
    "long": "0774500W"
  },
  {
    "stationcode": "YWB",
    "stationname": "Kangiqsujuaq Airport",
    "lat": "613516N",
    "long": "0715536W"
  },
  {
    "stationcode": "YMG",
    "stationname": "Manitouwadge Airport",
    "lat": "490800N",
    "long": "0854400W"
  },
  {
    "stationcode": "YEY",
    "stationname": "Amos Airport",
    "lat": "483500N",
    "long": "0780700W"
  },
  {
    "stationcode": "YOS",
    "stationname": "Billy Bishop Regional Airport",
    "lat": "443530N",
    "long": "0805020W"
  },
  {
    "stationcode": "YLF",
    "stationname": "Laforges Airport",
    "lat": "541100N",
    "long": "0722900W"
  },
  {
    "stationcode": "YBG",
    "stationname": "Bagotville Airport",
    "lat": "481956N",
    "long": "0705933W"
  },
  {
    "stationcode": "YRJ",
    "stationname": "Roberval Airport",
    "lat": "483112N",
    "long": "0721556W"
  },
  {
    "stationcode": "YUD",
    "stationname": "Umiujag Airport",
    "lat": "563208N",
    "long": "0763107W"
  },
  {
    "stationcode": "YMI",
    "stationname": "Minaki Airport",
    "lat": "500000N",
    "long": "0944000W"
  },
  {
    "stationcode": "YBK",
    "stationname": "Baker Lake Airport",
    "lat": "641800N",
    "long": "0960500W"
  },
  {
    "stationcode": "YBR",
    "stationname": "Brandon Airport",
    "lat": "495428N",
    "long": "0995703W"
  },
  {
    "stationcode": "ZPB",
    "stationname": "Sachigo Lake Airport",
    "lat": "535327N",
    "long": "0921145W"
  },
  {
    "stationcode": "YQD",
    "stationname": "The Pas Airport",
    "lat": "534920N",
    "long": "1011141W"
  },
  {
    "stationcode": "YLT",
    "stationname": "Alert Airport",
    "lat": "823100N",
    "long": "0621700W"
  },
  {
    "stationcode": "YCR",
    "stationname": "Cross Lake Airport",
    "lat": "543640N",
    "long": "0974539W"
  },
  {
    "stationcode": "ZPO",
    "stationname": "Pine House Airport",
    "lat": "553100N",
    "long": "1063600W"
  },
  {
    "stationcode": "XLB",
    "stationname": "Lac Brochet Airport",
    "lat": "583700N",
    "long": "1012801W"
  },
  {
    "stationcode": "ZNG",
    "stationname": "Negginan Airport",
    "lat": "530000N",
    "long": "0971600W"
  },
  {
    "stationcode": "YBV",
    "stationname": "Berens River Airport",
    "lat": "522133N",
    "long": "0970112W"
  },
  {
    "stationcode": "YRL",
    "stationname": "Red Lake Airport",
    "lat": "510401N",
    "long": "0934735W"
  },
  {
    "stationcode": "YLO",
    "stationname": "Shilo Airport",
    "lat": "494654N",
    "long": "0993818W"
  },
  {
    "stationcode": "YAC",
    "stationname": "Cat Lake Airport",
    "lat": "514340N",
    "long": "0914939W"
  },
  {
    "stationcode": "YGX",
    "stationname": "Gillam Airport",
    "lat": "562126N",
    "long": "0944239W"
  },
  {
    "stationcode": "YAD",
    "stationname": "Moose Lake Airport",
    "lat": "534300N",
    "long": "1002000W"
  },
  {
    "stationcode": "YTH",
    "stationname": "Thompson Airport",
    "lat": "554800N",
    "long": "0975200W"
  },
  {
    "stationcode": "ZTM",
    "stationname": "Shamattawa Airport",
    "lat": "555145N",
    "long": "0920452W"
  },
  {
    "stationcode": "XBE",
    "stationname": "Bearskin Lake Airport",
    "lat": "535753N",
    "long": "0910152W"
  },
  {
    "stationcode": "YFO",
    "stationname": "Flin Flon Airport",
    "lat": "544100N",
    "long": "1014100W"
  },
  {
    "stationcode": "YHD",
    "stationname": "Dryden Municipal Airport",
    "lat": "494954N",
    "long": "0924439W"
  },
  {
    "stationcode": "ZUC",
    "stationname": "High Prairie Airport",
    "lat": "493000N",
    "long": "0915000W"
  },
  {
    "stationcode": "YNR",
    "stationname": "Arnes Airport",
    "lat": "505000N",
    "long": "0970000W"
  },
  {
    "stationcode": "ZGR",
    "stationname": "Little Grand Rapids Airport",
    "lat": "520241N",
    "long": "0952759W"
  },
  {
    "stationcode": "YPG",
    "stationname": "Portage La Prarie Airport",
    "lat": "495900N",
    "long": "0981800W"
  },
  {
    "stationcode": "YGM",
    "stationname": "Gimli Airport",
    "lat": "503800N",
    "long": "0965900W"
  },
  {
    "stationcode": "TIL",
    "stationname": "Inverlake Airport",
    "lat": "510300N",
    "long": "1133800W"
  },
  {
    "stationcode": "YDU",
    "stationname": "Kasba Lake Airport",
    "lat": "601730N",
    "long": "1023010W"
  },
  {
    "stationcode": "YNE",
    "stationname": "Norway House Airport",
    "lat": "535730N",
    "long": "0975040W"
  },
  {
    "stationcode": "YWG",
    "stationname": "Winnipeg Airport",
    "lat": "495436N",
    "long": "0971424W"
  },
  {
    "stationcode": "ZSJ",
    "stationname": "Sandy Lake Airport",
    "lat": "530352N",
    "long": "0932047W"
  },
  {
    "stationcode": "YGO",
    "stationname": "Gods Narrows Airport",
    "lat": "543329N",
    "long": "0942925W"
  },
  {
    "stationcode": "MSA",
    "stationname": "Muskrat Dam Airport",
    "lat": "532629N",
    "long": "0914547W"
  },
  {
    "stationcode": "YYQ",
    "stationname": "Metropolitan Area Airport",
    "lat": "584412N",
    "long": "0940407W"
  },
  {
    "stationcode": "ZAC",
    "stationname": "York Landing Airport",
    "lat": "560523N",
    "long": "0960522W"
  },
  {
    "stationcode": "YCP",
    "stationname": "Co-Op Point Airport",
    "lat": "570400N",
    "long": "1020200W"
  },
  {
    "stationcode": "KES",
    "stationname": "Kelsey Airport",
    "lat": "561500N",
    "long": "0965800W"
  },
  {
    "stationcode": "PIW",
    "stationname": "Pikwitonei Airport",
    "lat": "553522N",
    "long": "0970949W"
  },
  {
    "stationcode": "KEW",
    "stationname": "Keewaywin Airport",
    "lat": "525932N",
    "long": "0925020W"
  },
  {
    "stationcode": "YIV",
    "stationname": "Island Lake/Garden Hill Airport",
    "lat": "535126N",
    "long": "0943915W"
  },
  {
    "stationcode": "ZFL",
    "stationname": "South Trout Lake Airport",
    "lat": "505000N",
    "long": "0933900W"
  },
  {
    "stationcode": "XSI",
    "stationname": "South Indian Lake Airport",
    "lat": "564735N",
    "long": "0985426W"
  },
  {
    "stationcode": "YKE",
    "stationname": "Knee Lake Airport",
    "lat": "545456N",
    "long": "0944754W"
  },
  {
    "stationcode": "YRT",
    "stationname": "Sherbrooke Airport",
    "lat": "624839N",
    "long": "0920653W"
  },
  {
    "stationcode": "YAX",
    "stationname": "Angling Lake Airport",
    "lat": "535054N",
    "long": "0893441W"
  },
  {
    "stationcode": "YBT",
    "stationname": "Brochet Airport",
    "lat": "575323N",
    "long": "1014047W"
  },
  {
    "stationcode": "YHP",
    "stationname": "Poplar Hill Airport",
    "lat": "520647N",
    "long": "0941520W"
  },
  {
    "stationcode": "YLR",
    "stationname": "Leaf Rapids Airport",
    "lat": "562700N",
    "long": "1000000W"
  },
  {
    "stationcode": "YYI",
    "stationname": "Rivers Airport",
    "lat": "500200N",
    "long": "1001200W"
  },
  {
    "stationcode": "YXN",
    "stationname": "Whale Cove Airport",
    "lat": "621423N",
    "long": "0923550W"
  },
  {
    "stationcode": "YPM",
    "stationname": "Pikangikum Airport",
    "lat": "514911N",
    "long": "0935823W"
  },
  {
    "stationcode": "YDW",
    "stationname": "Obre Lake Airport",
    "lat": "601920N",
    "long": "1030800W"
  },
  {
    "stationcode": "YRE",
    "stationname": "Resolution Island Airport",
    "lat": "613000N",
    "long": "0650000W"
  },
  {
    "stationcode": "YKI",
    "stationname": "Kennosao Lake Airport",
    "lat": "585838N",
    "long": "0235148E"
  },
  {
    "stationcode": "WNN",
    "stationname": "Wunnummin Lake Airport",
    "lat": "525341N",
    "long": "0891727W"
  },
  {
    "stationcode": "LRQ",
    "stationname": "Laurie River Airport",
    "lat": "561500N",
    "long": "1011800W"
  },
  {
    "stationcode": "YTD",
    "stationname": "Thicket Portage Airport",
    "lat": "551908N",
    "long": "0974226W"
  },
  {
    "stationcode": "YRS",
    "stationname": "Red Sucker Lake Airport",
    "lat": "541004N",
    "long": "0933325W"
  },
  {
    "stationcode": "XGL",
    "stationname": "Granville Lake Airport",
    "lat": "453912N",
    "long": "0000953E"
  },
  {
    "stationcode": "ZGI",
    "stationname": "Gods River Airport",
    "lat": "545023N",
    "long": "0940438W"
  },
  {
    "stationcode": "YOH",
    "stationname": "Oxford House Airport",
    "lat": "545602N",
    "long": "0951641W"
  },
  {
    "stationcode": "YYL",
    "stationname": "Lynn Lake Airport",
    "lat": "565151N",
    "long": "1010415W"
  },
  {
    "stationcode": "XPP",
    "stationname": "Poplar River Airport",
    "lat": "530000N",
    "long": "0972400W"
  },
  {
    "stationcode": "XKS",
    "stationname": "Kasabonika Airport",
    "lat": "533126N",
    "long": "0883838W"
  },
  {
    "stationcode": "XTL",
    "stationname": "Tadoule Lake Airport",
    "lat": "584224N",
    "long": "0983042W"
  },
  {
    "stationcode": "YCS",
    "stationname": "Chesterfield Inlet Airport",
    "lat": "632048N",
    "long": "0904344W"
  },
  {
    "stationcode": "YDV",
    "stationname": "Bloodvein Airport",
    "lat": "514500N",
    "long": "0964400W"
  },
  {
    "stationcode": "YXL",
    "stationname": "Sioux Lookout Airport",
    "lat": "500650N",
    "long": "0915419W"
  },
  {
    "stationcode": "ZJN",
    "stationname": "Swan River Airport",
    "lat": "520600N",
    "long": "1011600W"
  },
  {
    "stationcode": "YEK",
    "stationname": "Arviat Airport",
    "lat": "610539N",
    "long": "0940415W"
  },
  {
    "stationcode": "YVZ",
    "stationname": "Deer Lake Airport",
    "lat": "523919N",
    "long": "0940340W"
  },
  {
    "stationcode": "YQK",
    "stationname": "Kenora Airport",
    "lat": "494715N",
    "long": "0942130W"
  },
  {
    "stationcode": "ZRJ",
    "stationname": "Round Lake Airport",
    "lat": "525606N",
    "long": "0911808W"
  },
  {
    "stationcode": "YNO",
    "stationname": "North Spirit Lake Airport",
    "lat": "522926N",
    "long": "0925820W"
  },
  {
    "stationcode": "KIF",
    "stationname": "Kingfisher Lake Airport",
    "lat": "530045N",
    "long": "0895121W"
  },
  {
    "stationcode": "YUT",
    "stationname": "Repulse Bay Airport",
    "lat": "663117N",
    "long": "0861329W"
  },
  {
    "stationcode": "YAG",
    "stationname": "Fort Frances Municipal Airport",
    "lat": "483910N",
    "long": "0932650W"
  },
  {
    "stationcode": "YDN",
    "stationname": "Dauphin Airport",
    "lat": "510603N",
    "long": "1000309W"
  },
  {
    "stationcode": "ILF",
    "stationname": "Ilford Airport",
    "lat": "560306N",
    "long": "0953713W"
  },
  {
    "stationcode": "XPK",
    "stationname": "Pukatawagan Airport",
    "lat": "554800N",
    "long": "1012500W"
  },
  {
    "stationcode": "YEU",
    "stationname": "Eureka Airport",
    "lat": "795900N",
    "long": "0854900W"
  },
  {
    "stationcode": "YST",
    "stationname": "Ste. Therese Point Airport",
    "lat": "535045N",
    "long": "0945105W"
  },
  {
    "stationcode": "ZJG",
    "stationname": "Jenpeg Airport",
    "lat": "543109N",
    "long": "0980246W"
  },
  {
    "stationcode": "YTL",
    "stationname": "Big Trout Airport",
    "lat": "534903N",
    "long": "0895348W"
  },
  {
    "stationcode": "YNX",
    "stationname": "Snap Lake Airport",
    "lat": "633537N",
    "long": "1105419W"
  },
  {
    "stationcode": "YSY",
    "stationname": "Sachs Harbour Airport",
    "lat": "715936N",
    "long": "1251431W"
  },
  {
    "stationcode": "ZHP",
    "stationname": "High Prairie Airport",
    "lat": "552600N",
    "long": "1162900W"
  },
  {
    "stationcode": "HZP",
    "stationname": "Horizon Airport",
    "lat": "572200N",
    "long": "1114200W"
  },
  {
    "stationcode": "YVG",
    "stationname": "Vermilion Airport",
    "lat": "532200N",
    "long": "1105100W"
  },
  {
    "stationcode": "YPY",
    "stationname": "Fort Chipewyan Airport",
    "lat": "584606N",
    "long": "1110657W"
  },
  {
    "stationcode": "YYH",
    "stationname": "Taloyoak Airport",
    "lat": "693248N",
    "long": "0933436W"
  },
  {
    "stationcode": "JOJ",
    "stationname": "Doris Lake Airport",
    "lat": "680731N",
    "long": "1063506W"
  },
  {
    "stationcode": "YLL",
    "stationname": "Lloydminster Airport",
    "lat": "531833N",
    "long": "1100421W"
  },
  {
    "stationcode": "YCZ",
    "stationname": "Fairmount Springs Airport",
    "lat": "501955N",
    "long": "1155225W"
  },
  {
    "stationcode": "YOD",
    "stationname": "Cold Lake Airport",
    "lat": "542700N",
    "long": "1101000W"
  },
  {
    "stationcode": "DAS",
    "stationname": "Great Bear Lake Airport",
    "lat": "664210N",
    "long": "1194250W"
  },
  {
    "stationcode": "YXC",
    "stationname": "Cranbrook Airport",
    "lat": "493639N",
    "long": "1154656W"
  },
  {
    "stationcode": "YQL",
    "stationname": "Lethbridge Airport",
    "lat": "493747N",
    "long": "1124725W"
  },
  {
    "stationcode": "YZU",
    "stationname": "Whitecourt Airport",
    "lat": "540840N",
    "long": "1154720W"
  },
  {
    "stationcode": "YCB",
    "stationname": "Cambridge Bay Airport",
    "lat": "690600N",
    "long": "1050800W"
  },
  {
    "stationcode": "YXD",
    "stationname": "Edmonton Municipal Airport",
    "lat": "533421N",
    "long": "1133114W"
  },
  {
    "stationcode": "YBA",
    "stationname": "Banff Airport",
    "lat": "511000N",
    "long": "1153400W"
  },
  {
    "stationcode": "YEG",
    "stationname": "Edmonton International Airport",
    "lat": "531827N",
    "long": "1133510W"
  },
  {
    "stationcode": "YCK",
    "stationname": "Colville Lake Airport",
    "lat": "670223N",
    "long": "1260452W"
  },
  {
    "stationcode": "ZFN",
    "stationname": "Tulita Airport",
    "lat": "645435N",
    "long": "1253421W"
  },
  {
    "stationcode": "YOA",
    "stationname": "Ekati Airport",
    "lat": "644200N",
    "long": "1103700W"
  },
  {
    "stationcode": "YOP",
    "stationname": "Rainbow Lake Airport",
    "lat": "582929N",
    "long": "1192430W"
  },
  {
    "stationcode": "YGH",
    "stationname": "Fort Good Hope Airport",
    "lat": "661427N",
    "long": "1283903W"
  },
  {
    "stationcode": "YJA",
    "stationname": "Metropolitan Area Airport",
    "lat": "525300N",
    "long": "1180500W"
  },
  {
    "stationcode": "DVK",
    "stationname": "Diavik Airport",
    "lat": "643041N",
    "long": "1101722W"
  },
  {
    "stationcode": "YZF",
    "stationname": "Yellowknife Airport",
    "lat": "622807N",
    "long": "1142635W"
  },
  {
    "stationcode": "YDC",
    "stationname": "Industrial Airport",
    "lat": "531300N",
    "long": "1145900W"
  },
  {
    "stationcode": "YEV",
    "stationname": "Inuvik/Mike Zubko Airport",
    "lat": "681800N",
    "long": "1332900W"
  },
  {
    "stationcode": "YSG",
    "stationname": "Lutselke Airport",
    "lat": "622504N",
    "long": "1104057W"
  },
  {
    "stationcode": "YHI",
    "stationname": "Ulukhaktok Airport",
    "lat": "704404N",
    "long": "1174537W"
  },
  {
    "stationcode": "YQU",
    "stationname": "Grande Prairie Airport",
    "lat": "551103N",
    "long": "1185340W"
  },
  {
    "stationcode": "YVQ",
    "stationname": "Norman Wells Airport",
    "lat": "651654N",
    "long": "1264754W"
  },
  {
    "stationcode": "YBY",
    "stationname": "Bonnyville Airport",
    "lat": "541600N",
    "long": "1104400W"
  },
  {
    "stationcode": "YPP",
    "stationname": "Pine Point Airport",
    "lat": "605100N",
    "long": "1145100W"
  },
  {
    "stationcode": "YWO",
    "stationname": "Lupin Airport",
    "lat": "654530N",
    "long": "1111500W"
  },
  {
    "stationcode": "YXH",
    "stationname": "Medicine Hat Airport",
    "lat": "500102N",
    "long": "1104323W"
  },
  {
    "stationcode": "YHK",
    "stationname": "Gjoa Haven Airport",
    "lat": "683807N",
    "long": "0955101W"
  },
  {
    "stationcode": "YJP",
    "stationname": "Jasper-Hinton Airport",
    "lat": "531910N",
    "long": "1174510W"
  },
  {
    "stationcode": "YFI",
    "stationname": "Firebag Airport",
    "lat": "571333N",
    "long": "1105836W"
  },
  {
    "stationcode": "YNH",
    "stationname": "Hudson's Hope Airport",
    "lat": "560200N",
    "long": "1215900W"
  },
  {
    "stationcode": "YYC",
    "stationname": "Calgary International Airport",
    "lat": "510646N",
    "long": "1140111W"
  },
  {
    "stationcode": "YLB",
    "stationname": "Lac Biche Airport",
    "lat": "544524N",
    "long": "1120009W"
  },
  {
    "stationcode": "YOE",
    "stationname": "Falher Airport",
    "lat": "554400N",
    "long": "1171200W"
  },
  {
    "stationcode": "YPE",
    "stationname": "Peace River Airport",
    "lat": "561337N",
    "long": "1172650W"
  },
  {
    "stationcode": "YCT",
    "stationname": "Coronation Airport",
    "lat": "520431N",
    "long": "1112647W"
  },
  {
    "stationcode": "YCO",
    "stationname": "Kugluktuk Airport",
    "lat": "674900N",
    "long": "1150838W"
  },
  {
    "stationcode": "ZFM",
    "stationname": "Fort Mcpherson Airport",
    "lat": "672427N",
    "long": "1345138W"
  },
  {
    "stationcode": "YUF",
    "stationname": "Dewline Site Airport",
    "lat": "685300N",
    "long": "0895100W"
  },
  {
    "stationcode": "YLE",
    "stationname": "Wha Ti Airport",
    "lat": "630756N",
    "long": "1171451W"
  },
  {
    "stationcode": "YMM",
    "stationname": "Fort McMurray Airport",
    "lat": "563910N",
    "long": "1111314W"
  },
  {
    "stationcode": "YHY",
    "stationname": "Hay River Airport",
    "lat": "605023N",
    "long": "1154658W"
  },
  {
    "stationcode": "YFJ",
    "stationname": "Snare Lake Airport",
    "lat": "641126N",
    "long": "1140432W"
  },
  {
    "stationcode": "TNS",
    "stationname": "Tungsten Airport",
    "lat": "615700N",
    "long": "1281600W"
  },
  {
    "stationcode": "YJF",
    "stationname": "Fort Liard Airport",
    "lat": "601500N",
    "long": "1232800W"
  },
  {
    "stationcode": "YET",
    "stationname": "Edson Airport",
    "lat": "533500N",
    "long": "1162600W"
  },
  {
    "stationcode": "YFS",
    "stationname": "Fort Simpson Airport",
    "lat": "614500N",
    "long": "1211400W"
  },
  {
    "stationcode": "YSD",
    "stationname": "Suffield Airport",
    "lat": "501200N",
    "long": "1111000W"
  },
  {
    "stationcode": "YQF",
    "stationname": "Red Deer Airport",
    "lat": "513901N",
    "long": "1151419W"
  },
  {
    "stationcode": "YMD",
    "stationname": "Mould Bay Airport",
    "lat": "761500N",
    "long": "1193000W"
  },
  {
    "stationcode": "LAK",
    "stationname": "Aklavik Airport",
    "lat": "681324N",
    "long": "1350021W"
  },
  {
    "stationcode": "YFR",
    "stationname": "Fort Resolution Airport",
    "lat": "611050N",
    "long": "1134120W"
  },
  {
    "stationcode": "YRM",
    "stationname": "Rocky Mountain House Airport",
    "lat": "522200N",
    "long": "1145500W"
  },
  {
    "stationcode": "WPC",
    "stationname": "Pincher Creek Airport",
    "lat": "492900N",
    "long": "1135700W"
  },
  {
    "stationcode": "YYM",
    "stationname": "Cowley Airport",
    "lat": "493400N",
    "long": "1140500W"
  },
  {
    "stationcode": "YWY",
    "stationname": "Wrigley Airport",
    "lat": "631200N",
    "long": "1232500W"
  },
  {
    "stationcode": "YBB",
    "stationname": "Townsite Airport",
    "lat": "683208N",
    "long": "0894818W"
  },
  {
    "stationcode": "YSM",
    "stationname": "Fort Smith Airport",
    "lat": "600100N",
    "long": "1115800W"
  },
  {
    "stationcode": "JHL",
    "stationname": "Albian Aerodrome",
    "lat": "571340N",
    "long": "1112510W"
  },
  {
    "stationcode": "GSL",
    "stationname": "Taltheilei Narrows Airport",
    "lat": "623550N",
    "long": "1113240W"
  },
  {
    "stationcode": "YGC",
    "stationname": "Grande Cache Airport",
    "lat": "535300N",
    "long": "1190800W"
  },
  {
    "stationcode": "YUB",
    "stationname": "Tuktoyaktuk Airport",
    "lat": "692600N",
    "long": "1330135W"
  },
  {
    "stationcode": "ZFW",
    "stationname": "Fairview Airport",
    "lat": "560400N",
    "long": "1182300W"
  },
  {
    "stationcode": "YPC",
    "stationname": "Paulatuk Airport",
    "lat": "692134N",
    "long": "1240439W"
  },
  {
    "stationcode": "YZH",
    "stationname": "Slave Lake Airport",
    "lat": "551700N",
    "long": "1144600W"
  },
  {
    "stationcode": "NML",
    "stationname": "Mildred Lake Airport",
    "lat": "570333N",
    "long": "1113443W"
  },
  {
    "stationcode": "UZM",
    "stationname": "Hope Bay Airport",
    "lat": "680950N",
    "long": "1063651W"
  },
  {
    "stationcode": "YED",
    "stationname": "Namao Field",
    "lat": "531910N",
    "long": "1133600W"
  },
  {
    "stationcode": "YOJ",
    "stationname": "Footner Lake Airport",
    "lat": "583706N",
    "long": "1171006W"
  },
  {
    "stationcode": "YRA",
    "stationname": "Rae Lakes Airport",
    "lat": "640657N",
    "long": "1171833W"
  },
  {
    "stationcode": "YWJ",
    "stationname": "Deline Airport",
    "lat": "651240N",
    "long": "1232607W"
  },
  {
    "stationcode": "YGS",
    "stationname": "Germansen Airport",
    "lat": "554700N",
    "long": "1244300W"
  },
  {
    "stationcode": "YCG",
    "stationname": "Castlegar Airport",
    "lat": "491747N",
    "long": "1173757W"
  },
  {
    "stationcode": "YPZ",
    "stationname": "Burns Lake Airport",
    "lat": "542236N",
    "long": "1255704W"
  },
  {
    "stationcode": "YYE",
    "stationname": "Fort Nelson Airport",
    "lat": "585016N",
    "long": "1223552W"
  },
  {
    "stationcode": "YLM",
    "stationname": "Clinton Creek Airport",
    "lat": "642800N",
    "long": "1404400W"
  },
  {
    "stationcode": "YAV",
    "stationname": "Miner's Bay Airport",
    "lat": "485109N",
    "long": "1231806W"
  },
  {
    "stationcode": "YBF",
    "stationname": "Bamfield Airport",
    "lat": "485000N",
    "long": "1250800W"
  },
  {
    "stationcode": "YMP",
    "stationname": "Port McNeil Airport",
    "lat": "503420N",
    "long": "1270133W"
  },
  {
    "stationcode": "YKK",
    "stationname": "Kitkatla Airport",
    "lat": "534742N",
    "long": "1302622W"
  },
  {
    "stationcode": "DGF",
    "stationname": "Douglas Lake Airport",
    "lat": "501000N",
    "long": "1201100W"
  },
  {
    "stationcode": "ZFA",
    "stationname": "Faro Airport",
    "lat": "621000N",
    "long": "1334000W"
  },
  {
    "stationcode": "YPT",
    "stationname": "Pender Harbor Airport",
    "lat": "493722N",
    "long": "1240132W"
  },
  {
    "stationcode": "YPW",
    "stationname": "Powell River Airport",
    "lat": "495003N",
    "long": "1243000W"
  },
  {
    "stationcode": "YXQ",
    "stationname": "Beaver Creek Airport",
    "lat": "622200N",
    "long": "1405200W"
  },
  {
    "stationcode": "YYF",
    "stationname": "Penticton Airport",
    "lat": "492747N",
    "long": "1193608W"
  },
  {
    "stationcode": "YWH",
    "stationname": "Inner Harbour Airport",
    "lat": "482526N",
    "long": "1232223W"
  },
  {
    "stationcode": "YQQ",
    "stationname": "Comox Airport",
    "lat": "494239N",
    "long": "1245312W"
  },
  {
    "stationcode": "XBB",
    "stationname": "Blubber Bay Airport",
    "lat": "494800N",
    "long": "1243800W"
  },
  {
    "stationcode": "YDS",
    "stationname": "Desolation Sound Airport",
    "lat": "502000N",
    "long": "1250000W"
  },
  {
    "stationcode": "YQE",
    "stationname": "Kimberley Airport",
    "lat": "494000N",
    "long": "1155900W"
  },
  {
    "stationcode": "YQH",
    "stationname": "Watson Lake Airport",
    "lat": "600701N",
    "long": "1284930W"
  },
  {
    "stationcode": "YYD",
    "stationname": "Smithers Airport",
    "lat": "544929N",
    "long": "1271058W"
  },
  {
    "stationcode": "YLA",
    "stationname": "Langara Airport",
    "lat": "541400N",
    "long": "1330100W"
  },
  {
    "stationcode": "YAQ",
    "stationname": "Maple Bay Airport",
    "lat": "484745N",
    "long": "1233601W"
  },
  {
    "stationcode": "CJH",
    "stationname": "Chilko Lake Airport",
    "lat": "512000N",
    "long": "1240500W"
  },
  {
    "stationcode": "YAE",
    "stationname": "Alta Lake Airport",
    "lat": "501000N",
    "long": "1230000W"
  },
  {
    "stationcode": "YBD",
    "stationname": "New Westminister Airport",
    "lat": "491300N",
    "long": "1225400W"
  },
  {
    "stationcode": "YNK",
    "stationname": "Nootka Sound Airport",
    "lat": "500500N",
    "long": "1255500W"
  },
  {
    "stationcode": "YOC",
    "stationname": "Old Crow Airport",
    "lat": "673414N",
    "long": "1395021W"
  },
  {
    "stationcode": "SYF",
    "stationname": "Silva Bay Airport",
    "lat": "490900N",
    "long": "1234150W"
  },
  {
    "stationcode": "YVR",
    "stationname": "Vancouver International Airport",
    "lat": "491114N",
    "long": "1231107W"
  },
  {
    "stationcode": "YTI",
    "stationname": "Triple Island Airport",
    "lat": "462832N",
    "long": "0143337E"
  },
  {
    "stationcode": "ZOF",
    "stationname": "Ocean Falls Airport",
    "lat": "522111N",
    "long": "1274153W"
  },
  {
    "stationcode": "YHT",
    "stationname": "Haines Junction Airport",
    "lat": "604500N",
    "long": "1373000W"
  },
  {
    "stationcode": "XKO",
    "stationname": "Kemano Airport",
    "lat": "533400N",
    "long": "1275600W"
  },
  {
    "stationcode": "YRD",
    "stationname": "Dean River Airport",
    "lat": "524900N",
    "long": "1265800W"
  },
  {
    "stationcode": "ZST",
    "stationname": "Stewart Airport",
    "lat": "555700N",
    "long": "1295900W"
  },
  {
    "stationcode": "YKA",
    "stationname": "Kamloops Airport",
    "lat": "504157N",
    "long": "1202618W"
  },
  {
    "stationcode": "YCA",
    "stationname": "Courtenay Airport",
    "lat": "494045N",
    "long": "1245852W"
  },
  {
    "stationcode": "YDB",
    "stationname": "Burwash Landings Airport",
    "lat": "612100N",
    "long": "1390000W"
  },
  {
    "stationcode": "ZQS",
    "stationname": "Queen Charlotte Island Airport",
    "lat": "531509N",
    "long": "1320427W"
  },
  {
    "stationcode": "YRN",
    "stationname": "Rivers Inlet Airport",
    "lat": "512800N",
    "long": "1273500W"
  },
  {
    "stationcode": "YPI",
    "stationname": "Port Simpson Airport",
    "lat": "543300N",
    "long": "1302500W"
  },
  {
    "stationcode": "YGN",
    "stationname": "Greenway Sound Airport",
    "lat": "505100N",
    "long": "1265100W"
  },
  {
    "stationcode": "YCJ",
    "stationname": "Cape Saint James Airport",
    "lat": "515600N",
    "long": "1310100W"
  },
  {
    "stationcode": "YTC",
    "stationname": "Sturdee Airport",
    "lat": "571200N",
    "long": "1270900W"
  },
  {
    "stationcode": "YXY",
    "stationname": "Whitehorse Airport",
    "lat": "604234N",
    "long": "1350402W"
  },
  {
    "stationcode": "YHC",
    "stationname": "Hakai Pass Airport",
    "lat": "514400N",
    "long": "1280700W"
  },
  {
    "stationcode": "ZAA",
    "stationname": "Alice Arm Airport",
    "lat": "552900N",
    "long": "1292900W"
  },
  {
    "stationcode": "ZSW",
    "stationname": "Seal Cove Airport",
    "lat": "541710N",
    "long": "1302700W"
  },
  {
    "stationcode": "YTX",
    "stationname": "Telegraph Creek Airport",
    "lat": "575500N",
    "long": "1311000W"
  },
  {
    "stationcode": "YYJ",
    "stationname": "Victoria International Airport",
    "lat": "483855N",
    "long": "1232525W"
  },
  {
    "stationcode": "YCD",
    "stationname": "Nanaimo Airport",
    "lat": "490308N",
    "long": "1235213W"
  },
  {
    "stationcode": "ZGF",
    "stationname": "Grand Forks Airport",
    "lat": "490200N",
    "long": "1182800W"
  },
  {
    "stationcode": "YPR",
    "stationname": "Digby Island Airport",
    "lat": "541714N",
    "long": "1302627W"
  },
  {
    "stationcode": "YBL",
    "stationname": "Campbell River Airport",
    "lat": "495703N",
    "long": "1251614W"
  },
  {
    "stationcode": "YBQ",
    "stationname": "Telegraph Harbour Airport",
    "lat": "485900N",
    "long": "1233900W"
  },
  {
    "stationcode": "XMP",
    "stationname": "Macmillan Pass Airport",
    "lat": "625200N",
    "long": "1355500W"
  },
  {
    "stationcode": "YXX",
    "stationname": "Abbotsford Airport",
    "lat": "490132N",
    "long": "1222143W"
  },
  {
    "stationcode": "YBW",
    "stationname": "Bedwell Harbor Airport",
    "lat": "484449N",
    "long": "1231342W"
  },
  {
    "stationcode": "YTU",
    "stationname": "Tasu Airport",
    "lat": "523500N",
    "long": "1350500W"
  },
  {
    "stationcode": "IUM",
    "stationname": "Summit Lake Airport",
    "lat": "542000N",
    "long": "1224000W"
  },
  {
    "stationcode": "ZNU",
    "stationname": "Namu Airport",
    "lat": "515100N",
    "long": "1275200W"
  },
  {
    "stationcode": "YHS",
    "stationname": "Sechelt Airport",
    "lat": "492800N",
    "long": "1234500W"
  },
  {
    "stationcode": "YBM",
    "stationname": "Bronson Creek Airport",
    "lat": "564200N",
    "long": "1310000W"
  },
  {
    "stationcode": "YGG",
    "stationname": "Ganges Harbor Airport",
    "lat": "485106N",
    "long": "1232952W"
  },
  {
    "stationcode": "YSN",
    "stationname": "Salmon Arm Airport",
    "lat": "504100N",
    "long": "1191400W"
  },
  {
    "stationcode": "YCF",
    "stationname": "Cortes Bay Airport",
    "lat": "500400N",
    "long": "1245600W"
  },
  {
    "stationcode": "YDA",
    "stationname": "Dawson City Airport",
    "lat": "640235N",
    "long": "1390740W"
  },
  {
    "stationcode": "YBO",
    "stationname": "Bobquinn Lake Airport",
    "lat": "565800N",
    "long": "1301500W"
  },
  {
    "stationcode": "DCP",
    "stationname": "Cabin Plant Airport",
    "lat": "591401N",
    "long": "1214129W"
  },
  {
    "stationcode": "YSZ",
    "stationname": "Squirrel Cove Airport",
    "lat": "500300N",
    "long": "1245500W"
  },
  {
    "stationcode": "YZA",
    "stationname": "Ashcroft Airport",
    "lat": "504316N",
    "long": "1211647W"
  },
  {
    "stationcode": "YBH",
    "stationname": "Bull Harbour Airport",
    "lat": "505400N",
    "long": "1275500W"
  },
  {
    "stationcode": "YGB",
    "stationname": "Gillies Bay Airport",
    "lat": "494139N",
    "long": "1243104W"
  },
  {
    "stationcode": "YWL",
    "stationname": "Williams Lake Airport",
    "lat": "521105N",
    "long": "1220240W"
  },
  {
    "stationcode": "YZT",
    "stationname": "Port Hardy Airport",
    "lat": "504050N",
    "long": "1272150W"
  },
  {
    "stationcode": "YJO",
    "stationname": "Johnny Mountain Airport",
    "lat": "564000N",
    "long": "1310700W"
  },
  {
    "stationcode": "YQZ",
    "stationname": "Quesnel Airport",
    "lat": "530134N",
    "long": "1223037W"
  },
  {
    "stationcode": "YDT",
    "stationname": "Boundary Bay Airport",
    "lat": "490422N",
    "long": "1230048W"
  },
  {
    "stationcode": "CFQ",
    "stationname": "Creston Airport",
    "lat": "490213N",
    "long": "1162954W"
  },
  {
    "stationcode": "YZP",
    "stationname": "Sandspit Airport",
    "lat": "531516N",
    "long": "1314850W"
  },
  {
    "stationcode": "YKT",
    "stationname": "Klemtu Airport",
    "lat": "523535N",
    "long": "1283116W"
  },
  {
    "stationcode": "YXF",
    "stationname": "Snake River Airport",
    "lat": "590200N",
    "long": "1222600W"
  },
  {
    "stationcode": "YPF",
    "stationname": "Esquimalt Airport",
    "lat": "482600N",
    "long": "1232400W"
  },
  {
    "stationcode": "YTB",
    "stationname": "Hartley Bay Airport",
    "lat": "532500N",
    "long": "1291500W"
  },
  {
    "stationcode": "YZC",
    "stationname": "Beatton River Airport",
    "lat": "572600N",
    "long": "1212000W"
  },
  {
    "stationcode": "YMA",
    "stationname": "Mayo Airport",
    "lat": "633700N",
    "long": "1355200W"
  },
  {
    "stationcode": "YAA",
    "stationname": "Anahim Lake Airport",
    "lat": "522719N",
    "long": "1251829W"
  },
  {
    "stationcode": "DUQ",
    "stationname": "Duncan/Quam Airport",
    "lat": "484517N",
    "long": "1234235W"
  },
  {
    "stationcode": "YFL",
    "stationname": "Fort Reliance Airport",
    "lat": "624243N",
    "long": "1090946W"
  },
  {
    "stationcode": "YZW",
    "stationname": "Teslin Airport",
    "lat": "600900N",
    "long": "1324500W"
  },
  {
    "stationcode": "YXT",
    "stationname": "Terrace Airport",
    "lat": "542756N",
    "long": "1283428W"
  },
  {
    "stationcode": "YMF",
    "stationname": "Montagne Harbor Airport",
    "lat": "484900N",
    "long": "1231223W"
  },
  {
    "stationcode": "YCW",
    "stationname": "Chilliwack Airport",
    "lat": "491000N",
    "long": "1215700W"
  },
  {
    "stationcode": "YEP",
    "stationname": "Estevan Point Airport",
    "lat": "492300N",
    "long": "1263200W"
  },
  {
    "stationcode": "YZZ",
    "stationname": "Trail",
    "lat": "490320N",
    "long": "1173633W"
  },
  {
    "stationcode": "WPL",
    "stationname": "Powell Lake Airport",
    "lat": "501100N",
    "long": "1242400W"
  },
  {
    "stationcode": "ZMT",
    "stationname": "Masset Airport",
    "lat": "540139N",
    "long": "1320731W"
  },
  {
    "stationcode": "YLY",
    "stationname": "Langley Regional Airport",
    "lat": "501441N",
    "long": "1213406W"
  },
  {
    "stationcode": "YMB",
    "stationname": "Merritt Airport",
    "lat": "500700N",
    "long": "1204700W"
  },
  {
    "stationcode": "ZEL",
    "stationname": "Bella Bella Airport",
    "lat": "521105N",
    "long": "1280924W"
  },
  {
    "stationcode": "YRR",
    "stationname": "Stuart Island Airport",
    "lat": "502434N",
    "long": "1250752W"
  },
  {
    "stationcode": "ZTS",
    "stationname": "Tahsis Airport",
    "lat": "495500N",
    "long": "1263900W"
  },
  {
    "stationcode": "YLW",
    "stationname": "Kelowna Airport",
    "lat": "495722N",
    "long": "1192240W"
  },
  {
    "stationcode": "YIG",
    "stationname": "Big Bay Marina Airport",
    "lat": "502435N",
    "long": "1250753W"
  },
  {
    "stationcode": "YPB",
    "stationname": "Port Alberni Airport",
    "lat": "491920N",
    "long": "1245553W"
  },
  {
    "stationcode": "YTG",
    "stationname": "Sullivan Bay Airport",
    "lat": "505302N",
    "long": "1264942W"
  },
  {
    "stationcode": "YSE",
    "stationname": "Squamish Airport",
    "lat": "494200N",
    "long": "1230900W"
  },
  {
    "stationcode": "YYA",
    "stationname": "Big Bay Yacht Club Airport",
    "lat": "502400N",
    "long": "1230800W"
  },
  {
    "stationcode": "YAL",
    "stationname": "Alert Bay Airport",
    "lat": "503500N",
    "long": "1265600W"
  },
  {
    "stationcode": "YGE",
    "stationname": "Gorge Harbor Airport",
    "lat": "500547N",
    "long": "1245857W"
  },
  {
    "stationcode": "YHE",
    "stationname": "Hope Airport",
    "lat": "492300N",
    "long": "1212600W"
  },
  {
    "stationcode": "YAJ",
    "stationname": "Lyall Harbour Airport",
    "lat": "484753N",
    "long": "1231205W"
  },
  {
    "stationcode": "YSQ",
    "stationname": "Spring Island Airport",
    "lat": "445618N",
    "long": "1243301E"
  },
  {
    "stationcode": "YRV",
    "stationname": "Revelstoke Airport",
    "lat": "505900N",
    "long": "1181200W"
  },
  {
    "stationcode": "YVE",
    "stationname": "Vernon Airport",
    "lat": "501500N",
    "long": "1192000W"
  },
  {
    "stationcode": "YSX",
    "stationname": "Shearwater Airport",
    "lat": "520800N",
    "long": "1280400W"
  },
  {
    "stationcode": "TUX",
    "stationname": "Tumbler Ridge Airport",
    "lat": "551200N",
    "long": "1213300W"
  },
  {
    "stationcode": "YDX",
    "stationname": "Doc Creek Airport",
    "lat": "520300N",
    "long": "1280500W"
  },
  {
    "stationcode": "YMR",
    "stationname": "Merry Island Airport",
    "lat": "552900N",
    "long": "0773100W"
  },
  {
    "stationcode": "QBC",
    "stationname": "Bella Coola Airport",
    "lat": "522316N",
    "long": "1263542W"
  },
  {
    "stationcode": "YWS",
    "stationname": "Whistler Airport",
    "lat": "500900N",
    "long": "1225800W"
  },
  {
    "stationcode": "YDL",
    "stationname": "Dease Lake Airport",
    "lat": "582523N",
    "long": "1300150W"
  },
  {
    "stationcode": "ZMH",
    "stationname": "108 Mile Ranch Airport",
    "lat": "514200N",
    "long": "1212500W"
  },
  {
    "stationcode": "KNV",
    "stationname": "Knights Inlet Airport",
    "lat": "504100N",
    "long": "1255000W"
  },
  {
    "stationcode": "YAZ",
    "stationname": "Tofino Airport",
    "lat": "490437N",
    "long": "1254635W"
  },
  {
    "stationcode": "ZNA",
    "stationname": "Harbour Airport",
    "lat": "491005N",
    "long": "1235551W"
  },
  {
    "stationcode": "XQU",
    "stationname": "Qualicum Airport",
    "lat": "492015N",
    "long": "1242339W"
  },
  {
    "stationcode": "XRR",
    "stationname": "Ross River Airport",
    "lat": "615900N",
    "long": "1322700W"
  },
  {
    "stationcode": "YXS",
    "stationname": "Prince George Airport",
    "lat": "535255N",
    "long": "1224033W"
  },
  {
    "stationcode": "BDT",
    "stationname": "Gbadolite Airport",
    "lat": "041517N",
    "long": "0205819E"
  },
  {
    "stationcode": "IKL",
    "stationname": "Ikela Airport",
    "lat": "011200S",
    "long": "0231800E"
  },
  {
    "stationcode": "LIQ",
    "stationname": "Lisala Airport",
    "lat": "021016N",
    "long": "0212949E"
  },
  {
    "stationcode": "IDF",
    "stationname": "Idiofa Airport",
    "lat": "050000S",
    "long": "0193030E"
  },
  {
    "stationcode": "INO",
    "stationname": "Inongo Airport",
    "lat": "015649S",
    "long": "0181708E"
  },
  {
    "stationcode": "KKW",
    "stationname": "Kikwit Airport",
    "lat": "050209S",
    "long": "0184709E"
  },
  {
    "stationcode": "KRZ",
    "stationname": "Kiri Airport",
    "lat": "012700S",
    "long": "0190000E"
  },
  {
    "stationcode": "BOA",
    "stationname": "Boma Airport",
    "lat": "055200S",
    "long": "0130400E"
  },
  {
    "stationcode": "GMA",
    "stationname": "Gemena Airport",
    "lat": "031407N",
    "long": "0194617E"
  },
  {
    "stationcode": "FDU",
    "stationname": "Bandundu Airport",
    "lat": "031900S",
    "long": "0172200E"
  },
  {
    "stationcode": "NIO",
    "stationname": "Nioki Airport",
    "lat": "024300S",
    "long": "0174100E"
  },
  {
    "stationcode": "KIL",
    "stationname": "Kilwa Airport",
    "lat": "091800S",
    "long": "0282300E"
  },
  {
    "stationcode": "LZA",
    "stationname": "Luiza Airport",
    "lat": "071100S",
    "long": "0222100E"
  },
  {
    "stationcode": "NLO",
    "stationname": "N'Dolo Airport",
    "lat": "041900S",
    "long": "0151900E"
  },
  {
    "stationcode": "MNB",
    "stationname": "Moanda Airport",
    "lat": "055600S",
    "long": "0122100E"
  },
  {
    "stationcode": "LIE",
    "stationname": "Libenge Airport",
    "lat": "033800N",
    "long": "0183800E"
  },
  {
    "stationcode": "MDK",
    "stationname": "Mbandaka Airport",
    "lat": "000121N",
    "long": "0181719E"
  },
  {
    "stationcode": "BNB",
    "stationname": "Boende Airport",
    "lat": "001718S",
    "long": "0205250E"
  },
  {
    "stationcode": "LUS",
    "stationname": "Lusanga Airport",
    "lat": "045000S",
    "long": "0184400E"
  },
  {
    "stationcode": "GDJ",
    "stationname": "Gandajika Airport",
    "lat": "064400S",
    "long": "0235700E"
  },
  {
    "stationcode": "MAT",
    "stationname": "Matadi Airport",
    "lat": "054800S",
    "long": "0132600E"
  },
  {
    "stationcode": "KLI",
    "stationname": "Kota Koli Airport",
    "lat": "041000S",
    "long": "0214500E"
  },
  {
    "stationcode": "KGN",
    "stationname": "Kasongo Lunda Airport",
    "lat": "062806S",
    "long": "0164821E"
  },
  {
    "stationcode": "BSU",
    "stationname": "Basankusu Airport",
    "lat": "011330N",
    "long": "0194719E"
  },
  {
    "stationcode": "LZI",
    "stationname": "Luozi Airport",
    "lat": "045700S",
    "long": "0140800E"
  },
  {
    "stationcode": "MSM",
    "stationname": "Masi Manimba Airport",
    "lat": "045000S",
    "long": "0175100E"
  },
  {
    "stationcode": "KBO",
    "stationname": "Kabalo Airport",
    "lat": "060500S",
    "long": "0265500E"
  },
  {
    "stationcode": "KMN",
    "stationname": "Kamina Airport",
    "lat": "083828S",
    "long": "0251500E"
  },
  {
    "stationcode": "KNM",
    "stationname": "Kaniama Airport",
    "lat": "073500S",
    "long": "0240900E"
  },
  {
    "stationcode": "PFR",
    "stationname": "Ilebo Airport",
    "lat": "041900S",
    "long": "0203400E"
  },
  {
    "stationcode": "MNO",
    "stationname": "Manono Airport",
    "lat": "071721S",
    "long": "0272338E"
  },
  {
    "stationcode": "FKI",
    "stationname": "Kisangani Airport",
    "lat": "002854N",
    "long": "0252017E"
  },
  {
    "stationcode": "MJM",
    "stationname": "Mbuji Mayi Airport",
    "lat": "060716S",
    "long": "0233408E"
  },
  {
    "stationcode": "MEW",
    "stationname": "Mweka Airport",
    "lat": "045100S",
    "long": "0213300E"
  },
  {
    "stationcode": "KLY",
    "stationname": "Kalima Airport",
    "lat": "023500S",
    "long": "0264400E"
  },
  {
    "stationcode": "TSH",
    "stationname": "Tshikapa Airport",
    "lat": "062630S",
    "long": "0204747E"
  },
  {
    "stationcode": "YAN",
    "stationname": "Yangambi Airport",
    "lat": "004800N",
    "long": "0242700E"
  },
  {
    "stationcode": "BDV",
    "stationname": "Moba Airport",
    "lat": "070500S",
    "long": "0294400E"
  },
  {
    "stationcode": "IRP",
    "stationname": "Matari",
    "lat": "024943N",
    "long": "0273515E"
  },
  {
    "stationcode": "KWZ",
    "stationname": "Kolwezi Airport",
    "lat": "104557S",
    "long": "0253021E"
  },
  {
    "stationcode": "BNC",
    "stationname": "Beni Airport",
    "lat": "003440N",
    "long": "0292808E"
  },
  {
    "stationcode": "BZU",
    "stationname": "Buta Airport",
    "lat": "024900N",
    "long": "0244500E"
  },
  {
    "stationcode": "GOM",
    "stationname": "Goma Airport",
    "lat": "014015S",
    "long": "0291418E"
  },
  {
    "stationcode": "KBN",
    "stationname": "Kabinda Airport",
    "lat": "060700S",
    "long": "0243200E"
  },
  {
    "stationcode": "KOO",
    "stationname": "Kongolo Airport",
    "lat": "052400S",
    "long": "0270000E"
  },
  {
    "stationcode": "BKY",
    "stationname": "Kamenbe",
    "lat": "021800S",
    "long": "0284900E"
  },
  {
    "stationcode": "BAN",
    "stationname": "Basongo Airport",
    "lat": "041000S",
    "long": "0202000E"
  },
  {
    "stationcode": "KEC",
    "stationname": "Kasenga Airport",
    "lat": "102100S",
    "long": "0283800E"
  },
  {
    "stationcode": "PWO",
    "stationname": "Pweto Airport",
    "lat": "082800S",
    "long": "0285300E"
  },
  {
    "stationcode": "KND",
    "stationname": "Kindu Airport",
    "lat": "025509S",
    "long": "0255455E"
  },
  {
    "stationcode": "BMB",
    "stationname": "Bumba Airport",
    "lat": "021102N",
    "long": "0222845E"
  },
  {
    "stationcode": "BUX",
    "stationname": "Bunia Airport",
    "lat": "013357N",
    "long": "0301315E"
  },
  {
    "stationcode": "LBO",
    "stationname": "Lusambo Airport",
    "lat": "045743S",
    "long": "0232243E"
  },
  {
    "stationcode": "KGA",
    "stationname": "Kananga Airport",
    "lat": "055400S",
    "long": "0222809E"
  },
  {
    "stationcode": "PUN",
    "stationname": "Punia Airport",
    "lat": "012200S",
    "long": "0262000E"
  },
  {
    "stationcode": "KAP",
    "stationname": "Kapanga Airport",
    "lat": "082000S",
    "long": "0224000E"
  },
  {
    "stationcode": "RUE",
    "stationname": "Rughenda Airport",
    "lat": "000800N",
    "long": "0291600E"
  },
  {
    "stationcode": "NKL",
    "stationname": "Nkolo Airport",
    "lat": "052515S",
    "long": "0144903E"
  },
  {
    "stationcode": "DIC",
    "stationname": "Dili Airport",
    "lat": "032400N",
    "long": "0264700E"
  },
  {
    "stationcode": "LJA",
    "stationname": "Lodja Airport",
    "lat": "032746S",
    "long": "0233706E"
  },
  {
    "stationcode": "FMI",
    "stationname": "Kalemie Airport",
    "lat": "055240S",
    "long": "0291446E"
  },
  {
    "stationcode": "YAI",
    "stationname": "Chillan Airport",
    "lat": "363400S",
    "long": "0720200W"
  },
  {
    "stationcode": "KNA",
    "stationname": "Vina del Mar Airport",
    "lat": "325659S",
    "long": "0712844W"
  },
  {
    "stationcode": "WPU",
    "stationname": "Puerto Williams Airport",
    "lat": "545552S",
    "long": "0673736W"
  },
  {
    "stationcode": "OVL",
    "stationname": "Ovalle Airport",
    "lat": "302400S",
    "long": "0711200W"
  },
  {
    "stationcode": "PNT",
    "stationname": "Teniente J. Gallardo Airport",
    "lat": "514016S",
    "long": "0723142W"
  },
  {
    "stationcode": "ZIC",
    "stationname": "Victoria Airport",
    "lat": "381300S",
    "long": "0722000W"
  },
  {
    "stationcode": "PUQ",
    "stationname": "Pres Ibanez Airport",
    "lat": "530010S",
    "long": "0705117W"
  },
  {
    "stationcode": "WPR",
    "stationname": "Porvenir Airport",
    "lat": "531513S",
    "long": "0701916W"
  },
  {
    "stationcode": "CNR",
    "stationname": "Chanaral Airport",
    "lat": "261900S",
    "long": "0703700W"
  },
  {
    "stationcode": "CCP",
    "stationname": "Carriel Sur Airport",
    "lat": "364622S",
    "long": "0730347W"
  },
  {
    "stationcode": "IQQ",
    "stationname": "Cavancha Airport",
    "lat": "203207S",
    "long": "0701053W"
  },
  {
    "stationcode": "WCH",
    "stationname": "Chaiten Airport",
    "lat": "424655S",
    "long": "0725006W"
  },
  {
    "stationcode": "ZUD",
    "stationname": "Ancud Airport",
    "lat": "415400S",
    "long": "0734800W"
  },
  {
    "stationcode": "PMC",
    "stationname": "Tepual Airport",
    "lat": "412600S",
    "long": "0730600W"
  },
  {
    "stationcode": "LSQ",
    "stationname": "Los Angeles Airport",
    "lat": "372500S",
    "long": "0722100W"
  },
  {
    "stationcode": "GXQ",
    "stationname": "Ten. Vidal Airport",
    "lat": "453000S",
    "long": "0723100W"
  },
  {
    "stationcode": "ZOS",
    "stationname": "Canal Balo Airport",
    "lat": "403641S",
    "long": "0730338W"
  },
  {
    "stationcode": "LSC",
    "stationname": "La Florida Airport",
    "lat": "295459S",
    "long": "0711158W"
  },
  {
    "stationcode": "WPA",
    "stationname": "Puerto Aisen Airport",
    "lat": "452500S",
    "long": "0724200W"
  },
  {
    "stationcode": "ARI",
    "stationname": "Chacalluta Airport",
    "lat": "182055S",
    "long": "0702019W"
  },
  {
    "stationcode": "ZPC",
    "stationname": "Pucon Airport",
    "lat": "391729S",
    "long": "0715515W"
  },
  {
    "stationcode": "TTC",
    "stationname": "Taltal Airport",
    "lat": "253400S",
    "long": "0702300W"
  },
  {
    "stationcode": "ESR",
    "stationname": "El Salvador Airport",
    "lat": "261830S",
    "long": "0694506W"
  },
  {
    "stationcode": "CCH",
    "stationname": "Chile Chico Airport",
    "lat": "463300S",
    "long": "0714200W"
  },
  {
    "stationcode": "WCA",
    "stationname": "Gamboa Airport",
    "lat": "423000S",
    "long": "0734500W"
  },
  {
    "stationcode": "VLR",
    "stationname": "Vallenar Airport",
    "lat": "283600S",
    "long": "0704600W"
  },
  {
    "stationcode": "ANF",
    "stationname": "Cerro Moreno Airport",
    "lat": "232622S",
    "long": "0702634W"
  },
  {
    "stationcode": "SCL",
    "stationname": "Arturo Merino Benitez Airport",
    "lat": "332300S",
    "long": "0704700W"
  },
  {
    "stationcode": "ULC",
    "stationname": "Los Cerrillos Airport",
    "lat": "332923S",
    "long": "0704141W"
  },
  {
    "stationcode": "WAP",
    "stationname": "Alto Palena Airport",
    "lat": "433800S",
    "long": "0714900W"
  },
  {
    "stationcode": "FRT",
    "stationname": "Frutillar Airport",
    "lat": "410730S",
    "long": "0730355W"
  },
  {
    "stationcode": "PUX",
    "stationname": "Puerto Varas Airport",
    "lat": "412058S",
    "long": "0725649W"
  },
  {
    "stationcode": "TLX",
    "stationname": "Talca Airport",
    "lat": "352600S",
    "long": "0714000W"
  },
  {
    "stationcode": "LOB",
    "stationname": "Los Andes Airport",
    "lat": "295800S",
    "long": "0713200W"
  },
  {
    "stationcode": "LGR",
    "stationname": "Cochrane Airport",
    "lat": "471438S",
    "long": "0723519W"
  },
  {
    "stationcode": "COW",
    "stationname": "Coquimbo Airport",
    "lat": "301157S",
    "long": "0711357W"
  },
  {
    "stationcode": "PZS",
    "stationname": "Maquehue Airport",
    "lat": "384601S",
    "long": "0723814W"
  },
  {
    "stationcode": "CPO",
    "stationname": "Desierto De Atacama Airport",
    "lat": "271542S",
    "long": "0704645W"
  },
  {
    "stationcode": "ZCO",
    "stationname": "Temuco Airport",
    "lat": "385537S",
    "long": "0723849W"
  },
  {
    "stationcode": "TOQ",
    "stationname": "Barriles Airport",
    "lat": "220900S",
    "long": "0700600W"
  },
  {
    "stationcode": "CJC",
    "stationname": "El Loa Airport",
    "lat": "222953S",
    "long": "0685429W"
  },
  {
    "stationcode": "ZAL",
    "stationname": "Pichoy Airport",
    "lat": "393858S",
    "long": "0730511W"
  },
  {
    "stationcode": "VAP",
    "stationname": "Valparaiso Airport",
    "lat": "330300S",
    "long": "0712500W"
  },
  {
    "stationcode": "FFU",
    "stationname": "Futaleufu Airport",
    "lat": "431200S",
    "long": "0714900W"
  },
  {
    "stationcode": "SMB",
    "stationname": "Cerro Sombrero Airport",
    "lat": "524000S",
    "long": "0691600W"
  },
  {
    "stationcode": "BBA",
    "stationname": "Teniente Vidal Airport",
    "lat": "455458S",
    "long": "0714122W"
  },
  {
    "stationcode": "IPC",
    "stationname": "Mataveri International Airport",
    "lat": "270953S",
    "long": "1092519W"
  },
  {
    "stationcode": "BHA",
    "stationname": "Bahia De Caraquez Airport",
    "lat": "003500S",
    "long": "0802400W"
  },
  {
    "stationcode": "JIP",
    "stationname": "Jipijapa Airport",
    "lat": "012000S",
    "long": "0804030W"
  },
  {
    "stationcode": "PYO",
    "stationname": "Putumayo Airport",
    "lat": "000400N",
    "long": "0755200W"
  },
  {
    "stationcode": "TUA",
    "stationname": "Tulcan Airport",
    "lat": "004834N",
    "long": "0774229W"
  },
  {
    "stationcode": "PTZ",
    "stationname": "Pastaza Airport",
    "lat": "013100S",
    "long": "0780230W"
  },
  {
    "stationcode": "ETR",
    "stationname": "Santa Rosa Regional Airport",
    "lat": "032607S",
    "long": "0795841W"
  },
  {
    "stationcode": "GYE",
    "stationname": "Simon Bolivar Airport",
    "lat": "020927S",
    "long": "0795301W"
  },
  {
    "stationcode": "MCH",
    "stationname": "Machala Airport",
    "lat": "031600S",
    "long": "0795900W"
  },
  {
    "stationcode": "MRR",
    "stationname": "Macara Airport",
    "lat": "042300S",
    "long": "0795600W"
  },
  {
    "stationcode": "TPC",
    "stationname": "Tarapoa Airport",
    "lat": "000800S",
    "long": "0762000W"
  },
  {
    "stationcode": "SUQ",
    "stationname": "Sucua Airport",
    "lat": "022800S",
    "long": "0781100W"
  },
  {
    "stationcode": "MEC",
    "stationname": "Manta Airport",
    "lat": "005646S",
    "long": "0804044W"
  },
  {
    "stationcode": "LTX",
    "stationname": "Cotapaxi International Airport",
    "lat": "005427S",
    "long": "0783657W"
  },
  {
    "stationcode": "TNW",
    "stationname": "Jumandy Airport",
    "lat": "010338S",
    "long": "0773447W"
  },
  {
    "stationcode": "LOH",
    "stationname": "Loja Airport",
    "lat": "035945S",
    "long": "0792218W"
  },
  {
    "stationcode": "CUE",
    "stationname": "Cuenca Airport",
    "lat": "025322S",
    "long": "0785904W"
  },
  {
    "stationcode": "UIO",
    "stationname": "Mariscal Sucre Airport",
    "lat": "000726S",
    "long": "0782122W"
  },
  {
    "stationcode": "LGQ",
    "stationname": "Lago Agrio Airport",
    "lat": "000533N",
    "long": "0765210W"
  },
  {
    "stationcode": "SNC",
    "stationname": "Salinas Airport",
    "lat": "021209S",
    "long": "0805926W"
  },
  {
    "stationcode": "PVO",
    "stationname": "Portoviejo Airport",
    "lat": "010200S",
    "long": "0802800W"
  },
  {
    "stationcode": "ATF",
    "stationname": "Chachoan Airport",
    "lat": "011200S",
    "long": "0783400W"
  },
  {
    "stationcode": "XMS",
    "stationname": "Macas Airport",
    "lat": "021757S",
    "long": "0780715W"
  },
  {
    "stationcode": "TPN",
    "stationname": "Tiputini Airport",
    "lat": "004600S",
    "long": "0753200W"
  },
  {
    "stationcode": "OCC",
    "stationname": "Coca Airport",
    "lat": "002746S",
    "long": "0765913W"
  },
  {
    "stationcode": "WGL",
    "stationname": "Off-Line Point",
    "lat": "443200N",
    "long": "0791300W"
  },
  {
    "stationcode": "MZD",
    "stationname": "Mendez Airport",
    "lat": "024300S",
    "long": "0781900W"
  },
  {
    "stationcode": "ESM",
    "stationname": "Esmeraldas Airport",
    "lat": "005843N",
    "long": "0793736W"
  },
  {
    "stationcode": "TSC",
    "stationname": "Taisha Airport",
    "lat": "022500S",
    "long": "0772800W"
  },
  {
    "stationcode": "IBB",
    "stationname": "General Villamil Airport",
    "lat": "005634S",
    "long": "0905711W"
  },
  {
    "stationcode": "SCY",
    "stationname": "San Cristobal Airport",
    "lat": "005437S",
    "long": "0893703W"
  },
  {
    "stationcode": "GPS",
    "stationname": "Baltra Airport",
    "lat": "002714S",
    "long": "0901557W"
  },
  {
    "stationcode": "TEV",
    "stationname": "Teruel Airport",
    "lat": "402428N",
    "long": "0011305W"
  },
  {
    "stationcode": "GRX",
    "stationname": "Federico Garcia Lorca",
    "lat": "371119N",
    "long": "0034638W"
  },
  {
    "stationcode": "BCN",
    "stationname": "Barcelona Airport",
    "lat": "411749N",
    "long": "0020442E"
  },
  {
    "stationcode": "OVD",
    "stationname": "Asturias Airport",
    "lat": "433400N",
    "long": "0060200W"
  },
  {
    "stationcode": "LEN",
    "stationname": "Leon Airport",
    "lat": "423520N",
    "long": "0053920W"
  },
  {
    "stationcode": "REU",
    "stationname": "Reus Airport",
    "lat": "410900N",
    "long": "0011010E"
  },
  {
    "stationcode": "SVQ",
    "stationname": "Sevilla Airport",
    "lat": "372505N",
    "long": "0055335W"
  },
  {
    "stationcode": "RGS",
    "stationname": "Villafria Airport",
    "lat": "422127N",
    "long": "0033715W"
  },
  {
    "stationcode": "BJZ",
    "stationname": "Badajoz Airport",
    "lat": "385329N",
    "long": "0064917W"
  },
  {
    "stationcode": "SCQ",
    "stationname": "Santiago De Compostela Airport",
    "lat": "425347N",
    "long": "0082455W"
  },
  {
    "stationcode": "ODB",
    "stationname": "Cordoba Airport",
    "lat": "375031N",
    "long": "0045056W"
  },
  {
    "stationcode": "IBZ",
    "stationname": "Ibiza Airport",
    "lat": "385222N",
    "long": "0012223E"
  },
  {
    "stationcode": "SDR",
    "stationname": "Santander Airport",
    "lat": "432537N",
    "long": "0034912W"
  },
  {
    "stationcode": "FGR",
    "stationname": "Fuengirola Airport",
    "lat": "363200N",
    "long": "0043800W"
  },
  {
    "stationcode": "OZP",
    "stationname": "Air Base",
    "lat": "371030N",
    "long": "0053657W"
  },
  {
    "stationcode": "TOJ",
    "stationname": "Torrejon",
    "lat": "402948N",
    "long": "0032645W"
  },
  {
    "stationcode": "RJL",
    "stationname": "Agoncillo Airport",
    "lat": "422725N",
    "long": "0021925W"
  },
  {
    "stationcode": "ILD",
    "stationname": "Alguaire Airport",
    "lat": "414341N",
    "long": "0003207E"
  },
  {
    "stationcode": "MJV",
    "stationname": "San Javier Airport",
    "lat": "374629N",
    "long": "0004840W"
  },
  {
    "stationcode": "VLC",
    "stationname": "Valencia Airport",
    "lat": "392925N",
    "long": "0002843W"
  },
  {
    "stationcode": "VGO",
    "stationname": "Vigo Airport",
    "lat": "421335N",
    "long": "0083740W"
  },
  {
    "stationcode": "SLM",
    "stationname": "Matacan Airport",
    "lat": "405708N",
    "long": "0053007W"
  },
  {
    "stationcode": "HSK",
    "stationname": "Pirineos Airport",
    "lat": "420451N",
    "long": "0001924W"
  },
  {
    "stationcode": "MAD",
    "stationname": "Barajas Airport",
    "lat": "402937N",
    "long": "0033400W"
  },
  {
    "stationcode": "LCG",
    "stationname": "La Coruna Airport",
    "lat": "431808N",
    "long": "0082238W"
  },
  {
    "stationcode": "CDT",
    "stationname": "Castellon Airport",
    "lat": "401251N",
    "long": "0000424E"
  },
  {
    "stationcode": "EAS",
    "stationname": "San Sebastian Airport",
    "lat": "432123N",
    "long": "0014726W"
  },
  {
    "stationcode": "PMI",
    "stationname": "Palma Mallorca Airport",
    "lat": "393306N",
    "long": "0024420E"
  },
  {
    "stationcode": "GRO",
    "stationname": "Costa Brava Airport",
    "lat": "415403N",
    "long": "0024538E"
  },
  {
    "stationcode": "MAH",
    "stationname": "Mahon Airport",
    "lat": "395145N",
    "long": "0041307E"
  },
  {
    "stationcode": "ALC",
    "stationname": "Alicante Airport",
    "lat": "381656N",
    "long": "0003329W"
  },
  {
    "stationcode": "AGP",
    "stationname": "Malaga Airport",
    "lat": "364030N",
    "long": "0042957W"
  },
  {
    "stationcode": "MLN",
    "stationname": "Melilla Airport",
    "lat": "351647N",
    "long": "0025723W"
  },
  {
    "stationcode": "RMU",
    "stationname": "Corvera International Airport",
    "lat": "374814N",
    "long": "0010738W"
  },
  {
    "stationcode": "VIT",
    "stationname": "Vitoria Airport",
    "lat": "425247N",
    "long": "0024345W"
  },
  {
    "stationcode": "CQM",
    "stationname": "Central Airport",
    "lat": "385126N",
    "long": "0035817W"
  },
  {
    "stationcode": "BIO",
    "stationname": "Bilbao Airport",
    "lat": "431804N",
    "long": "0025438W"
  },
  {
    "stationcode": "LEU",
    "stationname": "Aeroport De La Seu",
    "lat": "422019N",
    "long": "0012433E"
  },
  {
    "stationcode": "VLL",
    "stationname": "Valladolid Airport",
    "lat": "414222N",
    "long": "0045107W"
  },
  {
    "stationcode": "PNA",
    "stationname": "Pamplona Airport",
    "lat": "424612N",
    "long": "0013847W"
  },
  {
    "stationcode": "ABC",
    "stationname": "Albacete Airport",
    "lat": "385655N",
    "long": "0015149W"
  },
  {
    "stationcode": "LEI",
    "stationname": "Almeria Airport",
    "lat": "365038N",
    "long": "0022212W"
  },
  {
    "stationcode": "ZAZ",
    "stationname": "Zaragoza Airport",
    "lat": "413958N",
    "long": "0010230W"
  },
  {
    "stationcode": "XRY",
    "stationname": "Jerez Airport",
    "lat": "364441N",
    "long": "0060336W"
  },
  {
    "stationcode": "TFS",
    "stationname": "Tenerife-Sur",
    "lat": "280240N",
    "long": "0163421W"
  },
  {
    "stationcode": "VDE",
    "stationname": "El Hierro Airport",
    "lat": "274853N",
    "long": "0175313W"
  },
  {
    "stationcode": "GMZ",
    "stationname": "La Gomera Airport",
    "lat": "280147N",
    "long": "0171253W"
  },
  {
    "stationcode": "LPA",
    "stationname": "Gran Canaria",
    "lat": "275555N",
    "long": "0152312W"
  },
  {
    "stationcode": "FUE",
    "stationname": "Fuerteventura Airport",
    "lat": "282709N",
    "long": "0135149W"
  },
  {
    "stationcode": "ACE",
    "stationname": "Lanzarote Airport",
    "lat": "285644N",
    "long": "0133619W"
  },
  {
    "stationcode": "TFN",
    "stationname": "Tenerife-Norte",
    "lat": "282858N",
    "long": "0162030W"
  },
  {
    "stationcode": "SPC",
    "stationname": "La Palma",
    "lat": "283735N",
    "long": "0174520W"
  },
  {
    "stationcode": "YAP",
    "stationname": "Yap Airport",
    "lat": "092956N",
    "long": "1380457E"
  },
  {
    "stationcode": "TKK",
    "stationname": "Truk Airport",
    "lat": "072800N",
    "long": "1515100E"
  },
  {
    "stationcode": "ULI",
    "stationname": "Ulithi Airport",
    "lat": "095800N",
    "long": "1394000E"
  },
  {
    "stationcode": "KSA",
    "stationname": "Kosrae Airport",
    "lat": "052125N",
    "long": "1625730E"
  },
  {
    "stationcode": "PNI",
    "stationname": "Pohnpei Airport",
    "lat": "065907N",
    "long": "1581232E"
  },
  {
    "stationcode": "KUS",
    "stationname": "Kulusuk Airport",
    "lat": "653424N",
    "long": "0370803W"
  },
  {
    "stationcode": "JFR",
    "stationname": "Paamiut Airport",
    "lat": "620047N",
    "long": "0494007W"
  },
  {
    "stationcode": "JQA",
    "stationname": "Qaarsut Airport",
    "lat": "704401N",
    "long": "0524134W"
  },
  {
    "stationcode": "JGO",
    "stationname": "Qeqertarsuaq Airport",
    "lat": "691503N",
    "long": "0533217W"
  },
  {
    "stationcode": "JNN",
    "stationname": "Nanortalik Airport",
    "lat": "600831N",
    "long": "0451359W"
  },
  {
    "stationcode": "GOH",
    "stationname": "Nuuk Airport",
    "lat": "641127N",
    "long": "0514041W"
  },
  {
    "stationcode": "JAV",
    "stationname": "Ilulissat Airport",
    "lat": "691434N",
    "long": "0510329W"
  },
  {
    "stationcode": "UMD",
    "stationname": "Umanak Airport",
    "lat": "704050N",
    "long": "0520642W"
  },
  {
    "stationcode": "XIQ",
    "stationname": "Ilimanaq Harbour Airport",
    "lat": "690450N",
    "long": "0510646W"
  },
  {
    "stationcode": "AGM",
    "stationname": "Tasiilaq Airport",
    "lat": "653645N",
    "long": "0373707W"
  },
  {
    "stationcode": "DUN",
    "stationname": "Dundas Airport",
    "lat": "763438N",
    "long": "0684632W"
  },
  {
    "stationcode": "SFJ",
    "stationname": "Kangerlussuaq Airport",
    "lat": "670101N",
    "long": "0504122W"
  },
  {
    "stationcode": "JEG",
    "stationname": "Aasiaat Airport",
    "lat": "684316N",
    "long": "0524737W"
  },
  {
    "stationcode": "JRK",
    "stationname": "Arsuk Airport",
    "lat": "611037N",
    "long": "0482512W"
  },
  {
    "stationcode": "NAQ",
    "stationname": "Qaanaaq Airport",
    "lat": "772919N",
    "long": "0692316W"
  },
  {
    "stationcode": "XEO",
    "stationname": "Oquatsut Harbour Airport",
    "lat": "692000N",
    "long": "0510000W"
  },
  {
    "stationcode": "LLU",
    "stationname": "Alluitsup Paa Airport",
    "lat": "602753N",
    "long": "0453410W"
  },
  {
    "stationcode": "XEQ",
    "stationname": "Tasiuasaq Harbour Airport",
    "lat": "601140N",
    "long": "0444849W"
  },
  {
    "stationcode": "UAK",
    "stationname": "Narsarsuaq Airport",
    "lat": "610938N",
    "long": "0452534W"
  },
  {
    "stationcode": "JCH",
    "stationname": "Qasigiannguit Airport",
    "lat": "684914N",
    "long": "0511020W"
  },
  {
    "stationcode": "JHS",
    "stationname": "Sisimiut Airport",
    "lat": "665705N",
    "long": "0534346W"
  },
  {
    "stationcode": "THU",
    "stationname": "Pituffik Airport",
    "lat": "763200N",
    "long": "0684500W"
  },
  {
    "stationcode": "OBY",
    "stationname": "Ittoqqortoormiit Airport",
    "lat": "702918N",
    "long": "0215818W"
  },
  {
    "stationcode": "CNP",
    "stationname": "Neerlerit Inaat Airport",
    "lat": "704436N",
    "long": "0223902W"
  },
  {
    "stationcode": "PDO",
    "stationname": "Pendopo Airport",
    "lat": "031700S",
    "long": "1035000E"
  },
  {
    "stationcode": "TSY",
    "stationname": "Tasikmalaya Airport",
    "lat": "072050S",
    "long": "1081447E"
  },
  {
    "stationcode": "KWB",
    "stationname": "Karimunjawa Airport",
    "lat": "055100S",
    "long": "1102800E"
  },
  {
    "stationcode": "TJB",
    "stationname": "Tanjung Balai Airport",
    "lat": "025800N",
    "long": "0994800E"
  },
  {
    "stationcode": "KLQ",
    "stationname": "Keluang Airport",
    "lat": "023600S",
    "long": "1035700E"
  },
  {
    "stationcode": "AKQ",
    "stationname": "Gunung Batin Airport",
    "lat": "043700S",
    "long": "1051400E"
  },
  {
    "stationcode": "RKI",
    "stationname": "Rokot Airport",
    "lat": "005700S",
    "long": "1004500E"
  },
  {
    "stationcode": "MPC",
    "stationname": "Muko-Muko Airport",
    "lat": "023300S",
    "long": "1010600E"
  },
  {
    "stationcode": "SBG",
    "stationname": "Sabang Airport",
    "lat": "055229N",
    "long": "0952025E"
  },
  {
    "stationcode": "SEQ",
    "stationname": "Sungaipinang Airport",
    "lat": "012200N",
    "long": "1020800E"
  },
  {
    "stationcode": "MSI",
    "stationname": "Masalembo Airport",
    "lat": "053400S",
    "long": "1142600E"
  },
  {
    "stationcode": "PWL",
    "stationname": "Purwokerto Airport",
    "lat": "072700S",
    "long": "1092500E"
  },
  {
    "stationcode": "TKG",
    "stationname": "Bandar Lampung",
    "lat": "051433S",
    "long": "1051044E"
  },
  {
    "stationcode": "SMQ",
    "stationname": "Sampit Airport",
    "lat": "022956S",
    "long": "1125830E"
  },
  {
    "stationcode": "KTG",
    "stationname": "Ketapang Airport",
    "lat": "014900S",
    "long": "1095749E"
  },
  {
    "stationcode": "CPF",
    "stationname": "Cepu Airport",
    "lat": "071100S",
    "long": "1113200E"
  },
  {
    "stationcode": "JBB",
    "stationname": "Noto Hadinegoro Airport",
    "lat": "081432S",
    "long": "1134140E"
  },
  {
    "stationcode": "TPK",
    "stationname": "Tapaktuan Airport",
    "lat": "031600N",
    "long": "0971100E"
  },
  {
    "stationcode": "DJB",
    "stationname": "Sultan Taha Syarifudn Airport",
    "lat": "013817S",
    "long": "1033840E"
  },
  {
    "stationcode": "MJY",
    "stationname": "Mangunjaya Airport",
    "lat": "024400S",
    "long": "1033400E"
  },
  {
    "stationcode": "GYO",
    "stationname": "Blangkejeren",
    "lat": "035648N",
    "long": "0972131E"
  },
  {
    "stationcode": "MEQ",
    "stationname": "Seunagan Airport",
    "lat": "040244N",
    "long": "0961458E"
  },
  {
    "stationcode": "PPR",
    "stationname": "Pasir Pangarayan Airport",
    "lat": "005000N",
    "long": "1002200E"
  },
  {
    "stationcode": "LSW",
    "stationname": "Lhoksumawe Airport",
    "lat": "051336N",
    "long": "0965701E"
  },
  {
    "stationcode": "LSX",
    "stationname": "Lhok Sukon Airport",
    "lat": "050300N",
    "long": "0971900E"
  },
  {
    "stationcode": "AEG",
    "stationname": "Aek Godang Airport",
    "lat": "012400N",
    "long": "0992548E"
  },
  {
    "stationcode": "DUM",
    "stationname": "Pinang Kampai Airport",
    "lat": "014100N",
    "long": "1012700E"
  },
  {
    "stationcode": "TJG",
    "stationname": "Tanjung Warukin Airport",
    "lat": "021300S",
    "long": "1152611E"
  },
  {
    "stationcode": "PPJ",
    "stationname": "Pulau Panjang Airport",
    "lat": "062100S",
    "long": "1063400E"
  },
  {
    "stationcode": "CXP",
    "stationname": "Tunggul Wulung Airport",
    "lat": "074400S",
    "long": "1090000E"
  },
  {
    "stationcode": "NTX",
    "stationname": "Natuna Ranai Airport",
    "lat": "035422N",
    "long": "1082316E"
  },
  {
    "stationcode": "RGT",
    "stationname": "Japura Airport",
    "lat": "002110S",
    "long": "1022005E"
  },
  {
    "stationcode": "KRC",
    "stationname": "Kerinci Airport",
    "lat": "020536S",
    "long": "1012809E"
  },
  {
    "stationcode": "SIQ",
    "stationname": "Dabo Airport",
    "lat": "002900S",
    "long": "1043500E"
  },
  {
    "stationcode": "BWX",
    "stationname": "Blimbingsari Airport",
    "lat": "081837S",
    "long": "1142024E"
  },
  {
    "stationcode": "SAE",
    "stationname": "Sangir Airport",
    "lat": "704831N",
    "long": "0513736W"
  },
  {
    "stationcode": "RKO",
    "stationname": "Sipora Airport",
    "lat": "020500S",
    "long": "1160000E"
  },
  {
    "stationcode": "CBN",
    "stationname": "Penggung Airport",
    "lat": "064500S",
    "long": "1083200E"
  },
  {
    "stationcode": "HLP",
    "stationname": "Halim Perdana Kusuma Airport",
    "lat": "061600S",
    "long": "1065328E"
  },
  {
    "stationcode": "FLZ",
    "stationname": "Ferdinand Airport",
    "lat": "013321N",
    "long": "0985320E"
  },
  {
    "stationcode": "PKY",
    "stationname": "Palangkaraya Airport",
    "lat": "021330S",
    "long": "1135634E"
  },
  {
    "stationcode": "BKS",
    "stationname": "Padangkemiling Airport",
    "lat": "035149S",
    "long": "1022021E"
  },
  {
    "stationcode": "SIW",
    "stationname": "Sibisa Airport",
    "lat": "023500N",
    "long": "0985600E"
  },
  {
    "stationcode": "SUP",
    "stationname": "Trunojoyo Airport",
    "lat": "070123S",
    "long": "1135319E"
  },
  {
    "stationcode": "MWK",
    "stationname": "Matak Airport",
    "lat": "031800N",
    "long": "1061556E"
  },
  {
    "stationcode": "GNS",
    "stationname": "Gunungsitoli Airport",
    "lat": "011001N",
    "long": "0974216E"
  },
  {
    "stationcode": "PCB",
    "stationname": "Pondok Cabe Airport",
    "lat": "062100S",
    "long": "1064600E"
  },
  {
    "stationcode": "PKN",
    "stationname": "Pangkalanbuun Airport",
    "lat": "024219S",
    "long": "1114024E"
  },
  {
    "stationcode": "TNJ",
    "stationname": "Kidjang Airport",
    "lat": "005526N",
    "long": "1043159E"
  },
  {
    "stationcode": "SZH",
    "stationname": "Senipah Airport",
    "lat": "005800S",
    "long": "1170800E"
  },
  {
    "stationcode": "KDI",
    "stationname": "Wolter Monginsidi Airport",
    "lat": "040500S",
    "long": "1222600E"
  },
  {
    "stationcode": "BJW",
    "stationname": "Bajawa Airport",
    "lat": "084228S",
    "long": "1210328E"
  },
  {
    "stationcode": "PUM",
    "stationname": "Pomala Airport",
    "lat": "034000S",
    "long": "1194759E"
  },
  {
    "stationcode": "KBU",
    "stationname": "Kotabaru Airport",
    "lat": "031746S",
    "long": "1160953E"
  },
  {
    "stationcode": "BYQ",
    "stationname": "Bunyu Airport",
    "lat": "032800N",
    "long": "1175200E"
  },
  {
    "stationcode": "LPU",
    "stationname": "Long Apung Airport",
    "lat": "014218N",
    "long": "1145813E"
  },
  {
    "stationcode": "TBM",
    "stationname": "Tumbang Samba Airport",
    "lat": "031500S",
    "long": "1123500E"
  },
  {
    "stationcode": "TTR",
    "stationname": "Tana Toraja Airport",
    "lat": "030245S",
    "long": "1194925E"
  },
  {
    "stationcode": "AXO",
    "stationname": "Pantar Airport",
    "lat": "081445S",
    "long": "1241307E"
  },
  {
    "stationcode": "KOD",
    "stationname": "Kotabangun Airport",
    "lat": "001600S",
    "long": "1163500E"
  },
  {
    "stationcode": "SWQ",
    "stationname": "Brang Bidji Airport",
    "lat": "082917S",
    "long": "1172441E"
  },
  {
    "stationcode": "TRK",
    "stationname": "Tarakan Airport",
    "lat": "031936N",
    "long": "1173400E"
  },
  {
    "stationcode": "SQG",
    "stationname": "Sintang Airport",
    "lat": "000349N",
    "long": "1112824E"
  },
  {
    "stationcode": "NNX",
    "stationname": "Nunukan Airport",
    "lat": "040809N",
    "long": "1174006E"
  },
  {
    "stationcode": "NAF",
    "stationname": "Banaina Airport",
    "lat": "024500N",
    "long": "1170300E"
  },
  {
    "stationcode": "UOL",
    "stationname": "Buol Airport",
    "lat": "010617N",
    "long": "1212455E"
  },
  {
    "stationcode": "DTD",
    "stationname": "Datadawai Airport",
    "lat": "004839N",
    "long": "1143151E"
  },
  {
    "stationcode": "NPO",
    "stationname": "Nangapinoh Airport",
    "lat": "002200S",
    "long": "1114500E"
  },
  {
    "stationcode": "LBW",
    "stationname": "Long Bawan Airport",
    "lat": "035300N",
    "long": "1154200E"
  },
  {
    "stationcode": "SGQ",
    "stationname": "Sanggata Airport",
    "lat": "003000N",
    "long": "1172000E"
  },
  {
    "stationcode": "ABU",
    "stationname": "Atambua Airport",
    "lat": "090430S",
    "long": "1245414E"
  },
  {
    "stationcode": "PSU",
    "stationname": "Putussibau Airport",
    "lat": "005008N",
    "long": "1125614E"
  },
  {
    "stationcode": "TJS",
    "stationname": "Tanjung Selor Airport",
    "lat": "025011N",
    "long": "1172225E"
  },
  {
    "stationcode": "BMU",
    "stationname": "Bima Airport",
    "lat": "083223S",
    "long": "1184114E"
  },
  {
    "stationcode": "SAU",
    "stationname": "Sawu Airport",
    "lat": "103000S",
    "long": "1215400E"
  },
  {
    "stationcode": "GTO",
    "stationname": "Tolotio Airport",
    "lat": "003814N",
    "long": "1225105E"
  },
  {
    "stationcode": "LYK",
    "stationname": "Lunyuk Airport",
    "lat": "085700S",
    "long": "1171400E"
  },
  {
    "stationcode": "ARD",
    "stationname": "Alor Island Airport",
    "lat": "080759S",
    "long": "1243549E"
  },
  {
    "stationcode": "BXT",
    "stationname": "Bontang Airport",
    "lat": "000800N",
    "long": "1173000E"
  },
  {
    "stationcode": "WGP",
    "stationname": "Waingapu Airport",
    "lat": "094009S",
    "long": "1201807E"
  },
  {
    "stationcode": "ENE",
    "stationname": "Ende Airport",
    "lat": "085057S",
    "long": "1213938E"
  },
  {
    "stationcode": "KXB",
    "stationname": "Sangia Nibandera",
    "lat": "042031S",
    "long": "1213118E"
  },
  {
    "stationcode": "BUW",
    "stationname": "Baubau Airport",
    "lat": "052915S",
    "long": "1223408E"
  },
  {
    "stationcode": "TNB",
    "stationname": "Tanahgrogot Airport",
    "lat": "015500S",
    "long": "1161200E"
  },
  {
    "stationcode": "AMI",
    "stationname": "Selaparang Airport",
    "lat": "083339S",
    "long": "1160541E"
  },
  {
    "stationcode": "SQN",
    "stationname": "Sanana Airport",
    "lat": "020603S",
    "long": "1255757E"
  },
  {
    "stationcode": "PBW",
    "stationname": "Palibelo Airport",
    "lat": "093159N",
    "long": "0001300W"
  },
  {
    "stationcode": "KOE",
    "stationname": "Eltari Airport",
    "lat": "101018S",
    "long": "1234016E"
  },
  {
    "stationcode": "MOF",
    "stationname": "Waioti Airport",
    "lat": "083826S",
    "long": "1221413E"
  },
  {
    "stationcode": "LWE",
    "stationname": "Lewoleba Airport",
    "lat": "082143S",
    "long": "1232619E"
  },
  {
    "stationcode": "TSX",
    "stationname": "Tanjung Santan Airport",
    "lat": "000500S",
    "long": "1172600E"
  },
  {
    "stationcode": "TQQ",
    "stationname": "Maranggo Airport",
    "lat": "054601S",
    "long": "1235508E"
  },
  {
    "stationcode": "LKA",
    "stationname": "Larantuka Airport",
    "lat": "081627S",
    "long": "1230002E"
  },
  {
    "stationcode": "RTG",
    "stationname": "Ruteng Airport",
    "lat": "083546S",
    "long": "1202841E"
  },
  {
    "stationcode": "TMC",
    "stationname": "Tambolaka Airport",
    "lat": "092434S",
    "long": "1191440E"
  },
  {
    "stationcode": "SRI",
    "stationname": "Samarinda Airport",
    "lat": "002904S",
    "long": "1170926E"
  },
  {
    "stationcode": "BTW",
    "stationname": "Batulicin Airport",
    "lat": "032700S",
    "long": "1160000E"
  },
  {
    "stationcode": "LLO",
    "stationname": "Lagaligo Airport",
    "lat": "030457S",
    "long": "1201442E"
  },
  {
    "stationcode": "TLI",
    "stationname": "Tolitoli Airport",
    "lat": "010725N",
    "long": "1204736E"
  },
  {
    "stationcode": "RTI",
    "stationname": "Roti Airport",
    "lat": "104601S",
    "long": "1230431E"
  },
  {
    "stationcode": "BJG",
    "stationname": "Bolaang Airport",
    "lat": "005600N",
    "long": "1241000E"
  },
  {
    "stationcode": "NAH",
    "stationname": "Naha Airport",
    "lat": "034058N",
    "long": "1253141E"
  },
  {
    "stationcode": "PLW",
    "stationname": "Mutiara Airport",
    "lat": "005507S",
    "long": "1195435E"
  },
  {
    "stationcode": "SEH",
    "stationname": "Senggeh Airport",
    "lat": "032600S",
    "long": "1404900E"
  },
  {
    "stationcode": "AAS",
    "stationname": "Apalapsili Airport",
    "lat": "035500S",
    "long": "1391500E"
  },
  {
    "stationcode": "TMH",
    "stationname": "Tanahmerah Airport",
    "lat": "060551S",
    "long": "1401811E"
  },
  {
    "stationcode": "GAV",
    "stationname": "Gag Island Airport",
    "lat": "002700S",
    "long": "1305300E"
  },
  {
    "stationcode": "RSK",
    "stationname": "Ransiki Airport",
    "lat": "013000S",
    "long": "1341500E"
  },
  {
    "stationcode": "BXM",
    "stationname": "Batom Airport",
    "lat": "021700S",
    "long": "1393600E"
  },
  {
    "stationcode": "GEB",
    "stationname": "Gebe Airport",
    "lat": "000500S",
    "long": "1292000E"
  },
  {
    "stationcode": "KEA",
    "stationname": "Keisah Airport",
    "lat": "374920N",
    "long": "0650808E"
  },
  {
    "stationcode": "TIM",
    "stationname": "Timika Airport",
    "lat": "043142S",
    "long": "1365315E"
  },
  {
    "stationcode": "VIQ",
    "stationname": "Viqueque Airport",
    "lat": "085300S",
    "long": "1262200E"
  },
  {
    "stationcode": "ZRM",
    "stationname": "Sarmi Airport",
    "lat": "015300S",
    "long": "1384600E"
  },
  {
    "stationcode": "KCD",
    "stationname": "Kamur Airport",
    "lat": "061200S",
    "long": "1384300E"
  },
  {
    "stationcode": "MXB",
    "stationname": "Masamba Airport",
    "lat": "023200S",
    "long": "1202000E"
  },
  {
    "stationcode": "UBR",
    "stationname": "Ubrub Irian Jaya Airport",
    "lat": "034000S",
    "long": "1405100E"
  },
  {
    "stationcode": "BXD",
    "stationname": "Bade Airport",
    "lat": "071000S",
    "long": "1393500E"
  },
  {
    "stationcode": "LII",
    "stationname": "Mulia Airport",
    "lat": "031000S",
    "long": "1361000E"
  },
  {
    "stationcode": "MKQ",
    "stationname": "Mopah Airport",
    "lat": "083105S",
    "long": "1402459E"
  },
  {
    "stationcode": "SXK",
    "stationname": "Saumlaki Airport",
    "lat": "075917S",
    "long": "1311818E"
  },
  {
    "stationcode": "AHI",
    "stationname": "Amahai Airport",
    "lat": "032000S",
    "long": "1285500E"
  },
  {
    "stationcode": "EWI",
    "stationname": "Enarotali Airport",
    "lat": "035600S",
    "long": "1362200E"
  },
  {
    "stationcode": "NDA",
    "stationname": "Bandanaira Airport",
    "lat": "043200S",
    "long": "1295400E"
  },
  {
    "stationcode": "KEI",
    "stationname": "Kepi Airport",
    "lat": "064000S",
    "long": "1392300E"
  },
  {
    "stationcode": "KMM",
    "stationname": "Kiman Airport",
    "lat": "075800S",
    "long": "1385400E"
  },
  {
    "stationcode": "NAM",
    "stationname": "Namlea Airport",
    "lat": "031414S",
    "long": "1270601E"
  },
  {
    "stationcode": "DOB",
    "stationname": "Dobo Airport",
    "lat": "054622S",
    "long": "1341240E"
  },
  {
    "stationcode": "WBA",
    "stationname": "Wahai Airport",
    "lat": "024900S",
    "long": "1292900E"
  },
  {
    "stationcode": "AUT",
    "stationname": "Atauro Airport",
    "lat": "081600S",
    "long": "1253600E"
  },
  {
    "stationcode": "AMQ",
    "stationname": "Pattimura Airport",
    "lat": "034222S",
    "long": "1280519E"
  },
  {
    "stationcode": "ONI",
    "stationname": "Moanamani Airport",
    "lat": "035900S",
    "long": "1360500E"
  },
  {
    "stationcode": "KCI",
    "stationname": "Kon Airport",
    "lat": "160733S",
    "long": "1234405E"
  },
  {
    "stationcode": "PSJ",
    "stationname": "Poso Airport",
    "lat": "012506S",
    "long": "1203924E"
  },
  {
    "stationcode": "BJK",
    "stationname": "Benjina Airport",
    "lat": "060500S",
    "long": "1341500E"
  },
  {
    "stationcode": "INX",
    "stationname": "Inanwatan Airport",
    "lat": "020800S",
    "long": "1321000E"
  },
  {
    "stationcode": "LUV",
    "stationname": "Langgur Airport",
    "lat": "053942S",
    "long": "1324353E"
  },
  {
    "stationcode": "ILA",
    "stationname": "Illaga Airport",
    "lat": "035000S",
    "long": "1373500E"
  },
  {
    "stationcode": "SQR",
    "stationname": "Soroako Airport",
    "lat": "023400S",
    "long": "1204500E"
  },
  {
    "stationcode": "KBF",
    "stationname": "Karubaga Airport",
    "lat": "034500S",
    "long": "1382000E"
  },
  {
    "stationcode": "OKL",
    "stationname": "Oksibil Airport",
    "lat": "045430S",
    "long": "1403750E"
  },
  {
    "stationcode": "DRH",
    "stationname": "Dabra Airport",
    "lat": "031500S",
    "long": "1383400E"
  },
  {
    "stationcode": "KEQ",
    "stationname": "Kebar Airport",
    "lat": "003800S",
    "long": "1330700E"
  },
  {
    "stationcode": "MDP",
    "stationname": "Mindiptana Airport",
    "lat": "055300S",
    "long": "1404300E"
  },
  {
    "stationcode": "BXB",
    "stationname": "Babo Airport",
    "lat": "023206S",
    "long": "1332620E"
  },
  {
    "stationcode": "MUF",
    "stationname": "Muting Airport",
    "lat": "072300S",
    "long": "1402000E"
  },
  {
    "stationcode": "NTI",
    "stationname": "Bintuni Airport",
    "lat": "020600S",
    "long": "1333100E"
  },
  {
    "stationcode": "NKD",
    "stationname": "Sinak Irian Jaya Airport",
    "lat": "035000S",
    "long": "1375100E"
  },
  {
    "stationcode": "ZRI",
    "stationname": "Serui Airport",
    "lat": "015227S",
    "long": "1361423E"
  },
  {
    "stationcode": "RUF",
    "stationname": "Yuruf Irian Jaya Airport",
    "lat": "033800S",
    "long": "1405600E"
  },
  {
    "stationcode": "BUI",
    "stationname": "Bokondini Airport",
    "lat": "034300S",
    "long": "1383900E"
  },
  {
    "stationcode": "OEC",
    "stationname": "Ocussi Airport",
    "lat": "091500S",
    "long": "1242400E"
  },
  {
    "stationcode": "TMY",
    "stationname": "Tiom Airport",
    "lat": "035700S",
    "long": "1382500E"
  },
  {
    "stationcode": "BIK",
    "stationname": "Mokmer Airport",
    "lat": "011112S",
    "long": "1360508E"
  },
  {
    "stationcode": "LLN",
    "stationname": "Kelila Airport",
    "lat": "034500S",
    "long": "1384000E"
  },
  {
    "stationcode": "KBX",
    "stationname": "Kambuaya Airport",
    "lat": "011900S",
    "long": "1321716E"
  },
  {
    "stationcode": "WSR",
    "stationname": "Wasior Airport",
    "lat": "024600S",
    "long": "1343000E"
  },
  {
    "stationcode": "FOO",
    "stationname": "Numfor Airport",
    "lat": "005600S",
    "long": "1345200E"
  },
  {
    "stationcode": "OKQ",
    "stationname": "Okaba Airport",
    "lat": "080600S",
    "long": "1394200E"
  },
  {
    "stationcode": "LHI",
    "stationname": "Lereh Airport",
    "lat": "030800S",
    "long": "1395400E"
  },
  {
    "stationcode": "WMX",
    "stationname": "Wamena Airport",
    "lat": "040600S",
    "long": "1385713E"
  },
  {
    "stationcode": "RAQ",
    "stationname": "Sugimanuru Airport",
    "lat": "045100S",
    "long": "1224300E"
  },
  {
    "stationcode": "RDE",
    "stationname": "Merdey Airport",
    "lat": "013500S",
    "long": "1332000E"
  },
  {
    "stationcode": "ZEG",
    "stationname": "Senggo Airport",
    "lat": "054127S",
    "long": "1392101E"
  },
  {
    "stationcode": "MPT",
    "stationname": "Maliana Airport",
    "lat": "085900S",
    "long": "1252300E"
  },
  {
    "stationcode": "KOX",
    "stationname": "Kokonao Airport",
    "lat": "044239S",
    "long": "1362607E"
  },
  {
    "stationcode": "MJU",
    "stationname": "Mamuju Airport",
    "lat": "023518S",
    "long": "1190140E"
  },
  {
    "stationcode": "RJM",
    "stationname": "Marinda Airport",
    "lat": "001536S",
    "long": "1303832E"
  },
  {
    "stationcode": "MNA",
    "stationname": "Melangguane Airport",
    "lat": "040024N",
    "long": "1264025E"
  },
  {
    "stationcode": "NBX",
    "stationname": "Nabire Airport",
    "lat": "032205S",
    "long": "1352948E"
  },
  {
    "stationcode": "KNG",
    "stationname": "Kaimana Airport",
    "lat": "033840S",
    "long": "1334144E"
  },
  {
    "stationcode": "LAH",
    "stationname": "Labuha Airport",
    "lat": "003805S",
    "long": "1273015E"
  },
  {
    "stationcode": "DEX",
    "stationname": "Nop Goliath Airport",
    "lat": "045119S",
    "long": "1392902E"
  },
  {
    "stationcode": "EWE",
    "stationname": "Ewer Airport",
    "lat": "052900S",
    "long": "1380400E"
  },
  {
    "stationcode": "NRE",
    "stationname": "Namrole Airport",
    "lat": "035118S",
    "long": "1264206E"
  },
  {
    "stationcode": "FKQ",
    "stationname": "Fak Fak Airport",
    "lat": "025513S",
    "long": "1321601E"
  },
  {
    "stationcode": "OTI",
    "stationname": "Morotai Island Airport",
    "lat": "020300N",
    "long": "1281900E"
  },
  {
    "stationcode": "AGD",
    "stationname": "Anggi Airport",
    "lat": "012300S",
    "long": "1335200E"
  },
  {
    "stationcode": "OBD",
    "stationname": "Obano Airport",
    "lat": "035400S",
    "long": "1361200E"
  },
  {
    "stationcode": "TAX",
    "stationname": "Taliabu Airport",
    "lat": "013836S",
    "long": "1243340E"
  },
  {
    "stationcode": "TXM",
    "stationname": "Teminabuan Airport",
    "lat": "012700S",
    "long": "1320100E"
  },
  {
    "stationcode": "AYW",
    "stationname": "Ayawasi Airport",
    "lat": "011200S",
    "long": "1323000E"
  },
  {
    "stationcode": "MAL",
    "stationname": "Mangole Airport",
    "lat": "015300S",
    "long": "1255000E"
  },
  {
    "stationcode": "ARJ",
    "stationname": "Arso Airport",
    "lat": "025600S",
    "long": "1404700E"
  },
  {
    "stationcode": "BCH",
    "stationname": "English Madeira Airport",
    "lat": "082900S",
    "long": "1262400E"
  },
  {
    "stationcode": "IUL",
    "stationname": "Ilu Airport",
    "lat": "034500S",
    "long": "1380500E"
  },
  {
    "stationcode": "KAZ",
    "stationname": "Kau Airport",
    "lat": "011105N",
    "long": "1275346E"
  },
  {
    "stationcode": "ELR",
    "stationname": "Elelim Irian Jaya Airport",
    "lat": "034658S",
    "long": "1392310E"
  },
  {
    "stationcode": "WET",
    "stationname": "Wagethe Airport",
    "lat": "040300S",
    "long": "1361600E"
  },
  {
    "stationcode": "GLX",
    "stationname": "Galela Airport",
    "lat": "015016N",
    "long": "1274709E"
  },
  {
    "stationcode": "MKW",
    "stationname": "Rendani Airport",
    "lat": "005331S",
    "long": "1340257E"
  },
  {
    "stationcode": "UGU",
    "stationname": "Zugapa Irian Jaya Airport",
    "lat": "034400S",
    "long": "1370200E"
  },
  {
    "stationcode": "WAR",
    "stationname": "Waris Airport",
    "lat": "031400S",
    "long": "1405900E"
  },
  {
    "stationcode": "ZKL",
    "stationname": "Steenkool Airport",
    "lat": "020610S",
    "long": "1333100E"
  },
  {
    "stationcode": "MLG",
    "stationname": "Malang Airport",
    "lat": "075600S",
    "long": "1124300E"
  },
  {
    "stationcode": "KUC",
    "stationname": "Kuria Airport",
    "lat": "010014N",
    "long": "1730152E"
  },
  {
    "stationcode": "MTK",
    "stationname": "Makin Island Airport",
    "lat": "032229N",
    "long": "1725933E"
  },
  {
    "stationcode": "BEZ",
    "stationname": "Beru Airport",
    "lat": "012116S",
    "long": "1760024E"
  },
  {
    "stationcode": "ABF",
    "stationname": "Abaiang Airport",
    "lat": "012254N",
    "long": "1730851E"
  },
  {
    "stationcode": "TBF",
    "stationname": "Tabiteuea Airport",
    "lat": "011329S",
    "long": "1744635E"
  },
  {
    "stationcode": "TSU",
    "stationname": "Tabiteuea South Airport",
    "lat": "012829S",
    "long": "1750353E"
  },
  {
    "stationcode": "TRW",
    "stationname": "Bonriki Airport",
    "lat": "012248N",
    "long": "1730848E"
  },
  {
    "stationcode": "MZK",
    "stationname": "Marakei Airport",
    "lat": "020331N",
    "long": "1731619E"
  },
  {
    "stationcode": "NIG",
    "stationname": "Nikunau Airport",
    "lat": "011852S",
    "long": "1762439E"
  },
  {
    "stationcode": "BBG",
    "stationname": "Butaritari Airport",
    "lat": "030511N",
    "long": "1724839E"
  },
  {
    "stationcode": "OOT",
    "stationname": "Onotoa Airport",
    "lat": "014747S",
    "long": "1753134E"
  },
  {
    "stationcode": "AAK",
    "stationname": "Aranuka Airport",
    "lat": "001107N",
    "long": "1733813E"
  },
  {
    "stationcode": "AIS",
    "stationname": "Arorae Island Airport",
    "lat": "023659S",
    "long": "1764809E"
  },
  {
    "stationcode": "MNK",
    "stationname": "Maiana Airport",
    "lat": "010013N",
    "long": "1730152E"
  },
  {
    "stationcode": "NON",
    "stationname": "Nonouti Airport",
    "lat": "003823S",
    "long": "1742541E"
  },
  {
    "stationcode": "TMN",
    "stationname": "Tamana Island Airport",
    "lat": "022910S",
    "long": "1755813E"
  },
  {
    "stationcode": "AEA",
    "stationname": "Abemama Atoll Airport",
    "lat": "002926N",
    "long": "1734948E"
  },
  {
    "stationcode": "TNV",
    "stationname": "Fanning Island Airport",
    "lat": "035000N",
    "long": "1592100W"
  },
  {
    "stationcode": "TNQ",
    "stationname": "Washington Island Airport",
    "lat": "044200N",
    "long": "1602000W"
  },
  {
    "stationcode": "CXI",
    "stationname": "Christmas Island Airport",
    "lat": "015911N",
    "long": "1572115W"
  },
  {
    "stationcode": "CIS",
    "stationname": "Canton Island Airport",
    "lat": "025000S",
    "long": "1714000W"
  },
  {
    "stationcode": "URA",
    "stationname": "Uralsk Airport",
    "lat": "510904N",
    "long": "0513230E"
  },
  {
    "stationcode": "SCO",
    "stationname": "Aktau Airport",
    "lat": "435136N",
    "long": "0510531E"
  },
  {
    "stationcode": "AKX",
    "stationname": "Aktyubinsk Airport",
    "lat": "501445N",
    "long": "0571224E"
  },
  {
    "stationcode": "GUW",
    "stationname": "Atyrau Airport",
    "lat": "470719N",
    "long": "0514917E"
  },
  {
    "stationcode": "TSE",
    "stationname": "Astana Airport",
    "lat": "510120N",
    "long": "0712801E"
  },
  {
    "stationcode": "CIT",
    "stationname": "Shimkent Airport",
    "lat": "422151N",
    "long": "0692844E"
  },
  {
    "stationcode": "KGF",
    "stationname": "Karaganda Airport",
    "lat": "494015N",
    "long": "0732004E"
  },
  {
    "stationcode": "PLX",
    "stationname": "Semipalatinsk Airport",
    "lat": "502105N",
    "long": "0801404E"
  },
  {
    "stationcode": "DZN",
    "stationname": "Zhezhazgan Airport",
    "lat": "474236N",
    "long": "0674425E"
  },
  {
    "stationcode": "UZR",
    "stationname": "Urdzhar Airport",
    "lat": "470523N",
    "long": "0813933E"
  },
  {
    "stationcode": "KOV",
    "stationname": "Kokshetau Airport",
    "lat": "531945N",
    "long": "0693541E"
  },
  {
    "stationcode": "EKB",
    "stationname": "Ekibastuz Airport",
    "lat": "513500N",
    "long": "0751300E"
  },
  {
    "stationcode": "PPK",
    "stationname": "Petropavlovsk Airport",
    "lat": "544628N",
    "long": "0691102E"
  },
  {
    "stationcode": "ATX",
    "stationname": "Atbasar Airport",
    "lat": "515300N",
    "long": "0682400E"
  },
  {
    "stationcode": "BXJ",
    "stationname": "Burundai Airport",
    "lat": "432200N",
    "long": "0765000E"
  },
  {
    "stationcode": "ALA",
    "stationname": "Almaty Airport",
    "lat": "432112N",
    "long": "0770232E"
  },
  {
    "stationcode": "HRC",
    "stationname": "Zhairem Airport",
    "lat": "482800N",
    "long": "0702800E"
  },
  {
    "stationcode": "UKK",
    "stationname": "Ust-Kamenogorsk Airport",
    "lat": "500212N",
    "long": "0822937E"
  },
  {
    "stationcode": "DMB",
    "stationname": "Dzhambyl Airport",
    "lat": "425113N",
    "long": "0711813E"
  },
  {
    "stationcode": "PWQ",
    "stationname": "Pavlodar Airport",
    "lat": "521100N",
    "long": "0770400E"
  },
  {
    "stationcode": "BXH",
    "stationname": "Balhash Airport",
    "lat": "465331N",
    "long": "0750002E"
  },
  {
    "stationcode": "KSN",
    "stationname": "Kostanay Airport",
    "lat": "531225N",
    "long": "0633301E"
  },
  {
    "stationcode": "HSA",
    "stationname": "Turkistan International",
    "lat": "431922N",
    "long": "0682407E"
  },
  {
    "stationcode": "AYK",
    "stationname": "Arkalyk Airport",
    "lat": "501900N",
    "long": "0665800E"
  },
  {
    "stationcode": "TDK",
    "stationname": "Taldy-Kurgan Airport",
    "lat": "450722N",
    "long": "0782634E"
  },
  {
    "stationcode": "SZI",
    "stationname": "Zaisan Airport",
    "lat": "472915N",
    "long": "0845311E"
  },
  {
    "stationcode": "KZO",
    "stationname": "Kzyl-Orda Airport",
    "lat": "444227N",
    "long": "0653532E"
  },
  {
    "stationcode": "TCN",
    "stationname": "Tehuacan Airport",
    "lat": "182900N",
    "long": "0972500W"
  },
  {
    "stationcode": "MEX",
    "stationname": "Juarez International Airport",
    "lat": "192611N",
    "long": "0990420W"
  },
  {
    "stationcode": "TAM",
    "stationname": "Gen F Javier Mina Airport",
    "lat": "221725N",
    "long": "0975209W"
  },
  {
    "stationcode": "LOV",
    "stationname": "Monclova Airport",
    "lat": "265715N",
    "long": "1012813W"
  },
  {
    "stationcode": "TAP",
    "stationname": "Tapachula International Airport",
    "lat": "144740N",
    "long": "0922212W"
  },
  {
    "stationcode": "AZP",
    "stationname": "Atizapan Airport",
    "lat": "193600N",
    "long": "0991800W"
  },
  {
    "stationcode": "MTT",
    "stationname": "Minatitlan Airport",
    "lat": "180612N",
    "long": "0943450W"
  },
  {
    "stationcode": "TUY",
    "stationname": "Tulum Airport",
    "lat": "201300N",
    "long": "0872800W"
  },
  {
    "stationcode": "PVR",
    "stationname": "Ordaz",
    "lat": "204112N",
    "long": "1051554W"
  },
  {
    "stationcode": "GDL",
    "stationname": "Miguel Hidal Airport",
    "lat": "203126N",
    "long": "1031845W"
  },
  {
    "stationcode": "MMC",
    "stationname": "Ciudad Mante Airport",
    "lat": "224500N",
    "long": "0990000W"
  },
  {
    "stationcode": "SCX",
    "stationname": "Salina Cruz Airport",
    "lat": "161000N",
    "long": "0951200W"
  },
  {
    "stationcode": "CJT",
    "stationname": "Copalar Airport",
    "lat": "161000N",
    "long": "0920300W"
  },
  {
    "stationcode": "CVM",
    "stationname": "Ciudad Victoria Airport",
    "lat": "234212N",
    "long": "0985723W"
  },
  {
    "stationcode": "ZIH",
    "stationname": "Extapa/Zihuatanejo Internacional Airport",
    "lat": "173606N",
    "long": "1012738W"
  },
  {
    "stationcode": "UPN",
    "stationname": "Uruapan Airport",
    "lat": "192348N",
    "long": "1020221W"
  },
  {
    "stationcode": "CZA",
    "stationname": "Chichen Itza Airport",
    "lat": "204000N",
    "long": "0883400W"
  },
  {
    "stationcode": "CTM",
    "stationname": "Chetumal Airport",
    "lat": "183017N",
    "long": "0881937W"
  },
  {
    "stationcode": "VER",
    "stationname": "Las Bajadas Airport",
    "lat": "190900N",
    "long": "0961100W"
  },
  {
    "stationcode": "CUN",
    "stationname": "Cancun Airport",
    "lat": "210212N",
    "long": "0865238W"
  },
  {
    "stationcode": "BJX",
    "stationname": "Del Bajio Airport",
    "lat": "205936N",
    "long": "1012851W"
  },
  {
    "stationcode": "ZCL",
    "stationname": "La Calera Airport",
    "lat": "225350N",
    "long": "1024113W"
  },
  {
    "stationcode": "NLD",
    "stationname": "Quetzalcoatl International Airport",
    "lat": "272605N",
    "long": "0993400W"
  },
  {
    "stationcode": "PBC",
    "stationname": "Huejotsingo Airport",
    "lat": "190929N",
    "long": "0982217W"
  },
  {
    "stationcode": "SZT",
    "stationname": "San Cristobal de las Casas Airport",
    "lat": "432447N",
    "long": "0034146E"
  },
  {
    "stationcode": "CZM",
    "stationname": "Cozumel Airport",
    "lat": "203118N",
    "long": "0865613W"
  },
  {
    "stationcode": "TSL",
    "stationname": "Tamuin Airport",
    "lat": "215900N",
    "long": "0984500W"
  },
  {
    "stationcode": "NTR",
    "stationname": "Aeropuerto Del Norte",
    "lat": "255157N",
    "long": "1001414W"
  },
  {
    "stationcode": "HUX",
    "stationname": "Huatulco Airport",
    "lat": "154631N",
    "long": "0961545W"
  },
  {
    "stationcode": "PQM",
    "stationname": "Palenque Airport",
    "lat": "173202N",
    "long": "0915905W"
  },
  {
    "stationcode": "NLU",
    "stationname": "Santa Lucia Airport",
    "lat": "194518N",
    "long": "0990102W"
  },
  {
    "stationcode": "AGU",
    "stationname": "Aguascalients Airport",
    "lat": "214200N",
    "long": "1021900W"
  },
  {
    "stationcode": "SLP",
    "stationname": "San Luis Potosi Airport",
    "lat": "221515N",
    "long": "1005551W"
  },
  {
    "stationcode": "CPE",
    "stationname": "Campeche International Airport",
    "lat": "194900N",
    "long": "0903001W"
  },
  {
    "stationcode": "QRO",
    "stationname": "Queretaro Airport",
    "lat": "203702N",
    "long": "1001108W"
  },
  {
    "stationcode": "REX",
    "stationname": "Gen Lucio Blanco Airport",
    "lat": "260045N",
    "long": "0981348W"
  },
  {
    "stationcode": "PAZ",
    "stationname": "Tajin Airport",
    "lat": "203607N",
    "long": "0972747W"
  },
  {
    "stationcode": "CLQ",
    "stationname": "Colima Airport",
    "lat": "191637N",
    "long": "1033439W"
  },
  {
    "stationcode": "ZMM",
    "stationname": "Zamora Airport",
    "lat": "195900N",
    "long": "1021600W"
  },
  {
    "stationcode": "PDS",
    "stationname": "Piedras Negras Airport",
    "lat": "283739N",
    "long": "1003207W"
  },
  {
    "stationcode": "PJZ",
    "stationname": "Puerto Juarez Airport",
    "lat": "211000N",
    "long": "0865000W"
  },
  {
    "stationcode": "TGZ",
    "stationname": "Llano San Juan Airport",
    "lat": "163343N",
    "long": "0930134W"
  },
  {
    "stationcode": "PNO",
    "stationname": "Pinotepa Nacional Airport",
    "lat": "162033N",
    "long": "0980410W"
  },
  {
    "stationcode": "MLM",
    "stationname": "Morelia Airport",
    "lat": "195000N",
    "long": "1010100W"
  },
  {
    "stationcode": "MAM",
    "stationname": "Matamoros Airport",
    "lat": "254613N",
    "long": "0973125W"
  },
  {
    "stationcode": "CYW",
    "stationname": "Celaya Airport",
    "lat": "203246N",
    "long": "1005312W"
  },
  {
    "stationcode": "PUH",
    "stationname": "Pochutla Airport",
    "lat": "154400N",
    "long": "0962800W"
  },
  {
    "stationcode": "LOM",
    "stationname": "Francisco P. V. y R. Airport",
    "lat": "211600N",
    "long": "1015600W"
  },
  {
    "stationcode": "VSA",
    "stationname": "Capitan Carlos Perez Airport",
    "lat": "175949N",
    "long": "0924903W"
  },
  {
    "stationcode": "SLW",
    "stationname": "Saltillo Airport",
    "lat": "253238N",
    "long": "1005542W"
  },
  {
    "stationcode": "IZT",
    "stationname": "Ixtepec Airport",
    "lat": "162656N",
    "long": "0950538W"
  },
  {
    "stationcode": "ACA",
    "stationname": "Alvarez International Airport",
    "lat": "164520N",
    "long": "0994601W"
  },
  {
    "stationcode": "ACN",
    "stationname": "Ciudad Acuna International Airport",
    "lat": "291959N",
    "long": "1010557W"
  },
  {
    "stationcode": "NCG",
    "stationname": "Nueva Casas Grandes Airport",
    "lat": "303100N",
    "long": "1075900W"
  },
  {
    "stationcode": "TRC",
    "stationname": "Torreon Airport",
    "lat": "253406N",
    "long": "1032438W"
  },
  {
    "stationcode": "ISJ",
    "stationname": "Isla Mujeres Airport",
    "lat": "211600N",
    "long": "0864500W"
  },
  {
    "stationcode": "AZG",
    "stationname": "Apatzingan Airport",
    "lat": "190500N",
    "long": "1022300W"
  },
  {
    "stationcode": "DGO",
    "stationname": "Guadalupe Victoria Airport",
    "lat": "240727N",
    "long": "1043141W"
  },
  {
    "stationcode": "ZLO",
    "stationname": "Manzanillo Airport",
    "lat": "190840N",
    "long": "1043329W"
  },
  {
    "stationcode": "JAL",
    "stationname": "Jalapa Airport",
    "lat": "192820N",
    "long": "0964655W"
  },
  {
    "stationcode": "TZM",
    "stationname": "Tizimin Airport",
    "lat": "211000N",
    "long": "0881000W"
  },
  {
    "stationcode": "CVJ",
    "stationname": "Cuernavaca Airport",
    "lat": "185009N",
    "long": "0991540W"
  },
  {
    "stationcode": "PCM",
    "stationname": "Playa Del Carmen Airport",
    "lat": "203300N",
    "long": "0870700W"
  },
  {
    "stationcode": "MID",
    "stationname": "Rejon Airport",
    "lat": "205613N",
    "long": "0893928W"
  },
  {
    "stationcode": "PXM",
    "stationname": "Puerto Escondido Airport",
    "lat": "155237N",
    "long": "0970521W"
  },
  {
    "stationcode": "LZC",
    "stationname": "Lazaro Cardenas Airport",
    "lat": "180000N",
    "long": "1021300W"
  },
  {
    "stationcode": "OAX",
    "stationname": "Xoxocotlan Airport",
    "lat": "170010N",
    "long": "0964330W"
  },
  {
    "stationcode": "CME",
    "stationname": "Ciudad Del Carmen Airport",
    "lat": "183912N",
    "long": "0914804W"
  },
  {
    "stationcode": "MTY",
    "stationname": "Gen Mariano Escobedo Airport",
    "lat": "254600N",
    "long": "1000600W"
  },
  {
    "stationcode": "TLC",
    "stationname": "Toluca Airport",
    "lat": "192013N",
    "long": "0993358W"
  },
  {
    "stationcode": "MZT",
    "stationname": "Gen Rafael Buelna Airport",
    "lat": "230900N",
    "long": "1061500W"
  },
  {
    "stationcode": "VIB",
    "stationname": "Villa Constitucion Airport",
    "lat": "250330N",
    "long": "1114200W"
  },
  {
    "stationcode": "CJS",
    "stationname": "Abraham Gonzalez International Airport",
    "lat": "313814N",
    "long": "1062543W"
  },
  {
    "stationcode": "PCO",
    "stationname": "Punta Colorada Airport",
    "lat": "233500N",
    "long": "1093000W"
  },
  {
    "stationcode": "SGM",
    "stationname": "San Ignacio Airport",
    "lat": "271700N",
    "long": "1125600W"
  },
  {
    "stationcode": "SJD",
    "stationname": "Los Cabos Airport",
    "lat": "231000N",
    "long": "1094200W"
  },
  {
    "stationcode": "SRL",
    "stationname": "Santa Rosalia Airport",
    "lat": "272000N",
    "long": "1121600W"
  },
  {
    "stationcode": "LAP",
    "stationname": "Leon Airport",
    "lat": "240422N",
    "long": "1102145W"
  },
  {
    "stationcode": "LTO",
    "stationname": "Loreto Airport",
    "lat": "255921N",
    "long": "1112054W"
  },
  {
    "stationcode": "CUA",
    "stationname": "Ciudad Constitucion Airport",
    "lat": "250300N",
    "long": "1113900W"
  },
  {
    "stationcode": "CUU",
    "stationname": "Gen Fierro Villalobos Airport",
    "lat": "284210N",
    "long": "1055752W"
  },
  {
    "stationcode": "CUL",
    "stationname": "Fedl De Bachigualato Airport",
    "lat": "244552N",
    "long": "1072829W"
  },
  {
    "stationcode": "TPQ",
    "stationname": "Tepic Airport",
    "lat": "212510N",
    "long": "1045033W"
  },
  {
    "stationcode": "MUG",
    "stationname": "Mulege Airport",
    "lat": "265315N",
    "long": "1120000W"
  },
  {
    "stationcode": "LMM",
    "stationname": "Federal Airport",
    "lat": "254107N",
    "long": "1090451W"
  },
  {
    "stationcode": "SNQ",
    "stationname": "San Quintin Airport",
    "lat": "303000N",
    "long": "1160000W"
  },
  {
    "stationcode": "PCV",
    "stationname": "Punta Chivato Airport",
    "lat": "270500N",
    "long": "1120000W"
  },
  {
    "stationcode": "TIJ",
    "stationname": "Rodriguez Airport",
    "lat": "323229N",
    "long": "1165813W"
  },
  {
    "stationcode": "SFH",
    "stationname": "San Felipe Airport",
    "lat": "305549N",
    "long": "1144831W"
  },
  {
    "stationcode": "BHL",
    "stationname": "Bahai Angeles Airport",
    "lat": "285700N",
    "long": "1133300W"
  },
  {
    "stationcode": "GUB",
    "stationname": "Guerrero Negro Airport",
    "lat": "280200N",
    "long": "1140400W"
  },
  {
    "stationcode": "MXL",
    "stationname": "Mexicali Airport",
    "lat": "323800N",
    "long": "1151400W"
  },
  {
    "stationcode": "AJS",
    "stationname": "Abreojos Airport",
    "lat": "264400N",
    "long": "1133500W"
  },
  {
    "stationcode": "ESE",
    "stationname": "Ensenada Airport",
    "lat": "314800N",
    "long": "1163600W"
  },
  {
    "stationcode": "WHK",
    "stationname": "Whakatane Airport",
    "lat": "375520S",
    "long": "1765459E"
  },
  {
    "stationcode": "WIR",
    "stationname": "Wairoa Airport",
    "lat": "390200S",
    "long": "1772500E"
  },
  {
    "stationcode": "KBZ",
    "stationname": "Kaikoura Airport",
    "lat": "422500S",
    "long": "1733600E"
  },
  {
    "stationcode": "PPQ",
    "stationname": "Paraparaumu Airport",
    "lat": "405404S",
    "long": "1745917E"
  },
  {
    "stationcode": "PMR",
    "stationname": "Palmerston North Airport",
    "lat": "401912S",
    "long": "1753649E"
  },
  {
    "stationcode": "BHE",
    "stationname": "Blenheim Airport",
    "lat": "413104S",
    "long": "1735209E"
  },
  {
    "stationcode": "MTA",
    "stationname": "Matamata Airport",
    "lat": "374400S",
    "long": "1754500E"
  },
  {
    "stationcode": "ROT",
    "stationname": "Rotorua Airport",
    "lat": "380625S",
    "long": "1761859E"
  },
  {
    "stationcode": "ALR",
    "stationname": "Alexandra Airport",
    "lat": "451300S",
    "long": "1692200E"
  },
  {
    "stationcode": "WGN",
    "stationname": "Waitangi Airport",
    "lat": "361601S",
    "long": "1740600E"
  },
  {
    "stationcode": "TUO",
    "stationname": "Taupo Airport",
    "lat": "384427S",
    "long": "1760506E"
  },
  {
    "stationcode": "KKO",
    "stationname": "Kaikohe Airport",
    "lat": "352700S",
    "long": "1734900E"
  },
  {
    "stationcode": "IVC",
    "stationname": "Invercargill Airport",
    "lat": "462453S",
    "long": "1681847E"
  },
  {
    "stationcode": "WKA",
    "stationname": "Wanaka Airport",
    "lat": "444319S",
    "long": "1691447E"
  },
  {
    "stationcode": "TMZ",
    "stationname": "Thames Airport",
    "lat": "370800S",
    "long": "1753300E"
  },
  {
    "stationcode": "ASG",
    "stationname": "Ashburton Airport",
    "lat": "435400S",
    "long": "1714700E"
  },
  {
    "stationcode": "WTZ",
    "stationname": "Whitianga Airport",
    "lat": "365000S",
    "long": "1754200E"
  },
  {
    "stationcode": "TIU",
    "stationname": "Timaru Airport",
    "lat": "441800S",
    "long": "1711329E"
  },
  {
    "stationcode": "GMN",
    "stationname": "Greymouth Airport",
    "lat": "422800S",
    "long": "1711100E"
  },
  {
    "stationcode": "WLG",
    "stationname": "Wellington International Airport",
    "lat": "411937S",
    "long": "1744825E"
  },
  {
    "stationcode": "WHO",
    "stationname": "Franz Josef Airport",
    "lat": "431800S",
    "long": "1700100E"
  },
  {
    "stationcode": "THH",
    "stationname": "Taharoa Airport",
    "lat": "372100S",
    "long": "1744900E"
  },
  {
    "stationcode": "AMZ",
    "stationname": "Ardmore Airport",
    "lat": "370200S",
    "long": "1745810E"
  },
  {
    "stationcode": "KTF",
    "stationname": "Takaka Airport",
    "lat": "405100S",
    "long": "1724800E"
  },
  {
    "stationcode": "HLZ",
    "stationname": "Hamilton Airport",
    "lat": "375200S",
    "long": "1751955E"
  },
  {
    "stationcode": "HKK",
    "stationname": "Hokitika Airport",
    "lat": "424241S",
    "long": "1705903E"
  },
  {
    "stationcode": "TWZ",
    "stationname": "Pukaki/Twizel Airport",
    "lat": "441400S",
    "long": "1700700E"
  },
  {
    "stationcode": "TKZ",
    "stationname": "Tokoroa Airport",
    "lat": "381400S",
    "long": "1755200E"
  },
  {
    "stationcode": "KWU",
    "stationname": "Mansion House Airport",
    "lat": "362500S",
    "long": "1745100E"
  },
  {
    "stationcode": "MHB",
    "stationname": "Mechanics Bay Airport",
    "lat": "365100S",
    "long": "1744700E"
  },
  {
    "stationcode": "MFN",
    "stationname": "Milford Sound Airport",
    "lat": "444000S",
    "long": "1675500E"
  },
  {
    "stationcode": "DGR",
    "stationname": "Dargaville Airport",
    "lat": "355600S",
    "long": "1735200E"
  },
  {
    "stationcode": "ZQN",
    "stationname": "Frankton Airport",
    "lat": "450106S",
    "long": "1684448E"
  },
  {
    "stationcode": "WIK",
    "stationname": "Surfdale Airport",
    "lat": "365000S",
    "long": "1750500E"
  },
  {
    "stationcode": "TRG",
    "stationname": "Tauranga Airport",
    "lat": "374024S",
    "long": "1761155E"
  },
  {
    "stationcode": "PCN",
    "stationname": "Koromiko Airport",
    "lat": "412050S",
    "long": "1735722E"
  },
  {
    "stationcode": "NSN",
    "stationname": "Nelson Airport",
    "lat": "411748S",
    "long": "1731327E"
  },
  {
    "stationcode": "OAM",
    "stationname": "Oamaru Airport",
    "lat": "445806S",
    "long": "1710448E"
  },
  {
    "stationcode": "MRO",
    "stationname": "Masterton Airport",
    "lat": "405830S",
    "long": "1753758E"
  },
  {
    "stationcode": "MON",
    "stationname": "Mount Cook Airport",
    "lat": "434700S",
    "long": "1700800E"
  },
  {
    "stationcode": "GBZ",
    "stationname": "Great Barrier Island Airport",
    "lat": "361000S",
    "long": "1752000E"
  },
  {
    "stationcode": "RAG",
    "stationname": "Raglan Airport",
    "lat": "374800S",
    "long": "1745300E"
  },
  {
    "stationcode": "DUD",
    "stationname": "Dunedin Airport",
    "lat": "455545S",
    "long": "1701151E"
  },
  {
    "stationcode": "WSZ",
    "stationname": "Westport Airport",
    "lat": "414413S",
    "long": "1713445E"
  },
  {
    "stationcode": "PKL",
    "stationname": "Pakatoa Island Airport",
    "lat": "362100S",
    "long": "1740900E"
  },
  {
    "stationcode": "GBS",
    "stationname": "Port Fitzroy Airport",
    "lat": "361000S",
    "long": "1752100E"
  },
  {
    "stationcode": "FGL",
    "stationname": "Fox Glacier Airport",
    "lat": "432400S",
    "long": "1700200E"
  },
  {
    "stationcode": "WRE",
    "stationname": "Whangarei Airport",
    "lat": "354608S",
    "long": "1742150E"
  },
  {
    "stationcode": "NPE",
    "stationname": "Hawkes Bay Airport",
    "lat": "392752S",
    "long": "1765204E"
  },
  {
    "stationcode": "WAG",
    "stationname": "Wanganui Airport",
    "lat": "395745S",
    "long": "1750123E"
  },
  {
    "stationcode": "SZS",
    "stationname": "Stewart Island Airport",
    "lat": "465359S",
    "long": "1680604E"
  },
  {
    "stationcode": "KAT",
    "stationname": "Kaitaia Airport",
    "lat": "350416S",
    "long": "1731713E"
  },
  {
    "stationcode": "TEU",
    "stationname": "Manapouri Airport",
    "lat": "453158S",
    "long": "1673902E"
  },
  {
    "stationcode": "NPL",
    "stationname": "New Plymouth Airport",
    "lat": "390029S",
    "long": "1741039E"
  },
  {
    "stationcode": "MZP",
    "stationname": "Motueka Airport",
    "lat": "410700S",
    "long": "1730000E"
  },
  {
    "stationcode": "CMV",
    "stationname": "Coromandel Airport",
    "lat": "364500S",
    "long": "1753000E"
  },
  {
    "stationcode": "GTN",
    "stationname": "Glentanner Airport",
    "lat": "435400S",
    "long": "1700700E"
  },
  {
    "stationcode": "KUI",
    "stationname": "Kawau Island Airport",
    "lat": "362800S",
    "long": "1745000E"
  },
  {
    "stationcode": "KKE",
    "stationname": "Kerikeri Airport",
    "lat": "351528S",
    "long": "1735443E"
  },
  {
    "stationcode": "GIS",
    "stationname": "Gisborne Airport",
    "lat": "383950S",
    "long": "1775837E"
  },
  {
    "stationcode": "CHT",
    "stationname": "Karewa Airport",
    "lat": "434843S",
    "long": "1762754W"
  },
  {
    "stationcode": "NHV",
    "stationname": "Nuku Hiva Airport",
    "lat": "084744S",
    "long": "1401344W"
  },
  {
    "stationcode": "AUQ",
    "stationname": "Atuona Airport",
    "lat": "094608S",
    "long": "1390041W"
  },
  {
    "stationcode": "HIX",
    "stationname": "Hiva Oa Airport",
    "lat": "094500S",
    "long": "1390000W"
  },
  {
    "stationcode": "UAH",
    "stationname": "Ua Huka Airport",
    "lat": "085610S",
    "long": "1393318W"
  },
  {
    "stationcode": "UAP",
    "stationname": "Ua Pou Airport",
    "lat": "092051S",
    "long": "1400447W"
  },
  {
    "stationcode": "AAA",
    "stationname": "Anaa Airport",
    "lat": "172109S",
    "long": "1453036W"
  },
  {
    "stationcode": "TIH",
    "stationname": "Tikehau Atoll Airport",
    "lat": "150713S",
    "long": "1481359W"
  },
  {
    "stationcode": "RFP",
    "stationname": "Raiatea Airport",
    "lat": "164300S",
    "long": "1512800W"
  },
  {
    "stationcode": "MVT",
    "stationname": "Mataiva Airport",
    "lat": "145211S",
    "long": "1484247W"
  },
  {
    "stationcode": "TUB",
    "stationname": "Tubuai Island Airport",
    "lat": "232155S",
    "long": "1493127W"
  },
  {
    "stationcode": "MKP",
    "stationname": "Makemo Airport",
    "lat": "163501S",
    "long": "1433928W"
  },
  {
    "stationcode": "APK",
    "stationname": "Apataki Airport",
    "lat": "153425S",
    "long": "1462455W"
  },
  {
    "stationcode": "AHE",
    "stationname": "Ahe Airport",
    "lat": "142541S",
    "long": "1461525W"
  },
  {
    "stationcode": "NAU",
    "stationname": "Napuka Island Airport",
    "lat": "141037S",
    "long": "1411602W"
  },
  {
    "stationcode": "NIU",
    "stationname": "Niau Airport",
    "lat": "160715S",
    "long": "1462225W"
  },
  {
    "stationcode": "XMH",
    "stationname": "Manihi Airport",
    "lat": "142612S",
    "long": "1460412W"
  },
  {
    "stationcode": "NKP",
    "stationname": "Nukutepipi Airport",
    "lat": "202713S",
    "long": "1433146W"
  },
  {
    "stationcode": "HHZ",
    "stationname": "Hikueru Airport",
    "lat": "173253S",
    "long": "1423648W"
  },
  {
    "stationcode": "TKX",
    "stationname": "Takaroa Airport",
    "lat": "142721S",
    "long": "1450128W"
  },
  {
    "stationcode": "HUH",
    "stationname": "Huahine Airport",
    "lat": "164114S",
    "long": "1510118W"
  },
  {
    "stationcode": "NUK",
    "stationname": "Nukutavake Airport",
    "lat": "191706S",
    "long": "1384611W"
  },
  {
    "stationcode": "TJN",
    "stationname": "Takume Airport",
    "lat": "155120S",
    "long": "1421606W"
  },
  {
    "stationcode": "FAV",
    "stationname": "Fakarava Airport",
    "lat": "160315S",
    "long": "1453926W"
  },
  {
    "stationcode": "KKR",
    "stationname": "Kaukura Atoll Airport",
    "lat": "153948S",
    "long": "1465305W"
  },
  {
    "stationcode": "RMT",
    "stationname": "Rimatara Airport",
    "lat": "223815S",
    "long": "1524819W"
  },
  {
    "stationcode": "TKP",
    "stationname": "Takapoto Airport",
    "lat": "144234S",
    "long": "1451445W"
  },
  {
    "stationcode": "RRR",
    "stationname": "Raroia Airport",
    "lat": "160242S",
    "long": "1422837W"
  },
  {
    "stationcode": "MOZ",
    "stationname": "Temae Airport",
    "lat": "172900S",
    "long": "1494600W"
  },
  {
    "stationcode": "RKA",
    "stationname": "Aratika Airport",
    "lat": "152907S",
    "long": "1452804W"
  },
  {
    "stationcode": "RVV",
    "stationname": "Raivavae Airport",
    "lat": "235307S",
    "long": "1473943W"
  },
  {
    "stationcode": "KXU",
    "stationname": "Katiu Airport",
    "lat": "162022S",
    "long": "1442411W"
  },
  {
    "stationcode": "KHZ",
    "stationname": "Kauehi Airport",
    "lat": "154651S",
    "long": "1450727W"
  },
  {
    "stationcode": "BOB",
    "stationname": "Motu-mute Airport",
    "lat": "162640S",
    "long": "1514505W"
  },
  {
    "stationcode": "FGU",
    "stationname": "Fangatau Airport",
    "lat": "154912S",
    "long": "1405309W"
  },
  {
    "stationcode": "MAU",
    "stationname": "Maupiti Airport",
    "lat": "162535S",
    "long": "1521437W"
  },
  {
    "stationcode": "FHZ",
    "stationname": "Fakahina Airport",
    "lat": "155932S",
    "long": "1400953W"
  },
  {
    "stationcode": "PUK",
    "stationname": "Pukarua Airport",
    "lat": "181746S",
    "long": "1370057W"
  },
  {
    "stationcode": "TKV",
    "stationname": "Tuamoto  Airport",
    "lat": "172120S",
    "long": "1382655W"
  },
  {
    "stationcode": "RGI",
    "stationname": "Rangiroa Airport",
    "lat": "145715S",
    "long": "1473939W"
  },
  {
    "stationcode": "HOI",
    "stationname": "Hao Island Airport",
    "lat": "180429S",
    "long": "1405645W"
  },
  {
    "stationcode": "AXR",
    "stationname": "Arutua Airport",
    "lat": "151454S",
    "long": "1463700W"
  },
  {
    "stationcode": "FAC",
    "stationname": "Faaite Airport",
    "lat": "164112S",
    "long": "1451947W"
  },
  {
    "stationcode": "REA",
    "stationname": "Reao Airport",
    "lat": "182758S",
    "long": "1362622W"
  },
  {
    "stationcode": "TTI",
    "stationname": "Tetiaroa Island Airport",
    "lat": "170500S",
    "long": "1493200W"
  },
  {
    "stationcode": "TPX",
    "stationname": "Tupai Airport",
    "lat": "161400S",
    "long": "1515000W"
  },
  {
    "stationcode": "PPT",
    "stationname": "Faaa Airport",
    "lat": "173314S",
    "long": "1493626W"
  },
  {
    "stationcode": "PKP",
    "stationname": "Puka Puka Airport",
    "lat": "144834S",
    "long": "1384846W"
  },
  {
    "stationcode": "RUR",
    "stationname": "Rurutu Airport",
    "lat": "222600S",
    "long": "1512000W"
  },
  {
    "stationcode": "VHZ",
    "stationname": "Vahitahi Airport",
    "lat": "184647S",
    "long": "1385112W"
  },
  {
    "stationcode": "UOA",
    "stationname": "Moruroa Atoll Airport",
    "lat": "214835S",
    "long": "1384844W"
  },
  {
    "stationcode": "ZTA",
    "stationname": "Tureia Airport",
    "lat": "204650S",
    "long": "1383403W"
  },
  {
    "stationcode": "GMR",
    "stationname": "Gambier Island Airport",
    "lat": "230448S",
    "long": "1345325W"
  },
  {
    "stationcode": "KMR",
    "stationname": "Karimui Airport",
    "lat": "063000S",
    "long": "1445000E"
  },
  {
    "stationcode": "HAZ",
    "stationname": "Hatzfeldthaven Airport",
    "lat": "042000S",
    "long": "1451000E"
  },
  {
    "stationcode": "KGB",
    "stationname": "Konge Airport",
    "lat": "060800S",
    "long": "1473900E"
  },
  {
    "stationcode": "RNR",
    "stationname": "Robinson River Airport",
    "lat": "100900S",
    "long": "1484900E"
  },
  {
    "stationcode": "PUI",
    "stationname": "Pureni Airport",
    "lat": "055000S",
    "long": "1425000E"
  },
  {
    "stationcode": "PGN",
    "stationname": "Pangia Airport",
    "lat": "062100S",
    "long": "1440700E"
  },
  {
    "stationcode": "DOO",
    "stationname": "Dorobisoro Airport",
    "lat": "093000S",
    "long": "1474800E"
  },
  {
    "stationcode": "IHU",
    "stationname": "Ihu Airport",
    "lat": "075400S",
    "long": "1452400E"
  },
  {
    "stationcode": "HGU",
    "stationname": "Kagamuga Airport",
    "lat": "054936S",
    "long": "1441745E"
  },
  {
    "stationcode": "TFB",
    "stationname": "Tifalmin Airport",
    "lat": "050600S",
    "long": "1412700E"
  },
  {
    "stationcode": "TSW",
    "stationname": "Tsewi Airport",
    "lat": "065800S",
    "long": "1460200E"
  },
  {
    "stationcode": "EFG",
    "stationname": "Efogi Airport",
    "lat": "090900S",
    "long": "1474000E"
  },
  {
    "stationcode": "BUL",
    "stationname": "Bulolo Airport",
    "lat": "071259S",
    "long": "1463900E"
  },
  {
    "stationcode": "IBI",
    "stationname": "Iboki Airport",
    "lat": "053310S",
    "long": "1491120E"
  },
  {
    "stationcode": "AMF",
    "stationname": "Ama Airport",
    "lat": "041000S",
    "long": "1414000E"
  },
  {
    "stationcode": "WWK",
    "stationname": "Boram Airport",
    "lat": "033502S",
    "long": "1434009E"
  },
  {
    "stationcode": "MJJ",
    "stationname": "Moki Airport",
    "lat": "054200S",
    "long": "1451500E"
  },
  {
    "stationcode": "EMI",
    "stationname": "Emirau Airport",
    "lat": "013900S",
    "long": "1495800E"
  },
  {
    "stationcode": "SMH",
    "stationname": "Sapmanga Airport",
    "lat": "060500S",
    "long": "1464500E"
  },
  {
    "stationcode": "DPU",
    "stationname": "Dumpu Airport",
    "lat": "055000S",
    "long": "1454500E"
  },
  {
    "stationcode": "LAB",
    "stationname": "Lablab Airport",
    "lat": "053800S",
    "long": "1480400E"
  },
  {
    "stationcode": "KQL",
    "stationname": "Kol Airport",
    "lat": "054400S",
    "long": "1445100E"
  },
  {
    "stationcode": "TKW",
    "stationname": "Tekin Airport",
    "lat": "051500S",
    "long": "1420900E"
  },
  {
    "stationcode": "TFM",
    "stationname": "Telefomin Airport",
    "lat": "050800S",
    "long": "1413900E"
  },
  {
    "stationcode": "KSG",
    "stationname": "Kisengan Airport",
    "lat": "062100S",
    "long": "1464300E"
  },
  {
    "stationcode": "CVL",
    "stationname": "Cape Vogel Airport",
    "lat": "094200S",
    "long": "1500300E"
  },
  {
    "stationcode": "BOT",
    "stationname": "Boset Airport",
    "lat": "071400S",
    "long": "1410500E"
  },
  {
    "stationcode": "KMA",
    "stationname": "Kerema Airport",
    "lat": "075749S",
    "long": "1454617E"
  },
  {
    "stationcode": "TLP",
    "stationname": "Tumolbil Airport",
    "lat": "044700S",
    "long": "1410100E"
  },
  {
    "stationcode": "LNQ",
    "stationname": "Loani Airport",
    "lat": "102600S",
    "long": "1500900E"
  },
  {
    "stationcode": "UNG",
    "stationname": "Kiunga Airport",
    "lat": "060730S",
    "long": "1411708E"
  },
  {
    "stationcode": "WAO",
    "stationname": "Wabo Airport",
    "lat": "065922S",
    "long": "1450431E"
  },
  {
    "stationcode": "AGK",
    "stationname": "Kagua Airport",
    "lat": "062000S",
    "long": "1435000E"
  },
  {
    "stationcode": "FAQ",
    "stationname": "Freida River Airport",
    "lat": "043700S",
    "long": "1415730E"
  },
  {
    "stationcode": "KEX",
    "stationname": "Kanabea Airport",
    "lat": "074600S",
    "long": "1455400E"
  },
  {
    "stationcode": "WBM",
    "stationname": "Wapenamanda Airport",
    "lat": "053806S",
    "long": "1435330E"
  },
  {
    "stationcode": "KWX",
    "stationname": "Kiwai Island Airport",
    "lat": "083000S",
    "long": "1432500E"
  },
  {
    "stationcode": "LMY",
    "stationname": "Lake Murray Airport",
    "lat": "065100S",
    "long": "1412500E"
  },
  {
    "stationcode": "DAU",
    "stationname": "Daru Airport",
    "lat": "090512S",
    "long": "1431229E"
  },
  {
    "stationcode": "FUM",
    "stationname": "Fuma Airport",
    "lat": "062300S",
    "long": "1422600E"
  },
  {
    "stationcode": "MHY",
    "stationname": "Morehead Airport",
    "lat": "084300S",
    "long": "1413800E"
  },
  {
    "stationcode": "SIZ",
    "stationname": "Sissano Airport",
    "lat": "030000S",
    "long": "1420300E"
  },
  {
    "stationcode": "KYX",
    "stationname": "Yalumet Airport",
    "lat": "060800S",
    "long": "1463600E"
  },
  {
    "stationcode": "KPM",
    "stationname": "Kompiam Airport",
    "lat": "052300S",
    "long": "1435600E"
  },
  {
    "stationcode": "AYU",
    "stationname": "Aiyura Airport",
    "lat": "062000S",
    "long": "1455400E"
  },
  {
    "stationcode": "GAZ",
    "stationname": "Guasopa Airport",
    "lat": "091400S",
    "long": "1525700E"
  },
  {
    "stationcode": "SBC",
    "stationname": "Selbang Airport",
    "lat": "051900S",
    "long": "1414500E"
  },
  {
    "stationcode": "DAO",
    "stationname": "Dabo Airport",
    "lat": "082600S",
    "long": "1475058E"
  },
  {
    "stationcode": "LTF",
    "stationname": "Leitre Airport",
    "lat": "025000S",
    "long": "1413700E"
  },
  {
    "stationcode": "ZEN",
    "stationname": "Zenag Airport",
    "lat": "065700S",
    "long": "1463700E"
  },
  {
    "stationcode": "KDP",
    "stationname": "Kandep Airport",
    "lat": "055200S",
    "long": "1433000E"
  },
  {
    "stationcode": "WSU",
    "stationname": "Wasu Airport",
    "lat": "055900S",
    "long": "1471100E"
  },
  {
    "stationcode": "KOR",
    "stationname": "Kokoro Airport",
    "lat": "074900S",
    "long": "1463300E"
  },
  {
    "stationcode": "AWR",
    "stationname": "Awar Airport",
    "lat": "040700S",
    "long": "1445100E"
  },
  {
    "stationcode": "NBA",
    "stationname": "Nambaiyufa Airport",
    "lat": "061500S",
    "long": "1451400E"
  },
  {
    "stationcode": "MIS",
    "stationname": "Misima Island Airport",
    "lat": "104122S",
    "long": "1525010E"
  },
  {
    "stationcode": "WED",
    "stationname": "Wedau Airport",
    "lat": "100600S",
    "long": "1500500E"
  },
  {
    "stationcode": "IMN",
    "stationname": "Imane Airport",
    "lat": "064200S",
    "long": "1460500E"
  },
  {
    "stationcode": "EGA",
    "stationname": "Engati Airport",
    "lat": "065500S",
    "long": "1460600E"
  },
  {
    "stationcode": "BVP",
    "stationname": "Bolovip Airport",
    "lat": "052300S",
    "long": "1414200E"
  },
  {
    "stationcode": "ARP",
    "stationname": "Aragip Airport",
    "lat": "095400S",
    "long": "1492800E"
  },
  {
    "stationcode": "ULE",
    "stationname": "Sule Airport",
    "lat": "045800S",
    "long": "1511900E"
  },
  {
    "stationcode": "KOM",
    "stationname": "Komo Airport",
    "lat": "060854S",
    "long": "1433927E"
  },
  {
    "stationcode": "MXK",
    "stationname": "Mindik Airport",
    "lat": "062819S",
    "long": "1472628E"
  },
  {
    "stationcode": "MKN",
    "stationname": "Malekolon Airport",
    "lat": "040100S",
    "long": "1534030E"
  },
  {
    "stationcode": "MFO",
    "stationname": "Manguna Airport",
    "lat": "053400S",
    "long": "1514700E"
  },
  {
    "stationcode": "WUG",
    "stationname": "Wau Airport",
    "lat": "072000S",
    "long": "1464300E"
  },
  {
    "stationcode": "IMA",
    "stationname": "Iamalele Airport",
    "lat": "093000S",
    "long": "1503000E"
  },
  {
    "stationcode": "GBF",
    "stationname": "Negarbo Airport",
    "lat": "063500S",
    "long": "1444000E"
  },
  {
    "stationcode": "SUZ",
    "stationname": "Suria Airport",
    "lat": "071000S",
    "long": "1435500E"
  },
  {
    "stationcode": "CVB",
    "stationname": "Chungribu Airport",
    "lat": "044800S",
    "long": "1444200E"
  },
  {
    "stationcode": "RKU",
    "stationname": "Kairuku Airport",
    "lat": "084900S",
    "long": "1463100E"
  },
  {
    "stationcode": "BAA",
    "stationname": "Bialla Airport",
    "lat": "051950S",
    "long": "1510029E"
  },
  {
    "stationcode": "NGR",
    "stationname": "Ningerum Airport",
    "lat": "054000S",
    "long": "1410700E"
  },
  {
    "stationcode": "NOO",
    "stationname": "Naoro Airport",
    "lat": "090500S",
    "long": "1470200E"
  },
  {
    "stationcode": "CGC",
    "stationname": "Cape Gloucester Airport",
    "lat": "052733S",
    "long": "1482557E"
  },
  {
    "stationcode": "IOP",
    "stationname": "Ioma Airport",
    "lat": "082200S",
    "long": "1475100E"
  },
  {
    "stationcode": "VIV",
    "stationname": "Vivigani Airport",
    "lat": "091800S",
    "long": "1501900E"
  },
  {
    "stationcode": "IMD",
    "stationname": "Imonda Airport",
    "lat": "032100S",
    "long": "1411000E"
  },
  {
    "stationcode": "KVG",
    "stationname": "Kavieng Airport",
    "lat": "023446S",
    "long": "1504829E"
  },
  {
    "stationcode": "PMP",
    "stationname": "Pimaga Airport",
    "lat": "062200S",
    "long": "1433300E"
  },
  {
    "stationcode": "SMJ",
    "stationname": "Sim Airport",
    "lat": "074000S",
    "long": "1465000E"
  },
  {
    "stationcode": "BCP",
    "stationname": "Bambu Airport",
    "lat": "055100S",
    "long": "1462900E"
  },
  {
    "stationcode": "YVD",
    "stationname": "Yeva Airport",
    "lat": "073300S",
    "long": "1461000E"
  },
  {
    "stationcode": "KJU",
    "stationname": "Kamiraba Airport",
    "lat": "025300S",
    "long": "1511500E"
  },
  {
    "stationcode": "XBN",
    "stationname": "Biniguni Airport",
    "lat": "093800S",
    "long": "1491900E"
  },
  {
    "stationcode": "SGJ",
    "stationname": "Sagarai Airport",
    "lat": "102300S",
    "long": "1495000E"
  },
  {
    "stationcode": "SXA",
    "stationname": "Sialum Airport",
    "lat": "060600S",
    "long": "1473600E"
  },
  {
    "stationcode": "MNP",
    "stationname": "Maron Airport",
    "lat": "013300S",
    "long": "1450200E"
  },
  {
    "stationcode": "ILX",
    "stationname": "Ileg Airport",
    "lat": "052900S",
    "long": "1455000E"
  },
  {
    "stationcode": "KNL",
    "stationname": "Kelanoa Airport",
    "lat": "060000S",
    "long": "1473000E"
  },
  {
    "stationcode": "GBC",
    "stationname": "Gasuke Airport",
    "lat": "060600S",
    "long": "1414400E"
  },
  {
    "stationcode": "TDB",
    "stationname": "Tetabedi Airport",
    "lat": "091200S",
    "long": "1480500E"
  },
  {
    "stationcode": "WOA",
    "stationname": "Wonenara Airport",
    "lat": "064800S",
    "long": "1455300E"
  },
  {
    "stationcode": "AUV",
    "stationname": "Aumo Airport",
    "lat": "054300S",
    "long": "1482600E"
  },
  {
    "stationcode": "NOM",
    "stationname": "Nomad River Airport",
    "lat": "063900S",
    "long": "1420700E"
  },
  {
    "stationcode": "LGN",
    "stationname": "Linga Linga Airport",
    "lat": "054100S",
    "long": "1492500E"
  },
  {
    "stationcode": "WBC",
    "stationname": "Wapolu Airport",
    "lat": "091900S",
    "long": "1502100E"
  },
  {
    "stationcode": "GOC",
    "stationname": "Gora Airport",
    "lat": "082500S",
    "long": "1474000E"
  },
  {
    "stationcode": "SDI",
    "stationname": "Saidor Airport",
    "lat": "053700S",
    "long": "1462800E"
  },
  {
    "stationcode": "BOV",
    "stationname": "Boang Airport",
    "lat": "032200S",
    "long": "1532000E"
  },
  {
    "stationcode": "RBP",
    "stationname": "Rabaraba Airport",
    "lat": "095900S",
    "long": "1495000E"
  },
  {
    "stationcode": "SFU",
    "stationname": "Safia Airport",
    "lat": "093500S",
    "long": "1483800E"
  },
  {
    "stationcode": "MDU",
    "stationname": "Mendi Airport",
    "lat": "060852S",
    "long": "1433926E"
  },
  {
    "stationcode": "SKC",
    "stationname": "Suki Airport",
    "lat": "080900S",
    "long": "1414300E"
  },
  {
    "stationcode": "AIE",
    "stationname": "Aiome Airport",
    "lat": "050800S",
    "long": "1444400E"
  },
  {
    "stationcode": "CMU",
    "stationname": "Chimbu Airport",
    "lat": "060126S",
    "long": "1445815E"
  },
  {
    "stationcode": "AGG",
    "stationname": "Angoram Airport",
    "lat": "040400S",
    "long": "1440600E"
  },
  {
    "stationcode": "AON",
    "stationname": "Arona Airport",
    "lat": "061500S",
    "long": "1455800E"
  },
  {
    "stationcode": "HIT",
    "stationname": "Hivaro Airport",
    "lat": "065631S",
    "long": "1430325E"
  },
  {
    "stationcode": "OGE",
    "stationname": "Ogeranang Airport",
    "lat": "062600S",
    "long": "1472000E"
  },
  {
    "stationcode": "ITK",
    "stationname": "Itokama Airport",
    "lat": "091203S",
    "long": "1481553E"
  },
  {
    "stationcode": "WTT",
    "stationname": "Wantoat Airport",
    "lat": "060300S",
    "long": "1462400E"
  },
  {
    "stationcode": "HEO",
    "stationname": "Haelogo Airport",
    "lat": "090800S",
    "long": "1473500E"
  },
  {
    "stationcode": "TBQ",
    "stationname": "Taraibo Airport",
    "lat": "062800S",
    "long": "1453200E"
  },
  {
    "stationcode": "RUU",
    "stationname": "Ruti Airport",
    "lat": "051800S",
    "long": "1441500E"
  },
  {
    "stationcode": "MAS",
    "stationname": "Momote Airport",
    "lat": "020354S",
    "long": "1472530E"
  },
  {
    "stationcode": "OKV",
    "stationname": "Okao Airport",
    "lat": "053340S",
    "long": "1410212E"
  },
  {
    "stationcode": "SMP",
    "stationname": "Stockholm Airport",
    "lat": "043400S",
    "long": "1515800E"
  },
  {
    "stationcode": "KIZ",
    "stationname": "Kikinonda Airport",
    "lat": "083900S",
    "long": "1475500E"
  },
  {
    "stationcode": "JAQ",
    "stationname": "Jacquinot Bay Airport",
    "lat": "053900S",
    "long": "1513000E"
  },
  {
    "stationcode": "BRP",
    "stationname": "Biaru Airport",
    "lat": "074000S",
    "long": "1462100E"
  },
  {
    "stationcode": "GVI",
    "stationname": "Green River Airport",
    "lat": "035500S",
    "long": "1411000E"
  },
  {
    "stationcode": "YEQ",
    "stationname": "Yenkis Airport",
    "lat": "050600S",
    "long": "1435500E"
  },
  {
    "stationcode": "MXH",
    "stationname": "Moro Airport",
    "lat": "062146S",
    "long": "1431352E"
  },
  {
    "stationcode": "LNV",
    "stationname": "Lihir Island Airport",
    "lat": "030233S",
    "long": "1523740E"
  },
  {
    "stationcode": "TRJ",
    "stationname": "Tarakbits Airport",
    "lat": "053700S",
    "long": "1410300E"
  },
  {
    "stationcode": "DAF",
    "stationname": "Daup Airport",
    "lat": "044400S",
    "long": "1445700E"
  },
  {
    "stationcode": "MBV",
    "stationname": "Masa Airport",
    "lat": "062100S",
    "long": "1473800E"
  },
  {
    "stationcode": "MFZ",
    "stationname": "Mesalia Airport",
    "lat": "060800S",
    "long": "1490700E"
  },
  {
    "stationcode": "IDN",
    "stationname": "Indagen Airport",
    "lat": "060200S",
    "long": "1470200E"
  },
  {
    "stationcode": "PDI",
    "stationname": "Pindiu Airport",
    "lat": "063000S",
    "long": "1473500E"
  },
  {
    "stationcode": "OBM",
    "stationname": "Morobe Airport",
    "lat": "074500S",
    "long": "1473500E"
  },
  {
    "stationcode": "GUE",
    "stationname": "Guriaso Airport",
    "lat": "033500S",
    "long": "1413500E"
  },
  {
    "stationcode": "TLW",
    "stationname": "Talasea Airport",
    "lat": "051634S",
    "long": "1500042E"
  },
  {
    "stationcode": "UAE",
    "stationname": "Mount Aue Airport",
    "lat": "061400S",
    "long": "1443900E"
  },
  {
    "stationcode": "KSB",
    "stationname": "Kasanombe Airport",
    "lat": "062300S",
    "long": "1470100E"
  },
  {
    "stationcode": "CPN",
    "stationname": "Cape Rodney Airport",
    "lat": "101100S",
    "long": "1482200E"
  },
  {
    "stationcode": "BRH",
    "stationname": "Brahman Airport",
    "lat": "054200S",
    "long": "1452200E"
  },
  {
    "stationcode": "AKG",
    "stationname": "Anguganak Airport",
    "lat": "033500S",
    "long": "1421500E"
  },
  {
    "stationcode": "FRQ",
    "stationname": "Feramin Airport",
    "lat": "052500S",
    "long": "1414200E"
  },
  {
    "stationcode": "KKD",
    "stationname": "Kokoda Airport",
    "lat": "085300S",
    "long": "1474400E"
  },
  {
    "stationcode": "OML",
    "stationname": "Omkalai Airport",
    "lat": "061100S",
    "long": "1445800E"
  },
  {
    "stationcode": "NKN",
    "stationname": "Nankina Airport",
    "lat": "055000S",
    "long": "1462700E"
  },
  {
    "stationcode": "MMV",
    "stationname": "Mal Airport",
    "lat": "012435S",
    "long": "1441113E"
  },
  {
    "stationcode": "KDE",
    "stationname": "Koroba Airport",
    "lat": "054100S",
    "long": "1424400E"
  },
  {
    "stationcode": "MWG",
    "stationname": "Marawaka Airport",
    "lat": "065800S",
    "long": "1455400E"
  },
  {
    "stationcode": "MWU",
    "stationname": "Mussau Airport",
    "lat": "013000S",
    "long": "1494000E"
  },
  {
    "stationcode": "BNM",
    "stationname": "Bodinumu Airport",
    "lat": "090500S",
    "long": "1473000E"
  },
  {
    "stationcode": "NIS",
    "stationname": "Simberi Island Airport",
    "lat": "024000S",
    "long": "1520000E"
  },
  {
    "stationcode": "BMH",
    "stationname": "Bomai Airport",
    "lat": "062200S",
    "long": "1443800E"
  },
  {
    "stationcode": "TEO",
    "stationname": "Terapo Airport",
    "lat": "080500S",
    "long": "1460400E"
  },
  {
    "stationcode": "MAG",
    "stationname": "Madang Airport",
    "lat": "051226S",
    "long": "1454719E"
  },
  {
    "stationcode": "TGL",
    "stationname": "Tagula Airport",
    "lat": "113000S",
    "long": "1533000E"
  },
  {
    "stationcode": "BWP",
    "stationname": "Bewani Airport",
    "lat": "030500S",
    "long": "1410600E"
  },
  {
    "stationcode": "OPB",
    "stationname": "Open Bay Airport",
    "lat": "044800S",
    "long": "1514200E"
  },
  {
    "stationcode": "OPU",
    "stationname": "Balimo Airport",
    "lat": "080307S",
    "long": "1425623E"
  },
  {
    "stationcode": "ABW",
    "stationname": "Abau Airport",
    "lat": "101000S",
    "long": "1484200E"
  },
  {
    "stationcode": "LPN",
    "stationname": "Leron Plains Airport",
    "lat": "062400S",
    "long": "1462100E"
  },
  {
    "stationcode": "MPF",
    "stationname": "Mapoda Airport",
    "lat": "075800S",
    "long": "1431000E"
  },
  {
    "stationcode": "BIZ",
    "stationname": "Bimin Airport",
    "lat": "051700S",
    "long": "1420100E"
  },
  {
    "stationcode": "KUY",
    "stationname": "Kamusi Airport",
    "lat": "072539S",
    "long": "1430726E"
  },
  {
    "stationcode": "SGB",
    "stationname": "Singaua Airport",
    "lat": "064200S",
    "long": "1470800E"
  },
  {
    "stationcode": "TBE",
    "stationname": "Timbunke Airport",
    "lat": "041200S",
    "long": "1433100E"
  },
  {
    "stationcode": "TIZ",
    "stationname": "Tari Airport",
    "lat": "055042S",
    "long": "1425651E"
  },
  {
    "stationcode": "VMU",
    "stationname": "Baimuru Airport",
    "lat": "073000S",
    "long": "1444800E"
  },
  {
    "stationcode": "WEP",
    "stationname": "Weam Airport",
    "lat": "084000S",
    "long": "1410810E"
  },
  {
    "stationcode": "WTP",
    "stationname": "Woitape Airport",
    "lat": "083245S",
    "long": "1471507E"
  },
  {
    "stationcode": "TDS",
    "stationname": "Sasereme Airport",
    "lat": "073723S",
    "long": "1425209E"
  },
  {
    "stationcode": "LMG",
    "stationname": "Lamassa Airport",
    "lat": "044200S",
    "long": "1524500E"
  },
  {
    "stationcode": "DGG",
    "stationname": "Daugo Airport",
    "lat": "093100S",
    "long": "1470300E"
  },
  {
    "stationcode": "APP",
    "stationname": "Asapa Airport",
    "lat": "085900S",
    "long": "1480800E"
  },
  {
    "stationcode": "ADC",
    "stationname": "Andakombe Airport",
    "lat": "070900S",
    "long": "1454500E"
  },
  {
    "stationcode": "AGL",
    "stationname": "Wanigela Airport",
    "lat": "092000S",
    "long": "1490900E"
  },
  {
    "stationcode": "NPG",
    "stationname": "Nipa Airport",
    "lat": "060800S",
    "long": "1432200E"
  },
  {
    "stationcode": "KPA",
    "stationname": "Kopiago Airport",
    "lat": "053500S",
    "long": "1452500E"
  },
  {
    "stationcode": "RAX",
    "stationname": "Oram Airport",
    "lat": "094020S",
    "long": "1480200E"
  },
  {
    "stationcode": "SGK",
    "stationname": "Sangapi Airport",
    "lat": "050500S",
    "long": "1444600E"
  },
  {
    "stationcode": "KSP",
    "stationname": "Kosipe Airport",
    "lat": "083330S",
    "long": "1472800E"
  },
  {
    "stationcode": "APR",
    "stationname": "April River Airport",
    "lat": "044037S",
    "long": "1423223E"
  },
  {
    "stationcode": "PGE",
    "stationname": "Yegepa Airport",
    "lat": "070800S",
    "long": "1460900E"
  },
  {
    "stationcode": "AWB",
    "stationname": "Awaba Airport",
    "lat": "080052S",
    "long": "1424504E"
  },
  {
    "stationcode": "PLE",
    "stationname": "Paiela Airport",
    "lat": "052300S",
    "long": "1425900E"
  },
  {
    "stationcode": "SPV",
    "stationname": "Sepik Plains Airport",
    "lat": "035400S",
    "long": "1434100E"
  },
  {
    "stationcode": "AZB",
    "stationname": "Amazon Bay Airport",
    "lat": "101800S",
    "long": "1492000E"
  },
  {
    "stationcode": "LNG",
    "stationname": "Lese Airport",
    "lat": "081000S",
    "long": "1460700E"
  },
  {
    "stationcode": "ASZ",
    "stationname": "Asirim Airport",
    "lat": "060035S",
    "long": "1502207E"
  },
  {
    "stationcode": "KNE",
    "stationname": "Kanainj Airport",
    "lat": "051700S",
    "long": "1444200E"
  },
  {
    "stationcode": "WUM",
    "stationname": "West New Britain Airport",
    "lat": "060300S",
    "long": "1492000E"
  },
  {
    "stationcode": "TLO",
    "stationname": "Tol Airport",
    "lat": "045900S",
    "long": "1520200E"
  },
  {
    "stationcode": "TPI",
    "stationname": "Tapini Airport",
    "lat": "082200S",
    "long": "1465900E"
  },
  {
    "stationcode": "GMI",
    "stationname": "Gasmata Island Airport",
    "lat": "061800S",
    "long": "1502000E"
  },
  {
    "stationcode": "GAR",
    "stationname": "Garaina Airport",
    "lat": "075200S",
    "long": "1470800E"
  },
  {
    "stationcode": "SXH",
    "stationname": "Sehulea Airport",
    "lat": "101300S",
    "long": "1502100E"
  },
  {
    "stationcode": "SWR",
    "stationname": "Silur Airport",
    "lat": "043200S",
    "long": "1530300E"
  },
  {
    "stationcode": "VAI",
    "stationname": "Vanimo Airport",
    "lat": "024133S",
    "long": "1411809E"
  },
  {
    "stationcode": "KCJ",
    "stationname": "Komaio Airport",
    "lat": "071600S",
    "long": "1433600E"
  },
  {
    "stationcode": "KSX",
    "stationname": "Yasuru Airport",
    "lat": "063600S",
    "long": "1461100E"
  },
  {
    "stationcode": "DDM",
    "stationname": "Dodoima Airport",
    "lat": "084500S",
    "long": "1472700E"
  },
  {
    "stationcode": "DOI",
    "stationname": "Doini Airport",
    "lat": "104300S",
    "long": "1504300E"
  },
  {
    "stationcode": "IOK",
    "stationname": "Iokea Airport",
    "lat": "082400S",
    "long": "1461600E"
  },
  {
    "stationcode": "MPX",
    "stationname": "Miyanmin Airport",
    "lat": "045400S",
    "long": "1413700E"
  },
  {
    "stationcode": "KGH",
    "stationname": "Yongai Airport",
    "lat": "083500S",
    "long": "1473000E"
  },
  {
    "stationcode": "FNE",
    "stationname": "Fane Airport",
    "lat": "083600S",
    "long": "1471400E"
  },
  {
    "stationcode": "RMN",
    "stationname": "Rumginae Airport",
    "lat": "055500S",
    "long": "1411800E"
  },
  {
    "stationcode": "MPG",
    "stationname": "Makini Airport",
    "lat": "063200S",
    "long": "1474300E"
  },
  {
    "stationcode": "AEK",
    "stationname": "Aseki Airport",
    "lat": "072200S",
    "long": "1461700E"
  },
  {
    "stationcode": "KII",
    "stationname": "Kibuli Airport",
    "lat": "090100S",
    "long": "1424143E"
  },
  {
    "stationcode": "MZN",
    "stationname": "Minj Airport",
    "lat": "055200S",
    "long": "1444000E"
  },
  {
    "stationcode": "KAQ",
    "stationname": "Kamulai Airport",
    "lat": "080800S",
    "long": "1464900E"
  },
  {
    "stationcode": "GEI",
    "stationname": "Green Islands Airport",
    "lat": "043000S",
    "long": "1541000E"
  },
  {
    "stationcode": "TIG",
    "stationname": "Tingwon Airport",
    "lat": "023600S",
    "long": "1494700E"
  },
  {
    "stationcode": "UUU",
    "stationname": "Manumu Airport",
    "lat": "080500S",
    "long": "1473300E"
  },
  {
    "stationcode": "DNU",
    "stationname": "Dinangat Airport",
    "lat": "060900S",
    "long": "1464000E"
  },
  {
    "stationcode": "TCK",
    "stationname": "Tinboli Airport",
    "lat": "040500S",
    "long": "1432300E"
  },
  {
    "stationcode": "BPB",
    "stationname": "Boridi Airport",
    "lat": "090800S",
    "long": "1473200E"
  },
  {
    "stationcode": "NUT",
    "stationname": "Nutuve Airport",
    "lat": "053800S",
    "long": "1512300E"
  },
  {
    "stationcode": "KUX",
    "stationname": "Kuyol Airport",
    "lat": "052242S",
    "long": "1413750E"
  },
  {
    "stationcode": "KIQ",
    "stationname": "Kira Airport",
    "lat": "080355S",
    "long": "1471956E"
  },
  {
    "stationcode": "GKA",
    "stationname": "Goroka Airport",
    "lat": "060454S",
    "long": "1452331E"
  },
  {
    "stationcode": "GUG",
    "stationname": "Guari Airport",
    "lat": "080600S",
    "long": "1465300E"
  },
  {
    "stationcode": "TFI",
    "stationname": "Tufi Airport",
    "lat": "090435S",
    "long": "1491912E"
  },
  {
    "stationcode": "GRL",
    "stationname": "Garasa Airport",
    "lat": "075700S",
    "long": "1471100E"
  },
  {
    "stationcode": "PMN",
    "stationname": "Pumani Airport",
    "lat": "094900S",
    "long": "1492800E"
  },
  {
    "stationcode": "AOA",
    "stationname": "Aroa Airport",
    "lat": "090200S",
    "long": "1464500E"
  },
  {
    "stationcode": "KZF",
    "stationname": "Kaintiba Airport",
    "lat": "073001S",
    "long": "1460202E"
  },
  {
    "stationcode": "KEG",
    "stationname": "Keglsugl Airport",
    "lat": "054830S",
    "long": "1450500E"
  },
  {
    "stationcode": "MGG",
    "stationname": "Margarima Airport",
    "lat": "060200S",
    "long": "1432200E"
  },
  {
    "stationcode": "MQO",
    "stationname": "Malam Airport",
    "lat": "084214S",
    "long": "1423904E"
  },
  {
    "stationcode": "HNN",
    "stationname": "Honinabi Airport",
    "lat": "061446S",
    "long": "1421037E"
  },
  {
    "stationcode": "TEP",
    "stationname": "Teptep Airport",
    "lat": "055700S",
    "long": "1463300E"
  },
  {
    "stationcode": "WSA",
    "stationname": "Wasua Airport",
    "lat": "081700S",
    "long": "1425200E"
  },
  {
    "stationcode": "OSG",
    "stationname": "Ossima Airport",
    "lat": "025500S",
    "long": "1411800E"
  },
  {
    "stationcode": "LGM",
    "stationname": "Laiagam Airport",
    "lat": "053200S",
    "long": "1432900E"
  },
  {
    "stationcode": "POM",
    "stationname": "Jackson Field",
    "lat": "092636S",
    "long": "1471312E"
  },
  {
    "stationcode": "WUV",
    "stationname": "Wuvulu Island Airport",
    "lat": "014500S",
    "long": "1425000E"
  },
  {
    "stationcode": "HNI",
    "stationname": "Heiweni Airport",
    "lat": "072400S",
    "long": "1462800E"
  },
  {
    "stationcode": "SQT",
    "stationname": "China Straits Airstrip",
    "lat": "103300S",
    "long": "1504100E"
  },
  {
    "stationcode": "NDN",
    "stationname": "Nadunumu Airport",
    "lat": "090800S",
    "long": "1474100E"
  },
  {
    "stationcode": "AUJ",
    "stationname": "Ambunti Airport",
    "lat": "041400S",
    "long": "1425000E"
  },
  {
    "stationcode": "BAP",
    "stationname": "Baibara Airport",
    "lat": "102000S",
    "long": "1493800E"
  },
  {
    "stationcode": "HKN",
    "stationname": "Hoskins Airport",
    "lat": "052744S",
    "long": "1502418E"
  },
  {
    "stationcode": "KRI",
    "stationname": "Kikori Airport",
    "lat": "072500S",
    "long": "1441300E"
  },
  {
    "stationcode": "SWG",
    "stationname": "Satwag Airport",
    "lat": "055300S",
    "long": "1475700E"
  },
  {
    "stationcode": "HWA",
    "stationname": "Hawabango Airport",
    "lat": "072600S",
    "long": "1455800E"
  },
  {
    "stationcode": "UKU",
    "stationname": "Nuku Airport",
    "lat": "034100S",
    "long": "1422800E"
  },
  {
    "stationcode": "UVO",
    "stationname": "Uvol Airport",
    "lat": "060100S",
    "long": "1505700E"
  },
  {
    "stationcode": "PNP",
    "stationname": "Girua Airport",
    "lat": "084816S",
    "long": "1481833E"
  },
  {
    "stationcode": "HBD",
    "stationname": "Habi Airport",
    "lat": "062000S",
    "long": "1422952E"
  },
  {
    "stationcode": "KRU",
    "stationname": "Kerau Airport",
    "lat": "081800S",
    "long": "1470500E"
  },
  {
    "stationcode": "LAE",
    "stationname": "Nadzab Airport",
    "lat": "063411S",
    "long": "1464334E"
  },
  {
    "stationcode": "KBM",
    "stationname": "Kabwum Airport",
    "lat": "062400S",
    "long": "1472200E"
  },
  {
    "stationcode": "BSP",
    "stationname": "Bensbach Airport",
    "lat": "085130S",
    "long": "1411534E"
  },
  {
    "stationcode": "GOE",
    "stationname": "Gonalia Airport",
    "lat": "052300S",
    "long": "1515200E"
  },
  {
    "stationcode": "GRH",
    "stationname": "Garuahi Airport",
    "lat": "101900S",
    "long": "1504100E"
  },
  {
    "stationcode": "KGM",
    "stationname": "Kungum Airport",
    "lat": "054000S",
    "long": "1410100E"
  },
  {
    "stationcode": "FIN",
    "stationname": "Finschhafen Airport",
    "lat": "063700S",
    "long": "1475200E"
  },
  {
    "stationcode": "RAA",
    "stationname": "Rakanda Airport",
    "lat": "041000S",
    "long": "1522800E"
  },
  {
    "stationcode": "WAJ",
    "stationname": "Wawoi Falls Airport",
    "lat": "065700S",
    "long": "1423900E"
  },
  {
    "stationcode": "AIH",
    "stationname": "Aiambak Airport",
    "lat": "072055S",
    "long": "1411600E"
  },
  {
    "stationcode": "ABP",
    "stationname": "Atkamba Airport",
    "lat": "060400S",
    "long": "1410600E"
  },
  {
    "stationcode": "DER",
    "stationname": "Derim Airport",
    "lat": "060600S",
    "long": "1464900E"
  },
  {
    "stationcode": "KMB",
    "stationname": "Koinambe Airport",
    "lat": "053000S",
    "long": "1443700E"
  },
  {
    "stationcode": "MDM",
    "stationname": "Munduku Airport",
    "lat": "043800S",
    "long": "1432700E"
  },
  {
    "stationcode": "GUV",
    "stationname": "Mougulu Airport",
    "lat": "061700S",
    "long": "1422500E"
  },
  {
    "stationcode": "ERE",
    "stationname": "Erave Airport",
    "lat": "063700S",
    "long": "1435400E"
  },
  {
    "stationcode": "SBE",
    "stationname": "Suabi Airport",
    "lat": "060500S",
    "long": "1421800E"
  },
  {
    "stationcode": "CPI",
    "stationname": "Cape Orford Airport",
    "lat": "052600S",
    "long": "1520500E"
  },
  {
    "stationcode": "WPM",
    "stationname": "Wipim Airport",
    "lat": "084500S",
    "long": "1425100E"
  },
  {
    "stationcode": "BMZ",
    "stationname": "Bamu Airport",
    "lat": "075000S",
    "long": "1431400E"
  },
  {
    "stationcode": "BAJ",
    "stationname": "Bali Airport",
    "lat": "045300S",
    "long": "1490800E"
  },
  {
    "stationcode": "SAM",
    "stationname": "Salamo Airport",
    "lat": "094000S",
    "long": "1504700E"
  },
  {
    "stationcode": "KUP",
    "stationname": "Kupiano Airport",
    "lat": "100400S",
    "long": "1481300E"
  },
  {
    "stationcode": "KPF",
    "stationname": "Kondubol Airport",
    "lat": "083200S",
    "long": "1423100E"
  },
  {
    "stationcode": "KPL",
    "stationname": "Kapal Airport",
    "lat": "083800S",
    "long": "1424928E"
  },
  {
    "stationcode": "HOC",
    "stationname": "Komako Airport",
    "lat": "072500S",
    "long": "1455300E"
  },
  {
    "stationcode": "TAJ",
    "stationname": "Tadji Airport",
    "lat": "031100S",
    "long": "1422500E"
  },
  {
    "stationcode": "BWJ",
    "stationname": "Bawan Airport",
    "lat": "062400S",
    "long": "1465400E"
  },
  {
    "stationcode": "EMS",
    "stationname": "Embessa Airport",
    "lat": "092700S",
    "long": "1484600E"
  },
  {
    "stationcode": "XYR",
    "stationname": "Yellow River Airport",
    "lat": "035300S",
    "long": "1414700E"
  },
  {
    "stationcode": "KPE",
    "stationname": "Yapsei Airport",
    "lat": "043800S",
    "long": "1410600E"
  },
  {
    "stationcode": "KMF",
    "stationname": "Kamina Airport",
    "lat": "071500S",
    "long": "1453200E"
  },
  {
    "stationcode": "TCJ",
    "stationname": "Torembi Airport",
    "lat": "040100S",
    "long": "1430800E"
  },
  {
    "stationcode": "OBX",
    "stationname": "Obo Airport",
    "lat": "073500S",
    "long": "1412000E"
  },
  {
    "stationcode": "AFR",
    "stationname": "Afore Airport",
    "lat": "090800S",
    "long": "1482300E"
  },
  {
    "stationcode": "KRJ",
    "stationname": "Karawari Airport",
    "lat": "084500S",
    "long": "1472950E"
  },
  {
    "stationcode": "NMN",
    "stationname": "Nomane Airport",
    "lat": "061900S",
    "long": "1450400E"
  },
  {
    "stationcode": "USO",
    "stationname": "Usino Airport",
    "lat": "053000S",
    "long": "1452600E"
  },
  {
    "stationcode": "AUP",
    "stationname": "Agaun Airport",
    "lat": "095500S",
    "long": "1492000E"
  },
  {
    "stationcode": "BPD",
    "stationname": "Bapi Airport",
    "lat": "074500S",
    "long": "1470100E"
  },
  {
    "stationcode": "BPK",
    "stationname": "Biangabip Airport",
    "lat": "053147S",
    "long": "1414500E"
  },
  {
    "stationcode": "MLQ",
    "stationname": "Malalaua Airport",
    "lat": "080600S",
    "long": "1461000E"
  },
  {
    "stationcode": "AMU",
    "stationname": "Amanab Airport",
    "lat": "033500S",
    "long": "1411300E"
  },
  {
    "stationcode": "LSJ",
    "stationname": "Long Island Airport",
    "lat": "052200S",
    "long": "1470100E"
  },
  {
    "stationcode": "ONB",
    "stationname": "Ononge Airport",
    "lat": "083500S",
    "long": "1471200E"
  },
  {
    "stationcode": "JOP",
    "stationname": "Josephstaal Airport",
    "lat": "044500S",
    "long": "1450100E"
  },
  {
    "stationcode": "LSA",
    "stationname": "Losuia Airport",
    "lat": "083023S",
    "long": "1510452E"
  },
  {
    "stationcode": "WGU",
    "stationname": "Wagau Airport",
    "lat": "064800S",
    "long": "1464800E"
  },
  {
    "stationcode": "WIU",
    "stationname": "Witu Airport",
    "lat": "034900S",
    "long": "1420200E"
  },
  {
    "stationcode": "TSI",
    "stationname": "Tsili Tsili Airport",
    "lat": "065100S",
    "long": "1462100E"
  },
  {
    "stationcode": "UMC",
    "stationname": "Umba Airport",
    "lat": "070100S",
    "long": "1455800E"
  },
  {
    "stationcode": "AUI",
    "stationname": "Aua Island Airport",
    "lat": "012700S",
    "long": "1430400E"
  },
  {
    "stationcode": "OSE",
    "stationname": "Omora Airport",
    "lat": "075000S",
    "long": "1470500E"
  },
  {
    "stationcode": "MRH",
    "stationname": "May River Airport",
    "lat": "042000S",
    "long": "1414800E"
  },
  {
    "stationcode": "EIA",
    "stationname": "Popondetta Airport",
    "lat": "081200S",
    "long": "1475100E"
  },
  {
    "stationcode": "TSK",
    "stationname": "Taskul Airport",
    "lat": "023550S",
    "long": "1502500E"
  },
  {
    "stationcode": "DBP",
    "stationname": "Debepare Airport",
    "lat": "061700S",
    "long": "1415400E"
  },
  {
    "stationcode": "ATP",
    "stationname": "Aitape Airstrip",
    "lat": "030800S",
    "long": "1422100E"
  },
  {
    "stationcode": "BDZ",
    "stationname": "Baindoung Airport",
    "lat": "062000S",
    "long": "1465700E"
  },
  {
    "stationcode": "UPR",
    "stationname": "Upiara Airport",
    "lat": "083200S",
    "long": "1423800E"
  },
  {
    "stationcode": "URU",
    "stationname": "Oro Province Airport",
    "lat": "093000S",
    "long": "1483400E"
  },
  {
    "stationcode": "GUR",
    "stationname": "Gurney Airport",
    "lat": "101841S",
    "long": "1502021E"
  },
  {
    "stationcode": "TBG",
    "stationname": "Tabubil Airport",
    "lat": "051643S",
    "long": "1411335E"
  },
  {
    "stationcode": "LNF",
    "stationname": "Munbil Airport",
    "lat": "045200S",
    "long": "1411300E"
  },
  {
    "stationcode": "RGE",
    "stationname": "Porgera Airport",
    "lat": "052900S",
    "long": "1430700E"
  },
  {
    "stationcode": "WAB",
    "stationname": "Wabag Airport",
    "lat": "052900S",
    "long": "1434000E"
  },
  {
    "stationcode": "ATN",
    "stationname": "Namatanai Airport",
    "lat": "034000S",
    "long": "1522700E"
  },
  {
    "stationcode": "WNU",
    "stationname": "Wanuma Airport",
    "lat": "045400S",
    "long": "1451900E"
  },
  {
    "stationcode": "FUB",
    "stationname": "Fulleborn Airport",
    "lat": "060900S",
    "long": "1503900E"
  },
  {
    "stationcode": "TWY",
    "stationname": "Tawa Airport",
    "lat": "072700S",
    "long": "1460700E"
  },
  {
    "stationcode": "KUQ",
    "stationname": "Kuri Airport",
    "lat": "070800S",
    "long": "1431600E"
  },
  {
    "stationcode": "KAK",
    "stationname": "Kar Airport",
    "lat": "061300S",
    "long": "1432900E"
  },
  {
    "stationcode": "MGP",
    "stationname": "Manga Airport",
    "lat": "040900S",
    "long": "1530200E"
  },
  {
    "stationcode": "PUA",
    "stationname": "Puas Airport",
    "lat": "022400S",
    "long": "1501400E"
  },
  {
    "stationcode": "MAP",
    "stationname": "Mamai Airport",
    "lat": "101800S",
    "long": "1493200E"
  },
  {
    "stationcode": "OKP",
    "stationname": "Oksapmin Airport",
    "lat": "051400S",
    "long": "1421400E"
  },
  {
    "stationcode": "MYX",
    "stationname": "Menyamya Airport",
    "lat": "071200S",
    "long": "1460100E"
  },
  {
    "stationcode": "DLB",
    "stationname": "Dalbertis Airport",
    "lat": "050000S",
    "long": "1420500E"
  },
  {
    "stationcode": "KIA",
    "stationname": "Kaiapit Airport",
    "lat": "061600S",
    "long": "1461500E"
  },
  {
    "stationcode": "NWT",
    "stationname": "Nowata Airport",
    "lat": "083000S",
    "long": "1431000E"
  },
  {
    "stationcode": "KGW",
    "stationname": "Kagi Airport",
    "lat": "090300S",
    "long": "1465000E"
  },
  {
    "stationcode": "ERU",
    "stationname": "Erume Airport",
    "lat": "081514S",
    "long": "1465829E"
  },
  {
    "stationcode": "MWI",
    "stationname": "Maramoni Airport",
    "lat": "050900S",
    "long": "1432900E"
  },
  {
    "stationcode": "KVE",
    "stationname": "Kitava Airport",
    "lat": "084000S",
    "long": "1512000E"
  },
  {
    "stationcode": "LMI",
    "stationname": "Lumi Airport",
    "lat": "032900S",
    "long": "1420200E"
  },
  {
    "stationcode": "BNV",
    "stationname": "Boana Airport",
    "lat": "062500S",
    "long": "1464900E"
  },
  {
    "stationcode": "MRM",
    "stationname": "Manari Airport",
    "lat": "091200S",
    "long": "1473600E"
  },
  {
    "stationcode": "HYF",
    "stationname": "Hayfields Airport",
    "lat": "033800S",
    "long": "1430300E"
  },
  {
    "stationcode": "KDQ",
    "stationname": "Kamberatoro Airport",
    "lat": "033620S",
    "long": "1410300E"
  },
  {
    "stationcode": "LWI",
    "stationname": "Lowai Airport",
    "lat": "061900S",
    "long": "1463500E"
  },
  {
    "stationcode": "MPU",
    "stationname": "Mapua Airport",
    "lat": "025500S",
    "long": "1512500E"
  },
  {
    "stationcode": "EPT",
    "stationname": "Eliptamin Airport",
    "lat": "050200S",
    "long": "1414000E"
  },
  {
    "stationcode": "NDI",
    "stationname": "Namudi Airport",
    "lat": "092700S",
    "long": "1481400E"
  },
  {
    "stationcode": "PPX",
    "stationname": "Param Airport",
    "lat": "095945S",
    "long": "1493530E"
  },
  {
    "stationcode": "SSS",
    "stationname": "Siassi Airport",
    "lat": "055400S",
    "long": "1475700E"
  },
  {
    "stationcode": "AMG",
    "stationname": "Amboin Airport",
    "lat": "043600S",
    "long": "1433000E"
  },
  {
    "stationcode": "SWE",
    "stationname": "Siwea Airport",
    "lat": "061700S",
    "long": "1473300E"
  },
  {
    "stationcode": "GWN",
    "stationname": "Gnarowein Airport",
    "lat": "063400S",
    "long": "1461500E"
  },
  {
    "stationcode": "ESA",
    "stationname": "Esa'Ala Airport",
    "lat": "095300S",
    "long": "1505700E"
  },
  {
    "stationcode": "SXW",
    "stationname": "Sauren Airport",
    "lat": "055810S",
    "long": "1485710E"
  },
  {
    "stationcode": "RAB",
    "stationname": "Tokua Airport",
    "lat": "042024S",
    "long": "1522238E"
  },
  {
    "stationcode": "OLQ",
    "stationname": "Olsobip Airport",
    "lat": "052300S",
    "long": "1413000E"
  },
  {
    "stationcode": "AOB",
    "stationname": "Annanberg Airport",
    "lat": "045500S",
    "long": "1444000E"
  },
  {
    "stationcode": "PAW",
    "stationname": "Pambwa Airport",
    "lat": "103500S",
    "long": "1510500E"
  },
  {
    "stationcode": "IAU",
    "stationname": "Iaura Airport",
    "lat": "094700S",
    "long": "1480600E"
  },
  {
    "stationcode": "KRX",
    "stationname": "Kar Kar Airport",
    "lat": "043300S",
    "long": "1455600E"
  },
  {
    "stationcode": "GLP",
    "stationname": "Gulgubip Airport",
    "lat": "051700S",
    "long": "1413100E"
  },
  {
    "stationcode": "SIL",
    "stationname": "Sila Airport",
    "lat": "090700S",
    "long": "1482400E"
  },
  {
    "stationcode": "SIM",
    "stationname": "Simbai Airport",
    "lat": "051600S",
    "long": "1443200E"
  },
  {
    "stationcode": "EMO",
    "stationname": "Emo Airport",
    "lat": "090725S",
    "long": "1480230E"
  },
  {
    "stationcode": "BXZ",
    "stationname": "Bunsil Airport",
    "lat": "054300S",
    "long": "1475200E"
  },
  {
    "stationcode": "PGB",
    "stationname": "Panaoa Airport",
    "lat": "070100S",
    "long": "1413300E"
  },
  {
    "stationcode": "BEA",
    "stationname": "Bereina Airport",
    "lat": "083900S",
    "long": "1462900E"
  },
  {
    "stationcode": "BIJ",
    "stationname": "Biliau Airport",
    "lat": "053500S",
    "long": "1462100E"
  },
  {
    "stationcode": "SPH",
    "stationname": "Sopu Airport",
    "lat": "090000S",
    "long": "1474600E"
  },
  {
    "stationcode": "GEW",
    "stationname": "Gewoya Airport",
    "lat": "091100S",
    "long": "1482900E"
  },
  {
    "stationcode": "TBA",
    "stationname": "Tabibuga Airport",
    "lat": "053500S",
    "long": "1443900E"
  },
  {
    "stationcode": "GAP",
    "stationname": "Gusap Airport",
    "lat": "060300S",
    "long": "1455800E"
  },
  {
    "stationcode": "TKB",
    "stationname": "Tekadu Airport",
    "lat": "074000S",
    "long": "1463300E"
  },
  {
    "stationcode": "KWO",
    "stationname": "Kawito Airport",
    "lat": "075900S",
    "long": "1424900E"
  },
  {
    "stationcode": "LNC",
    "stationname": "Lengbati Airport",
    "lat": "062200S",
    "long": "1472200E"
  },
  {
    "stationcode": "LNM",
    "stationname": "Langimar Airport",
    "lat": "071300S",
    "long": "1461200E"
  },
  {
    "stationcode": "BNT",
    "stationname": "Bundi Airport",
    "lat": "054500S",
    "long": "1451400E"
  },
  {
    "stationcode": "BNZ",
    "stationname": "Banz Airport",
    "lat": "054700S",
    "long": "1443700E"
  },
  {
    "stationcode": "TUT",
    "stationname": "Tauta Airport",
    "lat": "054800S",
    "long": "1455500E"
  },
  {
    "stationcode": "OTY",
    "stationname": "Oria Airport",
    "lat": "063500S",
    "long": "1554100E"
  },
  {
    "stationcode": "UBI",
    "stationname": "Buin Airport",
    "lat": "064400S",
    "long": "1554100E"
  },
  {
    "stationcode": "BOQ",
    "stationname": "Boku Airport",
    "lat": "061600S",
    "long": "1551300E"
  },
  {
    "stationcode": "KIE",
    "stationname": "Aropa Airport",
    "lat": "061822S",
    "long": "1554343E"
  },
  {
    "stationcode": "NUG",
    "stationname": "Nuguria Airport",
    "lat": "032000S",
    "long": "1544500E"
  },
  {
    "stationcode": "WKN",
    "stationname": "Wakunai Airport",
    "lat": "055200S",
    "long": "1551400E"
  },
  {
    "stationcode": "KAF",
    "stationname": "Karato Airport",
    "lat": "061700S",
    "long": "1552100E"
  },
  {
    "stationcode": "KXR",
    "stationname": "Karoola Airport",
    "lat": "051700S",
    "long": "1543600E"
  },
  {
    "stationcode": "KTK",
    "stationname": "Kanua Airport",
    "lat": "052020S",
    "long": "1544500E"
  },
  {
    "stationcode": "IUS",
    "stationname": "Inus Airport",
    "lat": "054900S",
    "long": "1551000E"
  },
  {
    "stationcode": "TOK",
    "stationname": "Torokina Airport",
    "lat": "061300S",
    "long": "1550300E"
  },
  {
    "stationcode": "TON",
    "stationname": "Tonu Airport",
    "lat": "063900S",
    "long": "1552600E"
  },
  {
    "stationcode": "IIS",
    "stationname": "Nissan Island Airport",
    "lat": "043000S",
    "long": "1541430E"
  },
  {
    "stationcode": "RAW",
    "stationname": "Arawa Airport",
    "lat": "061400S",
    "long": "1553400E"
  },
  {
    "stationcode": "MVI",
    "stationname": "Manetai Airport",
    "lat": "060000S",
    "long": "1552000E"
  },
  {
    "stationcode": "LHP",
    "stationname": "Lehu Airport",
    "lat": "062500S",
    "long": "1554200E"
  },
  {
    "stationcode": "BUA",
    "stationname": "Buka Airport",
    "lat": "052600S",
    "long": "1544000E"
  },
  {
    "stationcode": "KWV",
    "stationname": "Kurwina Airport",
    "lat": "061100S",
    "long": "1552000E"
  },
  {
    "stationcode": "DOS",
    "stationname": "Dios Airport",
    "lat": "053300S",
    "long": "1545800E"
  },
  {
    "stationcode": "SBV",
    "stationname": "Sabah Airport",
    "lat": "053500S",
    "long": "1550300E"
  },
  {
    "stationcode": "CBP",
    "stationname": "Coimbra Airport",
    "lat": "400941N",
    "long": "0082820W"
  },
  {
    "stationcode": "PXO",
    "stationname": "Porto Santo Airport",
    "lat": "330415N",
    "long": "0162059W"
  },
  {
    "stationcode": "AVR",
    "stationname": "Alverca Airport",
    "lat": "385259N",
    "long": "0090148W"
  },
  {
    "stationcode": "FNC",
    "stationname": "Madeira Airport",
    "lat": "324139N",
    "long": "0164641W"
  },
  {
    "stationcode": "SIE",
    "stationname": "Sines Airport",
    "lat": "375700N",
    "long": "0085200W"
  },
  {
    "stationcode": "VRL",
    "stationname": "Vila Real Airport",
    "lat": "411634N",
    "long": "0074314W"
  },
  {
    "stationcode": "COV",
    "stationname": "Covilha Airport",
    "lat": "401700N",
    "long": "0073000W"
  },
  {
    "stationcode": "PRM",
    "stationname": "Portimao Airport",
    "lat": "370859N",
    "long": "0083503W"
  },
  {
    "stationcode": "LIS",
    "stationname": "Lisboa Airport",
    "lat": "384627N",
    "long": "0090803W"
  },
  {
    "stationcode": "CHV",
    "stationname": "Chaves Airport",
    "lat": "414400N",
    "long": "0072800W"
  },
  {
    "stationcode": "BYJ",
    "stationname": "Beja International Airport",
    "lat": "380444N",
    "long": "0075557W"
  },
  {
    "stationcode": "VSE",
    "stationname": "Viseu Airport",
    "lat": "404400N",
    "long": "0075301W"
  },
  {
    "stationcode": "BGC",
    "stationname": "Braganca Airport",
    "lat": "415129N",
    "long": "0064226W"
  },
  {
    "stationcode": "BGZ",
    "stationname": "Braga Airport",
    "lat": "413500N",
    "long": "0082700W"
  },
  {
    "stationcode": "OPO",
    "stationname": "Porto Airport",
    "lat": "411408N",
    "long": "0084041W"
  },
  {
    "stationcode": "FAO",
    "stationname": "Faro Airport",
    "lat": "370052N",
    "long": "0075757W"
  },
  {
    "stationcode": "PIX",
    "stationname": "Pico Island Airport",
    "lat": "383316N",
    "long": "0282629W"
  },
  {
    "stationcode": "GRW",
    "stationname": "Graciosa Island Airport",
    "lat": "390532N",
    "long": "0280147W"
  },
  {
    "stationcode": "PDL",
    "stationname": "Nordela Airport",
    "lat": "374428N",
    "long": "0254152W"
  },
  {
    "stationcode": "CVU",
    "stationname": "Corvo Island (Azores) Airport",
    "lat": "394017N",
    "long": "0310649W"
  },
  {
    "stationcode": "SJZ",
    "stationname": "Sao Jorge Island Airport",
    "lat": "383956N",
    "long": "0281033W"
  },
  {
    "stationcode": "TER",
    "stationname": "Lajes Airport",
    "lat": "384524N",
    "long": "0270539W"
  },
  {
    "stationcode": "FLW",
    "stationname": "Santa Cruz Airport",
    "lat": "392719N",
    "long": "0310753W"
  },
  {
    "stationcode": "HOR",
    "stationname": "Horta Airport",
    "lat": "383112N",
    "long": "0284259W"
  },
  {
    "stationcode": "SMA",
    "stationname": "Vila Do Porto Airport",
    "lat": "365826N",
    "long": "0251016W"
  },
  {
    "stationcode": "KGD",
    "stationname": "Kaliningrad Airport",
    "lat": "545324N",
    "long": "0203533E"
  },
  {
    "stationcode": "LDG",
    "stationname": "Leshukonskoye Airport",
    "lat": "645343N",
    "long": "0454327E"
  },
  {
    "stationcode": "USK",
    "stationname": "Usinsk Airport",
    "lat": "660016N",
    "long": "0572202E"
  },
  {
    "stationcode": "VRI",
    "stationname": "Varandey Airport",
    "lat": "685053N",
    "long": "0581201E"
  },
  {
    "stationcode": "LPK",
    "stationname": "Lipetsk Airport",
    "lat": "523815N",
    "long": "0392707E"
  },
  {
    "stationcode": "CSH",
    "stationname": "Solovetsky Airport",
    "lat": "650146N",
    "long": "0354400E"
  },
  {
    "stationcode": "SKX",
    "stationname": "Saransk Airport",
    "lat": "540733N",
    "long": "0451248E"
  },
  {
    "stationcode": "UCT",
    "stationname": "Ukhta Airport",
    "lat": "633400N",
    "long": "0534816E"
  },
  {
    "stationcode": "ARH",
    "stationname": "Arkhangelsk Airport",
    "lat": "643601N",
    "long": "0404300E"
  },
  {
    "stationcode": "UTS",
    "stationname": "Ust-Tsilma Airport",
    "lat": "652600N",
    "long": "0521206E"
  },
  {
    "stationcode": "NOI",
    "stationname": "Novorossijsk Airport",
    "lat": "444500N",
    "long": "0374500E"
  },
  {
    "stationcode": "OEL",
    "stationname": "Orel Airport",
    "lat": "525900N",
    "long": "0360600E"
  },
  {
    "stationcode": "OGZ",
    "stationname": "Vladikavkaz Airport",
    "lat": "431218N",
    "long": "0443624E"
  },
  {
    "stationcode": "KRR",
    "stationname": "Krasnodar Airport",
    "lat": "450157N",
    "long": "0390959E"
  },
  {
    "stationcode": "GDZ",
    "stationname": "Gelendzik Airport",
    "lat": "443445N",
    "long": "0380043E"
  },
  {
    "stationcode": "KVK",
    "stationname": "Kirovsk Airport",
    "lat": "673435N",
    "long": "0333449E"
  },
  {
    "stationcode": "CSY",
    "stationname": "Cheboksary Airport",
    "lat": "560525N",
    "long": "0472050E"
  },
  {
    "stationcode": "PES",
    "stationname": "Petrozavodsk Airport",
    "lat": "615306N",
    "long": "0340924E"
  },
  {
    "stationcode": "KLF",
    "stationname": "Kaluga Airport",
    "lat": "543255N",
    "long": "0362218E"
  },
  {
    "stationcode": "JOK",
    "stationname": "Joshkar-Ola Airport",
    "lat": "564203N",
    "long": "0475347E"
  },
  {
    "stationcode": "VKO",
    "stationname": "Vnukovo Airport",
    "lat": "553530N",
    "long": "0371541E"
  },
  {
    "stationcode": "VLK",
    "stationname": "Volgodonsk Airport",
    "lat": "474100N",
    "long": "0420500E"
  },
  {
    "stationcode": "RYB",
    "stationname": "Rybinsk Airport",
    "lat": "580800N",
    "long": "0385200E"
  },
  {
    "stationcode": "SVO",
    "stationname": "Sheremetyevo Airport",
    "lat": "555822N",
    "long": "0372453E"
  },
  {
    "stationcode": "IAR",
    "stationname": "Yaroslavl Airport",
    "lat": "573332N",
    "long": "0400928E"
  },
  {
    "stationcode": "VLU",
    "stationname": "Velikiye Luki Airport",
    "lat": "562300N",
    "long": "0303700E"
  },
  {
    "stationcode": "BKA",
    "stationname": "Bykovo Airport",
    "lat": "552600N",
    "long": "0375800E"
  },
  {
    "stationcode": "NBC",
    "stationname": "Nizhnekamsk Airport",
    "lat": "553400N",
    "long": "0520600E"
  },
  {
    "stationcode": "NNM",
    "stationname": "Naryan-Mar Airport",
    "lat": "673825N",
    "long": "0530721E"
  },
  {
    "stationcode": "ZIA",
    "stationname": "Zhukovsky Airport",
    "lat": "553312N",
    "long": "0380906E"
  },
  {
    "stationcode": "NAL",
    "stationname": "Nalchik Airport",
    "lat": "433046N",
    "long": "0433812E"
  },
  {
    "stationcode": "MRV",
    "stationname": "Mineralnye Vody Airport",
    "lat": "441330N",
    "long": "0430455E"
  },
  {
    "stationcode": "GRV",
    "stationname": "Groznyy Airport",
    "lat": "432318N",
    "long": "0454200E"
  },
  {
    "stationcode": "TBW",
    "stationname": "Tambov Airport",
    "lat": "524202N",
    "long": "0412228E"
  },
  {
    "stationcode": "KZN",
    "stationname": "Kazan Airport",
    "lat": "553622N",
    "long": "0491643E"
  },
  {
    "stationcode": "NVR",
    "stationname": "Novgorod Airport",
    "lat": "582936N",
    "long": "0311431E"
  },
  {
    "stationcode": "RTW",
    "stationname": "Saratov Airport",
    "lat": "513354N",
    "long": "0460248E"
  },
  {
    "stationcode": "AMV",
    "stationname": "Amderma Airport",
    "lat": "694539N",
    "long": "0613425E"
  },
  {
    "stationcode": "ROV",
    "stationname": "Rostov Airport",
    "lat": "472937N",
    "long": "0395526E"
  },
  {
    "stationcode": "RZN",
    "stationname": "Ryazan Airport",
    "lat": "543800N",
    "long": "0393500E"
  },
  {
    "stationcode": "AER",
    "stationname": "Sochi International",
    "lat": "432700N",
    "long": "0395724E"
  },
  {
    "stationcode": "VOG",
    "stationname": "Volgograd Airport",
    "lat": "484657N",
    "long": "0442044E"
  },
  {
    "stationcode": "CKL",
    "stationname": "Chkalovsky Airport",
    "lat": "555200N",
    "long": "0380307E"
  },
  {
    "stationcode": "CEE",
    "stationname": "Cherepovets Airport",
    "lat": "591641N",
    "long": "0380121E"
  },
  {
    "stationcode": "VGD",
    "stationname": "Vologda Airport",
    "lat": "591655N",
    "long": "0395642E"
  },
  {
    "stationcode": "ASF",
    "stationname": "Astrakhan Airport",
    "lat": "461700N",
    "long": "0480023E"
  },
  {
    "stationcode": "KLD",
    "stationname": "Migalovo Airport",
    "lat": "564929N",
    "long": "0354528E"
  },
  {
    "stationcode": "KVX",
    "stationname": "Kirov Airport",
    "lat": "583003N",
    "long": "0492032E"
  },
  {
    "stationcode": "ULV",
    "stationname": "Baratayevka Airport",
    "lat": "541608N",
    "long": "0481326E"
  },
  {
    "stationcode": "VKT",
    "stationname": "Vorkuta Airport",
    "lat": "672921N",
    "long": "0635944E"
  },
  {
    "stationcode": "URS",
    "stationname": "Kursk Airport",
    "lat": "514500N",
    "long": "0361600E"
  },
  {
    "stationcode": "EGO",
    "stationname": "Belgorod Airport",
    "lat": "503838N",
    "long": "0363524E"
  },
  {
    "stationcode": "VOZ",
    "stationname": "Voronezh Airport",
    "lat": "514851N",
    "long": "0391347E"
  },
  {
    "stationcode": "SCW",
    "stationname": "Syktyvkar Airport",
    "lat": "613849N",
    "long": "0505042E"
  },
  {
    "stationcode": "ESL",
    "stationname": "Elista Airport",
    "lat": "462226N",
    "long": "0441951E"
  },
  {
    "stationcode": "MCX",
    "stationname": "Makhachkala Airport",
    "lat": "424901N",
    "long": "0473908E"
  },
  {
    "stationcode": "ULY",
    "stationname": "Ulyanovsk Airport",
    "lat": "542404N",
    "long": "0484810E"
  },
  {
    "stationcode": "INA",
    "stationname": "Inta Airport",
    "lat": "660320N",
    "long": "0600637E"
  },
  {
    "stationcode": "KMW",
    "stationname": "Kostroma Airport",
    "lat": "574500N",
    "long": "0405600E"
  },
  {
    "stationcode": "VUS",
    "stationname": "Velikij Ustyug Airport",
    "lat": "604705N",
    "long": "0461523E"
  },
  {
    "stationcode": "BZK",
    "stationname": "Briansk Airport",
    "lat": "531611N",
    "long": "0342012E"
  },
  {
    "stationcode": "GOJ",
    "stationname": "Nizhniy Novgorod Airport",
    "lat": "561307N",
    "long": "0434724E"
  },
  {
    "stationcode": "LNX",
    "stationname": "Smolensk Airport",
    "lat": "544800N",
    "long": "0320300E"
  },
  {
    "stationcode": "AAQ",
    "stationname": "Anapa Airport",
    "lat": "450008N",
    "long": "0372050E"
  },
  {
    "stationcode": "RVH",
    "stationname": "Rzhevka Airport",
    "lat": "595900N",
    "long": "0303600E"
  },
  {
    "stationcode": "EIK",
    "stationname": "Eisk Airport",
    "lat": "464044N",
    "long": "0381244E"
  },
  {
    "stationcode": "IWA",
    "stationname": "Ivanovo Airport",
    "lat": "570330N",
    "long": "0405848E"
  },
  {
    "stationcode": "TYA",
    "stationname": "Tula Airport",
    "lat": "541200N",
    "long": "0373700E"
  },
  {
    "stationcode": "STW",
    "stationname": "Stavropol Airport",
    "lat": "450633N",
    "long": "0420646E"
  },
  {
    "stationcode": "BWO",
    "stationname": "Balakovo Airport",
    "lat": "515200N",
    "long": "0474500E"
  },
  {
    "stationcode": "PEX",
    "stationname": "Pechora Airport",
    "lat": "650710N",
    "long": "0570750E"
  },
  {
    "stationcode": "PKV",
    "stationname": "Pskov Airport",
    "lat": "574702N",
    "long": "0282344E"
  },
  {
    "stationcode": "IGT",
    "stationname": "Sleptsovkskaya Airport",
    "lat": "431927N",
    "long": "0450011E"
  },
  {
    "stationcode": "LED",
    "stationname": "Pulkovo Airport",
    "lat": "594800N",
    "long": "0301800E"
  },
  {
    "stationcode": "MMK",
    "stationname": "Murmansk Airport",
    "lat": "684654N",
    "long": "0324503E"
  },
  {
    "stationcode": "KSZ",
    "stationname": "Kotlas Airport",
    "lat": "611410N",
    "long": "0464156E"
  },
  {
    "stationcode": "TGK",
    "stationname": "Taganrog Airport",
    "lat": "471153N",
    "long": "0385105E"
  },
  {
    "stationcode": "PEZ",
    "stationname": "Penza Airport",
    "lat": "530638N",
    "long": "0450117E"
  },
  {
    "stationcode": "KUF",
    "stationname": "Samara Airport",
    "lat": "533014N",
    "long": "0500906E"
  },
  {
    "stationcode": "OSF",
    "stationname": "Ostafyevo Airport",
    "lat": "553027N",
    "long": "0373015E"
  },
  {
    "stationcode": "IJK",
    "stationname": "Izhevsk Airport",
    "lat": "564945N",
    "long": "0532728E"
  },
  {
    "stationcode": "UUA",
    "stationname": "Bugulma Airport",
    "lat": "543807N",
    "long": "0524757E"
  },
  {
    "stationcode": "NEF",
    "stationname": "Neftekamsk Airport",
    "lat": "560600N",
    "long": "0542100E"
  },
  {
    "stationcode": "OSW",
    "stationname": "Orsk Airport",
    "lat": "510421N",
    "long": "0583544E"
  },
  {
    "stationcode": "OKT",
    "stationname": "Oktiabrskij Airport",
    "lat": "542600N",
    "long": "0532300E"
  },
  {
    "stationcode": "BCX",
    "stationname": "Beloreck Airport",
    "lat": "535600N",
    "long": "0582000E"
  },
  {
    "stationcode": "YMK",
    "stationname": "Mys Kamennyy Airport",
    "lat": "682758N",
    "long": "0733544E"
  },
  {
    "stationcode": "MQF",
    "stationname": "Magnitogorsk Airport",
    "lat": "532335N",
    "long": "0584524E"
  },
  {
    "stationcode": "NUX",
    "stationname": "Novyj Urengoj Airport",
    "lat": "660413N",
    "long": "0763114E"
  },
  {
    "stationcode": "SVX",
    "stationname": "Koltsovo Airport",
    "lat": "564435N",
    "long": "0604810E"
  },
  {
    "stationcode": "SLY",
    "stationname": "Salehard Airport",
    "lat": "663527N",
    "long": "0663640E"
  },
  {
    "stationcode": "NFG",
    "stationname": "Nefteyugansk Airport",
    "lat": "610800N",
    "long": "0730300E"
  },
  {
    "stationcode": "UFA",
    "stationname": "Ufa Airport",
    "lat": "543327N",
    "long": "0555302E"
  },
  {
    "stationcode": "RAT",
    "stationname": "Raduzhnyi Airport",
    "lat": "620936N",
    "long": "0771946E"
  },
  {
    "stationcode": "KRO",
    "stationname": "Kurgan Airport",
    "lat": "552815N",
    "long": "0652438E"
  },
  {
    "stationcode": "NJC",
    "stationname": "Nizhnevartovsk Airport",
    "lat": "605657N",
    "long": "0762901E"
  },
  {
    "stationcode": "TJM",
    "stationname": "Tyumen Airport",
    "lat": "571122N",
    "long": "0651927E"
  },
  {
    "stationcode": "EYK",
    "stationname": "Beloyarsky Airport",
    "lat": "634126N",
    "long": "0664159E"
  },
  {
    "stationcode": "NYM",
    "stationname": "Nadym Airport",
    "lat": "652853N",
    "long": "0724159E"
  },
  {
    "stationcode": "HMA",
    "stationname": "Khanty-Mansiysk",
    "lat": "610143N",
    "long": "0690510E"
  },
  {
    "stationcode": "TQL",
    "stationname": "Tarko-Sale Airport",
    "lat": "645548N",
    "long": "0774839E"
  },
  {
    "stationcode": "IRM",
    "stationname": "Igrim Airport",
    "lat": "631150N",
    "long": "0642629E"
  },
  {
    "stationcode": "OVS",
    "stationname": "Sovetsky Airport",
    "lat": "611931N",
    "long": "0633613E"
  },
  {
    "stationcode": "KXD",
    "stationname": "Kondinskoye Airport",
    "lat": "593900N",
    "long": "0672600E"
  },
  {
    "stationcode": "SGC",
    "stationname": "Surgut Airport",
    "lat": "612037N",
    "long": "0732407E"
  },
  {
    "stationcode": "EZV",
    "stationname": "Berezovo Airport",
    "lat": "635516N",
    "long": "0650150E"
  },
  {
    "stationcode": "UEN",
    "stationname": "Urengoy Airport",
    "lat": "655800N",
    "long": "0782200E"
  },
  {
    "stationcode": "CEK",
    "stationname": "Chelyabinsk Airport",
    "lat": "551821N",
    "long": "0613012E"
  },
  {
    "stationcode": "RMZ",
    "stationname": "Remezov",
    "lat": "580331N",
    "long": "0682002E"
  },
  {
    "stationcode": "URJ",
    "stationname": "Uraj Airport",
    "lat": "600658N",
    "long": "0644948E"
  },
  {
    "stationcode": "NYA",
    "stationname": "Nyagan Airport",
    "lat": "620606N",
    "long": "0653609E"
  },
  {
    "stationcode": "KGP",
    "stationname": "Kogalym International Airport",
    "lat": "621126N",
    "long": "0743202E"
  },
  {
    "stationcode": "PEE",
    "stationname": "Perm Airport",
    "lat": "575452N",
    "long": "0560116E"
  },
  {
    "stationcode": "KKQ",
    "stationname": "Krasnoselkup Airport",
    "lat": "654200N",
    "long": "0822700E"
  },
  {
    "stationcode": "REN",
    "stationname": "Orenburg Airport",
    "lat": "514745N",
    "long": "0552724E"
  },
  {
    "stationcode": "NOJ",
    "stationname": "Nojabrxsk Airport",
    "lat": "631101N",
    "long": "0751608E"
  },
  {
    "stationcode": "OVB",
    "stationname": "Tolmachevo Airport",
    "lat": "550045N",
    "long": "0823902E"
  },
  {
    "stationcode": "RGK",
    "stationname": "Gorno-Altaysk Airport",
    "lat": "515809N",
    "long": "0855011E"
  },
  {
    "stationcode": "OMS",
    "stationname": "Omsk Airport",
    "lat": "545801N",
    "long": "0731838E"
  },
  {
    "stationcode": "TOX",
    "stationname": "Tobolsk Airport",
    "lat": "580800N",
    "long": "0681400E"
  },
  {
    "stationcode": "EIE",
    "stationname": "Eniseysk Airport",
    "lat": "582824N",
    "long": "0920646E"
  },
  {
    "stationcode": "ACS",
    "stationname": "Achinsk Airport",
    "lat": "561611N",
    "long": "0903419E"
  },
  {
    "stationcode": "DKS",
    "stationname": "Dikson Airport",
    "lat": "733104N",
    "long": "0802237E"
  },
  {
    "stationcode": "NSK",
    "stationname": "Noril'sk Airport",
    "lat": "691840N",
    "long": "0871956E"
  },
  {
    "stationcode": "KJA",
    "stationname": "Krasnojarsk Airport",
    "lat": "561023N",
    "long": "0922936E"
  },
  {
    "stationcode": "KYZ",
    "stationname": "Kyzyl Airport",
    "lat": "514024N",
    "long": "0942429E"
  },
  {
    "stationcode": "KEJ",
    "stationname": "Kemerovo Airport",
    "lat": "551612N",
    "long": "0860626E"
  },
  {
    "stationcode": "THX",
    "stationname": "Turukhansk Airport",
    "lat": "654752N",
    "long": "0875608E"
  },
  {
    "stationcode": "BAX",
    "stationname": "Barnaul Airport",
    "lat": "532147N",
    "long": "0833250E"
  },
  {
    "stationcode": "SWT",
    "stationname": "Strzhewoi Airport",
    "lat": "604236N",
    "long": "0773938E"
  },
  {
    "stationcode": "NOZ",
    "stationname": "Novokuznetsk Airport",
    "lat": "534823N",
    "long": "0865216E"
  },
  {
    "stationcode": "TGP",
    "stationname": "Podkamennaya Tungus Airport",
    "lat": "613535N",
    "long": "0900009E"
  },
  {
    "stationcode": "IAA",
    "stationname": "Igarka Airport",
    "lat": "672600N",
    "long": "0863800E"
  },
  {
    "stationcode": "ABA",
    "stationname": "Abakan Airport",
    "lat": "534424N",
    "long": "0912306E"
  },
  {
    "stationcode": "TOF",
    "stationname": "Tomsk Airport",
    "lat": "562309N",
    "long": "0851248E"
  },
  {
    "stationcode": "UKX",
    "stationname": "Ust-Kut Airport",
    "lat": "565126N",
    "long": "1054354E"
  },
  {
    "stationcode": "ERG",
    "stationname": "Erbogachen Airport",
    "lat": "611630N",
    "long": "1080148E"
  },
  {
    "stationcode": "HTG",
    "stationname": "Hatanga Airport",
    "lat": "715841N",
    "long": "1022933E"
  },
  {
    "stationcode": "MJZ",
    "stationname": "Mirnyj Airport",
    "lat": "623200N",
    "long": "1140200E"
  },
  {
    "stationcode": "BTK",
    "stationname": "Bratsk Airport",
    "lat": "562214N",
    "long": "1014154E"
  },
  {
    "stationcode": "ODO",
    "stationname": "Bodaybo Airport",
    "lat": "575159N",
    "long": "1141432E"
  },
  {
    "stationcode": "UUD",
    "stationname": "Ulan-Ude Airport",
    "lat": "514828N",
    "long": "1072616E"
  },
  {
    "stationcode": "HTA",
    "stationname": "Chita Airport",
    "lat": "520135N",
    "long": "1131820E"
  },
  {
    "stationcode": "VHV",
    "stationname": "Verkhnevilyuysk Airport",
    "lat": "632732N",
    "long": "1201607E"
  },
  {
    "stationcode": "KCK",
    "stationname": "Kirensk Airport",
    "lat": "574622N",
    "long": "1080331E"
  },
  {
    "stationcode": "IKT",
    "stationname": "Irkutsk Airport",
    "lat": "521605N",
    "long": "1042320E"
  },
  {
    "stationcode": "UIK",
    "stationname": "Ust-Ilimsk Airport",
    "lat": "580800N",
    "long": "1023300E"
  },
  {
    "stationcode": "GYG",
    "stationname": "Magan Airport",
    "lat": "620614N",
    "long": "1293235E"
  },
  {
    "stationcode": "VYI",
    "stationname": "Vilyuysk Airport",
    "lat": "634526N",
    "long": "1214130E"
  },
  {
    "stationcode": "OLZ",
    "stationname": "Olekminsk Airport",
    "lat": "602403N",
    "long": "1202815E"
  },
  {
    "stationcode": "BQS",
    "stationname": "Blagoveschensk Airport",
    "lat": "502531N",
    "long": "1272445E"
  },
  {
    "stationcode": "NYR",
    "stationname": "Nyurba Airport",
    "lat": "631746N",
    "long": "1182027E"
  },
  {
    "stationcode": "YKS",
    "stationname": "Yakutsk Airport",
    "lat": "620536N",
    "long": "1294614E"
  },
  {
    "stationcode": "CNN",
    "stationname": "Chulman Airport",
    "lat": "115457N",
    "long": "0753245E"
  },
  {
    "stationcode": "IKS",
    "stationname": "Tiksi Airport",
    "lat": "714150N",
    "long": "1285408E"
  },
  {
    "stationcode": "PYJ",
    "stationname": "Polyarnyj Airport",
    "lat": "662443N",
    "long": "1115640E"
  },
  {
    "stationcode": "TYD",
    "stationname": "Tynda Airport",
    "lat": "551705N",
    "long": "1244641E"
  },
  {
    "stationcode": "ULK",
    "stationname": "Lensk Airport",
    "lat": "604322N",
    "long": "1144919E"
  },
  {
    "stationcode": "NER",
    "stationname": "Neryungri Airport",
    "lat": "565441N",
    "long": "1245411E"
  },
  {
    "stationcode": "ONK",
    "stationname": "Olenek Airport",
    "lat": "683053N",
    "long": "1122848E"
  },
  {
    "stationcode": "ADH",
    "stationname": "Aldan Airport",
    "lat": "583610N",
    "long": "1252424E"
  },
  {
    "stationcode": "ZIX",
    "stationname": "Zhigansk Airport",
    "lat": "664738N",
    "long": "1232152E"
  },
  {
    "stationcode": "UMS",
    "stationname": "Ust-Maya Airport",
    "lat": "602122N",
    "long": "1342605E"
  },
  {
    "stationcode": "GDG",
    "stationname": "Magdagachi Airport",
    "lat": "532800N",
    "long": "1254800E"
  },
  {
    "stationcode": "BQG",
    "stationname": "Bogorodskoye Airport",
    "lat": "522242N",
    "long": "1402654E"
  },
  {
    "stationcode": "GDX",
    "stationname": "Magadan Airport",
    "lat": "595500N",
    "long": "1504300E"
  },
  {
    "stationcode": "OHH",
    "stationname": "Okha Airport",
    "lat": "533100N",
    "long": "1425300E"
  },
  {
    "stationcode": "DEE",
    "stationname": "Mendeleevoo Airport",
    "lat": "435741N",
    "long": "1454108E"
  },
  {
    "stationcode": "TLY",
    "stationname": "Plastun Airport",
    "lat": "444853N",
    "long": "1361730E"
  },
  {
    "stationcode": "UKG",
    "stationname": "Ust-Kuyga Airport",
    "lat": "700038N",
    "long": "1353833E"
  },
  {
    "stationcode": "ZZO",
    "stationname": "Zonalnoe Airport",
    "lat": "504008N",
    "long": "1424540E"
  },
  {
    "stationcode": "NGK",
    "stationname": "Nogliki Airport",
    "lat": "514654N",
    "long": "1430824E"
  },
  {
    "stationcode": "USR",
    "stationname": "Ust-Nera Airport",
    "lat": "643258N",
    "long": "1430639E"
  },
  {
    "stationcode": "DPT",
    "stationname": "Deputatsky Airport",
    "lat": "692333N",
    "long": "1395325E"
  },
  {
    "stationcode": "UUS",
    "stationname": "Yuzhno-Sakhalinsk Airport",
    "lat": "465319N",
    "long": "1424303E"
  },
  {
    "stationcode": "ITU",
    "stationname": "Iturup Airport",
    "lat": "451523N",
    "long": "1475721E"
  },
  {
    "stationcode": "EKS",
    "stationname": "Shakhtersk Airport",
    "lat": "491132N",
    "long": "1420452E"
  },
  {
    "stationcode": "VVO",
    "stationname": "Vladivostok Airport",
    "lat": "432356N",
    "long": "1320853E"
  },
  {
    "stationcode": "KVR",
    "stationname": "Kavalerovo Airport",
    "lat": "441621N",
    "long": "1350144E"
  },
  {
    "stationcode": "NLI",
    "stationname": "Nikolayevsk-na-Amure Airport",
    "lat": "530915N",
    "long": "1403908E"
  },
  {
    "stationcode": "GVN",
    "stationname": "Sovetskaya Gavan Airport",
    "lat": "485538N",
    "long": "1400203E"
  },
  {
    "stationcode": "KHV",
    "stationname": "Novyy Airport",
    "lat": "483141N",
    "long": "1351118E"
  },
  {
    "stationcode": "KXK",
    "stationname": "Komsomolsk Na Amure Airport",
    "lat": "502428N",
    "long": "1365609E"
  },
  {
    "stationcode": "BQJ",
    "stationname": "Batagay Airport",
    "lat": "673852N",
    "long": "1344136E"
  },
  {
    "stationcode": "JON",
    "stationname": "Josephstaal Airport",
    "lat": "164400N",
    "long": "1693200W"
  },
  {
    "stationcode": "MDY",
    "stationname": "Sand Island Field",
    "lat": "281207N",
    "long": "1772251W"
  },
  {
    "stationcode": "AWK",
    "stationname": "Wake Island Airport",
    "lat": "191800N",
    "long": "1663500E"
  },
  {
    "stationcode": "YNG",
    "stationname": "Youngstown Airport",
    "lat": "411547N",
    "long": "0804044W"
  },
  {
    "stationcode": "CTY",
    "stationname": "Cross City Airport",
    "lat": "293900N",
    "long": "0830700W"
  },
  {
    "stationcode": "GAI",
    "stationname": "Montgomery County Airport",
    "lat": "391006N",
    "long": "0770958W"
  },
  {
    "stationcode": "UCA",
    "stationname": "Oneida County Airport",
    "lat": "430829N",
    "long": "0752249W"
  },
  {
    "stationcode": "ATO",
    "stationname": "Ohio University Airport",
    "lat": "392000N",
    "long": "0820600W"
  },
  {
    "stationcode": "ACY",
    "stationname": "Atlantic City International Airport",
    "lat": "392727N",
    "long": "0743438W"
  },
  {
    "stationcode": "HLG",
    "stationname": "Ohio County Airport",
    "lat": "401100N",
    "long": "0803900W"
  },
  {
    "stationcode": "MTP",
    "stationname": "Sky Portal Airport",
    "lat": "410437N",
    "long": "0715513W"
  },
  {
    "stationcode": "UKT",
    "stationname": "Upper Bucks Airport",
    "lat": "402600N",
    "long": "0752000W"
  },
  {
    "stationcode": "GMU",
    "stationname": "Greenville Downtown Airport",
    "lat": "345210N",
    "long": "0822500W"
  },
  {
    "stationcode": "EWB",
    "stationname": "New Bedford Airport",
    "lat": "414034N",
    "long": "0705725W"
  },
  {
    "stationcode": "FRG",
    "stationname": "Republic Field",
    "lat": "404346N",
    "long": "0732452W"
  },
  {
    "stationcode": "DAN",
    "stationname": "Danville Municipal Airport",
    "lat": "363408N",
    "long": "0792022W"
  },
  {
    "stationcode": "RWI",
    "stationname": "Rocky Mount-Wilson Airport",
    "lat": "354600N",
    "long": "0775800W"
  },
  {
    "stationcode": "OWK",
    "stationname": "Central Maine Airport",
    "lat": "444300N",
    "long": "0694800W"
  },
  {
    "stationcode": "ANQ",
    "stationname": "Tri-State Steuben County Airport",
    "lat": "413800N",
    "long": "0850000W"
  },
  {
    "stationcode": "AOH",
    "stationname": "Allen County Airport",
    "lat": "404300N",
    "long": "0840200W"
  },
  {
    "stationcode": "AVL",
    "stationname": "Asheville Regional Airport",
    "lat": "352610N",
    "long": "0823231W"
  },
  {
    "stationcode": "BDR",
    "stationname": "Igor I. Sikorsky Memorial Airport",
    "lat": "410949N",
    "long": "0730734W"
  },
  {
    "stationcode": "FLL",
    "stationname": "Ft. Lauderdale International Airport",
    "lat": "260421N",
    "long": "0800910W"
  },
  {
    "stationcode": "DCA",
    "stationname": "Ronald Reagan National Airport",
    "lat": "385108N",
    "long": "0770216W"
  },
  {
    "stationcode": "MCN",
    "stationname": "Lewis B. Wilson Airport",
    "lat": "324146N",
    "long": "0833853W"
  },
  {
    "stationcode": "DRE",
    "stationname": "Drummond Island Airport",
    "lat": "460032N",
    "long": "0834447W"
  },
  {
    "stationcode": "LGA",
    "stationname": "La Guardia Airport",
    "lat": "404638N",
    "long": "0735221W"
  },
  {
    "stationcode": "KYL",
    "stationname": "Port Largo Airport",
    "lat": "250400N",
    "long": "0802800W"
  },
  {
    "stationcode": "LAL",
    "stationname": "Lakeland Municipal Airport",
    "lat": "280500N",
    "long": "0815700W"
  },
  {
    "stationcode": "GDW",
    "stationname": "Gladwin Airport",
    "lat": "435900N",
    "long": "0842900W"
  },
  {
    "stationcode": "TPF",
    "stationname": "Peter O'Knight Airport",
    "lat": "275814N",
    "long": "0823120W"
  },
  {
    "stationcode": "ILM",
    "stationname": "New Hanover County Airport",
    "lat": "341614N",
    "long": "0775409W"
  },
  {
    "stationcode": "SFB",
    "stationname": "Orlando Sanford Intl Airport",
    "lat": "284640N",
    "long": "0811415W"
  },
  {
    "stationcode": "BVY",
    "stationname": "Beverly Airport",
    "lat": "423503N",
    "long": "0705458W"
  },
  {
    "stationcode": "OKK",
    "stationname": "Kokomo Airport",
    "lat": "403200N",
    "long": "0860400W"
  },
  {
    "stationcode": "ALB",
    "stationname": "Albany International Airport",
    "lat": "424454N",
    "long": "0734806W"
  },
  {
    "stationcode": "AZO",
    "stationname": "Battle Creek International Airport",
    "lat": "421406N",
    "long": "0853307W"
  },
  {
    "stationcode": "MUV",
    "stationname": "Mustin Alf Airport",
    "lat": "395205N",
    "long": "0751455W"
  },
  {
    "stationcode": "OCA",
    "stationname": "Ocean Reef Airport",
    "lat": "252200N",
    "long": "0801600W"
  },
  {
    "stationcode": "ABE",
    "stationname": "Lehigh Valley International Airport",
    "lat": "403908N",
    "long": "0752627W"
  },
  {
    "stationcode": "KYO",
    "stationname": "Topp of Tampa Airport",
    "lat": "281310N",
    "long": "0822210W"
  },
  {
    "stationcode": "HWO",
    "stationname": "North Perry Airport",
    "lat": "260008N",
    "long": "0801436W"
  },
  {
    "stationcode": "TMA",
    "stationname": "Henry Tift Myers Airport",
    "lat": "312600N",
    "long": "0832900W"
  },
  {
    "stationcode": "GRR",
    "stationname": "Kent County International Airport",
    "lat": "425251N",
    "long": "0853122W"
  },
  {
    "stationcode": "RIC",
    "stationname": "Richmond International Airport (Byrd Field)",
    "lat": "373022N",
    "long": "0771921W"
  },
  {
    "stationcode": "RMG",
    "stationname": "Richard B Russell Airport",
    "lat": "342100N",
    "long": "0851000W"
  },
  {
    "stationcode": "CAD",
    "stationname": "Cadillac Airport",
    "lat": "441633N",
    "long": "0852520W"
  },
  {
    "stationcode": "OCF",
    "stationname": "Taylor Field",
    "lat": "291009N",
    "long": "0821323W"
  },
  {
    "stationcode": "HCC",
    "stationname": "Columbia County Airport",
    "lat": "421740N",
    "long": "0734240W"
  },
  {
    "stationcode": "HCW",
    "stationname": "Cheraw Airport",
    "lat": "344200N",
    "long": "0795300W"
  },
  {
    "stationcode": "LHV",
    "stationname": "W T Piper Memorial Airport",
    "lat": "410800N",
    "long": "0772700W"
  },
  {
    "stationcode": "KPD",
    "stationname": "King Of Prussia Airport",
    "lat": "400500N",
    "long": "0752400W"
  },
  {
    "stationcode": "STQ",
    "stationname": "St. Marys Airport",
    "lat": "412600N",
    "long": "0783400W"
  },
  {
    "stationcode": "PGV",
    "stationname": "Pitt-Greenville Airport",
    "lat": "353809N",
    "long": "0772303W"
  },
  {
    "stationcode": "BRY",
    "stationname": "Samuels Field",
    "lat": "374849N",
    "long": "0853000W"
  },
  {
    "stationcode": "HPN",
    "stationname": "Westchester County Airport",
    "lat": "410401N",
    "long": "0734227W"
  },
  {
    "stationcode": "ISO",
    "stationname": "Stallings Field",
    "lat": "352000N",
    "long": "0773700W"
  },
  {
    "stationcode": "DNL",
    "stationname": "Daniel Field",
    "lat": "332800N",
    "long": "0820200W"
  },
  {
    "stationcode": "OPF",
    "stationname": "Opa Locka",
    "lat": "254810N",
    "long": "0801700W"
  },
  {
    "stationcode": "PBG",
    "stationname": "Plattsburgh International Airport",
    "lat": "443909N",
    "long": "0732808W"
  },
  {
    "stationcode": "AHN",
    "stationname": "Athens Airport",
    "lat": "335658N",
    "long": "0831919W"
  },
  {
    "stationcode": "VDI",
    "stationname": "Vidalia Municipal Airport",
    "lat": "321300N",
    "long": "0822400W"
  },
  {
    "stationcode": "VRB",
    "stationname": "Vero Beach Municipal Airport",
    "lat": "273920N",
    "long": "0802505W"
  },
  {
    "stationcode": "JXN",
    "stationname": "Reynolds Municipal Airport",
    "lat": "421534N",
    "long": "0842742W"
  },
  {
    "stationcode": "HFD",
    "stationname": "Brainard Airport",
    "lat": "414414N",
    "long": "0723855W"
  },
  {
    "stationcode": "COI",
    "stationname": "Merritt Island Airport",
    "lat": "281900N",
    "long": "0803600W"
  },
  {
    "stationcode": "NEN",
    "stationname": "Olf Usn Airport",
    "lat": "302000N",
    "long": "0815300W"
  },
  {
    "stationcode": "APN",
    "stationname": "Alpena County Regional Airport",
    "lat": "450441N",
    "long": "0833337W"
  },
  {
    "stationcode": "CEW",
    "stationname": "Bob Sikes Airport",
    "lat": "304700N",
    "long": "0863100W"
  },
  {
    "stationcode": "MIE",
    "stationname": "Delaware County Airport",
    "lat": "401432N",
    "long": "0852352W"
  },
  {
    "stationcode": "WBN",
    "stationname": "Cummings Park Airport",
    "lat": "422800N",
    "long": "0710900W"
  },
  {
    "stationcode": "MTH",
    "stationname": "Marathon Flight Strip",
    "lat": "244333N",
    "long": "0810310W"
  },
  {
    "stationcode": "HGR",
    "stationname": "Wash. County Regional Airport",
    "lat": "394229N",
    "long": "0774346W"
  },
  {
    "stationcode": "TIX",
    "stationname": "Space Center Executive Airport",
    "lat": "283100N",
    "long": "0804800W"
  },
  {
    "stationcode": "ISM",
    "stationname": "Kissimmee Municipal Airport",
    "lat": "281726N",
    "long": "0812616W"
  },
  {
    "stationcode": "CVG",
    "stationname": "Cincinnati/Northern Kentucky Airport",
    "lat": "390256N",
    "long": "0844004W"
  },
  {
    "stationcode": "ROA",
    "stationname": "Roanoke Municipal Airport",
    "lat": "371932N",
    "long": "0795832W"
  },
  {
    "stationcode": "OLE",
    "stationname": "Olean Municipal Airport",
    "lat": "421428N",
    "long": "0782216W"
  },
  {
    "stationcode": "ORF",
    "stationname": "Norfolk International Airport",
    "lat": "365341N",
    "long": "0761204W"
  },
  {
    "stationcode": "OXC",
    "stationname": "Waterbury-Oxford Airport",
    "lat": "412843N",
    "long": "0730807W"
  },
  {
    "stationcode": "AGC",
    "stationname": "Allegheny County Airport",
    "lat": "402116N",
    "long": "0795547W"
  },
  {
    "stationcode": "MLJ",
    "stationname": "Baldwin County Airport",
    "lat": "330900N",
    "long": "0831500W"
  },
  {
    "stationcode": "LBE",
    "stationname": "Westmoreland County Airport",
    "lat": "401633N",
    "long": "0792417W"
  },
  {
    "stationcode": "LEE",
    "stationname": "Leesburg Airport",
    "lat": "284920N",
    "long": "0814826W"
  },
  {
    "stationcode": "MSS",
    "stationname": "Richards Field",
    "lat": "445609N",
    "long": "0745044W"
  },
  {
    "stationcode": "HHH",
    "stationname": "Hilton Head Airport",
    "lat": "321320N",
    "long": "0804156W"
  },
  {
    "stationcode": "HTO",
    "stationname": "East Hampton Airport",
    "lat": "405733N",
    "long": "0721504W"
  },
  {
    "stationcode": "GVL",
    "stationname": "Lee Gilmer Memorial Airport",
    "lat": "341600N",
    "long": "0835000W"
  },
  {
    "stationcode": "ISQ",
    "stationname": "Schoolcraft County Airport",
    "lat": "455800N",
    "long": "0861000W"
  },
  {
    "stationcode": "EKI",
    "stationname": "Elkhart Municipal Airport",
    "lat": "414300N",
    "long": "0855900W"
  },
  {
    "stationcode": "DBN",
    "stationname": "Dublin Municipal Airport",
    "lat": "323300N",
    "long": "0825900W"
  },
  {
    "stationcode": "PMX",
    "stationname": "Palmer Metropolitan Airport",
    "lat": "420900N",
    "long": "0721900W"
  },
  {
    "stationcode": "FKN",
    "stationname": "Franklin Municipal Airport",
    "lat": "364100N",
    "long": "0765500W"
  },
  {
    "stationcode": "FME",
    "stationname": "Tipton Airport",
    "lat": "390508N",
    "long": "0764534W"
  },
  {
    "stationcode": "LBT",
    "stationname": "Lumberton Airport",
    "lat": "343600N",
    "long": "0790400W"
  },
  {
    "stationcode": "MQW",
    "stationname": "Telfair-Wheeler Airport",
    "lat": "320500N",
    "long": "0825200W"
  },
  {
    "stationcode": "GIF",
    "stationname": "Gilbert Field Airport",
    "lat": "280400N",
    "long": "0814500W"
  },
  {
    "stationcode": "SBY",
    "stationname": "Wicomico County Airport",
    "lat": "382026N",
    "long": "0753037W"
  },
  {
    "stationcode": "BZS",
    "stationname": "Buzzards Point S Airport",
    "lat": "432010N",
    "long": "0031309E"
  },
  {
    "stationcode": "CDW",
    "stationname": "Caldwell Wright Airport",
    "lat": "405231N",
    "long": "0741651W"
  },
  {
    "stationcode": "MYR",
    "stationname": "Myrtle Beach Air Force Base",
    "lat": "334047N",
    "long": "0785542W"
  },
  {
    "stationcode": "PVD",
    "stationname": "Theodore Francis Airport",
    "lat": "414330N",
    "long": "0712533W"
  },
  {
    "stationcode": "LYH",
    "stationname": "Preston-Glenn Field",
    "lat": "371936N",
    "long": "0791202W"
  },
  {
    "stationcode": "LZU",
    "stationname": "Lawrenceville Airport",
    "lat": "335841N",
    "long": "0835744W"
  },
  {
    "stationcode": "CIU",
    "stationname": "Chippewa County Airport",
    "lat": "461503N",
    "long": "0842821W"
  },
  {
    "stationcode": "PCT",
    "stationname": "Princeton Airport",
    "lat": "402357N",
    "long": "0743932W"
  },
  {
    "stationcode": "NLE",
    "stationname": "Jerry Tyler Memorial Airport",
    "lat": "415100N",
    "long": "0861300W"
  },
  {
    "stationcode": "RCR",
    "stationname": "Fulton County Airport",
    "lat": "410400N",
    "long": "0861300W"
  },
  {
    "stationcode": "SFM",
    "stationname": "Sanford Airport",
    "lat": "432332N",
    "long": "0704228W"
  },
  {
    "stationcode": "WSH",
    "stationname": "Brookhaven Airport",
    "lat": "404700N",
    "long": "0725500W"
  },
  {
    "stationcode": "WTC",
    "stationname": "World Trade Center Airport",
    "lat": "404200N",
    "long": "0740100W"
  },
  {
    "stationcode": "OAJ",
    "stationname": "Albert J Ellis Airport",
    "lat": "344948N",
    "long": "0773640W"
  },
  {
    "stationcode": "MGR",
    "stationname": "Thomasville Airport",
    "lat": "310500N",
    "long": "0834800W"
  },
  {
    "stationcode": "HTL",
    "stationname": "Roscommon County Airport",
    "lat": "442159N",
    "long": "0844030W"
  },
  {
    "stationcode": "ARB",
    "stationname": "Ann Arbor Municipal Airport",
    "lat": "421325N",
    "long": "0834445W"
  },
  {
    "stationcode": "BHB",
    "stationname": "Bar Harbor Airport",
    "lat": "442648N",
    "long": "0682141W"
  },
  {
    "stationcode": "TNT",
    "stationname": "Dade Collier Airport",
    "lat": "253300N",
    "long": "0803200W"
  },
  {
    "stationcode": "TOL",
    "stationname": "Express Airport",
    "lat": "413527N",
    "long": "0834803W"
  },
  {
    "stationcode": "BLM",
    "stationname": "Monmouth County Airport",
    "lat": "401108N",
    "long": "0740725W"
  },
  {
    "stationcode": "POU",
    "stationname": "Dutchess County Airport",
    "lat": "413740N",
    "long": "0735257W"
  },
  {
    "stationcode": "IPT",
    "stationname": "Lycoming County Airport",
    "lat": "411431N",
    "long": "0765516W"
  },
  {
    "stationcode": "VLD",
    "stationname": "Valdosta Regional Airport",
    "lat": "304657N",
    "long": "0831636W"
  },
  {
    "stationcode": "LWB",
    "stationname": "Greenbrier Valley Airport",
    "lat": "375122N",
    "long": "0802407W"
  },
  {
    "stationcode": "GED",
    "stationname": "Sussex County Airport",
    "lat": "384100N",
    "long": "0752200W"
  },
  {
    "stationcode": "GSH",
    "stationname": "Goshen Airport",
    "lat": "413500N",
    "long": "0855000W"
  },
  {
    "stationcode": "HVN",
    "stationname": "New Haven Airport",
    "lat": "411550N",
    "long": "0725313W"
  },
  {
    "stationcode": "TRI",
    "stationname": "Tri-Cities Regional",
    "lat": "362831N",
    "long": "0822427W"
  },
  {
    "stationcode": "CMH",
    "stationname": "Port Columbus International Airport",
    "lat": "395953N",
    "long": "0825331W"
  },
  {
    "stationcode": "PYM",
    "stationname": "Plymouth Airport",
    "lat": "415441N",
    "long": "0704344W"
  },
  {
    "stationcode": "WAL",
    "stationname": "Wallops Flight Center",
    "lat": "375600N",
    "long": "0752300W"
  },
  {
    "stationcode": "OTN",
    "stationname": "Green Airport",
    "lat": "385200N",
    "long": "0872600W"
  },
  {
    "stationcode": "BNL",
    "stationname": "Barnwell County Airport",
    "lat": "331500N",
    "long": "0812300W"
  },
  {
    "stationcode": "OXD",
    "stationname": "Miami University Airport",
    "lat": "393000N",
    "long": "0844400W"
  },
  {
    "stationcode": "WFK",
    "stationname": "Frenchville Airport",
    "lat": "472100N",
    "long": "0682300W"
  },
  {
    "stationcode": "MQT",
    "stationname": "Sawyer International Airport",
    "lat": "462058N",
    "long": "0872314W"
  },
  {
    "stationcode": "HNC",
    "stationname": "Hatteras Airport",
    "lat": "351358N",
    "long": "0753706W"
  },
  {
    "stationcode": "SCR",
    "stationname": "Scranton Municipal Airport",
    "lat": "610932N",
    "long": "0125032E"
  },
  {
    "stationcode": "GLR",
    "stationname": "Otsego County Airport",
    "lat": "450100N",
    "long": "0844200W"
  },
  {
    "stationcode": "EYW",
    "stationname": "Key West International Airport",
    "lat": "243322N",
    "long": "0814534W"
  },
  {
    "stationcode": "FXE",
    "stationname": "Executive Airport",
    "lat": "261146N",
    "long": "0801011W"
  },
  {
    "stationcode": "MJX",
    "stationname": "Robert J. Miller Airport",
    "lat": "395539N",
    "long": "0741738W"
  },
  {
    "stationcode": "AIY",
    "stationname": "Bader Field",
    "lat": "392136N",
    "long": "0742726W"
  },
  {
    "stationcode": "CNH",
    "stationname": "Claremont Municipal Airport",
    "lat": "432200N",
    "long": "0722200W"
  },
  {
    "stationcode": "BUF",
    "stationname": "Buffalo Niagara International Airport",
    "lat": "425626N",
    "long": "0784356W"
  },
  {
    "stationcode": "ORL",
    "stationname": "Herndon Airport",
    "lat": "283246N",
    "long": "0811954W"
  },
  {
    "stationcode": "OSC",
    "stationname": "Wurtsmith Airport",
    "lat": "442600N",
    "long": "0832000W"
  },
  {
    "stationcode": "PKB",
    "stationname": "Wood County Airport",
    "lat": "392044N",
    "long": "0812620W"
  },
  {
    "stationcode": "PSK",
    "stationname": "New River Valley Airport",
    "lat": "370700N",
    "long": "0804100W"
  },
  {
    "stationcode": "MLT",
    "stationname": "Millinocket Airport",
    "lat": "453858N",
    "long": "0684132W"
  },
  {
    "stationcode": "RZZ",
    "stationname": "Halifax County Airport",
    "lat": "362800N",
    "long": "0774000W"
  },
  {
    "stationcode": "AFN",
    "stationname": "Jaffrey Municipal Airport",
    "lat": "424820N",
    "long": "0720012W"
  },
  {
    "stationcode": "AMN",
    "stationname": "Gratiot Community Airport",
    "lat": "432300N",
    "long": "0843900W"
  },
  {
    "stationcode": "OGS",
    "stationname": "Ogdensburg Airport",
    "lat": "444055N",
    "long": "0752757W"
  },
  {
    "stationcode": "HAR",
    "stationname": "Harrisburg Skyport",
    "lat": "401600N",
    "long": "0765200W"
  },
  {
    "stationcode": "FPY",
    "stationname": "Perry-Foley Airport",
    "lat": "300700N",
    "long": "0833500W"
  },
  {
    "stationcode": "CDK",
    "stationname": "Lewis Airport",
    "lat": "290800N",
    "long": "0830200W"
  },
  {
    "stationcode": "PIT",
    "stationname": "Pittsburgh International Airport",
    "lat": "402948N",
    "long": "0801409W"
  },
  {
    "stationcode": "ADG",
    "stationname": "Lenawee County Airport",
    "lat": "415400N",
    "long": "0840200W"
  },
  {
    "stationcode": "HLB",
    "stationname": "Hillenbrand Airport",
    "lat": "391800N",
    "long": "0851300W"
  },
  {
    "stationcode": "PSF",
    "stationname": "Pittsfield Airport",
    "lat": "422539N",
    "long": "0731732W"
  },
  {
    "stationcode": "LOU",
    "stationname": "Bowman Field",
    "lat": "381038N",
    "long": "0854415W"
  },
  {
    "stationcode": "BJJ",
    "stationname": "Wayne County Airport",
    "lat": "404800N",
    "long": "0815600W"
  },
  {
    "stationcode": "RSX",
    "stationname": "Rouses Point Airport",
    "lat": "445905N",
    "long": "0732108W"
  },
  {
    "stationcode": "AND",
    "stationname": "Anderson Airport",
    "lat": "343000N",
    "long": "0824300W"
  },
  {
    "stationcode": "QWG",
    "stationname": "Wilgrove Air Park",
    "lat": "351240N",
    "long": "0804006W"
  },
  {
    "stationcode": "WST",
    "stationname": "Westerly State Airport",
    "lat": "412102N",
    "long": "0714808W"
  },
  {
    "stationcode": "PHN",
    "stationname": "St Clair County International Airport",
    "lat": "425400N",
    "long": "0823200W"
  },
  {
    "stationcode": "MNN",
    "stationname": "Marion Municipal Airport",
    "lat": "403700N",
    "long": "0830400W"
  },
  {
    "stationcode": "RDU",
    "stationname": "Raleigh/Durham Airport",
    "lat": "355240N",
    "long": "0784715W"
  },
  {
    "stationcode": "BCB",
    "stationname": "Virginia Tech Airport",
    "lat": "371230N",
    "long": "0802433W"
  },
  {
    "stationcode": "ORH",
    "stationname": "Worcester Airport",
    "lat": "421600N",
    "long": "0715227W"
  },
  {
    "stationcode": "ABY",
    "stationname": "Dougherty County Airport",
    "lat": "313208N",
    "long": "0841140W"
  },
  {
    "stationcode": "FRY",
    "stationname": "Fryeburg Airport",
    "lat": "435927N",
    "long": "0705651W"
  },
  {
    "stationcode": "PSB",
    "stationname": "Mid-State Airport",
    "lat": "405300N",
    "long": "0780500W"
  },
  {
    "stationcode": "LLY",
    "stationname": "Burlington County Airport",
    "lat": "395900N",
    "long": "0744700W"
  },
  {
    "stationcode": "PHD",
    "stationname": "Harry Clever Airport",
    "lat": "402800N",
    "long": "0812600W"
  },
  {
    "stationcode": "MSV",
    "stationname": "Sullivan County International Airport",
    "lat": "413700N",
    "long": "0744200W"
  },
  {
    "stationcode": "GDC",
    "stationname": "Donaldson Center Airport",
    "lat": "345053N",
    "long": "0822055W"
  },
  {
    "stationcode": "RID",
    "stationname": "Richmond Airport",
    "lat": "394500N",
    "long": "0845100W"
  },
  {
    "stationcode": "UST",
    "stationname": "St. Augustine Airport",
    "lat": "295741N",
    "long": "0812028W"
  },
  {
    "stationcode": "CHO",
    "stationname": "Albemarle Airport",
    "lat": "380819N",
    "long": "0782710W"
  },
  {
    "stationcode": "RCT",
    "stationname": "Miller Field",
    "lat": "435400N",
    "long": "0853100W"
  },
  {
    "stationcode": "ROC",
    "stationname": "Monroe County Airport",
    "lat": "430708N",
    "long": "0774021W"
  },
  {
    "stationcode": "AVO",
    "stationname": "Avon Park Municipal Airport",
    "lat": "273600N",
    "long": "0813100W"
  },
  {
    "stationcode": "MRN",
    "stationname": "Lenoir Airport",
    "lat": "354500N",
    "long": "0814330W"
  },
  {
    "stationcode": "RSW",
    "stationname": "Southwest Florida Regional Airport",
    "lat": "263210N",
    "long": "0814519W"
  },
  {
    "stationcode": "LAN",
    "stationname": "Capital City Airport",
    "lat": "424643N",
    "long": "0843514W"
  },
  {
    "stationcode": "HNB",
    "stationname": "Huntingburg Municipal Airport",
    "lat": "381800N",
    "long": "0865700W"
  },
  {
    "stationcode": "SHD",
    "stationname": "Shenandoah Valley Airport",
    "lat": "381543N",
    "long": "0785355W"
  },
  {
    "stationcode": "RUT",
    "stationname": "Rutland Airport",
    "lat": "433146N",
    "long": "0725659W"
  },
  {
    "stationcode": "AKC",
    "stationname": "Fulton International Airport",
    "lat": "410213N",
    "long": "0812808W"
  },
  {
    "stationcode": "CYE",
    "stationname": "Crystal Lake Airport",
    "lat": "421400N",
    "long": "0881900W"
  },
  {
    "stationcode": "ELZ",
    "stationname": "Wellsville Municipal Airport",
    "lat": "420700N",
    "long": "0775600W"
  },
  {
    "stationcode": "CEV",
    "stationname": "Mettle Field",
    "lat": "393800N",
    "long": "0850800W"
  },
  {
    "stationcode": "CGS",
    "stationname": "College Park Airport",
    "lat": "385850N",
    "long": "0765520W"
  },
  {
    "stationcode": "LCI",
    "stationname": "Laconia Municipal Airport",
    "lat": "433420N",
    "long": "0712515W"
  },
  {
    "stationcode": "PDK",
    "stationname": "Peachtree-Dekalb Airport",
    "lat": "335300N",
    "long": "0841800W"
  },
  {
    "stationcode": "SFZ",
    "stationname": "North Central Airport",
    "lat": "415515N",
    "long": "0712929W"
  },
  {
    "stationcode": "GDM",
    "stationname": "Gardner Municipal Airport",
    "lat": "423400N",
    "long": "0720000W"
  },
  {
    "stationcode": "CTX",
    "stationname": "Cortland Airport",
    "lat": "423536N",
    "long": "0761251W"
  },
  {
    "stationcode": "FID",
    "stationname": "Elizabeth Field",
    "lat": "411508N",
    "long": "0720156W"
  },
  {
    "stationcode": "SEF",
    "stationname": "Sebring Air Terminal",
    "lat": "272700N",
    "long": "0812100W"
  },
  {
    "stationcode": "MWO",
    "stationname": "Hook Field",
    "lat": "393000N",
    "long": "0842300W"
  },
  {
    "stationcode": "WGO",
    "stationname": "Winchester Municipal Airport",
    "lat": "390836N",
    "long": "0780839W"
  },
  {
    "stationcode": "DNN",
    "stationname": "Dalton Municipal Airport",
    "lat": "344300N",
    "long": "0845200W"
  },
  {
    "stationcode": "MAC",
    "stationname": "Smart Airport",
    "lat": "324920N",
    "long": "0833339W"
  },
  {
    "stationcode": "BOS",
    "stationname": "Logan International Airport",
    "lat": "422152N",
    "long": "0710019W"
  },
  {
    "stationcode": "CAE",
    "stationname": "Columbia Metropolitan Airport",
    "lat": "335620N",
    "long": "0810710W"
  },
  {
    "stationcode": "FMY",
    "stationname": "Page Field",
    "lat": "263500N",
    "long": "0815200W"
  },
  {
    "stationcode": "PLY",
    "stationname": "Plymouth Airport",
    "lat": "412200N",
    "long": "0861800W"
  },
  {
    "stationcode": "SGH",
    "stationname": "Springfield Airport",
    "lat": "395600N",
    "long": "0834900W"
  },
  {
    "stationcode": "INT",
    "stationname": "Smith-Reynolds Airport",
    "lat": "360815N",
    "long": "0801331W"
  },
  {
    "stationcode": "BSI",
    "stationname": "Blairsville Airport",
    "lat": "402700N",
    "long": "0791700W"
  },
  {
    "stationcode": "THV",
    "stationname": "York Airport",
    "lat": "395700N",
    "long": "0764400W"
  },
  {
    "stationcode": "CTO",
    "stationname": "Peconic River Airport",
    "lat": "405500N",
    "long": "0724500W"
  },
  {
    "stationcode": "RKW",
    "stationname": "Rockwood Municipal Airport",
    "lat": "355500N",
    "long": "0844100W"
  },
  {
    "stationcode": "BGE",
    "stationname": "Decatur County Airport",
    "lat": "305400N",
    "long": "0843600W"
  },
  {
    "stationcode": "ARX",
    "stationname": "Asbury Park Airport",
    "lat": "043409S",
    "long": "0374820W"
  },
  {
    "stationcode": "ASH",
    "stationname": "Boire Field",
    "lat": "424700N",
    "long": "0713100W"
  },
  {
    "stationcode": "RTD",
    "stationname": "Rotunda Airport",
    "lat": "265200N",
    "long": "0821600W"
  },
  {
    "stationcode": "JHW",
    "stationname": "Jamestown Airport",
    "lat": "420908N",
    "long": "0791534W"
  },
  {
    "stationcode": "MTN",
    "stationname": "Glenn L. Martin Airport",
    "lat": "391934N",
    "long": "0762450W"
  },
  {
    "stationcode": "EHT",
    "stationname": "Rentschler Airport",
    "lat": "414600N",
    "long": "0723900W"
  },
  {
    "stationcode": "QMV",
    "stationname": "Montvale Airport",
    "lat": "410200N",
    "long": "0740100W"
  },
  {
    "stationcode": "BED",
    "stationname": "Hanscom Field",
    "lat": "422812N",
    "long": "0711721W"
  },
  {
    "stationcode": "MPO",
    "stationname": "Mount Pocono Airport",
    "lat": "410810N",
    "long": "0752247W"
  },
  {
    "stationcode": "AXV",
    "stationname": "Neil Armstrong Airport",
    "lat": "403400N",
    "long": "0841100W"
  },
  {
    "stationcode": "LNN",
    "stationname": "Lost Nation Airport",
    "lat": "413800N",
    "long": "0812500W"
  },
  {
    "stationcode": "XSM",
    "stationname": "St Marys Airport",
    "lat": "373316N",
    "long": "1265815E"
  },
  {
    "stationcode": "CON",
    "stationname": "Concord Airport",
    "lat": "431210N",
    "long": "0713008W"
  },
  {
    "stationcode": "WAY",
    "stationname": "Green County Airport",
    "lat": "395400N",
    "long": "0801100W"
  },
  {
    "stationcode": "OBT",
    "stationname": "Oakland/Coliseum Station",
    "lat": "374509N",
    "long": "1221155W"
  },
  {
    "stationcode": "PVC",
    "stationname": "Provincetown Airport",
    "lat": "420422N",
    "long": "0701313W"
  },
  {
    "stationcode": "FTY",
    "stationname": "Fulton County Airport",
    "lat": "334642N",
    "long": "0843137W"
  },
  {
    "stationcode": "PIE",
    "stationname": "St. Petersburg International Airport",
    "lat": "275437N",
    "long": "0824115W"
  },
  {
    "stationcode": "MGJ",
    "stationname": "Orange County Airport",
    "lat": "413100N",
    "long": "0741600W"
  },
  {
    "stationcode": "HSP",
    "stationname": "Ingalls Field",
    "lat": "375708N",
    "long": "0794959W"
  },
  {
    "stationcode": "IND",
    "stationname": "Indianapolis International Airport",
    "lat": "394302N",
    "long": "0861740W"
  },
  {
    "stationcode": "CSG",
    "stationname": "Metropolitan Area",
    "lat": "323052N",
    "long": "0845625W"
  },
  {
    "stationcode": "BEH",
    "stationname": "Ross Field",
    "lat": "420800N",
    "long": "0862600W"
  },
  {
    "stationcode": "NGU",
    "stationname": "Naval Air Station Chambers",
    "lat": "365347N",
    "long": "0761154W"
  },
  {
    "stationcode": "FDY",
    "stationname": "Findlay Airport",
    "lat": "410100N",
    "long": "0834000W"
  },
  {
    "stationcode": "MBL",
    "stationname": "Blacker Airport",
    "lat": "441623N",
    "long": "0861508W"
  },
  {
    "stationcode": "POB",
    "stationname": "Pope Field Airport",
    "lat": "351003N",
    "long": "0790106W"
  },
  {
    "stationcode": "LWM",
    "stationname": "Lawrence Airport",
    "lat": "424303N",
    "long": "0710720W"
  },
  {
    "stationcode": "HUF",
    "stationname": "Hulman Field",
    "lat": "392700N",
    "long": "0871800W"
  },
  {
    "stationcode": "CLE",
    "stationname": "Hopkins International Airport",
    "lat": "412442N",
    "long": "0815059W"
  },
  {
    "stationcode": "BPA",
    "stationname": "Grumman Airport",
    "lat": "114033N",
    "long": "1252842E"
  },
  {
    "stationcode": "BBF",
    "stationname": "Burlington Airport",
    "lat": "423000N",
    "long": "0711100W"
  },
  {
    "stationcode": "PVL",
    "stationname": "Pike County Regional Airport",
    "lat": "373339N",
    "long": "0823411W"
  },
  {
    "stationcode": "LOZ",
    "stationname": "Corbin-London Airport",
    "lat": "370500N",
    "long": "0840400W"
  },
  {
    "stationcode": "LJC",
    "stationname": "Intercontinental Airport",
    "lat": "361600N",
    "long": "0854500W"
  },
  {
    "stationcode": "DWS",
    "stationname": "Walt Disney World Airport",
    "lat": "282301N",
    "long": "0813300W"
  },
  {
    "stationcode": "MMU",
    "stationname": "Morristown Municipal Airport",
    "lat": "404752N",
    "long": "0742455W"
  },
  {
    "stationcode": "SLK",
    "stationname": "Adirondack Airport",
    "lat": "442258N",
    "long": "0741224W"
  },
  {
    "stationcode": "SRQ",
    "stationname": "Bradenton Airport",
    "lat": "272338N",
    "long": "0823315W"
  },
  {
    "stationcode": "LNA",
    "stationname": "Palm Beach County Airport",
    "lat": "263510N",
    "long": "0800500W"
  },
  {
    "stationcode": "UWA",
    "stationname": "Ware Airport",
    "lat": "421500N",
    "long": "0721400W"
  },
  {
    "stationcode": "SME",
    "stationname": "Pulaski County Airport",
    "lat": "370316N",
    "long": "0843654W"
  },
  {
    "stationcode": "LFN",
    "stationname": "Franklin Airport",
    "lat": "360600N",
    "long": "0781800W"
  },
  {
    "stationcode": "DUJ",
    "stationname": "Jefferson County Airport",
    "lat": "411042N",
    "long": "0785356W"
  },
  {
    "stationcode": "DXR",
    "stationname": "Danbury Airport",
    "lat": "412216N",
    "long": "0732856W"
  },
  {
    "stationcode": "LEB",
    "stationname": "Lebanon Regional Airport",
    "lat": "433740N",
    "long": "0721826W"
  },
  {
    "stationcode": "ESP",
    "stationname": "Birchwood-Pocono Airport",
    "lat": "410000N",
    "long": "0751100W"
  },
  {
    "stationcode": "GQQ",
    "stationname": "Galion Airport",
    "lat": "404400N",
    "long": "0824800W"
  },
  {
    "stationcode": "ILN",
    "stationname": "Clinton Field",
    "lat": "392523N",
    "long": "0834712W"
  },
  {
    "stationcode": "ISP",
    "stationname": "Long Island MacArthur Airport",
    "lat": "404743N",
    "long": "0730601W"
  },
  {
    "stationcode": "FFT",
    "stationname": "Capital City Airport",
    "lat": "381100N",
    "long": "0845400W"
  },
  {
    "stationcode": "MVY",
    "stationname": "Martha's Vineyard Airport",
    "lat": "412336N",
    "long": "0703645W"
  },
  {
    "stationcode": "FKL",
    "stationname": "Chess-Lambertin Airport",
    "lat": "412245N",
    "long": "0795136W"
  },
  {
    "stationcode": "CRW",
    "stationname": "Yeager Airport",
    "lat": "382223N",
    "long": "0813535W"
  },
  {
    "stationcode": "SSI",
    "stationname": "McKinnon Airport",
    "lat": "310900N",
    "long": "0812300W"
  },
  {
    "stationcode": "VJI",
    "stationname": "Virginia Highlands Airport",
    "lat": "364110N",
    "long": "0820207W"
  },
  {
    "stationcode": "WBR",
    "stationname": "Big Rapids Airport",
    "lat": "434300N",
    "long": "0853000W"
  },
  {
    "stationcode": "MRB",
    "stationname": "Martinsburg Airport",
    "lat": "392410N",
    "long": "0775859W"
  },
  {
    "stationcode": "HAI",
    "stationname": "Dr. Haines Airport",
    "lat": "415700N",
    "long": "0853800W"
  },
  {
    "stationcode": "CWG",
    "stationname": "Callaway Gardens Airport",
    "lat": "325025N",
    "long": "0845257W"
  },
  {
    "stationcode": "BWI",
    "stationname": "Balt./Wash. International Airport",
    "lat": "391031N",
    "long": "0764006W"
  },
  {
    "stationcode": "CDN",
    "stationname": "Woodward Field",
    "lat": "341600N",
    "long": "0803600W"
  },
  {
    "stationcode": "CBE",
    "stationname": "Wiley Ford Airport",
    "lat": "393600N",
    "long": "0784600W"
  },
  {
    "stationcode": "ANP",
    "stationname": "Lee Airport",
    "lat": "385900N",
    "long": "0763000W"
  },
  {
    "stationcode": "HIE",
    "stationname": "Whitefield Regional Airport",
    "lat": "442200N",
    "long": "0713300W"
  },
  {
    "stationcode": "ADR",
    "stationname": "Andrews Airport",
    "lat": "332700N",
    "long": "0793400W"
  },
  {
    "stationcode": "LLX",
    "stationname": "Lyndonville Airport",
    "lat": "443409N",
    "long": "0720105W"
  },
  {
    "stationcode": "SYR",
    "stationname": "Hancock International Airport",
    "lat": "430638N",
    "long": "0760609W"
  },
  {
    "stationcode": "BTL",
    "stationname": "WK Kellogg Regional Airport",
    "lat": "421814N",
    "long": "0851522W"
  },
  {
    "stationcode": "GFD",
    "stationname": "Pope Field",
    "lat": "394700N",
    "long": "0854600W"
  },
  {
    "stationcode": "LEW",
    "stationname": "Auburn Airport",
    "lat": "440300N",
    "long": "0701700W"
  },
  {
    "stationcode": "LQK",
    "stationname": "Pickens Airport",
    "lat": "344900N",
    "long": "0824200W"
  },
  {
    "stationcode": "GKT",
    "stationname": "Gatlinburg Airport",
    "lat": "355100N",
    "long": "0833100W"
  },
  {
    "stationcode": "DTW",
    "stationname": "Wayne County Airport",
    "lat": "421245N",
    "long": "0832112W"
  },
  {
    "stationcode": "AUG",
    "stationname": "Augusta Airport",
    "lat": "441905N",
    "long": "0694749W"
  },
  {
    "stationcode": "WSG",
    "stationname": "County Airport",
    "lat": "400812N",
    "long": "0801723W"
  },
  {
    "stationcode": "MVL",
    "stationname": "Morrisville-Stowe Airport",
    "lat": "443206N",
    "long": "0723651W"
  },
  {
    "stationcode": "IAG",
    "stationname": "Niagara Falls International Airport",
    "lat": "430626N",
    "long": "0785646W"
  },
  {
    "stationcode": "JVI",
    "stationname": "Kupper Airport",
    "lat": "403131N",
    "long": "0743550W"
  },
  {
    "stationcode": "TMB",
    "stationname": "Tamiami Airport",
    "lat": "253853N",
    "long": "0802559W"
  },
  {
    "stationcode": "AYS",
    "stationname": "Ware County Airport",
    "lat": "311500N",
    "long": "0822400W"
  },
  {
    "stationcode": "HUL",
    "stationname": "Houlton International Airport",
    "lat": "460800N",
    "long": "0674800W"
  },
  {
    "stationcode": "AGS",
    "stationname": "Bush Field",
    "lat": "332212N",
    "long": "0815752W"
  },
  {
    "stationcode": "MPE",
    "stationname": "Madison Airport",
    "lat": "411700N",
    "long": "0723600W"
  },
  {
    "stationcode": "TVI",
    "stationname": "Thomasville Municipal Airport",
    "lat": "305410N",
    "long": "0835250W"
  },
  {
    "stationcode": "LCK",
    "stationname": "Rickenbacker Airport",
    "lat": "394850N",
    "long": "0825540W"
  },
  {
    "stationcode": "WWD",
    "stationname": "Cape May County Airport",
    "lat": "392733N",
    "long": "0743443W"
  },
  {
    "stationcode": "IAD",
    "stationname": "Dulles International Airport",
    "lat": "385640N",
    "long": "0772721W"
  },
  {
    "stationcode": "MMI",
    "stationname": "McMinn County Airport",
    "lat": "352700N",
    "long": "0843600W"
  },
  {
    "stationcode": "ZPH",
    "stationname": "Zephyrhills Airport",
    "lat": "281400N",
    "long": "0821000W"
  },
  {
    "stationcode": "GNV",
    "stationname": "Gainesville Regional Airport",
    "lat": "294124N",
    "long": "0821618W"
  },
  {
    "stationcode": "GSP",
    "stationname": "Greenville-Spartanburg Airport",
    "lat": "345344N",
    "long": "0821308W"
  },
  {
    "stationcode": "FWA",
    "stationname": "Fort Wayne Municipal Airport/Baer Field",
    "lat": "405859N",
    "long": "0851123W"
  },
  {
    "stationcode": "SOP",
    "stationname": "Pinehurst-S. Pines Airport",
    "lat": "351409N",
    "long": "0792329W"
  },
  {
    "stationcode": "DAB",
    "stationname": "Daytona Beach Regional Airport",
    "lat": "291048N",
    "long": "0810329W"
  },
  {
    "stationcode": "DFI",
    "stationname": "Defiance Memorial Airport",
    "lat": "411700N",
    "long": "0842200W"
  },
  {
    "stationcode": "OWD",
    "stationname": "Norwood Memorial Airport",
    "lat": "421130N",
    "long": "0711034W"
  },
  {
    "stationcode": "MHT",
    "stationname": "Manchester-Boston Municipal Airport",
    "lat": "425557N",
    "long": "0712608W"
  },
  {
    "stationcode": "MZZ",
    "stationname": "Marion Airport",
    "lat": "402900N",
    "long": "0854100W"
  },
  {
    "stationcode": "GGE",
    "stationname": "Georgetown Airport",
    "lat": "331900N",
    "long": "0791900W"
  },
  {
    "stationcode": "HAO",
    "stationname": "Hamilton Airport",
    "lat": "392400N",
    "long": "0843400W"
  },
  {
    "stationcode": "DSV",
    "stationname": "Dansville Airport",
    "lat": "423300N",
    "long": "0774100W"
  },
  {
    "stationcode": "ESC",
    "stationname": "Delta County Airport",
    "lat": "454314N",
    "long": "0870545W"
  },
  {
    "stationcode": "COP",
    "stationname": "Cooperstown Airport",
    "lat": "424200N",
    "long": "0745600W"
  },
  {
    "stationcode": "PTK",
    "stationname": "Pontiac Airport",
    "lat": "423955N",
    "long": "0832512W"
  },
  {
    "stationcode": "REH",
    "stationname": "Rehoboth Beach Airport",
    "lat": "384200N",
    "long": "0750600W"
  },
  {
    "stationcode": "ACK",
    "stationname": "Nantucket Memorial Airport",
    "lat": "411511N",
    "long": "0700337W"
  },
  {
    "stationcode": "AOO",
    "stationname": "Altoona Airport",
    "lat": "401750N",
    "long": "0781912W"
  },
  {
    "stationcode": "AVP",
    "stationname": "Wilkes-Barre International Airport",
    "lat": "412019N",
    "long": "0754324W"
  },
  {
    "stationcode": "ISS",
    "stationname": "Wiscasset Airport",
    "lat": "435743N",
    "long": "0694243W"
  },
  {
    "stationcode": "PHL",
    "stationname": "Philadelphia International Airport",
    "lat": "395219N",
    "long": "0751428W"
  },
  {
    "stationcode": "MDN",
    "stationname": "Jefferson Proving Ground",
    "lat": "384400N",
    "long": "0852200W"
  },
  {
    "stationcode": "HLM",
    "stationname": "Park Township Airport",
    "lat": "424700N",
    "long": "0860700W"
  },
  {
    "stationcode": "ESN",
    "stationname": "Easton Airport",
    "lat": "384807N",
    "long": "0760407W"
  },
  {
    "stationcode": "ERI",
    "stationname": "Erie International Airport",
    "lat": "420455N",
    "long": "0801034W"
  },
  {
    "stationcode": "SKY",
    "stationname": "Griffin Sandusky Airport",
    "lat": "412600N",
    "long": "0824200W"
  },
  {
    "stationcode": "DET",
    "stationname": "Detroit City Airport",
    "lat": "422400N",
    "long": "0830000W"
  },
  {
    "stationcode": "BRG",
    "stationname": "Whitesburg Municipal Airport",
    "lat": "023909N",
    "long": "1252529E"
  },
  {
    "stationcode": "AXB",
    "stationname": "Alexandria Bay Airport",
    "lat": "441509N",
    "long": "0755405W"
  },
  {
    "stationcode": "SMD",
    "stationname": "Smith Field",
    "lat": "405900N",
    "long": "0851100W"
  },
  {
    "stationcode": "MCD",
    "stationname": "Mackinac Island Airport",
    "lat": "455200N",
    "long": "0843800W"
  },
  {
    "stationcode": "DKK",
    "stationname": "Dunkirk Airport",
    "lat": "422900N",
    "long": "0792000W"
  },
  {
    "stationcode": "JST",
    "stationname": "Cambria County Airport",
    "lat": "401858N",
    "long": "0785002W"
  },
  {
    "stationcode": "OBE",
    "stationname": "Okeechobee County Airport",
    "lat": "271500N",
    "long": "0805000W"
  },
  {
    "stationcode": "EKN",
    "stationname": "Elkins Airport",
    "lat": "385324N",
    "long": "0795128W"
  },
  {
    "stationcode": "SUM",
    "stationname": "Sumter Municipal Airport",
    "lat": "335410N",
    "long": "0800710W"
  },
  {
    "stationcode": "NEL",
    "stationname": "Naec Airport",
    "lat": "400100N",
    "long": "0741900W"
  },
  {
    "stationcode": "PGC",
    "stationname": "Grant County Airport",
    "lat": "390000N",
    "long": "0790700W"
  },
  {
    "stationcode": "CGF",
    "stationname": "Cuyahoga County Airport",
    "lat": "413400N",
    "long": "0812903W"
  },
  {
    "stationcode": "CHA",
    "stationname": "Lovell Field",
    "lat": "350207N",
    "long": "0851214W"
  },
  {
    "stationcode": "MCO",
    "stationname": "Orlando International Airport",
    "lat": "282546N",
    "long": "0811832W"
  },
  {
    "stationcode": "WDR",
    "stationname": "Winder Airport",
    "lat": "335900N",
    "long": "0834000W"
  },
  {
    "stationcode": "GWV",
    "stationname": "Glendale Airport",
    "lat": "395600N",
    "long": "0804400W"
  },
  {
    "stationcode": "EEN",
    "stationname": "Dillant-Hopkins Airport",
    "lat": "425400N",
    "long": "0721600W"
  },
  {
    "stationcode": "EWN",
    "stationname": "Craven County Regional Airport",
    "lat": "350423N",
    "long": "0770235W"
  },
  {
    "stationcode": "CLU",
    "stationname": "Columbus Municipal Airport",
    "lat": "390800N",
    "long": "0855600W"
  },
  {
    "stationcode": "PPM",
    "stationname": "Pompano Beach Airport",
    "lat": "261400N",
    "long": "0800740W"
  },
  {
    "stationcode": "ODM",
    "stationname": "Metropolitan Area Airport",
    "lat": "392450N",
    "long": "0792450W"
  },
  {
    "stationcode": "HYA",
    "stationname": "Barnstable Airport",
    "lat": "413956N",
    "long": "0701653W"
  },
  {
    "stationcode": "ITH",
    "stationname": "Tompkins County Airport",
    "lat": "422928N",
    "long": "0762739W"
  },
  {
    "stationcode": "ECA",
    "stationname": "Emmet County Airport",
    "lat": "441600N",
    "long": "0832900W"
  },
  {
    "stationcode": "FOK",
    "stationname": "Suffolk County Airport",
    "lat": "405031N",
    "long": "0723757W"
  },
  {
    "stationcode": "PTW",
    "stationname": "Pottstown/Limerick Airport",
    "lat": "401538N",
    "long": "0754018W"
  },
  {
    "stationcode": "RED",
    "stationname": "Mifflin County Airport",
    "lat": "404100N",
    "long": "0773700W"
  },
  {
    "stationcode": "LTW",
    "stationname": "Saint Marys County Airport",
    "lat": "381700N",
    "long": "0773800W"
  },
  {
    "stationcode": "BID",
    "stationname": "Block Island Airport",
    "lat": "411005N",
    "long": "0713444W"
  },
  {
    "stationcode": "LGC",
    "stationname": "Calloway Airport",
    "lat": "330100N",
    "long": "0850500W"
  },
  {
    "stationcode": "BML",
    "stationname": "Berlin Airport",
    "lat": "442900N",
    "long": "0711000W"
  },
  {
    "stationcode": "WBW",
    "stationname": "Wyoming Valle Airport",
    "lat": "411400N",
    "long": "0755300W"
  },
  {
    "stationcode": "WLM",
    "stationname": "Waltham Airport",
    "lat": "422307N",
    "long": "0711505W"
  },
  {
    "stationcode": "MQI",
    "stationname": "Quincy Airport",
    "lat": "421500N",
    "long": "0710000W"
  },
  {
    "stationcode": "AYZ",
    "stationname": "Zahns Airport",
    "lat": "404000N",
    "long": "0732500W"
  },
  {
    "stationcode": "SCE",
    "stationname": "University Park Airport",
    "lat": "405102N",
    "long": "0775050W"
  },
  {
    "stationcode": "SUA",
    "stationname": "Witham Field",
    "lat": "271106N",
    "long": "0801312W"
  },
  {
    "stationcode": "BFD",
    "stationname": "Bradford Airport",
    "lat": "414808N",
    "long": "0783822W"
  },
  {
    "stationcode": "CAK",
    "stationname": "Akron/Canton Regional Airport",
    "lat": "405458N",
    "long": "0812632W"
  },
  {
    "stationcode": "BKW",
    "stationname": "Beckley Airport",
    "lat": "374714N",
    "long": "0810727W"
  },
  {
    "stationcode": "ART",
    "stationname": "Watertown Airport",
    "lat": "435931N",
    "long": "0760118W"
  },
  {
    "stationcode": "BAF",
    "stationname": "Barnes Airport",
    "lat": "420931N",
    "long": "0724256W"
  },
  {
    "stationcode": "MUU",
    "stationname": "Mount Union Airport",
    "lat": "135422S",
    "long": "0385627W"
  },
  {
    "stationcode": "MEO",
    "stationname": "Dare County Regional Airport",
    "lat": "355508N",
    "long": "0754146W"
  },
  {
    "stationcode": "DPK",
    "stationname": "Deer Park Airport",
    "lat": "410000N",
    "long": "0733200W"
  },
  {
    "stationcode": "SAV",
    "stationname": "Savannah/Hilton Head Airport",
    "lat": "320738N",
    "long": "0811158W"
  },
  {
    "stationcode": "IKB",
    "stationname": "Wilkes County Airport",
    "lat": "361000N",
    "long": "0810900W"
  },
  {
    "stationcode": "VSF",
    "stationname": "State Airport",
    "lat": "432028N",
    "long": "0723110W"
  },
  {
    "stationcode": "GTY",
    "stationname": "Gettysburg Airport",
    "lat": "395040N",
    "long": "0771618W"
  },
  {
    "stationcode": "TTN",
    "stationname": "Mercer County Airport",
    "lat": "401637N",
    "long": "0744843W"
  },
  {
    "stationcode": "FRH",
    "stationname": "French Lick Municipal Airport",
    "lat": "383300N",
    "long": "0863700W"
  },
  {
    "stationcode": "SXY",
    "stationname": "Sidney Airport",
    "lat": "421900N",
    "long": "0752400W"
  },
  {
    "stationcode": "VNC",
    "stationname": "Venice Airport",
    "lat": "270400N",
    "long": "0822600W"
  },
  {
    "stationcode": "CLT",
    "stationname": "Douglas Airport",
    "lat": "351250N",
    "long": "0805635W"
  },
  {
    "stationcode": "RDG",
    "stationname": "Reading Municipal Airport/Spaatz Field",
    "lat": "402245N",
    "long": "0755800W"
  },
  {
    "stationcode": "BFP",
    "stationname": "Beaver Falls Airport",
    "lat": "404600N",
    "long": "0802400W"
  },
  {
    "stationcode": "BDL",
    "stationname": "Bradley International Airport",
    "lat": "415620N",
    "long": "0724100W"
  },
  {
    "stationcode": "FLU",
    "stationname": "Flushing Airport",
    "lat": "404632N",
    "long": "0735004W"
  },
  {
    "stationcode": "CRE",
    "stationname": "Grand Strand Airport",
    "lat": "334842N",
    "long": "0784326W"
  },
  {
    "stationcode": "MBS",
    "stationname": "Tri City Airport",
    "lat": "433155N",
    "long": "0840457W"
  },
  {
    "stationcode": "SIV",
    "stationname": "Sullivan County Airport",
    "lat": "390600N",
    "long": "0872400W"
  },
  {
    "stationcode": "LCQ",
    "stationname": "Lake City Airport",
    "lat": "301055N",
    "long": "0823436W"
  },
  {
    "stationcode": "SPG",
    "stationname": "Whitted Airport",
    "lat": "274555N",
    "long": "0823746W"
  },
  {
    "stationcode": "JFK",
    "stationname": "John F. Kennedy International Airport",
    "lat": "403823N",
    "long": "0734644W"
  },
  {
    "stationcode": "WVL",
    "stationname": "Robert Lafleur Airport",
    "lat": "443210N",
    "long": "0694025W"
  },
  {
    "stationcode": "GON",
    "stationname": "Groton-New London Airport",
    "lat": "411947N",
    "long": "0720238W"
  },
  {
    "stationcode": "TBR",
    "stationname": "Statesboro Municipal Airport",
    "lat": "322900N",
    "long": "0814500W"
  },
  {
    "stationcode": "TDZ",
    "stationname": "Toledo Airport",
    "lat": "413900N",
    "long": "0833200W"
  },
  {
    "stationcode": "TEB",
    "stationname": "Teterboro Airport",
    "lat": "405100N",
    "long": "0740339W"
  },
  {
    "stationcode": "TZC",
    "stationname": "Tuscola Area Airport",
    "lat": "432731N",
    "long": "0832643W"
  },
  {
    "stationcode": "GRD",
    "stationname": "Greenwood Airport",
    "lat": "341500N",
    "long": "0820900W"
  },
  {
    "stationcode": "GSO",
    "stationname": "Piedmont Triad International Airport",
    "lat": "360552N",
    "long": "0795614W"
  },
  {
    "stationcode": "GBR",
    "stationname": "Great Barrington Airport",
    "lat": "421200N",
    "long": "0732200W"
  },
  {
    "stationcode": "HVS",
    "stationname": "Hartsville Municipal Airport",
    "lat": "342400N",
    "long": "0800700W"
  },
  {
    "stationcode": "EWR",
    "stationname": "Newark Liberty International Airport",
    "lat": "404133N",
    "long": "0741007W"
  },
  {
    "stationcode": "FNT",
    "stationname": "Bishop Airport",
    "lat": "425800N",
    "long": "0834430W"
  },
  {
    "stationcode": "FPR",
    "stationname": "Saint Lucie County Airport",
    "lat": "272900N",
    "long": "0802200W"
  },
  {
    "stationcode": "BBX",
    "stationname": "Wings Field",
    "lat": "400815N",
    "long": "0751556W"
  },
  {
    "stationcode": "BKL",
    "stationname": "Burke Lakefront Airport",
    "lat": "413103N",
    "long": "0814100W"
  },
  {
    "stationcode": "CEU",
    "stationname": "Oconee County Airport",
    "lat": "344000N",
    "long": "0825300W"
  },
  {
    "stationcode": "PIM",
    "stationname": "Garden Harris County Airport",
    "lat": "325000N",
    "long": "0845100W"
  },
  {
    "stationcode": "MRK",
    "stationname": "Marco Island Airport",
    "lat": "255600N",
    "long": "0814300W"
  },
  {
    "stationcode": "CGE",
    "stationname": "Cambridge Airport",
    "lat": "383300N",
    "long": "0760200W"
  },
  {
    "stationcode": "OIC",
    "stationname": "Eaton Airport",
    "lat": "423200N",
    "long": "0753100W"
  },
  {
    "stationcode": "SWF",
    "stationname": "Stewart Airport",
    "lat": "413021N",
    "long": "0740550W"
  },
  {
    "stationcode": "HZL",
    "stationname": "Hazleton Airport",
    "lat": "405900N",
    "long": "0760000W"
  },
  {
    "stationcode": "USC",
    "stationname": "Union County Airport",
    "lat": "344200N",
    "long": "0813700W"
  },
  {
    "stationcode": "MXE",
    "stationname": "Maxton Airport",
    "lat": "344400N",
    "long": "0792100W"
  },
  {
    "stationcode": "LNP",
    "stationname": "Wise Airport",
    "lat": "365900N",
    "long": "0823400W"
  },
  {
    "stationcode": "BCT",
    "stationname": "Boca Raton Public Airport",
    "lat": "262244N",
    "long": "0800627W"
  },
  {
    "stationcode": "CAR",
    "stationname": "Caribou Municipal Airport",
    "lat": "465200N",
    "long": "0680100W"
  },
  {
    "stationcode": "PNE",
    "stationname": "North Philadelphia Airport",
    "lat": "400456N",
    "long": "0750047W"
  },
  {
    "stationcode": "PLN",
    "stationname": "Emmet County Airport",
    "lat": "453415N",
    "long": "0844748W"
  },
  {
    "stationcode": "DLL",
    "stationname": "Dillon Airport",
    "lat": "342500N",
    "long": "0792200W"
  },
  {
    "stationcode": "LEX",
    "stationname": "Blue Grass Airport",
    "lat": "380211N",
    "long": "0843621W"
  },
  {
    "stationcode": "CUB",
    "stationname": "Owens Field",
    "lat": "335900N",
    "long": "0810000W"
  },
  {
    "stationcode": "SVH",
    "stationname": "Statesville Municipal Airport",
    "lat": "354553N",
    "long": "0805711W"
  },
  {
    "stationcode": "RME",
    "stationname": "Griffiss International Airport",
    "lat": "431357N",
    "long": "0752421W"
  },
  {
    "stationcode": "CMX",
    "stationname": "Houghton County Airport",
    "lat": "471006N",
    "long": "0882921W"
  },
  {
    "stationcode": "FLO",
    "stationname": "Florence Airport",
    "lat": "341115N",
    "long": "0794329W"
  },
  {
    "stationcode": "MNZ",
    "stationname": "Manassas Airport",
    "lat": "384319N",
    "long": "0773052W"
  },
  {
    "stationcode": "ZZV",
    "stationname": "Zanesville Airport",
    "lat": "395700N",
    "long": "0815300W"
  },
  {
    "stationcode": "OGB",
    "stationname": "Orangeburg Municipal Airport",
    "lat": "332700N",
    "long": "0805100W"
  },
  {
    "stationcode": "FFA",
    "stationname": "First Flight Airport",
    "lat": "360100N",
    "long": "0754000W"
  },
  {
    "stationcode": "SER",
    "stationname": "Freeman Municipal Airport",
    "lat": "385700N",
    "long": "0855300W"
  },
  {
    "stationcode": "CLW",
    "stationname": "Executive Airport",
    "lat": "275410N",
    "long": "0824110W"
  },
  {
    "stationcode": "BFR",
    "stationname": "Virgil I Grissom Municipal Airport",
    "lat": "385100N",
    "long": "0862900W"
  },
  {
    "stationcode": "BTV",
    "stationname": "Burlington International Airport",
    "lat": "442819N",
    "long": "0730912W"
  },
  {
    "stationcode": "PNN",
    "stationname": "Princeton Airport",
    "lat": "451210N",
    "long": "0673400W"
  },
  {
    "stationcode": "DCR",
    "stationname": "Decatur Hi-Way Airport",
    "lat": "405000N",
    "long": "0845600W"
  },
  {
    "stationcode": "CKB",
    "stationname": "Benedum Airport",
    "lat": "391748N",
    "long": "0801341W"
  },
  {
    "stationcode": "ONH",
    "stationname": "Oneonta Municipal Airport",
    "lat": "422700N",
    "long": "0750700W"
  },
  {
    "stationcode": "JFN",
    "stationname": "Ashtabula Airport",
    "lat": "414400N",
    "long": "0804600W"
  },
  {
    "stationcode": "MPV",
    "stationname": "Edward F Knapp State Airport",
    "lat": "441159N",
    "long": "0723345W"
  },
  {
    "stationcode": "UIZ",
    "stationname": "Berz-Macomb Airport",
    "lat": "423700N",
    "long": "0830300W"
  },
  {
    "stationcode": "ILG",
    "stationname": "Greater Wilmington Airport",
    "lat": "394043N",
    "long": "0753624W"
  },
  {
    "stationcode": "SDF",
    "stationname": "Standiford Field",
    "lat": "381028N",
    "long": "0854410W"
  },
  {
    "stationcode": "SRW",
    "stationname": "Rowan County Airport",
    "lat": "353800N",
    "long": "0803100W"
  },
  {
    "stationcode": "BQK",
    "stationname": "Glynco Jetport",
    "lat": "311532N",
    "long": "0812759W"
  },
  {
    "stationcode": "BTN",
    "stationname": "Bennettsville Airport",
    "lat": "343700N",
    "long": "0794100W"
  },
  {
    "stationcode": "APF",
    "stationname": "Naples Airport",
    "lat": "260912N",
    "long": "0814631W"
  },
  {
    "stationcode": "FRR",
    "stationname": "Warren County Airport",
    "lat": "385505N",
    "long": "0781525W"
  },
  {
    "stationcode": "PTB",
    "stationname": "Petersburg Municipal Airport",
    "lat": "371100N",
    "long": "0773100W"
  },
  {
    "stationcode": "MLB",
    "stationname": "Melbourne International Airport",
    "lat": "280609N",
    "long": "0803749W"
  },
  {
    "stationcode": "MOR",
    "stationname": "Moore-Murrell Airport",
    "lat": "361100N",
    "long": "0832200W"
  },
  {
    "stationcode": "LIZ",
    "stationname": "Loring International Airport",
    "lat": "465500N",
    "long": "0675000W"
  },
  {
    "stationcode": "VQQ",
    "stationname": "Cecil Airport",
    "lat": "303000N",
    "long": "0814100W"
  },
  {
    "stationcode": "LUK",
    "stationname": "Cincinnati Municipal Airport",
    "lat": "390600N",
    "long": "0842500W"
  },
  {
    "stationcode": "TPA",
    "stationname": "Tampa International Airport",
    "lat": "275823N",
    "long": "0823207W"
  },
  {
    "stationcode": "TYS",
    "stationname": "Mc Ghee Tyson Airport",
    "lat": "354840N",
    "long": "0835939W"
  },
  {
    "stationcode": "EDE",
    "stationname": "Edenton Municipal Airport",
    "lat": "360400N",
    "long": "0763900W"
  },
  {
    "stationcode": "MXG",
    "stationname": "Marlborough Airport",
    "lat": "422000N",
    "long": "0713300W"
  },
  {
    "stationcode": "NWH",
    "stationname": "Parlin Field Airport",
    "lat": "432000N",
    "long": "0721000W"
  },
  {
    "stationcode": "FDK",
    "stationname": "Frederick Municipal Airport",
    "lat": "392500N",
    "long": "0772500W"
  },
  {
    "stationcode": "MFD",
    "stationname": "Lahm Municipal Airport",
    "lat": "404924N",
    "long": "0823102W"
  },
  {
    "stationcode": "MIA",
    "stationname": "Miami International Airport",
    "lat": "254736N",
    "long": "0801726W"
  },
  {
    "stationcode": "OIL",
    "stationname": "Splane Memorial Airport",
    "lat": "412600N",
    "long": "0794200W"
  },
  {
    "stationcode": "LVL",
    "stationname": "Lawrenceville Airport",
    "lat": "364500N",
    "long": "0775100W"
  },
  {
    "stationcode": "IRS",
    "stationname": "Kirsch Municipal",
    "lat": "414800N",
    "long": "0852500W"
  },
  {
    "stationcode": "LNS",
    "stationname": "Lancaster Airport",
    "lat": "400718N",
    "long": "0761746W"
  },
  {
    "stationcode": "AIK",
    "stationname": "Aiken Municipal Airport",
    "lat": "333858N",
    "long": "0814121W"
  },
  {
    "stationcode": "ATL",
    "stationname": "Hartsfield-Jackson Atlanta International Airport",
    "lat": "333812N",
    "long": "0842541W"
  },
  {
    "stationcode": "OSU",
    "stationname": "Ohio State University Airport",
    "lat": "400500N",
    "long": "0830400W"
  },
  {
    "stationcode": "BOW",
    "stationname": "Bartow Airport",
    "lat": "275600N",
    "long": "0814700W"
  },
  {
    "stationcode": "PWM",
    "stationname": "Portland International Jetport",
    "lat": "433846N",
    "long": "0701833W"
  },
  {
    "stationcode": "LOW",
    "stationname": "Louisa Airport",
    "lat": "380000N",
    "long": "0775800W"
  },
  {
    "stationcode": "SSU",
    "stationname": "Greenbrier Airport",
    "lat": "374800N",
    "long": "0801800W"
  },
  {
    "stationcode": "JAX",
    "stationname": "Jacksonville International Airport",
    "lat": "302933N",
    "long": "0814123W"
  },
  {
    "stationcode": "USA",
    "stationname": "Concord Regional Airport",
    "lat": "352316N",
    "long": "0804233W"
  },
  {
    "stationcode": "LDJ",
    "stationname": "Linden Airport",
    "lat": "403700N",
    "long": "0741500W"
  },
  {
    "stationcode": "MDT",
    "stationname": "Harrisburg International Airport",
    "lat": "401137N",
    "long": "0764548W"
  },
  {
    "stationcode": "HTS",
    "stationname": "Tri-State/Milton Airport",
    "lat": "382206N",
    "long": "0823333W"
  },
  {
    "stationcode": "GBO",
    "stationname": "Baltimore Greenbelt T Airport",
    "lat": "391110N",
    "long": "0764100W"
  },
  {
    "stationcode": "SCH",
    "stationname": "County Airport",
    "lat": "425115N",
    "long": "0735545W"
  },
  {
    "stationcode": "CHS",
    "stationname": "Charleston International Airport",
    "lat": "325357N",
    "long": "0800220W"
  },
  {
    "stationcode": "AID",
    "stationname": "Anderson Municipal Airport",
    "lat": "400600N",
    "long": "0854000W"
  },
  {
    "stationcode": "BNH",
    "stationname": "Barnes Airport",
    "lat": "422136N",
    "long": "0710140W"
  },
  {
    "stationcode": "PMH",
    "stationname": "Portsmouth Regional Airport",
    "lat": "385000N",
    "long": "0825100W"
  },
  {
    "stationcode": "YIP",
    "stationname": "Willow Run Airport",
    "lat": "421417N",
    "long": "0833149W"
  },
  {
    "stationcode": "SYV",
    "stationname": "Sylvester Airport",
    "lat": "313100N",
    "long": "0835000W"
  },
  {
    "stationcode": "DYL",
    "stationname": "Doylestown Airport",
    "lat": "401900N",
    "long": "0750800W"
  },
  {
    "stationcode": "MGY",
    "stationname": "Montgomery County Airport",
    "lat": "393518N",
    "long": "0841330W"
  },
  {
    "stationcode": "EKX",
    "stationname": "Elizabethtown Airport",
    "lat": "374200N",
    "long": "0855200W"
  },
  {
    "stationcode": "ELM",
    "stationname": "Elmira Regional Airport",
    "lat": "420936N",
    "long": "0765330W"
  },
  {
    "stationcode": "MKG",
    "stationname": "Muskegon Airport",
    "lat": "431010N",
    "long": "0861418W"
  },
  {
    "stationcode": "RKH",
    "stationname": "Rock Hill Airport",
    "lat": "345600N",
    "long": "0810100W"
  },
  {
    "stationcode": "BGM",
    "stationname": "Binghamton Airport",
    "lat": "421231N",
    "long": "0755847W"
  },
  {
    "stationcode": "SPA",
    "stationname": "Spartanburg Downtown Memorial Airport",
    "lat": "345700N",
    "long": "0815500W"
  },
  {
    "stationcode": "PHF",
    "stationname": "Newport News/Williamsburg Airport",
    "lat": "370751N",
    "long": "0762938W"
  },
  {
    "stationcode": "BTP",
    "stationname": "Graham Field",
    "lat": "404636N",
    "long": "0795704W"
  },
  {
    "stationcode": "APH",
    "stationname": "Camp A P Hill Airport",
    "lat": "380300N",
    "long": "0772100W"
  },
  {
    "stationcode": "OEA",
    "stationname": "Oneal Airport",
    "lat": "384100N",
    "long": "0873200W"
  },
  {
    "stationcode": "HKY",
    "stationname": "Hickory Airport",
    "lat": "354400N",
    "long": "0812300W"
  },
  {
    "stationcode": "RBW",
    "stationname": "Walterboro Municipal Airport",
    "lat": "325400N",
    "long": "0804000W"
  },
  {
    "stationcode": "MGW",
    "stationname": "Morgantown Airport",
    "lat": "393828N",
    "long": "0795503W"
  },
  {
    "stationcode": "SEG",
    "stationname": "Penn Valley Airport",
    "lat": "404916N",
    "long": "0765151W"
  },
  {
    "stationcode": "CTH",
    "stationname": "Chestercounty Carlson Airport",
    "lat": "395800N",
    "long": "0754900W"
  },
  {
    "stationcode": "QNY",
    "stationname": "Marine Air Terminal",
    "lat": "404500N",
    "long": "0740000W"
  },
  {
    "stationcode": "BFT",
    "stationname": "County Airport",
    "lat": "322442N",
    "long": "0803809W"
  },
  {
    "stationcode": "PBI",
    "stationname": "West Palm Beach International Airport",
    "lat": "264059N",
    "long": "0800544W"
  },
  {
    "stationcode": "NPT",
    "stationname": "State Airport",
    "lat": "413603N",
    "long": "0712456W"
  },
  {
    "stationcode": "OCW",
    "stationname": "Warren Field",
    "lat": "353300N",
    "long": "0770300W"
  },
  {
    "stationcode": "PSM",
    "stationname": "Portsmouth Pease International",
    "lat": "430441N",
    "long": "0704924W"
  },
  {
    "stationcode": "LKP",
    "stationname": "Lake Placid Airport",
    "lat": "441700N",
    "long": "0735900W"
  },
  {
    "stationcode": "AAF",
    "stationname": "Apalachicola Municipal Airport",
    "lat": "294400N",
    "long": "0850200W"
  },
  {
    "stationcode": "BLF",
    "stationname": "Mercer County Airport",
    "lat": "371800N",
    "long": "0811200W"
  },
  {
    "stationcode": "IMM",
    "stationname": "Immokalee Airport",
    "lat": "262500N",
    "long": "0812500W"
  },
  {
    "stationcode": "DUF",
    "stationname": "Pine Island Airport",
    "lat": "361530N",
    "long": "0754720W"
  },
  {
    "stationcode": "ECG",
    "stationname": "Elizabeth City Airport",
    "lat": "361600N",
    "long": "0761100W"
  },
  {
    "stationcode": "RKD",
    "stationname": "Knox County Regional Airport",
    "lat": "440334N",
    "long": "0690559W"
  },
  {
    "stationcode": "PQI",
    "stationname": "Presque Isle Municipal Airport",
    "lat": "464120N",
    "long": "0680241W"
  },
  {
    "stationcode": "MOP",
    "stationname": "Mount Pleasant Municipal Airport",
    "lat": "433700N",
    "long": "0844400W"
  },
  {
    "stationcode": "TVC",
    "stationname": "Traverse City Airport",
    "lat": "444429N",
    "long": "0853456W"
  },
  {
    "stationcode": "IDI",
    "stationname": "Indiana Airport",
    "lat": "403752N",
    "long": "0790607W"
  },
  {
    "stationcode": "LAF",
    "stationname": "Purdue University Airport",
    "lat": "402500N",
    "long": "0865600W"
  },
  {
    "stationcode": "TLH",
    "stationname": "Tallahassee Municipal Airport",
    "lat": "302330N",
    "long": "0842059W"
  },
  {
    "stationcode": "MEJ",
    "stationname": "Meadville Airport",
    "lat": "413710N",
    "long": "0801200W"
  },
  {
    "stationcode": "CTZ",
    "stationname": "Sampson County Airport",
    "lat": "350000N",
    "long": "0782000W"
  },
  {
    "stationcode": "EFK",
    "stationname": "Newport Airport",
    "lat": "445330N",
    "long": "0721346W"
  },
  {
    "stationcode": "ERR",
    "stationname": "Errol Airport",
    "lat": "444733N",
    "long": "0710953W"
  },
  {
    "stationcode": "PGD",
    "stationname": "Charlotte County Airport",
    "lat": "265504N",
    "long": "0815943W"
  },
  {
    "stationcode": "ACB",
    "stationname": "Antrim County Airport",
    "lat": "445900N",
    "long": "0851210W"
  },
  {
    "stationcode": "NCO",
    "stationname": "Qounset State Airport",
    "lat": "413550N",
    "long": "0712444W"
  },
  {
    "stationcode": "TOC",
    "stationname": "Toccoa Airport",
    "lat": "343532N",
    "long": "0831752W"
  },
  {
    "stationcode": "PVZ",
    "stationname": "Casement Airport",
    "lat": "414300N",
    "long": "0811500W"
  },
  {
    "stationcode": "MFV",
    "stationname": "Accomack County Airport",
    "lat": "373900N",
    "long": "0754500W"
  },
  {
    "stationcode": "MIV",
    "stationname": "Millville Airport",
    "lat": "392200N",
    "long": "0750500W"
  },
  {
    "stationcode": "BMG",
    "stationname": "Bloomington Airport",
    "lat": "390833N",
    "long": "0863655W"
  },
  {
    "stationcode": "WBK",
    "stationname": "West Branch Community Airport",
    "lat": "441441N",
    "long": "0841047W"
  },
  {
    "stationcode": "PHK",
    "stationname": "Palm Beach County Glades Airport",
    "lat": "264900N",
    "long": "0803900W"
  },
  {
    "stationcode": "NZW",
    "stationname": "South Weymouth Airport",
    "lat": "421000N",
    "long": "0705600W"
  },
  {
    "stationcode": "LDM",
    "stationname": "Ludington Airport",
    "lat": "435700N",
    "long": "0862700W"
  },
  {
    "stationcode": "MMN",
    "stationname": "Minute Man Airfield",
    "lat": "422600N",
    "long": "0713010W"
  },
  {
    "stationcode": "GVE",
    "stationname": "Gordonsville Municipal Airport",
    "lat": "380900N",
    "long": "0781000W"
  },
  {
    "stationcode": "DAY",
    "stationname": "James Cox Dayton International Airport",
    "lat": "395409N",
    "long": "0841310W"
  },
  {
    "stationcode": "BGR",
    "stationname": "Bangor International Airport",
    "lat": "444827N",
    "long": "0684941W"
  },
  {
    "stationcode": "CEF",
    "stationname": "Westover Metro Airport",
    "lat": "421120N",
    "long": "0723216W"
  },
  {
    "stationcode": "FAY",
    "stationname": "Fayetteville Municipal Airport",
    "lat": "345924N",
    "long": "0785259W"
  },
  {
    "stationcode": "CRG",
    "stationname": "Craig Municipal Airport",
    "lat": "302002N",
    "long": "0813048W"
  },
  {
    "stationcode": "RBN",
    "stationname": "Fort Jefferson Airport",
    "lat": "243700N",
    "long": "0825200W"
  },
  {
    "stationcode": "GFL",
    "stationname": "Warren County Airport",
    "lat": "432020N",
    "long": "0733632W"
  },
  {
    "stationcode": "HTW",
    "stationname": "Huntington County Airport",
    "lat": "382500N",
    "long": "0822910W"
  },
  {
    "stationcode": "SBN",
    "stationname": "South Bend Regional",
    "lat": "414217N",
    "long": "0861847W"
  },
  {
    "stationcode": "OLD",
    "stationname": "Old Town Airport",
    "lat": "445700N",
    "long": "0684000W"
  },
  {
    "stationcode": "MUL",
    "stationname": "Spence Airport",
    "lat": "310400N",
    "long": "0834800W"
  },
  {
    "stationcode": "JBR",
    "stationname": "Jonesboro Airport",
    "lat": "354959N",
    "long": "0903858W"
  },
  {
    "stationcode": "PHP",
    "stationname": "Phillip Airport",
    "lat": "440200N",
    "long": "1013900W"
  },
  {
    "stationcode": "ISN",
    "stationname": "Sloulin Field International Airport",
    "lat": "481045N",
    "long": "1033836W"
  },
  {
    "stationcode": "EGV",
    "stationname": "Eagle River Airport",
    "lat": "455600N",
    "long": "0891600W"
  },
  {
    "stationcode": "DRT",
    "stationname": "Del Rio International Airport",
    "lat": "292224N",
    "long": "1005535W"
  },
  {
    "stationcode": "FRM",
    "stationname": "Fairmont Airport",
    "lat": "433900N",
    "long": "0942500W"
  },
  {
    "stationcode": "UTM",
    "stationname": "Tunica Municipal Airport",
    "lat": "344051N",
    "long": "0902047W"
  },
  {
    "stationcode": "TCL",
    "stationname": "Van De Graaf Airport",
    "lat": "331309N",
    "long": "0873636W"
  },
  {
    "stationcode": "BXA",
    "stationname": "George R. Carr Airport",
    "lat": "304700N",
    "long": "0895200W"
  },
  {
    "stationcode": "AHD",
    "stationname": "Ardmore Downtown Airport",
    "lat": "341000N",
    "long": "0970800W"
  },
  {
    "stationcode": "HDE",
    "stationname": "Brewster Field",
    "lat": "402600N",
    "long": "0992200W"
  },
  {
    "stationcode": "MIC",
    "stationname": "Crystal Airport",
    "lat": "450310N",
    "long": "0932100W"
  },
  {
    "stationcode": "PMB",
    "stationname": "Pembina Intermediate Airport",
    "lat": "485700N",
    "long": "0971400W"
  },
  {
    "stationcode": "HUJ",
    "stationname": "Hugo Airport",
    "lat": "340100N",
    "long": "0953100W"
  },
  {
    "stationcode": "SYI",
    "stationname": "Bomar Field",
    "lat": "353400N",
    "long": "0862700W"
  },
  {
    "stationcode": "CIN",
    "stationname": "Carroll Airport",
    "lat": "420300N",
    "long": "0944700W"
  },
  {
    "stationcode": "RLA",
    "stationname": "National Airport",
    "lat": "380740N",
    "long": "0914641W"
  },
  {
    "stationcode": "OWB",
    "stationname": "Daviess County Airport",
    "lat": "374424N",
    "long": "0871001W"
  },
  {
    "stationcode": "TOP",
    "stationname": "Philip Billard Airport",
    "lat": "390400N",
    "long": "0953700W"
  },
  {
    "stationcode": "HBG",
    "stationname": "Bobby L. Chain Municipal Airport",
    "lat": "311600N",
    "long": "0891500W"
  },
  {
    "stationcode": "BMI",
    "stationname": "Bloomington-Normal Airport",
    "lat": "402838N",
    "long": "0885457W"
  },
  {
    "stationcode": "SQI",
    "stationname": "Whiteside County Airport",
    "lat": "414425N",
    "long": "0894052W"
  },
  {
    "stationcode": "WWR",
    "stationname": "West Woodward Airport",
    "lat": "362600N",
    "long": "0993100W"
  },
  {
    "stationcode": "OJC",
    "stationname": "Johnson Executive Airport",
    "lat": "385100N",
    "long": "0944400W"
  },
  {
    "stationcode": "RCK",
    "stationname": "Coffield Airport",
    "lat": "303900N",
    "long": "0970000W"
  },
  {
    "stationcode": "MRC",
    "stationname": "Maury County Airport",
    "lat": "353600N",
    "long": "0870200W"
  },
  {
    "stationcode": "RFK",
    "stationname": "Rollang Field",
    "lat": "325800N",
    "long": "0905000W"
  },
  {
    "stationcode": "PKF",
    "stationname": "Park Falls Airport",
    "lat": "455600N",
    "long": "0902600W"
  },
  {
    "stationcode": "CGI",
    "stationname": "Cape Girardeau Airport",
    "lat": "371331N",
    "long": "0893415W"
  },
  {
    "stationcode": "MCB",
    "stationname": "Pike County Airport",
    "lat": "311043N",
    "long": "0902820W"
  },
  {
    "stationcode": "SAT",
    "stationname": "San Antonio International Airport",
    "lat": "293201N",
    "long": "0982811W"
  },
  {
    "stationcode": "STP",
    "stationname": "St. Paul Downtown Airport",
    "lat": "445600N",
    "long": "0930400W"
  },
  {
    "stationcode": "IAH",
    "stationname": "George Bush Intercontinental Airport",
    "lat": "295904N",
    "long": "0952029W"
  },
  {
    "stationcode": "SYN",
    "stationname": "Carleton Airport",
    "lat": "442805N",
    "long": "0930000W"
  },
  {
    "stationcode": "MWA",
    "stationname": "Williamson County Airport",
    "lat": "374505N",
    "long": "0890100W"
  },
  {
    "stationcode": "CNW",
    "stationname": "James Connall Airport",
    "lat": "313800N",
    "long": "0970400W"
  },
  {
    "stationcode": "BNA",
    "stationname": "Nashville Metropolitan Airport",
    "lat": "360728N",
    "long": "0864042W"
  },
  {
    "stationcode": "BNW",
    "stationname": "Boone Airport",
    "lat": "420400N",
    "long": "0935300W"
  },
  {
    "stationcode": "OWA",
    "stationname": "Owatonna Airport",
    "lat": "440700N",
    "long": "0931500W"
  },
  {
    "stationcode": "MVC",
    "stationname": "Monroe County Airport",
    "lat": "313100N",
    "long": "0871900W"
  },
  {
    "stationcode": "VDU",
    "stationname": "Tom O'Connor Oil Field Airport",
    "lat": "281800N",
    "long": "0971700W"
  },
  {
    "stationcode": "PSX",
    "stationname": "Palacios Airport",
    "lat": "284200N",
    "long": "0961300W"
  },
  {
    "stationcode": "NRI",
    "stationname": "Shangri-la Airport",
    "lat": "363400N",
    "long": "0945100W"
  },
  {
    "stationcode": "DWH",
    "stationname": "David Wayne Hooks Airport",
    "lat": "300340N",
    "long": "0953316W"
  },
  {
    "stationcode": "GPZ",
    "stationname": "Grand Rapids Airport",
    "lat": "471231N",
    "long": "0933040W"
  },
  {
    "stationcode": "HRO",
    "stationname": "Boone County Airport",
    "lat": "361541N",
    "long": "0930917W"
  },
  {
    "stationcode": "TXK",
    "stationname": "Texarkana Municipal Airport",
    "lat": "332723N",
    "long": "0935921W"
  },
  {
    "stationcode": "ENW",
    "stationname": "Kenosha Municipal Airport",
    "lat": "423545N",
    "long": "0875540W"
  },
  {
    "stationcode": "SLG",
    "stationname": "Smith Field",
    "lat": "361100N",
    "long": "0943200W"
  },
  {
    "stationcode": "SUD",
    "stationname": "Stroud Airport",
    "lat": "354400N",
    "long": "0963900W"
  },
  {
    "stationcode": "DFW",
    "stationname": "Dallas/Ft. Worth International Airport",
    "lat": "325349N",
    "long": "0970217W"
  },
  {
    "stationcode": "RAC",
    "stationname": "Horlick Airport",
    "lat": "424528N",
    "long": "0874915W"
  },
  {
    "stationcode": "OTM",
    "stationname": "Industrial Airport",
    "lat": "410600N",
    "long": "0922700W"
  },
  {
    "stationcode": "CNK",
    "stationname": "Blosser Municipal Airport",
    "lat": "393300N",
    "long": "0973900W"
  },
  {
    "stationcode": "AGO",
    "stationname": "Magnolia Municipal Airport",
    "lat": "331400N",
    "long": "0931300W"
  },
  {
    "stationcode": "ASL",
    "stationname": "Harrison County Airport",
    "lat": "323300N",
    "long": "0942200W"
  },
  {
    "stationcode": "AUZ",
    "stationname": "Aurora Municipal Airport",
    "lat": "414600N",
    "long": "0881900W"
  },
  {
    "stationcode": "FBL",
    "stationname": "Faribault Municipal Airport",
    "lat": "442000N",
    "long": "0931900W"
  },
  {
    "stationcode": "CGX",
    "stationname": "Merrill C Meigs Field",
    "lat": "415132N",
    "long": "0873630W"
  },
  {
    "stationcode": "BJI",
    "stationname": "Bemidji Airport",
    "lat": "473035N",
    "long": "0945559W"
  },
  {
    "stationcode": "SWO",
    "stationname": "Searcy Field",
    "lat": "360931N",
    "long": "0970513W"
  },
  {
    "stationcode": "INK",
    "stationname": "Wink Airport",
    "lat": "314700N",
    "long": "1031200W"
  },
  {
    "stationcode": "EUF",
    "stationname": "Weedon Field",
    "lat": "315700N",
    "long": "0850800W"
  },
  {
    "stationcode": "ARV",
    "stationname": "Noble F. Lee Airport",
    "lat": "455500N",
    "long": "0894400W"
  },
  {
    "stationcode": "MSL",
    "stationname": "Muscle Shoals Airport",
    "lat": "344447N",
    "long": "0873636W"
  },
  {
    "stationcode": "MCI",
    "stationname": "Kansas City International Airport",
    "lat": "391751N",
    "long": "0944250W"
  },
  {
    "stationcode": "MCW",
    "stationname": "Mason City Airport",
    "lat": "430921N",
    "long": "0932009W"
  },
  {
    "stationcode": "MLU",
    "stationname": "Monroe Municipal Airport",
    "lat": "323040N",
    "long": "0920220W"
  },
  {
    "stationcode": "DPA",
    "stationname": "Dupage County Airport",
    "lat": "415425N",
    "long": "0881515W"
  },
  {
    "stationcode": "DRI",
    "stationname": "Beauregard Parish Airport",
    "lat": "304956N",
    "long": "0932028W"
  },
  {
    "stationcode": "NQA",
    "stationname": "Millington Regional Jetport",
    "lat": "352125N",
    "long": "0895214W"
  },
  {
    "stationcode": "RST",
    "stationname": "Rochester Municipal Airport",
    "lat": "435437N",
    "long": "0922948W"
  },
  {
    "stationcode": "UOX",
    "stationname": "University-Oxford Airport",
    "lat": "342300N",
    "long": "0893200W"
  },
  {
    "stationcode": "HAB",
    "stationname": "Marion County Airport",
    "lat": "340700N",
    "long": "0880000W"
  },
  {
    "stationcode": "UIN",
    "stationname": "Quincy Municipal Airport",
    "lat": "395627N",
    "long": "0911140W"
  },
  {
    "stationcode": "MXA",
    "stationname": "Manila Municipal Airport",
    "lat": "355300N",
    "long": "0900900W"
  },
  {
    "stationcode": "CHK",
    "stationname": "Chickasha Municipal Airport",
    "lat": "350200N",
    "long": "0975800W"
  },
  {
    "stationcode": "DHT",
    "stationname": "Dalhart Airport",
    "lat": "360300N",
    "long": "1023000W"
  },
  {
    "stationcode": "DNV",
    "stationname": "Vermilion County Airport",
    "lat": "401200N",
    "long": "0873500W"
  },
  {
    "stationcode": "PTS",
    "stationname": "Pittsburg Municipal Airport",
    "lat": "372700N",
    "long": "0944400W"
  },
  {
    "stationcode": "OKM",
    "stationname": "Okmulgee Airport",
    "lat": "353700N",
    "long": "0955800W"
  },
  {
    "stationcode": "ALI",
    "stationname": "Alice International Airport",
    "lat": "274421N",
    "long": "0980137W"
  },
  {
    "stationcode": "FLP",
    "stationname": "Flippin Airport",
    "lat": "361800N",
    "long": "0923500W"
  },
  {
    "stationcode": "HYS",
    "stationname": "Hays Municipal Airport",
    "lat": "385101N",
    "long": "0991632W"
  },
  {
    "stationcode": "JCY",
    "stationname": "Johnson Airport",
    "lat": "322000N",
    "long": "0972000W"
  },
  {
    "stationcode": "JEF",
    "stationname": "Jefferson City Memorial Airport",
    "lat": "383600N",
    "long": "0920900W"
  },
  {
    "stationcode": "HEZ",
    "stationname": "Hardy-Anders Airport",
    "lat": "313700N",
    "long": "0911800W"
  },
  {
    "stationcode": "ILL",
    "stationname": "Willmar Airport",
    "lat": "450700N",
    "long": "0950600W"
  },
  {
    "stationcode": "DUC",
    "stationname": "Halliburton Airport",
    "lat": "342800N",
    "long": "0975800W"
  },
  {
    "stationcode": "SEP",
    "stationname": "Clark Field",
    "lat": "321300N",
    "long": "0981100W"
  },
  {
    "stationcode": "CLK",
    "stationname": "Clinton Municipal Airport",
    "lat": "353218N",
    "long": "0985600W"
  },
  {
    "stationcode": "ENL",
    "stationname": "Centralia Municipal Airport",
    "lat": "383100N",
    "long": "0890530W"
  },
  {
    "stationcode": "OLU",
    "stationname": "Columbus Airport",
    "lat": "412700N",
    "long": "0972000W"
  },
  {
    "stationcode": "PBF",
    "stationname": "Grider Field",
    "lat": "341100N",
    "long": "0915600W"
  },
  {
    "stationcode": "ASY",
    "stationname": "Ashley Airport",
    "lat": "460200N",
    "long": "0992200W"
  },
  {
    "stationcode": "MIO",
    "stationname": "Miami Airport",
    "lat": "365200N",
    "long": "0945200W"
  },
  {
    "stationcode": "MIQ",
    "stationname": "Millard Airport",
    "lat": "411146N",
    "long": "0960644W"
  },
  {
    "stationcode": "SPX",
    "stationname": "Spaceland Airport",
    "lat": "300617N",
    "long": "0305349E"
  },
  {
    "stationcode": "SGF",
    "stationname": "Springfield-Branson Regional Airport",
    "lat": "371424N",
    "long": "0932306W"
  },
  {
    "stationcode": "PIB",
    "stationname": "Hattiesburg-Laurel Regional Airport",
    "lat": "312802N",
    "long": "0892013W"
  },
  {
    "stationcode": "ERV",
    "stationname": "Kerrville Airport",
    "lat": "295900N",
    "long": "0990500W"
  },
  {
    "stationcode": "UCY",
    "stationname": "Everett-Stewart Airport",
    "lat": "362500N",
    "long": "0890300W"
  },
  {
    "stationcode": "TGE",
    "stationname": "Sharpe Field",
    "lat": "322800N",
    "long": "0854100W"
  },
  {
    "stationcode": "AIV",
    "stationname": "George Downer Airport",
    "lat": "330600N",
    "long": "0881200W"
  },
  {
    "stationcode": "BVO",
    "stationname": "Bartlesville Airport",
    "lat": "364600N",
    "long": "0960000W"
  },
  {
    "stationcode": "NGW",
    "stationname": "Cabaniss Field",
    "lat": "274620N",
    "long": "0973000W"
  },
  {
    "stationcode": "MLC",
    "stationname": "Mc Alester Airport",
    "lat": "345300N",
    "long": "0954700W"
  },
  {
    "stationcode": "IDP",
    "stationname": "Independence Airport",
    "lat": "371300N",
    "long": "0954200W"
  },
  {
    "stationcode": "PCU",
    "stationname": "Pearl River County Airport",
    "lat": "303100N",
    "long": "0894220W"
  },
  {
    "stationcode": "ESF",
    "stationname": "Esler Field",
    "lat": "312400N",
    "long": "0921800W"
  },
  {
    "stationcode": "ETB",
    "stationname": "West Bend Airport",
    "lat": "432500N",
    "long": "0881100W"
  },
  {
    "stationcode": "EBR",
    "stationname": "Baton Rouge Downtown Airport",
    "lat": "302700N",
    "long": "0911100W"
  },
  {
    "stationcode": "EPG",
    "stationname": "Browns Airport",
    "lat": "405200N",
    "long": "0960800W"
  },
  {
    "stationcode": "CZT",
    "stationname": "Carrizo Springs Airport",
    "lat": "283100N",
    "long": "0995200W"
  },
  {
    "stationcode": "OUN",
    "stationname": "Max Westheimer Airport",
    "lat": "350930N",
    "long": "0972800W"
  },
  {
    "stationcode": "BBB",
    "stationname": "Benson Municipal Airport",
    "lat": "451958N",
    "long": "0953905W"
  },
  {
    "stationcode": "FSN",
    "stationname": "Haley AAF",
    "lat": "420900N",
    "long": "0875800W"
  },
  {
    "stationcode": "NSL",
    "stationname": "Municipal Airport",
    "lat": "435912N",
    "long": "0954657W"
  },
  {
    "stationcode": "STE",
    "stationname": "Stevens Point Airport",
    "lat": "443300N",
    "long": "0893200W"
  },
  {
    "stationcode": "VRS",
    "stationname": "Versailles Airport",
    "lat": "382605N",
    "long": "0924900W"
  },
  {
    "stationcode": "WDG",
    "stationname": "Enid Woodring Municipal Airport",
    "lat": "362248N",
    "long": "0974728W"
  },
  {
    "stationcode": "MGI",
    "stationname": "National Wildlife Refuge Airport",
    "lat": "284100N",
    "long": "0955800W"
  },
  {
    "stationcode": "GLH",
    "stationname": "Greenville Airport",
    "lat": "332838N",
    "long": "0905906W"
  },
  {
    "stationcode": "GUF",
    "stationname": "Edwards Airport",
    "lat": "301000N",
    "long": "0873500W"
  },
  {
    "stationcode": "SEM",
    "stationname": "Craig Field Airport",
    "lat": "322100N",
    "long": "0865900W"
  },
  {
    "stationcode": "CCY",
    "stationname": "Charles City Municipal Airport",
    "lat": "430400N",
    "long": "0924000W"
  },
  {
    "stationcode": "OSB",
    "stationname": "Osage Beach Airport",
    "lat": "380900N",
    "long": "0923700W"
  },
  {
    "stationcode": "FMS",
    "stationname": "Fort Madison Municipal Airport",
    "lat": "403900N",
    "long": "0911900W"
  },
  {
    "stationcode": "LJN",
    "stationname": "Brazoria County Airport",
    "lat": "290200N",
    "long": "0952700W"
  },
  {
    "stationcode": "RRL",
    "stationname": "Merrill Municipal Airport",
    "lat": "451200N",
    "long": "0894200W"
  },
  {
    "stationcode": "OOA",
    "stationname": "Oskaloosa Municipal Airport",
    "lat": "411700N",
    "long": "0923800W"
  },
  {
    "stationcode": "MGM",
    "stationname": "Dannelly Field",
    "lat": "321800N",
    "long": "0862328W"
  },
  {
    "stationcode": "GWO",
    "stationname": "Leflore Airport",
    "lat": "333000N",
    "long": "0901200W"
  },
  {
    "stationcode": "DTL",
    "stationname": "Detroit Lakes Municipal Airport",
    "lat": "465000N",
    "long": "0955300W"
  },
  {
    "stationcode": "DUA",
    "stationname": "Eaker Airport",
    "lat": "335900N",
    "long": "0962200W"
  },
  {
    "stationcode": "EOK",
    "stationname": "Keokuk Airport",
    "lat": "402800N",
    "long": "0912600W"
  },
  {
    "stationcode": "GAD",
    "stationname": "Gadsden Municipal Airport",
    "lat": "335800N",
    "long": "0860500W"
  },
  {
    "stationcode": "XNA",
    "stationname": "Northwest Arkansas Regional Airport",
    "lat": "361651N",
    "long": "0941817W"
  },
  {
    "stationcode": "RNZ",
    "stationname": "Rensselaer Airport",
    "lat": "405700N",
    "long": "0870900W"
  },
  {
    "stationcode": "ASX",
    "stationname": "Ashland Airport",
    "lat": "463300N",
    "long": "0905500W"
  },
  {
    "stationcode": "BBC",
    "stationname": "Bay City Airport",
    "lat": "285900N",
    "long": "0955800W"
  },
  {
    "stationcode": "MSN",
    "stationname": "Dane County Regional Airport",
    "lat": "430823N",
    "long": "0892015W"
  },
  {
    "stationcode": "LIT",
    "stationname": "Little Rock Regional Airport",
    "lat": "344346N",
    "long": "0921328W"
  },
  {
    "stationcode": "IFA",
    "stationname": "Iowa Falls Airport",
    "lat": "423100N",
    "long": "0931600W"
  },
  {
    "stationcode": "LBF",
    "stationname": "Lee Bird Field",
    "lat": "410752N",
    "long": "1004135W"
  },
  {
    "stationcode": "ONL",
    "stationname": "O'Neill Municipal Airport",
    "lat": "422809N",
    "long": "0984113W"
  },
  {
    "stationcode": "POH",
    "stationname": "Pocahontas Municipal Airport",
    "lat": "424500N",
    "long": "0943900W"
  },
  {
    "stationcode": "JCT",
    "stationname": "Kimble County Airport",
    "lat": "302900N",
    "long": "0994600W"
  },
  {
    "stationcode": "EGP",
    "stationname": "Maverick County Airport",
    "lat": "285200N",
    "long": "1003200W"
  },
  {
    "stationcode": "SUS",
    "stationname": "Spirit of St. Louis Airport",
    "lat": "383942N",
    "long": "0903906W"
  },
  {
    "stationcode": "DMO",
    "stationname": "Sedalia Airport",
    "lat": "384233N",
    "long": "0931022W"
  },
  {
    "stationcode": "RKP",
    "stationname": "Aransas County Airport",
    "lat": "280500N",
    "long": "0970200W"
  },
  {
    "stationcode": "BWD",
    "stationname": "Brownwood Airport",
    "lat": "314800N",
    "long": "0985700W"
  },
  {
    "stationcode": "BWG",
    "stationname": "Warren County Airport",
    "lat": "365803N",
    "long": "0862509W"
  },
  {
    "stationcode": "FAM",
    "stationname": "Farmington Regional Airport",
    "lat": "374540N",
    "long": "0902543W"
  },
  {
    "stationcode": "DCU",
    "stationname": "Pyor Airport",
    "lat": "343900N",
    "long": "0865700W"
  },
  {
    "stationcode": "LYO",
    "stationname": "Rice County Municipal Airport",
    "lat": "382000N",
    "long": "0981300W"
  },
  {
    "stationcode": "LAW",
    "stationname": "Lawton Municipal Airport",
    "lat": "343400N",
    "long": "0982459W"
  },
  {
    "stationcode": "HPY",
    "stationname": "Baytown Airport",
    "lat": "294400N",
    "long": "0945800W"
  },
  {
    "stationcode": "GUY",
    "stationname": "Guymon Airport",
    "lat": "364100N",
    "long": "1012900W"
  },
  {
    "stationcode": "HUD",
    "stationname": "Humboldt Airport",
    "lat": "424400N",
    "long": "0941300W"
  },
  {
    "stationcode": "EAU",
    "stationname": "Eau Claire Airport",
    "lat": "445152N",
    "long": "0912914W"
  },
  {
    "stationcode": "FFL",
    "stationname": "Fairfield Municipal Airport",
    "lat": "405600N",
    "long": "0915700W"
  },
  {
    "stationcode": "SLB",
    "stationname": "Storm Lake Municipal Airport",
    "lat": "423600N",
    "long": "0951400W"
  },
  {
    "stationcode": "VOK",
    "stationname": "Volk Field",
    "lat": "435621N",
    "long": "0901545W"
  },
  {
    "stationcode": "ATW",
    "stationname": "Outagamie County Airport",
    "lat": "441600N",
    "long": "0883100W"
  },
  {
    "stationcode": "OTG",
    "stationname": "Worthington Airport",
    "lat": "433900N",
    "long": "0953500W"
  },
  {
    "stationcode": "CFD",
    "stationname": "Coulter Field",
    "lat": "304100N",
    "long": "0962400W"
  },
  {
    "stationcode": "PAH",
    "stationname": "Barkley Regional Airport",
    "lat": "370337N",
    "long": "0884623W"
  },
  {
    "stationcode": "PRW",
    "stationname": "Prentice Airport",
    "lat": "453300N",
    "long": "0901700W"
  },
  {
    "stationcode": "WMH",
    "stationname": "Mountain Home Airport",
    "lat": "362200N",
    "long": "0922800W"
  },
  {
    "stationcode": "HOT",
    "stationname": "Memorial Field",
    "lat": "342841N",
    "long": "0930546W"
  },
  {
    "stationcode": "MQB",
    "stationname": "Macomb Municipal Airport",
    "lat": "402700N",
    "long": "0903700W"
  },
  {
    "stationcode": "PCD",
    "stationname": "Prairie Du Chien Municipal Airport",
    "lat": "430100N",
    "long": "0910700W"
  },
  {
    "stationcode": "MIF",
    "stationname": "Roy Hurd Memorial Airport",
    "lat": "313600N",
    "long": "1025400W"
  },
  {
    "stationcode": "BIE",
    "stationname": "Beatrice Airport",
    "lat": "401900N",
    "long": "0964500W"
  },
  {
    "stationcode": "AFW",
    "stationname": "Fort Worth Alliance Airport",
    "lat": "325900N",
    "long": "0971900W"
  },
  {
    "stationcode": "CKV",
    "stationname": "Outlaw Field",
    "lat": "363700N",
    "long": "0872500W"
  },
  {
    "stationcode": "IDG",
    "stationname": "Ida Grove Municipal Airport",
    "lat": "422100N",
    "long": "0952800W"
  },
  {
    "stationcode": "GGG",
    "stationname": "Gregg County Airport",
    "lat": "322312N",
    "long": "0944255W"
  },
  {
    "stationcode": "GOK",
    "stationname": "Guthrie Airport",
    "lat": "355300N",
    "long": "0972500W"
  },
  {
    "stationcode": "TYR",
    "stationname": "Pounds Field",
    "lat": "322115N",
    "long": "0952409W"
  },
  {
    "stationcode": "IMT",
    "stationname": "Ford Airport",
    "lat": "454900N",
    "long": "0880700W"
  },
  {
    "stationcode": "SLO",
    "stationname": "Leckrone Airport",
    "lat": "383700N",
    "long": "0885600W"
  },
  {
    "stationcode": "CSM",
    "stationname": "Sherman Airport",
    "lat": "352024N",
    "long": "0991153W"
  },
  {
    "stationcode": "DHN",
    "stationname": "Dothan Airport",
    "lat": "311917N",
    "long": "0852659W"
  },
  {
    "stationcode": "BFM",
    "stationname": "Mobile Aerospace Field",
    "lat": "303800N",
    "long": "0880400W"
  },
  {
    "stationcode": "ALN",
    "stationname": "Alton Airport",
    "lat": "385330N",
    "long": "0901020W"
  },
  {
    "stationcode": "AAP",
    "stationname": "Andrau Airpark",
    "lat": "002223S",
    "long": "1171503E"
  },
  {
    "stationcode": "HPT",
    "stationname": "Hampton Municipal Airport",
    "lat": "424500N",
    "long": "0931200W"
  },
  {
    "stationcode": "ILE",
    "stationname": "Killeen Municipal Airport",
    "lat": "310506N",
    "long": "0974112W"
  },
  {
    "stationcode": "EFW",
    "stationname": "Jefferson Municipal Airport",
    "lat": "420100N",
    "long": "0942300W"
  },
  {
    "stationcode": "SHV",
    "stationname": "Shreveport Regional Airport",
    "lat": "322650N",
    "long": "0934944W"
  },
  {
    "stationcode": "UCE",
    "stationname": "Eunice Airport",
    "lat": "303000N",
    "long": "0922500W"
  },
  {
    "stationcode": "TRX",
    "stationname": "Trenton Memorial Airport",
    "lat": "400400N",
    "long": "0933600W"
  },
  {
    "stationcode": "CLL",
    "stationname": "Easterwood Field",
    "lat": "303519N",
    "long": "0962150W"
  },
  {
    "stationcode": "RVS",
    "stationname": "R. Lloyd Jones Airport",
    "lat": "360900N",
    "long": "0955800W"
  },
  {
    "stationcode": "BCS",
    "stationname": "Southern Seaplane Airport",
    "lat": "300300N",
    "long": "0901400W"
  },
  {
    "stationcode": "EMP",
    "stationname": "Emporia Airport",
    "lat": "382000N",
    "long": "0961100W"
  },
  {
    "stationcode": "BRO",
    "stationname": "South Padre Island International Airport",
    "lat": "255425N",
    "long": "0972533W"
  },
  {
    "stationcode": "AWM",
    "stationname": "West Memphis Municipal Airport",
    "lat": "350800N",
    "long": "0901100W"
  },
  {
    "stationcode": "VEX",
    "stationname": "Tioga Municipal Airport",
    "lat": "482300N",
    "long": "1025400W"
  },
  {
    "stationcode": "PSN",
    "stationname": "Palestine Airport",
    "lat": "314500N",
    "long": "0953700W"
  },
  {
    "stationcode": "ICT",
    "stationname": "Mid-Continent Airport",
    "lat": "373910N",
    "long": "0972559W"
  },
  {
    "stationcode": "RSN",
    "stationname": "Ruston Airport",
    "lat": "323100N",
    "long": "0923700W"
  },
  {
    "stationcode": "JMS",
    "stationname": "Jamestown Airport",
    "lat": "465547N",
    "long": "0984042W"
  },
  {
    "stationcode": "GFK",
    "stationname": "Grand Forks Airport",
    "lat": "475657N",
    "long": "0971034W"
  },
  {
    "stationcode": "THM",
    "stationname": "Thompsonfield Airport",
    "lat": "473425N",
    "long": "1151650W"
  },
  {
    "stationcode": "NEW",
    "stationname": "Lakefront Airport",
    "lat": "300236N",
    "long": "0900148W"
  },
  {
    "stationcode": "ATE",
    "stationname": "Antlers Airport",
    "lat": "341400N",
    "long": "0953700W"
  },
  {
    "stationcode": "AUW",
    "stationname": "Wausau Municipal Airport",
    "lat": "445542N",
    "long": "0893736W"
  },
  {
    "stationcode": "MOB",
    "stationname": "Mobile Municipal Airport",
    "lat": "304128N",
    "long": "0881434W"
  },
  {
    "stationcode": "RSL",
    "stationname": "Russell Airport",
    "lat": "385300N",
    "long": "0985100W"
  },
  {
    "stationcode": "ISW",
    "stationname": "Alexander Field",
    "lat": "442140N",
    "long": "0895017W"
  },
  {
    "stationcode": "LWC",
    "stationname": "Lawrence Airport",
    "lat": "385700N",
    "long": "0951700W"
  },
  {
    "stationcode": "MML",
    "stationname": "Marshall Municipal Airport-Ryan Field",
    "lat": "442639N",
    "long": "0954912W"
  },
  {
    "stationcode": "MPR",
    "stationname": "McPherson Airport",
    "lat": "382100N",
    "long": "0974100W"
  },
  {
    "stationcode": "TDW",
    "stationname": "Tradewind Airport",
    "lat": "351400N",
    "long": "1014300W"
  },
  {
    "stationcode": "FET",
    "stationname": "Fremont Municipal Airport",
    "lat": "412600N",
    "long": "0963000W"
  },
  {
    "stationcode": "COT",
    "stationname": "Cotulla Airport",
    "lat": "282600N",
    "long": "0991400W"
  },
  {
    "stationcode": "MBG",
    "stationname": "Mobridge Airport",
    "lat": "453200N",
    "long": "1002500W"
  },
  {
    "stationcode": "CCG",
    "stationname": "Crane County Airport",
    "lat": "312400N",
    "long": "1022100W"
  },
  {
    "stationcode": "PKD",
    "stationname": "Park Rapids Airport",
    "lat": "465400N",
    "long": "0950400W"
  },
  {
    "stationcode": "VPZ",
    "stationname": "Porter County Airport",
    "lat": "412700N",
    "long": "0865900W"
  },
  {
    "stationcode": "MDD",
    "stationname": "Midland Airpark",
    "lat": "315610N",
    "long": "1021200W"
  },
  {
    "stationcode": "GYY",
    "stationname": "Gary Regional Airport",
    "lat": "413659N",
    "long": "0872447W"
  },
  {
    "stationcode": "CUH",
    "stationname": "Cushing Municipal Airport",
    "lat": "355900N",
    "long": "0964600W"
  },
  {
    "stationcode": "GBG",
    "stationname": "Galesburg Airport",
    "lat": "405600N",
    "long": "0902600W"
  },
  {
    "stationcode": "CWA",
    "stationname": "Central Wisconsin Airport",
    "lat": "444700N",
    "long": "0894000W"
  },
  {
    "stationcode": "VYS",
    "stationname": "Illinois Valley Regional Airport",
    "lat": "412109N",
    "long": "0890859W"
  },
  {
    "stationcode": "CEY",
    "stationname": "Calloway County Airport",
    "lat": "364000N",
    "long": "0882200W"
  },
  {
    "stationcode": "ASN",
    "stationname": "Talladega Airport",
    "lat": "332600N",
    "long": "0860600W"
  },
  {
    "stationcode": "MUT",
    "stationname": "Muscatine Airport",
    "lat": "412159N",
    "long": "0910846W"
  },
  {
    "stationcode": "MVE",
    "stationname": "Montevideo Municipal Airport",
    "lat": "445700N",
    "long": "0954300W"
  },
  {
    "stationcode": "PWK",
    "stationname": "Pal-Waukee Airport",
    "lat": "420652N",
    "long": "0875409W"
  },
  {
    "stationcode": "TUP",
    "stationname": "Lemons Municipal Airport",
    "lat": "341605N",
    "long": "0884612W"
  },
  {
    "stationcode": "ONA",
    "stationname": "Winona Municipal Airport",
    "lat": "440500N",
    "long": "0913900W"
  },
  {
    "stationcode": "PPA",
    "stationname": "Perry Lefors Field",
    "lat": "353200N",
    "long": "1005700W"
  },
  {
    "stationcode": "VLA",
    "stationname": "Vandalia Airport",
    "lat": "385700N",
    "long": "0890500W"
  },
  {
    "stationcode": "WEA",
    "stationname": "Parker County Airport",
    "lat": "324500N",
    "long": "0974700W"
  },
  {
    "stationcode": "EVA",
    "stationname": "Evadale Landing Strip",
    "lat": "302100N",
    "long": "0940400W"
  },
  {
    "stationcode": "GRB",
    "stationname": "Austin-Straubel Field",
    "lat": "442908N",
    "long": "0880802W"
  },
  {
    "stationcode": "IRK",
    "stationname": "Kirksville Municipal Airport",
    "lat": "400537N",
    "long": "0923240W"
  },
  {
    "stationcode": "FEP",
    "stationname": "Albertus Airport",
    "lat": "421600N",
    "long": "0893500W"
  },
  {
    "stationcode": "ULM",
    "stationname": "New Ulm Airport",
    "lat": "441900N",
    "long": "0943000W"
  },
  {
    "stationcode": "BYH",
    "stationname": "Arkansas International Airport",
    "lat": "355700N",
    "long": "0895700W"
  },
  {
    "stationcode": "OLY",
    "stationname": "Olney Airport",
    "lat": "384300N",
    "long": "0880500W"
  },
  {
    "stationcode": "ANW",
    "stationname": "Ainsworth Airport",
    "lat": "423500N",
    "long": "0995900W"
  },
  {
    "stationcode": "AUS",
    "stationname": "Austin-Bergstrom International Airport",
    "lat": "301140N",
    "long": "0974012W"
  },
  {
    "stationcode": "OZA",
    "stationname": "Ozona Airport",
    "lat": "304200N",
    "long": "1011200W"
  },
  {
    "stationcode": "SJT",
    "stationname": "Mathis Field",
    "lat": "312135N",
    "long": "1002940W"
  },
  {
    "stationcode": "GRM",
    "stationname": "Devils Track Airport",
    "lat": "474500N",
    "long": "0912000W"
  },
  {
    "stationcode": "GVT",
    "stationname": "Majors Field",
    "lat": "330800N",
    "long": "0960700W"
  },
  {
    "stationcode": "RGR",
    "stationname": "Ranger Municipal Airport",
    "lat": "322800N",
    "long": "0984100W"
  },
  {
    "stationcode": "DAL",
    "stationname": "Love Field",
    "lat": "325050N",
    "long": "0965106W"
  },
  {
    "stationcode": "DNS",
    "stationname": "Denison Municipal Airport",
    "lat": "415900N",
    "long": "0952300W"
  },
  {
    "stationcode": "HKS",
    "stationname": "Hawkins Field",
    "lat": "322011N",
    "long": "0901326W"
  },
  {
    "stationcode": "LSE",
    "stationname": "La Crosse Municipal Airport",
    "lat": "435244N",
    "long": "0911524W"
  },
  {
    "stationcode": "DSI",
    "stationname": "Destin Airport",
    "lat": "302400N",
    "long": "0862817W"
  },
  {
    "stationcode": "DSM",
    "stationname": "Des Moines Airport",
    "lat": "413203N",
    "long": "0933928W"
  },
  {
    "stationcode": "SUX",
    "stationname": "Sioux Gateway Airport",
    "lat": "422407N",
    "long": "0962303W"
  },
  {
    "stationcode": "ATY",
    "stationname": "Watertown Airport",
    "lat": "445449N",
    "long": "0970904W"
  },
  {
    "stationcode": "BKX",
    "stationname": "Brookings Airport",
    "lat": "441816N",
    "long": "0964907W"
  },
  {
    "stationcode": "ANY",
    "stationname": "Anthony Airport",
    "lat": "370900N",
    "long": "0980200W"
  },
  {
    "stationcode": "BBD",
    "stationname": "Curtis Field",
    "lat": "310800N",
    "long": "0992000W"
  },
  {
    "stationcode": "MBY",
    "stationname": "Moberly Airport",
    "lat": "392800N",
    "long": "0922600W"
  },
  {
    "stationcode": "MOT",
    "stationname": "Minot International Airport",
    "lat": "481534N",
    "long": "1011649W"
  },
  {
    "stationcode": "OKC",
    "stationname": "Will Rogers Airport",
    "lat": "352335N",
    "long": "0973603W"
  },
  {
    "stationcode": "VKS",
    "stationname": "Vicksburg Airport",
    "lat": "321400N",
    "long": "0905600W"
  },
  {
    "stationcode": "MEI",
    "stationname": "Key Field",
    "lat": "321958N",
    "long": "0884507W"
  },
  {
    "stationcode": "MNM",
    "stationname": "Menominee Airport",
    "lat": "451700N",
    "long": "0873800W"
  },
  {
    "stationcode": "EOS",
    "stationname": "Neosho Airport",
    "lat": "365200N",
    "long": "0942200W"
  },
  {
    "stationcode": "SLN",
    "stationname": "Salina Airport",
    "lat": "384727N",
    "long": "0973908W"
  },
  {
    "stationcode": "ARG",
    "stationname": "Walnut Ridge Airport",
    "lat": "360700N",
    "long": "0905500W"
  },
  {
    "stationcode": "BPT",
    "stationname": "Jefferson County Airport",
    "lat": "295703N",
    "long": "0940115W"
  },
  {
    "stationcode": "BMT",
    "stationname": "Beaumont Municipal Airport",
    "lat": "300415N",
    "long": "0941257W"
  },
  {
    "stationcode": "PGL",
    "stationname": "Jackson County Airport",
    "lat": "302300N",
    "long": "0883000W"
  },
  {
    "stationcode": "PNC",
    "stationname": "Ponca City Airport",
    "lat": "364400N",
    "long": "0970600W"
  },
  {
    "stationcode": "BEC",
    "stationname": "Beech Airport",
    "lat": "374100N",
    "long": "0971300W"
  },
  {
    "stationcode": "ABR",
    "stationname": "Aberdeen Municipal Airport",
    "lat": "452713N",
    "long": "0982532W"
  },
  {
    "stationcode": "MOX",
    "stationname": "Morris Municipal Airport",
    "lat": "453500N",
    "long": "0955400W"
  },
  {
    "stationcode": "LFK",
    "stationname": "Lufkin Angelina County Airport",
    "lat": "311500N",
    "long": "0944510W"
  },
  {
    "stationcode": "AMA",
    "stationname": "Amarillo International Airport",
    "lat": "351310N",
    "long": "1014221W"
  },
  {
    "stationcode": "SGT",
    "stationname": "Stuttgart Airport",
    "lat": "343600N",
    "long": "0913400W"
  },
  {
    "stationcode": "GTR",
    "stationname": "Golden Triangle Regional Airport",
    "lat": "332757N",
    "long": "0882251W"
  },
  {
    "stationcode": "TTO",
    "stationname": "Britton Municipal Airport",
    "lat": "454800N",
    "long": "0974500W"
  },
  {
    "stationcode": "KNT",
    "stationname": "Kennett Municipal Airport",
    "lat": "361400N",
    "long": "0900300W"
  },
  {
    "stationcode": "GLS",
    "stationname": "Scholes Field",
    "lat": "291600N",
    "long": "0945200W"
  },
  {
    "stationcode": "SUE",
    "stationname": "Door County Airport",
    "lat": "445000N",
    "long": "0872500W"
  },
  {
    "stationcode": "RFD",
    "stationname": "Chicago Rockford Airport",
    "lat": "421153N",
    "long": "0890557W"
  },
  {
    "stationcode": "PTN",
    "stationname": "Harry P. Williams Memorial Airport",
    "lat": "294500N",
    "long": "0911830W"
  },
  {
    "stationcode": "BRD",
    "stationname": "Crow Wing County Airport",
    "lat": "462412N",
    "long": "0940747W"
  },
  {
    "stationcode": "FCM",
    "stationname": "Flying Cloud Airport",
    "lat": "444940N",
    "long": "0932736W"
  },
  {
    "stationcode": "HIB",
    "stationname": "Chisholm Airport",
    "lat": "472312N",
    "long": "0925020W"
  },
  {
    "stationcode": "JLN",
    "stationname": "Joplin Airport",
    "lat": "370907N",
    "long": "0942954W"
  },
  {
    "stationcode": "ALX",
    "stationname": "Thomas C Russell Field",
    "lat": "325600N",
    "long": "0855700W"
  },
  {
    "stationcode": "JCI",
    "stationname": "Johnson Industrial Airport",
    "lat": "385053N",
    "long": "0944416W"
  },
  {
    "stationcode": "GBD",
    "stationname": "Great Bend Airport",
    "lat": "382100N",
    "long": "0985200W"
  },
  {
    "stationcode": "FYV",
    "stationname": "Fayetteville Municipal Airport (Drake Field)",
    "lat": "360020N",
    "long": "0941013W"
  },
  {
    "stationcode": "SRC",
    "stationname": "Searcy Airport",
    "lat": "351500N",
    "long": "0914400W"
  },
  {
    "stationcode": "SSF",
    "stationname": "Stinson Municipal Airport",
    "lat": "293159N",
    "long": "0982800W"
  },
  {
    "stationcode": "ORD",
    "stationname": "O'Hare International Airport",
    "lat": "415843N",
    "long": "0875417W"
  },
  {
    "stationcode": "CPS",
    "stationname": "Bi-State Parks Airport",
    "lat": "383408N",
    "long": "0900915W"
  },
  {
    "stationcode": "PRX",
    "stationname": "Cox Field",
    "lat": "333800N",
    "long": "0952700W"
  },
  {
    "stationcode": "TVF",
    "stationname": "Thief River Falls Regional Airport",
    "lat": "480400N",
    "long": "0961100W"
  },
  {
    "stationcode": "LBL",
    "stationname": "Liberal Municipal Airport",
    "lat": "370239N",
    "long": "1005736W"
  },
  {
    "stationcode": "ETS",
    "stationname": "Enterprise Municipal Airport",
    "lat": "311900N",
    "long": "0855100W"
  },
  {
    "stationcode": "EGI",
    "stationname": "Duke Field",
    "lat": "302900N",
    "long": "0863000W"
  },
  {
    "stationcode": "LMS",
    "stationname": "Winston County Airport",
    "lat": "330900N",
    "long": "0890400W"
  },
  {
    "stationcode": "THA",
    "stationname": "Northern Airport",
    "lat": "352200N",
    "long": "0861100W"
  },
  {
    "stationcode": "FST",
    "stationname": "Pecos County Airport",
    "lat": "305500N",
    "long": "1025500W"
  },
  {
    "stationcode": "PLR",
    "stationname": "St. Clair County Airport",
    "lat": "333500N",
    "long": "0861700W"
  },
  {
    "stationcode": "VTN",
    "stationname": "Miller Field",
    "lat": "425100N",
    "long": "1003300W"
  },
  {
    "stationcode": "HSV",
    "stationname": "Huntsville International Airport",
    "lat": "343834N",
    "long": "0864632W"
  },
  {
    "stationcode": "FFM",
    "stationname": "Fergus Falls Airport",
    "lat": "461700N",
    "long": "0961000W"
  },
  {
    "stationcode": "FXY",
    "stationname": "Forest City Municipal Airport",
    "lat": "431600N",
    "long": "0933900W"
  },
  {
    "stationcode": "SIK",
    "stationname": "Sikeston Memorial Airport",
    "lat": "365300N",
    "long": "0893400W"
  },
  {
    "stationcode": "RIE",
    "stationname": "Rice Lake Airport",
    "lat": "453000N",
    "long": "0914400W"
  },
  {
    "stationcode": "UGN",
    "stationname": "Waukegan Memorial Airport",
    "lat": "422100N",
    "long": "0875000W"
  },
  {
    "stationcode": "CLI",
    "stationname": "Clintonville Airport",
    "lat": "443700N",
    "long": "0884600W"
  },
  {
    "stationcode": "BUB",
    "stationname": "Burwell Municipal Airport",
    "lat": "414700N",
    "long": "0990800W"
  },
  {
    "stationcode": "OKS",
    "stationname": "Oshkosh Airport",
    "lat": "412400N",
    "long": "1022000W"
  },
  {
    "stationcode": "OLV",
    "stationname": "Olive Branch Airport",
    "lat": "345844N",
    "long": "0894713W"
  },
  {
    "stationcode": "ALE",
    "stationname": "Alpine Airport",
    "lat": "302310N",
    "long": "1034100W"
  },
  {
    "stationcode": "TOI",
    "stationname": "Troy Municipal Airport",
    "lat": "314800N",
    "long": "0855800W"
  },
  {
    "stationcode": "BIS",
    "stationname": "Bismarck Airport",
    "lat": "464620N",
    "long": "1004445W"
  },
  {
    "stationcode": "AEX",
    "stationname": "Alexandria International Airport",
    "lat": "311939N",
    "long": "0923259W"
  },
  {
    "stationcode": "OBK",
    "stationname": "Sky Harbor Airport",
    "lat": "420700N",
    "long": "0874900W"
  },
  {
    "stationcode": "SES",
    "stationname": "Selfield Airport",
    "lat": "324334N",
    "long": "0870514W"
  },
  {
    "stationcode": "MWC",
    "stationname": "Lawrence J. Timmerman Airport",
    "lat": "430200N",
    "long": "0875400W"
  },
  {
    "stationcode": "MKL",
    "stationname": "Mckellar Airport",
    "lat": "353609N",
    "long": "0885450W"
  },
  {
    "stationcode": "UBS",
    "stationname": "Lowndes County Airport",
    "lat": "333000N",
    "long": "0882500W"
  },
  {
    "stationcode": "CRP",
    "stationname": "Corpus Christi International Airport",
    "lat": "274613N",
    "long": "0973004W"
  },
  {
    "stationcode": "STC",
    "stationname": "St. Cloud Municipal Airport",
    "lat": "453248N",
    "long": "0940336W"
  },
  {
    "stationcode": "IWD",
    "stationname": "Gogebic County Airport",
    "lat": "463140N",
    "long": "0900758W"
  },
  {
    "stationcode": "MGC",
    "stationname": "Michigan City Airport",
    "lat": "414200N",
    "long": "0864900W"
  },
  {
    "stationcode": "EAR",
    "stationname": "Kearney Airport",
    "lat": "404336N",
    "long": "0990033W"
  },
  {
    "stationcode": "SCB",
    "stationname": "State Airport",
    "lat": "414000N",
    "long": "0963900W"
  },
  {
    "stationcode": "LNK",
    "stationname": "Lincoln Municipal Airport",
    "lat": "405104N",
    "long": "0964533W"
  },
  {
    "stationcode": "RNH",
    "stationname": "New Richmond Municipal Airport",
    "lat": "450700N",
    "long": "0923200W"
  },
  {
    "stationcode": "CMI",
    "stationname": "Willard University Airport",
    "lat": "400221N",
    "long": "0881641W"
  },
  {
    "stationcode": "CWF",
    "stationname": "Chennault International Airport",
    "lat": "301239N",
    "long": "0930835W"
  },
  {
    "stationcode": "CDS",
    "stationname": "Childress Airport",
    "lat": "342500N",
    "long": "1001300W"
  },
  {
    "stationcode": "DXE",
    "stationname": "Bruce Campbell Field",
    "lat": "322629N",
    "long": "0900611W"
  },
  {
    "stationcode": "MKC",
    "stationname": "Kansas City Downtown Municipal Airport",
    "lat": "390722N",
    "long": "0943536W"
  },
  {
    "stationcode": "RHI",
    "stationname": "Oneida County Airport",
    "lat": "453752N",
    "long": "0892803W"
  },
  {
    "stationcode": "BHM",
    "stationname": "Birmingham Airport",
    "lat": "333347N",
    "long": "0864513W"
  },
  {
    "stationcode": "OMA",
    "stationname": "Eppley Airfield",
    "lat": "411811N",
    "long": "0955339W"
  },
  {
    "stationcode": "NHX",
    "stationname": "Barin Olf Osn Airport",
    "lat": "302500N",
    "long": "0874100W"
  },
  {
    "stationcode": "DEC",
    "stationname": "Decatur Airport",
    "lat": "395004N",
    "long": "0885156W"
  },
  {
    "stationcode": "MCK",
    "stationname": "Mc Cook Airport",
    "lat": "401228N",
    "long": "1003534W"
  },
  {
    "stationcode": "STJ",
    "stationname": "Rosecrans Memorial Airport",
    "lat": "394600N",
    "long": "0945500W"
  },
  {
    "stationcode": "KKT",
    "stationname": "Kentland Airport",
    "lat": "404600N",
    "long": "0872700W"
  },
  {
    "stationcode": "GHM",
    "stationname": "Centerville Municipal Airport",
    "lat": "354700N",
    "long": "0872800W"
  },
  {
    "stationcode": "GTG",
    "stationname": "Grantsburg Municipal Airport",
    "lat": "454700N",
    "long": "0924100W"
  },
  {
    "stationcode": "EST",
    "stationname": "Estherville Municipal Airport",
    "lat": "432400N",
    "long": "0945000W"
  },
  {
    "stationcode": "ELA",
    "stationname": "Eagle Lake Airport",
    "lat": "293500N",
    "long": "0962000W"
  },
  {
    "stationcode": "ELK",
    "stationname": "Elk City Municipal Airport",
    "lat": "352400N",
    "long": "0992500W"
  },
  {
    "stationcode": "RNC",
    "stationname": "Warren County Airport",
    "lat": "354200N",
    "long": "0855000W"
  },
  {
    "stationcode": "XES",
    "stationname": "Lake Geneva Municipal Airport",
    "lat": "423400N",
    "long": "0882500W"
  },
  {
    "stationcode": "CSQ",
    "stationname": "Creston Municipal Airport",
    "lat": "410400N",
    "long": "0942200W"
  },
  {
    "stationcode": "PTT",
    "stationname": "Pratt Airport",
    "lat": "374200N",
    "long": "0984500W"
  },
  {
    "stationcode": "CDH",
    "stationname": "Harrell Field",
    "lat": "333700N",
    "long": "0924600W"
  },
  {
    "stationcode": "KIP",
    "stationname": "Kickapoo Airport",
    "lat": "335137N",
    "long": "0982926W"
  },
  {
    "stationcode": "ICL",
    "stationname": "Clarinda Municipal Airport",
    "lat": "404400N",
    "long": "0950200W"
  },
  {
    "stationcode": "ANB",
    "stationname": "County Airport",
    "lat": "333518N",
    "long": "0855130W"
  },
  {
    "stationcode": "MQY",
    "stationname": "Smyrna Airport",
    "lat": "360035N",
    "long": "0863114W"
  },
  {
    "stationcode": "MSY",
    "stationname": "Louis Armstrong International Airport",
    "lat": "295936N",
    "long": "0901529W"
  },
  {
    "stationcode": "AXG",
    "stationname": "Algona Airport",
    "lat": "430400N",
    "long": "0941400W"
  },
  {
    "stationcode": "HTV",
    "stationname": "Huntsville Airport",
    "lat": "304500N",
    "long": "0953500W"
  },
  {
    "stationcode": "WIB",
    "stationname": "Wilbarger County Airport",
    "lat": "341332N",
    "long": "0991701W"
  },
  {
    "stationcode": "CWI",
    "stationname": "Clinton Airport",
    "lat": "415000N",
    "long": "0902100W"
  },
  {
    "stationcode": "ARA",
    "stationname": "Acadiana Regional Airport",
    "lat": "300234N",
    "long": "0915307W"
  },
  {
    "stationcode": "CIR",
    "stationname": "Cairo Airport",
    "lat": "370400N",
    "long": "0891300W"
  },
  {
    "stationcode": "MHK",
    "stationname": "Manhattan Municipal Airport",
    "lat": "390832N",
    "long": "0964009W"
  },
  {
    "stationcode": "GPT",
    "stationname": "Biloxi Regional Airport",
    "lat": "302426N",
    "long": "0890412W"
  },
  {
    "stationcode": "HEE",
    "stationname": "Thompson-Robbins Airport",
    "lat": "343500N",
    "long": "0904000W"
  },
  {
    "stationcode": "SNL",
    "stationname": "Shawnee Municipal Airport",
    "lat": "351900N",
    "long": "0965500W"
  },
  {
    "stationcode": "RFG",
    "stationname": "Rooke Field",
    "lat": "281800N",
    "long": "0971700W"
  },
  {
    "stationcode": "SPW",
    "stationname": "Spencer Municipal Airport",
    "lat": "431000N",
    "long": "0951100W"
  },
  {
    "stationcode": "JOT",
    "stationname": "Joliet Municipal Airport",
    "lat": "413200N",
    "long": "0880500W"
  },
  {
    "stationcode": "JVL",
    "stationname": "Rock County Airport",
    "lat": "423700N",
    "long": "0890200W"
  },
  {
    "stationcode": "MWL",
    "stationname": "Mineral Wells Airport",
    "lat": "324700N",
    "long": "0980400W"
  },
  {
    "stationcode": "MJQ",
    "stationname": "Jackson Airport",
    "lat": "433700N",
    "long": "0950100W"
  },
  {
    "stationcode": "MKE",
    "stationname": "General Mitchell Airport",
    "lat": "425650N",
    "long": "0875348W"
  },
  {
    "stationcode": "UNU",
    "stationname": "Dodge County Airport",
    "lat": "432400N",
    "long": "0884200W"
  },
  {
    "stationcode": "CRX",
    "stationname": "Roscoe Turner Airport",
    "lat": "345452N",
    "long": "0883612W"
  },
  {
    "stationcode": "WAH",
    "stationname": "Wahpeton Airport",
    "lat": "461600N",
    "long": "0963600W"
  },
  {
    "stationcode": "OSX",
    "stationname": "Attala County Airport",
    "lat": "330500N",
    "long": "0893300W"
  },
  {
    "stationcode": "FCY",
    "stationname": "Forrest City Municipal Airport",
    "lat": "345700N",
    "long": "0904600W"
  },
  {
    "stationcode": "LPO",
    "stationname": "Laporte Municipal Airport",
    "lat": "413600N",
    "long": "0864300W"
  },
  {
    "stationcode": "LXN",
    "stationname": "Lexington Airport",
    "lat": "404700N",
    "long": "0994500W"
  },
  {
    "stationcode": "MFI",
    "stationname": "Marshfield Municipal Airport",
    "lat": "443815N",
    "long": "0901120W"
  },
  {
    "stationcode": "POF",
    "stationname": "Earl Fields Memorial Airport",
    "lat": "364500N",
    "long": "0903000W"
  },
  {
    "stationcode": "HYR",
    "stationname": "Hayward Municipal Airport",
    "lat": "460200N",
    "long": "0912700W"
  },
  {
    "stationcode": "PIA",
    "stationname": "Greater Peoria Airport",
    "lat": "403951N",
    "long": "0894136W"
  },
  {
    "stationcode": "LRD",
    "stationname": "Laredo International Airport",
    "lat": "273238N",
    "long": "0992742W"
  },
  {
    "stationcode": "EFD",
    "stationname": "Ellington Field",
    "lat": "293620N",
    "long": "0950919W"
  },
  {
    "stationcode": "GAG",
    "stationname": "Gage Airport",
    "lat": "361900N",
    "long": "0994500W"
  },
  {
    "stationcode": "MKO",
    "stationname": "Davis Field",
    "lat": "354000N",
    "long": "0952200W"
  },
  {
    "stationcode": "RWF",
    "stationname": "Redwood Falls Municipal Airport",
    "lat": "443200N",
    "long": "0950700W"
  },
  {
    "stationcode": "ACT",
    "stationname": "Waco Municipal Airport",
    "lat": "313641N",
    "long": "0971350W"
  },
  {
    "stationcode": "HCA",
    "stationname": "Howard County Airport",
    "lat": "321800N",
    "long": "1012600W"
  },
  {
    "stationcode": "FSD",
    "stationname": "Sioux Falls Regional Airport (Jo Foss Field)",
    "lat": "433455N",
    "long": "0964431W"
  },
  {
    "stationcode": "LWV",
    "stationname": "Lawrenceville Municipal Airport",
    "lat": "384600N",
    "long": "0873600W"
  },
  {
    "stationcode": "LYU",
    "stationname": "Ely Airport",
    "lat": "475400N",
    "long": "0915100W"
  },
  {
    "stationcode": "STL",
    "stationname": "Lambert-St. Louis International Airport",
    "lat": "384450N",
    "long": "0902152W"
  },
  {
    "stationcode": "OFK",
    "stationname": "Stefan Field",
    "lat": "415900N",
    "long": "0972600W"
  },
  {
    "stationcode": "IKK",
    "stationname": "Greater Kankakee Airport",
    "lat": "410400N",
    "long": "0875100W"
  },
  {
    "stationcode": "GCY",
    "stationname": "Greenville Municipal Airport",
    "lat": "361100N",
    "long": "0824900W"
  },
  {
    "stationcode": "HSI",
    "stationname": "Hastings Airport",
    "lat": "403600N",
    "long": "0982600W"
  },
  {
    "stationcode": "AXN",
    "stationname": "Alexandria Airport",
    "lat": "454700N",
    "long": "0951600W"
  },
  {
    "stationcode": "HUM",
    "stationname": "Terrebonne Airport",
    "lat": "293500N",
    "long": "0904000W"
  },
  {
    "stationcode": "CFV",
    "stationname": "Coffeyville Municipal Airport",
    "lat": "370200N",
    "long": "0953700W"
  },
  {
    "stationcode": "MFE",
    "stationname": "Miller International Airport",
    "lat": "261033N",
    "long": "0981419W"
  },
  {
    "stationcode": "DLH",
    "stationname": "Duluth International Airport",
    "lat": "465032N",
    "long": "0921137W"
  },
  {
    "stationcode": "ADS",
    "stationname": "Addison Airport",
    "lat": "325813N",
    "long": "0965014W"
  },
  {
    "stationcode": "DVN",
    "stationname": "Davenport Airport",
    "lat": "413700N",
    "long": "0903500W"
  },
  {
    "stationcode": "SGR",
    "stationname": "Sugar Land Municipal Airport",
    "lat": "293700N",
    "long": "0953800W"
  },
  {
    "stationcode": "MEM",
    "stationname": "Memphis International Airport",
    "lat": "350259N",
    "long": "0895844W"
  },
  {
    "stationcode": "GLE",
    "stationname": "Gainesville Municipal Airport",
    "lat": "333700N",
    "long": "0970700W"
  },
  {
    "stationcode": "HUT",
    "stationname": "Hutchinson Airport",
    "lat": "380400N",
    "long": "0975200W"
  },
  {
    "stationcode": "DTN",
    "stationname": "Shreveport Downtown Airport",
    "lat": "322700N",
    "long": "0935000W"
  },
  {
    "stationcode": "CID",
    "stationname": "Cedar Rapids Airport",
    "lat": "415305N",
    "long": "0914239W"
  },
  {
    "stationcode": "MAW",
    "stationname": "Malden Airport",
    "lat": "363600N",
    "long": "0895900W"
  },
  {
    "stationcode": "APT",
    "stationname": "Marion County Airport",
    "lat": "350400N",
    "long": "0853800W"
  },
  {
    "stationcode": "ABI",
    "stationname": "Abilene Municipal Airport",
    "lat": "322441N",
    "long": "0994055W"
  },
  {
    "stationcode": "HBO",
    "stationname": "Humboldt Municipal Airport",
    "lat": "401000N",
    "long": "0955700W"
  },
  {
    "stationcode": "FSM",
    "stationname": "Fort Smith Municipal Airport",
    "lat": "352012N",
    "long": "0942203W"
  },
  {
    "stationcode": "MKT",
    "stationname": "Mankato Municipal Airport",
    "lat": "440900N",
    "long": "0935900W"
  },
  {
    "stationcode": "LKS",
    "stationname": "Lakeside Airport",
    "lat": "303003N",
    "long": "0980849W"
  },
  {
    "stationcode": "JKV",
    "stationname": "Jacksonville Airport",
    "lat": "315800N",
    "long": "0951700W"
  },
  {
    "stationcode": "MHE",
    "stationname": "Mitchell Municipal Airport",
    "lat": "434600N",
    "long": "0980200W"
  },
  {
    "stationcode": "MHL",
    "stationname": "Memorial Municipal Airport",
    "lat": "390500N",
    "long": "0931200W"
  },
  {
    "stationcode": "AXS",
    "stationname": "Altus Municipal Airport",
    "lat": "344210N",
    "long": "0992000W"
  },
  {
    "stationcode": "SNK",
    "stationname": "Winston Field",
    "lat": "324200N",
    "long": "1005700W"
  },
  {
    "stationcode": "SUW",
    "stationname": "Richard I Bong Airport",
    "lat": "464100N",
    "long": "0920542W"
  },
  {
    "stationcode": "AIZ",
    "stationname": "Lee C Fine Memorial Airport",
    "lat": "380600N",
    "long": "0923300W"
  },
  {
    "stationcode": "AUM",
    "stationname": "Austin Airport",
    "lat": "434000N",
    "long": "0925600W"
  },
  {
    "stationcode": "HKA",
    "stationname": "Blytheville Municipal Airport",
    "lat": "355610N",
    "long": "0895500W"
  },
  {
    "stationcode": "PPF",
    "stationname": "Tri-City Airport",
    "lat": "372000N",
    "long": "0953000W"
  },
  {
    "stationcode": "MPS",
    "stationname": "Mount Pleasant Airport",
    "lat": "330900N",
    "long": "0945800W"
  },
  {
    "stationcode": "GRE",
    "stationname": "Greenville Municipal Airport",
    "lat": "385300N",
    "long": "0892500W"
  },
  {
    "stationcode": "GRI",
    "stationname": "Grand Island Airport",
    "lat": "405803N",
    "long": "0981835W"
  },
  {
    "stationcode": "IRB",
    "stationname": "Iraan Municipal Airport",
    "lat": "305400N",
    "long": "1015400W"
  },
  {
    "stationcode": "RKR",
    "stationname": "Robert S. Kerr Airport",
    "lat": "350300N",
    "long": "0943700W"
  },
  {
    "stationcode": "CBK",
    "stationname": "Colby Municipal Airport",
    "lat": "392500N",
    "long": "1010300W"
  },
  {
    "stationcode": "PNS",
    "stationname": "Pensacola Regional Airport",
    "lat": "302824N",
    "long": "0871112W"
  },
  {
    "stationcode": "ALO",
    "stationname": "Waterloo Airport",
    "lat": "423322N",
    "long": "0922359W"
  },
  {
    "stationcode": "BDF",
    "stationname": "Rinkenberger Airport",
    "lat": "411100N",
    "long": "0893900W"
  },
  {
    "stationcode": "FSK",
    "stationname": "Fort Scott Municipal Airport",
    "lat": "374800N",
    "long": "0944600W"
  },
  {
    "stationcode": "DBQ",
    "stationname": "Dubuque Municipal Airport",
    "lat": "422436N",
    "long": "0904239W"
  },
  {
    "stationcode": "PLK",
    "stationname": "M Graham Clark Airport",
    "lat": "363700N",
    "long": "0931300W"
  },
  {
    "stationcode": "SAR",
    "stationname": "Sparta Community Airport",
    "lat": "380700N",
    "long": "0894200W"
  },
  {
    "stationcode": "DWN",
    "stationname": "Oklahoma City Downtown Airpark",
    "lat": "352700N",
    "long": "0973200W"
  },
  {
    "stationcode": "VHN",
    "stationname": "Culberson County Airport",
    "lat": "310336N",
    "long": "1044706W"
  },
  {
    "stationcode": "HAX",
    "stationname": "Hatbox Field",
    "lat": "354500N",
    "long": "0952500W"
  },
  {
    "stationcode": "GVW",
    "stationname": "Richards-Gebaur Airport",
    "lat": "385300N",
    "long": "0943200W"
  },
  {
    "stationcode": "FYM",
    "stationname": "Fayetteville Municipal Airport",
    "lat": "350900N",
    "long": "0863500W"
  },
  {
    "stationcode": "MWM",
    "stationname": "Windom Municipal Airport",
    "lat": "435500N",
    "long": "0950600W"
  },
  {
    "stationcode": "COM",
    "stationname": "Coleman Airport",
    "lat": "315000N",
    "long": "0992600W"
  },
  {
    "stationcode": "PIR",
    "stationname": "Pierre Airport",
    "lat": "442258N",
    "long": "1001709W"
  },
  {
    "stationcode": "BDE",
    "stationname": "Baudette Airport",
    "lat": "484300N",
    "long": "0943600W"
  },
  {
    "stationcode": "MXO",
    "stationname": "Monticello Municipal Airport",
    "lat": "421400N",
    "long": "0911100W"
  },
  {
    "stationcode": "RTL",
    "stationname": "Spirit Lake Airport",
    "lat": "432500N",
    "long": "0950600W"
  },
  {
    "stationcode": "UOS",
    "stationname": "Franklin County Airport",
    "lat": "350400N",
    "long": "0855500W"
  },
  {
    "stationcode": "VIH",
    "stationname": "Rolla National Airport",
    "lat": "380750N",
    "long": "0914610W"
  },
  {
    "stationcode": "LRJ",
    "stationname": "Lemars Municipal Airport",
    "lat": "424700N",
    "long": "0960900W"
  },
  {
    "stationcode": "MMS",
    "stationname": "Selfs Airport",
    "lat": "341300N",
    "long": "0901700W"
  },
  {
    "stationcode": "MSP",
    "stationname": "St Paul International Airport",
    "lat": "445310N",
    "long": "0931304W"
  },
  {
    "stationcode": "HSB",
    "stationname": "Raleigh Airport",
    "lat": "374400N",
    "long": "0883300W"
  },
  {
    "stationcode": "TPL",
    "stationname": "Draughon-Miller Airport",
    "lat": "310900N",
    "long": "0972400W"
  },
  {
    "stationcode": "UES",
    "stationname": "Waukesha Airport",
    "lat": "430232N",
    "long": "0881416W"
  },
  {
    "stationcode": "BKD",
    "stationname": "Stephens County Airport",
    "lat": "324500N",
    "long": "0985400W"
  },
  {
    "stationcode": "FLD",
    "stationname": "Fond Du Lac Airport",
    "lat": "434609N",
    "long": "0882911W"
  },
  {
    "stationcode": "FTW",
    "stationname": "Meacham Field",
    "lat": "324912N",
    "long": "0972134W"
  },
  {
    "stationcode": "YKN",
    "stationname": "Chan Gurney Airport",
    "lat": "425500N",
    "long": "0972300W"
  },
  {
    "stationcode": "TUL",
    "stationname": "Tulsa International Airport",
    "lat": "361152N",
    "long": "0955323W"
  },
  {
    "stationcode": "CKA",
    "stationname": "Kegelman Air Field",
    "lat": "364439N",
    "long": "0980724W"
  },
  {
    "stationcode": "CKK",
    "stationname": "Cherokee Village Airport",
    "lat": "361700N",
    "long": "0913000W"
  },
  {
    "stationcode": "MDF",
    "stationname": "Medford Airport",
    "lat": "450900N",
    "long": "0902000W"
  },
  {
    "stationcode": "EBS",
    "stationname": "Webster City Municipal Airport",
    "lat": "422800N",
    "long": "0934800W"
  },
  {
    "stationcode": "ELD",
    "stationname": "Goodwin Field",
    "lat": "331316N",
    "long": "0924848W"
  },
  {
    "stationcode": "FOD",
    "stationname": "Fort Dodge Airport",
    "lat": "423309N",
    "long": "0941123W"
  },
  {
    "stationcode": "CTK",
    "stationname": "Canton Airport",
    "lat": "431800N",
    "long": "0963500W"
  },
  {
    "stationcode": "BTR",
    "stationname": "Ryan Airport",
    "lat": "303159N",
    "long": "0910859W"
  },
  {
    "stationcode": "OPL",
    "stationname": "St Landry Parish Airport",
    "lat": "303300N",
    "long": "0920600W"
  },
  {
    "stationcode": "PEQ",
    "stationname": "Pecos City Airport",
    "lat": "312318N",
    "long": "1033052W"
  },
  {
    "stationcode": "PRO",
    "stationname": "Perry Municipal Airport",
    "lat": "415000N",
    "long": "0940600W"
  },
  {
    "stationcode": "AHH",
    "stationname": "Amery Municipal Airport",
    "lat": "451700N",
    "long": "0922200W"
  },
  {
    "stationcode": "PVW",
    "stationname": "Hale County Airport",
    "lat": "341000N",
    "long": "1014300W"
  },
  {
    "stationcode": "CRT",
    "stationname": "Crossett Municipal Airport",
    "lat": "330800N",
    "long": "0915800W"
  },
  {
    "stationcode": "MIW",
    "stationname": "Marshalltown Municipal Airport",
    "lat": "420700N",
    "long": "0925500W"
  },
  {
    "stationcode": "MPJ",
    "stationname": "Petit Jean Park Airport",
    "lat": "350900N",
    "long": "0924400W"
  },
  {
    "stationcode": "ADT",
    "stationname": "Ada Airport",
    "lat": "344800N",
    "long": "0964000W"
  },
  {
    "stationcode": "RRT",
    "stationname": "Warroad Airport",
    "lat": "485400N",
    "long": "0951800W"
  },
  {
    "stationcode": "PNX",
    "stationname": "Grayson County Airport",
    "lat": "333800N",
    "long": "0963500W"
  },
  {
    "stationcode": "IJX",
    "stationname": "Jacksonville Municipal Airport",
    "lat": "394600N",
    "long": "0901400W"
  },
  {
    "stationcode": "VPS",
    "stationname": "Ft. Walton Beach Airport",
    "lat": "302912N",
    "long": "0863112W"
  },
  {
    "stationcode": "MDW",
    "stationname": "Midway Airport",
    "lat": "414710N",
    "long": "0874509W"
  },
  {
    "stationcode": "CUX",
    "stationname": "Cuddihy Field",
    "lat": "274700N",
    "long": "0973000W"
  },
  {
    "stationcode": "GLW",
    "stationname": "Glasgow Municipal Airport",
    "lat": "370200N",
    "long": "0855700W"
  },
  {
    "stationcode": "SBM",
    "stationname": "Sheboygan Memorial Airport",
    "lat": "434544N",
    "long": "0875103W"
  },
  {
    "stationcode": "NVD",
    "stationname": "Nevada Airport",
    "lat": "375100N",
    "long": "0941800W"
  },
  {
    "stationcode": "LBB",
    "stationname": "Lubbock International Airport",
    "lat": "333949N",
    "long": "1014922W"
  },
  {
    "stationcode": "IWS",
    "stationname": "West Houston Airport",
    "lat": "294906N",
    "long": "0954022W"
  },
  {
    "stationcode": "PGR",
    "stationname": "Paragould Municipal Airport",
    "lat": "360300N",
    "long": "0903000W"
  },
  {
    "stationcode": "PHT",
    "stationname": "Henry County Airport",
    "lat": "362000N",
    "long": "0882300W"
  },
  {
    "stationcode": "HOU",
    "stationname": "Hobby Airport",
    "lat": "293842N",
    "long": "0951643W"
  },
  {
    "stationcode": "MTW",
    "stationname": "Manitowoc Municipal Airport",
    "lat": "440745N",
    "long": "0874058W"
  },
  {
    "stationcode": "HRL",
    "stationname": "Valley International Airport",
    "lat": "261343N",
    "long": "0973916W"
  },
  {
    "stationcode": "SLR",
    "stationname": "Sulphur Springs Airport",
    "lat": "330800N",
    "long": "0953600W"
  },
  {
    "stationcode": "WHT",
    "stationname": "Wharton Airport",
    "lat": "291516N",
    "long": "0960916W"
  },
  {
    "stationcode": "DNE",
    "stationname": "Dallas North Airport",
    "lat": "324500N",
    "long": "0972300W"
  },
  {
    "stationcode": "CXO",
    "stationname": "Montgomery County Airport",
    "lat": "301900N",
    "long": "0952700W"
  },
  {
    "stationcode": "CBF",
    "stationname": "Council Bluffs Municipal Airport",
    "lat": "411600N",
    "long": "0955200W"
  },
  {
    "stationcode": "PFN",
    "stationname": "Bay County Airport",
    "lat": "301245N",
    "long": "0854055W"
  },
  {
    "stationcode": "MVN",
    "stationname": "Mt Vernon-Outland Airport",
    "lat": "381900N",
    "long": "0885200W"
  },
  {
    "stationcode": "OCH",
    "stationname": "Nacogdoches Airport",
    "lat": "313448N",
    "long": "0944236W"
  },
  {
    "stationcode": "OEO",
    "stationname": "Osceola Municipal Airport",
    "lat": "451900N",
    "long": "0924200W"
  },
  {
    "stationcode": "FAR",
    "stationname": "Hector Field",
    "lat": "465514N",
    "long": "0964857W"
  },
  {
    "stationcode": "CKN",
    "stationname": "Crookston Municipal Airport",
    "lat": "475100N",
    "long": "0963700W"
  },
  {
    "stationcode": "LCH",
    "stationname": "Lake Charles Municipal Airport",
    "lat": "300752N",
    "long": "0931332W"
  },
  {
    "stationcode": "SPI",
    "stationname": "Capital Airport",
    "lat": "395039N",
    "long": "0894040W"
  },
  {
    "stationcode": "DVL",
    "stationname": "Devils Lake Airport",
    "lat": "480656N",
    "long": "0985429W"
  },
  {
    "stationcode": "RBD",
    "stationname": "Redbird Airport",
    "lat": "324000N",
    "long": "0965000W"
  },
  {
    "stationcode": "MDH",
    "stationname": "Southern Illinois Airport",
    "lat": "374700N",
    "long": "0891500W"
  },
  {
    "stationcode": "MTO",
    "stationname": "Coles County Memorial Airport",
    "lat": "392900N",
    "long": "0881700W"
  },
  {
    "stationcode": "ETN",
    "stationname": "Eastland Municipal Airport",
    "lat": "322500N",
    "long": "0984800W"
  },
  {
    "stationcode": "EVM",
    "stationname": "Eveleth Airport",
    "lat": "472600N",
    "long": "0923000W"
  },
  {
    "stationcode": "TRL",
    "stationname": "Terrell Field",
    "lat": "324239N",
    "long": "0961602W"
  },
  {
    "stationcode": "AIO",
    "stationname": "Atlantic Municipal Airport",
    "lat": "412400N",
    "long": "0950100W"
  },
  {
    "stationcode": "AUO",
    "stationname": "Auburn-Opelika Airport",
    "lat": "323700N",
    "long": "0852600W"
  },
  {
    "stationcode": "BWL",
    "stationname": "Blackwell Airport",
    "lat": "364800N",
    "long": "0971700W"
  },
  {
    "stationcode": "BRL",
    "stationname": "Burlington Airport",
    "lat": "404646N",
    "long": "0910721W"
  },
  {
    "stationcode": "BUM",
    "stationname": "Butler Airport",
    "lat": "381500N",
    "long": "0941900W"
  },
  {
    "stationcode": "FBY",
    "stationname": "Fairbury Municipal Airport",
    "lat": "400800N",
    "long": "0971100W"
  },
  {
    "stationcode": "LOT",
    "stationname": "Lewis Lockport Airport",
    "lat": "413600N",
    "long": "0880300W"
  },
  {
    "stationcode": "MLI",
    "stationname": "Quad-City Airport",
    "lat": "412655N",
    "long": "0903027W"
  },
  {
    "stationcode": "CKM",
    "stationname": "Fletcher Field",
    "lat": "341200N",
    "long": "0903400W"
  },
  {
    "stationcode": "XMD",
    "stationname": "Madison Airport",
    "lat": "440000N",
    "long": "0970700W"
  },
  {
    "stationcode": "JAS",
    "stationname": "County Airport",
    "lat": "305500N",
    "long": "0940100W"
  },
  {
    "stationcode": "BLV",
    "stationname": "Belleville Airport",
    "lat": "383228N",
    "long": "0895056W"
  },
  {
    "stationcode": "SPZ",
    "stationname": "Springdale Municipal Airport",
    "lat": "361100N",
    "long": "0940800W"
  },
  {
    "stationcode": "ONY",
    "stationname": "Olney Airport",
    "lat": "332200N",
    "long": "0984500W"
  },
  {
    "stationcode": "INL",
    "stationname": "Falls International Airport",
    "lat": "483358N",
    "long": "0932411W"
  },
  {
    "stationcode": "LUL",
    "stationname": "Hesler-Noble Field",
    "lat": "314000N",
    "long": "0891000W"
  },
  {
    "stationcode": "EDK",
    "stationname": "El Dorado Airport",
    "lat": "374600N",
    "long": "0965200W"
  },
  {
    "stationcode": "COU",
    "stationname": "Columbia Regional Airport",
    "lat": "384849N",
    "long": "0921309W"
  },
  {
    "stationcode": "ROG",
    "stationname": "Rogers Airport",
    "lat": "362216N",
    "long": "0940628W"
  },
  {
    "stationcode": "OSH",
    "stationname": "Wittman Field",
    "lat": "435900N",
    "long": "0883300W"
  },
  {
    "stationcode": "BGD",
    "stationname": "Borger Airport",
    "lat": "353900N",
    "long": "1012400W"
  },
  {
    "stationcode": "BVX",
    "stationname": "Batesville Municipal Airport",
    "lat": "354600N",
    "long": "0913900W"
  },
  {
    "stationcode": "CEA",
    "stationname": "Cessna Aircraft Field",
    "lat": "374110N",
    "long": "0971300W"
  },
  {
    "stationcode": "NIR",
    "stationname": "Chase Field Industrial Airport",
    "lat": "282400N",
    "long": "0974500W"
  },
  {
    "stationcode": "CRS",
    "stationname": "Corsicana Airport",
    "lat": "320600N",
    "long": "0962800W"
  },
  {
    "stationcode": "DDC",
    "stationname": "Dodge City Municipal Airport",
    "lat": "374537N",
    "long": "0995751W"
  },
  {
    "stationcode": "LFT",
    "stationname": "Lafayette Regional Airport",
    "lat": "301219N",
    "long": "0915915W"
  },
  {
    "stationcode": "JAN",
    "stationname": "Jackson-Evers International Airport",
    "lat": "321840N",
    "long": "0900433W"
  },
  {
    "stationcode": "IOW",
    "stationname": "Iowa City Airport",
    "lat": "413824N",
    "long": "0913257W"
  },
  {
    "stationcode": "WLD",
    "stationname": "Arkansas City Airport",
    "lat": "371000N",
    "long": "0970300W"
  },
  {
    "stationcode": "UVA",
    "stationname": "Garner Field",
    "lat": "291200N",
    "long": "0994400W"
  },
  {
    "stationcode": "CLC",
    "stationname": "Metroport Airport",
    "lat": "293325N",
    "long": "0950815W"
  },
  {
    "stationcode": "MAF",
    "stationname": "Odessa Regional Airport",
    "lat": "315633N",
    "long": "1021207W"
  },
  {
    "stationcode": "BBW",
    "stationname": "Broken Bow Airport",
    "lat": "412400N",
    "long": "0993800W"
  },
  {
    "stationcode": "BZT",
    "stationname": "Hinkles Ferry",
    "lat": "290300N",
    "long": "0953400W"
  },
  {
    "stationcode": "CNU",
    "stationname": "Martin Johnson Airport",
    "lat": "374100N",
    "long": "0952700W"
  },
  {
    "stationcode": "BKG",
    "stationname": "Branson Airport",
    "lat": "363154N",
    "long": "0931159W"
  },
  {
    "stationcode": "AHF",
    "stationname": "Arapahoe Municipal Airport",
    "lat": "402000N",
    "long": "0995400W"
  },
  {
    "stationcode": "MRF",
    "stationname": "Marfa Municipal Airport",
    "lat": "302216N",
    "long": "1040105W"
  },
  {
    "stationcode": "HBR",
    "stationname": "Hobart Airport",
    "lat": "345929N",
    "long": "0990317W"
  },
  {
    "stationcode": "AMW",
    "stationname": "Ames Airport",
    "lat": "420000N",
    "long": "0933700W"
  },
  {
    "stationcode": "HON",
    "stationname": "Howes Airport",
    "lat": "442307N",
    "long": "0981343W"
  },
  {
    "stationcode": "GCK",
    "stationname": "Garden City Municipal Airport",
    "lat": "375539N",
    "long": "1004328W"
  },
  {
    "stationcode": "FAL",
    "stationname": "Roma Falcon State Airport",
    "lat": "263500N",
    "long": "0990800W"
  },
  {
    "stationcode": "CSV",
    "stationname": "Crossville Memorial Airport",
    "lat": "355700N",
    "long": "0850500W"
  },
  {
    "stationcode": "ROX",
    "stationname": "Roseau Municipal Airport",
    "lat": "485100N",
    "long": "0954600W"
  },
  {
    "stationcode": "FDR",
    "stationname": "Frederick Municipal Airport",
    "lat": "342100N",
    "long": "0985900W"
  },
  {
    "stationcode": "TNU",
    "stationname": "Newton Municipal Airport",
    "lat": "414200N",
    "long": "0930300W"
  },
  {
    "stationcode": "VCT",
    "stationname": "County-Foster Airport",
    "lat": "285104N",
    "long": "0965452W"
  },
  {
    "stationcode": "DEH",
    "stationname": "Decorah Municipal Airport",
    "lat": "431900N",
    "long": "0915000W"
  },
  {
    "stationcode": "SWW",
    "stationname": "Sweetwater Airport",
    "lat": "322800N",
    "long": "1002800W"
  },
  {
    "stationcode": "MPZ",
    "stationname": "Mount Pleasant Municipal Airport",
    "lat": "405800N",
    "long": "0913300W"
  },
  {
    "stationcode": "UKN",
    "stationname": "Waukon Municipal Airport",
    "lat": "431600N",
    "long": "0912900W"
  },
  {
    "stationcode": "ECP",
    "stationname": "NW Florida Beaches International Airport",
    "lat": "302128N",
    "long": "0854756W"
  },
  {
    "stationcode": "EVV",
    "stationname": "Dress Regional Airport",
    "lat": "380213N",
    "long": "0873157W"
  },
  {
    "stationcode": "EWK",
    "stationname": "Newton City-County Airport",
    "lat": "380300N",
    "long": "0972100W"
  },
  {
    "stationcode": "LNR",
    "stationname": "Tri County Airport",
    "lat": "431100N",
    "long": "0901200W"
  },
  {
    "stationcode": "ADM",
    "stationname": "Ardmore Municipal Airport",
    "lat": "341800N",
    "long": "0970100W"
  },
  {
    "stationcode": "PWA",
    "stationname": "Wiley Post Airport",
    "lat": "353206N",
    "long": "0973844W"
  },
  {
    "stationcode": "HLC",
    "stationname": "Hill City Airport",
    "lat": "392200N",
    "long": "0995100W"
  },
  {
    "stationcode": "AEL",
    "stationname": "Albert Lea Airport",
    "lat": "434100N",
    "long": "0932200W"
  },
  {
    "stationcode": "APA",
    "stationname": "Arapahoe County Airport",
    "lat": "393419N",
    "long": "1045104W"
  },
  {
    "stationcode": "BIL",
    "stationname": "Billings Airport",
    "lat": "454828N",
    "long": "1083234W"
  },
  {
    "stationcode": "LEM",
    "stationname": "Lemmon Airport",
    "lat": "455500N",
    "long": "1020600W"
  },
  {
    "stationcode": "ECS",
    "stationname": "Mondell Airport",
    "lat": "435300N",
    "long": "1041900W"
  },
  {
    "stationcode": "SLC",
    "stationname": "Salt Lake City International Airport",
    "lat": "404703N",
    "long": "1115801W"
  },
  {
    "stationcode": "AFO",
    "stationname": "Afton Municipal Airport",
    "lat": "424300N",
    "long": "1105600W"
  },
  {
    "stationcode": "DRU",
    "stationname": "Drummond Airport",
    "lat": "463700N",
    "long": "1131200W"
  },
  {
    "stationcode": "CDC",
    "stationname": "Cedar City Airport",
    "lat": "374203N",
    "long": "1130556W"
  },
  {
    "stationcode": "IDA",
    "stationname": "Fanning Field",
    "lat": "433052N",
    "long": "1120415W"
  },
  {
    "stationcode": "STK",
    "stationname": "Crosson Field",
    "lat": "403700N",
    "long": "1031600W"
  },
  {
    "stationcode": "GRN",
    "stationname": "Gordon Airport",
    "lat": "424800N",
    "long": "1021200W"
  },
  {
    "stationcode": "SDY",
    "stationname": "Richland Municipal Airport",
    "lat": "474218N",
    "long": "1041147W"
  },
  {
    "stationcode": "SUC",
    "stationname": "Schloredt Airport",
    "lat": "442400N",
    "long": "1042300W"
  },
  {
    "stationcode": "BYI",
    "stationname": "Rupert Airport",
    "lat": "423200N",
    "long": "1134600W"
  },
  {
    "stationcode": "HNX",
    "stationname": "Hanna Airport",
    "lat": "415000N",
    "long": "1063000W"
  },
  {
    "stationcode": "BPI",
    "stationname": "Big Piney-Marbleton Airport",
    "lat": "423500N",
    "long": "1100700W"
  },
  {
    "stationcode": "EVW",
    "stationname": "Evanston Airport",
    "lat": "412100N",
    "long": "1110100W"
  },
  {
    "stationcode": "TRT",
    "stationname": "Tremonton Airport",
    "lat": "031109S",
    "long": "1195505E"
  },
  {
    "stationcode": "DRO",
    "stationname": "La Plata Airport",
    "lat": "370908N",
    "long": "1074513W"
  },
  {
    "stationcode": "ICS",
    "stationname": "Cascade Airport",
    "lat": "442937N",
    "long": "1160058W"
  },
  {
    "stationcode": "TOR",
    "stationname": "Torrington Municipal Airport",
    "lat": "420300N",
    "long": "1041000W"
  },
  {
    "stationcode": "POY",
    "stationname": "Powell/Lovell Airport",
    "lat": "445200N",
    "long": "1084800W"
  },
  {
    "stationcode": "CHL",
    "stationname": "Challis Airport",
    "lat": "443000N",
    "long": "1141400W"
  },
  {
    "stationcode": "CSE",
    "stationname": "Crested Butte Airport",
    "lat": "385200N",
    "long": "1065900W"
  },
  {
    "stationcode": "FBR",
    "stationname": "Fort Bridger Airport",
    "lat": "411900N",
    "long": "1102300W"
  },
  {
    "stationcode": "JAC",
    "stationname": "Jackson Hole Airport",
    "lat": "433624N",
    "long": "1104413W"
  },
  {
    "stationcode": "ESO",
    "stationname": "Espanola Airport",
    "lat": "360600N",
    "long": "1060200W"
  },
  {
    "stationcode": "AXX",
    "stationname": "Angel Fire Airport",
    "lat": "362500N",
    "long": "1051700W"
  },
  {
    "stationcode": "ROW",
    "stationname": "Industrial Airport",
    "lat": "331759N",
    "long": "1043148W"
  },
  {
    "stationcode": "LIC",
    "stationname": "Limon Municipal Airport",
    "lat": "391600N",
    "long": "1034100W"
  },
  {
    "stationcode": "GUC",
    "stationname": "Gunnison Airport",
    "lat": "383208N",
    "long": "1065559W"
  },
  {
    "stationcode": "RWL",
    "stationname": "Rawlins Airport",
    "lat": "414800N",
    "long": "1071200W"
  },
  {
    "stationcode": "PWY",
    "stationname": "Pinedale Airport",
    "lat": "424743N",
    "long": "1094825W"
  },
  {
    "stationcode": "SLT",
    "stationname": "Salida Airport",
    "lat": "383218N",
    "long": "1060256W"
  },
  {
    "stationcode": "CIG",
    "stationname": "Craig-Moffat Airport",
    "lat": "403000N",
    "long": "1073500W"
  },
  {
    "stationcode": "CNY",
    "stationname": "Canyonlands Field",
    "lat": "384539N",
    "long": "1094451W"
  },
  {
    "stationcode": "BFF",
    "stationname": "Scotts Bluff County Airport",
    "lat": "415227N",
    "long": "1033544W"
  },
  {
    "stationcode": "BFG",
    "stationname": "Bullfrog Basin Airport",
    "lat": "373300N",
    "long": "1104230W"
  },
  {
    "stationcode": "PIH",
    "stationname": "Pocatello Airport",
    "lat": "425435N",
    "long": "1123545W"
  },
  {
    "stationcode": "BCE",
    "stationname": "Bryce Airport",
    "lat": "374200N",
    "long": "1120900W"
  },
  {
    "stationcode": "BZN",
    "stationname": "Gallatin Field",
    "lat": "454639N",
    "long": "1110911W"
  },
  {
    "stationcode": "DLN",
    "stationname": "Dillon Airport",
    "lat": "451300N",
    "long": "1123800W"
  },
  {
    "stationcode": "LSB",
    "stationname": "Lordsburg Airport",
    "lat": "322500N",
    "long": "1084000W"
  },
  {
    "stationcode": "LND",
    "stationname": "Hunt Field",
    "lat": "424800N",
    "long": "1084030W"
  },
  {
    "stationcode": "RIL",
    "stationname": "Garfield County Airport",
    "lat": "393129N",
    "long": "1074331W"
  },
  {
    "stationcode": "WPO",
    "stationname": "North Fork Valley Airport",
    "lat": "385200N",
    "long": "1073600W"
  },
  {
    "stationcode": "CDR",
    "stationname": "Chadron Airport",
    "lat": "425006N",
    "long": "1030555W"
  },
  {
    "stationcode": "MLS",
    "stationname": "Miles City Municipal Airport",
    "lat": "462541N",
    "long": "1055311W"
  },
  {
    "stationcode": "SPF",
    "stationname": "Black Hills Airport",
    "lat": "442900N",
    "long": "1035100W"
  },
  {
    "stationcode": "OGA",
    "stationname": "Searle Field",
    "lat": "410800N",
    "long": "1014300W"
  },
  {
    "stationcode": "SVC",
    "stationname": "Grant County Airport",
    "lat": "323757N",
    "long": "1080923W"
  },
  {
    "stationcode": "TCC",
    "stationname": "Tucumcari Airport",
    "lat": "351100N",
    "long": "1033600W"
  },
  {
    "stationcode": "ENV",
    "stationname": "Wendover Airport",
    "lat": "404400N",
    "long": "1140200W"
  },
  {
    "stationcode": "GTF",
    "stationname": "Great Falls International Airport",
    "lat": "472855N",
    "long": "1112214W"
  },
  {
    "stationcode": "RPX",
    "stationname": "Roundup Airport",
    "lat": "462600N",
    "long": "1083200W"
  },
  {
    "stationcode": "VEL",
    "stationname": "Vernal Airport",
    "lat": "402636N",
    "long": "1093039W"
  },
  {
    "stationcode": "LAA",
    "stationname": "Lamar Field",
    "lat": "380400N",
    "long": "1024100W"
  },
  {
    "stationcode": "ONM",
    "stationname": "Socorro Airport",
    "lat": "340300N",
    "long": "1065300W"
  },
  {
    "stationcode": "OGD",
    "stationname": "Ogden Municipal Airport",
    "lat": "411146N",
    "long": "1120038W"
  },
  {
    "stationcode": "COS",
    "stationname": "Peterson Field",
    "lat": "384821N",
    "long": "1044203W"
  },
  {
    "stationcode": "EMM",
    "stationname": "Kemmerer Airport",
    "lat": "415100N",
    "long": "1103300W"
  },
  {
    "stationcode": "LVS",
    "stationname": "Las Vegas Airport",
    "lat": "353900N",
    "long": "1050900W"
  },
  {
    "stationcode": "EGE",
    "stationname": "Eagle County Airport",
    "lat": "393833N",
    "long": "1065504W"
  },
  {
    "stationcode": "SBO",
    "stationname": "Salina Airport",
    "lat": "390145N",
    "long": "1115015W"
  },
  {
    "stationcode": "SUN",
    "stationname": "Sun Valley Airport",
    "lat": "433020N",
    "long": "1141750W"
  },
  {
    "stationcode": "PUB",
    "stationname": "Pueblo Memorial Airport",
    "lat": "381721N",
    "long": "1042948W"
  },
  {
    "stationcode": "CEZ",
    "stationname": "Montezuma County Airport",
    "lat": "371811N",
    "long": "1083743W"
  },
  {
    "stationcode": "FCA",
    "stationname": "Glacier National Park Airport",
    "lat": "481838N",
    "long": "1141522W"
  },
  {
    "stationcode": "ELP",
    "stationname": "El Paso International Airport",
    "lat": "314809N",
    "long": "1062354W"
  },
  {
    "stationcode": "CAO",
    "stationname": "Clayton Airport",
    "lat": "362700N",
    "long": "1031100W"
  },
  {
    "stationcode": "BMC",
    "stationname": "Brigham City Airport",
    "lat": "413200N",
    "long": "1120300W"
  },
  {
    "stationcode": "LAM",
    "stationname": "Los Alamos Airport",
    "lat": "355249N",
    "long": "1061616W"
  },
  {
    "stationcode": "DTA",
    "stationname": "Delta Airport",
    "lat": "392300N",
    "long": "1123000W"
  },
  {
    "stationcode": "COD",
    "stationname": "Yellowstone Regional Airport",
    "lat": "443104N",
    "long": "1090134W"
  },
  {
    "stationcode": "OCE",
    "stationname": "Ocean City Municipal Airport",
    "lat": "381833N",
    "long": "0750714W"
  },
  {
    "stationcode": "MLD",
    "stationname": "Malad City Airport",
    "lat": "422010N",
    "long": "1121800W"
  },
  {
    "stationcode": "LGU",
    "stationname": "Cache Airport",
    "lat": "414700N",
    "long": "1115100W"
  },
  {
    "stationcode": "LAR",
    "stationname": "General Brees Field",
    "lat": "411843N",
    "long": "1054030W"
  },
  {
    "stationcode": "LRU",
    "stationname": "Las Cruces Municipal Airport",
    "lat": "321800N",
    "long": "1065600W"
  },
  {
    "stationcode": "GWS",
    "stationname": "Glendwood Springs Municipal Airport",
    "lat": "393300N",
    "long": "1071900W"
  },
  {
    "stationcode": "WHH",
    "stationname": "Hiltons Har H Airport",
    "lat": "400150N",
    "long": "1051405W"
  },
  {
    "stationcode": "CTB",
    "stationname": "Cut Bank Airport",
    "lat": "483800N",
    "long": "1122000W"
  },
  {
    "stationcode": "BWM",
    "stationname": "Bowman Airport",
    "lat": "460800N",
    "long": "1033500W"
  },
  {
    "stationcode": "BDG",
    "stationname": "Blanding Airport",
    "lat": "373500N",
    "long": "1092900W"
  },
  {
    "stationcode": "SMN",
    "stationname": "Salmon Airport",
    "lat": "451100N",
    "long": "1135500W"
  },
  {
    "stationcode": "GJT",
    "stationname": "Walker Field",
    "lat": "390724N",
    "long": "1083133W"
  },
  {
    "stationcode": "HBB",
    "stationname": "Industrial Airpark",
    "lat": "324110N",
    "long": "1031310W"
  },
  {
    "stationcode": "BDX",
    "stationname": "Broadus Airport",
    "lat": "452700N",
    "long": "1052500W"
  },
  {
    "stationcode": "LXV",
    "stationname": "Leadville Airport",
    "lat": "391314N",
    "long": "1061902W"
  },
  {
    "stationcode": "PNU",
    "stationname": "Panguitch Airport",
    "lat": "375000N",
    "long": "1122300W"
  },
  {
    "stationcode": "MHN",
    "stationname": "Mullen Airport",
    "lat": "420300N",
    "long": "1010400W"
  },
  {
    "stationcode": "CVN",
    "stationname": "Clovis Municipal Airport",
    "lat": "342535N",
    "long": "1030444W"
  },
  {
    "stationcode": "CYS",
    "stationname": "Cheyenne Airport",
    "lat": "410921N",
    "long": "1044843W"
  },
  {
    "stationcode": "DEN",
    "stationname": "Denver International Airport",
    "lat": "395117N",
    "long": "1044305W"
  },
  {
    "stationcode": "AMK",
    "stationname": "Animas Airpark",
    "lat": "370910N",
    "long": "1074510W"
  },
  {
    "stationcode": "SGU",
    "stationname": "Saint George Municipal Airport",
    "lat": "370526N",
    "long": "1133535W"
  },
  {
    "stationcode": "ROL",
    "stationname": "Roosevelt Airport",
    "lat": "401800N",
    "long": "1095900W"
  },
  {
    "stationcode": "BYG",
    "stationname": "Buffalo Municipal Airport",
    "lat": "442100N",
    "long": "1064200W"
  },
  {
    "stationcode": "MSO",
    "stationname": "Johnson-Bell Field",
    "lat": "465456N",
    "long": "1140515W"
  },
  {
    "stationcode": "SAF",
    "stationname": "Santa Fe Airport",
    "lat": "353700N",
    "long": "1060500W"
  },
  {
    "stationcode": "WYS",
    "stationname": "Yellowstone Airport",
    "lat": "444118N",
    "long": "1110704W"
  },
  {
    "stationcode": "WBI",
    "stationname": "Broker Inn Airport",
    "lat": "400100N",
    "long": "1051700W"
  },
  {
    "stationcode": "GDV",
    "stationname": "Dawson Community Airport",
    "lat": "470826N",
    "long": "1044834W"
  },
  {
    "stationcode": "EAN",
    "stationname": "Phifer Field",
    "lat": "420330N",
    "long": "1045510W"
  },
  {
    "stationcode": "RIF",
    "stationname": "Reynolds Airport",
    "lat": "384500N",
    "long": "1120600W"
  },
  {
    "stationcode": "PVU",
    "stationname": "Provo Airport",
    "lat": "401308N",
    "long": "1114321W"
  },
  {
    "stationcode": "FSU",
    "stationname": "Fort Sumner Airport",
    "lat": "342800N",
    "long": "1041500W"
  },
  {
    "stationcode": "MLF",
    "stationname": "Milford Airport",
    "lat": "382600N",
    "long": "1130100W"
  },
  {
    "stationcode": "LSK",
    "stationname": "Lusk Municipal Airport",
    "lat": "424500N",
    "long": "1042300W"
  },
  {
    "stationcode": "GUP",
    "stationname": "Senator Clark Airport",
    "lat": "353040N",
    "long": "1084722W"
  },
  {
    "stationcode": "MLK",
    "stationname": "Malta Airport",
    "lat": "482057N",
    "long": "1075307W"
  },
  {
    "stationcode": "FIL",
    "stationname": "Fillmore Municipal Airport",
    "lat": "385800N",
    "long": "1122000W"
  },
  {
    "stationcode": "BOI",
    "stationname": "Boise Air Terminal (Gowen Field)",
    "lat": "433352N",
    "long": "1161322W"
  },
  {
    "stationcode": "GNG",
    "stationname": "Gooding Airport",
    "lat": "425500N",
    "long": "1144700W"
  },
  {
    "stationcode": "DGW",
    "stationname": "Converse County Airport",
    "lat": "424500N",
    "long": "1052400W"
  },
  {
    "stationcode": "RXE",
    "stationname": "Madison County Airport",
    "lat": "435002N",
    "long": "1114818W"
  },
  {
    "stationcode": "ATS",
    "stationname": "Artesia Airport",
    "lat": "325100N",
    "long": "1042800W"
  },
  {
    "stationcode": "AKO",
    "stationname": "Washington County Airport",
    "lat": "401000N",
    "long": "1031300W"
  },
  {
    "stationcode": "FNL",
    "stationname": "Fort Collins/Loveland Municipal Airport",
    "lat": "402706N",
    "long": "1050042W"
  },
  {
    "stationcode": "MXC",
    "stationname": "San Juan County Airport",
    "lat": "375200N",
    "long": "1092100W"
  },
  {
    "stationcode": "DMN",
    "stationname": "Deming Airport",
    "lat": "321600N",
    "long": "1074500W"
  },
  {
    "stationcode": "PUC",
    "stationname": "Carbon County Airport",
    "lat": "393610N",
    "long": "1104500W"
  },
  {
    "stationcode": "FMN",
    "stationname": "Farmington Municipal Airport",
    "lat": "364429N",
    "long": "1081352W"
  },
  {
    "stationcode": "WRL",
    "stationname": "Worland Airport",
    "lat": "435752N",
    "long": "1075711W"
  },
  {
    "stationcode": "ALM",
    "stationname": "Alamogordo Municipal Airport",
    "lat": "325024N",
    "long": "1055926W"
  },
  {
    "stationcode": "BJC",
    "stationname": "Jeffco Airport",
    "lat": "395433N",
    "long": "1050658W"
  },
  {
    "stationcode": "NTJ",
    "stationname": "Manti-Ephraim Airport",
    "lat": "391600N",
    "long": "1113800W"
  },
  {
    "stationcode": "LVM",
    "stationname": "Mission Field",
    "lat": "454000N",
    "long": "1103400W"
  },
  {
    "stationcode": "IML",
    "stationname": "Imperial Airport",
    "lat": "403100N",
    "long": "1013700W"
  },
  {
    "stationcode": "AIA",
    "stationname": "Alliance Airport",
    "lat": "420307N",
    "long": "1024828W"
  },
  {
    "stationcode": "BCJ",
    "stationname": "Baca Grande Airport",
    "lat": "390000N",
    "long": "1060000W"
  },
  {
    "stationcode": "TCS",
    "stationname": "Truth Or Consequences Municipal Airport",
    "lat": "330700N",
    "long": "1071500W"
  },
  {
    "stationcode": "JDN",
    "stationname": "Jordan Airport",
    "lat": "471900N",
    "long": "1065500W"
  },
  {
    "stationcode": "HLN",
    "stationname": "Helena Airport",
    "lat": "463625N",
    "long": "1115858W"
  },
  {
    "stationcode": "RAP",
    "stationname": "Rapid City Regional Airport",
    "lat": "440235N",
    "long": "1030325W"
  },
  {
    "stationcode": "TWF",
    "stationname": "Twin Falls City County Airport",
    "lat": "422854N",
    "long": "1142916W"
  },
  {
    "stationcode": "SBS",
    "stationname": "Steamboat Springs Airport",
    "lat": "403100N",
    "long": "1065200W"
  },
  {
    "stationcode": "OLF",
    "stationname": "Wolf Point International Airport",
    "lat": "480541N",
    "long": "1053433W"
  },
  {
    "stationcode": "CPR",
    "stationname": "Casper Airport",
    "lat": "425429N",
    "long": "1062752W"
  },
  {
    "stationcode": "LWT",
    "stationname": "Lewistown Municipal Airport",
    "lat": "470255N",
    "long": "1092819W"
  },
  {
    "stationcode": "MTJ",
    "stationname": "Montrose County Airport",
    "lat": "383019N",
    "long": "1075339W"
  },
  {
    "stationcode": "GMV",
    "stationname": "Monument Valley Airport",
    "lat": "370000N",
    "long": "1101200W"
  },
  {
    "stationcode": "SBX",
    "stationname": "Shelby Airport",
    "lat": "483200N",
    "long": "1115200W"
  },
  {
    "stationcode": "MSD",
    "stationname": "Mt. Pleasant Airport",
    "lat": "393129N",
    "long": "1112828W"
  },
  {
    "stationcode": "MYL",
    "stationname": "Mc Call Airport",
    "lat": "445323N",
    "long": "1160608W"
  },
  {
    "stationcode": "TAD",
    "stationname": "Las Animas Airport",
    "lat": "371600N",
    "long": "1042000W"
  },
  {
    "stationcode": "OTO",
    "stationname": "Vor Airport",
    "lat": "350400N",
    "long": "1055600W"
  },
  {
    "stationcode": "SHR",
    "stationname": "Sheridan Airport",
    "lat": "444609N",
    "long": "1065849W"
  },
  {
    "stationcode": "RVR",
    "stationname": "Green River Airport",
    "lat": "385800N",
    "long": "1101500W"
  },
  {
    "stationcode": "CNM",
    "stationname": "Carlsbad Airport",
    "lat": "322015N",
    "long": "1041548W"
  },
  {
    "stationcode": "ABQ",
    "stationname": "Albuquerque International Airport",
    "lat": "350226N",
    "long": "1063634W"
  },
  {
    "stationcode": "PGO",
    "stationname": "Stevens Field",
    "lat": "371600N",
    "long": "1070300W"
  },
  {
    "stationcode": "XPR",
    "stationname": "Pine Ridge Airport",
    "lat": "430100N",
    "long": "1023100W"
  },
  {
    "stationcode": "BTM",
    "stationname": "Butte Airport",
    "lat": "455704N",
    "long": "1122938W"
  },
  {
    "stationcode": "GEY",
    "stationname": "South Big Horn County Airport",
    "lat": "443100N",
    "long": "1080500W"
  },
  {
    "stationcode": "GXY",
    "stationname": "Weld County Airport",
    "lat": "402000N",
    "long": "1043700W"
  },
  {
    "stationcode": "GCC",
    "stationname": "Campbell County Airport",
    "lat": "442051N",
    "long": "1053230W"
  },
  {
    "stationcode": "HVE",
    "stationname": "Hanksville Intermediate Airport",
    "lat": "382500N",
    "long": "1104200W"
  },
  {
    "stationcode": "RIW",
    "stationname": "Riverton Airport",
    "lat": "430356N",
    "long": "1082736W"
  },
  {
    "stationcode": "DBS",
    "stationname": "Dubois Airport",
    "lat": "441000N",
    "long": "1121400W"
  },
  {
    "stationcode": "GLD",
    "stationname": "Renner Field",
    "lat": "392200N",
    "long": "1014200W"
  },
  {
    "stationcode": "TEX",
    "stationname": "Telluride Airport",
    "lat": "375710N",
    "long": "1075409W"
  },
  {
    "stationcode": "WBU",
    "stationname": "Boulder Municipal Airport",
    "lat": "400200N",
    "long": "1051400W"
  },
  {
    "stationcode": "CUS",
    "stationname": "Columbus Municipal Airport",
    "lat": "315000N",
    "long": "1073800W"
  },
  {
    "stationcode": "HVR",
    "stationname": "Havre City County Airport",
    "lat": "483239N",
    "long": "1094533W"
  },
  {
    "stationcode": "CNE",
    "stationname": "Canon City Airport",
    "lat": "382500N",
    "long": "1050600W"
  },
  {
    "stationcode": "DIK",
    "stationname": "Dickinson Airport",
    "lat": "464800N",
    "long": "1024800W"
  },
  {
    "stationcode": "ASE",
    "stationname": "Aspen Airport",
    "lat": "391309N",
    "long": "1065202W"
  },
  {
    "stationcode": "NPH",
    "stationname": "Nephi Airport",
    "lat": "394420N",
    "long": "1115215W"
  },
  {
    "stationcode": "HDN",
    "stationname": "Yampa Valley Airport",
    "lat": "402900N",
    "long": "1071325W"
  },
  {
    "stationcode": "TSM",
    "stationname": "Taos Airport",
    "lat": "362800N",
    "long": "1054000W"
  },
  {
    "stationcode": "ALS",
    "stationname": "Alamosa Municipal Airport",
    "lat": "372615N",
    "long": "1055159W"
  },
  {
    "stationcode": "RNG",
    "stationname": "Rangely Airport",
    "lat": "400538N",
    "long": "1084551W"
  },
  {
    "stationcode": "RKS",
    "stationname": "Sweetwater County Airport",
    "lat": "413541N",
    "long": "1090356W"
  },
  {
    "stationcode": "RUI",
    "stationname": "Ruidoso Municipal Airport",
    "lat": "332500N",
    "long": "1053500W"
  },
  {
    "stationcode": "SAA",
    "stationname": "Shively Airport",
    "lat": "412700N",
    "long": "1064800W"
  },
  {
    "stationcode": "HOB",
    "stationname": "Lea County Airport",
    "lat": "324118N",
    "long": "1031254W"
  },
  {
    "stationcode": "KNB",
    "stationname": "Kanab Airport",
    "lat": "371500N",
    "long": "1125000W"
  },
  {
    "stationcode": "PWD",
    "stationname": "Sherwood Airport",
    "lat": "484600N",
    "long": "1043300W"
  },
  {
    "stationcode": "RTN",
    "stationname": "Crews Field",
    "lat": "364400N",
    "long": "1043000W"
  },
  {
    "stationcode": "BTF",
    "stationname": "Salt Lake Skypark",
    "lat": "405218N",
    "long": "1115539W"
  },
  {
    "stationcode": "GGW",
    "stationname": "Glasgow International Airport",
    "lat": "481245N",
    "long": "1063653W"
  },
  {
    "stationcode": "GNT",
    "stationname": "Milan Airport",
    "lat": "350900N",
    "long": "1075200W"
  },
  {
    "stationcode": "SNY",
    "stationname": "Sidney Airport",
    "lat": "410600N",
    "long": "1025900W"
  },
  {
    "stationcode": "THP",
    "stationname": "Hot Springs Airport",
    "lat": "433900N",
    "long": "1081200W"
  },
  {
    "stationcode": "EED",
    "stationname": "Needles Airport",
    "lat": "345100N",
    "long": "1143700W"
  },
  {
    "stationcode": "CLM",
    "stationname": "Fairchild International Airport",
    "lat": "480703N",
    "long": "1232945W"
  },
  {
    "stationcode": "BFI",
    "stationname": "Boeing Field International Airport",
    "lat": "473148N",
    "long": "1221807W"
  },
  {
    "stationcode": "BYW",
    "stationname": "Blakely Island Airport",
    "lat": "483500N",
    "long": "1225000W"
  },
  {
    "stationcode": "BUR",
    "stationname": "Bob Hope Airport",
    "lat": "341202N",
    "long": "1182131W"
  },
  {
    "stationcode": "SBT",
    "stationname": "Tri-City Airport",
    "lat": "711504N",
    "long": "0720606E"
  },
  {
    "stationcode": "WJF",
    "stationname": "William J Fox Airport",
    "lat": "344428N",
    "long": "1181307W"
  },
  {
    "stationcode": "BWS",
    "stationname": "Blaine Airport",
    "lat": "485800N",
    "long": "1225000W"
  },
  {
    "stationcode": "CCB",
    "stationname": "Cable Airport",
    "lat": "340500N",
    "long": "1173800W"
  },
  {
    "stationcode": "PWT",
    "stationname": "Polacca Airport",
    "lat": "472900N",
    "long": "1224500W"
  },
  {
    "stationcode": "JMC",
    "stationname": "Marin County Airport",
    "lat": "375100N",
    "long": "1223110W"
  },
  {
    "stationcode": "GOL",
    "stationname": "State Airport",
    "lat": "422500N",
    "long": "1242500W"
  },
  {
    "stationcode": "UIL",
    "stationname": "Quillayute State Airport",
    "lat": "475700N",
    "long": "1243400W"
  },
  {
    "stationcode": "EUG",
    "stationname": "Eugene Airport",
    "lat": "440729N",
    "long": "1231243W"
  },
  {
    "stationcode": "MWH",
    "stationname": "Grant County Airport",
    "lat": "471217N",
    "long": "1191900W"
  },
  {
    "stationcode": "APV",
    "stationname": "Apple Valley Airport",
    "lat": "343442N",
    "long": "1171112W"
  },
  {
    "stationcode": "PFC",
    "stationname": "State Airport",
    "lat": "451200N",
    "long": "1235700W"
  },
  {
    "stationcode": "DRA",
    "stationname": "Desert Rock Airport",
    "lat": "364000N",
    "long": "1155900W"
  },
  {
    "stationcode": "COA",
    "stationname": "Columbia Airport",
    "lat": "380200N",
    "long": "1202500W"
  },
  {
    "stationcode": "ROF",
    "stationname": "Yreka Rohrer Field",
    "lat": "414349N",
    "long": "1223244W"
  },
  {
    "stationcode": "OTK",
    "stationname": "Tillamook Airport",
    "lat": "422505N",
    "long": "1234851W"
  },
  {
    "stationcode": "AST",
    "stationname": "Astoria Airport",
    "lat": "460933N",
    "long": "1235252W"
  },
  {
    "stationcode": "WLW",
    "stationname": "Glenn County Airport",
    "lat": "393100N",
    "long": "1221300W"
  },
  {
    "stationcode": "RKC",
    "stationname": "Yreka Airport",
    "lat": "414400N",
    "long": "1223800W"
  },
  {
    "stationcode": "PSC",
    "stationname": "Tri-Cities Airport",
    "lat": "461600N",
    "long": "1190700W"
  },
  {
    "stationcode": "TWH",
    "stationname": "Two Harbors Airport",
    "lat": "332530N",
    "long": "1183030W"
  },
  {
    "stationcode": "NRC",
    "stationname": "Aux Field",
    "lat": "372400N",
    "long": "1210400W"
  },
  {
    "stationcode": "SDM",
    "stationname": "Brown Field Municipal Airport",
    "lat": "323400N",
    "long": "1165900W"
  },
  {
    "stationcode": "SHN",
    "stationname": "Sanderson Field",
    "lat": "471430N",
    "long": "1230800W"
  },
  {
    "stationcode": "NUW",
    "stationname": "Ault Field",
    "lat": "481500N",
    "long": "1224000W"
  },
  {
    "stationcode": "LPS",
    "stationname": "Lopez Island Airport",
    "lat": "482900N",
    "long": "1225616W"
  },
  {
    "stationcode": "CJN",
    "stationname": "El Cajon Airport",
    "lat": "074314S",
    "long": "1082924E"
  },
  {
    "stationcode": "FRD",
    "stationname": "Friday Harbor Airport",
    "lat": "483132N",
    "long": "1230134W"
  },
  {
    "stationcode": "MVW",
    "stationname": "Skagit Regional Airport",
    "lat": "482500N",
    "long": "1222000W"
  },
  {
    "stationcode": "POC",
    "stationname": "Brackett Field",
    "lat": "340529N",
    "long": "1174656W"
  },
  {
    "stationcode": "RCE",
    "stationname": "Roche Harbor Airport",
    "lat": "483641N",
    "long": "1230829W"
  },
  {
    "stationcode": "HMT",
    "stationname": "Ryan Field",
    "lat": "334500N",
    "long": "1165800W"
  },
  {
    "stationcode": "LMT",
    "stationname": "Kingsley Field",
    "lat": "420947N",
    "long": "1214414W"
  },
  {
    "stationcode": "CLR",
    "stationname": "Calipatria Airport",
    "lat": "330800N",
    "long": "1153100W"
  },
  {
    "stationcode": "BNO",
    "stationname": "Burns Airport",
    "lat": "433500N",
    "long": "1185700W"
  },
  {
    "stationcode": "MFR",
    "stationname": "Jackson County Airport",
    "lat": "422225N",
    "long": "1225220W"
  },
  {
    "stationcode": "SZP",
    "stationname": "Santa Paula Airport",
    "lat": "342049N",
    "long": "1190342W"
  },
  {
    "stationcode": "BLH",
    "stationname": "Blythe Airport",
    "lat": "333700N",
    "long": "1144300W"
  },
  {
    "stationcode": "SNS",
    "stationname": "Salinas Airport",
    "lat": "363956N",
    "long": "1213628W"
  },
  {
    "stationcode": "VLO",
    "stationname": "Stolport Airport",
    "lat": "380700N",
    "long": "1221400W"
  },
  {
    "stationcode": "CVO",
    "stationname": "Albany Airport",
    "lat": "443000N",
    "long": "1231700W"
  },
  {
    "stationcode": "NOT",
    "stationname": "Novato Airport",
    "lat": "380837N",
    "long": "1223322W"
  },
  {
    "stationcode": "LAX",
    "stationname": "Los Angeles International Airport",
    "lat": "335633N",
    "long": "1182429W"
  },
  {
    "stationcode": "SCK",
    "stationname": "Stockton Airport",
    "lat": "375339N",
    "long": "1211418W"
  },
  {
    "stationcode": "ESD",
    "stationname": "Orcas Island Airport",
    "lat": "484227N",
    "long": "1225439W"
  },
  {
    "stationcode": "DTH",
    "stationname": "Death Valley Airport",
    "lat": "363000N",
    "long": "1170000W"
  },
  {
    "stationcode": "RHV",
    "stationname": "Reid-Hillview Airport",
    "lat": "372154N",
    "long": "1215555W"
  },
  {
    "stationcode": "CVR",
    "stationname": "Hughes Airport",
    "lat": "411300N",
    "long": "0862500W"
  },
  {
    "stationcode": "OYS",
    "stationname": "Yosemite National Park Airport",
    "lat": "375100N",
    "long": "1193300W"
  },
  {
    "stationcode": "FUL",
    "stationname": "Fullerton Municipal Airport",
    "lat": "335220N",
    "long": "1175847W"
  },
  {
    "stationcode": "SFR",
    "stationname": "San Fernando Airport",
    "lat": "341600N",
    "long": "1182600W"
  },
  {
    "stationcode": "WSX",
    "stationname": "Westsound Airport",
    "lat": "483708N",
    "long": "1225727W"
  },
  {
    "stationcode": "JWC",
    "stationname": "Warner Center Business Plaza Heliport",
    "lat": "335630N",
    "long": "1182400W"
  },
  {
    "stationcode": "OAK",
    "stationname": "Metropolitan Oakland International Airport",
    "lat": "374317N",
    "long": "1221315W"
  },
  {
    "stationcode": "UKI",
    "stationname": "Ukiah Airport",
    "lat": "390736N",
    "long": "1231200W"
  },
  {
    "stationcode": "SEA",
    "stationname": "Seattle/Tacoma International Airport",
    "lat": "472656N",
    "long": "1221834W"
  },
  {
    "stationcode": "ASQ",
    "stationname": "Austin Airport",
    "lat": "393000N",
    "long": "1170400W"
  },
  {
    "stationcode": "FMU",
    "stationname": "Florence Municipal",
    "lat": "435858N",
    "long": "1240640W"
  },
  {
    "stationcode": "SQL",
    "stationname": "San Carlos Airport",
    "lat": "373046N",
    "long": "1221502W"
  },
  {
    "stationcode": "OTS",
    "stationname": "Anacortes Airport",
    "lat": "483000N",
    "long": "1223700W"
  },
  {
    "stationcode": "SJC",
    "stationname": "San Jose Municipal Airport",
    "lat": "372145N",
    "long": "1215544W"
  },
  {
    "stationcode": "BIH",
    "stationname": "Bishop Airport",
    "lat": "372225N",
    "long": "1182155W"
  },
  {
    "stationcode": "HHR",
    "stationname": "Hawthorne Airport",
    "lat": "335522N",
    "long": "1182005W"
  },
  {
    "stationcode": "TTD",
    "stationname": "Troutdale Airport",
    "lat": "453200N",
    "long": "1222300W"
  },
  {
    "stationcode": "SQV",
    "stationname": "Sequim Valley Airport",
    "lat": "480500N",
    "long": "1230600W"
  },
  {
    "stationcode": "PDT",
    "stationname": "Pendleton Airport",
    "lat": "454143N",
    "long": "1185013W"
  },
  {
    "stationcode": "BTY",
    "stationname": "Beatty Airport",
    "lat": "365110N",
    "long": "1164700W"
  },
  {
    "stationcode": "ACV",
    "stationname": "Arcata Airport",
    "lat": "405841N",
    "long": "1240631W"
  },
  {
    "stationcode": "NUQ",
    "stationname": "Moffett Field",
    "lat": "372300N",
    "long": "1220400W"
  },
  {
    "stationcode": "ODC",
    "stationname": "Oakdale Airport",
    "lat": "374522N",
    "long": "1204800W"
  },
  {
    "stationcode": "JDA",
    "stationname": "John Day Airport",
    "lat": "442500N",
    "long": "1185700W"
  },
  {
    "stationcode": "ESW",
    "stationname": "State Airport",
    "lat": "471400N",
    "long": "1211100W"
  },
  {
    "stationcode": "EAT",
    "stationname": "Pangborn Field",
    "lat": "472356N",
    "long": "1201226W"
  },
  {
    "stationcode": "TPH",
    "stationname": "Tonopah Airport",
    "lat": "380400N",
    "long": "1171400W"
  },
  {
    "stationcode": "EUE",
    "stationname": "Eureka Airport",
    "lat": "393100N",
    "long": "1155800W"
  },
  {
    "stationcode": "EMB",
    "stationname": "Embarkadero Airport",
    "lat": "373731N",
    "long": "1222230W"
  },
  {
    "stationcode": "MCC",
    "stationname": "McClellan Airport",
    "lat": "384010N",
    "long": "1212400W"
  },
  {
    "stationcode": "IDH",
    "stationname": "Idaho County Airport",
    "lat": "455633N",
    "long": "1160724W"
  },
  {
    "stationcode": "CPM",
    "stationname": "Compton Airport",
    "lat": "335400N",
    "long": "1181300W"
  },
  {
    "stationcode": "OVE",
    "stationname": "Oroville Airport",
    "lat": "392900N",
    "long": "1213800W"
  },
  {
    "stationcode": "IYK",
    "stationname": "Kern County Airport",
    "lat": "353917N",
    "long": "1174944W"
  },
  {
    "stationcode": "LVK",
    "stationname": "Livermore Airport",
    "lat": "374100N",
    "long": "1214600W"
  },
  {
    "stationcode": "DHB",
    "stationname": "Deer Harbor Airport",
    "lat": "483715N",
    "long": "1230011W"
  },
  {
    "stationcode": "PAO",
    "stationname": "Palo Alto Airport",
    "lat": "372742N",
    "long": "1220657W"
  },
  {
    "stationcode": "SNA",
    "stationname": "John Wayne Airport",
    "lat": "334032N",
    "long": "1175201W"
  },
  {
    "stationcode": "KIC",
    "stationname": "Mesa Del Rey Airport",
    "lat": "361341N",
    "long": "1210715W"
  },
  {
    "stationcode": "WVI",
    "stationname": "Watsonville Airport",
    "lat": "365603N",
    "long": "1214731W"
  },
  {
    "stationcode": "RIR",
    "stationname": "Riverside Fla-Bob Airport",
    "lat": "335900N",
    "long": "1172400W"
  },
  {
    "stationcode": "PVF",
    "stationname": "Placerville Airport",
    "lat": "384300N",
    "long": "1204800W"
  },
  {
    "stationcode": "SMF",
    "stationname": "Sacramento Metropolitan Airport",
    "lat": "384144N",
    "long": "1213527W"
  },
  {
    "stationcode": "LPC",
    "stationname": "Lompoc Airport",
    "lat": "344000N",
    "long": "1202810W"
  },
  {
    "stationcode": "MMH",
    "stationname": "Mammoth Lakes Airport",
    "lat": "373728N",
    "long": "1185026W"
  },
  {
    "stationcode": "HAF",
    "stationname": "Half Moon Airport",
    "lat": "372900N",
    "long": "1222800W"
  },
  {
    "stationcode": "SUO",
    "stationname": "Sun River Airport",
    "lat": "435500N",
    "long": "1213000W"
  },
  {
    "stationcode": "COE",
    "stationname": "Coeur D'Alene Airport",
    "lat": "474600N",
    "long": "1164900W"
  },
  {
    "stationcode": "BFL",
    "stationname": "Meadows Field",
    "lat": "352601N",
    "long": "1190324W"
  },
  {
    "stationcode": "AHM",
    "stationname": "Summer Parker Field Airport",
    "lat": "421125N",
    "long": "1223938W"
  },
  {
    "stationcode": "SEE",
    "stationname": "Gillespie Field",
    "lat": "324940N",
    "long": "1165821W"
  },
  {
    "stationcode": "PYS",
    "stationname": "Skypark Airport",
    "lat": "394238N",
    "long": "1203659W"
  },
  {
    "stationcode": "RLD",
    "stationname": "Richland Airport",
    "lat": "461823N",
    "long": "1191815W"
  },
  {
    "stationcode": "EMT",
    "stationname": "El Monte Airport",
    "lat": "340600N",
    "long": "1180200W"
  },
  {
    "stationcode": "HLI",
    "stationname": "Hollister Airport",
    "lat": "365100N",
    "long": "1212400W"
  },
  {
    "stationcode": "UCC",
    "stationname": "Yucca Flat Airport",
    "lat": "370000N",
    "long": "1160300W"
  },
  {
    "stationcode": "WHP",
    "stationname": "Whiteman Airport",
    "lat": "341534N",
    "long": "1182447W"
  },
  {
    "stationcode": "XSD",
    "stationname": "Test Range Airport",
    "lat": "380300N",
    "long": "1170500W"
  },
  {
    "stationcode": "LSN",
    "stationname": "Los Banos Airport",
    "lat": "370400N",
    "long": "1205100W"
  },
  {
    "stationcode": "GEG",
    "stationname": "Spokane International Airport",
    "lat": "473702N",
    "long": "1173224W"
  },
  {
    "stationcode": "EPH",
    "stationname": "Ephrata Airport",
    "lat": "471800N",
    "long": "1193100W"
  },
  {
    "stationcode": "EYR",
    "stationname": "Yerington Airport",
    "lat": "390014N",
    "long": "1190928W"
  },
  {
    "stationcode": "SBP",
    "stationname": "County Airport",
    "lat": "351414N",
    "long": "1203827W"
  },
  {
    "stationcode": "TRH",
    "stationname": "Trona Airport",
    "lat": "354845N",
    "long": "1171935W"
  },
  {
    "stationcode": "CZZ",
    "stationname": "Campo Airport",
    "lat": "370600N",
    "long": "1023500W"
  },
  {
    "stationcode": "RDD",
    "stationname": "Redding Airport",
    "lat": "403030N",
    "long": "1221732W"
  },
  {
    "stationcode": "MAE",
    "stationname": "Madera Airport",
    "lat": "365900N",
    "long": "1200700W"
  },
  {
    "stationcode": "FLX",
    "stationname": "Fallon Municipal Airport",
    "lat": "392800N",
    "long": "1184610W"
  },
  {
    "stationcode": "PAE",
    "stationname": "Snohomish County Airport",
    "lat": "475510N",
    "long": "1221701W"
  },
  {
    "stationcode": "OMK",
    "stationname": "Omak Municipal Airport",
    "lat": "482400N",
    "long": "1193200W"
  },
  {
    "stationcode": "RNT",
    "stationname": "Renton Airport",
    "lat": "473144N",
    "long": "1221805W"
  },
  {
    "stationcode": "MHS",
    "stationname": "Rep Airport",
    "lat": "411900N",
    "long": "1221900W"
  },
  {
    "stationcode": "RBK",
    "stationname": "French Valley Airport",
    "lat": "333437N",
    "long": "1170745W"
  },
  {
    "stationcode": "MRY",
    "stationname": "Monterey Peninsula Airport",
    "lat": "363517N",
    "long": "1215053W"
  },
  {
    "stationcode": "MIT",
    "stationname": "Kern County Airport",
    "lat": "353000N",
    "long": "1191600W"
  },
  {
    "stationcode": "LGB",
    "stationname": "Long Beach Municipal Airport",
    "lat": "334904N",
    "long": "1180906W"
  },
  {
    "stationcode": "SZN",
    "stationname": "Santa Cruz Island Airport",
    "lat": "365800N",
    "long": "1220100W"
  },
  {
    "stationcode": "SBA",
    "stationname": "Santa Barbara Municipal Airport",
    "lat": "342534N",
    "long": "1195025W"
  },
  {
    "stationcode": "SBD",
    "stationname": "San Bernardino International Airport",
    "lat": "340545N",
    "long": "1171410W"
  },
  {
    "stationcode": "BWC",
    "stationname": "Brawley Airport",
    "lat": "325900N",
    "long": "1153100W"
  },
  {
    "stationcode": "MHR",
    "stationname": "Mather Airport",
    "lat": "383318N",
    "long": "1211747W"
  },
  {
    "stationcode": "YKM",
    "stationname": "Yakima Air Terminal",
    "lat": "463405N",
    "long": "1203227W"
  },
  {
    "stationcode": "RZH",
    "stationname": "Quartz Hill Airport",
    "lat": "344000N",
    "long": "1180000W"
  },
  {
    "stationcode": "SAC",
    "stationname": "Executive Airport",
    "lat": "383100N",
    "long": "1213000W"
  },
  {
    "stationcode": "RBG",
    "stationname": "Roseburg Municipal Airport",
    "lat": "431400N",
    "long": "1232100W"
  },
  {
    "stationcode": "CSN",
    "stationname": "Carson City Airport",
    "lat": "391200N",
    "long": "1194400W"
  },
  {
    "stationcode": "PTV",
    "stationname": "Porterville Airport",
    "lat": "360200N",
    "long": "1190400W"
  },
  {
    "stationcode": "PRZ",
    "stationname": "Prineville Airport",
    "lat": "441800N",
    "long": "1205100W"
  },
  {
    "stationcode": "MCE",
    "stationname": "Merced Municipal Airport",
    "lat": "371700N",
    "long": "1203100W"
  },
  {
    "stationcode": "ONP",
    "stationname": "Newport Airport",
    "lat": "443500N",
    "long": "1240300W"
  },
  {
    "stationcode": "HQM",
    "stationname": "Bowerman Airport",
    "lat": "465800N",
    "long": "1235600W"
  },
  {
    "stationcode": "SLE",
    "stationname": "McNary Field",
    "lat": "445434N",
    "long": "1230009W"
  },
  {
    "stationcode": "CHZ",
    "stationname": "State Airport",
    "lat": "423500N",
    "long": "1215200W"
  },
  {
    "stationcode": "CXL",
    "stationname": "Calexico International Airport",
    "lat": "324000N",
    "long": "1153100W"
  },
  {
    "stationcode": "BXS",
    "stationname": "Borrego Springs Airport",
    "lat": "331500N",
    "long": "1161900W"
  },
  {
    "stationcode": "PSP",
    "stationname": "Palm Springs Municipal Airport",
    "lat": "334947N",
    "long": "1163024W"
  },
  {
    "stationcode": "SQA",
    "stationname": "Santa Ynez Airport",
    "lat": "343626N",
    "long": "1200438W"
  },
  {
    "stationcode": "HES",
    "stationname": "State Airport",
    "lat": "455100N",
    "long": "1191700W"
  },
  {
    "stationcode": "SVE",
    "stationname": "Susanville Airport",
    "lat": "402400N",
    "long": "1203900W"
  },
  {
    "stationcode": "TRM",
    "stationname": "Thermal Airport",
    "lat": "333715N",
    "long": "1160931W"
  },
  {
    "stationcode": "CLG",
    "stationname": "Coalinga Airport",
    "lat": "360900N",
    "long": "1202100W"
  },
  {
    "stationcode": "RAL",
    "stationname": "Riverside Municipal Airport",
    "lat": "335700N",
    "long": "1172600W"
  },
  {
    "stationcode": "OXR",
    "stationname": "Oxnard Airport",
    "lat": "341200N",
    "long": "1191200W"
  },
  {
    "stationcode": "PUW",
    "stationname": "Moscow Regional Airport",
    "lat": "464437N",
    "long": "1170640W"
  },
  {
    "stationcode": "CKR",
    "stationname": "Crane Island Airport",
    "lat": "483553N",
    "long": "1225953W"
  },
  {
    "stationcode": "RBL",
    "stationname": "Red Bluff FSS",
    "lat": "400900N",
    "long": "1221500W"
  },
  {
    "stationcode": "GAB",
    "stationname": "Gabbs Airport",
    "lat": "385200N",
    "long": "1175500W"
  },
  {
    "stationcode": "SRF",
    "stationname": "Hamilton Field",
    "lat": "380100N",
    "long": "1223100W"
  },
  {
    "stationcode": "HIO",
    "stationname": "Portland Airport",
    "lat": "453217N",
    "long": "1225651W"
  },
  {
    "stationcode": "SSW",
    "stationname": "Stuart Island Airport",
    "lat": "484200N",
    "long": "1231200W"
  },
  {
    "stationcode": "ANA",
    "stationname": "Anaheim Airport",
    "lat": "334900N",
    "long": "1175600W"
  },
  {
    "stationcode": "LAS",
    "stationname": "McCarran International Airport",
    "lat": "360448N",
    "long": "1150908W"
  },
  {
    "stationcode": "INS",
    "stationname": "Af Aux Airport",
    "lat": "363400N",
    "long": "1154000W"
  },
  {
    "stationcode": "VIS",
    "stationname": "Visalia Airport",
    "lat": "361916N",
    "long": "1192345W"
  },
  {
    "stationcode": "SFF",
    "stationname": "Felts Field",
    "lat": "474059N",
    "long": "1171921W"
  },
  {
    "stationcode": "CIC",
    "stationname": "Chico Airport",
    "lat": "394746N",
    "long": "1215130W"
  },
  {
    "stationcode": "MYF",
    "stationname": "Montgomery Field",
    "lat": "324856N",
    "long": "1170818W"
  },
  {
    "stationcode": "MYV",
    "stationname": "Yuba County Airport",
    "lat": "390600N",
    "long": "1213400W"
  },
  {
    "stationcode": "NVN",
    "stationname": "Nervino Airport",
    "lat": "394906N",
    "long": "1202110W"
  },
  {
    "stationcode": "CRO",
    "stationname": "Corcoran Airport",
    "lat": "360600N",
    "long": "1193300W"
  },
  {
    "stationcode": "MFH",
    "stationname": "Mesquite Airport",
    "lat": "365010N",
    "long": "1140330W"
  },
  {
    "stationcode": "LKV",
    "stationname": "Lake County Airport",
    "lat": "421000N",
    "long": "1202400W"
  },
  {
    "stationcode": "ELN",
    "stationname": "Bowers Field",
    "lat": "470200N",
    "long": "1203200W"
  },
  {
    "stationcode": "FCH",
    "stationname": "Fresno-Chandler Airport",
    "lat": "364359N",
    "long": "1194914W"
  },
  {
    "stationcode": "SMX",
    "stationname": "Santa Maria Public  Airport",
    "lat": "345356N",
    "long": "1202727W"
  },
  {
    "stationcode": "MEV",
    "stationname": "Douglas County Airport",
    "lat": "390000N",
    "long": "1194500W"
  },
  {
    "stationcode": "DLS",
    "stationname": "The Dalles Airport",
    "lat": "453600N",
    "long": "1211000W"
  },
  {
    "stationcode": "ONO",
    "stationname": "Ontario Airport",
    "lat": "440100N",
    "long": "1170100W"
  },
  {
    "stationcode": "NLN",
    "stationname": "Kneeland Airport",
    "lat": "404309N",
    "long": "1235539W"
  },
  {
    "stationcode": "KEH",
    "stationname": "Kenmore Air Harbor Airport",
    "lat": "474520N",
    "long": "1221534W"
  },
  {
    "stationcode": "FOB",
    "stationname": "Fort Bragg Airport",
    "lat": "392600N",
    "long": "1234800W"
  },
  {
    "stationcode": "BOK",
    "stationname": "Brookings State Airport",
    "lat": "420300N",
    "long": "1241700W"
  },
  {
    "stationcode": "BNG",
    "stationname": "Banning Airport",
    "lat": "335600N",
    "long": "1165200W"
  },
  {
    "stationcode": "PRB",
    "stationname": "Paso Robles Airport",
    "lat": "354000N",
    "long": "1203800W"
  },
  {
    "stationcode": "WMC",
    "stationname": "Winnemucca Airport",
    "lat": "405800N",
    "long": "1174400W"
  },
  {
    "stationcode": "VNY",
    "stationname": "Van Nuys Airport",
    "lat": "341222N",
    "long": "1182925W"
  },
  {
    "stationcode": "BKE",
    "stationname": "Baker Airport",
    "lat": "445000N",
    "long": "1174900W"
  },
  {
    "stationcode": "LOL",
    "stationname": "Derby Field",
    "lat": "400330N",
    "long": "1183400W"
  },
  {
    "stationcode": "TVL",
    "stationname": "South Lake Tahoe Airport",
    "lat": "385341N",
    "long": "1195943W"
  },
  {
    "stationcode": "RBF",
    "stationname": "Big Bear City Airport",
    "lat": "341600N",
    "long": "1165100W"
  },
  {
    "stationcode": "CLD",
    "stationname": "McClellan-Palomar Airport",
    "lat": "330742N",
    "long": "1171648W"
  },
  {
    "stationcode": "CEC",
    "stationname": "Mc Namara Field",
    "lat": "414649N",
    "long": "1241412W"
  },
  {
    "stationcode": "BDY",
    "stationname": "State Airport",
    "lat": "430700N",
    "long": "1242500W"
  },
  {
    "stationcode": "LOG",
    "stationname": "Longview Airport",
    "lat": "460700N",
    "long": "1225300W"
  },
  {
    "stationcode": "BLU",
    "stationname": "Blue Canyon Airport",
    "lat": "391600N",
    "long": "1204200W"
  },
  {
    "stationcode": "ONT",
    "stationname": "Ontario International Airport",
    "lat": "340322N",
    "long": "1173604W"
  },
  {
    "stationcode": "PDX",
    "stationname": "Portland International Airport",
    "lat": "453519N",
    "long": "1223551W"
  },
  {
    "stationcode": "GNF",
    "stationname": "Gansner Field Airport",
    "lat": "395638N",
    "long": "1205643W"
  },
  {
    "stationcode": "RMY",
    "stationname": "Mariposa-Yosemite",
    "lat": "373039N",
    "long": "1200222W"
  },
  {
    "stationcode": "VGT",
    "stationname": "North Air Terminal",
    "lat": "361233N",
    "long": "1151144W"
  },
  {
    "stationcode": "CKE",
    "stationname": "Clear Lake Airport",
    "lat": "390200N",
    "long": "1225000W"
  },
  {
    "stationcode": "SRU",
    "stationname": "Santa Cruz Skypark",
    "lat": "225621N",
    "long": "0933656E"
  },
  {
    "stationcode": "FAT",
    "stationname": "Fresno Yosemite Int'l",
    "lat": "364634N",
    "long": "1194305W"
  },
  {
    "stationcode": "MOD",
    "stationname": "Modesto Municipal Airport",
    "lat": "373736N",
    "long": "1205715W"
  },
  {
    "stationcode": "SAS",
    "stationname": "Salton City Airport",
    "lat": "331900N",
    "long": "1155900W"
  },
  {
    "stationcode": "TKF",
    "stationname": "Truckee Airport",
    "lat": "391906N",
    "long": "1200818W"
  },
  {
    "stationcode": "TOA",
    "stationname": "Torrance Airport",
    "lat": "334700N",
    "long": "1181500W"
  },
  {
    "stationcode": "BLD",
    "stationname": "Boulder City Airport",
    "lat": "355647N",
    "long": "1145140W"
  },
  {
    "stationcode": "HTH",
    "stationname": "Hawthorne Airport",
    "lat": "383300N",
    "long": "1183800W"
  },
  {
    "stationcode": "PMD",
    "stationname": "LA/Palmdale Regional Airport",
    "lat": "343746N",
    "long": "1180504W"
  },
  {
    "stationcode": "TWD",
    "stationname": "Port Townsend Airport",
    "lat": "480700N",
    "long": "1224600W"
  },
  {
    "stationcode": "STS",
    "stationname": "Sonoma County Airport",
    "lat": "383038N",
    "long": "1224837W"
  },
  {
    "stationcode": "TLR",
    "stationname": "Tulare Airport",
    "lat": "362000N",
    "long": "1191800W"
  },
  {
    "stationcode": "UDD",
    "stationname": "Bermuda Dunes Airport",
    "lat": "334458N",
    "long": "1161638W"
  },
  {
    "stationcode": "RDM",
    "stationname": "Roberts Field",
    "lat": "441608N",
    "long": "1211101W"
  },
  {
    "stationcode": "REO",
    "stationname": "State Airport",
    "lat": "423526N",
    "long": "1175202W"
  },
  {
    "stationcode": "CCD",
    "stationname": "Century City Airport",
    "lat": "340400N",
    "long": "1181600W"
  },
  {
    "stationcode": "LTH",
    "stationname": "Lathrop Wells Airport",
    "lat": "363800N",
    "long": "1162300W"
  },
  {
    "stationcode": "MER",
    "stationname": "Castle Airport",
    "lat": "372247N",
    "long": "1203402W"
  },
  {
    "stationcode": "RNO",
    "stationname": "Reno/Tahoe International Airport",
    "lat": "392952N",
    "long": "1194603W"
  },
  {
    "stationcode": "TSP",
    "stationname": "Kern County Airport",
    "lat": "350808N",
    "long": "1182624W"
  },
  {
    "stationcode": "BZF",
    "stationname": "Benton Field Airport",
    "lat": "403430N",
    "long": "1222429W"
  },
  {
    "stationcode": "OLM",
    "stationname": "Olympia Airport",
    "lat": "465803N",
    "long": "1225419W"
  },
  {
    "stationcode": "TNP",
    "stationname": "Twentynine Palms Airport",
    "lat": "340800N",
    "long": "1160300W"
  },
  {
    "stationcode": "BLI",
    "stationname": "Bellingham Airport",
    "lat": "484734N",
    "long": "1223215W"
  },
  {
    "stationcode": "ALW",
    "stationname": "Walla Walla Airport",
    "lat": "460542N",
    "long": "1181716W"
  },
  {
    "stationcode": "TIW",
    "stationname": "Industrial Airport",
    "lat": "471559N",
    "long": "1223400W"
  },
  {
    "stationcode": "EKA",
    "stationname": "Murray Field",
    "lat": "404800N",
    "long": "1240700W"
  },
  {
    "stationcode": "SXC",
    "stationname": "Avalon Vor/WP Airport",
    "lat": "333000N",
    "long": "1182500W"
  },
  {
    "stationcode": "AUN",
    "stationname": "Auburn Airport",
    "lat": "385400N",
    "long": "1210400W"
  },
  {
    "stationcode": "CCR",
    "stationname": "Buchanan Field",
    "lat": "375931N",
    "long": "1220321W"
  },
  {
    "stationcode": "AVX",
    "stationname": "Avalon Bay Airport",
    "lat": "332418N",
    "long": "1182458W"
  },
  {
    "stationcode": "BAM",
    "stationname": "Lander County Airport",
    "lat": "403800N",
    "long": "1165600W"
  },
  {
    "stationcode": "OCN",
    "stationname": "Oceanside Municipal Airport",
    "lat": "331302N",
    "long": "1172110W"
  },
  {
    "stationcode": "SAN",
    "stationname": "San Diego International Airport",
    "lat": "324401N",
    "long": "1171123W"
  },
  {
    "stationcode": "JBK",
    "stationname": "Berkeley Airport",
    "lat": "375200N",
    "long": "1221800W"
  },
  {
    "stationcode": "VSK",
    "stationname": "Vista Field Airport",
    "lat": "461306N",
    "long": "1191236W"
  },
  {
    "stationcode": "LWS",
    "stationname": "Nez Perce County Regional Airport",
    "lat": "462232N",
    "long": "1170049W"
  },
  {
    "stationcode": "HSH",
    "stationname": "Henderson Sky Harbor Airport",
    "lat": "355806N",
    "long": "1150709W"
  },
  {
    "stationcode": "CIB",
    "stationname": "Ap In The Sky Airport",
    "lat": "332422N",
    "long": "1182450W"
  },
  {
    "stationcode": "PUL",
    "stationname": "Poulsbo Airport",
    "lat": "474400N",
    "long": "1223900W"
  },
  {
    "stationcode": "ODW",
    "stationname": "Oak Harbor Airport",
    "lat": "481506N",
    "long": "1224026W"
  },
  {
    "stationcode": "MHV",
    "stationname": "Kern County Airport",
    "lat": "350333N",
    "long": "1180853W"
  },
  {
    "stationcode": "SIY",
    "stationname": "Siskiyou County Airport",
    "lat": "414653N",
    "long": "1222759W"
  },
  {
    "stationcode": "DTR",
    "stationname": "Decatur Island Airport",
    "lat": "483100N",
    "long": "1225000W"
  },
  {
    "stationcode": "DAG",
    "stationname": "Barstow-Daggett Airport",
    "lat": "345100N",
    "long": "1164700W"
  },
  {
    "stationcode": "OTH",
    "stationname": "North Bend Airport",
    "lat": "432502N",
    "long": "1241446W"
  },
  {
    "stationcode": "SMO",
    "stationname": "Santa Monica Airport",
    "lat": "340054N",
    "long": "1182706W"
  },
  {
    "stationcode": "LGD",
    "stationname": "La Grande Airport",
    "lat": "451900N",
    "long": "1180200W"
  },
  {
    "stationcode": "IPL",
    "stationname": "Imperial County Airport",
    "lat": "325006N",
    "long": "1153429W"
  },
  {
    "stationcode": "GCD",
    "stationname": "Grand Coulee Dam",
    "lat": "475525N",
    "long": "1190450W"
  },
  {
    "stationcode": "EKO",
    "stationname": "Elko Airport",
    "lat": "404939N",
    "long": "1154653W"
  },
  {
    "stationcode": "TDO",
    "stationname": "Winlock Airport",
    "lat": "462600N",
    "long": "1225100W"
  },
  {
    "stationcode": "CNO",
    "stationname": "Chino Airport",
    "lat": "340100N",
    "long": "1174200W"
  },
  {
    "stationcode": "APC",
    "stationname": "Napa County Airport",
    "lat": "381248N",
    "long": "1221648W"
  },
  {
    "stationcode": "KPT",
    "stationname": "Jackpot Airport",
    "lat": "415800N",
    "long": "1144000W"
  },
  {
    "stationcode": "HWD",
    "stationname": "Hayward Air Terminal",
    "lat": "373933N",
    "long": "1220722W"
  },
  {
    "stationcode": "GTP",
    "stationname": "Grants Pass Airport",
    "lat": "423036N",
    "long": "1232316W"
  },
  {
    "stationcode": "KLS",
    "stationname": "Longview Airport",
    "lat": "460900N",
    "long": "1225400W"
  },
  {
    "stationcode": "SFO",
    "stationname": "San Francisco International Airport",
    "lat": "373708N",
    "long": "1222330W"
  },
  {
    "stationcode": "WDN",
    "stationname": "Waldron Island Airport",
    "lat": "484300N",
    "long": "1230200W"
  },
  {
    "stationcode": "LWL",
    "stationname": "Harriet Field",
    "lat": "410700N",
    "long": "1145800W"
  },
  {
    "stationcode": "MDJ",
    "stationname": "Madris City-County Airport",
    "lat": "443800N",
    "long": "1210800W"
  },
  {
    "stationcode": "CLS",
    "stationname": "Centralia Airport",
    "lat": "464100N",
    "long": "1225900W"
  },
  {
    "stationcode": "CWS",
    "stationname": "Center Island Airport",
    "lat": "472906N",
    "long": "1224953W"
  },
  {
    "stationcode": "CZK",
    "stationname": "Cascade Locks/Stevens Airport",
    "lat": "454000N",
    "long": "1215400W"
  },
  {
    "stationcode": "ELY",
    "stationname": "Yelland Airport",
    "lat": "391800N",
    "long": "1145100W"
  },
  {
    "stationcode": "NME",
    "stationname": "Nightmute Airport",
    "lat": "602820N",
    "long": "1644157W"
  },
  {
    "stationcode": "TLJ",
    "stationname": "Tatalina Airport",
    "lat": "625344N",
    "long": "1555840W"
  },
  {
    "stationcode": "FXM",
    "stationname": "Flaxman Island Airport",
    "lat": "701200N",
    "long": "1460000W"
  },
  {
    "stationcode": "PRF",
    "stationname": "Port Johnson Airport",
    "lat": "550800N",
    "long": "1320000W"
  },
  {
    "stationcode": "DRG",
    "stationname": "Deering Airport",
    "lat": "660410N",
    "long": "1624559W"
  },
  {
    "stationcode": "KGZ",
    "stationname": "Glacier Creek Airport",
    "lat": "612700N",
    "long": "1422400W"
  },
  {
    "stationcode": "CDV",
    "stationname": "Mudhole Smith Airport",
    "lat": "602935N",
    "long": "1452812W"
  },
  {
    "stationcode": "PFA",
    "stationname": "Paf Warren Airport",
    "lat": "453557N",
    "long": "1263930E"
  },
  {
    "stationcode": "AOS",
    "stationname": "Amook Airport",
    "lat": "572816N",
    "long": "1534856W"
  },
  {
    "stationcode": "NNK",
    "stationname": "Naknek Airport",
    "lat": "584500N",
    "long": "1563200W"
  },
  {
    "stationcode": "KKI",
    "stationname": "Akiachak Airport",
    "lat": "605419N",
    "long": "1612526W"
  },
  {
    "stationcode": "RBY",
    "stationname": "Ruby Airport",
    "lat": "644338N",
    "long": "1552812W"
  },
  {
    "stationcode": "KEU",
    "stationname": "Kelly Bar Airport",
    "lat": "675500N",
    "long": "1621700W"
  },
  {
    "stationcode": "PUO",
    "stationname": "Prudhoe Bay Airport",
    "lat": "701505N",
    "long": "1482013W"
  },
  {
    "stationcode": "RDV",
    "stationname": "Red Devil Airport",
    "lat": "614713N",
    "long": "1572043W"
  },
  {
    "stationcode": "BVU",
    "stationname": "Beluga Airport",
    "lat": "611010N",
    "long": "1510210W"
  },
  {
    "stationcode": "BTT",
    "stationname": "Bettles Airport",
    "lat": "665450N",
    "long": "1513145W"
  },
  {
    "stationcode": "AET",
    "stationname": "Allakaket Airport",
    "lat": "663302N",
    "long": "1523755W"
  },
  {
    "stationcode": "KYK",
    "stationname": "Karluk Airport",
    "lat": "573356N",
    "long": "1542707W"
  },
  {
    "stationcode": "KTS",
    "stationname": "Brevig Mission Airport",
    "lat": "651956N",
    "long": "1662803W"
  },
  {
    "stationcode": "KLP",
    "stationname": "Kelp Bay Airport",
    "lat": "032233S",
    "long": "1123210E"
  },
  {
    "stationcode": "IAN",
    "stationname": "Bob Barker Memorial Airport",
    "lat": "665833N",
    "long": "1602612W"
  },
  {
    "stationcode": "UQE",
    "stationname": "Queen Airport",
    "lat": "585236N",
    "long": "1582554W"
  },
  {
    "stationcode": "WDY",
    "stationname": "Kodiak FSS Airport",
    "lat": "574700N",
    "long": "1522400W"
  },
  {
    "stationcode": "LPW",
    "stationname": "Little Port Walter Airport",
    "lat": "562300N",
    "long": "1345000W"
  },
  {
    "stationcode": "CIK",
    "stationname": "Chalkyitsik Airport",
    "lat": "663839N",
    "long": "1434434W"
  },
  {
    "stationcode": "WSF",
    "stationname": "Saricheff Airport",
    "lat": "543504N",
    "long": "1645421W"
  },
  {
    "stationcode": "PDB",
    "stationname": "Pedro Bay Airport",
    "lat": "594745N",
    "long": "1540725W"
  },
  {
    "stationcode": "MDO",
    "stationname": "Middleton Island Intermediate Airport",
    "lat": "592700N",
    "long": "1461900W"
  },
  {
    "stationcode": "ILI",
    "stationname": "Iliamna Airport",
    "lat": "594516N",
    "long": "1545439W"
  },
  {
    "stationcode": "EHM",
    "stationname": "Cape Newenham Airport",
    "lat": "583847N",
    "long": "1620346W"
  },
  {
    "stationcode": "PTL",
    "stationname": "Port Armstrong Airport",
    "lat": "040513S",
    "long": "0810133W"
  },
  {
    "stationcode": "MYK",
    "stationname": "May Creek Airport",
    "lat": "612200N",
    "long": "1424100W"
  },
  {
    "stationcode": "TNK",
    "stationname": "Tununak Airport",
    "lat": "603432N",
    "long": "1651616W"
  },
  {
    "stationcode": "DLO",
    "stationname": "Dolomi Airport",
    "lat": "550720N",
    "long": "1320300W"
  },
  {
    "stationcode": "SAG",
    "stationname": "Sagwon Airport",
    "lat": "194120N",
    "long": "0742238E"
  },
  {
    "stationcode": "LHB",
    "stationname": "Lost Harbor Sea Port",
    "lat": "541400N",
    "long": "1653600W"
  },
  {
    "stationcode": "KLG",
    "stationname": "Kalskag Municipal Airport",
    "lat": "613209N",
    "long": "1602052W"
  },
  {
    "stationcode": "VCB",
    "stationname": "View Cove Airport",
    "lat": "550500N",
    "long": "1330100W"
  },
  {
    "stationcode": "MHM",
    "stationname": "Minchumina Intermediate Airport",
    "lat": "081925S",
    "long": "1604755E"
  },
  {
    "stationcode": "SCJ",
    "stationname": "Smith Cove Airport",
    "lat": "552500N",
    "long": "1322000W"
  },
  {
    "stationcode": "SRV",
    "stationname": "Stony River Airport",
    "lat": "614721N",
    "long": "1563521W"
  },
  {
    "stationcode": "CKX",
    "stationname": "Chicken Airport",
    "lat": "640359N",
    "long": "1415705W"
  },
  {
    "stationcode": "EMK",
    "stationname": "Emmonak Airport",
    "lat": "624710N",
    "long": "1642927W"
  },
  {
    "stationcode": "OBU",
    "stationname": "Kobuk/Wien Airport",
    "lat": "665445N",
    "long": "1565354W"
  },
  {
    "stationcode": "KBK",
    "stationname": "Klagbay Airport",
    "lat": "264629N",
    "long": "0835342E"
  },
  {
    "stationcode": "WNA",
    "stationname": "Napakiak Airport",
    "lat": "604136N",
    "long": "1615845W"
  },
  {
    "stationcode": "HPB",
    "stationname": "Hooper Bay Airport",
    "lat": "613126N",
    "long": "1660848W"
  },
  {
    "stationcode": "EGX",
    "stationname": "Egegik Airport",
    "lat": "581108N",
    "long": "1572232W"
  },
  {
    "stationcode": "GAL",
    "stationname": "Galena Airport",
    "lat": "644410N",
    "long": "1565615W"
  },
  {
    "stationcode": "UMB",
    "stationname": "North Shore Airport",
    "lat": "121503S",
    "long": "0252617E"
  },
  {
    "stationcode": "WRH",
    "stationname": "Wrench Creek Airport",
    "lat": "460102N",
    "long": "0853929E"
  },
  {
    "stationcode": "CYT",
    "stationname": "Yakataga Intermediate Airport",
    "lat": "600500N",
    "long": "1422900W"
  },
  {
    "stationcode": "ORT",
    "stationname": "Northway Airport",
    "lat": "625741N",
    "long": "1415542W"
  },
  {
    "stationcode": "NCN",
    "stationname": "New Chenega Airport",
    "lat": "600446N",
    "long": "1475942W"
  },
  {
    "stationcode": "SVW",
    "stationname": "Sparrevohn Airport",
    "lat": "610500N",
    "long": "1553400W"
  },
  {
    "stationcode": "VBM",
    "stationname": "Blue Mountain Airport",
    "lat": "575127N",
    "long": "1570512W"
  },
  {
    "stationcode": "VIK",
    "stationname": "Airstrip",
    "lat": "700243N",
    "long": "1471946W"
  },
  {
    "stationcode": "WKK",
    "stationname": "Aleknagik Airport",
    "lat": "591655N",
    "long": "1583704W"
  },
  {
    "stationcode": "PHO",
    "stationname": "Point Hope Airport",
    "lat": "682056N",
    "long": "1664758W"
  },
  {
    "stationcode": "GMT",
    "stationname": "Granite Mountain Airport",
    "lat": "652400N",
    "long": "1611700W"
  },
  {
    "stationcode": "CLP",
    "stationname": "Clarks Point Airport",
    "lat": "585001N",
    "long": "1583146W"
  },
  {
    "stationcode": "PIP",
    "stationname": "Pilot Point Airport",
    "lat": "573449N",
    "long": "1573419W"
  },
  {
    "stationcode": "PVY",
    "stationname": "Pope Vanoy Airport",
    "lat": "591900N",
    "long": "1545500W"
  },
  {
    "stationcode": "LSR",
    "stationname": "Lost River Airport",
    "lat": "032331N",
    "long": "0975148E"
  },
  {
    "stationcode": "KLW",
    "stationname": "Klawock Airport",
    "lat": "553445N",
    "long": "1330434W"
  },
  {
    "stationcode": "WMO",
    "stationname": "White Mountain Airport",
    "lat": "644111N",
    "long": "1632440W"
  },
  {
    "stationcode": "TSG",
    "stationname": "Tanacross Intermediate Airport",
    "lat": "632300N",
    "long": "1432100W"
  },
  {
    "stationcode": "PTH",
    "stationname": "Port Heiden Airport",
    "lat": "565726N",
    "long": "1583814W"
  },
  {
    "stationcode": "AUK",
    "stationname": "Alakanuk Airport",
    "lat": "624044N",
    "long": "1643903W"
  },
  {
    "stationcode": "CDL",
    "stationname": "Candle Airport",
    "lat": "655400N",
    "long": "1615500W"
  },
  {
    "stationcode": "CBZ",
    "stationname": "Cabin Creek Airport",
    "lat": "373636N",
    "long": "1175309E"
  },
  {
    "stationcode": "PCE",
    "stationname": "Painter Creek Airport",
    "lat": "434231N",
    "long": "0102355E"
  },
  {
    "stationcode": "LKK",
    "stationname": "Kulik Lake Airport",
    "lat": "585800N",
    "long": "1550500W"
  },
  {
    "stationcode": "RSP",
    "stationname": "Raspberry Strait Airport",
    "lat": "581000N",
    "long": "1545000W"
  },
  {
    "stationcode": "KKA",
    "stationname": "Koyuk Airport",
    "lat": "645620N",
    "long": "1610919W"
  },
  {
    "stationcode": "KKH",
    "stationname": "Kongiganak Airport",
    "lat": "595737N",
    "long": "1625253W"
  },
  {
    "stationcode": "NKI",
    "stationname": "Naukiti Airport",
    "lat": "555120N",
    "long": "1331100W"
  },
  {
    "stationcode": "WCR",
    "stationname": "Chandalar Airport",
    "lat": "673000N",
    "long": "1483000W"
  },
  {
    "stationcode": "WTL",
    "stationname": "Tuntutuliak Airport",
    "lat": "602102N",
    "long": "1623920W"
  },
  {
    "stationcode": "GNU",
    "stationname": "Goodnews Bay Airport",
    "lat": "590704N",
    "long": "1613434W"
  },
  {
    "stationcode": "KVL",
    "stationname": "Kivalina Airport",
    "lat": "674410N",
    "long": "1643349W"
  },
  {
    "stationcode": "KWN",
    "stationname": "Kwinhagak Airport",
    "lat": "594523N",
    "long": "1615301W"
  },
  {
    "stationcode": "RLU",
    "stationname": "Bornite Upper Airport",
    "lat": "674500N",
    "long": "1560000W"
  },
  {
    "stationcode": "PTU",
    "stationname": "Platinum Airport",
    "lat": "590059N",
    "long": "1614926W"
  },
  {
    "stationcode": "MCG",
    "stationname": "Mc Grath Airport",
    "lat": "625713N",
    "long": "1553620W"
  },
  {
    "stationcode": "DOF",
    "stationname": "Dora Bay Airport",
    "lat": "551245N",
    "long": "1321600W"
  },
  {
    "stationcode": "KPC",
    "stationname": "Port Clarence Airport",
    "lat": "651500N",
    "long": "1665100W"
  },
  {
    "stationcode": "ANC",
    "stationname": "Anchorage International Airport",
    "lat": "611028N",
    "long": "1495947W"
  },
  {
    "stationcode": "OME",
    "stationname": "Nome Airport",
    "lat": "643044N",
    "long": "1652643W"
  },
  {
    "stationcode": "WWT",
    "stationname": "Newtok Airport",
    "lat": "605625N",
    "long": "1643833W"
  },
  {
    "stationcode": "KNW",
    "stationname": "New Stuyahok Airport",
    "lat": "592702N",
    "long": "1571943W"
  },
  {
    "stationcode": "FAK",
    "stationname": "False Island Airport",
    "lat": "573150N",
    "long": "1351300W"
  },
  {
    "stationcode": "FYU",
    "stationname": "Fort Yukon Airport",
    "lat": "663417N",
    "long": "1451502W"
  },
  {
    "stationcode": "CHU",
    "stationname": "Chuathbaluk Airport",
    "lat": "613505N",
    "long": "1591416W"
  },
  {
    "stationcode": "ORV",
    "stationname": "Curtis Memorial Airport",
    "lat": "664901N",
    "long": "1610145W"
  },
  {
    "stationcode": "FMC",
    "stationname": "Five Mile Airport",
    "lat": "665500N",
    "long": "1495030W"
  },
  {
    "stationcode": "VAK",
    "stationname": "Chevak Airport",
    "lat": "613224N",
    "long": "1653607W"
  },
  {
    "stationcode": "HOL",
    "stationname": "Holikachu Airport",
    "lat": "625200N",
    "long": "1593900W"
  },
  {
    "stationcode": "DGB",
    "stationname": "Danger Bay Airport",
    "lat": "580500N",
    "long": "1524500W"
  },
  {
    "stationcode": "BKC",
    "stationname": "Buckland Airport",
    "lat": "655854N",
    "long": "1610857W"
  },
  {
    "stationcode": "PCA",
    "stationname": "Portage Creek Airport",
    "lat": "585421N",
    "long": "1574254W"
  },
  {
    "stationcode": "FLT",
    "stationname": "Flat Airport",
    "lat": "622700N",
    "long": "1580000W"
  },
  {
    "stationcode": "SGW",
    "stationname": "Saginaw Bay Airport",
    "lat": "565200N",
    "long": "1340900W"
  },
  {
    "stationcode": "TKJ",
    "stationname": "Tok Airport",
    "lat": "631947N",
    "long": "1425716W"
  },
  {
    "stationcode": "MTX",
    "stationname": "Metro Field",
    "lat": "644920N",
    "long": "1475200W"
  },
  {
    "stationcode": "WOO",
    "stationname": "Woodchopper Airport",
    "lat": "652000N",
    "long": "1433200W"
  },
  {
    "stationcode": "BFB",
    "stationname": "Blue Fox Bay Airport",
    "lat": "581100N",
    "long": "1520800W"
  },
  {
    "stationcode": "NOL",
    "stationname": "Nakolik River Airport",
    "lat": "675400N",
    "long": "1604300W"
  },
  {
    "stationcode": "NUP",
    "stationname": "Nunapitchuk Airport",
    "lat": "605415N",
    "long": "1622630W"
  },
  {
    "stationcode": "SMU",
    "stationname": "Sheep Mountain Airport",
    "lat": "614800N",
    "long": "1474000W"
  },
  {
    "stationcode": "HCB",
    "stationname": "Shoal Cove Airport",
    "lat": "552700N",
    "long": "1311700W"
  },
  {
    "stationcode": "TWE",
    "stationname": "Taylor Airport",
    "lat": "661000N",
    "long": "1651000W"
  },
  {
    "stationcode": "DRF",
    "stationname": "Drift River Airport",
    "lat": "603500N",
    "long": "1520900W"
  },
  {
    "stationcode": "ONN",
    "stationname": "Onion Bay Airport",
    "lat": "581400N",
    "long": "1525000W"
  },
  {
    "stationcode": "WWP",
    "stationname": "Whale Pass Airport",
    "lat": "560700N",
    "long": "1330730W"
  },
  {
    "stationcode": "PPC",
    "stationname": "Prospect Creek Airport",
    "lat": "664800N",
    "long": "1503800W"
  },
  {
    "stationcode": "SWD",
    "stationname": "Seward Airport",
    "lat": "600800N",
    "long": "1492500W"
  },
  {
    "stationcode": "IVH",
    "stationname": "Ivishak Airport",
    "lat": "692400N",
    "long": "1481500W"
  },
  {
    "stationcode": "GLQ",
    "stationname": "Glennallen Airport",
    "lat": "620700N",
    "long": "1453300W"
  },
  {
    "stationcode": "HUS",
    "stationname": "Hughes Municipal Airport",
    "lat": "660235N",
    "long": "1541540W"
  },
  {
    "stationcode": "UGB",
    "stationname": "Ugashik Bay Airport",
    "lat": "572529N",
    "long": "1574441W"
  },
  {
    "stationcode": "AKK",
    "stationname": "Akhiok Airport",
    "lat": "565619N",
    "long": "1541102W"
  },
  {
    "stationcode": "ANV",
    "stationname": "Anvik Airport",
    "lat": "623857N",
    "long": "1601123W"
  },
  {
    "stationcode": "DJN",
    "stationname": "Delta Junction Airport",
    "lat": "640302N",
    "long": "1454332W"
  },
  {
    "stationcode": "OOK",
    "stationname": "Toksook Bay Airport",
    "lat": "603228N",
    "long": "1650515W"
  },
  {
    "stationcode": "BSW",
    "stationname": "Boswell Bay Airport",
    "lat": "602400N",
    "long": "1460800W"
  },
  {
    "stationcode": "GAK",
    "stationname": "Gakona Airport",
    "lat": "622000N",
    "long": "1451900W"
  },
  {
    "stationcode": "PFD",
    "stationname": "Port Frederick Airport",
    "lat": "575700N",
    "long": "1353900W"
  },
  {
    "stationcode": "PTC",
    "stationname": "Port Alice Airport",
    "lat": "554730N",
    "long": "1333600W"
  },
  {
    "stationcode": "DIO",
    "stationname": "Diomede Island Airport",
    "lat": "654531N",
    "long": "1685712W"
  },
  {
    "stationcode": "WLN",
    "stationname": "Little Naukati Airport",
    "lat": "555200N",
    "long": "1331300W"
  },
  {
    "stationcode": "TKA",
    "stationname": "Talkeetna Airport",
    "lat": "621800N",
    "long": "1500700W"
  },
  {
    "stationcode": "BTI",
    "stationname": "Barter Island Airport",
    "lat": "700802N",
    "long": "1433455W"
  },
  {
    "stationcode": "CZF",
    "stationname": "Cape Romanzof Airport",
    "lat": "614400N",
    "long": "1660000W"
  },
  {
    "stationcode": "FRP",
    "stationname": "Fresh Water Bay Airport",
    "lat": "575045N",
    "long": "1350130W"
  },
  {
    "stationcode": "SIT",
    "stationname": "Sitka Airport",
    "lat": "570250N",
    "long": "1352142W"
  },
  {
    "stationcode": "BIG",
    "stationname": "Intermediate Field",
    "lat": "640000N",
    "long": "1454400W"
  },
  {
    "stationcode": "XPU",
    "stationname": "West Kuparuk Airport",
    "lat": "433543N",
    "long": "0035528E"
  },
  {
    "stationcode": "ANI",
    "stationname": "Aniak Airport",
    "lat": "613456N",
    "long": "1593239W"
  },
  {
    "stationcode": "KNK",
    "stationname": "Kakhonak Airport",
    "lat": "592600N",
    "long": "1544813W"
  },
  {
    "stationcode": "FNK",
    "stationname": "Fin Creek Airport",
    "lat": "693000N",
    "long": "1473500W"
  },
  {
    "stationcode": "ELW",
    "stationname": "Ellamar Airport",
    "lat": "605350N",
    "long": "1463645W"
  },
  {
    "stationcode": "BMX",
    "stationname": "Big Mountain Airport",
    "lat": "592200N",
    "long": "1551600W"
  },
  {
    "stationcode": "RSH",
    "stationname": "Russian  Mission Airport",
    "lat": "614642N",
    "long": "1611911W"
  },
  {
    "stationcode": "LUR",
    "stationname": "Cape Lisburne Airport",
    "lat": "685230N",
    "long": "1660636W"
  },
  {
    "stationcode": "ISL",
    "stationname": "Isabel Pass Airport",
    "lat": "405837N",
    "long": "0284854E"
  },
  {
    "stationcode": "EWD",
    "stationname": "Wildman Lake Airport",
    "lat": "562723N",
    "long": "1594544W"
  },
  {
    "stationcode": "GAM",
    "stationname": "Gambell Airport",
    "lat": "634600N",
    "long": "1714358W"
  },
  {
    "stationcode": "CZC",
    "stationname": "Copper Centre Airport",
    "lat": "615800N",
    "long": "1451900W"
  },
  {
    "stationcode": "CZO",
    "stationname": "Chistochina Airport",
    "lat": "623400N",
    "long": "1444000W"
  },
  {
    "stationcode": "HBH",
    "stationname": "Hobart Bay Airport",
    "lat": "572430N",
    "long": "1332800W"
  },
  {
    "stationcode": "ICY",
    "stationname": "Icy Bay Airport",
    "lat": "595900N",
    "long": "1413900W"
  },
  {
    "stationcode": "WBB",
    "stationname": "Stebbins Airport",
    "lat": "633053N",
    "long": "1621703W"
  },
  {
    "stationcode": "MNT",
    "stationname": "Minto Airport",
    "lat": "650850N",
    "long": "1492203W"
  },
  {
    "stationcode": "CDB",
    "stationname": "Cold Bay Airport",
    "lat": "551222N",
    "long": "1624332W"
  },
  {
    "stationcode": "ORI",
    "stationname": "Port Lions Airport",
    "lat": "575304N",
    "long": "1525111W"
  },
  {
    "stationcode": "OCI",
    "stationname": "Oceanic Airport",
    "lat": "601500N",
    "long": "1475200W"
  },
  {
    "stationcode": "HCR",
    "stationname": "Holy Cross Airport",
    "lat": "621125N",
    "long": "1594621W"
  },
  {
    "stationcode": "STG",
    "stationname": "St. George Island Airport",
    "lat": "563440N",
    "long": "1693951W"
  },
  {
    "stationcode": "KKU",
    "stationname": "Ekuk Airport",
    "lat": "584840N",
    "long": "1583342W"
  },
  {
    "stationcode": "NKV",
    "stationname": "Nichen Cove Airport",
    "lat": "555100N",
    "long": "1331300W"
  },
  {
    "stationcode": "KBC",
    "stationname": "Birch Creek Airport",
    "lat": "661620N",
    "long": "1454908W"
  },
  {
    "stationcode": "HGZ",
    "stationname": "Hogatza Airport",
    "lat": "661100N",
    "long": "1554000W"
  },
  {
    "stationcode": "EAA",
    "stationname": "Eagle Airport",
    "lat": "644642N",
    "long": "1410938W"
  },
  {
    "stationcode": "MRI",
    "stationname": "Merrill Field",
    "lat": "611400N",
    "long": "1495400W"
  },
  {
    "stationcode": "JLA",
    "stationname": "Quart Creek Airport",
    "lat": "602900N",
    "long": "1495100W"
  },
  {
    "stationcode": "KKF",
    "stationname": "Kagvik Creek Airport",
    "lat": "673600N",
    "long": "1633000W"
  },
  {
    "stationcode": "JBT",
    "stationname": "Bethel City Landing",
    "lat": "604720N",
    "long": "1615000W"
  },
  {
    "stationcode": "GST",
    "stationname": "Gustavus Airport",
    "lat": "582531N",
    "long": "1354227W"
  },
  {
    "stationcode": "SKW",
    "stationname": "Skwentna Intermediate Airport",
    "lat": "615753N",
    "long": "1511117W"
  },
  {
    "stationcode": "NIE",
    "stationname": "Niblack Airport",
    "lat": "550400N",
    "long": "1320900W"
  },
  {
    "stationcode": "SMK",
    "stationname": "St. Michael Airport",
    "lat": "632852N",
    "long": "1620151W"
  },
  {
    "stationcode": "CKD",
    "stationname": "Crooked Creek Airport",
    "lat": "615200N",
    "long": "1580800W"
  },
  {
    "stationcode": "FIC",
    "stationname": "Fire Cove Airport",
    "lat": "554640N",
    "long": "1313215W"
  },
  {
    "stationcode": "SHH",
    "stationname": "Shishmaref Airport",
    "lat": "661458N",
    "long": "1660522W"
  },
  {
    "stationcode": "MLL",
    "stationname": "Marshall Airport",
    "lat": "615151N",
    "long": "1620134W"
  },
  {
    "stationcode": "MLY",
    "stationname": "Manley Hot Springs Airport",
    "lat": "645953N",
    "long": "1503828W"
  },
  {
    "stationcode": "HNH",
    "stationname": "Hoonah Airport",
    "lat": "580545N",
    "long": "1352446W"
  },
  {
    "stationcode": "HKB",
    "stationname": "Healy Lake Airport",
    "lat": "635203N",
    "long": "1485813W"
  },
  {
    "stationcode": "HDA",
    "stationname": "Hidden Falls Airport",
    "lat": "571200N",
    "long": "1345100W"
  },
  {
    "stationcode": "PTA",
    "stationname": "Port Alsworth Airport",
    "lat": "601209N",
    "long": "1541924W"
  },
  {
    "stationcode": "SVS",
    "stationname": "Stevens Village Airport",
    "lat": "660058N",
    "long": "1490340W"
  },
  {
    "stationcode": "WDB",
    "stationname": "Deep Bay Airport",
    "lat": "553500N",
    "long": "1314500W"
  },
  {
    "stationcode": "KCQ",
    "stationname": "Chignik Lake Airport",
    "lat": "561520N",
    "long": "1584646W"
  },
  {
    "stationcode": "UNK",
    "stationname": "Unalakleet Airport",
    "lat": "635318N",
    "long": "1604756W"
  },
  {
    "stationcode": "AIB",
    "stationname": "Anita Bay Airport",
    "lat": "561400N",
    "long": "1322300W"
  },
  {
    "stationcode": "BKF",
    "stationname": "Brooks Lake Airport",
    "lat": "583600N",
    "long": "1561400W"
  },
  {
    "stationcode": "PNF",
    "stationname": "Peterson's Point Airport",
    "lat": "584100N",
    "long": "1565000W"
  },
  {
    "stationcode": "POQ",
    "stationname": "Polk Inlet Airport",
    "lat": "552100N",
    "long": "1323000W"
  },
  {
    "stationcode": "HNS",
    "stationname": "Haines Municipal Airport",
    "lat": "591438N",
    "long": "1353125W"
  },
  {
    "stationcode": "TEK",
    "stationname": "Tatitlek Airport",
    "lat": "605217N",
    "long": "1464123W"
  },
  {
    "stationcode": "CUW",
    "stationname": "Cube Cove Airport",
    "lat": "575630N",
    "long": "1344500W"
  },
  {
    "stationcode": "KGX",
    "stationname": "Grayling Airport",
    "lat": "625352N",
    "long": "1600402W"
  },
  {
    "stationcode": "UMM",
    "stationname": "Summit Airport",
    "lat": "632000N",
    "long": "1490800W"
  },
  {
    "stationcode": "CXF",
    "stationname": "Coldfoot Airport",
    "lat": "671506N",
    "long": "1501215W"
  },
  {
    "stationcode": "ARC",
    "stationname": "Arctic Village Airport",
    "lat": "680653N",
    "long": "1453446W"
  },
  {
    "stationcode": "CZN",
    "stationname": "Chisana Field",
    "lat": "620425N",
    "long": "1420304W"
  },
  {
    "stationcode": "CEX",
    "stationname": "Chena Hot Springs Airport",
    "lat": "650300N",
    "long": "1460300W"
  },
  {
    "stationcode": "PML",
    "stationname": "Port Moller Airport",
    "lat": "560018N",
    "long": "1603341W"
  },
  {
    "stationcode": "ABL",
    "stationname": "Ambler Airport",
    "lat": "670626N",
    "long": "1575112W"
  },
  {
    "stationcode": "MCL",
    "stationname": "Mount Mckinley Airport",
    "lat": "634400N",
    "long": "1485400W"
  },
  {
    "stationcode": "MOS",
    "stationname": "Moses Point Intermediate Airport",
    "lat": "644200N",
    "long": "1620300W"
  },
  {
    "stationcode": "RBH",
    "stationname": "Brooks Lodge Airport",
    "lat": "583300N",
    "long": "1554800W"
  },
  {
    "stationcode": "ENA",
    "stationname": "Kenai Airport",
    "lat": "603423N",
    "long": "1511442W"
  },
  {
    "stationcode": "PJS",
    "stationname": "Port San Juan Airport",
    "lat": "600255N",
    "long": "1480350W"
  },
  {
    "stationcode": "WSM",
    "stationname": "Wiseman Airport",
    "lat": "672500N",
    "long": "1500700W"
  },
  {
    "stationcode": "KOT",
    "stationname": "Kotlik Airport",
    "lat": "630157N",
    "long": "1633150W"
  },
  {
    "stationcode": "ELI",
    "stationname": "Elim Airport",
    "lat": "643656N",
    "long": "1621613W"
  },
  {
    "stationcode": "PBK",
    "stationname": "Pack Creek Airport",
    "lat": "574800N",
    "long": "1344200W"
  },
  {
    "stationcode": "KPN",
    "stationname": "Kipnuk Airport",
    "lat": "595607N",
    "long": "1640155W"
  },
  {
    "stationcode": "KPV",
    "stationname": "Perryville Airport",
    "lat": "555424N",
    "long": "1590939W"
  },
  {
    "stationcode": "RTE",
    "stationname": "Marguerite Bay Airport",
    "lat": "233033S",
    "long": "0463818W"
  },
  {
    "stationcode": "WSN",
    "stationname": "South Naknek Airport",
    "lat": "584212N",
    "long": "1570024W"
  },
  {
    "stationcode": "WTK",
    "stationname": "Noatak Airport",
    "lat": "673358N",
    "long": "1625829W"
  },
  {
    "stationcode": "PTR",
    "stationname": "Pleasant Harbor Airport",
    "lat": "574500N",
    "long": "1525000W"
  },
  {
    "stationcode": "WOW",
    "stationname": "Willow Airport",
    "lat": "614500N",
    "long": "1500300W"
  },
  {
    "stationcode": "CEM",
    "stationname": "Central Airport",
    "lat": "653425N",
    "long": "1444645W"
  },
  {
    "stationcode": "AGN",
    "stationname": "Angoon Airport",
    "lat": "572942N",
    "long": "1343402W"
  },
  {
    "stationcode": "KYU",
    "stationname": "Koyukuk Airport",
    "lat": "645235N",
    "long": "1574328W"
  },
  {
    "stationcode": "KLL",
    "stationname": "Levelock Airport",
    "lat": "590734N",
    "long": "1565139W"
  },
  {
    "stationcode": "KAL",
    "stationname": "Kaltag Airport",
    "lat": "641905N",
    "long": "1584435W"
  },
  {
    "stationcode": "GKN",
    "stationname": "Gulkana Airport",
    "lat": "620924N",
    "long": "1452717W"
  },
  {
    "stationcode": "FOX",
    "stationname": "Fox Airport",
    "lat": "640000N",
    "long": "1470000W"
  },
  {
    "stationcode": "SNP",
    "stationname": "Saint Paul Island Airport",
    "lat": "571002N",
    "long": "1701314W"
  },
  {
    "stationcode": "RMP",
    "stationname": "Rampart Airport",
    "lat": "653029N",
    "long": "1500831W"
  },
  {
    "stationcode": "CIL",
    "stationname": "Melsing Creek Airport",
    "lat": "645400N",
    "long": "1634400W"
  },
  {
    "stationcode": "BGQ",
    "stationname": "Big Lake Airport",
    "lat": "613200N",
    "long": "1494900W"
  },
  {
    "stationcode": "CFA",
    "stationname": "Coffee Point Airport",
    "lat": "581300N",
    "long": "1573000W"
  },
  {
    "stationcode": "VEE",
    "stationname": "Venetie Airport",
    "lat": "670023N",
    "long": "1462242W"
  },
  {
    "stationcode": "TWA",
    "stationname": "Twin Hills Airport",
    "lat": "590426N",
    "long": "1601632W"
  },
  {
    "stationcode": "KOZ",
    "stationname": "Ouzinkie Airport",
    "lat": "575532N",
    "long": "1522948W"
  },
  {
    "stationcode": "KEB",
    "stationname": "English Bay Airport",
    "lat": "592108N",
    "long": "1515530W"
  },
  {
    "stationcode": "YAK",
    "stationname": "Yakutat Airport",
    "lat": "593033N",
    "long": "1393936W"
  },
  {
    "stationcode": "EFB",
    "stationname": "Eight Fathom Bight Airport",
    "lat": "580000N",
    "long": "1354400W"
  },
  {
    "stationcode": "BET",
    "stationname": "Bethel Airport",
    "lat": "604647N",
    "long": "1615017W"
  },
  {
    "stationcode": "BRW",
    "stationname": "Wiley Post/Will Rogers Memorial Airport",
    "lat": "711708N",
    "long": "1564558W"
  },
  {
    "stationcode": "PQS",
    "stationname": "Pilot Station Airport",
    "lat": "615605N",
    "long": "1625357W"
  },
  {
    "stationcode": "NLG",
    "stationname": "Nelson Lagoon Airport",
    "lat": "560028N",
    "long": "1610937W"
  },
  {
    "stationcode": "TLF",
    "stationname": "Telida Airport",
    "lat": "632300N",
    "long": "1531600W"
  },
  {
    "stationcode": "HMS",
    "stationname": "Homeshore Airport",
    "lat": "010119S",
    "long": "1145533E"
  },
  {
    "stationcode": "KDK",
    "stationname": "Kodiak Municipal Airport",
    "lat": "574800N",
    "long": "1522200W"
  },
  {
    "stationcode": "CHP",
    "stationname": "Circle Hot Springs Airport",
    "lat": "652800N",
    "long": "1443500W"
  },
  {
    "stationcode": "TCT",
    "stationname": "Takotna Airport",
    "lat": "625934N",
    "long": "1560413W"
  },
  {
    "stationcode": "WOD",
    "stationname": "Wood River Airport",
    "lat": "385200N",
    "long": "0900500W"
  },
  {
    "stationcode": "PPV",
    "stationname": "Port Protection Airport",
    "lat": "561932N",
    "long": "1333633W"
  },
  {
    "stationcode": "AKP",
    "stationname": "Anaktuvuk Airport",
    "lat": "680815N",
    "long": "1514424W"
  },
  {
    "stationcode": "PAQ",
    "stationname": "Palmer Municipal Airport",
    "lat": "613600N",
    "long": "1490700W"
  },
  {
    "stationcode": "KTN",
    "stationname": "Ketchikan International Airport",
    "lat": "552120N",
    "long": "1314250W"
  },
  {
    "stationcode": "DUT",
    "stationname": "Emergency Field",
    "lat": "535356N",
    "long": "1663241W"
  },
  {
    "stationcode": "KEK",
    "stationname": "Ekwok Airport",
    "lat": "592112N",
    "long": "1572835W"
  },
  {
    "stationcode": "TAL",
    "stationname": "Ralph Calhoun Airport",
    "lat": "651029N",
    "long": "1520624W"
  },
  {
    "stationcode": "SYB",
    "stationname": "Seal Bay Airport",
    "lat": "582201N",
    "long": "1521226W"
  },
  {
    "stationcode": "WRG",
    "stationname": "Wrangell Airport",
    "lat": "562904N",
    "long": "1322211W"
  },
  {
    "stationcode": "PKA",
    "stationname": "Napaskiak Airport",
    "lat": "604224N",
    "long": "1614625W"
  },
  {
    "stationcode": "TLA",
    "stationname": "Teller Airport",
    "lat": "651425N",
    "long": "1662016W"
  },
  {
    "stationcode": "HOM",
    "stationname": "Homer Airport",
    "lat": "593844N",
    "long": "1512836W"
  },
  {
    "stationcode": "HNE",
    "stationname": "Tabneta Pass Airport",
    "lat": "615300N",
    "long": "1472000W"
  },
  {
    "stationcode": "RDB",
    "stationname": "Red Dog Airport",
    "lat": "680142N",
    "long": "1625431W"
  },
  {
    "stationcode": "KQA",
    "stationname": "Akutan Airport",
    "lat": "540759N",
    "long": "1654651W"
  },
  {
    "stationcode": "KLN",
    "stationname": "Larsen Bay Airport",
    "lat": "573204N",
    "long": "1535840W"
  },
  {
    "stationcode": "RCP",
    "stationname": "Cinder Park Airport",
    "lat": "570459N",
    "long": "1574820W"
  },
  {
    "stationcode": "KCR",
    "stationname": "Colorado Creek Airport",
    "lat": "630900N",
    "long": "1492600W"
  },
  {
    "stationcode": "UMT",
    "stationname": "Umiat Airport",
    "lat": "692200N",
    "long": "1520800W"
  },
  {
    "stationcode": "ANN",
    "stationname": "Annette Island Airport",
    "lat": "550200N",
    "long": "1313400W"
  },
  {
    "stationcode": "KUK",
    "stationname": "Kasigluk Airport",
    "lat": "605226N",
    "long": "1623127W"
  },
  {
    "stationcode": "TKI",
    "stationname": "Tokeen Airport",
    "lat": "555615N",
    "long": "1331930W"
  },
  {
    "stationcode": "BSZ",
    "stationname": "Bartletts Airport",
    "lat": "581401N",
    "long": "1572101W"
  },
  {
    "stationcode": "KCG",
    "stationname": "Fisheries Airport",
    "lat": "561838N",
    "long": "1582229W"
  },
  {
    "stationcode": "SXP",
    "stationname": "Sheldon Point Airport",
    "lat": "623118N",
    "long": "1645048W"
  },
  {
    "stationcode": "PCK",
    "stationname": "Porcupine Creek Airport",
    "lat": "671344N",
    "long": "1501700W"
  },
  {
    "stationcode": "NUI",
    "stationname": "Nuiqsut Airport",
    "lat": "701236N",
    "long": "1510020W"
  },
  {
    "stationcode": "HED",
    "stationname": "Herendeen Airport",
    "lat": "554800N",
    "long": "1605400W"
  },
  {
    "stationcode": "SOV",
    "stationname": "Seldovia Airport",
    "lat": "592638N",
    "long": "1514219W"
  },
  {
    "stationcode": "SGY",
    "stationname": "Skagway Municipal Airport",
    "lat": "592738N",
    "long": "1351859W"
  },
  {
    "stationcode": "WBQ",
    "stationname": "Beaver Airport",
    "lat": "662143N",
    "long": "1472434W"
  },
  {
    "stationcode": "WLR",
    "stationname": "Loring Airport",
    "lat": "553600N",
    "long": "1313400W"
  },
  {
    "stationcode": "FWL",
    "stationname": "Farewell Airport",
    "lat": "623100N",
    "long": "1535400W"
  },
  {
    "stationcode": "SHX",
    "stationname": "Shageluk Airport",
    "lat": "624133N",
    "long": "1593409W"
  },
  {
    "stationcode": "UTO",
    "stationname": "Indian Mountain Airport",
    "lat": "655934N",
    "long": "1534216W"
  },
  {
    "stationcode": "PGM",
    "stationname": "Port Graham Airport",
    "lat": "592059N",
    "long": "1514952W"
  },
  {
    "stationcode": "AKN",
    "stationname": "King Salmon Airport",
    "lat": "584036N",
    "long": "1563857W"
  },
  {
    "stationcode": "DCK",
    "stationname": "Dahl Creek Airport",
    "lat": "665600N",
    "long": "1565400W"
  },
  {
    "stationcode": "PTD",
    "stationname": "Port Alexander Airport",
    "lat": "561500N",
    "long": "1343900W"
  },
  {
    "stationcode": "ZNC",
    "stationname": "Nyac Airport",
    "lat": "605900N",
    "long": "1595900W"
  },
  {
    "stationcode": "WLK",
    "stationname": "Selawik Airport",
    "lat": "663601N",
    "long": "1595904W"
  },
  {
    "stationcode": "HAY",
    "stationname": "Haycock Airport",
    "lat": "081448N",
    "long": "0733450W"
  },
  {
    "stationcode": "KSR",
    "stationname": "Sandy River Airpor",
    "lat": "061032S",
    "long": "1202611E"
  },
  {
    "stationcode": "SVA",
    "stationname": "Savoonga Airport",
    "lat": "634111N",
    "long": "1702933W"
  },
  {
    "stationcode": "UUK",
    "stationname": "Kuparuk Airport",
    "lat": "701951N",
    "long": "1493553W"
  },
  {
    "stationcode": "AKI",
    "stationname": "Akiak Airport",
    "lat": "605406N",
    "long": "1611356W"
  },
  {
    "stationcode": "JNU",
    "stationname": "Juneau International Airport",
    "lat": "582118N",
    "long": "1343435W"
  },
  {
    "stationcode": "UGI",
    "stationname": "Uganik Airport",
    "lat": "575025N",
    "long": "1531949W"
  },
  {
    "stationcode": "AQY",
    "stationname": "Alyeska Airport",
    "lat": "605800N",
    "long": "1490700W"
  },
  {
    "stationcode": "OTZ",
    "stationname": "Kotzebue Airport",
    "lat": "665319N",
    "long": "1623539W"
  },
  {
    "stationcode": "LIJ",
    "stationname": "Long Island Airport",
    "lat": "545545N",
    "long": "1324830W"
  },
  {
    "stationcode": "IGG",
    "stationname": "Igiugig Airport",
    "lat": "591926N",
    "long": "1555405W"
  },
  {
    "stationcode": "GEK",
    "stationname": "Ganes Creek Airport",
    "lat": "630600N",
    "long": "1562600W"
  },
  {
    "stationcode": "KBW",
    "stationname": "Chignik Bay Airport",
    "lat": "561700N",
    "long": "1582300W"
  },
  {
    "stationcode": "EEK",
    "stationname": "Eek Airport",
    "lat": "601254N",
    "long": "1620043W"
  },
  {
    "stationcode": "KUW",
    "stationname": "Kugururok River Airport",
    "lat": "675800N",
    "long": "1615900W"
  },
  {
    "stationcode": "KVC",
    "stationname": "King Cove Airport",
    "lat": "550700N",
    "long": "1621554W"
  },
  {
    "stationcode": "EFO",
    "stationname": "East Fork Airport",
    "lat": "644134N",
    "long": "1641635W"
  },
  {
    "stationcode": "GLV",
    "stationname": "Golovin Airport",
    "lat": "643253N",
    "long": "1630043W"
  },
  {
    "stationcode": "SCC",
    "stationname": "Prudhoe Bay/Deadhorse Airport",
    "lat": "701141N",
    "long": "1482755W"
  },
  {
    "stationcode": "BCC",
    "stationname": "Bear Creek Airport",
    "lat": "633414N",
    "long": "1560905W"
  },
  {
    "stationcode": "MXY",
    "stationname": "Mc Carthy Airport",
    "lat": "612500N",
    "long": "1425500W"
  },
  {
    "stationcode": "NIN",
    "stationname": "Ninilchik Airport",
    "lat": "600300N",
    "long": "1514000W"
  },
  {
    "stationcode": "WWA",
    "stationname": "Wasilla Airport",
    "lat": "613500N",
    "long": "1492600W"
  },
  {
    "stationcode": "TYE",
    "stationname": "Tyonek Airport",
    "lat": "610200N",
    "long": "1511700W"
  },
  {
    "stationcode": "SDP",
    "stationname": "Sand Point Municipal Airport",
    "lat": "551854N",
    "long": "1603122W"
  },
  {
    "stationcode": "FAI",
    "stationname": "Fairbanks International Airport",
    "lat": "644854N",
    "long": "1475123W"
  },
  {
    "stationcode": "CXC",
    "stationname": "Chitina Airport",
    "lat": "613400N",
    "long": "1442500W"
  },
  {
    "stationcode": "WAA",
    "stationname": "Wales Airport",
    "lat": "653724N",
    "long": "1680542W"
  },
  {
    "stationcode": "BYA",
    "stationname": "Boundary Airport",
    "lat": "640500N",
    "long": "1410700W"
  },
  {
    "stationcode": "VKW",
    "stationname": "West Kavik Airport",
    "lat": "694600N",
    "long": "1465500W"
  },
  {
    "stationcode": "TLT",
    "stationname": "Tuluksak Airport",
    "lat": "610552N",
    "long": "1605805W"
  },
  {
    "stationcode": "IRC",
    "stationname": "Circle City Airport",
    "lat": "654940N",
    "long": "1440433W"
  },
  {
    "stationcode": "KGK",
    "stationname": "New Koliganek Airport",
    "lat": "594338N",
    "long": "1571542W"
  },
  {
    "stationcode": "KWK",
    "stationname": "Kwigillingok Airport",
    "lat": "595235N",
    "long": "1631007W"
  },
  {
    "stationcode": "PIZ",
    "stationname": "Dew Station Airport",
    "lat": "694358N",
    "long": "1630019W"
  },
  {
    "stationcode": "PBA",
    "stationname": "Point Barrow Airport",
    "lat": "711700N",
    "long": "1564600W"
  },
  {
    "stationcode": "KMY",
    "stationname": "Moser Bay Airport",
    "lat": "570005N",
    "long": "1540906W"
  },
  {
    "stationcode": "SOL",
    "stationname": "Solomon Airport",
    "lat": "643500N",
    "long": "1641800W"
  },
  {
    "stationcode": "RWB",
    "stationname": "Rowan Bay Airport",
    "lat": "564500N",
    "long": "1340800W"
  },
  {
    "stationcode": "PII",
    "stationname": "Phillips Field",
    "lat": "645150N",
    "long": "1474250W"
  },
  {
    "stationcode": "NNL",
    "stationname": "Nondalton Airport",
    "lat": "595850N",
    "long": "1545021W"
  },
  {
    "stationcode": "HBC",
    "stationname": "Hanus Bay Airport",
    "lat": "573000N",
    "long": "1355500W"
  },
  {
    "stationcode": "HSL",
    "stationname": "Huslia Airport",
    "lat": "654152N",
    "long": "1562105W"
  },
  {
    "stationcode": "SCM",
    "stationname": "Scammon Bay Airport",
    "lat": "615044N",
    "long": "1653443W"
  },
  {
    "stationcode": "KFP",
    "stationname": "False Pass Airport",
    "lat": "545053N",
    "long": "1632423W"
  },
  {
    "stationcode": "FIV",
    "stationname": "Five Fingers Airport",
    "lat": "571600N",
    "long": "1333800W"
  },
  {
    "stationcode": "SKK",
    "stationname": "Shaktoolik Airport",
    "lat": "642140N",
    "long": "1611209W"
  },
  {
    "stationcode": "UNS",
    "stationname": "Umnak Island Airport",
    "lat": "531500N",
    "long": "1682000W"
  },
  {
    "stationcode": "ATK",
    "stationname": "Atqasuk Airport",
    "lat": "702802N",
    "long": "1572609W"
  },
  {
    "stationcode": "CZP",
    "stationname": "Cape Pole Airport",
    "lat": "555800N",
    "long": "1334700W"
  },
  {
    "stationcode": "FTL",
    "stationname": "Fortuna Ledge Airport",
    "lat": "615300N",
    "long": "1620500W"
  },
  {
    "stationcode": "GFB",
    "stationname": "Togiak Fishery Airport",
    "lat": "590100N",
    "long": "1600300W"
  },
  {
    "stationcode": "TPO",
    "stationname": "Tanalian Point Airport",
    "lat": "601000N",
    "long": "1542000W"
  },
  {
    "stationcode": "KWT",
    "stationname": "Kwethluk Airport",
    "lat": "604737N",
    "long": "1612631W"
  },
  {
    "stationcode": "ATT",
    "stationname": "Atmautluak Airport",
    "lat": "605201N",
    "long": "1621626W"
  },
  {
    "stationcode": "CYF",
    "stationname": "Chefornak Airport",
    "lat": "600935N",
    "long": "1641629W"
  },
  {
    "stationcode": "CBA",
    "stationname": "Corner Bay Airport",
    "lat": "574300N",
    "long": "1351200W"
  },
  {
    "stationcode": "NIB",
    "stationname": "Nikolai Airport",
    "lat": "630102N",
    "long": "1542156W"
  },
  {
    "stationcode": "AED",
    "stationname": "Aleneva Airport",
    "lat": "580200N",
    "long": "1525400W"
  },
  {
    "stationcode": "KTB",
    "stationname": "Thorne Bay Airport",
    "lat": "554100N",
    "long": "1323200W"
  },
  {
    "stationcode": "SHG",
    "stationname": "Shungnak Airport",
    "lat": "665317N",
    "long": "1570945W"
  },
  {
    "stationcode": "BVD",
    "stationname": "Beaver Inlet Sea Port",
    "lat": "534400N",
    "long": "1661900W"
  },
  {
    "stationcode": "MYU",
    "stationname": "Ellis Field",
    "lat": "602221N",
    "long": "1661618W"
  },
  {
    "stationcode": "PSG",
    "stationname": "Petersburg Municipal Airport",
    "lat": "564808N",
    "long": "1325637W"
  },
  {
    "stationcode": "LIV",
    "stationname": "Livengood Airport",
    "lat": "653200N",
    "long": "1483200W"
  },
  {
    "stationcode": "BIC",
    "stationname": "Big Creek Airport",
    "lat": "471932N",
    "long": "0031204W"
  },
  {
    "stationcode": "LMA",
    "stationname": "Lake Minchumina Airport",
    "lat": "635310N",
    "long": "1521807W"
  },
  {
    "stationcode": "MDR",
    "stationname": "Medfra Airport",
    "lat": "011756S",
    "long": "0350351E"
  },
  {
    "stationcode": "TEH",
    "stationname": "Tetlin Airport",
    "lat": "630800N",
    "long": "1423100W"
  },
  {
    "stationcode": "UGS",
    "stationname": "Ugashik Airport",
    "lat": "573200N",
    "long": "1572400W"
  },
  {
    "stationcode": "KSM",
    "stationname": "Saint Mary's Airport",
    "lat": "620338N",
    "long": "1631803W"
  },
  {
    "stationcode": "AIN",
    "stationname": "Wainwright Airport",
    "lat": "703649N",
    "long": "1595201W"
  },
  {
    "stationcode": "PRL",
    "stationname": "Port Oceanic Airport",
    "lat": "601230N",
    "long": "1474910W"
  },
  {
    "stationcode": "MOU",
    "stationname": "Mountain Village Airport",
    "lat": "620543N",
    "long": "1634056W"
  },
  {
    "stationcode": "ADQ",
    "stationname": "Kodiak Airport",
    "lat": "574500N",
    "long": "1522938W"
  },
  {
    "stationcode": "EDA",
    "stationname": "Edna Bay Airport",
    "lat": "555747N",
    "long": "1333719W"
  },
  {
    "stationcode": "LNI",
    "stationname": "Dew Station Airport",
    "lat": "705500N",
    "long": "1531500W"
  },
  {
    "stationcode": "ENN",
    "stationname": "Nenana Municipal Airport",
    "lat": "643300N",
    "long": "1490500W"
  },
  {
    "stationcode": "KZH",
    "stationname": "Kizhuyak Airport",
    "lat": "573500N",
    "long": "1530900W"
  },
  {
    "stationcode": "LVD",
    "stationname": "Lime Village Airport",
    "lat": "612100N",
    "long": "1552800W"
  },
  {
    "stationcode": "GBH",
    "stationname": "Galbraith Lake Airport",
    "lat": "682800N",
    "long": "1492900W"
  },
  {
    "stationcode": "SLQ",
    "stationname": "Sleetmute Airport",
    "lat": "614200N",
    "long": "1570957W"
  },
  {
    "stationcode": "OLH",
    "stationname": "Old Harbor Airport",
    "lat": "571218N",
    "long": "1531757W"
  },
  {
    "stationcode": "FBK",
    "stationname": "Fort Wainwright Airport",
    "lat": "644900N",
    "long": "1475200W"
  },
  {
    "stationcode": "TOG",
    "stationname": "Togiak Village Airport",
    "lat": "590309N",
    "long": "1602357W"
  },
  {
    "stationcode": "VDZ",
    "stationname": "Valdez Municipal Airport",
    "lat": "610802N",
    "long": "1461454W"
  },
  {
    "stationcode": "WLB",
    "stationname": "Labouchere Bay Airport",
    "lat": "561730N",
    "long": "1333930W"
  },
  {
    "stationcode": "KCL",
    "stationname": "Lagoon Airport",
    "lat": "561843N",
    "long": "1583153W"
  },
  {
    "stationcode": "SXQ",
    "stationname": "Soldotna Airport",
    "lat": "602900N",
    "long": "1510400W"
  },
  {
    "stationcode": "NUL",
    "stationname": "Nulato Airport",
    "lat": "644346N",
    "long": "1580427W"
  },
  {
    "stationcode": "FLJ",
    "stationname": "Falls Bay Airport",
    "lat": "600157N",
    "long": "1480030W"
  },
  {
    "stationcode": "PWR",
    "stationname": "Port Walter Airport",
    "lat": "562300N",
    "long": "1343900W"
  },
  {
    "stationcode": "DLG",
    "stationname": "Dillingham Municipal Airport",
    "lat": "590241N",
    "long": "1583020W"
  },
  {
    "stationcode": "CIV",
    "stationname": "Chomley Airport",
    "lat": "551300N",
    "long": "1321300W"
  },
  {
    "stationcode": "CKU",
    "stationname": "Cordova City Airport",
    "lat": "603300N",
    "long": "1454400W"
  },
  {
    "stationcode": "AKB",
    "stationname": "Atka Airport",
    "lat": "521306N",
    "long": "1741223W"
  },
  {
    "stationcode": "ATU",
    "stationname": "Casco Cove Airport",
    "lat": "525000N",
    "long": "1731100E"
  },
  {
    "stationcode": "AHT",
    "stationname": "Amchitka Airport",
    "lat": "513000N",
    "long": "1790000E"
  },
  {
    "stationcode": "ADK",
    "stationname": "Adak Island Airport",
    "lat": "515252N",
    "long": "1763854W"
  },
  {
    "stationcode": "ROY",
    "stationname": "Rio Mayo Airport",
    "lat": "454217S",
    "long": "0701531W"
  },
  {
    "stationcode": "ULA",
    "stationname": "San Julian Airport",
    "lat": "491824S",
    "long": "0674810W"
  },
  {
    "stationcode": "RZA",
    "stationname": "Santa Cruz Airport",
    "lat": "500104S",
    "long": "0683449W"
  },
  {
    "stationcode": "IGB",
    "stationname": "Ingeniero Jacobacci Airport",
    "lat": "411800S",
    "long": "0693500W"
  },
  {
    "stationcode": "LMD",
    "stationname": "Los Menucos Airport",
    "lat": "405000S",
    "long": "0680800W"
  },
  {
    "stationcode": "PMY",
    "stationname": "El Tehuelche Airport",
    "lat": "424533S",
    "long": "0650610W"
  },
  {
    "stationcode": "PUD",
    "stationname": "Puerto Deseado Airport",
    "lat": "474405S",
    "long": "0655412W"
  },
  {
    "stationcode": "FTE",
    "stationname": "El Calafate",
    "lat": "501648S",
    "long": "0720312W"
  },
  {
    "stationcode": "APZ",
    "stationname": "Zapala Airport",
    "lat": "385500S",
    "long": "0700500W"
  },
  {
    "stationcode": "UAQ",
    "stationname": "San Juan Airport",
    "lat": "313417S",
    "long": "0682506W"
  },
  {
    "stationcode": "JSM",
    "stationname": "Jose San de Martin Airport",
    "lat": "440200S",
    "long": "0702800W"
  },
  {
    "stationcode": "CCT",
    "stationname": "Colonia Catriel Airport",
    "lat": "375100S",
    "long": "0675200W"
  },
  {
    "stationcode": "EMX",
    "stationname": "El Maiten Airport",
    "lat": "420405S",
    "long": "0710920W"
  },
  {
    "stationcode": "OLN",
    "stationname": "Colonia Sarmiento",
    "lat": "453500S",
    "long": "0690000W"
  },
  {
    "stationcode": "LCP",
    "stationname": "Loncopue Airport",
    "lat": "380500S",
    "long": "0704000W"
  },
  {
    "stationcode": "CVH",
    "stationname": "Caviahue Airport",
    "lat": "375500S",
    "long": "0710050W"
  },
  {
    "stationcode": "USH",
    "stationname": "Islas Malvinas Airport",
    "lat": "545036S",
    "long": "0681744W"
  },
  {
    "stationcode": "MQD",
    "stationname": "Maquinchao Airport",
    "lat": "411500S",
    "long": "0684400W"
  },
  {
    "stationcode": "BRC",
    "stationname": "San Carlos de Bariloche International Airport",
    "lat": "410904S",
    "long": "0710928W"
  },
  {
    "stationcode": "EHL",
    "stationname": "El Bolson Airport",
    "lat": "415800S",
    "long": "0713100W"
  },
  {
    "stationcode": "RSA",
    "stationname": "Santa Rosa Airport",
    "lat": "363518S",
    "long": "0641633W"
  },
  {
    "stationcode": "LGS",
    "stationname": "Malargue Airport",
    "lat": "352943S",
    "long": "0693423W"
  },
  {
    "stationcode": "LHS",
    "stationname": "Las Heras Airport",
    "lat": "463200S",
    "long": "0685700W"
  },
  {
    "stationcode": "OES",
    "stationname": "San Antonio Oeste Airport",
    "lat": "404502S",
    "long": "0650207W"
  },
  {
    "stationcode": "VDM",
    "stationname": "Viedma Airport",
    "lat": "405213S",
    "long": "0625948W"
  },
  {
    "stationcode": "RDS",
    "stationname": "Rincon de Los Sauces Airport",
    "lat": "372300S",
    "long": "0685400W"
  },
  {
    "stationcode": "GNR",
    "stationname": "General Roca Airport",
    "lat": "390200S",
    "long": "0673500W"
  },
  {
    "stationcode": "CPC",
    "stationname": "Chapelco Airport",
    "lat": "400431S",
    "long": "0710814W"
  },
  {
    "stationcode": "ING",
    "stationname": "Lago Argentino Airport",
    "lat": "503500S",
    "long": "0724000W"
  },
  {
    "stationcode": "JUJ",
    "stationname": "El Cadillal Airport",
    "lat": "242400S",
    "long": "0650500W"
  },
  {
    "stationcode": "CRD",
    "stationname": "Comodoro Rivadavia Airport",
    "lat": "454714S",
    "long": "0672746W"
  },
  {
    "stationcode": "MDZ",
    "stationname": "El Plumerillo Airport",
    "lat": "324955S",
    "long": "0684705W"
  },
  {
    "stationcode": "GPO",
    "stationname": "General Pico Airport",
    "lat": "354200S",
    "long": "0634500W"
  },
  {
    "stationcode": "RGA",
    "stationname": "Rio Grande Airport",
    "lat": "534639S",
    "long": "0674500W"
  },
  {
    "stationcode": "RGL",
    "stationname": "Rio Gallegos Internacional Airport",
    "lat": "513632S",
    "long": "0691846W"
  },
  {
    "stationcode": "HOS",
    "stationname": "Oscar Reguera Airport",
    "lat": "372500S",
    "long": "0701300W"
  },
  {
    "stationcode": "ORA",
    "stationname": "Oran Airport",
    "lat": "230900S",
    "long": "0641900W"
  },
  {
    "stationcode": "EQS",
    "stationname": "Esquel Airport",
    "lat": "425414S",
    "long": "0710808W"
  },
  {
    "stationcode": "CUT",
    "stationname": "Cutral Airport",
    "lat": "385800S",
    "long": "0691500W"
  },
  {
    "stationcode": "SGV",
    "stationname": "Sierra Grande Airport",
    "lat": "413000S",
    "long": "0652100W"
  },
  {
    "stationcode": "GGS",
    "stationname": "Gobernador Gregores Airport",
    "lat": "484655S",
    "long": "0700920W"
  },
  {
    "stationcode": "IRJ",
    "stationname": "La Rioja Airport",
    "lat": "292250S",
    "long": "0664745W"
  },
  {
    "stationcode": "CVI",
    "stationname": "Caleta Olivia Airport",
    "lat": "462600S",
    "long": "0673100W"
  },
  {
    "stationcode": "CTC",
    "stationname": "Catamarca Airport",
    "lat": "283535S",
    "long": "0654504W"
  },
  {
    "stationcode": "REL",
    "stationname": "Trelew Airport",
    "lat": "431400S",
    "long": "0651924W"
  },
  {
    "stationcode": "SLA",
    "stationname": "Gen Belgrano Airport",
    "lat": "245135S",
    "long": "0652913W"
  },
  {
    "stationcode": "ARR",
    "stationname": "Alto Rio Senguerr Airport",
    "lat": "450810S",
    "long": "0704844W"
  },
  {
    "stationcode": "PMQ",
    "stationname": "Perito Moreno Airport",
    "lat": "463217S",
    "long": "0705843W"
  },
  {
    "stationcode": "VCF",
    "stationname": "Valcheta Airport",
    "lat": "404200S",
    "long": "0660900W"
  },
  {
    "stationcode": "RYO",
    "stationname": "Rio Turbio Airport",
    "lat": "513600S",
    "long": "0721300W"
  },
  {
    "stationcode": "AFA",
    "stationname": "San Rafael Airport",
    "lat": "343519S",
    "long": "0682410W"
  },
  {
    "stationcode": "TTG",
    "stationname": "Tartagal Airport",
    "lat": "223100S",
    "long": "0634700W"
  },
  {
    "stationcode": "NQN",
    "stationname": "Neuquen Airport",
    "lat": "385700S",
    "long": "0680908W"
  },
  {
    "stationcode": "UEE",
    "stationname": "Queenstown Airport",
    "lat": "420000S",
    "long": "1453030E"
  },
  {
    "stationcode": "FLS",
    "stationname": "Flinders Island Airport",
    "lat": "400540S",
    "long": "1475938E"
  },
  {
    "stationcode": "HLS",
    "stationname": "St. Helens Airport",
    "lat": "410000S",
    "long": "1481030E"
  },
  {
    "stationcode": "SIO",
    "stationname": "Smithton Airport",
    "lat": "405000S",
    "long": "1450500E"
  },
  {
    "stationcode": "BWT",
    "stationname": "Burnie Wynyard Airport",
    "lat": "405950S",
    "long": "1454336E"
  },
  {
    "stationcode": "DPO",
    "stationname": "Devonport Airport",
    "lat": "411011S",
    "long": "1462549E"
  },
  {
    "stationcode": "KNS",
    "stationname": "King Island Airport",
    "lat": "395239S",
    "long": "1435242E"
  },
  {
    "stationcode": "SRN",
    "stationname": "Strahan Airport",
    "lat": "420927S",
    "long": "1451723E"
  },
  {
    "stationcode": "LST",
    "stationname": "Launceston Airport",
    "lat": "413243S",
    "long": "1471240E"
  },
  {
    "stationcode": "CBI",
    "stationname": "Cape Barren Island Airport",
    "lat": "402325S",
    "long": "1480055E"
  },
  {
    "stationcode": "GEE",
    "stationname": "George Town Airport",
    "lat": "410600S",
    "long": "1465000E"
  },
  {
    "stationcode": "HBA",
    "stationname": "Hobart Airport",
    "lat": "425010S",
    "long": "1473037E"
  },
  {
    "stationcode": "LTB",
    "stationname": "Latrobe Airport",
    "lat": "411400S",
    "long": "1462400E"
  },
  {
    "stationcode": "STF",
    "stationname": "Stephen Island",
    "lat": "093000S",
    "long": "1433400E"
  },
  {
    "stationcode": "GYP",
    "stationname": "Gympie Airport",
    "lat": "261700S",
    "long": "1524200E"
  },
  {
    "stationcode": "EIH",
    "stationname": "Einasleigh Airport",
    "lat": "183000S",
    "long": "1440600E"
  },
  {
    "stationcode": "SRR",
    "stationname": "Dunwich Airport",
    "lat": "273100S",
    "long": "1522600E"
  },
  {
    "stationcode": "BCK",
    "stationname": "Bolwarra Airport",
    "lat": "172400S",
    "long": "1441000E"
  },
  {
    "stationcode": "MOV",
    "stationname": "Moranbah Airport",
    "lat": "220331S",
    "long": "1480435E"
  },
  {
    "stationcode": "TAN",
    "stationname": "Tangalooma Airport",
    "lat": "270830S",
    "long": "1532400E"
  },
  {
    "stationcode": "ZBO",
    "stationname": "Bowen Airport",
    "lat": "200100S",
    "long": "1481300E"
  },
  {
    "stationcode": "THG",
    "stationname": "Thangool Airport",
    "lat": "242943S",
    "long": "1503444E"
  },
  {
    "stationcode": "BEU",
    "stationname": "Bedourie Airport",
    "lat": "242048S",
    "long": "1392742E"
  },
  {
    "stationcode": "CTL",
    "stationname": "Charleville Airport",
    "lat": "262448S",
    "long": "1461545E"
  },
  {
    "stationcode": "DDI",
    "stationname": "Daydream Island Airport",
    "lat": "201000S",
    "long": "1481100E"
  },
  {
    "stationcode": "DFP",
    "stationname": "Drumduff Airport",
    "lat": "160000S",
    "long": "1430200E"
  },
  {
    "stationcode": "DBY",
    "stationname": "Dalby Airport",
    "lat": "271500S",
    "long": "1511000E"
  },
  {
    "stationcode": "DYA",
    "stationname": "Dysart Airport",
    "lat": "223700S",
    "long": "1482200E"
  },
  {
    "stationcode": "LUT",
    "stationname": "Laura Station Airport",
    "lat": "152600S",
    "long": "1444900E"
  },
  {
    "stationcode": "UTB",
    "stationname": "Muttaburra Airport",
    "lat": "224500S",
    "long": "1443500E"
  },
  {
    "stationcode": "XTG",
    "stationname": "Thargomindah Airport",
    "lat": "275911S",
    "long": "1434844E"
  },
  {
    "stationcode": "OKR",
    "stationname": "Yorke Island Airport",
    "lat": "094510S",
    "long": "1432420E"
  },
  {
    "stationcode": "GGD",
    "stationname": "Gregory Downs Airport",
    "lat": "184200S",
    "long": "1391500E"
  },
  {
    "stationcode": "HLV",
    "stationname": "Helenvale Airport",
    "lat": "154100S",
    "long": "1451200E"
  },
  {
    "stationcode": "ULP",
    "stationname": "Quilpie Airport",
    "lat": "263634S",
    "long": "1441529E"
  },
  {
    "stationcode": "CMA",
    "stationname": "Cunnamulla Airport",
    "lat": "280148S",
    "long": "1453720E"
  },
  {
    "stationcode": "CZY",
    "stationname": "Cluny Airport",
    "lat": "243000S",
    "long": "1393000E"
  },
  {
    "stationcode": "WAZ",
    "stationname": "Warwick Airport",
    "lat": "280900S",
    "long": "1515700E"
  },
  {
    "stationcode": "BZP",
    "stationname": "Bizant Airport",
    "lat": "151300S",
    "long": "1443800E"
  },
  {
    "stationcode": "ABH",
    "stationname": "Alpha Airport",
    "lat": "234200S",
    "long": "1463500E"
  },
  {
    "stationcode": "LTV",
    "stationname": "Lotusvale Airport",
    "lat": "170500S",
    "long": "1412200E"
  },
  {
    "stationcode": "WDI",
    "stationname": "Wondai Airport",
    "lat": "261900S",
    "long": "1515200E"
  },
  {
    "stationcode": "XTO",
    "stationname": "Taroom Airport",
    "lat": "254808S",
    "long": "1495356E"
  },
  {
    "stationcode": "DHD",
    "stationname": "Durham Downs Airport",
    "lat": "270400S",
    "long": "1415500E"
  },
  {
    "stationcode": "BCI",
    "stationname": "Barcaldine Airport",
    "lat": "233339S",
    "long": "1451815E"
  },
  {
    "stationcode": "OXO",
    "stationname": "Orientos Airport",
    "lat": "280800S",
    "long": "1413200E"
  },
  {
    "stationcode": "HIG",
    "stationname": "Highbury Airport",
    "lat": "162600S",
    "long": "1430900E"
  },
  {
    "stationcode": "LYT",
    "stationname": "Lady Elliot Island Airport",
    "lat": "240700S",
    "long": "1524200E"
  },
  {
    "stationcode": "LLP",
    "stationname": "Linda Downs Airport",
    "lat": "230100S",
    "long": "1384200E"
  },
  {
    "stationcode": "IFF",
    "stationname": "Iffley Airport",
    "lat": "185500S",
    "long": "1411500E"
  },
  {
    "stationcode": "OKB",
    "stationname": "Fraser Island Airport",
    "lat": "250000S",
    "long": "1531700E"
  },
  {
    "stationcode": "LWH",
    "stationname": "Lawn Hill Airport",
    "lat": "183357S",
    "long": "1383817E"
  },
  {
    "stationcode": "TXR",
    "stationname": "Tanbar Airport",
    "lat": "255500S",
    "long": "1415500E"
  },
  {
    "stationcode": "AYR",
    "stationname": "Ayr Airport",
    "lat": "194000S",
    "long": "1472200E"
  },
  {
    "stationcode": "NTN",
    "stationname": "Normanton Airport",
    "lat": "174101S",
    "long": "1410413E"
  },
  {
    "stationcode": "BHT",
    "stationname": "Brighton Downs Airport",
    "lat": "233000S",
    "long": "1413800E"
  },
  {
    "stationcode": "RKY",
    "stationname": "Rokeby Airport",
    "lat": "133900S",
    "long": "1423700E"
  },
  {
    "stationcode": "CNC",
    "stationname": "Coconut Island Airport",
    "lat": "100301S",
    "long": "1430418E"
  },
  {
    "stationcode": "MWY",
    "stationname": "Miranda Downs Airport",
    "lat": "172200S",
    "long": "1415200E"
  },
  {
    "stationcode": "KPP",
    "stationname": "Kalpowar Airport",
    "lat": "145300S",
    "long": "1441300E"
  },
  {
    "stationcode": "KKP",
    "stationname": "Koolburra Airport",
    "lat": "153500S",
    "long": "1435700E"
  },
  {
    "stationcode": "TDR",
    "stationname": "Theodore Airport",
    "lat": "245900S",
    "long": "1500500E"
  },
  {
    "stationcode": "BVW",
    "stationname": "Batavia Downs Airport",
    "lat": "122700S",
    "long": "1431500E"
  },
  {
    "stationcode": "NSV",
    "stationname": "Noosaville Airport",
    "lat": "262400S",
    "long": "1530400E"
  },
  {
    "stationcode": "SHQ",
    "stationname": "Southport Airport",
    "lat": "275707S",
    "long": "1532504E"
  },
  {
    "stationcode": "BKQ",
    "stationname": "Blackall Airport",
    "lat": "242557S",
    "long": "1452541E"
  },
  {
    "stationcode": "PMK",
    "stationname": "Palm Island Airport",
    "lat": "184519S",
    "long": "1463453E"
  },
  {
    "stationcode": "LDC",
    "stationname": "Lindeman Island Airport",
    "lat": "202700S",
    "long": "1490300E"
  },
  {
    "stationcode": "GLG",
    "stationname": "Glengyle Airport",
    "lat": "244600S",
    "long": "1393500E"
  },
  {
    "stationcode": "TQP",
    "stationname": "Trepell Airport",
    "lat": "215006S",
    "long": "1405317E"
  },
  {
    "stationcode": "EDR",
    "stationname": "Edward River Airport",
    "lat": "145349S",
    "long": "1413635E"
  },
  {
    "stationcode": "DAJ",
    "stationname": "Dauan Island Airport",
    "lat": "092600S",
    "long": "1423100E"
  },
  {
    "stationcode": "OOR",
    "stationname": "Mooraberree Airport",
    "lat": "251442S",
    "long": "1405851E"
  },
  {
    "stationcode": "OXY",
    "stationname": "Morney Airport",
    "lat": "252200S",
    "long": "1412800E"
  },
  {
    "stationcode": "HIP",
    "stationname": "Headingly Airport",
    "lat": "211900S",
    "long": "1381800E"
  },
  {
    "stationcode": "KSV",
    "stationname": "Springvale Airport",
    "lat": "234000S",
    "long": "1404500E"
  },
  {
    "stationcode": "MBH",
    "stationname": "Maryborough Airport",
    "lat": "253048S",
    "long": "1524254E"
  },
  {
    "stationcode": "NSA",
    "stationname": "Noosa Airport",
    "lat": "055438S",
    "long": "0351437W"
  },
  {
    "stationcode": "CUQ",
    "stationname": "Coen Airport",
    "lat": "134546S",
    "long": "1430701E"
  },
  {
    "stationcode": "GBP",
    "stationname": "Gamboola Airport",
    "lat": "163300S",
    "long": "1434000E"
  },
  {
    "stationcode": "MKY",
    "stationname": "Mackay Airport",
    "lat": "211018S",
    "long": "1491047E"
  },
  {
    "stationcode": "DVP",
    "stationname": "Davenport Downs Airport",
    "lat": "241000S",
    "long": "1410500E"
  },
  {
    "stationcode": "NMR",
    "stationname": "Nappamerry Airport",
    "lat": "273800S",
    "long": "1410500E"
  },
  {
    "stationcode": "IGH",
    "stationname": "Ingham Airport",
    "lat": "184800S",
    "long": "1460600E"
  },
  {
    "stationcode": "KDS",
    "stationname": "Kamaran Downs Airport",
    "lat": "242000S",
    "long": "1391000E"
  },
  {
    "stationcode": "HTI",
    "stationname": "Hamilton Island Airport",
    "lat": "202129S",
    "long": "1485706E"
  },
  {
    "stationcode": "CCL",
    "stationname": "Chinchilla Airport",
    "lat": "264620S",
    "long": "1503705E"
  },
  {
    "stationcode": "CML",
    "stationname": "Camooweal Airport",
    "lat": "195500S",
    "long": "1380800E"
  },
  {
    "stationcode": "CXT",
    "stationname": "Charters Towers Airport",
    "lat": "200300S",
    "long": "1461600E"
  },
  {
    "stationcode": "HIS",
    "stationname": "Hayman Island Airport",
    "lat": "200336S",
    "long": "1485301E"
  },
  {
    "stationcode": "SFP",
    "stationname": "Surfers Paradise Airport",
    "lat": "280000S",
    "long": "1532600E"
  },
  {
    "stationcode": "PPP",
    "stationname": "Whitsunday Coast Airport",
    "lat": "202939S",
    "long": "1483311E"
  },
  {
    "stationcode": "STH",
    "stationname": "Strathmore Airport",
    "lat": "175000S",
    "long": "1423200E"
  },
  {
    "stationcode": "HAP",
    "stationname": "Long Island Airport",
    "lat": "213123S",
    "long": "1485750E"
  },
  {
    "stationcode": "AXC",
    "stationname": "Aramac Airport",
    "lat": "230500S",
    "long": "1451500E"
  },
  {
    "stationcode": "HIH",
    "stationname": "Hook Island Airport",
    "lat": "201000S",
    "long": "1485700E"
  },
  {
    "stationcode": "MFL",
    "stationname": "Mount Full Stop Airport",
    "lat": "194000S",
    "long": "1445400E"
  },
  {
    "stationcode": "IFL",
    "stationname": "Innisfail Airport",
    "lat": "173400S",
    "long": "1460200E"
  },
  {
    "stationcode": "BLS",
    "stationname": "Bollon Airport",
    "lat": "280200S",
    "long": "1472200E"
  },
  {
    "stationcode": "JCK",
    "stationname": "Julia Creek Airport",
    "lat": "204006S",
    "long": "1414321E"
  },
  {
    "stationcode": "WEI",
    "stationname": "Weipa Airport",
    "lat": "124043S",
    "long": "1415531E"
  },
  {
    "stationcode": "GPD",
    "stationname": "Mount Gordon Mine Airport",
    "lat": "195958S",
    "long": "1395504E"
  },
  {
    "stationcode": "KGY",
    "stationname": "Kingaroy Airport",
    "lat": "263500S",
    "long": "1515100E"
  },
  {
    "stationcode": "WNR",
    "stationname": "Windorah Airport",
    "lat": "252438S",
    "long": "1424009E"
  },
  {
    "stationcode": "KQR",
    "stationname": "Kararra Airport",
    "lat": "291301S",
    "long": "1164113E"
  },
  {
    "stationcode": "WSY",
    "stationname": "Whitsunday Airstrip",
    "lat": "201732S",
    "long": "1484702E"
  },
  {
    "stationcode": "GIC",
    "stationname": "Boigu Island Airport",
    "lat": "091359S",
    "long": "1421303E"
  },
  {
    "stationcode": "SOI",
    "stationname": "South Molle Island Airport",
    "lat": "204500S",
    "long": "1483500E"
  },
  {
    "stationcode": "MVU",
    "stationname": "Musgrave Airport",
    "lat": "141000S",
    "long": "1434100E"
  },
  {
    "stationcode": "MRL",
    "stationname": "Miners Lake Airport",
    "lat": "200400S",
    "long": "1453500E"
  },
  {
    "stationcode": "MYI",
    "stationname": "Murray Island Airport",
    "lat": "095455S",
    "long": "1440317E"
  },
  {
    "stationcode": "CFP",
    "stationname": "Carpentaria Downs Airport",
    "lat": "184700S",
    "long": "1442100E"
  },
  {
    "stationcode": "TYG",
    "stationname": "Thylungra Airport",
    "lat": "260500S",
    "long": "1432500E"
  },
  {
    "stationcode": "CUD",
    "stationname": "Caloundra Airport",
    "lat": "264800S",
    "long": "1530900E"
  },
  {
    "stationcode": "AUD",
    "stationname": "Augustus Downs Airport",
    "lat": "184000S",
    "long": "1395600E"
  },
  {
    "stationcode": "ABG",
    "stationname": "Abingdon Airport",
    "lat": "174000S",
    "long": "1431200E"
  },
  {
    "stationcode": "ONR",
    "stationname": "Monkira Airport",
    "lat": "244900S",
    "long": "1403200E"
  },
  {
    "stationcode": "ISA",
    "stationname": "Mount Isa Airport",
    "lat": "203921S",
    "long": "1392845E"
  },
  {
    "stationcode": "CTN",
    "stationname": "Cooktown Airport",
    "lat": "152637S",
    "long": "1451058E"
  },
  {
    "stationcode": "BQL",
    "stationname": "Boulia Airport",
    "lat": "225447S",
    "long": "1395403E"
  },
  {
    "stationcode": "BDB",
    "stationname": "Bundaberg Airport",
    "lat": "245409S",
    "long": "1521915E"
  },
  {
    "stationcode": "MRG",
    "stationname": "Mareeba Airport",
    "lat": "170000S",
    "long": "1452600E"
  },
  {
    "stationcode": "KRB",
    "stationname": "Karumba Airport",
    "lat": "172710S",
    "long": "1404958E"
  },
  {
    "stationcode": "INJ",
    "stationname": "Injune Airport",
    "lat": "255100S",
    "long": "1483300E"
  },
  {
    "stationcode": "ZBL",
    "stationname": "Biloela Airport",
    "lat": "242400S",
    "long": "1503000E"
  },
  {
    "stationcode": "RMA",
    "stationname": "Roma Airport",
    "lat": "263242S",
    "long": "1484629E"
  },
  {
    "stationcode": "CQP",
    "stationname": "Cape Flattery Airport",
    "lat": "181200S",
    "long": "1473000E"
  },
  {
    "stationcode": "KUG",
    "stationname": "Kubin Island Airport",
    "lat": "101336S",
    "long": "1421311E"
  },
  {
    "stationcode": "PHQ",
    "stationname": "Phostphate Hill Airport",
    "lat": "214842S",
    "long": "1395524E"
  },
  {
    "stationcode": "UDA",
    "stationname": "Undarra Airport",
    "lat": "181100S",
    "long": "1443600E"
  },
  {
    "stationcode": "MET",
    "stationname": "Moreton Airport",
    "lat": "124500S",
    "long": "1424600E"
  },
  {
    "stationcode": "SBR",
    "stationname": "Saibai Island Airport",
    "lat": "092243S",
    "long": "1423730E"
  },
  {
    "stationcode": "ROH",
    "stationname": "Robinhood Airport",
    "lat": "185100S",
    "long": "1434200E"
  },
  {
    "stationcode": "BFC",
    "stationname": "Bloomfield Airport",
    "lat": "155226S",
    "long": "1451948E"
  },
  {
    "stationcode": "BUC",
    "stationname": "Burketown Airport",
    "lat": "174455S",
    "long": "1393204E"
  },
  {
    "stationcode": "SGO",
    "stationname": "St. George Airport",
    "lat": "280259S",
    "long": "1483543E"
  },
  {
    "stationcode": "HGD",
    "stationname": "Hughenden Airport",
    "lat": "204847S",
    "long": "1441331E"
  },
  {
    "stationcode": "KCE",
    "stationname": "Collinsville Airport",
    "lat": "203300S",
    "long": "1475200E"
  },
  {
    "stationcode": "DMD",
    "stationname": "Doomadgee Airport",
    "lat": "175621S",
    "long": "1384917E"
  },
  {
    "stationcode": "DRD",
    "stationname": "Dorunda Station Airport",
    "lat": "163314S",
    "long": "1414926E"
  },
  {
    "stationcode": "DRR",
    "stationname": "Durrie Airport",
    "lat": "253600S",
    "long": "1401500E"
  },
  {
    "stationcode": "LKD",
    "stationname": "Lakeland Downs Airport",
    "lat": "161000S",
    "long": "1453000E"
  },
  {
    "stationcode": "GTT",
    "stationname": "Georgetown Airport",
    "lat": "181800S",
    "long": "1433200E"
  },
  {
    "stationcode": "TSV",
    "stationname": "Townsville Airport",
    "lat": "191500S",
    "long": "1464600E"
  },
  {
    "stationcode": "ERQ",
    "stationname": "Elrose Mine Airport",
    "lat": "205836S",
    "long": "1410031E"
  },
  {
    "stationcode": "CMQ",
    "stationname": "Clermont Airport",
    "lat": "224600S",
    "long": "1473700E"
  },
  {
    "stationcode": "LZR",
    "stationname": "Lizard Island Airport",
    "lat": "144023S",
    "long": "1452715E"
  },
  {
    "stationcode": "DKI",
    "stationname": "Dunk Island Airport",
    "lat": "175621S",
    "long": "1460829E"
  },
  {
    "stationcode": "NMP",
    "stationname": "New Moon Airport",
    "lat": "191212S",
    "long": "1454619E"
  },
  {
    "stationcode": "ROK",
    "stationname": "Rockhampton Airport",
    "lat": "232255S",
    "long": "1502831E"
  },
  {
    "stationcode": "EMD",
    "stationname": "Emerald Airport",
    "lat": "233412S",
    "long": "1481040E"
  },
  {
    "stationcode": "NBR",
    "stationname": "Nambour Airport",
    "lat": "263200S",
    "long": "1525800E"
  },
  {
    "stationcode": "SSP",
    "stationname": "Silver Planes Airport",
    "lat": "135900S",
    "long": "1433300E"
  },
  {
    "stationcode": "UBB",
    "stationname": "Mabuiag Island Airport",
    "lat": "095702S",
    "long": "1421144E"
  },
  {
    "stationcode": "WON",
    "stationname": "Wondoola Airport",
    "lat": "184000S",
    "long": "1405500E"
  },
  {
    "stationcode": "MCY",
    "stationname": "Maroochydore Airport",
    "lat": "263615S",
    "long": "1530524E"
  },
  {
    "stationcode": "HNK",
    "stationname": "Hinchinbrooke Island Airport",
    "lat": "182300S",
    "long": "1461700E"
  },
  {
    "stationcode": "KOH",
    "stationname": "Koolatah Airport",
    "lat": "155800S",
    "long": "1422500E"
  },
  {
    "stationcode": "GAH",
    "stationname": "Gayndah Airport",
    "lat": "253700S",
    "long": "1513700E"
  },
  {
    "stationcode": "MLV",
    "stationname": "Merluna Airport",
    "lat": "130400S",
    "long": "1422700E"
  },
  {
    "stationcode": "ONG",
    "stationname": "Mornington Airport",
    "lat": "163942S",
    "long": "1391014E"
  },
  {
    "stationcode": "BVI",
    "stationname": "Birdsville Airport",
    "lat": "255331S",
    "long": "1392043E"
  },
  {
    "stationcode": "MXQ",
    "stationname": "Mitchell River Airport",
    "lat": "132322S",
    "long": "0385436W"
  },
  {
    "stationcode": "NLF",
    "stationname": "Darnley Island Airport",
    "lat": "093445S",
    "long": "1434649E"
  },
  {
    "stationcode": "OBA",
    "stationname": "Oban Airport",
    "lat": "211400S",
    "long": "1390200E"
  },
  {
    "stationcode": "GLT",
    "stationname": "Gladstone Airport",
    "lat": "235211S",
    "long": "1511322E"
  },
  {
    "stationcode": "XMY",
    "stationname": "Yam Island Airport",
    "lat": "095357S",
    "long": "1424627E"
  },
  {
    "stationcode": "SRM",
    "stationname": "Sandringham Airport",
    "lat": "240300S",
    "long": "1390400E"
  },
  {
    "stationcode": "LOA",
    "stationname": "Lorraine Airport",
    "lat": "190500S",
    "long": "1395800E"
  },
  {
    "stationcode": "CNJ",
    "stationname": "Cloncurry Airport",
    "lat": "204007S",
    "long": "1403016E"
  },
  {
    "stationcode": "BTX",
    "stationname": "Betoota Airport",
    "lat": "253800S",
    "long": "1404800E"
  },
  {
    "stationcode": "ABM",
    "stationname": "Bamaga Airport",
    "lat": "105703S",
    "long": "1422734E"
  },
  {
    "stationcode": "BIP",
    "stationname": "Bulimba Airport",
    "lat": "160900S",
    "long": "1434500E"
  },
  {
    "stationcode": "BLT",
    "stationname": "Blackwater Airport",
    "lat": "233611S",
    "long": "1484825E"
  },
  {
    "stationcode": "HPE",
    "stationname": "Hope Vale Airport",
    "lat": "144000S",
    "long": "1445500E"
  },
  {
    "stationcode": "MMM",
    "stationname": "Middlemount Airport",
    "lat": "224802S",
    "long": "1484218E"
  },
  {
    "stationcode": "HVB",
    "stationname": "Hervey Bay Airport",
    "lat": "251912S",
    "long": "1525249E"
  },
  {
    "stationcode": "PTI",
    "stationname": "Port Douglas Airport",
    "lat": "162901S",
    "long": "1452705E"
  },
  {
    "stationcode": "TWB",
    "stationname": "Toowoomba Airport",
    "lat": "273234S",
    "long": "1515502E"
  },
  {
    "stationcode": "HAT",
    "stationname": "Heathlands Airport",
    "lat": "114413S",
    "long": "1423438E"
  },
  {
    "stationcode": "DNB",
    "stationname": "Dunbar Airport",
    "lat": "160500S",
    "long": "1422500E"
  },
  {
    "stationcode": "CBY",
    "stationname": "Canobie Airport",
    "lat": "193800S",
    "long": "1405500E"
  },
  {
    "stationcode": "BMP",
    "stationname": "Brampton Island Airport",
    "lat": "204700S",
    "long": "1491400E"
  },
  {
    "stationcode": "IKP",
    "stationname": "Inkerman Airport",
    "lat": "161500S",
    "long": "1413000E"
  },
  {
    "stationcode": "WLL",
    "stationname": "Wollogorand Airport",
    "lat": "171300S",
    "long": "1375500E"
  },
  {
    "stationcode": "GKL",
    "stationname": "Great Keppel Island Airport",
    "lat": "231100S",
    "long": "1505700E"
  },
  {
    "stationcode": "XTR",
    "stationname": "Tara Airport",
    "lat": "271700S",
    "long": "1502800E"
  },
  {
    "stationcode": "WPK",
    "stationname": "Wrotham Park Airport",
    "lat": "163800S",
    "long": "1440000E"
  },
  {
    "stationcode": "TWN",
    "stationname": "Tewantin Airport",
    "lat": "262400S",
    "long": "1530500E"
  },
  {
    "stationcode": "JUN",
    "stationname": "Jundah Airport",
    "lat": "244800S",
    "long": "1430200E"
  },
  {
    "stationcode": "IRG",
    "stationname": "Lockhart River Airport",
    "lat": "124713S",
    "long": "1431817E"
  },
  {
    "stationcode": "CRH",
    "stationname": "Cherribah Airport",
    "lat": "273900S",
    "long": "1515700E"
  },
  {
    "stationcode": "LFP",
    "stationname": "Lakefield Airport",
    "lat": "145515S",
    "long": "1441212E"
  },
  {
    "stationcode": "RSB",
    "stationname": "Roseberth Airport",
    "lat": "254700S",
    "long": "1393700E"
  },
  {
    "stationcode": "SCG",
    "stationname": "Spring Creek Airport",
    "lat": "183800S",
    "long": "1443300E"
  },
  {
    "stationcode": "KWM",
    "stationname": "Kowanyama Airport",
    "lat": "152907S",
    "long": "1414508E"
  },
  {
    "stationcode": "PBY",
    "stationname": "Hamilton/Prosperine Airport",
    "lat": "201700S",
    "long": "1485000E"
  },
  {
    "stationcode": "HID",
    "stationname": "Horn Island Airport",
    "lat": "103511S",
    "long": "1421724E"
  },
  {
    "stationcode": "KML",
    "stationname": "Kamileroi Airport",
    "lat": "193000S",
    "long": "1400500E"
  },
  {
    "stationcode": "MTQ",
    "stationname": "Mitchell Airport",
    "lat": "263500S",
    "long": "1475800E"
  },
  {
    "stationcode": "TIS",
    "stationname": "Thursday Island Airport",
    "lat": "103000S",
    "long": "1420300E"
  },
  {
    "stationcode": "GLM",
    "stationname": "Glenormiston Airport",
    "lat": "221700S",
    "long": "1381900E"
  },
  {
    "stationcode": "WIN",
    "stationname": "Winton Airport",
    "lat": "222149S",
    "long": "1430508E"
  },
  {
    "stationcode": "CDQ",
    "stationname": "Croydon Airport",
    "lat": "181800S",
    "long": "1421500E"
  },
  {
    "stationcode": "DDN",
    "stationname": "Delta Downs Airport",
    "lat": "165500S",
    "long": "1411800E"
  },
  {
    "stationcode": "LLG",
    "stationname": "Chillagoe Airport",
    "lat": "170814S",
    "long": "1443143E"
  },
  {
    "stationcode": "LUU",
    "stationname": "Laura Airport",
    "lat": "154000S",
    "long": "1441000E"
  },
  {
    "stationcode": "MXD",
    "stationname": "Marion Downs Airport",
    "lat": "233000S",
    "long": "1394000E"
  },
  {
    "stationcode": "RLP",
    "stationname": "Rosella Plains Airport",
    "lat": "182600S",
    "long": "1442800E"
  },
  {
    "stationcode": "TWP",
    "stationname": "Torwood Airport",
    "lat": "172200S",
    "long": "1434500E"
  },
  {
    "stationcode": "RTP",
    "stationname": "Rutland Plains Airport",
    "lat": "153600S",
    "long": "1415000E"
  },
  {
    "stationcode": "GOO",
    "stationname": "Goondiwindi Airport",
    "lat": "283100S",
    "long": "1501900E"
  },
  {
    "stationcode": "VNR",
    "stationname": "Vanrook Airport",
    "lat": "165200S",
    "long": "1415500E"
  },
  {
    "stationcode": "WAN",
    "stationname": "Waverney Airport",
    "lat": "252200S",
    "long": "1415600E"
  },
  {
    "stationcode": "CNS",
    "stationname": "Cairns Airport",
    "lat": "165241S",
    "long": "1454456E"
  },
  {
    "stationcode": "BKP",
    "stationname": "Barkly Downs Airport",
    "lat": "210200S",
    "long": "1381500E"
  },
  {
    "stationcode": "DYM",
    "stationname": "Diamantina Lakes Airport",
    "lat": "235200S",
    "long": "1411500E"
  },
  {
    "stationcode": "LRE",
    "stationname": "Longreach Airport",
    "lat": "232555S",
    "long": "1441640E"
  },
  {
    "stationcode": "SNH",
    "stationname": "Stanthorpe Airport",
    "lat": "283800S",
    "long": "1515800E"
  },
  {
    "stationcode": "AUU",
    "stationname": "Aurukun Mission Airport",
    "lat": "131000S",
    "long": "1421500E"
  },
  {
    "stationcode": "DRN",
    "stationname": "Dirranbandi Airport",
    "lat": "283000S",
    "long": "1481530E"
  },
  {
    "stationcode": "SVM",
    "stationname": "St. Paul's Mission Airport",
    "lat": "101200S",
    "long": "1421800E"
  },
  {
    "stationcode": "MNQ",
    "stationname": "Monto Airport",
    "lat": "245300S",
    "long": "1510600E"
  },
  {
    "stationcode": "ZGL",
    "stationname": "South Galway Airport",
    "lat": "254100S",
    "long": "1420700E"
  },
  {
    "stationcode": "AGW",
    "stationname": "Agnew Airport",
    "lat": "120900S",
    "long": "1420900E"
  },
  {
    "stationcode": "DXD",
    "stationname": "Dixie Airport",
    "lat": "151000S",
    "long": "1434000E"
  },
  {
    "stationcode": "GVP",
    "stationname": "Greenvale Airport",
    "lat": "154700S",
    "long": "1443700E"
  },
  {
    "stationcode": "BDD",
    "stationname": "Badu Island Airport",
    "lat": "100900S",
    "long": "1421027E"
  },
  {
    "stationcode": "LTP",
    "stationname": "Lyndhurst Airport",
    "lat": "191200S",
    "long": "1442300E"
  },
  {
    "stationcode": "RCM",
    "stationname": "Richmond Airport",
    "lat": "204205S",
    "long": "1430651E"
  },
  {
    "stationcode": "ISI",
    "stationname": "Isisford Airport",
    "lat": "241200S",
    "long": "1442500E"
  },
  {
    "stationcode": "SQP",
    "stationname": "Starcke Airport",
    "lat": "145000S",
    "long": "1445800E"
  },
  {
    "stationcode": "OSO",
    "stationname": "Osborne Mine Airport",
    "lat": "220454S",
    "long": "1403325E"
  },
  {
    "stationcode": "AAB",
    "stationname": "Arrabury Airport",
    "lat": "264128S",
    "long": "1410250E"
  },
  {
    "stationcode": "SYU",
    "stationname": "Warraber Island Airport",
    "lat": "101226S",
    "long": "1424925E"
  },
  {
    "stationcode": "WUI",
    "stationname": "Murrin Airport",
    "lat": "284219S",
    "long": "1215326E"
  },
  {
    "stationcode": "OKY",
    "stationname": "Oakey Airport",
    "lat": "272441S",
    "long": "1514407E"
  },
  {
    "stationcode": "ORS",
    "stationname": "Waterport Airport",
    "lat": "183800S",
    "long": "1462900E"
  },
  {
    "stationcode": "GBL",
    "stationname": "Goulburn Island Airport",
    "lat": "114000S",
    "long": "1332500E"
  },
  {
    "stationcode": "BYX",
    "stationname": "Baniyala Airport",
    "lat": "131200S",
    "long": "1361400E"
  },
  {
    "stationcode": "DVR",
    "stationname": "Daly River Airport",
    "lat": "134500S",
    "long": "1304100E"
  },
  {
    "stationcode": "HOK",
    "stationname": "Hooker Creek Airport",
    "lat": "182017S",
    "long": "1303815E"
  },
  {
    "stationcode": "MQE",
    "stationname": "Marqua Airport",
    "lat": "224900S",
    "long": "1372100E"
  },
  {
    "stationcode": "TYP",
    "stationname": "Tobermorey Airport",
    "lat": "221700S",
    "long": "1375800E"
  },
  {
    "stationcode": "AYQ",
    "stationname": "Connellan Airport",
    "lat": "251110S",
    "long": "1305832E"
  },
  {
    "stationcode": "KGR",
    "stationname": "Kulgera Airport",
    "lat": "255000S",
    "long": "1333000E"
  },
  {
    "stationcode": "RPB",
    "stationname": "Roper Bar Airport",
    "lat": "144500S",
    "long": "1343300E"
  },
  {
    "stationcode": "PEP",
    "stationname": "Peppimenarti Airport",
    "lat": "140900S",
    "long": "1300600E"
  },
  {
    "stationcode": "DKV",
    "stationname": "Docker River Airport",
    "lat": "245200S",
    "long": "1290700E"
  },
  {
    "stationcode": "MIY",
    "stationname": "Mittiebah Airport",
    "lat": "185400S",
    "long": "1370400E"
  },
  {
    "stationcode": "KBB",
    "stationname": "Kirkimbie Airport",
    "lat": "174600S",
    "long": "1291300E"
  },
  {
    "stationcode": "ELC",
    "stationname": "Elcho Island Airport",
    "lat": "120110S",
    "long": "1353414E"
  },
  {
    "stationcode": "SHU",
    "stationname": "Smith Point Airport",
    "lat": "111000S",
    "long": "1335000E"
  },
  {
    "stationcode": "LEL",
    "stationname": "Lake Evella Airport",
    "lat": "122956S",
    "long": "1354821E"
  },
  {
    "stationcode": "KCS",
    "stationname": "Kings Creek Station Airport",
    "lat": "242300S",
    "long": "1314100E"
  },
  {
    "stationcode": "UTD",
    "stationname": "Nutwood Downs Airport",
    "lat": "155000S",
    "long": "1341000E"
  },
  {
    "stationcode": "CKI",
    "stationname": "Croker Island Airport",
    "lat": "110500S",
    "long": "1323000E"
  },
  {
    "stationcode": "HMG",
    "stationname": "Hermannsburg Airport",
    "lat": "235800S",
    "long": "1324000E"
  },
  {
    "stationcode": "CSD",
    "stationname": "Cresswell Downs Airport",
    "lat": "175800S",
    "long": "1355500E"
  },
  {
    "stationcode": "BRT",
    "stationname": "Bathurst Island Airport",
    "lat": "114600S",
    "long": "1303800E"
  },
  {
    "stationcode": "TCA",
    "stationname": "Tennant Creek Airport",
    "lat": "193804S",
    "long": "1341037E"
  },
  {
    "stationcode": "AWP",
    "stationname": "Austral Downs Airport",
    "lat": "202000S",
    "long": "1371500E"
  },
  {
    "stationcode": "MIZ",
    "stationname": "Mainoru Airport",
    "lat": "141800S",
    "long": "1340500E"
  },
  {
    "stationcode": "LIB",
    "stationname": "Limbunya Airport",
    "lat": "172000S",
    "long": "1295000E"
  },
  {
    "stationcode": "AMX",
    "stationname": "Ammaroo Airport",
    "lat": "214424S",
    "long": "1351424E"
  },
  {
    "stationcode": "FIK",
    "stationname": "Finke Airport",
    "lat": "253500S",
    "long": "1343600E"
  },
  {
    "stationcode": "DLV",
    "stationname": "Delissaville Airport",
    "lat": "122500S",
    "long": "1302030E"
  },
  {
    "stationcode": "EVD",
    "stationname": "Eva Downs Airport",
    "lat": "180500S",
    "long": "1345200E"
  },
  {
    "stationcode": "WOG",
    "stationname": "Woodgreen Airport",
    "lat": "223000S",
    "long": "1341500E"
  },
  {
    "stationcode": "ANZ",
    "stationname": "Angus Downs Airport",
    "lat": "250500S",
    "long": "1321000E"
  },
  {
    "stationcode": "KRD",
    "stationname": "Kurundi Airport",
    "lat": "203000S",
    "long": "1343800E"
  },
  {
    "stationcode": "MTD",
    "stationname": "Mount Sanford Airport",
    "lat": "165900S",
    "long": "1303300E"
  },
  {
    "stationcode": "GTE",
    "stationname": "Alyangula Airport",
    "lat": "135821S",
    "long": "1362731E"
  },
  {
    "stationcode": "MKV",
    "stationname": "Mt. Cavenagh Airport",
    "lat": "255800S",
    "long": "1331200E"
  },
  {
    "stationcode": "GPN",
    "stationname": "Garden Point Airport",
    "lat": "112400S",
    "long": "1302600E"
  },
  {
    "stationcode": "AMT",
    "stationname": "Amata Airport",
    "lat": "261200S",
    "long": "1310700E"
  },
  {
    "stationcode": "MNW",
    "stationname": "Macdonald Downs Airport",
    "lat": "223000S",
    "long": "1351000E"
  },
  {
    "stationcode": "HUB",
    "stationname": "Humbert River Airport",
    "lat": "162923S",
    "long": "1303747E"
  },
  {
    "stationcode": "MRT",
    "stationname": "Moroak Airport",
    "lat": "144900S",
    "long": "1334200E"
  },
  {
    "stationcode": "MNG",
    "stationname": "Maningrida Airport",
    "lat": "120322S",
    "long": "1341403E"
  },
  {
    "stationcode": "BOX",
    "stationname": "Borroloola Airport",
    "lat": "160500S",
    "long": "1361800E"
  },
  {
    "stationcode": "CFI",
    "stationname": "Camfield Airport",
    "lat": "170900S",
    "long": "1312100E"
  },
  {
    "stationcode": "KFG",
    "stationname": "Kalkurung Airport",
    "lat": "172555S",
    "long": "1304829E"
  },
  {
    "stationcode": "MNV",
    "stationname": "Mountain Valley Airport",
    "lat": "140700S",
    "long": "1335000E"
  },
  {
    "stationcode": "HRY",
    "stationname": "Henbury Airport",
    "lat": "244000S",
    "long": "1330500E"
  },
  {
    "stationcode": "RAM",
    "stationname": "Ramingining Airport",
    "lat": "122123S",
    "long": "1345351E"
  },
  {
    "stationcode": "KTR",
    "stationname": "Tindal Airport",
    "lat": "143116S",
    "long": "1322240E"
  },
  {
    "stationcode": "MFP",
    "stationname": "Manners Creek Airport",
    "lat": "220600S",
    "long": "1375900E"
  },
  {
    "stationcode": "YUE",
    "stationname": "Yuendumu Airport",
    "lat": "221600S",
    "long": "1314700E"
  },
  {
    "stationcode": "NRY",
    "stationname": "Newry Airport",
    "lat": "160500S",
    "long": "1292030E"
  },
  {
    "stationcode": "BTD",
    "stationname": "Brunette Downs Airport",
    "lat": "183800S",
    "long": "1355600E"
  },
  {
    "stationcode": "VCD",
    "stationname": "Victoria River Downs Airport",
    "lat": "162413S",
    "long": "1310009E"
  },
  {
    "stationcode": "CTR",
    "stationname": "Cattle Creek Airport",
    "lat": "173500S",
    "long": "1310000E"
  },
  {
    "stationcode": "RPM",
    "stationname": "Ngukurr Airport",
    "lat": "144300S",
    "long": "1344500E"
  },
  {
    "stationcode": "NUB",
    "stationname": "Numbulwar Airport",
    "lat": "141600S",
    "long": "1354300E"
  },
  {
    "stationcode": "TBK",
    "stationname": "Timber Creek Airport",
    "lat": "153700S",
    "long": "1302600E"
  },
  {
    "stationcode": "MSF",
    "stationname": "Mount Swan Airport",
    "lat": "224000S",
    "long": "1345500E"
  },
  {
    "stationcode": "MGT",
    "stationname": "Milingimbi Airport",
    "lat": "120540S",
    "long": "1345337E"
  },
  {
    "stationcode": "AYL",
    "stationname": "Anthony Lagoon Airport",
    "lat": "180100S",
    "long": "1353300E"
  },
  {
    "stationcode": "COB",
    "stationname": "Coolibah Airport",
    "lat": "154000S",
    "long": "1305800E"
  },
  {
    "stationcode": "RDA",
    "stationname": "Rockhampton Downs Airport",
    "lat": "185700S",
    "long": "1351200E"
  },
  {
    "stationcode": "IVW",
    "stationname": "Inverway Airport",
    "lat": "174600S",
    "long": "1291400E"
  },
  {
    "stationcode": "KBJ",
    "stationname": "King Canyon Airport",
    "lat": "241600S",
    "long": "1312940E"
  },
  {
    "stationcode": "AXL",
    "stationname": "Alexandria Airport",
    "lat": "190700S",
    "long": "1364600E"
  },
  {
    "stationcode": "GTS",
    "stationname": "Granites Airport",
    "lat": "203254S",
    "long": "1302100E"
  },
  {
    "stationcode": "NPP",
    "stationname": "Napperby Airport",
    "lat": "223800S",
    "long": "1325000E"
  },
  {
    "stationcode": "SNB",
    "stationname": "Snake Bay Airport",
    "lat": "112000S",
    "long": "1304500E"
  },
  {
    "stationcode": "DYW",
    "stationname": "Daly Waters Airport",
    "lat": "161600S",
    "long": "1332200E"
  },
  {
    "stationcode": "AYD",
    "stationname": "Alroy Downs Airport",
    "lat": "191800S",
    "long": "1355700E"
  },
  {
    "stationcode": "MUP",
    "stationname": "Mulga Park Airport",
    "lat": "255100S",
    "long": "1313600E"
  },
  {
    "stationcode": "PKT",
    "stationname": "Port Keats Airport",
    "lat": "141500S",
    "long": "1293200E"
  },
  {
    "stationcode": "WLO",
    "stationname": "Waterloo Airport",
    "lat": "163800S",
    "long": "1291900E"
  },
  {
    "stationcode": "CDA",
    "stationname": "Cooinda Airport",
    "lat": "125400S",
    "long": "1323100E"
  },
  {
    "stationcode": "MCV",
    "stationname": "Mcarthur River Airport",
    "lat": "162632S",
    "long": "1360459E"
  },
  {
    "stationcode": "LNH",
    "stationname": "Lake Nash Airport",
    "lat": "210000S",
    "long": "1375500E"
  },
  {
    "stationcode": "RPV",
    "stationname": "Roper Valley Airport",
    "lat": "150200S",
    "long": "1340200E"
  },
  {
    "stationcode": "AVG",
    "stationname": "Auvergne Airport",
    "lat": "154500S",
    "long": "1295500E"
  },
  {
    "stationcode": "RRV",
    "stationname": "Robinson River Airport",
    "lat": "164500S",
    "long": "1365500E"
  },
  {
    "stationcode": "GOV",
    "stationname": "Nhulunbuy Airport",
    "lat": "121610S",
    "long": "1364906E"
  },
  {
    "stationcode": "EDD",
    "stationname": "Erlunda Airport",
    "lat": "251500S",
    "long": "1331200E"
  },
  {
    "stationcode": "OPI",
    "stationname": "Oenpelli Airport",
    "lat": "122000S",
    "long": "1330500E"
  },
  {
    "stationcode": "ASP",
    "stationname": "Alice Springs Airport",
    "lat": "234824S",
    "long": "1335408E"
  },
  {
    "stationcode": "WAV",
    "stationname": "Kalkgurung Airport",
    "lat": "173900S",
    "long": "1305300E"
  },
  {
    "stationcode": "JAB",
    "stationname": "Jabiru Airport",
    "lat": "101300S",
    "long": "1323400E"
  },
  {
    "stationcode": "EKD",
    "stationname": "Elkedra Airport",
    "lat": "210200S",
    "long": "1351500E"
  },
  {
    "stationcode": "BCZ",
    "stationname": "Bickerton Island Airport",
    "lat": "134700S",
    "long": "1374800E"
  },
  {
    "stationcode": "OYK",
    "stationname": "Oiapoque Airport",
    "lat": "035200N",
    "long": "0514800W"
  },
  {
    "stationcode": "STM",
    "stationname": "Eduardo Gomes Airport",
    "lat": "022521S",
    "long": "0544734W"
  },
  {
    "stationcode": "BDC",
    "stationname": "Barra Do Corda Airport",
    "lat": "052800S",
    "long": "0451600W"
  },
  {
    "stationcode": "IOS",
    "stationname": "Eduardo Gomes Airport",
    "lat": "144857S",
    "long": "0390200W"
  },
  {
    "stationcode": "MAB",
    "stationname": "Maraba Airport",
    "lat": "052207S",
    "long": "0490817W"
  },
  {
    "stationcode": "SFK",
    "stationname": "Soure Airport",
    "lat": "004200S",
    "long": "0483100W"
  },
  {
    "stationcode": "ORX",
    "stationname": "Oriximina Airport",
    "lat": "014000S",
    "long": "0555000W"
  },
  {
    "stationcode": "RSG",
    "stationname": "Serra Pelada Airport",
    "lat": "055700S",
    "long": "0493900W"
  },
  {
    "stationcode": "CNV",
    "stationname": "Canavieiras Airport",
    "lat": "153800S",
    "long": "0385200W"
  },
  {
    "stationcode": "SSA",
    "stationname": "Airport Luis E. Magalhaes",
    "lat": "125500S",
    "long": "0382000W"
  },
  {
    "stationcode": "CCQ",
    "stationname": "Cachoeira Airport",
    "lat": "123600S",
    "long": "0385800W"
  },
  {
    "stationcode": "BPS",
    "stationname": "Porto Seguro Airport",
    "lat": "162628S",
    "long": "0390451W"
  },
  {
    "stationcode": "ITE",
    "stationname": "Itubera Airport",
    "lat": "134200S",
    "long": "0391200W"
  },
  {
    "stationcode": "CTP",
    "stationname": "Carutapera Airport",
    "lat": "011500S",
    "long": "0460100W"
  },
  {
    "stationcode": "LAZ",
    "stationname": "Bom Jesus Da Lapa Airport",
    "lat": "131600S",
    "long": "0432500W"
  },
  {
    "stationcode": "IMP",
    "stationname": "Imperatriz Airport",
    "lat": "053153S",
    "long": "0472736W"
  },
  {
    "stationcode": "SLZ",
    "stationname": "Mal. Cunha Machado Airport",
    "lat": "023500S",
    "long": "0441400W"
  },
  {
    "stationcode": "BQQ",
    "stationname": "Barra Airport",
    "lat": "110400S",
    "long": "0431000W"
  },
  {
    "stationcode": "CAU",
    "stationname": "Caruaru Airport",
    "lat": "081700S",
    "long": "0355800W"
  },
  {
    "stationcode": "PGG",
    "stationname": "Progresso Airport",
    "lat": "065715S",
    "long": "0552740W"
  },
  {
    "stationcode": "TUR",
    "stationname": "Tucurui Airport",
    "lat": "034710S",
    "long": "0494312W"
  },
  {
    "stationcode": "CMT",
    "stationname": "Cameta Airport",
    "lat": "021500S",
    "long": "0493000W"
  },
  {
    "stationcode": "IPU",
    "stationname": "Ipiau Airport",
    "lat": "140800S",
    "long": "0394400W"
  },
  {
    "stationcode": "AJU",
    "stationname": "Aracaju Airport",
    "lat": "105902S",
    "long": "0370413W"
  },
  {
    "stationcode": "BVM",
    "stationname": "Belmonte Airport",
    "lat": "155100S",
    "long": "0385400W"
  },
  {
    "stationcode": "JEQ",
    "stationname": "Jequie Airport",
    "lat": "135100S",
    "long": "0400500W"
  },
  {
    "stationcode": "CMC",
    "stationname": "Camocim Airport",
    "lat": "025400S",
    "long": "0405000W"
  },
  {
    "stationcode": "BVS",
    "stationname": "Breves Airport",
    "lat": "014000S",
    "long": "0502800W"
  },
  {
    "stationcode": "CKS",
    "stationname": "Carajas Airport",
    "lat": "060704S",
    "long": "0500013W"
  },
  {
    "stationcode": "JPA",
    "stationname": "Castro Pinto Airport",
    "lat": "070854S",
    "long": "0345702W"
  },
  {
    "stationcode": "OBI",
    "stationname": "Obidos Airport",
    "lat": "015206S",
    "long": "0553055W"
  },
  {
    "stationcode": "LEC",
    "stationname": "Chapada Diamantina Airport",
    "lat": "122848S",
    "long": "0411652W"
  },
  {
    "stationcode": "GNM",
    "stationname": "Guanambi Airport",
    "lat": "141300S",
    "long": "0424700W"
  },
  {
    "stationcode": "JDO",
    "stationname": "Regional Do Cariri Airport",
    "lat": "071307S",
    "long": "0391617W"
  },
  {
    "stationcode": "PTQ",
    "stationname": "Porto de Moz Airport",
    "lat": "014420S",
    "long": "0521440W"
  },
  {
    "stationcode": "APY",
    "stationname": "Alto Parnaiba Airport",
    "lat": "090600S",
    "long": "0455800W"
  },
  {
    "stationcode": "SEI",
    "stationname": "Senhor Do Bonfim Airport",
    "lat": "102700S",
    "long": "0401100W"
  },
  {
    "stationcode": "BRA",
    "stationname": "Barreiras Airport",
    "lat": "120445S",
    "long": "0450034W"
  },
  {
    "stationcode": "TXF",
    "stationname": "Teixeira de Freitas Airport",
    "lat": "173128S",
    "long": "0394009W"
  },
  {
    "stationcode": "BSS",
    "stationname": "Balsas Airport",
    "lat": "073000S",
    "long": "0460200W"
  },
  {
    "stationcode": "FOR",
    "stationname": "Pinto Martins Airport",
    "lat": "034635S",
    "long": "0383157W"
  },
  {
    "stationcode": "JCR",
    "stationname": "Jacareacanga Airport",
    "lat": "061401S",
    "long": "0574606W"
  },
  {
    "stationcode": "FLB",
    "stationname": "Cangapara Airport",
    "lat": "065000S",
    "long": "0430000W"
  },
  {
    "stationcode": "PAV",
    "stationname": "Paulo Afonso Airport",
    "lat": "092403S",
    "long": "0381502W"
  },
  {
    "stationcode": "RRN",
    "stationname": "Serra Do Norte Airport",
    "lat": "110000S",
    "long": "0590000W"
  },
  {
    "stationcode": "IRE",
    "stationname": "Irece Airport",
    "lat": "111800S",
    "long": "0415200W"
  },
  {
    "stationcode": "PNZ",
    "stationname": "Petrolina Internacional Airport",
    "lat": "092145S",
    "long": "0403409W"
  },
  {
    "stationcode": "GMS",
    "stationname": "Guimaraes Airport",
    "lat": "020800S",
    "long": "0443600W"
  },
  {
    "stationcode": "PDR",
    "stationname": "Presidente Dutra Municipal Airport",
    "lat": "051800S",
    "long": "0442800W"
  },
  {
    "stationcode": "VAL",
    "stationname": "Valenca Airport",
    "lat": "132200S",
    "long": "0390400W"
  },
  {
    "stationcode": "PHI",
    "stationname": "Pinheiro Airport",
    "lat": "023100S",
    "long": "0450500W"
  },
  {
    "stationcode": "APQ",
    "stationname": "Arapiraca Airport",
    "lat": "094500S",
    "long": "0363900W"
  },
  {
    "stationcode": "PDF",
    "stationname": "Prado Airport",
    "lat": "172100S",
    "long": "0391300W"
  },
  {
    "stationcode": "OIA",
    "stationname": "Ourilandia Airport",
    "lat": "064551S",
    "long": "0510254W"
  },
  {
    "stationcode": "ITN",
    "stationname": "Itabuna Airport",
    "lat": "144800S",
    "long": "0392300W"
  },
  {
    "stationcode": "THE",
    "stationname": "Teresina Airport",
    "lat": "050336S",
    "long": "0424925W"
  },
  {
    "stationcode": "ATM",
    "stationname": "Altamira Airport",
    "lat": "031514S",
    "long": "0521514W"
  },
  {
    "stationcode": "CPU",
    "stationname": "Cururupu Airport",
    "lat": "014800S",
    "long": "0445200W"
  },
  {
    "stationcode": "MEU",
    "stationname": "Monte Dourado Airport",
    "lat": "005304S",
    "long": "0523600W"
  },
  {
    "stationcode": "PHB",
    "stationname": "Santos Dumont Airport",
    "lat": "025336S",
    "long": "0414352W"
  },
  {
    "stationcode": "CDJ",
    "stationname": "Conceicao Do Araguaia Airport",
    "lat": "081700S",
    "long": "0491700W"
  },
  {
    "stationcode": "ALT",
    "stationname": "Alenquer Airport",
    "lat": "015620S",
    "long": "0544450W"
  },
  {
    "stationcode": "MVF",
    "stationname": "Dixsept Rosado Airport",
    "lat": "051202S",
    "long": "0372149W"
  },
  {
    "stationcode": "NAT",
    "stationname": "Augusto Severo Airport",
    "lat": "054605S",
    "long": "0352234W"
  },
  {
    "stationcode": "SNZ",
    "stationname": "Santa Cruz Airport",
    "lat": "225555S",
    "long": "0434307W"
  },
  {
    "stationcode": "CMP",
    "stationname": "Campo Alegre Airport",
    "lat": "092000S",
    "long": "0502000W"
  },
  {
    "stationcode": "XIG",
    "stationname": "Xinguara Municipal Airport",
    "lat": "065800S",
    "long": "0484800W"
  },
  {
    "stationcode": "NPR",
    "stationname": "Municipal Airport",
    "lat": "070733S",
    "long": "0552403W"
  },
  {
    "stationcode": "TUZ",
    "stationname": "Tucuma Airport",
    "lat": "064457S",
    "long": "0510913W"
  },
  {
    "stationcode": "UNA",
    "stationname": "Una Airport",
    "lat": "152119S",
    "long": "0385956W"
  },
  {
    "stationcode": "VDC",
    "stationname": "Vitoria Da Conquista Airport",
    "lat": "145426S",
    "long": "0405459W"
  },
  {
    "stationcode": "ITB",
    "stationname": "Itaituba Airport",
    "lat": "041432S",
    "long": "0560002W"
  },
  {
    "stationcode": "CLN",
    "stationname": "Carolina Airport",
    "lat": "072100S",
    "long": "0472600W"
  },
  {
    "stationcode": "RDC",
    "stationname": "Redencao Airport",
    "lat": "080149S",
    "long": "0495848W"
  },
  {
    "stationcode": "MVS",
    "stationname": "Mucuri Airport",
    "lat": "180500S",
    "long": "0393400W"
  },
  {
    "stationcode": "CRQ",
    "stationname": "Caravelas Airport",
    "lat": "173900S",
    "long": "0391500W"
  },
  {
    "stationcode": "JCM",
    "stationname": "Jacobina Airport",
    "lat": "111100S",
    "long": "0403100W"
  },
  {
    "stationcode": "REC",
    "stationname": "Guararapes International Airport",
    "lat": "080733S",
    "long": "0345526W"
  },
  {
    "stationcode": "BEL",
    "stationname": "Val De Cans Airport",
    "lat": "012245S",
    "long": "0482835W"
  },
  {
    "stationcode": "MCZ",
    "stationname": "Palmares Airport",
    "lat": "093055S",
    "long": "0354745W"
  },
  {
    "stationcode": "GDP",
    "stationname": "Guadalupe Airport",
    "lat": "064655S",
    "long": "0433454W"
  },
  {
    "stationcode": "PCS",
    "stationname": "Picos Airport",
    "lat": "070500S",
    "long": "0412800W"
  },
  {
    "stationcode": "SXX",
    "stationname": "Sao Felix Do Xingu Airport",
    "lat": "063804S",
    "long": "0515904W"
  },
  {
    "stationcode": "MCP",
    "stationname": "Macapa Internacional Airport",
    "lat": "000302N",
    "long": "0510420W"
  },
  {
    "stationcode": "TMT",
    "stationname": "Trombetas Airport",
    "lat": "012919S",
    "long": "0562332W"
  },
  {
    "stationcode": "MTE",
    "stationname": "Monte Alegre Airport",
    "lat": "020100S",
    "long": "0540400W"
  },
  {
    "stationcode": "BMS",
    "stationname": "Brumado Airport",
    "lat": "141300S",
    "long": "0414000W"
  },
  {
    "stationcode": "CPV",
    "stationname": "Joao Suassuna Airport",
    "lat": "071612S",
    "long": "0355347W"
  },
  {
    "stationcode": "BRB",
    "stationname": "Barreirinhas Airport",
    "lat": "025000S",
    "long": "0425000W"
  },
  {
    "stationcode": "FEC",
    "stationname": "Feira De Santana Airport",
    "lat": "121202S",
    "long": "0385426W"
  },
  {
    "stationcode": "ZMD",
    "stationname": "Sena Madureira Airport",
    "lat": "090400S",
    "long": "0684000W"
  },
  {
    "stationcode": "CQS",
    "stationname": "Costa Marques Airport",
    "lat": "122500S",
    "long": "0642000W"
  },
  {
    "stationcode": "LVR",
    "stationname": "Municipio Do Bom Futuro Airport",
    "lat": "130214S",
    "long": "0555632W"
  },
  {
    "stationcode": "PLL",
    "stationname": "Ponta Pelada Airport",
    "lat": "030844S",
    "long": "0595904W"
  },
  {
    "stationcode": "LBR",
    "stationname": "Labrea Airport",
    "lat": "071644S",
    "long": "0644611W"
  },
  {
    "stationcode": "ITA",
    "stationname": "Itacoatiara Airport",
    "lat": "031200S",
    "long": "0583000W"
  },
  {
    "stationcode": "CZS",
    "stationname": "Campo Internacional Airport",
    "lat": "073600S",
    "long": "0724610W"
  },
  {
    "stationcode": "PIN",
    "stationname": "Parintins Airport",
    "lat": "024026S",
    "long": "0564641W"
  },
  {
    "stationcode": "NVP",
    "stationname": "Novo Aripuana Airport",
    "lat": "050800S",
    "long": "0602200W"
  },
  {
    "stationcode": "JPR",
    "stationname": "Ji-Parana Airport",
    "lat": "105215S",
    "long": "0615049W"
  },
  {
    "stationcode": "HUW",
    "stationname": "Humaita Airport",
    "lat": "073208S",
    "long": "0630416W"
  },
  {
    "stationcode": "IPG",
    "stationname": "Ipiranga Airport",
    "lat": "032000S",
    "long": "0692500W"
  },
  {
    "stationcode": "AQM",
    "stationname": "Ariquemes Airport",
    "lat": "095600S",
    "long": "0630400W"
  },
  {
    "stationcode": "FBA",
    "stationname": "Fonte Boa Airport",
    "lat": "023157S",
    "long": "0660500W"
  },
  {
    "stationcode": "RBB",
    "stationname": "Borba Airport",
    "lat": "042400S",
    "long": "0593500W"
  },
  {
    "stationcode": "BVH",
    "stationname": "Vilhena Airport",
    "lat": "124140S",
    "long": "0600554W"
  },
  {
    "stationcode": "PBQ",
    "stationname": "Pimenta Bueno Airport",
    "lat": "113805S",
    "long": "0611008W"
  },
  {
    "stationcode": "PVH",
    "stationname": "Belmonte Airport",
    "lat": "084233S",
    "long": "0635408W"
  },
  {
    "stationcode": "OTT",
    "stationname": "Andre Maggi Airport",
    "lat": "095358S",
    "long": "0583455W"
  },
  {
    "stationcode": "OLC",
    "stationname": "Sao Paulo de Olivenca Airport",
    "lat": "032811S",
    "long": "0685517W"
  },
  {
    "stationcode": "MAO",
    "stationname": "Eduardo Gomes International Airport",
    "lat": "030219S",
    "long": "0600259W"
  },
  {
    "stationcode": "BCR",
    "stationname": "Boca Do Acre Airport",
    "lat": "083800S",
    "long": "0672500W"
  },
  {
    "stationcode": "IRZ",
    "stationname": "Santa Isable Rio Negro Airport",
    "lat": "002243S",
    "long": "0645932W"
  },
  {
    "stationcode": "TBT",
    "stationname": "Tabatinga Internacional Airport",
    "lat": "041521S",
    "long": "0695610W"
  },
  {
    "stationcode": "MNX",
    "stationname": "Manicore Airport",
    "lat": "054900S",
    "long": "0611700W"
  },
  {
    "stationcode": "ERN",
    "stationname": "Eirunepe Airport",
    "lat": "063823S",
    "long": "0695248W"
  },
  {
    "stationcode": "CIZ",
    "stationname": "Coari Airport",
    "lat": "040803S",
    "long": "0630757W"
  },
  {
    "stationcode": "AZL",
    "stationname": "Fazenda Tucunare Airport",
    "lat": "132821S",
    "long": "0585103W"
  },
  {
    "stationcode": "BVB",
    "stationname": "Boa Vista Airport",
    "lat": "025047N",
    "long": "0604124W"
  },
  {
    "stationcode": "RBR",
    "stationname": "Pres. Medici Airport",
    "lat": "095209S",
    "long": "0675339W"
  },
  {
    "stationcode": "MBZ",
    "stationname": "Maues Airport",
    "lat": "032500S",
    "long": "0574000W"
  },
  {
    "stationcode": "OAL",
    "stationname": "Cacoal Airport",
    "lat": "112559S",
    "long": "0612840W"
  },
  {
    "stationcode": "CSW",
    "stationname": "Colorado do Oeste Airport",
    "lat": "130110S",
    "long": "0611005W"
  },
  {
    "stationcode": "CAF",
    "stationname": "Carauari Airport",
    "lat": "045215S",
    "long": "0665350W"
  },
  {
    "stationcode": "BAZ",
    "stationname": "Barbelos Airport",
    "lat": "005852S",
    "long": "0625506W"
  },
  {
    "stationcode": "SJL",
    "stationname": "Da Cachoeira Airport",
    "lat": "000859S",
    "long": "0665912W"
  },
  {
    "stationcode": "PIG",
    "stationname": "Pitinga Airport",
    "lat": "244500S",
    "long": "0514300W"
  },
  {
    "stationcode": "TFF",
    "stationname": "Tefe Airport",
    "lat": "032300S",
    "long": "0644327W"
  },
  {
    "stationcode": "TRQ",
    "stationname": "Tarauaca Airport",
    "lat": "080900S",
    "long": "0704600W"
  },
  {
    "stationcode": "GJM",
    "stationname": "Guajara-Mirim Airport",
    "lat": "105000S",
    "long": "0652000W"
  },
  {
    "stationcode": "FEJ",
    "stationname": "Feijo Airport",
    "lat": "080900S",
    "long": "0702100W"
  },
  {
    "stationcode": "YIF",
    "stationname": "Pakuashipi Airport",
    "lat": "511236N",
    "long": "0583927W"
  },
  {
    "stationcode": "YBX",
    "stationname": "Blanc Sablon Airport",
    "lat": "512600N",
    "long": "0571300W"
  },
  {
    "stationcode": "ZKG",
    "stationname": "Kegaska Airport",
    "lat": "501146N",
    "long": "0611557W"
  },
  {
    "stationcode": "ZTB",
    "stationname": "Tete-A-La Baleine Airport",
    "lat": "504028N",
    "long": "0592301W"
  },
  {
    "stationcode": "ZLT",
    "stationname": "La Tabatiere Airport",
    "lat": "504951N",
    "long": "0585831W"
  },
  {
    "stationcode": "YHR",
    "stationname": "Chevery Airport",
    "lat": "502809N",
    "long": "0593810W"
  },
  {
    "stationcode": "ZGS",
    "stationname": "Gethsemani Airport",
    "lat": "501535N",
    "long": "0604029W"
  },
  {
    "stationcode": "YIB",
    "stationname": "Atikokan Airport",
    "lat": "484500N",
    "long": "0913700W"
  },
  {
    "stationcode": "YZS",
    "stationname": "Coral Harbour Airport",
    "lat": "641200N",
    "long": "0832200W"
  },
  {
    "stationcode": "YRB",
    "stationname": "Resolute Airport",
    "lat": "744300N",
    "long": "0945800W"
  },
  {
    "stationcode": "YPL",
    "stationname": "Pickle Lake Airport",
    "lat": "512647N",
    "long": "0901301W"
  },
  {
    "stationcode": "ZWL",
    "stationname": "Wollaston Lake Airport",
    "lat": "580626N",
    "long": "1031022W"
  },
  {
    "stationcode": "YYN",
    "stationname": "Swift Current Airport",
    "lat": "501700N",
    "long": "1074100W"
  },
  {
    "stationcode": "YLJ",
    "stationname": "Meadow Lake Airport",
    "lat": "540731N",
    "long": "1083122W"
  },
  {
    "stationcode": "YQV",
    "stationname": "Yorkton Airport",
    "lat": "511600N",
    "long": "1022800W"
  },
  {
    "stationcode": "YNL",
    "stationname": "Points North Landing Airport",
    "lat": "581630N",
    "long": "1040452W"
  },
  {
    "stationcode": "YSF",
    "stationname": "Stony Rapids Airport",
    "lat": "591500N",
    "long": "1055000W"
  },
  {
    "stationcode": "YQW",
    "stationname": "North Battleford Airport",
    "lat": "524600N",
    "long": "1081500W"
  },
  {
    "stationcode": "YDJ",
    "stationname": "Hatchet Lake Airport",
    "lat": "583940N",
    "long": "1023220W"
  },
  {
    "stationcode": "YMJ",
    "stationname": "Moose Jaw Airport",
    "lat": "502000N",
    "long": "1053300W"
  },
  {
    "stationcode": "XCL",
    "stationname": "Cluff Lake Airport",
    "lat": "582300N",
    "long": "1093100W"
  },
  {
    "stationcode": "YXE",
    "stationname": "Saskatoon Airport",
    "lat": "521015N",
    "long": "1064159W"
  },
  {
    "stationcode": "YPA",
    "stationname": "Prince Albert Airport",
    "lat": "531305N",
    "long": "1054024W"
  },
  {
    "stationcode": "YKY",
    "stationname": "Kindersley Airport",
    "lat": "512700N",
    "long": "1091000W"
  },
  {
    "stationcode": "YKJ",
    "stationname": "Key Lake Airport",
    "lat": "571500N",
    "long": "1053600W"
  },
  {
    "stationcode": "YDR",
    "stationname": "Broadview Airport",
    "lat": "502200N",
    "long": "1023500W"
  },
  {
    "stationcode": "YEN",
    "stationname": "Estevan Airport",
    "lat": "490400N",
    "long": "1030000W"
  },
  {
    "stationcode": "YBE",
    "stationname": "Uranium City Airport",
    "lat": "593342N",
    "long": "1082848W"
  },
  {
    "stationcode": "KNY",
    "stationname": "Kinoosao Airport",
    "lat": "570400N",
    "long": "1020200W"
  },
  {
    "stationcode": "YQR",
    "stationname": "Regina Airport",
    "lat": "502546N",
    "long": "1043926W"
  },
  {
    "stationcode": "YVC",
    "stationname": "La Ronge Airport",
    "lat": "550500N",
    "long": "1052000W"
  },
  {
    "stationcode": "YHB",
    "stationname": "Hudson Bay Airport",
    "lat": "524907N",
    "long": "1021840W"
  },
  {
    "stationcode": "YVT",
    "stationname": "Buffalo Narrows Airport",
    "lat": "555100N",
    "long": "1082900W"
  },
  {
    "stationcode": "YTT",
    "stationname": "Tisdale Airport",
    "lat": "525100N",
    "long": "1040400W"
  },
  {
    "stationcode": "ZFD",
    "stationname": "Fond Du Lac Airport",
    "lat": "592007N",
    "long": "1071104W"
  },
  {
    "stationcode": "YKC",
    "stationname": "Collins Bay Airport",
    "lat": "581400N",
    "long": "1034000W"
  },
  {
    "stationcode": "YCQ",
    "stationname": "Chetwynd Airport",
    "lat": "554200N",
    "long": "1214000W"
  },
  {
    "stationcode": "YXJ",
    "stationname": "Fort St. John Airport",
    "lat": "561400N",
    "long": "1204400W"
  },
  {
    "stationcode": "YDQ",
    "stationname": "Dawson Creek Airport",
    "lat": "554400N",
    "long": "1201100W"
  },
  {
    "stationcode": "GYM",
    "stationname": "Gen Jose M Yanez Airport",
    "lat": "275808N",
    "long": "1105531W"
  },
  {
    "stationcode": "XAL",
    "stationname": "Alamos Airport",
    "lat": "270100N",
    "long": "1085600W"
  },
  {
    "stationcode": "CEN",
    "stationname": "Ciudad Obregon Airport",
    "lat": "272334N",
    "long": "1094959W"
  },
  {
    "stationcode": "UAC",
    "stationname": "San Luis Rio Colorado Airport",
    "lat": "322700N",
    "long": "1144800W"
  },
  {
    "stationcode": "HMO",
    "stationname": "Gen Pesqueira Garcia Airport",
    "lat": "290545N",
    "long": "1110252W"
  },
  {
    "stationcode": "CNA",
    "stationname": "Cananea Airport",
    "lat": "310357N",
    "long": "1100549W"
  },
  {
    "stationcode": "PPE",
    "stationname": "Puerto Penasco Airport",
    "lat": "312106N",
    "long": "1131822W"
  },
  {
    "stationcode": "NOG",
    "stationname": "Nogales Airport",
    "lat": "311334N",
    "long": "1105833W"
  },
  {
    "stationcode": "KPW",
    "stationname": "Keperveyem Airport",
    "lat": "675042N",
    "long": "1660823E"
  },
  {
    "stationcode": "KVM",
    "stationname": "Markovo Airport",
    "lat": "643949N",
    "long": "1702454E"
  },
  {
    "stationcode": "CKH",
    "stationname": "Chokurdah Airport",
    "lat": "703728N",
    "long": "1475348E"
  },
  {
    "stationcode": "BGN",
    "stationname": "Belaya Gora Airport",
    "lat": "683325N",
    "long": "1461352E"
  },
  {
    "stationcode": "CYX",
    "stationname": "Cherskiy Airport",
    "lat": "684430N",
    "long": "1612015E"
  },
  {
    "stationcode": "OHO",
    "stationname": "Okhotsk Airport",
    "lat": "592451N",
    "long": "1430326E"
  },
  {
    "stationcode": "PVS",
    "stationname": "Provideniya Airport",
    "lat": "642247N",
    "long": "1731433W"
  },
  {
    "stationcode": "PWE",
    "stationname": "Pevek Airport",
    "lat": "694700N",
    "long": "1703549E"
  },
  {
    "stationcode": "DYR",
    "stationname": "Anadyr Airport",
    "lat": "644406N",
    "long": "1774429E"
  },
  {
    "stationcode": "PKC",
    "stationname": "Petropavlovsk-Kamchatsky Airport",
    "lat": "531010N",
    "long": "1582703E"
  },
  {
    "stationcode": "PVX",
    "stationname": "Pope Vanoy Airport",
    "lat": "642200N",
    "long": "1731400E"
  },
  {
    "stationcode": "FHU",
    "stationname": "Fort Huachuca/Sierra Vista Municipal Airport",
    "lat": "313519N",
    "long": "1102040W"
  },
  {
    "stationcode": "PJB",
    "stationname": "Payson Airport",
    "lat": "341540N",
    "long": "1112030W"
  },
  {
    "stationcode": "BGT",
    "stationname": "Bagdad Airport",
    "lat": "343400N",
    "long": "1131100W"
  },
  {
    "stationcode": "DVT",
    "stationname": "Phoenix-Deer Valley Airport",
    "lat": "334102N",
    "long": "1120500W"
  },
  {
    "stationcode": "IGM",
    "stationname": "Kingman Airport",
    "lat": "351524N",
    "long": "1135632W"
  },
  {
    "stationcode": "SCF",
    "stationname": "Scottsdale Municipal Airport",
    "lat": "332627N",
    "long": "1120039W"
  },
  {
    "stationcode": "GCW",
    "stationname": "Grand Canyon West Airport",
    "lat": "355925N",
    "long": "1134859W"
  },
  {
    "stationcode": "SOW",
    "stationname": "Show Low Airport",
    "lat": "341600N",
    "long": "1100000W"
  },
  {
    "stationcode": "PXL",
    "stationname": "Polacca Airport",
    "lat": "355000N",
    "long": "1102300W"
  },
  {
    "stationcode": "GYR",
    "stationname": "Litchfield Airport",
    "lat": "332523N",
    "long": "1122227W"
  },
  {
    "stationcode": "MYH",
    "stationname": "Marble Canyon Airport",
    "lat": "364900N",
    "long": "1113900W"
  },
  {
    "stationcode": "TUS",
    "stationname": "Tucson International Airport",
    "lat": "320730N",
    "long": "1105657W"
  },
  {
    "stationcode": "SDX",
    "stationname": "Sedona Airport",
    "lat": "345058N",
    "long": "1114716W"
  },
  {
    "stationcode": "FLG",
    "stationname": "Pulliam Field",
    "lat": "350818N",
    "long": "1114016W"
  },
  {
    "stationcode": "PGS",
    "stationname": "Peach Springs Airport",
    "lat": "353200N",
    "long": "1132500W"
  },
  {
    "stationcode": "HAE",
    "stationname": "Havasupai Airport",
    "lat": "361350N",
    "long": "1124010W"
  },
  {
    "stationcode": "VLE",
    "stationname": "J. T. Robidoux Airport",
    "lat": "353900N",
    "long": "1121200W"
  },
  {
    "stationcode": "GLB",
    "stationname": "San Carlos Apache Airport",
    "lat": "332111N",
    "long": "1104002W"
  },
  {
    "stationcode": "YUM",
    "stationname": "Yuma International Airport",
    "lat": "323924N",
    "long": "1143622W"
  },
  {
    "stationcode": "WTR",
    "stationname": "White River Airport",
    "lat": "334900N",
    "long": "1095900W"
  },
  {
    "stationcode": "NGC",
    "stationname": "North Rim Airport",
    "lat": "362323N",
    "long": "1120745W"
  },
  {
    "stationcode": "BXK",
    "stationname": "Buckeye Airport",
    "lat": "332200N",
    "long": "1123500W"
  },
  {
    "stationcode": "BSQ",
    "stationname": "Bisbee Municipal Airport",
    "lat": "312150N",
    "long": "1095252W"
  },
  {
    "stationcode": "DGL",
    "stationname": "Douglas Municipal Airport",
    "lat": "312810N",
    "long": "1093610W"
  },
  {
    "stationcode": "TBC",
    "stationname": "Tuba City Airport",
    "lat": "360800N",
    "long": "1111400W"
  },
  {
    "stationcode": "AVW",
    "stationname": "Avra Valley Airport",
    "lat": "322433N",
    "long": "1111259W"
  },
  {
    "stationcode": "IFP",
    "stationname": "Laughlin Bullhead International Airport",
    "lat": "350943N",
    "long": "1143336W"
  },
  {
    "stationcode": "INW",
    "stationname": "Winslow Airport",
    "lat": "350100N",
    "long": "1104400W"
  },
  {
    "stationcode": "HBK",
    "stationname": "Municipal Airport",
    "lat": "345626N",
    "long": "1100818W"
  },
  {
    "stationcode": "DUG",
    "stationname": "Bisbee-Douglas International Airport",
    "lat": "312800N",
    "long": "1093600W"
  },
  {
    "stationcode": "TYZ",
    "stationname": "Taylor Airport",
    "lat": "342800N",
    "long": "1100500W"
  },
  {
    "stationcode": "AZA",
    "stationname": "Williams Gateway Airport",
    "lat": "331836N",
    "long": "1113925W"
  },
  {
    "stationcode": "OLS",
    "stationname": "Nogales International Airport",
    "lat": "312505N",
    "long": "1105051W"
  },
  {
    "stationcode": "SAD",
    "stationname": "Safford Airport",
    "lat": "325100N",
    "long": "1093800W"
  },
  {
    "stationcode": "GCN",
    "stationname": "National Park Airport",
    "lat": "355709N",
    "long": "1120849W"
  },
  {
    "stationcode": "CFT",
    "stationname": "Morenci Airport",
    "lat": "325700N",
    "long": "1091200W"
  },
  {
    "stationcode": "MVM",
    "stationname": "Monument Valley Airport",
    "lat": "364300N",
    "long": "1101342W"
  },
  {
    "stationcode": "CGZ",
    "stationname": "Casa Grande Municipal Airport",
    "lat": "325300N",
    "long": "1114500W"
  },
  {
    "stationcode": "SJN",
    "stationname": "Saint Johns Municipal Airport",
    "lat": "343100N",
    "long": "1092300W"
  },
  {
    "stationcode": "CTW",
    "stationname": "Cottonwood Airport",
    "lat": "344349N",
    "long": "1120207W"
  },
  {
    "stationcode": "PRC",
    "stationname": "Prescott Airport",
    "lat": "343916N",
    "long": "1122511W"
  },
  {
    "stationcode": "CWX",
    "stationname": "Cochise County Airport",
    "lat": "321443N",
    "long": "1095340W"
  },
  {
    "stationcode": "HII",
    "stationname": "Lake Havasu City Municipal Airport",
    "lat": "343406N",
    "long": "1142119W"
  },
  {
    "stationcode": "PHX",
    "stationname": "Sky Harbor International Airport",
    "lat": "332603N",
    "long": "1120042W"
  },
  {
    "stationcode": "MSC",
    "stationname": "Falcon Field",
    "lat": "332737N",
    "long": "1114342W"
  },
  {
    "stationcode": "PGA",
    "stationname": "Page Airport",
    "lat": "365527N",
    "long": "1112652W"
  },
  {
    "stationcode": "HNM",
    "stationname": "Hana Airport",
    "lat": "204744N",
    "long": "1560052W"
  },
  {
    "stationcode": "HPV",
    "stationname": "Princeville Airport",
    "lat": "221245N",
    "long": "1592648W"
  },
  {
    "stationcode": "JHM",
    "stationname": "Kapalua Airport",
    "lat": "205747N",
    "long": "1564023W"
  },
  {
    "stationcode": "BKH",
    "stationname": "Barking Sands Airport",
    "lat": "215800N",
    "long": "1594300W"
  },
  {
    "stationcode": "PAK",
    "stationname": "Port Allen Airport",
    "lat": "215500N",
    "long": "1593500W"
  },
  {
    "stationcode": "OGG",
    "stationname": "Kahului Airport",
    "lat": "205355N",
    "long": "1562550W"
  },
  {
    "stationcode": "UPP",
    "stationname": "Upolu Point Airport",
    "lat": "201600N",
    "long": "1555100W"
  },
  {
    "stationcode": "LUP",
    "stationname": "Kalaupapa Airport",
    "lat": "211237N",
    "long": "1565832W"
  },
  {
    "stationcode": "MUE",
    "stationname": "Kamuela Airport",
    "lat": "200005N",
    "long": "1554005W"
  },
  {
    "stationcode": "ITO",
    "stationname": "Hilo International Airport",
    "lat": "194317N",
    "long": "1550254W"
  },
  {
    "stationcode": "HDH",
    "stationname": "Dillingham Airfield",
    "lat": "213447N",
    "long": "1581150W"
  },
  {
    "stationcode": "MKK",
    "stationname": "Molokai Airport",
    "lat": "210906N",
    "long": "1570552W"
  },
  {
    "stationcode": "NAX",
    "stationname": "Barbers Point Airport",
    "lat": "211800N",
    "long": "1580700W"
  },
  {
    "stationcode": "LIH",
    "stationname": "Kauai Island Airport",
    "lat": "215835N",
    "long": "1592010W"
  },
  {
    "stationcode": "JRF",
    "stationname": "Kalaeloa Airport",
    "lat": "211826N",
    "long": "1580413W"
  },
  {
    "stationcode": "BLW",
    "stationname": "Bellows Field",
    "lat": "044557N",
    "long": "0451418E"
  },
  {
    "stationcode": "HNL",
    "stationname": "Honolulu International Airport T2",
    "lat": "211940N",
    "long": "1575520W"
  },
  {
    "stationcode": "KOA",
    "stationname": "Keahole Airport",
    "lat": "194400N",
    "long": "1560300W"
  },
  {
    "stationcode": "WKL",
    "stationname": "Waikoloa Airport",
    "lat": "195500N",
    "long": "1555200W"
  },
  {
    "stationcode": "LNY",
    "stationname": "Lanai Airport",
    "lat": "204708N",
    "long": "1565705W"
  },
  {
    "stationcode": "ZVJ",
    "stationname": "EK Bus Station",
    "lat": "242500N",
    "long": "0543800E"
  },
  {
    "stationcode": "XNB",
    "stationname": "Travel Mall EY Bus Station",
    "lat": "251508N",
    "long": "0551648E"
  },
  {
    "stationcode": "QXZ",
    "stationname": "Woergl Bus Service",
    "lat": "472900N",
    "long": "0120400E"
  },
  {
    "stationcode": "XOH",
    "stationname": "Kitzbuehel Bus Station",
    "lat": "472653N",
    "long": "0122321E"
  },
  {
    "stationcode": "XOO",
    "stationname": "Bus Station",
    "lat": "465804N",
    "long": "0110025E"
  },
  {
    "stationcode": "XOV",
    "stationname": "Bus Station",
    "lat": "470753N",
    "long": "0101610E"
  },
  {
    "stationcode": "QDI",
    "stationname": "Dornbirn Bus Station",
    "lat": "472500N",
    "long": "0094359E"
  },
  {
    "stationcode": "XOI",
    "stationname": "Bus Station",
    "lat": "470042N",
    "long": "0101727E"
  },
  {
    "stationcode": "XOW",
    "stationname": "Bus Station",
    "lat": "471943N",
    "long": "0111111E"
  },
  {
    "stationcode": "ZAY",
    "stationname": "De Keyserlei Bus Station",
    "lat": "511300N",
    "long": "0042500E"
  },
  {
    "stationcode": "ZKY",
    "stationname": "Verbier Bus Station",
    "lat": "460600N",
    "long": "0071300E"
  },
  {
    "stationcode": "ZKI",
    "stationname": "Saas-Fee Bus Station",
    "lat": "460700N",
    "long": "0075500E"
  },
  {
    "stationcode": "ZDB",
    "stationname": "Adelboden Bus Station",
    "lat": "462900N",
    "long": "0073400E"
  },
  {
    "stationcode": "ZJD",
    "stationname": "Lenzerheide/Lai Bus Station",
    "lat": "464500N",
    "long": "0093300E"
  },
  {
    "stationcode": "FSA",
    "stationname": "CZ Bus Station",
    "lat": "230222N",
    "long": "1130629E"
  },
  {
    "stationcode": "HHS",
    "stationname": "CZ Bus Station",
    "lat": "223117N",
    "long": "1132034E"
  },
  {
    "stationcode": "NFF",
    "stationname": "CZ Bus Station",
    "lat": "230216N",
    "long": "1130908E"
  },
  {
    "stationcode": "HSQ",
    "stationname": "Bus Station",
    "lat": "301452N",
    "long": "1150355E"
  },
  {
    "stationcode": "ZIU",
    "stationname": "CZ Bus Station",
    "lat": "221644N",
    "long": "1133400E"
  },
  {
    "stationcode": "NDH",
    "stationname": "CZ Bus Station",
    "lat": "224910N",
    "long": "1131502E"
  },
  {
    "stationcode": "KYY",
    "stationname": "Bus Station",
    "lat": "453526N",
    "long": "0845215E"
  },
  {
    "stationcode": "HNZ",
    "stationname": "CZ Bus Station",
    "lat": "224343N",
    "long": "1141412E"
  },
  {
    "stationcode": "JBD",
    "stationname": "CZ Bus Station",
    "lat": "223509N",
    "long": "1130509E"
  },
  {
    "stationcode": "VNL",
    "stationname": "Bus Station",
    "lat": "223650N",
    "long": "0834245W"
  },
  {
    "stationcode": "ZDN",
    "stationname": "Brno Bus Station",
    "lat": "491126N",
    "long": "0163646E"
  },
  {
    "stationcode": "PFL",
    "stationname": "Florence Bus Station",
    "lat": "500521N",
    "long": "0142626E"
  },
  {
    "stationcode": "ZIK",
    "stationname": "Karlovy Vary Bus Station",
    "lat": "501300N",
    "long": "0125400E"
  },
  {
    "stationcode": "QBB",
    "stationname": "Bonn Thomastrasse Bus Station",
    "lat": "504407N",
    "long": "0070514E"
  },
  {
    "stationcode": "ZPR",
    "stationname": "Rosenheim Bus Station",
    "lat": "475100N",
    "long": "0120700E"
  },
  {
    "stationcode": "ZNV",
    "stationname": "Bus Station",
    "lat": "502100N",
    "long": "0073600E"
  },
  {
    "stationcode": "XET",
    "stationname": "Karlsruhe Bus Station",
    "lat": "490017N",
    "long": "0082309E"
  },
  {
    "stationcode": "OBZ",
    "stationname": "Bus Station",
    "lat": "535512N",
    "long": "0100111E"
  },
  {
    "stationcode": "DRX",
    "stationname": "Bus Station",
    "lat": "510225N",
    "long": "0134350E"
  },
  {
    "stationcode": "ZCD",
    "stationname": "Bamberg Bus Station",
    "lat": "495400N",
    "long": "0105400E"
  },
  {
    "stationcode": "XHI",
    "stationname": "Herzogenaurach Bus Service",
    "lat": "493400N",
    "long": "0105400E"
  },
  {
    "stationcode": "HBZ",
    "stationname": "Bus Station",
    "lat": "523758N",
    "long": "0094412E"
  },
  {
    "stationcode": "ZRW",
    "stationname": "Rastatt Bus Service",
    "lat": "485100N",
    "long": "0081300E"
  },
  {
    "stationcode": "ZQY",
    "stationname": "Giessen Bus Service",
    "lat": "503500N",
    "long": "0084200E"
  },
  {
    "stationcode": "ZIL",
    "stationname": "Bus Service",
    "lat": "555200N",
    "long": "0095000E"
  },
  {
    "stationcode": "ZIM",
    "stationname": "Odense Bus Service",
    "lat": "552400N",
    "long": "0102500E"
  },
  {
    "stationcode": "XNR",
    "stationname": "Aabenraa Bus Service",
    "lat": "550300N",
    "long": "0092500E"
  },
  {
    "stationcode": "QWQ",
    "stationname": "Struer Bus Service",
    "lat": "562900N",
    "long": "0083600E"
  },
  {
    "stationcode": "QXF",
    "stationname": "Vestbjerg Z1 Bus Service",
    "lat": "570800N",
    "long": "0095800E"
  },
  {
    "stationcode": "QHJ",
    "stationname": "Hjoerring Bus Station",
    "lat": "572900N",
    "long": "0095900E"
  },
  {
    "stationcode": "QJW",
    "stationname": "Kjellerup Bus Service",
    "lat": "561700N",
    "long": "0092500E"
  },
  {
    "stationcode": "XOA",
    "stationname": "Aalbaek Bus Station",
    "lat": "573537N",
    "long": "0102442E"
  },
  {
    "stationcode": "ZFK",
    "stationname": "Guderup Bus Service",
    "lat": "545900N",
    "long": "0095200E"
  },
  {
    "stationcode": "ZID",
    "stationname": "Bus Station",
    "lat": "560900N",
    "long": "0101300E"
  },
  {
    "stationcode": "XAQ",
    "stationname": "Broenderslev Bus Station",
    "lat": "571500N",
    "long": "0100000E"
  },
  {
    "stationcode": "QYQ",
    "stationname": "Sulsted Bus Station",
    "lat": "571000N",
    "long": "0095900E"
  },
  {
    "stationcode": "XAJ",
    "stationname": "Hirtshals Bus Station",
    "lat": "573600N",
    "long": "0095700E"
  },
  {
    "stationcode": "QJV",
    "stationname": "Skagen Bus Station",
    "lat": "574400N",
    "long": "0103600E"
  },
  {
    "stationcode": "XRA",
    "stationname": "Graasten Bus Service",
    "lat": "545500N",
    "long": "0094100E"
  },
  {
    "stationcode": "QFH",
    "stationname": "Frederikshavn Bus Service",
    "lat": "572800N",
    "long": "0103100E"
  },
  {
    "stationcode": "QLZ",
    "stationname": "Bus Station",
    "lat": "560800N",
    "long": "0091000E"
  },
  {
    "stationcode": "ZAW",
    "stationname": "Bus Station",
    "lat": "584800N",
    "long": "0085200E"
  },
  {
    "stationcode": "ZMW",
    "stationname": "Brande Bus Service",
    "lat": "555640N",
    "long": "0090748E"
  },
  {
    "stationcode": "QWO",
    "stationname": "Holstebro Bus Service",
    "lat": "562200N",
    "long": "0083600E"
  },
  {
    "stationcode": "ZRY",
    "stationname": "Herning Bus Service",
    "lat": "560757N",
    "long": "0085840E"
  },
  {
    "stationcode": "ZLV",
    "stationname": "Luosto Bus Service",
    "lat": "612100N",
    "long": "0260500E"
  },
  {
    "stationcode": "XQS",
    "stationname": "Sotkamo Bus Service",
    "lat": "640800N",
    "long": "0282400E"
  },
  {
    "stationcode": "XTP",
    "stationname": "Bus Service",
    "lat": "612934N",
    "long": "0234610E"
  },
  {
    "stationcode": "XHD",
    "stationname": "Bus Service",
    "lat": "660900N",
    "long": "0291000E"
  },
  {
    "stationcode": "QVY",
    "stationname": "Kouvola Bus Station",
    "lat": "605200N",
    "long": "0264200E"
  },
  {
    "stationcode": "XHA",
    "stationname": "Bus Service",
    "lat": "673100N",
    "long": "0241800E"
  },
  {
    "stationcode": "XGQ",
    "stationname": "Bus Service",
    "lat": "683900N",
    "long": "0282900E"
  },
  {
    "stationcode": "XVM",
    "stationname": "Vuokatti Bus Service",
    "lat": "640800N",
    "long": "0281600E"
  },
  {
    "stationcode": "XHC",
    "stationname": "Bus Service",
    "lat": "673600N",
    "long": "0240800E"
  },
  {
    "stationcode": "XPY",
    "stationname": "Pyha Bus Service",
    "lat": "610100N",
    "long": "0270900E"
  },
  {
    "stationcode": "XGX",
    "stationname": "Bus Service",
    "lat": "674700N",
    "long": "0244800E"
  },
  {
    "stationcode": "XER",
    "stationname": "Strasbourg Bus Station",
    "lat": "483500N",
    "long": "0074500E"
  },
  {
    "stationcode": "XTT",
    "stationname": "Etoile Bus Service",
    "lat": "485100N",
    "long": "0022100E"
  },
  {
    "stationcode": "QXK",
    "stationname": "Saint Genis Bus Service",
    "lat": "452900N",
    "long": "0003400W"
  },
  {
    "stationcode": "QZF",
    "stationname": "Bus Station",
    "lat": "423000N",
    "long": "0020300E"
  },
  {
    "stationcode": "WNC",
    "stationname": "Bus Station",
    "lat": "510340N",
    "long": "0011836W"
  },
  {
    "stationcode": "TTY",
    "stationname": "Bus Station",
    "lat": "510055N",
    "long": "0030613W"
  },
  {
    "stationcode": "ZFI",
    "stationname": "Chesterfield Bus Service",
    "lat": "531500N",
    "long": "0012500W"
  },
  {
    "stationcode": "ZFG",
    "stationname": "Sheffield Bus Service",
    "lat": "532400N",
    "long": "0012323W"
  },
  {
    "stationcode": "ZFC",
    "stationname": "Bradford Bus Station",
    "lat": "534700N",
    "long": "0014600W"
  },
  {
    "stationcode": "ZBC",
    "stationname": "Colmore Row Bus Station",
    "lat": "522900N",
    "long": "0015200W"
  },
  {
    "stationcode": "ZLZ",
    "stationname": "Leeds Bus Station",
    "lat": "534749N",
    "long": "0013259W"
  },
  {
    "stationcode": "ZMA",
    "stationname": "Mansfield Bus Service",
    "lat": "530900N",
    "long": "0011100W"
  },
  {
    "stationcode": "ZEQ",
    "stationname": "Dewsbury Bus Station",
    "lat": "534200N",
    "long": "0013700W"
  },
  {
    "stationcode": "ZGB",
    "stationname": "Nottingham Bus Station",
    "lat": "525851N",
    "long": "0001847W"
  },
  {
    "stationcode": "ZFH",
    "stationname": "Milton Keynes Bus Service",
    "lat": "520100N",
    "long": "0004400W"
  },
  {
    "stationcode": "ZMP",
    "stationname": "Manchester Bus Station",
    "lat": "533000N",
    "long": "0021500W"
  },
  {
    "stationcode": "RNW",
    "stationname": "Bus Station",
    "lat": "505049N",
    "long": "0014729W"
  },
  {
    "stationcode": "HHK",
    "stationname": "CZ Bus Station",
    "lat": "221647N",
    "long": "1140941E"
  },
  {
    "stationcode": "QPJ",
    "stationname": "Bus Station",
    "lat": "455927N",
    "long": "0181428E"
  },
  {
    "stationcode": "QZD",
    "stationname": "Bus Station",
    "lat": "461500N",
    "long": "0201000E"
  },
  {
    "stationcode": "QGY",
    "stationname": "Bus Station",
    "lat": "474100N",
    "long": "0173800E"
  },
  {
    "stationcode": "HVZ",
    "stationname": "MA Bus Station",
    "lat": "464725N",
    "long": "0171104E"
  },
  {
    "stationcode": "ZSL",
    "stationname": "Bus Station",
    "lat": "541600N",
    "long": "0082900E"
  },
  {
    "stationcode": "ZRO",
    "stationname": "Bus Station",
    "lat": "444300N",
    "long": "0103600E"
  },
  {
    "stationcode": "ZBN",
    "stationname": "Bus Station",
    "lat": "463100N",
    "long": "0112200E"
  },
  {
    "stationcode": "ZTC",
    "stationname": "Turin Bus Station",
    "lat": "450300N",
    "long": "0074000E"
  },
  {
    "stationcode": "ZMO",
    "stationname": "Modena Bus Station",
    "lat": "444000N",
    "long": "0105500E"
  },
  {
    "stationcode": "ZAK",
    "stationname": "Bus Station",
    "lat": "463800N",
    "long": "0113400E"
  },
  {
    "stationcode": "ZMR",
    "stationname": "Merano/Meran Bus Station",
    "lat": "464000N",
    "long": "0110900E"
  },
  {
    "stationcode": "LMJ",
    "stationname": "Bus Station",
    "lat": "354048N",
    "long": "1394608E"
  },
  {
    "stationcode": "NGY",
    "stationname": "EK Bus Station",
    "lat": "351014N",
    "long": "1365253E"
  },
  {
    "stationcode": "XJK",
    "stationname": "Klaipeda Bus Station",
    "lat": "554200N",
    "long": "0210800E"
  },
  {
    "stationcode": "RRB",
    "stationname": "AT Bus Station",
    "lat": "340101N",
    "long": "0065006W"
  },
  {
    "stationcode": "AZM",
    "stationname": "CZ Bus Station",
    "lat": "221150N",
    "long": "1143226E"
  },
  {
    "stationcode": "QYZ",
    "stationname": "Heerenveen Bus Service",
    "lat": "525700N",
    "long": "0055500E"
  },
  {
    "stationcode": "QYC",
    "stationname": "Drachten Bus Service",
    "lat": "530700N",
    "long": "0060500E"
  },
  {
    "stationcode": "QWZ",
    "stationname": "Best Bus Service",
    "lat": "513100N",
    "long": "0052400E"
  },
  {
    "stationcode": "QAR",
    "stationname": "Arnhem Bus Station",
    "lat": "520000N",
    "long": "0055300E"
  },
  {
    "stationcode": "QYT",
    "stationname": "Paterswolde Bus Service",
    "lat": "530900N",
    "long": "0063400E"
  },
  {
    "stationcode": "LKJ",
    "stationname": "Kaliska Bus Station",
    "lat": "514528N",
    "long": "0192556E"
  },
  {
    "stationcode": "QKI",
    "stationname": "Bus Station",
    "lat": "505232N",
    "long": "0202716E"
  },
  {
    "stationcode": "QYY",
    "stationname": "Bus Station",
    "lat": "530756N",
    "long": "0231007E"
  },
  {
    "stationcode": "ZFX",
    "stationname": "Szczecin Bus Service",
    "lat": "533501N",
    "long": "0145401E"
  },
  {
    "stationcode": "ZMC",
    "stationname": "Miedzyzdroje Bus Service",
    "lat": "535600N",
    "long": "0142600E"
  },
  {
    "stationcode": "QJY",
    "stationname": "Kolobrzeg Bus Service",
    "lat": "541000N",
    "long": "0153500E"
  },
  {
    "stationcode": "QLU",
    "stationname": "Bus Station",
    "lat": "511506N",
    "long": "0223417E"
  },
  {
    "stationcode": "ZFY",
    "stationname": "Katowice Bus Service",
    "lat": "502800N",
    "long": "0190000E"
  },
  {
    "stationcode": "XHV",
    "stationname": "Bus Service",
    "lat": "465959N",
    "long": "0255959E"
  },
  {
    "stationcode": "LGJ",
    "stationname": "Bus Station",
    "lat": "454116N",
    "long": "0215457E"
  },
  {
    "stationcode": "QRS",
    "stationname": "Bus Station",
    "lat": "451800N",
    "long": "0215300E"
  },
  {
    "stationcode": "DMS",
    "stationname": "SABTCO Bus Station",
    "lat": "261700N",
    "long": "0501300E"
  },
  {
    "stationcode": "RUJ",
    "stationname": "SAPTCO Olaya Bus Station",
    "lat": "243545N",
    "long": "0464454E"
  },
  {
    "stationcode": "QCA",
    "stationname": "Bus Service",
    "lat": "213000N",
    "long": "0395400E"
  },
  {
    "stationcode": "XJF",
    "stationname": "Falun Bus Service",
    "lat": "603000N",
    "long": "0153800E"
  },
  {
    "stationcode": "PZJ",
    "stationname": "Portoroz Bus Station",
    "lat": "453048N",
    "long": "0133542E"
  },
  {
    "stationcode": "MSB",
    "stationname": "Bus Station",
    "lat": "463336N",
    "long": "0153921E"
  },
  {
    "stationcode": "ZRG",
    "stationname": "Bratislava Bus Service",
    "lat": "481002N",
    "long": "0171301E"
  },
  {
    "stationcode": "XHQ",
    "stationname": "Hopa Bus Station",
    "lat": "412435N",
    "long": "0412557E"
  },
  {
    "stationcode": "QOF",
    "stationname": "Darnitsa Bus Station",
    "lat": "502400N",
    "long": "0302800E"
  },
  {
    "stationcode": "QOH",
    "stationname": "Hotel Rus Bus Station",
    "lat": "502600N",
    "long": "0303100E"
  },
  {
    "stationcode": "PVB",
    "stationname": "Fluvial Bus Station",
    "lat": "345725S",
    "long": "0545620W"
  },
  {
    "stationcode": "VDT",
    "stationname": "Tres Cruces Bus Station",
    "lat": "345337S",
    "long": "0561000W"
  },
  {
    "stationcode": "CDM",
    "stationname": "Fluvial Bus Station",
    "lat": "342830S",
    "long": "0575033W"
  },
  {
    "stationcode": "FMV",
    "stationname": "Fluvial Bus Station",
    "lat": "345411S",
    "long": "0561248W"
  },
  {
    "stationcode": "QZC",
    "stationname": "Smiggin Holes Bus Service",
    "lat": "363033S",
    "long": "1481500E"
  },
  {
    "stationcode": "QPV",
    "stationname": "Perisher Valley Bus Service",
    "lat": "353000S",
    "long": "1483700E"
  },
  {
    "stationcode": "QTH",
    "stationname": "Thredbo Bus Service",
    "lat": "363000S",
    "long": "1481900E"
  },
  {
    "stationcode": "QWL",
    "stationname": "Crackenback Village Bus Service",
    "lat": "362800S",
    "long": "1482900E"
  },
  {
    "stationcode": "QLE",
    "stationname": "Leeton Bus Service",
    "lat": "343300S",
    "long": "1462300E"
  },
  {
    "stationcode": "XGE",
    "stationname": "Central Bus Station",
    "lat": "451900N",
    "long": "0754000W"
  },
  {
    "stationcode": "ZHO",
    "stationname": "Houston Bus Station",
    "lat": "542600N",
    "long": "1264700W"
  },
  {
    "stationcode": "ZVC",
    "stationname": "Bus Service",
    "lat": "391902S",
    "long": "0721344W"
  },
  {
    "stationcode": "XJU",
    "stationname": "Bus Station",
    "lat": "422016N",
    "long": "0034204W"
  },
  {
    "stationcode": "XJN",
    "stationname": "Bus Station",
    "lat": "413828N",
    "long": "0044357W"
  },
  {
    "stationcode": "XJO",
    "stationname": "Bus Station",
    "lat": "414601N",
    "long": "0022846W"
  },
  {
    "stationcode": "VBT",
    "stationname": "Bus Station",
    "lat": "421325N",
    "long": "0084234W"
  },
  {
    "stationcode": "RZG",
    "stationname": "Bus Station",
    "lat": "413933N",
    "long": "0005440W"
  },
  {
    "stationcode": "JBU",
    "stationname": "Bus Station",
    "lat": "425315N",
    "long": "0083203W"
  },
  {
    "stationcode": "AQL",
    "stationname": "Bus Station",
    "lat": "365007N",
    "long": "0022718W"
  },
  {
    "stationcode": "VTI",
    "stationname": "Bus Station",
    "lat": "425059N",
    "long": "0023957W"
  },
  {
    "stationcode": "XJJ",
    "stationname": "Bus Station",
    "lat": "385952N",
    "long": "0015047W"
  },
  {
    "stationcode": "LZB",
    "stationname": "Bus Station",
    "lat": "423531N",
    "long": "0053442W"
  },
  {
    "stationcode": "ZRC",
    "stationname": "San Pedro de Alcantara Bus Station",
    "lat": "362900N",
    "long": "0045800W"
  },
  {
    "stationcode": "EVI",
    "stationname": "Bus Station",
    "lat": "372331N",
    "long": "0060015W"
  },
  {
    "stationcode": "XJR",
    "stationname": "Bus Station",
    "lat": "374016N",
    "long": "0014148W"
  },
  {
    "stationcode": "XJB",
    "stationname": "Bus  Service",
    "lat": "411810N",
    "long": "0020540E"
  },
  {
    "stationcode": "LWG",
    "stationname": "Bus Station",
    "lat": "422735N",
    "long": "0022639W"
  },
  {
    "stationcode": "GBX",
    "stationname": "Bus Station",
    "lat": "371157N",
    "long": "0033650W"
  },
  {
    "stationcode": "ZYJ",
    "stationname": "Bus Service",
    "lat": "410800N",
    "long": "0000837W"
  },
  {
    "stationcode": "ZYF",
    "stationname": "Bus Service",
    "lat": "370100N",
    "long": "0000758W"
  },
  {
    "stationcode": "ZYD",
    "stationname": "Bus Service",
    "lat": "384200N",
    "long": "0000908W"
  },
  {
    "stationcode": "QDK",
    "stationname": "Greyhound Bus Service",
    "lat": "350200N",
    "long": "0851200W"
  },
  {
    "stationcode": "QMP",
    "stationname": "FlightLink Bus Service",
    "lat": "324100N",
    "long": "0833900W"
  },
  {
    "stationcode": "QJJ",
    "stationname": "FlightLink Bus Service",
    "lat": "344300N",
    "long": "0845200W"
  },
  {
    "stationcode": "ZRK",
    "stationname": "Van Galder Bus Station",
    "lat": "421600N",
    "long": "0890500W"
  },
  {
    "stationcode": "QBF",
    "stationname": "Vail Bus Station",
    "lat": "394600N",
    "long": "1045300W"
  },
  {
    "stationcode": "QWP",
    "stationname": "Winter Park Bus Station",
    "lat": "395000N",
    "long": "1054500W"
  },
  {
    "stationcode": "QKS",
    "stationname": "Keystone Bus Station",
    "lat": "393000N",
    "long": "1060700W"
  },
  {
    "stationcode": "ZSH",
    "stationname": "Bus Station",
    "lat": "354100N",
    "long": "1055700W"
  },
  {
    "stationcode": "QWM",
    "stationname": "Longmont Bus Service",
    "lat": "395100N",
    "long": "1044200W"
  },
  {
    "stationcode": "XUN",
    "stationname": "Sun Valley Bus Station",
    "lat": "434133N",
    "long": "1142126W"
  },
  {
    "stationcode": "QWF",
    "stationname": "Fort Collins Bus Service",
    "lat": "403500N",
    "long": "1050500W"
  },
  {
    "stationcode": "ZTL",
    "stationname": "Telluride Bus Station",
    "lat": "374500N",
    "long": "1075100W"
  },
  {
    "stationcode": "XHH",
    "stationname": "Bus Service",
    "lat": "400220N",
    "long": "1051330W"
  },
  {
    "stationcode": "QWH",
    "stationname": "Loveland Bus Service",
    "lat": "403000N",
    "long": "1050000W"
  },
  {
    "stationcode": "QKB",
    "stationname": "Bus Station",
    "lat": "392900N",
    "long": "1060300W"
  },
  {
    "stationcode": "QCE",
    "stationname": "Copper Mountain Bus Station",
    "lat": "393000N",
    "long": "1060500W"
  },
  {
    "stationcode": "ZJO",
    "stationname": "San Jose Bus Service",
    "lat": "372100N",
    "long": "1215500W"
  },
  {
    "stationcode": "ZSM",
    "stationname": "Santa Clara Bus Service",
    "lat": "372100N",
    "long": "1215700W"
  },
  {
    "stationcode": "ZBS",
    "stationname": "Mesa Bus Station",
    "lat": "332003N",
    "long": "1115030W"
  },
  {
    "stationcode": "ZSY",
    "stationname": "Scottsdale Bus Station",
    "lat": "333000N",
    "long": "1115600W"
  },
  {
    "stationcode": "XWW",
    "stationname": "Westbahnhof Railway",
    "lat": "481148N",
    "long": "0162014E"
  },
  {
    "stationcode": "XWC",
    "stationname": "Suedbahnhof Railway",
    "lat": "481107N",
    "long": "0162236E"
  },
  {
    "stationcode": "ZSB",
    "stationname": "Hbf Railway Station",
    "lat": "474847N",
    "long": "0130245E"
  },
  {
    "stationcode": "XWT",
    "stationname": "Mitte CAT Rail Station",
    "lat": "481226N",
    "long": "0162305E"
  },
  {
    "stationcode": "XJS",
    "stationname": "Kitzbuehl Railway",
    "lat": "472720N",
    "long": "0122335E"
  },
  {
    "stationcode": "LZS",
    "stationname": "Hauptbahnhof Rail Station",
    "lat": "481726N",
    "long": "0141728E"
  },
  {
    "stationcode": "ZYR",
    "stationname": "Midi Railway Station",
    "lat": "505420N",
    "long": "0042900E"
  },
  {
    "stationcode": "QNM",
    "stationname": "Railway Service",
    "lat": "502800N",
    "long": "0045200E"
  },
  {
    "stationcode": "ZYZ",
    "stationname": "Antwerp-Berchem Rail Station",
    "lat": "511156N",
    "long": "0042559E"
  },
  {
    "stationcode": "QMO",
    "stationname": "Railway Service",
    "lat": "502700N",
    "long": "0035600E"
  },
  {
    "stationcode": "XHN",
    "stationname": "Guillemins Railway Station",
    "lat": "503728N",
    "long": "0053400E"
  },
  {
    "stationcode": "ZYB",
    "stationname": "SNCB Rail Network",
    "lat": "500000N",
    "long": "0042900E"
  },
  {
    "stationcode": "ZWE",
    "stationname": "Antwerp Central Rail Station",
    "lat": "511304N",
    "long": "0042515E"
  },
  {
    "stationcode": "ZGJ",
    "stationname": "Brugge Railway Station",
    "lat": "511300N",
    "long": "0031400E"
  },
  {
    "stationcode": "ZHI",
    "stationname": "Grenchen Sued Rail Station",
    "lat": "471100N",
    "long": "0072300E"
  },
  {
    "stationcode": "ZIN",
    "stationname": "Interlaken Ost Rail Station",
    "lat": "464100N",
    "long": "0075100E"
  },
  {
    "stationcode": "QEQ",
    "stationname": "Embrach/Rorbas Railway Station",
    "lat": "473000N",
    "long": "0083600E"
  },
  {
    "stationcode": "ZHT",
    "stationname": "Geneva Railway Station",
    "lat": "461200N",
    "long": "0061000E"
  },
  {
    "stationcode": "ZDX",
    "stationname": "Dietikon Railway Station",
    "lat": "472500N",
    "long": "0084500E"
  },
  {
    "stationcode": "ZKH",
    "stationname": "Saint Mortiz Railway Station",
    "lat": "463000N",
    "long": "0095000E"
  },
  {
    "stationcode": "ZLP",
    "stationname": "Zurich HBF Railway Service",
    "lat": "472240N",
    "long": "0083226E"
  },
  {
    "stationcode": "ZDH",
    "stationname": "SBB Railway Service",
    "lat": "473252N",
    "long": "0073523E"
  },
  {
    "stationcode": "ZKA",
    "stationname": "Sargans Railway Station",
    "lat": "463000N",
    "long": "0092600E"
  },
  {
    "stationcode": "ZHB",
    "stationname": "Engelberg Railway Station",
    "lat": "465900N",
    "long": "0082500E"
  },
  {
    "stationcode": "ZAP",
    "stationname": "Appenzell Railway Station",
    "lat": "472000N",
    "long": "0092400E"
  },
  {
    "stationcode": "ZRQ",
    "stationname": "Zone 1 SBB Rail",
    "lat": "472700N",
    "long": "0092300E"
  },
  {
    "stationcode": "ZBA",
    "stationname": "Basel Bad Railway",
    "lat": "473404N",
    "long": "0073630E"
  },
  {
    "stationcode": "QZB",
    "stationname": "Zermatt Railway Station",
    "lat": "460100N",
    "long": "0074500E"
  },
  {
    "stationcode": "ZKZ",
    "stationname": "Vevey Railway Station",
    "lat": "462700N",
    "long": "0065100E"
  },
  {
    "stationcode": "ZTQ",
    "stationname": "Zone 2 SBB Rail",
    "lat": "473530N",
    "long": "0073030E"
  },
  {
    "stationcode": "ZDJ",
    "stationname": "Berne Railway Service",
    "lat": "465510N",
    "long": "0073000E"
  },
  {
    "stationcode": "ZLQ",
    "stationname": "Oerlikon Railway Station",
    "lat": "472500N",
    "long": "0083300E"
  },
  {
    "stationcode": "ZDV",
    "stationname": "Davos Dorf Railway Station",
    "lat": "464900N",
    "long": "0095000E"
  },
  {
    "stationcode": "KVN",
    "stationname": "Kunshannan Railway Stn",
    "lat": "312118N",
    "long": "1205649E"
  },
  {
    "stationcode": "OPQ",
    "stationname": "Puning Railway Station",
    "lat": "231601N",
    "long": "1161200E"
  },
  {
    "stationcode": "DBL",
    "stationname": "Dalianbei Railway Station",
    "lat": "385525N",
    "long": "1213749E"
  },
  {
    "stationcode": "YDD",
    "stationname": "Yingkoudong Railway Station",
    "lat": "403734N",
    "long": "1222529E"
  },
  {
    "stationcode": "HBV",
    "stationname": "Harbinxi Railway Station",
    "lat": "454534N",
    "long": "1263737E"
  },
  {
    "stationcode": "GKW",
    "stationname": "Guilin Railway Station",
    "lat": "251541N",
    "long": "1101659E"
  },
  {
    "stationcode": "WLI",
    "stationname": "Raiway Station",
    "lat": "282846N",
    "long": "1211930E"
  },
  {
    "stationcode": "TVX",
    "stationname": "Tongxiang Railway Station",
    "lat": "303221N",
    "long": "1203348E"
  },
  {
    "stationcode": "KZZ",
    "stationname": "Wuzhou Railway Station",
    "lat": "232919N",
    "long": "1111359E"
  },
  {
    "stationcode": "XUK",
    "stationname": "Xuzhou Dong Railway Station",
    "lat": "341559N",
    "long": "1171206E"
  },
  {
    "stationcode": "VBL",
    "stationname": "Anshanxi Railway Station",
    "lat": "410659N",
    "long": "1225434E"
  },
  {
    "stationcode": "HBM",
    "stationname": "North Railway Station",
    "lat": "310854N",
    "long": "1145731E"
  },
  {
    "stationcode": "IBD",
    "stationname": "Chaoshan Railway Station",
    "lat": "233235N",
    "long": "1163501E"
  },
  {
    "stationcode": "EMJ",
    "stationname": "Guiyang Railway Station",
    "lat": "263324N",
    "long": "1064209E"
  },
  {
    "stationcode": "YUQ",
    "stationname": "Suzhouyuanqu Rail Station",
    "lat": "312035N",
    "long": "1204222E"
  },
  {
    "stationcode": "YYP",
    "stationname": "North Railway Station",
    "lat": "300318N",
    "long": "1210940E"
  },
  {
    "stationcode": "OCD",
    "stationname": "Duyun Railway Station",
    "lat": "261357N",
    "long": "1073055E"
  },
  {
    "stationcode": "XJW",
    "stationname": "North Railway Station",
    "lat": "313523N",
    "long": "1141311E"
  },
  {
    "stationcode": "AZQ",
    "stationname": "Chaoyang Railway Station",
    "lat": "232254N",
    "long": "1162521E"
  },
  {
    "stationcode": "JYQ",
    "stationname": "Bayuquan Railway Station",
    "lat": "401116N",
    "long": "1220541E"
  },
  {
    "stationcode": "FII",
    "stationname": "Beihai Railway Station",
    "lat": "212717N",
    "long": "1090731E"
  },
  {
    "stationcode": "UIG",
    "stationname": "Huaihua Railway Station",
    "lat": "273342N",
    "long": "1095745E"
  },
  {
    "stationcode": "XTQ",
    "stationname": "West Railway Station",
    "lat": "302540N",
    "long": "1130824E"
  },
  {
    "stationcode": "TMV",
    "stationname": "South Railway Station",
    "lat": "302552N",
    "long": "1132701E"
  },
  {
    "stationcode": "XYW",
    "stationname": "East Railway Station",
    "lat": "320726N",
    "long": "1141032E"
  },
  {
    "stationcode": "HXN",
    "stationname": "Xingtai Railway Station",
    "lat": "370413N",
    "long": "1142932E"
  },
  {
    "stationcode": "NKJ",
    "stationname": "Nanjing Nan Railway Station",
    "lat": "315816N",
    "long": "1184732E"
  },
  {
    "stationcode": "JXS",
    "stationname": "Jiaxingnan Railway Station",
    "lat": "304134N",
    "long": "1204745E"
  },
  {
    "stationcode": "VWA",
    "stationname": "Shenyangbei Railway Station",
    "lat": "414901N",
    "long": "1232541E"
  },
  {
    "stationcode": "XJV",
    "stationname": "Ostrava Main Railway Station",
    "lat": "495103N",
    "long": "0181603E"
  },
  {
    "stationcode": "XJC",
    "stationname": "Olomouc Main Railway Station",
    "lat": "493533N",
    "long": "0171640E"
  },
  {
    "stationcode": "XYG",
    "stationname": "Prague Main Railway Station",
    "lat": "500500N",
    "long": "0142800E"
  },
  {
    "stationcode": "XYJ",
    "stationname": "Holesovice Railway Station",
    "lat": "500500N",
    "long": "0142800E"
  },
  {
    "stationcode": "XJP",
    "stationname": "Pardubice Main Railway Station",
    "lat": "500157N",
    "long": "0154524E"
  },
  {
    "stationcode": "QKU",
    "stationname": "Messe/Deutz Rail Station",
    "lat": "505626N",
    "long": "0065828E"
  },
  {
    "stationcode": "ZMB",
    "stationname": "Hbf Railway Station",
    "lat": "533311N",
    "long": "0100024E"
  },
  {
    "stationcode": "ZMG",
    "stationname": "Hbf Railway Station",
    "lat": "520751N",
    "long": "0113737E"
  },
  {
    "stationcode": "ZSD",
    "stationname": "Schwanheide Railway Service",
    "lat": "532600N",
    "long": "0104100E"
  },
  {
    "stationcode": "ZOG",
    "stationname": "Lueneburg Railway Service",
    "lat": "531500N",
    "long": "0102400E"
  },
  {
    "stationcode": "QYG",
    "stationname": "Germany Railway Service",
    "lat": "540000N",
    "long": "0130000E"
  },
  {
    "stationcode": "ZWD",
    "stationname": "Warnemuende Railway Service",
    "lat": "541200N",
    "long": "0120500E"
  },
  {
    "stationcode": "QFB",
    "stationname": "Hbf Railway Station",
    "lat": "475952N",
    "long": "0075032E"
  },
  {
    "stationcode": "XIT",
    "stationname": "Leipzig Hbf Rail Station",
    "lat": "512045N",
    "long": "0122254E"
  },
  {
    "stationcode": "ZNQ",
    "stationname": "Hbf Railway Station",
    "lat": "484440N",
    "long": "0112613E"
  },
  {
    "stationcode": "ESZ",
    "stationname": "Hbf Railway Station",
    "lat": "512706N",
    "long": "0070052E"
  },
  {
    "stationcode": "QGV",
    "stationname": "Neu Isenburg Rail Station",
    "lat": "500300N",
    "long": "0084200E"
  },
  {
    "stationcode": "QII",
    "stationname": "Hbf Railway Station",
    "lat": "473300N",
    "long": "0094100E"
  },
  {
    "stationcode": "ZSK",
    "stationname": "Pasewalk Railway Service",
    "lat": "533000N",
    "long": "0140000E"
  },
  {
    "stationcode": "ZSU",
    "stationname": "Hbf Railway Station",
    "lat": "515000N",
    "long": "0121500E"
  },
  {
    "stationcode": "KLT",
    "stationname": "Hbf Railway Station",
    "lat": "492652N",
    "long": "0074500E"
  },
  {
    "stationcode": "UWE",
    "stationname": "Hbf Railway Station",
    "lat": "500412N",
    "long": "0081438E"
  },
  {
    "stationcode": "ZAQ",
    "stationname": "Hbf Railway Station",
    "lat": "492642N",
    "long": "0110457E"
  },
  {
    "stationcode": "ZIT",
    "stationname": "Zittau Railway Service",
    "lat": "505500N",
    "long": "0145000E"
  },
  {
    "stationcode": "XHJ",
    "stationname": "Hbf Railway Station",
    "lat": "504606N",
    "long": "0060528E"
  },
  {
    "stationcode": "ZQV",
    "stationname": "Hbf Railway Station",
    "lat": "493808N",
    "long": "0082135E"
  },
  {
    "stationcode": "QPP",
    "stationname": "Hbf Railway Station",
    "lat": "523130N",
    "long": "0132210E"
  },
  {
    "stationcode": "QWU",
    "stationname": "Hbf Railway Station",
    "lat": "494719N",
    "long": "0095611E"
  },
  {
    "stationcode": "QBO",
    "stationname": "Hbf Railway Station",
    "lat": "512845N",
    "long": "0071322E"
  },
  {
    "stationcode": "ZCB",
    "stationname": "Hbf Railway Station",
    "lat": "495849N",
    "long": "0090838E"
  },
  {
    "stationcode": "ZCC",
    "stationname": "Baden-Baden Railway Station",
    "lat": "484726N",
    "long": "0081127E"
  },
  {
    "stationcode": "ZSN",
    "stationname": "Stendal Railway Service",
    "lat": "523543N",
    "long": "0115117E"
  },
  {
    "stationcode": "DUI",
    "stationname": "Hbf Railway Station",
    "lat": "512548N",
    "long": "0064626E"
  },
  {
    "stationcode": "ZPF",
    "stationname": "Hbf Railway Station",
    "lat": "483427N",
    "long": "0132703E"
  },
  {
    "stationcode": "ZGE",
    "stationname": "Goerlitz Railway",
    "lat": "502000N",
    "long": "0115800E"
  },
  {
    "stationcode": "MKF",
    "stationname": "Muenster HBF",
    "lat": "515725N",
    "long": "0073807E"
  },
  {
    "stationcode": "ZMU",
    "stationname": "Hbf Railway Station",
    "lat": "480828N",
    "long": "0113316E"
  },
  {
    "stationcode": "ZSO",
    "stationname": "Suhl Railway Service",
    "lat": "503700N",
    "long": "0104300E"
  },
  {
    "stationcode": "ZOO",
    "stationname": "Hbf Railway Station",
    "lat": "512600N",
    "long": "0065300E"
  },
  {
    "stationcode": "ZSR",
    "stationname": "Hbf Railway Station",
    "lat": "533800N",
    "long": "0112300E"
  },
  {
    "stationcode": "DTZ",
    "stationname": "Hbf Railway Station",
    "lat": "513104N",
    "long": "0072733E"
  },
  {
    "stationcode": "BNJ",
    "stationname": "Bonn Hbf Railway Station",
    "lat": "504400N",
    "long": "0070600E"
  },
  {
    "stationcode": "XZE",
    "stationname": "Rail (Generic)",
    "lat": "500000N",
    "long": "0080000E"
  },
  {
    "stationcode": "ZPL",
    "stationname": "Hbf Railway Station",
    "lat": "513700N",
    "long": "0071200E"
  },
  {
    "stationcode": "ZLU",
    "stationname": "Ludwigslust Railway Service",
    "lat": "532000N",
    "long": "0113000E"
  },
  {
    "stationcode": "ZRX",
    "stationname": "Riesa Railway Service",
    "lat": "511800N",
    "long": "0131800E"
  },
  {
    "stationcode": "ZWM",
    "stationname": "Wismar Railway Service",
    "lat": "535400N",
    "long": "0112800E"
  },
  {
    "stationcode": "SDA",
    "stationname": "Dudweilerstrasse Station",
    "lat": "491416N",
    "long": "0065950E"
  },
  {
    "stationcode": "ZPE",
    "stationname": "Osnabrueck Hbf Rail St",
    "lat": "521622N",
    "long": "0080338E"
  },
  {
    "stationcode": "QFL",
    "stationname": "Off-Line Point",
    "lat": "491410N",
    "long": "0065910E"
  },
  {
    "stationcode": "ZEY",
    "stationname": "Hbf Railway Station",
    "lat": "512147N",
    "long": "0072739E"
  },
  {
    "stationcode": "ZWS",
    "stationname": "Hbf Railway Station",
    "lat": "484701N",
    "long": "0091053E"
  },
  {
    "stationcode": "ZIO",
    "stationname": "Hbf Railway Station",
    "lat": "510939N",
    "long": "0070014E"
  },
  {
    "stationcode": "ZSX",
    "stationname": "Stralsund Railway Service",
    "lat": "541833N",
    "long": "0130441E"
  },
  {
    "stationcode": "QHD",
    "stationname": "Hbf Railway Station",
    "lat": "492414N",
    "long": "0084033E"
  },
  {
    "stationcode": "ZSQ",
    "stationname": "Salzwedel Railway Station",
    "lat": "525100N",
    "long": "0111000E"
  },
  {
    "stationcode": "ZTT",
    "stationname": "Cottbus Railway Station",
    "lat": "514300N",
    "long": "0142100E"
  },
  {
    "stationcode": "QWB",
    "stationname": "Ostbahnhof Railway Station",
    "lat": "523100N",
    "long": "0132500E"
  },
  {
    "stationcode": "ZQF",
    "stationname": "Hbf Railway Station",
    "lat": "494523N",
    "long": "0063908E"
  },
  {
    "stationcode": "ZWT",
    "stationname": "Wittenburg Railway Station",
    "lat": "514844N",
    "long": "0124819E"
  },
  {
    "stationcode": "XIR",
    "stationname": "Hbf Railway Station",
    "lat": "510357N",
    "long": "0134426E"
  },
  {
    "stationcode": "ZOY",
    "stationname": "Hbf Railway Station",
    "lat": "512800N",
    "long": "0065100E"
  },
  {
    "stationcode": "ZVM",
    "stationname": "Messe/Laatzen Rail Station",
    "lat": "521900N",
    "long": "0094800E"
  },
  {
    "stationcode": "ZSC",
    "stationname": "Schoena Railway Service",
    "lat": "540000N",
    "long": "0132000E"
  },
  {
    "stationcode": "EIZ",
    "stationname": "Hbf Railway Station",
    "lat": "505835N",
    "long": "0101953E"
  },
  {
    "stationcode": "ZEJ",
    "stationname": "Hbf Railway Station",
    "lat": "513019N",
    "long": "0070609E"
  },
  {
    "stationcode": "KJR",
    "stationname": "Hbf Railway Station",
    "lat": "485937N",
    "long": "0082408E"
  },
  {
    "stationcode": "DHC",
    "stationname": "Bremen HBF",
    "lat": "530501N",
    "long": "0084851E"
  },
  {
    "stationcode": "ZTZ",
    "stationname": "Hbf Railway Station",
    "lat": "505000N",
    "long": "0125500E"
  },
  {
    "stationcode": "ZGW",
    "stationname": "Greifswald Railway",
    "lat": "540600N",
    "long": "0132400E"
  },
  {
    "stationcode": "KWQ",
    "stationname": "Wilhelmshoehe Rail Station",
    "lat": "511847N",
    "long": "0092648E"
  },
  {
    "stationcode": "XIU",
    "stationname": "Hbf Railway Station",
    "lat": "505821N",
    "long": "0110217E"
  },
  {
    "stationcode": "ZQU",
    "stationname": "Wolfsburg Rail Station",
    "lat": "522600N",
    "long": "0104800E"
  },
  {
    "stationcode": "ZGA",
    "stationname": "Hbf Railway Station",
    "lat": "505200N",
    "long": "0120500E"
  },
  {
    "stationcode": "ZEX",
    "stationname": "Hbf Railway Station",
    "lat": "515400N",
    "long": "0082300E"
  },
  {
    "stationcode": "ZGO",
    "stationname": "Gotha Railway",
    "lat": "505622N",
    "long": "0104246E"
  },
  {
    "stationcode": "ZSI",
    "stationname": "Sassnitz Railway Service",
    "lat": "543200N",
    "long": "0134000E"
  },
  {
    "stationcode": "ZHZ",
    "stationname": "Halle Hbf Rail Station",
    "lat": "512838N",
    "long": "0115916E"
  },
  {
    "stationcode": "ZVR",
    "stationname": "Hbf Railway Station",
    "lat": "522238N",
    "long": "0094431E"
  },
  {
    "stationcode": "QUL",
    "stationname": "Hbf Railway Station",
    "lat": "482359N",
    "long": "0095900E"
  },
  {
    "stationcode": "UWP",
    "stationname": "Hbf Railway Station",
    "lat": "511518N",
    "long": "0070900E"
  },
  {
    "stationcode": "QWC",
    "stationname": "Zoo Railway Station",
    "lat": "523029N",
    "long": "0131953E"
  },
  {
    "stationcode": "QYF",
    "stationname": "German Railway Service",
    "lat": "500200N",
    "long": "0083400E"
  },
  {
    "stationcode": "ZRB",
    "stationname": "Hbf Railway Station",
    "lat": "500624N",
    "long": "0083947E"
  },
  {
    "stationcode": "QFZ",
    "stationname": "Hbf Railway Station",
    "lat": "491427N",
    "long": "0065927E"
  },
  {
    "stationcode": "QMZ",
    "stationname": "Hbf Railway Station",
    "lat": "500006N",
    "long": "0081533E"
  },
  {
    "stationcode": "ZSG",
    "stationname": "Hbf Railway Station",
    "lat": "502100N",
    "long": "0111000E"
  },
  {
    "stationcode": "QYJ",
    "stationname": "German Railway Service",
    "lat": "500200N",
    "long": "0083400E"
  },
  {
    "stationcode": "ZPM",
    "stationname": "Hbf Railway Station",
    "lat": "490044N",
    "long": "0120559E"
  },
  {
    "stationcode": "ZWN",
    "stationname": "Wittenberge Railway Service",
    "lat": "525900N",
    "long": "0114500E"
  },
  {
    "stationcode": "QDU",
    "stationname": "Hbf Railway Station",
    "lat": "511313N",
    "long": "0064735E"
  },
  {
    "stationcode": "QKL",
    "stationname": "Cologne Hbf Rail Station",
    "lat": "505636N",
    "long": "0065730E"
  },
  {
    "stationcode": "ZJS",
    "stationname": "Paradies Rail Station",
    "lat": "505600N",
    "long": "0113500E"
  },
  {
    "stationcode": "ZGH",
    "stationname": "Copenhagen Rail Station",
    "lat": "554022N",
    "long": "0123352E"
  },
  {
    "stationcode": "ZBJ",
    "stationname": "Fredericia Railway Service",
    "lat": "553500N",
    "long": "0094600E"
  },
  {
    "stationcode": "ZIR",
    "stationname": "Randers Rail Station",
    "lat": "562800N",
    "long": "0100300E"
  },
  {
    "stationcode": "QXV",
    "stationname": "Svendborg Railway Service",
    "lat": "550300N",
    "long": "0103700E"
  },
  {
    "stationcode": "XJE",
    "stationname": "Vojens Railway Service",
    "lat": "551500N",
    "long": "0091900E"
  },
  {
    "stationcode": "ZBT",
    "stationname": "Kolding Railway Service",
    "lat": "553100N",
    "long": "0092900E"
  },
  {
    "stationcode": "ZJH",
    "stationname": "Aarhus Rail Station",
    "lat": "560900N",
    "long": "0101300E"
  },
  {
    "stationcode": "ZTG",
    "stationname": "Aalborg Rail Station",
    "lat": "570300N",
    "long": "0095600E"
  },
  {
    "stationcode": "ZIJ",
    "stationname": "Sjaelland Rail Station",
    "lat": "555500N",
    "long": "0114000E"
  },
  {
    "stationcode": "ZGX",
    "stationname": "Viborg Rail Station",
    "lat": "562600N",
    "long": "0092400E"
  },
  {
    "stationcode": "ZIB",
    "stationname": "Nyborg Rail Station",
    "lat": "551900N",
    "long": "0104800E"
  },
  {
    "stationcode": "XAH",
    "stationname": "Silkeborg Railway Service",
    "lat": "561000N",
    "long": "0093400E"
  },
  {
    "stationcode": "XVX",
    "stationname": "Vejle Railway Service",
    "lat": "554200N",
    "long": "0093200E"
  },
  {
    "stationcode": "ZBB",
    "stationname": "Esbjerg Railway Service",
    "lat": "552800N",
    "long": "0082700E"
  },
  {
    "stationcode": "ZKN",
    "stationname": "Skive Rail Station",
    "lat": "563800N",
    "long": "0090100E"
  },
  {
    "stationcode": "ZBQ",
    "stationname": "Odense Railway Service",
    "lat": "552400N",
    "long": "0102300E"
  },
  {
    "stationcode": "ZDY",
    "stationname": "Ronne Rail Station",
    "lat": "243036N",
    "long": "0522007E"
  },
  {
    "stationcode": "XAK",
    "stationname": "Herning Railway Service",
    "lat": "560800N",
    "long": "0085900E"
  },
  {
    "stationcode": "HVT",
    "stationname": "Tikkurila Railway Station",
    "lat": "601738N",
    "long": "0250242E"
  },
  {
    "stationcode": "HEC",
    "stationname": "Central Railway Station",
    "lat": "601019N",
    "long": "0245629E"
  },
  {
    "stationcode": "XHP",
    "stationname": "Gare de l'Est Rail Station",
    "lat": "485237N",
    "long": "0022133E"
  },
  {
    "stationcode": "XYL",
    "stationname": "Lyon Perrache Rail Service",
    "lat": "454454N",
    "long": "0044932E"
  },
  {
    "stationcode": "XJT",
    "stationname": "Tours Rail Station",
    "lat": "472300N",
    "long": "0004100E"
  },
  {
    "stationcode": "XPJ",
    "stationname": "Railway Service",
    "lat": "433617N",
    "long": "0035252E"
  },
  {
    "stationcode": "XFF",
    "stationname": "Frethun Rail Station",
    "lat": "505406N",
    "long": "0014841E"
  },
  {
    "stationcode": "ZLN",
    "stationname": "Le Mans Rail Station",
    "lat": "475945N",
    "long": "0001131E"
  },
  {
    "stationcode": "XIZ",
    "stationname": "Champagne Railway Station",
    "lat": "491253N",
    "long": "0035941E"
  },
  {
    "stationcode": "ZFQ",
    "stationname": "Gare De Bordeaux Rail Station",
    "lat": "444904N",
    "long": "0003342W"
  },
  {
    "stationcode": "XSH",
    "stationname": "Railway Service",
    "lat": "472322N",
    "long": "0004138E"
  },
  {
    "stationcode": "XZN",
    "stationname": "Avignon  Railway Service",
    "lat": "435518N",
    "long": "0044709E"
  },
  {
    "stationcode": "ZBH",
    "stationname": "Severac Le Chateau Railway Service",
    "lat": "441900N",
    "long": "0030400E"
  },
  {
    "stationcode": "XZI",
    "stationname": "Gare Lorraine TGV",
    "lat": "485652N",
    "long": "0061012E"
  },
  {
    "stationcode": "QXB",
    "stationname": "RAILWAY STATION",
    "lat": "433123N",
    "long": "0052643E"
  },
  {
    "stationcode": "XPG",
    "stationname": "Gare du Nord Rail Station",
    "lat": "485251N",
    "long": "0022119E"
  },
  {
    "stationcode": "XYD",
    "stationname": "Lyon Part-Dieu Rail Service",
    "lat": "454538N",
    "long": "0045134E"
  },
  {
    "stationcode": "QJZ",
    "stationname": "Nantes Railway Service",
    "lat": "471304N",
    "long": "0013231W"
  },
  {
    "stationcode": "XFA",
    "stationname": "Lille Flanders Rail Service",
    "lat": "503811N",
    "long": "0030415E"
  },
  {
    "stationcode": "XYV",
    "stationname": "Satolas Rail Service",
    "lat": "454200N",
    "long": "0050800E"
  },
  {
    "stationcode": "XRF",
    "stationname": "Railway Service",
    "lat": "431810N",
    "long": "0052251E"
  },
  {
    "stationcode": "XCC",
    "stationname": "TGV Railway Station",
    "lat": "464555N",
    "long": "0042959E"
  },
  {
    "stationcode": "ZFJ",
    "stationname": "Gare De Rennes Rail Station",
    "lat": "480407N",
    "long": "0014353W"
  },
  {
    "stationcode": "XED",
    "stationname": "Disneyland Railway Service",
    "lat": "485211N",
    "long": "0024658E"
  },
  {
    "stationcode": "TXQ",
    "stationname": "Matabiau Railway Station",
    "lat": "433641N",
    "long": "0012714E"
  },
  {
    "stationcode": "ZAF",
    "stationname": "Arles Railway Service",
    "lat": "434000N",
    "long": "0043800E"
  },
  {
    "stationcode": "XZV",
    "stationname": "TGV Station",
    "lat": "430742N",
    "long": "0055547E"
  },
  {
    "stationcode": "XHK",
    "stationname": "Railway Service",
    "lat": "445930N",
    "long": "0045843E"
  },
  {
    "stationcode": "XDT",
    "stationname": "C. de. G./TGV Rail Service",
    "lat": "485000N",
    "long": "0022000E"
  },
  {
    "stationcode": "QXG",
    "stationname": "Angers Rail Station",
    "lat": "472753N",
    "long": "0003325W"
  },
  {
    "stationcode": "BYF",
    "stationname": "Albert Bay Airport",
    "lat": "495812N",
    "long": "0024133E"
  },
  {
    "stationcode": "XDB",
    "stationname": "Lille Europe Rail Service",
    "lat": "503820N",
    "long": "0030434E"
  },
  {
    "stationcode": "XJY",
    "stationname": "TGV Gare de Massy Rail Station",
    "lat": "484330N",
    "long": "0021538E"
  },
  {
    "stationcode": "XOP",
    "stationname": "Poitiers Rail Station",
    "lat": "463456N",
    "long": "0001959E"
  },
  {
    "stationcode": "XQM",
    "stationname": "Market Harborough Rail Station",
    "lat": "522900N",
    "long": "0005500W"
  },
  {
    "stationcode": "XGM",
    "stationname": "Grantham Rail Station",
    "lat": "525500N",
    "long": "0003900W"
  },
  {
    "stationcode": "ZGG",
    "stationname": "Glasgow ScotRail",
    "lat": "555134N",
    "long": "0041529W"
  },
  {
    "stationcode": "XMZ",
    "stationname": "Macclesfield Rail Station",
    "lat": "531600N",
    "long": "0020700W"
  },
  {
    "stationcode": "QQD",
    "stationname": "Dover Rail Station",
    "lat": "510800N",
    "long": "0011900E"
  },
  {
    "stationcode": "QQY",
    "stationname": "York Rail Service",
    "lat": "535800N",
    "long": "0010500W"
  },
  {
    "stationcode": "XVU",
    "stationname": "Durham Rail Station",
    "lat": "544700N",
    "long": "0013400W"
  },
  {
    "stationcode": "XVC",
    "stationname": "Crewe Rail Station",
    "lat": "530522N",
    "long": "0022559W"
  },
  {
    "stationcode": "XVW",
    "stationname": "Wolverhampton Rail Station",
    "lat": "523600N",
    "long": "0020800W"
  },
  {
    "stationcode": "QQK",
    "stationname": "Kings Cross Rail Station",
    "lat": "513200N",
    "long": "0000700W"
  },
  {
    "stationcode": "ZIV",
    "stationname": "Inverness Scot Rail",
    "lat": "572700N",
    "long": "0041500W"
  },
  {
    "stationcode": "ZXE",
    "stationname": "Edinburgh Scot Rail",
    "lat": "555708N",
    "long": "0031122W"
  },
  {
    "stationcode": "XVH",
    "stationname": "Peterborough Railway Station",
    "lat": "523500N",
    "long": "0011500W"
  },
  {
    "stationcode": "QQS",
    "stationname": "St. Pancras International",
    "lat": "513154N",
    "long": "0000735W"
  },
  {
    "stationcode": "XQT",
    "stationname": "Lichfield T.V. Rail Station",
    "lat": "524114N",
    "long": "0014802W"
  },
  {
    "stationcode": "XQD",
    "stationname": "Bedford Rail Station",
    "lat": "520800N",
    "long": "0002900W"
  },
  {
    "stationcode": "XQE",
    "stationname": "Ebbsfleet Kent Station",
    "lat": "512635N",
    "long": "0001916E"
  },
  {
    "stationcode": "ZXA",
    "stationname": "Aberdeen Scot Rail",
    "lat": "571000N",
    "long": "0020400W"
  },
  {
    "stationcode": "XQI",
    "stationname": "Loughborough Rail Station",
    "lat": "524700N",
    "long": "0011100W"
  },
  {
    "stationcode": "XNK",
    "stationname": "Newark Northgate Rail Station",
    "lat": "530500N",
    "long": "0000049W"
  },
  {
    "stationcode": "BPR",
    "stationname": "Parkway Railway Station",
    "lat": "513050N",
    "long": "0023230W"
  },
  {
    "stationcode": "XVA",
    "stationname": "Stockport Rail Station",
    "lat": "532500N",
    "long": "0021000W"
  },
  {
    "stationcode": "XPT",
    "stationname": "Preston Rail Service",
    "lat": "534520N",
    "long": "0024226W"
  },
  {
    "stationcode": "XTK",
    "stationname": "Thirsk Rail Station",
    "lat": "541400N",
    "long": "0012000W"
  },
  {
    "stationcode": "XNO",
    "stationname": "Northallerton Rail Station",
    "lat": "542000N",
    "long": "0012600W"
  },
  {
    "stationcode": "QQX",
    "stationname": "Bath Rail Service",
    "lat": "512240N",
    "long": "0022126W"
  },
  {
    "stationcode": "LXL",
    "stationname": "Lime Street Rail Station",
    "lat": "532428N",
    "long": "0025839W"
  },
  {
    "stationcode": "XWD",
    "stationname": "Wakefield Westgate Rail Station",
    "lat": "534200N",
    "long": "0012900W"
  },
  {
    "stationcode": "XWE",
    "stationname": "Wellingborough Rail Station",
    "lat": "521900N",
    "long": "0004200W"
  },
  {
    "stationcode": "ZLS",
    "stationname": "Liverpool Street Stn. Railway Service",
    "lat": "513105N",
    "long": "0000458W"
  },
  {
    "stationcode": "XVJ",
    "stationname": "Stevenage Rail Station",
    "lat": "515500N",
    "long": "0001400W"
  },
  {
    "stationcode": "XWN",
    "stationname": "Warrington  B. Q. Rail Station",
    "lat": "532400N",
    "long": "0023700W"
  },
  {
    "stationcode": "XHU",
    "stationname": "Huntingdon Rail Station",
    "lat": "522000N",
    "long": "0001200W"
  },
  {
    "stationcode": "XWB",
    "stationname": "Stirling Rail Station",
    "lat": "560700N",
    "long": "0035700W"
  },
  {
    "stationcode": "KXN",
    "stationname": "Oxenholme Rail Station",
    "lat": "541819N",
    "long": "0024319W"
  },
  {
    "stationcode": "QQM",
    "stationname": "Piccadilly Railway Station",
    "lat": "532800N",
    "long": "0021400W"
  },
  {
    "stationcode": "WXF",
    "stationname": "Watford Junction Rail Station",
    "lat": "513950N",
    "long": "0002348W"
  },
  {
    "stationcode": "XSR",
    "stationname": "Salisbury Rail Station",
    "lat": "510500N",
    "long": "0014800W"
  },
  {
    "stationcode": "QQW",
    "stationname": "London-Waterloo Rail Station",
    "lat": "513000N",
    "long": "0000700W"
  },
  {
    "stationcode": "QQR",
    "stationname": "Ramsgate Rail Station",
    "lat": "512000N",
    "long": "0012500E"
  },
  {
    "stationcode": "TTK",
    "stationname": "Railway Service",
    "lat": "513500N",
    "long": "0000400W"
  },
  {
    "stationcode": "XRC",
    "stationname": "Runcorn Rail Station",
    "lat": "532000N",
    "long": "0024400W"
  },
  {
    "stationcode": "LXC",
    "stationname": "Liverpool Central Railway Station",
    "lat": "532417N",
    "long": "0025848W"
  },
  {
    "stationcode": "ZEP",
    "stationname": "Victoria Railway Station",
    "lat": "512942N",
    "long": "0000841W"
  },
  {
    "stationcode": "XPW",
    "stationname": "Didcot Parkway Rail Station",
    "lat": "513700N",
    "long": "0011500W"
  },
  {
    "stationcode": "QQU",
    "stationname": "Euston Rail Station",
    "lat": "513145N",
    "long": "0000805W"
  },
  {
    "stationcode": "XVB",
    "stationname": "Stafford Rail Station",
    "lat": "524800N",
    "long": "0020700W"
  },
  {
    "stationcode": "XWO",
    "stationname": "Woking Rail Station",
    "lat": "512000N",
    "long": "0003400W"
  },
  {
    "stationcode": "XWS",
    "stationname": "Swindon Rail Station",
    "lat": "513357N",
    "long": "0014709W"
  },
  {
    "stationcode": "ZLX",
    "stationname": "British Rail Terminal",
    "lat": "125630N",
    "long": "0233356E"
  },
  {
    "stationcode": "QQP",
    "stationname": "Paddington Rail Station",
    "lat": "513102N",
    "long": "0001040W"
  },
  {
    "stationcode": "ZXP",
    "stationname": "Perth Scot Rail",
    "lat": "562800N",
    "long": "0032800W"
  },
  {
    "stationcode": "XPF",
    "stationname": "Penrith Rail Station",
    "lat": "544000N",
    "long": "0024400W"
  },
  {
    "stationcode": "XQG",
    "stationname": "Berwick Rail Station",
    "lat": "554600N",
    "long": "0020000W"
  },
  {
    "stationcode": "XOF",
    "stationname": "Stratford Railway Station",
    "lat": "513239N",
    "long": "0000025E"
  },
  {
    "stationcode": "EXS",
    "stationname": "St. Thomas Railway Station",
    "lat": "504302N",
    "long": "0033221W"
  },
  {
    "stationcode": "XNM",
    "stationname": "Nottingham Rail Station",
    "lat": "525810N",
    "long": "0011000W"
  },
  {
    "stationcode": "XNE",
    "stationname": "Newport (Gwent) Rail Station",
    "lat": "513520N",
    "long": "0030002W"
  },
  {
    "stationcode": "XQB",
    "stationname": "Basingstoke Rail Station",
    "lat": "511600N",
    "long": "0010500W"
  },
  {
    "stationcode": "XQL",
    "stationname": "Lancaster Rail Station",
    "lat": "540256N",
    "long": "0024828W"
  },
  {
    "stationcode": "XVG",
    "stationname": "Darlington Rail Station",
    "lat": "543100N",
    "long": "0013400W"
  },
  {
    "stationcode": "CXE",
    "stationname": "Chester Central Railway Station",
    "lat": "531149N",
    "long": "0025247W"
  },
  {
    "stationcode": "TPB",
    "stationname": "Temple Meads Rail Station",
    "lat": "512657N",
    "long": "0023451W"
  },
  {
    "stationcode": "QDH",
    "stationname": "Ashford International Rail Station",
    "lat": "510836N",
    "long": "0005229E"
  },
  {
    "stationcode": "XQW",
    "stationname": "Motherwell Rail Station",
    "lat": "554800N",
    "long": "0040000W"
  },
  {
    "stationcode": "XRG",
    "stationname": "Rugeley Rail Station",
    "lat": "524600N",
    "long": "0015500W"
  },
  {
    "stationcode": "XNV",
    "stationname": "Nuneaton Rail Station",
    "lat": "523134N",
    "long": "0012753W"
  },
  {
    "stationcode": "QQN",
    "stationname": "Birmingham-New Street Rail Service",
    "lat": "522800N",
    "long": "0015500W"
  },
  {
    "stationcode": "CXX",
    "stationname": "Carlisle Central Railway Station",
    "lat": "545326N",
    "long": "0025600W"
  },
  {
    "stationcode": "XWH",
    "stationname": "Stoke on Trent Rail Station",
    "lat": "530000N",
    "long": "0021000W"
  },
  {
    "stationcode": "XQH",
    "stationname": "Nottingham Rail Station",
    "lat": "525500N",
    "long": "0013000W"
  },
  {
    "stationcode": "ZDU",
    "stationname": "Dundee Scot Rail",
    "lat": "562800N",
    "long": "0025800W"
  },
  {
    "stationcode": "XRU",
    "stationname": "Rugby Rail Station",
    "lat": "522300N",
    "long": "0011500W"
  },
  {
    "stationcode": "XWI",
    "stationname": "Wigan NW Railway",
    "lat": "533300N",
    "long": "0023800W"
  },
  {
    "stationcode": "QQH",
    "stationname": "Harwich Rail Station",
    "lat": "515700N",
    "long": "0011700E"
  },
  {
    "stationcode": "ZTW",
    "stationname": "Tsuen Wan Railway Service",
    "lat": "222200N",
    "long": "1140600E"
  },
  {
    "stationcode": "XXJ",
    "stationname": "Deli Railway",
    "lat": "472958N",
    "long": "0190130E"
  },
  {
    "stationcode": "XXQ",
    "stationname": "Keleti Railway",
    "lat": "473001N",
    "long": "0190502E"
  },
  {
    "stationcode": "IMR",
    "stationname": "Rogoredo Railway Station",
    "lat": "452600N",
    "long": "0091418E"
  },
  {
    "stationcode": "XIK",
    "stationname": "Central Station",
    "lat": "452909N",
    "long": "0091216E"
  },
  {
    "stationcode": "IRT",
    "stationname": "Tiburtina Railway Station",
    "lat": "415438N",
    "long": "0123150E"
  },
  {
    "stationcode": "IPR",
    "stationname": "Porta Garibaldi Station",
    "lat": "452905N",
    "long": "0091116E"
  },
  {
    "stationcode": "IBT",
    "stationname": "Central Railway Station",
    "lat": "443022N",
    "long": "0112036E"
  },
  {
    "stationcode": "TXB",
    "stationname": "Trieste Railway Station",
    "lat": "453926N",
    "long": "0134620E"
  },
  {
    "stationcode": "ZMI",
    "stationname": "Mergellina Railway Service",
    "lat": "405000N",
    "long": "0141500E"
  },
  {
    "stationcode": "IRR",
    "stationname": "Ostiense Railway",
    "lat": "415220N",
    "long": "0122905E"
  },
  {
    "stationcode": "INP",
    "stationname": "Central Railway Station",
    "lat": "405108N",
    "long": "0141623E"
  },
  {
    "stationcode": "XRJ",
    "stationname": "Termini Rail Station",
    "lat": "415404N",
    "long": "0123007E"
  },
  {
    "stationcode": "FIR",
    "stationname": "Firenze C. M. Railway Station",
    "lat": "434640N",
    "long": "0111636E"
  },
  {
    "stationcode": "ITT",
    "stationname": "Porta Susa Railway Station",
    "lat": "450343N",
    "long": "0074042E"
  },
  {
    "stationcode": "XVQ",
    "stationname": "Santa Lucia Rail Station",
    "lat": "452628N",
    "long": "0121916E"
  },
  {
    "stationcode": "XRL",
    "stationname": "Mediopadana Rail Station",
    "lat": "444148N",
    "long": "0103857E"
  },
  {
    "stationcode": "XIX",
    "stationname": "Porta Nuova Rail Station",
    "lat": "452700N",
    "long": "0110000E"
  },
  {
    "stationcode": "RCC",
    "stationname": "Central Railway Station",
    "lat": "380614N",
    "long": "0153808E"
  },
  {
    "stationcode": "ZMS",
    "stationname": "S.M. Novella Railway Service",
    "lat": "434637N",
    "long": "0111453E"
  },
  {
    "stationcode": "XNC",
    "stationname": "Cadorna Railway Station",
    "lat": "454600N",
    "long": "0092000E"
  },
  {
    "stationcode": "XVY",
    "stationname": "Mestre Rail Station",
    "lat": "452857N",
    "long": "0121356E"
  },
  {
    "stationcode": "TPY",
    "stationname": "Porta Nuova Railway Station",
    "lat": "450344N",
    "long": "0074044E"
  },
  {
    "stationcode": "LTZ",
    "stationname": "Central Railway Station",
    "lat": "385517N",
    "long": "0161519E"
  },
  {
    "stationcode": "QPA",
    "stationname": "Central Railway Station",
    "lat": "452503N",
    "long": "0115249E"
  },
  {
    "stationcode": "XZJ",
    "stationname": "Rail (Generic)",
    "lat": "354000N",
    "long": "1394500E"
  },
  {
    "stationcode": "XKL",
    "stationname": "Kuala Lumpur Sentral Rail",
    "lat": "030804N",
    "long": "1014112E"
  },
  {
    "stationcode": "ZSZ",
    "stationname": "Swakopmund Railway Station",
    "lat": "224010S",
    "long": "0143400E"
  },
  {
    "stationcode": "ZWH",
    "stationname": "Windhoek Railway Service",
    "lat": "223410S",
    "long": "0170610E"
  },
  {
    "stationcode": "QYH",
    "stationname": "Hengelo Railway Service",
    "lat": "521500N",
    "long": "0064500E"
  },
  {
    "stationcode": "ZYH",
    "stationname": "Holland Spoor Rail Station",
    "lat": "520454N",
    "long": "0041945E"
  },
  {
    "stationcode": "QYM",
    "stationname": "Amersfoort Railway Service",
    "lat": "520900N",
    "long": "0052400E"
  },
  {
    "stationcode": "ZVH",
    "stationname": "Veldhoven Railway Service",
    "lat": "512400N",
    "long": "0052500E"
  },
  {
    "stationcode": "QYP",
    "stationname": "Apeldoorn Railway Service",
    "lat": "521300N",
    "long": "0055800E"
  },
  {
    "stationcode": "ZYO",
    "stationname": "Roosendaal Railway Service",
    "lat": "513200N",
    "long": "0042800E"
  },
  {
    "stationcode": "QYI",
    "stationname": "Hilversum Railway Service",
    "lat": "521335N",
    "long": "0051055E"
  },
  {
    "stationcode": "ZYT",
    "stationname": "Maastricht Railway Service",
    "lat": "505102N",
    "long": "0054223E"
  },
  {
    "stationcode": "QYV",
    "stationname": "Deventer Railway Service",
    "lat": "521500N",
    "long": "0061000E"
  },
  {
    "stationcode": "ZYM",
    "stationname": "Arnhem Railway Service",
    "lat": "515900N",
    "long": "0055500E"
  },
  {
    "stationcode": "ZYE",
    "stationname": "Eindhoven Railway Service",
    "lat": "512600N",
    "long": "0052800E"
  },
  {
    "stationcode": "QYL",
    "stationname": "Almelo Railway Service",
    "lat": "522100N",
    "long": "0063900E"
  },
  {
    "stationcode": "ZYU",
    "stationname": "Utrecht Railway Service",
    "lat": "520500N",
    "long": "0050800E"
  },
  {
    "stationcode": "QYE",
    "stationname": "Enschede Railway Service",
    "lat": "521200N",
    "long": "0065300E"
  },
  {
    "stationcode": "ZYA",
    "stationname": "Amsterdam Railway Service",
    "lat": "522244N",
    "long": "0045402E"
  },
  {
    "stationcode": "SPL",
    "stationname": "Schiphol Railway Service",
    "lat": "521830N",
    "long": "0044605E"
  },
  {
    "stationcode": "ZXY",
    "stationname": "Holland Spoor Rail Station",
    "lat": "520411N",
    "long": "0041918E"
  },
  {
    "stationcode": "ZMQ",
    "stationname": "Raufoss Railway",
    "lat": "604324N",
    "long": "0103652E"
  },
  {
    "stationcode": "XUC",
    "stationname": "Elverum Railway",
    "lat": "605304N",
    "long": "0113243E"
  },
  {
    "stationcode": "YVS",
    "stationname": "Ski Railway",
    "lat": "594315N",
    "long": "0105003E"
  },
  {
    "stationcode": "ZMX",
    "stationname": "Tynset Railway",
    "lat": "621634N",
    "long": "0104619E"
  },
  {
    "stationcode": "ZZN",
    "stationname": "Nationaltheatret Rail Station",
    "lat": "595453N",
    "long": "0104521E"
  },
  {
    "stationcode": "XKJ",
    "stationname": "Steinkjer Rail Station",
    "lat": "640045N",
    "long": "0112939E"
  },
  {
    "stationcode": "XZT",
    "stationname": "Trondheim Central Station",
    "lat": "632610N",
    "long": "0102351E"
  },
  {
    "stationcode": "QFV",
    "stationname": "Bergen Rail Station",
    "lat": "602300N",
    "long": "0052000E"
  },
  {
    "stationcode": "XND",
    "stationname": "Drammen Rail Station",
    "lat": "594425N",
    "long": "0101210E"
  },
  {
    "stationcode": "XUI",
    "stationname": "Lysaker Railway",
    "lat": "595447N",
    "long": "0103813E"
  },
  {
    "stationcode": "XGP",
    "stationname": "Rail Station",
    "lat": "620406N",
    "long": "0090732E"
  },
  {
    "stationcode": "YVH",
    "stationname": "Hjerkinn Railway",
    "lat": "621311N",
    "long": "0093227E"
  },
  {
    "stationcode": "ZXX",
    "stationname": "Rade Railway Station",
    "lat": "592053N",
    "long": "0105159E"
  },
  {
    "stationcode": "XGI",
    "stationname": "Rail Station",
    "lat": "623401N",
    "long": "0074125E"
  },
  {
    "stationcode": "XKQ",
    "stationname": "Sarpsborg Rail Station",
    "lat": "591709N",
    "long": "0110705E"
  },
  {
    "stationcode": "XJA",
    "stationname": "Alvdal Railway",
    "lat": "620634N",
    "long": "0103820E"
  },
  {
    "stationcode": "XOR",
    "stationname": "Otta Rail Station",
    "lat": "614619N",
    "long": "0093212E"
  },
  {
    "stationcode": "XZO",
    "stationname": "Oslo Central Rail Station",
    "lat": "595438N",
    "long": "0104510E"
  },
  {
    "stationcode": "XXG",
    "stationname": "Verdal Railway",
    "lat": "634727N",
    "long": "0112905E"
  },
  {
    "stationcode": "ZYW",
    "stationname": "Sandvika Railway Station",
    "lat": "595316N",
    "long": "0103130E"
  },
  {
    "stationcode": "XGS",
    "stationname": "Gjerstad Rail Station",
    "lat": "585212N",
    "long": "0090100E"
  },
  {
    "stationcode": "ZMZ",
    "stationname": "Bjerka Railway",
    "lat": "660858N",
    "long": "0134954E"
  },
  {
    "stationcode": "ZXU",
    "stationname": "Rygge Railway Station",
    "lat": "592251N",
    "long": "0104459E"
  },
  {
    "stationcode": "XXC",
    "stationname": "Bo Rail Station",
    "lat": "592432N",
    "long": "0090409E"
  },
  {
    "stationcode": "XOD",
    "stationname": "Oppdal Rail Station",
    "lat": "623533N",
    "long": "0094137E"
  },
  {
    "stationcode": "XKD",
    "stationname": "Halden Rail Station",
    "lat": "590712N",
    "long": "0112302E"
  },
  {
    "stationcode": "XUU",
    "stationname": "Stjordal Railway",
    "lat": "632816N",
    "long": "0105445E"
  },
  {
    "stationcode": "XUH",
    "stationname": "Levanger Railway",
    "lat": "634443N",
    "long": "0111806E"
  },
  {
    "stationcode": "XUJ",
    "stationname": "Moelv Railway",
    "lat": "605546N",
    "long": "0104151E"
  },
  {
    "stationcode": "XVK",
    "stationname": "Rail Station",
    "lat": "603745N",
    "long": "0062436E"
  },
  {
    "stationcode": "XXL",
    "stationname": "Lillehammer Rail Station",
    "lat": "610653N",
    "long": "0102739E"
  },
  {
    "stationcode": "XXR",
    "stationname": "No. Al Railway",
    "lat": "603733N",
    "long": "0083342E"
  },
  {
    "stationcode": "XKI",
    "stationname": "Lillestrom Rail Station",
    "lat": "595709N",
    "long": "0110240E"
  },
  {
    "stationcode": "ZWJ",
    "stationname": "Haugastol Rail Station",
    "lat": "603041N",
    "long": "0075209E"
  },
  {
    "stationcode": "XKG",
    "stationname": "Grong Rail Station",
    "lat": "642730N",
    "long": "0122013E"
  },
  {
    "stationcode": "XUB",
    "stationname": "Berkak Railway",
    "lat": "624920N",
    "long": "0100053E"
  },
  {
    "stationcode": "XUM",
    "stationname": "Neslandsvatn Railway",
    "lat": "585816N",
    "long": "0090921E"
  },
  {
    "stationcode": "XUR",
    "stationname": "Skoppum Railway",
    "lat": "592328N",
    "long": "0102443E"
  },
  {
    "stationcode": "YVK",
    "stationname": "Koppang Railway",
    "lat": "613424N",
    "long": "0110235E"
  },
  {
    "stationcode": "ZYV",
    "stationname": "Vegarshei Railway Station",
    "lat": "584535N",
    "long": "0085111E"
  },
  {
    "stationcode": "XKZ",
    "stationname": "Vinstra Rail Station",
    "lat": "613542N",
    "long": "0094448E"
  },
  {
    "stationcode": "XGD",
    "stationname": "Arendal Rail Station",
    "lat": "582754N",
    "long": "0084614E"
  },
  {
    "stationcode": "ZYG",
    "stationname": "Gjovik Railway",
    "lat": "604750N",
    "long": "0104140E"
  },
  {
    "stationcode": "ZYY",
    "stationname": "Marnardal Railway Station",
    "lat": "581317N",
    "long": "0073114E"
  },
  {
    "stationcode": "XUX",
    "stationname": "Ustaoset Railway",
    "lat": "602946N",
    "long": "0080242E"
  },
  {
    "stationcode": "XOB",
    "stationname": "Rail Station",
    "lat": "584403N",
    "long": "0053855E"
  },
  {
    "stationcode": "XGU",
    "stationname": "Rail Station",
    "lat": "595001N",
    "long": "0102556E"
  },
  {
    "stationcode": "XHT",
    "stationname": "Arna Railway",
    "lat": "602510N",
    "long": "0052750E"
  },
  {
    "stationcode": "XUL",
    "stationname": "Nesbyen Railway",
    "lat": "603436N",
    "long": "0090647E"
  },
  {
    "stationcode": "XKE",
    "stationname": "Rena Rail Station",
    "lat": "610803N",
    "long": "0112208E"
  },
  {
    "stationcode": "XKF",
    "stationname": "Fredrikstad Rail Station",
    "lat": "591232N",
    "long": "0105703E"
  },
  {
    "stationcode": "XUE",
    "stationname": "Heimdal Railway",
    "lat": "632103N",
    "long": "0102126E"
  },
  {
    "stationcode": "XKW",
    "stationname": "Tonsberg Rail Station",
    "lat": "591620N",
    "long": "0102432E"
  },
  {
    "stationcode": "XRD",
    "stationname": "Rail Station",
    "lat": "582739N",
    "long": "0060011E"
  },
  {
    "stationcode": "XKM",
    "stationname": "Moss Rail Station",
    "lat": "592555N",
    "long": "0103925E"
  },
  {
    "stationcode": "XUG",
    "stationname": "Holmestrand Railway",
    "lat": "592931N",
    "long": "0101844E"
  },
  {
    "stationcode": "ZVD",
    "stationname": "Drangedal Railway Station",
    "lat": "590540N",
    "long": "0090351E"
  },
  {
    "stationcode": "ZYS",
    "stationname": "Sandefjord Railway Service",
    "lat": "590800N",
    "long": "0101329E"
  },
  {
    "stationcode": "XHF",
    "stationname": "Honefoss Rail Station",
    "lat": "601008N",
    "long": "0101450E"
  },
  {
    "stationcode": "ZWC",
    "stationname": "Stavanger Rail Station",
    "lat": "585802N",
    "long": "0054356E"
  },
  {
    "stationcode": "XUO",
    "stationname": "Nordagutu Railway",
    "lat": "592505N",
    "long": "0091921E"
  },
  {
    "stationcode": "XUV",
    "stationname": "Storekvina Railway",
    "lat": "582245N",
    "long": "0065729E"
  },
  {
    "stationcode": "XKK",
    "stationname": "Larvik Rail Station",
    "lat": "590300N",
    "long": "0100147E"
  },
  {
    "stationcode": "XKR",
    "stationname": "Kristiansand Station",
    "lat": "580819N",
    "long": "0075948E"
  },
  {
    "stationcode": "XUW",
    "stationname": "Storen Railway",
    "lat": "630311N",
    "long": "0101703E"
  },
  {
    "stationcode": "XKP",
    "stationname": "Porsgrunn Rail Station",
    "lat": "590829N",
    "long": "0093929E"
  },
  {
    "stationcode": "XOL",
    "stationname": "Rail Station",
    "lat": "604408N",
    "long": "0070724E"
  },
  {
    "stationcode": "XKB",
    "stationname": "Kongsberg Rail Station",
    "lat": "594020N",
    "long": "0093900E"
  },
  {
    "stationcode": "XKN",
    "stationname": "Finse Rail Station",
    "lat": "603607N",
    "long": "0073014E"
  },
  {
    "stationcode": "XUQ",
    "stationname": "Ringebu Railway",
    "lat": "613144N",
    "long": "0100819E"
  },
  {
    "stationcode": "XGH",
    "stationname": "Rail Station",
    "lat": "605146N",
    "long": "0070647E"
  },
  {
    "stationcode": "XHL",
    "stationname": "Rail Station",
    "lat": "583927N",
    "long": "0083746E"
  },
  {
    "stationcode": "XKC",
    "stationname": "Sandnes Rail Station",
    "lat": "585052N",
    "long": "0054402E"
  },
  {
    "stationcode": "XXE",
    "stationname": "Vennesla Railway",
    "lat": "581510N",
    "long": "0075804E"
  },
  {
    "stationcode": "XOQ",
    "stationname": "Rail Station",
    "lat": "582502N",
    "long": "0063937E"
  },
  {
    "stationcode": "XUS",
    "stationname": "Snartemo Railway",
    "lat": "581955N",
    "long": "0071242E"
  },
  {
    "stationcode": "ZVB",
    "stationname": "Brumunddal Railway",
    "lat": "605257N",
    "long": "0105611E"
  },
  {
    "stationcode": "QYO",
    "stationname": "Glowny Railway Station",
    "lat": "534800N",
    "long": "0202900E"
  },
  {
    "stationcode": "RWA",
    "stationname": "Central Railway Station",
    "lat": "521344N",
    "long": "0210013E"
  },
  {
    "stationcode": "RPZ",
    "stationname": "Glowny Railway Station",
    "lat": "522407N",
    "long": "0165439E"
  },
  {
    "stationcode": "XXW",
    "stationname": "Rail",
    "lat": "515509N",
    "long": "0190842E"
  },
  {
    "stationcode": "VKR",
    "stationname": "Glowny Railway Station",
    "lat": "500403N",
    "long": "0195650E"
  },
  {
    "stationcode": "RGD",
    "stationname": "Glowny Railway Station",
    "lat": "542120N",
    "long": "0183840E"
  },
  {
    "stationcode": "XYY",
    "stationname": "Arvika Rail Station",
    "lat": "593913N",
    "long": "0123528E"
  },
  {
    "stationcode": "XWP",
    "stationname": "Hassleholm Railway Service",
    "lat": "560928N",
    "long": "0134549E"
  },
  {
    "stationcode": "XWM",
    "stationname": "Hallsberg Rail Station",
    "lat": "590401N",
    "long": "0150640E"
  },
  {
    "stationcode": "XYC",
    "stationname": "Herrljunga Rail Station",
    "lat": "580444N",
    "long": "0130117E"
  },
  {
    "stationcode": "XXN",
    "stationname": "Kil Rail Station",
    "lat": "593018N",
    "long": "0131857E"
  },
  {
    "stationcode": "XXZ",
    "stationname": "Sundsvall Rail Station",
    "lat": "622312N",
    "long": "0171858E"
  },
  {
    "stationcode": "XFH",
    "stationname": "Strangnas Airport",
    "lat": "592200N",
    "long": "0171000E"
  },
  {
    "stationcode": "XFJ",
    "stationname": "Eskilstuna Railway Service",
    "lat": "592210N",
    "long": "0163023E"
  },
  {
    "stationcode": "XYX",
    "stationname": "Sala Railway Service",
    "lat": "595530N",
    "long": "0163624E"
  },
  {
    "stationcode": "XEW",
    "stationname": "Flemingsberg Railway Station",
    "lat": "591300N",
    "long": "0175700E"
  },
  {
    "stationcode": "XYF",
    "stationname": "Falkoping Rail Station",
    "lat": "581033N",
    "long": "0133306E"
  },
  {
    "stationcode": "QYU",
    "stationname": "Gavle Railway Service",
    "lat": "604034N",
    "long": "0170903E"
  },
  {
    "stationcode": "XYO",
    "stationname": "Karlshamn Railway Service",
    "lat": "561037N",
    "long": "0145205E"
  },
  {
    "stationcode": "XXV",
    "stationname": "Kumla Rail Station",
    "lat": "590736N",
    "long": "0150828E"
  },
  {
    "stationcode": "XXU",
    "stationname": "Hedemora Railway Service",
    "lat": "601627N",
    "long": "0155850E"
  },
  {
    "stationcode": "XXY",
    "stationname": "Ronneby Railway Service",
    "lat": "561226N",
    "long": "0151659E"
  },
  {
    "stationcode": "XEZ",
    "stationname": "Sodertalje Railway Service",
    "lat": "590900N",
    "long": "0173800E"
  },
  {
    "stationcode": "XGC",
    "stationname": "Lund Railway Service",
    "lat": "554219N",
    "long": "0131114E"
  },
  {
    "stationcode": "XEV",
    "stationname": "Stockholm Railway Service",
    "lat": "591950N",
    "long": "0180332E"
  },
  {
    "stationcode": "XWV",
    "stationname": "Varberg Rail Station",
    "lat": "570634N",
    "long": "0121456E"
  },
  {
    "stationcode": "XYK",
    "stationname": "Norrkoping Railway Service",
    "lat": "583548N",
    "long": "0161101E"
  },
  {
    "stationcode": "QYX",
    "stationname": "Uppsala Railway Service",
    "lat": "595132N",
    "long": "0173846E"
  },
  {
    "stationcode": "XWK",
    "stationname": "Karlskrona Railway Service",
    "lat": "561003N",
    "long": "0153508E"
  },
  {
    "stationcode": "XWR",
    "stationname": "Orebo-Bofors Railway Service",
    "lat": "591643N",
    "long": "0151243E"
  },
  {
    "stationcode": "XWZ",
    "stationname": "Nykoping Railway Service",
    "lat": "584520N",
    "long": "0165941E"
  },
  {
    "stationcode": "XFR",
    "stationname": "Malmo South Railway Service",
    "lat": "553254N",
    "long": "0125928E"
  },
  {
    "stationcode": "XXI",
    "stationname": "Koping Rail Station",
    "lat": "593024N",
    "long": "0160015E"
  },
  {
    "stationcode": "XXA",
    "stationname": "Alvesta Railway Service",
    "lat": "565356N",
    "long": "0143328E"
  },
  {
    "stationcode": "XXT",
    "stationname": "Arboga Rail Station",
    "lat": "592350N",
    "long": "0155028E"
  },
  {
    "stationcode": "XXO",
    "stationname": "Leksand Rail Station",
    "lat": "604402N",
    "long": "0150010E"
  },
  {
    "stationcode": "XYQ",
    "stationname": "Angelholm Railway Service",
    "lat": "561443N",
    "long": "0125115E"
  },
  {
    "stationcode": "XZQ",
    "stationname": "Rail Generic",
    "lat": "000000N",
    "long": "0000000E"
  },
  {
    "stationcode": "XYI",
    "stationname": "Flen Railway Service",
    "lat": "590327N",
    "long": "0163517E"
  },
  {
    "stationcode": "XYM",
    "stationname": "Falkenberg Rail Station",
    "lat": "565407N",
    "long": "0122915E"
  },
  {
    "stationcode": "XYZ",
    "stationname": "Harnosand Rail Station",
    "lat": "623807N",
    "long": "0175543E"
  },
  {
    "stationcode": "XWF",
    "stationname": "Falun Railway Service",
    "lat": "603613N",
    "long": "0153832E"
  },
  {
    "stationcode": "XYB",
    "stationname": "Borlange Railway Service",
    "lat": "602859N",
    "long": "0152535E"
  },
  {
    "stationcode": "XYH",
    "stationname": "Helsingborg Railway Service",
    "lat": "560235N",
    "long": "0124144E"
  },
  {
    "stationcode": "XXM",
    "stationname": "Mjolby Rail Station",
    "lat": "581923N",
    "long": "0150755E"
  },
  {
    "stationcode": "XFU",
    "stationname": "Tierp Railway Service",
    "lat": "602000N",
    "long": "0173000E"
  },
  {
    "stationcode": "XYN",
    "stationname": "Kristinehamn Rail Station",
    "lat": "591900N",
    "long": "0140830E"
  },
  {
    "stationcode": "XWL",
    "stationname": "Gothenberg Central Station",
    "lat": "574231N",
    "long": "0115824E"
  },
  {
    "stationcode": "XWQ",
    "stationname": "Enkoping Railway Service",
    "lat": "593840N",
    "long": "0170518E"
  },
  {
    "stationcode": "XEU",
    "stationname": "Arlanda Railway Service",
    "lat": "593900N",
    "long": "0175500E"
  },
  {
    "stationcode": "XYP",
    "stationname": "Avesta Krylbo Railway Service",
    "lat": "600747N",
    "long": "0161301E"
  },
  {
    "stationcode": "XXK",
    "stationname": "Katrineholm Railway Service",
    "lat": "585949N",
    "long": "0161230E"
  },
  {
    "stationcode": "XXD",
    "stationname": "Degerfors Rail Station",
    "lat": "591344N",
    "long": "0142623E"
  },
  {
    "stationcode": "XFP",
    "stationname": "Malmo Railway Service",
    "lat": "553633N",
    "long": "0125959E"
  },
  {
    "stationcode": "XWX",
    "stationname": "Nassjo Rail Station",
    "lat": "573907N",
    "long": "0144141E"
  },
  {
    "stationcode": "XYU",
    "stationname": "Solvesborg Railway Service",
    "lat": "560300N",
    "long": "0143458E"
  },
  {
    "stationcode": "LJR",
    "stationname": "Ljubljana Railway Station",
    "lat": "460332N",
    "long": "0143047E"
  },
  {
    "stationcode": "SQD",
    "stationname": "Sinop Railway Service",
    "lat": "420100N",
    "long": "0351100E"
  },
  {
    "stationcode": "XZX",
    "stationname": "Rail (Generic)",
    "lat": "330000S",
    "long": "1510000E"
  },
  {
    "stationcode": "XHX",
    "stationname": "Adelaide Keswick Rail Station",
    "lat": "345617S",
    "long": "1383453E"
  },
  {
    "stationcode": "XZK",
    "stationname": "Amherst Rail Service",
    "lat": "455000N",
    "long": "0641200W"
  },
  {
    "stationcode": "XKV",
    "stationname": "Sackville Rail Service",
    "lat": "455322N",
    "long": "0642207W"
  },
  {
    "stationcode": "XFC",
    "stationname": "Fredericton Junction Rail Service",
    "lat": "455800N",
    "long": "0663900W"
  },
  {
    "stationcode": "XEY",
    "stationname": "Miramichi Rail Service",
    "lat": "470000N",
    "long": "0653400W"
  },
  {
    "stationcode": "XAZ",
    "stationname": "Campbellton Rail Service",
    "lat": "480013N",
    "long": "0664118W"
  },
  {
    "stationcode": "XLZ",
    "stationname": "Truro Rail Service",
    "lat": "452146N",
    "long": "0631634W"
  },
  {
    "stationcode": "XDG",
    "stationname": "Halifax Rail Station",
    "lat": "443820N",
    "long": "0633406W"
  },
  {
    "stationcode": "XDP",
    "stationname": "Moncton Rail Station",
    "lat": "460501N",
    "long": "0644708W"
  },
  {
    "stationcode": "XDD",
    "stationname": "Gaspe Rail Station",
    "lat": "485200N",
    "long": "0643000W"
  },
  {
    "stationcode": "XEL",
    "stationname": "New Carlisle Rail Service",
    "lat": "480100N",
    "long": "0652000W"
  },
  {
    "stationcode": "XLJ",
    "stationname": "Quebec Station Rail Service",
    "lat": "464903N",
    "long": "0711250W"
  },
  {
    "stationcode": "XLK",
    "stationname": "Levis Rail Service",
    "lat": "464721N",
    "long": "0712353W"
  },
  {
    "stationcode": "XHM",
    "stationname": "Georgetown Rail Service",
    "lat": "433920N",
    "long": "0795509W"
  },
  {
    "stationcode": "XJQ",
    "stationname": "Jonquiere Rail Service",
    "lat": "482444N",
    "long": "0711516W"
  },
  {
    "stationcode": "XGW",
    "stationname": "Gananoque Rail Service",
    "lat": "442200N",
    "long": "0761000W"
  },
  {
    "stationcode": "XFS",
    "stationname": "Alexandria Rail Service",
    "lat": "451905N",
    "long": "0743823W"
  },
  {
    "stationcode": "XOK",
    "stationname": "Oakville Rail Service",
    "lat": "432719N",
    "long": "0794058W"
  },
  {
    "stationcode": "XLQ",
    "stationname": "Guildwood Rail Service",
    "lat": "434518N",
    "long": "0791154W"
  },
  {
    "stationcode": "XFE",
    "stationname": "Parent Rail Service",
    "lat": "475500N",
    "long": "0743700W"
  },
  {
    "stationcode": "ZIF",
    "stationname": "Fallowfield Railway",
    "lat": "451758N",
    "long": "0754413W"
  },
  {
    "stationcode": "XAX",
    "stationname": "Dorval Rail Station",
    "lat": "452656N",
    "long": "0734429W"
  },
  {
    "stationcode": "XDS",
    "stationname": "Ottawa Rail Station",
    "lat": "451910N",
    "long": "0754010W"
  },
  {
    "stationcode": "XFI",
    "stationname": "Port Daniel Rail Service",
    "lat": "481100N",
    "long": "0645800W"
  },
  {
    "stationcode": "YMY",
    "stationname": "Montreal Downtown Rail Station",
    "lat": "453000N",
    "long": "0733400W"
  },
  {
    "stationcode": "XWA",
    "stationname": "Watford Rail Station",
    "lat": "481542N",
    "long": "1034503W"
  },
  {
    "stationcode": "XGY",
    "stationname": "Grimsby Rail Service",
    "lat": "431147N",
    "long": "0793330W"
  },
  {
    "stationcode": "XIA",
    "stationname": "Guelph Rail Service",
    "lat": "433300N",
    "long": "0801500W"
  },
  {
    "stationcode": "XII",
    "stationname": "Prescott Rail Service",
    "lat": "444300N",
    "long": "0753100W"
  },
  {
    "stationcode": "XDM",
    "stationname": "Drummondville Rail Service",
    "lat": "455257N",
    "long": "0722915W"
  },
  {
    "stationcode": "YBZ",
    "stationname": "Toronto Downtown Rail Station",
    "lat": "433843N",
    "long": "0792251W"
  },
  {
    "stationcode": "XPX",
    "stationname": "Pointe-aux-Trembles Rail Service",
    "lat": "453900N",
    "long": "0733000W"
  },
  {
    "stationcode": "XDX",
    "stationname": "Sarnia Rail Station",
    "lat": "425727N",
    "long": "0822321W"
  },
  {
    "stationcode": "XCI",
    "stationname": "Chambord Railway Services",
    "lat": "482600N",
    "long": "0720400W"
  },
  {
    "stationcode": "XAW",
    "stationname": "Capreol Rail Services",
    "lat": "464239N",
    "long": "0805542W"
  },
  {
    "stationcode": "XDY",
    "stationname": "Sudbury Jct. Rail Station",
    "lat": "463710N",
    "long": "0804810W"
  },
  {
    "stationcode": "XFV",
    "stationname": "Brantford Rail Service",
    "lat": "430848N",
    "long": "0801557W"
  },
  {
    "stationcode": "XIO",
    "stationname": "St. Marys Rail Service",
    "lat": "431500N",
    "long": "0810800W"
  },
  {
    "stationcode": "XLY",
    "stationname": "Aldershot Rail Service",
    "lat": "431848N",
    "long": "0795121W"
  },
  {
    "stationcode": "XGJ",
    "stationname": "Cobourg Rail Service",
    "lat": "435805N",
    "long": "0781015W"
  },
  {
    "stationcode": "XLV",
    "stationname": "Niagara Falls Rail Service",
    "lat": "430632N",
    "long": "0790349W"
  },
  {
    "stationcode": "XFK",
    "stationname": "Senneterre Rail Service",
    "lat": "482336N",
    "long": "0771450W"
  },
  {
    "stationcode": "XPH",
    "stationname": "Port Hope Rail Service",
    "lat": "435700N",
    "long": "0781800W"
  },
  {
    "stationcode": "XFY",
    "stationname": "Sainte-Foy Rail Service",
    "lat": "464516N",
    "long": "0711752W"
  },
  {
    "stationcode": "XZC",
    "stationname": "Glencoe Rail Service",
    "lat": "424600N",
    "long": "0814400W"
  },
  {
    "stationcode": "XEM",
    "stationname": "New Richmond Rail Service",
    "lat": "482000N",
    "long": "0655000W"
  },
  {
    "stationcode": "XEC",
    "stationname": "Windsor Rail Station",
    "lat": "421931N",
    "long": "0830030W"
  },
  {
    "stationcode": "XDQ",
    "stationname": "London Rail Station",
    "lat": "430210N",
    "long": "0810910W"
  },
  {
    "stationcode": "XPN",
    "stationname": "Brampton Rail Service",
    "lat": "434100N",
    "long": "0794600W"
  },
  {
    "stationcode": "XEG",
    "stationname": "Kingston Rail Station",
    "lat": "441526N",
    "long": "0763213W"
  },
  {
    "stationcode": "XTY",
    "stationname": "Strathroy Rail Service",
    "lat": "425700N",
    "long": "0813800W"
  },
  {
    "stationcode": "XIP",
    "stationname": "Woodstock Rail Service",
    "lat": "430800N",
    "long": "0804500W"
  },
  {
    "stationcode": "XFG",
    "stationname": "Perce Rail Service",
    "lat": "482800N",
    "long": "0641900W"
  },
  {
    "stationcode": "XFQ",
    "stationname": "Weymont Rail Service",
    "lat": "475400N",
    "long": "0734500W"
  },
  {
    "stationcode": "XGK",
    "stationname": "Coteau Rail Service",
    "lat": "451629N",
    "long": "0741359W"
  },
  {
    "stationcode": "XLP",
    "stationname": "Matapedia Rail Service",
    "lat": "475800N",
    "long": "0665700W"
  },
  {
    "stationcode": "XRP",
    "stationname": "Riviere-a-Pierre Rail Service",
    "lat": "465900N",
    "long": "0721100W"
  },
  {
    "stationcode": "XJL",
    "stationname": "Joliette Rail Service",
    "lat": "460100N",
    "long": "0732700W"
  },
  {
    "stationcode": "XDU",
    "stationname": "Hervey Rail Service",
    "lat": "455100N",
    "long": "0722800W"
  },
  {
    "stationcode": "XVV",
    "stationname": "Belleville Rail Service",
    "lat": "441046N",
    "long": "0772230W"
  },
  {
    "stationcode": "XLM",
    "stationname": "St. Lambert Rail Station",
    "lat": "452957N",
    "long": "0733027W"
  },
  {
    "stationcode": "XDO",
    "stationname": "Grande-Riviere Rail Service",
    "lat": "482400N",
    "long": "0643000W"
  },
  {
    "stationcode": "XWY",
    "stationname": "Wyoming Rail Service",
    "lat": "425700N",
    "long": "0820700W"
  },
  {
    "stationcode": "XFD",
    "stationname": "Stratford Rail Service",
    "lat": "432153N",
    "long": "0805836W"
  },
  {
    "stationcode": "XON",
    "stationname": "Carleton Rail Services",
    "lat": "480700N",
    "long": "0660800W"
  },
  {
    "stationcode": "XIB",
    "stationname": "Ingersoll Rail Service",
    "lat": "430200N",
    "long": "0805300W"
  },
  {
    "stationcode": "XIF",
    "stationname": "Napanee Rail Service",
    "lat": "441500N",
    "long": "0765700W"
  },
  {
    "stationcode": "XZB",
    "stationname": "Casselman Rail Service",
    "lat": "451900N",
    "long": "0750500W"
  },
  {
    "stationcode": "XFO",
    "stationname": "Taschereau Rail Service",
    "lat": "484000N",
    "long": "0784000W"
  },
  {
    "stationcode": "XDL",
    "stationname": "Chandler Rail Services",
    "lat": "482100N",
    "long": "0644100W"
  },
  {
    "stationcode": "XFL",
    "stationname": "Shawinigan Rail Service",
    "lat": "463300N",
    "long": "0724500W"
  },
  {
    "stationcode": "XID",
    "stationname": "Maxville Rail Service",
    "lat": "451700N",
    "long": "0745100W"
  },
  {
    "stationcode": "XEE",
    "stationname": "Lac Edouard Rail Service",
    "lat": "473900N",
    "long": "0721600W"
  },
  {
    "stationcode": "XIM",
    "stationname": "Saint Hyacinthe Rail Service",
    "lat": "453740N",
    "long": "0725657W"
  },
  {
    "stationcode": "XAD",
    "stationname": "Churchill Rail Station",
    "lat": "584601N",
    "long": "0941021W"
  },
  {
    "stationcode": "XEF",
    "stationname": "Winnipeg Rail Station",
    "lat": "495321N",
    "long": "0970804W"
  },
  {
    "stationcode": "XEK",
    "stationname": "Melville Rail Service",
    "lat": "505522N",
    "long": "1024810W"
  },
  {
    "stationcode": "XDZ",
    "stationname": "The Pas Rail Station",
    "lat": "534930N",
    "long": "1011452W"
  },
  {
    "stationcode": "XDH",
    "stationname": "Jasper Rail Station",
    "lat": "525235N",
    "long": "1180444W"
  },
  {
    "stationcode": "XZL",
    "stationname": "Edmonton Rail Service",
    "lat": "533456N",
    "long": "1133133W"
  },
  {
    "stationcode": "XHS",
    "stationname": "Chemainus Rail Services",
    "lat": "485517N",
    "long": "1234306W"
  },
  {
    "stationcode": "XEH",
    "stationname": "Ladysmith Rail Service",
    "lat": "490000N",
    "long": "1234900W"
  },
  {
    "stationcode": "XEJ",
    "stationname": "Langford Rail Service",
    "lat": "482700N",
    "long": "1233000W"
  },
  {
    "stationcode": "XEA",
    "stationname": "Vancouver Rail Station",
    "lat": "491618N",
    "long": "1230554W"
  },
  {
    "stationcode": "XPB",
    "stationname": "Parksville Rail Service",
    "lat": "360419N",
    "long": "1292031E"
  },
  {
    "stationcode": "XFM",
    "stationname": "Shawnigan Rail Service",
    "lat": "483800N",
    "long": "1233500W"
  },
  {
    "stationcode": "XDV",
    "stationname": "Prince George Rail Station",
    "lat": "535518N",
    "long": "1224511W"
  },
  {
    "stationcode": "XDW",
    "stationname": "Prince Rupert Rail Station",
    "lat": "541828N",
    "long": "1302004W"
  },
  {
    "stationcode": "FIS",
    "stationname": "Central Railway St.",
    "lat": "041804S",
    "long": "0151904E"
  },
  {
    "stationcode": "QGN",
    "stationname": "Tarragona/Camp Rail Station",
    "lat": "411131N",
    "long": "0011622E"
  },
  {
    "stationcode": "XZZ",
    "stationname": "Delicias Railway Station",
    "lat": "413905N",
    "long": "0005342W"
  },
  {
    "stationcode": "UER",
    "stationname": "Puertollano Railway Station",
    "lat": "384129N",
    "long": "0040643W"
  },
  {
    "stationcode": "FES",
    "stationname": "Bahia Sur Railway Station",
    "lat": "362800N",
    "long": "0061200W"
  },
  {
    "stationcode": "YJI",
    "stationname": "Abando Railway Station",
    "lat": "431539N",
    "long": "0025539W"
  },
  {
    "stationcode": "XQA",
    "stationname": "Santa Justa Railway Station",
    "lat": "372339N",
    "long": "0055827W"
  },
  {
    "stationcode": "GEN",
    "stationname": "Herrera Railway Station",
    "lat": "372300N",
    "long": "0044700W"
  },
  {
    "stationcode": "XOC",
    "stationname": "Atocha Railway Station",
    "lat": "402421N",
    "long": "0034127W"
  },
  {
    "stationcode": "YJD",
    "stationname": "Franca Railway Station",
    "lat": "412303N",
    "long": "0021110E"
  },
  {
    "stationcode": "CDZ",
    "stationname": "Cadiz Railway Station",
    "lat": "363146N",
    "long": "0061719W"
  },
  {
    "stationcode": "YJB",
    "stationname": "Sants Railway Station",
    "lat": "412245N",
    "long": "0020824E"
  },
  {
    "stationcode": "AEI",
    "stationname": "Algeciras Railway Station",
    "lat": "360736N",
    "long": "0052657W"
  },
  {
    "stationcode": "XOJ",
    "stationname": "Central Railway Station",
    "lat": "375318N",
    "long": "0044722W"
  },
  {
    "stationcode": "XIV",
    "stationname": "Campo Grande Rail Station",
    "lat": "413831N",
    "long": "0044337W"
  },
  {
    "stationcode": "XTI",
    "stationname": "Chamartin Railway Station",
    "lat": "402826N",
    "long": "0034057W"
  },
  {
    "stationcode": "XOU",
    "stationname": "Guiomar Railway Station",
    "lat": "405436N",
    "long": "0040541W"
  },
  {
    "stationcode": "ZKD",
    "stationname": "Leningradskiy Rail Station",
    "lat": "554650N",
    "long": "0373900E"
  },
  {
    "stationcode": "ZLK",
    "stationname": "Moscovskiy Railway Station",
    "lat": "595547N",
    "long": "0302144E"
  },
  {
    "stationcode": "JQO",
    "stationname": "Belorussky Railway Station",
    "lat": "554635N",
    "long": "0373449E"
  },
  {
    "stationcode": "FVS",
    "stationname": "Finlyandsky Rail Station",
    "lat": "595720N",
    "long": "0302123E"
  },
  {
    "stationcode": "XRK",
    "stationname": "Paveletskiy Rail Station",
    "lat": "554359N",
    "long": "0373859E"
  },
  {
    "stationcode": "JQF",
    "stationname": "Savelovsky Rail Station",
    "lat": "554739N",
    "long": "0373517E"
  },
  {
    "stationcode": "ZRV",
    "stationname": "Providence Rail Station",
    "lat": "414945N",
    "long": "0712450W"
  },
  {
    "stationcode": "ZRP",
    "stationname": "Newark NJ Rail",
    "lat": "404405N",
    "long": "0740953W"
  },
  {
    "stationcode": "ZBP",
    "stationname": "Baltimore Rail",
    "lat": "391827N",
    "long": "0763657W"
  },
  {
    "stationcode": "ZTY",
    "stationname": "Boston BkBay Rail",
    "lat": "422051N",
    "long": "0710437W"
  },
  {
    "stationcode": "ZVE",
    "stationname": "New Haven Rail",
    "lat": "411752N",
    "long": "0725537W"
  },
  {
    "stationcode": "ZUG",
    "stationname": "Harrisburg Rail",
    "lat": "401542N",
    "long": "0765243W"
  },
  {
    "stationcode": "ZFT",
    "stationname": "Fort Lauderdale Railway Service",
    "lat": "260410N",
    "long": "0801010W"
  },
  {
    "stationcode": "ZFZ",
    "stationname": "Buffalo-Depew Rail Service",
    "lat": "425423N",
    "long": "0784335W"
  },
  {
    "stationcode": "ZTN",
    "stationname": "Trenton Rail Station",
    "lat": "401307N",
    "long": "0744359W"
  },
  {
    "stationcode": "ZME",
    "stationname": "Metropark Rail Service",
    "lat": "403406N",
    "long": "0741947W"
  },
  {
    "stationcode": "ZTD",
    "stationname": "Schenectady Rail",
    "lat": "424855N",
    "long": "0735636W"
  },
  {
    "stationcode": "ZTJ",
    "stationname": "Princeton JT Rail Station",
    "lat": "401859N",
    "long": "0743729W"
  },
  {
    "stationcode": "ZRA",
    "stationname": "Atlantic City Railway Service",
    "lat": "392210N",
    "long": "0742700W"
  },
  {
    "stationcode": "ZXS",
    "stationname": "Exchange St. Railway Service",
    "lat": "425610N",
    "long": "0784410W"
  },
  {
    "stationcode": "ZYQ",
    "stationname": "Syracuse New York Rail",
    "lat": "430413N",
    "long": "0760213W"
  },
  {
    "stationcode": "ZWP",
    "stationname": "West Palm Beach Railway Service",
    "lat": "264110N",
    "long": "0800610W"
  },
  {
    "stationcode": "ZGD",
    "stationname": "New London Rail",
    "lat": "412115N",
    "long": "0720537W"
  },
  {
    "stationcode": "NYP",
    "stationname": "New York Pennsylvania Station",
    "lat": "404231N",
    "long": "0734331W"
  },
  {
    "stationcode": "ZHC",
    "stationname": "North Philadelhia Rail Station",
    "lat": "400510N",
    "long": "0750100W"
  },
  {
    "stationcode": "ZRT",
    "stationname": "Hartford CT Rail",
    "lat": "414609N",
    "long": "0724052W"
  },
  {
    "stationcode": "ZWU",
    "stationname": "Washington DC Rail",
    "lat": "385351N",
    "long": "0770023W"
  },
  {
    "stationcode": "ZSF",
    "stationname": "Springfield Massachusetts Rail Road",
    "lat": "420621N",
    "long": "0723537W"
  },
  {
    "stationcode": "ZRD",
    "stationname": "Richmond Virginia Rail",
    "lat": "373703N",
    "long": "0772949W"
  },
  {
    "stationcode": "ZRU",
    "stationname": "Boston RT128 Rail",
    "lat": "421413N",
    "long": "0710758W"
  },
  {
    "stationcode": "ZTO",
    "stationname": "Boston South Rail",
    "lat": "422107N",
    "long": "0710321W"
  },
  {
    "stationcode": "ZLY",
    "stationname": "Albany New York Rail",
    "lat": "423828N",
    "long": "0734428W"
  },
  {
    "stationcode": "ZWI",
    "stationname": "Wilmington Rail Service",
    "lat": "394413N",
    "long": "0753304W"
  },
  {
    "stationcode": "ZRL",
    "stationname": "Lancaster PA Rail",
    "lat": "400313N",
    "long": "0761827W"
  },
  {
    "stationcode": "ZWB",
    "stationname": "Williamsburg Rail Service",
    "lat": "371634N",
    "long": "0764231W"
  },
  {
    "stationcode": "ZFV",
    "stationname": "Philadelphia Rail Station",
    "lat": "395721N",
    "long": "0751056W"
  },
  {
    "stationcode": "ZTE",
    "stationname": "Rochester New York Rail",
    "lat": "430946N",
    "long": "0773633W"
  },
  {
    "stationcode": "ZYP",
    "stationname": "New York Penn Station",
    "lat": "404502N",
    "long": "0735937W"
  },
  {
    "stationcode": "ZUA",
    "stationname": "Utica New York Rail",
    "lat": "430614N",
    "long": "0751328W"
  },
  {
    "stationcode": "ZWW",
    "stationname": "Newport News Rail",
    "lat": "370121N",
    "long": "0762706W"
  },
  {
    "stationcode": "ZRZ",
    "stationname": "New Carrolton Rail Road",
    "lat": "385650N",
    "long": "0765211W"
  },
  {
    "stationcode": "XZU",
    "stationname": "Rail (Generic)",
    "lat": "400000N",
    "long": "0740000W"
  },
  {
    "stationcode": "ZUK",
    "stationname": "Railway Service",
    "lat": "421528N",
    "long": "0875017W"
  },
  {
    "stationcode": "ZSV",
    "stationname": "St. Louis Railway Service",
    "lat": "383710N",
    "long": "0901110W"
  },
  {
    "stationcode": "ZUN",
    "stationname": "Union Railway Service",
    "lat": "415240N",
    "long": "0873830W"
  },
  {
    "stationcode": "ZWV",
    "stationname": "Glenview Railway Service",
    "lat": "420742N",
    "long": "0874853W"
  },
  {
    "stationcode": "ZTV",
    "stationname": "Railway Service",
    "lat": "424158N",
    "long": "0875452W"
  },
  {
    "stationcode": "ZML",
    "stationname": "Milwaukee Railway Service",
    "lat": "425710N",
    "long": "0875410W"
  },
  {
    "stationcode": "XJG",
    "stationname": "Darwin Rail Terminal",
    "lat": "122821S",
    "long": "1305415E"
  },
  {
    "stationcode": "XHW",
    "stationname": "Alice Springs Railway",
    "lat": "234151S",
    "long": "1335226E"
  },
  {
    "stationcode": "NDO",
    "stationname": "Railway Station",
    "lat": "372300N",
    "long": "0063300W"
  },
  {
    "stationcode": "QVL",
    "stationname": "Railway Station",
    "lat": "463708N",
    "long": "0135056E"
  },
  {
    "stationcode": "KGV",
    "stationname": "Railway Station",
    "lat": "463656N",
    "long": "0141848E"
  },
  {
    "stationcode": "POK",
    "stationname": "Railway Station",
    "lat": "481230N",
    "long": "0153728E"
  },
  {
    "stationcode": "GGZ",
    "stationname": "Railway Station",
    "lat": "470423N",
    "long": "0152458E"
  },
  {
    "stationcode": "IOB",
    "stationname": "Railway Station",
    "lat": "471546N",
    "long": "0112404E"
  },
  {
    "stationcode": "ZGP",
    "stationname": "Railway Station",
    "lat": "510149N",
    "long": "0042925E"
  },
  {
    "stationcode": "ZRN",
    "stationname": "Railway Station",
    "lat": "462300N",
    "long": "0061500E"
  },
  {
    "stationcode": "QGL",
    "stationname": "Railway Station",
    "lat": "472800N",
    "long": "0092400E"
  },
  {
    "stationcode": "ZJZ",
    "stationname": "Railway Station",
    "lat": "472800N",
    "long": "0093100E"
  },
  {
    "stationcode": "ZHD",
    "stationname": "Railway Station",
    "lat": "465400N",
    "long": "0083800E"
  },
  {
    "stationcode": "ZKS",
    "stationname": "Railway Station",
    "lat": "471400N",
    "long": "0073100E"
  },
  {
    "stationcode": "ZLF",
    "stationname": "Railway Station",
    "lat": "472800N",
    "long": "0081900E"
  },
  {
    "stationcode": "ZDC",
    "stationname": "Railway Station",
    "lat": "461900N",
    "long": "0065800E"
  },
  {
    "stationcode": "ZHS",
    "stationname": "Railway Station",
    "lat": "465300N",
    "long": "0095200E"
  },
  {
    "stationcode": "ZDA",
    "stationname": "Railway Station",
    "lat": "472300N",
    "long": "0080300E"
  },
  {
    "stationcode": "ZHW",
    "stationname": "Railway Station",
    "lat": "471300N",
    "long": "0074700E"
  },
  {
    "stationcode": "ZJM",
    "stationname": "Railway Station",
    "lat": "460500N",
    "long": "0070500E"
  },
  {
    "stationcode": "ZKC",
    "stationname": "Railway Station",
    "lat": "465400N",
    "long": "0081400E"
  },
  {
    "stationcode": "ZKV",
    "stationname": "Railway Station",
    "lat": "471700N",
    "long": "0083400E"
  },
  {
    "stationcode": "ZLI",
    "stationname": "Railway Station",
    "lat": "473000N",
    "long": "0084500E"
  },
  {
    "stationcode": "ZDZ",
    "stationname": "Railway Station",
    "lat": "470700N",
    "long": "0084500E"
  },
  {
    "stationcode": "ZHR",
    "stationname": "Railway Station",
    "lat": "462900N",
    "long": "0074000E"
  },
  {
    "stationcode": "ZDO",
    "stationname": "Railway Station",
    "lat": "471000N",
    "long": "0092800E"
  },
  {
    "stationcode": "ZHK",
    "stationname": "Railway Station",
    "lat": "462800N",
    "long": "0071700E"
  },
  {
    "stationcode": "ZLJ",
    "stationname": "Railway Station",
    "lat": "464700N",
    "long": "0063900E"
  },
  {
    "stationcode": "ZDW",
    "stationname": "Railway Station",
    "lat": "472200N",
    "long": "0072000E"
  },
  {
    "stationcode": "ZJQ",
    "stationname": "Railway Station",
    "lat": "463100N",
    "long": "0063000E"
  },
  {
    "stationcode": "ZDQ",
    "stationname": "Railway Station",
    "lat": "461100N",
    "long": "0065200E"
  },
  {
    "stationcode": "ZDS",
    "stationname": "Railway Station",
    "lat": "455000N",
    "long": "0090200E"
  },
  {
    "stationcode": "ZHL",
    "stationname": "Railway Station",
    "lat": "472400N",
    "long": "0093800E"
  },
  {
    "stationcode": "ZKK",
    "stationname": "Railway Station",
    "lat": "470100N",
    "long": "0083900E"
  },
  {
    "stationcode": "ZDG",
    "stationname": "Railway Station",
    "lat": "472800N",
    "long": "0081800E"
  },
  {
    "stationcode": "QNC",
    "stationname": "Railway Station",
    "lat": "470000N",
    "long": "0065800E"
  },
  {
    "stationcode": "ZLA",
    "stationname": "Railway Station",
    "lat": "461800N",
    "long": "0070300E"
  },
  {
    "stationcode": "QLJ",
    "stationname": "Railway Station",
    "lat": "470258N",
    "long": "0081838E"
  },
  {
    "stationcode": "ZJV",
    "stationname": "Railway Station",
    "lat": "462900N",
    "long": "0095400E"
  },
  {
    "stationcode": "ZKF",
    "stationname": "Railway Station",
    "lat": "472700N",
    "long": "0093700E"
  },
  {
    "stationcode": "ZLL",
    "stationname": "Railway Station",
    "lat": "471700N",
    "long": "0075600E"
  },
  {
    "stationcode": "ZDR",
    "stationname": "Railway Station",
    "lat": "462800N",
    "long": "0070800E"
  },
  {
    "stationcode": "ZLE",
    "stationname": "Railway Station",
    "lat": "463600N",
    "long": "0075500E"
  },
  {
    "stationcode": "ZJL",
    "stationname": "Railway Station",
    "lat": "470400N",
    "long": "0071800E"
  },
  {
    "stationcode": "ZJP",
    "stationname": "Railway Station",
    "lat": "462600N",
    "long": "0065500E"
  },
  {
    "stationcode": "ZHH",
    "stationname": "Railway Station",
    "lat": "472500N",
    "long": "0091500E"
  },
  {
    "stationcode": "ZHG",
    "stationname": "Railway Station",
    "lat": "470200N",
    "long": "0090400E"
  },
  {
    "stationcode": "ZJC",
    "stationname": "Railway Station",
    "lat": "472300N",
    "long": "0081100E"
  },
  {
    "stationcode": "ZDE",
    "stationname": "Railway Station",
    "lat": "464700N",
    "long": "0094000E"
  },
  {
    "stationcode": "ZDK",
    "stationname": "Railway Station",
    "lat": "471000N",
    "long": "0071500E"
  },
  {
    "stationcode": "ZHE",
    "stationname": "Railway Station",
    "lat": "473300N",
    "long": "0085400E"
  },
  {
    "stationcode": "ZHU",
    "stationname": "Railway Station",
    "lat": "473900N",
    "long": "0091100E"
  },
  {
    "stationcode": "ZJW",
    "stationname": "Railway Station",
    "lat": "471300N",
    "long": "0084900E"
  },
  {
    "stationcode": "ZLB",
    "stationname": "Railway Station",
    "lat": "461700N",
    "long": "0075200E"
  },
  {
    "stationcode": "ZHF",
    "stationname": "Railway Station",
    "lat": "464800N",
    "long": "0070900E"
  },
  {
    "stationcode": "ZLC",
    "stationname": "Railway Station",
    "lat": "471400N",
    "long": "0084000E"
  },
  {
    "stationcode": "ZLD",
    "stationname": "Railway Station",
    "lat": "473400N",
    "long": "0090600E"
  },
  {
    "stationcode": "ZJU",
    "stationname": "Railway Station",
    "lat": "472100N",
    "long": "0075500E"
  },
  {
    "stationcode": "ZLH",
    "stationname": "Railway Station",
    "lat": "472800N",
    "long": "0090300E"
  },
  {
    "stationcode": "ZDL",
    "stationname": "Railway Station",
    "lat": "461900N",
    "long": "0075900E"
  },
  {
    "stationcode": "ZLM",
    "stationname": "Railway Station",
    "lat": "471000N",
    "long": "0083100E"
  },
  {
    "stationcode": "ZDT",
    "stationname": "Railway Station",
    "lat": "465100N",
    "long": "0093000E"
  },
  {
    "stationcode": "ZTK",
    "stationname": "Railway Station",
    "lat": "464500N",
    "long": "0073700E"
  },
  {
    "stationcode": "ZDD",
    "stationname": "Railway Station",
    "lat": "473100N",
    "long": "0092600E"
  },
  {
    "stationcode": "ZDP",
    "stationname": "Railway Station",
    "lat": "470300N",
    "long": "0073700E"
  },
  {
    "stationcode": "ZJA",
    "stationname": "Railway Station",
    "lat": "470400N",
    "long": "0064500E"
  },
  {
    "stationcode": "ZDI",
    "stationname": "Railway Station",
    "lat": "461200N",
    "long": "0090100E"
  },
  {
    "stationcode": "ZKJ",
    "stationname": "Railway Station",
    "lat": "474200N",
    "long": "0083800E"
  },
  {
    "stationcode": "ZKW",
    "stationname": "Railway Station",
    "lat": "471900N",
    "long": "0084700E"
  },
  {
    "stationcode": "ZKX",
    "stationname": "Railway Station",
    "lat": "472600N",
    "long": "0090800E"
  },
  {
    "stationcode": "ZKO",
    "stationname": "Railway Station",
    "lat": "461800N",
    "long": "0073200E"
  },
  {
    "stationcode": "ZHV",
    "stationname": "Railway Station",
    "lat": "470800N",
    "long": "0065100E"
  },
  {
    "stationcode": "ZKU",
    "stationname": "Railway Station",
    "lat": "471000N",
    "long": "0080600E"
  },
  {
    "stationcode": "ZHN",
    "stationname": "Railway Station",
    "lat": "471100N",
    "long": "0074200E"
  },
  {
    "stationcode": "ZHJ",
    "stationname": "Railway Station",
    "lat": "463700N",
    "long": "0080300E"
  },
  {
    "stationcode": "QLS",
    "stationname": "Railway Station",
    "lat": "463102N",
    "long": "0063745E"
  },
  {
    "stationcode": "ZJI",
    "stationname": "Railway Station",
    "lat": "461000N",
    "long": "0084800E"
  },
  {
    "stationcode": "NHG",
    "stationname": "Railway Station",
    "lat": "283944N",
    "long": "1155511E"
  },
  {
    "stationcode": "LHC",
    "stationname": "Railway Station",
    "lat": "285258N",
    "long": "1211434E"
  },
  {
    "stationcode": "EJJ",
    "stationname": "Railway Station",
    "lat": "302222N",
    "long": "1121227E"
  },
  {
    "stationcode": "RLZ",
    "stationname": "Railway Station",
    "lat": "241827N",
    "long": "1092320E"
  },
  {
    "stationcode": "DHU",
    "stationname": "Railway Station",
    "lat": "275039N",
    "long": "1130915E"
  },
  {
    "stationcode": "TTZ",
    "stationname": "Railway Station",
    "lat": "344450N",
    "long": "1133936E"
  },
  {
    "stationcode": "JQJ",
    "stationname": "Railway Station",
    "lat": "292635N",
    "long": "1084726E"
  },
  {
    "stationcode": "JLL",
    "stationname": "Railway Station",
    "lat": "435139N",
    "long": "1263420E"
  },
  {
    "stationcode": "GJN",
    "stationname": "Railway Station",
    "lat": "302728N",
    "long": "1113422E"
  },
  {
    "stationcode": "QHX",
    "stationname": "Railway Station",
    "lat": "191430N",
    "long": "1102916E"
  },
  {
    "stationcode": "LQQ",
    "stationname": "Railway Station",
    "lat": "411646N",
    "long": "1230943E"
  },
  {
    "stationcode": "KLJ",
    "stationname": "Railway Station",
    "lat": "320333N",
    "long": "1120902E"
  },
  {
    "stationcode": "IUO",
    "stationname": "Railway Station",
    "lat": "295339N",
    "long": "1141739E"
  },
  {
    "stationcode": "LQP",
    "stationname": "Railway Station",
    "lat": "254831N",
    "long": "1130202E"
  },
  {
    "stationcode": "OSQ",
    "stationname": "Railway Station",
    "lat": "431001N",
    "long": "1242208E"
  },
  {
    "stationcode": "HBP",
    "stationname": "Railway Station",
    "lat": "265334N",
    "long": "1123738E"
  },
  {
    "stationcode": "WEC",
    "stationname": "Railway Station",
    "lat": "193627N",
    "long": "1104359E"
  },
  {
    "stationcode": "KFI",
    "stationname": "Railway Station",
    "lat": "315313N",
    "long": "1171843E"
  },
  {
    "stationcode": "WZE",
    "stationname": "Railway Station",
    "lat": "303937N",
    "long": "1112154E"
  },
  {
    "stationcode": "YUG",
    "stationname": "Railway Station",
    "lat": "292246N",
    "long": "1130651E"
  },
  {
    "stationcode": "YIU",
    "stationname": "Railway Station",
    "lat": "292250N",
    "long": "1200219E"
  },
  {
    "stationcode": "EJQ",
    "stationname": "Railway Station",
    "lat": "323613N",
    "long": "1104654E"
  },
  {
    "stationcode": "WXJ",
    "stationname": "Railway Station",
    "lat": "184834N",
    "long": "1102237E"
  },
  {
    "stationcode": "HSD",
    "stationname": "Railway Station",
    "lat": "300923N",
    "long": "1145607E"
  },
  {
    "stationcode": "LQS",
    "stationname": "Railway Station",
    "lat": "183217N",
    "long": "1100145E"
  },
  {
    "stationcode": "ZUJ",
    "stationname": "Railway Station",
    "lat": "321156N",
    "long": "1192539E"
  },
  {
    "stationcode": "TTV",
    "stationname": "Railway Station",
    "lat": "421350N",
    "long": "1233957E"
  },
  {
    "stationcode": "BPO",
    "stationname": "Railway Station",
    "lat": "190817N",
    "long": "1102858E"
  },
  {
    "stationcode": "DYN",
    "stationname": "Railway Station",
    "lat": "320013N",
    "long": "1193513E"
  },
  {
    "stationcode": "HZC",
    "stationname": "Railway Station",
    "lat": "305156N",
    "long": "1200104E"
  },
  {
    "stationcode": "RNX",
    "stationname": "Railway Station",
    "lat": "300102N",
    "long": "1203433E"
  },
  {
    "stationcode": "DDV",
    "stationname": "Railway Station",
    "lat": "275016N",
    "long": "1130908E"
  },
  {
    "stationcode": "WHZ",
    "stationname": "Railway Station",
    "lat": "303637N",
    "long": "1142507E"
  },
  {
    "stationcode": "EWV",
    "stationname": "Railway Station",
    "lat": "302107N",
    "long": "1092847E"
  },
  {
    "stationcode": "PXG",
    "stationname": "Railway Station",
    "lat": "273847N",
    "long": "1135031E"
  },
  {
    "stationcode": "VWB",
    "stationname": "Railway Station",
    "lat": "435434N",
    "long": "1251919E"
  },
  {
    "stationcode": "QSJ",
    "stationname": "Railway Station",
    "lat": "380039N",
    "long": "1142904E"
  },
  {
    "stationcode": "ZPA",
    "stationname": "Railway Station",
    "lat": "482834N",
    "long": "0075647E"
  },
  {
    "stationcode": "ZPY",
    "stationname": "Railway Station",
    "lat": "504800N",
    "long": "0071200E"
  },
  {
    "stationcode": "ZAU",
    "stationname": "Railway Station",
    "lat": "503500N",
    "long": "0124200E"
  },
  {
    "stationcode": "ZEU",
    "stationname": "Railway Station",
    "lat": "513212N",
    "long": "0095537E"
  },
  {
    "stationcode": "ZNB",
    "stationname": "Railway Station",
    "lat": "514100N",
    "long": "0074800E"
  },
  {
    "stationcode": "ZNW",
    "stationname": "Railway Station",
    "lat": "502300N",
    "long": "0080300E"
  },
  {
    "stationcode": "ZEE",
    "stationname": "Railway Station",
    "lat": "503314N",
    "long": "0094102E"
  },
  {
    "stationcode": "ZEI",
    "stationname": "Railway Station",
    "lat": "473000N",
    "long": "0110600E"
  },
  {
    "stationcode": "ZPD",
    "stationname": "Railway Station",
    "lat": "530837N",
    "long": "0081321E"
  },
  {
    "stationcode": "QOB",
    "stationname": "Railway Station",
    "lat": "491800N",
    "long": "0103500E"
  },
  {
    "stationcode": "ZOM",
    "stationname": "Railway Station",
    "lat": "521700N",
    "long": "0085500E"
  },
  {
    "stationcode": "ZFR",
    "stationname": "Railway Station",
    "lat": "522100N",
    "long": "0143300E"
  },
  {
    "stationcode": "QSG",
    "stationname": "Railway Station",
    "lat": "545752N",
    "long": "0094730E"
  },
  {
    "stationcode": "ZYN",
    "stationname": "Railway Station",
    "lat": "434957N",
    "long": "0042159E"
  },
  {
    "stationcode": "XWG",
    "stationname": "Railway Station",
    "lat": "483506N",
    "long": "0074404E"
  },
  {
    "stationcode": "NNE",
    "stationname": "Railway Station",
    "lat": "431128N",
    "long": "0030018E"
  },
  {
    "stationcode": "XVS",
    "stationname": "Railway Station",
    "lat": "502100N",
    "long": "0033200E"
  },
  {
    "stationcode": "PCW",
    "stationname": "Railway Station",
    "lat": "502122N",
    "long": "0044216W"
  },
  {
    "stationcode": "FWM",
    "stationname": "Railway Station",
    "lat": "564900N",
    "long": "0050700W"
  },
  {
    "stationcode": "QEW",
    "stationname": "Railway Station",
    "lat": "523752N",
    "long": "0010729W"
  },
  {
    "stationcode": "USX",
    "stationname": "Railway Station",
    "lat": "502023N",
    "long": "0044722W"
  },
  {
    "stationcode": "OXQ",
    "stationname": "Railway Station",
    "lat": "514513N",
    "long": "0011613W"
  },
  {
    "stationcode": "XRE",
    "stationname": "Railway Station",
    "lat": "512733N",
    "long": "0005822W"
  },
  {
    "stationcode": "WSS",
    "stationname": "Railway Station",
    "lat": "513733N",
    "long": "0035625W"
  },
  {
    "stationcode": "PZC",
    "stationname": "Railway Station",
    "lat": "500720N",
    "long": "0053156W"
  },
  {
    "stationcode": "CFW",
    "stationname": "Railway Station",
    "lat": "512833N",
    "long": "0031045W"
  },
  {
    "stationcode": "ISR",
    "stationname": "Railway Station",
    "lat": "404031N",
    "long": "0144620E"
  },
  {
    "stationcode": "CTJ",
    "stationname": "Railway Station",
    "lat": "410408N",
    "long": "0141942E"
  },
  {
    "stationcode": "LCZ",
    "stationname": "Railway Station",
    "lat": "402044N",
    "long": "0180956E"
  },
  {
    "stationcode": "BQD",
    "stationname": "Railway Station",
    "lat": "403803N",
    "long": "0175620E"
  },
  {
    "stationcode": "BZQ",
    "stationname": "Railway Station",
    "lat": "462947N",
    "long": "0112130E"
  },
  {
    "stationcode": "VNZ",
    "stationname": "Railway Station",
    "lat": "453228N",
    "long": "0113219E"
  },
  {
    "stationcode": "FOI",
    "stationname": "Railway Station",
    "lat": "412757N",
    "long": "0153320E"
  },
  {
    "stationcode": "QNQ",
    "stationname": "Railway Station",
    "lat": "515036N",
    "long": "0055109E"
  },
  {
    "stationcode": "QAZ",
    "stationname": "Railway Station",
    "lat": "491700N",
    "long": "0195400E"
  },
  {
    "stationcode": "XUF",
    "stationname": "Railway Station",
    "lat": "373617N",
    "long": "0005826W"
  },
  {
    "stationcode": "YJM",
    "stationname": "Railway Station",
    "lat": "364241N",
    "long": "0042558W"
  },
  {
    "stationcode": "SPO",
    "stationname": "Railway Station",
    "lat": "362800N",
    "long": "0052400W"
  },
  {
    "stationcode": "EEL",
    "stationname": "Railway Station",
    "lat": "420334N",
    "long": "0013551W"
  },
  {
    "stationcode": "XUA",
    "stationname": "Railway Station",
    "lat": "420800N",
    "long": "0002436W"
  },
  {
    "stationcode": "UTL",
    "stationname": "Railway Station",
    "lat": "363700N",
    "long": "0043000W"
  },
  {
    "stationcode": "YJE",
    "stationname": "Railway Station",
    "lat": "382041N",
    "long": "0002945W"
  },
  {
    "stationcode": "QLQ",
    "stationname": "Railway Station",
    "lat": "413715N",
    "long": "0003759E"
  },
  {
    "stationcode": "RII",
    "stationname": "Railway Station",
    "lat": "414518N",
    "long": "0022836W"
  },
  {
    "stationcode": "OZU",
    "stationname": "Railway Station",
    "lat": "373500N",
    "long": "0043800W"
  },
  {
    "stationcode": "CPJ",
    "stationname": "Railway Station",
    "lat": "395918N",
    "long": "0000308W"
  },
  {
    "stationcode": "JEA",
    "stationname": "Railway Station",
    "lat": "374648N",
    "long": "0034727W"
  },
  {
    "stationcode": "YJW",
    "stationname": "Railway Station",
    "lat": "364048N",
    "long": "0060735W"
  },
  {
    "stationcode": "UGR",
    "stationname": "Railway Station",
    "lat": "422215N",
    "long": "0033958W"
  },
  {
    "stationcode": "QWX",
    "stationname": "Railway Station",
    "lat": "385518N",
    "long": "0062036W"
  },
  {
    "stationcode": "EER",
    "stationname": "Railway Station",
    "lat": "410938N",
    "long": "0010601E"
  },
  {
    "stationcode": "YJH",
    "stationname": "Railway Station",
    "lat": "431905N",
    "long": "0015837W"
  },
  {
    "stationcode": "YJR",
    "stationname": "Railway Station",
    "lat": "421404N",
    "long": "0084247W"
  },
  {
    "stationcode": "XJI",
    "stationname": "Railway Station",
    "lat": "385906N",
    "long": "0035448W"
  },
  {
    "stationcode": "OUQ",
    "stationname": "Railway Station",
    "lat": "422102N",
    "long": "0075223W"
  },
  {
    "stationcode": "YJV",
    "stationname": "Railway Station",
    "lat": "392800N",
    "long": "0002238W"
  },
  {
    "stationcode": "RRA",
    "stationname": "Railway Station",
    "lat": "364400N",
    "long": "0051000W"
  },
  {
    "stationcode": "AVS",
    "stationname": "Railway Station",
    "lat": "403925N",
    "long": "0044100W"
  },
  {
    "stationcode": "OZI",
    "stationname": "Railway Station",
    "lat": "370300N",
    "long": "0044200W"
  },
  {
    "stationcode": "PCI",
    "stationname": "Railway Station",
    "lat": "420056N",
    "long": "0043201W"
  },
  {
    "stationcode": "GDU",
    "stationname": "Railway Station",
    "lat": "403838N",
    "long": "0031056W"
  },
  {
    "stationcode": "YJC",
    "stationname": "Railway Station",
    "lat": "432110N",
    "long": "0082438W"
  },
  {
    "stationcode": "EEM",
    "stationname": "Railway Station",
    "lat": "385958N",
    "long": "0015054W"
  },
  {
    "stationcode": "QIJ",
    "stationname": "Railway Station",
    "lat": "433215N",
    "long": "0054034W"
  },
  {
    "stationcode": "HEV",
    "stationname": "Railway Station",
    "lat": "371511N",
    "long": "0065704W"
  },
  {
    "stationcode": "XUT",
    "stationname": "Railway Station",
    "lat": "375830N",
    "long": "0010754W"
  },
  {
    "stationcode": "QUQ",
    "stationname": "Railway Station",
    "lat": "392741N",
    "long": "0062309W"
  },
  {
    "stationcode": "EEU",
    "stationname": "Railway Station",
    "lat": "423546N",
    "long": "0053458W"
  },
  {
    "stationcode": "YJL",
    "stationname": "Railway Station",
    "lat": "432728N",
    "long": "0034844W"
  },
  {
    "stationcode": "PXS",
    "stationname": "Railway Station",
    "lat": "363600N",
    "long": "0061400W"
  },
  {
    "stationcode": "LGV",
    "stationname": "Railway Station",
    "lat": "422727N",
    "long": "0022632W"
  },
  {
    "stationcode": "YJG",
    "stationname": "Railway Station",
    "lat": "371103N",
    "long": "0033633W"
  },
  {
    "stationcode": "BQZ",
    "stationname": "Railway Station",
    "lat": "385326N",
    "long": "0065853W"
  },
  {
    "stationcode": "OVI",
    "stationname": "Railway Station",
    "lat": "432158N",
    "long": "0055117W"
  },
  {
    "stationcode": "VIO",
    "stationname": "Railway Station",
    "lat": "425029N",
    "long": "0024021W"
  },
  {
    "stationcode": "GIA",
    "stationname": "Railway Station",
    "lat": "415845N",
    "long": "0024901E"
  },
  {
    "stationcode": "XTJ",
    "stationname": "Railway Station",
    "lat": "395145N",
    "long": "0040040W"
  },
  {
    "stationcode": "EEP",
    "stationname": "Railway Station",
    "lat": "424931N",
    "long": "0013942W"
  },
  {
    "stationcode": "ZOB",
    "stationname": "Railway Station",
    "lat": "413058N",
    "long": "0054423W"
  },
  {
    "stationcode": "TEJ",
    "stationname": "Railway Station",
    "lat": "402027N",
    "long": "0010637W"
  },
  {
    "stationcode": "VBR",
    "stationname": "Railway Station",
    "lat": "604316N",
    "long": "0284444E"
  },
  {
    "stationcode": "ZTF",
    "stationname": "Railway Station",
    "lat": "410249N",
    "long": "0733231W"
  }
 ]
