
<div class="container">
  <div class="auto-container">
    <div class="input-field originfield"
    [class.isError]="isSeachClicked && !originCtrl.value">
      <div class="icon-container">
        <i
          class="material-icons icon md-36"
          [class.isErrorIcon]="isSeachClicked && !originCtrl.value"
        >
          my_location
        </i>
      </div>
      <input
        type="text"
        [aa-autocomplete]="origin"
        [formControl]="originCtrl"
        [id]="'origin-autocomplete'"
        (focus)="setFocus('isOriginFocus', true, '0')"
        (blur)="setFocus('isOriginFocus', false, '0')"
        required
        (keydown)="onkeypress($event, '0')"
        #originInput
      />
      <div class="icon-container">
        <i
          class="material-icons icon md-36"
          [class.isErrorIcon]="isSeachClicked && !originCtrl.value"
          *ngIf="!loadingStatus.origin"
        >
          {{ isSeachClicked && !originCtrl.value ? "error" : " " }}
        </i>
        <div *ngIf="loadingStatus.origin" class="lds-ring">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      </div>
      <label
        [attr.for]="'origin-autocomplete'"
        [class]="'active'"
        [class]="isOriginFocus || originCtrl.value ? 'active' : ''"
        >{{ "search-origin" | locale }}</label
      >
    </div>
    <aa-auto-complete #origin (optionSelected)="setOriginStation($event)">
      <aa-option
        *ngFor="let item of origiStations$ | async"
        [value]="stationDisplay(item)"
        [data]="item"
      >
        <label class="fnf-title">{{ stationDisplay(item) }}</label>
        <!-- COMMENT HERE <label class="fnf-description" >{{item.CountryName}}</label> -->
      </aa-option>
      <aa-option *ngIf="(origiStations$ | async)?.length == 0">
        <label class="fnf-title">{{ "errorMessage-noResult" | locale }}</label>
      </aa-option>
      <aa-option *ngIf="loadingStatus.originError">
        <label class="fnf-title">{{ "errorMessage-noOrigin" | locale }}</label>
      </aa-option>
    </aa-auto-complete>
  </div>

  <div class="switchbutton-container">
    <div class="switchbutton">
      <i
        class="material-icons icon md-36"
        [class.isErrorIcon]="isSeachClicked && !originCtrl.value"
        *ngIf="!loadingStatus.origin"
        (click)="switchOrigDest()"
        >swap_horiz
      </i>
    </div>
  </div>

  <div class="auto-container">
    <div
      class="input-field destfield"
      [class.isError]="isSeachClicked && !destCtrl.value"
      [class.active]="isDestFocus"
    >
      <div class="icon-container">
        <i
          class="material-icons icon md-36"
          [class.isErrorIcon]="isSeachClicked && !originCtrl.value"
        >
          location_pin
        </i>
      </div>
      <input
        type="text"
        [aa-autocomplete]="destination"
        [formControl]="destCtrl"
        [id]="'dest-autocomplete'"
        (focus)="setFocus('isDestFocus', true, '1')"
        (blur)="setFocus('isDestFocus', false, '1')"
        required
        #destInput
        (keydown)="onkeypress($event, '1')"
      />
      <div class="icon-container">
        <i
          class="material-icons icon md-36"
          [class.isErrorIcon]="isSeachClicked && !destCtrl.value"
          *ngIf="!loadingStatus.destination"
        >
          {{ isSeachClicked && !destCtrl.value ? "error" : " " }}
        </i>
        <div *ngIf="loadingStatus.destination" class="lds-ring">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      </div>
      <label
        [attr.for]="'dest-autocomplete'"
        [class]="isDestFocus || destCtrl.value ? 'active' : ''"
        >{{ "search-destination" | locale }}</label
      >
    </div>
    <aa-auto-complete
      #destination
      (optionSelected)="setDestinationStation($event)"
    >
      <aa-option
        *ngFor="let item of destinationStations$ | async"
        [value]="stationDisplay(item)"
        [data]="item"
      >
        <label class="fnf-title">{{ stationDisplay(item) }}</label>
        <!-- COMMENT HERE <label class="fnf-description" >{{item.CountryName}}</label> -->
      </aa-option>
      <aa-option *ngIf="(destinationStations$ | async)?.length == 0">
        <label class="fnf-title">{{ "errorMessage-noResult" | locale }}</label>
      </aa-option>

      <aa-option *ngIf="loadingStatus.destinationError">
        <label class="fnf-title">{{
          "errorMessage-noDestination" | locale
        }}</label>
      </aa-option>
    </aa-auto-complete>
  </div>

  <div class="auto-container">
    <div class="fromDateBox">
      <label>{{ "search-departureDate" | locale }}</label>
      <input
        #PickerFromInput
        class="matDateInput"
        [min]="minDate"
        [max]="maxDate"
        matInput
        (dateInput)="setStartDate($event.value)"
        [matDatepicker]="pickerFrom"
        [placeholder]='"search-departureDate" | locale'
        readonly
        (click)="pickerFrom.open()"
      />
      <mat-datepicker-toggle
        matSuffix
        [for]="pickerFrom"
        [disabled]="false"
      ></mat-datepicker-toggle>
      <mat-datepicker
        #pickerFrom
        [disabled]="false"
        [touchUi]="isMobileSize"
      ></mat-datepicker>
    </div>
  </div>
  <!-- <div class="auto-container">
    <div class="toDateBox">
      <input
        #PickerToInput
        class="matDateInput"
        [min]="startDate || minDate"
        matInput
        (dateInput)="setEndDate($event.value)"
        [matDatepicker]="pickerTo"
        [placeholder]='"search-endDate" | locale'
        disabled
      />
      <mat-datepicker-toggle
        matSuffix
        [for]="pickerTo"
        [disabled]="false"
      ></mat-datepicker-toggle>
      <mat-datepicker
        #pickerTo
        [disabled]="false"
        [touchUi]="isMobileSize"
      ></mat-datepicker>
    </div>
  </div> -->

  <!-- <div class="auto-container">
    <div class="fromDateBox">
      <mat-date-range-input [rangePicker]="picker">
        <input matStartDate placeholder="Start date" />
        <input matEndDate placeholder="End date" />
      </mat-date-range-input>

      <mat-date-range-picker #picker></mat-date-range-picker>
    </div>
  </div> -->

  <div class="route-auto-container-button"><button (click) = 'search()'>{{"search-findFlights"| locale}}</button></div>
</div>
<!-- <div class="find-button-container">
  <button (click)="search()">{{ "search-findFlights" | locale }}</button>
</div> -->

<!-- <div class="container"> -->
<!-- <div id="flight-search-widget"></div> -->
<!-- <button (click) = 'search()'>{{"search-findFlights"| locale}}</button> -->
<!-- <aa-under-maintenance></aa-under-maintenance> -->
<!-- </div> -->
