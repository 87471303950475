<!-- refresh cache Info -->
<!-- <ng-container *ngIf="!this.globalOutofSyncIssue"> -->
<ng-container>
  <div class="new-flight-result-info-container" *ngIf="details.length > 0">
    <div class="timelable">
      <div *ngIf="showplus">
        {{ "result-spillovers" | locale }} {{ "result-localTime" | locale }}
        <!-- {{ "result-lastUpdate" | locale : formattedLastUpdatedCache }}. -->
      </div>
      <div *ngIf="!showplus">
        {{ "result-localTime" | locale }}
        <!-- {{ "result-lastUpdate" | locale : formattedLastUpdatedCache }}. -->
      </div>
    </div>
    <p class="plus1hint"><strong>+1</strong> Flight arrives next day</p>
  </div>
  <!-- Results -->
  <div id="new-flight-result" class="new-flight-result-container">
    <div
      *ngFor="let detail of details; let i = index"
      #tabhead
      tabindex="0"
      attr.aria-label="Flight {{ detail.flightName }} status {{
        detail.status
      }} scheduled to depart from {{ detail.depart }} at {{
        detail.scheduledDepartTime
      }} arrive to {{ detail.arrival }} at {{ detail.scheduledArrivalTime }} {{
        detail.carrierName
      }}"
      class="flight-result-container flight-result-wrapper-{{ i }}"
      [class.flip-diverted-cards]="detail.isDiverted"
    >
      <!-- Status - On Time -->
      <!-- <ng-container *ngIf="!outofSyncIssueSearchByRoute[i]"> -->
      <ng-container>
        <div
          class="status-card {{
            details.length === 2 ? 'multi-cards-' + i : 'multi-cards'
          }}"
          [class.merge-cards]="detail.isDiverted"
        >
          <div class="status-info">
            <div class="fly-with small-text-x1" [class.mapview]="!hideMap">
              {{ detail.carrierName | locale }}
            </div>
            <div
              class="status"
              [class.landed]="detail.status == 'Landed'"
              [class.departed]="detail.status === 'Departed'"
              [class.cancelled]="detail.status == 'Cancelled'"
              [class.ontime]="detail.status == 'On Time'"
              [class.departedontime]="
                detail.status == FLIGHT_STATUS.DEPARTED_ON_TIME
              "
              [class.delayed]="detail.status == 'Delayed'"
              [class.diverted]="detail.status == 'Diverted'"
              [class.earlyDeparture]="detail.status == 'Advanced'"
            >
              {{ detail.status }}
            </div>
          </div>
          <!-- Departure and Arrival info -->
          <div class="info-labels">
            <div class="departure-text" [class.hideMap]="!hideMap">
              {{ "result-departure" | locale }}
            </div>
            <div class="arrival-text" [class.hideMap]="!hideMap">
              {{ "result-arrival" | locale }}
            </div>
          </div>
          <div class="airport-and-flight-info">
            <div class="departure-info">
              <div class="city-name departure-city-name">
                {{ detail.depart }}
              </div>
            </div>
            <div class="flight-timeline-img">
              <div class="flight-number small-text-x2">
                {{ detail.flightName }}
              </div>
              <!-- status classes: ontime / departed / landed -->
              <div
                class="flight-travel-progress"
                attr.data-progress="{{ detail.flightProgress }}"
                [class.landed]="detail.status == 'Landed'"
                [class.departed]="detail.status == 'Departed'"
                [class.cancelled]="detail.status == 'Cancelled'"
                [class.ontime]="detail.status == 'On Time'"
                [class.departedontime]="
                  detail.status == FLIGHT_STATUS.DEPARTED_ON_TIME
                "
                [class.delayed]="detail.status == 'Delayed'"
                [class.diverted]="detail.status == 'Diverted'"
                [class.earlyDeparture]="detail.status == 'Advanced'"
              ></div>
              <span
                *ngIf="detail.status == 'Diverted' && details && details[1]"
                class="divertedTo small-text-x2"
                >{{ detail.depart }} -
                {{
                  detail.depart !== details[1].arrival
                    ? details[1].arrival
                    : details[0].arrival
                }}
                diverted to {{ detail.arrival }}</span
              >
            </div>
            <div class="arrival-info text-right">
              <div class="city-name arrival-city-name">
                {{ detail.arrival }}
              </div>
            </div>
          </div>
          <!-- Airport Name info -->
          <div class="airport-terminal-gate-info">
            <div class="departure-info">
              <div class="small-text-x1">
                {{ detail.deptStationDisplay }}
              </div>
            </div>

            <div class="arrival-info text-right">
              <div class="small-text-x1">
                {{ detail.arriveStationDisplay }}
              </div>
            </div>
          </div>
          <!-- Terminal info -->
          <div class="airport-terminal-gate-info">
            <div class="departure-info">
              <span class="badge blue-badge small-text-x1">
                {{ detail.departureTerminalDisplayed }}
              </span>
            </div>

            <div class="arrival-info text-right">
              <span class="badge blue-badge small-text-x1">
                {{ detail.arrivalTerminalDisplayed }}
              </span>
            </div>
          </div>
          <!-- Checkin & Gate info -->
          <div class="flight-gate-checkin-info">
            <div class="gate-checkin-info departure-airport-info">
              <div class="flight-time">
                <div
                  class="small-text-x1"
                  *ngIf="
                    detail.status == 'Advanced' ||
                    detail.status == 'Delayed' ||
                    detail.status == 'Not yet departed' ||
                    detail.status == 'Cancelled' ||
                    detail.status == 'In flight' ||
                    detail.status == FLIGHT_STATUS.FLIGHT_RETURN ||
                    detail.status == FLIGHT_STATUS.RAMP_RETURN
                  "
                >
                  {{ "result-estimated" | locale }}
                </div>
                <div class="small-text-x1" *ngIf="detail.status == 'On Time'">
                  {{ "result-scheduled" | locale }}
                </div>
                <div
                  class="small-text-x1"
                  *ngIf="
                    detail.status == 'Landed' ||
                    detail.status == FLIGHT_STATUS.DEPARTED_ON_TIME ||
                    detail.status == 'Departed' ||
                    detail.status == 'Diverted'
                  "
                >
                  {{ "result-actual" | locale }}
                </div>
                <div
                  class="medium-text departure-time"
                  *ngIf="detail.status == 'On Time'"
                >
                  {{
                    (detail.scheduledDepartTimeDisplayed | TimePipe) === "08:01"
                      ? "08:00"
                      : (detail.scheduledDepartTimeDisplayed | TimePipe)
                  }}
                </div>
                <div
                  class="medium-text departure-time"
                  *ngIf="
                    detail.status == 'Advanced' ||
                    detail.status == FLIGHT_STATUS.DEPARTED_ON_TIME ||
                    detail.status == 'Landed' ||
                    detail.status == 'Cancelled' ||
                    detail.status == 'Departed' ||
                    detail.status == 'Diverted' ||
                    detail.status == 'Delayed'
                  "
                >
                  {{ detail.departTimeDisplayed | TimePipe
                  }}<sup class="spill-over" *ngIf="detail.isSpillOverDepart > 0"
                    >+{{ detail.isSpillOverDepart }}</sup
                  >
                </div>
                <div
                  class="small-text-x2 recorded-time"
                  *ngIf="detail.status != 'On Time'"
                >
                  <span>{{ "result-scheduled" | locale }}</span>
                  <span>{{
                    (detail.scheduledDepartTimeDisplayed | TimePipe) === "08:01"
                      ? "08:00"
                      : (detail.scheduledDepartTimeDisplayed | TimePipe)
                  }}</span>
                </div>
              </div>
              <div class="departure-boarding-info flight-gate">
                <div class="small-text-x1">{{ "result-gate" | locale }}</div>
                <div class="medium-text departure-gate">
                  {{ detail.boardingGate ? detail.boardingGate : "-" }}
                </div>
              </div>
              <div class="departure-boarding-info flight-checkin-counter">
                <div class="small-text-x1">{{ "result-counter" | locale }}</div>
                <div class="medium-text departure-checkin-counter">
                  {{ detail.checkinCounter ? detail.checkinCounter : "-" }}
                </div>
              </div>
            </div>

            <div class="gate-checkin-info arrival-airport-info">
              <div class="flight-time text-right">
                <div
                  class="small-text-x1"
                  *ngIf="
                    detail.status == 'Landed' ||
                    detail.status == 'Not yet departed' ||
                    detail.status == 'Diverted' ||
                    detail.status == 'In flight'
                  "
                >
                  {{ "result-actual" | locale }}
                </div>
                <div class="small-text-x1" *ngIf="detail.status == 'On Time'">
                  {{ "result-scheduled" | locale }}
                </div>
                <div
                  class="small-text-x1"
                  *ngIf="
                    detail.status == FLIGHT_STATUS.FLIGHT_RETURN ||
                    detail.status == FLIGHT_STATUS.RAMP_RETURN
                  "
                >
                  {{ "result-actualReturn" | locale }}
                </div>
                <div
                  class="small-text-x1"
                  *ngIf="
                    detail.status == 'Delayed' ||
                    detail.status == 'Advanced' ||
                    detail.status == FLIGHT_STATUS.DEPARTED_ON_TIME ||
                    detail.status == 'Cancelled' ||
                    detail.status == 'Departed'
                  "
                >
                  {{ "result-estimated" | locale }}
                </div>
                <div
                  class="medium-text arrival-time"
                  *ngIf="detail.status == 'On Time'"
                >
                  {{
                    (detail.scheduledArriveTimeDisplayed | TimePipe) === "08:01"
                      ? "08:00"
                      : (detail.scheduledArriveTimeDisplayed | TimePipe)
                  }}<sup
                    class="spill-over"
                    *ngIf="detail.isSpillOverArrivalScheduled > 0"
                    >+{{ detail.isSpillOverArrivalScheduled }}</sup
                  >
                </div>
                <div
                  class="medium-text arrival-time"
                  *ngIf="detail.status != 'On Time'"
                >
                  {{ detail.arriveTimeDisplayed | TimePipe
                  }}<sup
                    class="spill-over"
                    *ngIf="detail.isSpillOverArrivalActualEstimated > 0"
                    >+{{ detail.isSpillOverArrivalActualEstimated }}</sup
                  >
                </div>
                <div
                  class="small-text-x2 recorded-time"
                  *ngIf="detail.status != 'On Time'"
                >
                  <span>{{ "result-scheduled" | locale }}</span>
                  <span>{{
                    (detail.scheduledArriveTimeDisplayed | TimePipe) === "08:01"
                      ? "08:00"
                      : (detail.scheduledArriveTimeDisplayed | TimePipe)
                  }}</span>
                  <sup
                    class="spill-over"
                    *ngIf="detail.isSpillOverArrivalScheduled > 0"
                    >+{{ detail.isSpillOverArrivalScheduled }}</sup
                  >
                </div>
              </div>
              <!-- <div class="arrival-landing-info flight-gate text-right">
            <div class="small-text-x1">{{ "result-gate" | locale }}</div>
            <div class="medium-text arrival-gate">-</div>
          </div> -->
              <div class="arrival-landing-info flight-baggage text-right">
                <div class="small-text-x1">
                  {{ "result-baggageCarousel" | locale }}
                </div>
                <div class="medium-text arrival-baggage-counter">
                  {{ detail.baggageCarousel ? detail.baggageCarousel : "-" }}
                </div>
              </div>
            </div>
          </div>
          <div
            class="delay-info"
            [class.earlydepartinfo]="detail.isEarlyDepart"
            *ngIf="detail.status == 'Delayed' || detail.status == 'Diverted'"
          >
            <span class="badge yellow-badge small-text-x1">
              {{ "result-delayedBy" | locale }}&nbsp;{{
                detail.getDelayInMinutes
              }}
            </span>
          </div>
        </div>
        <div
          class="map-card-go"
          [class.mapview]="!hideMap"
          [class.listview]="hideMap"
          *ngIf="
            detail.inBoundFlights.flight_map_flag &&
            isSearchByFlightNumber &&
            (detail.status === 'Departed' ||
              detail.status === 'On Time' ||
              detail.status === 'Advanced' ||
              detail.status === 'Delayed')
          "
        >
          <button type="button" class="map-button" (click)="toggleMap()">
            <mat-icon *ngIf="!hideMap"> map</mat-icon>
            <mat-icon *ngIf="hideMap">search</mat-icon
            ><span>{{
              !hideMap ? "Show Status" : "Where is my aircraft?"
            }}</span>
          </button>
          <a (click)="openMobileSheet()"
            ><mat-icon class="mobile-view-inbound-icon" *ngIf="!hideMap">
              info</mat-icon
            ></a
          >
        </div>
        <div class="map-card" [class.hideMap]="hideMap">
          <div
            #flightMapCanvas
            id="flightMapCanvas"
            class="map-card-canvas"
            [class.hideMap]="hideMap"
          ></div>
          <div id="from">{{ detail.depart }}</div>
          <div id="to">{{ detail.arrival }}</div>
          <div id="inboundFrom" *ngIf="detail.inBoundFlights.flight_map_flag">
            {{ detail.inBoundFlights.prev_departure }}
          </div>
          <ng-container *ngIf="!isMobileScreen">
            <div id="inBoundFlight" class="mapStatus inBoundFlightStatus">
              <div class="flightETD_ETA">
                <ng-container *ngIf="inBoundState('first-rotation')">
                  <mat-icon class="inbound-flight-icon"> flight</mat-icon>
                  <span class="info"
                    >Aircraft is being prepared for the departure at
                    {{ detail.inBoundFlights.prev_departure_formatted }}
                  </span>
                </ng-container>
                <ng-container *ngIf="inBoundState('inbound-landed')">
                  <mat-icon class="inbound-flight-icon"> flight</mat-icon>
                  <span class="info"
                    >Your flight is being prepared for departure
                  </span>
                </ng-container>
                <ng-container *ngIf="inBoundState('inbound-on-air')">
                  <mat-icon class="inbound-flight-icon"> flight</mat-icon>
                  <span class="info"
                    >The flight is en route and is expected to arrive at
                    {{ detail.inBoundFlights.prev_arrival_formatted }}
                  </span>
                </ng-container>
              </div>
            </div>

            <div
              id="currentFlight"
              class="mapStatus currentFlight"
              *ngIf="
                inBoundState('inbound-landed') || inBoundState('inbound-on-air')
              "
            >
              <div class="flightETD_ETA">
                <span>My flight</span
                ><span class="flightNumber"
                  >{{ detail.flightName }} | {{ detail.depart }}-{{
                    detail.arrival
                  }}</span
                >
                <span class="info"
                  >{{
                    detail.estimatedUTCDepartureTimeDisplayed ? "ETD" : "STD"
                  }}
                  {{
                    detail.estimatedUTCDepartureTimeDisplayed !== null
                      ? detail.estimatedUTCDepartureTimeDisplayed
                      : (detail.scheduledDepartTimeDisplayed | TimePipe)
                  }}
                </span>
              </div>
            </div>
          </ng-container>
          <!-- <ng-container *ngIf="!detail.inBoundFlights.flight_map_flag">
        <div id="currentFlightETA" class="mapStatus currentFlightETA" *ngIf="detail.status === 'Departed' || detail.status === 'Departed On Time'">
          <div class="flightETD_ETA"><span class="flightNumber">{{detail.flightName}}</span>  <span class="info">{{detail.depart}} - {{detail.arrival}}</span> <span>ETA {{(detail.estimatedArrivalTimeDisplayed  | TimePipe) }} </span></div>
        </div>

        <div id="currentFlightETA" class="mapStatus currentFlightSTD" *ngIf="detail.status === 'On Time'">
          <div class="flightETD_ETA"><span class="flightNumber">{{detail.flightName}}</span>  <span class="info">{{detail.depart}} - {{detail.arrival}}</span> <span>STD {{(detail.scheduledDepartTimeDisplayed  | TimePipe) }} </span></div>
        </div>

        <div id="currentFlightETA" class="mapStatus currentFlightATA" *ngIf="detail.status === 'Landed'">
          <div class="flightETD_ETA"><span class="flightNumber">{{detail.flightName}}</span>  <span class="info">{{detail.depart}} - {{detail.arrival}}</span> <span>ATA {{(detail.arriveTimeDisplayed  | TimePipe) }} </span></div>
        </div>
      </ng-container> -->
        </div>
        <div
          class="mobile-view-inbound"
          [class.open]="bottomSheet"
          *ngIf="isMobileScreen"
        >
          <div class="mobile-pull-up"></div>
          <div
            id="inBoundFlight"
            class="mapStatus inBoundFlightStatus"
            *ngIf="inBoundState('first-rotation')"
          >
            <div class="flightETD_ETA">
              <mat-icon class="inbound-flight-icon"> flight</mat-icon>
              <span class="info"
                >Aircraft is being prepared for the departure at
                {{ detail.inBoundFlights.prev_departure_timein }}
                {{ detail.inBoundFlights.prev_departure_formatted }}
              </span>
            </div>
          </div>
          <div
            id="inBoundFlight"
            class="mapStatus inBoundFlightStatus"
            *ngIf="inBoundState('inbound-landed')"
          >
            <div class="flightETD_ETA">
              <mat-icon class="inbound-flight-icon"> flight</mat-icon>
              <span class="info"
                >Your flight is being prepared for departure
              </span>
            </div>
          </div>
          <div
            id="inBoundFlight"
            class="mapStatus inBoundFlightStatus"
            *ngIf="inBoundState('inbound-on-air')"
          >
            <div class="flightETD_ETA">
              <mat-icon class="inbound-flight-icon"> flight</mat-icon>
              <span class="info"
                >The flight is en route and is expected to arrive at
                {{ detail.inBoundFlights.prev_arrival_timein }}
                {{ detail.inBoundFlights.prev_arrival_formatted }}
              </span>
            </div>
          </div>

          <div
            id="currentFlight"
            class="mapStatus currentFlight"
            *ngIf="
              inBoundState('inbound-landed') || inBoundState('inbound-on-air')
            "
          >
            <div class="flightETD_ETA">
              <span>My flight</span
              ><span class="flightNumber"
                >{{ detail.flightName }} | {{ detail.depart }}-{{
                  detail.arrival
                }}</span
              >
              <span class="info"
                >{{
                  detail.estimatedUTCDepartureTimeDisplayed !== null
                    ? "ETD"
                    : "STD"
                }}
                {{
                  detail.estimatedUTCDepartureTimeDisplayed !== null
                    ? detail.estimatedUTCDepartureTimeDisplayed
                    : (detail.departTimeDisplayed | TimePipe)
                }}
              </span>
            </div>
          </div>
        </div>
      </ng-container>
    </div>
  </div>
</ng-container>

<!-- <ng-container *ngIf="this.globalOutofSyncIssue">
  <div class="error-box">
    <div>
        <h2>{{'errorMessage-oops' | locale}}</h2>
        <div style="margin-top: 1em;"> {{ 'errorMessage-noFlight' | locale }}</div>
    </div>
  </div>
</ng-container> -->
