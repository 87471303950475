import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'aa-results-skeleton',
  templateUrl: './aa-results-skeleton.component.html',
  styleUrls: ['./aa-results-skeleton.component.scss']
})
export class AaResultsSkeletonComponent implements OnInit {

  @Input() origin ='bangalore';
  @Input() dest = 'mumbai';
  // flatbed =true;
  constructor() { }

  ngOnInit() {
  }

}
