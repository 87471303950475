import { Injectable } from '@angular/core';
import { BaseService } from './_base.service';
// import { ɵc as UserdataService } from 'aa-universal-header-footer';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {


  constructor(
    private base: BaseService,
  ) { }
  
  getNotifications(locale):any {
    return this.base.get( environment.notificationUrl + `${locale}` +'/file.json', '')
  }

  getReadMore(locale):any{
    return this.base.get(environment.readMoreUrl+`${locale}`+'/file.json','');
  }
}