
import {ENTER, SPACE} from '@angular/cdk/keycodes';
import {
  AfterViewChecked,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Inject,
  InjectionToken,
  Input,
  OnDestroy,
  Optional,
  Output,
  QueryList,
  ViewEncapsulation,
} from '@angular/core';
import {Subject} from 'rxjs';

import {filter, take, switchMap, delay, tap, map,distinctUntilChanged} from 'rxjs/operators';



let _uniqueIdCounter = 0;

export class OptionSelectionChange {
  constructor(
    /** Reference to the option that emitted the event. */
    public option: OptionComponent,
    /** Whether the change in the option's value was a result of a user action. */
    public isUserInput = false) { }
}


@Component({
  selector: 'aa-option',
  exportAs: 'aaOption',
  templateUrl: './option.component.html',
  styleUrls: ['./option.component.scss'],
  host:{
    'role': 'option',
    '[attr.tabindex]': '_getTabIndex()',
    '[id]': 'id',
    '[attr.aria-selected]': 'selected.toString()',
    '[attr.aria-disabled]': 'disabled.toString()',
    '(click)': '_selectViaInteraction()',
    '(keydown)': '_handleKeydown($event)',

  }
})
export class OptionComponent{

  private _selected = false;
  public _active = false;
  private _disabled = false;
  private _id = `aa-option-${_uniqueIdCounter++}`;
  private _mostRecentViewValue = '';

  /** The unique ID of the option. */
  get id(): string { return this._id; }

  /** Whether or not the option is currently selected. */
  get selected(): boolean { return this._selected; }

  /** The form value of the option. */
 @Input() value: any;

 @Input() data:any;

 @Input()
  get disabled() { return this._disabled; }

 @Output() readonly onSelectionChange = new EventEmitter<any>()

 constructor(
    private _element: ElementRef,
    ) {}

  get active(): boolean {
     return this._active;
   }

 get viewValue(): string {
   // TODO(kara): Add input property alternative for node envs.
   return (this._getHostElement().textContent || '').trim();
 }

 select(): void {
    this._selected = true;
    // this._changeDetectorRef.markForCheck();
    this._emitSelectionChangeEvent();
  }

  deselect(): void {
    this._selected = false;
    // this._changeDetectorRef.markForCheck();
    this._emitSelectionChangeEvent();
  }

  focus(): void {
    const element = this._getHostElement();

    if (typeof element.focus === 'function') {
      element.focus();
    }
  }

  setActiveStyles(): void {
    if (!this._active) {
      this._active = true;
      // this._changeDetectorRef.markForCheck();
    }
  }

  setInactiveStyles(): void {
    if (this._active) {
      this._active = false;
      // this._changeDetectorRef.markForCheck();
    }
  }

  getLabel(): string {
    return this.viewValue;
  }

  _handleKeydown(event: KeyboardEvent): void {
    if (event.keyCode === ENTER || event.keyCode === SPACE) {
      this._selectViaInteraction();

      // Prevent the page from scrolling down and form submits.
      event.preventDefault();
    }
  }

  _selectViaInteraction(): void {
    if (!this.disabled) {
      // console.log('clicked')
      this._selected = true;
      // this._changeDetectorRef.markForCheck();
      this._emitSelectionChangeEvent(true);
    }
  }

  _getTabIndex(): string {
    return this.disabled ? '-1' : '0';
  }

  _getHostElement(): HTMLElement {
    return this._element.nativeElement;
  }

  private _emitSelectionChangeEvent(isUserInput = false): void {
    // console.log('emiting...',this)
   this.onSelectionChange.emit(new OptionSelectionChange(this, isUserInput));
 }


}


// export function _getOptionScrollPosition(optionIndex: number, optionHeight: number,
//     currentScrollPosition: number, panelHeight: number): number {
//   const optionOffset = optionIndex * optionHeight;
//
//   if (optionOffset < currentScrollPosition) {
//     return optionOffset;
//   }
//
//   if (optionOffset + optionHeight > currentScrollPosition + panelHeight) {
//     return Math.max(0, optionOffset - panelHeight + optionHeight);
//   }
//
//   return currentScrollPosition;
// }
