import { Component, HostListener, OnInit } from "@angular/core";
import { FlightStatusResultService } from "../_services/flight-status-result.service";
import { ControllerService } from "../_services/controller.service";
import { FlightStatusInteractor } from "../interactor/flight-status-result-interactor";
import { DateFormatInteractor } from "../interactor/dateFormatInteractor";
import { ListKeyManager, FocusMonitor } from "@angular/cdk/a11y";
import { StatusResult } from "../interface/flight-status-interface";
import { MockData } from "../mock";
import { Subscription } from "rxjs";
import { ɵc as UserdataService } from "aa-universal-header-footer";
import { DateService } from "../_services/date.service";
import { concatMap, tap } from "rxjs/operators";
import { WindowReferenceService } from "../_services/window-reference.service";
// environment.searchUrl

declare var window;
@Component({
  selector: "app-day-header",
  templateUrl: "./day-header.component.html",
  styleUrls: ["./day-header.component.scss"],
})
export class DayHeaderComponent implements OnInit {
  constructor(
    private flightStatusResultService: FlightStatusResultService,
    private controllerService: ControllerService,
    private flightStatusInteractor: FlightStatusInteractor,
    private dateFormatInteractor: DateFormatInteractor,
    private mockData: MockData,
    private userdataService: UserdataService,
    private dateService: DateService,
    private _windowRef: WindowReferenceService
  ) {
    this.selectedTab = 1;
  }

  public loadingStatus: any = [];
  public loadingTerminals: boolean = true;
  public selectedTab: number;
  isDayHeader = false;
  errorCard = false;
  errorMessage: string;
  private dayAndDates: object = {};
  public details: StatusResult[];
  public terminals: any[];
  showSpinner: boolean = true;
  datearr: string[] = [];
  dayarr: string[] = [];
  data: object = [];
  label: any;
  allSub: Subscription[] = [];
  dateArrzProm: string[] = [];
  arrr: string[] = [];
  dates: string[] = this.dateFormatInteractor.dynamicDate()["date"];
  flag: boolean = true;
  org: string;
  destinat: string;
  rs: boolean = false;
  fns: boolean = false;
  public flightCode: string;
  public flightNum: string;
  public sbr: boolean = false;
  public sbfn: boolean = false;
  public startDate: string = "";
  public endDate: string = "";
  public emptyResult: boolean = false;
  public pnrNum: string;
  isDateBarFixed: boolean = false;

  dateEvent(index, event) {
    this.selectedTab = index;

    // console.log(this.selectedTab, "= + + ++ ");
    if (event.key === "Enter" || event.key === undefined) {
      let dateFormatted = this.dateFormatInteractor.inputDateFormat(
        this.dates[index]
      );

      this.controllerService.getSearchDate({
        searchByDate: true,
        date: dateFormatted,
      });

      this._windowRef.pushEventsToGTMOnFindFlights(
        "Search by Date",
        this.flightNum,
        dateFormatted
      );

      this.selectedTab = index;
    }
  }

  @HostListener("window:scroll", ["$event"]) onScroll() {
    // const searchContainerPos = document.getElementById("flightResults");
    const dateListPos = document.getElementById("date-list");
    if (
      dateListPos &&
      window.scrollY > dateListPos &&
      dateListPos.offsetTop + dateListPos.offsetHeight
    ) {
      this.isDateBarFixed = true;
    } else {
      this.isDateBarFixed = false;
    }
  }

  getData(userInput) {
    this.emptyResult = false;
    this.loadingTerminals = true;
    // console.log(this.selectedTab, "inside getdata")
    // console.log(this.dates[this.selectedTab], " this.dates[this.selectedTab]")
    if (this.flag) {
      this.flightCode = userInput.data.selectedCode;
      this.flightNum = userInput.data.flightNumber;
      this.org = userInput.data.origin;
      this.destinat = userInput.data.dest;
      this.flag = false;
    }

    if (userInput.searchByRoute) {
      this.flightCode = userInput.data.selectedCode;
      this.flightNum = userInput.data.flightNumber;
      this.org = userInput.data.origin;
      this.destinat = userInput.data.dest;
      this.rs = true;
      this.fns = false;
      //  console.log(this.rs + " RS " + this.fns)
    }
    if (userInput.searchByFlightNumber) {
      this.flightCode = userInput.data.selectedCode;
      this.flightNum = userInput.data.flightNumber;
      this.org = userInput.data.origin;
      this.destinat = userInput.data.dest;
      this.rs = false;
      this.fns = true;
      //  console.log(this.rs + " FNS " + this.fns)
    }

    if (userInput.searchByPNR) {
      this.pnrNum = userInput.data.pnrNumber;
    }

    let gtmDataLayer = window.gtmDataLayer;
    let terminalResultSub;
    let flightResultSub =
      // this.flightStatusResultService.getFlightStatus(userInput);

      this.flightStatusResultService
        .getFlightStatus(userInput)
        .pipe(
          tap((data) => {
            if (data.length <= 0) {
              this.emptyResult = true;
              this.loadingTerminals = false;
              this.errorMessage = "errorMessage-noFlight";
            }
          }),
          concatMap(
            (data) =>
              (terminalResultSub =
                this.flightStatusResultService.getAirportTerminal(
                  data,
                  userInput
                ))
          )
        )
        .subscribe(
          (data) => {
            if (typeof data === "string") {
              this.isDayHeader = true;
              this.errorCard = true;
              this.details = [];
              this.errorMessage = "errorMessage-noFlightToday";

              if (userInput.searchByRoute) {
                //  console.log(userInput.searchByRoute + " searchbyroute")
                this.rs = true;
                this.fns = false;

                gtmDataLayer.push({
                  event: "trackEventNoEcommerce",
                  "eventDetails.category": "Flight Status Error",
                  "eventDetails.action": "Error (no flights on this day)",
                  "eventDetails.label":
                    "Search by route (" +
                    this.org +
                    "-" +
                    this.destinat +
                    ")  (" +
                    this.dateFormatInteractor.inputDateFormat(
                      this.dates[this.selectedTab]
                    ) +
                    ")",
                });
              }
              if (userInput.searchByFlightNumber) {
                this.rs = false;
                this.fns = true;
                // console.log(userInput.searchByFlightNumber + " searchbynumber")
                gtmDataLayer.push({
                  event: "trackEventNoEcommerce",
                  "eventDetails.category": "Flight Status Error",
                  "eventDetails.action": "Error (no flights on this day)",
                  "eventDetails.label":
                    "Search by flight number (" +
                    this.flightCode +
                    this.flightNum +
                    ")  (" +
                    this.dateFormatInteractor.inputDateFormat(
                      this.dates[this.selectedTab]
                    ) +
                    ")",
                });
              }

              if (userInput.searchByPNR) {
                this.rs = false;
                this.fns = true;
                this.isDayHeader = false;
                // console.log(userInput.searchByFlightNumber + " searchbynumber")
                gtmDataLayer.push({
                  event: "trackEventNoEcommerce",
                  "eventDetails.category": "Flight Status Error",
                  "eventDetails.action": "Error (no flights on this day)",
                  "eventDetails.label":
                    "Search by pnr number (" +
                    this.pnrNum +
                    ")  (" +
                    this.dateFormatInteractor.inputDateFormat(
                      this.dates[this.selectedTab]
                    ) +
                    ")",
                });
              }

              if (userInput.searchByDate && this.fns) {
                //  console.log(this.fns + "   fns GTM")
                gtmDataLayer.push({
                  event: "trackEventNoEcommerce",
                  "eventDetails.category": "Flight Status Error",
                  "eventDetails.action": "Error (no flights on this day)",
                  "eventDetails.label":
                    "Search by flight number (" +
                    this.flightCode +
                    this.flightNum +
                    ")  (" +
                    this.dateFormatInteractor.inputDateFormat(
                      this.dates[this.selectedTab]
                    ) +
                    ")",
                });
              }

              if (userInput.searchByDate && this.rs) {
                //  console.log(this.rs + "   rs GTM")
                gtmDataLayer.push({
                  event: "trackEventNoEcommerce",
                  "eventDetails.category": "Flight Status Error",
                  "eventDetails.action": "Error (no flights on this day)",
                  "eventDetails.label":
                    "Search by route (" +
                    this.org +
                    "-" +
                    this.destinat +
                    ")  (" +
                    this.dateFormatInteractor.inputDateFormat(
                      this.dates[this.selectedTab]
                    ) +
                    ")",
                });
              }
            } else {
              this.isDayHeader = !userInput.searchByPNR;
              this.errorCard = false;
              if (this.flightStatusResultService.testData == "true") {
                console.log("### MOCK");
                this.details = this.flightStatusInteractor.flightStatusResult(
                  this.mockData.getMockdata()
                );
                this.loadingTerminals = false;
                console.log(this.details);
              } else {
                if (
                  // (this.org == "KUL" && this.destinat == "ICN") ||
                  (this.flightCode == "D7" && this.flightNum == "506") ||
                  (this.flightCode == "D7" && this.flightNum == "507")
                ) {
                  this.isDayHeader = true;
                  this.errorCard = true;
                  this.details = [];
                  this.errorMessage = "errorMessage-noFlightToday";
                  return;
                }
                // if(userInput.searchbyroute){
                //   this.rs = true;
                //   this.fns = false;
                //   console.log(this.rs+ " RS "+this.fns)
                // }
                // if(userInput.searchByFlightNumber){
                //   this.rs = false;
                //   this.fns = true;
                //   console.log(this.fns+ " RS "+this.rs)
                // }

                this.emptyResult = data.length <= 0;
                if (this.emptyResult) {
                  this.errorMessage = "errorMessage-noFlight";
                  // this.isDayHeader = false;
                }

                this.details =
                  this.flightStatusInteractor.flightStatusResult(data);
                this.loadingTerminals = false;

                setTimeout(() => {
                  const dateListPos = document.getElementById("date-list");
                  dateListPos.scrollIntoView({ behavior: "smooth" });
                }, 500);
              }
            }
          },
          (err) => {
            //  console.log(err, "internal server erro - - - - -r")
            this.details = [];
            this.isDayHeader = false;
            this.errorCard = true;
            if (err.error == "IncorrectValue") {
              // console.log(err.error, " 8 8 8 8")
              this.errorMessage = "errorMessage-noFlight";
              if (userInput.searchByFlightNumber) {
                gtmDataLayer.push({
                  event: "trackEventNoEcommerce",
                  "eventDetails.category": "Flight Status Error",
                  "eventDetails.action": "Error (No result)",
                  "eventDetails.label":
                    "Search by flight number (" +
                    userInput.data.selectedCode +
                    userInput.data.flightNumber +
                    ")  (" +
                    this.dateFormatInteractor.inputDateFormat(
                      this.dates[this.selectedTab]
                    ) +
                    ")",
                });
              }
            } else {
              this.errorMessage = "errorMessage-serverDown";
              if (userInput.searchByRoute) {
                gtmDataLayer.push({
                  event: "trackEventNoEcommerce",
                  "eventDetails.category": "Flight Status Error",
                  "eventDetails.action": "Error 500",
                  "eventDetails.label":
                    "Search by route (" +
                    userInput.data.origin +
                    "-" +
                    userInput.data.dest +
                    ")  (" +
                    this.dateFormatInteractor.inputDateFormat(
                      this.dates[this.selectedTab]
                    ) +
                    ")",
                });
              }
              if (userInput.searchByFlightNumber) {
                //    console.log('U R in Wrong B')
                gtmDataLayer.push({
                  event: "trackEventNoEcommerce",
                  "eventDetails.category": "Flight Status Error",
                  "eventDetails.action": "Error 500",
                  "eventDetails.label":
                    "Search by flight number (" +
                    userInput.data.selectedCode +
                    userInput.data.flightNumber +
                    ")  (" +
                    this.dateFormatInteractor.inputDateFormat(
                      this.dates[this.selectedTab]
                    ) +
                    ")",
                });
              }
              if (userInput.searchByPNR) {
                //    console.log('U R in Wrong B')
                gtmDataLayer.push({
                  event: "trackEventNoEcommerce",
                  "eventDetails.category": "Flight Status Error",
                  "eventDetails.action": "Error 500",
                  "eventDetails.label":
                    "Search by pnr number (" +
                    userInput.data.pnrNumber +
                    ")  (" +
                    this.dateFormatInteractor.inputDateFormat(
                      this.dates[this.selectedTab]
                    ) +
                    ")",
                });
              }
            }
          }
        );

    this.allSub.push(flightResultSub);
    this.allSub.push(terminalResultSub);
  }

  clearData() {
    this.details = [];
    this.isDayHeader = false;
    this.errorCard = false;
    this.emptyResult = false;
  }

  leftScroll() {
    const left = document.querySelector(".scroll-images");
    left.scrollBy(-200, 0);
  }

  rightScroll() {
    const right = document.querySelector(".scroll-images");
    right.scrollBy(200, 0);
  }

  ngOnInit() {
    let loadingSub = this.controllerService
      .getLoadingStatus()
      .subscribe((value) => {
        // console.log(value)
        this.loadingStatus = value;
      });

    this.allSub.push(loadingSub);

    let localeDateSub = this.userdataService.$localeChange.subscribe(
      (locale) => {
        this.dateService.getDateMask(locale).subscribe((data) => {
          // var aaUtils = window.aaUtils;
          // let aaUtils = window.utils;

          this.dateArrzProm = [];
          for (let date of this.dates) {
            this.dateArrzProm.push(
              // aaUtils.dateFormatter.format(date, data.date.abbreviate, locale)
              this.dateFormatInteractor.formatDate(date)
            );
          }
          Promise.all(this.dateArrzProm).then((resp) => {
            this.datearr = [];
            this.datearr = resp;
          });
        });
      }
    );
    this.allSub.push(localeDateSub);

    this.dayarr = this.dateFormatInteractor.dynamicDate()["day"];

    this.dates[this.selectedTab] = this.dateFormatInteractor.formatDate(
      // new Date()
      this.dates[this.selectedTab]
    );
    let searchSub = this.controllerService.search$.subscribe((userInput) => {
      console.log("DH userInput ", userInput);
      let datesSearched = this.dateFormatInteractor.dynamicDateSearch();

      if (userInput.data.startDate && userInput.data.endDate) {
        datesSearched = this.dateFormatInteractor.dynamicDateSearch(
          userInput.data.startDate,
          userInput.data.endDate
        );
      } else if (userInput.data.startDate && !userInput.data.endDate) {
        datesSearched = this.dateFormatInteractor.dynamicDateSearch(
          userInput.data.startDate,
          userInput.data.startDate
        );
      } else if (!userInput.data.startDate && userInput.data.endDate) {
        datesSearched = this.dateFormatInteractor.dynamicDateSearch(
          userInput.data.endDate,
          userInput.data.endDate
        );
      }

      this.dates = datesSearched["date"];
      this.datearr = datesSearched["date"];
      this.dayarr = datesSearched["day"];
      this.selectedTab = datesSearched["selected"];

      const showDayHeader = !userInput["searchByPNR"];
      this.isDayHeader = showDayHeader;

      this.dates[this.selectedTab] = this.dateFormatInteractor.formatDate(
        this.dates[this.selectedTab]
      );
      return this.getData(userInput);
    });

    this.allSub.push(searchSub);

    let dateSub = this.controllerService.searchByDate$.subscribe(
      (userInput) => {
        return this.getData(userInput);
      }
    );
    this.allSub.push(dateSub);

    let clearSearchSub = this.controllerService.clearSearch$.subscribe(
      (_userInput) => {
        this.clearData();
      }
    );

    this.allSub.push(clearSearchSub);
  }

  ngOnDestroy() {
    for (let sub of this.allSub) {
      if (sub) sub.unsubscribe();
    }
  }
}
