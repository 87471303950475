import { ChangeDetectorRef, Component, Inject, OnInit } from "@angular/core";
import { Meta, MetaDefinition } from "@angular/platform-browser";
import { LookupService } from "../_services/lookup.service";
import { ControllerService } from "../_services/controller.service";
import { NewLocaleService } from "../_services/locale_new.service";
import { FlightStatusResultService } from "../_services";
import { ɵc as UserDataService } from "aa-universal-header-footer";
import { environment } from "../../environments/environment";
import { ActivatedRoute } from "@angular/router";
import {
  DEEPLINK_PARAM_KEY,
  DEEPLINK_PARAM_VALUE,
} from "../_utilities/constant-variables";
import { DOCUMENT } from "@angular/common";

@Component({
  selector: "aa-flight-search",
  templateUrl: "./aa-flight-search.component.html",
  styleUrls: ["./aa-flight-search.component.scss"],
})
export class FlightSearchComponent implements OnInit {
  loadingStatus: any = {};
  private noOfLoaders = 3;
  private loadersArray = [];
  isError = false;
  currentLocale: string = "en-gb";
  loadingSub: any;
  env = environment;
  showAppHeaderFooter = true;
  isUnderMaintenance = false;
  constructor(
    private lookupService: LookupService,
    private controllerService: ControllerService,
    private localeService: NewLocaleService,
    private flightStatusResultService: FlightStatusResultService,
    private userDataService: UserDataService,
    private metaService: Meta,
    private route: ActivatedRoute,
    private cd: ChangeDetectorRef,
    @Inject(DOCUMENT) private document: Document
  ) {
    this.loadersArray = [...Array(this.noOfLoaders)];
  }
  ngOnInit() {
    this.route.queryParams.subscribe((params: Array<string>) => {
      const mobileDevice = params[DEEPLINK_PARAM_KEY.ANDROID_IOS];
      console.log(mobileDevice);
      if (
        mobileDevice === DEEPLINK_PARAM_VALUE.isAndroid ||
        mobileDevice === DEEPLINK_PARAM_VALUE.isIos
      ) {
        this.showAppHeaderFooter = false;
      }
      if (this.isUnderMaintenance) {
        this.document.body.classList.add("maintenance-mode");
      }
    });

    console.log(this.showAppHeaderFooter);

    this.loadingSub = this.controllerService
      .getLoadingStatus()
      .subscribe((value) => {
        this.loadingStatus = value;
      });
    this.cd.detectChanges();
  }

  ngOnDestroy() {
    if (this.loadingSub) this.loadingSub.unsubscribe();
  }
}
