import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';

@Injectable()
export class ConfigService {

  public env = environment;
  constructor() {
  }
  getGcsUrl(): string {
    if (this.env.gcsUrl) {
      return this.env.gcsUrl;
    }

    return '';
  }

  getLocaleBucketUrl(locale): string {
    let [lang1, lang2] = locale.split('-');
    lang2 = lang2.toLowerCase();
    let formatLocale = lang1 + '-' + lang2;
    let local_URL = this.env.localeBucketUrl + `${formatLocale}` + '/file.json'
    return local_URL;
  }

  public dynamicHeader: number = 3;
  public showFlightResult: number = 10;
}
