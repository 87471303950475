export const environment = {
  production: true,
  uhfEnv: "dev",
  gcsUrl: "https://sch.apiairasia.com",
  searchUrl: "https://flightstatus-dot-airasia-flightstatus-dev.appspot.com/",
  // searchUrlNew: "https://flightstatus-4zdy2ynl3a-as.a.run.app",
  searchUrlNew: "https://flightstatus-4zdy2ynl3a-as.a.run.app/api/v2",
  accessToken: null,
  localeBucketUrl: "https://sch.apiairasia.com/flightstatus/main/",
  testData: "true",
  notificationUrl: "https://sch.apiairasia.com/flightstatus/notificationstg/",
  readMoreUrl: "https://sch.apiairasia.com/flightstatus/main/",
  dateFormatUrl: "https://sch.apiairasia.com/dataformat/date/",
  airportTerminalUrl: "https://sch.apiairasia.com/odterminal",
  airportTerminalPageUrl:
    "https://aacsm--aauat.sandbox.my.site.com/s/airports-and-terminals",
  authUrl: "https://flightstatus-4zdy2ynl3a-as.a.run.app",
  imageStorage: "https://static.stgairasia.com/flight-status/images",
};
