import { Injectable } from "@angular/core";

function getWindow(): any {
  return window;
}

export interface GTMDataLayer {
  event?: string;
  "eventDetails.category": string;
  "eventDetails.action": string;
  "eventDetails.label": string;
}

@Injectable({
  providedIn: "root",
})
export class WindowReferenceService {
  constructor() {}

  get nativeWindow() {
    return getWindow();
  }

  setGTMDataLayer(obj: GTMDataLayer): void {
    if (obj) {
      this.nativeWindow.gtmDataLayer.push(obj);
    }
  }

  pushEventsToGTM(tab: string): void {
    let dl = {
      event: "trackEventNoEcommerce",
      "eventDetails.category": "flightstatus|tab",
      "eventDetails.action": "click",
      "eventDetails.label": tab, //send either "search by flight number" or "Search by route"
    };
    this.setGTMDataLayer(dl);
  }

  pushEventsToGTMOnFindFlights(
    tab: string,
    flightNo: string,
    departureDate: string
  ): void {
    let dl = {
      event: "trackEventNoEcommerce",
      "eventDetails.category": `flightstatus|${tab}|findflights`,
      "eventDetails.action": "click",
      "eventDetails.label": `${flightNo}|${departureDate}`, //send either "search by flight number" or "Search by route"
    };
    this.setGTMDataLayer(dl);
  }

  pushEventsToGTMOnFindRoutes(
    tab: string,
    origin: string,
    destination: string
  ): void {
    let dl = {
      event: "trackEventNoEcommerce",
      "eventDetails.category": `flightstatus|${tab}|findflights`,
      "eventDetails.action": "click",
      "eventDetails.label": `${origin}|${destination}`, //send either "search by flight number" or "Search by route"
    };
    this.setGTMDataLayer(dl);
  }

  pushEventsToGTMOnWhereIsMyAircraft(): void {
    let dl = {
      event: "trackEventNoEcommerce",
      "eventDetails.category": `flightstatus|map`,
      "eventDetails.action": "click",
      "eventDetails.label": "where is my aircraft", //send "where is my aircraft"
    };
    this.setGTMDataLayer(dl);
  }
}
